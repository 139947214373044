export function isGptQuestionGenerator(roles, isSuperAdmin) {
  if (isSuperAdmin) {
    return true;
  }
  for (let i = 0; i < roles?.length; i++) {
    if (roles[i].name === 'Create GPT questions') {
      return true;
    }
  }

  return false;
}
