import axios from 'axios';
import UniversalCookies from 'universal-cookie';

import setAuthorizationToken from './setAuthorizationToken';

export default function redirectToLoginIfTokenExpired(callback) {
  const cookies = new UniversalCookies();
  const token = cookies.get('token2');
  const errorStatus = 401;
  const errorMessages = [
    'No session found',
    'No Authorization header was found',
  ];

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { response } = error;

        if (
          response &&
          response.status === errorStatus &&
          'data' in response &&
          typeof response.data === 'object' &&
          'err' in response.data &&
          errorMessages.indexOf(response.data.err) !== -1
        ) {
          sessionStorage.setItem(
            'url_before_redirected_to_login',
            window.location.href,
          );
          callback();
          setAuthorizationToken();
          if (!token) {
            window.location.href = '/login';
          }
        }
      }

      return Promise.reject(error);
    },
  );
}
