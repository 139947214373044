export const SET_COUPON = 'coupons/SET_COUPON';
export const SET_COUPONS = 'coupons/SET_COUPONS';
export const SET_CODE_GROUPS = 'coupons/SET_CODE_GROUPS';
export const SET_IS_LOADING_CODE_GROUPS = 'coupons/SET_IS_LOADING_CODE_GROUPS';
export const ADD_CODE_GROUPS = 'coupons/ADD_CODE_GROUPS';
export const CANCELL_ALL_CODES = 'coupons/CANCELL_ALL_CODES';
export const UPDATE_CODE_GROUP = 'coupons/UPDATE_CODE_GROUP';
export const ADD_COUPON = 'coupons/ADD_COUPON';
export const UPDATE_COUPON = 'coupons/UPDATE_COUPON';
export const UPDATE_NEW_COUPONS = 'coupons/UPDATE_NEW_COUPONS';
export const UPDATE_SUBCODES = 'coupons/UPDATE_SUBCODES';
export const SET_CODE_GROUP = 'coupons/SET_CODE_GROUP';
export const SET_IS_GETTING_CODE_GROUP = 'coupons/SET_IS_GETTING_CODE_GROUP';
export const CLEAN_CODE_GROUP = 'coupons/CLEAN_CODE_GROUP';
export const SET_SELECETED_CODE_GROUP = 'coupons/SET_SELECETED_CODE_GROUP';
export const CANCEL_CODE = 'coupons/CANCEL_CODE';
export const SET_REDEEMED_CODES = 'coupons/SET_REDEEMED_CODES';
export const SET_UNREDEEMED_CODES = 'coupons/SET_UNREDEEMED_CODES';
export const SET_PLAN_SUBSCRIPTIONS = 'coupons/SET_PLAN_SUBSCRIPTIONS';
export const SET_COUPONS_REFERRAL = 'coupons/SET_COUPONS_REFERRAL';
export const SET_SELECTED_COUPON_REFERRAL =
  'coupons/SET_SELECTED_COUPON_REFERRAL';
export const SET_LOADING_COUPONS = 'coupons/SET_LOADING_COUPONS';
export const SET_BAD_REQUEST = 'coupons/SET_BAD_REQUEST';
export const SET_WAITING_RESPONSE = 'coupons/SET_WAITING_RESPONSE';
export const SET_NEW_COUPONS = 'coupons/SET_NEW_COUPONS';
export const SET_COUPON_DETAIL = 'coupons/SET_COUPON_DETAIL';
export const SET_DELETE_COUPON = 'coupons/SET_DELETE_COUPON';
export const SET_LOAD_COUPONS = 'coupons/SET_LOAD_COUPONS';
