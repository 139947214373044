import './AssignmentsByWriter.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import _ from 'underscore';

import Spinner from '../../../../commonComponents/Spinner/Spinner';
import {
  getStatusByName,
  TablesStatusFilters,
} from '../../../../constants/assignments';
import NumberUtils from '../../../../lib/NumberUtils';
import QuestionsContainer from '../../../Questions/components/QuestionsContainer';
import ReviewHistoryContainer from '../../../ReviewHistory/components/ReviewHistoryContainer';
import Table from '../Assignemnts/Table/Table';
import AssignWriterModalContainer from './AssignWriterModal/AssignWriterModalContainer';

export default class AssignmentsByWriter extends React.Component {
  componentWillMount() {
    this.props.loadAllWriters();
  }

  getWriters = (writers) => {
    let WritersObj = [];
    _.each(writers, (value) => {
      WritersObj.push(value[0]);
    });
    WritersObj = _.sortBy(WritersObj, 'name');

    const toReturn = [];
    _.each(WritersObj, (value) => {
      const AA_assignments = value.assignments.filter(
        (asig) => asig.status === 'Submitted',
      );
      const showAA =
        AA_assignments &&
        AA_assignments !== undefined &&
        AA_assignments.length > 0;
      toReturn.push(
        <div
          key={value.id}
          className={
            this.props.selectedWriter
              ? this.props.selectedWriter.id === value.id
                ? 'writer active justify-content-between'
                : 'writer justify-content-between'
              : ''
          }
          onClick={() => {
            this.props.selectWriter(value);
          }}
        >
          <span>
            {value.name} {value.lastName != null ? value.lastName : ''}{' '}
          </span>
          <div className="counters">
            {showAA && (
              <span
                className="AA-assignments-count"
                id={`AA-assignments-count-${value.id}`}
                data-tooltip-content=""
                data-tooltip-id={`AA-assignments-count-${value.id}-tooltip`}
              >
                ({AA_assignments.length})
              </span>
            )}
            {showAA && <span> / </span>}
            <span
              className="All-assignments-count"
              id={`All-assignments-count-${value.id}`}
              data-tooltip-content=""
              data-tooltip-id={`All-assignments-count-${value.id}-tooltip`}
            >
              ({value.assignments.length})
            </span>
          </div>
          <ReactTooltip
            id={`AA-assignments-count-${value.id}-tooltip`}
            place="bottom"
            variant="dark"
            className="react-tooltip"
          >
            Total assignments awaiting approval
          </ReactTooltip>
          <ReactTooltip
            id={`All-assignments-count-${value.id}-tooltip`}
            place="bottom"
            variant="dark"
            className="react-tooltip"
          >
            Total active assignments
          </ReactTooltip>
        </div>,
      );
    });
    return toReturn;
  };

  getStatusCount(status) {
    if (this.props.selectedWriter) {
      if (status === 'In progress') {
        return _.filter(
          this.props.selectedWriter.assignments,
          (item) => item.status === 'In_Progress',
        ).length;
      }
      if (status === 'Not Started') {
        return _.filter(
          this.props.selectedWriter.assignments,
          (item) => item.status === 'Not_Started',
        ).length;
      }
      if (status === 'Awaiting approval') {
        return _.filter(
          this.props.selectedWriter.assignments,
          (item) => item.status === 'Submitted',
        ).length;
      }
    } else {
      return 0;
    }
  }

  getQuestionComponent = () => {
    if (
      this.props.isQuestionOverview &&
      this.props.selectedAssignment.status === 'Submitted'
    ) {
      return (
        <ReviewHistoryContainer
          assignment={this.props.selectedAssignment}
          isQual={!this.props.selectedAssignment.studyProgram.type === 'qual'}
          writers
        />
      );
    }
    let reference = {};
    if (
      this.props.selectedAssignment &&
      this.props.selectedAssignment.chapter &&
      this.props.selectedAssignment.chapter.reference
    ) {
      reference = this.props.selectedAssignment.chapter.reference;
    }
    return (
      <QuestionsContainer
        assignment={this.props.selectedAssignment}
        selectedRate={this.props.selectedAssignment.studyProgram.typeId.id}
        isQual={!this.props.selectedAssignment.studyProgram.type === 'qual'}
        reference={reference}
        writers
      />
    );
  };

  showAssignModal = () => {
    const writers = this.props.writers || [];
    const allAssignments = [];
    _.each(writers, (value) => {
      _.each(value[0].assignments, (assignment) => {
        allAssignments.push(assignment);
      });
    });
    this.props.openModal({
      modalContent: (
        <AssignWriterModalContainer
          closeModal={this.props.closeModal}
          title="Assign Writers"
          assignments={allAssignments}
          selectedWriter={this.props.selectedWriter}
          writers={writers}
          rolesPermissions={this.props.rolesPermissions}
        />
      ),
      modalClassName: 'someClass',
    });
  };

  render() {
    const writers = this.props.writers || [];
    const permissions = this.props.rolesPermissions;
    const AssignPermissions = [];
    permissions.forEach((p) => {
      if (p.actions.indexOf('Assignment.Assign') !== -1) {
        AssignPermissions.push(p);
      }
    });
    const canAssign =
      this.props.user.isSuperAdmin || AssignPermissions.length > 0;

    return (
      <div style={{ height: '100%' }}>
        {this.props.selectedAssignment ? (
          this.getQuestionComponent()
        ) : (
          <Row style={{ height: '100%' }}>
            <Col
              xs={6}
              md={3}
              ref="AssignmentList"
              className="GroupList assignmentsByWriter"
            >
              <Row className="GroupRow align-left">
                <div className="globalCountsContainer">
                  <div className="countsContainer">
                    <div className="count activeTab">Writers</div>
                  </div>
                </div>
                <div className="writers">{this.getWriters(writers)}</div>
              </Row>
            </Col>
            {!this.props.selectedWriter && (
              <Col
                md={9}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="text-center">
                  Loading Writers
                  <Spinner />
                </div>
              </Col>
            )}

            {this.props.selectedWriter && (
              <Col
                md={9}
                style={{
                  flex: 1,
                  paddingRight: '30px',
                  paddingLeft: '15px',
                  paddingTop: '15px',
                }}
                className="assignmentsTables"
              >
                {canAssign && (
                  <div className="assignmentsBtnRow">
                    <div
                      className="assignmentsBtn"
                      onClick={this.showAssignModal}
                    >
                      <div className="assignmentsBtnText">Assign</div>
                    </div>
                  </div>
                )}
                {TablesStatusFilters.map((filter, index) => {
                  const count = this.getStatusCount(filter);
                  return (
                    <div
                      className={index !== 0 ? 'statusTableContainer' : ''}
                      key={NumberUtils.obtainUniqueKey()}
                    >
                      <div className="filterName">{`${filter}: ${count}`}</div>
                      {count > 0 ? (
                        <Table
                          {...this.props}
                          assignments={this.props.selectedWriter.assignments.filter(
                            (assignment) =>
                              assignment.status ===
                              getStatusByName(
                                filter === 'Awaiting approval'
                                  ? 'Submitted'
                                  : filter,
                              ),
                          )}
                          activeStatus={
                            filter === 'Awaiting approval'
                              ? 'Submitted'
                              : filter
                          }
                          Writers
                          summary={this.props.summary}
                          setLoadingCreateGPTquestion={
                            this.props.setLoadingCreateGPTquestion
                          }
                        />
                      ) : (
                        <div className="noAssignmentsText">
                          No assignments to show
                        </div>
                      )}
                    </div>
                  );
                })}
                <br />
              </Col>
            )}
          </Row>
        )}
      </div>
    );
  }
}
