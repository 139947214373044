import React from 'react';
import { Row, Col } from 'react-bootstrap';

const barLetter = [
  '0',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

class searchBarFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLetter: '',
      selectedbook: '',
      index: 0,
      hasToScroll: false,
      pageClicked: 0,
    };
  }

  handleOnClick = letter => {
    this.props.setParentStates(letter);
    this.props.getNamesToSecondBar(letter);
    this.props.onClearReferences();
    this.props.loadReferences(
      null,
      null,
      '',
      0,
      false,
      this.props.rate.id,
      undefined,
      letter,
    );
    this.setState({ selectedLetter: letter, index: 0 });
    return;
  };

  onHandleBookClick = async (book, index) => {
    if (this.props.references.length < index * 20) {
      this.props.onClearReferences();
      await this.props.loadReferences(
        null,
        null,
        '',
        0,
        false,
        this.props.rate.id,
        index * 20 + 20,
        book[0],
      );
    }
    window.jQuery('.infinite-scroll').scrollTop(58 * index * 20);
    this.setState({
      selectedbook: book,
      hasToScroll: true,
      pageClicked: index,
    });
    return;
  };

  componentDidUpdate = prevProps => {
    if (this.state.hasToScroll && !this.props.loading) {
      window
        .jQuery('.infinite-scroll')
        .scrollTop(58 * this.state.pageClicked * 20);
      this.setState({ hasToScroll: false });
    }
  };

  setSecondBarState = indexSecondBar => {
    this.setState({ index: indexSecondBar });
  };

  onHandleShowMoreClick = addition => {
    this.setState({ index: this.state.index + addition });
  };

  render() {
    const secondBar = [];
    for (let i = this.state.index; i < this.state.index + 6; i++) {
      if (this.props.namesToSecondBar[i]) {
        secondBar.push(this.props.namesToSecondBar[i]);
      }
    }
    return (
      <Row
        className="searchbar-filter"
        style={{
          height:
            this.props.namesToSecondBar &&
            this.props.namesToSecondBar.length > 0
              ? '10%'
              : '5%',
          zIndex: '1',
          width: 'auto',
          marginRight: '2px',
        }}
      >
        {this.props.namesToSecondBar && this.props.namesToSecondBar.length > 0 && (
          <Col className="second-filter">
            {secondBar.length === 6 ? (
              <button
                disabled={this.state.index === 0 ? true : false}
                onClick={() => this.onHandleShowMoreClick(-1)}
                className="individual-name arrow-btn"
              >
                {'<'}
              </button>
            ) : (
              ''
            )}
            {secondBar.map((names, index) => (
              <div
                id={names}
                className="individual-name"
                onClick={() => this.onHandleBookClick(names, index)}
              >
                {names}
              </div>
            ))}
            {secondBar.length === 6 ? (
              <button
                disabled={
                  this.state.index === this.props.namesToSecondBar.length - 6
                    ? true
                    : false
                }
                className="individual-name arrow-btn"
                onClick={() => this.onHandleShowMoreClick(1)}
              >
                {'>'}
              </button>
            ) : (
              ''
            )}
          </Col>
        )}
        <Col className="letter-filter">
          {barLetter.map(letter => (
            <div
              id={letter}
              className={`individual-letter${
                this.state.selectedLetter === letter ? ' selected' : ''
              }`}
              onClick={event => this.handleOnClick(event.target.innerHTML)}
            >
              {letter}
            </div>
          ))}
        </Col>
      </Row>
    );
  }
}

export default searchBarFilter;
