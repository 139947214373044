import * as type from './actionTypes';

export const getAllBannersAction = () => ({
  type: type.GET_ALL_BANNERS,
});
export const setAllBannersAction = banners => ({
  type: type.SET_ALL_BANNERS,
  banners,
});

export const setBannerDetailsAction = banner => ({
  type: type.SET_BANNER_DETAILS,
  banner,
});

export const loadBannerDetails = value => ({
  type: type.LOAD_BANNER_DETAILS,
  value,
});

export const bannerDetailsAction = banner => ({
  type: type.BANNER_DETAILS,
  banner,
});

export const createBannerView = (value, editBanner) => ({
  type: type.CREATE_BANNER_VIEW,
  value,
  editBanner,
});

export const createBannerAction = banner => ({
  type: type.CREATE_BANNER,
  banner,
});

export const editBannerAction = banner => ({
  type: type.EDIT_BANNER,
  banner,
});

export const deleteBannerAction = banner => ({
  type: type.DELETE_BANNER,
  banner,
});

export const activeBannerAction = (bannerId, value) => ({
  type: type.ACTIVE_BANNER,
  bannerId,
  value,
});

export const setLoadingInfo = value => ({
  type: type.SET_LOADING_INFO_BANNER,
  value,
});
