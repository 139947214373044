import './Admins.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import Select from 'react-select';
import { Field } from 'redux-form';
import _ from 'underscore';

import PhotoRow from '../../../commonComponents/AdminProfile/PhotoRow';
import Card from '../../../commonComponents/Card/Card';
import CheckBoxList from '../../../commonComponents/CheckBoxList/CheckBoxList';
import { InputWithoutIcon } from '../../../commonComponents/Input/Input';
import NumberUtils from '../../../lib/NumberUtils';
import {
  validateEmail,
  validatePassword,
} from '../../../utilities/getItemsHelper';

class NewAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'Select type',
      redBorder: {
        name: false,
        lastName: false,
        email: false,
        password: false,
        rate: false,
        rank: false,
        dutyState: false,
        userName: false,
      },
      empty: false,
      dateHired: null,
      nextRaiseDue: null,
      selectedRoles: [],
      files: null,
      photo: null,
      openRole: {},
      isOpen: false,
    };
  }

  componentWillMount() {
    this.props.loadQuals();
    this.props.loadRates();
    this.props.getAllroles();
  }

  componentWillReceiveProps({ creationResponse, roles }) {
    if (
      this.props.creationResponse === creationResponse &&
      creationResponse !== null
    )
      return;
    this.setState({
      empty: true,
    });
    this.props.resetForm();
    this.reinitValues();
  }

  cardRow = (
    name,
    placeholder,
    type,
    select = false,
    options,
    selectPlaceholder,
    empty,
    isRequired,
  ) => (
    <Row className="cardRowContainer" style={{ width: '100%' }}>
      <Col xs={4} md={4} lg={4}>
        <div className="valueName">{placeholder}</div>
      </Col>
      <Col xs={8} md={8} lg={8}>
        {select
          ? this.renderSelect(options, selectPlaceholder, name)
          : this.renderInput(name, placeholder, type, empty, isRequired)}
      </Col>
    </Row>
  );

  renderSelect = (options, selectPlaceholder, name) => (
    <select
      value={this.state[name]}
      className={
        this.state.redBorder[name]
          ? 'form-control input-group mb-3 selectNewStudent redBorder'
          : 'form-control input-group mb-3 selectNewStudent '
      }
      onChange={(e) => {
        if (name === 'rate')
          this.setState({
            [name]: e.target.value,
            plan: 'Select plan',
            type: 'Select type',
          });
        else {
          this.setState({ [name]: e.target.value });
        }
      }}
    >
      <option value={selectPlaceholder}>{selectPlaceholder}</option>
      {options &&
        options.length !== 0 &&
        options.map((option) => (
          <option
            key={`option-select-${option.id}`}
            value={option.id}
            disabled={!!option.disabled}
          >
            {option.name}
          </option>
        ))}
    </select>
  );

  renderInput = (
    name,
    placeholder,
    type = 'text',
    empty,
    isRequired = false,
  ) => (
    <div>
      <Field
        name={name}
        type={type}
        component={InputWithoutIcon}
        placeholder={placeholder}
        className={
          this.state.redBorder[name] ? 'form-control redBorder' : 'form-control'
        }
        canShowPassword
        empty={empty}
        isRequired={isRequired}
        resetEmptyInput={() => {
          this.setState({ empty: false });
        }}
        focusInput={this.focusInput}
      />
      {this.state.errors && this.state.errors[name] && (
        <span className="errors">{this.state.errors[name]}</span>
      )}
    </div>
  );

  reinitValues = () => {
    this.setState({
      redBorder: {
        name: false,
        lastName: false,
        email: false,
        password: false,
        userName: false,
      },
      photo: null,
      selectedRoles: [],
      openRole: {},
    });
  };

  validateExistEmail = (userEmail) => {
    let userDontExist = true;
    this.props.admins.map((user) => {
      if (user.email === userEmail) {
        userDontExist = false;
      }
    });
    return userDontExist;
  };

  validateExistUsername = (userName) => {
    let userDontExist = true;
    this.props.admins.map((user) => {
      if (user.userName === userName) {
        userDontExist = false;
      }
    });
    return userDontExist;
  };

  validate = (fields) => {
    this.setState({ isOpen: false });
    if (
      !fields.name ||
      !fields.lastName ||
      !validateEmail(fields.email) ||
      !this.validateExistEmail(fields.email) ||
      !validatePassword(fields.password) ||
      !this.validateExistUsername(fields.userName)
    ) {
      this.setState({
        redBorder: {
          name: !fields.name,
          lastName: !fields.lastName,
          email:
            !validateEmail(fields.email) ||
            !this.validateExistEmail(fields.email),
          password: !validatePassword(fields.password),
          userName: !this.validateExistUsername(fields.userName),
        },
      });
      return;
    }
    const newadmin = { ...fields };

    if (this.state.photo) {
      newadmin.photo = this.state.photo;
    }
    if (this.state.selectedRoles) {
      newadmin.roles = this.state.selectedRoles;
    }
    newadmin.rateRoles = [];
    newadmin.qualRoles = [];

    this.props.roles.map((role) => {
      if (this.state[role.name]) {
        this.state[role.name].map((rate) => {
          rate.qual
            ? newadmin.qualRoles.push(rate)
            : newadmin.rateRoles.push(rate);
          return rate;
        });
      }
      if (this.state[`${role.name}isQual`]) {
        this.state[`${role.name}isQual`].map((qual) => {
          newadmin.qualRoles.push(qual);
          return qual;
        });
      }
      return role;
    });
    this.props.onCreateAdmin(newadmin);
  };

  itemsForCheckboxList = () =>
    this.props.roles
      .filter((r) => !r.isStudyProgramRole)
      .map((r) => ({
        id: r.id,
        name: r.name,
        checked: this.state.selectedRoles.includes(r.id),
      }));

  CheckBoxListChange = (id) => {
    const role = parseInt(id, 10);
    let selectedRoles = this.state.selectedRoles;
    if (selectedRoles.includes(role)) {
      selectedRoles = selectedRoles.filter((r) => r !== role);
    } else {
      selectedRoles.push(role);
    }
    this.setState({ selectedRoles });
  };

  updatePhoto = (user) => {
    if (user.hasOwnProperty('photo')) {
      this.setState({ photo: user.photo });
    }
    if (user.hasOwnProperty('files')) {
      this.setState({ files: user.files });
    }
  };

  onChange = (role, selectedOptions, isQual = false) => {
    const roleName = isQual ? `${role.name}isQual` : role.name;
    const otherOptions = this.state[roleName]
      ? this.state[roleName].filter((s) =>
          s.hasOwnProperty(isQual ? 'rate' : 'qual'),
        )
      : [];
    this.setState({
      [roleName]: otherOptions.concat(
        selectedOptions.map((s) => {
          if (isQual) {
            return {
              role: role.id,
              qual: s.id,
            };
          }
          return {
            role: role.id,
            rate: s.id,
          };
        }),
      ),
      openRole: { role: role.id, isQual },
      isOpen: true,
    });
  };

  isRoleOpen = (role, isQual) =>
    this.state.openRole.role === role.id &&
    isQual === this.state.openRole.isQual;

  focusInput = () => {
    this.setState({ isOpen: false });
  };

  cardContent = () => {
    const { empty } = this.state;
    const filteredRoles = this.props.roles.filter((r) => r.isStudyProgramRole);
    return (
      <Row className="cardContentWrapper newStudentWrapper">
        <form
          onSubmit={this.props.handleSubmit((fields) => this.validate(fields))}
          style={{ width: '100%' }}
        >
          <PhotoRow
            valueName="Photo"
            value={this.state.photo}
            updateUser={this.updatePhoto}
            profile={{ id: null }}
            items={[]}
            load={() => {}}
            nameForValue="photo"
            multiple={false}
            accept="image/*"
          />
          {this.cardRow(
            'name',
            'Fist name',
            'text',
            false,
            null,
            '',
            empty,
            true,
          )}
          {this.cardRow(
            'lastName',
            'Last name',
            'text',
            false,
            null,
            '',
            empty,
            true,
          )}
          {this.cardRow(
            'userName',
            'User name',
            'text',
            false,
            null,
            '',
            empty,
            true,
          )}
          {this.cardRow(
            'email',
            'Email',
            'email',
            false,
            null,
            '',
            empty,
            true,
          )}
          {this.cardRow(
            'password',
            'Password',
            'password',
            false,
            null,
            '',
            empty,
            true,
          )}
          {this.state.redBorder.password ? (
            <div className="warning-tooltip-password">
              password must be between 12 and 30 chars, must contain 2 special
              characters, 2 numbers, 2 upper case, and 2 lower case
            </div>
          ) : (
            ''
          )}

          <div className="separatorLine" />
          <div className="separatorLineMargin" onClick={this.focusInput}>
            <Row className="cardRowContainer" style={{ width: '100%' }}>
              <Col xs={4} md={4} lg={4}>
                <div className="valueName">General Roles</div>
              </Col>
              <Col xs={8} md={8} lg={8}>
                <CheckBoxList
                  items={this.itemsForCheckboxList()}
                  onChange={this.CheckBoxListChange}
                />
              </Col>
            </Row>
          </div>
          <div className="separatorLine" />
          <Row>
            <Col xs={4} md={4} lg={4}>
              Rates roles
            </Col>
            <Col xs={8} md={8} lg={8}>
              <div className="centered">
                {filteredRoles.map((role) => (
                  <Role
                    key={`${role.name} - isNotQual`}
                    role={role}
                    options={this.props.rates}
                    onChange={this.onChange}
                    onUnselect={this.onRoleUnselect}
                    onSelect={this.onRoleSelect}
                    placeholder="Select rate"
                    isOpen={this.isRoleOpen(role, false) && this.state.isOpen}
                    value={this.state[role.name] ? this.state[role.name] : []}
                    isQual={false}
                  />
                ))}
              </div>
            </Col>
            <Col xs={4} md={4} lg={4}>
              Quals roles
            </Col>
            <Col xs={8} md={8} lg={8}>
              <div className="centered">
                {filteredRoles.map((role) => (
                  <Role
                    key={`${role.name} - isQual`}
                    role={role}
                    options={this.props.quals}
                    onChange={this.onChange}
                    isOpen={this.isRoleOpen(role, true) && this.state.isOpen}
                    onUnselect={this.onRoleUnselect}
                    onSelect={this.onRoleSelect}
                    placeholder="Select qual"
                    value={
                      this.state[`${role.name}isQual`]
                        ? this.state[`${role.name}isQual`]
                        : []
                    }
                    isQual
                  />
                ))}
              </div>
            </Col>
          </Row>
          <div className="separatorLine" />
          <div className="buttonsContainer">
            <Button
              className="btn btn-success"
              type="submit"
              loading={this.props.loading}
            >
              Create
            </Button>
            <Button
              className="btn btn-secondary"
              onClick={() => this.props.onCancel()}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Row>
    );
  };

  render() {
    return (
      <div className="newStudentContainer">
        <Card title="New Admin" content={this.cardContent()} />
      </div>
    );
  }
}

export default NewAdmin;

export class Role extends React.Component {
  componentDidMount() {
    const { role, isQual, isOpen } = this.props;
    if (this.refs && this.refs[role.id + isQual] && isOpen) {
      this.refs[role.id + isQual].focus();
    }
  }

  getRole = () => {
    const { role, options, value, isQual = false } = this.props;
    return (
      <div>
        <b>{role.name}</b>
        <div className="selectorContainer">
          <Select
            value={
              isQual
                ? value.map((val) => ({
                    value: val.qual,
                    label: options.filter((option) => option.id === val.qual)[0]
                      .description,
                  }))
                : value.map((val) => ({
                    value: val.rate,
                    label: options.filter((option) => option.id === val.rate)[0]
                      ?.label,
                  }))
            }
            className="writersSelector"
            options={options.map((option) => {
              option.value = option.id;
              option.label = option.shortname ? option.shortname : option.name;
              return option;
            })}
            key={role.id + isQual}
            ref={role.id + isQual}
            closeMenuOnSelect
            isMulti
            onChange={(rate) => {
              const ratesToChange = rate.map((r) => {
                if (
                  Object.keys(r).includes('label') &&
                  Object.keys(r).includes('value')
                ) {
                  return options.filter((option) => option.id === r.value)[0];
                }
                return r;
              });

              this.props.onChange(role, ratesToChange, isQual);
            }}
            openMenuOnFocus
          />
        </div>
      </div>
    );
  };

  render() {
    return <div style={{ marginBottom: '10px' }}>{this.getRole()} </div>;
  }
}
