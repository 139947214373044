import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import { getQuestionDefinitions, getUpdatedDefinitions } from '../selectors';
import { getEditPermission } from '../../Questions/selectors';
import DefinitionsList from '../../../commonComponents/DefinitionsList/DefinitionsList';
import {
  loadDefinitionsByQuestion,
  removeDefinitions,
  editDefinition,
  createDefinition,
} from '../actionsSagas';
import { editQuestionSagas } from '../../Questions/actionsSagas';
import { clearDefinitions, setUpdatedDefinitions } from '../actions';

const mapStateToProps = (state, ownProps) => {
  return {
    list: getQuestionDefinitions(state),
    reference: ownProps.question,
    closeModal: ownProps.closeModal,
    noRecordsText: ownProps.noRecordsText,
    assignment: ownProps.assignment,
    isQuestion: true,
    updatedDefinitions: getUpdatedDefinitions(state),
    editPermission: getEditPermission(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoad: question => {
      dispatch(loadDefinitionsByQuestion(question));
    },
    onDelete: (id, assignment) => {
      dispatch(removeDefinitions(id, true, assignment));
    },
    onEdit: (item, assignment) => {
      dispatch(editDefinition(item, true, assignment));
    },
    onAdd: (item, assignment) => {
      item.question = item.reference;
      delete item.reference;
      dispatch(createDefinition(item, assignment));
    },
    clearDefinitions: () => {
      dispatch(clearDefinitions());
    },
    setQuestionDefinitions: definitions => {
      dispatch(setUpdatedDefinitions(definitions));
    },
    updateQuestion: (question, assignment) => {
      dispatch(editQuestionSagas(question, assignment));
    },
    showNotification: (title, message, type) => {
      dispatch(
        Notifications.show(
          {
            title: title,
            message,
          },
          type,
        ),
      );
    },
  };
};

const QuestionDefinitionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefinitionsList);

export default QuestionDefinitionsContainer;
