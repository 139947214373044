import Notifications from 'react-notification-system-redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { closeModal } from '../../containers/Full/actions';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import * as api from '../../utilities/ServiceManager';
import {
  referencesReceivedAction,
  updateLoadingGrid,
} from '../Bibliographies/actions';
import {
  addQual,
  setAllStudyPrograms,
  setQuals,
  setSelectedQual,
} from './actions';
import {
  CREATE_QUAL,
  GET_ALL_QUALS,
  GET_QUAL_REFERENCES,
  LOAD_ALL_QUALS,
} from './actionTypesSagas';
/**
 * LOAD Quals
 */
function* loadAllQualsSaga() {
  yield takeLatest(GET_ALL_QUALS, callLoadQuals);
}

function* callLoadQuals(action) {
  try {
    const quals = yield call(api.getAllQuals, action.page);
    yield put(setQuals(quals.data, action.page));
    const qualsList = quals.data.data;
    if (qualsList && qualsList.length > 0 && !action.selectedQual) {
      yield put(setSelectedQual(qualsList[0]));
    }
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load quals. Please try again later.',
      ),
    );
  }
}

/**
 * LOAD ALL QUALS (NO PAGINATION)
 */
function* loadQualsSaga() {
  yield takeLatest(LOAD_ALL_QUALS, callLoadAllQuals);
}

function* callLoadAllQuals(action) {
  try {
    const quals = yield call(api.getAllQuals);
    yield put(setAllStudyPrograms(quals.data));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load quals. Please try again later.',
      ),
    );
  }
}

/**
 * Create Quals
 */
function* createQualSaga() {
  yield takeLatest(CREATE_QUAL, callCreateQual);
}

function* callCreateQual(action) {
  try {
    const fields = action.fields;
    fields.isQual = true;
    const qual = yield call(api.createRate, fields);
    yield put(addQual(qual));
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Qual created successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not create qual. Please try again later.',
      ),
    );
  }
}

/**
 * LOAD Qual references
 */
function* loadQualReferencesSaga() {
  yield takeLatest(GET_QUAL_REFERENCES, callLoadQualReferences);
}

function* callLoadQualReferences(action) {
  try {
    yield put(updateLoadingGrid(true));
    const references = yield call(
      api.getQualReferences,
      action.qual,
      action.archived,
    );
    yield put(referencesReceivedAction(references));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load references. Please try again later.',
      ),
    );
  } finally {
    yield put(updateLoadingGrid(false));
  }
}

/**
 * DEFAUL EXPORT
 */
export default function* sagas() {
  yield all([
    loadQualsSaga(),
    loadAllQualsSaga(),
    createQualSaga(),
    loadQualReferencesSaga(),
  ]);
}

function showNotificationErrorFromException(exception) {
  const message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
