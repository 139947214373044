import * as t from './actionTypes';

export const setRates = rates => ({
  type: t.SET_RATES,
  payload: rates,
});

export const setWaiting = value => ({
  type: t.SET_WAITING,
  value,
});
