import PropTypes from 'prop-types';
import React from 'react';

import addAbilityToOpenModal from '../../utilities/modalHOC';
import NewAdmin from '../Modals/NewAdmin/NewAdmin';
import UsersListStateless from './UsersListStateless';

class UsersListStateful extends React.Component {
  static propTypes = {
    users: PropTypes.array,
    getUsers: PropTypes.func,
    updateProfile: PropTypes.func,
    reloadUsers: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    pagination: PropTypes.object,
    profile: PropTypes.object,
  };

  static defaultProps = {
    pagination: { total: 0 },
  };

  constructor(props) {
    super(props);

    this.timeout = null;

    this.state = {
      page: 1,
    };
  }

  changePage = (page) => {
    this.setState({ page });
    this.debounce(
      () =>
        this.props.reloadUsers(
          this.props.search,
          this.props.pagination.page,
          this.props.filterBy,
        ),
      500,
      true,
    );
  };

  debounce = (func, wait = 500, immediate = false, ...theArgs) => {
    const context = this;
    const args = theArgs;
    const later = () => {
      this.timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !this.timeout;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };

  openModal = () => {
    this.props.openModal({
      modalTitle: 'Creating a new Admin User',
      modalContent: (
        <NewAdmin
          onSubmit={(fields) => this.props.onCreateAdmin(fields)}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  render() {
    return (
      <UsersListStateless
        users={this.props.users}
        forAdmin={this.props.forAdmin}
        profile={this.props.profile}
        updateProfile={this.props.updateProfile}
        search={this.props.search}
        filterBy={this.props.filterBy}
        updateSearch={(ev) => this.props.onSetSearch(ev.target.value)}
        updateFilterBy={(filter) => this.props.onSetFilterBy(filter)}
        loading={this.props.loading}
        openModal={this.openModal}
        page={this.state.page}
        totalRecords={this.props.pagination.total}
        onChangePage={this.changePage}
        toggleNew={this.props.toggleNew}
        clearSearchInput={this.props.clearSearchInput}
        newStudent={this.props.newStudent}
        setSelectedUser={this.props.setSelectedUser}
        loadUserStatistics={this.props.loadUserStatistics}
        generalPermissions={this.props.generalPermissions}
        loggedUser={this.props.loggedUser}
      />
    );
  }
}

export default addAbilityToOpenModal(UsersListStateful);
