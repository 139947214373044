import { all, put, call, takeLatest } from 'redux-saga/effects';
import {
  cyclesReceivedAction,
  createCycleReceivedAction,
  updateCycleReceivedAction,
  deleteCycleReceivedAction,
  activateCycleReceivedAction,
  moveSubstitutesReceivedAction,
  moveSelectedRatesReceivedAction,
} from './actions';
import {
  LOAD_CYCLES,
  CREATE_CYCLES_SAGAS,
  DELETE_CYCLE_SAGAS,
  UPDATE_CYCLE_SAGAS,
  ACTIVATE_CYCLE_SAGAS,
  MOVE_SUBSTITUTES_SAGAS,
  MOVE_SELECTED_RATES_SAGAS,
  LOAD_ARCHIVED_CYCLES,
  TOOGLE_ARCHIVE_CYCLE,
} from './actionTypesSagas';
import * as api from '../../utilities/ServiceManager';
import Notifications from 'react-notification-system-redux';
import { closeModal } from '../../containers/Full/actions';
import _ from 'underscore';

function* fetchCycles(action) {
  try {
    const cycles = yield call(api.getCycles);
    yield put(cyclesReceivedAction(cycles));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load cycles. Please try again later.',
      ),
    );
  } finally {
  }
}

function* fetchArchivedCycles() {
  try {
    const cycles = yield call(api.getArchivedCycles);
    yield put(cyclesReceivedAction(cycles));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load archived cycles. Please try again later.',
      ),
    );
  }
}

export function* watchGetCycles() {
  yield takeLatest(LOAD_CYCLES, fetchCycles);
}

export function* watchGetArchivedCycles() {
  yield takeLatest(LOAD_ARCHIVED_CYCLES, fetchArchivedCycles);
}

export function* watchToggleArchiveCycle() {
  yield takeLatest(TOOGLE_ARCHIVE_CYCLE, toggleArchiveCycle);
}

function* toggleArchiveCycle(action) {
  let message = action.cycle.isArchived ? 'archived' : 'unarchived';
  try {
    const archiveReponse = yield call(api.archiveCycle, action.cycle);
    yield put(deleteCycleReceivedAction(archiveReponse));

    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Cycle ' + message + ' successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not ' + message + ' cycle. Please try again later.',
      ),
    );
  }
}

function* createCycle(action) {
  try {
    const creationResponse = yield call(api.createCycle, action.fields);
    yield put(createCycleReceivedAction(creationResponse));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Cycle created successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not create cycle. Please try again later.',
      ),
    );
  } finally {
  }
}

export function* watchCreateCycle() {
  yield takeLatest(CREATE_CYCLES_SAGAS, createCycle);
}

function* deleteCycle(action) {
  try {
    const deleteResponse = yield call(api.deleteCycle, action.id);
    yield put(deleteCycleReceivedAction(deleteResponse));
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Cycle deleted successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    if (
      exception.response.data &&
      exception.response.data === 'Can`t destroy an active cycle'
    ) {
      yield put(closeModal());
      yield put(
        Notifications.show(
          {
            title: 'Error',
            message:
              'Please, activate another cycle before deleting the current one.',
          },
          'error',
        ),
      );
    }
  } finally {
  }
}

export function* watchDeleteCycle() {
  yield takeLatest(DELETE_CYCLE_SAGAS, deleteCycle);
}

function* updateCycle(action) {
  try {
    const updateResponse = yield call(api.updateCycle, action.fields);
    yield put(updateCycleReceivedAction(updateResponse));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Cycle updated successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      yield put(
        showNotificationErrorFromException(
          exception,
          'Could not update cycle. Please try again later.',
        ),
      ),
    );
  } finally {
  }
}

export function* watchUpdateCycle() {
  yield takeLatest(UPDATE_CYCLE_SAGAS, updateCycle);
}

function* activateCycle(action) {
  try {
    const response = yield call(api.activateCycle, action.id);
    yield put(activateCycleReceivedAction(response));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Cycle activated successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not activate cycle. Please try again later.',
      ),
    );
  } finally {
  }
}

function* moveSubstitutes(action) {
  try {
    const response = yield call(api.moveSubstitutes, action.id);
    yield put(moveSubstitutesReceivedAction(response));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Substitutes updated successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not updated Substitutes. Please try again later.',
      ),
    );
  } finally {
  }
}

export function* watchActivateCycle() {
  yield takeLatest(ACTIVATE_CYCLE_SAGAS, activateCycle);
}

export function* watchMoveSubstitutes() {
  yield takeLatest(MOVE_SUBSTITUTES_SAGAS, moveSubstitutes);
}

function* moveSelectedRates(action) {
  try {
    const response = yield call(api.moveSelectedRates, action.id, action.rates);
    yield put(moveSelectedRatesReceivedAction(response));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Rates updated successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update Rates. Please try again later.',
      ),
    );
  } finally {
  }
}

export function* watchMoveSelectedRates() {
  yield takeLatest(MOVE_SELECTED_RATES_SAGAS, moveSelectedRates);
}

export default function* sagas() {
  yield all([
    watchGetCycles(),
    watchCreateCycle(),
    watchDeleteCycle(),
    watchUpdateCycle(),
    watchActivateCycle(),
    watchMoveSubstitutes(),
    watchMoveSelectedRates(),
    watchGetArchivedCycles(),
    watchToggleArchiveCycle(),
  ]);
}

function showNotificationErrorFromException(
  exception,
  defaultMessage = 'Something went wrong, please try again.',
) {
  let message = defaultMessage;
  if (exception && exception.response && exception.response.data) {
    message = exception.response.data;
  }

  return Notifications.show({ title: 'Oops!', message }, 'error');
}
