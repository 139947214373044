import React from 'react';
import QualsListContainer from './QualsList/QualsListContainer';
import QualInformationContainer from './QualInformation/QualInformationContainer';
import { Row } from 'react-bootstrap';

export default class QualsPage extends React.Component {
  render() {
    return (
      <Row style={{ height: '100%' }}>
        <QualsListContainer />
        <QualInformationContainer />
      </Row>
    );
  }
}
