import React from 'react';
import { reduxForm } from 'redux-form';
import Button from 'react-bootstrap-button-loader';
import 'react-select2-wrapper/css/select2.css';
import './ChangePrimaryWriter.css';
import _ from 'underscore';
import Spinner from '../../Spinner/Spinner';

class ChangePrimaryWriter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      primaryWriter: 0,
    };
  }

  componentWillMount() {
    if (this.props.onLoad) {
      this.props.onLoad(
        this.props.rate ? 'rate' : 'qual',
        this.props.rate ? this.props.rate.id : this.props.qual.id
      );
    }
  }

  selectPrimaryWriter = id => {
    this.setState({
      primaryWriter: parseInt(id, 10),
    });
  };

  updatePrimaryWriter = () => {
    this.props.onSubmit(this.state.primaryWriter, this.props.group);
  };

  render() {
    const { onCancel } = this.props;
    return (
      <div className="change-primary-writer-component">
        <div className="card-block">
          {this.props.isWaiting ? (
            <Spinner text="Loading" />
          ) : this.props.writers.length > 0 ? (
            <form className="widget-form new-admin-form">
              <div className="explanation">
                This user is currently a primary writer of at least one
                assignment under the selected rate. Another primary writer must
                be provided before deleting this user. Please select one from
                the list, you could reassign again later.
              </div>
              <div className="form-group">
                <span className="label labelPosition">Primary writer:</span>
                <select
                  className="form-control"
                  value={this.state.primaryWriter}
                  onChange={e => this.selectPrimaryWriter(e.target.value)}
                >
                  <option key={`writersForAssign0`} value={0}>
                    Select a new primary writer
                  </option>
                  {this.props.writers
                    .filter(writer => writer.id !== this.props.writer)
                    .map(writer => (
                      <option
                        key={`writersForAssign${writer.id}`}
                        value={writer.id}
                      >
                        {writer.name + ' ' + writer.lastName}
                      </option>
                    ))}
                </select>
                {this.props.forAssign &&
                this.state.errors &&
                this.state.errors['primaryWriter'] && (
                  <span className="errors">
                    {this.state.errors['primaryWriter']}
                  </span>
                )}
              </div>
              <div className="bottom-buttons">
                <Button className="btn btn-secondary" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  className="btn btn-success"
                  onClick={this.updatePrimaryWriter}
                  loading={this.state.isWaiting}
                  disabled={this.state.primaryWriter === 0}
                >
                  Assign
                </Button>
              </div>
            </form>
          ) : (
            <div className="center-text">No Writers found</div>
          )}
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: 'ChangePrimaryWriter' })(ChangePrimaryWriter);
