import { List, Map } from 'immutable';

import { AssignmentsStatusFilters } from '../../constants/assignments';
import * as t from './actionTypes';

const initialState = Map({
  adminsByRate: [],
  adminsByQual: [],
  adminsByRateImmutable: List([]),
  adminsByQualImmutable: List([]),
  assignments: [],
  activeStatus: AssignmentsStatusFilters[0],
  selectedAdmin: null,
  selectedRate: null,
  selectedAssignment: null,
  isViewRates: true,
  isQuestionOverview: false,
  isChangingType: false,
  loading: false,
  assignmentsCount: [],
  awaitingApproval: false,
  isReloadAdminAssignments: false,
  studyProgramAdmins: [],
  noGroups: false,
  adminScroll: 0,
  isBackFromQuestion: false,
  myStudyPrograms: [],
  loaginMySP: null,
  writers: [],
  selectedWriter: null,
  assignModalWaiting: false,
  assignModalSuccess: false,
  assignModalError: false,
  assignModalReferences: [],
  assignModalWriters: [],
  assignModalRates: [],
  assignModalSelectedRate: {},
  assignModalLoadingReferences: false,
  loadingCreateQuestions: false,
  loadingSummaries: false,
  summaries: [],
  loadingSummary: false,
  summary: null,
});

function updateUsersCount(
  selectedRate,
  selectedUser,
  rates = [],
  quals = [],
  state,
  rejection = false,
) {
  const updatedRateAdmins = rates.slice();
  const updatedQualsAdmins = quals.slice();
  let userToUpdate;
  updatedRateAdmins.map((rate) => {
    if (rate.adminUsers.find((admin) => admin.id === selectedUser.id)) {
      userToUpdate = rate.adminUsers.find(
        (admin) => admin.id === selectedUser.id,
      );
    }
    return rate;
  });
  if (!userToUpdate) {
    updatedQualsAdmins.map((rate) => {
      if (rate.adminUsers.find((admin) => admin.id === selectedUser.id)) {
        userToUpdate = rate.adminUsers.find(
          (admin) => admin.id === selectedUser.id,
        );
      }
      return rate;
    });
  }
  if (rejection) {
    if (userToUpdate) {
      userToUpdate.counts.submitted -= 1;
      userToUpdate.counts.inProgress += 1;
    }

    return {
      updatedRateAdmins,
      updatedQualsAdmins,
    };
  }
  if (state === 'Completed' && userToUpdate) {
    userToUpdate.counts.submitted -= 1;
  }
  if (state === 'Submitted' && userToUpdate) {
    userToUpdate.counts.inProgress -= 1;
    userToUpdate.counts.submitted += 1;
  }
  if (state === 'In_Progress' && userToUpdate) {
    userToUpdate.counts.notStarted -= 1;
    userToUpdate.counts.inProgress += 1;
  }
  return {
    updatedRateAdmins,
    updatedQualsAdmins,
  };
}

function updateUserAllCount(rates, quals, assignment) {
  const updatedRateAdmins = rates.slice();
  const updatedQualsAdmins = quals.slice();
  let userToUpdate;
  updatedRateAdmins.map((rate) => {
    rate.adminUsers = rate.adminUsers.map((user) => {
      if (
        assignment.assignedTo.find((assigned) => assigned.id === user.id) &&
        rate.id === assignment.studyProgram.typeId
      ) {
        user.counts.notStarted += 1;
      }
      return user;
    });

    return rate;
  });

  if (!userToUpdate) {
    updatedQualsAdmins.map((rate) => {
      rate.adminUsers = rate.adminUsers.map((user) => {
        if (
          assignment.assignedTo.find((assigned) => assigned.id === user.id) &&
          rate.id === assignment.studyProgram.typeId
        )
          user.counts.notStarted += 1;
        return user;
      });
      return rate;
    });
  }

  return {
    updatedRateAdmins,
    updatedQualsAdmins,
  };
}

function updateUserAllCountForDeletedAssignment(rates, quals, assignment) {
  const updatedRateAdmins = rates.slice();
  const updatedQualsAdmins = quals.slice();

  updatedRateAdmins.map((rate) => {
    rate.adminUsers = rate.adminUsers.map((user) => {
      if (assignment.assignedTo.find((assigned) => assigned.id === user.id)) {
        switch (assignment.status) {
          case 'Not_Started':
            user.counts.notStarted -= 1;
            break;
          case 'In_Progress':
            user.counts.inProgress -= 1;
            break;
          case 'Submitted':
            user.counts.submitted -= 1;
            break;
          case 'Published':
            user.counts.published -= 1;
            break;
          default:
            break;
        }
      }
      return user;
    });

    return rate;
  });

  updatedQualsAdmins.map((qual) => {
    qual.adminUsers = qual.adminUsers.map((user) => {
      if (assignment.assignedTo.find((assigned) => assigned.id === user.id)) {
        switch (assignment.status) {
          case 'Not_Started':
            user.counts.notStarted -= 1;
            break;
          case 'In_Progress':
            user.counts.inProgress -= 1;
            break;
          case 'Submitted':
            user.counts.submitted -= 1;
            break;
          case 'Published':
            user.counts.published -= 1;
            break;
          default:
            break;
        }
      }
      return user;
    });

    return qual;
  });

  return {
    updatedRateAdmins,
    updatedQualsAdmins,
  };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case t.SET_ASSIGN_MODAL_WAITING:
      return state.set('assignModalWaiting', action.value);

    case t.SET_ASSIGN_MODAL_SUCCESS:
      return state.set('assignModalSuccess', action.value);

    case t.SET_ASSIGN_MODAL_ERROR:
      return state.set('assignModalError', action.value);

    case t.SET_ASSIGN_MODAL_REFERENCES:
      return state.set('assignModalReferences', action.references);

    case t.SET_ASSIGN_MODAL_WRITERS:
      return state.set('assignModalWriters', action.writers);

    case t.SET_ASSIGN_MODAL_RATES:
      return state.set('assignModalRates', action.rates);

    case t.SET_ASSIGN_MODAL_SELECTED_RATE:
      return state.set('assignModalSelectedRate', action.selectedRate);

    case t.SET_ASSIGN_MODAL_LOADING_REFERENCES:
      return state.set('assignModalLoadingReferences', action.value);

    case t.LOADING_CREATE_GPT_QUESTIONS:
      return state.set('loadingCreateQuestions', action.value);

    case t.LOADING_GET_SUMMARIES:
      return state.set('loadingSummaries', action.value);

    case t.SET_SUMMARIES_ACTION:
      return state.set('summaries', action.summaries);

    case t.SET_ASSIGNMENTS_COUNT:
      return state.set('assignmentsCount', action.count);

    case t.SET_AWAITING_APPROVAL:
      return state.set('awaitingApproval', action.value);

    case t.SET_ADMINS_BY_RATE:
      return state
        .set('adminsByRate', action.admins)
        .set('adminsByRateImmutable', List(action.admins));

    case t.SET_ADMINS_BY_QUAL:
      return state
        .set('adminsByQual', action.admins)
        .set('adminsByQualImmutable', List(action.admins));

    case t.SET_LOADING:
      return state.set('loading', action.loading);

    case t.SET_CHANGE_TYPE:
      return state.set('isChangingType', action.value);

    case t.SET_ACTIVE_STATUS:
      return state.set('activeStatus', action.status);

    case t.SET_LOADING_MY_SP:
      return state.set('loaginMySP', action.isLoading);

    case t.SET_SELECTED_ADMIN_RATE:
      return state
        .set('selectedAdmin', action.admin)
        .set('selectedRate', action.rate);

    case t.SET_SELECTED_ADMIN:
      return state.set('selectedAdmin', action.admin);

    case t.SET_SELECTED_WRITER:
      return state.set('selectedWriter', action.writer);

    case t.SET_SELECTED_RATE:
      return state.set('selectedRate', action.rate);

    case t.SET_ASSIGNMENTS:
      return state.set('assignments', action.assignments);

    case t.IS_RELOAD_ADMIN_ASSIGNMENT:
      return state.set('isReloadAdminAssignments', action.isReloadAdmin);

    case t.SET_IS_VIEW_RATES:
      return state.set('isViewRates', action.isViewRates);

    case t.SHOW_QUESTION_DETAILS:
      return state.set('selectedAssignment', action.assignment);

    case t.TOGGLE_QUESTION_OVERVIEW:
      return state.set('isQuestionOverview', action.value);

    case t.SHOW_QUESTION_OVERVIEW:
      return state
        .set('selectedAssignment', action.assignment)
        .set('isQuestionOverview', action.show);

    case t.CLEAR_SELECTED_ASSIGNMENT:
      return state
        .set('selectedAssignment', null)
        .set('isQuestionOverview', false);

    case t.ADD_ASSIGNMENT:
      let assignmentsState = state.get('assignments');
      let countersUpdated = updateUserAllCount(
        state.get('adminsByRate'),
        state.get('adminsByQual') || [],
        action.assignment,
      );

      if (
        action.assignment.assignedTo.find(
          (writer) => writer.id === state.get('selectedAdmin').id,
        )
      ) {
        if (state.get('activeStatus') !== 'Completed')
          assignmentsState = assignmentsState.concat([action.assignment]);
        return state
          .set('assignments', assignmentsState)
          .set(
            'assignmentsCount',
            state
              .get('assignmentsCount')
              .slice(0)
              .map((count) => {
                if (count.name === 'Not_Started') {
                  count.count++;
                }
                return count;
              }),
          )
          .set('adminsByRate', countersUpdated.updatedRateAdmins)
          .set('adminsByQual', countersUpdated.updatedQualAdmins);
      }
      return state;

    case t.UPDATE_ASSIGNMENT_STATUS:
      const selectedRate = state.get('selectedRate');
      const selectedUser = state.get('selectedAdmin');
      const rates = state.get('adminsByRate');
      const quals = state.get('adminsByQual');
      const updatedAdminsCollection = updateUsersCount(
        selectedRate,
        selectedUser,
        rates,
        quals,
        action.assignment.status,
      );

      const updatedCounts = state.get('assignmentsCount').slice();
      let newAssignments = state.get('assignments').map((assignment) => {
        if (assignment.id === action.assignment.id) {
          assignment.status = action.assignment.status;
        }
        return assignment;
      });
      if (action.assignment.status === 'Completed') {
        const updateSubmitted = updatedCounts.find(
          (count) => count.name === 'Submitted',
        );
        updateSubmitted.count -= 1;
        const updateCompleted = updatedCounts.find(
          (count) => count.name === 'Completed',
        );
        updateCompleted.count += 1;
        newAssignments = newAssignments.concat([action.assignment]);
      }
      if (action.assignment.status === 'Submitted') {
        const updateInProgress = updatedCounts.find(
          (count) => count.name === 'In_Progress',
        );
        updateInProgress.count -= 1;
        const updateSubmitted2 = updatedCounts.find(
          (count) => count.name === 'Submitted',
        );
        updateSubmitted2.count += 1;
      }
      if (action.assignment.status === 'In_Progress') {
        const updateNotStarted = updatedCounts.find(
          (count) => count.name === 'Not_Started',
        );
        updateNotStarted.count -= 1;
        const updateInProgress2 = updatedCounts.find(
          (count) => count.name === 'In_Progress',
        );
        updateInProgress2.count += 1;
      }
      if (action.assignment.status === 'Published') {
        newAssignments = newAssignments.filter(
          (assignment) => assignment.status !== 'Published',
        );
      }
      return state
        .set('assignments', newAssignments)
        .set('assignmentsCount', updatedCounts)
        .set('adminsByRate', updatedAdminsCollection.updatedRateAdmins)
        .set('adminsByQual', updatedAdminsCollection.updatedQualsAdmins);

    case t.ASSIGNMENT_UPDATE_COMPLETE:
      const selectedRateCompletion = state.get('selectedRate');
      const selectedUserCompletion = state.get('selectedAdmin');
      const ratesCompletion = state.get('adminsByRate');
      const qualsCompletion = state.get('adminsByQual');

      const updatedAdminsCollectionCompletion = updateUsersCount(
        selectedRateCompletion,
        selectedUserCompletion,
        ratesCompletion,
        qualsCompletion,
        'Completed',
        true,
      );
      return state
        .set(
          'assignmentsCount',
          state.get('assignmentsCount').map((count) => {
            if (count.name === 'Submitted') {
              count.count--;
            }
            if (count.name === 'Published') {
              count.count++;
            }
            if (count.name === 'Completed') {
              count.count++;
            }
            return count;
          }),
        )
        .set('selectedAssignment', null)
        .set('activeStatus', 'Completed')
        .set('isReloadAdminAssignments', true)
        .set(
          'adminsByRate',
          updatedAdminsCollectionCompletion.updatedRateAdmins,
        )
        .set(
          'adminsByQual',
          updatedAdminsCollectionCompletion.updatedQualsAdmins,
        );

    case t.ASSIGNMENT_UPDATE_REJECTION:
      const updatedAssignmentsAfterRejection = state
        .get('assignments')
        .concat([action.assignment]);
      const updatedCountsAfterRejection = state.get('assignmentsCount').slice();
      const updateInProgressCount = updatedCountsAfterRejection.find(
        (count) => count.name === 'In_Progress',
      );
      const updateSubmittedCount = updatedCountsAfterRejection.find(
        (count) => count.name === 'Submitted',
      );
      updateInProgressCount.count += 1;
      updateSubmittedCount.count -= 1;

      const selectedRateRejection = state.get('selectedRate');
      const selectedUserRejection = state.get('selectedAdmin');
      const ratesRejection = state.get('adminsByRate');
      const qualsRejection = state.get('adminsByQual');

      const updatedAdminsCollectionRejection = updateUsersCount(
        selectedRateRejection,
        selectedUserRejection,
        ratesRejection,
        qualsRejection,
        action.assignment.status,
        true,
      );

      return state
        .set('assignments', updatedAssignmentsAfterRejection)
        .set('assignmentsCount', updatedCountsAfterRejection)
        .set('adminsByRate', updatedAdminsCollectionRejection.updatedRateAdmins)
        .set(
          'adminsByQual',
          updatedAdminsCollectionRejection.updatedQualsAdmins,
        );

    case t.UPDATE_ASSIGNMENT_DUE_DATE:
      const updatedAssignments = state.get('assignments').map((assignment) => {
        if (assignment.id === action.assignment.id) {
          assignment.dueDate = action.assignment.dueDate;
        }
        return assignment;
      });
      return state.set('assignments', updatedAssignments);

    case t.ASSIGNMENT_UPDATE:
      return state.set(
        'assignments',
        state
          .get('assignments')
          .map((assignment) => {
            if (
              assignment.id === action.assignment.id &&
              !action.assignment.assignedTo.find(
                (writer) => writer.id === state.get('selectedAdmin').id,
              )
            ) {
              return null;
            }
            return assignment;
          })
          .filter((assignment) => assignment),
      );

    case t.ASSIGNMENT_UPDATE_DELETE:
      countersUpdated = updateUserAllCountForDeletedAssignment(
        state.get('adminsByRate'),
        state.get('adminsByQual') || [],
        action.assignment,
      );

      return state
        .set(
          'assignments',
          state
            .get('assignments')
            .filter((assignment) => assignment.id !== action.assignment.id),
        )
        .set(
          'assignmentsCount',
          state.get('assignmentsCount').map((countElement) => {
            if (countElement.name === action.assignment.status) {
              countElement.count--;
            }
            return countElement;
          }),
        )
        .set('adminsByRate', countersUpdated.updatedRateAdmins)
        .set('adminsByQual', countersUpdated.updatedQualAdmins);
    case t.UPDATE_SP_ADMINS:
      return state.set('studyProgramAdmins', action.admins);

    case t.SET_ADMIN_SCROLL:
      return state.set('adminScroll', action.scroll);

    case t.SET_IS_BACK_FROM_QUESTION:
      return state.set('isBackFromQuestion', action.isBackFromQuestion);

    case t.NO_GROUPS:
      return state.set('noGroups', action.value);

    case t.SET_MY_SP:
      return state.set('myStudyPrograms', action.sp);
    case t.SET_WRITERS:
      return state.set('writers', action.writers);
    case t.SET_LOADING_SUMMARY:
      return state.set('loadingSummary', action.value);
    case t.SET_SUMMARY:
      return state.set('summary', action.summary);

    default:
      return state;
  }
}
