// Insights/selectors.js
import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

const getState = state => {
  return getStateWithOnlyOneLevel(state, 'admins');
};

export function getAdmins(state) {
  return getState(state).get('admins');
}

export function getAdminHistory(state) {
  return getState(state).get('history');
}

export function getActivitySelector(state) {
  return getState(state).get('activity');
}

export function getHistoryPagination(state) {
  return getState(state).get('historyPagination');
}

export function getAdminsPagination(state) {
  return getState(state).get('pagination');
}

export function getAdminsProfile(state) {
  return getState(state).get('profile');
}

export function getRoles(state) {
  return getState(state)
    .get('roles')
    .toArray();
}

export function getAdminRoles(state) {
  return getState(state).get('adminRoles');
}

export function getFormErrors(state) {
  return getState(state).get('errorFromService');
}

export function getIsWaiting(state) {
  return getState(state).get('isWaiting');
}

export function getAdminUserLogs(state) {
  return getState(state).get('adminUserLogs');
}

export function getAdminUserLogsPagination(state) {
  return getState(state).get('adminUserLogsPagination');
}

export function getLoadingInfo(state) {
  return getState(state).get('loadingInfo');
}

export const getErrors = state => getState(state).get('errors');
export const getSuccessful = state => getState(state).get('successful');
export const getMessage = state => getState(state).get('message');
export const getUser = state => getState(state).get('user');
export const getToken = state => getState(state).get('studentToken');
export const getLoading = state => getState(state).get('loading');
