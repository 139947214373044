const couponsSagaNamespace = 'coupons-saga';
export const LOAD_COUPONS = `${couponsSagaNamespace}/LOAD_COUPONS`;
export const LOAD_COUPONS_REFERRAL = `${couponsSagaNamespace}/LOAD_COUPONS_REFERRAL`;
export const LOAD_CODE_GROUPS = `${couponsSagaNamespace}/LOAD_CODE_GROUPS`;
export const CREATE_CODE_GROUPS = `${couponsSagaNamespace}/CREATE_CODE_GROUPS`;
export const CANCELL_ALL_CODES = `${couponsSagaNamespace}/CANCELL_ALL_CODES`;
export const EDIT_CODE_GROUP = `${couponsSagaNamespace}/EDIT_CODE_GROUP`;
export const CREATE_COUPON = `${couponsSagaNamespace}/CREATE_COUPON`;
export const CREATE_NEW_COUPON = `${couponsSagaNamespace}/CREATE_NEW_COUPON`;
export const EDIT_NEW_COUPON = `${couponsSagaNamespace}/EDIT_NEW_COUPON`;
export const CREATE_SUBCODES = `${couponsSagaNamespace}/CREATE_SUBCODES`;
export const LOAD_SUBCODES = `${couponsSagaNamespace}/LOAD_SUBCODES`;
export const DELETE_DISABLE_COUPON = `${couponsSagaNamespace}/DELETE_DISABLE_COUPON`;
export const LIST_PLANS_SUBSCRIPTIONS = `${couponsSagaNamespace}/LIST_PLANS_SUBSCRIPTIONS`;
export const EDIT_COUPON = `${couponsSagaNamespace}/EDIT_COUPON`;
export const GET_CODE_GROUP = `${couponsSagaNamespace}/GET_CODE_GROUP`;
export const CANCEL_CODE = `${couponsSagaNamespace}/CANCEL_CODE`;
export const GET_REDEEMS = `${couponsSagaNamespace}/GET_REDEEMS`;
export const DOWNLOAD_UNREDEEMED_CODES = `${couponsSagaNamespace}/DOWNLOAD_UNREDEEMED_CODES`;
export const CREATE_COUPON_REFERRAL = `${couponsSagaNamespace}/CREATE_COUPON_REFERRAL`;
export const INSERT_COUPON_REFERRAL = `${couponsSagaNamespace}/INSERT_COUPON_REFERRAL`;
export const LOAD_NEW_COUPONS = `${couponsSagaNamespace}/LOAD_NEW_COUPONS`;
