import { connect } from 'react-redux';
import { getCoupons, getSelectedCoupon } from '../../selectors';
import CouponList from './CouponList';
import addAbilityToOpenModal from '../../../../utilities/modalHOC';
import {
  setSelectedCoupon,
  cleanCodeGroup,
  setSelectedCodeGroup,
} from '../../actions';
import { loadCoupons, createCoupon } from '../../actionsSagas';

const mapStateToProps = (state, ownProps) => {
  return {
    coupons: getCoupons(state),
    selectedCoupon: getSelectedCoupon(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedCoupon: coupon => {
      dispatch(setSelectedCoupon(coupon));
    },
    loadCoupons: () => {
      dispatch(loadCoupons());
    },
    onCreateCoupon: coupon => {
      dispatch(createCoupon(coupon));
    },
    onCleanCodeGroup: () => dispatch(cleanCodeGroup),
    onSelectSelectedCodeGroup: selectedCodeGroup =>
      dispatch(setSelectedCodeGroup(selectedCodeGroup)),
  };
};

const CouponListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponList);

export default addAbilityToOpenModal(CouponListContainer);
