import * as t from './actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  practiceExamInformation: [],
  practiceExamInformationWaiting: false,
  rate: null,
  rank: null,
  dutyState: null,
  exam: null,
  selectedCycle: null,
  buildPracticeExamWaiting: false,
  removeQuestionWaiting: false,
  removeQuestionSuccess: false,
  activateExamWaiting: false,
  activateExamSuccess: false,
  buildAllExamsWaiting: false,
  buildAllExamsSuccess: false,
  buildAllExamsProgress: 0,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case t.SET_PRACTICE_EXAM_INFORMATION:
      if (!action.data) {
        return state.set(
          'practiceExamInformation',
          initialState.get('practiceExamInformation'),
        );
      } else {
        return state.set('practiceExamInformation', action.data);
      }
    case t.SET_PRACTICE_EXAM_INFORMATION_WAITING:
      return state.set('practiceExamInformationWaiting', action.value);
    case t.SET_RATE:
      return state.set('rate', action.rate);
    case t.SET_RANK:
      return state.set('rank', action.rank);
    case t.SET_DUTY_STATE:
      return state.set('dutyState', action.dutyState);
    case t.SET_CYCLE:
      const practiceExamInformation = state.get('practiceExamInformation');
      const selectedRecord = practiceExamInformation.find(
        record => record.cycle.cycleId === action.cycle,
      );
      const selectedExam = selectedRecord.freePracticeExam.id;
      if (practiceExamInformation !== []) {
        return state
          .set('selectedCycle', action.cycle)
          .set('exam', selectedExam);
      } else {
        return state.set('selectedCycle', state.get('selectedCycle'));
      }
    case t.SET_EXAM:
      return state.set('exam', action.exam);
    case t.SET_BUILD_PRACTICE_EXAM_WAITING:
      return state.set('buildPracticeExamWaiting', action.value);
    case t.SET_EXAM_AFTER_BUILD:
      const PEInfo = state.get('practiceExamInformation');
      const selectedRec = PEInfo.find(
        record => record.cycle.cycleId === state.get('selectedCycle'),
      );
      const index = PEInfo.indexOf(selectedRec);
      if (action.free && !action.bonus && !action.extra) {
        selectedRec.freePracticeExam = action.exam;
      } else if (!action.free && !action.extra) {
        selectedRec.paidPracticeExam = action.exam;
      } else if (action.extra) {
        selectedRec.extraPracticeExam = action.exam;
      } else {
        selectedRec.bonusPracticeExam = action.exam;
      }
      PEInfo[index] = selectedRec;
      return state.set('practiceExamInformation', PEInfo);
    case t.SET_REMOVE_QUESTION_SUCCESS:
      return state.set('removeQuestionSuccess', action.value);
    case t.SET_REMOVE_QUESTION_WAITING:
      return state.set('removeQuestionWaiting', action.value);
    case t.SET_ACTIVATE_EXAM_WAITING:
      return state.set('activateExamWaiting', action.value);
    case t.SET_ACTIVATE_EXAM_SUCCESS:
      return state.set('activateExamSuccess', action.value);
    case t.SET_BUILD_ALL_EXAMS_WAITING:
      return state.set('buildAllExamsWaiting', action.value);
    case t.SET_BUILD_ALL_EXAMS_SUCCESS:
      return state.set('buildAllExamsSuccess', action.value);
    case t.SET_BUILD_ALL_EXAMS_PROGRESS:
      return state.set('buildAllExamsProgress', action.value);
    default:
      return state;
  }
}
