import React from 'react';
import './AdminProfile.css';
import { Row, Col } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import NumberUtils from '../../lib/NumberUtils';

export default class PhotoRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      value: '',
    };
  }

  componentWillReceiveProps({ value }) {
    if (this.props.value === value) return;
    this.setState({ value: value });
  }

  onSubmit = () => {
    if (!this.state.value && !this.props.select) {
      this.setState({ error: true });
      return;
    }
    this.setState({ error: false });
    let updatedUser = Object.assign({}, this.props.profile);
    updatedUser.photo = this.state.value;
    this.props.updateUser(updatedUser);
  };

  deleteUserPhoto = () => {
    this.props.updatePhoto({ id: this.props.profile.id, photo: null });
  };

  onDrop = (files) => {
    if (this.props.nameForValue === 'photo') {
      this.props.updateUser({ id: this.props.profile.id, photo: files[0] });
    } else {
      if (this.props.profile.id) {
        this.props.updateUser({ id: this.props.profile.id, files: files });
      } else {
        let file = this.props.files ? this.props.files : [];
        for (let f of files) {
          file.push(f);
        }
        this.props.updateUser({ id: this.props.profile.id, files: file });
      }
    }
  };

  onRemoveFile = (index) => {
    let files = JSON.parse(this.props.profile.files);
    files.splice(index, 1);
    this.props.updatePhoto({ id: this.props.profile.id, files });
  };

  render() {
    let dropzoneRef;
    let image =
      this.props.value && this.props.value !== '' ? this.props.value : null;
    if (this.props.nameForValue === 'files') {
      image = null;
    }
    return (
      <Row
        className="cardRowContainer"
        style={{ width: '100%', marginBottom: '20px' }}
      >
        <Col xs={12} md={12} lg={4} className="photoCol">
          <div className="valueName">{this.props.valueName}</div>
        </Col>
        <Col
          xs={this.state.editMode ? 12 : 10}
          md={this.state.editMode ? 12 : 10}
          lg={this.state.editMode || !image ? 8 : 6}
          style={{ paddingLeft: '0px', paddingRight: '0px' }}
          className="middleColumn"
        >
          {this.props.files && this.props.profile.id ? (
            <Files
              editable={this.props.editable}
              files={this.props.files}
              onRemoveFile={this.onRemoveFile}
            />
          ) : (
            ''
          )}
          {this.props.files && !this.props.profile.id && (
            <ImageFiles
              files={this.props.files}
              onRemoveFile={(index) => {
                this.props.updateUser({
                  id: null,
                  files: this.props.files.slice(index, 1),
                });
              }}
            />
          )}
          {!image && (
            <div className="DropzoneContainer">
              <Dropzone
                ref={(node) => {
                  dropzoneRef = node;
                }}
                onDrop={this.onDrop}
                multiple={this.props.multiple}
                accept={this.props.accept}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} className="Dropzone">
                      <input
                        {...getInputProps()}
                        style={{
                          display: 'block',
                          height: '1px',
                          width: '1px',
                        }}
                      />
                      <p>Drop files here</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <p
                onClick={() => {
                  dropzoneRef.open();
                }}
              >
                Find files
              </p>
            </div>
          )}
          {image && this.props.profile.id && (
            <div className="profileImageContainer">
              <img
                src={image}
                alt="Admin"
                className="profileImage"
                loading="lazy"
              />
            </div>
          )}
          {image && !this.props.profile.id && (
            <ImageFiles
              files={[image]}
              onRemoveFile={(index) => {
                this.props.updateUser({ id: null, photo: null });
              }}
            />
          )}
        </Col>
        {image && this.props.editable && (
          <Col className="photoColPen" xs={2} md={2} lg={2}>
            <i
              onClick={this.deleteUserPhoto}
              className="fa fa-trash fa-lg photoValueName"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export class Files extends React.Component {
  getShortName(name) {
    if (!name) {
      return '';
    }
    let shortName = name.split('-');
    //shortName = shortName[shortName.length-1].split('.')
    return shortName[shortName.length - 1];
  }

  getFiles = () => {
    let files = this.props.files;
    if (!files) {
      return '';
    }
    files = JSON.parse(files);
    return files.map((f, index) => (
      <Row key={NumberUtils.obtainUniqueKey()}>
        <Col md={9} className="fileName">
          <a href={f} target="_blank">
            {this.getShortName(f)}
          </a>
        </Col>
        <Col md={3}>
          {this.props.editable && (
            <i
              onClick={() => this.props.onRemoveFile(index)}
              className="fa fa-trash fa-lg photoValueName"
            />
          )}
        </Col>
      </Row>
    ));
  };
  render() {
    return <div style={{ marginBottom: '10px' }}>{this.getFiles()}</div>;
  }
}

export class ImageFiles extends React.Component {
  getShortName(file) {
    if (!file) {
      return '';
    }
    return file.name ? file.name : file[0].name;
  }

  render() {
    return (
      <div style={{ marginBottom: '10px' }}>
        {this.props.files.map((file, index) => (
          <Row>
            <Col md={9} className="fileName">
              {this.getShortName(file)}
            </Col>
            <Col md={3}>
              <i
                onClick={() => this.props.onRemoveFile(index)}
                className="fa fa-trash fa-lg photoValueName"
              />
            </Col>
          </Row>
        ))}
      </div>
    );
  }
}
