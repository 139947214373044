const cyclesSagaNamespace = 'cycles';
export const LOAD_CYCLES = `${cyclesSagaNamespace}/LOAD_CYCLES`;
export const CREATE_CYCLES_SAGAS = `${cyclesSagaNamespace}/CREATE_CYCLES_SAGAS`;
export const DELETE_CYCLE_SAGAS = `${cyclesSagaNamespace}/DELETE_CYCLE_SAGAS`;
export const UPDATE_CYCLE_SAGAS = `${cyclesSagaNamespace}/UPDATE_CYCLE_SAGAS`;
export const ACTIVATE_CYCLE_SAGAS = `${cyclesSagaNamespace}/ACTIVATE_CYCLE_SAGAS`;
export const MOVE_SUBSTITUTES_SAGAS = `${cyclesSagaNamespace}/MOVE_SUBSTITUTES_SAGAS`;
export const MOVE_SELECTED_RATES_SAGAS = `${cyclesSagaNamespace}/MOVE_SELECTED_RATES_SAGAS`;
export const LOAD_ARCHIVED_CYCLES = `${cyclesSagaNamespace}/LOAD_ARCHIVED_CYCLES`;
export const TOOGLE_ARCHIVE_CYCLE = `${cyclesSagaNamespace}/TOOGLE_ARCHIVE_CYCLE`;
