import { connect } from 'react-redux';
import { getUser } from '../../Login/selectors';
import SearchInstructionsModal from '../../../commonComponents/Modals/SearchInstructionsModal/SearchInstructionsModal';

const mapStateToProps = (state, ownProps) => {
  return {
    user: getUser(state),
    closeModal: ownProps.onCancel,    
  };
};

const SearchInstructionsModalContainer = connect(
  mapStateToProps,
  null
)(SearchInstructionsModal);

export default SearchInstructionsModalContainer;