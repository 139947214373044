import * as type from './actionTypesSagas';

export const getAllQuals = (page, selectedQual) => ({
  type: type.GET_ALL_QUALS,
  page,
  selectedQual,
});

export const createQual = fields => ({
  type: type.CREATE_QUAL,
  fields,
});

export const loadQualReferences = (qual, archived) => ({
  type: type.GET_QUAL_REFERENCES,
  qual,
  archived,
});

export const loadAllQuals = () => ({
  type: type.LOAD_ALL_QUALS,
});
