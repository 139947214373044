import { Button, Modal } from 'react-bootstrap';

function ImageSizeNotificationModal({ isOpen, handleToggleModal }) {
  return (
    <Modal show={isOpen} onHide={handleToggleModal}>
      <Modal.Header>
        <Modal.Title>Image size warning</Modal.Title>
        <Button variant="modal__close-button" onClick={handleToggleModal}>
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>Uploaded images should be at least 75KB.</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleToggleModal}>
          Undestood
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImageSizeNotificationModal;
