import React from 'react';
import './CheckBoxListItem.css';

class CheckBoxListItem extends React.Component {
  render() {
    return (
      <div className="CheckBoxListItemContainer">
        <label className="label d-flex">
          <input
            ref="checkbox"
            type="checkbox"
            name={this.props.item.id}
            value={this.props.item.name}
            checked={this.props.isChecked}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
          />
          {this.props.label}
        </label>
      </div>
    );
  }
}

export default CheckBoxListItem;
