import * as type from './actionTypesSagas';

export const loadDefinitionsByReference = reference => ({
  type: type.LOAD_DEFINITIONS_BY_REFERENCE,
  reference,
});

export const loadDefinitionsByQuestion = question => ({
  type: type.LOAD_DEFINITIONS_BY_QUESTION,
  question,
});

export const removeDefinitions = (id, isQuestion, assignment) => ({
  type: type.REMOVE_DEFINITIONS,
  id,
  isQuestion,
  assignment,
});

export const editDefinition = (item, isQuestion, assignment) => ({
  type: type.EDIT_DEFINITION,
  item,
  isQuestion,
  assignment,
});

export const createDefinition = (item, assignment) => ({
  type: type.CREATE_DEFINITION,
  item,
  assignment,
});
