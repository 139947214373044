import './Maintenance.css';

import React from 'react';
// import BJLOGO from '../../commonComponents/Images/BJ-Boat-Logo-Compressed.svg';

class Maintenance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    this.props.verifyIsMaintenance();
  }

  render() {
    const location = window.location;
    return (
      <div className="maintenance-page">
        <h1 className="maintenance-title">Under maintenance!</h1>
        <a href={location && location.origin ? location.origin : '#'}>
          {/* <img alt="" src={BJLOGO} className="bj-logo" /> */}
        </a>
        <div className="default-content">
          <p className="maintenance-description">
            Sorry for the inconvenience.
          </p>
          <p className="maintenance-description">
            Bluejacketeer will be down for scheduled maintenance.
          </p>
          <p className="maintenance-description">
            Thank you for understanding.
          </p>
          <small className="maintenance-description">
            The Bluejacketeer Team
          </small>
        </div>
      </div>
    );
  }
}

export default Maintenance;
