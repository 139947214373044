import React from 'react';
import './Settings.css';
import { Textarea } from '../../../commonComponents/Input/Input';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import WYSIWYG from '../../../commonComponents/WYSIWYG/WYSIWYG';

class SettingRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      value: this.props.setting.value,
    };
  }

  validate = (fields, callback) => {
    let errors = {};
    if (
      !fields[this.props.setting.key] &&
      !(
        this.props.setting.key.includes('EMAIL_') &&
        !this.props.setting.key.includes('_SUBJECT')
      )
    )
      errors[this.props.setting.key] = 'Field is required';
    if (
      this.props.setting.key.includes('EMAIL_') &&
      !this.props.setting.key.includes('_SUBJECT')
    ) {
      if (this.state.isUpdated && !this.state.value) {
        errors[this.props.setting.key] = 'Field is required';
      }
    }
    this.setState({ errors });
    if (errors[this.props.setting.key]) return;
    let newKey = {
      id: this.props.setting.id,
      value: this.state.isUpdated
        ? this.state.value
        : fields[this.props.setting.key],
    };
    callback(newKey);
  };

  onChangeEditor = (value) => {
    this.setState({ value: value, isUpdated: true });
  };

  renderFieldOrWYSIWYG = () => {
    let { disabled = false } = this.props;

    if (
      this.props.setting.key.includes('EMAIL_') &&
      !this.props.setting.key.includes('_SUBJECT')
    ) {
      return (
        <WYSIWYG
          onChange={this.onChangeEditor}
          height={300}
          value={this.state.value}
          disabled={disabled}
          updateEditorView={false}
          showWarning={this.props.showWarning}
        />
      );
    } else {
      if (this.props.setting.key === 'MASTER_USER_REPORT_CSV_PATH') {
        return (
          <Field
            name={this.props.setting.key}
            type="text"
            placeholder=""
            component={Textarea}
            disabled={true}
            defaultValue={this.props.setting.value}
          />
        );
      } else {
        return (
          <Field
            name={this.props.setting.key}
            type="text"
            placeholder=""
            component={Textarea}
            disabled={disabled}
            defaultValue={this.props.setting.value}
          />
        );
      }
    }
  };

  render() {
    let { disabled = false } = this.props;
    return (
      <form
        className="settingRowContainer"
        onSubmit={this.props.handleSubmit((fields) =>
          this.validate(fields, this.props.updateKey),
        )}
      >
        <Row>
          <Col xs={4} md={4}>
            <div className="key">{this.props.setting.key}</div>
          </Col>
          <Col xs={4} md={4}>
            {this.renderFieldOrWYSIWYG()}
            {this.state.errors && this.state.errors[this.props.setting.key] && (
              <span className="errors">
                {this.state.errors[this.props.setting.key]}
              </span>
            )}
          </Col>
          <Col xs={4} md={4}>
            {!(this.props.setting.key === 'MASTER_USER_REPORT_CSV_PATH') && (
              <Button
                className="btn btn-success"
                type="submit"
                disabled={disabled}
              >
                Save
              </Button>
            )}
          </Col>
        </Row>
      </form>
    );
  }
}

export default SettingRow;
