import * as t from './actionsTypes';

export const getAllRates = () => ({
  type: t.GET_ALL_RATES,
});

export const getUserSubmittedQuestions = (rateId) => ({
  type: t.GET_USER_SUBMITTED_QUESTIONS,
  rateId,
});

export const getUserDeletedSubmittedQuestions = (rateId) => ({
  type: t.GET_USER_DELETED_SUBMITTED_QUESTIONS,
  rateId,
});

export const getFavoriteQuestions = (rateId, limit, skip) => ({
  type: t.GET_FAVORITE_QUESTIONS,
  rateId,
  limit,
  skip,
});

export const deleteSubmittedQuestion = (
  questionId,
  questionRate,
  timestamp,
) => ({
  type: t.DELETE_SUBMITTED_QUESTION,
  questionId,
  questionRate,
  timestamp,
});

export const undeleteSubmittedQuestion = (
  questionId,
  userId,
  updatedAt,
  rateId,
) => ({
  type: t.UNDELETE_SUBMITTED_QUESTION,
  questionId,
  userId,
  updatedAt,
  rateId,
});

export const approveSubmittedQuestion = (questionId, rateId, timestamp) => ({
  type: t.APPROVE_SUBMITTED_QUESTION,
  questionId,
  rateId,
  timestamp,
});

export const rejectSubmittedQuestion = (questionId, rateId, timestamp) => ({
  type: t.REJECT_SUBMITTED_QUESTION,
  questionId,
  rateId,
  timestamp,
});

export const editSubmittedQuestion = (
  questionId,
  question,
  rateId,
  timestamp,
) => ({
  type: t.EDIT_SUBMITTED_QUESTION,
  questionId,
  question,
  rateId,
  timestamp,
});

export const removeFavoriteQuestion = (rateId, questions) => ({
  type: t.REMOVE_FAVORITE_QUESTION,
  rateId,
  questions,
});
