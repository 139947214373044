import './References.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import { browserHistory } from 'react-router';
import _ from 'underscore';
import UniversalCookies from 'universal-cookie';

import ManualRedModal from '../../../commonComponents/Modals/ConfirmationReason/ConfirmationReason';
import ConfirmDeletion from '../../../commonComponents/Modals/ConfirmDeletion/ConfirmDeletion';
import CopyReference from '../../../commonComponents/Modals/CopyReference/CopyReference';
import CreateChapter from '../../../commonComponents/Modals/CreateChapter/CreateChapter';
import CreateRate from '../../../commonComponents/Modals/CreateRate/CreateRate';
import CreateReference from '../../../commonComponents/Modals/CreateReference/CreateReference';
import SimpleConfirmation from '../../../commonComponents/Modals/SimpleConfirmation/SimpleConfirmation';
import RatesList from '../../../commonComponents/RatesList/RatesList';
import ChapterGridAccordionLib from '../../../commonComponents/ReferencesGridAccordion/ChapterGridAccordionLib';
import ReferencesGridAccordion from '../../../commonComponents/ReferencesGridAccordion/ReferencesGridAccordion';
import ReferencesGridAccordionLib from '../../../commonComponents/ReferencesGridAccordion/ReferencesGridAccordionLib';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import SubNavBar from '../../../commonComponents/SubNavBar/SubNavBar';
import {
  filterLinksForTabs,
  hasPagePermissionRates,
  lookRateWithPermission,
} from '../../../utilities/FilterLinksForTabs';
import DefinitionsContainer from '../../Definitions/components/DefinitionsContainer';
import SubtopicsContainer from '../../Subtopics/components/SubtopicsContainer';
import WritersContainer from '../../Writers/components/WritersContainer';

const cookies = new UniversalCookies();

class References extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rate: null,
      filters: [
        { name: 'Assigned to bibs', active: true },
        { name: 'Not assigned to bibs' },
        { name: 'Archived' },
        { name: 'All' },
      ],
      formattedReferences: [],
      formattedChapters: [],
      links: [],
    };
  }

  componentWillMount() {
    this.props.loadRates(this.props.ratesPagination.page);
  }

  componentWillReceiveProps({
    rates,
    references,
    waiting,
    referencesImmutable,
    chapters,
    chaptersImmutable,
    rate,
    user,
    rolesPermissions,
    generalRolesPermissions,
  }) {
    if (this.state.rate === null && rates.length) {
      if (!rate.id) {
        rate = user.isSuperAdmin
          ? rates[0]
          : lookRateWithPermission('References', rates, rolesPermissions);
        this.selectRate(
          rate,
          user,
          rolesPermissions,
          generalRolesPermissions,
          rates,
        );
      } else {
        this.selectRate(
          rate,
          user,
          rolesPermissions,
          generalRolesPermissions,
          rates,
        );
      }
    }

    if (waiting !== this.props.waiting && !waiting) {
      this.props.closeModal();
    }

    if (this.props.referencesImmutable !== referencesImmutable) {
      this.convertReferencesFromServer(
        references,
        chapters,
        rolesPermissions,
        user,
      );
      this.convertChaptersFromServer(
        chapters,
        references,
        rolesPermissions,
        user,
      );
    }

    if (this.props.chaptersImmutable !== chaptersImmutable) {
      this.convertReferencesFromServer(
        references,
        chapters,
        rolesPermissions,
        user,
      );
      this.convertChaptersFromServer(
        chapters,
        references,
        rolesPermissions,
        user,
      );
    }
    if (waiting !== this.props.waiting && !waiting) {
      this.props.closeModal();
    }
  }

  showCreateRateModal = () => {
    this.props.openModal({
      modalTitle: 'Create a rate',
      modalContent: (
        <CreateRate
          onSubmit={this.props.createRate}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  convertChaptersFromServer = (
    chapters,
    references,
    rolesPermissions = this.props.rolesPermissions,
    user = this.props.user,
  ) => {
    const functions = {
      onDeletePdfChapter: this.handleDeletePdfChapter,
      onStateChapterClick: this.handleStateChapterClick,
      onChangeSubtopicsIndicator: this.onChangeSubtopicsIndicator,
      onChangeQuestionsIndicator: this.onChangeQuestionsIndicator,
      onMoveChapters: this.props.onMoveChapters,
      onChangeSettingsChapter: this.onChangeSettingsChapter,
    };

    this.setState({
      formattedChapters: ChapterGridAccordionLib.getChaptersForGrid(
        chapters,
        references,
        functions,
        false,
        this.state.rate.id,
        rolesPermissions,
        user.isSuperAdmin,
      ),
    });
  };

  convertReferencesFromServer = (
    references,
    chapters,
    rolesPermissions = this.props.rolesPermissions,
    user = this.props.user,
  ) => {
    const functions = {
      onDeletePdf: this.handleDeletePdf,
      onStateClick: this.handleStateClick,
      onDefinitionsClick: this.handleDefinitionsClick,
      onChangeSettingsReference: this.onChangeSettingsReference,
      onAddChapter: this.addChapter,
      onChangeQuestionsIndicator: this.onChangeQuestionsIndicator,
    };
    this.setState({
      formattedReferences: ReferencesGridAccordionLib.getReferencesForGrid(
        references,
        chapters,
        functions,
        this.state.rate.id,
        rolesPermissions,
        user.isSuperAdmin,
      ),
    });
  };

  onChangeSubtopicsIndicator = (event, child, chapter, textMenu) => {
    if (textMenu === 'Set manual red') {
      this.openModalReason(
        this.props.setManualRedSubtopics,
        chapter,
        'subtopicIndicatorReason',
      );
    } else {
      this.props.removeManualRedSubtopics(chapter);
    }
  };

  onChangeQuestionsIndicator = (event, child, chapter, textMenu) => {
    if (textMenu === 'Set manual red') {
      this.openModalReason(
        this.props.setManualRedQuestions,
        chapter,
        'questionIndicatorReason',
      );
    } else {
      this.props.removeManualRedQuestions(chapter);
    }
  };

  openModalReason = (submit, entity, reasonField) => {
    this.props.openModal({
      modalTitle: 'Add a reason',
      modalContent: (
        <ManualRedModal
          onSubmit={(fields) => submit(entity, fields)}
          onCancel={this.props.closeModal}
          bodyText={
            reasonField === 'questionIndicatorReason'
              ? `You are about to set manual red for chapter '${entity.name}' questions`
              : `You are about to set manual red for chapter '${entity.name}' subtopics`
          }
          reasonField={reasonField}
        />
      ),
      modalClassName: 'modal-create-reference',
      className: '',
    });
  };

  onChangeSettingsReference = (event, child, reference) => {
    if (child.props.children === 'Edit') {
      this.props.openModal({
        modalTitle: 'Edit reference',
        modalContent: (
          <CreateReference
            onSubmit={(fields) =>
              this.props.updateReference(fields, reference.id)
            }
            onCancel={this.props.closeModal}
            edit
            reference={reference}
          />
        ),
        modalClassName: 'modal-create-reference',
        className: '',
      });
    }
    if (child.props.children === 'Delete') {
      this.props.openModal({
        modalTitle: 'Deleting a reference',
        modalContent: (
          <ConfirmDeletion
            onSubmit={() => this.props.deleteReference(reference)}
            message={`You are about to delete the reference ${reference.name} .`}
            onCancel={this.props.closeModal}
          />
        ),
      });
    }
    if (
      child.props.children === 'Archive' ||
      child.props.children === 'Unarchive'
    ) {
      this.props.archiveReference(reference, this.props.filter);
    }

    if (child.props.children === 'Copy') {
      this.props.openModal({
        modalTitle: 'Copy reference to another rate',
        modalContent: (
          <CopyReference
            onSubmit={(fields) => this.props.copyReference(fields)}
            onCancel={this.props.closeModal}
            reference={reference.id}
            selectedSP={this.state.rate}
          />
        ),
      });
    }

    if (child.props.children === 'Assign writer') {
      this.showAssignWritterModal(reference, null);
    }
  };

  showAssignWritterModal = (reference, chapter) => {
    this.props.openModal({
      modalTitle: 'Assign writers to reference',
      modalContent: (
        <WritersContainer
          closeModal={this.props.closeModal}
          chapter={chapter}
          reference={reference}
          rate={this.state.rate.id}
        />
      ),
    });
  };

  addChapter = (reference) => {
    this.props.openModal({
      modalTitle: 'Create chapter',
      modalContent: (
        <CreateChapter
          onSubmit={(fields) => this.props.createChapter(fields)}
          onCancel={this.props.closeModal}
          addChapter
          references={this.props.references}
          reference={reference}
        />
      ),
      modalClassName: 'modal-create-reference',
      className: '',
    });
  };

  onChangeSettingsChapter = (event, child, chapter, reference) => {
    if (child.props.children === 'Delete') {
      this.props.openModal({
        modalTitle: 'Deleting a chapter',
        modalContent: (
          <ConfirmDeletion
            onSubmit={() =>
              this.props.deleteChapter(
                reference.id,
                chapter.id,
                chapter.presentationIndex,
              )
            }
            message={`You are about to delete the chapter ${chapter.name} .`}
            onCancel={this.props.closeModal}
          />
        ),
      });
    }
    if (child.props.children === 'Delete all questions') {
      this.props.openModal({
        modalTitle: 'Deleting all questions of chapter',
        modalContent: (
          <ConfirmDeletion
            onSubmit={() => this.props.deleteAllQuestions(chapter.id)}
            message={`You are about to delete all questions of chapter ${chapter.name} .`}
            onCancel={this.props.closeModal}
          />
        ),
      });
    }
    if (child.props.children === 'Edit Subtopics') {
      this.props.openModal({
        modalTitle: 'Edit subtopics',
        modalContent: (
          <SubtopicsContainer
            onCancel={this.props.closeModal}
            rate={this.state.rate.id}
            reference={reference.id}
            chapter={chapter.id}
          />
        ),
        modalClassName: 'modal-create-chapter',
        className: '',
      });
    }
    if (child.props.children === 'Assign writer') {
      this.showAssignWritterModal(reference, chapter);
    }
    if (child.props.children === 'Edit') {
      this.editChapter(chapter, reference);
    }
  };

  editChapter = (chapter, reference) => {
    this.props.openModal({
      modalTitle: 'Edit chapter',
      modalContent: (
        <CreateChapter
          onSubmit={(fields) => this.props.updateChapter(fields, chapter.id)}
          onCancel={this.props.closeModal}
          edit
          reference={reference}
          chapter={chapter}
          chaptersOfReference={reference.chapters.length}
        />
      ),
      modalClassName: 'modal-create-reference',
      className: '',
    });
  };

  handleDefinitionsClick = (reference) => {
    this.props.openModal({
      modalTitle: 'Definitions',
      modalContent: (
        <DefinitionsContainer
          closeModal={this.props.closeModal}
          reference={reference}
        />
      ),
      modalClassName: 'modal-create-chapter',
      className: '',
    });
  };

  handleStateClick = (reference) => {
    this.props.changeStatus(reference);
  };

  handleStateChapterClick = (chapter) => {
    this.props.changeStatusChapter(chapter);
  };

  handleDeletePdf = (reference) => {
    this.props.openModal({
      modalTitle: 'Delete pdf',
      modalContent: (
        <SimpleConfirmation
          onConfirm={() => this.props.deletePdf(reference)}
          onCancel={this.props.closeModal}
          text={
            <div>
              {`Are you sure you want to delete pdf file of reference `}
              <strong>{reference.name}</strong>?
            </div>
          }
        />
      ),
      modalClassName: 'modal-create-chapter',
      className: '',
    });
  };

  handleDeletePdfChapter = (chapter) => {
    this.props.openModal({
      modalTitle: 'Delete pdf',
      modalContent: (
        <SimpleConfirmation
          onConfirm={() => this.props.deletePdfChapter(chapter)}
          onCancel={this.props.closeModal}
          text={
            <div>
              {`Are you sure you want to delete pdf file of chapter `}
              <strong>{chapter.name}</strong>?
            </div>
          }
        />
      ),
      modalClassName: 'modal-create-chapter',
      className: '',
    });
  };

  selectRate = (
    rate,
    user = {},
    rolesPermissions = this.props.rolesPermissions,
    generalRolesPermissions = this.props.generalRolesPermissions,
    rates = this.props.rates,
  ) => {
    if (
      rate ||
      cookies.get('isSuperAdmin') === 'true' ||
      hasPagePermissionRates('Books', generalRolesPermissions)
    ) {
      this.setState({
        rate,
      });
      this.props.updateRate(rate);
      if (this.props.filter !== 'Assigned to bibs') {
        this.onFilterClick(this.props.filter, rate);
        this.props.updateDutyState(this.props.dutyState);
        this.props.updateRank(this.props.rank);
      } else {
        if (
          this.props.rank !== undefined &&
          this.props.rank !== '' &&
          this.props.dutyState !== undefined &&
          this.props.dutyState !== ''
        ) {
          this.props.obtainAssignedToBib(
            rate.id,
            this.props.rank,
            this.props.dutyState,
          );
        } else {
          this.onFilterClick(this.props.filter, rate);
        }
      }
      const links = filterLinksForTabs(
        'References',
        rolesPermissions,
        rate,
        this.props.links,
        cookies.get('isSuperAdmin') === 'true',
      );
      this.setState({
        links,
      });
    } else {
      if (lookRateWithPermission('Subtopics', rates, rolesPermissions)) {
        browserHistory.replace('/home/rates/topics');
      } else {
        browserHistory.replace('/home/rates/bibliography');
      }
    }
  };

  onFilterClick = (filterApplied, rate = this.state.rate) => {
    const filtersUpdated = this.state.filters.map((filter) => {
      if (filter.name === filterApplied) {
        return { name: filter.name, active: true };
      }
      return { name: filter.name, active: false };
    });
    this.setState({ filters: filtersUpdated });
    this.props.updateFilter(
      filtersUpdated.find((filter) => filter.active).name,
    );
    if (filterApplied === 'Assigned to bibs') {
      if (
        this.props.rank !== undefined &&
        this.props.rank !== '' &&
        this.props.dutyState !== undefined &&
        this.props.dutyState !== ''
      ) {
        this.props.obtainAssignedToBib(
          rate.id,
          this.props.rank,
          this.props.dutyState,
        );
      } else {
        this.props.obtainAllAssigned(rate.id);
        this.updateFiltersView();
      }
    }
    if (filterApplied === 'Not assigned to bibs') {
      this.props.obtainNotAssignedToBib(rate.id);
    }
    if (filterApplied === 'Archived') {
      this.props.obtainArchived(rate.id);
    }
    if (filterApplied === 'All') {
      this.props.loadReferences(rate.id, 'rate');
    }
  };

  updateFiltersView = () => {
    if (this.props.rank === undefined || this.props.rank === '') {
      const updatedRanks = this.props.ranks.map((rank) => ({
        name: rank.name,
        active: false,
      }));
      this.props.updateRanks(updatedRanks);
    }
    if (this.props.dutyState === undefined || this.props.dutyState === '') {
      const updatedDutyStates = this.props.dutyStates.map((ds) => ({
        name: ds.name,
        active: false,
      }));
      this.props.updateDutyStates(updatedDutyStates);
    }
  };

  onDutyStateClick = (newDutyState, rate = this.state.rate) => {
    newDutyState = newDutyState === this.props.dutyState ? '' : newDutyState;
    this.props.updateDutyState(newDutyState);
    const updatedDutyStates = this.props.dutyStates.map((dutyState) => {
      if (dutyState.name === newDutyState) {
        return { name: newDutyState, active: !dutyState.active };
      }
      return { name: dutyState.name, active: false };
    });
    this.props.updateDutyStates(updatedDutyStates);
    if (
      this.props.rank !== undefined &&
      this.props.rank !== '' &&
      newDutyState !== ''
    ) {
      this.props.obtainAssignedToBib(rate.id, this.props.rank, newDutyState);
    } else {
      this.props.obtainAllAssigned(rate.id);
    }
  };

  onRankClick = (newRank, rate = this.state.rate) => {
    newRank = newRank === this.props.rank ? '' : newRank;
    this.props.updateRank(newRank);
    const updatedRanks = this.props.ranks.map((rank) => {
      if (rank.name === newRank) {
        return { name: newRank, active: !rank.active };
      }
      return { name: rank.name, active: false };
    });
    this.props.updateRanks(updatedRanks);
    if (
      this.props.dutyState !== undefined &&
      this.props.dutyState !== '' &&
      newRank !== ''
    ) {
      this.props.obtainAssignedToBib(rate.id, newRank, this.props.dutyState);
    } else {
      this.props.obtainAllAssigned(rate.id);
    }
  };

  handleCreateChapter = () => {
    this.props.openModal({
      modalTitle: 'Create a chapter',
      modalContent: (
        <CreateChapter
          onSubmit={(fields) => this.props.createChapter(fields)}
          onCancel={this.props.closeModal}
          references={this.props.references.filter((ref) => ref.actAsParent)}
        />
      ),
      modalClassName: 'modal-create-chapter',
      className: '',
    });
  };

  handleCreateReference = () => {
    this.props.openModal({
      modalTitle: 'Create a reference',
      modalContent: (
        <CreateReference
          onSubmit={(fields) =>
            this.props.createReference(fields, this.state.rate)
          }
          onCancel={this.props.closeModal}
        />
      ),
      modalClassName: 'modal-create-reference',
      className: '',
    });
  };

  render() {
    return (
      <Row className="references-component">
        <Col xs={6} md={3} className="rates-list-container">
          <RatesList
            title="Rates"
            onPlusClick={this.showCreateRateModal}
            rates={this.props.rates}
            selected={this.state.rate}
            onRateClick={(rate) => this.selectRate(rate)}
            pagination={this.props.ratesPagination}
            onPageChange={this.props.loadRates}
            withShortcuts
            tab="References"
          />
        </Col>
        {this.state.rate && (
          <Col xs={6} md={9} className="rates-content-container">
            <SubNavBar
              name={this.state.rate.name}
              links={this.state.links}
              className=""
            />
            <SubNavBar
              links={this.state.filters}
              className="light-grey noMargin"
              onClickEvent={this.onFilterClick}
            />
            {this.props.filter === 'Assigned to bibs' && (
              <SubNavBar
                links={this.props.ranks}
                className="light-grey noMargin"
                onClickEvent={this.onRankClick}
              />
            )}
            {this.props.filter === 'Assigned to bibs' && (
              <SubNavBar
                links={this.props.dutyStates}
                className="dark-grey noMargin"
                onClickEvent={this.onDutyStateClick}
              />
            )}
            <Row>
              <Col xs={12} md={12}>
                <div className="create-reference-btn-container">
                  <Button
                    className="btn-sm add"
                    variant="primary"
                    onClick={this.handleCreateReference}
                  >
                    Create reference
                  </Button>
                  <Button
                    className="btn-sm add"
                    variant="primary"
                    onClick={this.handleCreateChapter}
                  >
                    Create chapter
                  </Button>
                </div>
              </Col>
            </Row>
            {this.props.loadingGrid ? (
              <Spinner text="Loading content" />
            ) : (
              <Row>
                <Col xs={12} md={12}>
                  <div className="gridContainer">
                    <ReferencesGridAccordion
                      references={this.state.formattedReferences}
                      chapters={this.state.formattedChapters}
                      studyProgram={this.state.rate}
                      getUrlPdf={this.props.getUrlPdf}
                      urlPdfPrivate={this.props.urlPdfPrivate}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        )}
      </Row>
    );
  }
}

export default References;
