import { connect } from 'react-redux';
import { getChangeHistory, getWaiting } from '../selectors';
import ChangeHistory from '../../../commonComponents/Modals/ChangeHistory/ChangeHistory';
import { loadChangeHistory } from '../actionsSagas';

const mapStateToProps = (state, ownProps) => {
  return {
    changeHistory: getChangeHistory(state),
    question: ownProps.question,
    closeModal: ownProps.closeModal,
    assignment: ownProps.assignment,
    waiting: getWaiting(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoad: (assignment, question) => {
      dispatch(loadChangeHistory(assignment, question));
    },
  };
};

const ChangeHistoryContainer = connect(mapStateToProps, mapDispatchToProps)(
  ChangeHistory
);

export default ChangeHistoryContainer;
