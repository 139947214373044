import * as t from './actionTypes';

const initialState = {
  requesting: false,
  successful: false,
  messages: [],
  errors: [],
};

const reducer = function newAdminReducer(state = initialState, action) {
  switch (action.type) {
    // Set the requesting flag and append a message to be shown
    case t.NEW_ADMIN_REQUESTING:
      return {
        requesting: true,
        successful: false,
        messages: [{ body: 'Loading...', time: new Date() }],
        errors: [],
      };
    // Successful?  Reset the login state.
    case t.NEW_ADMIN_SUCCESS:
      return {
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
      };
    // Append the error returned from our api
    // set the success and requesting flags to false
    case t.NEW_ADMIN_ERROR:
      return {
        errors: state.errors.concat([
          {
            body: action.message.message
              ? action.message.message
              : action.message,
            time: new Date(),
          },
        ]),
        messages: [],
        requesting: false,
        successful: false,
      };

    default:
      return state;
  }
};

export default reducer;
