import React from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';

import { getSearchText } from '../selectors';

class SearchBar extends React.Component {
  search() {
    const {
      originalReferences,
      activeFilter,
      rate,
      setHasMoreData,
      onClearReferences,
      loadReferences,
      setParentState,
      runFilter,
      searchText,
    } = this.props;
    const activeFilters = activeFilter;
    const currentFilter = _.where(activeFilters, {
      rate: rate && rate.id ? rate.id : null,
    })[0];
    if (rate || (rate && rate.id === 0)) {
      setHasMoreData(true);
      const page = 0;
      onClearReferences();
      if (activeFilters?.length > 0 && currentFilter) {
        runFilter(currentFilter, searchText);
      } else {
        if (searchText === '' && rate && rate.id !== 0) {
          this.props.obtainAllForRate(rate.id);
        } else loadReferences(null, null, searchText, page, false, rate.id);
      }
    } else {
      if (searchText) {
        setParentState({
          references: originalReferences.filter(
            (reference) =>
              reference.name
                .toLowerCase()
                .indexOf(searchText.toLocaleLowerCase()) > -1,
          ),
        });
      }
    }
  }

  resetSearch() {
    const {
      setHasMoreData,
      onClearReferences,
      activeFilter,
      rate,
      loadReferences,
      obtainAllForRate,
      runFilter,
      setSearchText,
    } = this.props;
    const page = 0;
    /*
    this.setState({ searchText: '' }, () => {
      setHasMoreData(true);
      onClearReferences();

      let activeFilter2 = _.where(activeFilter, {
        rate: rate.id,
      })[0];
      if (rate && rate.id !== 0 && activeFilter2) {
        runFilter(activeFilter2);
      } else if (rate && rate.id !== 0) {
        obtainAllForRate(this.state.rate.id);
      } else {
        loadReferences(null, null, '', page);
      }
    }); */
    setHasMoreData(true);
    onClearReferences();
    setSearchText('');
    const activeFilter2 = _.where(activeFilter, {
      rate: rate.id,
    })[0];
    if (rate && rate.id !== 0 && activeFilter2) {
      runFilter(activeFilter2);
    } else if (rate && rate.id !== 0) {
      obtainAllForRate(rate.id);
    } else {
      loadReferences(null, null, '', page);
    }
  }

  render() {
    const {
      rate,
      setHasMoreData,
      setParentState,
      originalReferences,
      searchText,
      setSearchText,
    } = this.props;
    const style = { display: 'flex', marginLeft: '15px', width: '70%' };
    style.width = rate && rate.id !== 0 ? '70%' : '100%';
    return (
      <span style={style}>
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
          style={{ flex: 1, display: 'flex', height: '31px' }}
          onChange={(e) => setSearchText(e.target.value ? e.target.value : '')}
          onKeyUp={(e) => {
            if (!e.target.value) {
              if (rate && rate.id === 0) setHasMoreData(true);
              setParentState({ references: originalReferences });
            }
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.search();
              this.props.setFromSearch(false);
            }
          }}
        >
          <div className="search">
            <input
              type="text"
              className="search-input"
              placeholder="Search books"
              data-test="search-books-input"
              value={searchText}
            />
            <i
              className={
                searchText === '' ? 'fa fa-search' : 'fa fa-search fix'
              }
              aria-hidden="true"
              onClick={() => this.search()}
              data-test="search-book-button"
            />
            <i
              className={
                searchText === '' ? 'fa fa-remove hidden' : 'fa fa-remove'
              }
              aria-hidden="true"
              onClick={() => {
                this.resetSearch();
                this.props.setFromSearch(false);
              }}
            />
          </div>
          <input type="submit" style={{ display: 'none' }} />
        </form>
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  searchText: getSearchText(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
