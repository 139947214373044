import { connect } from 'react-redux';

import addAbilityToOpenModal from '../../../../utilities/modalHOC';
import { getUser } from '../../../Login/selectors';
import {
  setAdminScroll,
  setAssignmentsCount,
  setIsBackFromQuestion,
  setLoadingMySP,
  setMyStudyPrograms,
  setSelectedAdminAndRate,
} from '../../actions';
import { loadMyAssignments } from '../../actionsSagas';
import {
  getAdminScroll,
  getAssignments,
  getIsBackFromQuestion,
  getIsQuestionOverview,
  getLoadingMySP,
  getMyStudyPrograms,
  getSelectedAdmin,
  getSelectedAssignment,
  getSelectedRate,
} from '../../selectors';
import MyAssignments from './MyAssignments';

const mapStateToProps = (state, ownProps) => ({
  selectedAdmin: getSelectedAdmin(state),
  myStudyPrograms: getMyStudyPrograms(state),
  user: getUser(state),
  selectedSP: getSelectedRate(state),
  assignments: getAssignments(state),
  isLoadinMySP: getLoadingMySP(state),
  selectedAssignment: getSelectedAssignment(state),
  isQuestionOverview: getIsQuestionOverview(state),
  adminScroll: getAdminScroll(state),
  isBackFromQuestion: getIsBackFromQuestion(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadMyAssignments: (selectedSP) => dispatch(loadMyAssignments(selectedSP)),
  setLoadingMySP: (isLoading) => dispatch(setLoadingMySP(isLoading)),
  onSelectAdmin: (admin, rate) =>
    dispatch(setSelectedAdminAndRate(admin, rate)),
  setAssignmentsCount: (counters) => dispatch(setAssignmentsCount(counters)),
  setMyStudyPrograms: (sp) => dispatch(setMyStudyPrograms(sp)),
  setAdminScroll: (y) => {
    dispatch(setAdminScroll(y));
  },
  setIsBackFromQuestion: (isBackFromQuestion) => {
    dispatch(setIsBackFromQuestion(isBackFromQuestion));
  },
});

const MyAssignmentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MyAssignments);

export default addAbilityToOpenModal(MyAssignmentsContainer);
