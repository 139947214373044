import { NAME as componentName } from './constants';

export const EDITROLE = `${componentName}/EDITROLE`;
export const CREATEROLE = `${componentName}/CREATEROLE`;
export const DELETEROLE = `${componentName}/DELETEROLE`;
export const PAGESRECEIVED = `${componentName}/PAGESRECEIVED`;
export const UPDATE_WAITING = `${componentName}/UPDATE_WAITING`;
export const ACTIONSRECEIVED = `${componentName}/ACTIONSRECEIVED`;
export const ALLROLESRECEIVED = `${componentName}/ALLROLESRECEIVED`;
export const SET_SELECTED_PAGE = `${componentName}/SET_SELECTED_PAGE`;
export const UPDATECHECKEDPAGES = `${componentName}/UPDATECHECKEDPAGES`;
export const UPDATESELECTEDROLE = `${componentName}/UPDATESELECTEDROLE`;
export const UPDATECHECKEDACTIONS = `${componentName}/UPDATECHECKEDACTIONS`;
export const UPDATE_ACTION_INDICATOR = `${componentName}/UPDATE_ACTION_INDICATOR`;
export const ERROR_FETCHING_FROM_SERVICE = `${componentName}/ERROR_FETCHING_FROM_SERVICE`;
export const SET_SELECTED_ROLE = `${componentName}/SET_SELECTED_ROLE`;
export const UPDATE_ROLE_NAME = `${componentName}/UPDATE_ROLE_NAME`;
export const UPDATE_CHECKS = `${componentName}/UPDATE_CHECKS`;
export const TOGGLE_NEW_ROLE = `${componentName}/TOGGLE_NEW_ROLE`;
export const PAGEROLESRECEIVED = `${componentName}/PAGEROLESRECEIVED`;
export const FETCH_ALL_ROLES = `${componentName}/FETCH_ALL_ROLES`;
