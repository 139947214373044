import * as t from './actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  rates: [],
  waiting: false,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case t.SET_RATES:
      return state.set('rates', action.payload);

    case t.SET_WAITING:
      return state.set('waiting', action.value);

    default:
      return state;
  }
}
