import React from 'react';
import { showingDistance } from '../../constants/pagination';
import './Pagination.css';
class Pagination extends React.Component {
  renderPageLink(page, currentPage, onChangePage) {
    return (
      <li
        key={`pagelink-${page}`}
        className={page === currentPage ? 'page-item active' : 'page-item'}
      >
        <a className="page-link" onClick={() => onChangePage(page)}>
          {page}
        </a>
      </li>
    );
  }

  render() {
    const { currentPage, onChangePage, totalRecords } = this.props;

    let totalPages = Math.ceil(totalRecords / 50);
    if (totalPages === 0) totalPages = 1;

    let page;
    let pageLinks = [];
    for (let i = 0; i < totalPages; i++) {
      page = i + 1;

      if (Math.abs(page - currentPage) === showingDistance) {
        pageLinks.push(
          <li
            key={`pageDots-${page}`}
            style={{
              padding: '5px',
              display: 'flex',
              verticalAlign: 'middle',
              justifyContent: 'center',
            }}
          >
            ...
          </li>,
        );
      }

      if (Math.abs(page - currentPage) >= 2) {
        continue;
      }

      pageLinks.push(this.renderPageLink(page, currentPage, onChangePage));
    }

    return (
      <ul className="pagination">
        {currentPage > 2 && (
          <li className="page-item">
            <a className="page-link" onClick={() => onChangePage(1)}>
              &lt;&lt;
            </a>
          </li>
        )}
        {currentPage > 1 && (
          <li className="page-item">
            <a
              className="page-link"
              onClick={() => onChangePage(currentPage - 1)}
            >
              &lt;
            </a>
          </li>
        )}
        {pageLinks}
        {currentPage < totalPages && (
          <li className="page-item">
            <a
              className="page-link"
              onClick={() => onChangePage(currentPage + 1)}
            >
              &gt;
            </a>
          </li>
        )}
        {currentPage < totalPages - 1 && (
          <li className="page-item">
            <a className="page-link" onClick={() => onChangePage(totalPages)}>
              &gt;&gt;
            </a>
          </li>
        )}
      </ul>
    );
  }
}

export default Pagination;
