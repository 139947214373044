import { connect } from 'react-redux';
import {
  editStudyPlan,
  updateStartDate,
  updateEmail,
  editStudyPlanUser,
  deleteStudyPlanUser,
  sendStudyPlanPreview,
} from '../../actionsSagas';
import {
  getSelectedStudyPlan,
  getSelectedStudyPlanUser,
  getPreviewPlans,
  getStudyPlans,
} from '../../selectors';
import StudyPlan from './StudyPlan';
import { loadAllRates } from '../../../Rates/actionsSagas';
import { getAllStudyPrograms } from '../../../Quals/selectors';
import { getUser, getGeneralRolesPermissions } from '../../../Login/selectors';
import Notifications from 'react-notification-system-redux';

const mapStateToProps = (state) => {
  return {
    selectedStudyPlan: getSelectedStudyPlan(state),
    selectedStudyPlanUser: getSelectedStudyPlanUser(state),
    rates: getAllStudyPrograms(state),
    previewPlans: getPreviewPlans(state),
    studyPlans: getStudyPlans(state),
    user: getUser(state),
    generalPermissions: getGeneralRolesPermissions(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEditStudyPlan: (studyPlan, subject, isActive) =>
      dispatch(editStudyPlan(studyPlan, subject, isActive)),
    updateStartDate: (studyPlan, rank, dutyState, date, isApplicable) =>
      dispatch(updateStartDate(studyPlan, rank, dutyState, date, isApplicable)),
    updateEmail: (studyPlan, subject, content, emailId) =>
      dispatch(updateEmail(studyPlan, subject, content, emailId)),
    loadAllRates: () => {
      dispatch(loadAllRates());
    },
    editStudyPlanUser: (rate, dutyState, rank, isSubscribed, id) => {
      dispatch(editStudyPlanUser(rate, dutyState, rank, isSubscribed, id));
    },
    deleteStudyPlanUser: (id) => {
      dispatch(deleteStudyPlanUser(id));
    },
    sendStudyPlanPreview: (studyPlan, rate, rank, dutyState, week, emails) => {
      dispatch(
        sendStudyPlanPreview(studyPlan, rate, rank, dutyState, week, emails),
      );
    },
    showWarning: (title, message) =>
      dispatch(Notifications.warning({ title, message }, 'failure')),
  };
};

const StudyPlanContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StudyPlan);

export default StudyPlanContainer;
