const reportsNamespace = 'reports';
export const GET_BIBLIOGRAPHY_REPORT_FOR_RATES = `${reportsNamespace}/GET_BIBLIOGRAPHY_REPORT_FOR_RATES`;
export const GET_BIBLIOGRAPHY_REPORT_FOR_RATES_SUCCESS = `${reportsNamespace}/GET_BIBLIOGRAPHY_REPORT_FOR_RATES_SUCCESS`;
export const GET_PROFILE_SHEET_CSV = `${reportsNamespace}/GET_PROFILE_SHEET_CSV`;
export const GET_BIBLIOGRAPHY_REPORT_FOR_RATES_ERROR = `${reportsNamespace}/GET_BIBLIOGRAPHY_REPORT_FOR_RATES_ERROR`;
export const GET_FAVORITE_QUESTIONS_REPORT_FOR_RATES = `${reportsNamespace}/GET_FAVORITE_QUESTIONS_REPORT_FOR_RATES`;
export const GET_FAVORITE_QUESTIONS_REPORT_FOR_RATES_SUCCESS = `${reportsNamespace}/GET_FAVORITE_QUESTIONS_REPORT_FOR_RATES_SUCCESS`;
export const GET_FAVORITE_QUESTIONS_REPORT_FOR_RATES_ERROR = `${reportsNamespace}/GET_FAVORITE_QUESTIONS_REPORT_FOR_RATES_ERROR`;
export const GET_USERS_COUNT = `${reportsNamespace}/GET_USERS_COUNT`;
export const CALL_USERS_COUNT = `${reportsNamespace}/CALL_USERS_COUNT`;
export const GET_USERS_COUNT_SUCCESS = `${reportsNamespace}/GET_USERS_COUNT_SUCCESS`;
export const GET_USERS_COUNT_ERROR = `${reportsNamespace}/GET_USERS_COUNT_ERROR`;
export const SET_GRAPH = `${reportsNamespace}/SET_GRAPH`;
export const GET_HISTORICAL_USERS_REPORT = `${reportsNamespace}/GET_HISTORICAL_USERS_REPORT`;
export const SET_HISTORICAL_USERS_REPORT = `${reportsNamespace}/SET_HISTORICAL_USERS_REPORT`;
export const GET_HISTORICAL_USERS_REPORT_ERROR = `${reportsNamespace}/GET_HISTORICAL_USERS_REPORT_ERROR`;
export const CLEAR_API_ERROR = `${reportsNamespace}/CLEAR_API_ERROR`;
export const CLEAR_PROPS = `${reportsNamespace}/CLEAR_PROPS`;
export const GET_COMPLETION_STATUS = `${reportsNamespace}/GET_COMPLETION_STATUS`;
export const GET_ACTIVE_SUBSCRIPTIONS = `${reportsNamespace}/GET_ACTIVE_SUBSCRIPTIONS`;
export const SET_ACTIVE_SUBSCRIPTIONS = `${reportsNamespace}/SET_ACTIVE_SUBSCRIPTIONS`;
export const SET_LAST_CUSTOMER_SUBSCRIPTION = `${reportsNamespace}/SET_LAST_CUSTOMER_SUBSCRIPTION`;
export const CLEAR_PROPS_SUBSCRIPTIONS = `${reportsNamespace}/CLEAR_PROPS_SUBSCRIPTIONS`;
export const SET_ACTIVE_SUBSCRIPTIONS_ERROR = `${reportsNamespace}/SET_ACTIVE_SUBSCRIPTIONS_ERROR`;
export const SET_ACTIVE_SUBSCRIPTIONS_ERROR_FALSE = `${reportsNamespace}/SET_ACTIVE_SUBSCRIPTIONS_ERROR_FALSE`;
export const GET_MASTER_USER_REPORT = `${reportsNamespace}/GET_MASTER_USER_REPORT`;
export const GET_PROFILE_SHEET_REPORT = `${reportsNamespace}/GET_PROFILE_SHEET_REPORT`;
export const SET_MASTER_USER_REPORT = `${reportsNamespace}/SET_MASTER_USER_REPORT`;
export const SET_MASTER_USER_REPORT_ERROR = `${reportsNamespace}/SET_MASTER_USER_REPORT_ERROR`;
export const CLEAR_MASTER_REPORT_PROPS = `${reportsNamespace}/CLEAR_MASTER_REPORT_PROPS`;
export const CLEAR_MASTER_REPORT_API_ERROR = `${reportsNamespace}/CLEAR_MASTER_REPORT_API_ERROR`;
export const DOWNLOAD_MASTER_USER_REPORT = `${reportsNamespace}/DOWNLOAD_MASTER_USER_REPORT`;
export const DOWNLOAD_ACTIVE_SUBSCRIPTIONS_REPORT = `${reportsNamespace}/DOWNLOAD_ACTIVE_SUBSCRIPTIONS_REPORT`;
export const SET_DOWNLOAD_MASTER_USER_REPORT = `${reportsNamespace}/SET_DOWNLOAD_MASTER_USER_REPORT`;
export const SET_DOWNLOAD_ACTIVE_SUBSCRIPTIONS = `${reportsNamespace}/SET_DOWNLOAD_ACTIVE_SUBSCRIPTIONS`;
export const SET_DOWNLOAD_MASTER_USER_REPORT_ERROR = `${reportsNamespace}/SET_DOWNLOAD_MASTER_USER_REPORT_ERROR`;
export const CLEAR_AUTOMATIC_MASTER_REPORT_PROPS = `${reportsNamespace}/CLEAR_AUTOMATIC_MASTER_REPORT_PROPS`;
export const CLEAR_AUTOMATIC_ACTIVE_SUBSCRIPTIONS = `${reportsNamespace}/CLEAR_AUTOMATIC_ACTIVE_SUBSCRIPTIONS`;
export const LATEST_CSV_CREATION_DATE = `${reportsNamespace}/LATEST_CSV_CREATION_DATE`;
export const SET_LATEST_CSV_CREATION_DATE = `${reportsNamespace}/SET_LATEST_CSV_CREATION_DATE`;
export const SET_LATEST_CSV_CREATION_DATE_ERROR = `${reportsNamespace}/SET_LATEST_CSV_CREATION_DATE_ERROR`;
export const LATEST_CSV_CREATION_SUBSCRIPTIONS_DATE = `${reportsNamespace}/LATEST_CSV_CREATION_SUBSCRIPTIONS_DATE`;
export const SET_LATEST_CSV_CREATION_SUBSCRIPTIONS_DATE = `${reportsNamespace}/SET_LATEST_CSV_CREATION_SUBSCRIPTIONS_DATE`;
export const GET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT = `${reportsNamespace}/GET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT`;
export const SET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT = `${reportsNamespace}/SET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT`;
export const CLEAR_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT_PROPS = `${reportsNamespace}/CLEAR_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT_PROPS`;
export const SET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT_ERROR = `${reportsNamespace}/SET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT_ERROR`;
export const CLEAR_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_API_ERROR = `${reportsNamespace}/CLEAR_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_API_ERROR`;
export const GET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES = `${reportsNamespace}/GET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES`;
export const SET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES = `${reportsNamespace}/SET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES`;
export const SET_IS_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES_LOADING = `${reportsNamespace}/SET_IS_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES_LOADING`;
export const SET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES_ERROR = `${reportsNamespace}/SET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES_ERROR`;
export const GET_INTERCOM_ACTIVE_USERS_REPORT = `${reportsNamespace}/GET_INTERCOM_ACTIVE_USERS_REPORT`;
export const SET_INTERCOM_ACTIVE_USERS = `${reportsNamespace}/SET_INTERCOM_ACTIVE_USERS`;
export const SET_INTERCOM_ACTIVE_SUBSCRIPTIONS_ERROR = `${reportsNamespace}/SET_INTERCOM_ACTIVE_SUBSCRIPTIONS_ERROR`;
export const CLEAR_INTERCOM_ACTIVE_USERS_REPORT_PROPS = `${reportsNamespace}/CLEAR_INTERCOM_ACTIVE_USERS_REPORT_PROPS`;
export const CLEAR_INTERCOM_ACTIVE_USERS_API_ERROR = `${reportsNamespace}/CLEAR_INTERCOM_ACTIVE_USERS_API_ERROR`;
export const GET_TOPICS_REPORT_FOR_RATES = `${reportsNamespace}/GET_TOPICS_REPORT_FOR_RATES`;
export const GET_TOPICS_REPORT_FOR_RATES_SUCCESS = `${reportsNamespace}/GET_TOPICS_REPORT_FOR_RATES_SUCCESS`;
export const GET_TOPICS_REPORT_FOR_RATES_ERROR = `${reportsNamespace}/GET_TOPICS_REPORT_FOR_RATES_ERROR`;
export const COUNT_FAVORITE_REPORT = `${reportsNamespace}/COUNT_FAVORITE_REPORT`;
export const LOADING_COUNTER_REPORT = `${reportsNamespace}/LOADING_COUNTER_REPORT`;
export const SET_ARRAY_IDS_REPORT = `${reportsNamespace}/SET_ARRAY_IDS_REPORT`;
export const SET_CSV_REPORT = `${reportsNamespace}/SET_CSV_REPORT`;
export const GENERATE_RATE_QUESTIONS_REPORT = `${reportsNamespace}/GENERATE_RATE_QUESTIONS_REPORT`;
export const GET_LAST_RATE_QUESTIONS_REPORTS = `${reportsNamespace}/GET_LAST_RATE_QUESTIONS_REPORTS`;
export const SET_LAST_RATE_QUESTIONS_REPORTS = `${reportsNamespace}/SET_LAST_RATE_QUESTIONS_REPORTS`;
