import * as type from './actionTypesSagas';

export const loadWriters = (stype, typeId) => ({
  type: type.LOAD_WRITERS,
  stype,
  typeId,
});

export const getChapterWritersSaga = chapter => ({
  type: type.LOAD_CHAPTER_WRITERS,
  chapter,
});

export const assignWriters = (
  chapter,
  primaryWriter,
  secondaryWriters,
  reference
) => ({
  type: type.ASSIGN_WRITERS_TO_REFERENCE,
  reference,
  chapter,
  primaryWriter,
  secondaryWriters,
});

export const createAssignment = (
  chapter,
  primaryWriter,
  secondaryWriters,
  dueDate,
  status,
  createdBy,
  studyProgram,
  dontAddAssignment = false
) => ({
  type: type.CREATE_ASSIGNMENT,
  chapter,
  primaryWriter,
  secondaryWriters,
  dueDate,
  status,
  createdBy,
  studyProgram,
  dontAddAssignment,
});

export const updateAssignment = (
  assignment,
  primaryWriter,
  secondaryWriters,
  selectedWriterEmail
) => ({
  type: type.UPDATE_ASSIGNMENT,
  assignment,
  primaryWriter,
  secondaryWriters,
  selectedWriterEmail,
});

export const loadReferencesOfStudyProgram = (studyProgramType, typeId) => ({
  type: type.LOAD_REFERENCES,
  studyProgramType,
  typeId,
});

export const loadAssignment = assignment => ({
  type: type.LOAD_ASSIGNMENT,
  assignment,
});
