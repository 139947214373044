import * as type from './actionTypes';

export const setReference = (response) => ({
  type: type.SET_REFERENCE,
  payload: response,
});

export const waiting = (waiting) => ({
  type: type.UPDATE_WAITING,
  waiting,
});

export const updating = (updating) => ({
  type: type.UPDATE_UPDATING,
  updating,
});

export const setCurrentPage = (currentPage) => ({
  type: type.SET_CURRENT_PAGE,
  currentPage,
});

export const setQuestions = (questions, page, rows, isInfiniteScroll) => ({
  type: type.SET_QUESTIONS,
  payload: questions,
  page,
  rows,
  isInfiniteScroll,
});

export const editQuestion = (question, assignment, pdf = false) => ({
  type: type.UPDATE_QUESTION,
  question,
  assignment,
  pdf,
});

export const addQuestion = (question) => ({
  type: type.ADD_QUESTION,
  question,
});

export const batchAddQuestions = (questions) => ({
  type: type.BATCH_ADD_QUESTION,
  questions,
});

export const setQuestionAfterChangeState = (question) => ({
  type: type.SET_QUESTION_CHANGE_STATE,
  payload: question,
});

export const updateChapterAfterUpdateSubtopics = (chapter) => ({
  type: type.SET_CHAPTER_EDIT_SUBTOPICS,
  payload: chapter,
});

export const setAllQuestions = (questions) => ({
  type: type.ADD_ALL_QUESTIONS,
  questions,
});

export const deleteQuestionAction = (id) => ({
  type: type.DELETE_QUESTION,
  payload: id,
});

export const setChapter = (chapter, deleting = false, adding = false) => ({
  type: type.SET_CHAPTER,
  chapter,
  deleting,
  adding,
});

export const setChapterAfterDeletePdf = (chapter) => ({
  type: type.SET_CHAPTER_AFTER_DELETE_PDF,
  chapter,
});

export const setIsLoadingQuestions = (isLoadingQuestions) => ({
  type: type.SET_IS_LOADING_QUESTIONS,
  isLoadingQuestions,
});

export const setPublishedQuestion = (question) => ({
  type: type.SET_PUBLISHED_QUESTION,
  question,
});

export const restoreQuestionAction = (question) => ({
  type: type.RESTORE_QUESTION,
  question,
});

export const updateWaitingPdf = (value) => ({
  type: type.UPDATE_WAITING_PDF,
  value,
});

export const updateQuestionPdfProcessing = (question) => ({
  type: type.UPDATE_QUESTION_PDF,
  question,
});

export const updateSelectedView = (view) => ({
  type: type.UPDATE_SELECTED_VIEW,
  view,
});

export const setSelectedQuestion = (question) => ({
  type: type.SET_SELECTED_QUESTION,
  question,
});

export const setSeletedQuestionsForDeleted = (question, value) => ({
  type: type.SET_SELECTED_QUESTIONS_FOR_DELETED,
  question,
  value,
});

export const setIsSaveQuestion = (isSaveQuestion) => ({
  type: type.SET_ON_SAVE_QUESTION,
  isSaveQuestion,
});

export const setIsRefreshForm = (isRefreshForm) => ({
  type: type.IS_REFRESH_FORM,
  isRefreshForm,
});

export const setIsShowDeletedQuestions = (isShowDeleted) => ({
  type: type.SET_IS_SHOW_DELETED,
  isShowDeleted,
});

export const setUrlQuestion = (question) => ({
  type: type.SET_URL_QUESTION,
  question,
});

export const setCreateAnother = (value) => ({
  type: type.SET_CREATE_ANOTHER,
  value,
});

export const setEditPermission = (value) => ({
  type: type.SET_EDIT_PERMISSION,
  value,
});

export const setAmountOfQuestionsInChapter = (value) => ({
  type: type.SET_AMOUNT_OF_QUESTIONS_IN_CHAPTER,
  value,
});

export const setQuestionsToDownload = (questions) => ({
  type: type.SET_QUESTIONS_TO_DOWNLOAD,
  questions,
});

export const clearQuestionsToDownload = () => ({
  type: type.CLEAR_QUESTIONS_TO_DOWNLOAD,
});

export const setIsCreatingAiQuestion = (value) => ({
  type: type.SET_IS_CREATING_AI_QUESTION,
  value,
});

export const setUserCustomPrompts = (userCustomPrompts) => ({
  type: type.SET_USER_CUSTOM_PROMPTS,
  userCustomPrompts,
});

export const setQuestionHistory = (questionHistory) => ({
  type: type.SET_QUESTION_HISTORY,
  questionHistory,
});

export const setShowModalQuestionHistory = (value) => ({
  type: type.SET_SHOW_MODAL_QUESTION_HISTORY,
  value,
});

export const setImproveAiInQuestion = (aiSuggestion, questionId, status) => ({
  type: type.SET_IMPROVE_AI_IN_QUESTION,
  aiSuggestion,
  questionId,
  status,
});

export const setIncludeNotesInSuggestion = (questionId, value) => ({
  type: type.SET_INCLUDE_NOTES_IN_SUGGESTION,
  questionId,
  value,
});
