import * as t from './actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  booksResponse: { books: [], count: 0 },
  chaptersResponse: { chapters: [], count: 0 },
  questionsResponse: { questions: [], count: 0 },
  isSearching: false,
  searchSuccess: false,
  searchError: false,
  errors: [],
  query: '',
  searchFromHeader: false,
  options: 'All',
  moreBooks: true,
  moreChapters: true,
  moreQuestions: true,
  isSearchingBooks: false,
  isSearchingChapters: false,
  isSearchingQuestions: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case t.SET_BOOKS_RESPONSE:
      return state.set('booksResponse', action.response);

    case t.SET_CHAPTERS_RESPONSE:
      return state.set('chaptersResponse', action.response);

    case t.SET_QUESTIONS_RESPONSE:
      return state.set('questionsResponse', action.response);

    case t.SET_IS_SEARCHING_BOOKS:
      return state.set('isSearchingBooks', action.value);

    case t.SET_IS_SEARCHING_CHAPTERS:
      return state.set('isSearchingChapters', action.value);

    case t.SET_IS_SEARCHING_QUESTIONS:
      return state.set('isSearchingQuestions', action.value);

    case t.SET_SEARCH_SUCCES:
      return state.set('searchSuccess', action.value);

    case t.SET_SEARCH_ERROR:
      return state.set('searchError', action.value);

    case t.SET_ERRORS:
      return state.set('errors', action.value);

    case t.SET_OPTIONS:
      return state.set('options', action.options);

    case t.SET_QUERY:
      return state.set('query', action.query);

    case t.SET_SEARCH_FROM_HEADER:
      return state.set('searchFromHeader', action.value);

    case t.SET_MORE_BOOKS:
      return state.set('moreBooks', action.value);

    case t.SET_MORE_CHAPTERS:
      return state.set('moreChapters', action.value);

    case t.SET_MORE_QUESTIONS:
      return state.set('moreQuestions', action.value);

    case t.ATTACH_BOOKS_RESPONSE:
      // eslint-disable-next-line prefer-const
      let currentBooks = state.get('booksResponse');
      currentBooks.books = currentBooks.books.concat(action.response.books);
      currentBooks.count = action.response.count;
      return state.set('booksResponse', currentBooks);

    case t.ATTACH_CHAPTERS_RESPONSE:
      // eslint-disable-next-line prefer-const
      let currentChapters = state.get('chaptersResponse');
      currentChapters.chapters = currentChapters.chapters.concat(
        action.response.chapters,
      );
      currentChapters.count = action.response.count;
      return state.set('chaptersResponse', currentChapters);

    case t.ATTACH_QUESTIONS_RESPONSE:
      // eslint-disable-next-line prefer-const
      let currentQuestions = state.get('questionsResponse');
      currentQuestions.questions = currentQuestions.questions.concat(
        action.response.questions,
      );
      currentQuestions.count = action.response.count;
      return state.set('questionsResponse', currentQuestions);

    default:
      return state;
  }
}
