import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'subtopics');
}

export const getSubtopics = state => getState(state).get('subtopics');
export const getTopicSubtopics = state => getState(state).get('topicSubtopics');
export const getRate = state => getState(state).get('rateForSubtopics');
export const getChapters = state => getState(state).get('chapters');
