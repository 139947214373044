import Notifications from 'react-notification-system-redux';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { pageSize } from '../../constants/pagination';
import { closeModal } from '../../containers/Full/actions';
import * as api from '../../utilities/ServiceManager';
import { urlPdfPrivate } from '../Books/actions';
import { setRateQuestionPercentage, waiting } from '../Rates/actions';
import {
  createChapterReceivedAction,
  createReferenceReceivedAction,
  examReceivedAction,
  hasMoreData,
  loadAddChapter,
  loadReferencesDuplicateChapter,
  referencesReceived,
  referencesReceivedAction,
  searchReferenceDuplicateChapter,
  searchReferenceReceived,
  setPaginationPage,
  setRateBibliographyJson,
  setRateChapterQuestionsReport,
  setRatingExamQuestions,
  setReferenceById,
  submitBibReceivedAction,
  updateBibLogs,
  updateLoadingExam,
  updateLoadingGrid,
  updateLoadingInfiniteScroll,
} from './actions';
import {
  getRateQuestionsPercentage,
  getRateRatingExamQuestions,
} from './actionsSagas';
import {
  CREATE_CHAPTER_SAGAS,
  CREATE_REFERENCE_SAGAS,
  DUPLICATE_CHAPTER,
  GET_PDF_BY_REFERENCE,
  GET_REFERENCE_BY_ID,
  LOAD_EXAM,
  LOAD_RATE_BIBLIOPGRAPHY_JSON,
  LOAD_RATE_CHAPTER_QUESTION_REPORT,
  LOAD_RATE_QUESTION_PERCENTAGE,
  LOAD_RATING_EXAM_QUESTIONS,
  LOAD_REFERENCES,
  LOAD_REPORT,
  LOAD_TYPES,
  SUBMIT_BIB_SAGAS,
} from './actionTypesSagas';

function* fetchReferences(action) {
  // flag

  try {
    if (action.isLoadingInfiniteList) {
      yield put(updateLoadingInfiniteScroll(true));
    } else if (!action.duplicateChapter) {
      yield put(updateLoadingGrid(true));
    }

    let refs;
    if (action.id) {
      refs = yield call(
        api.getReferencesForStudyProgram,
        action.id,
        action.sptype,
      );
      yield put(referencesReceivedAction(refs));
    } else {
      const page = action.page > 0 ? action.page : 0;
      yield put(loadReferencesDuplicateChapter(true));
      refs = yield call(
        api.getAllReferences,
        action.search ? action.search : '',
        page,
        action.search ? action.rate : undefined,
        action.amountOfReferences,
        action.barFilter,
        action.duplicateChapter,
      );
      if (action.search && !action.duplicateChapter) {
        yield put(
          searchReferenceReceived(refs, action.isLoadingInfiniteList, page),
        );
      } else if (action.search && action.duplicateChapter) {
        yield put(searchReferenceDuplicateChapter(refs));
      } else {
        yield put(referencesReceived(refs));
        if (action.amountOfReferences) {
          yield put(setPaginationPage(action.amountOfReferences / pageSize));
        }
      }
      if (refs.length === 0) {
        yield put(hasMoreData(false));
      }
    }
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not load topics. Please try again later.',
    );
  } finally {
    yield put(updateLoadingInfiniteScroll(false));
    yield put(updateLoadingGrid(false));
    yield put(loadReferencesDuplicateChapter(false));
  }
}

function* getReferenceByIdSagas() {
  yield takeEvery(GET_REFERENCE_BY_ID, getReferenceById);
}

function* getReferenceById(action) {
  try {
    yield put(updateLoadingGrid(true));
    const reference = yield call(
      api.getReferenceById,
      Number(action.referenceId),
    );
    yield put(setReferenceById(reference));
    yield put(updateLoadingGrid(false));
  } catch (exception) {
    yield put(updateLoadingGrid(false));
    yield put(
      showNotificationErrorFromException(
        exception,
        'Something happend trying to get reference.',
      ),
    );
  }
}

function* duplicateChapterSagas() {
  yield takeEvery(DUPLICATE_CHAPTER, duplicateChapter);
}

function* duplicateChapter(action) {
  try {
    yield put(waiting(true));
    const duplicateResponse = yield call(
      api.duplicateChapterService,
      action.referenceId,
      action.chapterId,
      action.rateId,
    );
    if (duplicateResponse.name) {
      yield put(createChapterReceivedAction(duplicateResponse));
    }
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Chapter duplicate successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    yield put(waiting(false));
  } catch (exception) {
    yield put(waiting(false));
    yield put(
      showNotificationErrorFromException(
        exception,
        'Something happend trying to duplicate chapter.',
      ),
    );
  }
}

export function* watchGetReferences() {
  yield takeLatest(LOAD_REFERENCES, fetchReferences);
}

function* watchCreateReference() {
  yield takeEvery(CREATE_REFERENCE_SAGAS, createReference);
}

function* createReference(action) {
  try {
    const reference = action.fields;
    if (action.rate) {
      reference.rate = action.rate;
    }
    const creationResponse = yield call(api.createReference, reference);
    yield put(createReferenceReceivedAction(creationResponse));
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Reference created successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(closeModal());
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

function* watchPdfPrivate() {
  yield takeEvery(GET_PDF_BY_REFERENCE, pdfByRerefenceSaga);
}

export function* pdfByRerefenceSaga(action) {
  try {
    const response = yield call(api.getReferencePdfPrivate, action.payload);
    yield put(urlPdfPrivate(response));
  } catch (err) {}
}

function* watchLoadLogs() {
  yield takeEvery(LOAD_TYPES, loadBibliographyLogs);
}

function* loadBibliographyLogs(action) {
  try {
    const logs = yield call(
      api.getBibliograpgyLogs,
      action.ratingExam,
      action.dutyState,
    );
    yield put(updateBibLogs(logs));
  } catch (error) {
    yield put(closeModal());
    yield put(
      Notifications.show(
        { title: 'Error', message: 'Could not load logs' },
        'error',
      ),
    );
  }
}

function* watchCreateChapter() {
  yield takeEvery(CREATE_CHAPTER_SAGAS, createChapter);
}

function* createChapter(action) {
  try {
    yield put(loadAddChapter(true));
    const creationResponse = yield call(api.createChapter, action.fields);
    yield put(createChapterReceivedAction(creationResponse));
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Chapter created successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    yield put(loadAddChapter(false));
  } catch (error) {
    yield put(closeModal());
    yield put(loadAddChapter(false));
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

function* watchSubmitBib() {
  yield takeEvery(SUBMIT_BIB_SAGAS, submitBibliography);
}

function* submitBibliography(action) {
  try {
    const creationResponse = yield call(
      api.submitBibliography,
      action.bibliography,
    );
    yield put(submitBibReceivedAction(creationResponse));
    yield put(
      getRateRatingExamQuestions(
        { id: creationResponse.rate },
        true,
        action.bibliography.cycle,
      ),
    );
    yield put(getRateQuestionsPercentage(creationResponse.rate));
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Bibliography submitted successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(closeModal());
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  }
}

function* watchLoadExam() {
  yield takeEvery(LOAD_EXAM, loadExam);
}

function* loadExam(action) {
  try {
    const exam = {
      dutyState: action.dutyState,
      rank: action.rank,
      rate: action.rate,
      cycle: action.cycle,
    };
    const response = yield call(api.loadExam, exam);
    yield put(examReceivedAction(response));
  } catch (exception) {
    yield put(updateLoadingExam(false));
    showNotificationErrorFromException(
      exception,
      'Could not load bibliography. Please try again later.',
    );
  }
}

function* watchLoadRatingExamQuestions() {
  yield takeEvery(LOAD_RATING_EXAM_QUESTIONS, loadRatingExamQuestions);
}

function* loadRatingExamQuestions(action) {
  try {
    const creationResponse = yield call(
      api.loadRatingExamQuestions,
      action.rate,
      action.cycle,
    );
    yield put(setRatingExamQuestions(creationResponse, true, action.isReplace));
  } catch (error) {}
}

function* watchLoadRateQuestionPercentage() {
  yield takeEvery(LOAD_RATE_QUESTION_PERCENTAGE, loadRateQuestionPercentage);
}

function* loadRateQuestionPercentage(action) {
  try {
    const percentage = yield call(api.loadRateQuestionsPercentage, action.rate);
    yield put(setRateQuestionPercentage(action.rate, percentage));
  } catch (error) {}
}

function* watchLoadRateBibliographyJson() {
  yield takeEvery(LOAD_RATE_BIBLIOPGRAPHY_JSON, loadRateBibliographyJson);
}

function* loadRateBibliographyJson(action) {
  try {
    const json = yield call(api.loadRateBibliographyJson, action.rate);
    yield put(setRateBibliographyJson(json));
  } catch (error) {}
}

function* watchLoadRateChapterQuestionsReport() {
  yield takeEvery(
    LOAD_RATE_CHAPTER_QUESTION_REPORT,
    loadRateChapterQuestionsReport,
  );
}

function* loadRateChapterQuestionsReport(action) {
  try {
    const json = yield call(api.loadRateChapterQuestionsReport, action.rate);
    yield put(setRateChapterQuestionsReport(json));
  } catch (error) {}
}

function* watchLoadReport() {
  yield takeEvery(LOAD_REPORT, loadReport);
}

function* loadReport(action) {
  try {
    yield put(updateLoadingGrid(true));
    const json = yield call(api[action.report]);
    yield put(setRateChapterQuestionsReport(json));
  } catch (error) {
  } finally {
    yield put(updateLoadingGrid(false));
  }
}

export default function* sagas() {
  yield all([
    watchGetReferences(),
    watchCreateReference(),
    watchCreateChapter(),
    watchSubmitBib(),
    watchLoadExam(),
    watchLoadLogs(),
    watchLoadRatingExamQuestions(),
    watchLoadRateQuestionPercentage(),
    watchLoadRateBibliographyJson(),
    watchLoadRateChapterQuestionsReport(),
    watchLoadReport(),
    watchPdfPrivate(),
    getReferenceByIdSagas(),
    duplicateChapterSagas(),
  ]);
}

function showNotificationErrorFromException(
  exception,
  defaultMessage = 'Something went wrong, please try again.',
) {
  let message = defaultMessage;

  if (
    exception &&
    exception.response &&
    exception.response.data &&
    exception.response.data.error &&
    exception.response.data.error.code &&
    exception.response.data.error.code !== 'UsageError' &&
    exception.response.data.error.message
  ) {
    message = exception.response.data.error.message;
  }

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
