import React from 'react';
import PropTypes from 'prop-types';

export default class UserRow extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    updateProfile: PropTypes.func,
  };

  render() {
    let {
      user,
      selected,
      newStudent,
      setSelectedUser,
      loadUserStatistics,
    } = this.props;
    return (
      <div
        onClick={() => {
          if (newStudent) this.props.toggleNew();
          loadUserStatistics(user.id);
          setSelectedUser(user);
        }}
        className={selected ? 'rowUser active' : 'rowUser'}
      >
        <div className="valueName">
          <b>{user ? user.name + ' ' + user.lastName : ''}</b>
          {user.active ? '' : <i className="fas fa-user-lock" />}
        </div>
        <div className="valueEmail">{user ? user.email : ''}</div>
      </div>
    );
  }
}
