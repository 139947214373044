import * as type from './actionTypes';

export const setDefinitions = definitions => ({
  type: type.SET_DEFINITIONS,
  definitions,
});

export const setQuestionDefinitions = definitions => ({
  type: type.SET_UPDATED_DEFINITIONS,
  definitions,
});

export const updateWaiting = waiting => ({
  type: type.UPDATE_WAITING,
  waiting,
});

export const removeDefinition = id => ({
  type: type.REMOVE_DEFINITION,
  id,
});

export const updateDefinition = item => ({
  type: type.UPDATE_DEFINITION,
  item,
});

export const addDefinition = item => ({
  type: type.ADD_DEFINITION,
  item,
});

export const clearDefinitions = () => ({
  type: type.CLEAR_DEFINITIONS,
});

export const removeQuestionDefinition = id => ({
  type: type.REMOVE_QUESTION_DEFINITION,
  id,
});

export const updateQuestionDefinition = item => ({
  type: type.UPDATE_QUESTION_DEFINITION,
  item,
});

export const addQuestionDefinition = item => ({
  type: type.ADD_QUESTION_DEFINITION,
  item,
});

export const addQuestion = question => ({
  type: type.ADD_QUESTIONS,
  question,
});

export const editQuestion = question => ({
  type: type.EDIT_QUESTIONS,
  question,
});

export const setUpdatedDefinitions = definitions => ({
  type: type.SET_UPDATED_DEFINITIONS,
  definitions,
});
