import { connect } from 'react-redux';

import { setRateChapterQuestionsReport } from '../../Bibliographies/actions';
import { loadReportSaga } from '../../Bibliographies/actionsSagas';
import {
  getRateChapterQuestionsReport,
  loadingGrid,
} from '../../Bibliographies/selectors';
import { loadCycles } from '../../Cycles/actionsSagas';
import { getCycles } from '../../Cycles/selectors';
import { loadRates } from '../../Rates/actionsSagas';
import { getRates, getWaiting } from '../../Rates/selectors';
import {
  generateRateQuestionsReport,
  getBibliographyReportForGivenRates,
  getFavoriteQuestionsReportForGivenRates,
  getLastRateQuestionsReports as getLastRateQuestionsReportsAction,
  getProfileSheetReport,
  getUsersCount,
  getUserSubmittedQuestionsReportForGivenRates,
  setCompletionStatus,
  setGraphSagas,
  setUserSubmittedQuestionsReport,
} from '../actions';
import { getCompletionStatusSagas } from '../actionsSagas';
import {
  getArrayIdsFavoriteReport,
  getBibliographyCSVJsonReport,
  getBibliographyReportError,
  getBibliographyReportIsLoading,
  getCompletionStatus,
  getCountFavoriteReport,
  getCsvFavoriteQuestionReport,
  getfavoriteQuestionsReportCSVJson,
  getFavoriteQuestionsReportError,
  getFavoriteQuestionsReportIsLoading,
  getGraph,
  getIsUserSubmittedQuestionsReportLoading,
  getLastRateQuestionsReports,
  getLoadingCounterReport,
  getProfileSheetCsv,
  getProfileSheetIsLoading,
  getUsersCountData,
  getUsersReportIsLoading,
  getUserSubmittedQuestionsReportCSVJson,
  getUserSubmittedQuestionsReportError,
} from '../selectors';
import Reports from './Reports';

const mapStateToProps = (state) => ({
  report: getRateChapterQuestionsReport(state),
  isLoading: loadingGrid(state),
  ratesIsLoading: getWaiting(state),
  rates: getRates(state),
  /* All CSV Bibliography Data Report */
  bibliographyReport: getBibliographyCSVJsonReport(state),
  bibliographyReportIsLoading: getBibliographyReportIsLoading(state),
  bibliographyReportError: getBibliographyReportError(state),
  /* Users Report */
  usersReportIsLoading: getUsersReportIsLoading(state),
  usersCount: getUsersCountData(state),
  graph: getGraph(state),
  /* Completion Status Report */
  completionStatus: getCompletionStatus(state),
  /* Profile Sheet Data Report */
  cycles: getCycles(state),
  profileSheetCsv: getProfileSheetCsv(state),
  profileSheetIsLoading: getProfileSheetIsLoading(state),
  /* User Submitted Questions Report */
  userSubmittedQuestionsReport: getUserSubmittedQuestionsReportCSVJson(state),
  isUserSubmittedQuestionsReportLoading:
    getIsUserSubmittedQuestionsReportLoading(state),
  userSubmittedQuestionsReportError:
    getUserSubmittedQuestionsReportError(state),
  /* Favorite questions Report */
  favoriteQuestionsReport: getfavoriteQuestionsReportCSVJson(state),
  favoriteQuestionsReportIsLoading: getFavoriteQuestionsReportIsLoading(state),
  favoriteQuestionsReportError: getFavoriteQuestionsReportError(state),
  countFavoriteReport: getCountFavoriteReport(state),
  loadingCounterReport: getLoadingCounterReport(state),
  csvFavoriteQuestionReport: getCsvFavoriteQuestionReport(state),
  arrayIdsFavoriteReport: getArrayIdsFavoriteReport(state),
  /* Rate questions Report */
  lastRateQuestionsReports: getLastRateQuestionsReports(state),
  // rateQuestionsReportIsLoading={this.props.favoriteQuestionsReportIsLoading}
  // rateQuestionsReportError={this.props.favoriteQuestionsReportError}
});

const mapDispatchToProps = (dispatch) => ({
  loadRates: () => {
    dispatch(loadRates());
  },
  downloadReport: (report) => {
    dispatch(loadReportSaga(report));
  },
  onClearReport: () => {
    dispatch(setRateChapterQuestionsReport());
  },

  /* All CSV Bibliography Data Report */
  loadCycles: () => {
    dispatch(loadCycles());
  },
  getBibliographyReportForGivenRates: (rates, ratingExams, multiple) => {
    dispatch(getBibliographyReportForGivenRates(rates, ratingExams, multiple));
  },
  /* Users Report */
  getUsersCount: (query) => {
    dispatch(getUsersCount(query));
  },
  setGraph: (graph) => {
    dispatch(setGraphSagas(graph));
  },
  /* Profile Sheet Data Report */
  getProfileSheetReport: (cycleId) => {
    dispatch(getProfileSheetReport(cycleId));
  },
  /* Completion Status Report */
  setCompletionStatus: (data) => {
    dispatch(setCompletionStatus(data));
  },
  getCompletionStatusSagas: () => {
    dispatch(getCompletionStatusSagas());
  },
  /* User Submitted Questions Report */
  getUserSubmittedQuestionsReportForGivenRates: (rates) => {
    dispatch(getUserSubmittedQuestionsReportForGivenRates(rates));
  },
  setUserSubmittedQuestionsReport: (data) => {
    dispatch(setUserSubmittedQuestionsReport(data));
  },
  /* Favorite questions Report */
  getFavoriteQuestionsReportForGivenRates: (
    rates,
    ratingExams,
    csvReport,
    arrayIds,
    loading,
  ) => {
    dispatch(
      getFavoriteQuestionsReportForGivenRates(
        rates,
        ratingExams,
        csvReport,
        arrayIds,
        loading,
      ),
    );
  },
  /* Rate questions Report */
  generateRateQuestionsReport: (ratesIds) => {
    dispatch(generateRateQuestionsReport(ratesIds));
  },
  getLastRateQuestionsReports: () => {
    dispatch(getLastRateQuestionsReportsAction());
  },
});

const ReportsContainer = connect(mapStateToProps, mapDispatchToProps)(Reports);

export default ReportsContainer;
