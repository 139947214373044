import * as t from './actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  selectedCoupon: null,
  coupons: [],
  isLoadingCodeGroups: false,
  isGettingCodeGroup: false,
  codeGroups: [],
  codeGroup: null,
  selectedCodeGroup: null,
  redeemedCodes: [],
  unredeemedCodes: undefined,
  couponsReferral: [],
  selectedCouponReferral: null,
  loadingCoupons: false,
  badRequest: false,
  waitingResponse: false,
  newCoupons: [],
  couponDetail: '',
  loadCoupons: false,
  plansSubscriptions: [],
  deleteCoupon: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case t.SET_COUPON:
      return state.set('selectedCoupon', action.coupon);
    case t.SET_COUPONS:
      return state.set('coupons', action.coupons);
    case t.SET_CODE_GROUPS:
      return state.set('codeGroups', action.codeGroups);
    case t.SET_LOAD_COUPONS:
      return state.set('loadCoupons', action.value);
    case t.SET_CODE_GROUP:
      return state.set('codeGroup', action.codeGroup);
    case t.UPDATE_CODE_GROUP:
      return state.set(
        'codeGroups',
        state
          .get('codeGroups')
          .map((c) => {
            if (c.id === action.codeGroup.id) {
              c = action.codeGroup;
            }
            return c;
          })
          .slice(),
      );
    case t.SET_UNREDEEMED_CODES:
      return state.set('unredeemedCodes', action.codes);
    case t.SET_IS_LOADING_CODE_GROUPS:
      return state.set('isLoadingCodeGroups', action.isLoading);
    case t.SET_IS_GETTING_CODE_GROUP:
      return state.set('isGettingCodeGroup', action.isLoading);
    case t.CANCELL_ALL_CODES:
      return state.set(
        'codeGroups',
        state.get('codeGroups').map((c) => {
          if (c.id === action.codeGroup) {
            c.couponCodes = c.couponCodes.map((e) => {
              e.isCancelled = true;
              return e;
            });
          }
          return c;
        }),
      );
    case t.ADD_CODE_GROUPS:
      return state.set(
        'codeGroups',
        state.get('codeGroups').concat([action.codeGroup]),
      );
    case t.ADD_COUPON:
      return state.set('coupons', state.get('coupons').concat([action.coupon]));
    case t.UPDATE_COUPON:
      return state.set(
        'coupons',
        state.get('coupons').map((c) => {
          if (c.id === action.coupon.id) {
            c.description = action.coupon.description;
          }
          return c;
        }),
      );
    case t.CLEAN_CODE_GROUP:
      return state.set('codeGroup', null);
    case t.SET_SELECETED_CODE_GROUP:
      return state.set('selectedCodeGroup', action.selectedCodeGroup);
    case t.SET_REDEEMED_CODES:
      return state.set('redeemedCodes', action.redeemedCodes);
    case t.SET_COUPONS_REFERRAL:
      return state.set('couponsReferral', action.couponsReferral);
    case t.SET_PLAN_SUBSCRIPTIONS:
      return state.set('plansSubscriptions', action.plans);
    case t.SET_SELECTED_COUPON_REFERRAL:
      return state.set('selectedCouponReferral', action.couponReferral);
    case t.SET_LOADING_COUPONS:
      return state.set('loadingCoupons', action.value);
    case t.SET_BAD_REQUEST:
      return state.set('badRequest', action.value);
    case t.SET_WAITING_RESPONSE:
      return state.set('waitingResponse', action.value);
    case t.SET_NEW_COUPONS:
      return state.set('newCoupons', action.newCoupons);
    case t.UPDATE_NEW_COUPONS:
      let oldNewCoupons = state.get('newCoupons');
      action.coupon.forEach((c) => {
        oldNewCoupons = oldNewCoupons.filter((oc) => oc.id !== c.id);
      });
      let couponName = undefined;
      if (action.coupon[0]) {
        couponName = action.coupon[0].name;
      }
      return state
        .set('newCoupons', [...oldNewCoupons, ...action.coupon])
        .set('couponDetail', couponName);

    case t.UPDATE_SUBCODES:
      const coupons = state.get('newCoupons').map((c) => {
        if (c.name === action.couponName) c.subcodes = action.subcodes;
        return c;
      });
      return state.set('newCoupons', [...coupons]);

    case t.SET_COUPON_DETAIL:
      return state.set('couponDetail', action.nameCoupon);
    case t.SET_DELETE_COUPON:
      return state.set('deleteCoupon', action.value);
    default:
      return state;
  }
}
