const assignmentsSagaNamespace = 'assignments';
export const GET_ALL_RATES_ADMINS = `${assignmentsSagaNamespace}/GET_ALL_RATES_ADMINS`;
export const ON_EDIT_ASSIGNMENT = `${assignmentsSagaNamespace}/ON_EDIT_ASSIGNMENT`;
export const LOAD_ASSIGNMENTS = `${assignmentsSagaNamespace}/LOAD_ASSIGNMENTS`;
export const DELETE_ASSIGNMENT = `${assignmentsSagaNamespace}/DELETE_ASSIGNMENT`;
export const ASSIGNMENTS_COUNT_SAGAS = `${assignmentsSagaNamespace}/ASSIGNMENTS_COUNT_SAGAS`;
export const GET_AWAITING_APPROVAL = `${assignmentsSagaNamespace}/GET_AWAITING_APPROVAL`;
export const GET_ALL_QUALS_ADMINS = `${assignmentsSagaNamespace}/GET_ALL_QUALS_ADMINS`;
export const LOAD_MY_ASSIGNMENTS = `${assignmentsSagaNamespace}/LOAD_MY_ASSIGNMENTS`;
export const LOAD_WRITERS = `${assignmentsSagaNamespace}/LOAD_WRITERS`;
export const LOAD_RATES_FOR_ASSIGN_MODAL = `${assignmentsSagaNamespace}/LOAD_RATES_FOR_ASSIGN_MODAL`;
export const LOAD_REFERENCES_FOR_ASSIGN_MODAL = `${assignmentsSagaNamespace}/LOAD_REFERENCES_FOR_ASSIGN_MODAL`;
export const SUBMIT_QUESTIONS_GPT = `${assignmentsSagaNamespace}/SUBMIT_QUESTIONS_GPT`;
export const SUBMIT_SUMMARY = `${assignmentsSagaNamespace}/SUBMIT_SUMMARY`;
