import PropTypes from 'prop-types';
import React from 'react';

import AuthyForm from './AuthyForm';
import LoginStateless from './LoginStateless';

class LoginStateful extends React.Component {
  // Pass the correct proptypes in for validation
  static propTypes = {
    handleSubmit: PropTypes.func,
    loginRequest: PropTypes.func,
    login: PropTypes.shape({
      requesting: PropTypes.bool,
      successful: PropTypes.bool,
      authy_requesting: PropTypes.bool,
      messages: PropTypes.array,
      errors: PropTypes.array,
      email: PropTypes.string,
      authy_waiting: PropTypes.bool,
    }),
  };

  // Remember, Redux Form passes the form values to our handler
  // In this case it will be an object with `email` and `password`
  submit = (values) => {
    this.props.loginRequest(values);
  };

  authyVerificate = (values) => {
    const {
      login: { email },
    } = this.props;

    const code = values.code;
    this.props.authyVerificationRequest({
      code,
      email,
    });
  };

  resendCode = () => {
    const {
      login: { email },
    } = this.props;

    this.props.resendCodeRequest(email);
  };

  resetStatus = () => {
    this.props.resetStatus();
  };

  render() {
    const {
      handleSubmit, // remember, Redux Form injects this into our props
      login: { requesting, messages, errors, authy_requesting, authy_waiting },
    } = this.props;

    return (
      <div className="container login">
        {!authy_requesting && (
          <LoginStateless
            handleSubmit={handleSubmit}
            submit={this.submit}
            requesting={requesting}
            messages={messages}
            errors={errors}
            authy_requesting={authy_requesting}
          />
        )}

        {authy_requesting && (
          <AuthyForm
            handleSubmit={handleSubmit}
            submit={this.authyVerificate}
            requesting={requesting}
            messages={messages}
            errors={errors}
            authy_requesting={authy_requesting}
            authy_waiting={authy_waiting}
            resendCode={this.resendCode}
            resetStatus={this.resetStatus}
          />
        )}
      </div>
    );
  }
}

export default LoginStateful;
