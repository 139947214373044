import { connect } from 'react-redux';
import { getLogs } from '../actionsSagas'
import { getCurrentLogs, getWaiting } from '../selectors';
import ViewActivityModal from './ViewActivityModal';
import { setLogs } from '../actions';


const mapStateToProps = (state, ownProps) => {
  return {
    closeModal: ownProps.closeModal,
    id: ownProps.id,
    isChapter: ownProps.isChapter,
    logs: getCurrentLogs(state),
    waiting: getWaiting(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoad: (id, isChapter) => {
      dispatch(getLogs(id, isChapter));
    },
    setLogs: () => {
      dispatch(setLogs([]));
    }  
  };
};

const ViewActivityContainer = connect(mapStateToProps, mapDispatchToProps)(
    ViewActivityModal
);

export default ViewActivityContainer;
