import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './newCouponsListStyles.css';

import CreateNewCoupon from './CreateNewCoupon';

class newCouponsList extends React.Component {
  state = {
    search: '',
    isActive: false,
    getCoupon: true,
  };

  componentDidMount() {
    if (this.state.getCoupon) {
      this.props.loadNewCoupons();
      this.props.listPlansSubscriptions();
      this.setState({ getCoupon: false });
    }
  }
  componentWillReceiveProps = () => {
    if (this.props.loadCoupons) {
      this.props.loadNewCoupons();
    }
  };
  listCoupon(newCoupons) {
    let hash = {};
    newCoupons = newCoupons.filter((o) =>
      hash[o.name] ? false : (hash[o.name] = true),
    );
    return newCoupons;
  }

  renderSearch(search) {
    return (
      <Col className="noPadRight" xs={10} md={10}>
        <div className="searchInput">
          <input
            id="input"
            className="form-control"
            value={search}
            onChange={this.onSearch}
          />
          {search !== '' ? (
            <i
              onClick={() => this.setState({ search: '' })}
              className="fa fa-close fa-lg fa-close"
            />
          ) : (
            <i className="fa fa-search fa-lg fa-in-search" />
          )}
        </div>
      </Col>
    );
  }

  onOpenCreateModal = () => {
    this.props.openModal({
      modalContent: (
        <CreateNewCoupon
          createNewCoupon={this.props.createNewCoupon}
          onCancel={this.props.closeModal}
          title="Create Coupon"
          loadCoupons={this.props.loadCouponAction}
          plansSubscriptions={this.props.plansSubscriptions}
          listPlansSubscriptions={this.props.listPlansSubscriptions}
          newCoupons={this.props.newCoupons}
        />
      ),
    });
  };

  onSearch = (e) => {
    this.setState({ search: e.target.value });
  };

  renderSearch(search) {
    return (
      <Col className="noPadRight" xs={10} md={10}>
        <div className="searchInput">
          <input
            id="input"
            className="form-control"
            value={search}
            onChange={this.onSearch}
          />
          {search !== '' ? (
            <i
              onClick={() => this.setState({ search: '' })}
              className="fa fa-close fa-lg fa-close"
            />
          ) : (
            <i className="fa fa-search fa-lg fa-in-search" />
          )}
        </div>
      </Col>
    );
  }

  render() {
    let { couponDetail, newCoupons, setCouponDetail } = this.props;

    let isSearch = this.state.search !== '';

    const result = this.listCoupon(newCoupons);

    let filteredCoupons = isSearch
      ? result.filter((c, index) =>
          c.name.toLowerCase().includes(this.state.search.toLowerCase()),
        )
      : result;

    //Sort alphabetically by coupon name
    filteredCoupons.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });

    if (!couponDetail && filteredCoupons && filteredCoupons[0]) {
      this.props.setCouponDetail(filteredCoupons[0].name);
    }
    return (
      <Col className="coupon-list-container coupons-list">
        <div>
          <Row className="couponContainer">
            {this.renderSearch(this.state.search)}
            <Col className="plusContainer">
              {this.props.plansSubscriptions.length > 0 && (
                <i
                  className="fa fa-plus fa-lg"
                  onClick={this.onOpenCreateModal}
                />
              )}
            </Col>
          </Row>

          <div className="coupon-name-list">
            {filteredCoupons.length ? (
              filteredCoupons.map((coupon) => (
                <div className="row" key={coupon.id}>
                  <div
                    className={
                      couponDetail && couponDetail === coupon.name
                        ? 'coupon-line active'
                        : 'coupon-line'
                    }
                    onClick={() => {
                      setCouponDetail(coupon.name);
                    }}
                  >
                    <div>
                      <div className="description">{coupon.name}</div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Coupons</p>
            )}
          </div>
        </div>
      </Col>
    );
  }
}

export default newCouponsList;
