import React from 'react';
import Button from 'react-bootstrap-button-loader';
import './CreateAIquestionForChapterStyle.css';
import { Row, Col } from 'react-bootstrap';
import DropFileInput from '../../../commonComponents/DropFileInput/DropFileInput';
import {
  SimpleInput,
  SimpleTextarea,
} from '../../../commonComponents/Input/Input';
import UploadInput from '../../Questions/commonComponents/UploadInput/UploadInput';
import Spinner from '../../../commonComponents/Spinner/Spinner';

class CreateAIquestionForChapterModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      wating: false,
      pdf: null,
      url: '',
      pageFrom: 1,
      pageTo: '',
      customPrompt: '',
      pdfChapter: '',
      isLoading: false,
    };
  }
  componentWillMount() {
    if (this.props.totalPages)
      this.setState({
        pageTo: this.props.totalPages,
      });
    if (this.props.pdf)
      this.setState({
        pdfChapter: this.props.pdf,
      });
  }

  validate = () => {
    let errors = {};
    let fields = Object.assign({}, this.state);
    delete fields.errors;
    delete fields.wating;
    delete fields.isLoading;
    delete fields.pdfChapter;
    if (!fields.pdf && fields.url === '' && !this.state.pdfChapter) {
      errors.name = 'Pdf or url field is required';
    }

    if (
      this.state.pageFrom > this.state.pageTo ||
      this.state.pageFrom <= 0 ||
      this.state.pageTo <= 0
    ) {
      errors.invalidRange = true;
    }

    if (this.props.totalPages && this.props.totalPages >= 100) {
      errors.totalPages =
        'The total number of pages in the PDF must be less than 100';
    }

    if (
      this.state.errors &&
      (this.state.errors.pageFrom || this.state.errors.pageTo)
    )
      return;

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    if (this.state.pdfChapter) {
      fields.pdf = null;
    }
    if (!this.state.pdfChapter) {
      fields.pageFrom = null;
      fields.pageTo = null;
    }
    fields.url = this.state.pdfChapter;
    fields.referenceId = this.props.referenceId;
    fields.chapterId = this.props.chapter.id;
    fields.rateId = this.props.rateId;
    this.setState({ errors: null, wating: true });
    this.props.submitQuestionsGPT(fields, null);
  };

  handlePageNumberChange = (value, title) => {
    const inputNumber = value;
    let errors = {};
    if (title === 'From') {
      this.setState({ pageFrom: inputNumber });
      if (
        value > this.props.totalPages ||
        !(
          !isNaN(inputNumber) &&
          inputNumber >= 1 &&
          inputNumber <= this.props.totalPages
        )
      )
        errors.pageFrom = true;
      else errors.pageFrom = false;
    }

    if (title === 'To') {
      this.setState({ pageTo: inputNumber });
      if (
        value > this.props.totalPages ||
        !(
          !isNaN(inputNumber) &&
          inputNumber >= 1 &&
          inputNumber <= this.props.totalPages
        )
      )
        errors.pageTo = true;
      else errors.pageTo = false;
    }

    this.setState({ errors });
  };

  renderInputPage(title, totalPages) {
    let errorValue = false;
    if (title === 'From' && this.state.errors && this.state.errors.pageFrom)
      errorValue = true;
    if (title === 'To' && this.state.errors && this.state.errors.pageTo)
      errorValue = true;
    return (
      <SimpleInput
        id="pageNumberInput"
        type="number"
        min="1"
        max={totalPages}
        value={title === 'From' ? this.state.pageFrom : this.state.pageTo}
        onChange={(e) =>
          this.handlePageNumberChange(parseInt(e.target.value), title)
        }
        errorValue={errorValue}
        placeholder={title}
      />
    );
  }

  render() {
    const {
      closeModal,
      chapter,
      referenceId,
      totalPages,
      deletePdfChapter,
      updateQuestionPdfProcessing,
      questionPdfProcessing,
      updateChapter,
    } = this.props;
    return (
      <div className="modal-create-AI-question-container">
        <div className="card-block mb-0">
          <div className="title-container">
            Create GPT Questions
            <i
              className="fa fa-times fa-xs"
              onClick={() => {
                if (!this.state.wating) closeModal();
              }}
            />
            <hr className="hrModal" />
          </div>
          {this.props.isWaiting ? (
            <div>
              <Spinner text="Loading" />
            </div>
          ) : (
            <div className="widget-form new-admin-form">
              {!this.state.url && (
                <Row>
                  <Col md={4}>
                    <span className="labelForm mb-0">Pdf</span>
                  </Col>
                  <Col md={8} className="mb-2">
                    <div className="mb-0">
                      <DropFileInput
                        input={{
                          onChange: (e) => {
                            this.setState({ pdf: e, url: '' });
                          },
                        }}
                        value={this.state.pdf}
                        defaultValue={this.state.pdfChapter}
                        multiple={false}
                        accept={{ 'application/pdf': ['.pdf'] }}
                        getUrlPdf={this.props.getUrlPdf}
                        onRemoveFile={() => {
                          this.setState({ pdfChapter: '' });
                        }}
                        createQuestionModal={true}
                      />
                    </div>
                  </Col>
                </Row>
              )}
              {!this.state.pdf && (
                <Row>
                  <Col md={4}>
                    <span className="labelForm">Or insert the URL</span>
                  </Col>
                  <Col md={8}>
                    <SimpleInput
                      type="text"
                      value={this.state.url}
                      onChange={(e) => {
                        this.setState({ url: e.target.value, pdf: null });
                      }}
                    />
                  </Col>
                </Row>
              )}
              {this.state.pdfChapter && (
                <Row className="alignItems">
                  <Col md={4}>
                    <span className="labelForm mb-0">
                      Enter the range of pages
                    </span>
                    <p className="mb-0">Total pages: {totalPages}</p>
                  </Col>
                  <Col md={8}>
                    <div className="directionRow">
                      <div className="mr-2">
                        {this.renderInputPage('From', totalPages)}
                      </div>
                      <div>{this.renderInputPage('To', totalPages)}</div>
                    </div>
                  </Col>
                  <div className="ml-2">
                    {this.state.errors &&
                      (this.state.errors.pageFrom ||
                        this.state.errors.pageTo) && (
                        <span
                          style={{ color: 'red' }}
                        >{`Out of bounds page reference, total page is ${totalPages} `}</span>
                      )}
                    {this.state.errors && this.state.errors.invalidRange && (
                      <span
                        style={{ color: 'red' }}
                      >{`Invalid input range`}</span>
                    )}
                  </div>
                </Row>
              )}
              <Row>
                <Col md={4} className="alignItems">
                  <span className="labelForm mb-0">Custom Prompt</span>
                </Col>
                <Col md={8} className="mt-2">
                  <div>
                    <SimpleTextarea
                      type="text"
                      value={this.state.customPrompt}
                      onChange={(e) => {
                        this.setState({ customPrompt: e.target.value });
                      }}
                      style={{ height: '6rem' }}
                      error={
                        this.state.errors && this.state.errors.customPrompt
                          ? this.state.errors.customPrompt
                          : null
                      }
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}

          <hr className="hrModal" />
          {this.state.errors && this.state.errors.pdf && (
            <span style={{ color: 'red' }}>{this.state.errors.pdf}</span>
          )}
          {this.state.errors && this.state.errors.totalPages && (
            <span style={{ color: 'red' }}>{this.state.errors.totalPages}</span>
          )}
          <div className="bottom-buttons-new">
            <Button
              id="btn-success"
              className="btn btn-success"
              onClick={() => {
                this.validate();
                closeModal();
              }}
              loading={this.state.wating}
            >
              Create
            </Button>
            <Button
              className="btn btn-secondary"
              onClick={() => closeModal()}
              disabled={this.state.wating}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateAIquestionForChapterModal;
