export function filterLinksForTabs(
  tabName,
  rolesPermissions,
  rate,
  links,
  superAdmin,
) {
  let auxLinks = links.map(link => {
    let auxLink = link;
    if (link.name === tabName) {
      auxLink.active = true;
    } else {
      auxLink.active = false;
    }
    return auxLink;
  });
  if (superAdmin) {
    return auxLinks;
  } else {
    let filteredLinks = [];
    auxLinks.map(lnk => {
      let auxLnk = rolesPermissions.find(
        rolePerm =>
          rolePerm.actions.find(
            action =>
              action.split('.').length > 1 && action.split('.')[1] === lnk.name,
          ) && Number(rolePerm.typeId) === rate.id,
      );
      if (auxLnk) {
        filteredLinks.push(lnk);
      }
      return lnk;
    });
    return filteredLinks;
  }
}

export function hasPermissions(tabName, rolesPermissions, rate) {
  let hasPermission = false;
  rolesPermissions.map(rolesPermission => {
    if (
      rolesPermission.actions.find(action => action.endsWith(tabName)) &&
      Number(rolesPermission.typeId) === rate.id
    ) {
      hasPermission = true;
    }
    return rolesPermission;
  });
  return hasPermission;
}

export function lookRateWithPermission(tabName, rates, rolesPermissions) {
  let role = rolesPermissions.find(role =>
    role.actions.find(action => action.endsWith(tabName)),
  );
  let rate = role && rates.find(rateAux => rateAux.id === Number(role.typeId));
  return rate;
}

export function lookRatesWithPermission(tabName, rates, rolesPermissions) {
  let role = rolesPermissions.find(role =>
    role.actions.find(action => action.endsWith(tabName)),
  );
  let ratesAux =
    role && rates.filter(rateAux => rateAux.id === Number(role.typeId));
  return ratesAux;
}

export function hasPagePermissionRates(tabName, pagesPermissions) {
  let pagePerm = pagesPermissions.find(pagePermission =>
    pagePermission.endsWith(tabName),
  );

  if (pagePerm) {
    return true;
  }
  return false;
}

export function hasPagePermission(page, pagesPermissions) {
  let pagePerm = pagesPermissions.find(
    pagePermission => pagePermission === page,
  );
  if (pagePerm) {
    return true;
  }
  return false;
}

export function hasSomePermissionForPage(page, pagesPermissions) {
  let pagePerm = pagesPermissions.find(pagePermission =>
    pagePermission.startsWith(page),
  );
  if (pagePerm) {
    return true;
  }
  return false;
}
