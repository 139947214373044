import React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import SubHeader from '../../../commonComponents/SubHeader/SubHeader';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import './PracticeExams.css';

const dutyState = ['Regular', 'Substitute', 'Reserve'];
const ranks = ['E4', 'E5', 'E6', 'E7'];

export default class PracticeExams extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.props.options,
      requestSent: false,
      internalQueryError: false,
      search: '',
      rateId: null,
      dutyState: null,
      rank: null,
      questionToDelete: null,
      activateModalOpen: false,
      scrolled: false,
      questionUpdateMessage: true,
      questionsViewScrolled: false,
    };
  }

  // REACT LIFECYCLE FUNCTIONS
  componentDidMount() {
    this.props.loadCycles();
    this.props.loadRates();
    this.checkScrollStatus();
    this.props.setBuildAllExamsWaiting(true);
    this.props.setBuildAllExamsProgress(0);
    this.props.setBuildAllExamsSuccess(false);
    this.props.checkBuildPEProgress();
  }
  showQuestionUpdateMessage = () => {
    this.setState({ questionUpdateMessage: false });
    setTimeout(() => {
      this.setState({ questionUpdateMessage: true });
    }, 2500);
  };
  componentWillReceiveProps = nextProps => {
    if (nextProps.removeQuestionSuccess) {
      this.openDeleteQuestionModal(null);
      this.props.setRemoveQuestionSuccess(false);
      this.showQuestionUpdateMessage();
      this.setState({ questionsViewScrolled: true });
    }
    if (nextProps.activateExamSuccess) {
      this.openActivateExamModal(false);
      this.props.setActivateExamSuccess(false);
    }
    if (nextProps.buildAllExamsSuccess) {
      this.props.setBuildAllExamsSuccess(false);
    }
  };

  componentDidUpdate = () => {
    this.checkScrollStatus();
  };

  checkScrollStatus = () => {
    if (!this.state.scrolled) {
      const ratesContainer = document.querySelector('.rates-container');
      if (ratesContainer) {
        const activeRow = document.querySelector('.active-row');
        if (activeRow) {
          ratesContainer.scrollTop =
            activeRow.parentNode.parentNode.offsetTop - 200;
          this.setState({ scrolled: true });
        }
      }
    }
  };

  onSearch = event => {
    this.setState({ search: event.target.value });
  };

  clearSearchInput = () => {
    this.setState({ search: '' });
  };

  selectOption = (rateId, rank, dutyState) => {
    this.props.checkBuildPEProgress();
    this.props.selectOption(rateId, rank, dutyState);
    this.props.loadPracticeExamInformation(rateId, rank, dutyState);
  };

  getRates = () => {
    let { rates } = this.props;

    //search filter
    if (this.state.search !== '') {
      const search = this.state.search.toLowerCase();
      rates = rates.filter(r => {
        if (r.id !== 0) {
          return (
            r.name.toLowerCase().includes(search) ||
            r.shortname.toLowerCase().includes(search)
          );
        } else {
          return null;
        }
      });
    }

    if (rates.length > 0) {
      if (rates[0].id === 0) {
        rates.shift();
      }

      return rates.map((rate, index) => (
        <RateLine
          key={`${rate.shortname}${index}`}
          rate={rate}
          selectOption={this.selectOption}
          selectedDutyState={this.props.dutyState}
          selectedRate={this.props.rate}
          selectedRank={this.props.rank}
        />
      ));
    }
    return <p>No rates loaded</p>;
  };

  buildAllExams = () => {
    const { rates } = this.props;
    const ratingExamsList = [];

    if (rates.length > 0) {
      //CREATE RATING EXAMS LIST
      let filteredRates = rates.filter(r => r.id !== 0);
      filteredRates = filteredRates.map(r => r.id);

      filteredRates.forEach(rateId => {
        ranks.forEach(rnk => {
          dutyState.forEach(ds => {
            ratingExamsList.push({
              rate: rateId,
              rank: rnk,
              dutyState: ds,
            });
          });
        });
      });

      this.props.buildAllExams(ratingExamsList);
    }
  };

  renderRatingExamsList = () => {
    return (
      <Col md={3} className="practice-exams-group-list px-0">
        <SubHeader
          options={['Practice Exams']}
          onOptionChange={() => {}}
          activeOption="Practice Exams"
          onSearch={this.onSearch}
          onPlusClick={null}
          disabledOptions={[]}
          searchValue={this.state.search}
          clearSearchInput={this.clearSearchInput}
          pePage={true}
          checkBuildPEProgress={this.props.checkBuildPEProgress}
          buildAllExams={this.buildAllExams}
          buildAllExamsWaiting={this.props.buildAllExamsWaiting}
        />
        <div className="rates-container">{this.getRates()}</div>
      </Col>
    );
  };

  getCycleSelectorOptions = info => {
    return info.map(record => {
      return (
        <option value={Number(record.cycle.cycleId)} key={record.cycle.cycleId}>
          {record.cycle.title}
        </option>
      );
    });
  };

  handleCycleChange = cycleId => {
    this.props.setCycle(cycleId);
  };

  renderExamsSelector = info => {
    const { selectedCycle, exam } = this.props;
    const selectedRecord = info.find(
      record => record.cycle.cycleId === selectedCycle,
    );
    return (
      <select
        className="form-control exam-selector"
        value={exam}
        onChange={e => this.handleExamChange(e.target.value)}
      >
        <option value={selectedRecord.freePracticeExam.id}>Free Exam</option>
        <option value={selectedRecord.paidPracticeExam.id}>Paid Exam</option>
        {selectedRecord.bonusPracticeExam &&
          selectedRecord.bonusPracticeExam.id && (
            <option value={selectedRecord.bonusPracticeExam.id}>
              Bonus Exam
            </option>
          )}
        {selectedRecord.extraPracticeExam &&
          selectedRecord.extraPracticeExam.id && (
            <option value={selectedRecord.extraPracticeExam.id}>
              Extra Exam
            </option>
          )}
      </select>
    );
  };

  handleExamChange = examId => {
    this.props.setExam(Number(examId));
  };

  openDeleteQuestionModal = questionId => {
    this.setState({ questionToDelete: questionId });
  };

  openActivateExamModal = value => {
    this.setState({ activateModalOpen: value });
  };

  renderQuestionsContent = exam => {
    if (this.state.questionsViewScrolled) {
      const scrollTo = document.getElementById(String(exam.newQuestion));
      if (scrollTo) {
        scrollTo.scrollIntoView();
      }
    }
    if (exam.questions.length === 0) {
      return (
        <div className="no-questions-message">
          This exam has not questions yet. Press BUILD to start.
        </div>
      );
    } else {
      return exam.questions.map((question, index) => (
        <div key={`%${question.id}-${index}`} className="question-detail">
          <div id={question.id} className="detail-header">
            {`Question: ${question.id}`}
            {question.id !== exam.newQuestion ? (
              ' '
            ) : this.state.questionUpdateMessage ? (
              <sapn className="font-weight-bold ml-2 question-updated">
                {' '}
                Question Updated{' '}
              </sapn>
            ) : (
              <sapn className="font-weight-bold ml-2 question-updated question-updated-visible">
                {' '}
                Question Updated{' '}
              </sapn>
            )}
            <i
              className="fa fa-trash"
              onClick={() => this.openDeleteQuestionModal(question.id)}
            />
          </div>
          <div
            className="question-text"
            dangerouslySetInnerHTML={{
              __html: question.text,
            }}
          />
        </div>
      ));
    }
  };

  removeQuestion = (exam, questionToDelete) => {
    this.setState({ questionsViewScrolled: false });
    this.props.removeQuestion(exam, questionToDelete);
  };

  renderContent = () => {
    const {
      waiting,
      buildAllExamsWaiting,
      practiceExamInformation,
      selectedCycle,
      exam,
      progress,
      buildPracticeExam,
      buildPracticeExamWaiting,
      removeQuestion,
      removeQuestionWaiting,
      activateExam,
      activateExamWaiting,
    } = this.props;

    if (waiting) {
      return (
        <div className="practice-exams-content">
          <Spinner text="Loading" className="loading-data-gif" />
        </div>
      );
    } else if (buildAllExamsWaiting) {
      return (
        <div className="practice-exams-content">
          <Spinner
            text={`The Practice Exams are being created in the background. You can close this tab.`}
            className="loading-data-gif"
          />
        </div>
      );
    } else if (practiceExamInformation !== []) {
      const selectedRecord = practiceExamInformation.find(
        record => record.cycle.cycleId === selectedCycle,
      );

      let selectedExam = null;
      if (selectedRecord) {
        const exams = [
          selectedRecord.freePracticeExam,
          selectedRecord.paidPracticeExam,
          selectedRecord.bonusPracticeExam,
          selectedRecord.extraPracticeExam,
        ];
        selectedExam = exams.find(e => e.id === exam);
      }

      return (
        <div className="practice-exams-content">
          <Modal show={this.state.questionToDelete}>
            <div className="delete-question-modal-body">
              {!removeQuestionWaiting && this.state.questionToDelete && (
                <span>
                  Are you sure that you want to delete the following question:{' '}
                  {`${this.state.questionToDelete}?`}
                </span>
              )}
              {removeQuestionWaiting && (
                <span>
                  <Spinner
                    text={`Removing question: ${this.state.questionToDelete}`}
                  />
                </span>
              )}
              <div className="modal-buttons">
                <button
                  className="btn btn-danger delete-question-btn"
                  onClick={() =>
                    this.removeQuestion(exam, this.state.questionToDelete)
                  }
                >
                  DELETE
                </button>
                <button
                  className="btn btn-success delete-question-btn-cancel"
                  onClick={() => this.openDeleteQuestionModal(null)}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </Modal>
          <Modal show={this.state.activateModalOpen}>
            <div className="delete-question-modal-body">
              {!activateExamWaiting && (
                <span>
                  Do you want to activate this exam? this action is
                  irreversible.
                </span>
              )}
              {activateExamWaiting && <Spinner />}
              <div className="modal-buttons">
                <button
                  className="btn btn-danger delete-question-btn"
                  onClick={() => activateExam(exam)}
                >
                  ACTIVATE
                </button>
                <button
                  className="btn btn-success delete-question-btn-cancel"
                  onClick={() => this.openActivateExamModal(false)}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </Modal>
          {selectedExam && (
            <div className="top-bar">
              {/* CYCLE SELECTOR */}
              <select
                className="form-control cycle-selector"
                value={selectedCycle}
                onChange={e => this.handleCycleChange(Number(e.target.value))}
              >
                {this.getCycleSelectorOptions(practiceExamInformation)}
              </select>
              {/* EXAM SELECTOR */}
              {this.renderExamsSelector(practiceExamInformation)}
              {/* BUILD BUTTON */}
              {selectedExam.questions.length === 0 && !selectedExam.enabled && (
                <button
                  className="practice-exams-button btn btn-primary"
                  onClick={() => buildPracticeExam(selectedExam.id)}
                >
                  BUILD
                </button>
              )}
              {selectedExam.questions.length > 0 && (
                <button
                  className="practice-exams-button btn btn-primary"
                  onClick={() => buildPracticeExam(selectedExam.id)}
                >
                  RE-BUILD
                </button>
              )}
              {/* ACTIVATE BUTTON */}
              {selectedExam.questions.length > 0 && !selectedExam.enabled && (
                <button
                  className="practice-exams-button btn btn-danger"
                  onClick={() => this.openActivateExamModal(true)}
                >
                  ACTIVATE
                </button>
              )}
            </div>
          )}
          <div className="questions-content">
            {selectedExam &&
              !buildPracticeExamWaiting &&
              this.renderQuestionsContent(selectedExam)}
            {buildPracticeExamWaiting && <Spinner />}
          </div>
        </div>
      );
    } else {
      return (
        <div className="practice-exams-content">
          NO EXAMS FOUND FOR RATING EXAM
        </div>
      );
    }
  };

  render() {
    return (
      <div className="practice-exams-component">
        {this.renderRatingExamsList()}
        {this.renderContent()}
      </div>
    );
  }
}

export class RateLine extends React.Component {
  state = {
    expanded: false,
  };

  getRatingExamNumber(rank) {
    switch (rank) {
      case 'E4':
        return '3';
      case 'E5':
        return '2';
      case 'E6':
        return '1';
      default:
        return 'C';
    }
  }

  componentWillReceiveProps = nextProps => {
    if (
      Number(nextProps.selectedRate) === Number(this.props.rate.id) &&
      !this.state.expanded
    ) {
      this.setState({ expanded: true });
    }
  };

  renderExpandIcon() {
    return (
      <div className="expand-icon-container">
        <i
          className={
            this.state.expanded
              ? 'fa fa-caret-down marginRight14'
              : 'fa fa-caret-right marginRight18'
          }
          aria-hidden="true"
        />
      </div>
    );
  }

  getDutyStateRowClass = (ds, rankId, rateId) => {
    const { selectedDutyState, selectedRank, selectedRate } = this.props;

    if (
      selectedDutyState === ds &&
      selectedRank === rankId &&
      rateId === selectedRate
    ) {
      return 'duty-state-row active-row';
    } else {
      return 'duty-state-row';
    }
  };

  getRow = rank => {
    const { rate, selectOption } = this.props;
    return (
      <div className="rank-row">
        <Row className={this.getDutyStateRowClass(dutyState[0], rank, rate.id)}>
          <Col md={4} className="center-text">
            {rate.shortname + this.getRatingExamNumber(rank)}
          </Col>
          <Col
            md={8}
            className="pointer"
            onClick={() => selectOption(rate.id, rank, dutyState[0])}
          >
            {dutyState[0]}
          </Col>
        </Row>
        <Row className={this.getDutyStateRowClass(dutyState[1], rank, rate.id)}>
          <Col md={4} />
          <Col
            md={8}
            className="pointer"
            onClick={() => selectOption(rate.id, rank, dutyState[1])}
          >
            {dutyState[1]}
          </Col>
        </Row>
        <Row className={this.getDutyStateRowClass(dutyState[2], rank, rate.id)}>
          <Col md={4} />
          <Col
            md={8}
            className="pointer"
            onClick={() => selectOption(rate.id, rank, dutyState[2])}
          >
            {dutyState[2]}
          </Col>
        </Row>
      </div>
    );
  };

  getExpandedContent = () => {
    return (
      <div className="expanded-content-container">
        {this.getRow(ranks[0])}
        {this.getRow(ranks[1])}
        {this.getRow(ranks[2])}
        {this.getRow(ranks[3])}
      </div>
    );
  };

  render() {
    const { rate } = this.props;
    return (
      <div className="group-list">
        <div
          className="group-row-hover"
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => this.setState({ expanded: !this.state.expanded })}
        >
          {this.renderExpandIcon()}
          <div className="rate-name">
            <span className="rate-name-label">
              {rate.shortname
                ? '(' + rate.shortname + ') ' + rate.name
                : rate.name}
            </span>
          </div>
        </div>
        {this.state.expanded && this.getExpandedContent()}
      </div>
    );
  }
}
