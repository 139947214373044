import './Header.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import DropDown from '../../../../commonComponents/DropDown/DropDown';
import ConfirmDeletion from '../../../../commonComponents/Modals/ConfirmDeletion/ConfirmDeletion';
import ImportCSV from '../../../../commonComponents/Modals/ImportCSV/ImportCSV';
import {
  allowRolesPermissions,
  downloadCSV,
  downloadXLSX,
  equalArray,
  hasDownloadCSV,
} from '../../../../utilities/functionalitiesForCSV';
import { isGptQuestionGenerator } from '../../../../utilities/isGptQuestionGenerator';
import { getAiTaskByChapterService } from '../../../../utilities/ServiceManager';
import ViewActivityContainer from '../../../Books/components/ViewActivityContainer';
import UploadInput from '../UploadInput/UploadInput';
import AIQuestionNotification from './components/AIQuestionNotification';
import { AI_TASK_STATUS } from './constants/constants';

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDownloadCSV: false,
      isDownloadXLSX: false,
      isDownloadXLS: false,
      aiQuestionGeneration: null,
      isLoadingAiQuestionGeneration: false,
    };
  }

  componentWillReceiveProps({ questions, questionsToDownload }) {
    if (
      !equalArray(this.props.questionsToDownload, questionsToDownload) &&
      this.state.isDownloadXLSX
    ) {
      downloadXLSX(
        questionsToDownload,
        'xlsx',
        this.props.reference ? this.props.reference.id : '',
      );
      this.setState({ isDownloadXLSX: false });
    }

    if (
      !equalArray(this.props.questionsToDownload, questionsToDownload) &&
      this.state.isDownloadXLS
    ) {
      downloadXLSX(
        questionsToDownload,
        'xls',
        this.props.reference ? this.props.reference.id : '',
      );
      this.setState({ isDownloadXLS: false });
    }
  }

  async componentDidUpdate(prevProps) {
    let hasGPTPermissions = false;

    if (this.props.user && this.props.user.roles) {
      hasGPTPermissions = !!(
        (Array.isArray(this.props.user.roles) &&
          this.props.user.roles.some(
            (role) => role?.name === 'Create GPT questions',
          )) ||
        this.props.user.roles?.name === 'Create GPT questions' ||
        this.props.user.isSuperAdmin
      );
    }

    if (
      this.props.chapter &&
      this.props.chapter.id &&
      !this.state.aiQuestionGeneration &&
      !this.state.isLoadingAiQuestionGeneration
    ) {
      this.setState({
        isLoadingAiQuestionGeneration: true,
      });
      const getAiQuestionGenerationStatus = hasGPTPermissions
        ? await getAiTaskByChapterService(this.props.chapter.id)
        : null;

      if (
        getAiQuestionGenerationStatus &&
        !getAiQuestionGenerationStatus.deletedAt
      ) {
        const { id, status, createdAt, deletedAt, executionTime } =
          getAiQuestionGenerationStatus;

        const getTimeElapsed = () => {
          if (status === AI_TASK_STATUS.IN_PROGRESS) {
            return Math.floor((Date.now() - createdAt) / 1000);
          }
          return Math.floor(executionTime);
        };

        this.setState({
          aiQuestionGeneration: {
            id,
            status,
            timeElapsed: getTimeElapsed(),
            deletedAt,
          },
          isLoadingAiQuestionGeneration: false,
        });
      } else {
        this.setState({
          aiQuestionGeneration: 'none',
          isLoadingAiQuestionGeneration: false,
        });
      }
    }
    if (prevProps.questionsCSV !== this.props.questionsCSV) {
      document.getElementById('questions-download-csv').click();
    }
  }

  componentWillUnmount() {
    this.props.clearQuestionsToDownload();
  }

  getCurrentURL = () => {
    const url = window.location.href;
    return url.split('/');
  };

  getSubtopics = (subtopics, topics, onDeleteSubtopic, onAddSubtopic, rate) => {
    if (rate && rate.id === 0) {
      return 'Subtopics are not displayed while accessing chapters from the All tab';
    }
    let subs = [];
    if (subtopics && topics) {
      subs = subtopics.map((subtopic, index) => {
        const topic = topics.find((topic) => subtopic.topic === topic.id);
        if (topic) {
          return (
            <Subtopic
              subtopic={subtopic}
              topic={topic}
              onDeleteSubtopic={onDeleteSubtopic}
              editPermission={this.props.editPermission}
              key={index}
            />
          );
        }
        return null;
      });
    }
    if (this.props.editPermission) {
      subs.push(
        <div className="PlusBox" onClick={onAddSubtopic} key={subs.length}>
          <i className="fa fa-plus fa-lg mt-1" />
        </div>,
      );
    }

    return subs;
  };

  openFileImport = () => {
    this.props.openModal({
      modalContent: (
        <ImportCSV
          onConfirm={(questions) => this.onImportQuestions(questions)}
          onCancel={this.props.closeModal}
          onShowNotification={this.props.onShowNotification}
        />
      ),
      modalClassName: 'modal-import-questions',
      className: '',
    });
  };

  onDelete = () => {
    this.props.openModal({
      modalTitle: 'Delete all questions',
      modalContent: (
        <ConfirmDeletion
          onSubmit={() =>
            this.props.deleteAllQuestions(
              this.props.chapter,
              this.props.assignment,
            )
          }
          text="You are about to delete all questions of this chapter"
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  onDeleteSeletedQuestions = () => {
    this.props.openModal({
      modalTitle: this.props.isShowDeletedQuestions
        ? 'Undelete Selected Questions'
        : 'Delete selected questions',
      modalContent: (
        <ConfirmDeletion
          onSubmit={() => {
            if (this.props.isShowDeletedQuestions) {
              this.props.restoreQuestion(
                this.props.questionsForDeleted,
                this.props.assignment ? this.props.assignment.id : null,
              );
              this.props.setSeletedQuestionsForDeleted('', false);
              // this.props.toggleDeletedQuestionsView();
            } else {
              this.props.deleteQuestion(
                this.props.questionsForDeleted,
                this.props.assignment ? this.props.assignment.id : null,
              );
              this.props.setSeletedQuestionsForDeleted('', false);
            }
          }}
          text={`You are about to ${
            this.props.isShowDeletedQuestions ? 'undelete' : 'delete'
          } selected questions of this chapter`}
          onCancel={this.props.closeModal}
          questionsSelected={this.props.questionsForDeleted}
          setSeletedQuestionsForDeleted={
            this.props.setSeletedQuestionsForDeleted
          }
          isShowDeletedQuestions={this.props.isShowDeletedQuestions}
          isDelete
        />
      ),
    });
  };

  getText = (text) => {
    if (!text || typeof text !== 'string') {
      return '';
    }
    return text;
  };

  onImportQuestions = (questions) => {
    // ToDo: handle all questions at once
    const assignment = this.props.assignment ? this.props.assignment.id : null;
    let answers = [];

    const batchQuestions = questions.map((questionToUpdate, index) => {
      // Mode rules
      // flashmode is 1 and flashcardonly is 0 => multipleOptionMode = true;
      // flashmode is 1 and flashcardonly is 1 => multipleOptionMode = false;
      // flashmode is 0 and flashcardonly is 1 => multipleOptionMode = false;
      // flashmode is 0 and flashcardonly is 0 => multopleOptionMOde = true;
      let FLASH_CARD_MODE;
      if (
        questionToUpdate.hasOwnProperty('flashmode') &&
        questionToUpdate.hasOwnProperty('flashmodeonly')
      ) {
        FLASH_CARD_MODE =
          (parseInt(questionToUpdate.flashmode, 10) === 1 &&
            parseInt(questionToUpdate.flashmodeonly, 10) === 1) ||
          (parseInt(questionToUpdate.flashmode, 10) === 0 &&
            parseInt(questionToUpdate.flashmodeonly, 10) === 1);

        // Headings must match these heading names or it won't work and we require flashmode and flashmodeonly or the set fails and is rejected with an error noting the missing field
        // question,correctanswer,incorrectanswer_0,incorrectanswer_1,incorrectanswer_2,notes,softwarepage,flashmode,flashmodeonly,documentpage

        // Missing either the correct answer will fail
        if (!questionToUpdate.correctanswer) {
          return {
            question_id: index + 2,
            text: questionToUpdate.question,
            error: { correctanswer: 'missing' },
          };
        }
        answers = [
          {
            correct: true,
            order: 0,
            text: this.getText(questionToUpdate.correctanswer),
            id: 1,
          },
          {
            correct: false,
            order: 0,
            text: this.getText(questionToUpdate.incorrectanswer_0),
            id: 2,
          },
          {
            correct: false,
            order: 0,
            text: this.getText(questionToUpdate.incorrectanswer_1),
            id: 3,
          },
          {
            correct: false,
            order: 0,
            text: this.getText(questionToUpdate.incorrectanswer_2),
            id: 4,
          },
        ];

        const answerAllIndex = answers.findIndex(
          (el) => el.text.toLowerCase() === 'all of the above',
        );
        const answerFourIndex = answers.findIndex(
          (el) => el.order === answers.length,
        );
        if (answerAllIndex >= 0) answers[answerAllIndex].order = answers.length;
        if (answerFourIndex > 0)
          answers[answerFourIndex].order = answers[answerAllIndex].order;
      } else {
        const error = {};
        if (!questionToUpdate.hasOwnProperty('flashmode')) {
          error.flashmode = 'missing';
        }
        if (!questionToUpdate.hasOwnProperty('flashmodeonly')) {
          error.flashmodeonly = 'missing';
        }
        return {
          question_id: index + 2,
          text: questionToUpdate.question,
          error,
        };
      }
      questionToUpdate.answers = answers;
      questionToUpdate.text = questionToUpdate.question;
      questionToUpdate.notes = questionToUpdate.notes; // eslint-disable-line no-self-assign
      questionToUpdate.softwarePage = questionToUpdate.softwarepage;
      !questionToUpdate.softwarePage && delete questionToUpdate.softwarePage;
      questionToUpdate.softwarepage && delete questionToUpdate.softwarepage;
      questionToUpdate.chapter = this.props.chapter.id;
      questionToUpdate.flashQuestionText = questionToUpdate.text;
      questionToUpdate.flashMode = FLASH_CARD_MODE;
      questionToUpdate.multipleOptionMode = !FLASH_CARD_MODE;
      if (questionToUpdate.documentpage) {
        // we add a ' at the beginning of the document page to avoid excel to use this column as date
        questionToUpdate.documentPage = questionToUpdate.documentpage
          .toString()
          .startsWith("'")
          ? questionToUpdate.documentpage.slice(1)
          : questionToUpdate.documentpage;
        delete questionToUpdate.documentpage;
      }
      return questionToUpdate;
    });

    const finalBatchQuestions = batchQuestions.filter((question) => question);
    const errorBatchQuestions = batchQuestions.filter(
      (question) => question.error,
    );

    if (errorBatchQuestions.length > 0) {
      const errorMessage = errorBatchQuestions.map((error, index) => {
        const missingKey = Object.keys(error.error) || [];
        return (
          <p>
            Question {error.question_id} is missing {missingKey[0]}{' '}
          </p>
        );
      });
      this.props.onShowNotification(errorMessage);
    } else if (finalBatchQuestions.length !== batchQuestions.length) {
      alert(
        'Oops! at least one of the questions is missing a required field!!  Please review your spreadsheet for errors and try again.',
      );
    } else {
      this.props.onBatchCreateQuestions(finalBatchQuestions, assignment);
      this.props.closeModal();
    }
  };

  toggleDeletedSubmitQuestionsView = () => {
    const chapter = this.props.chapter;
    if (chapter) {
      this.props.loadQuestionsOfChapter(
        chapter.id,
        1,
        50,
        null,
        false,
        0,
        false,
        false,
        null,
        'viewDeletedByAdmin',
      );
    }
  };

  toggleSubmitedQuestionsView = () => {
    const chapter = this.props.chapter;
    if (chapter) {
      this.props.loadQuestionsOfChapter(
        chapter.id,
        1,
        50,
        null,
        false,
        0,
        false,
        false,
        null,
        'viewSubmitedQuestion',
      );
    }
  };

  togglePublishedSubmitQuestionsView = () => {
    const chapter = this.props.chapter;
    if (chapter) {
      this.props.loadQuestionsOfChapter(
        chapter.id,
        1,
        50,
        null,
        false,
        0,
        false,
        false,
        null,
        'viewPublishedSubmitedQuestion',
      );
    }
  };

  getChapterOptions = () => {
    const {
      permissions,
      generalRolesPermissions,
      toggleDeletedQuestionsView,
      isShowDeletedQuestions,
      editPermission,
    } = this.props;

    const options = [];
    if (
      this.props.reference &&
      this.props.reference.name !== 'SUBMIT QUESTIONS'
    ) {
      if (editPermission) {
        options.push(
          {
            name: 'Import Questions',
            event: this.openFileImport,
          },
          {
            name: 'Delete All Questions',
            event: this.onDelete,
          },
        );
      }
      options.push({
        name: isShowDeletedQuestions
          ? 'View Published Questions'
          : 'View Deleted Questions',
        event: toggleDeletedQuestionsView,
      });
      options.push({
        name: isShowDeletedQuestions
          ? 'Undelete Selected Questions'
          : 'Delete Selected Questions',
        event: this.onDeleteSeletedQuestions,
      });
      const permission = hasDownloadCSV(generalRolesPermissions);
      const rolesPerm = allowRolesPermissions(permissions);
      if (permission || rolesPerm) {
        options.push({
          name: 'View Chapter Activity',
          event: this.openViewChapterActivityModal,
        });
        options.push(
          {
            name: 'Download CSV',
            event: this.setQuestionsCSV,
          },
          {
            name: 'Download XLSX',
            event: this.setQuestionsXLSX,
          },
          {
            name: 'Download XLS',
            event: this.setQuestionsXLS,
          },
          {
            name: 'View Chapter Activity',
            event: this.openViewChapterActivityModal,
          },
        );
      }
    }
    if (editPermission) {
      options.push(
        {
          name: 'View Deleted by Admin',
          event: this.toggleDeletedSubmitQuestionsView,
        },
        {
          name: 'View Submitted Questions',
          event: this.toggleSubmitedQuestionsView,
        },
        {
          name: 'View Published Questions',
          event: this.togglePublishedSubmitQuestionsView,
        },
      );
    }

    return options;
  };

  openViewChapterActivityModal = () => {
    this.props.openModal({
      modalContent: (
        <ViewActivityContainer
          closeModal={this.props.closeModal}
          id={this.props.chapter.id}
          isChapter
        />
      ),
      modalClassName: 'ActivityModal',
    });
  };

  setQuestionsCSV = () => {
    const isInWheelHousePage = this.getCurrentURL().includes('wheelhouse');
    const isWritersPage = this.getCurrentURL().includes('writers');
    const isAssignmentsPage = this.getCurrentURL().includes('assignments');
    const isMyAssignmentsPage = this.getCurrentURL().includes('my-assignments');
    const isAssignment =
      isInWheelHousePage ||
      isWritersPage ||
      isAssignmentsPage ||
      isMyAssignmentsPage;
    const reference = this.props.reference;
    const chapter = this.props.chapter;
    const filteredReference = { ...reference };

    if (chapter && !isAssignment) {
      filteredReference.chapters = reference.chapters.filter(
        (c) => c.id === chapter.id,
      );
    }
    const idChapter = !isAssignment
      ? filteredReference.chapters[0].id
      : chapter.id;
    this.props.loadChapterQuestionsCSV(idChapter, true, isAssignment);
    this.setState({ isDownloadCSV: true });
  };

  setQuestionsXLSX = () => {
    const chapter = this.props.chapter;
    if (chapter) {
      // this.props.loadQuestionsOfChapter(chapter.id, 1, 1000, null, false, 0);
      this.props.getQuestionsForDownload(chapter.id);
      this.setState({ isDownloadXLSX: true });
    }
  };

  setQuestionsXLS = () => {
    const chapter = this.props.chapter;
    if (chapter) {
      this.props.loadQuestionsOfChapter(chapter.id, 1, 1000, null, false, 0);
      this.setState({ isDownloadXLS: true });
    }
  };

  getDisabledBar = (assignments) => {
    const assignment = assignments[0];
    if (assignment) {
      const writer = assignment.primaryWriter || {};
      return (
        <div className="DisabledChapterBar">
          This chapter has been assigned to {writer.name} {writer.lastName} and
          cannot be edited until published.
        </div>
      );
    }
  };

  onHeaderBackClick = () => {
    const { isWaiting, rate, historyObject, isQual, onHeaderBack } = this.props;
    this.props.setCurrentPage(0);

    if (!isWaiting) {
      onHeaderBack({
        selectedRate: rate || { id: 0 },
        historyObject,
        isQual,
      });
    }
  };

  render() {
    const {
      reference,
      chapter,
      subtopics,
      topics,
      onDeleteSubtopic,
      onShowDefinitions,
      onAddSubtopics,
      selectedView,
      deletePdfChapter,
      updateQuestionPdfProcessing,
      questionPdfProcessing,
      updateChapter,
      rate,
      assignment,
    } = this.props;

    const showDisabledBar =
      !assignment &&
      chapter &&
      chapter.assignments &&
      chapter.assignments.length;
    const chapterOptions = showDisabledBar ? [] : this.getChapterOptions();

    const canCreateGPTQuestions = isGptQuestionGenerator(
      this.props.user.roles,
      this.props.user.isSuperAdmin,
    );

    return (
      <Row className={showDisabledBar ? 'HeaderRow noPaddingTop' : 'HeaderRow'}>
        {showDisabledBar ? this.getDisabledBar(chapter.assignments) : ''}
        <Col md={12} className="header__title">
          <div className="header__left">
            <span
              className="fa fa-arrow-left fa-lg mt-1 pointer"
              data-test="back-button"
              onClick={this.onHeaderBackClick}
            />
            <span className="ReferenceName">
              {assignment && assignment.chapter && assignment.chapter.reference
                ? assignment.chapter.reference.name
                : reference && reference.name
                  ? reference.name
                  : ''}{' '}
              -{' '}
              {chapter &&
                chapter.name +
                  (chapter.description
                    ? ` - ${chapter.description}`
                    : reference.description
                      ? ` - ${reference.description}`
                      : '')}
            </span>
          </div>
          <div className="header__right">
            {this.state.aiQuestionGeneration &&
            !this.state.aiQuestionGeneration.deletedAt &&
            canCreateGPTQuestions ? (
              <AIQuestionNotification
                aiQuestionGeneration={this.state.aiQuestionGeneration}
                deleteNotification={this.props.deleteNotification}
              />
            ) : null}
            {chapterOptions.length > 0 ? (
              <div style={{ marginLeft: 'auto' }}>
                <DropDown
                  data-test="book-options-dropdown"
                  options={chapterOptions}
                  shouldCloseAfter
                  containerStyle={{ float: 'right', marginLeft: 'auto' }}
                  style={{
                    position: 'absolute',
                    right: '5px',
                    zIndex: '5',
                    marginTop: '10px',
                  }}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </Col>
        <Col md={12}>
          {!this.props.isQual &&
            topics &&
            topics.length > 0 &&
            this.getSubtopics(
              subtopics,
              topics,
              onDeleteSubtopic,
              onAddSubtopics,
              rate,
            )}
          {chapter && chapter.id && (
            <UploadInput
              reference={reference}
              imageUrl={chapter.pdfUrl}
              chapter={chapter}
              deletePdfChapter={deletePdfChapter}
              updateQuestionPdfProcessing={updateQuestionPdfProcessing}
              questionPdfProcessing={questionPdfProcessing}
              updateChapter={updateChapter}
              isWaiting={this.props.isWaiting}
              editPermission={this.props.editPermission}
              isPrivatePDF
              urlPdfPrivate={this.props.urlPdfPrivate}
              getPrivateUrl={this.props.getUrlPdf}
            />
          )}
          {selectedView !== 'gridView' && (
            <div onClick={onShowDefinitions} className="Definitions">
              Definitions
            </div>
          )}
        </Col>
        <div style={{ display: 'none' }}>
          <CSVLink
            id="questions-download-csv"
            filename={chapter && chapter.name}
            data={this.props.questionsCSV ? this.props.questionsCSV : ''}
            headers={[
              { label: 'questionID', key: 'questionID' },
              { label: 'AI_question', key: 'AI_question' },
              { label: 'AI_question_change', key: 'AI_question_change' },
              { label: 'AI_answer', key: 'AI_answer' },
              { label: 'AI_answer_change', key: 'AI_answer_change' },
              { label: 'question', key: 'question' },
              { label: 'correctanswer', key: 'correctanswer' },
              { label: 'incorrectanswer_0', key: 'incorrectanswer_0' },
              { label: 'incorrectanswer_1', key: 'incorrectanswer_1' },
              { label: 'incorrectanswer_2', key: 'incorrectanswer_2' },
              { label: 'notes', key: 'notes' },
              { label: 'softwarepage', key: 'softwarepage' },
              { label: 'flashmode', key: 'flashmode' },
              { label: 'flashmodeonly', key: 'flashmodeonly' },
              { label: 'documentpage', key: 'documentpage' },
              { label: 'deleted', key: 'deleted' },
              { label: 'link', key: 'link' },
              { label: 'AI_prompt', key: 'AI_prompt' },
            ]}
          />
        </div>
      </Row>
    );
  }
}

Header.propTypes = {
  reference: PropTypes.object,
  chapter: PropTypes.object,
  subtopics: PropTypes.array,
  onAddSubtopics: PropTypes.func,
  onShowDefinitions: PropTypes.func,
  onBack: PropTypes.func,
  onDeleteSubtopic: PropTypes.func,
};

export class Subtopic extends React.Component {
  render() {
    const { topic, subtopic, onDeleteSubtopic } = this.props;
    return (
      <div className="SubtopicBox">
        {`${topic.name} >> ${subtopic.name}`}{' '}
        {this.props.editPermission && (
          <i
            className="fa fa-times fa-lg mt-1"
            onClick={() => onDeleteSubtopic(subtopic)}
          />
        )}
      </div>
    );
  }
}
