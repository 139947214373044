import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import pure from 'recompose/pure';
import { Field } from 'redux-form';

import Input from '../../../commonComponents/Input/Input';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import Errors from '../../Notifications/Errors';
import Messages from '../../Notifications/Messages';

function LoginStateless({
  handleSubmit,
  submit,
  requesting,
  errors,
  messages,
}) {
  return (
    <div className="row justify-content-center">
      <div className="col-md-8">
        <div className="card-group mb-0">
          <div className="iheader">
            <center>
              <img
                className="logo-bj"
                src="/img/header.png"
                alt="bluejacketeer"
                loading="lazy"
              />
            </center>
          </div>
          <div className="card p-4">
            <div className="card-block">
              <form className="widget-form" onSubmit={handleSubmit(submit)}>
                <h1>Login</h1>
                <p className="text-muted">Sign In to your account</p>
                <Field
                  name="email"
                  component={Input}
                  type="email"
                  placeholder="Email"
                />
                <Field
                  name="password"
                  type="password"
                  component={Input}
                  placeholder="Password"
                />
                <div className="row">
                  <div className="col-6">
                    <button
                      className="btn btn-login px-4"
                      action="submit"
                      data-test="login-button"
                    >
                      Login
                    </button>
                  </div>
                  <div className="col-6 text-right">
                    <Link
                      className="forgot-password-link login-footer-link"
                      to="/forgot-password"
                    >
                      <small>Forgot Your Password?</small>
                    </Link>
                  </div>
                </div>
              </form>
              <div className="auth-messages">
                {/* As in the signup, we're just using the message and error helpers */}
                {!requesting && errors && !!errors.length && (
                  <Errors message="Failure to login due to:" errors={errors} />
                )}
                {!requesting && !!messages.length && (
                  <Messages messages={messages} />
                )}
                {requesting && <Spinner />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

LoginStateless.propTypes = {
  handleSubmit: PropTypes.func,
  loginRequest: PropTypes.func,
  requesting: PropTypes.bool,
  successful: PropTypes.bool,
  messages: PropTypes.array,
  errors: PropTypes.array,
};

export default pure(LoginStateless);
