import * as type from './actionTypesSagas';

export const loadReference = (id) => ({
  type: type.LOAD_REFERENCE,
  id,
});

export const loadQuestionsOfChapter = (
  id,
  page,
  rows,
  assignment,
  isInfiniteScroll,
  skip,
  isViewDeletedQuestions,
  loadAll = false,
  downloadCsv,
  deletedByAdmin = null,
) => ({
  type: type.LOAD_QUESTIONS,
  id,
  page,
  rows,
  assignment,
  isInfiniteScroll,
  skip,
  isViewDeletedQuestions,
  loadAll,
  downloadCsv,
  deletedByAdmin,
});

export const getQuestionsForDownload = (chapter) => ({
  type: type.GET_QUESTIONS_FOR_DOWNLOAD,
  chapter,
});

export const amountOfQuestionsInChapter = (chapter) => ({
  type: type.AMOUNT_OF_QUESTIONS_IN_CHAPTER,
  chapter,
});

export const editQuestion = (
  question,
  assignment,
  showWorkInProgressAlert,
) => ({
  type: type.EDIT_QUESTION,
  question,
  assignment,
  showWorkInProgressAlert,
});

export const createQuestion = (question, assignment) => ({
  type: type.CREATE_QUESTION,
  question,
  assignment,
});

export const batchCreateQuestions = (questions, assignment) => ({
  type: type.BATCH_CREATE_QUESTIONS,
  questions,
  assignment,
});

export const changeStatusSagas = (question, assignment) => ({
  type: type.CHANGE_STATUS,
  question,
  assignment,
});

export const loadAllQuestions = (id, assignment) => ({
  type: type.LOAD_ALL_QUESTIONS,
  id,
  assignment,
});

export const editQuestionSagas = (
  question,
  assignment,
  showWorkInProgressAlert,
  isEditPresentationIndex,
  isCreatingAiQuestion,
) => ({
  type: type.EDIT_QUESTION_SAGAS,
  question,
  assignment,
  showWorkInProgressAlert,
  isEditPresentationIndex,
  isCreatingAiQuestion,
});

export const deleteQuestionSagas = (question, assignment) => ({
  type: type.DELETE_QUESTION_SAGAS,
  question,
  assignment,
});

export const onDeletePdfSagas = (question, assignment) => ({
  type: type.DELETE_PDF_SAGAS,
  question,
  assignment,
});

export const loadChapter = (chapter) => ({
  type: type.LOAD_CHAPTER_SAGAS,
  chapter,
});

export const loadPublishedQuestion = (question) => ({
  type: type.LOAD_PUBLISHED_QUESTION,
  question,
});

export const restoreQuestionSagas = (question, assignment) => ({
  type: type.RESTORE_QUESTION_SAGAS,
  question,
  assignment,
});

export const deleteAllChaptersQuestions = (chapter, assignment) => ({
  type: type.DELETE_ALL_CHAPTER_QUESTIONS_SAGAS,
  chapter,
  assignment,
});

export const searchQuestionIdSagas = (id, chapter) => ({
  type: type.SEARCH_QUESTION_IN_CHAPTER_BY_ID,
  id,
  chapter,
});

export const checkQuestionSpelling = (id) => ({
  type: type.CHECK_QUESTION_SPELLING,
  id,
});
export const createUserCustomPrompt = (customPrompt, chapterId) => ({
  type: type.CREATE_USER_CUSTOM_PROMPT,
  customPrompt,
  chapterId,
});

export const getUserCustomPrompt = (chapterId) => ({
  type: type.GET_USER_CUSTOM_PROMPT,
  chapterId,
});

export const getQuestionHistory = (questionId) => ({
  type: type.GET_QUESTION_HISTORY,
  questionId,
});

export const ImproveQuestionWithAi = (questionId, chapterId) => ({
  type: type.IMPROVE_QUESTION_WITH_AI,
  questionId,
  chapterId,
});

export const updateIncludeNotesInSuggestion = (questionId, value) => ({
  type: type.UPDATE_INCLUDE_NOTES_IN_SUGGESTION,
  questionId,
  value,
});
