import React from 'react';
import Button from 'react-bootstrap-button-loader';
import CheckBoxList from '../../../commonComponents/CheckBoxList/CheckBoxList';
import { SimpleInput } from '../../../commonComponents/Input/Input';
import './MoveRatesModal.css';

class MoveRatesModal extends React.Component {
  state = {
    rateSearch: '',
    selectAllRates: false,
    ratesChecked: {},
    notShow: {},
  };

  componentDidMount() {
    const notShow = this.props.notShow.reduce(
      (o, key) => ({ ...o, [key]: true }),
      {},
    );
    this.setState({ notShow: notShow });
  }

  ratesCheckboxes = () => {
    let ratesFormatted = [];
    const filter = new RegExp(this.state.rateSearch, 'i');
    if (this.props.rates && this.props.rates.length) {
      ratesFormatted = this.props.rates
        .filter(r => r.shortname)
        .map(r => {
          return {
            id: r.id,
            name: `${r.shortname} - ${r.name}`,
            checked: this.state.ratesChecked[r.id],
          };
        })
        .filter(r =>
          this.state.rateSearch !== '' ? r.name.match(filter) : true,
        )
        .filter(r => !this.state.notShow[r.id]);
    }

    return (
      this.props.rates &&
      this.props.rates.length && (
        <div>
          <span>Search Rate</span>
          <SimpleInput
            value={this.state.rateSearch}
            onChange={e =>
              this.setState({
                rateSearch: e.target.value,
              })
            }
          />
          <div className="checkbox-list">
            {ratesFormatted.length ? (
              <CheckBoxList
                items={ratesFormatted}
                onChange={e =>
                  this.setState(prevState => ({
                    ratesChecked: {
                      ...prevState.ratesChecked,
                      [e]: !prevState.ratesChecked[e],
                    },
                    selectAllRates: false,
                  }))
                }
              />
            ) : (
              <div>No Matching Rates</div>
            )}
          </div>
          <div className="select-all-rates">
            <label className="label d-flex">
              <input
                className="check-item"
                type="checkbox"
                name="selectAllRates"
                checked={this.state.selectAllRates}
                onChange={() => this.handleSelectAllRates()}
              />
              Select all rates
            </label>
          </div>
        </div>
      )
    );
  };

  handleSelectAllRates = () => {
    const selectAllRates = !this.state.selectAllRates;
    this.setState({ selectAllRates: selectAllRates });
    const selectedRates = {};
    this.props.rates.forEach(r => {
      selectedRates[r.id] = selectAllRates;
    });
    this.setState({ ratesChecked: selectedRates });
  };

  render() {
    return (
      <div className="rates-modal">
        {this.ratesCheckboxes()}
        <div className="bottom-buttons">
          <Button
            className="btn btn-secondary"
            onClick={() => {
              this.setState({ ratesChecked: {} });
              this.props.closeModal();
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-success"
            type="submit"
            loading={this.props.isWaiting}
            onClick={() => this.props.handleMoveRates(this.state.ratesChecked)}
          >
            Move rates
          </Button>
        </div>
      </div>
    );
  }
}
export default MoveRatesModal;
