import Notifications from 'react-notification-system-redux';
import { browserHistory } from 'react-router';
import { all, call, cancelled, put, takeEvery } from 'redux-saga/effects';

import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import { resetPassword } from '../../utilities/ServiceManager';
import { RESET_PASSWORD_SUCCESS } from '../Admins/actionTypes';
import {
  REQUEST_RESPONSE,
  RESET_PASSWORD_ENDED,
  RESET_PASSWORD_ERROR_USER,
} from './actionTypes';
import {
  RESET_PASSWORD_FORGOT_SAGAS,
  RESET_PASSWORD_SAGAS,
} from './actionTypesSagas';

function* resetPasswordFlow(action) {
  let response;
  try {
    const newPwd = action.payload.user;
    newPwd.password = action.payload.password;
    newPwd.confirmPassword = action.payload.confirmPassword;
    if (!newPwd.password || !newPwd.confirmPassword) {
      throw new Error('Both fields are required');
    }
    response = yield call(resetPassword, newPwd);
    if (response) {
      yield put({ type: RESET_PASSWORD_ENDED, response });
      yield put({ type: RESET_PASSWORD_SUCCESS, response });
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message: 'Reset password completed successfully',
            autoDismiss: 1,
          },
          'success',
        ),
      );
    }
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  } finally {
    if (yield cancelled()) {
      browserHistory.replace('/');
    }
  }

  return response;
}

function* resetPasswordForgotFlow(action) {
  let response;
  try {
    const newPwd = action.payload;
    if (!newPwd.password || !newPwd.confirmPassword) {
      throw new Error('Both fields are required');
    }

    response = yield call(resetPassword, newPwd);
    if (response) {
      yield put({ type: RESET_PASSWORD_ENDED, response });
      yield put({ type: RESET_PASSWORD_SUCCESS, response });
      yield put({ type: REQUEST_RESPONSE, response });
      if (!response.data.use2FA) {
        yield put(
          Notifications.show(
            {
              title: 'Done!',
              message: 'Reset password completed successfully',
              autoDismiss: 1,
            },
            'success',
          ),
        );
      }
    } else if (response === undefined) {
      const error = false;
      yield put({ type: RESET_PASSWORD_ERROR_USER, error });
    }
  } catch (error) {
    const message = getRequestErrorMessage(error);
    yield put(Notifications.show({ title: 'Error', message }, 'error'));
  } finally {
    if (yield cancelled()) {
      browserHistory.replace('/');
    }
  }

  return response;
}

export function* resetPasswordWatcher() {
  yield takeEvery(RESET_PASSWORD_SAGAS, resetPasswordFlow);
}

export function* resetPasswordForgotWatcher() {
  yield takeEvery(RESET_PASSWORD_FORGOT_SAGAS, resetPasswordForgotFlow);
}

export default function* sagas() {
  yield all([resetPasswordWatcher(), resetPasswordForgotWatcher()]);
}
