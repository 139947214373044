import { connect } from 'react-redux';

import {
  bookSelectAction,
  rateSelectAction,
} from '../../Bibliographies/actions';
import {
  duplicateChapterAction,
  loadReferences,
} from '../../Bibliographies/actionsSagas';
import {
  getRateIdSelect,
  getReferenceIdSelect,
  getRerferenceDuplicateChapter,
  loadingReferences,
} from '../../Bibliographies/selectors';
import { getWaiting } from '../../Rates/selectors';
import { setSearchText } from '../actions';
import DuplicateChapterModal from './DuplicateChapterModal';

const mapStateToProps = (state, ownProps) => ({
  closeModal: ownProps.closeModal,
  id: ownProps.id,
  isChapter: ownProps.isChapter,
  books: getRerferenceDuplicateChapter(state),
  referenceIdSelect: getReferenceIdSelect(state),
  isWaiting: getWaiting(state),
  loadingReferences: loadingReferences(state),
  rateIdSelect: getRateIdSelect(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSearchText: (text) => {
    dispatch(setSearchText(text));
  },
  loadReferences: (
    id,
    type,
    search,
    page,
    isLoadingList = false,
    rate = 0,
    amountOfReferences,
    barFilter,
    duplicateChapter = false,
  ) =>
    dispatch(
      loadReferences(
        id,
        type,
        search,
        page,
        isLoadingList,
        rate,
        amountOfReferences,
        barFilter,
        duplicateChapter,
      ),
    ),
  duplicateChapter: (referenceId, chapterId, rateId) =>
    dispatch(duplicateChapterAction(referenceId, chapterId, rateId)),
  bookSelect: (referenceId) => dispatch(bookSelectAction(referenceId)),
  rateSelect: (rateId) => dispatch(rateSelectAction(rateId)),
});

const DuplicateChapterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DuplicateChapterModal);

export default DuplicateChapterContainer;
