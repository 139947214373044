import React from 'react';
import { Row } from 'react-bootstrap';

import StudyPlanListContainer from './StudyPlan/StudyPlanListContainer';
import StudyPlanContainer from './StudyPlan/StudyPlanContainer';

class TenWeekPage extends React.Component {
  state = {
    studyPlan: null,
  };

  render() {
    return (
      <Row style={{ height: '100%' }}>
        <StudyPlanListContainer
          setStudyPlan={studyPlan => this.setState({ studyPlan })}
          calledFromTenWeek={true}
        />
        <StudyPlanContainer />
      </Row>
    );
  }
}

export default TenWeekPage;
