import React from 'react';
import { SimpleInput } from '../../../../commonComponents/Input/Input';
import WYSIWYG from '../../../../commonComponents/WYSIWYG/WYSIWYG';
import './StudyPlanEmails.css';

class EmailItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: this.props.subject ? this.props.subject : '',
      body: {
        content: this.props.body ? this.props.body : '',
        updateEditorView: true,
      },
    };
  }

  onBodyChange = (value) => {
    let body = this.state.body;
    body.content = value;
    body.updateEditorView = false;
    this.setState({ body });
  };

  onBodyBlur = (name, content) => {
    let { studyPlan, email, updateEmail } = this.props;
    let body = this.state.body;
    body.content = content;
    body.updateEditorView = false;
    updateEmail(studyPlan, this.state.subject, content, email);
    this.setState({ body });
  };

  onSubjectChange = (e) => {
    this.setState({ subject: e.target.value });
  };

  onSubjectBlur = (e) => {
    let { studyPlan, email, updateEmail } = this.props;
    updateEmail(studyPlan, e.target.value, this.state.body.content, email);
    this.setState({ subject: e.target.value });
  };

  render() {
    let { week, introEmail, disabled } = this.props;
    return (
      <div>
        {!introEmail ? (
          <div>
            <span className="weekLabel">
              <strong>{'Week ' + week}</strong>
            </span>
          </div>
        ) : null}
        <div>
          <span className="availableVars">
            Available variables{' '}
            {introEmail ? (
              <strong>
                {'{USER_NAME}, {UNSUBSCRIBE_URL}, {EDIT_RATING_EXAM_URL}'}
              </strong>
            ) : (
              <strong>
                {'{CHAPTER_LINKS}, {USER_NAME}, {USER_LASTNAME},' +
                  '{RATE}, {RANK}, {DUTY_STATE}, {UNSUBSCRIBE_URL},' +
                  '{STUDY_PLAN_PDF}, {STUDENT_SITE}, {CURRENT_YEAR}, {EDIT_RATING_EXAM_URL}'}
              </strong>
            )}
          </span>
        </div>
        <span style={{ marginRight: '10px' }}>Subject</span>
        <SimpleInput
          type="text"
          value={this.state.subject}
          onBlur={(e) => {
            this.onSubjectBlur(e);
          }}
          onChange={(e) => {
            this.onSubjectChange(e);
          }}
          disabled={disabled}
        />
        <WYSIWYG
          onChange={this.onBodyChange}
          height={550}
          redBorder={this.props.redBorder}
          value={this.state.body.content}
          updateEditorView={this.state.body.updateEditorView}
          onBlur={this.onBodyBlur}
          disabled={disabled}
          showWarning={this.props.showWarning}
        />
      </div>
    );
  }
}

export default EmailItem;
