import React from 'react';
import { Col } from 'react-bootstrap';
import NumberUtils from '../../../../lib/NumberUtils';
import './StudyPlanList.css';
import CreateStudyPlan from '../../../../commonComponents/Modals/CreateStudyPlan/CreateStudyPlan';
import SubHeader from '../../../../commonComponents/SubHeader/SubHeader';
import InfiniteList from '../../../../commonComponents/InfiniteList/InfiniteList';

const listOptions = ['Study Plans', 'Users'];
export default class StudyPlanList extends React.Component {
  state = {
    search: '',
  };

  componentDidMount = () => {
    this.props.loadStudyPlans();
    this.props.loadStudyPlansUsers(0, this.state.search);
  };

  loadUsers = () => {
    if (!this.props.isLoadingUsers) {
      this.props.loadStudyPlansUsers(this.props.page, this.state.search);
    }
  };

  onSearch = e => {
    let search = `${e.target.value}`;
    this.setState({ search });
    this.debounce(() => this.props.loadStudyPlansUsers(0, search), 500);
  };

  debounce(func, wait = 500, immediate = false, ...theArgs) {
    let context = this,
      args = theArgs;
    let later = () => {
      this.timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !this.timeout;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  }

  onOpenCreateModal = () => {
    this.props.openModal({
      modalContent: (
        <CreateStudyPlan
          onSubmit={this.props.onCreateStudyPlan}
          onCancel={this.props.closeModal}
          title="Create Study Plan"
        />
      ),
    });
  };

  onPreview = () => {
    this.props.setSelectedStudyPlan(null);
    this.props.setSelectedStudyPlanUser(null);
    this.props.setPreviewPlans(true);
  };

  clearSearchInput = () => {
    this.setState({ search: '' });
    this.props.loadStudyPlansUsers(0, '');
  };

  getDisabeldOptions = () => {
    return [];
  };

  getStudyPlans = () => {
    let {
      selectedStudyPlan,
      setSelectedStudyPlan,
      studyPlans,
      setSelectedStudyPlanUser,
      setPreviewPlans,
    } = this.props;
    let isSearch = this.state.search !== '';
    let sortedPlans =
      studyPlans && studyPlans.data
        ? studyPlans.data.sort((a, b) => a.id - b.id)
        : studyPlans.data;
    let filteredStudyPlans = isSearch
      ? sortedPlans.filter(sc =>
          sc.title.toLowerCase().includes(this.state.search.toLowerCase()),
        )
      : sortedPlans;
    let studyPlanList = null;
    if (filteredStudyPlans && filteredStudyPlans.length) {
      studyPlanList = filteredStudyPlans.map(studyPlan => (
        <p className="row" key={NumberUtils.obtainUniqueKey()}>
          <div
            className={
              selectedStudyPlan && selectedStudyPlan.id === studyPlan.id
                ? 'rate-line active'
                : 'rate-line'
            }
            onClick={() => {
              setSelectedStudyPlan(studyPlan);
              setSelectedStudyPlanUser(null);
              setPreviewPlans(null);
            }}
          >
            <div className="description">{studyPlan.title}</div>
          </div>
        </p>
      ));
    } else {
      studyPlanList = (
        <p
          style={{ marginLeft: '20px', marginTop: '10px', fontWeight: 'bold' }}
        >
          No Study Plans
        </p>
      );
    }
    return studyPlanList;
  };

  getUsers = () => {
    let {
      selectedStudyPlanUser,
      setSelectedStudyPlanUser,
      studyPlansUsers,
      setSelectedStudyPlan,
      setPreviewPlans,
      isLoadingUsers,
    } = this.props;
    let filteredStudyPlansUsers = studyPlansUsers;
    let usersList = null;
    if (filteredStudyPlansUsers && filteredStudyPlansUsers.length) {
      usersList = (
        <InfiniteList onReachBottom={this.loadUsers} isLoading={isLoadingUsers}>
          {filteredStudyPlansUsers.map(studyPlanUser => (
            <p className="row" key={NumberUtils.obtainUniqueKey()}>
              <div
                className={
                  selectedStudyPlanUser &&
                  selectedStudyPlanUser.id === studyPlanUser.id
                    ? 'rate-line active'
                    : 'rate-line'
                }
                onClick={() => {
                  setSelectedStudyPlanUser(studyPlanUser);
                  setSelectedStudyPlan(null);
                  setPreviewPlans(null);
                }}
              >
                <div className="description">
                  {studyPlanUser.name +
                    ' ' +
                    studyPlanUser.lastName +
                    ' - ' +
                    studyPlanUser.email}
                </div>
              </div>
            </p>
          ))}
        </InfiniteList>
      );
    } else {
      usersList = (
        <p
          style={{ marginLeft: '20px', marginTop: '10px', fontWeight: 'bold' }}
        >
          No Study Plans Users
        </p>
      );
    }
    return usersList;
  };

  render() {
    let searchAction = this.onSearch;
    let plusAction = () => {
      this.onOpenCreateModal();
    };
    let previewAction = () => {
      this.onPreview();
    };
    let { user = {}, generalPermissions = [] } = this.props;
    const { calledFromTenWeek } = this.props;
    let canEdit = user.isSuperAdmin
      ? true
      : generalPermissions.find(r => r === 'Settings.EditTWSP');
    if (!canEdit) {
      plusAction = null;
    }
    return (
      <Col
        md={3}
        className="GroupList"
        style={{
          paddingLeft: '0px',
          paddingRight: '0px',
          minWidth: '400px',
        }}
      >
        <div className="rates-list-component tenWeekPage">
          <SubHeader
            options={listOptions}
            onOptionChange={this.props.onUpdateSelectedTenWeekListTab}
            activeOption={this.props.activeListTab}
            onSearch={searchAction}
            onPlusClick={plusAction}
            disabledOptions={this.getDisabeldOptions()}
            searchValue={this.state.search}
            clearSearchInput={this.clearSearchInput}
            onPreviewClick={previewAction}
            calledFromTenWeek={calledFromTenWeek ? calledFromTenWeek : false}
          />
          <div className="rates-list">
            {this.props.activeListTab === listOptions[0]
              ? this.getStudyPlans()
              : this.getUsers()}
          </div>
        </div>
      </Col>
    );
  }
}

export class SearchWithIcon extends React.Component {
  render() {
    return (
      <div className="searchInput">
        <input
          className="form-control"
          value={this.props.search}
          onChange={this.props.onSearch}
        />
        {this.props.search !== '' ? (
          <i
            onClick={this.props.onClearSearch}
            className="fa fa-close fa-lg fa-in-search"
          />
        ) : (
          <i className="fa fa-search fa-lg fa-in-search" />
        )}
      </div>
    );
  }
}
