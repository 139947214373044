import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import { getUser, getGeneralRolesPermissions } from '../../Login/selectors';
import CouponListContainer from './CouponList/CouponListContainer';
import CodeGroupListContainer from './CodeGroupList/CodeGroupListContainer';
import { getCodeGroups } from '../selectors';

class CouponsPage extends React.Component {
  state = {
    codeGroupList: null,
  };

  render() {
    let { user = {}, generalPermissions = [] } = this.props;
    let canEdit = user.isSuperAdmin
      ? true
      : generalPermissions.find(r => r === 'Settings.EditCoupons');
    return (
      <Row style={{ height: '100%' }}>
        <CouponListContainer
          setCodeGroupList={codeGroupList => this.setState({ codeGroupList })}
          canEdit={canEdit}
        />
        <CodeGroupListContainer
          onSelectAnalytics={analyticsCodeGroup =>
            this.setState({ analyticsCodeGroup })}
          setCodeGroupList={codeGroupList => this.setState({ codeGroupList })}
          canEdit={canEdit}
        />
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    codeGroups: getCodeGroups(state),
    user: getUser(state),
    generalPermissions: getGeneralRolesPermissions(state),
  };
};

export default connect(mapStateToProps, null)(CouponsPage);
