import './DailyQuiz.css';

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import DailyQuizTable from './components/DailyQuizTable';

function DailyQuiz({
  allDailyQuiz,
  getAllDailyQuizForToday,
  generateDailyQuizForToday,
}) {
  const [noDailyQuizList, setNoDailyQuizList] = useState([]);
  const [dailyQuizList, setDailyQuizList] = useState([]);

  useEffect(() => {
    getAllDailyQuizForToday();
  }, []);

  useEffect(() => {
    setNoDailyQuizList(
      allDailyQuiz.filter(
        (dailyQuiz) =>
          !dailyQuiz.questions || dailyQuiz.questions?.length === 0,
      ),
    );

    setDailyQuizList(
      allDailyQuiz.filter(
        (dailyQuiz) => dailyQuiz.questions && dailyQuiz.questions?.length > 0,
      ),
    );
  }, [allDailyQuiz]);

  return (
    <Col className="daily-quiz-container">
      <div
        className="generate-daily-button"
        onClick={() => {
          generateDailyQuizForToday();
        }}
      >
        Generate Daily Quiz for today
      </div>

      <Row className="rates-daily-quiz-container">
        {/* Rates without daily quiz */}
        <div className="rates-daily-quiz">
          <p className="title-text">
            Rating exams <span className="text-red">without</span> daily quiz
            for today:
          </p>
          {dailyQuizList.length === 0 && (
            <p className="text-red">
              No Rating Exam has a daily quiz for today!
            </p>
          )}
          {noDailyQuizList.length !== 0 ? (
            <DailyQuizTable dailyList={noDailyQuizList} />
          ) : (
            '-'
          )}
        </div>

        {/* Rates with daily quiz */}
        <div className="rates-daily-quiz">
          <p className="title-text">Rating exams with daily quiz for today:</p>
          {noDailyQuizList.length === 0 && (
            <p className="text-green">
              All Rating Exams have a Daily Quiz for today!
            </p>
          )}
          {dailyQuizList.length !== 0 ? (
            <DailyQuizTable dailyList={dailyQuizList} />
          ) : (
            '-'
          )}
        </div>
      </Row>
    </Col>
  );
}

export default DailyQuiz;
