import { SET_FILTERS } from '../Books/actionTypes';
import {
  AUTHY_VERIFICATION_REQUEST,
  LOGIN_REQUESTING,
  LOGOUT,
  LOGOUT_USER,
  LOGOUT_USER_SAGAS,
  RESEND_CODE_REQUEST,
  RESET_STATUS,
  SET_LOADING,
  SET_LOGIN_AS,
  SET_PERMISSIONS,
  UPDATE_PERMISSIONS,
} from './constants';

// In order to perform an action of type LOGIN_REQUESTING
// we need an email and password
const loginRequest = function loginRequest({ email, password }) {
  return {
    type: LOGIN_REQUESTING,
    email,
    password,
  };
};

export const logoutRequest = function logoutRequest() {
  return {
    type: LOGOUT,
  };
};

export const authyVerificationRequest = function authyVerificationRequest({
  code,
  email,
}) {
  return {
    type: AUTHY_VERIFICATION_REQUEST,
    code,
    email,
  };
};

export const resendCodeRequest = function resendCodeRequest(email) {
  return {
    type: RESEND_CODE_REQUEST,
    email,
  };
};

export const resetStatus = function resetStatus() {
  return {
    type: RESET_STATUS,
  };
};

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const logoutUserSagas = () => ({
  type: LOGOUT_USER_SAGAS,
});

export const setPermissions = (response) => ({
  type: UPDATE_PERMISSIONS,
  payload: response,
});

export const setFilters = (response) => ({
  type: SET_FILTERS,
  payload: response,
});

export const setLoading = (value) => ({
  type: SET_LOADING,
  payload: value,
});

export const setLoginAs = (value) => ({
  type: SET_LOGIN_AS,
  payload: value,
});

export const setPermissionsLoginAs = (value) => ({
  type: SET_PERMISSIONS,
  payload: value,
});
export default loginRequest;
