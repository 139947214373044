import { useState } from 'react';

import QuestionItemInput from './QuestionItemInput';

function IncorrectQuestionsList({
  isEditable,
  handleInputChange,
  incorrectAnswers,
}) {
  const filteredIncorrectAnswers = incorrectAnswers.filter(
    (item) => item !== '',
  );

  const incorrectAnswersForInputs =
    filteredIncorrectAnswers.length > 0
      ? filteredIncorrectAnswers.map((item, index) => ({
          name: `incorrectAnswer${index + 1}`,
          value: item,
        }))
      : [
          {
            name: 'incorrectAnswer1',
            value: incorrectAnswers[0],
          },
        ];

  const [inputs, setInputs] = useState(incorrectAnswersForInputs);

  const handleAddInput = () => {
    if (inputs.length < 3) {
      setInputs((prev) => [
        ...prev,
        {
          name: `incorrectAnswer${inputs.length + 1}`,
          value: '',
        },
      ]);
    }
  };

  const isLastIndex = (index) => index === inputs.length - 1 && index !== 2;

  return (
    <>
      {inputs.map((item, index) => (
        <li key={item.name}>
          <QuestionItemInput
            isEditable={isEditable}
            name={item.name}
            value={incorrectAnswers[index]}
            disabled={!isEditable}
            onChange={handleInputChange}
            hasAddButton={isLastIndex(index)}
            handleAddInput={handleAddInput}
          />
        </li>
      ))}
    </>
  );
}

export default IncorrectQuestionsList;
