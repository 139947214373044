// Insights/actionTypesSagas.js
import * as t from './actionTypesSagas';

export const resetPasswordActionSagas = payload => ({
  type: t.RESET_PASSWORD_SAGAS,
  payload,
});

export const resetPasswordForgotActionSagas = payload => ({
  type: t.RESET_PASSWORD_FORGOT_SAGAS,
  payload,
});

export const resetPassword = (password, confirmPassword, token) => ({
  type: t.RESET_PASSWORD,
  password,
  confirmPassword,
  token,
});
