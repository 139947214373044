import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'reports');
}

export const getBibliographyCSVJsonReport = (state) =>
  getState(state).get('bibliographyCSVJsonReport');
export const getTopicsCSVJsonReport = (state) =>
  getState(state).get('topicsCSVJsonReport');
export const getProfileSheetCsv = (state) =>
  getState(state).get('profileSheetCsv');
export const getBibliographyReportIsLoading = (state) =>
  getState(state).get('isLoading');
export const getTopicsReportIsLoading = (state) =>
  getState(state).get('isLoading_topics');
export const getProfileSheetIsLoading = (state) =>
  getState(state).get('isLoading');
export const getUsersReportIsLoading = (state) =>
  getState(state).get('isLoading');
export const getBibliographyReportError = (state) =>
  getState(state).get('errorMessage');
export const getTopicsReportError = (state) =>
  getState(state).get('errorMessage_topics');
export const getUsersCountData = (state) => getState(state).get('usersCount');
export const getGraph = (state) => getState(state).get('setGraph');
export const values = (state) => getState(state).get('values');
export const count = (state) => getState(state).get('count');
export const lastCustomer = (state) => getState(state).get('lastCustomer');
export const finish = (state) => getState(state).get('finish');
export const allRates = (state) => getState(state).get('allRates');
export const historicalReportError = (state) =>
  getState(state).get('historicalReportError');
export const getCSVData = (state) => getState(state).get('CSVData');
export const hasMore = (state) => getState(state).get('hasMoreSubscription');
export const lastCustomerSubscription = (state) =>
  getState(state).get('lastCustomerSubscription');
export const getCompletionStatus = (state) =>
  getState(state).get('completionStatus');
export const getaskingFor = (state) => getState(state).get('askingFor');
export const getActiveSubscriptionsError = (state) =>
  getState(state).get('activeSubscriptionError');
export const getLastCustomerMaster = (state) =>
  getState(state).get('lastCustomerMaster');
export const getMasterReportCSVData = (state) =>
  getState(state).get('masterReportData');
export const getMasterReportHasMore = (state) =>
  getState(state).get('masterReportHasMore');
export const getAPIError = (state) => getState(state).get('masterReportError');
export const getCsvAvailability = (state) =>
  getState(state).get('csvDataAvailably');
export const getAutomaticMasterReportData = (state) =>
  getState(state).get('automaticMasterReportData');
export const getAutomaticSubscriptionsReport = (state) =>
  getState(state).get('automaticSubscriptionsReport');
export const getAutomaticMasterReportCreationDate = (state) =>
  getState(state).get('automaticMasterReportCreationDate');
export const getLatestCreationDate = (state) =>
  getState(state).get('latestCsvCreationDate');
export const getLatestCsvCreationDateSubscriptions = (state) =>
  getState(state).get('latestCsvCreationDateSubscriptions');
export const getCsvDataSubsActive = (state) =>
  getState(state).get('csvDataSubsActive');
export const getfavoriteQuestionsReportCSVJson = (state) =>
  getState(state).get('favoriteQuestionsReportCSVJson');
export const getFavoriteQuestionsReportIsLoading = (state) =>
  getState(state).get('favoriteQuestionsReportIsLoading');
export const getFavoriteQuestionsReportError = (state) =>
  getState(state).get('favoriteQuestionsReportErrorMessage');
export const getNumberOfQuestionsEnabledPerRatingExamReportCSVData = (state) =>
  getState(state).get('numberOfQuestionsPerRatingExamReportData');
export const getNumberOfQuestionsEnabledPerRatingExamReportHasMore = (state) =>
  getState(state).get('numberOfQuestionsPerRatingExamReportHasMore');
export const getLastRateToUseNumberOfQuestionsEnabledPerRatingExamReport = (
  state,
) => getState(state).get('nextRateToUse');
export const getOverallUniqueNumberOfQuestionsEnabledPerRatingExamReport = (
  state,
) => getState(state).get('numberOfQuestionsPerRatingExamReportOverallUnique');
export const getAPIErrorNumberOfQuestionsEnabledPerRatingExamReport = (state) =>
  getState(state).get('numberOfQuestionsPerRatingExamReportApiError');
export const getUserSubmittedQuestionsReportCSVJson = (state) =>
  getState(state).get('userSubmittedQuestionsReportCSVJson');
export const getIsUserSubmittedQuestionsReportLoading = (state) =>
  getState(state).get('isUserSubmittedQuestionsReportLoading');
export const getUserSubmittedQuestionsReportError = (state) =>
  getState(state).get('userSubmittedQuestionsReportError');
export const getlastCustomerSubscriptionIntercomActiveUsersReport = (state) =>
  getState(state).get('lastCustomerSubscriptionIntercom');
export const getIntercomActiveUsersReportHasMore = (state) =>
  getState(state).get('intercomActiveUsersHasMore');
export const getIntercomActiveUsersReportCSVData = (state) =>
  getState(state).get('intercomActiveUsersData');
export const getAPIErrorIntercomActiveUsersReport = (state) =>
  getState(state).get('intercomActiveUsersApiError');
export const getCountFavoriteReport = (state) =>
  getState(state).get('countFavoriteReport');
export const getLoadingCounterReport = (state) =>
  getState(state).get('loadingCounterReport');
export const getCsvFavoriteQuestionReport = (state) =>
  getState(state).get('csvFavoriteQuestionReport');
export const getArrayIdsFavoriteReport = (state) =>
  getState(state).get('arrayIdsFavoriteReport');
export const getLastRateQuestionsReports = (state) =>
  getState(state).get('lastRateQuestionsReports');
