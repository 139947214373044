import './Admins.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import AdminProfile from '../../../commonComponents/AdminProfile/AdminProfile';
import { getUser } from '../../Login/selectors';
import {
  resetPasswordUserActionSagas,
  updatePhoneNumberActionSagas,
} from '../actionsSagas';
import Roles from './Roles/Roles';

class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
    };
  }

  render() {
    return (
      <div className="usersSectionContainer myAccountContainer">
        <div className="MyAccountTitle">
          {' '}
          My Account - {this.props.user.name}{' '}
          {this.props.user.lastName ? this.props.user.lastName : ''}
        </div>
        <Row className="containerRow height-90">
          <Col xs={3} md={3} lg={3} className="my-account-col">
            <div className="userProfileContainer">
              <AdminProfile
                updateUser={this.updateUser}
                onUpdatePhoneNumber={(fields) =>
                  this.props.onUpdatePhoneNumber(this.props.user.id, fields)
                }
                true
                profile={
                  this.props.user || {
                    name: this.props.user.name,
                    lastName: this.props.user.lastname,
                  }
                }
                changeRole={() => {}}
                onResetPassword={(id, fields) =>
                  this.props.resetPassword(id, fields)
                }
                onDelete={() => {}}
                onToggleActive={() => {}}
                rates={[]}
                updateAdminFile={() => {}}
                loggedUser={this.props.user}
                generalPermissions={[]}
                loginAs={() => {}}
                myAccount
              />
            </div>
          </Col>
          <Col xs={3} md={3} lg={3} className="my-account-col">
            <Roles
              user={this.props.user}
              roles={this.props.user.roles}
              allRoles={this.state.roles}
              updateAdmin={() => {}}
              loadRates={() => {}}
              rates={this.props.rates}
              loadQuals={() => {}}
              quals={this.props.quals}
              onUpdateAdminGroups={() => {}}
              myAccount
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (id, passwordFields) => {
    dispatch(resetPasswordUserActionSagas(id, passwordFields));
  },
  onUpdatePhoneNumber: (id, fields) => {
    dispatch(updatePhoneNumberActionSagas(id, fields));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
