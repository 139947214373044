import * as t from './actionTypesSagas';

export const getAllRolesActionSagas = () => ({
  type: t.GET_ALL_ROLES_SAGAS,
});

export const getPagesActionSagas = () => ({
  type: t.GET_PAGES_SAGAS,
});

export const getActionsActionSagas = () => ({
  type: t.GET_WORKFLOW_ACTIONS_SAGAS,
});

export const createRoleActionSagas = role => ({
  type: t.CREATE_ROLE_ACTIONS_SAGAS,
  role,
});

export const editRoleActionSagas = role => ({
  type: t.EDIT_ROLE_ACTIONS_SAGAS,
  role,
});

export const deleteRoleActionSagas = role => ({
  type: t.DELETE_ROLE_ACTIONS_SAGAS,
  role,
});

export const getPageRolesActionSagas = page => ({
  type: t.GET_PAGE_ROLES_SAGAS,
  page,
});
