import * as type from './actionTypes';

export const updateFilterAction = filter => ({
  type: type.UPDATE_FILTER,
  filter,
});

export const updateDutyState = dutyState => ({
  type: type.UPDATE_DUTYSTATE_REFERENCES,
  dutyState,
});

export const updateRank = rank => ({
  type: type.UPDATE_RANK_REFERENCES,
  rank,
});
