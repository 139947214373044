import React from 'react';

export default class RoleRow extends React.Component {
  render() {
    let { role, selected, toggleNewRole } = this.props;
    return (
      <div
        onClick={() => {
          toggleNewRole(false);
          this.props.setSelectedRole(role);
          this.props.updateRoleName(role.name);
        }}
        className={selected ? 'roleRow active' : 'roleRow'}
      >
        <div className="valueName">{role.name}</div>
      </div>
    );
  }
}
