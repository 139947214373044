import React from 'react';
import pure from 'recompose/pure';
import Messages from '../../Notifications/Messages';
import Errors from '../../Notifications/Errors';
import Input from '../../../commonComponents/Input/Input';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import './NewAdminStateless.css';

const NewAdminStateless = ({
  handleSubmit,
  submit,
  requesting,
  errors,
  messages,
  successful,
}) => (
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="card-group mb-0">
          <div className="card p-4">
            <div className="card-block">
              <form className="widget-form" onSubmit={handleSubmit(submit)}>
                <Field
                  name="name"
                  component={Input}
                  type="name"
                  placeholder="Name"
                />
                <Field
                  name="lastName"
                  component={Input}
                  type="lastName"
                  placeholder="Last Name"
                />
                <Field
                  name="email"
                  component={Input}
                  type="email"
                  placeholder="Email"
                />
                <Field
                  name="password"
                  type="password"
                  component={Input}
                  placeholder="Password"
                />
                <Field
                  name="confirmPassword"
                  type="password"
                  component={Input}
                  placeholder="Confirm Password"
                />
                <div className="row">
                  <div className="col-12">
                    <button
                      className="btn btn-primary px-4 createBtn"
                      action="submit"
                    >
                      Create
                    </button>
                  </div>
                </div>
              </form>
              <div className="auth-messages">
                {!requesting &&
                  errors &&
                  !!errors.length &&
                  <Errors message="Failure to login due to:" errors={errors} />}
                {!requesting &&
                  !!messages.length &&
                  <Messages messages={messages} />}
                {requesting && <Spinner />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

NewAdminStateless.propTypes = {
  handleSubmit: PropTypes.func,
  creationRequest: PropTypes.func,
  requesting: PropTypes.bool,
  successful: PropTypes.bool,
  messages: PropTypes.array,
  errors: PropTypes.array,
};

export default pure(NewAdminStateless);
