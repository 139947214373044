import * as type from './actionTypesSagas';

export const updateStudyProgramsOfReference = (studyPrograms, reference) => ({
  type: type.UPDATE_SP_REFERENCE,
  studyPrograms,
  reference,
});

export const loadFilters = (user) => ({
  type: type.LOAD_FILTERS,
  user,
});

export const getLogs = (id, isChapter) => ({
  type: type.GET_LOGS,
  id,
  isChapter,
});

export const createFilter = (filter) => ({
  type: type.CREATE_FILTER,
  filter,
});

export const editFilter = (filter) => ({
  type: type.EDIT_FILTER,
  filter,
});

export const setActiveFilter = (filter) => ({
  type: type.SET_ACTIVE_FILTER,
  filter,
});

export const deleteFilter = (filterId) => ({
  type: type.DELETE_FILTER,
  filterId,
});

export const loadChapterQuestionsCSV = (chapter, byDetail, isAssignment) => ({
  type: type.LOAD_CHAPTER_QUESTIONS_CSV,
  chapter,
  byDetail,
  isAssignment,
});

export const getNamesToSecondBar = (letter) => ({
  type: type.GET_NAMES_TO_SECOND_BAR,
  letter,
});
