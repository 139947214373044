import { all, put, call, takeLatest } from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import { setRates, setWaiting } from './actions';
import { LOAD_RATES } from './actionTypesSagas';
import * as api from '../../utilities/ServiceManager';

/**
 * LOAD Rates
 */
function* loadRatesSagas() {
  yield takeLatest(LOAD_RATES, loadRates);
}

function* loadRates(action) {
  try {
    yield put(setWaiting(true));
    let rates = yield call(api.getRatesForStudents);
    yield put(setWaiting(false));
    rates.sort((a, b) => {
      if (a.shortname < b.shortname) return -1;
      if (a.shortname > b.shortname) return 1;
      return 0;
    });
    yield put(setRates(rates));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load rates. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 * DEFAUL EXPORT
 */
export default function* sagas() {
  yield all([loadRatesSagas()]);
}

function showNotificationErrorFromException(exception) {
  let message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
