import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import Spinner from '../../../../commonComponents/Spinner/Spinner';

export default class MasterUserReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      step: 0,
      csvCompleted: false,
      retryAvailable: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isCsvAvailable) {
      window.open(this.props.automaticMasterReportData);
      this.props.clearAutomaticReportProps();
    }
    if (
      this.props.lastCustomer !== prevProps.lastCustomer &&
      this.props.hasMore &&
      this.state.csvCompleted === false
    ) {
      this.props.getMasterUserReport(this.props.lastCustomer);
      this.setState({ step: this.state.step + 1 });
    }
    if (
      this.props.hasMore !== prevProps.hasMore &&
      this.props.hasMore === false
    ) {
      this.setState({ csvCompleted: true, isLoading: false });
      document.getElementById('generate-report-download').click();
      this.props.clearProps();
    }
    if (
      prevProps.APIError !== this.props.APIError &&
      this.props.APIError === true
    ) {
      this.setState({ retryAvailable: true });
    }
  }

  componentWillUnmount() {
    this.props.clearProps();
  }

  componentDidMount() {
    this.props.latestCsvCreationDate();
  }

  render() {
    return (
      <div>
        {this.state.isLoading && this.state.retryAvailable === false ? (
          <Spinner text={`Step ${this.state.step}`} />
        ) : (
          <Row>
            <Col className="d-block col-6">
              <div
                className="whiteButtons manuallyReportButtonWidth"
                onClick={() => {
                  this.props.downloadMasterUserReport();
                }}
              >
                Download Latest Report ({this.props.lastCreationDate})
              </div>
            </Col>

            <Col className="d-block col-6">
              <div>
                {this.state.retryAvailable ? (
                  <div
                    className="whiteButtons"
                    onClick={() => {
                      this.props.getMasterUserReport(this.props.lastCustomer),
                        this.setState({
                          isLoading: true,
                          retryAvailable: false,
                        }),
                        this.props.clearMasterReportAPIError();
                    }}
                  >
                    Retry
                  </div>
                ) : (
                  <div
                    className="whiteButtons manuallyReportButtonWidth"
                    onClick={() => {
                      this.props.getMasterUserReport(''),
                        this.setState({
                          isLoading: true,
                          csvCompleted: false,
                          step: 0,
                        });
                    }}
                  >
                    Generate Report Manually
                  </div>
                )}
              </div>
            </Col>
          </Row>
        )}
        <div style={{ display: 'none' }}>
          <CSVLink
            id="generate-report-download"
            filename={`Master User Report ${Date.now()}.csv`}
            data={this.props.CSVData}
          >
            Generate Report
          </CSVLink>
        </div>
      </div>
    );
  }
}
