import Notifications from 'react-notification-system-redux';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { closeModal } from '../../containers/Full/actions';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import * as api from '../../utilities/ServiceManager';
import {
  deleteChapterAction,
  deleteChapterForBooksPage,
  deleteReference as deleteReferenceAction,
  hasMoreData,
  referencesReceivedAction,
  relocateChapters,
  setArchived,
  setChapterBooksPage,
  updateChapterAction,
  updateChapterAfterDeletePdf,
  updateChapterAfterUpdate,
  updateLoadingGrid,
  updateReference as updateReferenceAction,
  updateReferenceAfterDeletePdf,
} from '../Bibliographies/actions';
import {
  setChapter,
  setChapterAfterDeletePdf,
  setReference,
  updateQuestionPdfProcessing,
} from '../Questions/actions';
import { waiting } from '../Rates/actions';
import {
  ALL_ASSIGNED_SAGAS,
  ALL_FOR_RATE_SAGAS,
  ARCHIVE_SAGAS,
  ARCHIVED_REFERENCES_SAGAS,
  ASSIGNED_TO_BIB_SAGAS,
  CHANGE_STATUS_SAGAS,
  CHAPTER_STATUS_SAGAS,
  COPY_REFERENCE_SAGAS,
  DELETE_ALL_QUESTIONS_SAGAS,
  DELETE_CHAPTER_SAGAS,
  DELETE_PDF_CHAPTER_SAGAS,
  DELETE_PDF_SAGAS,
  DELETE_REFERENCE_SAGAS,
  MOVE_CHAPTERS_SAGAS,
  NOT_ASSIGNED_TO_BIB_SAGAS,
  REMOVE_MANUAL_RED_QUESTIONS_SAGAS,
  REMOVE_MANUAL_RED_SUBTOPICS_SAGAS,
  SET_MANUAL_RED_QUESTIONS_SAGAS,
  SET_MANUAL_RED_SUBTOPICS_SAGAS,
  UPDATE_CHAPTER_SAGAS,
  UPDATE_REFERENCE_SAGAS,
} from './actionTypesSagas';

/**
 *  UPDATE REFERENCE INFO
 */
function* updateReferenceSaga() {
  yield takeEvery(UPDATE_REFERENCE_SAGAS, updateReference);
}

function* updateReference(action) {
  try {
    action.fields.id = action.referenceId;
    const response = yield call(
      api.updateReference,
      action.fields,
      action.activeFilter,
    );

    if (action.isQuestionPage) {
      yield put(updateQuestionPdfProcessing(false));
      yield put(setReference(response));
      yield put(updateReferenceAction(response));
    } else {
      response.isLoadingPdf = false;
      yield put(updateReferenceAction(response));
    }
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Reference updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(updateQuestionPdfProcessing(false));
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update reference. Please try again later.',
      ),
    );
  } finally {
    yield put(closeModal());
  }
}

/**
 *  DELETE REFERENCE
 */
function* deleteReferenceSaga() {
  yield takeLatest(DELETE_REFERENCE_SAGAS, deleteReference);
}

function* deleteReference(action) {
  try {
    yield call(api.deleteReference, action.reference);
    yield put(deleteReferenceAction(action.reference.id));
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Reference deleted successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not delete reference. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 *  CHANGE STATUS REFERENCE
 */
function* changeStatusSaga() {
  yield takeLatest(CHANGE_STATUS_SAGAS, changeStatus);
}

function* changeStatus(action) {
  try {
    const ref = {
      id: action.reference.id,
      enabled: !action.reference.enabled,
    };
    const response = yield call(api.updateReference, ref);
    yield put(updateReferenceAction(response));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: action.reference.enabled
            ? 'Reference disabled successfully'
            : 'Reference enabled successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        action.reference.enabled
          ? 'Could not disable reference. Please try again later.'
          : 'Could not enable reference. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 *  ARCHIVE/UNARCHIVE REFERENCE
 */
function* archiveSaga() {
  yield takeLatest(ARCHIVE_SAGAS, archive);
}

function* archive(action) {
  try {
    const ref = {
      id: action.reference.id,
      archived: !action.reference.archived,
    };
    const response = yield call(api.updateReference, ref);
    yield put(setArchived(response, action.filter));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: ref.archived
            ? 'Reference archived successfully'
            : 'Reference unarchived successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        !action.reference.archived
          ? 'Could not archive reference. Please try again later.'
          : 'Could not unarchive reference. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 *  DELETE PDF FILE
 */
function* deletePdfSaga() {
  yield takeLatest(DELETE_PDF_SAGAS, deletePdf);
}

function* deletePdf(action) {
  try {
    const response = yield call(api.deletePdfReference, action.reference.id);
    yield put(updateReferenceAfterDeletePdf(response));
    if (!response.actAsParent) {
      yield put(setChapterAfterDeletePdf(response.chapters[0]));
      yield put(updateChapterAfterDeletePdf(response.chapters[0]));
    }
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'File deleted successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not delete file. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 *  COPY REFERENCE
 */
function* copyReferenceSaga() {
  yield takeLatest(COPY_REFERENCE_SAGAS, copyReference);
}

function* copyReference(action) {
  try {
    yield call(api.copyReference, action.fields);
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Reference copied!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not copy reference. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 *  All References for one rate
 */
export function* watchGetAllForRate() {
  yield takeLatest(ALL_FOR_RATE_SAGAS, fetchAllReferencesForRate);
}

function* fetchAllReferencesForRate(action) {
  try {
    const refs = yield call(api.getAllForRate, action);
    yield put(referencesReceivedAction(refs));
    yield put(updateLoadingGrid(false));
  } catch (exception) {
    yield put(updateLoadingGrid(false));
    yield put(referencesReceivedAction([]));
    showNotificationErrorFromException(
      exception,
      'Could not load references. Please try again later.',
    );
  } finally {
  }
}

/**
 *  All Assigned to bibs
 */
export function* watchGetAllAssignedToBib() {
  yield takeLatest(ALL_ASSIGNED_SAGAS, fetchAllReferencesAssigned);
}

function* fetchAllReferencesAssigned(action) {
  try {
    const refs = yield call(api.getAllAssignedToBib, action);
    yield put(referencesReceivedAction(refs));
    yield put(updateLoadingGrid(false));
  } catch (exception) {
    yield put(updateLoadingGrid(false));
    yield put(referencesReceivedAction([]));
    showNotificationErrorFromException(
      exception,
      'Could not load references. Please try again later.',
    );
  } finally {
  }
}

/**
 *  Filter Assigned to bibs
 */
export function* watchGetAssignedToBibReferences() {
  yield takeLatest(ASSIGNED_TO_BIB_SAGAS, fetchReferences);
}

function* fetchReferences(action) {
  try {
    yield put(hasMoreData(false));
    const refs = yield call(api.getAssignedToBibReferences, action);
    yield put(referencesReceivedAction(refs));
    yield put(updateLoadingGrid(false));
  } catch (exception) {
    yield put(updateLoadingGrid(false));
    yield put(referencesReceivedAction([]));
    showNotificationErrorFromException(
      exception,
      'Could not load references. Please try again later.',
    );
  } finally {
  }
}

/**
 *  Filter not Assigned to bibs
 */
export function* watchGetNotAssignedToBibReferences() {
  yield takeLatest(NOT_ASSIGNED_TO_BIB_SAGAS, fetchReferencesNotAssignedTobib);
}

function* fetchReferencesNotAssignedTobib(action) {
  try {
    // yield put(hasMoreData(false));
    const refs = yield call(api.getNotAssignedToBibReferences, action);
    yield put(referencesReceivedAction(refs));
    yield put(updateLoadingGrid(false));
  } catch (exception) {
    yield put(updateLoadingGrid(false));
    yield put(referencesReceivedAction([]));
    showNotificationErrorFromException(
      exception,
      'Could not load references. Please try again later.',
    );
  } finally {
  }
}

/**
 *  Filter Archived
 */
export function* watchGetArchivedReferences() {
  yield takeLatest(ARCHIVED_REFERENCES_SAGAS, fetchReferencesArchived);
}

function* fetchReferencesArchived(action) {
  try {
    yield put(hasMoreData(false));
    const refs = yield call(api.getArchivedReferences, action);
    yield put(referencesReceivedAction(refs));
    yield put(updateLoadingGrid(false));
  } catch (exception) {
    yield put(updateLoadingGrid(false));
    yield put(referencesReceivedAction([]));
    showNotificationErrorFromException(
      exception,
      'Could not load references. Please try again later.',
    );
  } finally {
  }
}

/**
 *  DELETE CHAPTER
 */
function* deleteChapterSaga() {
  yield takeLatest(DELETE_CHAPTER_SAGAS, deleteChapter);
}

function* deleteChapter(action) {
  try {
    yield call(api.deleteChapter, action);
    if (action.forBooks) yield put(deleteChapterForBooksPage(action.chapter));
    else
      yield put(
        deleteChapterAction(
          action.chapter,
          action.reference,
          action.presentationIndex,
        ),
      );
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Chapter deleted successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not delete chapter. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 *  DELETE ALL QUESTIONS
 */
function* deleteAllQuestionsSaga() {
  yield takeLatest(DELETE_ALL_QUESTIONS_SAGAS, deleteQuestions);
}

function* deleteQuestions(action) {
  try {
    yield call(api.deleteQuestions, action);
    yield put(updateChapterAction(action.chapter));
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Questions deleted successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not delete questions. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 *  CHANGE STATUS CHAPTER
 */
function* changeStatusChapterSaga() {
  yield takeLatest(CHAPTER_STATUS_SAGAS, changeStatusChapter);
}

function* changeStatusChapter(action) {
  try {
    const chap = {
      id: action.chapter.id,
      enabled: !action.chapter.enabled,
      reference: action.chapter.reference,
    };
    const response = yield call(api.updateChapter, chap);
    yield put(updateChapterAfterUpdate(response));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: action.chapter.enabled
            ? 'Chapter disabled successfully'
            : 'Chapter enabled successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        action.chapter.enabled
          ? 'Could not disable chapter. Please try again later.'
          : 'Could not enable chapter. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 *  DELETE PDF FILE CHAPTER
 */
function* deletePdfChapterSaga() {
  yield takeLatest(DELETE_PDF_CHAPTER_SAGAS, deletePdfChapter);
}

function* deletePdfChapter(action) {
  try {
    const response = yield call(api.deletePdfChapter, action.chapter.id);

    if (action.forQuestion) {
      yield put(setChapter(response, true));
      yield put(updateChapterAfterDeletePdf(response));
    } else {
      yield put(updateChapterAfterDeletePdf(response));
    }
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'File deleted successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not delete file. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 *  SET MANUAL RED SUBTOPICS
 */
function* setManualRedSubtopicsSaga() {
  yield takeLatest(SET_MANUAL_RED_SUBTOPICS_SAGAS, setManualRedSubtopics);
}

function* setManualRedSubtopics(action) {
  try {
    const chap = {
      id: action.chapter.id,
      subtopicsIndicator: 'forced',
      subtopicIndicatorReason: action.reason.reason,
    };
    const response = yield call(api.updateChapter, chap);
    yield put(updateChapterAfterUpdate(response));
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Indicator updated',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update indicator. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 *  SET MANUAL RED QUESTIONS
 */
function* setManualRedQuestionsSaga() {
  yield takeLatest(SET_MANUAL_RED_QUESTIONS_SAGAS, setManualRedQuestions);
}

function* setManualRedQuestions(action) {
  try {
    const chap = {
      id: action.chapter.id,
      questionsIndicator: 'forced',
      questionIndicatorReason: action.reason.reason,
    };
    const response = yield call(api.updateChapter, chap);
    yield put(updateChapterAfterUpdate(response));
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Indicator updated',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update indicator. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 *  REMOVE MANUAL RED QUESTIONS
 */
function* removeManualRedQuestionsSaga() {
  yield takeLatest(REMOVE_MANUAL_RED_QUESTIONS_SAGAS, removeManualRedQuestions);
}

function* removeManualRedQuestions(action) {
  try {
    const chap = {
      id: action.chapter.id,
      questionsIndicator: 'calculated',
    };
    const response = yield call(api.updateChapter, chap);
    yield put(updateChapterAfterUpdate(response));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Indicator updated',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update indicator. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 *  REMOVE MANUAL RED SUBTOPICS
 */
function* removeManualRedSubtopicsSaga() {
  yield takeLatest(REMOVE_MANUAL_RED_SUBTOPICS_SAGAS, removeManualRedSubtopics);
}

function* removeManualRedSubtopics(action) {
  try {
    const chap = {
      id: action.chapter.id,
      subtopicsIndicator: 'calculated',
    };
    const response = yield call(api.updateChapter, chap);
    yield put(updateChapterAfterUpdate(response));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Indicator updated',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update indicator. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 *  UPDATE CHAPTER INFO
 */
function* updateChapterSaga() {
  yield takeEvery(UPDATE_CHAPTER_SAGAS, updateChapter);
}

function* updateChapter(action) {
  try {
    yield put(waiting(true));
    action.fields.id = action.chapterId;
    const response = yield call(api.updateChapter, action.fields);
    if (action.forQuestion) {
      yield put(setChapter(response, false, true));
      yield put(updateChapterAfterUpdate(response));
    } else {
      if (action.forBooks) {
        yield put(setChapterBooksPage(response));
        yield put(updateChapterAfterUpdate(response));
      } else {
        yield put(updateChapterAfterUpdate(response));
      }
    }
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Chapter updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update chapter. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
    if (action.forQuestion) yield put(updateQuestionPdfProcessing(null));
  }
}

/**
 *  MOVE CHAPTERS
 */
function* moveChapterSaga() {
  yield takeEvery(MOVE_CHAPTERS_SAGAS, moveChapter);
}

function* moveChapter(action) {
  try {
    yield call(
      api.swapPosition,
      action.chapter1.reference,
      action.chapter1.id,
      action.chapter2.id,
    );
    yield put(relocateChapters(action.chapter1, action.chapter2));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Chapter updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not move chapters. Please try again later.',
      ),
    );
  }
}

/**
 * DEFAULT EXPORT
 */
export default function* sagas() {
  yield all([
    updateReferenceSaga(),
    deleteReferenceSaga(),
    changeStatusSaga(),
    archiveSaga(),
    deletePdfSaga(),
    copyReferenceSaga(),
    watchGetAssignedToBibReferences(),
    watchGetNotAssignedToBibReferences(),
    watchGetArchivedReferences(),
    watchGetAllForRate(),
    watchGetAllAssignedToBib(),
    deleteChapterSaga(),
    deleteAllQuestionsSaga(),
    changeStatusChapterSaga(),
    deletePdfChapterSaga(),
    setManualRedSubtopicsSaga(),
    setManualRedQuestionsSaga(),
    removeManualRedQuestionsSaga(),
    removeManualRedSubtopicsSaga(),
    updateChapterSaga(),
    moveChapterSaga(),
  ]);
}

function showNotificationErrorFromException(exception) {
  const message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
