import { connect } from 'react-redux';

import {
  clearAPIError,
  clearProps,
  getHistoricalUsersReportSagas,
} from '../../actions';
import {
  allRates,
  finish,
  historicalReportError,
  lastCustomer,
  values,
} from '../../selectors';
import HistoricalUsersReport from './HistoricalUsersReport';

const mapStateToProps = (state) => ({
  finish: finish(state),
  lastCustomer: lastCustomer(state),
  values: values(state),
  allRates: allRates(state),
  historicalReportError: historicalReportError(state),
});

const mapDispatchToProps = (dispatch) => ({
  getHistoricalUsersReport: (
    lastCustomer,
    startingDate,
    endingDate,
    lessCustomers,
  ) => {
    dispatch(
      getHistoricalUsersReportSagas(
        lastCustomer,
        startingDate,
        endingDate,
        lessCustomers,
      ),
    );
  },
  clearAPIError: () => {
    dispatch(clearAPIError());
  },
  clearProps: () => {
    dispatch(clearProps());
  },
});

const HistoricalUsersReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HistoricalUsersReport);

export default HistoricalUsersReportContainer;
