import * as type from './actionTypes';

export const getAllDailyQuizForTodayAction = () => ({
  type: type.GET_ALL_DALY_QUIZ_FOR_TODAY,
});
export const setAllDailyQuizForTodayAction = (allDailyQuiz) => ({
  type: type.SET_ALL_DALY_QUIZ_FOR_TODAY,
  allDailyQuiz,
});
export const generateDailyQuizForTodayAction = () => ({
  type: type.GENERATE_DAILY_QUIZ_FOR_TODAY,
});
