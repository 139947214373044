/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap-button-loader';
import FileReaderInput from 'react-file-reader-input';
import csvToJson from 'csvtojson';
import * as Sentry from '@sentry/browser';
import './ImportCSV.css';
import _ from 'underscore';

class ImportCSV extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileContents: null,
      error: null,
      waiting: false,
    };
  }

  processFileSafe = (e, results) => {
    try {
      this.processFile(e, results);
    } catch (err) {
      this.props.onShowNotification(
        'Oops! This is an invalid file, please review your spreadsheet for errors and try again.',
      );
      Sentry.captureException(err);
    }
  };

  processFile = (e, results) => {
    results.forEach((result) => {
      const [e, file] = result;
      // Decide whether you're importing csv or xlsx format and handle it
      if (file.type.indexOf('csv') >= 0) {
        csvToJson({
          ignoreEmpty: true,
          headers: [
            'question',
            'correctanswer',
            'incorrectanswer_0',
            'incorrectanswer_1',
            'incorrectanswer_2',
            'notes',
            'softwarepage',
            'flashmode',
            'flashmodeonly',
            'documentpage',
          ],
          colParser: {
            question: 'string',
            correctanswer: 'string',
            incorrectanswer_0: 'string',
            incorrectanswer_1: 'string',
            incorrectanswer_2: 'string',
            notes: 'string',
            softwarepage: 'number',
            flashmode: 'number',
            flashmodeonly: 'number',
            documentpage: 'string',
          },
        })
          .fromString(decodeURIComponent(escape(e.target.result)))
          .then((jsonArrObj) => {
            this.processResult(jsonArrObj);
          });
      } else {
        let wb = window.XLSX.read(e.target.result, {
          type: 'binary',
          raw: false,
        });
        let sheetNameList = wb.SheetNames;
        let sheetJSON = window.XLSX.utils.sheet_to_json(
          wb.Sheets[sheetNameList[0]],
          {
            raw: false,
          },
        );
        const paredSheetJSON = sheetJSON.map((row) => {
          row.softwarepage = parseInt(row.softwarepage);
          row.flashmode = parseInt(row.flashmode);
          row.flashmodeonly = parseInt(row.flashmodeonly);

          return row;
        });

        this.processResult(paredSheetJSON);
      }
    });
  };

  processResult = (JSONResult) => {
    let processedFile = JSONResult.map((question) => {
      let key,
        keys = Object.keys(question);
      let keysLength = keys.length;
      let newObj = {};
      while (keysLength--) {
        key = keys[keysLength];
        newObj[key.toLowerCase().trim()] = question[key];
      }
      return newObj;
    });
    this.setState({ fileContents: processedFile });
  };

  render() {
    const {
      onConfirm,
      onCancel,
      title = 'Import Questions',
      yesButtonText = 'Import questions',
      noButtonText = 'Cancel',
      greenButton,
    } = this.props;

    return (
      <div className="import-component">
        <div className="card-block">
          <div className="title">
            {title}
            <i
              style={{ cursor: 'pointer' }}
              className="fa fa-times fa-xs"
              onClick={() => this.props.onCancel()}
            />
          </div>
          <p>
            <a
              href="https://static.bluejacketeer.com/files/Questions_Template.xlsx"
              target="_blank"
            >
              Example Template
            </a>
          </p>
          <form
            className="widget-form import-csv-form"
            onSubmit={(ev) => {
              ev.preventDefault();
              this.setState({ waiting: true });
              onConfirm(this.state.fileContents);
            }}
          >
            <hr className="titleHR" />
            <FileReaderInput
              className="file-input"
              as="binary"
              id="my-file-input"
              onChange={this.processFileSafe}
            >
              {this.state.fileContents ? (
                this.state.fileContents.length && (
                  <div className="question-wrapper">
                    <p>You are about to import the following questions:</p>
                    <div className="question-container">
                      <div>
                        {this.state.fileContents.map((line, index) => {
                          return (
                            <div key={index} className="question-line">{`${
                              index + 1
                            }. ${line.question}`}</div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div>
                  <p>You may upload a question file in .csv or .xls(x) ...</p>
                  <p>
                    Please note that your spreadsheet must include the following
                    headings:{' '}
                    <b>
                      question, correctanswer, incorrectanswer_0,
                      incorrectanswer_1, incorrectanswer_2, notes, softwarepage,
                      flashmode, flashmodeonly, documentpage
                    </b>
                  </p>

                  <button type="button">Choose file to import</button>
                </div>
              )}
            </FileReaderInput>

            {this.state.error ? (
              <div className="errors">{this.state.error}</div>
            ) : null}
            {this.state.fileContents && (
              <div className="bottom-buttons">
                <Button className="btn btn-secondary" onClick={onCancel}>
                  {noButtonText}
                </Button>
                <Button
                  className={greenButton ? 'btn btn-success' : 'btn btn-danger'}
                  type="submit"
                  loading={this.state.waiting}
                >
                  {yesButtonText}
                </Button>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

ImportCSV.propTypes = {
  handleSubmit: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ImportCSV;
