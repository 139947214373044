import UniversalCookies from 'universal-cookie';

import { cookiesExpirationTime } from '../constants/cookiesExpirationTime';
import getCookieTokenDomain from './getCookieTokenDomain';

const cookieHandler = new UniversalCookies();

const cookieTokenOptions = {
  path: '/',
  domain: getCookieTokenDomain(window.location),
  expires: cookiesExpirationTime(),
};

export function getUserToken() {
  return cookieHandler.get('token', cookieTokenOptions);
}
export function setUserToken(token) {
  cookieHandler.set('token', token, cookieTokenOptions);
}
export function removeUserToken() {
  cookieHandler.remove('token', cookieTokenOptions);
}
