import React from 'react';
import { SimpleInput } from '../../../../commonComponents/Input/Input';
import './PreviewPlans.css';

class PreviewPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rate: '',
      dutyState: '',
      rank: '',
      week: '',
      studyPlan: '',
      emails: '',
    };
  }

  onRateChange = event => {
    this.setState({ rate: event.target.value });
  };

  onRankChange = event => {
    this.setState({ rank: event.target.value });
  };

  onDutyStateChange = event => {
    this.setState({ dutyState: event.target.value });
  };

  onWeekChange = event => {
    this.setState({ week: event.target.value });
  };

  onStudyPlanChange = event => {
    this.setState({ studyPlan: event.target.value });
  };

  onEmailsChange = event => {
    this.setState({ emails: event.target.value });
  };

  onSendEmail = () => {
    let { studyPlan, rate, rank, dutyState, week, emails } = this.state;
    this.props.sendStudyPlanPreview(
      studyPlan,
      rate,
      rank,
      dutyState,
      week,
      emails
    );
  };

  render() {
    return (
      <div className="studyPlan">
        <div>
          <div className="header">
            <h1>Study Plans Preview</h1>
          </div>
          <div className="dataContainer">
            <div className="personalData">
              <div>
                <span>
                  <strong>Study Plan:</strong>
                </span>
                <select
                  className="form-control"
                  id="rateSelect"
                  name="rate_id"
                  required=""
                  value={this.state.studyPlan}
                  onChange={this.onStudyPlanChange}
                >
                  <option value="">Select your plan</option>
                  {this.props.studyPlans.data.map(studyPlan => {
                    return (
                      <option
                        key={'studyPlan-' + studyPlan.id}
                        value={studyPlan.id}
                      >
                        {studyPlan.title}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <span>
                  <strong>Week:</strong>
                </span>
                <select
                  className="form-control"
                  required=""
                  value={this.state.week}
                  onChange={this.onWeekChange}
                >
                  <option value="">Week</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div>
                <span>
                  <strong>Rate:</strong>
                </span>
                <select
                  className="form-control"
                  required=""
                  value={this.state.rate}
                  onChange={this.onRateChange}
                >
                  <option value="">Select your rate</option>
                  {this.props.rates.map(rate => {
                    return (
                      <option key={'rate-' + rate.id} value={rate.id}>
                        {rate.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <span>
                  <strong>Rank:</strong>
                </span>
                <select
                  className="form-control"
                  required=""
                  value={this.state.rank}
                  onChange={this.onRankChange}
                >
                  <option value="">Exam Rank</option>
                  <option value="E4">E-4</option>
                  <option value="E5">E-5</option>
                  <option value="E6">E-6</option>
                  <option value="E7">E-7</option>
                </select>
              </div>
              <div>
                <span>
                  <strong>Duty State:</strong>
                </span>
                <select
                  className="form-control"
                  required=""
                  value={this.state.dutyState}
                  onChange={this.onDutyStateChange}
                >
                  <option value="">Exam Type</option>
                  <option value="Regular">Regular</option>
                  <option value="Substitute">Substitute</option>
                  <option value="Reserve">Reserve</option>
                </select>
              </div>
            </div>
            <div>
              <div>
                <span>
                  <strong>Emails:</strong>
                </span>
              </div>
              <SimpleInput
                type="text"
                value={this.state.emails}
                onChange={this.onEmailsChange}
                error={
                  this.state.errors && this.state.errors.emails ? (
                    this.state.errors.emails
                  ) : null
                }
              />
              <div>
                <span>
                  Email preview recipients. You can add more than one separated
                  by comma.
                </span>
              </div>
            </div>
          </div>
          <div className="buttonsContainer">
            <button
              className={
                this.state.rate !== '' &&
                this.state.rank !== '' &&
                this.state.dutyState !== '' &&
                this.state.week !== '' &&
                this.state.emails !== '' ? (
                  'navyButton'
                ) : (
                  'navyButton disabled'
                )
              }
              onClick={this.onSendEmail}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PreviewPlans;
