export const SET_SELECTED_STUDY_PLAN =
  'studyPlansUsers/SET_SELECTED_STUDY_PLAN';
export const ADD_STUDY_PLAN = 'studyPlansUsers/ADD_STUDY_PLAN';
export const SET_STUDY_PLANS = 'studyPlansUsers/SET_STUDY_PLANS';
export const UPDATE_STUDY_PLAN = 'studyPlansUsers/UPDATE_STUDY_PLAN';
export const SET_SELECTED_STUDY_PLAN_USER =
  'studyPlansUsers/SET_SELECTED_STUDY_PLAN_USER';
export const SET_STUDY_PLANS_USERS = 'studyPlansUsers/SET_STUDY_PLANS_USERS';
export const UPDATE_SELECTED_TEN_WEEK_LIST_TAB =
  'studyPlansUsers/UPDATE_SELECTED_TEN_WEEK_LIST_TAB';
export const SET_PREVIEW_PLANS = 'studyPlansUsers/SET_PREVIEW_PLANS';
export const SET_LOADING_USERS = 'studyPlansUsers/SET_LOADING_USERS';
