import rootReducer from './../components/rootReducer';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './../components/rootSaga';
import { persistStore, persistReducer } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import { blacklistedReducers } from './blacklistedReducers';
import storageSession from 'redux-persist/lib/storage/session';

const persistConfig = {
  transforms: [immutableTransform()],
  key: 'root',
  storage: storageSession,
  blacklist: blacklistedReducers,
};

const composeEnhancers = compose;

export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
}
