import { connect } from 'react-redux';
import ReviewHistoryAssignment from './ReviewHistoryAssigment';
import { onEditAssignment } from '../../Assignments/actionsSagas';
import {
  setActiveStatus,
  clearSelectedAssignment,
} from '../../Assignments/actions';
import addAbilityToOpenModal from '../../../utilities/modalHOC';

const mapStateToProps = (state, ownProps) => ({
  assignment: ownProps ? ownProps.assignment : null,
});

const mapDispatchToProps = dispatch => {
  return {
    onEditAssignment: assignment => {
      dispatch(onEditAssignment(assignment, true));
      dispatch(setActiveStatus('Completed'));
      dispatch(clearSelectedAssignment());
    },
    cancelAssignment: assignment => {
      dispatch(onEditAssignment(assignment, true, true));
      dispatch(setActiveStatus('In progress'));
      dispatch(clearSelectedAssignment());
    },
    onBack: () => {
      dispatch(clearSelectedAssignment());
    },
  };
};

const ReviewHistoryContainer = connect(mapStateToProps, mapDispatchToProps)(
  ReviewHistoryAssignment
);

export default addAbilityToOpenModal(ReviewHistoryContainer);
