import React from 'react';
import './Activity.css';
import Card from '../../../../commonComponents/Card/Card';
import { Row, Col } from 'react-bootstrap';
import CardRow from './CardRow';
import Pagination from '../../../../commonComponents/Pagination/Pagination';
import moment from 'moment';
import { SimpleDatePicker } from '../../../../commonComponents/Datepicker/Datepicker';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import ActivityView from './ActivityView';
import { change } from 'redux-form';

class Activity extends React.Component {
  constructor(props) {
    super(props);

    this.timeout = null;

    this.state = {
      page: 1,
      showDate: false,
      dateInit: 0,
      dateEnd: 0,
      totalPages: this.props.pagination,
    };
  }

  renderDate = (date) => {
    let dateString = moment(Number(date));
    return (
      dateString.format('MMMM') +
      ' ' +
      dateString.format('DD') +
      ', ' +
      dateString.format('YYYY') +
      ' ' +
      dateString.format('HH') +
      ':' +
      dateString.format('mm')
    );
  };

  reloadActivity = (
    user = this.props.user.id,
    page = this.props.pagination.page,
  ) => {
    let init =
      this.state.dateInit !== 0 ? moment(this.state.dateInit).valueOf() : 0;
    let finish =
      this.state.dateEnd !== 0 ? moment(this.state.dateEnd).valueOf() : 0;
    this.setState({ page: page || 1 });
    const filter = {
      dateI: init,
      dateE: finish,
    };
    this.props.getAdminHistory(user, page, filter);
  };

  changePage = (page) => {
    this.reloadActivity(this.props.user.id, page);
  };

  getDates = () => {
    let dates = [
      {
        name: 'Show/Hide',
        event: this.dateStatus,
      },
      {
        name: 'Clear',
        event: this.clearDate,
      },
    ];
    return dates;
  };

  clearDate = () => {
    this.setState(
      {
        dateInit: 0,
        dateEnd: 0,
      },
      this.reloadActivity,
    );
  };

  dateStatus = () => {
    let aux = this.state.showDate;
    this.setState({ showDate: !aux });
  };

  dateFrom = (date) => {
    this.setState({ dateInit: date }, this.reloadActivity);
  };

  dateTo = (date) => {
    this.setState({ dateEnd: date }, this.reloadActivity);
  };

  renderDates = () => {
    return (
      <Row className="paddingRow">
        <Col
          xs={12}
          md={12}
          lg={12}
          style={{
            paddingRight: '0px',
          }}
        >
          <SimpleDatePicker
            className="datePickerStyle"
            date={this.state.dateInit ? moment(this.state.dateInit) : null}
            onChange={(dateInit) => {
              this.dateFrom(dateInit);
            }}
            placeholderText="FROM"
          />
          <SimpleDatePicker
            className="datePickerStyle"
            date={this.state.dateEnd ? moment(this.state.dateEnd) : null}
            onChange={(dateEnd) => {
              this.dateTo(dateEnd);
            }}
            placeholderText="TO"
          />
        </Col>
        <div className="separatorLine" />
      </Row>
    );
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.activity && prevProps.activity !== this.props.activity) {
      this.openActivity();
    }
  };

  openActivity = () => {
    const result = [];

    if (this.props.activity) {
      this.props.activity.forEach((changes) => {
        if (changes[0] === 'answers') {
          if (Array.isArray(changes[1]) && Array.isArray(changes[2])) {
            changes[1].forEach((olds) => {
              changes[2].forEach((news) => {
                if (olds.id === news.id && olds.text !== news.text) {
                  result.push(['answers', olds.text, news.text]);
                }
              });
            });
          }
        } else {
          result.push(changes);
        }
      });
    }
    this.props.openModal({
      modalTitle: 'detail activity',
      modalContent: (
        <ActivityView
          onCancel={this.props.closeModal}
          getActivity={this.props.getActivity}
          content={result}
          isQuestion={true}
        />
      ),
      modalClassName: 'activity-modal',
    });
  };

  openAssignment = (item) => {
    const content = [['assignment', item.statusFrom, item.statusTo]];
    this.props.openModal({
      modalTitle: 'detail activity',
      modalContent: (
        <ActivityView
          onCancel={this.props.closeModal}
          getActivity={this.props.getActivity}
          content={content}
          isQuestion={true}
        />
      ),
      modalClassName: 'activity-modal',
    });
  };

  renderContent = (history) => {
    const { loading } = this.props;
    return (
      <Row className="cardContentWrapper">
        {this.state.showDate ? this.renderDates() : null}
        {history.length === 0 ? (
          <div> There is no current activity</div>
        ) : loading ? (
          <Spinner text="Loading activity" />
        ) : (
          history.map((count) => {
            if (count.question) {
              return (
                <CardRow
                  key={'card-row-activity-' + count.createdAt}
                  title={this.renderDate(count.createdAt)}
                  content={
                    <b>
                      Question {count.question} {count.action}{' '}
                    </b>
                  }
                  openActivity={() => this.props.getActivity(count.id)}
                  link="Detail"
                  id={count.id}
                  getActivity={this.props.getActivity}
                />
              );
            } else {
              return (
                <CardRow
                  key={'card-row-activity-' + count.createdAt}
                  title={this.renderDate(count.createdAt)}
                  content={<b>Assignment {count.id} status changed</b>}
                  openActivity={() => this.openAssignment(count)}
                  link="Detail"
                  id={count.id}
                  getActivity={this.props.getActivity}
                />
              );
            }
          })
        )}
        <div className="paginationContainer">
          <Pagination
            currentPage={this.state.page}
            totalRecords={
              this.props.pagination ? this.props.pagination.total : 0
            }
            onChangePage={this.changePage}
          />
        </div>
      </Row>
    );
  };

  render() {
    return (
      <div className="ActivityContainer">
        <Card
          title="Activity"
          activityText="All Activity"
          content={this.renderContent(this.props.histoy)}
          date={true}
          datesArray={this.getDates()}
        />
      </div>
    );
  }
}

export default Activity;
