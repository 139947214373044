import routes from '../routes';

export function validatePage() {
  let currentLocation = window.location.hash.slice(
    1,
    window.location.hash.length,
  );
  currentLocation = currentLocation.toLocaleLowerCase();
  const questionRoute = '/app/question/';
  let isValid = false;
  if (currentLocation.slice(0, questionRoute.length).includes(questionRoute)) {
    isValid = true;
  } else {
    let i = 0;
    while (routes[i] && !isValid) {
      let validRoute;
      if (routes[i].layout !== '/' && routes[i].layout !== '/auth') {
        validRoute = routes[i].layout + routes[i].path;
      } else {
        validRoute = routes[i].path;
      }
      validRoute = validRoute.toLocaleLowerCase();

      if (
        currentLocation.includes(validRoute) &&
        validRoute.includes(currentLocation)
      ) {
        isValid = true;
      }
      i++;
    }
  }
  return isValid;
}
