import React from 'react';
import PropTypes from 'prop-types';
import NewAdminStateless from './NewAdminStateless';
import addAbilityToOpenModal from './../../../utilities/modalHOC';

class NewAdminStateful extends React.Component {
  state = {
    errors: [],
  };

  static propTypes = {
    handleSubmit: PropTypes.func,
    newAdminRequest: PropTypes.func,
    NewAdmin: PropTypes.shape({
      requesting: PropTypes.bool,
      successful: PropTypes.bool,
      messages: PropTypes.array,
      errors: PropTypes.array,
    }),
  };

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.creationRequest.successful &&
      this.props.errors !== nextProps.creationRequest.errors
    ) {
      let filtered = [];
      filtered.push(
        nextProps.creationRequest.errors[
          nextProps.creationRequest.errors.length - 1
        ]
      );
      this.setState({ errors: filtered });
    }
    if (nextProps.creationRequest.successful) {
      this.props.toggleModalVisibility({ modalVisibility: false });
    }
  }

  submit = values => {
    this.props.newAdminRequest(values);
  };

  render() {
    const {
      handleSubmit,
      creationRequest: { requesting, messages, successful },
    } = this.props;

    return (
      <NewAdminStateless
        handleSubmit={handleSubmit}
        submit={this.submit}
        requesting={requesting}
        messages={messages}
        errors={this.state.errors}
        successful={successful}
      />
    );
  }
}

export default addAbilityToOpenModal(NewAdminStateful);
