import React from 'react';
import SubNavBar from '../../../commonComponents/SubNavBar/SubNavBar';
import CycleForm from './CycleForm';
import './Cycles.css';
import ConfirmDeletion from '../../../commonComponents/Modals/ConfirmDeletion/ConfirmDeletion';
import _ from 'underscore';

class Cycles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cycle: {},
      viewArchivedCycles: false,
    };
  }

  componentWillMount() {
    //this.props.updateSelectedCycle(null);
    this.props.loadCycles();
    this.props.loadRates();
  }

  selectCycle = cycle => {
    this.props.updateSelectedCycle(cycle);
  };

  openModalDelete = () => {
    const { selectedCycle } = this.props;

    this.props.openModal({
      modalTitle: 'Deleting a cycle',
      modalContent: (
        <ConfirmDeletion
          onSubmit={() => this.props.deleteCycle(selectedCycle.id)}
          message={`You are about to delete the cycle ${
            selectedCycle.description
          } ${selectedCycle.title}.`}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  sortCycles = cycles => {
    let activeCycles = _.filter(cycles, { isActive: true });
    let nonActiveCycles = _.filter(cycles, { isActive: false });

    return activeCycles.concat(nonActiveCycles);
  };

  toggleCycles = () => {
    if (this.state.viewArchivedCycles) {
      this.props.loadCycles();
    } else {
      this.props.loadArchivedCycles();
    }
    this.setState({ viewArchivedCycles: !this.state.viewArchivedCycles });
  };

  render() {
    if (this.props.selectedCycle && this.props.selectedCycle.plusAction) {
      return (
        <div className="cycles-content-container">
          <SubNavBar name={'New cycle'} className="" />
          <CycleForm
            onSubmit={this.props.createCycle}
            cycle={this.props.selectedCycle}
            onDelete={this.openModalDelete}
            onUpdate={this.props.updateCycle}
            onMoveSubstitutes={this.props.moveSubstitutes}
            onMoveRates={this.props.moveSelectedRates}
            onSetActive={this.props.activateCycle}
            showNotificationError={this.props.showNotificationError}
            onArchive={fields => {
              this.props.toggleArchiveCycle(this.props.selectedCycle);
            }}
            setUpdatedCycle={this.props.setUpdatedCycle}
            updatedCycle={this.props.updatedCycle}
            rates={this.props.rates}
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
          />
        </div>
      );
    } else if (this.props.selectedCycle && this.props.selectedCycle.title) {
      return (
        <div className="cycles-content-container">
          <SubNavBar name={this.props.selectedCycle.title} className="" />
          <CycleForm
            onSubmit={this.props.createCycle}
            cycle={this.props.selectedCycle}
            onDelete={this.openModalDelete}
            onUpdate={this.props.updateCycle}
            onMoveSubstitutes={this.props.moveSubstitutes}
            onMoveRates={this.props.moveSelectedRates}
            onSetActive={this.props.activateCycle}
            showNotificationError={this.props.showNotificationError}
            onArchive={fields => {
              this.props.toggleArchiveCycle(this.props.selectedCycle);
            }}
            setUpdatedCycle={this.props.setUpdatedCycle}
            updatedCycle={this.props.updatedCycle}
            rates={this.props.rates}
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
          />
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default Cycles;
