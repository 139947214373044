/**********************
 * REDUX ACTION TYPES *
 **********************/
const ratesNamespace = 'rates';
export const SET_RATES = `${ratesNamespace}/SET_RATES`;
export const SET_TOPICS = `${ratesNamespace}/SET_TOPICS`;
export const ADD_TOPIC = `${ratesNamespace}/ADD_TOPIC`;
export const ADD_SUBTOPIC = `${ratesNamespace}/ADD_SUBTOPIC`;
export const UPDATE_TOPIC = `${ratesNamespace}/UPDATE_TOPIC`;
export const UPDATE_SUBTOPIC = `${ratesNamespace}/UPDATE_SUBTOPIC`;
export const REMOVE_TOPIC = `${ratesNamespace}/REMOVE_TOPIC`;
export const REMOVE_SUBTOPIC = `${ratesNamespace}/REMOVE_SUBTOPIC`;
export const UPDATE_WAITING = `${ratesNamespace}/UPDATE_WAITING`;
export const ADD_RATE = `${ratesNamespace}/ADD_RATE`;
export const CHANGE_PAGINATION_PAGE = `${ratesNamespace}/CHANGE_PAGINATION_PAGE`;
export const UPDATE_RATE = `${ratesNamespace}/UPDATE_RATE`;
export const SET_QUALS = `${ratesNamespace}/SET_QUALS`;
export const SET_SELECTED_SP = `${ratesNamespace}/SET_SELECTED_SP`;
export const ADD_QUAL = `${ratesNamespace}/ADD_QUAL`;
export const UPDATE_SP = `${ratesNamespace}/UPDATE_SP`;
export const REMOVE_SP = `${ratesNamespace}/REMOVE_SP`;
export const SET_RATING_EXAM_QUESTION_PERCENTAGE = `${ratesNamespace}/SET_RATING_EXAM_QUESTION_PERCENTAGE`;
