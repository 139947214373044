import Cycles from './Cycles';
import { connect } from 'react-redux';
import addAbilityToOpenModal from '../../../utilities/modalHOC';
import {
  getCycles,
  getCyclesImmutable,
  getSelectedCycle,
  getUpdatedCycle,
} from '../selectors';
import {
  loadCycles,
  createCycleActionSagas,
  deleteCycleActionSagas,
  updateCycleActionSagas,
  activateCycleActionSagas,
  moveSubstitutesActionSagas,
  moveSelectedRatesActionSagas,
  loadArchivedCycles,
  toggleArchiveCycle,
} from '../actionsSagas';
import { updateSelectedCycleAction, setUpdatedCycle } from '../actions';
import Notifications from 'react-notification-system-redux';
import { loadRates } from '../../Rates/actionsSagas';
import { getRates } from '../../Rates/selectors';

const mapStateToProps = state => ({
  cycles: getCycles(state),
  cyclesImmutable: getCyclesImmutable(state),
  selectedCycle: getSelectedCycle(state),
  updatedCycle: getUpdatedCycle(state),
  rates: getRates(state),
});

const mapDispatchToProps = dispatch => ({
  loadCycles: () => dispatch(loadCycles()),
  createCycle: fields => {
    dispatch(createCycleActionSagas(fields));
  },
  updateSelectedCycle: cycle => {
    dispatch(updateSelectedCycleAction(cycle));
  },
  deleteCycle: id => {
    dispatch(deleteCycleActionSagas(id));
  },
  updateCycle: fields => {
    dispatch(updateCycleActionSagas(fields));
  },
  activateCycle: id => {
    dispatch(activateCycleActionSagas(id));
  },
  moveSubstitutes: id => {
    dispatch(moveSubstitutesActionSagas(id));
  },
  moveSelectedRates: (id, rates) => {
    dispatch(moveSelectedRatesActionSagas(id, rates));
  },
  showNotificationError: error => {
    dispatch(
      Notifications.show(
        { title: 'Notice', message: error.toString() },
        'error',
      ),
    );
  },
  loadArchivedCycles: () => {
    dispatch(loadArchivedCycles());
  },
  toggleArchiveCycle: cycle => {
    dispatch(toggleArchiveCycle(cycle));
  },
  setUpdatedCycle: isUpdated => {
    dispatch(setUpdatedCycle(isUpdated));
  },
  loadRates: () => {
    dispatch(loadRates());
  },
});

export default addAbilityToOpenModal(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Cycles),
);
