import './FavoriteQuestionsReport.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import { SimpleInput } from '../../../../commonComponents/Input/Input';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import RatingExamSelector from '../RatingExamSelector/RatingExamSelector';

export default class FavoriteQuestionsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRates: [],
      selectedRatingExam: [],
      rateSearch: '',
    };
  }

  componentDidMount() {
    const { rates } = this.props;
    if (rates && rates.length) {
      this.setState({
        selectedRates: this.props.rates.slice(1),
      });
    } else if (!this.ratesIsLoading) {
      this.props.loadRates();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.rates || !prevProps.rates.length) &&
      this.props.rates &&
      this.props.rates.length
    ) {
      this.setState({
        selectedRates: this.props.rates.slice(1),
      });
    }

    if (
      prevProps.favoriteQuestionsReportIsLoading &&
      !this.props.favoriteQuestionsReportIsLoading &&
      !this.props.favoriteQuestionsReportError &&
      this.props.favoriteQuestionsReport.length > 0
    ) {
      setTimeout(() => {
        document.getElementById('report-download').click();
      }, 1000);
    }
  }

  areAllRatesSelected = () => {
    let areAllRatesSelected = true;
    let areAllRatesDeselected = true;
    this.state.selectedRates.forEach((currentRate) => {
      if (currentRate.isSelected) {
        areAllRatesDeselected = false;
      } else {
        areAllRatesSelected = false;
      }
    });
    return {
      areAllRatesSelected,
      areAllRatesDeselected,
    };
  };

  onChangeRateSelection = (rateId, newValue) => {
    const { selectedRates } = this.state;
    const checkedRate = selectedRates.find((r) => r.id == rateId);
    checkedRate.isSelected = newValue;
    if (!checkedRate.isSelected) {
      this.deselectRelatedRE(checkedRate.shortname);
    }
    this.setState({ selectedRates });
  };

  changeAllValues = (newValue) => {
    const { selectedRates } = this.state;

    if (!newValue) {
      this.setState({ selectedRatingExam: [] });
    }

    this.setState({
      selectedRates: selectedRates.map((rate) => {
        rate.isSelected = newValue;
        return rate;
      }),
    });
  };

  deselectRelatedRE = (ratingExam) => {
    const { selectedRatingExam } = this.state;
    const result = [];
    selectedRatingExam.forEach((SRE, index) => {
      if (SRE.slice(7) != ratingExam) {
        result.push(SRE);
      }
    });

    this.setState({ selectedRatingExam: result });
  };

  renderSelectAllOrNothing = (areAllRatesSelected, areAllRatesDeselected) => (
    <Row>
      <Col md={12} id="all-or-none">
        <SimpleInput
          type="checkbox"
          description={areAllRatesDeselected ? 'Select All' : 'Deselect All'}
          value={!areAllRatesDeselected}
          onChange={(e) => {
            this.changeAllValues(!!areAllRatesDeselected);
          }}
        />
      </Col>
    </Row>
  );

  renderRateAndCheckbox = (rate, index) => {
    if (rate.id) {
      return (
        <Row key={rate.id}>
          <Col md={12}>
            <SimpleInput
              description={`(${rate.shortname}) ${rate.name}`}
              type="checkbox"
              value={rate.isSelected}
              onChange={(e) => {
                this.onChangeRateSelection(rate.id, !rate.isSelected);
              }}
            />
          </Col>
        </Row>
      );
    }
    return null;
  };

  generateReportForSelectedRates = (csvReport, arrayIds, loading) => {
    const selectedRatesIds = this.state.selectedRates
      .filter((rate) => rate.isSelected)
      .map((rate) => rate.id);
    this.props.generateReportForSelectedRates(
      selectedRatesIds,
      this.state.selectedRatingExam,
      csvReport,
      arrayIds,
      loading,
    );
  };

  changeRatingExamSelected = (ratingExamSelected) => {
    const { selectedRatingExam } = this.state;
    const index = selectedRatingExam.indexOf(ratingExamSelected);
    if (index != -1) {
      selectedRatingExam.splice(index, 1);
    } else {
      selectedRatingExam.push(ratingExamSelected);
    }
    this.setState({ selectedRatingExam });
  };

  selectAllRatingExam = (ratingExams) => {
    const { selectedRatingExam } = this.state;

    ratingExams.forEach((RE) => {
      if (!selectedRatingExam.includes(RE)) {
        selectedRatingExam.push(RE);
      }
    });
    this.setState({ selectedRatingExam });
  };

  takeTheRate = (rate) => {
    const separate = rate[0].split(' ');
    const result = separate[0].replace(/([0-9])/g, '');
    return result;
  };

  render() {
    const { areAllRatesSelected, areAllRatesDeselected } =
      this.areAllRatesSelected();
    return (
      <Row className="all-csv-report">
        {this.props.ratesIsLoading ||
        this.props.favoriteQuestionsReportIsLoading ? (
          <Spinner
            text={
              this.props.loadingCounterReport === 0
                ? 'Loading... This may take a couple of minutes..'
                : `Working on step ${this.props.loadingCounterReport} of ${this.props.countFavoriteReport}`
            }
          />
        ) : (
          <div className="d-flex w-100">
            <div className="d-none">
              <CSVLink
                id="report-download"
                className="report-download"
                filename="Favorite-Questions-Report.csv"
                data={this.props.favoriteQuestionsReport}
              />
            </div>
            {!this.props.favoriteQuestionsReportError ? (
              <Col className="col-6">
                <div
                  className={
                    !areAllRatesDeselected
                      ? 'generate-report'
                      : 'generate-report disabled'
                  }
                  onClick={() => {
                    if (!areAllRatesDeselected) {
                      this.generateReportForSelectedRates(
                        this.props.csvFavoriteQuestionReport,
                        this.props.arrayIdsFavoriteReport,
                        this.props.loadingCounterReport,
                      );
                    }
                  }}
                >
                  Generate Report
                </div>
                <div className="report-title">
                  {' '}
                  Select the rates you want included in the Favorite Questions
                  Report{' '}
                </div>
                <span>Search Rate</span>
                <SimpleInput
                  value={this.state.rateSearch}
                  onChange={(e) =>
                    this.setState({
                      rateSearch: e.target.value,
                    })
                  }
                />
                {this.renderSelectAllOrNothing(
                  areAllRatesSelected,
                  areAllRatesDeselected,
                )}
                {this.state.selectedRates
                  .filter((r) =>
                    this.state.rateSearch !== ''
                      ? `(${r.shortname}) ${r.name}`
                          .toLowerCase()
                          .includes(this.state.rateSearch.toLowerCase())
                      : true,
                  )
                  .map((rate, index) =>
                    this.renderRateAndCheckbox(rate, index),
                  )}
              </Col>
            ) : (
              <div style={{ width: '100%' }}>
                <div
                  className={
                    !areAllRatesDeselected
                      ? 'generate-report'
                      : 'generate-report disabled'
                  }
                  onClick={() => {
                    if (!areAllRatesDeselected) {
                      this.generateReportForSelectedRates(
                        this.props.csvFavoriteQuestionReport,
                        this.props.arrayIdsFavoriteReport,
                        this.props.loadingCounterReport,
                      );
                    }
                  }}
                >
                  retry
                </div>
              </div>
            )}

            <Col className="d-block col-6">
              {!areAllRatesDeselected &&
              !this.props.favoriteQuestionsReportError ? (
                <RatingExamSelector
                  selectedRatingExam={this.state.selectedRatingExam}
                  selectAllRatingExam={this.selectAllRatingExam}
                  changeRatingExamSelected={this.changeRatingExamSelected}
                />
              ) : (
                <div />
              )}
            </Col>
          </div>
        )}
      </Row>
    );
  }
}

FavoriteQuestionsReport.propTypes = {
  rates: PropTypes.array.isRequired,
  ratesIsLoading: PropTypes.bool.isRequired,
  loadRates: PropTypes.func.isRequired,
  generateReportForSelectedRates: PropTypes.func,
  favoriteQuestionsReport: PropTypes.array,
  favoriteQuestionsReportIsLoading: PropTypes.bool,
  favoriteQuestionsReportError: PropTypes.string,
};
