import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import TopicsList from '../../../commonComponents/TopicsList/TopicsList';
import 'react-treeview/react-treeview.css';
import './Rates.css';
import ConfirmDeletion from '../../../commonComponents/Modals/ConfirmDeletion/ConfirmDeletion';
import CreateRate from '../../../commonComponents/Modals/CreateRate/CreateRate';
import {
  filterLinksForTabs,
  hasPagePermissionRates,
  lookRateWithPermission,
  lookRatesWithPermission,
  hasPermissions,
} from '../../../utilities/FilterLinksForTabs';
import { browserHistory } from 'react-router';
import UniversalCookies from 'universal-cookie';
import TeamsRatesList from '../../../commonComponents/TeamsRatesList/TeamsRatesList';
const cookies = new UniversalCookies();

class Rates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rate: null,
      page: 1,
      links: [],
      loadingTopics: false,
    };
  }

  componentWillMount() {
    this.props.loadRates();
  }

  componentWillReceiveProps({
    rates,
    topics,
    waiting,
    params,
    rate,
    user,
    rolesPermissions,
    generalRolesPermissions,
    topicTypeCreated,
  }) {
    if (this.state.rate === null && rates.length) {
      if (this.props.params.id) {
        let id = parseInt(this.props.params.id, 10);
        rate = user.isSuperAdmin
          ? rates.filter((rate) => rate.id === id)[0]
          : lookRatesWithPermission('Subtopics', rates, rolesPermissions)
              .length > 0
          ? lookRatesWithPermission('Subtopics', rates, rolesPermissions).find(
              (rateAux) => rateAux.id === id,
            )
          : lookRateWithPermission('Subtopics', rates, rolesPermissions);
        rates.length &&
          this.selectRate(
            rate,
            user,
            rolesPermissions,
            generalRolesPermissions,
            rates,
          );
      } else {
        if (rates.length) {
          if (rate && !rate.id) {
            rate = user.isSuperAdmin
              ? rates[1]
              : lookRateWithPermission('Subtopics', rates, rolesPermissions);
            this.selectRate(
              rate,
              user,
              rolesPermissions,
              generalRolesPermissions,
              rates,
            );
          } else {
            this.selectRate(
              rate,
              user,
              rolesPermissions,
              generalRolesPermissions,
              rates,
            );
          }
        }
      }
    }

    if (waiting !== this.props.waiting && !waiting) {
      this.props.closeModal();
    }

    topics.length && this.setState({ loadingTopics: false });
  }

  selectRate = (
    rate,
    user = {},
    rolesPermissions = this.props.rolesPermissions,
    generalRolesPermissions = this.props.generalRolesPermissions,
    rates = this.props.rates,
  ) => {
    if (
      rate &&
      (cookies.get('isSuperAdmin') === 'true' ||
        hasPagePermissionRates('Subtopics', generalRolesPermissions) ||
        hasPermissions('Subtopics', rolesPermissions, rate))
    ) {
      this.setState({ rate, loadingTopics: true });
      this.props.updateRate(rate);
      this.props.loadTopics(rate.id);
      let links = filterLinksForTabs(
        'Subtopics',
        rolesPermissions,
        rate,
        this.props.links,
        cookies.get('isSuperAdmin') === 'true',
      );
      this.setState({
        links,
      });
    } else {
      if (lookRateWithPermission('References', rates, rolesPermissions)) {
        browserHistory.replace('/home/rates/references');
      } else {
        browserHistory.replace('/home/rates/bibliography');
      }
    }
  };

  createTopic = (name) => {
    this.setState({ loadingTopics: false });
    this.props.createTopic(this.state.rate.id, name);
  };

  createSubtopic = (index, name) => {
    const topic = this.props.topics[index];

    this.props.createSubtopic(topic.id, name);
  };

  updateTopic = (index, name) => {
    const topic = this.props.topics[index];

    this.props.updateTopic(topic.id, name);
  };

  updateSubtopic = (indexTopic, indexSubtopic, name) => {
    const subtopic = this.props.topics[indexTopic].subtopics[indexSubtopic];

    this.props.updateSubtopic(subtopic.id, name);
  };

  deleteTopic = (index) => {
    const topic = this.props.topics[index];

    this.props.openModal({
      modalTitle: 'Deleting a topic',
      modalContent: (
        <ConfirmDeletion
          onSubmit={() => this.props.deleteTopic(topic.id)}
          requesting={this.props.waiting}
          itemName={`the topic ${topic.name}`}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  showCreateRateModal = () => {
    this.props.openModal({
      modalTitle: 'Create a rate',
      modalContent: (
        <CreateRate
          onSubmit={this.props.createRate}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  deleteSubtopic = (indexTopic, indexSubtopic) => {
    const subtopic = this.props.topics[indexTopic].subtopics[indexSubtopic];

    this.props.toggleModalVisibility({
      modalTitle: 'Deleting a subtopic',
      modalContent: (
        <ConfirmDeletion
          onSubmit={() => this.props.deleteSubtopic(subtopic.id)}
          requesting={this.props.waiting}
          itemName={`the subtopic ${subtopic.name}`}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  render() {
    return (
      <Row className="rates-component subtopicsPage">
        <Col
          xs={6}
          md={3}
          lg={3}
          style={{
            paddingRight: '0px',
            paddingLeft: '0px',
            maxWidth: '480px',
            minWidth: '320px',
            overflow: 'visible',
          }}
          className="rates-list-container"
        >
          <div className="ratesListContainer">
            <TeamsRatesList
              rates={this.props.rates}
              selectedRate={this.state.rate}
              onRateClick={this.selectRate}
              subtopicsPage
              user={this.props.user}
              generalRolesPermissions={this.props.generalRolesPermissions}
              rolesPermissions={this.props.rolesPermissions}
            />
          </div>
        </Col>

        {this.state.rate && (
          <Col xs={6} md={9} className="rates-content-container">
            {this.props.waiting ? (
              <div className="subtopics-spinner">
                <Spinner text="Loading topics..." />
              </div>
            ) : (
              <TopicsList
                topicTypeCreated={this.props.topicTypeCreated}
                topicsInmutable={this.props.topicsInmutable}
                topics={this.props.topics}
                waiting={this.props.waiting}
                createTopic={this.createTopic}
                createSubtopic={this.createSubtopic}
                updateTopic={this.updateTopic}
                deleteTopic={this.deleteTopic}
                updateSubtopic={this.updateSubtopic}
                deleteSubtopic={this.deleteSubtopic}
                onOpenModal={this.props.openModal}
                closeModal={this.props.closeModal}
              />
            )}
          </Col>
        )}
      </Row>
    );
  }
}

export default Rates;
