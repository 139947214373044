import React from 'react';
import StartDateItem from './StartDateItem';
import './StartDates.css';

const ranks = ['E4', 'E5', 'E6', 'E7'];
const dutyStates = ['Regular', 'Substitute', 'Reserve'];

class StartDates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { studyPlan } = this.props;
    let startDates = [];
    for (let rank of ranks) {
      for (let dutyState of dutyStates) {
        let dateItem = studyPlan.dates
          ? studyPlan.dates.find(
              d => d.rank === rank && d.dutyState === dutyState
            )
          : null;
        startDates.push({
          rank: rank,
          dutyState: dutyState,
          date: dateItem ? dateItem.date : undefined,
          isApplicable: dateItem ? dateItem.isApplicable : false,
        });
      }
    }
    return (
      <div>
        <div>
          {startDates.map(dateFor => (
            <StartDateItem
              key={dateFor.id}
              startDate={dateFor.date}
              rank={dateFor.rank}
              dutyState={dateFor.dutyState}
              isApplicable={dateFor.isApplicable}
              studyPlan={this.props.studyPlan.id}
              updateStartDate={this.props.updateStartDate}
              disabled={!this.props.canEdit}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default StartDates;
