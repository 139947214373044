import './Mask.css';

import React from 'react';

export default class Mask extends React.Component {
  render() {
    const { handleClickMask } = this.props;
    return (
      <div
        className="mask"
        onClick={(e) => {
          if (
            e &&
            e.target &&
            e.nativeEvent instanceof Event &&
            handleClickMask
          ) {
            handleClickMask(e);
          }
        }}
      />
    );
  }
}
