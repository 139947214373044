// eslint-disable-next-line simple-import-sort/imports
import React from 'react';
import { IndexRoute, Route, Router } from 'react-router';

import AdminsContainer from './components/Admins/components/AdminsContainer';
import MyAccount from './components/Admins/components/MyAccount';
import AssignmentsByWriter from './components/Assignments/components/AssignmentsByWriter/AssignmentsByWriterContainer';
/** *******
 * Pages *
 ******** */
//* * Assignments
import AssignmentsPage from './components/Assignments/components/AssignmentsPage';
import MyAssignmentsPage from './components/Assignments/components/MyAssignments/MyAssignmentsContainer';
import BannersPage from './components/Banners/BannersContainer';
import BibliographiesContainer from './components/Bibliographies/components/BibliographiesContainer';
//* * Books
import BooksContainer from './components/Books/components/BooksContainer';
// ** Customer Submission */
import CustomerSubmissionPage from './components/CustomerSubmission/CustomerSubmissionContainer';
import CyclesContainer from './components/Cycles/components/CyclesContainer';
//* * Dashboard
import Dashboard from './components/Dashboard/Dashboard';
import GroupsContainer from './components/Groups/components/GroupsContainer';
//* * Simple pages
import LoginContainer from './components/Login/components/LoginContainer';
import Logout from './components/Logout/Logout';
import MessageCenterPage from './components/MessageCenter/messageCenterContainer';
//* *  New Coupons
import newCoupons from './components/NewCoupons/newCoupons';
import PermissionsPage from './components/Permissions/components/PermissionsContainer';
//* * Practice Exams
import PracticeExamsContainer from './components/PracticeExams/components/PracticeExamsContainer';
import PromptsPage from './components/Prompts/PromptsContainer';
//* * Quals
import QualsPage from './components/Quals/components/QualsPage';
// import ReferencesContainer from './components/References/components/ReferencesContainer';
//* * Questions
import QuestionsContainer from './components/Questions/components/QuestionsContainer';
//* * Rates
import RatesContainer from './components/Rates/components/RatesContainer';
//* * Referral Program
import ReferralProgramContainer from './components/ReferralProgram/components/ReferralProgramContainer';
import ReportsPage from './components/Reports/components/ReportsContainer';
import ResetPassword from './components/ResetPassword/components/ResetPasswordContainer';
import RolesContainer from './components/Roles/components/RolesContainer';
//* * Search
import SearchContainer from './components/Search/components/SearchContainer';
import SettingsPage from './components/Settings/components/SettingsContainer';
//* * Settings
import DailyQuizContainer from './components/DailyQuiz/DailyQuizContainer';
import StudyProgramsPage from './components/StudyPrograms/StudyProgramsContainer';
//* * 10Week */
import TenWeekPage from './components/TenWeek/components/TenWeekPage';
//* * People
import UsersContainer from './components/Users/components/UsersContainer';
import ForgotPassword from './containers/forgotPasswordContainer';
/** ************
 * Containers *
 ************* */
import Full from './containers/Full';
import MaintenanceContainer from './containers/maintenanceContiner';
import Simple from './containers/Simple';
/** *********
 * HEADERS *
 ********** */
import * as headerOptions from './HeaderOptions';
/** *************
 * MIDDLEWARES *
 ************** */
import {
  checkAuthorization,
  checkAuthorizationAndRedirectToSomeAllowedPage,
  checkLoginAuthorization,
} from './lib/check-auth';
import Loading from './components/Pages/Loading/Loading';
import Page401 from './components/Pages/Page401/Page401';
import Page404 from './components/Pages/Page404';
import Page500 from './components/Pages/Page500';

function onEnter(store, name) {
  return checkAuthorization(store, name);
}

function AppRouter(store) {
  if (process.env.REACT_APP_ON_MAINTENANCE === 'true') {
    return (
      <Route path="*" name="Maintenance" component={MaintenanceContainer} />
    );
  }
  return (
    <Router>
      <Route path="home" name="Home" component={Full}>
        {/*  THE ONLY PURPOOSE OF DASHBOARD COMPONENT IS ONLY TO REDIRECT USER TO SOME ALLOWED PAGE WHEN THE ROUTE IS "HOME"  */}
        <IndexRoute component={Dashboard} onEnter={() => {}} />
        {/* MY-ACCOUNT */}
        <Route
          path="my-account"
          name="my-account"
          header={headerOptions.wheelhouse}
          component={MyAccount}
        >
          {' '}
        </Route>

        {/* DASHBOARD */}
        <Route
          path="wheelhouse"
          name="Wheelhouse"
          header={headerOptions.wheelhouse}
        >
          <IndexRoute
            onEnter={onEnter(store, 'Wheelhouse.Assignments')}
            component={Dashboard}
          />
          <Route
            path="cycles"
            onEnter={onEnter(store, 'Wheelhouse.Cycles')}
            name="Wheelhouse.Cycles"
            component={CyclesContainer}
          />
          <Route
            path="assignments"
            onEnter={onEnter(store, 'Wheelhouse.Assignments')}
            name="Wheelhouse.Assignments"
            component={AssignmentsPage}
          />
          <Route
            path="my-assignments"
            onEnter={onEnter(store, 'Wheelhouse.MyAssignments')}
            name="Wheelhouse.MyAssignments"
            component={MyAssignmentsPage}
          />
          <Route
            path="writers"
            onEnter={onEnter(store, 'Wheelhouse.AssignmentsByWriter')}
            name="Wheelhouse.AssignmentsByWriter"
            component={AssignmentsByWriter}
          />

          <Route
            path="reports"
            onEnter={onEnter(store, 'Wheelhouse.Reports')}
            name="Wheelhouse.Reports"
            component={ReportsPage}
          />

          <Route
            path="message-center"
            onEnter={onEnter(store, 'Wheelhouse.MessageCenter')}
            name="Wheelhouse.MessageCenter"
            component={MessageCenterPage}
          />

          <Route
            path="customer-submission"
            onEnter={onEnter(store, 'Wheelhouse.CustomerSubmission')}
            name="Wheelhouse.CustomerSubmission"
            component={CustomerSubmissionPage}
          />
        </Route>

        {/* ACCOUNTS */}
        <Route
          path="accounts"
          name="Accounts"
          header={headerOptions.wheelhouse}
        >
          <IndexRoute
            onEnter={onEnter(store, 'Students')}
            component={UsersContainer}
          />
          <Route
            path="students"
            onEnter={onEnter(store, 'Students')}
            name="Accounts.Students"
            component={UsersContainer}
            header={headerOptions.wheelhouse}
          />
          <Route path="admins">
            <IndexRoute
              onEnter={onEnter(store, 'Admins')}
              name="Accounts.Admins.List"
              component={AdminsContainer}
              header={headerOptions.wheelhouse}
            />
          </Route>

          <Route
            path="roles"
            onEnter={onEnter(store, 'Roles')}
            name="Accounts.Admins.Roles"
            component={RolesContainer}
            header={headerOptions.wheelhouse}
          />

          <Route
            path="teams"
            onEnter={onEnter(store, 'Teams')}
            name="Accounts.Teams"
            component={GroupsContainer}
          />
        </Route>

        {/* QUALS */}
        <Route
          path="quals"
          header={headerOptions.wheelhouse}
          onEnter={onEnter(store, 'Quals')}
          name="Quals"
          component={QualsPage}
        />

        {/* BOOKS */}
        <Route
          path="books"
          header={headerOptions.wheelhouse}
          name="Books"
          component={BooksContainer}
          onEnter={onEnter(store, 'Books.Books')}
        />

        {/* QUESTIONS (SHOW A SPECIFIC QUESTION) */}
        <Route
          path="questions"
          name="Question Overview"
          header={headerOptions.questions}
        >
          <Route
            onEnter={onEnter(store, 'Books.Books')}
            header={headerOptions.questions}
            name="Question Overview"
            component={QuestionsContainer}
          />
          <Route
            path=":reference/:chapter/:rate/:isQual"
            header={headerOptions.questions}
            name="Question Overview"
            component={QuestionsContainer}
          />
        </Route>

        {/* BIBLIOGRAPHY */}
        <Route
          path="rating-exam"
          header={headerOptions.wheelhouse}
          name="Rating Exams"
          component={BibliographiesContainer}
          onEnter={onEnter(store, 'Bibliography')}
        />

        {/* SUBTOPICS */}
        <Route
          path="subtopics"
          header={headerOptions.wheelhouse}
          name="Subtopics"
          component={RatesContainer}
          onEnter={onEnter(store, 'Subtopics')}
        />

        {/* PRACTICE EXAMS */}
        <Route
          path="practice-exams"
          header={headerOptions.wheelhouse}
          name="Practice Exams"
          component={PracticeExamsContainer}
          onEnter={onEnter(store, 'PracticeExams')}
        />

        {/* REFERRAL PROGRAM */}
        <Route
          path="referral-program"
          header={headerOptions.wheelhouse}
          name="Referral Program"
          component={ReferralProgramContainer}
          onEnter={onEnter(store, 'ReferralProgram')}
        />

        {/* SEARCH */}
        <Route
          path="search"
          header={headerOptions.wheelhouse}
          name="Search"
          component={SearchContainer}
          onEnter={onEnter(store, 'Search')}
        />

        {/* SETTINGS */}
        <Route path="settings" name="People" header={headerOptions.wheelhouse}>
          <Route
            path="keys"
            onEnter={onEnter(store, 'Settings')}
            name="Keys"
            header={headerOptions.wheelhouse}
            component={SettingsPage}
          />
          <Route
            path="permissions"
            onEnter={onEnter(store, 'Settings')}
            name="Account.Admins.Roles"
            component={PermissionsPage}
            header={headerOptions.wheelhouse}
          />
          <Route
            path="banners"
            onEnter={onEnter(store, 'Settings')}
            name="Account.Admins.Roles"
            component={BannersPage}
            header={headerOptions.wheelhouse}
          />
          <Route
            path="rates"
            onEnter={onEnter(store, 'Settings')}
            name="rates"
            header={headerOptions.wheelhouse}
            component={StudyProgramsPage}
          />
          <Route
            path="10week"
            onEnter={onEnter(store, 'Settings')}
            name="10week"
            header={headerOptions.wheelhouse}
            component={TenWeekPage}
          />
          <Route
            path="newCoupons"
            onEnter={onEnter(store, 'Settings')}
            name="NewCoupons"
            header={headerOptions.wheelhouse}
            component={newCoupons}
          />
          <Route
            path="prompts"
            onEnter={onEnter(store, 'Settings')}
            name="Account.Admins.Roles"
            component={PromptsPage}
            header={headerOptions.wheelhouse}
          />
          <Route
            path="dailyQuiz"
            onEnter={onEnter(store, 'Settings')}
            name="DailyQuiz"
            component={DailyQuizContainer}
            header={headerOptions.wheelhouse}
          />
        </Route>
      </Route>

      <Route path="/" name="Pages" component={Simple}>
        <IndexRoute
          onEnter={checkAuthorizationAndRedirectToSomeAllowedPage(store)}
          component={LoginContainer}
        />
        <Route
          path="login"
          name="Login Page"
          component={LoginContainer}
          onEnter={checkLoginAuthorization(store)}
        />
        <Route
          path="/forgot-password"
          name="ForgotPassword"
          component={ForgotPassword}
        />
        <Route
          path="/password-reset"
          name="ResetPassword"
          component={ResetPassword}
        />
        <Route path="401" name="Page 401" component={Page401} />
        <Route path="loading" name="Loading" component={Loading} />
        <Route path="500" name="Page 500" component={Page500} />
        <Route path="logout" name="Logout" component={Logout} />
        <Route
          path="maintenance"
          name="Maintenance"
          component={MaintenanceContainer}
        />
      </Route>
      <Route path="*" name="Page 404" component={Page404} />
    </Router>
  );
}

export default AppRouter;
