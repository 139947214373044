import addAbilityToOpenModal from '../../../../../src/utilities/modalHOC';
import CardRow from './CardRow';
import { connect } from 'react-redux';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default addAbilityToOpenModal(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CardRow),
);
