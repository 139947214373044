import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import loginRequest, {
  authyVerificationRequest,
  resendCodeRequest,
  resetStatus,
} from '../actions';
import {
  getAuthyRequesting,
  getAuthyRetry,
  getAuthyWaiting,
  getEmail,
  getErrors,
  getMessages,
  getRequesting,
  getSuccessful,
} from '../selectors';
import LoginStateful from './LoginStateful';

// Grab only the piece of state we need
const mapStateToProps = (state) => ({
  login: {
    messages: getMessages(state),
    successful: getSuccessful(state),
    requesting: getRequesting(state),
    errors: getErrors(state),
    authy_requesting: getAuthyRequesting(state),
    authy_retry: getAuthyRetry(state),
    email: getEmail(state),
    authy_waiting: getAuthyWaiting(state),
  },
});

// make Redux state piece of `login` and our action `loginRequest`
// available in this.props within our component
const connected = connect(mapStateToProps, {
  loginRequest,
  authyVerificationRequest,
  resendCodeRequest,
  resetStatus,
})(LoginStateful);

// in our Redux's state, this form will be available in 'form.login'
const LoginContainer = reduxForm({
  form: 'login',
})(connected);

export default LoginContainer;
