import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import ConvertHtmlToPlainText from '../../../utilities/ConvertHtmlToPlainText';
import obtainDifferenceBetweenTwoJSON from '../../../utilities/obtainDifferenceBetweenTwoJSON';
import moment from 'moment';

class ChangeHistoryRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  formatQuestion = question => {
    return (
      <div>
        <label className="bold">Multiple option Text: </label>
        <label className="value">
          {question.text ? ConvertHtmlToPlainText(question.text) : 'N/A'}
        </label>
        <label className="bold">Flash question text: </label>
        <label className="value">
          {question.flashQuestionText ? (
            ConvertHtmlToPlainText(question.flashQuestionText)
          ) : (
            'N/A'
          )}
        </label>
        <label className="bold">Answers: </label>
        {question.answers && question.multipleOptionMode ? (
          question.answers.map(answer => this.obtainAnswerLabels(answer))
        ) : (
          question.answers &&
          question.flashMode &&
          !question.multipleOptionMode && (
            <div>
              <label className="value">
                {question.answers.find(answer => answer.correct).text}
              </label>
            </div>
          )
        )}
        {question.definitions && <label className="bold">Definitions: </label>}
        {question.definitions &&
          question.definitions.map(definition =>
            this.definitionsFormat(definition)
          )}
        <label className="bold">Multiple option mode:</label>
        {question.multipleOptionMode ? (
          <label className="value">Yes</label>
        ) : (
          <label className="value">No</label>
        )}
        <label className="bold">Flashcard mode:</label>
        {question.flashMode ? (
          <label className="value">Yes</label>
        ) : (
          <label className="value">No</label>
        )}
      </div>
    );
  };

  obtainAnswerLabels = answer => {
    return (
      <div>
        <label className="bold">Text: </label>
        <label className="value">{answer.text}</label>
        <label className="bold">Order: </label>
        <label className="value">{answer.order}</label>
        <label className="bold">Correct: </label>
        {answer.correct ? (
          <label className="value">Yes</label>
        ) : (
          <label className="value">No</label>
        )}
      </div>
    );
  };

  definitionsFormat = definition => {
    return (
      <div>
        <label className="bold">Term: </label>
        <label className="value">{definition.term}</label>
        <label className="bold">Definition: </label>
        <label className="value">{definition.definition}</label>
      </div>
    );
  };

  changesColumn = question => {
    let changes = obtainDifferenceBetweenTwoJSON(
      question.oldQuestion,
      question.newQuestion
    );
    return (
      <div>
        {changes.text && (
          <div>
            <label className="bold">Multiple option Text:</label>
            <label className="value">
              {ConvertHtmlToPlainText(changes.text)}
            </label>
          </div>
        )}
        {changes.flashQuestionText && (
          <div>
            <label className="bold">Flash question text:</label>
            <label className="value">
              {ConvertHtmlToPlainText(changes.flashQuestionText)}
            </label>
          </div>
        )}
        {question.oldQuestion.answers &&
        question.newQuestion.answers && (
          <div>
            {question.newQuestion.answers.map(answer => {
              let oldAnswer = question.oldQuestion.answers.find(
                answerAux => answerAux.id === answer.id
              );
              if (oldAnswer.text !== answer.text) {
                return (
                  <div>
                    <label className="bold">Answers: </label>
                    <label>{this.obtainAnswerLabels(answer)}</label>
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
        {question.oldQuestion.definitions &&
        question.newQuestion.definitions &&
        question.oldQuestion.definitions.length > 0 &&
        question.newQuestion.length > 0 && (
          <div>
            {question.newQuestion.definitions.map(definition => {
              let oldDefinition = question.oldQuestion.definitions.find(
                definitionAux => definitionAux.id === definition.id
              );
              if (
                oldDefinition.definition !== definition.definition ||
                oldDefinition.term !== definition.term
              ) {
                return (
                  <div>
                    <label className="bold">Definitions: </label>
                    <label>{this.definitionsFormat(definition)}</label>
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
        {changes.multipleOptionMode && (
          <div>
            <label className="bold">Multiple option mode:</label>
            <label className="value">
              {changes.multipleOptionMode ? (
                <label className="value">Yes</label>
              ) : (
                <label className="value">No</label>
              )}
            </label>
          </div>
        )}
        {changes.flashMode && (
          <div>
            <label className="bold">Flashcard mode:</label>
            <label className="value">
              {changes.flashMode ? (
                <label className="value">Yes</label>
              ) : (
                <label className="value">No</label>
              )}
            </label>
          </div>
        )}
      </div>
    );
  };

  getItem = () => {
    return (
      <Row className={this.props.style}>
        <Col sm={2}>
          <div className="change-text">
            {this.props.item.createdBy.name +
              ' ' +
              this.props.item.createdBy.lastName}
          </div>
        </Col>
        <Col sm={2}>
          <div className="change-text">
            {moment(this.props.item.updatedAt).format(
              'dddd, MMMM Do YYYY, h:mm:ss a'
            )}
          </div>
        </Col>
        <Col sm={3}>
          <div className="change-text">
            {this.formatQuestion(this.props.item.oldQuestion)}
          </div>
        </Col>
        <Col sm={3}>
          <div className="change-text">
            {this.formatQuestion(this.props.item.newQuestion)}
          </div>
        </Col>
        <Col sm={2}>{this.changesColumn(this.props.item)}</Col>
      </Row>
    );
  };

  render() {
    return <div>{this.getItem()}</div>;
  }
}

ChangeHistoryRow.propTypes = {
  item: PropTypes.object,
};

export default ChangeHistoryRow;
