import * as React from 'react';
import CycleLine from './CycleLine';

import './CyclesList.css';

export default function CycleList({
  title,
  cycles,
  selected,
  onCycleClick,
  onPlusClick,
  showNewCycle,
}) {
  return (
    <div className="cycles-list-component">
      <div className="cycles-title">
        {title}
        {onPlusClick &&
        showNewCycle && (
          <i className="fa fa-plus fa-lg mt-1" onClick={onPlusClick} />
        )}
        {title || onPlusClick ? <hr /> : ''}
      </div>
      <div className="cycles-list">
        {cycles.length ? (
          cycles.map(cycle => (
            <p key={`cycles-link-${cycle.id}`} className="cycleLineHover">
              <CycleLine
                onClick={() => onCycleClick(cycle)}
                name={`${cycle.title} - ${cycle.description}`}
                selected={selected && cycle.id === selected.id}
                id={cycle.id}
                active={cycle.isActive}
              />
            </p>
          ))
        ) : (
          <p>No Cycles</p>
        )}
      </div>
    </div>
  );
}
