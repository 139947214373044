import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getStateMessageCenter(state) {
  return getStateWithOnlyOneLevel(state, 'messageCenter');
}

export const getAllRatesSelectors = state =>
  getStateMessageCenter(state).get('rates');

export const getRateSelector = state =>
  getStateMessageCenter(state).get('rate');

export const loadNoticationsSelectors = state =>
  getStateMessageCenter(state).get('loadNotifications');

export const getNotificationsSelectors = state =>
  getStateMessageCenter(state).get('notifications');
