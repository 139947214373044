import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import './RolesEditor.css';

import React from 'react';
import Button from 'react-bootstrap-button-loader';
import CheckboxTree from 'react-checkbox-tree';

import addAbilityToOpenModal from '../../utilities/modalHOC';
import ConfirmDeletion from '../Modals/ConfirmDeletion/ConfirmDeletion';

class RolesEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRole: null,
      roleName: '',

      checks: [],
      expanded: [],
    };
  }

  componentWillReceiveProps({ creating, deleting, roles, selectedRole }) {
    const was = this.props;

    if (was.creating && !creating && roles.length) {
      this.selectRole(roles[roles.length - 1].id);
    }

    if (was.deleting && !deleting) {
      this.props.toggleNewRole(false);
      this.setState({ selectedRole: null, roleName: '' });
    }

    if (this.props.selectedRole !== selectedRole && selectedRole.id) {
      this.props.updateChecks(
        selectedRole[this.props.permissionsAttribute].map(
          (permission) => permission.id,
        ),
      );
    }
  }

  selectRole = (roleId) => {
    const selectedRole = this.props.roles.find(
      (role) => role.id === Number(roleId),
    );

    if (selectedRole) {
      this.props.updateChecks(
        selectedRole[this.props.permissionsAttribute].map(
          (permission) => permission.id,
        ),
      );
      this.setState({
        selectedRole,
        roleName: selectedRole.name,
        addMode: false,
      });
    }
  };

  static convertPermissionsForTreeview = (permissions) => {
    const validPermissions = permissions.filter(
      (permission) => permission.display,
    );
    const convertedPermissions = validPermissions
      .map((permission) => {
        const permissionHierarchy = permission.display.split('.');

        if (permissionHierarchy.length === 1) {
          const subpermissions = validPermissions.filter(
            (p) =>
              p.id !== permission.id &&
              p.display.startsWith(permissionHierarchy[0]),
          );
          return {
            value: permission.id.toString(),
            label: (
              <span title={permission.description}>{permission.display}</span>
            ),
            children: subpermissions.map((subpermission) => ({
              value: subpermission.id.toString(),
              label: (
                <span title={subpermission.description}>
                  {subpermission.display.split('.')[1] === 'References'
                    ? 'Books'
                    : subpermission.display.split('.')[1]}
                </span>
              ),
            })),
          };
        }
        return null;
      })
      .filter((permission) => permission);
    return convertedPermissions;
  };

  // ToDo: (JR) - I commented out this function because it seems to be breaking the "quals" role checkbox.
  //  I was unable to ascertain the purpose of this function, but when I remove it the checkbox works properly so I am
  //  leaving it here until we have had the chance to test this further.  If no side-effects are reported we can remove
  //  this code altogether.

  // convertCheckedValues(checks) {
  //   let subtopicsId = 8;
  //   let bibliographyId = 9;
  //   let referencesId = 10;
  //   let qualsId = 11;
  //   if (this.props.permissionsAttribute === 'pagePermissions') {
  //     subtopicsId = 15;
  //     bibliographyId = 16;
  //     referencesId = 17;
  //     qualsId = 18;
  //   }
  //   if (
  //     !checks.find(
  //       check =>
  //         Number(check) === subtopicsId ||
  //         Number(check) === bibliographyId ||
  //         Number(check) === referencesId ||
  //         Number(check) === qualsId
  //     ) &&
  //     checks.find(check => Number(check) === '11')
  //   ) {
  //     checks = checks.filter(check => Number(check) !== '11');
  //   } else {
  //     if (
  //       checks.find(
  //         check =>
  //           Number(check) === subtopicsId ||
  //           Number(check) === bibliographyId ||
  //           Number(check) === referencesId ||
  //           Number(check) === qualsId
  //       ) &&
  //       !checks.find(check => Number(check) === '11')
  //     ) {
  //       checks.push('11');
  //     }
  //   }
  //   return checks;
  // }

  submitRole = (event) => {
    event.preventDefault();
    const role = {
      id: this.props.selectedRole && this.props.selectedRole.id,
      name: this.props.roleName,
      [this.props.permissionsAttribute]: this.props.checks.map(Number),
    };

    this.props.selectedRole
      ? this.props.onUpdate(role)
      : this.props.onCreate(role);
  };

  deleteRole = () => {
    this.props.openModal({
      modalTitle: `Deleting Role: ${this.props.selectedRole.name}`,
      modalContent: (
        <ConfirmDeletion
          text={
            <span>
              Enter the word DELETE to confirm deletion.
              <br />
              <br />
              Please take in consideration that if you remove this role, it will
              affect any user that is associated to it, causing the users to
              lose permissions.
            </span>
          }
          onSubmit={() => {
            this.props.closeModal();
            this.props.onDelete(this.props.selectedRole);
          }}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  updateChecked = (checks) => {
    // ToDo: (JR) - See comments above this function for more information.
    // checks = this.convertCheckedValues(checks);

    this.props.updateChecks(checks);
    // this.setState({ checks });
  };

  getExpanded = (permissions) =>
    permissions
      .filter((permission) => permission.display)
      .map((permission) => permission.id.toString());

  render() {
    const nodes = RolesEditor.convertPermissionsForTreeview(
      this.props.permissions || [],
    );
    const checks = this.props.checks.map((check) => check.toString());

    return (
      <div className="role-editor-component">
        {((this.props.selectedRole && this.props.selectedRole.id) ||
          this.props.newRoleMode) && (
          <div>
            <form>
              <div className="form-group row">
                <div className="col-md-12">
                  <input
                    className="form-control"
                    placeholder="Role Name"
                    value={this.props.roleName}
                    onChange={(e) => this.props.updateRoleName(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-12">
                  <CheckboxTree
                    nodes={nodes}
                    checked={checks || []}
                    expanded={this.getExpanded(this.props.permissions)}
                    onCheck={(checks) => this.updateChecked(checks)}
                    onExpand={(expanded) => this.setState({ expanded })}
                    showNodeIcon={false}
                    iconsClass="fa5"
                  />
                </div>
              </div>

              {false && (
                <div className="buttons">
                  {this.props.selectedRole && this.props.selectedRole.id && (
                    <Button
                      variant="danger"
                      onClick={this.deleteRole}
                      loading={this.props.deleting}
                      disabled={this.props.creating || this.props.updating}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    type="submit"
                    variant="success"
                    loading={this.props.creating || this.props.updating}
                    disabled={this.props.deleting}
                  >
                    {this.props.selectedRole ? 'Update' : 'Create'}
                  </Button>
                </div>
              )}
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default addAbilityToOpenModal(RolesEditor);
