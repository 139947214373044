import Notifications from 'react-notification-system-redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { closeModal } from '../../containers/Full/actions';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import * as api from '../../utilities/ServiceManager';
import {
  addCodeGroup,
  addCoupon,
  cancelAllCodes,
  cancelCode,
  setBadRequest,
  setCodeGroup,
  setCodeGroups,
  setCouponDetail,
  setCoupons,
  setCouponsReferral,
  setDeleteCoupon,
  setGettingCodeGroup,
  setLoadCoupons,
  setLoadingCodeGroups,
  setLoadingCoupons,
  setNewCoupons,
  setPlansSubscriptions,
  setRedeemedCodes,
  setSelectedCoupon,
  setSelectedCouponReferral,
  setUnredeemedCodes,
  setWaitingResponse,
  updateCodeGroup,
  updateCoupon,
  updateNewCoupons,
  updateSubcodes,
} from './actions';
import { loadCodeGroup, loadCouponsReferral } from './actionsSagas';
import {
  CANCEL_CODE,
  CANCELL_ALL_CODES,
  CREATE_CODE_GROUPS,
  CREATE_COUPON,
  CREATE_COUPON_REFERRAL,
  CREATE_NEW_COUPON,
  CREATE_SUBCODES,
  DELETE_DISABLE_COUPON,
  DOWNLOAD_UNREDEEMED_CODES,
  EDIT_CODE_GROUP,
  EDIT_COUPON,
  EDIT_NEW_COUPON,
  GET_CODE_GROUP,
  GET_REDEEMS,
  INSERT_COUPON_REFERRAL,
  LIST_PLANS_SUBSCRIPTIONS,
  LOAD_CODE_GROUPS,
  LOAD_COUPONS,
  LOAD_COUPONS_REFERRAL,
  LOAD_NEW_COUPONS,
  LOAD_SUBCODES,
} from './actionTypesSagas';

/**
 * LOAD Coupons
 */
function* loadCouponsSaga() {
  yield takeLatest(LOAD_COUPONS, loadCoupons);
}

function* loadCouponsReferralSaga() {
  yield takeLatest(LOAD_COUPONS_REFERRAL, loadCouponsReferralFromDB);
}

function* createCouponReferralSaga() {
  yield takeLatest(CREATE_COUPON_REFERRAL, createCouponReferral);
}

function* insertCouponReferralSaga() {
  yield takeLatest(INSERT_COUPON_REFERRAL, insertCouponReferral);
}

function* loadCoupons(action) {
  try {
    const coupons = yield call(api.loadCoupons);
    yield put(setCoupons(coupons));
    if (coupons && coupons.length > 0) {
      yield put(setSelectedCoupon(coupons[0]));
    }
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load quals. Please try again later.',
      ),
    );
  }
}

function* loadCouponsReferralFromDB() {
  try {
    yield put(setLoadingCoupons(true));
    const couponsReferral = yield call(api.getCouponsReferral);
    yield put(setLoadingCoupons(false));
    couponsReferral = couponsReferral.sort(
      (a, b) => a.coupon.percentageOff - b.coupon.percentageOff,
    );
    yield put(setCouponsReferral(couponsReferral));
  } catch (exception) {
    yield put(
      showNotificationErrorFromInsertCoupon(
        'Could not create a coupon. Please try again later.',
      ),
    );
  }
  yield put(setLoadingCoupons(false));
}

function* createCouponReferral(action) {
  try {
    yield put(setBadRequest(false));
    yield put(setWaitingResponse(true));
    const couponReferral = yield call(
      api.createCouponReferral,
      action.couponData,
    );
    yield put(setSelectedCouponReferral(couponReferral));
    yield put(loadCouponsReferral());
    yield put(setWaitingResponse(false));
  } catch (exception) {
    yield put(setBadRequest(true));
    yield put(
      showNotificationErrorFromInsertCoupon(
        'Could not create a coupon. Please try again later.',
      ),
    );
    yield put(setWaitingResponse(false));
  }
}

function* insertCouponReferral(action) {
  try {
    yield put(setBadRequest(false));
    yield put(setWaitingResponse(true));
    const couponReferral = yield call(
      api.insertCouponReferral,
      action.couponData,
    );
    yield put(setSelectedCouponReferral(couponReferral));
    yield put(loadCouponsReferral());
    yield put(setWaitingResponse(false));
  } catch (exception) {
    yield put(setBadRequest(true));
    yield put(
      showNotificationErrorFromInsertCoupon(
        'Not found coupon for this Stripe Code or the coupon you wanted to insert does not correspond to the corresponding percentage',
      ),
    );
    yield put(setWaitingResponse(false));
  }
}

/**
 * LOAD Code Groups
 */
function* loadCodeGroupsSaga() {
  yield takeLatest(LOAD_CODE_GROUPS, loadCodeGroups);
}

function* loadCodeGroups(action) {
  try {
    yield put(setLoadingCodeGroups(true));
    const codeGroups = yield call(api.loadCodeGroups, action.coupon);
    yield put(setCodeGroups(codeGroups));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load quals. Please try again later.',
      ),
    );
  } finally {
    yield put(setLoadingCodeGroups(false));
  }
}

/* New coupons */
function* loadNewCouponsSaga() {
  yield takeLatest(LOAD_NEW_COUPONS, loadNewCoupons);
}

function* loadNewCoupons() {
  try {
    const response = yield call(api.loadNewCoupons);
    yield put(setNewCoupons(response));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load coupons. Please try again later.',
      ),
    );
  }
}

function* createCouponSaga() {
  yield takeLatest(CREATE_NEW_COUPON, createNewCoupon);
}

function* createNewCoupon(action) {
  try {
    yield put(setLoadCoupons(true));
    yield call(api.createNewCouponService, action.coupon);
    yield put(setCouponDetail(action.coupon ? action.coupon.name : ''));

    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Coupon created successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );

    yield put(closeModal());
    yield put(setLoadCoupons(false));
  } catch (exception) {
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message:
            'The coupon does not exist or something went wrong.\n Please try again later.',
        },
        'error',
      ),
    );
    yield put(setLoadCoupons(false));
    yield put(closeModal());
  }
}

function* deleteDisableCouponSaga() {
  yield takeLatest(DELETE_DISABLE_COUPON, deleteDisableCoupon);
}

function* deleteDisableCoupon(action) {
  try {
    if (action.value === 'Delete') {
      yield put(setDeleteCoupon(true));
      const coupon = yield call(
        api.deleteNewCouponCouponService,
        action.coupon,
        action.value,
      );
      yield put(setDeleteCoupon(false));
      yield put(setCouponDetail(''));
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message: 'Coupon deleted successfully!',
            autoDismiss: 1,
          },
          'success',
        ),
      );
    } else {
      yield put(setDeleteCoupon(true));
      const coupon = yield call(
        api.disableNewCouponCouponService,
        action.coupon,
        action.value,
      );
      yield put(setDeleteCoupon(false));
    }
  } catch (exception) {
    yield put(
      Notifications.show(
        { title: 'Error!', message: 'Error.Please try again later' },
        'error',
      ),
    );
  }
}

function* listPlanSubscriptionsSaga() {
  yield takeLatest(LIST_PLANS_SUBSCRIPTIONS, listSubscriptions);
}

function* listSubscriptions(action) {
  try {
    const plans = yield call(api.loadPlans, action.codeGroup);
    yield put(setPlansSubscriptions(plans));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load plans. Please try again later.',
      ),
    );
  }
}

/**
 * Download unredeemed codes
 */
function* downloadUnredeemedCodesSaga() {
  yield takeLatest(DOWNLOAD_UNREDEEMED_CODES, downloadUnredeemedCodes);
}

function* downloadUnredeemedCodes(action) {
  try {
    const codes = yield call(api.loadUnredeemedCodes, action.codeGroup);
    yield put(setUnredeemedCodes(codes));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load unredeemed codes. Please try again later.',
      ),
    );
  }
}

/**
 * GET Code Group
 */
function* getCodeGroupSaga() {
  yield takeLatest(GET_CODE_GROUP, getCodeGroup);
}

function* getCodeGroup(action) {
  try {
    yield put(setGettingCodeGroup(true));
    const codeGroup = yield call(
      api.getCodesFromCodeGroup,
      action.codeGroup,
      action.fromValue,
      action.toValue,
    );
    yield put(setCodeGroup(codeGroup));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load code group. Please try again later.',
      ),
    );
  } finally {
    yield put(setGettingCodeGroup(false));
  }
}

/**
 * GET Redeemed Codes
 */
function* getRedeemedCodesSaga() {
  yield takeLatest(GET_REDEEMS, getRedeemedCodes);
}

function* getRedeemedCodes(action) {
  try {
    const redeemedCodes = yield call(api.getRedeemedCodes, action.codeGroup);
    yield put(setRedeemedCodes(redeemedCodes));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load redeemed codes. Please try again later.',
      ),
    );
  }
}

/**
 * LOAD Code Groups
 */
function* createCodeGroupsSaga() {
  yield takeLatest(CREATE_CODE_GROUPS, createCodeGroups);
}

function* createCodeGroups(action) {
  try {
    const codeGroup = yield call(api.createCodeGroup, action.codeGroup);
    yield put(addCodeGroup(codeGroup));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Code Group created successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  } finally {
    yield put(closeModal());
  }
}

/**
 * LOAD Code Groups
 */
function* cancelAllCodeGroupCodesSaga() {
  yield takeLatest(CANCELL_ALL_CODES, cancelAllCodeGroupCodes);
}

function* cancelAllCodeGroupCodes(action) {
  try {
    yield call(api.cancelAllCodeGroupCodes, action.codeGroup);
    yield put(cancelAllCodes(action.codeGroup));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Codes cancelled successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  }
}

/**
 * LOAD Code Groups
 */
function* editCodeGroupSaga() {
  yield takeLatest(EDIT_CODE_GROUP, editCodeGroup);
}

function* editCodeGroup(action) {
  try {
    const codeGroup = yield call(api.editCodeGroup, action.codeGroup);
    yield put(updateCodeGroup(codeGroup));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Codes edited successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  } finally {
    yield put(closeModal());
  }
}

function* editNewCouponSaga() {
  yield takeLatest(EDIT_NEW_COUPON, editNewCoupon);
}

function* editNewCoupon(action) {
  try {
    const coupons = yield call(
      api.editNewCoupon,
      action.updatedCoupon,
      action.couponName,
    );
    yield put(updateNewCoupons(coupons));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Coupon edited successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  } finally {
    yield put(closeModal());
  }
}

function* loadSubcodesSagas() {
  yield takeLatest(LOAD_SUBCODES, loadSubcodes);
}

function* loadSubcodes(action) {
  try {
    const subcodes = yield call(api.loadSubcodes, action.couponName);
    yield put(updateSubcodes(action.couponName, subcodes));
    // yield put(
    //   Notifications.show(
    //     { title: 'Done!', message: 'Subcodes created successfully!' },
    //     'success',
    //   ),
    // );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  } finally {
  }
}

function* createSubcodesSagas() {
  yield takeLatest(CREATE_SUBCODES, createSubcodes);
}

function* createSubcodes(action) {
  try {
    const subcodes = yield call(api.createSubcodes, action.couponName);
    yield put(updateSubcodes(action.couponName, subcodes));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Subcodes created successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  } finally {
  }
}

function* editCouponSaga() {
  yield takeLatest(EDIT_COUPON, editCoupon);
}

function* editCoupon(action) {
  try {
    const coupon = yield call(api.editCoupon, action.coupon);
    yield put(updateCoupon(coupon));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Coupon edited successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  } finally {
    yield put(closeModal());
  }
}

function* cancelCodeSaga() {
  yield takeLatest(CANCEL_CODE, cancelCodeFromCodeGroup);
}

function* cancelCodeFromCodeGroup(action) {
  try {
    yield call(api.cancelCode, action.code);
    yield put(cancelCode(action.code));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Code cancelled successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    yield put(
      loadCodeGroup(action.codeGroup, action.fromValue, action.toValue),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  }
}

/**
 * DEFAUL EXPORT
 */
export default function* sagas() {
  yield all([
    loadCouponsSaga(),
    loadCodeGroupsSaga(),
    loadNewCouponsSaga(),
    createCodeGroupsSaga(),
    cancelAllCodeGroupCodesSaga(),
    editCodeGroupSaga(),
    createCouponSaga(),
    editCouponSaga(),
    getCodeGroupSaga(),
    cancelCodeSaga(),
    getRedeemedCodesSaga(),
    downloadUnredeemedCodesSaga(),
    loadCouponsReferralSaga(),
    createCouponReferralSaga(),
    editNewCouponSaga(),
    createSubcodesSagas(),
    loadSubcodesSagas(),
    insertCouponReferralSaga(),
    deleteDisableCouponSaga(),
    listPlanSubscriptionsSaga(),
  ]);
}

function showNotificationErrorFromException(exception) {
  const message = getRequestErrorMessage(exception);
  return Notifications.show({ title: 'Ops!', message }, 'error');
}

function showNotificationErrorFromInsertCoupon(message) {
  return Notifications.show({ title: 'Ops!', message }, 'error');
}
