import * as type from './actionTypes';

export const setSelectedStudyPlan = studyPlan => ({
  type: type.SET_SELECTED_STUDY_PLAN,
  studyPlan,
});

export const setStudyPlans = studyPlans => ({
  type: type.SET_STUDY_PLANS,
  studyPlans,
});

export const addStudyPlan = studyPlan => ({
  type: type.ADD_STUDY_PLAN,
  studyPlan,
});

export const updateStudyPlan = studyPlan => ({
  type: type.UPDATE_STUDY_PLAN,
  studyPlan,
});

export const setSelectedStudyPlanUser = studyPlanUser => ({
  type: type.SET_SELECTED_STUDY_PLAN_USER,
  studyPlanUser,
});

export const setStudyPlansUsers = (studyPlansUsers, page) => ({
  type: type.SET_STUDY_PLANS_USERS,
  studyPlansUsers,
  page,
});

export const updateSelectedTenWeekListTab = selectedTenWeekListTab => ({
  type: type.UPDATE_SELECTED_TEN_WEEK_LIST_TAB,
  selectedTenWeekListTab,
});

export const setPreviewPlans = previewPlans => ({
  type: type.SET_PREVIEW_PLANS,
  previewPlans,
});

export const setLoadiingUser = isLoading => ({
  type: type.SET_LOADING_USERS,
  isLoading,
});
