import './ReviewHistoryAssignment.css';

import React from 'react';

import ConfirmationReason from '../../../commonComponents/Modals/ConfirmationReason/ConfirmationReason';
import { getStatusByName } from '../../../constants/assignments';
import QuestionsContainer from '../../Questions/components/QuestionsContainer';

class ReviewHistoryAssignment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '1',
      waiting: false,
      isInQuestionDetails: false,
      isInHistoryView: false,
    };
  }

  selectFilter(filter) {
    this.setState({ filter });
  }

  onApproveAssignment = () => {
    const editedAssignment = {
      id: this.props.assignment.id,
      status: getStatusByName('Published'),
      writerEmail: this.props.assignment.writerEmail
        ? this.props.assignment.writerEmail
        : null,
    };
    this.props.onEditAssignment(editedAssignment);
  };

  onCancelAssignment = () => {
    this.props.openModal({
      modalContent: (
        <ConfirmationReason
          onSubmit={(fields) => {
            const updatedAssignment = {
              id: this.props.assignment.id,
              status: 'In_Progress',
              reason: fields.reason,
            };
            this.props.cancelAssignment(updatedAssignment);
          }}
          onCancel={this.props.closeModal}
          bodyText="You are about to reject an assignment"
          submitBtnText="Reject"
        />
      ),
      modalClassName:
        'modal-create-chapter grey-modal-body manual-red-component',
    });
  };

  render() {
    return (
      <div className="review-history-assigment-component">
        <QuestionsContainer
          assignment={this.props.assignment}
          onQuestionDetailEnter={() =>
            this.setState({ isInQuestionDetails: true })
          }
          onQuestionDetailBack={() =>
            this.setState({ isInQuestionDetails: false })
          }
          onHistoryViewEnter={() => this.setState({ isInHistoryView: true })}
          onHistoryViewBack={() => this.setState({ isInHistoryView: false })}
          isInHistoryView
          onApproveAssignment={this.onApproveAssignment}
          onCancelAssignment={this.onCancelAssignment}
        />
      </div>
    );
  }
}

export default ReviewHistoryAssignment;
