import axios from 'axios';

import setAuthorizationToken from './setAuthorizationToken';
import { removeUserToken } from './userToken';

export default function redirectTo401IfForbidden() {
  const errorStatus = 403;

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { response } = error;

        if (response.status === 401) {
          removeUserToken();
        }

        if (response && response.status === errorStatus) {
          window.location.href = '/401';
        }
      }
      return Promise.reject(error);
    },
  );
}
