import * as Sentry from '@sentry/browser';
import { browserHistory } from 'react-router';
import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';
import Cookies from 'universal-cookie';

import { cookiesExpirationTime } from '../../constants/cookiesExpirationTime';
import { redirect as redirectGivenPermissions } from '../../lib/check-auth';
import getCookieTokenDomain from '../../utilities/getCookieTokenDomain';
import { GENERIC_ERROR_MESSAGE } from '../../utilities/SagasHelper';
import * as api from '../../utilities/ServiceManager';
import { requestPassword as requestPasswordService } from '../../utilities/ServiceManager';
import setAuthorizationToken from '../../utilities/setAuthorizationToken';
import * as userToken from '../../utilities/userToken';
import { setMessage } from '../Admins/actions';
import { REQUEST_PASSWORD } from '../Admins/actionTypes';
import { setFilters } from '../Books/actions';
import { setUser, unsetUser } from '../User/actions';
import { USER_UNSET, VERIFY_IS_MAINTENANCE } from '../User/constants';
import {
  AUTHY_ERROR,
  AUTHY_REQUESTING,
  AUTHY_VERIFICATION_REQUEST,
  LOGIN_DEFAULT,
  LOGIN_ERROR,
  LOGIN_REQUESTING,
  LOGIN_SUCCESS,
  LOGOUT_USER_SAGAS,
  RESEND_CODE_DONE,
  RESEND_CODE_REQUEST,
  RESET_STATUS,
} from './constants';

const cookies = new Cookies();

function* logout() {
  // const cookieTokenDomain = getCookieTokenDomain(window.location);
  yield put(unsetUser());
  Sentry.setUser({});
  // cookies.remove('token', { path: '/', domain: cookieTokenDomain });
  userToken.removeUserToken();
  setAuthorizationToken();
  localStorage.clear();
  sessionStorage.clear();
  sessionStorage.removeItem('persist:root');
  browserHistory.replace('/');
}

export function redirect(pagePermissions, rolesPermissions) {
  /* Previous location */
  const previousUrl = sessionStorage.getItem('url_before_redirected_to_login');
  if (previousUrl) {
    if (previousUrl.includes('maintenance')) {
      browserHistory.replace('/home/wheelhouse/assignments');
    } else {
      browserHistory.replace(previousUrl);
    }
    sessionStorage.removeItem('url_before_redirected_to_login');
    return;
  }
  redirectGivenPermissions(pagePermissions, rolesPermissions);
}

function* loginFlow(email, password) {
  let response;
  try {
    const userCookie = cookies.get(String(email));

    response = yield call(api.login, email, password, userCookie);
    // const cookieTokenDomain = getCookieTokenDomain(window.location);
    if (response.token) {
      yield put(setUser(response.token));
      Sentry.setUser({
        email: response.user.email,
        id: response.user.id,
      });

      if (response.hasToUse2FA) {
        if (userCookie !== undefined && userCookie.key) {
          yield put(setUser(response.token));
          yield put({ type: LOGIN_SUCCESS, payload: response });
          yield put(setFilters(response.user.filters));
          /* cookies.set('token', response.token, {
            path: '/',
            domain: cookieTokenDomain,
            expires: cookiesExpirationTime(),
          }); */
          userToken.setUserToken(response.token);
          setAuthorizationToken(response.token);
          redirect(
            response.roles.generalRolesPermissions,
            response.roles.rolesPermissions,
          );
        } else {
          yield put({ type: AUTHY_REQUESTING, payload: response });
        }
      } else {
        yield put(setUser(response.token));
        yield put({ type: LOGIN_SUCCESS, payload: response });
        yield put(setFilters(response.user.filters));
        /* cookies.set('token', response.token, {
          path: '/',
          domain: cookieTokenDomain,
          expires: cookiesExpirationTime(),
        }); */
        userToken.setUserToken(response.token);
        setAuthorizationToken(response.token);
        redirect(
          response.roles.generalRolesPermissions,
          response.roles.rolesPermissions,
        );
      }
    }
  } catch (error) {
    yield put({ type: LOGIN_ERROR, error });
  } finally {
    if (yield cancelled()) {
      browserHistory.replace('/');
    }
  }

  return response;
}

function* loginWatcher() {
  while (true) {
    const { email, password } = yield take(LOGIN_REQUESTING);
    const task = yield fork(loginFlow, email, password);
    const action = yield take([USER_UNSET, LOGIN_ERROR]);
    if (action.type === USER_UNSET) yield cancel(task);
    yield call(logout);
  }
}

function* resendCodeRequestWatcher() {
  yield takeLatest(RESEND_CODE_REQUEST, resendCodeRequest);
}

function* resendCodeRequest(type) {
  const email = type.email;
  try {
    yield call(api.resendCode, email);
    yield put({ type: RESEND_CODE_DONE, payload: { email } });
  } catch (error) {
    yield put({ type: AUTHY_ERROR, error });
  }
}

function* resetStatusWatcher() {
  yield takeLatest(RESET_STATUS, resetStatus);
}

export function* watchUserRequestPassword() {
  yield takeLatest(REQUEST_PASSWORD, requestPassword);
}

function* resetStatus() {
  yield put({ type: LOGIN_DEFAULT });
  location.reload();
}

function* authyVerificationRequestWatcher() {
  yield takeLatest(AUTHY_VERIFICATION_REQUEST, authyVerificationRequest);
}

function* authyVerificationRequest(type) {
  const code = type.code;
  const email = type.email;
  let response;
  const cookieTokenDomain = getCookieTokenDomain(window.location);
  try {
    response = yield call(api.verificateAuthyCode, {
      code,
      email,
    });
    if (response.token) {
      yield put(setUser(response.token));
      yield put({ type: LOGIN_SUCCESS, payload: response });
      yield put(setFilters(response.user.filters));
      /* cookies.set('token', response.token, {
        path: '/',
        domain: cookieTokenDomain,
        expires: cookiesExpirationTime(),
      }); */
      userToken.setUserToken(response.token);
      const now = new Date();
      cookies.set(
        String(email),
        { key: email },
        { path: '/', expires: new Date(now.getTime() + 31536000000) },
      );
      setAuthorizationToken(response.token);
      redirect(
        response.roles.generalRolesPermissions,
        response.roles.rolesPermissions,
      );
    }
  } catch (error) {
    yield put({ type: AUTHY_ERROR, error });
  }
}

export function* requestPassword(action) {
  try {
    yield put(setMessage(''));
    const response = yield call(requestPasswordService, action.email);
    if (response) {
      yield put(setMessage('true'));
    } else {
      yield put(
        setMessage('This email does not exist in database, please check it'),
      );
    }
  } catch (exception) {
    yield put(
      setMessage(
        exception.response ? exception.response.data : GENERIC_ERROR_MESSAGE,
      ),
    );
  }
}

/**
 * Update study programs of reference
 */
function* logoutSagasWatcher() {
  yield takeLatest(LOGOUT_USER_SAGAS, logoutSagas);
}

function* logoutSagas() {
  try {
    yield call(api.logout);
  } catch (exception) {}
}

function* verifyIsMaintenanceSagaWatcher() {
  yield takeLatest(VERIFY_IS_MAINTENANCE, verifyIsMaintenanceSaga);
}

export function* verifyIsMaintenanceSaga() {
  try {
    const response = yield call(api.getLoggedAdmin);
    if (!response.isMaintenance) {
      browserHistory.replace('/home/wheelhouse/assignments');
    }
  } catch (error) {}
}

export default function* sagas() {
  yield all([
    loginWatcher(),
    logoutSagasWatcher(),
    verifyIsMaintenanceSagaWatcher(),
    authyVerificationRequestWatcher(),
    resendCodeRequestWatcher(),
    resetStatusWatcher(),
    watchUserRequestPassword(),
  ]);
}
