export const SET_ADMINS_BY_RATE = 'assignments/SET_ADMINS_BY_RATE';
export const SET_ACTIVE_STATUS = 'assignments/SET_ACTIVE_STATUS';
export const SET_SELECTED_ADMIN = 'assignments/SET_SELECTED_ADMIN';
export const SET_SELECTED_RATE = 'assignments/SET_SELECTED_RATE';
export const UPDATE_ASSIGNMENT_DUE_DATE =
  'assignments/UPDATE_ASSIGNMENT_DUE_DATE';
export const SET_ASSIGNMENTS = 'assignments/SET_ASSIGNMENTS';
export const SET_IS_VIEW_RATES = 'assignments/SET_IS_VIEW_RATES';
export const ADD_ASSIGNMENT = 'assignments/ADD_ASSIGNMENT';
export const UPDATE_ASSIGNMENT_STATUS = 'assignments/UPDATE_ASSIGNMENT_STATUS';
export const SHOW_QUESTION_DETAILS = 'assignments/SHOW_QUESTION_DETAILS';
export const CLEAR_SELECTED_ASSIGNMENT =
  'assignments/CLEAR_SELECTED_ASSIGNMENT';
export const SHOW_QUESTION_OVERVIEW = 'assignments/SHOW_QUESTION_OVERVIEW';
export const ASSIGNMENT_UPDATE = 'assignments/ASSIGNMENT_UPDATE';
export const ASSIGNMENT_UPDATE_DELETE = 'assignments/ASSIGNMENT_UPDATE_DELETE';
export const ASSIGNMENT_UPDATE_REJECTION =
  'assignments/ASSIGNMENT_UPDATE_REJECTION';
export const SET_CHANGE_TYPE = 'assignments/SET_CHANGE_TYPE';
export const SET_LOADING = 'assignments/SET_LOADING';
export const SET_ASSIGNMENTS_COUNT = 'assignments/SET_ASSIGNMENTS_COUNT';
export const SET_AWAITING_APPROVAL = 'assignments/SET_AWAITING_APPROVAL';
export const SET_SELECTED_ADMIN_RATE = 'assignments/SET_SELECTED_ADMIN_RATE';
export const SET_ADMINS_BY_QUAL = 'assignments/SET_ADMINS_BY_QUAL';
export const ASSIGNMENT_UPDATE_COMPLETE =
  'assignments/ASSIGNMENT_UPDATE_COMPLETE';
export const IS_RELOAD_ADMIN_ASSIGNMENT =
  'assignments/IS_RELOAD_ADMIN_ASSIGNMENT';
export const SET_ON_QUESTION_OVERVIEW = 'assignments/SET_ON_QUESTION_OVERVIEW';
export const TOGGLE_QUESTION_OVERVIEW = 'assignments/TOGGLE_QUESTION_OVERVIEW';
export const UPDATE_SP_ADMINS = 'assignments/UPDATE_SP_ADMINS';
export const NO_GROUPS = 'assignments/NO_GROUPS';
export const SET_ADMIN_SCROLL = 'assignments/SET_ADMIN_SCROLL';
export const SET_IS_BACK_FROM_QUESTION =
  'assignments/SET_IS_BACK_FROM_QUESTION';
export const SET_MY_SP = 'assignments/SET_MY_SP';
export const SET_LOADING_MY_SP = 'assignments/SET_LOADING_MY_SP';
export const SET_WRITERS = 'assignments/SET_WRITERS';
export const SET_SELECTED_WRITER = 'assignments/SET_SELECTED_WRITER';
export const SET_ASSIGN_MODAL_WAITING = 'assignments/SET_ASSIGN_MODAL_WAITING';
export const SET_ASSIGN_MODAL_SUCCESS = 'assignments/SET_ASSIGN_MODAL_SUCCESS';
export const SET_ASSIGN_MODAL_ERROR = 'assignments/SET_ASSIGN_MODAL_ERROR';
export const SET_ASSIGN_MODAL_REFERENCES =
  'assignments/SET_ASSIGN_MODAL_REFERENCES';
export const SET_ASSIGN_MODAL_WRITERS = 'assignments/SET_ASSIGN_MODAL_WRITERS';
export const SET_ASSIGN_MODAL_RATES = 'assignments/SET_ASSIGN_MODAL_RATES';
export const SET_ASSIGN_MODAL_SELECTED_RATE =
  'assignments/SET_ASSIGN_MODAL_SELECTED_RATE';
export const SET_ASSIGN_MODAL_LOADING_REFERENCES =
  'assignments/SET_ASSIGN_MODAL_LOADING_REFERENCES';
export const LOADING_CREATE_GPT_QUESTIONS =
  'assignments/LOADING_CREATE_GPT_QUESTIONS';
export const LOADING_GET_SUMMARIES = 'assignments/LOADING_GET_SUMMARIES';
export const SET_SUMMARIES_ACTION = 'assignments/SET_SUMMARIES_ACTION';
export const SET_LOADING_SUMMARY = 'assignments/SET_LOADING_SUMMARY';
export const SET_SUMMARY = 'assignments/SET_SUMMARY';
