import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

class AssignmentCommentsRow extends React.Component {
  getItem() {
    return (
      <Row>
        <Col sm={4}>
          <div className="change-text">
            {this.props.item.createdBy.name +
              ' ' +
              this.props.item.createdBy.lastName}
          </div>
        </Col>
        <Col sm={4}>
          <div className="change-text">
            {moment(this.props.item.createdAt).format('MM/DD/YYYY')}
          </div>
        </Col>
        <Col sm={4}>
          <div className="change-text">
            {this.props.item.comment !== '' ? this.props.item.comment : '-'}
          </div>
        </Col>
      </Row>
    );
  }

  render() {
    return <div>{this.getItem()}</div>;
  }
}

AssignmentCommentsRow.propTypes = {
  item: PropTypes.object,
};

export default AssignmentCommentsRow;
