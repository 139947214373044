import './CreateReference.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';

import DropFileInput from '../../DropFileInput/DropFileInput';
import { SimpleInput, SimpleTextarea } from '../../Input/Input';

class CreateReference extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      waiting: false,
      name: this.props.reference.name,
      description: this.props.reference.description,
      adminNotes: this.props.reference.adminNotes,
      actAsParent: this.props.reference.actAsParent,
      pdf: this.props.reference.actAsParent
        ? this.props.reference.pdfUrl
        : this.props.reference.chapters[0].pdfUrl,
      enabled: this.props.reference.enabled,
      highlightBook: this.props.reference.highlightBook
        ? this.props.reference.highlightBook
        : false,
      weNeedThisPublication: this.props.reference.weNeedThisPublication
        ? this.props.reference.weNeedThisPublication
        : false,
    };
  }

  componentWillReceiveProps({ submitSucceeded }) {
    if (submitSucceeded && this.state.waiting) {
      this.setState({ waiting: false });
    }
  }

  validate = () => {
    const errors = {};
    const fields = { ...this.state };

    if (!fields.name) {
      errors.name = 'Title field is required';
    }

    if (!fields.description) {
      errors.description = 'Description field is required';
    }

    if (fields.pdf && fields.pdf.length > 0) {
      fields.pdf = fields.pdf[0];
    }

    if (
      fields.pdf &&
      fields.pdf.name &&
      fields.pdf.name.toLowerCase().substr(-3) !== 'pdf'
    ) {
      errors.pdf = 'Please select a pdf file';
    }

    if (!fields.actAsParent) {
      fields.actAsParent = false;
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: null, waiting: true });

    this.props.onSubmit(fields);
  };

  disableEditActsAsParent = () => {
    const { edit, reference } = this.props;

    if (!edit || !reference || !reference.id) {
      return false;
    }
    return reference.chapters.length > 0 && reference.actAsParent;
  };

  disableHighlightBook = () => {
    const { edit } = this.props;
    if (edit) {
      return false;
    }
    return true;
  };

  handleKeyPress = (event) => {
    if (event.ctrlKey && event.key === 'Enter') {
      document.getElementById('btn-success').click();
    }
  };

  render() {
    const { onCancel, edit = false, reference = {}, onDelete } = this.props;
    const title = edit ? 'Edit Book' : 'Add Book';
    return (
      <div className="create-chapter-component">
        <div className="card-block">
          <div className="createChapterTitle">
            {title}
            <i
              className="fa fa-times fa-xs"
              onClick={() => this.props.onCancel()}
            />
            <hr style={{ width: '600px', marginLeft: '-15px' }} />
          </div>
          <form
            onKeyDown={this.handleKeyPress}
            className="widget-form new-admin-form"
            onSubmit={this.validate}
          >
            <Row>
              <Col md={4}>Title</Col>
              <Col md={8}>
                <SimpleInput
                  type="text"
                  value={this.state.name}
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                  error={
                    this.state.errors && this.state.errors.name
                      ? this.state.errors.name
                      : null
                  }
                  dataTest="create-book-title-input"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>Description</Col>
              <Col md={8}>
                <SimpleTextarea
                  type="text"
                  value={this.state.description}
                  onChange={(e) => {
                    this.setState({ description: e.target.value });
                  }}
                  error={
                    this.state.errors && this.state.errors.description
                      ? this.state.errors.description
                      : null
                  }
                  dataTest="create-book-description-input"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>Admin notes</Col>
              <Col md={8}>
                <SimpleTextarea
                  type="text"
                  value={this.state.adminNotes}
                  onChange={(e) => {
                    this.setState({ adminNotes: e.target.value });
                  }}
                  error={
                    this.state.errors && this.state.errors.adminNotes
                      ? this.state.errors.adminNotes
                      : null
                  }
                  dataTest="create-book-admin-notes-input"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <span className="InputLebel">Act as a parent</span>
              </Col>
              <Col md={8}>
                <SimpleInput
                  type="checkbox"
                  value={this.state.actAsParent}
                  onChange={(e) => {
                    this.setState({ actAsParent: !this.state.actAsParent });
                  }}
                  disabled={this.disableEditActsAsParent()}
                  dataTest="create-book-act-as-parent-input"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <span className="InputLebel">Enabled</span>
              </Col>
              <Col md={8}>
                <SimpleInput
                  type="checkbox"
                  value={this.state.enabled}
                  onChange={(e) => {
                    this.setState({ enabled: !this.state.enabled });
                  }}
                  dataTest="create-book-enabled-input"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <span className="InputLebel">Highlight book</span>
              </Col>
              <Col md={8}>
                <SimpleInput
                  type="checkbox"
                  value={this.state.highlightBook}
                  onChange={(e) => {
                    this.setState({ highlightBook: !this.state.highlightBook });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label className="InputLebel" htmlFor="needPublication">
                  We need this publication!
                </label>
              </Col>
              <Col md={8} className="colNeedPublication">
                <input
                  id="needPublication"
                  type="checkbox"
                  value={this.state.weNeedThisPublication}
                  checked={this.state.weNeedThisPublication}
                  onChange={(e) => {
                    this.setState({
                      weNeedThisPublication: !this.state.weNeedThisPublication,
                    });
                  }}
                  data-test="create-book-need-publication-input"
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <span className="InputLebel">Pdf</span>
              </Col>
              <Col md={8}>
                <DropFileInput
                  input={{
                    onChange: (e) => {
                      this.setState({ pdf: e });
                    },
                  }}
                  value={this.state.pdf}
                  defaultValue={this.state.pdf}
                  multiple={false}
                  accept={{ 'application/pdf': ['.pdf'] }}
                  getUrlPdf={this.props.getUrlPdf}
                />
              </Col>
            </Row>

            {this.state.errors && this.state.errors.general && (
              <div className="errors">{this.state.errors.general}</div>
            )}

            <hr style={{ width: '600px', marginLeft: '-15px' }} />
            <div className="bottom-buttons-new">
              {edit && (
                <i
                  className="fa fa-trash icon-button"
                  title="Remove this reference."
                  data-test="delete-book-button"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    onDelete(reference);
                  }}
                />
              )}
              <Button
                id="btn-success"
                className="btn btn-success"
                onClick={this.validate}
                loading={this.state.waiting}
              >
                Submit
              </Button>
              <Button
                data-test="cancel-edit-button"
                className="btn btn-secondary"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

CreateReference.defaultProps = {
  reference: {
    name: '',
    description: '',
    adminNotes: '',
    actAsParent: true,
    pdf: '',
    enabled: true,
  },
};

export default CreateReference;
