import References from './References';
import { connect } from 'react-redux';
import {
  getRates,
  getWaiting,
  getPagination,
  getRate,
  getLinks,
} from '../../Rates/selectors';
import {
  getFilter,
  getDutyStateReferences,
  getRankReferences,
} from '../selectors';
import {
  udpateReferenceActionSagas,
  deleteReferenceActionSagas,
  changeStatusActionSagas,
  archiveActionSagas,
  deletePdfActionSagas,
  copyReferenceActionSagas,
  assignedToBibActionSagas,
  archivedReferencesActionSagas,
  notAssignedToBibActionSagas,
  allAssignedReferencesActionSagas,
  deleteChapterActionSagas,
  deleteAllQuestionsActionSagas,
  changeStatusChapterActionSagas,
  deletePdfChapterActionSagas,
  setManualRedSubtopicsSagas,
  setManualRedQuestionsSagas,
  removeManualRedQuestionsSagas,
  removeManualRedSubtopicsSagas,
  updateChapterActionSagas,
  moveChapterSaga,
} from '../actionsSagas';
import { getUrlPdfPrivate } from '../../Books/selectors';
import { loadRates, createRate } from '../../Rates/actionsSagas';
import { updateRate } from '../../Rates/actions';
import {
  getReferences,
  getReferencesImmutable,
  getChapters,
  getChaptersImmutable,
  loadingGrid,
  getRanks,
  getDutyStates,
  getRank as getRankBib,
  getDutyState as getDutyStateBib,
} from '../../Bibliographies/selectors';
import { updateFilterAction, updateRank, updateDutyState } from '../actions';
import addAbilityToOpenModal from '../../../utilities/modalHOC';
import {
  loadReferences,
  createReferenceActionSagas,
  createChapterActionSagas,
  getUrlPdf,
} from '../../Bibliographies/actionsSagas';
import { updateRanks, updateDutyStates } from '../../Bibliographies/actions';
import {
  getGeneralRolesPermissions,
  getRolesPermissions,
  getUser,
} from '../../Login/selectors';

const mapStateToProps = (state) => ({
  rates: getRates(state),
  filter: getFilter(state),
  waiting: getWaiting(state),
  references: getReferences(state),
  chapters: getChapters(state),
  referencesImmutable: getReferencesImmutable(state),
  chaptersImmutable: getChaptersImmutable(state),
  dutyState: getDutyStateReferences(state),
  rank: getRankReferences(state),
  ratesPagination: getPagination(state),
  rate: getRate(state),
  loadingGrid: loadingGrid(state),
  ranks: getRanks(state),
  dutyStates: getDutyStates(state),
  rankBib: getRankBib(state),
  dutyStateBib: getDutyStateBib(state),
  links: getLinks(state),
  generalRolesPermissions: getGeneralRolesPermissions(state),
  rolesPermissions: getRolesPermissions(state),
  user: getUser(state),
  urlPdfPrivate: getUrlPdfPrivate(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadRates: (page) => dispatch(loadRates(page)),
  updateFilter: (filter) => dispatch(updateFilterAction(filter)),
  createRate: (fields) => dispatch(createRate(fields)),
  loadReferences: (id, type) => dispatch(loadReferences(id, type)),
  updateDutyState: (dutyState) => dispatch(updateDutyState(dutyState)),
  updateRank: (rank) => dispatch(updateRank(rank)),
  createChapter: (fields) => {
    dispatch(createChapterActionSagas(fields));
  },
  createReference: (fields, rate) => {
    dispatch(createReferenceActionSagas(fields, rate));
  },
  updateReference: (fields, referenceId) => {
    dispatch(udpateReferenceActionSagas(fields, referenceId));
  },
  deleteReference: (reference) => {
    dispatch(deleteReferenceActionSagas(reference));
  },
  changeStatus: (reference) => {
    dispatch(changeStatusActionSagas(reference));
  },
  changeStatusChapter: (reference) => {
    dispatch(changeStatusChapterActionSagas(reference));
  },
  archiveReference: (reference, filter) => {
    dispatch(archiveActionSagas(reference, filter));
  },
  deletePdf: (reference) => {
    dispatch(deletePdfActionSagas(reference));
  },
  deletePdfChapter: (chapter) => {
    dispatch(deletePdfChapterActionSagas(chapter));
  },
  copyReference: (fields) => {
    fields.type = 'rate';
    dispatch(copyReferenceActionSagas(fields));
  },
  obtainAssignedToBib: (rate, rank, dutyState) => {
    dispatch(assignedToBibActionSagas(rate, rank, dutyState));
  },
  obtainNotAssignedToBib: (rate) => {
    dispatch(notAssignedToBibActionSagas(rate));
  },
  obtainArchived: (rate) => {
    dispatch(archivedReferencesActionSagas(rate));
  },
  obtainAllAssigned: (rate) => {
    dispatch(allAssignedReferencesActionSagas(rate));
  },
  deleteChapter: (reference, chapter, presentationIndex) => {
    dispatch(deleteChapterActionSagas(reference, chapter, presentationIndex));
  },
  deleteAllQuestions: (chapter) => {
    dispatch(deleteAllQuestionsActionSagas(chapter));
  },
  updateRate: (rate) => dispatch(updateRate(rate)),
  setManualRedSubtopics: (chapter, reason) =>
    dispatch(setManualRedSubtopicsSagas(chapter, reason)),
  setManualRedQuestions: (chapter, reason) =>
    dispatch(setManualRedQuestionsSagas(chapter, reason)),
  removeManualRedQuestions: (chapter) =>
    dispatch(removeManualRedQuestionsSagas(chapter)),
  removeManualRedSubtopics: (chapter) =>
    dispatch(removeManualRedSubtopicsSagas(chapter)),
  updateChapter: (fields, chapterId) => {
    dispatch(updateChapterActionSagas(fields, chapterId));
  },
  onMoveChapters: (chapter, other) => {
    dispatch(moveChapterSaga(chapter, other));
  },
  updateRanks: (ranks) => dispatch(updateRanks(ranks)),
  getUrlPdf: (url) => {
    dispatch(getUrlPdf(url));
  },
  updateDutyStates: (dutyStates) => dispatch(updateDutyStates(dutyStates)),
});

export default addAbilityToOpenModal(
  connect(mapStateToProps, mapDispatchToProps)(References),
);
