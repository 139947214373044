import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'search');
}

export const getBooksResponse = state => getState(state).get('booksResponse');
export const getChaptersResponse = state => getState(state).get('chaptersResponse');
export const getQuestionsResponse = state => getState(state).get('questionsResponse');
export const getIsSearchingBooks = state => getState(state).get('isSearchingBooks');
export const getIsSearchingChapters = state => getState(state).get('isSearchingChapters');
export const getIsSearchingQuestions = state => getState(state).get('isSearchingQuestions');
export const getSearchSuccess = state => getState(state).get('searchSuccess');
export const getSearchError = state => getState(state).get('searchError');
export const getErrors = state => getState(state).get('errors');
export const getQuery = state => getState(state).get('query');
export const getSearchFromHeader = state => getState(state).get('searchFromHeader');
export const getOptions = state => getState(state).get('options');
export const getMoreBooks = state => getState(state).get('moreBooks');
export const getMoreChapters = state => getState(state).get('moreChapters');
export const getMoreQuestions = state => getState(state).get('moreBooks');
