import { connect } from 'react-redux';
import StudyPrograms from './StudyPrograms';
import { getRates, getQuals, getSelectedSP } from '../Rates/selectors';
import {
  loadRates,
  loadQuals,
  createRate,
  updateSP,
  deleteSP,
} from '../Rates/actionsSagas';
import { setSelectedSP } from '../Rates/actions';
import { getUser, getGeneralRolesPermissions } from '../Login/selectors';

const mapStateToProps = state => {
  return {
    rates: getRates(state),
    quals: getQuals(state),
    selectedSP: getSelectedSP(state),
    user: getUser(state),
    generalPermissions: getGeneralRolesPermissions(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadRates: page => dispatch(loadRates(page)),
    loadQuals: page => dispatch(loadQuals(page)),
    onSPClick: sp => dispatch(setSelectedSP(sp)),
    onCreate: sp => dispatch(createRate(sp)),
    onEdit: sp => dispatch(updateSP(sp)),
    onDelete: sp => dispatch(deleteSP(sp)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyPrograms);
