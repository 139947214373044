import React from 'react';
import TreeView from 'react-treeview';

import ConfirmDeletion from '../../../commonComponents/Modals/ConfirmDeletion/ConfirmDeletion';
import CreateChapter from '../../../commonComponents/Modals/CreateChapter/CreateChapter';
import CreateReference from '../../../commonComponents/Modals/CreateReference/CreateReference';
import PdfRequired from '../../../commonComponents/Modals/PdfRequired/PdfRequired';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import NumberUtils from '../../../lib/NumberUtils';
import {
  downloadCSV,
  downloadXLSX,
  equalArray,
} from '../../../utilities/functionalitiesForCSV';
import ViewSummaryModal from '../../Assignments/components/ViewSummaryModal/ViewSummaryModal';
import AssociateRatesToReferenceContainer from '../../AssociateRatesToReference/components/AssociateRatesToReferenceContainer';
import WritersContainer from './AssignWritersContainer';
import BookItem from './BookItem';
import CreateAIquestionForChapterContainer from './CreateAIquestionForChapterContainer';
import DuplicateChapterContainer from './DuplicateChapterContainer';
import ViewActivityContainer from './ViewActivityContainer';

class BooksList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      references: BooksList.addFieldsToReferences(
        this.props.references,
        true,
        this.props.lastSelectedTreeView,
      ),
      referenceToAdd: {
        mode: 'add',
        name: '',
        loading: false,
        error: false,
      },
      dragging: false,
      searchText: '',
      originalReferences: BooksList.addFieldsToReferences(
        this.props.references,
        true,
        this.props.lastSelectedTreeView,
      ),
      isDownloadCSV: false,
      isDownloadXLSX: false,
      isDownloadXLS: false,
      chapterAddedToReference: null,
      loadAddChapter: false,
    };
  }

  componentWillMount() {
    this.setState({
      references: this.state.references.map((reference, index) => {
        let inStore = false;
        let selectedRate = 0;
        if (this.props.selectedRate) {
          selectedRate = this.props.selectedRate.id;
        }
        this.props.lastSelectedBookByRate.map((rates) => {
          if (rates.rate === selectedRate && rates.book === reference.id) {
            reference.collapsed = false;
            inStore = true;
          }
        });
        if (!inStore) {
          reference.collapsed = true;
        }
        return reference;
      }),
    });
  }

  componentWillReceiveProps({
    references,
    waiting,
    referencesInmutable,
    selectedRate,
    expandAll,
    questions,
  }) {
    if (
      references !== this.props.references ||
      referencesInmutable !== this.props.referencesInmutable
    ) {
      const mergedReferences = BooksList.addFieldsToReferences(
        references.map((reference) => ({
          ...this.state.references.find((t) => t.id === reference.id),
          ...reference,
        })),
        true,
        null,
      );

      this.setState(
        {
          originalReferences: mergedReferences,
          references: mergedReferences,
          referenceToAdd: {
            mode: 'add',
            name: '',
            loading: false,
          },
        },
        () => {
          const newRef = this.state.references
            .filter((r) => r.isNew)
            .map((rr) => rr.id)
            .sort();
          const scrollToNewRef = newRef.length > 0 ? newRef.pop() : null;

          if (this.props.selectedRate?.id !== 0 && scrollToNewRef) {
            // window.jQuery('#' + scrollToNewRef)[0].scrollIntoView();
          } else if (this.state.chapterAddedToReference) {
            this.setState({ chapterAddedToReference: null }, () => {
              // ToDo: find a solution that without the timeout - for now this is the only solution that works
              // setTimeout(() => window.jQuery('input#' + ref)[0].focus(), 2000);
            });
          }
        },
      );
    } else if (!waiting) {
      this.setState({
        originalReferences: this.state.references.map((reference) => {
          reference.loading = false;

          return reference;
        }),
        references: this.state.references.map((reference) => {
          reference.loading = false;

          return reference;
        }),
        referenceToAdd: { ...this.state.referenceToAdd, loading: false },
      });

      if (
        selectedRate !== this.props.selectedRate &&
        this.props.selectedRate === 0
      )
        this.props.onExpandAll(false);
    }
    if (expandAll !== this.props.expandAll) {
      this.setState({
        references: this.state.references.map((reference) => {
          reference.collapsed = this.props.expandAll;
          return reference;
        }),
      });
    }
    if (
      !equalArray(this.props.questions, questions) &&
      this.state.isDownloadCSV
    ) {
      downloadCSV(questions);
      this.setState({ isDownloadCSV: false });
    }

    if (
      !equalArray(this.props.questions, questions) &&
      this.state.isDownloadXLSX
    ) {
      downloadXLSX(questions, 'xlsx');
      this.setState({ isDownloadXLSX: false });
    }

    if (
      !equalArray(this.props.questions, questions) &&
      this.state.isDownloadXLS
    ) {
      downloadXLSX(questions, 'xls');
      this.setState({ isDownloadXLS: false });
    }
  }

  static addFieldsToReferences = (
    references = [],
    defaultCollapsed = true,
    lastSelectedTreeView = null,
  ) =>
    references.map((reference, index) => {
      reference.collapsed =
        lastSelectedTreeView !== null
          ? !(Number(lastSelectedTreeView) === Number(index))
          : reference.collapsed !== undefined
            ? reference.collapsed
            : defaultCollapsed;

      reference.mode = 'normal';
      reference.editedName = reference.name;
      reference.loading = false;

      let chapterToAddText = '';
      if (reference.chapterToAdd) {
        chapterToAddText = reference.chapterToAdd.loading
          ? ''
          : reference.chapterToAdd.name;
      }

      reference.chapterToAdd = {
        mode: reference.chapterToAdd ? reference.chapterToAdd.mode : 'add',
        name: chapterToAddText,
        loading: false,
      };

      if (reference.chapters) {
        reference.chapters = reference.chapters.map((chapter) => {
          chapter.mode = 'normal';
          chapter.loading = false;

          return chapter;
        });
      }
      return reference;
    });

  updateNameReferenceToAdd = (name) => {
    const referenceToAdd = this.state.referenceToAdd;

    referenceToAdd.name = name;

    this.setState({ referenceToAdd });
  };

  createReference = (text) => {
    const referenceToAdd = this.state.referenceToAdd;
    const textParts = text.split('-');
    if (textParts.length === 1) {
      referenceToAdd.error = true;
      this.setState({ referenceToAdd });
      return;
    }
    referenceToAdd.error = false;
    const name = text.split('-')[0].toUpperCase();
    const description = text.split('-')[1].toUpperCase();
    referenceToAdd.loading = true;
    this.setState({ referenceToAdd });
    const reference = {
      name,
      description,
      actAsParent: true,
    };
    return this.props.createReference(reference);
  };

  toggleReference = (index) => {
    if (this.state.references[index].mode === 'edit') return;

    const references = this.state.references;
    references[index].collapsed = !references[index].collapsed;

    this.setState({ references });
    this.props.setLastSelectedTreeView(index);
  };

  toggleEditMode = (index, indexSt = null) => {
    const references = this.state.references;

    if (indexSt === null) {
      if (references[index].mode === 'normal') {
        references[index].mode = 'edit';
        references[index].focus = true;
      } else {
        references[index].mode = 'normal';
        references[index].focus = false;
      }
      references[index].editedName = references[index].name;
    } else {
      if (references[index].chapters[indexSt].mode === 'normal') {
        references[index].chapters[indexSt].mode = 'edit';
        references[index].chapters[indexSt].focus = true;
      } else {
        references[index].chapters[indexSt].mode = 'normal';
        references[index].chapters[indexSt].focus = false;
      }

      references[index].chapters[indexSt].editedName =
        references[index].chapters[indexSt].name;
    }

    this.setState({ references });
  };

  updateEditedName = (index, editedName, indexSt = null) => {
    const references = this.state.references;

    if (indexSt === null) {
      references[index].editedName = editedName;
    } else {
      references[index].chapters[indexSt].editedName = editedName;
    }

    this.setState({ references });
  };

  updateReference = (index, name, referenceId) => {
    const references = this.state.references;
    references[index].loading = true;
    this.setState({ references });
    return this.props.updateReference({ name }, referenceId);
  };

  deleteReference = (reference) => {
    this.props.openModal({
      modalTitle: 'Deleting a reference',
      modalContent: (
        <ConfirmDeletion
          onSubmit={() => this.props.deleteReference(reference)}
          message={`You are about to delete the reference ${reference.name} .`}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  createChapter = (index, name, description, reference, presentationIndex) => {
    if (this.props.hasEditPermission()) {
      const references = this.state.references;
      references[index].chapterToAdd.nameError =
        !name || name.trim().length === 0;
      references[index].chapterToAdd.descriptionError =
        !description || description.trim().length === 0;
      if (
        references[index].chapterToAdd.descriptionError ||
        references[index].chapterToAdd.nameError
      ) {
        references[index].chapterToAdd.name = name;
        references[index].chapterToAdd.description = description;
        this.setState({ references });
        return;
      }

      references[index].chapterToAdd.error = false;
      references[index].chapterToAdd.loading = true;
      this.setState({ references, chapterAddedToReference: reference.id });
      const chapter = {
        name: name.toUpperCase(),
        description: description.toUpperCase(),
        reference: reference.id,
      };
      if (presentationIndex) {
        chapter.presentationIndex = presentationIndex;
      }
      return this.props.createChapter(chapter);
    }
  };

  onUpdatePdf = (pdf, id, isReference) => {
    if (pdf && pdf.length > 0) {
      pdf = pdf[0];
    }
    const myNewPdf = new File([pdf], pdf.name.replace('.PDF', '.pdf'), {
      type: pdf.type,
    });
    if (isReference) {
      this.props.updateReference({ pdf: myNewPdf }, id);
    } else {
      this.props.updateChapter({ pdf: myNewPdf }, id);
    }
  };

  updateChapter = (index, indexSt, name, chapterId, pdf) => {
    const references = this.state.references;
    references[index].chapters[indexSt].loading = true;
    this.setState({ references });

    return this.props.updateChapter({ name }, chapterId);
  };

  updateChapterIndex = (index, old_index, new_index, chapterId) => {
    if (this.props.hasEditPermission()) {
      const references = this.state.references;
      const chapters = references[index].chapters;

      new_index = Math.max(1, Math.min(new_index, chapters.length));

      const chapterToMove = chapters[old_index - 1];

      chapters.splice(old_index - 1, 1);

      chapters.splice(new_index - 1, 0, chapterToMove);

      chapters.forEach((chapter, idx) => {
        chapter.presentationIndex = idx + 1;
      });

      this.setState({ references });

      return this.props.updateChapter(
        { presentationIndex: new_index },
        chapterId,
      );
    }
  };

  onDrag = (book_index) => {
    this.setState({ dragging: book_index });
  };

  deleteChapter = (chapter, reference) => {
    this.props.openModal({
      modalTitle: 'Deleting a chapter',
      modalContent: (
        <ConfirmDeletion
          onSubmit={() =>
            this.props.deleteChapter(
              reference.id,
              chapter.id,
              chapter.presentationIndex,
            )
          }
          message={`You are about to delete the chapter ${chapter.name} .`}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  associateRatesModal = (reference) => {
    if (this.props.hasEditPermission()) {
      this.props.openModal({
        modalTitle: 'Associate rates',
        modalContent: (
          <AssociateRatesToReferenceContainer
            onSubmit={(rates, reference) =>
              this.props.associateRates(rates, reference)
            }
            onCancel={this.props.closeModal}
            reference={reference}
          />
        ),
        modalClassName: 'modal-associate-rates',
      });
    }
  };

  openViewReferenceActivityModal = (id) => {
    this.props.openModal({
      modalContent: (
        <ViewActivityContainer
          closeModal={this.props.closeModal}
          id={id}
          isChapter={false}
        />
      ),
      modalClassName: 'ActivityModal',
    });
  };

  openDuplicateChapterModal = (id) => {
    this.props.openModal({
      modalContent: (
        <DuplicateChapterContainer
          closeModal={this.props.closeModal}
          id={id}
          isChapter
          references={this.props.references}
          rateList={this.props.rateList}
        />
      ),
      modalClassName: 'ActivityModal',
    });
  };

  openViewChapterActivityModal = (id) => {
    this.props.openModal({
      modalContent: (
        <ViewActivityContainer
          closeModal={this.props.closeModal}
          id={id}
          isChapter
        />
      ),
      modalClassName: 'ActivityModal',
    });
  };

  openSummaryViewModal = (chapter) => {
    this.props.openModal({
      modalContent: (
        <ViewSummaryModal
          onCancel={this.props.closeModal}
          chapter={chapter}
          summaries={this.props.summaries}
        />
      ),
      modalClassName: 'viewComments',
    });
  };

  openCreateAIquestionForChapterModal = (reference, chapter, rateId) => {
    if (chapter.pdfUrl)
      this.props.openModal({
        modalContent: (
          <CreateAIquestionForChapterContainer
            closeModal={this.props.closeModal}
            chapter={chapter}
            isChapter
            referenceId={reference.id}
            pdf={chapter.pdfUrl}
            totalPages={chapter.pdfPages}
            loadingPdf={false}
            rateId={rateId}
          />
        ),
        modalClassName: 'modalDuplicate',
      });
    else
      this.props.openModal({
        modalContent: (
          <PdfRequired
            closeModal={this.props.closeModal}
            editChapter={() =>
              this.openEditChapterModal(chapter, reference, true)
            }
          />
        ),
        modalClassName: 'modalDuplicate',
      });
  };

  openEditReferenceModal = (reference, rateId) => {
    if (this.props.hasEditPermission()) {
      this.props.openModal({
        modalContent: (
          <CreateReference
            onSubmit={(fields) =>
              this.props.updateReference(
                fields,
                reference.id,
                this.props.activeFilter,
              )
            }
            onCancel={this.props.closeModal}
            edit
            reference={reference}
            getUrlPdf={this.props.getUrlPdf}
            onDelete={() => this.deleteReference(reference)}
          />
        ),
        modalClassName: 'modal-create-chapter',
        className: '',
      });
    }
  };

  openEditChapterModal = (chapter, reference, PdfRequired = false) => {
    if (PdfRequired) {
      this.props.closeModal;
    }
    if (this.props.hasEditPermission()) {
      this.props.openModal({
        modalContent: (
          <CreateChapter
            onSubmit={(fields) => this.props.updateChapter(fields, chapter.id)}
            onCancel={this.props.closeModal}
            edit
            reference={reference}
            chapter={chapter}
            onDelete={() => this.deleteChapter(chapter, reference)}
            getUrlPdf={this.props.getUrlPdf}
            chaptersOfReference={reference.chapters.length}
          />
        ),
        modalClassName: 'modal-create-chapter',
        className: '',
      });
    }
  };

  showAssignModal = (reference, chapter) => {
    if (this.props.hasEditPermission()) {
      const filteredReference = { ...reference };
      if (chapter) {
        filteredReference.chapters = reference.chapters.filter(
          (c) => c.id === chapter.id,
        );
      }
      this.props.openModal({
        modalContent: (
          <WritersContainer
            title="Assign"
            closeModal={this.props.closeModal}
            rate={this.props.selectedRate.id}
            forAssign
            references={[filteredReference]}
            selectedStudyProgram={this.props.selectedRate}
            reference={reference}
          />
        ),
        modalClassName: 'modal-create-chapter grey-modal-body',
      });
    }
  };

  setQuestionsCSV = (chapter) => {
    if (chapter) {
      this.props.loadChapterQuestionsCSV(chapter, false, false);
      this.setState({ isDownloadCSV: true });
    }
  };

  setQuestionsXLSX = (chapter) => {
    if (chapter) {
      this.props.loadQuestionsOfChapter(chapter.id, 1, 1000, null, false, 0);
      this.setState({ isDownloadXLSX: true });
    }
  };

  setQuestionsXLS = (chapter) => {
    if (chapter) {
      this.props.loadQuestionsOfChapter(chapter.id, 1, 1000, null, false, 0);
      this.setState({ isDownloadXLS: true });
    }
  };

  render() {
    const {
      selectedRate,
      generalRolesPermissions,
      onMoveChapters,
      filterHeight,
      historyObject,
    } = this.props;
    const style = {};
    if (filterHeight) {
      style.marginBottom = `${filterHeight}px`;
    }
    const references = this.state.references.slice();
    const referencesLength = references.length;
    if (
      referencesLength > 0 &&
      !references[referencesLength - 1].isSpinner &&
      this.props.loading
    ) {
      references.push({ isSpinner: true });
    }
    if (!historyObject.isBackFromQuestion) {
      historyObject.amountOfReferences = referencesLength;
    }
    return (
      <div className="listWrapper" style={style}>
        {referencesLength > 0 ? (
          <div
            className={
              selectedRate && selectedRate.id !== 0
                ? 'references-list-component'
                : 'references-list-component calculatedHeight'
            }
          >
            {references.map((reference, index) =>
              reference.isSpinner ? (
                <Spinner text="Loading books" />
              ) : reference.actAsParent ? (
                <TreeView
                  data-test="book-list-button"
                  key={`reference-${NumberUtils.obtainUniqueKey()}`}
                  collapsed={reference.collapsed}
                  onClick={() => this.toggleReference(index)}
                  nodeLabel={
                    <BookItem
                      hasEditPermission={this.props.hasEditPermission}
                      getUrlPdf={this.props.getUrlPdf}
                      urlPdfPrivate={this.props.urlPdfPrivate}
                      rateId={
                        this.props.selectedRate ? this.props.selectedRate.id : 0
                      }
                      setLastBookOpenPerRate={this.props.setLastBookOpenPerRate}
                      activeFilter={this.props.activeFilter}
                      setMainScroll={this.props.setMainScroll}
                      setSidebarScroll={this.props.setSidebarScroll}
                      setLastSelectedTreeView={
                        this.props.setLastSelectedTreeView
                      }
                      setLastSelectedPanel={this.props.setLastSelectedPanel}
                      onUpdatePdf={(pdf) =>
                        this.onUpdatePdf(pdf, reference.id, true)
                      }
                      key={`reference1-${NumberUtils.obtainUniqueKey()}`}
                      id={`reference-${reference.id}`}
                      waiting={this.props.waiting}
                      item={reference}
                      isChapter={false}
                      level={1}
                      onClick={() => this.toggleReference(index)}
                      onToggleEditMode={() => this.toggleEditMode(index)}
                      onUpdateEditedName={(editedName) =>
                        this.updateEditedName(index, editedName)
                      }
                      onUpdate={(name) =>
                        this.updateReference(index, name, reference.id)
                      }
                      // onDelete={() => this.deleteReference(reference)}
                      onPlusButton={() => this.associateRatesModal(reference)}
                      openEditModal={() =>
                        this.openEditReferenceModal(reference, selectedRate.id)
                      }
                      openViewReferenceActivityModal={() =>
                        this.openViewReferenceActivityModal(reference.id)
                      }
                      setLoadingPdf={() => {
                        const references = this.state.references;
                        references[index].isLoadingPdf = true;
                        this.setState({ references });
                      }}
                      historyObject={historyObject}
                      onAssign={() => this.showAssignModal(reference)}
                      questions={this.props.questions}
                      references={references}
                      user={this.props.user}
                      loadAddChapter={this.props.loadAddChapter}
                    />
                  }
                >
                  <div className="subtree">
                    {!reference.deletedAt && (
                      <BookItem
                        hasEditPermission={this.props.hasEditPermission}
                        getUrlPdf={this.props.getUrlPdf}
                        urlPdfPrivate={this.props.urlPdfPrivate}
                        rateId={
                          this.props.selectedRate
                            ? this.props.selectedRate.id
                            : 0
                        }
                        setLastBookOpenPerRate={
                          this.props.setLastBookOpenPerRate
                        }
                        activeFilter={this.props.activeFilter}
                        setMainScroll={this.props.setMainScroll}
                        setSidebarScroll={this.props.setSidebarScroll}
                        setLastSelectedTreeView={
                          this.props.setLastSelectedTreeView
                        }
                        setLastSelectedPanel={this.props.setLastSelectedPanel}
                        waiting={this.props.waiting}
                        item={reference.chapterToAdd}
                        reference={reference}
                        level={2}
                        id={`chapter-${reference.chapterToAdd.id}`}
                        isChapter
                        book_index={index}
                        onUpdate={(value, property) => {
                          const references = this.state.references;
                          references[index].chapterToAdd[property] = value;
                          this.setState({ references });
                        }}
                        openViewReferenceActivityModal={() =>
                          this.openViewReferenceActivityModal(reference.id)
                        }
                        onUpdateIndex={(
                          dropped_chapter,
                          old_index,
                          new_index,
                        ) =>
                          this.updateChapterIndex(
                            index,
                            old_index,
                            new_index,
                            dropped_chapter.id,
                          )
                        }
                        onAdd={(title, description, presentationIndex) =>
                          this.createChapter(
                            index,
                            title,
                            description,
                            reference,
                            presentationIndex,
                          )
                        }
                        allChapters={reference.chapters}
                        loadAddChapter={this.props.loadAddChapter}
                      />
                    )}
                    {reference.actAsParent &&
                      reference.chapters &&
                      reference.chapters.map((chapter, indexSt) => (
                        <BookItem
                          hasEditPermission={this.props.hasEditPermission}
                          getUrlPdf={this.props.getUrlPdf}
                          urlPdfPrivate={this.props.urlPdfPrivate}
                          rateId={
                            this.props.selectedRate
                              ? this.props.selectedRate.id
                              : 0
                          }
                          setLastBookOpenPerRate={
                            this.props.setLastBookOpenPerRate
                          }
                          activeFilter={this.props.activeFilter}
                          setMainScroll={this.props.setMainScroll}
                          setSidebarScroll={this.props.setSidebarScroll}
                          setLastSelectedTreeView={
                            this.props.setLastSelectedTreeView
                          }
                          setLastSelectedPanel={this.props.setLastSelectedPanel}
                          index={indexSt}
                          book_index={index}
                          allChapters={reference.chapters}
                          onMoveChapters={onMoveChapters}
                          waiting={this.props.waiting}
                          key={`chapter-${NumberUtils.obtainUniqueKey()}`}
                          item={chapter}
                          isChapter
                          id={`chapter-${chapter.id}`}
                          level={2}
                          onDrag={(index) => this.onDrag(index)}
                          onToggleEditMode={() =>
                            this.toggleEditMode(index, indexSt)
                          }
                          onUpdateEditedName={(editedName) =>
                            this.updateEditedName(index, editedName, indexSt)
                          }
                          onUpdate={(name) =>
                            this.updateChapter(index, indexSt, name, chapter.id)
                          }
                          onUpdateIndex={(
                            dropped_chapter,
                            old_index,
                            new_index,
                          ) =>
                            this.updateChapterIndex(
                              index,
                              old_index,
                              new_index,
                              dropped_chapter.id,
                            )
                          }
                          onUpdatePdf={(pdf) =>
                            this.onUpdatePdf(pdf, chapter.id, false)
                          }
                          setLoadingPdf={() => {
                            const references = this.state.references;
                            references[index].chapters[indexSt].isLoadingPdf =
                              true;
                            this.setState({ references });
                          }}
                          openEditModal={() =>
                            this.openEditChapterModal(chapter, reference)
                          }
                          openDuplicateChapterModal={() =>
                            this.openDuplicateChapterModal(chapter.id)
                          }
                          openViewChapterActivityModal={() =>
                            this.openViewChapterActivityModal(chapter.id)
                          }
                          openSummaryViewModal={() =>
                            this.openSummaryViewModal(chapter)
                          }
                          rolesPermissions={this.props.rolesPermissions}
                          isSuperAdmin={this.props.isSuperAdmin}
                          selectedRate={selectedRate}
                          generalRolesPermissions={generalRolesPermissions}
                          historyObject={historyObject}
                          onAssign={() =>
                            this.showAssignModal(reference, chapter)
                          }
                          onDownloadCSV={() => this.setQuestionsCSV(chapter)}
                          onDownloadXLSX={() => this.setQuestionsXLSX(chapter)}
                          onDownloadXLS={() => this.setQuestionsXLS(chapter)}
                          questions={this.props.questions}
                          references={references}
                          openCreateAIquestionForChapterModal={() =>
                            this.openCreateAIquestionForChapterModal(
                              reference,
                              chapter,
                              this.props.selectedRate
                                ? this.props.selectedRate.id
                                : 0,
                            )
                          }
                          summaries={this.props.summaries}
                          user={this.props.user}
                          loadAddChapter={this.props.loadAddChapter}
                        />
                      ))}
                  </div>
                </TreeView>
              ) : (
                <div
                  key={`reference-${NumberUtils.obtainUniqueKey()}`}
                  className="actAsChapterContainer"
                >
                  <BookItem
                    hasEditPermission={this.props.hasEditPermission}
                    getUrlPdf={this.props.getUrlPdf}
                    urlPdfPrivate={this.props.urlPdfPrivate}
                    rateId={
                      this.props.selectedRate ? this.props.selectedRate.id : 0
                    }
                    setLastBookOpenPerRate={this.props.setLastBookOpenPerRate}
                    activeFilter={this.props.activeFilter}
                    setMainScroll={this.props.setMainScroll}
                    setSidebarScroll={this.props.setSidebarScroll}
                    setLastSelectedTreeView={this.props.setLastSelectedTreeView}
                    setLastSelectedPanel={this.props.setLastSelectedPanel}
                    waiting={this.props.waiting}
                    item={reference}
                    isChapter={false}
                    id={`reference-${reference.id}`}
                    level={1}
                    onUpdatePdf={(pdf) =>
                      this.onUpdatePdf(pdf, reference.id, true)
                    }
                    onClick={() => this.toggleReference(index)}
                    onToggleEditMode={() => this.toggleEditMode(index)}
                    onUpdateEditedName={(editedName) =>
                      this.updateEditedName(index, editedName)
                    }
                    onUpdate={(name) =>
                      this.updateReference(index, name, reference.id)
                    }
                    setLoadingPdf={() => {
                      const references = this.state.references;
                      references[index].isLoadingPdf = true;
                      this.setState({ references });
                    }}
                    onPlusButton={() => this.associateRatesModal(reference)}
                    openEditModal={() =>
                      this.openEditReferenceModal(reference, selectedRate.id)
                    }
                    openViewReferenceActivityModal={() =>
                      this.openViewReferenceActivityModal(reference.id)
                    }
                    rolesPermissions={this.props.rolesPermissions}
                    generalRolesPermissions={generalRolesPermissions}
                    isSuperAdmin={this.props.isSuperAdmin}
                    selectedRate={selectedRate}
                    historyObject={historyObject}
                    onAssign={() => this.showAssignModal(reference)}
                    references={references}
                    onDownloadCSV={() =>
                      this.setQuestionsCSV(reference.chapters[0])
                    }
                    onDownloadXLSX={() =>
                      this.setQuestionsXLSX(reference.chapters[0])
                    }
                    onDownloadXLS={() =>
                      this.setQuestionsXLS(reference.chapters[0])
                    }
                    questions={this.props.questions}
                    user={this.props.user}
                    loadAddChapter={this.props.loadAddChapter}
                  />
                </div>
              ),
            )}
          </div>
        ) : (
          !this.props.loading && <div className="noReferences">No books</div>
        )}
      </div>
    );
  }
}

export default BooksList;
