import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import DropDown from '../../../commonComponents/DropDown/DropDown';
import { MODAL_TYPES } from '../helpers/constants';
import ConfirmationModal from './ConfirmationModal';

function FavoriteQuestionItem({
  question,
  selectedRateId,
  selectedRateShortName,
  selectedQuestions,
  handleToggleSelectedQuestion,
  removeFavoriteQuestion,
}) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const handleToggleModal = () => {
    setIsConfirmationModalOpen((prev) => !prev);
  };

  const transformedDate = question.maxCreatedAt
    ? new Date(Number(question.maxCreatedAt)).toLocaleDateString('en-US')
    : '-';

  return (
    <article className="question__item" key={question.id}>
      <Row>
        <Col md={6} className="column--left">
          {question.text}
        </Col>
        <Col md={1} className="column-middle text-center">
          {question.numberOfUsersFavoriteQuestion}
        </Col>
        <Col md={1} className="column-middle text-center">
          {question.numberOfUsersRemovedQuestion}
        </Col>
        <Col md={1} className="column-middle text-center">
          {transformedDate}
        </Col>
        <Col md={2} className="column-middle">
          <Row>
            {question.rates ? (
              question.rates.map((rate) => (
                <Col md={3} key={rate.shortname}>
                  <a href={rate.link} target="_blank" rel="noreferrer">
                    {rate.shortname}
                  </a>
                </Col>
              ))
            ) : (
              <Col md={3}>
                <a href={question.link}>{selectedRateShortName}</a>
              </Col>
            )}
          </Row>
        </Col>
        <Col md={1} className="column--right options__container">
          <input
            type="checkbox"
            onClick={() => handleToggleSelectedQuestion(question.id)}
            value={selectedQuestions.includes(question.id)}
          />
          <DropDown
            options={[
              {
                name: 'Delete',
                event: () => {
                  setModalType(MODAL_TYPES.DELETE_FAVORITE);
                  handleToggleModal();
                },
              },
            ]}
            title="Question Menu"
            style={{
              position: 'absolute',
              right: '10px',
            }}
          />
        </Col>
      </Row>

      {isConfirmationModalOpen ? (
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          handleToggleModal={handleToggleModal}
          type={modalType}
          question={question}
          selectedRateId={selectedRateId}
          removeFavoriteQuestion={removeFavoriteQuestion}
        />
      ) : null}
    </article>
  );
}

export default FavoriteQuestionItem;
