import React from 'react';
import { FormGroup, Form, Input, InputGroup, Row } from 'reactstrap';
import { Link } from 'react-router';
import { validateE } from '../../../utilities/getItemsHelper';

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      alertBox: false,
      alertBoxMessage: '',
      validate: {
        emailState: false,
      },
    };
    this.handleEnter = this.handleEnter.bind(this);
    this.requestPassword = this.requestPassword.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }
  handleEnter(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.requestPassword();
    }
  }
  async requestPassword() {
    this.props.requestPassword(this.state.email);
    await this.setState({ alertBox: true });
  }

  handleCloseAlert() {
    this.setState({ alertBox: false });
  }
  handleChange = async event => {
    const { target } = event;
    const value = target.value;
    const { name } = target;

    await this.setState({
      [name]: value,
    });
    if (name === 'email') {
      this.validateEmail(target);
    }
  };
  validateEmail(e) {
    const { validate } = this.state;
    validate.emailState = validateE(e);
    this.setState({ validate });
  }

  componentWillUnmount = () => {
    this.props.setMessage('');
  };

  render() {
    const { email } = this.state;
    return (
      <div className="forgot-password">
        <div className="alert-container ">
          {this.props.message !== '' && this.props.message !== 'true' && (
            <div
              className={this.state.alertBox ? 'alert' : 'd-none'}
              role="alert"
            >
              <i onClick={this.handleCloseAlert} className="icon-close" />
              <p className="description">{this.props.message}</p>
            </div>
          )}
        </div>
        <h5 className="see"> Forgot your password?</h5>
        <p>Tell us your account email and we’ll send you a new one.</p>
        <Form role="form">
          <FormGroup className="">
            <InputGroup>
              <Input
                className="form-field"
                placeholder="Email Address"
                type="email"
                name="email"
                value={email}
                valid={this.state.validate.emailState}
                invalid={!this.state.validate.emailState}
                onChange={e => this.handleChange(e)}
                onKeyPress={this.handleEnter}
              />
            </InputGroup>
          </FormGroup>
          <button
            className="w-100 btn forgot-password-button btn-danger "
            type="button"
            onClick={this.requestPassword}
            disabled={this.props.isLoading || !this.state.validate.emailState}
          >
            {this.props.isLoading ? (
              <span>Sign in</span>
            ) : (
              'SEND MY NEW PASSWORD'
            )}
          </button>
          <Link to="/login">
            <button
              className="w-100 btn btn-outline-secondary forgot-password-button"
              type="button"
            >
              {this.props.isLoading ? <span>Sign in</span> : 'BACK TO LOGIN'}
            </button>
          </Link>
        </Form>
      </div>
    );
  }
}
export default ForgotPasswordForm;
