import React from 'react';
import { redirect } from '../../lib/check-auth';
import {
  getGeneralRolesPermissions,
  getRolesPermissions,
} from '../Login/selectors';
import { connect } from 'react-redux';

class Dashboard extends React.Component {
  componentWillMount() {
    redirect(this.props.generalRolesPermissions, this.props.rolesPermissions);
  }

  render() {
    return (
      <div className="animated fadeIn">
        <div className="row">WHEELHOUSE</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  generalRolesPermissions: getGeneralRolesPermissions(state),
  rolesPermissions: getRolesPermissions(state),
});

export default connect(mapStateToProps)(Dashboard);
