import { connect } from 'react-redux';
import { getSelectedQual, getQuals } from '../../selectors';
import { getUrlPdfPrivate } from '../../../Books/selectors';
import QualInformation from './QualInformation';
import { loadQualReferences } from '../../actionsSagas';
import addAbilityToOpenModal from '../../../../utilities/modalHOC';
import {
  getReferences,
  getChapters,
  loadingGrid,
} from '../../../Bibliographies/selectors';
import {
  createReferenceActionSagas,
  createChapterActionSagas,
  getUrlPdf,
} from '../../../Bibliographies/actionsSagas';
import {
  deletePdfActionSagas,
  changeStatusActionSagas,
  udpateReferenceActionSagas,
  deleteReferenceActionSagas,
  archiveActionSagas,
  copyReferenceActionSagas,
  setManualRedQuestionsSagas,
  removeManualRedQuestionsSagas,
  deletePdfChapterActionSagas,
  changeStatusChapterActionSagas,
  moveChapterSaga,
  updateChapterActionSagas,
  deleteAllQuestionsActionSagas,
  deleteChapterActionSagas,
} from '../../../References/actionsSagas';
import {
  getGeneralRolesPermissions,
  getRolesPermissions,
  getUser,
} from '../../../Login/selectors';
import { updateStudyProgramsOfReference } from '../../../Books/actionsSagas';
const mapStateToProps = (state, ownProps) => {
  return {
    quals: getQuals(state),
    selectedQual: getSelectedQual(state),
    references: getReferences(state),
    chapters: getChapters(state),
    generalRolesPermissions: getGeneralRolesPermissions(state),
    rolesPermissions: getRolesPermissions(state),
    user: getUser(state),
    loading: loadingGrid(state),
    urlPdfPrivate: getUrlPdfPrivate(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createReference: (fields, qual) =>
      dispatch(createReferenceActionSagas(fields, qual, 'qual')),
    loadReferences: (id, archived) =>
      dispatch(loadQualReferences(id, archived)),
    deleteReferencePdf: (reference) =>
      dispatch(deletePdfActionSagas(reference)),
    changeReferenceStatus: (reference) =>
      dispatch(changeStatusActionSagas(reference)),
    updateReference: (fields, referenceId) => {
      dispatch(udpateReferenceActionSagas(fields, referenceId));
    },
    deleteReference: (reference) => {
      dispatch(deleteReferenceActionSagas(reference));
    },
    archiveReference: (reference, filter) => {
      dispatch(archiveActionSagas(reference, filter));
    },
    copyReference: (fields) => {
      fields.type = 'qual';
      dispatch(copyReferenceActionSagas(fields));
    },
    createChapter: (fields) => {
      dispatch(createChapterActionSagas(fields));
    },
    setManualRedQuestions: (chapter, reason) => {
      dispatch(setManualRedQuestionsSagas(chapter, reason));
    },
    removeManualRedQuestions: (chapter) => {
      dispatch(removeManualRedQuestionsSagas(chapter));
    },
    deletePdfChapter: (chapter) => {
      dispatch(deletePdfChapterActionSagas(chapter));
    },
    changeStatusChapter: (reference) => {
      dispatch(changeStatusChapterActionSagas(reference));
    },
    onMoveChapters: (chapter, other) => {
      dispatch(moveChapterSaga(chapter, other));
    },
    updateChapter: (fields, chapterId) => {
      dispatch(updateChapterActionSagas(fields, chapterId));
    },
    deleteAllQuestions: (chapter) => {
      dispatch(deleteAllQuestionsActionSagas(chapter));
    },
    deleteChapter: (reference, chapter, presentationIndex) => {
      dispatch(deleteChapterActionSagas(reference, chapter, presentationIndex));
    },
    associateRates: (studyPrograms, reference) => {
      dispatch(updateStudyProgramsOfReference(studyPrograms, reference));
    },
    getUrlPdf: (url) => {
      dispatch(getUrlPdf(url));
    },
  };
};

const QualInformationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(QualInformation);

export default addAbilityToOpenModal(QualInformationContainer);
