import * as t from './actionTypes';

const resetPasswordRequest = function resetPasswordRequest(response) {
  return {
    type: t.RESET_PASSWORD_REQUESTING,
    payload: response,
  };
};

export const resetErrors = function resetErrors() {
  return {
    type: t.RESET_ERRORS,
  };
};

export default resetPasswordRequest;
