import Notifications from 'react-notification-system-redux';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { closeModal } from '../../containers/Full/actions';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import * as api from '../../utilities/ServiceManager';
import { updateReference } from '../Bibliographies/actions';
import {
  addFilter,
  editFilter as updateFilterAction,
  removeFilter,
  setFilters,
  setFinishProcessing,
  setLogs,
  setNamesToBuildSecondBar,
  setQuestionCsvByDetail,
  setQuestionsCSV,
  updateWaiting,
} from './actions';
import {
  CREATE_FILTER,
  DELETE_FILTER,
  EDIT_FILTER,
  GET_LOGS,
  GET_NAMES_TO_SECOND_BAR,
  LOAD_CHAPTER_QUESTIONS_CSV,
  LOAD_FILTERS,
  SET_ACTIVE_FILTER,
  UPDATE_SP_REFERENCE,
} from './actionTypesSagas';

function* getLogsSagas() {
  yield takeEvery(GET_LOGS, getLogs);
}

function* getLogs(action) {
  let logs;
  yield put(updateWaiting(true));
  try {
    if (action.isChapter) {
      logs = yield call(api.getChapterLogs, action.id);
    } else {
      logs = yield call(api.getReferenceLogs, action.id);
    }

    if (logs) {
      yield put(setLogs(logs));
    } else {
      yield put(setLogs([]));
    }

    yield put(updateWaiting(false));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Something happend trying to get activity. Please try again later.',
      ),
    );
    yield put(updateWaiting(false));
  }
}

/**
 * Update study programs of reference
 */
function* updateStudyProgramsSagas() {
  yield takeLatest(UPDATE_SP_REFERENCE, callUpdateStudyPrograms);
}

function* callUpdateStudyPrograms(action) {
  try {
    yield put(updateWaiting(true));
    const reference = yield call(
      api.updateStudyProgramsReference,
      action.studyPrograms,
      action.reference,
    );
    yield put(updateReference(reference));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Study programs updated successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load update study programs. Please try again later.',
      ),
    );
  } finally {
    yield put(updateWaiting(false));
    yield put(closeModal(true));
  }
}

/**
 * Load adminuser filters
 */
function* loadFiltersSagas() {
  yield takeLatest(LOAD_FILTERS, callLoadFilters);
}

function* callLoadFilters(action) {
  try {
    const user = yield call(api.getUserFilters, action.user);
    yield put(setFilters(user.filters));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load filters. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 * Create filter
 */
function* createFilterSagas() {
  yield takeLatest(CREATE_FILTER, callCreateFilter);
}

function* callCreateFilter(action) {
  try {
    const filter = yield call(api.createFilter, action.filter);
    yield put(addFilter(filter));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Filter created successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not create filter. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 * Edit filter
 */
function* editFilterSagas() {
  yield takeLatest(EDIT_FILTER, callEditFilter);
}

function* callEditFilter(action) {
  try {
    const updatedFilter = action.filter;
    delete updatedFilter.adminUsers;
    const filter = yield call(api.updateFilter, updatedFilter);
    yield put(updateFilterAction(filter));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not edit filter. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 * Set active filter
 */
function* setActiveFilterSagas() {
  yield takeLatest(SET_ACTIVE_FILTER, callSetActiveFilter);
}

function* callSetActiveFilter(action) {
  try {
    const activeFilter = action.filter;
    const filter = yield call(api.setActiveFilter, activeFilter);
    yield put(updateFilterAction(filter));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not edit filter. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 * Delete filter
 */
function* deleteFilterSagas() {
  yield takeLatest(DELETE_FILTER, callDeleteFilter);
}

function* callDeleteFilter(action) {
  try {
    yield call(api.deleteFilter, action.filterId);
    yield put(removeFilter(action.filterId));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Filter deleted successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not delete filter. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 * Load chapter questions csv
 */
function* loadChapterQuestionsCSVSagas() {
  yield takeLatest(LOAD_CHAPTER_QUESTIONS_CSV, loadChapterQuestionsCSV);
}

function* loadChapterQuestionsCSV(action) {
  try {
    const questionsArray = [];

    const csv = yield call(
      api.getChapterQuestionsCSV,
      action.chapter,
      action.isAssignment,
    );

    const { report, totalQuestions } = csv;

    questionsArray.push(...report);

    if (totalQuestions > questionsArray.length) {
      yield put(
        Notifications.show(
          {
            title: 'Loading',
            message: 'Loading questions CSV...',
            autoDismiss: 3,
          },
          'info',
        ),
      );

      for (let i = 10; i < totalQuestions; i += 10) {
        const response = yield call(
          api.getChapterQuestionsCSV,
          action.chapter,
          action.isAssignment,
          i,
        );

        questionsArray.push(...response.report);
      }
    }

    const sortedQuestionsByID = questionsArray.sort(
      (a, b) => a.questionID - b.questionID,
    );

    if (action.byDetail) {
      yield put(
        setQuestionCsvByDetail(addLiteralToDocumentPage(sortedQuestionsByID)),
      );
    } else {
      yield put(setQuestionsCSV(addLiteralToDocumentPage(sortedQuestionsByID)));
    }

    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Questions CSV loaded successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    put(
      showNotificationErrorFromException(
        exception,
        'Could not load the question CSV. Please try again later.',
      ),
    );
  } finally {
    yield put(setFinishProcessing(true));
  }
}

function* getNamesToSecondBarSagas() {
  yield takeLatest(GET_NAMES_TO_SECOND_BAR, getNamesToSecondBar);
}

function* getNamesToSecondBar(action) {
  try {
    const response = yield call(api.getNamesToBuildSecondBar, action.letter);
    yield put(setNamesToBuildSecondBar(response));
  } catch (err) {
  } finally {
  }
}

function addLiteralToDocumentPage(allQuestion) {
  // we add a ' at the beginning of the document page to avoid excel to use this column as date
  if (allQuestion.length) {
    return allQuestion.map((question) => {
      if (question.documentpage) {
        question.documentpage = `'${question.documentpage}`;
      }
      return question;
    });
  }
  return allQuestion;
}

/**
 * DEFAULT EXPORT
 */
export default function* sagas() {
  yield all([
    updateStudyProgramsSagas(),
    loadFiltersSagas(),
    createFilterSagas(),
    editFilterSagas(),
    setActiveFilterSagas(),
    deleteFilterSagas(),
    loadChapterQuestionsCSVSagas(),
    getLogsSagas(),
    getNamesToSecondBarSagas(),
  ]);
}

function showNotificationErrorFromException(exception) {
  const message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
