import React from 'react';
import { Col, Row } from 'reactstrap';

class ActivityView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let chapter = '';
    let reference = '';
    let link = '';
    this.props.content.forEach((row) => {
      if (row[0] === 'chapter') {
        reference = row[2].chapter.reference.name;
        chapter = row[2].chapter.name;
        link = row[2].chapter.link;
      }
    });
    return (
      <div>
        <Row>
          {reference && chapter && link ? (
            <Col xs={8}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  <b>{reference}</b> / <b>{chapter}</b>{' '}
                </span>
                <span>
                  <a href={link} target="_blank" rel="noreferrer">
                    View question
                  </a>{' '}
                </span>
              </div>
            </Col>
          ) : null}
          <Col xs={reference && chapter && link ? 4 : 12}>
            <span className="closeButton" onClick={this.props.onCancel}>
              X
            </span>
          </Col>
        </Row>
        <Row className="headerRow">
          <Col xs={4} className="detailactivity-row">
            Type
          </Col>
          <Col xs={4} className="detailactivity-row">
            Old
          </Col>
          <Col xs={4} className="detailactivity-row">
            New
          </Col>
        </Row>
        {this.props.isQuestion &&
          this.props.content.map((row, index) => (
            <div key={index}>
              {row[0] !== 'chapter' && (
                <Row className="headerRow" key={index}>
                  <Col xs={4} className="detailactivity-row">
                    {row[0]}
                  </Col>
                  <Col xs={4} className="detailactivity-row">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: row[1],
                      }}
                    />
                  </Col>
                  <Col xs={4} className="detailactivity-row">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: row[2],
                      }}
                    />
                  </Col>
                </Row>
              )}
            </div>
          ))}
      </div>
    );
  }
}

export default ActivityView;
