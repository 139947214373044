import React from 'react';
import {
  SimpleInput,
  SimpleTextarea,
} from '../../../../commonComponents/Input/Input';
import StartDates from '../StartDates/StartDates';
import StudyPlanEmails from '../StudyPlanEmails/StudyPlanEmails';
import StudyPlanUser from '../StudyPlanUser/StudyPlanUser';
import './StudyPlan.css';
import PreviewPlans from '../PreviewPlans/PreviewPlans';
import moment from 'moment';

class StudyPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      isActive: false,
      showStartDates: false,
      showEmails: false,
      showIntroEmail: false,
      editMode: false,
    };
  }

  componentDidMount() {
    this.props.loadAllRates();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedStudyPlan &&
      (!prevProps.selectedStudyPlan ||
        this.props.selectedStudyPlan.id !== prevProps.selectedStudyPlan.id)
    ) {
      this.setState({
        showStartDates: false,
        showEmails: false,
        showIntroEmail: false,
        editMode: false,
        isActive: this.props.selectedStudyPlan.isActive,
        title: this.props.selectedStudyPlan.title,
      });
    }
  }

  validate = () => {
    let errors = {};
    let fields = this.state;

    if (!fields.title || fields.title === '') {
      errors.title = 'This field is required';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: null, waiting: true });
    if (this.props.studyPlan) {
      fields.id = this.props.studyPlan.id;
    }
    this.props.onSubmit(fields);
  };

  handleStartDates = () => {
    this.setState({
      showStartDates: true,
      showEmails: false,
      showIntroEmail: false,
    });
  };

  handleEdit = () => {
    this.setState({
      editMode: true,
    });
  };

  handleEmails = () => {
    this.setState({
      showStartDates: false,
      showEmails: true,
      showIntroEmail: false,
    });
  };

  handleIntroEmail = () => {
    this.setState({
      showStartDates: false,
      showEmails: false,
      showIntroEmail: true,
    });
  };

  onToggleEditMode = () => {
    let { selectedStudyPlan, onEditStudyPlan } = this.props;
    onEditStudyPlan(
      selectedStudyPlan.id,
      this.state.title,
      this.state.isActive,
    );
    this.setState({ editMode: false });
  };

  render() {
    let {
      selectedStudyPlan,
      updateStartDate,
      updateEmail,
      selectedStudyPlanUser,
      editStudyPlanUser,
      deleteStudyPlanUser,
      previewPlans,
      user = {},
      generalPermissions = [],
    } = this.props;
    let { showStartDates, showEmails, showIntroEmail, editMode } = this.state;
    let canEdit = user.isSuperAdmin
      ? true
      : generalPermissions.find((r) => r === 'Settings.EditTWSP');
    return selectedStudyPlan ? (
      <div className="studyPlan">
        <div>
          <div className="header">
            {!this.state.editMode ? (
              <h1>{selectedStudyPlan ? selectedStudyPlan.title : 'Title'}</h1>
            ) : null}
          </div>
          <div className="dataContainer">
            {!this.state.editMode ? (
              <div className="dataItem">
                <span>
                  <strong>Active:</strong>{' '}
                  {selectedStudyPlan
                    ? selectedStudyPlan.isActive
                      ? 'Yes'
                      : 'No'
                    : 'No'}
                </span>
              </div>
            ) : (
              <div>
                <div>
                  <span>
                    <strong>Title:</strong>
                  </span>
                  <SimpleTextarea
                    type="text"
                    value={this.state.title}
                    onChange={(e) => {
                      this.setState({ title: e.target.value });
                    }}
                    error={
                      this.state.errors && this.state.errors.title
                        ? this.state.errors.title
                        : null
                    }
                  />
                </div>
                <span>
                  <strong>Active:</strong>
                </span>
                <SimpleInput
                  type="checkbox"
                  value={this.state.isActive}
                  onChange={(e) => {
                    this.setState({ isActive: !this.state.isActive });
                  }}
                  error={
                    this.state.errors && this.state.errors.isActive
                      ? this.state.errors.isActive
                      : null
                  }
                />
              </div>
            )}
            <span className="dataItem">
              <strong>Created Date:</strong>{' '}
              {selectedStudyPlan
                ? moment(Number(selectedStudyPlan.createdAt)).format(
                    'YYYY-MM-DD',
                  )
                : ''}
            </span>
            {editMode ? (
              <div style={{ marginBottom: '40px', marginTop: '40px' }}>
                <button
                  className="editingButton"
                  onClick={() => {
                    this.setState({ editMode: false });
                  }}
                >
                  Cancel
                </button>
                <button
                  className="editingButton"
                  onClick={(ev) => {
                    ev.stopPropagation();
                    this.onToggleEditMode();
                  }}
                >
                  Save
                </button>
              </div>
            ) : null}
          </div>
          <div className="buttonsContainer">
            <button
              className={!showStartDates ? 'navyButton' : 'navyButtonClicked'}
              onClick={this.handleStartDates}
            >
              Start Dates
            </button>
            <button
              className={!showEmails ? 'navyButton' : 'navyButtonClicked'}
              onClick={this.handleEmails}
            >
              Emails
            </button>
            <button
              className={!showIntroEmail ? 'navyButton' : 'navyButtonClicked'}
              onClick={this.handleIntroEmail}
            >
              Introduction Email
            </button>
            <button
              className={!editMode ? 'navyButton' : 'navyButtonClicked'}
              onClick={this.handleEdit}
              disabled={!canEdit}
            >
              Edit
            </button>
          </div>
        </div>
        <div className="componentContainer">
          {showStartDates ? (
            <StartDates
              studyPlan={selectedStudyPlan}
              updateStartDate={updateStartDate}
              canEdit={canEdit}
            />
          ) : null}
          {showEmails ? (
            <StudyPlanEmails
              studyPlan={selectedStudyPlan}
              updateEmail={updateEmail}
              introEmail={false}
              canEdit={canEdit}
              showWarning={this.props.showWarning}
            />
          ) : null}
          {showIntroEmail ? (
            <StudyPlanEmails
              studyPlan={selectedStudyPlan}
              updateEmail={updateEmail}
              introEmail={true}
              canEdit={canEdit}
              showWarning={this.props.showWarning}
            />
          ) : null}
        </div>
      </div>
    ) : selectedStudyPlanUser ? (
      <StudyPlanUser
        selectedStudyPlanUser={selectedStudyPlanUser}
        rates={this.props.rates}
        editStudyPlanUser={editStudyPlanUser}
        deleteStudyPlanUser={deleteStudyPlanUser}
        canEdit={canEdit}
      />
    ) : previewPlans ? (
      <PreviewPlans
        rates={this.props.rates}
        studyPlans={this.props.studyPlans}
        sendStudyPlanPreview={this.props.sendStudyPlanPreview}
        canEdit={canEdit}
      />
    ) : null;
  }
}

export default StudyPlan;
