import './CustomerSubmissionStyles.css';

import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import RatesList from '../../commonComponents/RatesList/RatesList';
import CategorySelector from './components/CategorySelector';
import FavoriteQuestionsList from './components/FavoriteQuestionsList';
import SubmittedQuestionsList from './components/SubmittedQuestionsList';
import { useDebounce } from './hooks/useDebounce';

function CustomerSubmissionView({
  rates,
  setRate,
  getAllRates,
  selectedRate,
  getUserSubmittedQuestions,
  isLoadingSubmittedQuestions,
  getFavoriteQuestions,
  userSubmittedQuestions,
  deleteSubmittedQuestion,
  approveSubmittedQuestion,
  rejectSubmittedQuestion,
  editSubmittedQuestion,
  favoriteQuestions,
  isMoreFavoriteQuestions,
  isLoadingFavoriteQuestions,
  removeFavoriteQuestion,
  resetUserSubmittedQuestions,
  setIsLoadingSubmittedQuestions,
  getUserDeletedSubmittedQuestions,
  undeleteSubmittedQuestion,
}) {
  const [selectedCategory, setSelectedCategory] = useState('user-submitted');
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearch = useDebounce(searchValue);

  const handleChangeSelectedCategory = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    getAllRates();
    setRate({
      id: 0,
      name: 'All',
      notifications: 0,
    });
  }, []);

  const ratesWithSearchFilter = rates.filter(
    (rate) =>
      rate.name.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
      rate.shortname?.toLowerCase()?.includes(debouncedSearch.toLowerCase()),
  );

  return (
    <Row>
      <Col className="rate-list__container" md={3}>
        <div className="search__container">
          <div className="flexItemsTitlePlus">
            <div className="booksPageTitle">Rates</div>
            <div className="searchInput">
              <input
                id="input"
                className="form-control search__input"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <i className="fa fa-search fa-lg magnifying__icon" />
            </div>
          </div>
          <div className="lineBooksPage" />
        </div>
        <RatesList
          rates={ratesWithSearchFilter}
          selected={selectedRate}
          setRate={setRate}
          onRateClick={(rate) => {
            setRate(rate);
          }}
        />
      </Col>
      <Col md={9} className="category__column__container">
        <CategorySelector
          selectedCategory={selectedCategory}
          handleChangeSelectedCategory={handleChangeSelectedCategory}
        />
        <section className="user__submitted__container">
          {selectedCategory === 'user-submitted' ? (
            <SubmittedQuestionsList
              selectedRateId={selectedRate.id}
              getUserSubmittedQuestions={getUserSubmittedQuestions}
              isLoadingSubmittedQuestions={isLoadingSubmittedQuestions}
              userSubmittedQuestions={userSubmittedQuestions}
              deleteSubmittedQuestion={deleteSubmittedQuestion}
              approveSubmittedQuestion={approveSubmittedQuestion}
              rejectSubmittedQuestion={rejectSubmittedQuestion}
              editSubmittedQuestion={editSubmittedQuestion}
              resetUserSubmittedQuestions={resetUserSubmittedQuestions}
              setIsLoadingSubmittedQuestions={setIsLoadingSubmittedQuestions}
              getUserDeletedSubmittedQuestions={
                getUserDeletedSubmittedQuestions
              }
              undeleteSubmittedQuestion={undeleteSubmittedQuestion}
            />
          ) : (
            <FavoriteQuestionsList
              selectedRateId={selectedRate.id}
              selectedRateShortName={selectedRate.shortname}
              getFavoriteQuestions={getFavoriteQuestions}
              favoriteQuestions={favoriteQuestions}
              isMoreFavoriteQuestions={isMoreFavoriteQuestions}
              isLoadingFavoriteQuestions={isLoadingFavoriteQuestions}
              removeFavoriteQuestion={removeFavoriteQuestion}
            />
          )}
        </section>
      </Col>
    </Row>
  );
}

export default CustomerSubmissionView;
