import './ReferencesGridAccordion.css';

import React from 'react';
import TreeView from 'react-treeview';
import _ from 'underscore';

import AssociateRatesToReferenceContainer from '../../components/AssociateRatesToReference/components/AssociateRatesToReferenceContainer';
import BookItem from '../../components/Books/components/BookItem';
import { hasPagePermission } from '../../utilities/FilterLinksForTabs';
import ConfirmDeletion from '../Modals/ConfirmDeletion/ConfirmDeletion';
import CreateChapter from '../Modals/CreateChapter/CreateChapter';
import CreateReference from '../Modals/CreateReference/CreateReference';
import TextLink from '../TextLink/TextLink';

const expandText = 'Expand all';

class ReferencesGridAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      references: ReferencesGridAccordion.addFieldsToReferences(
        this.props.references,
      ),
      data: [],
      referenceSelected: {},
      rows: this.getDefaultRows(this.props.references),
      text: expandText,
      expandAll: false,
      referenceToAdd: {
        mode: 'add',
        name: '',
        loading: false,
      },
      selectedQual: props.selectedQual,
    };
  }

  componentWillReceiveProps(nextProps) {
    /* let chapterIds = _.pluck(
      nextProps.chapters,
      'id'
    ); ,
      mergedReferences = ReferencesGridAccordion.addFieldsToReferences(
        nextProps.references.map(reference => {
          if (this.state.references.length > 0) {
            let stateReference = this.state.references.find(
              t => t.id === reference.id
            );
            if (
              stateReference &&
              chapterIds.length <= stateReference.chapters.length
            ) {
              stateReference.chapters = stateReference.chapters.filter(
                chapter => chapterIds.indexOf(chapter.id)
              );
              return Object.assign({}, reference, stateReference);
            } else {
              return Object.assign({}, stateReference, reference);
            }
          } else {
            return reference;
          }
        })
      ); */
    const mergedReferences = ReferencesGridAccordion.addFieldsToReferences(
      nextProps.references,
    );
    this.setState({
      originalReferences: mergedReferences,
      references: mergedReferences,
      rows: this.getDefaultRows(nextProps.references),
      text: expandText,
      selectedQual: nextProps.selectedQual,
      referenceToAdd: {
        mode: 'add',
        name: '',
        loading: false,
      },
    });
  }

  static addFieldsToReferences = (references = [], defaultCollapsed = true) =>
    references.map((reference) => {
      reference.collapsed =
        reference.collapsed !== undefined
          ? reference.collapsed
          : defaultCollapsed;

      reference.mode = 'normal';
      reference.editedName = reference.name;
      reference.loading = false;

      let chapterToAddText = '';
      if (reference.chapterToAdd) {
        chapterToAddText = reference.chapterToAdd.loading
          ? ''
          : reference.chapterToAdd.name;
      }

      reference.chapterToAdd = {
        mode: reference.chapterToAdd ? reference.chapterToAdd.mode : 'add',
        name: chapterToAddText,
        loading: false,
      };

      reference.chapters = reference.chapters.map((chapter) => {
        chapter.mode = 'normal';
        chapter.loading = false;

        return chapter;
      });

      return reference;
    });

  getDefaultRows = (references) => {
    const defaultRows = {};
    references.forEach((value, i) => {
      defaultRows[i] = false;
    });
    return defaultRows;
  };

  toggleReference = (index) => {
    if (this.state.references[index].mode === 'edit') return;

    const references = this.state.references;
    references[index].collapsed = !references[index].collapsed;

    this.setState({ references });
  };

  toggleExpandAll = () => {
    this.setState({
      references: this.state.references.map((reference) => {
        reference.collapsed = this.state.expandAll;
        return reference;
      }),
      expandAll: !this.state.expandAll,
    });
  };

  updateNameReferenceToAdd = (name) => {
    const referenceToAdd = this.state.referenceToAdd;

    referenceToAdd.name = name;

    this.setState({ referenceToAdd });
  };

  updateNameChapterToAdd = (index, name) => {
    const references = this.state.references;

    references[index].chapterToAdd.name = name;

    this.setState({ references });
  };

  updateChapterIndex = (index, old_index, new_index, chapterId) => {
    const references = this.state.references;

    new_index = Math.min(new_index, references[index].chapters.length);
    if (new_index - 1 >= references[index].chapters.length) {
      let k = new_index - 1 - references[index].chapters.length + 1;
      while (k--) {
        references[index].chapters.push(undefined);
      }
    }
    references[index].chapters.splice(
      new_index - 1,
      0,
      references[index].chapters.splice(old_index - 1, 1)[0],
    );
    this.setState({ references });
    return this.props.updateChapter(
      { presentationIndex: new_index },
      chapterId,
    );
  };

  createChapter = (index, text, desc, reference) => {
    const references = this.state.references;
    let name = text;
    let description = desc;
    if (!description) {
      const textParts = text.split('-');
      if (textParts.length === 1) {
        references[index].chapterToAdd.error = true;
        this.setState({ references });
        return;
      }
      references[index].chapterToAdd.error = false;
      name = text.split('-')[0].toUpperCase();
      description = text.split('-')[1].toUpperCase();
    }

    references[index].chapterToAdd.loading = true;
    this.setState({ references });
    const chapter = {
      name: name.trim(),
      description: description.trim(),
      reference: reference.id,
    };
    return this.props.createChapter(chapter);
  };

  updateChapter = (index, indexSt, name, chapterId) => {
    const references = this.state.references;
    references[index].chapters[indexSt].loading = true;
    this.setState({ references });

    return this.props.updateChapter({ name }, chapterId);
  };

  deleteRowChapter = (idChapter, idReference, references) => {
    const indexReference = references.findIndex(
      (ref) => ref.id === idReference,
    );
    let indexChapter;
    if (indexReference !== -1) {
      indexChapter = references[indexReference].chapters.findIndex(
        (chapt) => chapt.id === idChapter,
      );
    }
    if (indexChapter !== -1 && indexReference !== -1) {
      references[indexReference].chapters.splice(indexChapter, 1);
    }
  };

  deleteAndCleanChapter = (chapter, reference) => {
    this.props.deleteChapter(
      reference.id,
      chapter.id,
      chapter.presentationIndex,
    );
    this.deleteRowChapter(chapter.id, reference.id, this.state.references);
  };

  deleteChapter = (chapter, reference) => {
    this.props.openModal({
      modalTitle: 'Deleting a chapter',
      modalContent: (
        <ConfirmDeletion
          onSubmit={() => this.deleteAndCleanChapter(chapter, reference)}
          message={`You are about to delete the chapter ${chapter.name} .`}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  createReference = (text, desc) => {
    const referenceToAdd = this.state.referenceToAdd;
    let name = text;
    let description = desc;
    if (!description) {
      const textParts = text.split('-');
      if (textParts.length === 1) {
        referenceToAdd.error = true;
        this.setState({ referenceToAdd });
        return;
      }
      referenceToAdd.error = false;
      name = text.split('-')[0].toUpperCase();
      description = text.split('-')[1].toUpperCase();
    }

    referenceToAdd.loading = true;
    this.setState({ referenceToAdd });
    const reference = {
      name,
      description,
      actAsParent: true,
      selectedQual: this.state.selectedQual,
      qualAdded: true,
    };
    return this.props.createReference(reference);
  };

  updateReference = (index, name, referenceId) => {
    const references = this.state.references;
    references[index].loading = true;
    this.setState({ references });
    return this.props.updateReference({ name }, referenceId);
  };

  deleteReference = (reference) => {
    this.props.openModal({
      modalTitle: 'Deleting a reference',
      modalContent: (
        <ConfirmDeletion
          onSubmit={() => this.props.deleteReference(reference)}
          message={`You are about to delete the reference ${reference.name} .`}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  openEditReferenceModal = (reference) => {
    this.props.openModal({
      modalContent: (
        <CreateReference
          onSubmit={(fields) =>
            this.props.updateReference(fields, reference.id)
          }
          onCancel={this.props.closeModal}
          onDelete={() => this.deleteReference(reference)}
          edit
          reference={reference}
          getUrlPdf={this.props.getUrlPdf}
        />
      ),
      modalClassName: 'modal-create-reference',
      className: '',
    });
  };

  openEditChapterModal = (chapter, reference) => {
    this.props.openModal({
      modalContent: (
        <CreateChapter
          onSubmit={(fields) => this.props.updateChapter(fields, chapter.id)}
          onCancel={this.props.closeModal}
          edit
          reference={reference}
          chapter={chapter}
          onDelete={() => this.deleteChapter(chapter, reference)}
          chaptersOfReference={reference.chapters.length}
          getUrlPdf={this.props.getUrlPdf}
        />
      ),
      modalClassName: 'modal-create-chapter',
      className: '',
    });
  };

  associateRatesModal = (reference) => {
    this.props.openModal({
      modalTitle: 'Associate rates',
      modalContent: (
        <AssociateRatesToReferenceContainer
          onSubmit={(rates, reference) =>
            this.props.associateRates(rates, reference)
          }
          onCancel={this.props.closeModal}
          reference={reference}
        />
      ),
      modalClassName: 'modal-associate-rates',
    });
  };

  hasEditPermission = () => {
    const { generalRolesPermissions, user } = this.props;
    const isSuperAdmin = user ? user.isSuperAdmin : false;
    if (
      (hasPagePermission('Books.View', generalRolesPermissions) &&
        hasPagePermission('Books.Edit', generalRolesPermissions)) ||
      isSuperAdmin
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { expandAll } = this.state;
    const showTable =
      this.props.references.length > 0 || this.props.references.length > 0;
    return (
      <div className="centerText">
        {showTable ? (
          <div>
            <BookItem
              hasEditPermission={this.hasEditPermission}
              isQual
              getUrlPdf={this.props.getUrlPdf}
              urlPdfPrivate={this.props.urlPdfPrivate}
              waiting={this.props.waiting}
              item={this.state.referenceToAdd}
              level={0}
              onUpdate={(name) => this.updateNameReferenceToAdd(name)}
              onAdd={this.createReference}
              isReference
            />
            <TextLink
              text={expandAll ? 'Collapse all' : 'Expand all'}
              onAction={this.toggleExpandAll}
              style={{ textAlign: 'left', marginBottom: '5px' }}
            />
            {this.state.references &&
              this.state.references.map((reference, index) => (
                <TreeView
                  key={`reference-${reference.id}`}
                  collapsed={reference.collapsed}
                  onClick={() => this.toggleReference(index)}
                  nodeLabel={
                    <BookItem
                      hasEditPermission={this.hasEditPermission}
                      isQual
                      getUrlPdf={this.props.getUrlPdf}
                      urlPdfPrivate={this.props.urlPdfPrivate}
                      waiting={this.props.waiting}
                      item={reference}
                      level={1}
                      onUpdatePdf={(pdf) =>
                        this.props.onUpdatePdf(pdf, reference.id, true)
                      }
                      onClick={() => this.toggleReference(index)}
                      onToggleEditMode={() => this.toggleEditMode(index)}
                      onUpdateEditedName={(editedName) =>
                        this.updateEditedName(index, editedName)
                      }
                      onUpdate={(name) =>
                        this.updateReference(index, name, reference.id)
                      }
                      onDelete={() => this.deleteReference(reference)}
                      onPlusButton={() => this.associateRatesModal(reference)}
                      openEditModal={() =>
                        this.openEditReferenceModal(reference)
                      }
                      setLoadingPdf={() => {
                        const references = this.state.references;
                        references[index].isLoadingPdf = true;
                        this.setState({ references });
                      }}
                      references={this.state.references}
                    />
                  }
                >
                  <div className="subtree">
                    <BookItem
                      hasEditPermission={this.hasEditPermission}
                      isQual
                      getUrlPdf={this.props.getUrlPdf}
                      urlPdfPrivate={this.props.urlPdfPrivate}
                      waiting={this.props.waiting}
                      item={reference.chapterToAdd}
                      level={2}
                      book_index={index}
                      allChapters={reference.chapters}
                      onUpdateIndex={(dropped_chapter, old_index, new_index) =>
                        this.updateChapterIndex(
                          index,
                          old_index,
                          new_index,
                          dropped_chapter.id,
                        )
                      }
                      onUpdate={(name) =>
                        this.updateNameChapterToAdd(index, name)
                      }
                      onAdd={(name, desc) =>
                        this.createChapter(index, name, desc, reference)
                      }
                    />
                    {reference.chapters &&
                      reference.chapters.map((chapter, indexSt) => (
                        <BookItem
                          hasEditPermission={this.hasEditPermission}
                          isQual
                          getUrlPdf={this.props.getUrlPdf}
                          urlPdfPrivate={this.props.urlPdfPrivate}
                          waiting={this.props.waiting}
                          key={`chapter-${chapter.id}`}
                          item={chapter}
                          level={2}
                          index={indexSt}
                          book_index={index}
                          allChapters={reference.chapters}
                          onToggleEditMode={() =>
                            this.toggleEditMode(index, indexSt)
                          }
                          onUpdateEditedName={(editedName) =>
                            this.updateEditedName(index, editedName, indexSt)
                          }
                          onUpdate={(name) =>
                            this.updateChapter(index, indexSt, name, chapter.id)
                          }
                          onUpdateIndex={(
                            dropped_chapter,
                            old_index,
                            new_index,
                          ) =>
                            this.updateChapterIndex(
                              index,
                              old_index,
                              new_index,
                              dropped_chapter.id,
                            )
                          }
                          onDelete={() =>
                            this.deleteChapter(chapter, reference)
                          }
                          openEditModal={() =>
                            this.openEditChapterModal(chapter, reference)
                          }
                          rolesPermissions={this.props.rolesPermissions}
                          isSuperAdmin={
                            this.props.user
                              ? this.props.user.isSuperAdmin
                              : false
                          }
                          setLoadingPdf={() => {
                            const references = this.state.references;
                            references[index].chapters[indexSt].isLoadingPdf =
                              true;
                            this.setState({ references });
                          }}
                          onUpdatePdf={(pdf) =>
                            this.props.onUpdatePdf(pdf, chapter.id, false)
                          }
                          selectedRate={
                            this.props.isQual
                              ? this.props.selectedQual
                              : this.props.rate
                          }
                          generalRolesPermissions={
                            this.props.generalRolesPermissions
                          }
                          onAssign={() =>
                            this.props.onAssign(reference, chapter)
                          }
                          references={this.state.references}
                        />
                      ))}
                  </div>
                </TreeView>
              ))}
          </div>
        ) : (
          <div>
            <BookItem
              hasEditPermission={this.hasEditPermission}
              isQual
              getUrlPdf={this.props.getUrlPdf}
              urlPdfPrivate={this.props.urlPdfPrivate}
              waiting={this.props.waiting}
              item={this.state.referenceToAdd}
              level={0}
              onUpdate={(name) => this.updateNameReferenceToAdd(name)}
              onAdd={this.createReference}
              isReference
            />
            <span>No references found</span>
          </div>
        )}
      </div>
    );
  }
}

ReferencesGridAccordion.defaultProps = { references: [] };

export default ReferencesGridAccordion;
