import React from 'react';
import TreeView from 'react-treeview';
import TextLink from '../TextLink/TextLink';
import TopicItem from './TopicItem';
import TopicItemContainer from '../../components/Subtopics/components/TopicItemContainer';
import SubtopicChaptersContainer from '../../components/Subtopics/components/SubtopicChaptersContainer';
import './TopicsList.css';

class TopicsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topics: TopicsList.addFieldsToTopics(this.props.topics),
      topicToAdd: {
        mode: 'add',
        name: '',
        loading: false,
      },
      expandAll: false,
      updateSource: '',
      chaptersUpdatedThisTopic: [],
      redoChaptersUpdatedThisTopic: [],
      waitingSubtopic: false,
    };
  }

  componentWillReceiveProps({
    topics,
    waiting,
    topicsInmutable,
    topicTypeCreated,
  }) {
    if (topicsInmutable !== this.props.topicsInmutable) {
      const mergedTopics = TopicsList.addFieldsToTopics(
        topics.map((topic) =>
          Object.assign(
            {},
            this.state.topics.find((t) => t.id === topic.id),
            topic,
          ),
        ),
      );

      this.setState({
        topics: mergedTopics,
        topicToAdd: {
          mode: 'add',
          name: '',
          loading: false,
        },
      });
    } else if (!waiting) {
      this.setState({
        topics: this.state.topics.map((topic) => {
          topic.loading = false;

          return topic;
        }),
        topicToAdd: Object.assign({}, this.state.topicToAdd, {
          loading: false,
        }),
      });
      if (topicTypeCreated.type === 'topic') {
        if (window.jQuery('input#topic')[0]) {
          window.jQuery('input#topic')[0].focus();
        }
      } else {
        if (window.jQuery(`input#${topicTypeCreated.topicId}`)[0]) {
          window.jQuery(`input#${topicTypeCreated.topicId}`)[0].focus();
        }
      }
    }
    if (this.state.waitingSubtopic) {
      this.setState({
        topics: TopicsList.addFieldsToTopics(topics),
        waitingSubtopic: false,
      });
    }
  }

  static addFieldsToTopics = (topics = [], defaultCollapsed = true) => {
    return topics.map((topic) => {
      topic.collapsed =
        topic.collapsed !== undefined ? topic.collapsed : defaultCollapsed;

      topic.mode = 'normal';
      topic.editedName = topic.name;
      topic.loading = false;

      let subtopicToAddText = '';
      if (topic.subtopicToAdd) {
        subtopicToAddText = topic.subtopicToAdd.loading
          ? ''
          : topic.subtopicToAdd.name;
      }

      topic.subtopicToAdd = {
        mode: topic.subtopicToAdd ? topic.subtopicToAdd.mode : 'add',
        name: subtopicToAddText,
        loading: false,
      };

      topic.subtopics = topic.subtopics.map((subtopic) => {
        subtopic.mode = 'normal';
        subtopic.loading = false;

        return subtopic;
      });

      return topic;
    });
  };

  toggleTopic = (index) => {
    if (this.state.topics[index].mode === 'edit') return;

    const topics = this.state.topics;
    topics[index].collapsed = !topics[index].collapsed;

    this.setState({ topics });
  };

  toggleEditMode = (index, indexSt = null) => {
    const topics = this.state.topics;

    if (indexSt === null) {
      if (topics[index].mode === 'normal') {
        topics[index].mode = 'edit';
        topics[index].focus = true;
      } else {
        topics[index].mode = 'normal';
        topics[index].focus = false;
      }
      topics[index].editedName = topics[index].name;
    } else {
      if (topics[index].subtopics[indexSt].mode === 'normal') {
        topics[index].subtopics[indexSt].mode = 'edit';
        topics[index].subtopics[indexSt].focus = true;
      } else {
        topics[index].subtopics[indexSt].mode = 'normal';
        topics[index].subtopics[indexSt].focus = false;
      }

      topics[index].subtopics[indexSt].editedName =
        topics[index].subtopics[indexSt].name;
    }

    this.setState({ topics });
  };

  updateEditedName = (index, editedName, indexSt = null) => {
    const topics = this.state.topics;

    if (indexSt === null) {
      topics[index].editedName = editedName;
    } else {
      topics[index].subtopics[indexSt].editedName = editedName;
    }

    this.setState({ topics });
  };

  updateNameTopicToAdd = (name) => {
    const topicToAdd = this.state.topicToAdd;

    topicToAdd.name = name;

    this.setState({ topicToAdd });
  };

  updateNameSubtopicToAdd = (index, name) => {
    const topics = this.state.topics;

    topics[index].subtopicToAdd.name = name;

    this.setState({ topics });
  };

  updateTopic = (index, name) => {
    const topics = this.state.topics;
    topics[index].loading = true;
    this.setState({ topics });

    return this.props.updateTopic(index, name);
  };

  updateSubtopic = (index, indexSt, name) => {
    const topics = this.state.topics;
    topics[index].subtopics[indexSt].loading = true;
    this.setState({ topics });

    return this.props.updateSubtopic(index, indexSt, name);
  };

  createTopic = (name, index) => {
    const topicToAdd = this.state.topicToAdd;

    topicToAdd.loading = true;
    this.setState({ topicToAdd });

    return this.props.createTopic(name.toUpperCase().trim(), index);
  };

  createSubtopic = (index, name) => {
    const topics = this.state.topics;

    topics[index].subtopicToAdd.loading = true;
    this.setState({ topics, waitingSubtopic: true });

    return this.props.createSubtopic(index, name.toUpperCase().trim());
  };

  deleteTopic = (index) => {
    const topics = this.state.topics;

    topics[index].loading = true;

    this.setState({ topics });

    return this.props.deleteTopic(index);
  };

  deleteSubtopic = (index, indexSt) => {
    const topics = this.state.topics;

    topics[index].subtopics[indexSt].loading = true;

    this.setState({ topics });

    return this.props.deleteSubtopic(index, indexSt);
  };

  toggleExpandAll = () => {
    this.setState({
      topics: this.state.topics.map((topic) => {
        topic.collapsed = this.state.expandAll;
        return topic;
      }),
      expandAll: !this.state.expandAll,
    });
  };

  openUpdateChaptersModal = (subtopic) => {
    const rate =
      this.props.topics && this.props.topics.length
        ? this.props.topics[0].rate
        : 0;
    this.props.onOpenModal({
      modalTitle: 'Add Chapters',
      modalContent: (
        <SubtopicChaptersContainer
          onCancel={this.props.closeModal}
          onSetUpdatedChaptersCache={(chapters, subtopicId) =>
            this.setUpdatedChaptersCache(chapters, subtopicId)
          }
          subtopic={subtopic}
          rate={rate}
          chaptersUpdatedThisTopic={this.state.chaptersUpdatedThisTopic}
        />
      ),
      modalClassName: 'modal-add-subtopic-chapters',
    });
  };

  setUpdatedChaptersCache = (chaptersUpdatedThisTopic, subtopicId) => {
    // Update the cache and clear the redo cache because the sequence changed
    const filteredRedoCache = this.state.redoChaptersUpdatedThisTopic.filter(
      (r) => r.subtopic.id !== subtopicId,
    );
    this.setState({
      chaptersUpdatedThisTopic,
      redoChaptersUpdatedThisTopic: [...filteredRedoCache],
    });
  };

  popChapterUpdatedThisTopic = () => {
    const chaptersCached = [...this.state.chaptersUpdatedThisTopic];
    const redoChaptersCached = [...this.state.redoChaptersUpdatedThisTopic];
    const poppedChapters = chaptersCached.pop();
    redoChaptersCached.push(poppedChapters);
    this.setState({
      redoChaptersUpdatedThisTopic: redoChaptersCached,
      chaptersUpdatedThisTopic: chaptersCached,
    });
  };

  popRedoChapterUpdatedThisTopic = () => {
    const chaptersCached = [...this.state.chaptersUpdatedThisTopic];
    const redoChaptersCached = [...this.state.redoChaptersUpdatedThisTopic];
    const poppedChapters = redoChaptersCached.pop();
    chaptersCached.push(poppedChapters);
    this.setState({
      redoChaptersUpdatedThisTopic: redoChaptersCached,
      chaptersUpdatedThisTopic: chaptersCached,
    });
  };

  render() {
    const {
      expandAll,
      chaptersUpdatedThisTopic,
      redoChaptersUpdatedThisTopic,
    } = this.state;

    return (
      <div className="topics-list-component">
        <TopicItem
          id="topic"
          waiting={this.props.waiting}
          item={this.state.topicToAdd}
          level={0}
          onUpdate={(name) => this.updateNameTopicToAdd(name)}
          onAdd={(name) => this.createTopic(name, 'topic')}
        />
        {this.props.topics.length > 0 && (
          <TextLink
            text={expandAll ? 'Collapse all' : 'Expand all'}
            onAction={this.toggleExpandAll}
          />
        )}
        {this.state.topics.map((topic, index) => (
          <TreeView
            key={`topic-${topic.id}`}
            collapsed={topic.collapsed}
            onClick={() => this.toggleTopic(index)}
            nodeLabel={
              <TopicItem
                waiting={this.props.waiting}
                item={topic}
                level={1}
                onClick={() => this.toggleTopic(index)}
                onToggleEditMode={() => this.toggleEditMode(index)}
                onUpdateEditedName={(editedName) =>
                  this.updateEditedName(index, editedName)
                }
                onUpdate={(name) => this.updateTopic(index, name)}
                onDelete={() => this.deleteTopic(index)}
              />
            }
          >
            <div className="subtree">
              <TopicItem
                id={topic.id}
                waiting={this.props.waiting}
                item={topic.subtopicToAdd}
                level={2}
                onUpdate={(name) => this.updateNameSubtopicToAdd(index, name)}
                onAdd={(name) => this.createSubtopic(index, name)}
              />
              {topic.subtopics.map((subtopic, indexSt) => (
                <TopicItemContainer
                  waiting={this.props.waiting}
                  key={`subtopic-${subtopic.id}`}
                  item={subtopic}
                  level={2}
                  onToggleEditMode={() => this.toggleEditMode(index, indexSt)}
                  onUpdateEditedName={(editedName) =>
                    this.updateEditedName(index, editedName, indexSt)
                  }
                  onUpdate={(name) => this.updateSubtopic(index, indexSt, name)}
                  onDelete={() => this.deleteSubtopic(index, indexSt)}
                  onClick={() => this.openUpdateChaptersModal(subtopic)}
                  chaptersUpdatedThisTopic={chaptersUpdatedThisTopic.filter(
                    (c) => c.subtopic.id === subtopic.id,
                  )}
                  redoChaptersUpdatedThisTopic={redoChaptersUpdatedThisTopic.filter(
                    (c) => c.subtopic.id === subtopic.id,
                  )}
                  onUndoChaptersUpdatedThisTopic={
                    this.popChapterUpdatedThisTopic
                  }
                  onRedoChaptersUpdatedThisTopic={
                    this.popRedoChapterUpdatedThisTopic
                  }
                />
              ))}
            </div>
          </TreeView>
        ))}
      </div>
    );
  }
}

export default TopicsList;
