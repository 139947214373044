import { Map } from 'immutable';
import Cookies from 'universal-cookie';

import getCookieTokenDomain from '../../utilities/getCookieTokenDomain';
import * as userToken from '../../utilities/userToken';
import { SET_FILTERS } from '../Books/actionTypes';
import {
  AUTHY_ERROR,
  AUTHY_REQUESTING,
  AUTHY_VERIFICATION_REQUEST,
  LOGIN_DEFAULT,
  LOGIN_ERROR,
  LOGIN_REQUESTING,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  RESEND_CODE_DONE,
  RESEND_CODE_REQUEST,
  SET_LOADING,
  SET_LOGIN_AS,
  SET_PERMISSIONS,
  UPDATE_PERMISSIONS,
  UPDATE_USER,
  UPDATE_USER_EXPIRED_PASSWORD,
  UPDATE_USER_VALID_PASSWORD,
} from './constants';

const cookies = new Cookies();

const initialState = Map({
  requesting: false,
  successful: false,
  authy_requesting: false,
  messages: [],
  errors: [],
  generalRolesPermissions: [],
  rolesPermissions: [],
  user: {},
  loadingPermissions: false,
  loading: true,
  loginAs: false,
  filters: [],
  email: '',
  authy_waiting: false,
  user_phone: null,
  user_cc: null,
});

const reducer = function loginReducer(state = initialState, action) {
  const cookieTokenDomain = getCookieTokenDomain(window.location);
  switch (action.type) {
    case LOGIN_REQUESTING:
      return state
        .set('requesting', true)
        .set('successful', false)
        .set('authy_requesting', false)
        .set('authy_waiting', false)
        .set('messages', [{ body: 'Logging in...', time: new Date() }])
        .set('errors', []);

    case LOGIN_DEFAULT:
      return initialState;

    case LOGIN_SUCCESS:
      cookies.set('isSuperAdmin', action.payload.user.isSuperAdmin, {
        path: '/',
      });
      return state
        .set('errors', [])
        .set('messages', [])
        .set('requesting', false)
        .set('authy_requesting', false)
        .set('authy_waiting', false)
        .set('successful', true)
        .set(
          'generalRolesPermissions',
          action.payload.roles.generalRolesPermissions,
        )
        .set('rolesPermissions', action.payload.roles.rolesPermissions)
        .set('user', action.payload.user)
        .set('user_phone', action.payload.user.phone)
        .set('user_cc', action.payload.user.countryCode);

    case AUTHY_REQUESTING:
      return state
        .set('requesting', false)
        .set('successful', false)
        .set('authy_requesting', true)
        .set('authy_waiting', false)
        .set('messages', [])
        .set('errors', [])
        .set('email', action.payload.user.email);

    case AUTHY_VERIFICATION_REQUEST:
      return state
        .set('requesting', true)
        .set('successful', false)
        .set('authy_requesting', true)
        .set('authy_waiting', true)
        .set('messages', [])
        .set('errors', []);

    case RESEND_CODE_REQUEST:
      return state
        .set('requesting', true)
        .set('successful', false)
        .set('authy_requesting', true)
        .set('authy_waiting', true)
        .set('messages', [])
        .set('errors', []);

    case RESEND_CODE_DONE:
      return state
        .set('requesting', false)
        .set('successful', false)
        .set('authy_requesting', true)
        .set('authy_waiting', true)
        .set('messages', [])
        .set('errors', [])
        .set('email', action.payload.email);

    case SET_FILTERS:
      return state.set('filters', action.filters);

    case LOGIN_ERROR:
      return state
        .set(
          'errors',
          state.get('errors').concat([
            {
              body:
                action.error.response && action.error.response.data.toString()
                  ? action.error.response.data.toString()
                  : 'Ops!, could not login. Please try again later',
              time: new Date(),
            },
          ]),
        )
        .set('messages', [])
        .set('requesting', false)
        .set('authy_requesting', false)
        .set('successful', false);

    case AUTHY_ERROR:
      return state
        .set(
          'errors',
          state.get('errors').concat([
            {
              body:
                action.error.response && action.error.response.data.toString()
                  ? action.error.response.data.toString()
                  : 'Ops!, could not login. Please try again later',
              time: new Date(),
            },
          ]),
        )
        .set('messages', [])
        .set('requesting', false)
        .set('authy_requesting', true)
        .set('successful', false);

    case LOGOUT_USER:
      // cookies.remove('token', { path: '/', domain: cookieTokenDomain });
      userToken.removeUserToken();
      cookies.remove('isSuperAdmin', { path: '/' });
      cookies.remove('loading', { path: '/' });
      return state
        .set('generalRolesPermissions', [])
        .set('rolesPermissions', [])
        .set('user', {});

    case UPDATE_PERMISSIONS:
      cookies.set('isSuperAdmin', action.payload.user.isSuperAdmin, {
        path: '/',
      });
      cookies.set('userRoles', action.payload.roles.generalRolesPermissions, {
        path: '/',
      });
      cookies.set('loading', false, { path: '/' });
      return state
        .set(
          'generalRolesPermissions',
          action.payload.roles.generalRolesPermissions,
        )
        .set('rolesPermissions', action.payload.roles.rolesPermissions)
        .set('user', action.payload.user)
        .set('loadingPermissions', false)
        .set('loading', false);

    case SET_PERMISSIONS:
      return state
        .set('generalRolesPermissions', action.payload.generalRolesPermissions)
        .set('rolesPermissions', action.payload.rolesPermissions)
        .set('loadingPermissions', false)
        .set('loading', false);

    case SET_LOADING:
      return state.set('loading', action.payload);

    case SET_LOGIN_AS:
      cookies.set('loginAs', action.payload, {
        path: '/',
      });
      return state;

    case UPDATE_USER:
      return state
        .set('user', action.payload)
        .set('user_phone', action.payload.phone)
        .set('user_cc', action.payload.countryCode);

    case UPDATE_USER_VALID_PASSWORD:
      return state.setIn(['user', 'validPasswordCriteria'], action.payload);

    case UPDATE_USER_EXPIRED_PASSWORD:
      return state.setIn(['user', 'isPasswordExpired'], action.payload);

    default:
      return state;
  }
};

export default reducer;
