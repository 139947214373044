import './Prompt.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';

import PromptCreateView from './components/PromptCreateView';
import PromptList from './components/PromptList';

const listOptions = ['Question', 'Summary', 'Suggestion'];
class Prompts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInput: '',
      loadInfo: false,
      activeTab: 'Question',
    };
    this.props.getAllPrompts();
  }

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    return (
      <Row className="promptContainer">
        <Col md={3} className="promptListContainer">
          <div className="promptList">
            <PromptList
              prompts={this.props.prompts}
              setPromptDetail={this.props.setPromptDetail}
              loadPromptDetails={this.props.loadPromptDetails}
              setCreateMode={this.props.setCreateMode}
              activeTab={this.state.activeTab}
              setActiveTab={this.setActiveTab}
            />
          </div>
        </Col>
        <Col md={9} className="animated fadeIn">
          {(this.props.promptDetails?.id || this.props.createMode) && (
            <PromptCreateView
              createPrompt={this.props.createPrompt}
              loadPromptDetails={this.props.loadPromptDetails}
              editPrompt={this.props.editPrompt}
              promptDetails={this.props.promptDetails}
              createMode={this.props.createMode}
              deletePrompt={this.props.deletePrompt}
              activePrompt={this.props.activePrompt}
              activeTab={this.state.activeTab}
            />
          )}
        </Col>
      </Row>
    );
  }
}

export default Prompts;
