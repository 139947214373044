import { connect } from 'react-redux';
import {
  getAssignModalWaiting,
  getAssignModalSuccess,
  getAssignModalError,
  getAssignModalReferences,
  getAssignModalWriters,
  getAssignModalRates,
  getAssignModalSelectedRate,
  getAssignModalLoadingReferences,
} from '../../../selectors';
import {
  setAssignModalWaiting,
  setAssignModalSuccess,
  setAssignModalError,
  setAssignModalReferences,
  setAssignModalRates,
  setAssignModalSelectedRate,
} from '../../../actions';
import {
  loadRatesForAssignModal,
  loadReferencesForAssignModal,
  loadAllWriters,
} from '../../../actionsSagas';
import AssignWriterModal from './AssignWriterModal';
import {
  loadWriters,
  createAssignment,
} from '../../../../Writers/actionsSagas';
import { getWriters } from '../../../../Writers/selectors';
import { getUser } from '../../../../Login/selectors';

const mapStateToProps = (state) => {
  return {
    user: getUser(state),
    assignModalWaiting: getAssignModalWaiting(state),
    assignModalError: getAssignModalError(state),
    assignModalSuccess: getAssignModalSuccess(state),
    assignModalReferences: getAssignModalReferences(state),
    assignModalWriters: getAssignModalWriters(state),
    assignModalRates: getAssignModalRates(state),
    assignModalSelectedRate: getAssignModalSelectedRate(state),
    assignModalLoadingReferences: getAssignModalLoadingReferences(state),
    writers: getWriters(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadRatesForAssignModal: () => {
      dispatch(loadRatesForAssignModal());
    },
    setAssignModalWaiting: (value) => {
      dispatch(setAssignModalWaiting(value));
    },
    setAssignModalSuccess: (value) => {
      dispatch(setAssignModalSuccess(value));
    },
    setAssignModalError: (value) => {
      dispatch(setAssignModalError(value));
    },
    setAssignModalReferences: (references) => {
      dispatch(setAssignModalReferences(references));
    },
    setAssignModalRates: (rates) => {
      dispatch(setAssignModalRates(rates));
    },
    setAssignModalSelectedRate: (selectedRate) => {
      dispatch(setAssignModalSelectedRate(selectedRate));
    },
    loadReferencesForAssignModal: (studyProgramType, typeId) => {
      dispatch(loadReferencesForAssignModal(studyProgramType, typeId));
    },
    getWriters: (type, typeId) => {
      dispatch(loadWriters(type, typeId));
    },
    createAssignment: (
      chapter,
      primaryWriter,
      secondaryWriters,
      dueDate,
      status,
      createdBy,
      studyProgram,
    ) => {
      dispatch(
        createAssignment(
          chapter,
          primaryWriter,
          secondaryWriters,
          dueDate,
          status,
          createdBy,
          studyProgram,
          true,
        ),
      );
      dispatch(loadAllWriters());
    },
  };
};

const AssignWriterModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignWriterModal);

export default AssignWriterModalContainer;
