/**********************
 * REDUX ACTION TYPES *
 **********************/
const ratesNamespace = 'groups';
export const SET_ROLES_FOR_RATES = `${ratesNamespace}/SET_ROLES_FOR_RATES`;
export const SET_GROUPS_FOR_STUDY_PROGRAM = `${ratesNamespace}/SET_GROUPS_FOR_STUDY_PROGRAM`;
export const UPDATE_WAITING = `${ratesNamespace}/UPDATE_WAITING`;
export const REMOVE_GROUP = `${ratesNamespace}/REMOVE_GROUP`;
export const SET_ADMINS = `${ratesNamespace}/SET_ADMINS`;
export const ADD_GROUP = `${ratesNamespace}/ADD_GROUP`;
export const DELETE_RESPONSE = `${ratesNamespace}/DELETE_RESPONSE`;
export const UPDATE_DELETE_RESPONSE = `${ratesNamespace}/UPDATE_DELETE_RESPONSE`;
export const UPDATE_GROUP_TO_DELETE = `${ratesNamespace}/UPDATE_GROUP_TO_DELETE`;
export const SET_SELECTED_RATE = `${ratesNamespace}/SET_SELECTED_RATE`;
export const SET_SELECTED_QUAL = `${ratesNamespace}/SET_SELECTED_QUAL`;
export const SET_VIEW_RATES = `${ratesNamespace}/SET_VIEW_RATES`;
