import * as t from './actionTypes';
import { Map } from 'immutable';
import { pageSize } from '../../constants/pagination';

const initialState = Map({
  quals: [],
  allStudyPrograms: [],
  selectedQual: null,
  pagination: { total: 1, page: 1 },
});

function isInLastPaginationPage(pagination) {
  return pagination.total / pagination.page < pageSize;
}

export default function(state = initialState, action) {
  switch (action.type) {
    case t.SET_QUALS:
      return state.set('quals', action.quals.data).set('pagination', {
        total: action.quals.pagination.total,
        page: action.page,
      });

    case t.ADD_QUAL:
      if (isInLastPaginationPage(state.get('pagination'))) {
        return state.set('quals', state.get('quals').concat([action.qual]));
      }
      return state;

    case t.SET_ALL_SP:
      return state.set('allStudyPrograms', action.studyPrograms);

    case t.SET_SELECTED_QUAL:
      return state.set('selectedQual', action.qual);

    default:
      return state;
  }
}
