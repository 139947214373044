function UserQuestionSubmission({
  setUserSubmissionBlockStatus,
  isUserBlocked,
  userId,
  isLoadingBlockStatus,
}) {
  return (
    <>
      <div className="separatorLine" />
      <article className="question__container">
        <span className="question__title">Submit Questions</span>
        {isUserBlocked ? (
          <p>The user is blocked from submitting questions.</p>
        ) : (
          <p>The user is allowed to submit questions.</p>
        )}
        <div className="question__buttons__container">
          <button
            className="btn question__button--block"
            onClick={() => setUserSubmissionBlockStatus(userId, true)}
            disabled={isUserBlocked || isLoadingBlockStatus}
          >
            Block
          </button>
          <button
            className="btn btn-primary"
            onClick={() => setUserSubmissionBlockStatus(userId, false)}
            disabled={!isUserBlocked || isLoadingBlockStatus}
          >
            Unblock
          </button>
        </div>
      </article>
      <div className="separatorLine" />
    </>
  );
}

export default UserQuestionSubmission;
