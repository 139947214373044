import { connect } from 'react-redux';
import { getQuals, getSelectedQual, getQualPagination } from '../../selectors';
import QualsList from './QualsList';
import { getAllQuals, createQual } from '../../actionsSagas';
import addAbilityToOpenModal from '../../../../utilities/modalHOC';
import { setSelectedQual } from '../../actions';

const mapStateToProps = (state, ownProps) => {
  return {
    quals: getQuals(state),
    selectedQual: getSelectedQual(state),
    pagination: getQualPagination(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadQuals: (page, selectedQual) => {
      dispatch(getAllQuals(page, selectedQual));
    },
    onCreateQual: fields => {
      dispatch(createQual(fields));
    },
    onSelectQual: qual => {
      dispatch(setSelectedQual(qual));
    },
  };
};

const QualsListContainer = connect(mapStateToProps, mapDispatchToProps)(
  QualsList
);

export default addAbilityToOpenModal(QualsListContainer);
