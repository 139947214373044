import * as t from './actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  settings: [],
  loading: false,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case t.SET_SETTINGS:
      return state.set('settings', action.settings);

    case t.SET_LOADING:
      return state.set('loading', action.value);

    default:
      return state;
  }
}
