import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'coupons');
}

export const getCoupons = state => getState(state).get('coupons');
export const getSelectedCoupon = state => getState(state).get('selectedCoupon');
export const getIsLoadingCodeGroups = state =>
  getState(state).get('isLoadingCodeGroups');
export const getCodeGroups = state => getState(state).get('codeGroups');
export const getCodeGroup = state => getState(state).get('codeGroup');
export const getIsGettingCodeGroup = state =>
  getState(state).get('isGettingCodeGroup');
export const getSelectedCodeGroup = state =>
  getState(state).get('selectedCodeGroup');
export const getRedeemedCodes = state => getState(state).get('redeemedCodes');
export const getUnredeemedCodes = state =>
  getState(state).get('unredeemedCodes');
export const getCouponsReferral = state =>
  getState(state).get('couponsReferral');
export const setPlansSubscriptions = state =>
  getState(state).get('plansSubscriptions');
export const getSelectedCouponReferral = state =>
  getState(state).get('selectedCouponReferral');
export const getLoadingCoupons = state => getState(state).get('loadingCoupons');
export const getBadRequest = state => getState(state).get('badRequest');
export const getWaitingResponse = state =>
  getState(state).get('waitingResponse');
export const getNewCoupons = state => getState(state).get('newCoupons');
export const getCouponDetail = state => getState(state).get('couponDetail');
export const getDeleteCoupon = state => getState(state).get('deleteCoupon');
export const getLoadCoupons = state => getState(state).get('loadCoupons');
