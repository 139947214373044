import React from 'react';
import './AssociateRatesToReference.css';
import Button from 'react-bootstrap-button-loader';
import Select2 from 'react-select2-wrapper';
import Spinner from '../../Spinner/Spinner';
import _ from 'underscore';

class AssociateRatesToReference extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRates: [],
    };
  }

  componentWillReceiveProps({ rates }) {
    let reference = this.props.reference;
    if (
      this.props.rates !== rates &&
      reference.studyPrograms &&
      reference.studyPrograms.length > 0
    ) {
      this.setState({ selectedRates: _.pluck(reference.studyPrograms, 'id') });
    }
  }

  componentWillMount() {
    this.props.onLoad();
  }

  selectRate = event => {
    let idToAdd = parseInt(event.params.data.id, 10);
    if (this.state.selectedRates.indexOf(idToAdd) < 0) {
      let newSelectedRates = this.state.selectedRates;
      newSelectedRates.push(idToAdd);
      this.setState({ selectedRates: newSelectedRates });
    }
  };

  unselectRate = event => {
    let idToRemove = parseInt(event.params.data.id, 10);
    let newSelectedRates = this.state.selectedRates.filter(
      writer => writer !== idToRemove,
    );
    this.setState({ selectedRates: newSelectedRates });
  };

  validate = callback => {
    // let rates = this.state.selectedRates.map(rateId =>
    //   this.props.rates.find(rate => rate.id === Number(rateId))
    // );
    callback(
      { studyPrograms: this.state.selectedRates },
      this.props.reference.id,
    );
  };

  render() {
    const { onSubmit, onCancel, rates, waiting } = this.props;
    let possibleRates = rates.filter(rate => !rates.typeInstance);
    return (
      <div className="associate-rates-modal">
        {waiting ? (
          <Spinner text="Loading study programs" />
        ) : (
          <div className="wrapperSelector">
            <Select2
              value={this.state.selectedRates}
              className="ratesSelector"
              multiple
              data={possibleRates.map(rate => {
                if (rate.typeInstance) {
                  rate.text = (rate.typeInstance.shortname
                  ? rate.typeInstance.shortname
                  : rate.typeInstance.description)
                    ? ' ' +
                      (rate.typeInstance.shortname
                        ? rate.typeInstance.shortname
                        : rate.typeInstance.description) +
                      ' - ' +
                      rate.typeInstance.name
                    : rate.typeInstance.name;
                }
                delete rate.disabled;
                return rate;
              })}
              options={{
                placeholder: 'search by name',
              }}
              onSelect={this.selectRate}
              onUnselect={this.unselectRate}
            />
            <div className="separatorLine" />
            <div className="bottom-buttons">
              <Button
                className="btn btn-success"
                onClick={() => this.validate(onSubmit)}
                loading={this.props.isWaiting}
              >
                Save
              </Button>
              <Button className="btn btn-secondary" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AssociateRatesToReference;
