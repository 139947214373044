const referencesSagaNamespace = 'references-saga';
export const CHANGE_ACT_AS_PARENT = `${referencesSagaNamespace}/CHANGE_ACT_AS_PARENT`;
export const DELETE_REFERENCE_SAGAS = `${referencesSagaNamespace}/DELETE_REFERENCE_SAGAS`;
export const CHANGE_STATUS_SAGAS = `${referencesSagaNamespace}/CHANGE_STATUS_SAGAS`;
export const ARCHIVE_SAGAS = `${referencesSagaNamespace}/ARCHIVE_SAGAS`;
export const UPDATE_REFERENCE_SAGAS = `${referencesSagaNamespace}/UPDATE_REFERENCE_SAGAS`;
export const DELETE_PDF_SAGAS = `${referencesSagaNamespace}/DELETE_PDF_SAGAS`;
export const COPY_REFERENCE_SAGAS = `${referencesSagaNamespace}/COPY_REFERENCE_SAGAS`;
export const ASSIGNED_TO_BIB_SAGAS = `${referencesSagaNamespace}/ASSIGNED_TO_BIB_SAGAS`;
export const NOT_ASSIGNED_TO_BIB_SAGAS = `${referencesSagaNamespace}/NOT_ASSIGNED_TO_BIB_SAGAS`;
export const ARCHIVED_REFERENCES_SAGAS = `${referencesSagaNamespace}/ARCHIVED_REFERENCES_SAGAS`;
export const ALL_FOR_RATE_SAGAS = `${referencesSagaNamespace}/ALL_FOR_RATE_SAGAS`;
export const ALL_ASSIGNED_SAGAS = `${referencesSagaNamespace}/ALL_ASSIGNED_SAGAS`;
export const DELETE_CHAPTER_SAGAS = `${referencesSagaNamespace}/DELETE_CHAPTER_SAGAS`;
export const DELETE_ALL_QUESTIONS_SAGAS = `${referencesSagaNamespace}/DELETE_ALL_QUESTIONS_SAGAS`;
export const CHAPTER_STATUS_SAGAS = `${referencesSagaNamespace}/CHAPTER_STATUS_SAGAS`;
export const DELETE_PDF_CHAPTER_SAGAS = `${referencesSagaNamespace}/DELETE_PDF_CHAPTER_SAGAS`;
export const SET_MANUAL_RED_SUBTOPICS_SAGAS = `${referencesSagaNamespace}/SET_MANUAL_RED_SUBTOPICS_SAGAS`;
export const SET_MANUAL_RED_QUESTIONS_SAGAS = `${referencesSagaNamespace}/SET_MANUAL_RED_QUESTIONS_SAGAS`;
export const REMOVE_MANUAL_RED_QUESTIONS_SAGAS = `${referencesSagaNamespace}/REMOVE_MANUAL_RED_QUESTIONS_SAGAS`;
export const REMOVE_MANUAL_RED_SUBTOPICS_SAGAS = `${referencesSagaNamespace}/REMOVE_MANUAL_RED_SUBTOPICS_SAGAS`;
export const UPDATE_CHAPTER_SAGAS = `${referencesSagaNamespace}/UPDATE_CHAPTER_SAGAS`;
export const MOVE_CHAPTERS_SAGAS = `${referencesSagaNamespace}/MOVE_CHAPTERS_SAGAS`;
