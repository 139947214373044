import './TeamsRatesList.css';

import React from 'react';

import NumberUtils from '../../lib/NumberUtils';
import {
  hasPagePermission,
  hasSomePermissionForPage,
} from '../../utilities/FilterLinksForTabs';
import Spinner from '../Spinner/Spinner';
import TeamRatesRow from './TeamRatesRow';

class TeamRatesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = { search: '' };
  }

  reloadUsers = (search = this.state.search, page = this.state.page) => {
    this.setState({ search: search || '', page: page || 1 });
    this.props.reloadUsers(search, page);
  };

  changePage = (page) => {
    this.setState({ page });
    this.reloadUsers(null, page);
  };

  renderRole = (rate) => (
    <TeamRatesRow
      key={NumberUtils.obtainUniqueKey()}
      rate={rate}
      selected={
        (this.props.selectedRate && this.props.selectedRate.id) ||
        (this.props.selectedRate && this.props.selectedRate.id === 0)
          ? this.props.selectedRate.id === rate.id
          : false
      }
      onRateClick={
        this.props.viewQuals ? this.props.onQualsClick : this.props.onRateClick
      }
      viewQuals={this.props.viewQuals}
      booksPage={this.props.booksPage}
      subtopicsPage={this.props.subtopicsPage}
      loadingBooks={this.props.loadingBooks}
    />
  );

  componentWillUnmount() {
    sessionStorage.setItem('rateSearch', this.state.search);
  }

  componentDidMount() {
    if (
      this.state.search !== sessionStorage.getItem('rateSearch') &&
      this.state.search !== undefined &&
      this.state.search !== null &&
      sessionStorage.getItem('rateSearch') &&
      this.props.booksPage
    ) {
      this.setState({ search: sessionStorage.getItem('rateSearch') });
    }
  }

  renderRoles = (rates) => {
    if (rates) {
      return rates.map((rate) => this.renderRole(rate));
    }
  };

  renderLoading = () => <Spinner />;

  renderTable = (loading, rates) => {
    if (loading) {
      return this.renderLoading();
    }
    return <div>{this.renderRoles(rates)}</div>;
  };

  renderBookPageHeader = (onPlusClick, onSearch, searchValue) => (
    <div className="booksPageHeaderContainer">
      <div className="flexItemsTitlePlus">
        <div className="booksPageTitle">Rates</div>
        {onPlusClick && (
          <i className="fa fa-plus fa-lg mt-1 addRate" onClick={onPlusClick} />
        )}
        {onSearch && (
          <div
            className="searchInput"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <input
              id="input"
              className="form-control"
              value={searchValue}
              onChange={onSearch}
            />
            {searchValue ? (
              <i
                onClick={() => this.clearSearchInput()}
                className="fa fa-close fa-lg"
              />
            ) : (
              <i className="fa fa-search fa-lg" />
            )}
          </div>
        )}
      </div>
      <div className="lineBooksPage" />
    </div>
  );

  clearSearchInput = () => {
    this.setState({ search: '' });
  };

  onSearch = (event) => {
    this.setState({ search: event.target.value });
  };

  getSearchRates = () => {
    let { rates } = this.props;
    const { subtopicsPage } = this.props;
    if (subtopicsPage) {
      rates.shift();
    }
    if (this.state.search !== '') {
      const search = this.state.search.toLowerCase();
      rates = rates.filter((r) => {
        if (r.id !== 0) {
          return (
            r.name.toLowerCase().includes(search) ||
            r.shortname.toLowerCase().includes(search)
          );
        }
        return null;
      });
    }
    rates = rates.sort((a, b) => a.shortname - b.shortname);
    return rates;
  };

  render() {
    const {
      loading,
      rates,
      toggleList,
      booksPage,
      user,
      subtopicsPage,
      viewQuals,
      generalRolesPermissions = [],
      rolesPermissions = [],
    } = this.props;
    const searchAction = this.onSearch;
    let editableRates = rates.slice();
    if (subtopicsPage) {
      if (
        user &&
        !user.isSuperAdmin &&
        !hasPagePermission('Subtopics', generalRolesPermissions)
      ) {
        const roles = rolesPermissions.filter((role) =>
          role.actions.find((action) => action === 'Subtopics'),
        );
        editableRates = editableRates.filter(
          (rate) =>
            rate.id === 0 ||
            roles.find((role) => parseInt(role.typeId, 10) === rate.id),
        );
      }
      if (
        !booksPage ||
        (!generalRolesPermissions.find(
          (permission) => permission === 'Books.Books',
        ) &&
          !user.isSuperAdmin)
      ) {
        editableRates.shift();
      }
    }
    let newEditableRates = this.getSearchRates(editableRates);
    if (
      !booksPage &&
      !subtopicsPage &&
      user &&
      !user.isSuperAdmin &&
      !hasSomePermissionForPage('Teams', generalRolesPermissions)
    ) {
      const possibleRates = rolesPermissions
        .filter(
          (rate) =>
            rate.type === 'rate' &&
            rate.actions.find((a) => a.includes('Teams')),
        )
        .map((r) => Number(r.typeId));
      newEditableRates = rates.filter((r) => possibleRates.includes(r.id));
    }
    return (
      <div>
        <div className="rolesListItems">
          {!booksPage && !subtopicsPage && (
            <div className="titleContainer">
              <div
                className={booksPage ? 'title booksPageTitle' : 'ratesTitle'}
                onClick={() => toggleList()}
              >
                Rates
              </div>
              <div className="qualsTitle" onClick={() => toggleList()}>
                Quals
              </div>
            </div>
          )}
          {!booksPage && !subtopicsPage && (
            <div className={viewQuals ? 'line lineQuals' : 'line'} />
          )}
          {(booksPage || subtopicsPage) &&
            this.renderBookPageHeader(
              this.onPlusClick,
              searchAction,
              this.state.search,
              this.props.clearSearchInput,
            )}
        </div>
        <div className="animated fadeIn rolesTable">
          {newEditableRates.length > 0 ? (
            this.renderTable(loading, newEditableRates)
          ) : (
            <div>No Rates</div>
          )}
        </div>
      </div>
    );
  }
}

export default TeamRatesList;
