import { connect } from 'react-redux';
import { updateSubtopicChapters } from '../actionsSagas';
import TopicItem from '../../../commonComponents/TopicsList/TopicItem';

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = dispatch => ({
  updateSubtopicChapters: (subtopic, checkedChapters) =>
    dispatch(updateSubtopicChapters(subtopic.id, checkedChapters)),
});

const TopicItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TopicItem);

export default TopicItemContainer;
