import './Loading.css';

import React, { Component } from 'react';

class Loading extends Component {
  render() {
    return (
      <div className="loadingContainer">
        <div className="spinnerPosition">
          <i className="fa fa-circle-o-notch fa-spin" />
          <br />
          {this.props.text || null}
        </div>
      </div>
    );
  }
}

export default Loading;
