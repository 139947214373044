const bibliographiesSagaNamespace = 'bibliographies';
export const LOAD_REFERENCES = `${bibliographiesSagaNamespace}/LOAD_REFERENCES`;
export const CREATE_REFERENCE_SAGAS = `${bibliographiesSagaNamespace}/CREATE_REFERENCE_SAGAS`;
export const CREATE_CHAPTER_SAGAS = `${bibliographiesSagaNamespace}/CREATE_CHAPTER_SAGAS`;
export const SUBMIT_BIB_SAGAS = `${bibliographiesSagaNamespace}/SUBMIT_BIB_SAGAS`;
export const LOAD_EXAM = `${bibliographiesSagaNamespace}/LOAD_EXAM`;
export const LOAD_TYPES = `${bibliographiesSagaNamespace}/LOAD_TYPES`;
export const LOAD_RATING_EXAM_QUESTIONS = `${bibliographiesSagaNamespace}/LOAD_RATING_EXAM_QUESTIONS`;
export const LOAD_RATE_QUESTION_PERCENTAGE = `${bibliographiesSagaNamespace}/LOAD_RATE_QUESTION_PERCENTAGE`;
export const LOAD_RATE_BIBLIOPGRAPHY_JSON = `${bibliographiesSagaNamespace}/LOAD_RATE_BIBLIOPGRAPHY_JSON`;
export const LOAD_RATE_CHAPTER_QUESTION_REPORT = `${bibliographiesSagaNamespace}/LOAD_RATE_CHAPTER_QUESTION_REPORT`;
export const LOAD_REPORT = `${bibliographiesSagaNamespace}/LOAD_REPORT`;
export const GET_REFERENCE_BY_ID = `${bibliographiesSagaNamespace}/GET_REFERENCE_BY_ID`;
export const GET_PDF_BY_REFERENCE = `${bibliographiesSagaNamespace}/GET_PDF_BY_REFERENCE`;
export const DUPLICATE_CHAPTER = `${bibliographiesSagaNamespace}/DUPLICATE_CHAPTER`;
