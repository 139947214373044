export const QUESTION_TYPES = {
  FLASHCARD: 'Flashcard',
  MC: 'MC',
};

export const MODAL_TYPES = {
  DELETE: 'DELETE',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  EDIT: 'EDIT',
  DELETE_FAVORITE: 'DELETE_FAVORITE',
  DELETE_ALL_FAVORITE: 'DELETE_ALL_FAVORITE',
  DELETE_SELECTED_FAVORITE: 'DELETE_SELECTED_FAVORITE',
  RECOVER: 'RECOVER',
};
