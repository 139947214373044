function RateItem({ rate, checked, onChange }) {
  return (
    <div className="submitted__questions__rate__item">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <p>
        {rate.shortname ? `(${rate.shortname}) ` : ''}
        {rate.name === 'All' ? (
          <span className="submitted__questions__rate__item__all">
            Select all
          </span>
        ) : (
          rate.name
        )}
      </p>
    </div>
  );
}

export default RateItem;
