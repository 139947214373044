import React from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
//import Pagination from "../../../../commonComponents/Pagination/Pagination";
import { pageSize } from '../../../../constants/pagination';
import DropDown from '../../../../commonComponents/DropDown/DropDown';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import CodesPagination from '../CodesPagination/CodesPagination';

export default class CodeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getRedeemedBy(code) {
    if (
      !code ||
      !code.redeemedBy ||
      (!code.redeemedBy.name && !code.redeemedBy.lastName)
    ) {
      return '';
    }
    return code.redeemedBy.name + ' ' + code.redeemedBy.lastName;
  }

  getEmail(code) {
    if (!code || !code.redeemedBy) {
      return '';
    }
    return code.redeemedBy.email;
  }

  getPaginatedCodes = () => {
    let final = pageSize;
    let initial = 0;
    return this.props.codes.slice(initial, final);
  };

  render() {
    let { isGettingCodeGroup, onCancelCode, page, codeGroupId } = this.props;
    let paginatedCodes = this.getPaginatedCodes();
    return (
      <div style={{ width: '100%' }}>
        <Row className="CodeListRow CodeHeader">
          <Col md={3}> Code </Col>
          <Col md={3}> Redeem By </Col>
          <Col md={3}> Email </Col>
          <Col md={2}> Date Redeemed </Col>
          <Col md={1} />
        </Row>
        {!isGettingCodeGroup ? (
          paginatedCodes.map(code => (
            <Row className="CodeListRow" key={code.id}>
              <Col md={3} className={code.isCancelled ? 'line-through' : ''}>
                {' '}
                {code.code}{' '}
              </Col>
              <Col md={3}> {this.getRedeemedBy(code)} </Col>
              <Col md={3}> {this.getEmail(code)} </Col>
              <Col md={2}>
                {' '}
                {code.redeemedAt ? (
                  moment(Number(code.redeemedAt)).format('L')
                ) : (
                  ''
                )}{' '}
              </Col>
              <Col md={1}>
                <DropDown
                  options={[
                    {
                      name: 'Cancel',
                      event: () =>
                        onCancelCode(
                          code.code,
                          codeGroupId,
                          (page - 1) * pageSize,
                          page * pageSize
                        ),
                    },
                  ]}
                  title="Code"
                  style={{
                    position: 'relative',
                    top: '60px',
                  }}
                />
              </Col>
            </Row>
          ))
        ) : (
          <Spinner text="Loading codes" />
        )}
        <Row className="paginationContainer">
          <CodesPagination
            currentPage={this.props.page}
            totalRecords={this.props.pagination.total}
            onChangePage={this.props.onChangePage}
            redeemedPage={this.props.redeemedPage}
          />
        </Row>
      </div>
    );
  }
}
