import './Footer.css';

import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap-button-loader';

import ConfirmationReason from '../../../../commonComponents/Modals/ConfirmationReason/ConfirmationReason';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import { getStatusByName } from '../../../../constants/assignments';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waiting: false,
    };
  }

  onPublish = () => {
    const editedAssignment = {
      id: this.props.assignment.id,
      status: getStatusByName('Published'),
      writerEmail: this.props.assignment.writerEmail
        ? this.props.assignment.writerEmail
        : null,
    };
    this.props.onEditAssignment(editedAssignment, true, false);
  };

  getActiveClass(selectedView, currentView) {
    return selectedView === currentView ? ' FooterActive' : '';
  }

  hasPermission = (permission) => {
    const { loggedUser, permissions, selectedSP } = this.props;
    if (loggedUser && loggedUser.isSuperAdmin) {
      return true;
    }
    if (selectedSP) {
      const type = selectedSP.shortname ? 'rate' : 'qual';
      const userPermissionsInSelectedRate = permissions.find(
        (p) => p.typeId === `${selectedSP.id}` && p.type === type,
      );
      if (userPermissionsInSelectedRate) {
        const role = `Assignment.${permission}`;
        return userPermissionsInSelectedRate.actions.find(
          (action) => action === role,
        );
      }
    }
    return false;
  };

  onRejectAssignment = () => {
    this.props.openModal({
      modalContent: (
        <ConfirmationReason
          onSubmit={(fields) => {
            const updatedAssignment = {
              id: this.props.assignment.id,
              status: 'In_Progress',
              reason: fields.reason,
              writerEmail: this.props.assignment.writerEmail
                ? this.props.assignment.writerEmail
                : null,
            };
            this.props.onEditAssignment(updatedAssignment, true, true);
          }}
          onCancel={this.props.closeModal}
          bodyText="You are about to reject an assignment. "
          submitBtnText="Reject"
        />
      ),
      modalClassName:
        'modal-create-chapter grey-modal-body manual-red-component',
    });
  };

  getActionButtons = () => {
    const { assignment } = this.props;
    const buttons = [];
    if (assignment && assignment.status === 'Submitted') {
      if (this.hasPermission('Approve')) {
        buttons.push(
          <Button
            key={buttons.length}
            className="btn btn-danger"
            onClick={this.onRejectAssignment}
          >
            Reject
          </Button>,
        );
      }
      if (this.hasPermission('Publish')) {
        buttons.push(
          <Button
            key={buttons.length}
            className="btn btn-publish"
            onClick={this.onPublish}
          >
            Publish
          </Button>,
        );
      }
    }
    return buttons;
  };

  render() {
    const {
      onViewChange,
      selectedView,
      isInHistoryView,
      onApproveAssignment,
      onCancelAssignment,
      assignment,
      chapter,
      editPermission,
    } = this.props;
    const isDisabled =
      (!assignment &&
        chapter &&
        chapter.assignments &&
        chapter.assignments.length) ||
      !editPermission;
    const hidden =
      selectedView !== 'gridView' && !isDisabled ? '' : 'not-visible';

    return (
      <div className="FooterRow">
        <div className="ViewContainer">
          <span className="viewText">Views</span>
          <div
            onClick={() => {
              onViewChange('listView');
              this.props.setCurrentPage(0);
            }}
            className={`FooterNav${this.getActiveClass(
              selectedView,
              'listView',
            )}`}
          >
            <i className="fa fa-list fa-lg mt-1" />
          </div>
          <div
            onClick={() => {
              onViewChange('pdfView');
            }}
            className={`FooterNav${this.getActiveClass(
              selectedView,
              'pdfView',
            )}`}
          >
            <i className="fa fa-file-text-o fa-lg mt-1" />
          </div>
          <div
            onClick={() => {
              onViewChange('gridView');
              this.props.setCurrentPage(0);
            }}
            className={`FooterNav${this.getActiveClass(
              selectedView,
              'gridView',
            )}`}
          >
            <i className="fa fa-th fa-lg mt-1" />
          </div>
        </div>
        {!this.props.isWaiting && (
          <div className={`buttonContainer ${hidden}`}>
            <Button
              className="btn btn-save btn-save-default"
              data-test="save-question-button"
              disabled={this.props.isWaiting}
              onClick={this.props.onSave}
            >
              Save
            </Button>
            {this.props.selectedQuestion === null && (
              <Button
                className="btn btn-save btn-save-create"
                disabled={this.props.isWaiting}
                onClick={this.props.onSaveAndCreateAnother}
              >
                Save and Create Another
              </Button>
            )}
            {this.getActionButtons()}
          </div>
        )}
        {this.props.isWaiting && (
          <div className={`buttonContainer ${hidden}`}>
            <Spinner className="waiting-spinner" />
          </div>
        )}
        {selectedView === 'gridView' &&
          isInHistoryView &&
          assignment &&
          assignment.status === 'Submitted' && (
            <div className="reviewButtonsWrapper">
              <div className="cancelCol">
                <Button
                  className="btn btn-secondary cancelButtonPos"
                  onClick={() => onCancelAssignment()}
                >
                  Reject
                </Button>
              </div>
              <div className="approveCol">
                <Button
                  className="btn btn-success approveButtonPos"
                  onClick={() => onApproveAssignment()}
                  loading={this.state.waiting}
                >
                  Publish
                </Button>
              </div>
            </div>
          )}
      </div>
    );
  }
}

Footer.propTypes = {
  onSave: PropTypes.func,
  onReject: PropTypes.func,
  onPublish: PropTypes.func,
  onViewChange: PropTypes.func,
  selectedView: PropTypes.string,
};
