//import Bibliographies from './Bibliographies';
import NewBibliographies from './NewBibliographies';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  getCheckedSubtopics,
  getCheckedReferences,
  getReferences,
  getReferencesImmutable,
  getDutyState,
  getRank,
  loadingExam,
  getCycle,
  getRanks,
  getDutyStates,
  getActiveTab,
  getActiveListTab,
  loadingGrid,
  getOriginalCheckedSubtopics,
  getOriginalCheckedReferences,
  getRatingExamQuestions,
  getBibliographyJson,
  getRateChapterQuestionsReport,
  getIsUndoChecked,
  getIsRevertChecked,
  getUndoState,
  getActualState,
} from '../selectors';
import { getLinks } from '../../Rates/selectors';
import {
  updateCheckedSubtopics,
  updateCheckedReferences,
  updateDutyState,
  updateRank,
  updateCycleAction,
  updateRanks,
  updateDutyStates,
  examReceivedAction,
  updateSelectedTab,
  updateSelectedListTab,
  setRatingExamQuestions,
  setRateBibliographyJson,
  setRateChapterQuestionsReport,
  setIsUndoChecked,
  setIsRevertChecked,
  setUndoState,
  setActualState,
} from '../actions';
import {
  loadReferences,
  createReferenceActionSagas,
  createChapterActionSagas,
  submitBibliographyActionSagas,
  loadExam,
  getRateRatingExamQuestions,
  loadRateBibliographyJson,
  loadRateChapterQuestionsReport,
} from '../actionsSagas';
import {
  getRates,
  getTopics,
  getTopicsInmutable,
  getWaiting,
  getPagination,
  getRate,
} from '../../Rates/selectors';
import {
  getRankReferences,
  getDutyStateReferences,
} from '../../References/selectors';
import { updateRate } from '../../Rates/actions';
import { getCycles, getSelectedCycle } from '../../Cycles/selectors';
import { loadRates, loadTopics, createRate } from '../../Rates/actionsSagas';
import { loadCycles, loadArchivedCycles } from '../../Cycles/actionsSagas';
import { updateSelectedCycleAction } from '../../Cycles/actions';
import addAbilityToOpenModal from '../../../utilities/modalHOC';
import {
  getGeneralRolesPermissions,
  getRolesPermissions,
  getUser,
} from '../../Login/selectors';

const mapStateToProps = (state) => ({
  rates: getRates(state),
  topics: getTopics(state),
  topicsImmutable: getTopicsInmutable(state),
  references: getReferences(state),
  referencesImmutable: getReferencesImmutable(state),
  checkedSubtopics: getCheckedSubtopics(state),
  checkedReferences: getCheckedReferences(state),
  dutyState: getDutyState(state),
  rank: getRank(state),
  cycles: getCycles(state),
  loadingExam: loadingExam(state),
  loadingGrid: loadingGrid(state),
  cycle: getCycle(state),
  isUndoChecked: getIsUndoChecked(state),
  isRevertChecked: getIsRevertChecked(state),
  waiting: getWaiting(state),
  ratesPagination: getPagination(state),
  rate: getRate(state),
  generalRolesPermissions: getGeneralRolesPermissions(state),
  rolesPermissions: getRolesPermissions(state),
  links: getLinks(state),
  user: getUser(state),
  ranks: getRanks(state),
  dutyStates: getDutyStates(state),
  rankReferences: getRankReferences(state),
  dutyStateReferences: getDutyStateReferences(state),
  activeTab: getActiveTab(state),
  activeListTab: getActiveListTab(state),
  selectedCycle: getSelectedCycle(state),
  originalCheckedSubtopics: getOriginalCheckedSubtopics(state),
  originalCheckedReferences: getOriginalCheckedReferences(state),
  ratingExamQuestions: getRatingExamQuestions(state),
  bibliographyJson: getBibliographyJson(state),
  rateChapterQuestionsReport: getRateChapterQuestionsReport(state),
  getUndoState: getUndoState(state),
  getActualState: getActualState(state),
});

const mapDispatchToProps = (dispatch) => ({
  setUndoState: (undoState) => dispatch(setUndoState(undoState)),
  setActualState: (actualState) => dispatch(setActualState(actualState)),
  setIsUndoChecked: (undoChecked) => dispatch(setIsUndoChecked(undoChecked)),
  setIsRevertChecked: (revertChecked) =>
    dispatch(setIsRevertChecked(revertChecked)),
  loadRates: () => dispatch(loadRates(null, true)),
  loadTopics: (id) => dispatch(loadTopics(id, 'Bibliographies')),
  loadReferences: (id, type) => dispatch(loadReferences(id, type)),
  updateCheckedSubtopics: (subtopics) =>
    dispatch(updateCheckedSubtopics(subtopics)),
  updateCheckedReferences: (references) =>
    dispatch(updateCheckedReferences(references)),
  updateCycle: (cycle) => dispatch(updateCycleAction(cycle)),
  createReference: (fields, rate) => {
    dispatch(createReferenceActionSagas(fields, rate, 'rate'));
  },
  createChapter: (fields) => {
    dispatch(createChapterActionSagas(fields));
  },
  updateDutyState: (dutyState) => dispatch(updateDutyState(dutyState)),
  updateRank: (rating) => dispatch(updateRank(rating)),
  submitBibliography: (bibliography) =>
    dispatch(submitBibliographyActionSagas(bibliography)),
  loadCycles: () => dispatch(loadCycles()),
  loadExam: (dutyState, rank, rate, cycle) =>
    dispatch(loadExam(dutyState, rank, rate, cycle)),
  createRate: (fields) => dispatch(createRate(fields)),
  updateRate: (rate) => dispatch(updateRate(rate)),
  updateRanks: (ranks) => dispatch(updateRanks(ranks)),
  updateDutyStates: (dutyStates) => dispatch(updateDutyStates(dutyStates)),
  loadExamNoCycle: () =>
    dispatch(examReceivedAction({ subtopics: [], chapters: [] })),
  onUpdateSelectedTab: (tab) => dispatch(updateSelectedTab(tab)),
  onUpdateSelectedListTab: (tab) => dispatch(updateSelectedListTab(tab)),
  updateSelectedCycle: (cycle) => {
    dispatch(reset('CycleForm'));
    dispatch(updateSelectedCycleAction(cycle));
  },
  loadArchivedCycles: () => {
    dispatch(loadArchivedCycles());
  },
  getRateRatingExamQuestions: (rate, rank, dutyState, cycle) => {
    dispatch(getRateRatingExamQuestions(rate, false, cycle));
  },
  setRatingExamQuestions: (ratingExamQuestion) => {
    dispatch(setRatingExamQuestions(ratingExamQuestion));
  },
  loadRateBibliographyJson: (rateId) => {
    dispatch(loadRateBibliographyJson(rateId));
  },
  loadRateChapterQuestionsReport: (rateId) => {
    dispatch(loadRateChapterQuestionsReport(rateId));
  },
  clearBibJson: () => {
    dispatch(setRateChapterQuestionsReport());
    dispatch(setRateBibliographyJson());
  },
});

export default addAbilityToOpenModal(
  connect(mapStateToProps, mapDispatchToProps)(NewBibliographies),
);
