//import * as t from "./actionTypes";
import { Map } from 'immutable';

const initialState = Map({});

export default function(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
