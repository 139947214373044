import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Button from 'react-bootstrap-button-loader';
import { Textarea } from '../../Input/Input';
import './ConfirmationReason.css';
import { Col, Row } from 'react-bootstrap';

class ConfirmationReason extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      waiting: false,
    };
  }

  componentWillReceiveProps({ submitSucceeded }) {
    if (submitSucceeded && this.state.waiting) {
      this.setState({ waiting: false });
    }
  }

  validate = (fields, reasonField, callback) => {
    if (!fields.reason) {
      fields.reason = '';
    }
    this.setState({ error: null, waiting: true });
    callback(fields);
  };

  render() {
    const {
      handleSubmit,
      onSubmit,
      onCancel,
      reasonField,
      bodyText,
      submitBtnText,
    } = this.props;

    return (
      <div className="manual-red-component">
        <div className="card-block">
          <div className="createChapterTitle">
            Add a reason
            <i
              className="fa fa-times fa-xs"
              onClick={() => this.props.onCancel()}
            />
            <hr style={{ width: '610px', marginLeft: '-15px' }} />
          </div>
          <div className="text">
            {bodyText ? bodyText : ''}
            If you are sure, please type the reason to confirm.
          </div>
          <form
            className="widget-form manualRedConfirmationForm"
            onSubmit={handleSubmit(fields =>
              this.validate(fields, reasonField, onSubmit)
            )}
          >
            <Row>
              <Col md={3}>
                <span className="label">Reason</span>
              </Col>
              <Col md={9}>
                <Field
                  name="reason"
                  component={Textarea}
                  placeholder="reason"
                />
                {this.state.error ? (
                  <div className="errors">{this.state.error}</div>
                ) : null}
              </Col>
            </Row>
            <hr style={{ width: '610px', marginLeft: '-15px' }} />
            <div className="bottom-buttons-new">
              <Button
                className="btn btn-success"
                type="submit"
                loading={this.state.waiting}
              >
                {submitBtnText ? submitBtnText : 'Submit'}
              </Button>
              <Button className="btn btn-secondary" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ConfirmationReason.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  requesting: PropTypes.bool,
};

export default reduxForm({ form: 'ConfirmationReason' })(ConfirmationReason);
