import { connect } from 'react-redux';
import {
  getWriters,
  getSelectedAdmin,
  getSelectedWriter,
  getIsQuestionOverview,
  getSelectedAssignment,
  getSummary,
  getLoadingCreateQuestions,
} from '../../selectors';
import {
  loadAllWriters,
  onEditAssignment,
  onDeleteAssignment,
  submitQuestionsGPT,
} from '../../actionsSagas';
import {
  onShowQuestionDetails,
  toggleQuestionOverview,
  onShowQuestionOverview,
  setSelectedWriter,
  setLoadingCreateGPTquestion,
} from '../../actions';
import AssignmentsByWriter from './AssignmentsByWriter';
import {
  getUser,
  getGeneralRolesPermissions,
  getRolesPermissions,
} from '../../../Login/selectors';
import addAbilityToOpenModal from '../../../../utilities/modalHOC';

const mapStateToProps = (state, ownProps) => {
  return {
    writers: getWriters(state),
    user: getUser(state),
    selectedAdmin: getSelectedAdmin(state),
    selectedWriter: getSelectedWriter(state),
    isQuestionOverview: getIsQuestionOverview(state),
    selectedAssignment: getSelectedAssignment(state),
    generalRolesPermissions: getGeneralRolesPermissions(state),
    rolesPermissions: getRolesPermissions(state),
    summary: getSummary(state),
    loadingCreateGPTquestions: getLoadingCreateQuestions(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAllWriters: () => dispatch(loadAllWriters()),
    onEditAssignment: (assignment, isEditStatus, rejected) => {
      dispatch(onEditAssignment(assignment, isEditStatus, rejected));
    },
    onQuestionDetails: (assignment, rate) => {
      dispatch(onShowQuestionDetails(assignment, rate));
      dispatch(toggleQuestionOverview(true));
    },
    toggleQuestionOverview: (value) => {
      dispatch(toggleQuestionOverview(value));
    },
    onQuestionOverview: (assignment) => {
      dispatch(onShowQuestionOverview(assignment));
      dispatch(toggleQuestionOverview(true));
    },
    onDelete: (assignment, writerId) => {
      dispatch(onDeleteAssignment(assignment, writerId));
    },
    selectWriter: (writer) => {
      dispatch(setSelectedWriter(writer));
    },
    onSubmitQuestions: (fields, assignment) => {
      dispatch(submitQuestionsGPT(fields, assignment));
    },
    setLoadingCreateGPTquestion: (value) => {
      dispatch(setLoadingCreateGPTquestion(value));
    },
  };
};

const AssignmentsByWriterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignmentsByWriter);

export default addAbilityToOpenModal(AssignmentsByWriterContainer);
