import './PromptListStyle.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';

import SubHeader from '../../../commonComponents/SubHeader/SubHeader';

const listOptions = ['Question', 'Summary', 'Suggestion'];
class PromptList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInput: '',
      selectedId: 0,
    };
  }

  renderSearch(search) {
    return (
      <div>
        <div className="searchInput">
          <input
            id="input"
            className="form-control"
            value={search}
            onChange={this.onSearch}
          />
          {search !== '' ? (
            <i
              onClick={() => this.setState({ searchInput: '' })}
              className="fa fa-close fa-lg fa-close"
            />
          ) : (
            <i className="fa fa-search fa-lg fa-in-search" />
          )}
        </div>
      </div>
    );
  }

  onSearch = (e) => {
    this.setState({ searchInput: e.target.value });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.prompts?.length < this.props.prompts?.length) {
      // select the latest prompt (new one)
      const latestPrompt = this.props.prompts.reduce((prev, current) =>
        prev.createdAt > current.createdAt ? prev : current,
      );
      this.handlePromptSelect(latestPrompt.id);
    } else {
      if (prevProps.prompts?.length > this.props.prompts?.length) {
        // select the first prompt in list
        if (this.props.prompts.length > 0) {
          this.handlePromptSelect(this.props.prompts[0].id);
        } else {
          this.handlePromptSelect(-1);
        }
      } else {
        // select the first prompt in list
        if (this.state.selectedId === 0 && this.props.prompts.length > 0) {
          this.handlePromptSelect(this.props.prompts[0].id);
        }
      }
    }
  }

  handlePromptSelect(promptId) {
    this.props.setPromptDetail(promptId);

    this.props.loadPromptDetails(true);
    this.props.setCreateMode(false);
    this.setState({
      selectedId: promptId,
    });
  }

  handleAddPrompt() {
    this.props.loadPromptDetails(false);
    this.props.setCreateMode(true);
    this.setState({ selectedId: -1 });
  }

  renderListPrompt() {
    const { prompts } = this.props;
    const promptFilter =
      this.props.activeTab !== ''
        ? prompts.filter((b) =>
            b.type.toLowerCase().includes(this.props.activeTab.toLowerCase()),
          )
        : prompts;
    return promptFilter.map((prompt) => (
      <div key={prompt.id}>
        <div
          className={
            prompt.id !== this.state.selectedId
              ? 'promptName'
              : 'promptName selected'
          }
          onClick={() => this.handlePromptSelect(prompt.id)}
        >
          {prompt.name}
          {prompt.active && (
            <div className="btn-success activeLabel">
              <b>Active prompt</b>
            </div>
          )}
        </div>
      </div>
    ));
  }

  render() {
    return (
      <div className="containerList">
        <div>
          <Row className="pl-0">
            <Col md={8} className="ml-0 pl-0">
              <SubHeader
                options={listOptions}
                onOptionChange={this.props.setActiveTab}
                activeOption={this.props.activeTab}
              />
            </Col>
            <Col md={4} className="plusContainer mt-0">
              <i
                className="fa fa-plus fa-lg"
                onClick={() => {
                  this.handleAddPrompt();
                }}
              />
            </Col>
          </Row>
        </div>
        {this.props.prompts.length > 0 ? (
          <div className="animated fadeIn">{this.renderListPrompt()}</div>
        ) : (
          <p className="noPrompts">No prompt has been created</p>
        )}
      </div>
    );
  }
}

export default PromptList;
