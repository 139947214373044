import './UpdateChapterSubtopics.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import _ from 'underscore';

import CheckBoxList from '../../CheckBoxList/CheckBoxList';
import TextLink from '../../TextLink/TextLink';

export default class UpdateChapterSubtopics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subtopics: this.props.subtopics,
      allChecked: false,
      showContent: false,
    };
  }

  componentWillMount() {
    this.props.loadSubtopicsByRate(this.props.rate);
  }

  componentWillReceiveProps() {
    if (subtopics.length > 0) {
      const checkableSubtopics = this.getCheckableSubtopics(subtopics);
      this.setState({
        subtopics: _.sortBy(checkableSubtopics, 'name'),
        showContent: true,
      });
    }
  }

  getCheckableSubtopics = (subtopics) =>
    subtopics.map((subtopic) => ({
      id: subtopic.id,
      name: `${subtopic.topic.name} >> ${subtopic.name}`,
      checked: _.contains(_.pluck(subtopic.chapters, 'id'), this.props.chapter),
    }));

  allCheckedChange = () => {
    const check = !this.state.allChecked;
    const subtopics = this.state.subtopics;

    _.map(subtopics, (subtopic) => {
      subtopic.checked = check;
    });
    this.setState({ subtopics, allChecked: check });
  };

  onCheckChange = (id) => {
    const subtopics = this.state.subtopics;

    _.map(subtopics, (subtopic) => {
      if (subtopic.id === parseInt(id, 10)) {
        subtopic.checked = !subtopic.checked;
      }
    });
    this.setState({ subtopics });
  };

  toggleOrder = () => {
    this.setState({ subtopics: this.state.subtopics.reverse() });
  };

  onUpdate = () => {
    const checkedSubtopics = _.pluck(
      _.filter(this.state.subtopics, { checked: true }),
      'id',
    );
    this.props.updateChapterSubtopics(
      this.props.reference,
      this.props.chapter,
      checkedSubtopics,
    );
    this.props.onCancel();
  };

  render() {
    return (
      <Row className="UpdateChapterSubtopicsListsContainer">
        <Col xs={12} md={12} className="UpdateChapterSubtopics">
          <TextLink text="Subtopics" onAction={this.toggleOrder} />
          <div className="select-all-option">
            <input
              ref="checkbox"
              type="checkbox"
              value="allChecked"
              name="allChecked"
              checked={this.state.allChecked}
              onChange={this.allCheckedChange}
            />
            <label className="checkbox-inline">Select/Deselect all</label>
          </div>
          <div className="subtopics-list">
            {this.state.showContent ? (
              <CheckBoxList
                items={this.state.subtopics}
                onChange={this.onCheckChange}
              />
            ) : (
              ''
            )}
          </div>
          <div className="bottom-buttons">
            <Button className="btn btn-secondary" onClick={this.props.onCancel}>
              Cancel
            </Button>
            <Button className="btn btn-success" onClick={this.onUpdate}>
              Update Chapter
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}

UpdateChapterSubtopics.propTypes = {
  subtopics: PropTypes.array,
  chapter: PropTypes.number,
  rate: PropTypes.number,
  reference: PropTypes.number,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  loadSubtopicsByRate: PropTypes.func,
};
