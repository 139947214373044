import React from 'react';

// reactstrap components
import { Row, Col } from 'reactstrap';
import ForgotPasswordForm from './forgotPasswordForm.jsx';
import ForgotPasswordMessage from './forgotPasswordMessage.jsx';
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewForm: true,
      responseMessage: '',
    };
    this.requestPassword = this.requestPassword.bind(this);
  }

  handleChange = async (event) => {
    const { target } = event;
    const value = target.value;
    const { name } = target;
    await this.setState({
      [name]: value,
    });
    if (name === 'email') {
      this.validateEmail(target);
    }
    if (name === 'password') {
      this.validatePassword(target);
    }
  };

  componentDidUpdate() {
    if (this.props.message === undefined && this.state.viewForm === true) {
      this.setState({ viewForm: false });
    }
  }
  async requestPassword(email) {
    await this.props.requestPassword(email);
    this.setState({ responseMessage: this.props.message });
  }

  componentDidMount = () => {
    this.props.verifyLoggedAdmin();
  };

  render() {
    const content = () => {
      if (this.state.viewForm) {
        return (
          <ForgotPasswordForm
            requestPassword={this.requestPassword}
            message={this.props.message}
            successful={this.props.successful}
            errors={this.props.errors}
            setMessage={this.props.setMessage}
          />
        );
      } else {
        return (
          <ForgotPasswordMessage
            successfull={this.props.successful}
            message={this.props.message}
            setMessage={this.props.setMessage}
          />
        );
      }
    };
    return (
      <div className="container login">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="headerview">
              {/* image */}
              <center>
                <div className="iheader">
                  <h1>
                    <img
                      className="logo-bj"
                      src="/img/header.png"
                      alt="bluejacketeer"
                      loading="lazy"
                    />
                  </h1>
                </div>
              </center>
              <div className="card">
                <div className="card-block">
                  {/* form */}

                  <div className="login-form-container">{content()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Login.propTypes = {
//   handleSubmit: PropTypes.func,
//   message: PropTypes.string,
// };

export default ForgotPassword;
