import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'banners');
}

export const getBannersSelectors = state => getState(state).get('allBanners');

export const getBannerDetailsSelectors = state =>
  getState(state).get('bannerDetail');

export const loadBannerDetailsSelectors = state =>
  getState(state).get('loadBannerDetails');

export const createBannerViewSelector = state =>
  getState(state).get('createBannerView');

export const editBannerViewSelector = state =>
  getState(state).get('editBanner');

export const setLoadingInfoSelector = state =>
  getState(state).get('loadingInfo');
