import React from 'react';
import { Link } from 'react-router';
import TextWithTooltip from '../../../commonComponents/TextWithTooltip/TextWithTooltip';

class SidebarLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = { render: false };
  }

  render() {
    const {
      to,
      activeIcon,
      inactiveIcon,
      isActive,
      name,
      hidden = false,
      noImg = false,
    } = this.props;

    const active = isActive || this.state.hover;

    return (
      <li className="nav-item" hidden={hidden}>
        <Link
          to={to}
          className={active ? 'nav-link active' : 'nav-link'}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
        >
          <TextWithTooltip
            text={
              noImg ? (
                name
              ) : (
                <img
                  alt="icon"
                  src={active ? activeIcon : inactiveIcon}
                  className="linkIcon"
                  loading="lazy"
                />
              )
            }
            tooltip={name === 'Topics' ? 'Rates' : name}
          />
        </Link>
      </li>
    );
  }
}

export default SidebarLink;
