import './ResetPassword.css';

import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap-button-loader';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { clearErrorFromService as clearErrorFromServiceAdmin } from '../../../components/Admins/actions';
import {
  getFormErrors as getFormErrorsAdmin,
  getIsWaiting as getIsWaitingAdmin,
} from '../../../components/Admins/selectors';
import { updateFormErrors as clearErrorFromServiceUser } from '../../../components/Users/actions';
import {
  getFormErrors as getFormErrorsUser,
  getIsWaiting as getIsWaitingUser,
} from '../../../components/Users/selectors';
import Input from '../../Input/Input';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
    };
  }

  componentWillUnmount() {
    this.props.clearErrorMessageAdmin();
    this.props.clearErrorMessageUser();
  }

  validatePassword = () => {
    const re =
      /^(?=(.*[^A-Za-z0-9]){2,})(?=(.*[A-Z]){2,})(?=(.*\d){2,})(?=(.*[a-z]){2,}).+/;
    const result = re.test(String(this.state.value));
    if (!result) {
      this.setState({
        passwordError:
          'password must contain 2 special characters, 2 numbers, 2 upper case, and 2 lower case',
      });
    } else {
      this.setState({ passwordError: '' });
    }
    return result;
  };

  validate({ password, confirmPassword }, callback) {
    const errors = {};

    if (!password) {
      errors.password = 'Password field is mandatory';
    }
    if (!confirmPassword) {
      errors.confirmPassword = 'Confirm Password field is mandatory';
    }

    if (password.length < 12 || password.length > 30) {
      errors.password = 'password must be between 12 and 30 chars';
    }

    if (password !== confirmPassword) {
      errors.general = 'Both fields must match';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: null, waiting: true });
    callback({ password, confirmPassword });
  }

  render() {
    const { handleSubmit, onSubmit, onCancel, user } = this.props;

    const isAdmin = 'mainRole' in user;

    const formErrors = isAdmin
      ? this.props.formErrorsAdmin
      : this.props.formErrorsUser;

    const showError =
      (this.state.errors && this.state.errors.general) || formErrors;
    const errorMessage =
      this.state.errors && this.state.errors.general
        ? this.state.errors.general
        : formErrors;

    return (
      <div className="reset-password-component">
        <div className="card-block">
          <div className="text">
            You are reseting the password for {user.name} {user.lastName}
          </div>
          <form
            className="widget-form add-member-in-group-form"
            onSubmit={handleSubmit((fields) => this.validate(fields, onSubmit))}
          >
            <div>
              <span className="label">New Password:</span>
              <Field
                name="password"
                type="password"
                component={Input}
                icon="lock"
                placeholder="New password"
              />
              {this.state.errors && this.state.errors.password && (
                <span className="errors">{this.state.errors.password}</span>
              )}
            </div>

            <div style={{ marginTop: '30px' }}>
              <span className="label">Confirm New Password:</span>
              <Field
                name="confirmPassword"
                type="password"
                component={Input}
                icon="lock"
                placeholder="Confirm New Password"
              />
              {this.state.errors && this.state.errors.confirmPassword && (
                <span className="errors">
                  {this.state.errors.confirmPassword}
                </span>
              )}
            </div>

            {showError && <div className="errors">{errorMessage}</div>}

            <div className="bottom-buttons">
              <Button className="btn btn-secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                className="btn btn-success"
                type="submit"
                loading={
                  isAdmin ? this.props.isWaitingAdmin : this.props.isWaitingUser
                }
              >
                Reset password
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  requesting: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  formErrorsAdmin: getFormErrorsAdmin(state),
  isWaitingAdmin: getIsWaitingAdmin(state),
  formErrorsUser: getFormErrorsUser(state),
  isWaitingUser: getIsWaitingUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearErrorMessageAdmin: () => {
    dispatch(clearErrorFromServiceAdmin());
  },
  clearErrorMessageUser: () => {
    dispatch(clearErrorFromServiceUser());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'ResetPassword' })(ResetPassword));
