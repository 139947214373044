import * as type from './actionTypes';

export const setRates = (response, page) => ({
  type: type.SET_RATES,
  payload: response,
  page,
});

export const setQuals = (response, page) => ({
  type: type.SET_QUALS,
  payload: response,
  page,
});

export const addRate = rate => ({
  type: type.ADD_RATE,
  rate,
});

export const setTopics = response => ({
  type: type.SET_TOPICS,
  payload: response,
});

export const updateTopic = response => ({
  type: type.UPDATE_TOPIC,
  payload: response,
});

export const updateSubtopic = response => ({
  type: type.UPDATE_SUBTOPIC,
  payload: response,
});

export const removeTopic = response => ({
  type: type.REMOVE_TOPIC,
  payload: response,
});

export const removeSubtopic = response => ({
  type: type.REMOVE_SUBTOPIC,
  payload: response,
});

export const addTopic = response => ({
  type: type.ADD_TOPIC,
  payload: response,
});

export const addSubtopic = response => ({
  type: type.ADD_SUBTOPIC,
  payload: response,
});

export const waiting = waiting => ({
  type: type.UPDATE_WAITING,
  waiting,
});

export const updateRate = rate => ({
  type: type.UPDATE_RATE,
  rate,
});

export const setSelectedSP = sp => ({
  type: type.SET_SELECTED_SP,
  sp,
});

export const addQual = qual => ({
  type: type.ADD_QUAL,
  qual,
});

export const updateSP = sp => ({
  type: type.UPDATE_SP,
  sp,
});

export const removeSP = sp => ({
  type: type.REMOVE_SP,
  sp,
});

export const setRateQuestionPercentage = (rate, percentage) => ({
  type: type.SET_RATING_EXAM_QUESTION_PERCENTAGE,
  rate,
  percentage,
});
