import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'books');
}

export const getWaiting = (state) => getState(state).get('waiting');
export const getFilters = (state) => getState(state).get('filters');
export const getExpandAll = (state) => getState(state).get('expandAll');
export const getSearchText = (state) => getState(state).get('searchText');
export const getSelectedRate = (state) => getState(state).get('selectedRate');
export const getIsViewDeletedBooks = (state) =>
  getState(state).get('isViewDeletedBooks');
export const getChapterQuestionsCSV = (state) =>
  getState(state).get('chapterQuestionsCSV');
export const getCurrentLogs = (state) => getState(state).get('logs');
export const getLastSelectedId = (state) =>
  getState(state).get('lastSelectedId');
export const getLastSelectedPanel = (state) =>
  getState(state).get('lastSelectedPanel');
export const getLastSelectedTreeView = (state) =>
  getState(state).get('lastSelectedTreeView');
export const getSidebarScroll = (state) => getState(state).get('sidebarScroll');
export const getMainScroll = (state) => getState(state).get('mainScroll');
export const getLastBookOpenPerRate = (state) =>
  getState(state).get('lastSelectedBookPerRate');
export const getNamesToSecondBarReducer = (state) =>
  getState(state).get('namesToSecondBar');
export const getUrlPdfPrivate = (state) => getState(state).get('urlPdfPrivate');
export const getQuestionsCSVbyDetail = (state) =>
  getState(state).get('questionsCSVbyDetail');
export const getFinishProcessing = (state) =>
  getState(state).get('finishProcessing');
