import * as type from './actionTypesSagas';

export const loadStudyPlans = selectedStudyPlan => ({
  type: type.LOAD_STUDY_PLANS,
  selectedStudyPlan,
});

export const createStudyPlan = studyPlan => ({
  type: type.CREATE_STUDY_PLAN,
  studyPlan,
});

export const editStudyPlan = (studyPlan, title, isActive) => ({
  type: type.EDIT_STUDY_PLAN,
  studyPlan,
  title,
  isActive,
});

export const updateStartDate = (
  studyPlan,
  rank,
  dutyState,
  date,
  isApplicable
) => ({
  type: type.UPDATE_START_DATE,
  studyPlan,
  rank,
  dutyState,
  date,
  isApplicable,
});

export const updateEmail = (studyPlan, subject, content, emailId) => ({
  type: type.UPDATE_EMAIL,
  studyPlan,
  subject,
  content,
  emailId,
});

export const loadStudyPlansUsers = (page, search) => ({
  type: type.LOAD_STUDY_PLANS_USERS,
  page,
  search,
});

export const editStudyPlanUser = (rate, dutyState, rank, isSubscribed, id) => ({
  type: type.EDIT_STUDY_PLAN_USER,
  rate,
  dutyState,
  rank,
  isSubscribed,
  id,
});

export const deleteStudyPlanUser = id => ({
  type: type.DELETE_STUDY_PLAN_USER,
  id,
});

export const sendStudyPlanPreview = (
  studyPlan,
  rate,
  rank,
  dutyState,
  week,
  emails
) => ({
  type: type.SEND_STUDY_PLAN_PREVIEW,
  studyPlan,
  rate,
  rank,
  dutyState,
  week,
  emails,
});
