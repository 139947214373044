import React from 'react';
import RolesList from '../../../commonComponents/RolesList/RolesList';
import { Row, Col } from 'react-bootstrap';
import RoleCard from '../../Roles/components/RoleCard/RoleCard';
import '../../Roles/components/RoleCard/RoleCard.css';
import '../../Roles/components/Roles.css';

class Permissions extends React.Component {
  componentWillMount() {
    this.props.onLoad(this.props.pagination.page);
  }

  componentWillUnmount() {
    this.props.updateChecks([]);
  }

  createRole(role) {
    role.isStudyProgramRole = false;
    this.props.createRole(role);
  }

  componentWillReceiveProps({ roles, selectedRole, newRoleMode }) {
    if (
      selectedRole &&
      !selectedRole.id &&
      this.props.roles !== roles &&
      roles.length > 0 &&
      !newRoleMode
    ) {
      this.props.updateRoleName(roles[0].name);
      this.props.setSelectedRole(roles[0]);
    }
  }

  toggleNewRole() {
    this.props.toggleNewRole(!this.props.toggleNewRole);
  }

  render() {
    const commonProps = {
      onUpdate: this.props.updateRole,
      onDelete: this.props.deleteRole,
      creating: this.props.creating,
      updating: this.props.updating,
      deleting: this.props.deleting,
    };

    return (
      <div className="rolesSectionContainer">
        <Row
          style={{
            marginRight: '0px',
            marginLeft: '0px',
          }}
        >
          <Col
            xs={3}
            md={3}
            lg={3}
            style={{
              paddingRight: '0px',
              paddingLeft: '19px',
              maxWidth: '480px',
              minWidth: '320px',
            }}
            className="rolesListSector"
          >
            <div className="rolesListContainer">
              <RolesList
                roles={this.props.roles}
                selectedRole={this.props.selectedRole}
                setSelectedRole={this.props.setSelectedRole}
                updateRoleName={this.props.updateRoleName}
                toggleNewRole={this.props.toggleNewRole}
                permissionsAttribute="pagePermissions"
                updateChecks={this.props.updateChecks}
                loggedUser={this.props.user}
                pagePermissions={this.props.pagePermissions}
              />
            </div>
          </Col>
          <Col
            xs={9}
            md={9}
            lg={9}
            style={{
              paddingRight: '0px',
              paddingLeft: '0px',
              maxWidth: '480px',
              minWidth: '320px',
              padding: '20px',
            }}
          >
            <RoleCard
              selectedRole={this.props.selectedRole}
              roles={this.props.roles}
              permissions={this.props.pages}
              permissionsName="Pages"
              permissionsAttribute="pagePermissions"
              onCreate={role => this.createRole(role, true)}
              updateRoleName={this.props.updateRoleName}
              roleName={this.props.roleName}
              checks={this.props.checks}
              updateChecks={this.props.updateChecks}
              newRoleMode={this.props.newRoleMode}
              toggleNewRole={this.props.toggleNewRole}
              setSelectedRole={this.props.setSelectedRole}
              loggedUser={this.props.user}
              pagePermissions={this.props.pagePermissions}
              generalPermissions={this.props.generalPermissions}
              {...commonProps}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Permissions;
