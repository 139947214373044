import { put, call, takeLatest, all } from 'redux-saga/effects';
import {
  LOAD_PRACTICE_EXAM_INFORMATION,
  BUILD_PRACTICE_EXAM,
  REMOVE_QUESTION,
  ACTIVATE_EXAM,
  BUILD_ALL_EXAMS,
  CHECK_BUILD_PE_PROGRESS,
} from './actionTypesSagas';
import {
  setPracticeExamInformation,
  setPracticeExamInformationWaiting,
  setCycle,
  setExam,
  setBuildPracticeExamWaiting,
  setExamAfterBuild,
  setRemoveQuestionSuccess,
  setRemoveQuestionWaiting,
  setActivateExamWaiting,
  setActivateExamSuccess,
  setBuildAllExamsSuccess,
  setBuildAllExamsWaiting,
  setBuildAllExamsProgress,
} from './actions';
import * as api from '../../utilities/ServiceManager';
import Notifications from 'react-notification-system-redux';

export function* watchLaodPracticeExamInformation() {
  yield takeLatest(
    LOAD_PRACTICE_EXAM_INFORMATION,
    laodPracticeExamInformationSaga,
  );
}

function* laodPracticeExamInformationSaga(action) {
  try {
    yield put(setPracticeExamInformationWaiting(true));

    const response = yield call(
      api.loadPracticeExamsData,
      action.rate,
      action.rank,
      action.dutyState,
    );

    if (response.error) {
      yield put(
        Notifications.show(
          {
            title: 'Error!',
            message: `No exams related`,
          },
          'error',
        ),
      );
      yield put(setPracticeExamInformationWaiting(false));
      yield put(setPracticeExamInformation([]));
    } else {
      yield put(setPracticeExamInformation(response));
      if (response.length > 0) {
        yield put(setCycle(Number(response[0].cycle.cycleId)));
        yield put(setExam(Number(response[0].freePracticeExam.id)));
        for (let i = 1; i < response.length; i++) {
          if (response[i].cycle.isActive) {
            yield put(setCycle(Number(response[i].cycle.cycleId)));
            yield put(setExam(Number(response[i].freePracticeExam.id)));
          }
        }
      }
    }

    yield put(setPracticeExamInformationWaiting(false));
  } catch (err) {
    yield put(
      Notifications.show(
        { title: 'Error!', message: err.response.data },
        'error',
      ),
    );
    yield put(setPracticeExamInformationWaiting(false));
    yield put(setPracticeExamInformation([]));
  }
}

export function* watchBuildPracticeExam() {
  yield takeLatest(BUILD_PRACTICE_EXAM, buildPracticeExamSaga);
}

function* buildPracticeExamSaga(action) {
  try {
    yield put(setBuildPracticeExamWaiting(true));

    const response = yield call(api.buildPracticeExam, action.practiceExamId);

    if (response.error) {
      yield put(
        Notifications.show(
          { title: 'Error!', message: response.error },
          'error',
        ),
      );
    } else {
      yield put(
        setExamAfterBuild(
          response,
          response.free,
          response.bonus,
          response.extra,
        ),
      );
    }

    yield put(setBuildPracticeExamWaiting(false));
  } catch (err) {
    yield put(
      Notifications.show(
        { title: 'Error!', message: err.response.data },
        'error',
      ),
    );
    yield put(setBuildPracticeExamWaiting(false));
  }
}

export function* watchRemoveQuestion() {
  yield takeLatest(REMOVE_QUESTION, removeQuestionSaga);
}

function* removeQuestionSaga(action) {
  try {
    yield put(setRemoveQuestionWaiting(true));

    const response = yield call(
      api.removePracticeExamQuestion,
      action.practiceExamId,
      action.questionId,
    );

    if (response.error) {
      yield put(setRemoveQuestionSuccess(false));
      yield put(
        Notifications.show(
          { title: 'Error!', message: response.error },
          'error',
        ),
      );
    } else {
      yield put(
        setExamAfterBuild(
          response,
          response.free,
          response.bonus,
          response.extra,
        ),
      );
      yield put(setRemoveQuestionSuccess(true));
    }

    yield put(setRemoveQuestionWaiting(false));
  } catch (err) {
    yield put(
      Notifications.show(
        { title: 'Error!', message: err.response.data },
        'error',
      ),
    );
    yield put(setRemoveQuestionWaiting(false));
    yield put(setRemoveQuestionSuccess(false));
  }
}

export function* watchActivateExam() {
  yield takeLatest(ACTIVATE_EXAM, activateExamSaga);
}

function* activateExamSaga(action) {
  try {
    yield put(setActivateExamWaiting(true));

    const response = yield call(
      api.activatePracticeExam,
      action.practiceExamId,
    );

    if (response.error) {
      yield put(
        Notifications.show(
          { title: 'Error!', message: response.error },
          'error',
        ),
      );
      yield put(setActivateExamSuccess(false));
    } else {
      yield put(
        Notifications.show(
          {
            title: 'Success!',
            message: 'Practice exam activated',
            autoDismiss: 1,
          },
          'success',
        ),
      );
      yield put(
        setExamAfterBuild(
          response,
          response.free,
          response.bonus,
          response.extra,
        ),
      );
      yield put(setActivateExamSuccess(true));
    }

    yield put(setActivateExamWaiting(false));
  } catch (err) {
    yield put(
      Notifications.show(
        { title: 'Error!', message: err.response.data },
        'error',
      ),
    );
    yield put(setActivateExamWaiting(false));
    yield put(setActivateExamSuccess(false));
  }
}

export function* watchCheckBuilPEProgress() {
  yield takeLatest(CHECK_BUILD_PE_PROGRESS, checkBuildPEProgressSagas);
}

export function* checkBuildPEProgressSagas() {
  try {
    const isRunning = yield call(api.checkBuildPracticeExamsProgress);
    yield put(setBuildAllExamsWaiting(isRunning));
  } catch (err) {
    yield put(setBuildAllExamsWaiting(false));
  }
}

export function* watchBuildAllExams() {
  yield takeLatest(BUILD_ALL_EXAMS, buildAllExamsSaga);
}

function* buildAllExamsSaga(action) {
  try {
    const isRunning = yield call(api.checkBuildPracticeExamsProgress);
    if (!isRunning) {
      const list = action.ratingExamsList;
      yield call(api.buildAllPracticeExams, list);
    }
    yield put(setBuildAllExamsWaiting(true));
  } catch (err) {
    yield put(setBuildAllExamsWaiting(false));
    yield put(setBuildAllExamsSuccess(false));
    yield put(
      Notifications.show(
        {
          title: 'Cannot activate exam!',
          message: err.response.data,
        },
        'error',
      ),
    );
  }
}

/* SAGAS EXPORT */
export default function* sagas() {
  yield all([
    watchLaodPracticeExamInformation(),
    watchBuildPracticeExam(),
    watchRemoveQuestion(),
    watchActivateExam(),
    watchBuildAllExams(),
    watchCheckBuilPEProgress(),
  ]);
}
