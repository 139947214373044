import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import Spinner from '../../../commonComponents/Spinner/Spinner';

class ResultsTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: true,
    };
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  getTooltipContent = (item) => {
    if (this.props.title === 'Books') {
      return (
        <div className="tooltip-body">
          <div className="book-item">
            <strong>Title: </strong>
            {item.title}
          </div>
          <div className="book-item">
            <strong>Description: </strong>
            {item.description}
          </div>
          <div className="book-item">
            <strong>Id: </strong>
            {item.id}
          </div>
          <div className="book-item">
            <strong>Act as Parent: </strong>
            {item.actAsParent === true ? 'true' : 'false'}
          </div>
        </div>
      );
    }
    if (this.props.title === 'Chapters') {
      return (
        <div className="tooltip-body">
          <div className="chapter-item">
            <strong>Title: </strong>
            {item.title}
          </div>
          <div className="chapter-item">
            <strong>Description: </strong>
            {item.meta}
          </div>
          <div className="chapter-item">
            <strong>Id: </strong>
            {item.id}
          </div>
          <div className="chapter-item">
            <strong>Reference Id: </strong>
            {item.reference}
          </div>
          <div className="chapter-item">
            <strong>Reference Title: </strong>
            {item.referenceName}
          </div>
          <div className="chapter-item">
            <strong>Reference Description: </strong>
            {item.referenceDescription}
          </div>
          <div className="chapter-item">
            <strong>Ghost Chapter: </strong>
            {item.isGhostChapter === true ? 'true' : 'false'}
          </div>
        </div>
      );
    }
    if (this.props.title === 'Questions') {
      return (
        <div className="tooltip-body">
          <div className="chapter-item">
            <strong>Text: </strong>
            {`${item.title.slice(0, 150)}...`}
          </div>
          <div className="chapter-item">
            <strong>Notes: </strong>
            {this.stripHtml(`${item.meta.slice(0, 150)}...`)}
          </div>
          <div className="chapter-item">
            <strong>Id: </strong>
            {item.id}
          </div>
          <div className="chapter-item">
            <strong>Flash Mode: </strong>
            {item.flashMode === true ? 'true' : 'false'}
          </div>
          <div className="chapter-item">
            <strong>Chapter ID: </strong>
            {item.chapter}
          </div>
          <div className="chapter-item">
            <strong>Chapter Title: </strong>
            {item.chapterName}
          </div>
          <div className="chapter-item">
            <strong>Chapter Description: </strong>
            {item.chapterDescription}
          </div>
          <div className="chapter-item">
            <strong>Reference ID: </strong>
            {item.referenceId}
          </div>
          <div className="chapter-item">
            <strong>Reference Title: </strong>
            {item.referenceName}
          </div>
          <div className="chapter-item">
            <strong>Reference Description: </strong>
            {item.referenceDescription}
          </div>
        </div>
      );
    }
  };

  getLink = (item) => {
    if (this.props.title === 'Chapters') {
      return `/home/books?referenceid=${item.reference}&actasparent=${item.referenceActAsParent}`;
    }
    if (this.props.title === 'Books') {
      return `/home/books?referenceid=${item.id}&actasparent=${item.actAsParent}`;
    }
    if (this.props.title === 'Questions') {
      return `/home/questions/${item.referenceId}/${item.chapter}/0/false?search=${this.props.query}&questionid=${item.id}`;
    }
  };

  stripHtml = (meta) => {
    if (this.props.title === 'Questions') {
      // eslint-disable-next-line prefer-const
      let temporalDivElement = document.createElement('div');
      temporalDivElement.innerHTML = meta;
      return (
        temporalDivElement.textContent || temporalDivElement.innerText || meta
      );
    }
    return meta;
  };

  render() {
    return (
      <Col sm={12} className="resultsPageCol">
        <button
          className={
            this.state.expanded
              ? 'btn expandButton expanded'
              : 'btn expandButton not-expanded'
          }
          onClick={this.toggleExpanded}
        >
          {this.state.expanded ? '-' : '+'}
        </button>
        <span className="ResultsTabTitle">{this.props.title}</span>
        <span className="badge badge-pill">
          {this.props.resultSize} of {this.props.count}
        </span>

        {this.state.expanded && this.props.resultSize > 0 && (
          <ol className="resultsList text-muted">
            {this.props.items.map((item, index) => (
              <li key={index + 1}>
                <div className="resultItem">
                  <Link
                    to={this.getLink(item)}
                    className="resultItemTitle"
                    data-tooltip-id={`tooltip${index + 1}-${this.props.title}`}
                    data-tooltip-content=""
                  >
                    {item.title}
                  </Link>
                  <ReactTooltip
                    place="bottom"
                    variant="dark"
                    className="react-tooltip"
                    id={`tooltip${index + 1}-${this.props.title}`}
                  >
                    {this.getTooltipContent(item)}
                  </ReactTooltip>
                  <span className="resultItemMeta">
                    {`  · ${this.stripHtml(item.meta)}`}
                  </span>
                </div>
              </li>
            ))}
          </ol>
        )}
        {this.props.searching && (
          <Spinner className="loading-results-spinner" />
        )}
        {this.props.resultSize === 0 && !this.props.searching && (
          <div className="no-results-div">
            No Results Found for this category.
          </div>
        )}
        {!this.props.searching &&
          this.state.expanded &&
          this.props.count > this.props.resultSize && (
            <button
              className="btn btn-secondary btn-load-more"
              onClick={this.props.loadMore}
            >
              <i className="fa fa-ellipsis-h fa-lg" />
              Load More
            </button>
          )}
      </Col>
    );
  }
}

ResultsTab.propTypes = {
  title: PropTypes.string.isRequired,
  resultSize: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  searching: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};

export default ResultsTab;
