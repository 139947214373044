import * as React from 'react';
import RateLine from './RateLine';
import './RatesList.css';
import { connect } from 'react-redux';
import {
  getRolesPermissions,
  getUser,
  getGeneralRolesPermissions,
} from '../../components/Login/selectors';
import {
  hasPermissions,
  hasPagePermissionRates,
} from '../../utilities/FilterLinksForTabs';
import NumberUtils from '../../lib/NumberUtils';

class RatesList extends React.Component {
  render() {
    let {
      title,
      rates,
      selected,
      onRateClick,
      onPlusClick,
      rolesPermissions,
      user,
      generalRolesPermissions,
    } = this.props;

    let pmk = rates.find(rate => rate.isPmkOnly);

    return (
      <div className="rates-list-component">
        {(title || onPlusClick) && (
          <div className="rates-title">
            {title}
            {onPlusClick && (
              <i className="fa fa-plus fa-lg mt-1" onClick={onPlusClick} />
            )}
            <div className="line-quals-page" />
          </div>
        )}
        <div className="rates-list">
          {pmk && (
            <div>
              <RateLine
                onClick={() => onRateClick(pmk)}
                name={`${pmk.name}`}
                selected={selected && pmk.id === selected.id}
                pmk
              />
              <hr />
            </div>
          )}
          {rates.length ? (
            rates.filter(rate => !rate.isPmkOnly).map(rate => (
              <div className="row" key={NumberUtils.obtainUniqueKey()}>
                <RateLine
                  onClick={() => onRateClick(rate)}
                  name={`${rate.name} ${rate.shortname
                    ? '(' + rate.shortname + ')'
                    : ''}`}
                  selected={selected && rate.id === selected.id}
                  withBibShortcut={
                    hasPermissions('Bibliography', rolesPermissions, rate) ||
                    hasPagePermissionRates(
                      'Bibliography',
                      generalRolesPermissions
                    ) ||
                    user.isSuperAdmin
                  }
                  withSubtopicShortcut={
                    hasPermissions('Subtopics', rolesPermissions, rate) ||
                    hasPagePermissionRates(
                      'Subtopics',
                      generalRolesPermissions
                    ) ||
                    user.isSuperAdmin
                  }
                  pmk={false}
                  id={rate.id}
                />
              </div>
            ))
          ) : (
            <p>No Rates</p>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  rolesPermissions: getRolesPermissions(state),
  generalRolesPermissions: getGeneralRolesPermissions(state),
  user: getUser(state),
});

export default connect(mapStateToProps)(RatesList);
