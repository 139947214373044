export const AI_TASK_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED',
};

export const AI_TASK_TYPE = {
  QUESTION_GENERATION: 'QUESTION_GENERATION',
  SUMMARY_GENERATION: 'SUMMARY_GENERATION',
  SUGGESTION_GENERATION: 'SUGGESTION_GENERATION',
};
