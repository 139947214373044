import { connect } from 'react-redux';
import {
  getStudyPlans,
  getSelectedStudyPlan,
  getSelectedStudyPlanUser,
  getStudyPlansUsers,
  getActiveListTab,
  getStudyPlansUsersPage,
  getIsLoadingUserse,
} from '../../selectors';
import StudyPlanList from './StudyPlanList';
import addAbilityToOpenModal from '../../../../utilities/modalHOC';
import {
  setSelectedStudyPlan,
  setSelectedStudyPlanUser,
  updateSelectedTenWeekListTab,
  setPreviewPlans,
} from '../../actions';
import {
  loadStudyPlans,
  createStudyPlan,
  loadStudyPlansUsers,
} from '../../actionsSagas';
import { getUser, getGeneralRolesPermissions } from '../../../Login/selectors';

const mapStateToProps = state => {
  return {
    studyPlans: getStudyPlans(state),
    selectedStudyPlan: getSelectedStudyPlan(state),
    activeListTab: getActiveListTab(state),
    studyPlansUsers: getStudyPlansUsers(state),
    selectedStudyPlanUser: getSelectedStudyPlanUser(state),
    user: getUser(state),
    generalPermissions: getGeneralRolesPermissions(state),
    page: getStudyPlansUsersPage(state),
    isLoadingUsers: getIsLoadingUserse(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSelectedStudyPlan: studyPlan => {
      dispatch(setSelectedStudyPlan(studyPlan));
    },
    loadStudyPlans: () => {
      dispatch(loadStudyPlans());
    },
    onCreateStudyPlan: studyPlan => {
      dispatch(createStudyPlan(studyPlan));
    },
    onUpdateSelectedTenWeekListTab: tab =>
      dispatch(updateSelectedTenWeekListTab(tab)),
    loadStudyPlansUsers: (page, search) => {
      dispatch(loadStudyPlansUsers(page, search));
    },
    setSelectedStudyPlanUser: studyPlanUser => {
      dispatch(setSelectedStudyPlanUser(studyPlanUser));
    },
    setPreviewPlans: previewPlans => {
      dispatch(setPreviewPlans(previewPlans));
    },
  };
};

const StudyPlanListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StudyPlanList);

export default addAbilityToOpenModal(StudyPlanListContainer);
