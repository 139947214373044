import './Roles.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';

import RolesList from '../../../commonComponents/RolesList/RolesList';
import RoleCard from './RoleCard/RoleCard';

class Roles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 1,
    };
  }

  componentWillMount() {
    this.props.loadPages();
    this.props.loadActions();
  }

  createRole(role, isStudyProgramRole) {
    role.isStudyProgramRole = isStudyProgramRole;

    this.props.createRole(role);
  }

  componentWillReceiveProps({ roles, selectedRole, newRoleMode }) {
    if (
      selectedRole &&
      !selectedRole.id &&
      this.props.roles !== roles &&
      roles.filter((role) => role.isStudyProgramRole).length > 0 &&
      !newRoleMode
    ) {
      this.props.updateRoleName(
        roles.filter((role) => role.isStudyProgramRole)[0].name,
      );
      this.props.setSelectedRole(
        roles.filter((role) => role.isStudyProgramRole)[0],
      );
    }
  }

  toggleNewRole() {
    this.props.toggleNewRole(!this.props.toggleNewRole);
  }

  render() {
    const commonProps = {
      onUpdate: this.props.updateRole,
      onDelete: this.props.deleteRole,
      creating: this.props.creating,
      updating: this.props.updating,
      deleting: this.props.deleting,
    };
    return (
      <div className="rolesSectionContainer">
        <Row style={{ height: '100%' }}>
          <Col
            xs={3}
            md={3}
            lg={3}
            style={{
              paddingRight: '0px',
              paddingLeft: '19px',
              maxWidth: '480px',
              minWidth: '320px',
            }}
            className="rolesListSector"
          >
            <div className="rolesListContainer">
              <RolesList
                roles={this.props.roles.filter(
                  (role) => role.isStudyProgramRole,
                )}
                selectedRole={this.props.selectedRole}
                setSelectedRole={this.props.setSelectedRole}
                updateRoleName={this.props.updateRoleName}
                toggleNewRole={this.props.toggleNewRole}
                updateChecks={this.props.updateChecks}
                loggedUser={this.props.loggedUser}
                generalPermissions={this.props.generalPermissions}
              />
            </div>
          </Col>
          <Col
            xs={9}
            md={9}
            lg={9}
            style={{
              paddingRight: '0px',
              paddingLeft: '0px',
              maxWidth: '480px',
              minWidth: '320px',
              padding: '20px',
            }}
          >
            <RoleCard
              selectedRole={this.props.selectedRole}
              roles={this.props.roles.filter((role) => role.isStudyProgramRole)}
              permissions={this.props.actions}
              permissionsName="Actions"
              permissionsAttribute="workflowActions"
              onCreate={(role) => this.createRole(role, true)}
              updateRoleName={this.props.updateRoleName}
              roleName={this.props.roleName}
              checks={this.props.checks}
              updateChecks={this.props.updateChecks}
              newRoleMode={this.props.newRoleMode}
              toggleNewRole={this.props.toggleNewRole}
              setSelectedRole={this.props.setSelectedRole}
              loggedUser={this.props.loggedUser}
              generalPermissions={this.props.generalPermissions}
              isLoading={this.props.isLoading}
              {...commonProps}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Roles;
