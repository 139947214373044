import React from 'react';
import { Col, Row } from 'react-bootstrap';
import RateList from './components/rateList/rateList';
import NotificationsMessageDetails from './components/messageDetails/notificationsMessageDetails';
import './styles/messageCenterStyles.css';

class messageCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
    };
  }

  componentDidMount() {
    if (!this.state.load) {
      this.props.getAllRates();
      this.setState({ load: false });
    }
  }
  componentWillReceiveProps = nextProps => {
    if (nextProps.loadNotifications) {
      this.props.getAllRates();
    }
  };

  render() {
    return (
      <Row className="messageCenterContainer">
        <Col className="rateListContainer" md={3}>
          <RateList
            rates={this.props.rates}
            setRate={this.props.setRate}
            allNotifications={this.props.allNotifications}
            getNotifications={this.props.getNotifications}
          />
        </Col>
        <Col className="notificationsDetailsContainer" md={9}>
          <NotificationsMessageDetails
            onSubmit={this.props.onSubmit}
            closeModal={this.props.closeModal}
            openModal={this.props.openModal}
            rate={this.props.rate}
            createNotification={this.props.createNotification}
            getNotifications={this.props.getNotifications}
            notifications={this.props.notifications}
            loadNotifications={this.props.loadNotifications}
            deleteNotification={this.props.deleteNotification}
            editNotification={this.props.editNotification}
          />
        </Col>
      </Row>
    );
  }
}

export default messageCenter;
