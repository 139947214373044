import axios from 'axios';
import UniversalCookies from 'universal-cookie';

import { cookiesExpirationTime } from '../constants/cookiesExpirationTime';
import * as userToken from './userToken';

const cookies = new UniversalCookies();

const renewCookieExpirationTime = () => {
  axios.interceptors.request.use((config) => {
    const currentToken = userToken.getUserToken();

    if (currentToken) {
      userToken.setUserToken(currentToken);
      /* cookies.set('token', currentToken, {
        path: '/',
        expires: cookiesExpirationTime(),
      }); */
    }

    return config;
  });
};

export default renewCookieExpirationTime;
