import React from 'react';
import { CSVLink } from 'react-csv';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './profileSheetReport.css';
import { SimpleInput } from '../../../../commonComponents/Input/Input';

export default class ProfileSheetData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      step: 0,
      csvCompleted: false,
      retryAvailable: false,
      selectedCycles: [],
      selectedCylesId: [],
      select: false,
      reportDownload: false,
    };
  }

  componentWillMount() {
    const { cycles } = this.props;
    if (cycles && cycles.length) {
      this.setState({
        selectedCycles: this.props.cycles.slice(1),
      });

      this.props.loadCycles();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.profileSheetIsLoading &&
      !this.props.profileSheetIsLoading &&
      this.props.profileSheetCsv.length > 0
    ) {
      setTimeout(function() {
        document.getElementById('report-download').click();
      }, 1000);
    }
  }

  onChangeCycleSelection = (index, newValue) => {
    const { selectedCycles } = this.state;
    selectedCycles[index].isSelected = newValue;
    this.setState({ selectedCycles, select: true });
  };

  renderAllCyles(cycle, index) {
    return (
      <Row key={cycle.id}>
        <Col md={12}>
          <SimpleInput
            description={`${cycle.description} - ${cycle.title}`}
            type="checkbox"
            value={cycle.isSelected}
            onChange={e => {
              this.onChangeCycleSelection(index, !cycle.isSelected);
            }}
          />
        </Col>
      </Row>
    );
  }

  generateReportProfileSheet = () => {
    const selectedCyclesIds = this.state.selectedCycles
      .filter(cycles => cycles.isSelected)
      .map(cycles => cycles.id);
    this.props.profileSheetReport(selectedCyclesIds);
    this.setState({ reportDownload: true });
  };

  areAllCyclesSelected = () => {
    let areAllCyclesSelected = true;
    let areAllCyclesDeselected = true;
    this.state.selectedCycles.forEach(currentCycle => {
      if (currentCycle.isSelected) {
        areAllCyclesDeselected = false;
      } else {
        areAllCyclesSelected = false;
      }
    });
    return {
      areAllCyclesDeselected,
      areAllCyclesSelected,
    };
  };

  downloadXls = books => {
    let book = window.XLSX.utils.book_new();
    for (let i = 1; i < books.length; i++) {
      let sheet = window.XLSX.utils.json_to_sheet(books[i], { skipHeader: 1 });

      window.XLSX.utils.book_append_sheet(book, sheet);
    }
    window.XLSX.writeFile(book, 'Profile-sheet-Data-Report.xls');
  };

  renderSelectAllOrNothing = (areAllCyclesSelected, areAllCyclesDeselected) => (
    <Row>
      <Col md={12} id="all-or-none">
        <SimpleInput
          type="checkbox"
          description={areAllCyclesDeselected ? 'Select All' : 'Deselect All'}
          value={!areAllCyclesDeselected}
          onChange={e => {
            this.changeAllValues(areAllCyclesDeselected ? true : false);
          }}
        />
      </Col>
    </Row>
  );

  changeAllValues = newValue => {
    const { selectedCycles } = this.state;
    this.setState({
      selectedCycles: selectedCycles.map(cycle => {
        cycle.isSelected = newValue;
        return cycle;
      }),
    });
  };

  render() {
    const {
      areAllCyclesSelected,
      areAllCyclesDeselected,
    } = this.areAllCyclesSelected();

    const selectedCyclesIds = this.state.selectedCycles.filter(
      cycles => cycles.isSelected,
    );

    if (selectedCyclesIds.length === 0) {
      this.props.profileSheetCsv.length === 0;
    }

    return (
      <div>
        {this.props.profileSheetIsLoading ? (
          <Spinner />
        ) : (
          <div className="generateReportButton">
            <div>
              <Col className="d-block col-6">
                <div
                  className={
                    !areAllCyclesDeselected
                      ? 'generate-report'
                      : 'generate-report disabled'
                  }
                  onClick={
                    !areAllCyclesDeselected
                      ? this.generateReportProfileSheet
                      : () => {}
                  }
                >
                  Generate Report
                </div>
                <div className="d-none">
                  <CSVLink
                    id="report-download"
                    className="report-download"
                    filename="Profile-Sheet-Data-Report.csv"
                    data={this.props.profileSheetCsv}
                  />
                </div>
              </Col>
            </div>
          </div>
        )}

        <div>
          <div className="report-title">Cycles</div>
          {this.renderSelectAllOrNothing(
            areAllCyclesSelected,
            areAllCyclesDeselected,
          )}
          {this.state.selectedCycles.length === 0 && <Spinner />}
          {this.state.selectedCycles.length > 0 &&
            this.state.selectedCycles.map((cycle, index) => {
              return this.renderAllCyles(cycle, index);
            })}
        </div>
      </div>
    );
  }
}

ProfileSheetData.propTypes = {
  cycles: PropTypes.array.isRequired,
  loadCycles: PropTypes.func.isRequired,
};
