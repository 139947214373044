import './AIQuestionNotification.css';

import { useState } from 'react';

import { AI_TASK_STATUS } from '../constants/constants';
import DeleteAIQuestionNotificationModal from './DeleteAIQuestionNotificationModal';

export default function AIQuestionNotification({
  aiQuestionGeneration,
  deleteNotification,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const handleToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const handleCloseNotification = () => {
    setIsActive(false);
  };

  const { status, timeElapsed, id } = aiQuestionGeneration;

  if (!isActive) return null;

  return (
    <>
      {status === AI_TASK_STATUS.IN_PROGRESS ? (
        <div className="notification--in-progress">
          <span>Creating AI Questions</span>
          <span>Time elapsed: {timeElapsed} seconds</span>
          <i className="fa fa-times close--icon" onClick={handleToggleModal} />
        </div>
      ) : null}

      {status === AI_TASK_STATUS.FINISHED ? (
        <div className="notification--finished">
          <span>AI Questions created</span>
          <span>Total time: {timeElapsed} seconds</span>
          <i className="fa fa-times close--icon" onClick={handleToggleModal} />
        </div>
      ) : null}

      {status === AI_TASK_STATUS.FAILED ? (
        <div className="notification--failed">
          <span>AI Questions generation failed</span>
          <span>Total time: {timeElapsed} seconds</span>
          <i className="fa fa-times close--icon" onClick={handleToggleModal} />
        </div>
      ) : null}

      {isOpen ? (
        <DeleteAIQuestionNotificationModal
          isOpen={isOpen}
          toggle={handleToggleModal}
          aiTaskId={id}
          handleCloseNotification={handleCloseNotification}
          deleteNotification={deleteNotification}
        />
      ) : null}
    </>
  );
}
