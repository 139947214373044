import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { SimpleInput } from '../../../../commonComponents/Input/Input';
import PropTypes from 'prop-types';

const ratingExams = [
  'E4 Reg',
  'E4 Sub',
  'E4 Res',
  'E5 Reg',
  'E5 Sub',
  'E5 Res',
  'E6 Reg',
  'E6 Sub',
  'E6 Res',
  'E7 Reg',
  'E7 Sub',
  'E7 Res',
];

export default class RatingExamSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: true,
    };
  }

  componentDidMount = () => {
    this.props.selectAllRatingExam(ratingExams);
  };

  hideRatingExams = () => {
    this.setState({ hide: !this.state.hide });
  };

  render() {
    return (
      <div>
        <Row className="justify-content-center">
          <Col>
            <Button
              onClick={() => this.hideRatingExams()}
              className="generate-report bg-white align-center"
            >
              {this.state.hide ? 'Hide' : 'Show'}
            </Button>
          </Col>
        </Row>
        <Row>
          {this.state.hide &&
            ratingExams.map((ratingExam) => (
              <Col
                xs={3}
                key={ratingExam}
                className="col-12 col-sm-6 col-md-4 px-0"
              >
                <SimpleInput
                  description={`${ratingExam}`}
                  key={`${ratingExam}`}
                  type="checkbox"
                  initialValue={true}
                  value={
                    this.props.selectedRatingExam.includes(ratingExam)
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    this.props.changeRatingExamSelected(ratingExam);
                  }}
                />
              </Col>
            ))}
        </Row>
      </div>
    );
  }
}

RatingExamSelector.propTypes = {
  selectAllRatingExam: PropTypes.func.isRequired,
  changeRatingExamSelected: PropTypes.func.isRequired,
  selectedRatingExam: PropTypes.array.isRequired,
};
