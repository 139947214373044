export const UPDATE_WAITING = 'books/UPDATE_WAITING';
export const ADD_FILTER = 'books/ADD_FILTER';
export const EDIT_FILTER = 'books/EDIT_FILTER_ACTION';
export const SET_ACTIVE_FILTER = 'books/SET_ACTIVE_FILTER';
export const REMOVE_FILTER = 'books/REMOVE_FILTER';
export const SET_FILTERS = 'books/SET_FILTERS';
export const UPDATE_EXPAND_ALL = 'books/UPDATE_EXPAND_ALL';
export const UPDATE_SEARCH_TEXT = 'books/UPDATE_SEARCH_TEXT';
export const SET_SELECTED_RATE = 'books/SET_SELECTED_RATE';
export const SET_IS_VIEW_DELETED_BOOKS = 'books/SET_IS_VIEW_DELETED_BOOKS';
export const SET_CHAPTER_QUESTIONS_CSV = 'books/SET_CHAPTER_QUESTIONS_CSV';
export const SET_LOGS = 'books/SET_LOGS';
export const SET_LAST_SELECTED_ID = 'books/SET_LAST_SELECTED_ID';
export const SET_LAST_SELECTED_PANEL = 'books/SET_LAST_SELECTED_PANEL';
export const SET_LAST_SELECTED_TREE_VIEW = 'books/SET_LAST_SELECTED_TREE_VIEW';
export const SET_SIDEBAR_SCROLL = 'books/SET_SIDEBAR_SCROLL';
export const SET_MAIN_SCROLL = 'books/SET_MAIN_SCROLL';
export const SET_LAST_BOOK_OPEN_PER_RATE = 'books/SET_LAST_BOOK_OPEN_PER_RATE';
export const SET_NAMES_TO_BUILD_SECOND_BAR =
  'books/SET_NAMES_TO_BUILD_SECOND_BAR';
export const SET_URL_PDF_PRIVATE = 'books/URL_PDF_PRIVATE';
export const SET_QUESTIONS_CSV_BY_DETAIL = 'books/SET_QUESTIONS_CSV_BY_DETAIL';
export const FINISH_PROCESSING = 'books/FINISH_PROCESSING';
