import './DropDown.css';

import React from 'react';
import ReactDOM from 'react-dom';

import Mask from '../Mask/Mask';

export default class DropDown extends React.Component {
  constructor() {
    super();
    this.state = {
      isMenuOpen: false,
      renderTop: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = function (event) {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        open: false,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.open && prevState.open == false) {
      const element = document.getElementsByClassName(
        'threeDotsMenuContainer2',
      )[0];
      const windowClass = document.querySelector('.assignmentsTables')
        ? '.assignmentsTables'
        : '.userCardsContainer';
      const windowTop = document.querySelector(windowClass)
        ? document.querySelector(windowClass).scrollTop
        : 0;
      const windowBottom = windowTop + window.innerHeight;
      const clickPosition = element.parentElement.offsetTop;
      if (windowBottom - clickPosition < 388) {
        this.setState({ renderTop: true });
      }
    }
  }

  handleClickMask = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      open: false,
    });
  };

  toggle = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  close = () => {
    this.setState({ isMenuOpen: false });
  };

  onClick = (event) => {
    event();
    if (this.props.shouldCloseAfter) {
      this.setState({ open: false });
    }
  };

  render() {
    const { gear, containerStyle = {} } = this.props;

    return (
      <div>
        <div style={(containerStyle, { position: 'relative' })}>
          {this.state.open && (
            <div className="threeDotsMenuContainer2">
              <div
                className="box"
                style={
                  this.props.style
                    ? !this.state.renderTop
                      ? this.props.style
                      : { position: 'absolute', top: -80, right: 0 }
                    : {}
                }
              >
                <div
                  className="title"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <div className="titleContent">{this.props.title}</div>
                  <div
                    className="closeIconContainer"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.setState({ open: false });
                    }}
                  >
                    <i className="fa fa-close fa-lg" />
                  </div>
                </div>
                {this.props.options.map((option, index) => (
                  <div
                    className="option"
                    onClick={(e) => {
                      this.onClick(option.event);
                      this.setState({ open: false });
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    key={`key-for-${option.name}${index}`}
                    style={option.style ? option.style : {}}
                    data-test={
                      option.name === 'Edit'
                        ? 'edit-book-button'
                        : `${option.name
                            .replace(/ /g, '-')
                            .toLowerCase()}-button`
                    }
                  >
                    {option.icon && option.icon}
                    {option.name}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div
            className={
              this.props.writers
                ? 'threeDotsMenuContainer mt-0'
                : 'threeDotsMenuContainer'
            }
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.setState({ open: true });
            }}
          >
            {this.state.open
              ? ''
              : this.props.options &&
                this.props.options.length > 0 &&
                (this.props.isDate ? (
                  <i className="fa fa-calendar icons positionCalendar" />
                ) : (
                  <i
                    className={
                      gear
                        ? 'fa fa-gear fa-lg mt-2 icons d-block'
                        : this.props.furtherReadingOption
                        ? 'fa fa-ellipsis-v icons d-block'
                        : 'fa fa-ellipsis-v icons font-2xl d-block'
                    }
                    // inherit the data-test prop from the parent
                    data-test={this.props['data-test'] || null}
                  />
                ))}
          </div>
        </div>
        {this.state.open ? <Mask handleClickMask={this.handleClickMask} /> : ''}
      </div>
    );
  }
}
