import { List } from 'immutable';
import Notifications from 'react-notification-system-redux';
import { connect } from 'react-redux';

import addAbilityToOpenModal from '../../../utilities/modalHOC';
import { getSummariesSelector } from '../../Assignments/selectors';
import { hasMoreData, setReferences } from '../../Bibliographies/actions';
import {
  createChapterActionSagas,
  createReferenceActionSagas,
  getReferenceById,
  getUrlPdf,
  loadReferences,
} from '../../Bibliographies/actionsSagas';
import {
  getHasMoreData,
  getLoadAddChapter,
  getLoadingInfiniteScroll,
  getPage,
  getReferences,
  getReferencesImmutable,
  loadingGrid,
} from '../../Bibliographies/selectors';
import {
  getGeneralRolesPermissions,
  getLogin,
  getRolesPermissions,
  getUser,
} from '../../Login/selectors';
import { loadQuestionsOfChapter } from '../../Questions/actionsSagas';
import { getQuestions } from '../../Questions/selectors';
import { createRate, loadRates } from '../../Rates/actionsSagas';
import { getRates, getWaiting } from '../../Rates/selectors';
import {
  allAssignedReferencesActionSagas,
  allForRateActionSagas,
  archivedReferencesActionSagas,
  assignedToBibActionSagas,
  deleteChapterActionSagas,
  deleteReferenceActionSagas,
  moveChapterSaga,
  notAssignedToBibActionSagas,
  udpateReferenceActionSagas,
  updateChapterActionSagas,
} from '../../References/actionsSagas';
import {
  onExpandAll,
  setFinishProcessing,
  setIsViewDeletedBooks,
  setLastBookOpenPerRateActions,
  setLastSelectedId,
  setLastSelectedPanel,
  setLastSelectedTreeView,
  setMainScroll,
  setQuestionsCSV,
  setSearchText,
  setSelectedRate,
  setSidebarScroll,
} from '../actions';
import {
  createFilter,
  deleteFilter,
  editFilter,
  getNamesToSecondBar,
  loadChapterQuestionsCSV,
  loadFilters,
  setActiveFilter,
  updateStudyProgramsOfReference,
} from '../actionsSagas';
import {
  getChapterQuestionsCSV,
  getCurrentLogs,
  getExpandAll,
  getFilters,
  getFinishProcessing,
  getIsViewDeletedBooks,
  getLastBookOpenPerRate,
  getLastSelectedId,
  getLastSelectedPanel,
  getLastSelectedTreeView,
  getMainScroll,
  getNamesToSecondBarReducer,
  getSearchText,
  getSelectedRate,
  getSidebarScroll,
  getUrlPdfPrivate,
  getWaiting as downloadWaiting,
} from '../selectors';
import Books from './Books';

const mapStateToProps = (state) => ({
  references: getReferences(state),
  referencesImmutable: getReferencesImmutable(state),
  waiting: getWaiting(state),
  downloadWaiting: downloadWaiting(state),
  rates: getRates(state),
  user: getUser(state),
  login: getLogin(state),
  generalRolesPermissions: getGeneralRolesPermissions(state),
  rolesPermissions: getRolesPermissions(state),
  filters: getFilters(state),
  loading: loadingGrid(state),
  loadingInfiniteScroll: getLoadingInfiniteScroll(state),
  hasMoreData: getHasMoreData(state),
  page: getPage(state),
  expandAll: getExpandAll(state),
  searchText: getSearchText(state),
  questions: getQuestions(state),
  selectedRate: getSelectedRate(state),
  isViewDeletedBooks: getIsViewDeletedBooks(state),
  chapterQuestionsCSV: getChapterQuestionsCSV(state),
  logs: getCurrentLogs(state),
  lastSelectedId: getLastSelectedId(state),
  lastSelectedPanel: getLastSelectedPanel(state),
  lastSelectedTreeView: getLastSelectedTreeView(state),
  sidebarScroll: getSidebarScroll(state),
  mainScroll: getMainScroll(state),
  urlPdfPrivate: getUrlPdfPrivate(state),
  lastSelectedBookByRate: getLastBookOpenPerRate(state),
  namesToSecondBar: getNamesToSecondBarReducer(state),
  summaries: getSummariesSelector(state),
  loadAddChapter: getLoadAddChapter(state),
  finishProcessing: getFinishProcessing(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadRates: () => dispatch(loadRates()),
  setIsViewDeletedBooks: (isViewDeleted) =>
    dispatch(setIsViewDeletedBooks(isViewDeleted)),
  loadReferences: (
    id,
    type,
    search,
    page,
    isLoadingList = false,
    rate = 0,
    amountOfReferences,
    barFilter,
  ) =>
    dispatch(
      loadReferences(
        id,
        type,
        search,
        page,
        isLoadingList,
        rate,
        amountOfReferences,
        barFilter,
      ),
    ),
  createReference: (fields) => {
    dispatch(createReferenceActionSagas(fields));
  },
  getNamesToSecondBar: (letter) => {
    dispatch(getNamesToSecondBar(letter));
  },
  setLastSelectedId: (value) => {
    dispatch(setLastSelectedId(value));
  },
  setLastSelectedPanel: (value) => {
    dispatch(setLastSelectedPanel(value));
  },
  setLastSelectedTreeView: (value) => {
    dispatch(setLastSelectedTreeView(value));
  },
  setLastBookOpenPerRate: (rate, book) => {
    dispatch(setLastBookOpenPerRateActions(rate, book));
  },
  setMainScroll: (value) => {
    dispatch(setMainScroll(value));
  },
  setSidebarScroll: (value) => {
    dispatch(setSidebarScroll(value));
  },
  createChapter: (fields) => {
    dispatch(createChapterActionSagas(fields));
  },
  updateReference: (fields, referenceId, activeFilter) => {
    dispatch(udpateReferenceActionSagas(fields, referenceId, activeFilter));
  },
  updateChapter: (fields, chapterId) => {
    dispatch(updateChapterActionSagas(fields, chapterId, false, true));
  },
  deleteReference: (reference) => {
    dispatch(deleteReferenceActionSagas(reference));
  },
  deleteChapter: (reference, chapter, presentationIndex) => {
    dispatch(
      deleteChapterActionSagas(reference, chapter, presentationIndex, true),
    );
  },
  getReferenceById: (id) => {
    dispatch(getReferenceById(id));
  },
  associateRates: (studyPrograms, reference) => {
    dispatch(updateStudyProgramsOfReference(studyPrograms, reference));
  },
  createRate: (fields) => dispatch(createRate(fields)),
  obtainAssignedToBib: (rate, rank, dutyState, search) => {
    dispatch(assignedToBibActionSagas(rate, rank, dutyState, search));
  },
  obtainAllForRate: (rate, isViewDeleted) => {
    dispatch(allForRateActionSagas(rate, isViewDeleted));
  },
  obtainAllAssigned: (rate) => {
    dispatch(allAssignedReferencesActionSagas(rate));
  },
  obtainNotAssignedToBib: (rate, search) => {
    dispatch(notAssignedToBibActionSagas(rate, search));
  },
  obtainArchived: (rate, search) => {
    dispatch(archivedReferencesActionSagas(rate, search));
  },
  loadFilters: (userId) => {
    dispatch(loadFilters(userId));
  },
  createFilter: (filter) => {
    dispatch(createFilter(filter));
  },
  editFilter: (filter) => {
    dispatch(editFilter(filter));
  },
  setActiveFilter: (filter) => {
    dispatch(setActiveFilter(filter));
  },
  deleteFilter: (filterId) => {
    dispatch(deleteFilter(filterId));
  },
  setHasMoreData: (value) => {
    dispatch(hasMoreData(value));
  },
  setReferences: (value) => {
    dispatch(setReferences(List(value)));
  },
  onMoveChapters: (chapter, other) => {
    dispatch(moveChapterSaga(chapter, other));
  },
  onExpandAll: (expandAll) => {
    dispatch(onExpandAll(expandAll));
  },
  loadQuestionsOfChapter: (id, page, rows, assignment) => {
    if (id) {
      dispatch(loadQuestionsOfChapter(id, page, rows, assignment));
    }
  },
  onClearReferences: () => {
    dispatch(setReferences(List([])));
  },
  setSearchText: (text) => {
    dispatch(setSearchText(text));
  },
  setSelectedRate: (rate) => {
    dispatch(setSelectedRate(rate));
  },
  loadChapterQuestionsCSV: (chapterId, ByDetail, isisAssignment) => {
    dispatch(loadChapterQuestionsCSV(chapterId, ByDetail, isisAssignment));
  },
  getUrlPdf: (url) => {
    dispatch(getUrlPdf(url));
  },
  onClearChapterQuestions: () => {
    dispatch(setQuestionsCSV(''));
  },
  showNotification: (message) => {
    dispatch(Notifications.show({ title: 'Denied', message }, 'error'));
  },
  setFinishProcessing: (value) => {
    dispatch(setFinishProcessing(value));
  },
});

export default addAbilityToOpenModal(
  connect(mapStateToProps, mapDispatchToProps)(Books),
);
