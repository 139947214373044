import { connect } from 'react-redux';
import PracticeExams from './PracticeExams';
import addAbilityToOpenModal from '../../../utilities/modalHOC';
import {
  getPracticeExamInformation,
  getPracticeExamInformationWaiting,
  getRate,
  getRank,
  getDutyState,
  getExam,
  getCycle,
  getBuildPracticeExamWaiting,
  getRemoveQuestionSuccess,
  getRemoveQuestionWaiting,
  getActivateExamWaiting,
  getActivateExamSuccess,
  getBuildAllExamsSuccess,
  getBuildAllExamsWaiting,
  getBuildAllExamsProgress,
} from '../selectors';
import {
  loadPracticeExamInformation,
  buildPracticeExam,
  removeQuestion,
  activateExam,
  buildAllExams,
  checkBuildPEProgress,
} from '../actionSagas';
import {
  setRate,
  setRank,
  setDutyState,
  setCycle,
  setExam,
  setRemoveQuestionSuccess,
  setActivateExamSuccess,
  setBuildAllExamsSuccess,
  setBuildAllExamsProgress,
  setBuildAllExamsWaiting,
} from '../actions';
import { loadRates } from '../../Rates/actionsSagas';
import { loadCycles } from '../../Cycles/actionsSagas';
import { getRates } from '../../Rates/selectors';
import { getCycles } from '../../Cycles/selectors';

const mapStateToProps = state => ({
  practiceExamInformation: getPracticeExamInformation(state),
  waiting: getPracticeExamInformationWaiting(state),
  rates: getRates(state),
  cycles: getCycles(state),
  rate: getRate(state),
  rank: getRank(state),
  dutyState: getDutyState(state),
  exam: getExam(state),
  selectedCycle: getCycle(state),
  buildPracticeExamWaiting: getBuildPracticeExamWaiting(state),
  removeQuestionSuccess: getRemoveQuestionSuccess(state),
  removeQuestionWaiting: getRemoveQuestionWaiting(state),
  activateExamSuccess: getActivateExamSuccess(state),
  activateExamWaiting: getActivateExamWaiting(state),
  buildAllExamsSuccess: getBuildAllExamsSuccess(state),
  buildAllExamsWaiting: getBuildAllExamsWaiting(state),
  progress: getBuildAllExamsProgress(state),
});

const mapDispatchToProps = dispatch => ({
  loadPracticeExamInformation: (rate, rank, dutyState) =>
    dispatch(loadPracticeExamInformation(rate, rank, dutyState)),
  buildPracticeExam: practiceExamId =>
    dispatch(buildPracticeExam(practiceExamId)),
  removeQuestion: (practiceExamId, questionId) =>
    dispatch(removeQuestion(practiceExamId, questionId)),
  activateExam: practiceExamId => dispatch(activateExam(practiceExamId)),
  loadRates: () => dispatch(loadRates(null, true)),
  loadCycles: () => dispatch(loadCycles()),
  selectOption: (rate, rank, dutyState) => {
    dispatch(setRate(rate));
    dispatch(setRank(rank));
    dispatch(setDutyState(dutyState));
  },
  setExam: exam => dispatch(setExam(exam)),
  setCycle: cycle => dispatch(setCycle(cycle)),
  setRemoveQuestionSuccess: value => dispatch(setRemoveQuestionSuccess(value)),
  setActivateExamSuccess: value => dispatch(setActivateExamSuccess(value)),
  buildAllExams: ratingExamsList => dispatch(buildAllExams(ratingExamsList)),
  setBuildAllExamsSuccess: value => dispatch(setBuildAllExamsSuccess(value)),
  setBuildAllExamsProgress: value => dispatch(setBuildAllExamsProgress(value)),
  setBuildAllExamsWaiting: value => dispatch(setBuildAllExamsWaiting(value)),
  checkBuildPEProgress: () => dispatch(checkBuildPEProgress()),
});

const PracticeExamsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PracticeExams);

export default addAbilityToOpenModal(PracticeExamsContainer);
