import './newCouponsListStyles.css';

import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import DropDown from '../../../commonComponents/DropDown/DropDown';
import EditCouponModal from './EditCouponModal';
import SubcodeModal from './SubcodeModal';

class couponDetail extends React.Component {
  state = {
    search: '',
    isActive: false,
    getCoupon: true,
    name: '',
    description: '',
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.deleteCoupon) {
      this.props.loadNewCoupons();
    }
    if (this.props.couponDetail !== nextProps.couponDetail) {
      this.props.loadSubcodes(nextProps.couponDetail);
    }
  }

  componentDidMount() {
    this.props.loadSubcodes(this.props.couponDetail);
  }

  handleCoupon(coupon, value) {
    if (value === 'Delete') {
      this.props.deleteDisableCoupon(coupon, value);
    }
    if (value === 'Disable') {
      this.props.deleteDisableCoupon(coupon, value);
    }
    if (value === 'Enable') {
      this.props.deleteDisableCoupon(coupon, value);
    }
  }

  renderMenu = (coupon, isNCCouponsAvailable, isECCouponsAvailable) => (
    <Col className="dropContainer">
      <DropDown
        options={[
          {
            name: 'Edit',
            event: () => this.openEditModal(coupon),
          },
          {
            name: 'Subcode List',
            event: () =>
              this.openSubcodeModal(
                coupon,
                isNCCouponsAvailable,
                isECCouponsAvailable,
              ),
          },
          {
            name: 'Delete',
            event: () => this.handleCoupon(coupon.name, 'Delete'),
          },
          {
            name: !coupon.enabled ? 'Enable' : 'Disable',
            event: () =>
              this.handleCoupon(
                coupon.name,
                !coupon.enabled ? 'Enable' : 'Disable',
              ),
          },
        ]}
        title="Coupon"
        style={{
          position: 'relative',
        }}
      />
    </Col>
  );

  openEditModal = (coupon) => {
    this.props.openModal({
      modalContent: (
        <EditCouponModal
          coupon={coupon}
          onCancel={() => this.props.closeModal()}
          onSubmit={(updatedCoupon) =>
            this.props.editCoupon(updatedCoupon, coupon.name)
          }
        />
      ),
    });
  };

  openSubcodeModal = (coupon, isNCCouponsAvailable, isECCouponsAvailable) => {
    this.props.openModal({
      modalContent: (
        <SubcodeModal
          coupon={coupon}
          onCancel={() => this.props.closeModal()}
          onSubmit={() => this.props.createSubcodes(coupon.name)}
          isNCCouponsAvailable={isNCCouponsAvailable}
          isECCouponsAvailable={isECCouponsAvailable}
        />
      ),
    });
  };

  renderDetailLine = (name, value, enabled) => (
    <div className={enabled ? 'couponDate' : 'couponDate disable'}>
      <div>
        <div className="containerTitle">
          <b>{name}</b>: <span>{value}</span>
        </div>
      </div>
      <div className="separatorLine" />
    </div>
  );

  renderTitleLine = (name, value) => (
    <div>
      <div className="titleContainer">
        <div>
          <b>{name}</b>: <span>{value}</span>
        </div>
      </div>
      <div className="separatorLine" />
    </div>
  );

  renderPlanCard = (coupon) => {
    const { plansSubscriptions } = this.props;
    if (!plansSubscriptions.length) return;
    let plan = plansSubscriptions.find((p) => p.id === coupon.planId);
    if (!plan) return;
    plan = plan.product.name;

    return (
      <Col sm={12} md={6} lg={4} key={coupon.id}>
        <div
          className={coupon.enabled ? 'detailCoupon' : 'detailCoupon disable'}
        >
          <div className="cardCouponBorder">
            <div>
              {this.renderTitleLine('Plan', plan)}
              {this.renderDetailLine(
                'Stripe code',
                coupon.stripeCode,
                coupon.enabled,
              )}
              {coupon.amountOff
                ? this.renderDetailLine(
                    'Amount Off',
                    `$${coupon.amountOff}`,
                    coupon.enabled,
                  )
                : ''}
              {coupon.percentageOff
                ? this.renderDetailLine(
                    'Percentage Off',
                    `${coupon.percentageOff}%`,
                    coupon.enabled,
                  )
                : ''}
              {this.renderDetailLine(
                'Duration',
                `${coupon.duration}`,
                coupon.enabled,
              )}
              {this.renderDetailLine(
                'Duration in months',
                `${
                  coupon.durationInMonths
                    ? `${coupon.durationInMonths} months`
                    : 'One month'
                }`,
                coupon.enabled,
              )}
              {this.renderDetailLine(
                'Times redeemed',
                `${coupon.timesRedeemed ? coupon.timesRedeemed : 0}`,
                coupon.enabled,
              )}
            </div>
          </div>
        </div>
      </Col>
    );
  };

  render() {
    const { couponDetail, newCoupons = [] } = this.props;

    const couponsNewCustomers = [];
    const couponsExisitingCustomers = [];
    const filteredCoupons = newCoupons.filter((c) => c.name === couponDetail);
    filteredCoupons.forEach((c) => {
      c.appliedToNewCustomers
        ? couponsNewCustomers.push(c)
        : couponsExisitingCustomers.push(c);
    });
    const isNCCouponsAvailable =
      couponsNewCustomers && couponsNewCustomers.length > 0;
    const isECCouponsAvailable =
      couponsNewCustomers && couponsExisitingCustomers.length > 0;
    return (
      <div>
        {newCoupons && filteredCoupons && filteredCoupons.length !== 0 && (
          <div className="contentCenter">
            <div className="cardDetail">
              <div className="coupon-header">
                <Row className="centerHeader">
                  <Row className="ml-1">
                    <div className="mr-2">
                      <b>Coupon</b>:<span> {filteredCoupons[0].name}</span>
                      {filteredCoupons[0].subcodes &&
                      filteredCoupons[0].subcodes.length ? (
                        <div className="has-subcode">
                          <i>This coupon has a subcode list</i>
                        </div>
                      ) : null}
                    </div>
                  </Row>
                  {this.renderMenu(
                    filteredCoupons[0],
                    isNCCouponsAvailable,
                    isECCouponsAvailable,
                  )}
                </Row>
              </div>
              <Row>
                <Col md={12}>
                  <div className="coupon-details-container">
                    <div className="coupon-details-table">
                      <Row>
                        <Col md={6}>
                          <div className="mt-1">
                            <b>Name</b>:<span> {filteredCoupons[0].name}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mt-1">
                            <b>Description</b>:
                            <span> {filteredCoupons[0].description}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mt-1">
                            <b>Max Redemptions</b>:
                            <span> {filteredCoupons[0].maxRedeptions}</span>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mt-1">
                            <b>Expiry at</b>:
                            <span>
                              {' '}
                              {moment(
                                Number(filteredCoupons[0].expiryAt),
                              ).format('L')}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="cardsCenter">
                    <div>
                      {couponsNewCustomers.length > 0 && (
                        <b>New Customers Coupons </b>
                      )}
                      <div className="centerRow">
                        {filteredCoupons.map((coupon, i) => {
                          if (coupon.appliedToNewCustomers) {
                            return this.renderPlanCard(coupon);
                          }
                        })}
                      </div>
                    </div>
                    <div>
                      {couponsExisitingCustomers.length > 0 && (
                        <b>Existing Customers Coupons </b>
                      )}
                      <div className="centerRow">
                        {filteredCoupons.map((coupon, i) => {
                          if (!coupon.appliedToNewCustomers) {
                            return this.renderPlanCard(coupon);
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default couponDetail;
