import {
  getErrors,
  getLoading,
  getMessage,
  getSuccessful,
} from '../components/Login/selectors';

import ForgotPasswordView from '../components/User/ForgotPassword/forgotPasswordView.jsx';
import { connect } from 'react-redux';
import {
  requestPasswordAction,
  setMessage,
} from '../components/Admins/actions';
import { verifyLoggedAdmin } from '../utilities/verifyUserLogged';

const mapStateToProps = state => ({
  errors: getErrors(state),
  successful: getSuccessful(state),
  message: getMessage(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = dispatch => {
  return {
    requestPassword: email => {
      dispatch(requestPasswordAction(email));
    },
    verifyLoggedAdmin: () => {
      verifyLoggedAdmin();
    },
    setMessage: value => {
      dispatch(setMessage(value));
    },
  };
};

const ForgotPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordView);

export default ForgotPasswordContainer;
