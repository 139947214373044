import * as type from './actionTypesSagas';

export const submitBooksSearch = (query, separatedTags, count, more) => {
  return {type: type.SUBMIT_BOOKS_SEARCH,
    query,
    count,
    separatedTags,
    more
  };
};

export const submitChaptersSearch = (query, separatedTags, count, more) => {
  return {type: type.SUBMIT_CHAPTERS_SEARCH,
    query,
    count,
    separatedTags,
    more
  };
};

export const submitQuestionsSearch = (query, separatedTags, count, more) => {
  return {type: type.SUBMIT_QUESTIONS_SEARCH,
    query,
    count,
    separatedTags,
    more
  };
};