import Notifications from 'react-notification-system-redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { closeModal } from '../../containers/Full/actions';
import * as api from '../../utilities/ServiceManager';
import {
  addGroup,
  removeGroup,
  setAdmins,
  setConsultDeleteResponse,
  setGroupsForStudyProgram,
  setRolesForRates,
  waiting,
} from './actions';
import {
  ADD_MEMBER_IN_GROUP,
  CONSULT_DELETE,
  DELETE_GROUP,
  LOAD_ALL_ADMINS,
  LOAD_GROUPS_FOR_STUDY_PROGRAM,
  LOAD_ROLES_FOR_RATES,
  UPDATE_PRIMARY_WRITER,
} from './actionTypesSagas';

/** **************************************************************************
 * LOAD ROLES FOR RATES
 */
function* loadRolesForRatesSaga() {
  yield takeLatest(LOAD_ROLES_FOR_RATES, callLoadRolesForRates);
}

function* callLoadRolesForRates() {
  try {
    yield put(waiting(true));
    const roles = yield call(api.getRolesForRates);

    yield put(setRolesForRates(roles));
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not load roles. Please try again later.',
    );
  } finally {
    yield put(waiting(false));
  }
}

/** **************************************************************************
 * LOAD GROUPS FOR STUDY PROGRAM
 */
function* loadGroupsForStudyProgramSaga() {
  yield takeLatest(
    LOAD_GROUPS_FOR_STUDY_PROGRAM,
    callLoadGroupsForStudyProgram,
  );
}

function* callLoadGroupsForStudyProgram({ stype, typeId }) {
  try {
    yield put(waiting(true, 'groupsForStudyProgram'));
    yield put(setGroupsForStudyProgram([]));

    const groups = yield call(api.getGroupsForStudyProgram, stype, typeId);

    yield put(setGroupsForStudyProgram(groups));
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not load groups for the study program. Please try again later.',
    );
  } finally {
    yield put(waiting(false, 'groupsForStudyProgram'));
  }
}

/** **************************************************************************
 * DELETE GROUP
 */
function* deleteGroupSaga() {
  yield takeLatest(DELETE_GROUP, callDeleteGroup);
}

function* callDeleteGroup({ groupId }) {
  try {
    yield put(waiting(true));

    yield call(api.deleteGroup, groupId);
    yield put(removeGroup(groupId));

    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Member removed successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not remove the group. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
  }
}

/** **************************************************************************
 * ADD MEMBER IN GROUP
 */
function* loadAllAdminsSaga() {
  yield takeLatest(ADD_MEMBER_IN_GROUP, callLoadAllAdmins);
}

function* callLoadAllAdmins({ fields, rate, role, typeSP }) {
  try {
    yield put(waiting(true));
    const group = yield call(
      api.createGroup,
      fields.user,
      rate.id,
      role.id,
      typeSP,
    );
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Member added successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );

    yield put(addGroup(group));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not add member. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
  }
}

/** **************************************************************************
 * LOAD ALL ADMINS
 */
function* addMemberInGroupSaga() {
  yield takeLatest(LOAD_ALL_ADMINS, callAddMemberInGroup);
}

function* callAddMemberInGroup() {
  try {
    const admins = yield call(api.loadAllAdmins);

    yield put(setAdmins(admins));
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not remove the group. Please try again later.',
    );
  } finally {
    yield put(waiting(false));
  }
}

/** **************************************************************************
 * CONSULT DELETE
 */
function* consultDeleteSaga() {
  yield takeLatest(CONSULT_DELETE, consultDelete);
}

function* consultDelete(action) {
  try {
    const response = yield call(api.consultDelete, action.group.id);
    yield put(setConsultDeleteResponse(response));
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not remove user from group. Please try again later.',
    );
  } finally {
    yield put(waiting(false));
  }
}

/** **************************************************************************
 * UPDATE PRIMARY WRITER
 */
function* updatePrimaryWriterSaga() {
  yield takeLatest(UPDATE_PRIMARY_WRITER, updatePrimaryWriter);
}

function* updatePrimaryWriter(action) {
  try {
    yield call(
      api.updatePrimaryWriterForAssignment,
      action.writerToDelete,
      action.writer,
    );
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Member deleted successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    yield put(removeGroup(action.writerToDelete));
    yield put(closeModal());
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not update the primary writer. Please try again later.',
    );
  } finally {
    yield put(waiting(false));
  }
}

/** **************************************************************************
 * DEFAUL EXPORT
 *************************************************************************** */
export default function* sagas() {
  yield all([
    loadRolesForRatesSaga(),
    loadGroupsForStudyProgramSaga(),
    deleteGroupSaga(),
    addMemberInGroupSaga(),
    loadAllAdminsSaga(),
    consultDeleteSaga(),
    updatePrimaryWriterSaga(),
  ]);
}

/** **************************************************************************
 * PRIVATE FUNCTIONS
 */
function showNotificationErrorFromException(
  exception,
  defaultMessage = 'Something went wrong, please try again.',
) {
  let message = defaultMessage;

  if (
    exception &&
    exception.response &&
    exception.response.data &&
    exception.response.data.error &&
    exception.response.data.error.code &&
    exception.response.data.error.code !== 'UsageError' &&
    exception.response.data.error.message
  ) {
    message = exception.response.data.error.message;
  }

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
