import React from 'react';
import Button from 'react-bootstrap-button-loader';
import { Row, Col } from 'react-bootstrap';
import { SimpleInput, SimpleTextarea } from '../../Input/Input';
import './CreateCodeGroup.css';
import { SimpleDatePicker } from '../../Datepicker/Datepicker';
import moment from 'moment';

export default class CreateCodeGroup extends React.Component {
  state = {
    description: this.props.codeGroup ? this.props.codeGroup.description : '',
    numberOfCodes: this.props.codeGroup
      ? this.props.codeGroup.codes
        ? this.props.codeGroup.codes
        : this.props.codeGroup.couponCodes
          ? this.props.codeGroup.couponCodes.length
          : 0
      : 0,
    expiryAt: this.props.codeGroup
      ? Number(this.props.codeGroup.expiryAt)
      : null,
    errors: null,
    waiting: false,
    rate: this.props.codeGroup ? this.props.codeGroup.rate : null,
  };

  validate = () => {
    let errors = {};
    let fields = this.state;

    if (!fields.description || fields.description === '') {
      errors.description = 'This field is required';
    }

    if (!fields.rate) {
      errors.rate = 'This field is required';
    }

    if (!fields.numberOfCodes || Number(fields.numberOfCodes) < 1) {
      errors.numberOfCodes = "Number of codes can't be 0";
    } else {
      fields.numberOfCodes = Number(fields.numberOfCodes);
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    if (fields.expiryAt) {
      fields.expiryAt = moment(fields.expiryAt).valueOf();
    }

    if (this.props.codeGroup) {
      fields.id = this.props.codeGroup.id;
    }

    this.setState({ errors: null, waiting: true });
    delete fields.errors;
    fields.coupon = this.props.coupon.id;
    this.props.onSubmit(fields);
  };

  render() {
    const { onCancel, title, rates } = this.props;
    return (
      <div className="create-code-group-component">
        <div className="card-block">
          <div className="createChapterTitle">
            {title}
            <i
              className="fa fa-times fa-xs"
              onClick={() => this.props.onCancel()}
            />
            <hr style={{ width: '600px', marginLeft: '-15px' }} />
          </div>
          <Row>
            <Col md={4}>
              <span className="InputLebel">Where are you sending them</span>
            </Col>
            <Col md={8}>
              <SimpleTextarea
                type="text"
                value={this.state.description}
                onChange={e => {
                  this.setState({ description: e.target.value });
                }}
                error={
                  this.state.errors && this.state.errors.description ? (
                    this.state.errors.description
                  ) : null
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="InputLebel">How many?</span>
            </Col>
            <Col md={8}>
              <SimpleInput
                type="number"
                value={this.state.numberOfCodes}
                onChange={e => {
                  this.setState({ numberOfCodes: e.target.value });
                }}
                error={
                  this.state.errors && this.state.errors.numberOfCodes ? (
                    this.state.errors.numberOfCodes
                  ) : null
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="InputLebel">Rate</span>
            </Col>
            <Col md={8}>
              <div className="form-group">
                <div className="input-group mb-3">
                  <select
                    required={true}
                    value={this.state.rate}
                    onChange={e => {
                      this.setState({ rate: e.target.value });
                    }}
                  >
                    <option />
                    {rates.map(rate => (
                      <option
                        key={`referenceForChapter${rate.id}`}
                        value={rate.id}
                      >
                        {rate.shortname}
                      </option>
                    ))}
                  </select>
                </div>
                {this.state.errors &&
                this.state.errors.rate && (
                  <span className="errors">{this.state.errors.rate}</span>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="InputLebel">Redeem by</span>
            </Col>
            <Col md={8}>
              <SimpleDatePicker
                date={this.state.expiryAt ? moment(this.state.expiryAt) : null}
                onChange={expiryAt => {
                  this.setState({ expiryAt });
                }}
                placeholderText="MM/DD/YYYY"
              />
            </Col>
          </Row>
          <hr style={{ width: '600px', marginLeft: '-15px' }} />
          <div className="bottom-buttons-new">
            <Button
              id="btn-success"
              className="btn btn-success"
              onClick={this.validate}
              loading={this.state.waiting}
            >
              Submit
            </Button>
            <Button className="btn btn-secondary" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
