import { Map } from 'immutable';
import _ from 'underscore';

import * as t from './actionsTypes';

const initialState = Map({
  rates: [],
  rate: {},
  submittedQuestions: [],
  isLoadingSubmittedQuestions: false,
  favoriteQuestions: [],
  isLoadingFavoriteQuestions: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case t.SET_ALL_RATES:
      return state.set('rates', action.rates);

    case t.SET_RATE:
      return state.set('rate', action.rate);

    case t.SET_USER_SUBMITTED_QUESTIONS:
      return state.set('submittedQuestions', action.questions);

    case t.SET_IS_LOADING_SUBMITTED_QUESTIONS:
      return state.set('isLoadingSubmittedQuestions', action.status);

    case t.SET_USER_FAVORITE_QUESTIONS: {
      const newQuestions = action.questions;
      if (Array.isArray(newQuestions)) {
        if (newQuestions.length === 0 && action.isFirst) {
          return state
            .set('favoriteQuestions', [])
            .set('isMoreFavoriteQuestions', false);
        }
        if (newQuestions.length === 0) {
          return state.set('isMoreFavoriteQuestions', false);
        }
        if (action.isFirst) {
          return state
            .set('favoriteQuestions', newQuestions)
            .set('isMoreFavoriteQuestions', true);
        }

        const questions = state.get('favoriteQuestions');
        if (Array.isArray(questions)) {
          newQuestions.forEach((newQ) => {
            if (!questions.some((q) => q.id === newQ.id)) {
              questions.push(newQ);
            }
          });
          return state
            .set('favoriteQuestions', questions)
            .set('isMoreFavoriteQuestions', true);
        }
      }
      return state;
    }

    case t.SET_IS_LOADING_FAVORITE_QUESTIONS:
      return state.set('isLoadingFavoriteQuestions', action.status);

    default:
      return state;
  }
}
