export default class ArrayUtils {
  // Use - examples:
  // var compareNames = propComparator('name');
  // var compareFoos = propComparator('foo');
  //  -- now the keys name or foo are ready for use as sorting algos
  // arr.sort(compareNames);

  static propComparator(key) {
    return function compareString(a, b) {
      // Use toUpperCase() to ignore character casing
      const keyA = a[key].toUpperCase();
      const keyB = b[key].toUpperCase();

      let comparison = 0;
      if (keyA > keyB) {
        comparison = 1;
      } else if (keyA < keyB) {
        comparison = -1;
      }
      return comparison;
    };
  }
}
