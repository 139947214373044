import '../GroupsList/GroupsList.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  getStatusByName,
  TablesStatusFilters,
} from '../../../../constants/assignments';
import QuestionsContainer from '../../../Questions/components/QuestionsContainer';
import ReviewHistoryContainer from '../../../ReviewHistory/components/ReviewHistoryContainer';
import AssignemntsContainer from '../Assignemnts/AssignemntsContainer';
import StatusFiltersContainer from '../StatusFilters/StatusFiltersContainer';

export default class MyAssignments extends React.Component {
  componentWillMount() {
    this.props.loadMyAssignments(this.props.selectedSP);
    this.props.onSelectAdmin(this.props.user, this.props.selectedSP);
  }

  componentWillUnmount() {
    this.props.setMyStudyPrograms([]);
    this.props.setLoadingMySP(null);
  }

  componentWillReceiveProps({
    assignments,
    selectedSP,
    isBackFromQuestion,
    adminScroll,
    selectedAssignment,
  }) {
    if (assignments.length > 0) {
      const spId = selectedSP ? selectedSP.id : 0;
      const status = TablesStatusFilters.concat(['Completed']);
      const spAssignemnts = assignments.filter((a) => a.studyProgram === spId);
      const counters = status.map((status) => {
        let name = getStatusByName(
          status === 'Awaiting approval' ? 'Submitted' : status,
        );
        name = name === 'Completed' ? 'Published' : name;
        return {
          name,
          count: spAssignemnts.filter((a) => a.status === name).length,
        };
      });
      this.props.setAssignmentsCount(counters);
    }
  }

  componentDidUpdate() {
    const { isBackFromQuestion, selectedSP, selectedAssignment } = this.props;
    if (isBackFromQuestion && !selectedAssignment && selectedSP) {
      const scrollTo = document.getElementById(
        `my-assignments-rates-${selectedSP.id}`,
      );
      if (scrollTo) {
        scrollTo.scrollIntoView();
        this.props.setIsBackFromQuestion(false);
      }
    }
  }

  getMyStudyPrograms = () => {
    const { selectedSP, onSelectAdmin, selectedAdmin, setAdminScroll } =
      this.props;

    let { myStudyPrograms } = this.props;

    if (myStudyPrograms.length === 0) {
      return 'No assignments to show';
    }
    if (myStudyPrograms.length !== 0) {
      // array of rates
      let ratesArray = [];

      myStudyPrograms.forEach((item) => {
        if (item.shortname) {
          ratesArray.push(item);
        }
      });

      ratesArray = ratesArray.sort((a, b) => {
        let first = '';
        let second = '';
        first = a.shortname.toString();
        second = b.shortname.toString();

        return first.localeCompare(second);
      });

      // quals
      let qualsArray = [];

      myStudyPrograms.forEach((item) => {
        if (!item.shortname) {
          qualsArray.push(item);
        }
      });

      qualsArray = qualsArray.sort((a, b) => {
        let first = '';
        let second = '';
        first = a.name.toString();
        second = b.name.toString();

        return first.localeCompare(second);
      });

      myStudyPrograms = ratesArray.concat(qualsArray);
    }
    return myStudyPrograms.map((sp) => (
      <div
        id={`my-assignments-rates-${sp.id}`}
        key={`my_assignments_${sp.id}`}
        className={
          selectedSP && selectedSP.id === sp.id ? 'mySP active' : 'mySP'
        }
        onClick={() => {
          onSelectAdmin(selectedAdmin, sp);
          setAdminScroll(window.jQuery('.myAssignmentList').scrollTop());
        }}
      >
        {sp.shortname && `(${sp.shortname}) `}
        {sp.name}
        <div>
          <span>{sp.count > 0 && sp.count}</span>
        </div>
      </div>
    ));
  };

  getQuestionComponent = () => {
    if (
      this.props.isQuestionOverview &&
      this.props.selectedAssignment.status === 'Submitted'
    ) {
      return (
        <ReviewHistoryContainer
          assignment={this.props.selectedAssignment}
          isQual={this.props.selectedSP.type === 'qual'}
        />
      );
    }
    return (
      <QuestionsContainer
        assignment={this.props.selectedAssignment}
        selectedRate={this.props.selectedSP.id}
        selectedRateObject={this.props.selectedSP}
        isQual={this.props.selectedSP.type === 'qual'}
        isMyAssignments
      />
    );
  };

  render() {
    return this.props.selectedAssignment ? (
      this.getQuestionComponent()
    ) : (
      <Row style={{ height: '100%' }}>
        <Col
          xs={6}
          md={3}
          ref="AssignmentList"
          className="GroupList myAssignmentList"
        >
          <Row className="GroupRow">
            <div className="globalCountsContainer">
              <div className="countsContainer">
                <div className="count activeTab">All</div>
              </div>
            </div>
            <div className="mySPContainer">{this.getMyStudyPrograms()}</div>
          </Row>
        </Col>
        {!this.props.isLoadinMySP && this.props.isLoadinMySP !== null && (
          <Col
            md={9}
            style={{
              flex: 1,
              paddingRight: 0,
              paddingLeft: 0,
              height: '90%',
              overflow: 'auto',
            }}
          >
            <StatusFiltersContainer />
            <AssignemntsContainer />
          </Col>
        )}
      </Row>
    );
  }
}
