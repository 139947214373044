import * as type from './actionTypesSagas';

export const udpateReferenceActionSagas = (
  fields,
  referenceId,
  activeFilter,
  isQuestionPage = false
) => ({
  type: type.UPDATE_REFERENCE_SAGAS,
  fields,
  referenceId,
  activeFilter,
  isQuestionPage,
});

export const deleteReferenceActionSagas = reference => ({
  type: type.DELETE_REFERENCE_SAGAS,
  reference,
});

export const changeStatusActionSagas = reference => ({
  type: type.CHANGE_STATUS_SAGAS,
  reference,
});

export const archiveActionSagas = (reference, filter) => ({
  type: type.ARCHIVE_SAGAS,
  reference,
  filter,
});

export const deletePdfActionSagas = reference => ({
  type: type.DELETE_PDF_SAGAS,
  reference,
});

export const copyReferenceActionSagas = fields => ({
  type: type.COPY_REFERENCE_SAGAS,
  fields,
});

export const assignedToBibActionSagas = (rate, rank, dutyState, search) => ({
  type: type.ASSIGNED_TO_BIB_SAGAS,
  rate,
  rank,
  dutyState,
  search,
});

export const notAssignedToBibActionSagas = (rate, search) => ({
  type: type.NOT_ASSIGNED_TO_BIB_SAGAS,
  rate,
  search,
});

export const archivedReferencesActionSagas = (rate, search) => ({
  type: type.ARCHIVED_REFERENCES_SAGAS,
  rate,
  search,
});

export const allForRateActionSagas = (rate, isViewDeleted) => ({
  type: type.ALL_FOR_RATE_SAGAS,
  rate,
  isViewDeleted,
});

export const allAssignedReferencesActionSagas = rate => ({
  type: type.ALL_ASSIGNED_SAGAS,
  rate,
});

export const deleteChapterActionSagas = (
  reference,
  chapter,
  presentationIndex,
  forBooks = false
) => ({
  type: type.DELETE_CHAPTER_SAGAS,
  reference,
  chapter,
  presentationIndex,
  forBooks,
});

export const deleteAllQuestionsActionSagas = chapter => ({
  type: type.DELETE_ALL_QUESTIONS_SAGAS,
  chapter,
});

export const changeStatusChapterActionSagas = chapter => ({
  type: type.CHAPTER_STATUS_SAGAS,
  chapter,
});

export const deletePdfChapterActionSagas = (chapter, forQuestion = false) => ({
  type: type.DELETE_PDF_CHAPTER_SAGAS,
  chapter,
  forQuestion,
});

export const setManualRedSubtopicsSagas = (chapter, reason) => ({
  type: type.SET_MANUAL_RED_SUBTOPICS_SAGAS,
  chapter,
  reason,
});

export const setManualRedQuestionsSagas = (chapter, reason) => ({
  type: type.SET_MANUAL_RED_QUESTIONS_SAGAS,
  chapter,
  reason,
});

export const removeManualRedQuestionsSagas = chapter => ({
  type: type.REMOVE_MANUAL_RED_QUESTIONS_SAGAS,
  chapter,
});

export const removeManualRedSubtopicsSagas = chapter => ({
  type: type.REMOVE_MANUAL_RED_SUBTOPICS_SAGAS,
  chapter,
});

export const updateChapterActionSagas = (
  fields,
  chapterId,
  forQuestion = false,
  forBooks = false
) => ({
  type: type.UPDATE_CHAPTER_SAGAS,
  fields,
  chapterId,
  forQuestion,
  forBooks,
});

export const moveChapterSaga = (chapter1, chapter2) => ({
  type: type.MOVE_CHAPTERS_SAGAS,
  chapter1,
  chapter2,
});
