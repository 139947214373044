import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import Spinner from '../../../../commonComponents/Spinner/Spinner';

export default class HistoricalUsersReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      isLoading: false,
      CSVData: [],
      completeCSV: false,
      startingDate: '',
      endingDate: '',
      selectedStartingDate: '',
      selectedEndingDate: '',
      ratesBuilt: false,
      index: 0,
      halfCustomers: false,
    };
  }

  handleDownloadReport = () => {
    this.setState({ isLoading: true, completeCSV: false });
    this.createCSVTemplate(
      `${this.state.selectedStartingDate}-01-01`,
      `${this.state.selectedEndingDate}-12-31`,
    );
    this.props.getHistoricalUsersReport(
      this.props.lastCustomer ? this.props.lastCustomer : '',
      `${this.state.selectedStartingDate}-01-01`,
      `${this.state.selectedEndingDate}-12-31`,
      this.state.halfCustomers,
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.finish && !prevProps.finish) {
      this.completeCSVData(true);
    } else if (
      prevProps.lastCustomer !== this.props.lastCustomer &&
      !this.props.finish &&
      !this.state.completeCSV
    ) {
      this.setState({ step: this.state.step + 1 });
      this.props.getHistoricalUsersReport(
        this.props.lastCustomer,
        `${this.state.selectedStartingDate}-01-01`,
        `${this.state.selectedEndingDate}-12-31`,
        this.state.halfCustomers,
      );
      if (prevProps.lastCustomer === '' && this.props.allRates) {
        this.buildRECSV(this.props.allRates);
      } else {
        this.completeCSVData(false);
      }
    }
    if (!prevState.completeCSV && this.state.completeCSV) {
      this.setState({ isLoading: false });
      document.getElementById('generate-report-download').click();
      this.props.clearProps();
      const todayDate = new Date();
      const todayYear = todayDate.getFullYear();
      this.setState({
        selectedStartingDate: 2018,
        selectedEndingDate: todayYear,
        step: 1,
        CSVData: [],
        ratesBuilt: false,
        index: 0,
        halfCustomers: false,
      });
    }
  }

  downloadCSV = () => {
    document.getElementById('generate-report-download').click();
  };

  componentDidMount() {
    const todayDate = new Date();
    const todayYear = todayDate.getFullYear();
    this.setState({
      startingDate: 2018,
      endingDate: todayYear,
      selectedStartingDate: 2018,
      selectedEndingDate: todayYear,
    });
  }

  buildRECSV = (allRates) => {
    const resAux = this.state.CSVData;
    const paygrades = [
      'E4 Regular',
      'E4 Substitute',
      'E4 Reserve',
      'E5 Regular',
      'E5 Substitute',
      'E5 Reserve',
      'E6 Regular',
      'E6 Substitute',
      'E6 Reserve',
      'E7 Regular',
      'E7 Substitute',
      'E7 Reserve',
    ];
    allRates.forEach((rate, index) => {
      resAux.push([]);
      const line = [rate];
      for (let i = 0; i < this.state.CSVData[0].length - 1; i++) {
        line.push(0);
      }
      resAux.push(line);
      paygrades.forEach((paygrade) => {
        const linePaygrade = [`${rate} ${paygrade}`];
        for (let j = 0; j < this.state.CSVData[0].length - 1; j++) {
          linePaygrade.push(0);
        }
        resAux.push(linePaygrade);
      });
    });
    this.setState({ CSVData: resAux, ratesBuilt: true });
  };

  handleRetryReport = () => {
    this.props.getHistoricalUsersReport(
      this.props.lastCustomer ? this.props.lastCustomer : '',
      `${this.state.selectedStartingDate}-01-01`,
      `${this.state.selectedEndingDate}-12-31`,
      this.state.halfCustomers,
    );
    this.props.clearAPIError();
  };

  handleRetryReportWithHalfCustomers = () => {
    this.props.getHistoricalUsersReport(
      this.props.lastCustomer ? this.props.lastCustomer : '',
      `${this.state.selectedStartingDate}-01-01`,
      `${this.state.selectedEndingDate}-12-31`,
      true,
    );
    if (!this.state.halfCustomers) {
      this.setState({ step: (this.state.step - 1) * 2 + 1 });
    }
    this.setState({ halfCustomers: true });
    this.props.clearAPIError();
  };

  componentWillUnmount() {
    this.props.clearProps();
  }

  completeCSVData = (finish) => {
    const result = this.state.CSVData;
    this.props.values.forEach((value, indexP) => {
      if (indexP >= this.state.index) {
        const arrayValue = value.split(' ');
        const qPos = this.state.CSVData[0].indexOf(
          `${parseInt(arrayValue[3], 10) + 1 < 10 ? '0' : ''}${
            parseInt(arrayValue[3], 10) + 1
          } ${arrayValue[4]}`,
        );
        result.forEach((row) => {
          if (row && row.length > 0 && row[0]) {
            if (row[0].length < 6) {
              if (row[0] === arrayValue[0]) {
                row[qPos] += 1;
              }
            }
            if (
              row[0] === `${arrayValue[0]} ${arrayValue[1]} ${arrayValue[2]}`
            ) {
              row[qPos] += 1;
            }
          }
        });
      }
    });
    this.setState({ CSVData: result, index: this.props.values.length });
    if (finish) {
      this.setState({ completeCSV: true });
    }
  };

  createCSVTemplate = (start, finish) => {
    const startingDate = new Date(start + 1);
    const finishDate = new Date(finish);
    const headerArray = [['']];
    for (
      let i = startingDate.getFullYear();
      i <= finishDate.getFullYear();
      i++
    ) {
      let month = 0;
      if (i === startingDate.getFullYear()) {
        month = startingDate.getMonth();
      }
      for (let j = month; j < 12; j++) {
        headerArray[0].push(`${j + 1 < 10 ? '0' : ''}${j + 1} ${i}`);
      }
    }
    this.setState({ CSVData: headerArray });
  };

  handleChangeStartingDate = (value) => {
    this.setState({ selectedStartingDate: value });
    if (value > this.state.selectedEndingDate) {
      this.setState({ selectedEndingDate: value });
    }
  };

  handleChangeEndingDate = (value) => {
    this.setState({ selectedEndingDate: value });
  };

  render() {
    const options = [];
    for (let i = this.state.startingDate; i <= this.state.endingDate; i++) {
      options.push(i);
    }
    const optionsEnd = [];
    for (
      let i = parseInt(this.state.selectedStartingDate, 10);
      i <= parseInt(this.state.endingDate, 10);
      i++
    ) {
      optionsEnd.push(i);
    }
    return (
      <div>
        {this.state.isLoading && !this.props.historicalReportError ? (
          <Spinner
            text={
              this.state.step === 1 ? 'Processing' : `Step ${this.state.step}`
            }
          />
        ) : (
          <div>
            {this.props.historicalReportError ? (
              <Row className="p-3">
                <div className="alert alert-danger w-100">
                  There was an error connecting with the API. Refresh the page
                  to try again or click in Retry button to continue with the
                  process
                </div>
                <div
                  className="whiteButtons"
                  onClick={() => this.handleRetryReport()}
                >
                  Retry
                </div>
                <div
                  style={{ width: 'auto' }}
                  className="whiteButtons p-3"
                  onClick={() => this.handleRetryReportWithHalfCustomers()}
                >
                  Retry with less customers
                </div>
                <div className="alert alert-info mt-4 w-100">
                  Retry with less customers will retry with less customers but
                  more steps. This is a good option for slow network
                </div>
              </Row>
            ) : (
              <div>
                <Row className="pt-4 justify-content-center">
                  <Col className="ml-3">
                    <label>
                      {' '}
                      Starting date
                      <select
                        className="form-control filter-width"
                        id="select-starting-date"
                        onChange={(event) =>
                          this.handleChangeStartingDate(event.target.value)
                        }
                      >
                        {options.map((year) => (
                          <option key={year}>{year}</option>
                        ))}
                      </select>
                    </label>
                  </Col>
                  <Col className="ml-3">
                    <label>
                      {' '}
                      Ending date
                      <select
                        className="form-control filter-width"
                        id="select-ending-date"
                        onChange={(event) =>
                          this.handleChangeEndingDate(event.target.value)
                        }
                      >
                        {optionsEnd.map((year) => (
                          <option
                            key={year}
                            selected={year === this.state.selectedEndingDate}
                          >
                            {year}
                          </option>
                        ))}
                      </select>
                    </label>
                  </Col>
                </Row>
                <div
                  className="whiteButtons"
                  onClick={() => this.handleDownloadReport()}
                >
                  Generate Report
                </div>
              </div>
            )}
          </div>
        )}
        <div style={{ display: 'none' }}>
          <CSVLink
            id="generate-report-download"
            filename={`historic-users-report${Date.now()}.csv`}
            data={this.state.CSVData}
          >
            Generate Report
          </CSVLink>
        </div>
      </div>
    );
  }
}
