import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';

export default class DateFromReferralProgram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
    };
  }

  componentDidMount() {
    const startDate = this.props.settings.find(
      (set) => set.key === 'REFERRAL_START_DATE',
    );
    const endDate = this.props.settings.find(
      (set) => set.key === 'REFERRAL_END_DATE',
    );
    if (startDate && startDate.value) {
      this.setState({ startDate: moment(startDate.value) });
    }
    if (endDate && endDate.value) {
      this.setState({ endDate: moment(endDate.value) });
    }
  }

  updateReferralDates = () => {
    const { startDate, endDate } = this.state;
    const dates = {
      start: startDate ? startDate.toJSON() : null,
      end: endDate ? endDate.toJSON() : startDate ? startDate.toJSON() : null,
    };
    this.props.updateReferralDates(dates);
  };

  handleReferralStart = (startDate) => {
    this.setState({ startDate });
    if (!this.state.endDate) this.setState({ endDate: startDate });
  };

  handleReferralEnd = (endDate) => {
    this.setState({ endDate });
  };

  render() {
    const { startDate, endDate } = this.state;
    const getStartDateObject = () => {
      if (startDate) {
        return moment.isMoment(startDate) ? startDate.toDate() : startDate;
      }
      return null;
    };

    const getEndDateObject = () => {
      if (endDate) {
        return moment.isMoment(endDate) ? endDate.toDate() : endDate;
      }
      return null;
    };

    const startJSDate = getStartDateObject();
    const endJSDate = getEndDateObject();
    return (
      <div>
        <div className="referral-dates">
          <div className="h4 ">Referral program Schedule</div>
          <div className="input-dates">
            <div className="input-date">
              <span className="input-label">Start date:</span>
              <DatePicker
                selected={startJSDate}
                startDate={startJSDate}
                onChange={(date) => this.handleReferralStart(date)}
                selectsStart
                endDate={endJSDate}
                placeholderText="MM/DD/YYYY"
                className="datePickerClass"
              />
            </div>
            <div className="input-date">
              <span className="input-label">End date:</span>
              <DatePicker
                selected={endJSDate}
                selectsEnd
                startDate={startJSDate}
                endDate={endJSDate}
                minDate={startJSDate}
                onChange={(date) => this.handleReferralEnd(date)}
                placeholderText="MM/DD/YYYY"
                className="datePickerClass"
              />
            </div>
          </div>
          <button
            className="btn-lg btn-primary"
            onClick={() => this.updateReferralDates()}
          >
            Update Referral Program Dates
          </button>
        </div>
      </div>
    );
  }
}
