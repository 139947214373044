import React from 'react';
import './Activity.css';
import { Row, Col } from 'react-bootstrap';

class CardRow extends React.Component {
  state = {};

  render() {
    return (
      <Row
        style={
          this.props.margin
            ? { width: '100%', marginTop: '9px' }
            : { width: '100%' }
        }
      >
        <Col
          xs={this.props.link || this.props.status ? 9 : 4}
          md={this.props.link || this.props.status ? 9 : 4}
          lg={this.props.link || this.props.status ? 9 : 4}
          style={{ paddingRight: '0px' }}
        >
          <div className="">{this.props.title}</div>
        </Col>
        <Col
          xs={this.props.link || this.props.status ? 3 : 6}
          md={this.props.link || this.props.status ? 3 : 6}
          lg={this.props.link || this.props.status ? 3 : 6}
          style={{ paddingLeft: '0px' }}
        >
          {this.props.link && (
            <div className="link" onClick={() => this.props.openActivity()}>
              {this.props.link}
            </div>
          )}
        </Col>
        <Col style={{ marginTop: '13px' }} xs={12} md={12} lg={12}>
          {this.props.content}
        </Col>

        <div className="separatorLine" />
      </Row>
    );
  }
}

export default CardRow;
