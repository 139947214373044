import { connect } from 'react-redux';
import ReferralProgram from './ReferralProgram';
import {
  loadCouponsReferral,
  createCouponReferral,
  insertCouponReferral,
} from '../../Coupons/actionsSagas';
import { setLoadingCoupons, setBadRequest } from '../../Coupons/actions';
import {
  getCouponsReferral,
  getSelectedCouponReferral,
  getLoadingCoupons,
  getBadRequest,
} from '../../Coupons/selectors';
import addAbilityToOpenModal from '../../../utilities/modalHOC';
import { getLoading, getSettings } from '../../Settings/selectors';
import { loadSettings, updateReferralDates } from '../../Settings/actionsSagas';

const mapStateToProps = state => ({
  couponsReferral: getCouponsReferral(state),
  selectedCouponReferral: getSelectedCouponReferral(state),
  loadingCoupons: getLoadingCoupons(state),
  badRequest: getBadRequest(state),
  settings: getSettings(state),
  isLoading: getLoading(state),
});

const mapDispatchToProps = dispatch => ({
  loadSettings: () => dispatch(loadSettings()),
  loadCouponsReferral: () => dispatch(loadCouponsReferral()),
  updateReferralDates: dates => dispatch(updateReferralDates(dates)),
  createCouponReferral: couponData =>
    dispatch(createCouponReferral(couponData)),
  insertCouponReferral: couponData =>
    dispatch(insertCouponReferral(couponData)),
  setLoadingCoupons: value => dispatch(setLoadingCoupons(value)),
  setBadRequest: value => dispatch(setBadRequest(value)),
});

const CouponListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferralProgram);

export default addAbilityToOpenModal(CouponListContainer);
