import './DailyQuizTable.css';

function DailyQuizTable({ dailyList }) {
  return (
    <div className="daily-table-container">
      <table className="table-daily">
        <thead className="table-daily-header">
          <tr>
            <th>Rate</th>
            <th>Rank</th>
            <th>Duty State</th>
            <th>Cycle</th>
          </tr>
        </thead>
        <tbody className="table-daily-body">
          {dailyList.map((daily, index) => (
            <tr key={index}>
              <td>{daily.rateShortName}</td>
              <td>{daily.rank}</td>
              <td>{daily.dutyState}</td>
              <td>{daily.cycle}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DailyQuizTable;
