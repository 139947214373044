import React from 'react';
import { connect } from 'react-redux';

import { getUser } from '../Login/selectors';

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

class HeaderIntercom extends React.Component {
  componentDidMount() {
    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: INTERCOM_APP_ID,
    };
    this.setIntercomSettings();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user.id !== prevProps.user.id && user.id) {
      this.setIntercomUpdate(user);
    }
  }

  setIntercomUpdate = (user) => {
    window.Intercom('update', {
      hide_default_launcher: false,
      username: user.username,
      user_hash: user.user_hash,
      user_id: user.id,
      name: `${user.name} ${user.lastName}`,
      email: user.email,
      created_at: Math.floor(user.createdAt / 1000),
      signed_up_at: Math.floor(user.createdAt / 1000),
      bluejacketeer_id: user.id,
      sentry_link: `https://sentry.io/organizations/serfe-id/issues/?project=1400609&query=user.id%3A${user.id}&statsPeriod=14d`,
    });
  };

  setIntercomSettings = () => {
    (function () {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        function l() {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.rel = 'preconnect';
          s.id = 'intercom-script-id';
          s.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`;
          const x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        }
        if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  };

  render() {
    return <div />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(HeaderIntercom);
