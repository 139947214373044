import './styles/StudyProgramsDetails.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';

import Card from '../../commonComponents/Card/Card';
import ConfirmDeletion from '../../commonComponents/Modals/ConfirmDeletion/ConfirmDeletion';
import addAbilityToOpenModal from '../../utilities/modalHOC';

class StudyProgramsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      shortname: '',
      description: '',
      type: 'rate',
      isDisabled: false,
      isAvailableForDailyQuiz: false,
      isPmkOnly: false,
      hasPassword: false,
      password: null,
      e4_regular: false,
      e4_substitute: false,
      e4_reserve: false,
      e5_regular: false,
      e5_substitute: false,
      e5_reserve: false,
      e6_regular: false,
      e6_substitute: false,
      e6_reserve: false,
      e7_regular: false,
      e7_substitute: false,
      e7_reserve: false,
    };
  }

  componentWillReceiveProps({ selectedSP }) {
    if (
      (selectedSP && selectedSP.id !== this.props.selectedSP.id) ||
      selectedSP.type !== this.props.selectedSP.type
    ) {
      if (selectedSP.id) {
        selectedSP.hasPassword = selectedSP.password;
        this.setState(selectedSP);
      } else {
        this.setState({
          name: '',
          shortname: '',
          description: '',
          type: selectedSP.type,
          isDisabled: false,
          isAvailableForDailyQuiz: !!selectedSP.isAvailableForDailyQuiz,
          id: null,
          hasPassword: false,
          password: null,
          e4_regular: false,
          e4_substitute: false,
          e4_reserve: false,
          e5_regular: false,
          e5_substitute: false,
          e5_reserve: false,
          e6_regular: false,
          e6_substitute: false,
          e6_reserve: false,
          e7_regular: false,
          e7_substitute: false,
          e7_reserve: false,
        });
      }
    }
  }

  renderContent = () => {
    const isRate = this.props.selectedSP.type === 'rate';

    const exams = [
      this.props.selectedSP.e4_regular !== undefined ? 'e4_regular' : '',
      this.props.selectedSP.e4_substitute !== undefined ? 'e4_substitute' : '',
      this.props.selectedSP.e4_reserve !== undefined ? 'e4_reserve' : '',
      this.props.selectedSP.e5_regular !== undefined ? 'e5_regular' : '',
      this.props.selectedSP.e5_substitute !== undefined ? 'e5_substitute' : '',
      this.props.selectedSP.e5_reserve !== undefined ? 'e5_reserve' : '',
      this.props.selectedSP.e6_regular !== undefined ? 'e6_regular' : '',
      this.props.selectedSP.e6_substitute !== undefined ? 'e6_substitute' : '',
      this.props.selectedSP.e6_reserve !== undefined ? 'e6_reserve' : '',
      this.props.selectedSP.e7_regular !== undefined ? 'e7_regular' : '',
      this.props.selectedSP.e7_substitute !== undefined ? 'e7_substitute' : '',
      this.props.selectedSP.e7_reserve !== undefined ? 'e7_reserve' : '',
    ];

    return (
      <Row className="cardContentWrapper" style={{ display: 'block' }}>
        <div className="form-group row">
          <div className="col-md-12">
            <input
              className="form-control"
              placeholder="Name"
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </div>
        </div>
        {isRate || this.props.selectedSP.shortname ? (
          <div className="form-group row">
            <div className="col-md-12">
              <input
                className="form-control"
                placeholder="Short name"
                value={this.state.shortname}
                onChange={(e) => this.setState({ shortname: e.target.value })}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="form-group row">
          <div className="col-md-12">
            <input
              className="form-control"
              placeholder="Description"
              value={this.state.description}
              onChange={(e) => this.setState({ description: e.target.value })}
            />
          </div>
        </div>
        <div className="form-group row mt-10">
          <div className="col-md-12">
            <input
              type="checkbox"
              value="isDisabled"
              name="isDisabled"
              checked={this.state.isDisabled}
              onChange={() => {
                this.setState({ isDisabled: !this.state.isDisabled });
              }}
            />
            <label className="checkbox-inline ml-10">Disabled</label>
          </div>
          {!isRate ? (
            <div className="col-md-12">
              <input
                type="checkbox"
                name="isAvailableForQuiz"
                checked={!this.state.isAvailableForDailyQuiz}
                onChange={() => {
                  this.setState({
                    isAvailableForDailyQuiz:
                      !this.state.isAvailableForDailyQuiz,
                  });
                }}
              />
              <label className="checkbox-inline ml-10">
                Disabled for daily quiz
              </label>
            </div>
          ) : null}
        </div>
        {isRate ? (
          <div className="form-group row">
            <div className="col-md-12">
              <input
                type="checkbox"
                value="isPmkOnly"
                name="isPmkOnly"
                checked={this.state.isPmkOnly}
                onChange={() => {
                  this.setState({ isPmkOnly: !this.state.isPmkOnly });
                }}
              />
              <label className="checkbox-inline ml-10">Not available</label>
            </div>
          </div>
        ) : (
          ''
        )}
        {isRate ? (
          <div className="form-group row">
            <div className="col-md-12">
              <input
                type="checkbox"
                value="hasPassword"
                name="hasPassword"
                checked={this.state.hasPassword}
                onChange={() => {
                  this.setState({ hasPassword: !this.state.hasPassword });
                }}
              />
              <label className="checkbox-inline ml-10">
                Remove from pricing page
              </label>
            </div>
          </div>
        ) : (
          ''
        )}
        {isRate && this.state.hasPassword ? (
          <div className="form-group row">
            <div className="col-md-12">
              <input
                className="form-control"
                placeholder="Password"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
            </div>
          </div>
        ) : (
          ''
        )}
        {isRate ? <div className="separatorLine width-auto" /> : ''}
        {isRate && !this.state.isPmkOnly && !this.state.isDisabled && (
          <div className="form-group row form-group-marginTop">
            <div className="individualExam-contianer">
              <span className="individualExam-text">
                Selecting each individual exam, it will disabled
              </span>
            </div>
            {exams.map((exam) => (
              <div className="col-md-4">
                {exam !== '' ? (
                  <input
                    type="checkbox"
                    value={exam}
                    name={exam}
                    checked={this.state[exam]}
                    onChange={() => {
                      this.setState({ [exam]: !this.state[exam] });
                    }}
                  />
                ) : (
                  ''
                )}
                <label className="checkbox-inline ml-10">
                  {exam === ''
                    ? 'No exam'
                    : exam.replace('_', ' ').substr(0, 6)}
                </label>
              </div>
            ))}
          </div>
        )}
      </Row>
    );
  };

  deleteSP = () => {
    this.props.openModal({
      modalTitle: `Deleting : ${this.props.selectedSP.name}`,
      modalContent: (
        <ConfirmDeletion
          text={<span>Enter the word DELETE to confirm deletion.</span>}
          onSubmit={() => {
            this.props.closeModal();
            this.props.onDelete(this.props.selectedSP);
          }}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  onSave = () => {
    const state = this.state;
    state.isQual = this.state.type === 'qual';
    if (!state.hasPassword) {
      state.password = null;
    }

    const filtered = {};
    for (const key in state) {
      if (key in this.props.selectedSP) {
        filtered[key] = state[key];
      }
    }
    if (filtered) {
      filtered.isQual = this.state.type === 'qual';
    }

    if (state.id) {
      this.props.onEdit(filtered);
    } else {
      this.props.onCreate(state);
    }
  };

  getCardFooter = (canEdit) => (
    <Row className="cardFooterWrapper" style={{ justifyContent: 'flex-end' }}>
      <div className="bottom-buttons">
        <Button
          type="submit"
          className="btn-success btn-success-roles"
          loading={this.props.creating || this.props.updating}
          onClick={this.onSave}
          disabled={!canEdit}
        >
          Save
        </Button>
        {this.props.selectedSP.id ? (
          <Button
            onClick={this.deleteSP}
            loading={this.props.isDeleting}
            className="btn btn-danger btn-cancel-roles"
            disabled={!canEdit}
          >
            Delete
          </Button>
        ) : (
          ''
        )}
      </div>
    </Row>
  );

  getTitle = (sp) => {
    if (sp.id) {
      return sp.name;
    }
    return `New ${sp.type === 'rate' ? 'rate' : 'qual'}`;
  };

  render() {
    const { loggedUser = {}, generalPermissions = [] } = this.props;
    const canEdit = loggedUser.isSuperAdmin
      ? true
      : generalPermissions.find((r) => r === 'Settings.EditStudyProgram');
    return (
      <Row style={{ width: '100%', margin: 0 }}>
        <Col
          style={{ padding: '20px', maxWidth: '480px', minWidth: '320px' }}
          md={9}
        >
          <Card
            title={this.getTitle(this.props.selectedSP)}
            content={this.renderContent()}
            footer={this.getCardFooter(canEdit)}
          />
        </Col>
      </Row>
    );
  }
}

export default addAbilityToOpenModal(StudyProgramsDetails);
