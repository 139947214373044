// Insights/actionTypesSagas.js
import * as t from './actionTypesSagas';

export const getAdminsActionSagas = (search, page, userId) => ({
  type: t.ADMINS_GET_SAGAS,
  search,
  page,
  userId,
});

export const getRolesActionSagas = (payload) => ({
  type: t.ROLES_GET_SAGAS,
  payload,
});

export const changeMainRoleActionSagas = (payload, change) => ({
  type: t.CHANGE_MAIN_ROLE_SAGAS,
  payload,
  change,
});

export const deleteAdminActionSagas = (id) => ({
  type: t.DELETE_ADMIN_SAGAS,
  id,
});

export const toggleActiveActionSagas = (id, currentActive) => ({
  type: t.TOGGLE_ACTIVE_SAGAS,
  id,
  currentActive,
});

export const resetPasswordUserActionSagas = (id, fields) => ({
  type: t.RESET_PASSWORD_ADMIN_SAGAS,
  id,
  fields,
});

export const updatePhoneNumberActionSagas = (id, fields) => ({
  type: t.UPDATE_PHONE_NUMBER_SAGAS,
  id,
  fields,
});

export const createAdminUserActionSagas = (fields) => ({
  type: t.CREATE_ADMIN_USER_SAGAS,
  fields,
});

export const toggleAdminRoleSagas = (admin, role) => ({
  type: t.TOGGLE_ADMIN_ROLE_SAGAS,
  admin,
  role,
});

export const updateAdminSaga = (admin) => ({
  type: t.UPDATE_ADMIN_SAGAS,
  admin,
});

export const resetPassword = (id, fields) => ({
  type: t.RESET_PASSWORD,
  id,
  fields,
});

export const updateAdminFileSaga = (admin) => ({
  type: t.UPDATE_ADMIN_FILES_SAGAS,
  admin,
});

export const getAdminRolesSaga = (admin) => ({
  type: t.GET_ADMIN_ROLES_SAGAS,
  admin,
});

export const getAdminHistorySaga = (admin, page, filter) => ({
  type: t.GET_ADMIN_HISTORY_SAGAS,
  admin,
  page,
  filter,
});

export const loginAs = (admin) => ({
  type: t.LOGIN_AS_ADMIN,
  admin,
});

export const updateAdminGroupSaga = (admin) => ({
  type: t.UPDATE_ADMIN_GROUP_SAGA,
  admin,
});

export const fetchAdminUserLogs = (admin, page) => ({
  type: t.FETCH_ADMIN_LOGS_SAGA,
  admin,
  page,
});

export const getActivity = (id) => ({
  type: t.GET_ACTIVITY,
  id,
});

export const updateSuperAdmin = (admin, value) => ({
  type: t.UPDATE_SUPER_ADMIN,
  admin,
  value,
});

export const forcePasswordChange = (id) => ({
  type: t.FORCE_PASSWORD_CHANGE,
  id,
});
