import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'quals');
}

export const getQuals = state => getState(state).get('quals');
export const getSelectedQual = state => getState(state).get('selectedQual');
export const getQualPagination = state => getState(state).get('pagination');
export const getAllStudyPrograms = state =>
  getState(state).get('allStudyPrograms');
