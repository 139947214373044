/** ********************
 * REDUX ACTION TYPES *
 ********************* */
const questionsNamespace = 'questions';
export const SET_REFERENCE = `${questionsNamespace}/SET_REFERENCE`;
export const UPDATE_WAITING = `${questionsNamespace}/UPDATE_WAITING`;
export const SET_QUESTIONS = `${questionsNamespace}/SET_QUESTIONS`;
export const UPDATE_QUESTION = `${questionsNamespace}/UPDATE_QUESTION`;
export const ADD_QUESTION = `${questionsNamespace}/ADD_QUESTION`;
export const BATCH_ADD_QUESTION = `${questionsNamespace}/BATCH_ADD_QUESTION`;
export const SET_QUESTION_CHANGE_STATE = `${questionsNamespace}/SET_QUESTION_CHANGE_STATE`;
export const SET_CHAPTER_EDIT_SUBTOPICS = `${questionsNamespace}/SET_CHAPTER_EDIT_SUBTOPICS`;
export const ADD_ALL_QUESTIONS = `${questionsNamespace}/ADD_ALL_QUESTIONS`;
export const DELETE_QUESTION = `${questionsNamespace}/DELETE_QUESTION`;
export const SET_CHAPTER = `${questionsNamespace}/SET_CHAPTER`;
export const SET_CHAPTER_AFTER_DELETE_PDF = `${questionsNamespace}/SET_CHAPTER_AFTER_DELETE_PDF`;
export const SET_IS_LOADING_QUESTIONS = `${questionsNamespace}/SET_IS_LOADING_QUESTIONS`;
export const SET_PUBLISHED_QUESTION = `${questionsNamespace}/SET_PUBLISHED_QUESTION`;
export const RESTORE_QUESTION = `${questionsNamespace}/RESTORE_QUESTION`;
export const UPDATE_WAITING_PDF = `${questionsNamespace}/UPDATE_WAITING_PDF`;
export const UPDATE_QUESTION_PDF = `${questionsNamespace}/UPDATE_QUESTION_PDF`;
export const UPDATE_SELECTED_VIEW = `${questionsNamespace}/UPDATE_SELECTED_VIEW`;
export const SET_SELECTED_QUESTION = `${questionsNamespace}/SET_SELECTED_QUESTION`;
export const SET_ON_SAVE_QUESTION = `${questionsNamespace}/SET_ON_SAVE_QUESTION`;
export const IS_REFRESH_FORM = `${questionsNamespace}/IS_REFRESH_FORM`;
export const SET_IS_SHOW_DELETED = `${questionsNamespace}/SET_IS_SHOW_DELETED`;
export const SET_URL_QUESTION = `${questionsNamespace}/SET_URL_QUESTION`;
export const SET_CURRENT_PAGE = `${questionsNamespace}/SET_CURRENT_PAGE`;
export const SET_CREATE_ANOTHER = `${questionsNamespace}/SET_CREATE_ANOTHER`;
export const UPDATE_UPDATING = `${questionsNamespace}/UPDATE_UPDATING`;
export const SET_EDIT_PERMISSION = `${questionsNamespace}/SET_EDIT_PERMISSION`;
export const SET_AMOUNT_OF_QUESTIONS_IN_CHAPTER = `${questionsNamespace}/SET_AMOUNT_OF_QUESTIONS_IN_CHAPTER`;
export const SET_QUESTIONS_TO_DOWNLOAD = `${questionsNamespace}/SET_QUESTIONS_TO_DOWNLOAD`;
export const CLEAR_QUESTIONS_TO_DOWNLOAD = `${questionsNamespace}/CLEAR_QUESTIONS_TO_DOWNLOAD`;
export const SET_IS_CREATING_AI_QUESTION = `${questionsNamespace}/SET_IS_CREATING_AI_QUESTION`;
export const SET_USER_CUSTOM_PROMPTS = `${questionsNamespace}/SET_USER_CUSTOM_PROMPTS`;
export const SET_SELECTED_QUESTIONS_FOR_DELETED = `${questionsNamespace}/SET_SELECTED_QUESTIONS_FOR_DELETED`;
export const SET_QUESTION_HISTORY = `${questionsNamespace}/SET_QUESTION_HISTORY`;
export const SET_SHOW_MODAL_QUESTION_HISTORY = `${questionsNamespace}/SET_SHOW_MODAL_QUESTION_HISTORY`;
export const SET_IMPROVE_AI_IN_QUESTION = `${questionsNamespace}/SET_IMPROVE_AI_IN_QUESTION`;
export const SET_INCLUDE_NOTES_IN_SUGGESTION = `${questionsNamespace}/SET_INCLUDE_NOTES_IN_SUGGESTION`;
