import Notifications from 'react-notification-system-redux';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import _ from 'underscore';

import addAbilityToOpenModal from '../../../utilities/modalHOC';
import {
  clearSelectedAssignment,
  onShowQuestionOverview,
  setIsBackFromQuestion,
} from '../../Assignments/actions';
import { onEditAssignment } from '../../Assignments/actionsSagas';
import {
  getSelectedAssignment,
  getSelectedRate,
} from '../../Assignments/selectors';
import { getUrlPdf } from '../../Bibliographies/actionsSagas';
import { loadChapterQuestionsCSV } from '../../Books/actionsSagas';
import {
  getQuestionsCSVbyDetail,
  getUrlPdfPrivate,
} from '../../Books/selectors';
import {
  getGeneralRolesPermissions,
  getRolesPermissions,
  getUser,
} from '../../Login/selectors';
import { setTopics } from '../../Rates/actions';
import { loadTopics } from '../../Rates/actionsSagas';
import { getTopics } from '../../Rates/selectors';
import {
  deletePdfActionSagas,
  deletePdfChapterActionSagas,
  udpateReferenceActionSagas,
  updateChapterActionSagas,
} from '../../References/actionsSagas';
import { updateChapterSubtopics } from '../../Subtopics/actionsSagas';
import {
  clearQuestionsToDownload,
  setChapter,
  setCreateAnother,
  setCurrentPage,
  setEditPermission,
  setIsCreatingAiQuestion,
  setIsSaveQuestion,
  setIsShowDeletedQuestions,
  setQuestions,
  setReference,
  setSeletedQuestionsForDeleted,
  setUrlQuestion,
  updateQuestionPdfProcessing,
  updateSelectedView,
} from '../actions';
import {
  amountOfQuestionsInChapter,
  batchCreateQuestions,
  changeStatusSagas,
  createQuestion,
  createUserCustomPrompt,
  deleteAllChaptersQuestions,
  deleteQuestionSagas,
  editQuestionSagas,
  getQuestionsForDownload,
  ImproveQuestionWithAi,
  loadChapter,
  loadQuestionsOfChapter,
  loadReference,
  onDeletePdfSagas,
  restoreQuestionSagas,
  updateIncludeNotesInSuggestion,
} from '../actionsSagas';
import {
  getAmountOfQuestions,
  getChapter,
  getCreateAnother,
  getCreatingAiQuestion,
  getCurrentPage,
  getEditPermission,
  getIsLoadingQuestions,
  getIsSaveQuestion,
  getIsShowDeletedQuestions,
  getPagination,
  getPublishedQuestion,
  getQuestionPdfProcessing,
  getQuestions,
  getQuestionsForDeleted,
  getQuestionsImmutable,
  getQuestionsToDownload,
  getReference,
  getSelectedQuestion,
  getSelectedView,
  getUpdating,
  getUserCustomPromptSelector,
  getWaiting,
  getWaitingPdf,
} from '../selectors';
import Questions from './Questions';

const mapStateToProps = (state, ownProps) => ({
  reference: ownProps.reference ? ownProps.reference : getReference(state),
  chapter: getChapter(state),
  isWaiting: getWaiting(state),
  questions: getQuestions(state),
  questionsI: getQuestionsImmutable(state),
  topics: getTopics(state),
  pagination: getPagination(state),
  isLoadingQuestions: getIsLoadingQuestions(state),
  assignment: getSelectedAssignment(state) || ownProps.assignment,
  selectedRate: ownProps.selectedRate,
  publishedQuestion: getPublishedQuestion(state),
  waitingPdf: getWaitingPdf(state),
  questionPdfProcessing: getQuestionPdfProcessing(state),
  selectedView: getSelectedView(state),
  selectedQuestion: getSelectedQuestion(state),
  loggedUser: getUser(state),
  permissions: getRolesPermissions(state),
  generalRolesPermissions: getGeneralRolesPermissions(state),
  selectedSP: getSelectedRate(state),
  isInHistoryView: ownProps.isInHistoryView,
  onApproveAssignment: ownProps.onApproveAssignment,
  onCancelAssignment: ownProps.onCancelAssignment,
  isSaveQuestion: getIsSaveQuestion(state),
  isShowDeletedQuestions: getIsShowDeletedQuestions(state),
  currentPage: getCurrentPage(state),
  createAnother: getCreateAnother(state),
  isUpdating: getUpdating(state),
  editPermission: getEditPermission(state),
  amountOfQuestions: getAmountOfQuestions(state),
  questionsToDownload: getQuestionsToDownload(state),
  urlPdfPrivate: getUrlPdfPrivate(state),
  questionsCSV: getQuestionsCSVbyDetail(state),
  isCreatingAiQuestion: getCreatingAiQuestion(state),
  userCustomPrompts: getUserCustomPromptSelector(state),
  questionsForDeleted: getQuestionsForDeleted(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUrlPdf: (url) => dispatch(getUrlPdf(url)),
  loadReference: (id) => dispatch(loadReference(id)),
  loadChapter: (id) => dispatch(loadChapter(id)),
  getQuestionsForDownload: (chapter) =>
    dispatch(getQuestionsForDownload(chapter)),
  loadQuestionsOfChapter: (
    id,
    page,
    rows,
    assignment,
    isInfiniteScroll,
    skip,
    isViewDeletedQuestions,
    loadAll = false,
    downloadCsv = null,
    deletedByAdmin = null,
  ) => {
    if (id) {
      dispatch(
        loadQuestionsOfChapter(
          id,
          page,
          rows,
          assignment,
          isInfiniteScroll,
          skip,
          isViewDeletedQuestions,
          loadAll,
          downloadCsv,
          deletedByAdmin,
        ),
      );
    }
  },
  clearQuestionsToDownload: () => {
    dispatch(clearQuestionsToDownload());
  },
  onClearQuestions: () => {
    dispatch(
      setQuestions({ data: [], pagination: { total: 0 } }, 0, 25, false),
    );
  },
  onBackToAssignments: () => dispatch(clearSelectedAssignment()),
  changeStatus: (question, assignment) =>
    dispatch(changeStatusSagas(question, assignment)),
  setCreateAnother: (value) => dispatch(setCreateAnother(value)),
  loadTopics: (reference, rate, sp) => {
    dispatch(loadTopics(reference || sp || rate, null, reference, sp));
  },
  onEdit: (question, assignment, isUpdatePresentationIndex) =>
    dispatch(editQuestionSagas(question, assignment)),
  onDeletePdf: (question, assignment) =>
    dispatch(onDeletePdfSagas(question, assignment)),
  updateQuestionPdfProcessing: (question) =>
    dispatch(updateQuestionPdfProcessing(question)),
  loadChapterQuestionsCSV: (chapterId, ByDetail, isAssignment) => {
    dispatch(loadChapterQuestionsCSV(chapterId, ByDetail, isAssignment));
  },
  onBack: (assignment, state, isMyAssignments, writers, location) => {
    dispatch(setIsBackFromQuestion(true));
    if (location && location.query && location.query.search) {
      browserHistory.push({
        pathname: '/home/search',
        state,
      });
      return;
    }
    if (assignment) {
      dispatch(onShowQuestionOverview(null, false));
      if (isMyAssignments) {
        browserHistory.push({
          pathname: '/home/wheelhouse/my-assignments',
          state,
        });
      } else {
        if (writers) {
          browserHistory.push({
            pathname: '/home/wheelhouse/writers',
            state,
          });
        } else {
          browserHistory.push({
            pathname: '/home/wheelhouse/assignments',
            state,
          });
        }
      }
    } else {
      if (state) {
        if (state.isQual) {
          browserHistory.push({ pathname: '/home/quals', state });
        } else {
          browserHistory.push({ pathname: '/home/books', state });
        }
      } else {
        browserHistory.push('/home/books');
      }
    }
    dispatch(setChapter(null));
  },
  onDeleteSubtopic: (reference, chapter, subtopics, subptopicToDelete) => {
    const checkedSubtopics = subtopics.filter(
      (s) => s.id !== subptopicToDelete.id,
    );
    dispatch(
      updateChapterSubtopics(
        chapter.reference,
        chapter.id,
        checkedSubtopics.map((s) => s.id),
      ),
    );
  },
  clearReference: () => {
    dispatch(setReference({}));
  },
  clearChapter: () => {
    dispatch(setChapter({}));
  },
  onViewChange: (view) => dispatch(updateSelectedView(view)),
  deletePdfChapter: (chapter, questionView, reference) => {
    if (
      reference &&
      reference.id &&
      !reference.actAsParent &&
      (reference.pdfUrl ||
        (reference.chapters[0] &&
          reference.chapters[0].id == chapter.id &&
          reference.chapters[0].pdfUrl))
    ) {
      dispatch(deletePdfActionSagas(reference, questionView));
      reference.pdfUrl = null;
      dispatch(setReference(reference));
    } else {
      dispatch(deletePdfChapterActionSagas(chapter, questionView));
    }
  },
  updateChapter: (fields, chapterId, forQuestions, reference) => {
    if (reference && reference.id && !reference.actAsParent && fields.pdf) {
      dispatch(udpateReferenceActionSagas(fields, reference.id, false, true));
    } else {
      dispatch(updateChapterActionSagas(fields, chapterId, forQuestions));
    }
  },
  onSave: () => {
    dispatch(setIsSaveQuestion(true));
    dispatch(setCreateAnother(false));
  },
  onSaveAndCreateAnother: () => {
    dispatch(setCreateAnother(true));
    dispatch(setIsSaveQuestion(true));
  },
  onCreateQuestion: (question, assignment) =>
    dispatch(createQuestion(question, assignment)),
  onEditAssignment: (assignment, isEditStatus, reject) => {
    dispatch(onEditAssignment(assignment, isEditStatus, reject));
  },
  clearTopics: () => {
    dispatch(setTopics({ response: { data: [] } }));
  },
  onBatchCreateQuestions: (question, assignment) => {
    dispatch(batchCreateQuestions(question, assignment));
  },
  deleteAllQuestions: (chapter, assignment) => {
    dispatch(deleteAllChaptersQuestions(chapter, assignment));
  },
  onShowNotification: (message) => {
    dispatch(Notifications.show({ title: 'Notice', message }, 'error'));
  },
  setIsViewDeletedQuestions: (isView) => {
    dispatch(setIsShowDeletedQuestions(isView));
  },
  setUrlQuestion: (question) => dispatch(setUrlQuestion(question)),
  setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
  setEditPermission: (value) => dispatch(setEditPermission(value)),
  amountOfQuestionsInChapter: (chapter) =>
    dispatch(amountOfQuestionsInChapter(chapter)),
  showWarning: (title, message) =>
    dispatch(Notifications.warning({ title, message }, 'failure')),
  setIsCreatingAiQuestion: (value) => dispatch(setIsCreatingAiQuestion(value)),
  createUserCustomPrompt: (customPrompt, chapterId) =>
    dispatch(createUserCustomPrompt(customPrompt, chapterId)),
  deleteQuestion: (question, assignment) =>
    dispatch(deleteQuestionSagas(question, assignment)),
  setSeletedQuestionsForDeleted: (question, value) =>
    dispatch(setSeletedQuestionsForDeleted(question, value)),
  restoreQuestion: (question, assignment) =>
    dispatch(restoreQuestionSagas(question, assignment)),
  improveQuestionWithAi: (questionId, chapterId) =>
    dispatch(ImproveQuestionWithAi(questionId, chapterId)),
  updateIncludeNotesInSuggestion: (questionId, value) =>
    dispatch(updateIncludeNotesInSuggestion(questionId, value)),
});

export default addAbilityToOpenModal(
  connect(mapStateToProps, mapDispatchToProps)(Questions),
);
