import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'assignments');
}

export const getAdminsByRate = (state) => getState(state).get('adminsByRate');
export const getAdminsByRateI = (state) =>
  getState(state).get('adminsByRateImmutable');
export const getAdminsByQual = (state) => getState(state).get('adminsByQual');
export const getAdminsByQualI = (state) =>
  getState(state).get('adminsByQualImmutable');
export const getActiveStatus = (state) => getState(state).get('activeStatus');
export const getSelectedAdmin = (state) => getState(state).get('selectedAdmin');
export const getAssignments = (state) => getState(state).get('assignments');
export const getSelectedRate = (state) => getState(state).get('selectedRate');
export const getIsViewRates = (state) => getState(state).get('isViewRates');
export const getSelectedAssignment = (state) =>
  getState(state).get('selectedAssignment');
export const getIsQuestionOverview = (state) =>
  getState(state).get('isQuestionOverview');
export const isChangingType = (state) => getState(state).get('isChangingType');
export const getLoading = (state) => getState(state).get('loading');
export const getAssignmentsCount = (state) =>
  getState(state).get('assignmentsCount');
export const getAwaitingApproval = (state) =>
  getState(state).get('awaitingApproval');
export const getIsReloadAdminAssignments = (state) =>
  getState(state).get('isReloadAdminAssignments');
export const getStudyProgramAdmins = (state) =>
  getState(state).get('studyProgramAdmins');
export const getNoGroups = (state) => getState(state).get('noGroups');
export const getAdminScroll = (state) => getState(state).get('adminScroll');
export const getIsBackFromQuestion = (state) =>
  getState(state).get('isBackFromQuestion');
export const getMyStudyPrograms = (state) =>
  getState(state).get('myStudyPrograms');
export const getLoadingMySP = (state) => getState(state).get('loaginMySP');
export const getWriters = (state) => getState(state).get('writers');
export const getSelectedWriter = (state) =>
  getState(state).get('selectedWriter');
export const getAssignModalWaiting = (state) =>
  getState(state).get('assignModalWaiting');
export const getAssignModalSuccess = (state) =>
  getState(state).get('assignModalSuccess');
export const getAssignModalError = (state) =>
  getState(state).get('assignModalError');
export const getAssignModalReferences = (state) =>
  getState(state).get('assignModalReferences');
export const getAssignModalWriters = (state) =>
  getState(state).get('assignModalWriters');
export const getAssignModalRates = (state) =>
  getState(state).get('assignModalRates');
export const getAssignModalSelectedRate = (state) =>
  getState(state).get('assignModalSelectedRate');
export const getAssignModalLoadingReferences = (state) =>
  getState(state).get('assignModalLoadingReferences');
export const getLoadingCreateQuestions = (state) =>
  getState(state).get('loadingCreateQuestions');
export const getLoadingSummaries = (state) =>
  getState(state).get('loadingSummaries');
export const getSummariesSelector = (state) => getState(state).get('summaries');
export const getLoadingSummary = (state) =>
  getState(state).get('loadingSummary');
export const getSummary = (state) => getState(state).get('summary');
