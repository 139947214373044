import { all, put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import {
  setWriters,
  updateWaiting,
  setChapterWriters,
  setReferences,
  setAssignment,
} from './actions';
import _ from 'underscore';
import { setAssignmentUpdate } from '../Assignments/actions';
import { addAssignmentToBook } from '../Bibliographies/actions';
import { addAssignment } from '../Assignments/actions';
import {
  LOAD_WRITERS,
  ASSIGN_WRITERS_TO_REFERENCE,
  LOAD_CHAPTER_WRITERS,
  LOAD_REFERENCES,
  CREATE_ASSIGNMENT,
  LOAD_ASSIGNMENT,
  UPDATE_ASSIGNMENT,
} from './actionTypesSagas';
import { assignWritersChapter } from '../Bibliographies/actions';
import * as api from '../../utilities/ServiceManager';
import { closeModal } from '../../containers/Full/actions';
import {
  setWriters as setWritersAssignments,
  setSelectedWriter,
} from '../Assignments/actions';

/**
 * LOAD Writers
 */
function* loadWritersSaga() {
  yield takeLatest(LOAD_WRITERS, callLoadWriters);
}

function* callLoadWriters(action) {
  try {
    const writers = yield call(api.getWriters, action.stype, action.typeId);
    yield put(setWriters(writers));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load writers. Please try again later.',
      ),
    );
  } finally {
    yield put(updateWaiting(false));
  }
}

/**
 * LOAD chapter´s secondary writers
 */
function* loadChapterWriters() {
  yield takeLatest(LOAD_CHAPTER_WRITERS, callLoadChapterWriters);
}

function* callLoadChapterWriters(action) {
  try {
    const writers = yield call(api.getChapter, action.chapter);
    yield put(setChapterWriters(writers));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load writers. Please try again later.',
      ),
    );
  }
}

/**
 * Assign writers
 */
function* assignWritersSaga() {
  yield takeEvery(ASSIGN_WRITERS_TO_REFERENCE, callAssignWriters);
}

function* callAssignWriters(action) {
  try {
    yield put(closeModal());
    let method = action.chapter
      ? api.assignWritersByChapter
      : api.assignWritersByReference;
    let params = action.chapter ? action.chapter : action.reference;
    const chapter = yield call(
      method,
      params,
      action.primaryWriter,
      action.secondaryWriters,
    );
    yield put(assignWritersChapter(chapter));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Writers assigned successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not assign writers. Please try again later.',
      ),
    );
  } finally {
    yield put(updateWaiting(false));
  }
}

/**
 * LOAD Reference of Study program
 */
function* loadReferences() {
  yield takeLatest(LOAD_REFERENCES, callLoadReferences);
}

function* callLoadReferences(action) {
  try {
    const references = yield call(
      api.getReferencesForStudyProgram,
      action.typeId,
      action.studyProgramType,
    );
    yield put(setReferences(references));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load references. Please try again later.',
      ),
    );
  } finally {
    yield put(updateWaiting(false));
  }
}

/**
 * Create Assigment
 */
function* createAssigmentSagas() {
  yield takeEvery(CREATE_ASSIGNMENT, callCreateAssigment);
}

function* callCreateAssigment(action) {
  try {
    let writers = action.secondaryWriters;
    writers.push(action.primaryWriter);
    let params = {};
    params.chapter = action.chapter;
    params.status = action.status ? action.status : 'Not_Started';
    params.createdBy = action.createdBy;
    params.assignedTo = writers;
    params.dueDate = action.dueDate;
    params.primaryWriter = action.primaryWriter;
    params.studyProgram = action.studyProgram;
    const newAssignment = yield call(api.createAssigment, params);
    yield put(closeModal());
    if (action.dontAddAssignment) {
      yield put(addAssignmentToBook(newAssignment));
    } else {
      yield put(addAssignment(newAssignment));
    }
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Assigment created successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not create assigment. Please try again later.',
      ),
    );
  } finally {
    yield put(updateWaiting(false));
  }
}

/**
 * LOAD Assignment
 */
function* loadAssignmentsaga() {
  yield takeLatest(LOAD_ASSIGNMENT, callLoadAssignment);
}

function* callLoadAssignment(action) {
  try {
    const assignment = yield call(api.getAssignment, action.assignment);
    yield put(setAssignment(assignment));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load assignment. Please try again later.',
      ),
    );
  } finally {
    yield put(updateWaiting(false));
  }
}

/**
 * Update Assigment
 */
function* updateAssignmentSagas() {
  yield takeEvery(UPDATE_ASSIGNMENT, callUpdateAssigment);
}

function* callUpdateAssigment(action) {
  try {
    let writers = action.secondaryWriters;
    writers.push(action.primaryWriter);
    let params = {};
    params.assignment = action.assignment;
    params.assignedTo = writers;
    params.primaryWriter = action.primaryWriter;
    const assignment = yield call(api.updateAssignment, params);
    yield put(setAssignmentUpdate(assignment));
    if (action.selectedWriterEmail) {
      const writersFromApi = yield call(api.getAllWriters);
      yield put(setWritersAssignments(writersFromApi));
      let writerForSelect;
      _.each(writersFromApi, (value) => {
        if (value[0].email === action.selectedWriterEmail) {
          writerForSelect = value[0];
        }
      });
      yield put(setSelectedWriter(writerForSelect));
    }
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Assigment updated successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update assigment. Please try again later.',
      ),
    );
  } finally {
    yield put(updateWaiting(false));
  }
}

/**
 * DEFAUL EXPORT
 */
export default function* sagas() {
  yield all([
    loadWritersSaga(),
    assignWritersSaga(),
    loadChapterWriters(),
    loadReferences(),
    createAssigmentSagas(),
    loadAssignmentsaga(),
    updateAssignmentSagas(),
  ]);
}

function showNotificationErrorFromException(exception) {
  let message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
