import './UsersReport.css';

import { Chart, registerables } from 'chart.js';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { CSVLink } from 'react-csv';
import Select from 'react-select';

import Spinner from '../../../../commonComponents/Spinner/Spinner';
import { getDutyStates, getRanks } from '../../../../utilities/getItemsHelper';

Chart.register(...registerables);

const TOTAL_USERS = 'TOTAL_USERS';
const ACTIVE_SUBSCRIPTIONS = 'ACTIVE_SUBSCRIPTIONS';
const CANCELED = 'CANCELED';
const NEW_SIGN_UPS = 'NEW_SIGN_UPS';
const YESTERDAY = 'YESTERDAY';
const TODAY = 'TODAY';
const SEVEN_DAYS = 'SEVEN_DAYS';
const THIRTY_DAYS = 'THIRTY_DAYS';
const MTD = 'MTD';
const ONE_YEAR = 'ONE_YEAR';
const YTD = 'YTD';
const ALL = 'ALL';

const WEEK_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const newArray = (length) => {
  const newAry = [];
  for (let i = 0; i < length; i++) {
    newAry.push(0);
  }
  return newAry;
};

const getHours = () => {
  const hours = newArray(12);
  const hourLabels = hours.map((e, index) => {
    if (index === 6) {
      return '12:00 PM';
    }
    return index >= 6 ? `${(index - 6) * 2}:00 PM` : `${index * 2}:00 AM`;
  });

  return hourLabels;
};

const getWeekDays = () => {
  const theDay = new Date().getDay();
  const weekDays = newArray(7);

  return weekDays.map((e, index) => WEEK_DAYS[(theDay + index) % 7]);
};

const getMonthDays = (length) => {
  const monthDays = newArray(length);

  return monthDays.map((e, index) =>
    moment().subtract(index, 'days').format('MM/DD'),
  );
};

const getMonths = (length) => {
  const months = newArray(length);

  return months.map((e, index) =>
    moment().subtract(index, 'months').format('YYYY/MMM'),
  );
};

const labels = {
  YESTERDAY: () => getHours(),
  TODAY: () => getHours(),
  SEVEN_DAYS: () => getWeekDays(),
  THIRTY_DAYS: () => getMonthDays(30).reverse(),
  MTD: () => getMonthDays(new Date().getDate()).reverse(),
  ONE_YEAR: () => getMonths(12).reverse(),
  YTD: () => getMonths(new Date().getMonth() + 1).reverse(),
  ALL: (nMonths) => getMonths(nMonths).reverse(),
};
const labelss = {
  YESTERDAY: () => getHours(),
  TODAY: () => getHours(),
  SEVEN_DAYS: () => getWeekDays(),
  THIRTY_DAYS: () => getMonthDays(30).reverse(),
  MTD: () => getMonthDays(new Date().getDate()).reverse(),
  ONE_YEAR: () => getMonths(12).reverse(),
  YTD: () => getMonths(new Date().getMonth() + 1).reverse(),
  ALL: (nMonths) => getMonths(nMonths).reverse(),
};

const UserTypes = [
  {
    id: 0,
    title: 'Total Users',
    type: TOTAL_USERS,
  },
  {
    id: 1,
    title: 'Active Subscriptions',
    type: ACTIVE_SUBSCRIPTIONS,
  },
  {
    id: 2,
    title: 'Canceled',
    type: CANCELED,
  },
  {
    id: 3,
    title: 'New Sign ups',
    type: NEW_SIGN_UPS,
  },
];

const PeriodTypes = [
  {
    id: 0,
    title: 'Yesterday',
    type: YESTERDAY,
  },
  {
    id: 1,
    title: 'Today',
    type: TODAY,
  },
  {
    id: 2,
    title: '7 days',
    type: SEVEN_DAYS,
  },
  {
    id: 3,
    title: '30 days',
    type: THIRTY_DAYS,
  },
  {
    id: 4,
    title: 'MTD',
    type: MTD,
  },
  {
    id: 5,
    title: '1 year',
    type: ONE_YEAR,
  },
  {
    id: 6,
    title: 'YTD',
    type: YTD,
  },
  {
    id: 7,
    title: 'ALL',
    type: ALL,
  },
];

const AllType = [
  {
    id: 1,
    title: 'All-individual',
  },
  {
    id: 2,
    title: 'All-summarized',
  },
];

const colorArray = [
  '#63b598',
  '#ce7d78',
  '#ea9e70',
  '#a48a9e',
  '#c6e1e8',
  '#648177',
  '#0d5ac1',
  '#f205e6',
  '#1c0365',
  '#14a9ad',
  '#4ca2f9',
  '#a4e43f',
  '#d298e2',
  '#6119d0',
  '#d2737d',
  '#c0a43c',
  '#f2510e',
  '#651be6',
  '#79806e',
  '#61da5e',
  '#cd2f00',
  '#9348af',
  '#01ac53',
  '#c5a4fb',
  '#996635',
  '#b11573',
  '#4bb473',
  '#75d89e',
  '#2f3f94',
  '#2f7b99',
  '#da967d',
  '#34891f',
  '#b0d87b',
  '#ca4751',
  '#7e50a8',
  '#c4d647',
  '#e0eeb8',
  '#11dec1',
  '#289812',
  '#566ca0',
  '#ffdbe1',
  '#2f1179',
  '#935b6d',
  '#916988',
  '#513d98',
  '#aead3a',
  '#9e6d71',
  '#4b5bdc',
  '#0cd36d',
  '#250662',
  '#cb5bea',
  '#228916',
  '#ac3e1b',
  '#df514a',
  '#539397',
  '#880977',
  '#f697c1',
  '#ba96ce',
  '#679c9d',
  '#c6c42c',
  '#5d2c52',
  '#48b41b',
  '#e1cf3b',
  '#5be4f0',
  '#57c4d8',
  '#a4d17a',
  '#225b8',
  '#be608b',
  '#96b00c',
  '#088baf',
  '#f158bf',
  '#e145ba',
  '#ee91e3',
  '#05d371',
  '#5426e0',
  '#4834d0',
  '#802234',
  '#6749e8',
  '#0971f0',
  '#8fb413',
  '#b2b4f0',
  '#c3c89d',
  '#c9a941',
  '#41d158',
  '#fb21a3',
  '#51aed9',
  '#5bb32d',
  '#807fb',
  '#21538e',
  '#89d534',
  '#d36647',
  '#7fb411',
  '#0023b8',
  '#3b8c2a',
  '#986b53',
  '#f50422',
  '#983f7a',
  '#ea24a3',
  '#79352c',
  '#521250',
  '#c79ed2',
  '#d6dd92',
  '#e33e52',
  '#b2be57',
  '#fa06ec',
  '#1bb699',
  '#6b2e5f',
  '#64820f',
  '#1c271',
  '#21538e',
  '#89d534',
  '#d36647',
  '#7fb411',
  '#0023b8',
  '#3b8c2a',
  '#986b53',
  '#f50422',
  '#983f7a',
  '#ea24a3',
  '#79352c',
  '#521250',
  '#c79ed2',
  '#d6dd92',
  '#e33e52',
  '#b2be57',
  '#fa06ec',
  '#1bb699',
  '#6b2e5f',
  '#64820f',
  '#1c271',
  '#9cb64a',
  '#996c48',
  '#9ab9b7',
  '#06e052',
  '#e3a481',
  '#0eb621',
  '#fc458e',
  '#b2db15',
  '#aa226d',
  '#792ed8',
  '#73872a',
  '#520d3a',
  '#cefcb8',
  '#a5b3d9',
  '#7d1d85',
  '#c4fd57',
  '#f1ae16',
  '#8fe22a',
  '#ef6e3c',
  '#243eeb',
  '#1dc18',
  '#dd93fd',
  '#3f8473',
  '#e7dbce',
  '#421f79',
  '#7a3d93',
  '#635f6d',
  '#93f2d7',
  '#9b5c2a',
  '#15b9ee',
  '#0f5997',
  '#409188',
  '#911e20',
  '#1350ce',
  '#10e5b1',
  '#fff4d7',
  '#cb2582',
  '#ce00be',
  '#32d5d6',
  '#17232',
  '#608572',
  '#c79bc2',
  '#00f87c',
  '#77772a',
  '#6995ba',
  '#fc6b57',
  '#f07815',
  '#8fd883',
  '#060e27',
  '#96e591',
  '#21d52e',
  '#d00043',
  '#b47162',
  '#1ec227',
  '#4f0f6f',
  '#1d1d58',
  '#947002',
  '#bde052',
  '#e08c56',
  '#28fcfd',
  '#bb09b',
  '#36486a',
  '#d02e29',
  '#1ae6db',
  '#3e464c',
  '#a84a8f',
  '#911e7e',
  '#3f16d9',
  '#0f525f',
  '#ac7c0a',
  '#b4c086',
  '#c9d730',
  '#30cc49',
  '#3d6751',
  '#fb4c03',
  '#640fc1',
  '#62c03e',
  '#d3493a',
  '#88aa0b',
  '#406df9',
  '#615af0',
  '#4be47',
  '#2a3434',
  '#4a543f',
  '#79bca0',
  '#a8b8d4',
  '#00efd4',
  '#7ad236',
  '#7260d8',
  '#1deaa7',
  '#06f43a',
  '#823c59',
  '#e3d94c',
  '#dc1c06',
  '#f53b2a',
  '#b46238',
  '#2dfff6',
  '#a82b89',
  '#1a8011',
  '#436a9f',
  '#1a806a',
  '#4cf09d',
  '#c188a2',
  '#67eb4b',
  '#b308d3',
  '#fc7e41',
  '#af3101',
  '#ff065',
  '#71b1f4',
  '#a2f8a5',
  '#e23dd0',
  '#d3486d',
  '#00f7f9',
  '#474893',
  '#3cec35',
  '#1c65cb',
  '#5d1d0c',
  '#2d7d2a',
  '#ff3420',
  '#5cdd87',
  '#a259a4',
  '#e4ac44',
  '#1bede6',
  '#8798a4',
  '#d7790f',
  '#b2c24f',
  '#de73c2',
  '#d70a9c',
  '#25b67',
  '#88e9b8',
  '#c2b0e2',
  '#86e98f',
  '#ae90e2',
  '#1a806b',
  '#436a9e',
  '#0ec0ff',
  '#f812b3',
  '#b17fc9',
  '#8d6c2f',
  '#d3277a',
  '#2ca1ae',
  '#9685eb',
  '#8a96c6',
  '#dba2e6',
  '#76fc1b',
  '#608fa4',
  '#20f6ba',
  '#07d7f6',
  '#dce77a',
  '#77ecca',
];

class UsersReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterOptions: {
        usertype: TOTAL_USERS,
        period: YESTERDAY,
        rate: props.rates[0].id,
        paygrade: getRanks()[0].id,
        examtype: getDutyStates()[0].id,
      },
      Graphic: {
        labels: [
          '07-06-19',
          '08-06-19',
          '09-06-19',
          '10-06-19',
          '11-06-19',
          '12-06-19',
        ],
        datasets: [],
      },
      selectedOption: [{ name: 'All', id: 0, value: 0, label: 'ALL' }],
      rompe: null,
      all: 'All-individual',
    };
  }

  componentWillMount() {
    this.props.getUsersCount(this.state.filterOptions);
  }

  onChangeFilter = (value, type) => {
    const { filterOptions, Graphic, selectedOption } = this.state;
    filterOptions[type] = value;
    if (selectedOption && selectedOption.length > 0) {
      Graphic.datasets.forEach((data) => {
        data.data = [];
      });

      let callAall = null;
      let quit = null;
      if (selectedOption) {
        const rates = [];
        selectedOption.map((option) => {
          if (option.id === 0) {
            callAall = 0;
            quit = option;
          } else {
            rates.push(option.id);
          }
        });
        if (quit !== null) {
          this.deleteGraph([quit]);
        }

        if (rates.length > 0) {
          filterOptions.rate = rates;
          this.props.getUsersCount(filterOptions);
        }
        if (callAall !== null) {
          setTimeout(() => {
            this.props.getUsersCount({
              usertype: filterOptions.usertype,
              period: filterOptions.period,
              rate: 0,
              paygrade: filterOptions.paygrade,
              examtype: filterOptions.examtype,
            });
          }, 1000);
        }
      }
    }
  };

  setGraph = (totalUsers, selectedOption) => {
    const datasets = {};
    datasets.label = selectedOption[selectedOption.length - 1].shortname;
    datasets.data = totalUsers;
    datasets.borderColor =
      colorArray[selectedOption[selectedOption.length - 1].id];
    datasets.fill = false;

    return datasets;
  };

  setRatesGraph = (users, graphic) => {
    const newGraph = graphic.datasets.map((data) => {
      const datasets = data;
      datasets.data = [];
      return datasets;
    });
    graphic.datasets.forEach((graph, index) => {
      users.forEach((label) => {
        let flag = 1;
        label.forEach((element, column) => {
          if (graph.label === element.rateid) {
            newGraph[index].data.push(element.count);
            flag = 0;
          }
        });
        if (flag) {
          newGraph[index].data.push(0);
        }
      });
    });

    return newGraph;
  };

  sumGraphic = (adition, total) => {
    const result = [];
    const totalResult = total;
    total.datasets[0].data.forEach((row, index) => {
      result[index] = row + adition[index];
    });
    totalResult.datasets[0].data = result;
    return totalResult;
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.usersCount &&
      prevProps.usersCount.length === 0 &&
      prevProps.usersCount !== this.props.usersCount &&
      this.state.selectedOption !== null
    ) {
      if (typeof this.props.usersCount[0] === 'string') {
        const { selectedOption } = this.state;

        const graph = this.state.Graphic;
        if (this.state.filterOptions.period === ALL) {
          graph.labels = labelss[this.state.filterOptions.period](
            this.props.usersCount.length,
          );
        } else {
          graph.labels = labelss[this.state.filterOptions.period]();
        }

        let graphSetup = null;
        graphSetup = this.setGraph(this.props.usersCount, selectedOption);

        graph.datasets.push(graphSetup);
        this.setState({ Graphic: graph });
        if (this.state.all === 'All-individual') {
          this.props.setGraph(graph);
        } else if (this.state.all === 'All-summarized') {
          this.handleAllChange(this.state.Graphic, this.state.all);
        }
      } else {
        const graph = this.state.Graphic;
        if (this.state.filterOptions.period === ALL) {
          graph.labels = labelss[this.state.filterOptions.period](
            this.props.usersCount.length,
          );
        } else {
          // falla porque hay un problema con el nombre
          graph.labels = labelss[this.state.filterOptions.period]();
        }

        const datasets = this.setRatesGraph(
          this.props.usersCount,
          this.state.Graphic,
        );
        graph.datasets = datasets;
        this.setState({ Graphic: graph });
        if (this.state.all === 'All-individual') {
          this.props.setGraph(graph);
        } else if (this.state.all === 'All-summarized') {
          this.handleAllChange(this.state.Graphic, this.state.all);
        }
      }
    }
    if (
      prevProps.usersCount &&
      prevProps.usersCount.length === 0 &&
      prevProps.usersCount !== this.props.usersCount &&
      this.state.filterOptions.rate === 0
    ) {
      const graph = this.state.Graphic;

      if (this.state.filterOptions.period === ALL) {
        graph.labels = labels[this.state.filterOptions.period](
          this.props.usersCount.length,
        );
      } else {
        graph.labels = labels[this.state.filterOptions.period]();
      }
      this.setState({ Graphic: graph });
      this.props.setGraph(graph);
    }
  }

  deleteGraph = (selectedOption) => {
    const graphics = this.state.Graphic;
    const datasets = [];
    this.state.Graphic.datasets.forEach((graph) => {
      if (graph.label !== selectedOption[0].shortname) {
        datasets.push(graph);
      }
    });
    graphics.datasets = datasets;
    this.setState({ Graphic: graphics });
    this.handleAllChange(this.state.Graphic, this.state.all);
  };

  handleChange = (selectedOption) => {
    if (
      selectedOption.length <
      (this.state.selectedOption ? this.state.selectedOption.length : 0)
    ) {
      this.setState({ selectedOption });
      const difference = this.state.selectedOption.filter(
        (x) => !selectedOption.includes(x),
      );
      this.deleteGraph(difference);
      // si se borro un elemento se debe borrar del estado
    } else if (
      selectedOption.length >
      (this.state.selectedOption ? this.state.selectedOption.length : 0)
    ) {
      const { filterOptions } = this.state;
      filterOptions.rate = selectedOption[selectedOption.length - 1].id;

      this.setState({ selectedOption });
      this.props.getUsersCount(filterOptions);
    }
  };

  handleAllChange = (Graphic, event) => {
    if (event === 'All-summarized') {
      const asd = [];
      Graphic.datasets.map((dataset) => {
        dataset.data.map((data, index) => {
          asd[index] = (asd[index] ? asd[index] : 0) + parseInt(data);
        });
      });

      const dataSets = [
        { data: asd, label: 'all', fill: false, borderColor: colorArray[0] },
      ];

      const newState = {
        Graphic: {
          ...Graphic,
          datasets: dataSets,
        },
        all: event,
      };

      this.setState(newState);
      this.props.setGraph(newState.Graphic);
    } else if (event === 'All-individual') {
      const { filterOptions, selectedOption } = this.state;
      const newGraphic = { ...Graphic };
      newGraphic.datasets.forEach((data) => {
        data.data = [];
      });

      if (selectedOption) {
        const rates = selectedOption.map((option) => option.id);
        filterOptions.rate = rates;
        this.props.getUsersCount(filterOptions);
      }

      const newState = {
        filterOptions,
        Graphic: newGraphic,
        all: event,
      };

      this.setState(newState);
      this.props.setGraph(newGraphic);
    }
  };

  render() {
    const { filterOptions, selectedOption } = this.state;
    const { rates, isLoading } = this.props;

    let header = '';
    if (this.props.graph.labels) {
      header = JSON.parse(JSON.stringify(this.props.graph.labels));
      header[this.props.graph.labels.length] = 'Rank';
      header[this.props.graph.labels.length + 1] = filterOptions.usertype;
      header[this.props.graph.labels.length + 2] = filterOptions.period;
      header[this.props.graph.labels.length + 3] = filterOptions.paygrade;
      header[this.props.graph.labels.length + 4] = filterOptions.examtype;
    }

    const datas = this.props.graph.datasets[0]
      ? this.props.graph.datasets.map((data) => {
          const dataResult = JSON.parse(JSON.stringify(data.data));
          dataResult[data.data.length] = data.label;
          return dataResult;
        })
      : '';

    let selectDisabled = false;
    if (selectedOption !== null) {
      selectedOption.map((option) => {
        if (option.id === 0) {
          selectDisabled = true;
        }
      });
    }

    return (
      <div className="container-report">
        <Row className="users-report">
          <Col>
            <div className="rate-filter-container">
              <div className="filter-item form-inline">
                <span>Rate: </span>
                <Select
                  className="select-rate-report"
                  key="select-rate"
                  value={this.state.selectedOption}
                  onChange={(e) => this.handleChange(e)}
                  options={rates.map((rate) => {
                    rate.value = rate.id;
                    rate.label = `${rate.shortname ? rate.shortname : 'ALL'}`;
                    return rate;
                  })}
                  closeMenuOnSelect
                  isMulti
                  openMenuOnFocus
                />
              </div>
            </div>
            <div className="rate-filter-container">
              <div className="filter-item form-inline mr-4">
                <span>Exam Paygrade: </span>
                <select
                  className="form-control ml-2 filter-width"
                  value={filterOptions.paygrade}
                  onChange={(e) =>
                    this.onChangeFilter(e.target.value, 'paygrade')
                  }
                >
                  {getRanks().map((rank) => (
                    <option key={rank.id} value={rank.id}>
                      {rank.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filter-item form-inline mr-4">
                <span>Exam Type: </span>
                <select
                  className="form-control ml-2 filter-width"
                  value={filterOptions.examtype}
                  onChange={(e) =>
                    this.onChangeFilter(e.target.value, 'examtype')
                  }
                >
                  {getDutyStates().map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filter-item form-inline mr-4">
                <span>User Type: </span>
                <select
                  className="form-control ml-2 filter-width"
                  value={filterOptions.usertype}
                  onChange={(e) =>
                    this.onChangeFilter(e.target.value, 'usertype')
                  }
                >
                  {UserTypes.map((type) => (
                    <option key={type.id} value={type.type}>
                      {type.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filter-item form-inline">
                <span>Period: </span>
                <select
                  className="form-control ml-2 filter-width"
                  value={filterOptions.period}
                  onChange={(e) =>
                    this.onChangeFilter(e.target.value, 'period')
                  }
                >
                  {PeriodTypes.map((type) => (
                    <option key={type.id} value={type.type}>
                      {type.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filter-item form-inline">
                <span>All: </span>
                <select
                  disabled={selectDisabled}
                  className="form-control ml-2 filter-width"
                  value={this.state.all}
                  onChange={(e) =>
                    this.handleAllChange(
                      { ...this.props.graph },
                      e.target.value,
                    )
                  }
                >
                  {AllType.map((type) => (
                    <option key={type.id} value={type.title}>
                      {type.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="generate-report-download">
                <CSVLink
                  filename={`users-report${Date.now()}.csv`}
                  headers={header}
                  data={datas}
                >
                  Generate Report
                </CSVLink>
              </div>
            </div>
            <div className="chart-wrapper">
              {isLoading && (
                <div className="loading-box">
                  <Spinner />
                </div>
              )}
              <Line
                data={this.props.graph}
                options={{
                  maintainAspectRatio: false,
                  legend: {
                    display: false,
                  },
                }}
                height={300}
              />
            </div>
          </Col>
        </Row>
        <Row className="legend-report">
          <Col md={12}>
            {this.props.graph.datasets.map((dataset) => (
              <div
                className="element-report"
                key={dataset.label !== undefined ? dataset.label : 'ALL'}
                style={{ textShadow: `0 0 2px ${dataset.borderColor}` }}
              >
                {`${dataset.label !== undefined ? dataset.label : 'ALL'}: ${
                  dataset.data[dataset.data.length - 1]
                    ? dataset.data[dataset.data.length - 1]
                    : 0
                }`}
              </div>
            ))}
          </Col>
        </Row>
      </div>
    );
  }
}

UsersReport.propTypes = {
  rates: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default UsersReport;
