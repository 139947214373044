import * as type from './actionTypesSagas';

export const getAllRatesAdmins = (
  showInactive,
  removingApprovalFilter,
  isNotSetAdmin,
) => ({
  type: type.GET_ALL_RATES_ADMINS,
  showInactive,
  removingApprovalFilter,
  isNotSetAdmin,
});

export const getAllQualsAdmins = (showInactive) => ({
  type: type.GET_ALL_QUALS_ADMINS,
  showInactive,
});

export const onEditAssignment = (
  assignment,
  isUpdateStatus,
  rejection,
  isDisableQuestion,
) => ({
  type: type.ON_EDIT_ASSIGNMENT,
  assignment,
  isUpdateStatus,
  rejection,
  isDisableQuestion,
});

export const submitQuestionsGPT = (fields, assignment) => ({
  type: type.SUBMIT_QUESTIONS_GPT,
  fields,
  assignment,
});

export const submitSummary = (fields, assignment, option) => ({
  type: type.SUBMIT_SUMMARY,
  fields,
  assignment,
  option,
});

export const loadAssignments = (admin, status, studyProgram) => ({
  type: type.LOAD_ASSIGNMENTS,
  admin,
  status,
  studyProgram,
});

export const onDeleteAssignment = (assignment, writerEmail) => ({
  type: type.DELETE_ASSIGNMENT,
  assignment,
  writerEmail,
});

export const getAssignmentsCount = (admin, rate, StudyProgramType) => ({
  type: type.ASSIGNMENTS_COUNT_SAGAS,
  admin,
  rate,
  StudyProgramType,
});

export const getAwaitingApproval = () => ({
  type: type.GET_AWAITING_APPROVAL,
});

export const loadMyAssignments = (selectedSP) => ({
  type: type.LOAD_MY_ASSIGNMENTS,
  selectedSP,
});

export const loadAllWriters = () => ({
  type: type.LOAD_WRITERS,
});

export const loadRatesForAssignModal = () => ({
  type: type.LOAD_RATES_FOR_ASSIGN_MODAL,
});

export const loadReferencesForAssignModal = (studyProgramType, typeId) => ({
  type: type.LOAD_REFERENCES_FOR_ASSIGN_MODAL,
  studyProgramType,
  typeId,
});
