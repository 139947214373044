import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from 'react-bootstrap-button-loader';
import './CopyReference.css';
import { InputWithoutIcon } from '../../Input/Input';
import { loadAllRates } from '../../../components/Rates/actionsSagas';
import { loadAllQuals } from '../../../components/Quals/actionsSagas';
import { getAllStudyPrograms } from '../../../components/Quals/selectors';
import { setAllStudyPrograms } from '../../../components/Quals/actions';
import { connect } from 'react-redux';

class CopyReference extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      waiting: false,
    };
  }

  componentWillMount() {
    if (this.props.isQual) {
      this.props.loadAllQuals();
    } else {
      this.props.loadAllRates();
    }
  }

  componentWillReceiveProps({ submitSucceeded }) {
    if (submitSucceeded && this.state.waiting) {
      this.setState({ waiting: false });
    }
  }

  componentWillUnmount() {
    this.props.clearAllStudyPrograms();
  }

  validate(fields, callback) {
    let errors = {};

    if (!fields.studyProgramId) {
      errors.studyProgramId = 'You must select a rate';
    }

    if (!fields.copyPdf) {
      fields.copyPdf = false;
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: null, waiting: true });
    callback(fields);
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      onCancel,
      selectedSP,
      studyPrograms,
      reference,
      isQual,
    } = this.props;

    let filteredSP = studyPrograms.filter(sp => {
      return sp.id !== selectedSP.id;
    });
    return (
      <div className="copy-reference-component">
        <div className="card-block">
          <form
            className="widget-form new-admin-form"
            onSubmit={handleSubmit(fields => this.validate(fields, onSubmit))}
          >
            <div className="form-group">
              <span className="label labelPosition">
                {isQual ? 'Qual:' : 'Rate:'}
              </span>
              <Field name="studyProgramId" component="select">
                <option />
                {filteredSP.map(sp =>
                  <option key={`ratesForReference${sp.id}`} value={sp.id}>
                    {sp.name}
                  </option>
                )}
              </Field>
              <br />
              {this.state.errors &&
                this.state.errors['rate'] &&
                <span className="errors">{this.state.errors['rate']}</span>}
            </div>
            <div className="form-group">
              <Field
                name="copyPdf"
                type="checkbox"
                placeholder="Include all pdf files"
                component={InputWithoutIcon}
              />
            </div>
            <Field
              name="sourceId"
              type="text"
              placeholder=""
              component={InputWithoutIcon}
              hidden
              defaultValue={reference}
            />
            {this.state.errors &&
              this.state.errors.general &&
              <div className="errors">{this.state.errors.general}</div>}

            <div className="bottom-buttons">
              <Button className="btn btn-secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                className="btn btn-success"
                type="submit"
                loading={this.state.waiting}
              >
                Copy
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    studyPrograms: getAllStudyPrograms(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadAllQuals: () => {
      dispatch(loadAllQuals());
    },
    loadAllRates: () => {
      dispatch(loadAllRates());
    },
    clearAllStudyPrograms: () => {
      dispatch(setAllStudyPrograms([]));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({ form: 'CopyReference' })(CopyReference)
);
