import { NAME } from './constants';

export const USERS_GET_SAGAS = `${NAME}-sagas/USERSGETSAGAS`;
export const DELETE_USER_SAGAS = `${NAME}-sagas/DELETEUSERSAGAS`;
export const TOGGLE_ACTIVE_SAGAS = `${NAME}-sagas/TOGGLE_ACTIVE_SAGAS`;
export const UNLOCK_USER_SAGAS = `${NAME}-sagas/UNLOCK_USER_SAGAS`;
export const RESET_PASSWORD_USER_SAGAS = `${NAME}-sagas/RESET_PASSWORD_USER_SAGAS`;
export const UPDATE_USER_SAGAS = `${NAME}-sagas/UPDATE_USER_SAGAS`;
export const CREATE_STUDENT = `${NAME}-sagas/CREATE_STUDENT`;
export const LOAD_USER_STATISTICS = `${NAME}-sagas/LOAD_USER_STATISTICS`;
export const LOGIN_AS_STUDENT = `${NAME}-sagas/LOGIN_AS_STUDENT`;
export const LOAD_USER_SUBSCRIPTION_TYPE = `${NAME}-sagas/LOAD_USER_SUBSCRIPTION_TYPE`;
export const UPDATE_USER_SUBSCRIPTION_SAGAS = `${NAME}-sagas/UPDATE_USER_SUBSCRIPTION_SAGAS`;
export const RESET_USER_QUESTIONS = `${NAME}/RESET_USER_QUESTIONS`;
export const LOAD_SELECTED_USER = `${NAME}/LOAD_SELECTED_USER`;
export const LOAD_PRACTICE_EXAMS = `${NAME}/LOAD_PRACTICE_EXAMS`;
export const RESTART_PRACTICE_EXAM = `${NAME}/RESTART_PRACTICE_EXAM`;
export const GET_CYCLE_INFORMATION = `${NAME}/GET_CYCLE_INFORMATION`;
export const GET_USER_VERIFICATION = `${NAME}/GET_USER_VERIFICATION`;
export const UPDATE_USER_VERIFICATION = `${NAME}/UPDATE_USER_VERIFICATION`;
export const BLOCK_USER_SAGAS = `${NAME}/BLOCK_USER_SAGAS`;
