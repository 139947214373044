import * as type from './actionTypesSagas';

export const loadCoupons = () => ({
  type: type.LOAD_COUPONS,
});

export const loadCouponsReferral = () => ({
  type: type.LOAD_COUPONS_REFERRAL,
});

export const loadCodeGroups = (coupon) => ({
  type: type.LOAD_CODE_GROUPS,
  coupon,
});

export const loadNewCouponsAction = (coupon) => ({
  type: type.LOAD_NEW_COUPONS,
  coupon,
});

export const createCodeGroup = (codeGroup) => ({
  type: type.CREATE_CODE_GROUPS,
  codeGroup,
});

export const cancelAllCodes = (codeGroup) => ({
  type: type.CANCELL_ALL_CODES,
  codeGroup,
});

export const editCodeGroup = (codeGroup) => ({
  type: type.EDIT_CODE_GROUP,
  codeGroup,
});

export const createCoupon = (coupon) => ({
  type: type.CREATE_COUPON,
  coupon,
});

export const createNewCoupon = (coupon) => ({
  type: type.CREATE_NEW_COUPON,
  coupon,
});

export const editNewCoupon = (updatedCoupon, couponName) => ({
  type: type.EDIT_NEW_COUPON,
  updatedCoupon,
  couponName,
});

export const loadSubcodes = (couponName) => ({
  type: type.LOAD_SUBCODES,
  couponName,
});

export const createSubcodes = (couponName) => ({
  type: type.CREATE_SUBCODES,
  couponName,
});

export const deleteDisableCoupon = (coupon, value) => ({
  type: type.DELETE_DISABLE_COUPON,
  coupon,
  value,
});
export const listPlansSubscriptions = () => ({
  type: type.LIST_PLANS_SUBSCRIPTIONS,
});

export const editCoupon = (coupon) => ({
  type: type.EDIT_COUPON,
  coupon,
});

export const loadCodeGroup = (codeGroup, fromValue, toValue) => {
  return {
    type: type.GET_CODE_GROUP,
    codeGroup,
    fromValue,
    toValue,
  };
};

export const cancelCode = (code, codeGroup, fromValue, toValue) => ({
  type: type.CANCEL_CODE,
  code,
  codeGroup,
  fromValue,
  toValue,
});

export const loadRedeems = (codeGroup) => ({
  type: type.GET_REDEEMS,
  codeGroup,
});

export const downloadUnredeemedCodes = (codeGroup) => ({
  type: type.DOWNLOAD_UNREDEEMED_CODES,
  codeGroup,
});

export const createCouponReferral = (couponData) => ({
  type: type.CREATE_COUPON_REFERRAL,
  couponData,
});

export const insertCouponReferral = (couponData) => ({
  type: type.INSERT_COUPON_REFERRAL,
  couponData,
});
