import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CreateCouponReferralContainer from '../../../commonComponents/Modals/CreateCouponReferralModal/CreateCouponReferralContainer';
import './ReferralProgram.css';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import DatesFromReferralProgram from './DatesForReferralProgram/DatesForReferralProgram';

const TABS = [
  // {
  //   id: 0,
  //   name: 'Coupons',
  // },
  {
    id: 1,
    name: 'Settings',
  },
];
export default class ReferralProgram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
      selectedTab: TABS[0],
    };
    this.onOpenCreateModal = this.onOpenCreateModal.bind(this);
    this.onOpenInsertModal = this.onOpenInsertModal.bind(this);
  }

  onCreateCouponReferral = couponData => {
    this.props.createCouponReferral(couponData);
  };

  onInsertCouponReferral = couponData => {
    this.props.insertCouponReferral(couponData);
  };

  renderReferralComponent = () => {
    let { selectedTab, coupons } = this.state;

    switch (selectedTab.id) {
      case 0:
        return this.props.loadingCoupons ? (
          <Spinner text="Loading referral coupons" />
        ) : (
          <div style={{ height: '100%' }}>
            {this.props.couponsReferral &&
              coupons.map((c, index) => {
                return (
                  <div
                    className={c.error ? 'coupon border-red' : 'coupon border'}
                    key={index}
                  >
                    <div className="coupon-info">
                      <p className="coupon-name">
                        <span className="bold">Name:</span>
                        {c.coupon.description}
                      </p>
                      <p className="coupon-discount">
                        <span className="bold">Discount:</span>
                        {c.coupon.percentageOff}%
                      </p>
                      {!c.error && (
                        <p className="coupon-stripeid">
                          <span className="bold">Stripe Id:</span>
                          {c.coupon.stripeCode}
                        </p>
                      )}
                      {c.error && (
                        <p className="coupon-stripeid bold">
                          Create or Insert a new Referral Coupon
                        </p>
                      )}
                    </div>
                    <div className="coupon-buttons">
                      <button
                        className="btn-primary coupon-btn"
                        onClick={() => this.onOpenCreateModal(c.coupon)}
                      >
                        CREATE
                      </button>
                      <button
                        className="btn-secondary coupon-btn"
                        onClick={() => this.onOpenInsertModal(c.coupon)}
                      >
                        INSERT
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        );
      case 1:
        return this.props.isLoading ? (
          <Spinner text="Loading referral dates" />
        ) : (
          <DatesFromReferralProgram
            updateReferralDates={this.props.updateReferralDates}
            settings={this.props.settings}
          />
        );
      default:
        return null;
    }
  };

  onOpenCreateModal = coupon => {
    this.props.openModal({
      modalContent: (
        <CreateCouponReferralContainer
          isCreate={true}
          isInsert={false}
          onSubmit={this.onCreateCouponReferral}
          onCancel={this.props.closeModal}
          coupon={coupon}
          closeModal={this.props.closeModal}
          title="Create Coupon Referral"
        />
      ),
    });
  };

  onOpenInsertModal = coupon => {
    this.props.openModal({
      modalContent: (
        <CreateCouponReferralContainer
          isInsert={true}
          isCreate={false}
          onSubmit={this.onInsertCouponReferral}
          onCancel={this.props.closeModal}
          coupon={coupon}
          closeModal={this.props.closeModal}
          title="Insert Coupon Referral"
        />
      ),
    });
  };

  componentDidMount = () => {
    // this.props.loadCouponsReferral();
    this.props.loadSettings();
  };

  componentWillReceiveProps = nextProps => {
    this.setState({ coupons: nextProps.couponsReferral });
  };

  render() {
    const { selectedTab } = this.state;
    return (
      <Row className="options-list-row">
        <Col xs={6} md={3} className="quals-list-container options-list">
          <div className="rates-list-component">
            <div className="rates-title">
              <div className="flexItemsTitlePlus">Referral Program</div>
              <div className="line-quals-page" />
            </div>
            <div className="rates-list">
              {TABS.map(tab => (
                <p className="row" key={'tab-' + tab.id}>
                  <div
                    className={
                      selectedTab.id === tab.id
                        ? 'rate-line active'
                        : 'rate-line'
                    }
                    onClick={() => this.setState({ selectedTab: tab })}
                  >
                    <div className="description">{tab.name}</div>
                  </div>
                </p>
              ))}
            </div>
          </div>
        </Col>
        <Col md={9} style={{ overflow: 'auto', height: '100%' }}>
          {this.renderReferralComponent()}
        </Col>
      </Row>
    );
  }
}
