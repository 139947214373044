import './UsersList.css';

import { blue100 } from 'material-ui/styles/colors';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import pure from 'recompose/pure';

import NumberUtils from '../../lib/NumberUtils';
import DropDown from '../DropDown/DropDown';
import Spinner from '../Spinner/Spinner';
import UserRow from './UserRow/UserRow';

function renderUser(
  user,
  updateProfile,
  profile,
  toggleNew,
  newStudent,
  setSelectedUser,
  loadUserStatistics,
) {
  return (
    <UserRow
      key={NumberUtils.obtainUniqueKey()}
      user={user}
      updateProfile={updateProfile}
      selected={profile && user ? profile.id === user.id : false}
      toggleNew={toggleNew}
      newStudent={newStudent}
      setSelectedUser={setSelectedUser}
      loadUserStatistics={loadUserStatistics || (() => {})}
    />
  );
}

function renderUsers(
  users,
  updateProfile,
  profile,
  toggleNew,
  newStudent,
  setSelectedUser,
  loadUserStatistics,
) {
  if (users) {
    return users.map((option) =>
      renderUser(
        option,
        updateProfile,
        profile,
        toggleNew,
        newStudent,
        setSelectedUser,
        loadUserStatistics,
      ),
    );
  }
}

function renderLoading() {
  return <Spinner />;
}

function renderTable(
  loading,
  users,
  updateProfile,
  profile,
  toggleNew,
  newStudent,
  setSelectedUser,
  loadUserStatistics,
) {
  return (
    <div className="table">
      <div>
        {renderUsers(
          users,
          updateProfile,
          profile,
          toggleNew,
          newStudent,
          setSelectedUser,
          loadUserStatistics,
        )}
      </div>
      {loading ? renderLoading() : ''}
    </div>
  );
}

const areMoreUser = (pagination, users, loading) => {
  if (pagination && users && users.length && !loading) {
    return pagination > users.length;
  }
};

function UsersListStateless({
  users,
  forAdmin,
  updateProfile,
  search,
  filterBy,
  updateSearch,
  updateFilterBy,
  loading,
  openModal,
  page,
  onChangePage,
  totalRecords,
  profile,
  toggleNew,
  clearSearchInput,
  newStudent,
  setSelectedUser,
  loadUserStatistics,
  generalPermissions,
  loggedUser,
}) {
  return (
    <div>
      <div className="usersListItems">
        <Row className="usersListItemsRow">
          <Col className="noPadRight" xs={9} md={9}>
            <div className="searchInput">
              <input
                id="input"
                className="form-control"
                value={search}
                onChange={updateSearch}
                onPaste={updateSearch}
              />
              {search ? (
                <i
                  onClick={() => clearSearchInput()}
                  className="fa fa-close fa-lg"
                />
              ) : (
                <i className="fa fa-search fa-lg" />
              )}
            </div>
          </Col>
          {!forAdmin && (
            <Col xs={2} md={2} className="dropDownfilterByContainer">
              <DropDown
                title="Filter by:"
                options={[
                  {
                    name: 'Apple',
                    event: () => {
                      updateFilterBy('Apple');
                    },
                  },
                  {
                    name: 'Stripe',
                    event: () => {
                      updateFilterBy('Stripe');
                    },
                  },
                ]}
                style={{
                  position: 'absolute',
                  zIndex: 10000,
                  top: 0,
                  right: 0,
                }}
              />
            </Col>
          )}

          <Col xs={2} md={2} className="plusContainer">
            {((!forAdmin &&
              generalPermissions.find(
                (permission) => permission === 'Students.Create',
              )) ||
              (loggedUser && loggedUser.isSuperAdmin) ||
              (forAdmin &&
                generalPermissions.find(
                  (permission) => permission === 'Admins.Create',
                ))) && (
              <i onClick={() => toggleNew()} className="fa fa-plus fa-lg" />
            )}
          </Col>
        </Row>
        {filterBy && (
          <Row>
            <div className="filteredByContainer">
              <p>
                Filtered by:{' '}
                <span style={{ color: '#366fde' }}>{filterBy}</span>
              </p>
              <p
                className="clearFilterBy"
                onClick={() => {
                  updateFilterBy('');
                }}
              >
                <i className="fa fa-close fa-lg" />
              </p>
            </div>
          </Row>
        )}
      </div>
      <div className="animated fadeIn usersTable">
        <div style={{ height: '100%', overflow: 'auto' }}>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => onChangePage()}
            hasMore={!loading && areMoreUser(totalRecords, users, loading)}
            useWindow={false}
          >
            {renderTable(
              loading,
              users,
              updateProfile,
              profile,
              toggleNew,
              newStudent,
              setSelectedUser,
              loadUserStatistics,
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}

export default pure(UsersListStateless);
