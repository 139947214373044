import React from 'react';
import Button from 'react-bootstrap-button-loader';
import { Row, Col } from 'react-bootstrap';
import { SimpleInput } from '../../Input/Input';
import './CreateCouponReferral.css';

export default class CreateCouponReferral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      percent_off: 0,
      stripeCode: '',
      onSubmit: false,
    };
  }

  componentWillUnmount = () => {
    this.props.setBadRequest(false);
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.badRequest) {
      this.setState({ onSubmit: false });
    }
    if (
      !nextProps.badRequest &&
      !nextProps.waitingResponse &&
      this.state.onSubmit
    ) {
      this.props.onCancel();
    }
  };

  onSubmitInsert = () => {
    this.setState({ onSubmit: true });
    this.props.onSubmit({
      name: this.state.name,
      percent_off: this.props.coupon.percentageOff,
      stripeCode: this.state.stripeCode,
      couponId: this.props.coupon.id,
    });
  };

  onSubmitCreate = () => {
    this.setState({ onSubmit: true });
    this.props.onSubmit({
      name: this.state.name,
      percent_off: this.props.coupon.percentageOff,
      duration: 'once',
      couponId: this.props.coupon.id,
    });
  };

  render() {
    const {
      onCancel,
      title,
      isCreate,
      isInsert,
      coupon,
      waitingResponse,
      badRequest,
    } = this.props;
    return (
      <div className="create-code-group-component">
        <div className="card-block">
          <div className="createChapterTitle">
            {title}
            <i
              className="fa fa-times fa-xs"
              onClick={() => this.props.onCancel()}
            />
            <hr style={{ width: '600px', marginLeft: '-15px' }} />
          </div>
          {isCreate && (
            <div>
              <Row>
                <Col md={4}>
                  <span className="InputLebel">Name *</span>
                </Col>
                <Col md={8}>
                  <SimpleInput
                    type="text"
                    value={this.state.name}
                    onChange={e => {
                      this.setState({ name: e.target.value });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <span className="InputLebel">Percent Off</span>
                </Col>
                <Col md={8}>
                  <SimpleInput
                    type="number"
                    value={coupon.percentageOff}
                    disabled={true}
                  />
                </Col>
              </Row>
            </div>
          )}
          {isInsert && (
            <div>
              <Row>
                <Col md={4}>
                  <span className="InputLebel">Name *</span>
                </Col>
                <Col md={8}>
                  <SimpleInput
                    type="text"
                    value={this.state.name}
                    onChange={e => {
                      this.setState({ name: e.target.value });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <span className="InputLebel">Stripe Code *</span>
                </Col>
                <Col md={8}>
                  <SimpleInput
                    type="text"
                    value={this.state.stripeCode}
                    onChange={e => {
                      this.setState({ stripeCode: e.target.value });
                    }}
                  />
                </Col>
              </Row>
            </div>
          )}
          {isInsert && badRequest && (
            <p style={{ color: 'red' }} className="bold">
              Not found coupon for this Stripe Code or the coupon you wanted to
              insert does not correspond to the corresponding percentage.
            </p>
          )}
          {isCreate && badRequest && (
            <p style={{ color: 'red' }} className="bold">
              Could not create a coupon. Please try again later.
            </p>
          )}
          <hr style={{ width: '600px', marginLeft: '-15px' }} />
          <div className="bottom-buttons-new">
            {isCreate && (
              <Button
                id="btn-success"
                style={{ width: 'auto' }}
                className="btn btn-success"
                onClick={() => {
                  this.onSubmitCreate();
                }}
                loading={waitingResponse}
                disabled={this.state.name == ''}
              >
                Submit
              </Button>
            )}
            {isInsert && (
              <Button
                id="btn-success"
                style={{ width: 'auto' }}
                className="btn btn-success"
                onClick={() => {
                  this.onSubmitInsert();
                }}
                loading={waitingResponse}
                disabled={this.state.stripeCode == '' || this.state.name == ''}
              >
                Submit
              </Button>
            )}
            <Button className="btn btn-secondary" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
