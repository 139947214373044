import { QUESTION_TYPES } from './constants';

export const userSubmittedQuestionAdapter = (question) => {
  const { text, answer, noteUser, flashMode, multipleOptionMode } = question;

  if (flashMode) {
    const correctAnswer = answer.filter((ans) => ans.correct);
    return {
      text,
      correctAnswer: correctAnswer[0].text,
      notes: noteUser,
      incorrectAnswers: [''],
      type: QUESTION_TYPES.FLASHCARD,
    };
  }

  if (multipleOptionMode) {
    const correctAnswer =
      answer.length > 1 ? answer.filter((ans) => ans.correct)[0] : answer[0];
    const incorrectAnswersObject =
      answer.length > 1 ? answer.filter((ans) => !ans.correct) : [];
    const incorrectAnswers =
      incorrectAnswersObject.length > 0
        ? incorrectAnswersObject.map((ans) => ans.text)
        : [];
    return {
      text,
      correctAnswer: correctAnswer.text,
      incorrectAnswers,
      notes: noteUser,
      type: QUESTION_TYPES.MC,
    };
  }
};
