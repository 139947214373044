import * as type from './actionTypes';

export const setWriters = writers => ({
  type: type.SET_WRITERS,
  writers,
});

export const updateWaiting = waiting => ({
  type: type.UPDATE_WAITING,
  waiting,
});

export const setChapterWriters = writers => ({
  type: type.UPDATE_CHAPTERS_WRITERS,
  writers,
});

export const clearWriters = () => ({
  type: type.CLEAR_CHAPTERS_WRITERS,
});

export const setReferences = references => ({
  type: type.SET_REFERENCES,
  references,
});

export const setAssignment = assignment => ({
  type: type.SET_ASSIGNMENT,
  assignment,
});
