import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { getSelectedCoupon, getRedeemedCodes } from '../../selectors';
import { loadCodeGroups } from '../../actionsSagas';
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import './Analytics.css';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { SimpleDatePicker } from '../../../../commonComponents/Datepicker/Datepicker';
const ALL_TABS = ['Week', 'Month', 'Year'];
const TAB_CONFIG = {
  Week: {
    frecuency: 'day',
    frecuencyAddition: 'week',
    frecuencyAmount: 1,
    format: 'dddd',
  },
  Month: {
    frecuency: 'day',
    frecuencyAddition: 'month',
    frecuencyAmount: 1,
    format: 'M/D',
  },
  Year: {
    frecuency: 'month',
    frecuencyAddition: 'month',
    frecuencyAmount: 12,
    format: 'MMMM',
  },
  Custom: {
    frecuency: 'day',
    format: 'M/D/Y',
  },
  All: {
    frecuency: 'day',
    format: 'M/D/Y',
  },
};

class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'Week',
      dateError: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedCoupon &&
      prevProps.selectedCoupon &&
      this.props.selectedCoupon.id !== prevProps.selectedCoupon.id
    ) {
      this.props.onBack();
      this.props.loadCodeGroups(this.props.selectedCoupon.id);
    }
  }

  getEndDate = () => {
    let { tab } = this.state;
    return tab === 'Custom' || tab === 'All'
      ? this.state.endDate2.clone()
      : moment().startOf('day');
  };

  getInitialDate = () => {
    let { tab } = this.state;
    return tab === 'Custom' || tab === 'All'
      ? this.state.initialDate2.clone()
      : moment()
          .subtract(
            TAB_CONFIG[this.state.tab].frecuencyAmount,
            TAB_CONFIG[this.state.tab].frecuencyAddition
          )
          .startOf('day');
  };

  getData = () => {
    let { codeGroup, redeemedCodes } = this.props;
    if (!codeGroup || !redeemedCodes) {
      return [];
    }
    let dates = [];
    let lastDate = this.getEndDate();
    let currDate = this.getInitialDate();
    while (currDate.diff(lastDate) <= 0) {
      dates.push({
        day: moment(currDate).format(TAB_CONFIG[this.state.tab].format),
        redeems: redeemedCodes.filter(c =>
          this.isSameDay(c.redeemedAt, currDate)
        ).length,
      });
      currDate.add(1, TAB_CONFIG[this.state.tab].frecuency);
    }
    return dates;
  };

  isSameDay = (a, b) => {
    return moment(Number(a)).isSame(
      moment(b).valueOf(),
      TAB_CONFIG[this.state.tab].frecuency
    );
  };

  getTab = tab => {
    return (
      <span
        className={this.state.tab === tab ? 'Tab Active' : 'Tab'}
        onClick={() => this.setState({ tab: tab })}
      >
        {tab}
      </span>
    );
  };

  seeCustomRange = () => {
    let { initialDate, endDate } = this.state;
    if (initialDate && endDate && moment(endDate).isAfter(initialDate)) {
      this.setState({
        dateError: '',
        tab: 'Custom',
        initialDate2: initialDate.clone(),
        endDate2: endDate.clone(),
      });
    } else {
      this.setState({ dateError: 'Date error' });
    }
  };

  setAllTab = () => {
    let { redeemedCodes } = this.props;
    if (redeemedCodes) {
      let dates = redeemedCodes
        .filter(c => c.redeemedAt)
        .map(c => c.redeemedAt);
      let startDate = Math.min(...dates);
      let endDate = Math.max(...dates);
      this.setState({
        tab: 'All',
        initialDate2: moment(startDate),
        endDate2: moment(endDate),
      });
    }
  };

  render() {
    let { codeGroup } = this.props;
    return (
      <Col className="AnalyticsContainer" md={8}>
        <Row>
          <span className="Title">Codes redeemed over time</span>
        </Row>
        <Row>
          <Col md={3}>
            <span className="TitleBig">
              {Math.round(codeGroup.redeems * 100 / Number(codeGroup.codes))}%
              <br />
              redeemed
            </span>
          </Col>
          <Col md={9}>
            Started:{' '}
            <span className="font20">
              {momentTimezone(Number(codeGroup.createdAt))
                .tz('America/Los_Angeles')
                .format('dddd MMMM DD, YYYY hh:mm a zz')}
            </span>
            <br />
            Total codes sent: <span className="font20">{codeGroup.codes}</span>
            <br />
            Total codes redeemed:{' '}
            <span className="font20">{codeGroup.redeems}</span>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            md={12}
            className="CodeGroup"
            style={{ height: '300px' }}
          >
            <ResponsiveContainer width="100%">
              <AreaChart
                height={400}
                data={this.getData()}
                margin={{ top: 20, right: 30, left: 20, bottom: 0 }}
              >
                <XAxis dataKey="day" hide={this.state.tab === 'Custom'} />
                <Tooltip cursor={false} />
                <Area
                  type="monotone"
                  dataKey="redeems"
                  stroke="#23a127"
                  fill="#9bd69d"
                  isAnimationActive={false}
                  dot={this.state.tab === 'Week' ? <CustomizedDot /> : false}
                />
              </AreaChart>
            </ResponsiveContainer>
          </Col>
        </Row>
        <Row className="TabRow">
          {ALL_TABS.map(tab => this.getTab(tab))}
          <span
            className={this.state.tab === 'All' ? 'Tab Active' : 'Tab'}
            onClick={this.setAllTab}
          >
            All
          </span>
        </Row>
        <Row className="DateRow TabRow">
          <SimpleDatePicker
            date={
              this.state.initialDate ? moment(this.state.initialDate) : null
            }
            onChange={initialDate => {
              this.setState({ initialDate });
            }}
            placeholderText="MM/DD/YYYY"
          />
          <span style={{ padding: '15px' }}>to</span>
          <SimpleDatePicker
            date={this.state.endDate ? moment(this.state.endDate) : null}
            onChange={endDate => {
              this.setState({ endDate });
            }}
            placeholderText="MM/DD/YYYY"
          />
          <div
            className={this.state.tab === 'Custom' ? 'Tab Active' : 'Tab'}
            onClick={this.seeCustomRange}
          >
            Go
          </div>
          <span style={{ paddingLeft: '15px', color: 'red' }}>
            {this.state.dateError}
          </span>
        </Row>
      </Col>
    );
  }
}

class CustomizedDot extends React.Component {
  render() {
    const { cx, cy, payload } = this.props;
    return (
      <g>
        <circle cx={cx} cy={cy} r={15} fill="#23a127" />
        <text x={cx - 3} y={cy} textAnchor="middle" stroke="white" dy=".3em">
          {payload.redeems}
        </text>
      </g>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedCoupon: getSelectedCoupon(state),
    redeemedCodes: getRedeemedCodes(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCodeGroups: coupon => dispatch(loadCodeGroups(coupon)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Analytics);
