export const SET_DEFINITIONS = 'definitions/SET_DEFINITIONS';
export const CLEAR_DEFINITIONS = 'definitions/CLEAR_DEFINITIONS';
export const UPDATE_WAITING = 'definitions/UPDATE_WAITING';
export const REMOVE_DEFINITION = 'definitions/REMOVE_DEFINITION';
export const UPDATE_DEFINITION = 'definitions/UPDATE_DEFINITION';
export const ADD_DEFINITION = 'definitions/ADD_DEFINITION';
export const SET_QUESTION_DEFINITIONS = 'definitions/SET_QUESTION_DEFINITIONS';
export const REMOVE_QUESTION_DEFINITION =
  'definitions/REMOVE_QUESTION_DEFINITION';
export const UPDATE_QUESTION_DEFINITION =
  'definitions/UPDATE_QUESTION_DEFINITION';
export const ADD_QUESTION_DEFINITION = 'definitions/ADD_QUESTION_DEFINITION';
export const ADD_QUESTIONS = 'definitions/ADD_QUESTIONS';
export const EDIT_QUESTIONS = 'definitions/EDIT_QUESTIONS';
export const SET_UPDATED_DEFINITIONS = 'definitions/SET_UPDATED_DEFINITIONS';
