import React from 'react';
import Mask from '../Mask/Mask';
import './Select.css';

export default class Select extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedValue: '',
    };
  }

  componentWillMount() {
    if (!this.props.value) return;
    this.setState({ selectedValue: this.props.value });
  }

  componentWillReceiveProps({ value }) {
    if (this.props.value === value) return;
    this.setState({ selectedValue: value });
  }

  onClick() {}

  render() {
    return (
      <div>
        <div className="selectContainer">
          <div className="placeHolderButtons">
            <div className="placeHolder">{this.state.selectedValue.name}</div>
            <div className="check">
              <i
                onClick={() => {
                  this.props.onSubmit(this.state.selectedValue);
                  this.props.onClose();
                }}
                className="fa fa-check fa-lg"
              />
            </div>
            <div className="cross">
              <i
                onClick={() => this.props.onClose()}
                className="fa fa-close fa-lg"
              />
            </div>
          </div>
          <div className="optionsContainer">
            {this.props.options.map(option => (
              <div
                title={option.name}
                className={
                  option.id === this.state.selectedValue.id ? (
                    'option active'
                  ) : (
                    'option'
                  )
                }
                onClick={() => this.props.onOptionClick(option)}
                key={'key-for-' + option.name}
              >
                {option.name}
              </div>
            ))}
          </div>
        </div>
        <Mask />
      </div>
    );
  }
}
