import { connect } from 'react-redux';
import Header from './Header';
import { deleteTaskNotification } from './components/actionsSagas';
import { getUser } from '../../../Login/selectors';

const mapStateToProps = (state) => ({
  user: getUser(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    deleteNotification: (taskId) => {
      dispatch(deleteTaskNotification(taskId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
