import { connect } from 'react-redux';
import Prompts from './Prompts';
import addAbilityToOpenModal from '../../utilities/modalHOC';
import {
  getAllPromptsAction,
  setPromptDetailsAction,
  loadPromptDetails,
  createPromptAction,
  editPromptAction,
  deletePromptAction,
  toggleActivePromptAction,
  setCreateMode,
} from './actionSagas';
import {
  getPromptsSelectors,
  getPromptDetailsSelectors,
  loadPromptDetailsSelectors,
  setLoadingInfoSelector,
  getCreateModeSelector,
} from './selectors';

const mapStateToProps = (state) => ({
  prompts: getPromptsSelectors(state),
  promptDetails: getPromptDetailsSelectors(state),
  loadPrompt: loadPromptDetailsSelectors(state),
  loadingInfo: setLoadingInfoSelector(state),
  createMode: getCreateModeSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAllPrompts: () => dispatch(getAllPromptsAction()),
  setPromptDetail: (promptId) => dispatch(setPromptDetailsAction(promptId)),
  loadPromptDetails: (value) => dispatch(loadPromptDetails(value)),
  createPrompt: (prompt) => dispatch(createPromptAction(prompt)),
  editPrompt: (prompt) => dispatch(editPromptAction(prompt)),
  deletePrompt: (prompt) => dispatch(deletePromptAction(prompt)),
  activePrompt: (promptId, value) =>
    dispatch(toggleActivePromptAction(promptId, value)),
  setCreateMode: (value) => dispatch(setCreateMode(value)),
});

const PromptsContainer = connect(mapStateToProps, mapDispatchToProps)(Prompts);

export default addAbilityToOpenModal(PromptsContainer);
