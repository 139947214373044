// Insights/selectors.js
import { NAME } from './constants';
import { getStateWithOnlyOneLevel } from './../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, NAME);
}

export function getModalVisibility(state) {
  if (getState(state).get('modalVisibility') !== undefined) {
    return getState(state).get('modalVisibility');
  }
  return undefined;
}

export function getModalContent(state) {
  if (getState(state).get('modalContent') !== undefined) {
    return getState(state).get('modalContent');
  }
  return undefined;
}

export function getModalFooter(state) {
  if (getState(state).get('modalFooter') !== undefined) {
    return getState(state).get('modalFooter');
  }
  return undefined;
}

export function getModalClassName(state) {
  if (getState(state).get('modalClassName') !== undefined) {
    return getState(state).get('modalClassName');
  }
  return undefined;
}

export function getCross(state) {
  if (getState(state).get('cross') !== undefined) {
    return getState(state).get('cross');
  }
  return undefined;
}

export function getTitleClassName(state) {
  if (getState(state).get('titleClassName') !== undefined) {
    return getState(state).get('titleClassName');
  }
  return undefined;
}

export function getModalTitle(state) {
  if (getState(state).get('modalTitle') !== undefined) {
    return getState(state).get('modalTitle');
  }
  return undefined;
}

export function getUser(state) {
  if (getState(state).get('user') !== undefined) {
    return getState(state).get('user');
  }
  return undefined;
}

export function getAction(state) {
  if (getState(state).get('action') !== undefined) {
    return getState(state).get('action');
  }
  return undefined;
}

export const getGeneralRolesPermissions = state =>
  getState(state)
    .get('generalRolesPermissions')
    .toArray();

export const getRolesPermissions = state =>
  getState(state)
    .get('rolesPermissions')
    .toArray();
