import React from 'react';
import './AddFilter.css';
import Button from 'react-bootstrap-button-loader';

class AddFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Assignment: 'Assigned to bibs',
      ExamType: '',
      Rank: '',
      allExams: true,
    };
  }

  componentWillMount() {
    if (this.props.filter) {
      this.setState({
        Assignment: this.props.filter.book_status,
        ExamType: this.props.filter.dutystate,
        Rank: this.props.filter.rank,
      });
    }
  }

  onCheckboxChange = () => {
    this.setState({
      allExams: !this.state.allExams,
      ExamType: this.state.allExams ? 'Regular' : '',
      Rank: this.state.allExams ? 'E4' : '',
    });
  };

  renderFilters = (filters, name) => {
    return (
      <div className="filterContainer">
        <div className="filterName">{name}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="boxesContainer">
            {filters.map(filter => (
              <div
                className={
                  filter ===
                  this.state[name === 'Pay grade' ? 'Rank' : name] ? (
                    'filterBox active'
                  ) : (
                    'filterBox'
                  )
                }
                onClick={() => {
                  if (
                    filter === 'Assigned to bibs' &&
                    !this.state.ExamType &&
                    !this.state.Rank
                  )
                    this.setState({
                      [name]: filter,
                      ExamType: 'Regular',
                      Rank: 'E4',
                      allExams: false,
                    });
                  else if (name === 'ExamType') {
                    this.setState({
                      ExamType: filter,
                      Rank: this.state.Rank !== '' ? this.state.Rank : 'E4',
                      allExams: false,
                    });
                  } else if (name === 'Pay grade') {
                    this.setState({
                      Rank: filter,
                      ExamType:
                        this.state.ExamType !== ''
                          ? this.state.ExamType
                          : 'Regular',
                      allExams: false,
                    });
                  } else this.setState({ [name]: filter });
                }}
              >
                {filter}
              </div>
            ))}
          </div>
          {name === 'ExamType' && (
            <label className="label labelAll">
              <input
                ref="checkbox"
                type="checkbox"
                name="allItems"
                checked={this.state.allExams}
                onChange={() => this.onCheckboxChange(name)}
                style={{ marginRight: '5px' }}
              />
              All Exams
            </label>
          )}
        </div>
      </div>
    );
  };

  getFilterData = () => {
    if (!this.props.filter) {
      if (this.state.Assignment === 'Assigned to bibs')
        return {
          book_status: this.state.Assignment,
          dutystate: this.state.ExamType,
          rank: this.state.Rank,
          rate: this.props.rate.id,
        };
      return {
        book_status: this.state.Assignment,
        rate: this.props.rate.id,
      };
    }
    let updatedFilter = this.props.filter;
    if (this.state.Assignment === 'Assigned to bibs') {
      updatedFilter.book_status = this.state.Assignment;
      updatedFilter.dutystate = this.state.ExamType;
      updatedFilter.rank = this.state.Rank;
      updatedFilter.rate = this.props.rate.id;
    } else {
      updatedFilter.book_status = this.state.Assignment;
      updatedFilter.rate = this.props.rate.id;
    }
    return updatedFilter;
  };

  render() {
    return (
      <div className="add-filter-component">
        {this.renderFilters(
          ['Assigned to bibs', 'Not assigned to bibs', 'Archived'],
          'Assignment'
        )}
        {this.state.Assignment === 'Assigned to bibs' && (
          <div className="mTop20 serviceColumn">
            {this.renderFilters(
              ['Regular', 'Substitute', 'Reserve'],
              'ExamType'
            )}
          </div>
        )}
        {this.state.Assignment === 'Assigned to bibs' && (
          <div className="mTop20 rankColumn">
            {this.renderFilters(['E4', 'E5', 'E6', 'E7'], 'Pay grade')}
          </div>
        )}
        <div className="separatorLine" />
        <div className="bottomButtons">
          <Button
            onClick={() => {
              let filter = this.getFilterData();
              this.props.onSubmit(filter);
              this.props.onCancel();
            }}
            className="btn btn-success"
            type="submit"
          >
            {this.props.filter ? 'Update' : 'Add'}
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default AddFilter;
