import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

function PasswordAlertModal({ show, user, updateAdmin, passwordExpired }) {
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [error, setError] = useState({});

  const validatePassword = () => {
    if (!currentPassword) {
      setError({ currentPassword: 'Current password is mandatory' });
      return false;
    }

    if (newPassword.length < 12 || newPassword.length > 30) {
      setError({ newPassword: 'new password must be between 12 and 30 chars' });
      return false;
    }
    const re =
      /^(?=(.*[^A-Za-z0-9]){2,})(?=(.*[A-Z]){2,})(?=(.*\d){2,})(?=(.*[a-z]){2,}).+/;
    const result = re.test(newPassword);
    if (!result) {
      setError({
        newPassword:
          'new password must contain 2 special characters, 2 numbers, 2 upper case, and 2 lower case',
      });
      return false;
    }

    return true;
  };

  const handleChangePassword = () => {
    if (!validatePassword()) return;
    updateAdmin({
      id: user.id,
      password: newPassword,
      currentPassword,
      lastPasswordChange: Date.now(),
    });
  };

  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>Password Update Required</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pe-modal-body">
        <p style={{ marginBottom: '1rem' }}>
          This message will continue to appear until you update your password.
        </p>

        <Form>
          <Form.Group controlId="formNewPassword">
            <Form.Label>Old Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter old password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            {error && error.currentPassword && (
              <Form.Text className="text-danger" style={{ marginTop: '1rem' }}>
                {error.currentPassword}
              </Form.Text>
            )}
            <Form.Label style={{ marginTop: '1rem' }}>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {error && error.newPassword && (
              <Form.Text className="text-danger" style={{ marginTop: '1rem' }}>
                {error.newPassword}
              </Form.Text>
            )}
          </Form.Group>
          <Button
            variant="primary"
            style={{ marginLeft: 0, marginTop: '1rem' }}
            onClick={handleChangePassword}
          >
            Update Password
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default PasswordAlertModal;
