import { all, put, call, takeLatest } from 'redux-saga/effects';
import {
  pagesRolesReceivedAction,
  allRolesReceivedAction,
  pagesReceivedAction,
  actionsReceivedAction,
  errorFetchingFromService,
  createRoleReceivedAction,
  editRoleReceivedAction,
  deleteRoleReceivedAction,
  waiting,
  actionIndicator,
  fetchAllRoles,
} from './actions';
import {
  GET_ALL_ROLES_SAGAS,
  GET_PAGES_SAGAS,
  GET_WORKFLOW_ACTIONS_SAGAS,
  CREATE_ROLE_ACTIONS_SAGAS,
  EDIT_ROLE_ACTIONS_SAGAS,
  DELETE_ROLE_ACTIONS_SAGAS,
  GET_PAGE_ROLES_SAGAS,
} from './actionTypesSagas';
import {
  getAllRoles,
  getPages,
  getWorkflowActions,
  createRole,
  editRole,
  deleteRole,
  getPageRoles,
} from '../../utilities/ServiceManager';
import Notifications from 'react-notification-system-redux';

export function* fetchRoles() {
  try {
    yield put(fetchAllRoles());
    const rolesResponse = yield call(getAllRoles);
    yield put(allRolesReceivedAction(rolesResponse));
  } catch (err) {
    yield put(errorFetchingFromService(err));
  }
}

export function* fetchPageRoles(action) {
  try {
    const pageRoles = yield call(getPageRoles, action.page);
    yield put(pagesRolesReceivedAction(pageRoles, action.page));
  } catch (err) {
    yield put(errorFetchingFromService(err));
  }
}

export function* fetchPages() {
  try {
    const pagesResponse = yield call(getPages);
    yield put(pagesReceivedAction(pagesResponse));
  } catch (err) {
    yield put(errorFetchingFromService(err));
  }
}

export function* fetchActions() {
  try {
    const actionsResponse = yield call(getWorkflowActions);
    yield put(actionsReceivedAction(actionsResponse));
  } catch (err) {
    yield put(errorFetchingFromService(err));
  }
}

export function* createRoleSagas(action) {
  yield put(waiting(true));
  yield put(actionIndicator('creating', true));

  try {
    delete action.role.id;
    const creationResponse = yield call(createRole, action.role);
    yield put(createRoleReceivedAction(creationResponse));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Role created successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (err) {
    yield put(
      Notifications.show({ title: 'Error', message: err.message }, 'error'),
    );
  } finally {
    yield put(waiting(false));
    yield put(actionIndicator('creating', false));
  }
}

export function* editRoleSagas(action) {
  try {
    yield put(waiting(true));
    yield put(actionIndicator('updating', true));
    const editionResponse = yield call(
      editRole,
      action.role.id,
      action.role.name,
      action.role.pagePermissions,
      action.role.workflowActions,
      action.role.showGroup,
    );
    yield put(editRoleReceivedAction(editionResponse));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Role updated successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (err) {
    yield put(
      Notifications.show({ title: 'Error', message: err.message }, 'error'),
    );
  } finally {
    yield put(waiting(false));
    yield put(actionIndicator('updating', false));
  }
}

export function* deleteRoleSagas(action) {
  try {
    yield put(waiting(true));
    yield put(actionIndicator('deleting', true));

    const deletionResponse = yield call(deleteRole, action.role.id);
    yield put(deleteRoleReceivedAction(deletionResponse));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Role deleted successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (err) {
    yield put(
      Notifications.show({ title: 'Error', message: err.message }, 'error'),
    );
  } finally {
    yield put(waiting(false));
    yield put(actionIndicator('deleting', false));
  }
}

export function* watchGetRoles() {
  yield takeLatest(GET_ALL_ROLES_SAGAS, fetchRoles);
}

export function* watchGetPageRoles() {
  yield takeLatest(GET_PAGE_ROLES_SAGAS, fetchPageRoles);
}

export function* watchGetPages() {
  yield takeLatest(GET_PAGES_SAGAS, fetchPages);
}

export function* watchGetActions() {
  yield takeLatest(GET_WORKFLOW_ACTIONS_SAGAS, fetchActions);
}

export function* watchCreateRole() {
  yield takeLatest(CREATE_ROLE_ACTIONS_SAGAS, createRoleSagas);
}

export function* watchEditRole() {
  yield takeLatest(EDIT_ROLE_ACTIONS_SAGAS, editRoleSagas);
}

export function* watchDeleteRole() {
  yield takeLatest(DELETE_ROLE_ACTIONS_SAGAS, deleteRoleSagas);
}

export default function* sagas() {
  yield all([
    watchGetRoles(),
    watchGetPages(),
    watchGetActions(),
    watchCreateRole(),
    watchEditRole(),
    watchDeleteRole(),
    watchGetPageRoles(),
  ]);
}
