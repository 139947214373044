import React from 'react';
import CheckBoxListItem from './CheckBoxListItem';

class CheckBoxList extends React.Component {
  render() {
    return (
      <div>
        {this.props.items &&
          this.props.items.length &&
          this.props.items.map(item => (
            <CheckBoxListItem
              key={item.id}
              item={item}
              onChange={event => this.props.onChange(event.target.name)}
              isChecked={item.checked}
              label={item.name}
              disabled={item.disabled}
            />
          ))}
      </div>
    );
  }
}

export default CheckBoxList;
