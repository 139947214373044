import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'rates');
}

export const getRates = state =>
  getState(state)
    .get('list')
    .toArray();

export const getQuals = state => getState(state).get('listQuals');

export const getTopics = state =>
  getState(state)
    .get('topics')
    .toArray();

export const getTopicTypeCreated = state =>
  getState(state).get('topicTypeCreated');

export const getWaiting = state => getState(state).get('waiting');

export const getPagination = state => getState(state).get('pagination');

export const getPaginationQuals = state =>
  getState(state).get('paginationQuals');

export const getTopicsInmutable = state => getState(state).get('topics');

export const getRate = state => getState(state).get('rate');

export const getLinks = state => getState(state).get('links');

export const getSelectedSP = state => getState(state).get('selectedSP');
