import React from 'react';
import ModalImage from 'react-modal-image';
import _ from 'underscore';

import DropDown from '../../../../commonComponents/DropDown/DropDown';
import ImgIcon from '../../../../commonComponents/Images/default-img.png';
import { SimpleInput } from '../../../../commonComponents/Input/Input';
import ChapterDefinitions from '../../../../commonComponents/Modals/ChapterDefinitions/ChapterDefinitions';
import EditQuestionModal from '../../../../commonComponents/Modals/EditQuestionModal/EditQuestionModal';
import SimpleConfirmation from '../../../../commonComponents/Modals/SimpleConfirmation/SimpleConfirmation';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import { spellCheckContent } from '../../../../lib/spellCheckContent';
import ConvertHtmlToPlainText from '../../../../utilities/ConvertHtmlToPlainText';
import nonModifiedQuestionOrAnswer from '../../../../utilities/nonModifiedQuestionOrAnswer';
import UpdateQuestionIndexModal from './Modals/UpdateQuestionIndexModal/UpdateQuestionIndexModal';

export default class QuestionGridRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isQuestionIndexModalOpen: false,
      questionsCheckDeleted: [],
    };
  }

  spellCheck(content) {
    if (this.props.spellCheckCells) {
      return spellCheckContent(content);
    }
    return content;
  }

  componentDidMount() {
    this.props.setSeletedQuestionsForDeleted('', false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.questionsForDeleted !== prevProps.questionsForDeleted) {
      this.setState({
        questionsCheckDeleted: this.props.questionsForDeleted,
      });
    }
    if (
      this.props.isShowDeletedQuestions !== prevProps.isShowDeletedQuestions
    ) {
      this.props.setSeletedQuestionsForDeleted('', false);
    }

    if (
      this.props.question !== prevProps.question &&
      this.props.modalVisibility
    ) {
      this.openEditionModal(
        this.props.question,
        this.props.question,
        'Question',
        false,
        true,
        this.props.question.multipleOptionMode,
      );
    }
  }

  handleToggleQuestionIndexModal = () => {
    this.setState({
      isQuestionIndexModalOpen: !this.state.isQuestionIndexModalOpen,
    });
  };

  handleChangeQuestionIndex = (question, newIndex) => {
    const questionUpdated = { ...question };
    questionUpdated.presentationIndex = newIndex;
    this.props.onEdit(
      questionUpdated,
      this.props.assignment ? this.props.assignment.id : null,
    );
  };

  getMoveUp = (question) => {
    if (question.presentationIndex === 1) return null;
    const toMove = this.props.questions[question.presentationIndex - 1];
    if (!toMove) return null;
    return {
      name: 'Move up',
      event: () => {
        const questionUpdated = { ...question };
        questionUpdated.presentationIndex = question.presentationIndex - 1;
        this.props.onEdit(
          questionUpdated,
          this.props.assignment ? this.props.assignment.id : null,
        );
      },
      icon: <i className="fa fa-arrow-circle-up fa-lg" />,
      style: { display: 'flex', alignItems: 'center' },
    };
  };

  getMoveDown = (question) => {
    const toMove = this.props.questions[question.presentationIndex];
    if (!toMove) return null;
    return {
      name: 'Move down',
      event: () => {
        const questionUpdated = { ...question };
        questionUpdated.presentationIndex = question.presentationIndex + 1;
        this.props.onEdit(
          questionUpdated,
          this.props.assignment ? this.props.assignment.id : null,
        );
      },
      icon: <i className="fa fa-arrow-circle-down fa-lg" />,
      style: { display: 'flex', alignItems: 'center' },
    };
  };

  getOptions = (question) => {
    if (!this.props.editPermission) {
      return [];
    }
    const moveUp = this.getMoveUp(question);
    const moveDown = this.getMoveDown(question);
    const options = [
      {
        name: 'Move to Index',
        event: () => {
          this.setState({ isQuestionIndexModalOpen: true });
        },
        icon: <i className="fa fa-sort fa-lg" style={{ marginLeft: '13px' }} />,
        style: {
          display: 'flex',
          alignItems: 'center',
          gap: '1.5rem',
        },
      },
      {
        name: question.multipleOptionMode
          ? 'Switch to Flaschard mode'
          : 'Switch to Multiple Option',
        event: () => this.changeMode(),
        icon: <i className="fa fa-exchange fa-lg" />,
        style: { display: 'flex', alignItems: 'center' },
      },
      {
        name: 'Edit definitions',
        event: () => this.openQuestionsModal(question),
        icon: <i className="fa fa-pencil fa-lg" />,
        style: { display: 'flex', alignItems: 'center' },
      },
    ];

    if (this.props.assignment && this.props.assignment.status === 'Submitted')
      options.push({
        name: 'View changes',
        event: () => this.props.openHistoryView(question),
        icon: <i className="fa fa-history fa-lg mt-2" />,
        style: { display: 'flex', alignItems: 'center' },
      });
    if (moveDown) options.unshift(moveDown);
    if (moveUp) options.unshift(moveUp);

    return options;
  };

  changeMode = () => {
    const questionUpdated = this.props.question;
    questionUpdated.multipleOptionMode =
      !this.props.question.multipleOptionMode;
    questionUpdated.flashMode = !this.props.question.flashMode;
    this.props.onEdit(
      questionUpdated,
      this.props.assignment ? this.props.assignment.id : null,
    );
  };

  openQuestionsModal = (question) => {
    this.props.openModal({
      modalClassName: 'modal-question-definitions',
      modalContent: (
        <ChapterDefinitions
          reference={
            this.props.assignment
              ? this.props.assignment.chapter.reference
              : this.props.reference
          }
          question={question}
          closeModal={this.props.closeModal}
          assignment={this.props.assignment}
        />
      ),
    });
  };

  getCorrectAnswer = (question) =>
    question.answers.find((answer) => answer.correct);

  renderAnswer = (
    originalResponse,
    answer,
    width,
    question,
    labelForEditionModal,
    correctAnswer,
    index,
  ) => {
    if (!answer) {
      return '';
    }
    const hasOrder = answer.order !== 0 && answer.order !== null;
    const aiAnswer =
      originalResponse && originalResponse.ai_answer && answer.correct;
    let nonModifiedAnswer = false;
    if (answer && correctAnswer && answer.text === correctAnswer.text) {
      nonModifiedAnswer = nonModifiedQuestionOrAnswer(
        false,
        question,
        correctAnswer,
      );
    }

    return (
      <div
        style={{ width, cursor: 'pointer' }}
        className={hasOrder ? 'tRowWithOrder' : `tRow`}
        onClick={() =>
          this.openEditionModal(answer, question, labelForEditionModal, true)
        }
        key={index}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          {question.multipleOptionMode && hasOrder ? (
            <div className="bold orderAnswer">Order: {answer.order}</div>
          ) : null}
          <div className={aiAnswer ? 'columnDirection' : null}>
            <div style={{ height: '100%' }} className="questionNonModified">
              <div>
                <div className={aiAnswer ? 'rowDirection' : null}>
                  <div
                    className="textContainer"
                    title={answer.text}
                    dangerouslySetInnerHTML={{
                      __html: this.renderText(answer.text),
                    }}
                  />
                </div>
              </div>
            </div>
            {originalResponse &&
              originalResponse.ai_answer &&
              answer.correct && (
                <div style={{ height: '100%' }}>
                  <div className="titleOriginalResponseContainer">
                    <span className="title">Original AI Answer</span>
                  </div>
                  <div className="originalResponseContainerGrid">
                    <span>{originalResponse.ai_answer}</span>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  };

  editQuestion = (newQuestion) => {
    if (
      typeof newQuestion.customPromptId === 'string' &&
      newQuestion.customPromptId !== ''
    ) {
      newQuestion.customPromptId = this.props.userCustomPrompts[0].id;
    }

    this.props.onEdit(
      newQuestion,
      this.props.assignment ? this.props.assignment.id : null,
    );
  };

  openEditionModal = (
    item,
    question,
    label,
    isAnswer = false,
    isQuestion = false,
    multipleOption = false,
    isPresentationIndex = false,
    isEditingPage = false,
    isFurtherReading = label === 'Further reading',
  ) => {
    if (
      this.isQuestionDeleted(question) ||
      this.isQuestionDisabled(question) ||
      !this.props.editPermission
    ) {
      return;
    }
    this.props.openModal({
      modalClassName: 'modal-edit-question',
      modalContent: (
        <EditQuestionModal
          question={question}
          closeModal={this.props.closeModal}
          item={item}
          title={label}
          isAnswer={isAnswer}
          textarea={isAnswer || isQuestion}
          onSubmit={this.editQuestion}
          isQuestion={isQuestion}
          isPresentationIndex={isPresentationIndex}
          assignment={this.props.assignment ? this.props.assignment.id : null}
          multipleOption={multipleOption}
          isEditingPage={isEditingPage}
          setIsCreatingAiQuestion={this.props.setIsCreatingAiQuestion}
          isCreatingAiQuestion={this.props.isCreatingAiQuestion}
          userCustomPrompts={this.props.userCustomPrompts}
          createUserCustomPrompt={this.props.createUserCustomPrompt}
          chapterId={this.props.chapter.id}
          isFurtherReading={isFurtherReading}
          improveQuestionWithAi={this.props.improveQuestionWithAi}
          isSaveQuestion={this.props.isSaveQuestion}
          updateIncludeNotesInSuggestion={
            this.props.updateIncludeNotesInSuggestion
          }
        />
      ),
    });
  };

  getOptionsLabel = (index) => {
    switch (index) {
      case 0:
        return 'Option A';

      case 1:
        return 'Option B';

      case 2:
        return 'Option C';

      default:
        return 'Option D';
    }
  };

  renderImgIcon = (notes) => {
    const hasImg = notes ? notes.includes('img') : null;
    return hasImg
      ? `<img src=${ImgIcon} alt="Has imgs" class="default-img" />`
      : null;
  };

  buildBlueText = (definitions, originalText, htmlText) => {
    let newText = originalText;
    if (!newText) return newText;
    definitions.map((definition) => {
      const position = newText.search(definition.term);
      if (position !== -1) {
        newText = `${newText.substring(
          0,
          position,
        )}<span class="blueText" title="${definition.definition}">${
          definition.term
        }</span>${newText.substring(
          position + definition.term.length,
          newText.length,
        )}`;
      }
      return definition;
    });
    let img;
    if (htmlText) {
      img = this.renderImgIcon(htmlText);
    }
    if (img && htmlText && !this.renderImgIcon(newText))
      newText = `<div class="textWrapper">${newText}</div>${img}`;
    return newText;
  };

  renderText = (text, textHtml = null) => {
    let questionDefinitions;
    if (typeof this.props.question.definitions === 'object') {
      questionDefinitions = this.props.question.definitions
        ? this.props.question.definitions
        : [];
    } else {
      questionDefinitions = this.props.question.definitions
        ? JSON.parse(this.props.question.definitions)
        : [];
    }
    const referenceDefinitions = this.props.reference.definitions
      ? this.props.reference.definitions
      : [];
    if (
      (!questionDefinitions || questionDefinitions.length === 0) &&
      (!referenceDefinitions || referenceDefinitions.length === 0)
    )
      return this.spellCheck(text);
    if (
      questionDefinitions &&
      questionDefinitions.length > 0 &&
      referenceDefinitions.length > 0
    ) {
      return this.spellCheck(
        this.buildBlueText(
          referenceDefinitions,
          this.buildBlueText(questionDefinitions, text, textHtml),
          textHtml,
        ),
      );
    }

    if (
      questionDefinitions &&
      questionDefinitions.length > 0 &&
      referenceDefinitions.length === 0
    )
      return this.spellCheck(
        this.buildBlueText(questionDefinitions, text, textHtml),
      );
    if (
      questionDefinitions &&
      referenceDefinitions.length > 0 &&
      questionDefinitions.length === 0
    )
      return this.spellCheck(
        this.buildBlueText(referenceDefinitions, text, textHtml),
      );
  };

  getQuestionPdfUrl = (question) => {
    if (question.softwarePage) {
      return `${question.chapter.pdfUrl}#page=${question.softwarePage}`;
    }
    return question.chapter.pdfUrl;
  };

  isQuestionDisabled = (question) => {
    let isInAssignment = false;
    let isInCurrentAssignment = false;
    if (this.props.assignment && this.props.assignment.disabledQuestions) {
      isInAssignment = _.pluck(
        this.props.assignment.disabledQuestions,
        'id',
      ).includes(question.id);
    }
    if (
      !this.props.assignment &&
      this.props.chapter &&
      this.props.chapter.assignments &&
      this.props.chapter.assignments.length
    ) {
      isInCurrentAssignment = true;
    }
    return isInAssignment || isInCurrentAssignment;
  };

  isQuestionDeleted = (question) =>
    question && (question.status === 'Deleted' || question.deletedAt);

  answersForRender = (answers) => {
    const index = [2, 3];
    const forSend = answers;
    forSend.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
    let counter = 1;
    let prevId;
    let hasCorrect = false;
    forSend.forEach((e) => {
      if (e.correct === true) hasCorrect = true;
    });
    if (!hasCorrect) forSend.push({ correct: true, order: 0, text: '', id: 1 });
    index.forEach((i) => {
      if (!forSend[i]) {
        prevId = forSend[i - 1] ? forSend[i - 1].id : 0;
        if (counter === 1) {
          forSend.push({ correct: false, order: 0, text: '', id: prevId + 2 });
        } else {
          forSend.push({ correct: false, order: 0, text: '', id: prevId + 3 });
        }
        counter++;
      }
    });
    return forSend;
  };

  getNonEmptyValue = (text, altText) => {
    if (text) {
      return text;
    }
    return altText;
  };

  renderWithoutImages = (furtherReading) => {
    let result = JSON.parse(JSON.stringify(furtherReading));
    if (furtherReading && furtherReading.includes('<img')) {
      const start = furtherReading.indexOf('<img');
      const partial = furtherReading.slice(start);
      const end = partial.indexOf('>');
      result = furtherReading
        .slice(0, -(furtherReading.length - start))
        .concat(partial.slice(end + 1));
    }
    return result;
  };

  getFurtherReadingImage = (furtherReadingImage) => {
    let result = '';
    if (furtherReadingImage && furtherReadingImage.includes('<img')) {
      const start = furtherReadingImage.indexOf('<img') + 10;
      const partial = furtherReadingImage.slice(start);
      const end = partial.indexOf('"');
      result = partial.slice(0, -(partial.length - end));
    }
    return result;
  };

  handleCheckboxDeletedQuestion(question, checkQuestion) {
    let listQuestions = [...this.state.questionsCheckDeleted];
    if (!checkQuestion) {
      listQuestions.push(question);
    } else {
      listQuestions = listQuestions.filter((item) => item.id !== question.id);
    }

    this.setState({ questionsCheckDeleted: listQuestions });
    this.props.setSeletedQuestionsForDeleted(question, checkQuestion);
  }

  render() {
    const { question, isUpdating } = this.props;
    const multipleOption = question.multipleOptionMode;
    const correctAnswer = this.getCorrectAnswer(question);
    const isQuestionDisabled =
      this.isQuestionDeleted(question) || this.isQuestionDisabled(question);
    const isLineThrough = isQuestionDisabled ? ' LineThrough' : '';
    const qAnswers = this.answersForRender(question.answers);
    const nonModifiedQuestion = nonModifiedQuestionOrAnswer(true, question);
    const furtherReadingImage = this.getFurtherReadingImage(question.notes);
    const aiQuestion =
      question.originalResponse && question.originalResponse.ai_question;
    const { questionsCheckDeleted } = this.state;
    const checkQuestion = questionsCheckDeleted.includes(question);
    return (
      <div
        className={`questionRowContainer${isLineThrough}`}
        style={{
          pointerEvents: isUpdating ? 'none' : 'auto',
        }}
      >
        <div
          style={{
            width: '67px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
          className="tRow"
        >
          <div
            onClick={() =>
              this.openEditionModal(
                question,
                question,
                'Presentation Index',
                false,
                false,
                false,
                true,
              )
            }
            title={question.id}
            className="bold"
            style={{ cursor: 'pointer' }}
          >
            <div className="question-id">
              <div className="presentation-index">
                {question.presentationIndex ? question.presentationIndex : 0}
              </div>
              <div className="question-number">{question.id}</div>
            </div>
          </div>
          <SimpleInput
            type="checkbox"
            notMb
            onChange={(e) => {
              this.handleCheckboxDeletedQuestion(question, checkQuestion);
            }}
            value={checkQuestion}
            disabled={this.props.isLoadingQuestions}
          />
        </div>
        <div
          style={{ width: '17%', cursor: 'pointer', paddingLeft: '4px' }}
          className="tRow borderQuestion tRow-no-padding"
        >
          <div className={aiQuestion ? 'columnDirection ' : null}>
            <div
              style={{ height: '100%' }}
              className={nonModifiedQuestion ? 'questionNonModified' : 'p-1 '}
            >
              <div className={aiQuestion ? 'rowDirection' : null}>
                {question.imageUrl ? (
                  <ModalImage
                    small="/img/image-regular.png"
                    large={question.imageUrl}
                    className="image-question-preview"
                    hideDownload
                  />
                ) : (
                  ''
                )}
                <div
                  onClick={() =>
                    this.openEditionModal(
                      question,
                      question,
                      'Question',
                      false,
                      true,
                      multipleOption,
                    )
                  }
                >
                  {multipleOption ? (
                    <div
                      className="divContent"
                      style={{ width: '100%' }}
                      title={ConvertHtmlToPlainText(
                        this.getNonEmptyValue(
                          question.text,
                          question.flashQuestionText,
                        ),
                      )}
                      dangerouslySetInnerHTML={{
                        __html: this.renderText(
                          this.getNonEmptyValue(
                            question.text,
                            question.flashQuestionText,
                          ),
                        ),
                      }}
                    />
                  ) : (
                    <div
                      className="divContent"
                      style={{ width: '100%' }}
                      title={ConvertHtmlToPlainText(
                        this.getNonEmptyValue(
                          question.flashQuestionText,
                          question.text,
                        ),
                      )}
                      dangerouslySetInnerHTML={{
                        __html: this.renderText(
                          this.getNonEmptyValue(
                            question.flashQuestionText,
                            question.text,
                          ),
                        ),
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            {aiQuestion && (
              <div style={{ height: '100%' }}>
                <div
                  className="titleOriginalResponseContainer"
                  style={{ marginLeft: '-4px' }}
                >
                  <span className="title">Original AI Question</span>
                </div>
                <div
                  className="originalResponseContainerGrid"
                  style={{ marginLeft: '-4px' }}
                >
                  {question.originalResponse.ai_question && (
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: question.originalResponse.ai_question,
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {multipleOption &&
          qAnswers
            .sort((x, y) => y.correct - x.correct)
            .map((answer, index) =>
              this.renderAnswer(
                question.originalResponse,
                answer,
                '13%',
                question,
                this.getOptionsLabel(index),
                correctAnswer,
                index,
              ),
            )}

        {!multipleOption &&
          this.renderAnswer(
            question.originalResponse,
            correctAnswer,
            '52%',
            question,
            'Answer',
            correctAnswer,
          )}
        <div
          style={{ width: '60px', wordBreak: 'break-all', cursor: 'pointer' }}
          className="tRow"
          onClick={() =>
            this.openEditionModal(
              question,
              question,
              'Software page',
              false,
              false,
              false,
              false,
              true,
            )
          }
        >
          {question.softwarePage}
        </div>
        <div
          style={{ width: '60px', wordBreak: 'break-all', cursor: 'pointer' }}
          className="tRow"
          onClick={() =>
            this.openEditionModal(
              question,
              question,
              'Document page',
              false,
              false,
              false,
              false,
              true,
            )
          }
        >
          {question.documentPage}
        </div>
        <div
          style={{ width: '17%', cursor: 'pointer' }}
          className="tRow"
          onClick={() =>
            this.openEditionModal(
              question,
              question,
              'Further reading',
              false,
              false,
              multipleOption,
            )
          }
        >
          <div style={{ width: '100%' }}>
            <div
              className="further-reading"
              style={{ width: '100%' }}
              title={ConvertHtmlToPlainText(question.notes)}
              dangerouslySetInnerHTML={{
                __html: this.spellCheck(
                  this.renderWithoutImages(question.notes),
                ),
              }}
            />
            {furtherReadingImage ? (
              <div className="furtherreading">
                <ModalImage
                  small="/img/image-regular.png"
                  large={furtherReadingImage}
                  className="image-question-preview"
                  hideDownload
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div
          style={{
            width: '36px',
            overflow: 'visible',
          }}
          className="tRow"
        >
          <DropDown
            style={{
              top: '-20px',
              position: 'absolute',
              left: '-160px',
              zIndex: 10000,
            }}
            title={question.id}
            options={this.getOptions(question)}
            furtherReadingOption
          />
        </div>
        {this.state.isQuestionIndexModalOpen ? (
          <UpdateQuestionIndexModal
            isQuestionIndexModalOpen={this.state.isQuestionIndexModalOpen}
            handleToggleQuestionIndexModal={this.handleToggleQuestionIndexModal}
            question={question}
            handleChangeQuestionIndex={this.handleChangeQuestionIndex}
            amountOfQuestions={this.props.amountOfQuestions}
          />
        ) : null}
      </div>
    );
  }
}
