import * as t from './actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  definitions: [],
  waiting: false,
  questionDefinitions: [],
  updatedDefinitions: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case t.SET_UPDATED_DEFINITIONS:
      return state.set('updatedDefinitions', action.definitions);

    case t.SET_DEFINITIONS:
      return state.set('definitions', action.definitions.data);

    case t.SET_QUESTION_DEFINITIONS:
      return state.set('questionDefinitions', action.definitions.data);

    case t.CLEAR_DEFINITIONS:
      return state.set('definitions', []).set('questionDefinitions', []);

    case t.UPDATE_WAITING:
      return state.set('waiting', action.waiting);

    case t.REMOVE_DEFINITION:
      let filteresDefinitions = state
        .get('definitions')
        .filter(definition => definition.id !== action.id);
      return state.set('definitions', filteresDefinitions);

    case t.UPDATE_DEFINITION:
      let defsUpdated = state.get('definitions').map(def => {
        if (def.id === action.item.id) {
          return action.item;
        }
        return def;
      });
      return state.set('definitions', defsUpdated);

    case t.ADD_DEFINITION:
      return state.set(
        'definitions',
        state.get('definitions').concat([action.item])
      );

    case t.REMOVE_QUESTION_DEFINITION:
      let filteresQDefinitions = state
        .get('questionDefinitions')
        .filter(definition => definition.id !== action.id);
      return state.set('questionDefinitions', filteresQDefinitions);

    case t.UPDATE_QUESTION_DEFINITION:
      let defsQUpdated = state.get('questionDefinitions').map(def => {
        if (def.id === action.item.id) {
          return action.item;
        }
        return def;
      });
      return state.set('questionDefinitions', defsQUpdated);

    case t.ADD_QUESTION_DEFINITION:
      return state.set(
        'questionDefinitions',
        state.get('questionDefinitions').concat([action.item])
      );

    default:
      return state;
  }
}
