import { connect } from 'react-redux';
import newCouponsList from '../components/newCouponsList';
import addAbilityToOpenModal from '../../../utilities/modalHOC';
import {
  loadNewCouponsAction,
  createNewCoupon,
  listPlansSubscriptions,
} from '../../Coupons/actionsSagas';
import { getNewCoupons, getDeleteCoupon } from '../../Coupons/selectors';
import {
  setCouponDetail,
  setLoadCoupons,
  setDeleteCoupon,
} from '../../Coupons/actions';
import {
  getCouponDetail,
  getLoadCoupons,
  setPlansSubscriptions,
} from '../../Coupons/selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    newCoupons: getNewCoupons(state),
    couponDetail: getCouponDetail(state),
    loadCoupons: getLoadCoupons(state),
    plansSubscriptions: setPlansSubscriptions(state),
    deleteCoupon: getDeleteCoupon(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadNewCoupons: () => dispatch(loadNewCouponsAction()),
    setCouponDetail: value => dispatch(setCouponDetail(value)),
    createNewCoupon: value => dispatch(createNewCoupon(value)),
    loadCouponAction: value => dispatch(setLoadCoupons(value)),
    listPlansSubscriptions: () => dispatch(listPlansSubscriptions()),
    setDeleteCoupon: value => dispatch(setDeleteCoupon(value)),
  };
};

const newCouponListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(newCouponsList);

export default addAbilityToOpenModal(newCouponListContainer);
