import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import Cookies from 'universal-cookie';

import addAbilityToOpenModal from '../../../utilities/modalHOC';
import AuthyForm from '../../Login/components/AuthyForm';
import ResetPasswordStateless from './ResetPasswordStateless';

const cookies = new Cookies();

class ResetPasswordStateful extends React.Component {
  state = {
    successMessageClassName: 'show',
    errorMessage: '',
    use2FA: false,
  };

  static propTypes = {
    handleSubmit: PropTypes.func,
    resetPasswordForgotRequest: PropTypes.func,
    ResetPassword: PropTypes.shape({
      requesting: PropTypes.bool,
      successful: PropTypes.bool,
      messages: PropTypes.array,
      errors: PropTypes.array,
    }),
  };

  componentDidMount() {
    this.props.verifyLoggedAdmin();
    this.props.resetErrors();
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.resetRequest.successful &&
      this.props.errors !== nextProps.resetRequest.errors
    ) {
      this.setState({ errors: nextProps.resetRequest.errors });
    }
  }

  componentWillUnmount() {
    cookies.remove('token2');
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.resetRequest.errorMessage &&
      nextProps.resetRequest.errorMessage
    ) {
      this.setState({
        errorMessage: 'Something goes wrong. Please contact the support',
      });
    } else if (
      !this.props.resetRequest.requestResponse &&
      nextProps.resetRequest.requestResponse
    ) {
      if (nextProps.resetRequest.requestResponse.use2FA === 'use 2FA') {
        this.setState({ use2FA: true });
      } else {
        this.props.loginAs(nextProps.resetRequest.requestResponse.user.id);
        this.props.loginAs(nextProps.resetRequest.requestResponse.user.id);
      }
      // this.setState({
      //   errorMessage: 'Something goes wrong. Please contact the support',
      // });
    }
  }

  componentWillMount() {
    const token = window.location.hash.substr(2);
    if (token) cookies.set('token2', token);
  }

  validatePassword = (values) => {
    if (
      !values.password ||
      values.password.length < 12 ||
      values.password.length > 30
    ) {
      this.setState({
        errorMessage: 'Password must be between 12 and 30 characters long',
      });
      return false;
    }

    const re =
      /^(?=(.*[^A-Za-z0-9]){2,})(?=(.*[A-Z]){2,})(?=(.*\d){2,})(?=(.*[a-z]){2,}).+/;
    if (!re.test(values.password)) {
      this.setState({
        errorMessage:
          'Password must 2 special characters, 2 numbers, 2 upper case, and 2 lower case',
      });
      return false;
    }
    if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      this.setState({ errorMessage: 'Passwords does not match' });
      return false;
    }
    if (this.props.resetRequest && this.props.resetRequest.errorMessage) {
      this.setState({
        errorMessage: 'Something goes wrong. Please contact the support',
      });
      return false;
    }
    this.setState({ password: values.password });
    return true;
  };

  authyVerificate = (values) => {
    const code = values.code;
    const password = this.state.password;
    const confirmPassword = this.state.password;
    this.props.resetPasswordForgotRequest({
      password,
      confirmPassword,
      token: cookies.get('token2'),
      use2FA: true,
      code,
    });
  };

  resendCode = () => {
    const email = this.props.resetRequest.requestResponse.email;

    this.props.resendCodeRequest(email);
  };

  resetStatus = () => {
    this.props.resetStatus();
  };

  submit = (values) => {
    if (this.validatePassword(values)) {
      this.props.resetPasswordForgotRequest({
        password: values.password,
        confirmPassword: values.confirmPassword,
        token: cookies.get('token2'),
      });
      if (this.props.resetRequest && this.props.resetRequest.errorMessage) {
        this.setState({
          errorMessage: 'Something goes wrong. Please contact the support',
        });
      }
      // else {
      //   this.props.loginAs(119);
      //   this.props.loginAs(119);
      //   // browserHistory.push('/home/wheelhouse/assignments');
      // }
    }
  };

  hideSuccessMessage = () => {
    this.setState({ successMessageClassName: 'hidden' });
  };

  toggleModal = () => {
    this.props.toggleModalVisibility({ modalVisibility: false });
    this.setState({ errors: [] });
  };

  render() {
    const {
      handleSubmit,
      resetRequest: { requesting, messages, successful, errors },
    } = this.props;

    const { use2FA } = this.state;

    return (
      <div className="container reset-password">
        {!use2FA ? (
          <ResetPasswordStateless
            errorMessage={this.state.errorMessage}
            handleSubmit={handleSubmit}
            submit={this.submit}
            requesting={requesting}
            messages={messages}
            errors={this.state.errors}
            successful={successful}
            hideSuccessMessage={this.hideSuccessMessage}
            successMessageClassName={this.state.successMessageClassName}
          />
        ) : (
          <AuthyForm
            handleSubmit={handleSubmit}
            submit={this.authyVerificate}
            requesting={requesting}
            errors={errors}
            resendCode={this.resendCode}
            resetStatus={this.resetStatus}
          />
        )}
      </div>
    );
  }
}

export default addAbilityToOpenModal(ResetPasswordStateful);
