import './Assignemnts.css';

import React from 'react';
import { Col } from 'react-bootstrap';

import Spinner from '../../../../commonComponents/Spinner/Spinner';
import {
  getStatusByName,
  TablesStatusFilters,
} from '../../../../constants/assignments';
import NumberUtils from '../../../../lib/NumberUtils';
import { hasPagePermission } from '../../../../utilities/FilterLinksForTabs';
import WritersContainer from '../../../Writers/components/WritersContainer';
import Table from './Table/Table';

export default class Assignemnts extends React.Component {
  componentWillMount() {
    this.props.toggleQuestionOverview(false);
    if (!this.props.assignments) {
      this.props.updateLoading(true);
      this.onLoadAssignments(
        this.props.selectedAdmin,
        this.props.activeStatus,
        this.props.selectedRate,
      );
    }
  }

  componentWillReceiveProps({
    selectedAdmin,
    activeStatus,
    selectedRate,
    isChangingType,
    awaitingApproval,
    isReloadAdminAssignments,
  }) {
    if (
      (!awaitingApproval &&
        (selectedAdmin?.id !== this.props.selectedAdmin?.id ||
          activeStatus !== this.props.activeStatus ||
          selectedRate?.id !== this.props.selectedRate?.id) &&
        !isChangingType) ||
      this.props.isChangingType !== isChangingType
    ) {
      this.props.updateLoading(true);
      this.onLoadAssignments(
        selectedAdmin,
        activeStatus === 'Completed' ? 'Published' : activeStatus,
        selectedRate,
      );
    }
    if (isReloadAdminAssignments) {
      this.props.setReloadAdminAssignments(false);
      this.onLoadAssignments(selectedAdmin, 'Published', selectedRate);
    }

    // if (noGroups) this.props.updateLoading(false);
  }

  onLoadAssignments = (selectedAdmin, activeStatus, selectedRate) => {
    if (activeStatus && selectedAdmin && selectedRate) {
      const selectedSP = {
        typeId: selectedRate.type ? selectedRate.typeId : selectedRate.id,
        type: selectedRate.type
          ? selectedRate.type
          : selectedRate.isQual
            ? 'qual'
            : 'rate',
      };
      this.props.onLoadAssignments(selectedAdmin.id, activeStatus, selectedSP);
    }
  };

  showAssignModal = () => {
    this.props.openModal({
      modalContent: (
        <WritersContainer
          title="Assign"
          closeModal={this.props.closeModal}
          rate={this.props.selectedRate.id}
          isQual={this.props.selectedRate.isQual}
          forAssign
          selectedUser={this.props.selectedAdmin.id}
        />
      ),
      modalClassName: 'modal-create-chapter grey-modal-body',
    });
  };

  getActionButtons = () => {
    const permissions = this.props.rolesPermissions;
    const pagePermissions = this.props.generalRolesPermissions;
    let permission;
    if (this.props.selectedRate) {
      permission = permissions.find(
        (permission) =>
          Number(permission.typeId) === this.props.selectedRate.id,
      );
    }
    if (
      this.props.user.isSuperAdmin ||
      (permission &&
        permission.actions.find((action) => action === 'Assignment.Assign')) ||
      hasPagePermission('Wheelhouse.Assignments', pagePermissions)
    ) {
      if (!this.props.noGroups && this.props.myStudyPrograms.length === 0)
        return (
          <div className="assignmentsBtn" onClick={this.showAssignModal}>
            <div className="assignmentsBtnText">Assign</div>
          </div>
        );
    }
  };

  getStatusCount(status) {
    if (this.props.assignmentsCount.length > 0) {
      const value = this.props.assignmentsCount.find((count) => {
        if (count.name === status) return count;
        if (status === 'In progress' && count.name === 'In_Progress')
          return count;
        if (status === 'Not Started' && count.name === 'Not_Started')
          return count;
        if (status === 'Awaiting approval' && count.name === 'Submitted')
          return count;
        return null;
      });
      if (value) {
        return value.count;
      }
      return 0;
    }
    return 0;
  }

  renderTables() {
    if (this.props.activeStatus === 'Completed') {
      const assignments = this.props.assignments.filter(
        (assignment) => assignment.status === 'Published',
      );
      return assignments.length > 0 ? (
        <Table
          {...this.props}
          assignments={assignments}
          activeStatus="Completed"
          loadingCreateGPTquestions={this.props.loadingCreateGPTquestions}
          summaries={this.props.summaries}
          summary={this.props.summary}
          setLoadingCreateGPTquestion={this.props.setLoadingCreateGPTquestion}
        />
      ) : (
        <div className="noAssignmentsText">No assignments to show</div>
      );
    }
    return TablesStatusFilters.map((filter, index) => {
      const count = this.getStatusCount(filter);
      return (
        <div
          className={index !== 0 ? 'statusTableContainer' : ''}
          key={NumberUtils.obtainUniqueKey()}
        >
          <div className="filterName">{`${filter}: ${count}`}</div>
          {count > 0 ? (
            <Table
              {...this.props}
              assignments={this.props.assignments.filter(
                (assignment) =>
                  assignment.status ===
                  getStatusByName(
                    filter === 'Awaiting approval' ? 'Submitted' : filter,
                  ),
              )}
              activeStatus={
                filter === 'Awaiting approval' ? 'Submitted' : filter
              }
              openModal={this.props.openModal}
              loadingCreateGPTquestions={this.props.loadingCreateGPTquestions}
              summaries={this.props.summaries}
              summary={this.props.summary}
              setLoadingCreateGPTquestion={
                this.props.setLoadingCreateGPTquestion
              }
            />
          ) : (
            <div className="noAssignmentsText">No assignments to show</div>
          )}
        </div>
      );
    });
  }

  render() {
    return (
      <Col md={11} className="centeredCol">
        {this.getActionButtons()}
        <div
          style={{
            clear: 'both',
            width: '100%',
            margin: '0',
            paddingTop: '15px',
            textAlign: 'center',
          }}
          className="assignmentsTables"
        >
          {this.props.loading ? (
            <Spinner text="Loading assignments" />
          ) : (
            this.renderTables()
          )}
        </div>
      </Col>
    );
  }
}
