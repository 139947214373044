// Insights/actions.js
import * as t from './actionTypes';

export const toggleModalVisibilityAction = (modalContent) => ({
  type: t.TOGGLEMODALVISIBILITY,
  payload: modalContent,
});

export const openModal = (configuration) => ({
  type: t.OPEN_MODAL,
  configuration,
});

export const closeModal = () => ({
  type: t.CLOSE_MODAL,
});
