import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { reduxForm } from 'redux-form';

class DefinitionsListRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      errors: null,
      definition: {
        term: this.props.item.term,
        definition: this.props.item.definition,
      },
    };
  }

  validate = (fields, callback) => {
    let errors = {};

    if (!fields.term) {
      errors.term = 'This field cant be empty';
    }

    if (!fields.definition) {
      errors.definition = 'This field cant be empty';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: null, waiting: true });
    callback(fields);
  };

  toggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing, errors: null });
  };

  onEdit = item => {
    if (this.props.editPermission) {
      item.id = this.props.item.id;
      this.props.onEdit(
        item,
        this.props.assignment ? this.props.assignment.id : null,
      );
      this.toggleEdit();
    } else {
      this.props.showNotification('Denied', 'Not enough permissions', 'error');
    }
  };
  onDelete = () => {
    if (this.props.editPermission) {
      this.props.onDelete(
        this.props.isQuestion ? this.props.item : this.props.item.id,
        this.props.assignment ? this.props.assignment.id : null,
      );
    } else {
      this.props.showNotification('Denied', 'Not enough permissions', 'error');
    }
  };

  getItem = () => {
    return (
      <Row className={this.props.style}>
        <Col sm={5}>
          <div className="definition-text">{this.props.item.term}</div>
        </Col>
        <Col sm={5}>
          <div className="definition-text">{this.props.item.definition}</div>
        </Col>
        {!this.props.readOnly && (
          <Col sm={2}>
            <Row style={{ border: 'none' }}>
              <i
                className="fa fa-pencil fa-lg mt-1"
                onClick={this.toggleEdit}
              />
              <i className="fa fa-trash fa-lg mt-1" onClick={this.onDelete} />
            </Row>
          </Col>
        )}
      </Row>
    );
  };

  submitForm = () => {
    if (this.props.editPermission) {
      this.validate(this.state.definition, this.onEdit);
    } else {
      this.props.showNotification('Denied', 'Not enough permissions', 'error');
    }
  };

  onKeyDown = ev => {
    ev.keyCode === 13 && this.submitForm();
  };

  onInputChange = event => {
    let definition = this.state.definition;
    definition[event.target.name] = event.target.value;
    this.setState({ definition: definition });
  };

  getEditingItem = () => {
    return (
      <Row className={this.props.style}>
        <Col sm={5}>
          <input
            name="term"
            className="form-control"
            type="text"
            placeholder="Term"
            defaultValue={this.props.item.term}
            onKeyDown={this.onKeyDown}
            onChange={this.onInputChange}
          />
          {this.state.errors && this.state.errors['term'] && (
            <span className="errors">{this.state.errors['term']}</span>
          )}
        </Col>
        <Col sm={5}>
          <input
            name="definition"
            className="form-control"
            type="text"
            placeholder="Definition"
            defaultValue={this.props.item.definition}
            onKeyDown={this.onKeyDown}
            onChange={this.onInputChange}
          />
          {this.state.errors && this.state.errors['definition'] && (
            <span className="errors">{this.state.errors['definition']}</span>
          )}
        </Col>
        <Col sm={2} className="editableButtons">
          <i className="fa fa-check fa-lg mt-1" onClick={this.submitForm} />
          <i className="fa fa-times fa-lg mt-1" onClick={this.toggleEdit} />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div>{this.state.isEditing ? this.getEditingItem() : this.getItem()}</div>
    );
  }
}

DefinitionsListRow.propTypes = {
  item: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default reduxForm({ form: 'DefinitionsListRow' })(DefinitionsListRow);
