import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';

import Spinner from '../../../../commonComponents/Spinner/Spinner';
import { infinitePageSize } from '../../../../constants/pagination';
import { getSelectedAssignment } from '../../../Assignments/selectors';
import { loadQuestionsOfChapter } from '../../actionsSagas';
import {
  getChapter,
  getIsLoadingQuestions,
  getIsShowDeletedQuestions,
  getPagination,
  getQuestions,
} from '../../selectors';

class QuestionBirectionalList extends React.Component {
  areThereMoreQuestions(pagination, questions) {
    return !(pagination.total <= questions.length);
  }

  loadFunc = (
    notClearOtherQuestions = true,
    paginationPage,
    isFromTop = false,
  ) => {
    const { assignment, chapter, pagination, isLoadingQuestions, questions } =
      this.props;
    if (
      !isLoadingQuestions &&
      (this.areThereMoreQuestions(pagination, questions) || isFromTop)
    ) {
      this.props.loadQuestionsOfChapter(
        chapter.id,
        paginationPage || pagination.page + 1,
        infinitePageSize,
        assignment ? assignment.id : null,
        notClearOtherQuestions,
        null,
        this.props.isViewDeletedQuestions,
      );
    }
  };

  loadTopFunc = () => {
    const { questions, isLoadingQuestions } = this.props;

    if (
      !isLoadingQuestions &&
      questions &&
      questions[0] &&
      questions[0].presentationIndex > 1
    ) {
      const page =
        Math.ceil(questions[0].presentationIndex / infinitePageSize) - 1;
      this.loadFunc(true, page, true);
    }
  };

  render() {
    const { isLoadingQuestions, pagination, questions } = this.props;
    return (
      <div style={{ height: '96%', overflow: 'auto' }}>
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadFunc}
          hasMore={
            !isLoadingQuestions &&
            this.areThereMoreQuestions(pagination, questions)
          }
          useWindow={false}
        >
          {isLoadingQuestions && <Spinner />}
          {this.props.children}
          {questions?.length > 0 && isLoadingQuestions && <Spinner />}
        </InfiniteScroll>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  chapter: getChapter(state),
  pagination: getPagination(state),
  assignment: getSelectedAssignment(state),
  isLoadingQuestions: getIsLoadingQuestions(state),
  questions: getQuestions(state),
  isShowDeletedQuestions: getIsShowDeletedQuestions(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadQuestionsOfChapter: (
    id,
    page,
    rows,
    assignment,
    isInfiniteScroll,
    skip,
    isShowDeletedQuestions,
  ) => {
    if (id) {
      dispatch(
        loadQuestionsOfChapter(
          id,
          page,
          rows,
          assignment,
          isInfiniteScroll,
          skip,
          isShowDeletedQuestions,
        ),
      );
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionBirectionalList);
