import * as type from './actionTypes';

export const setAdminByRate = (admins, changedType) => ({
  type: type.SET_ADMINS_BY_RATE,
  admins,
  changedType,
});

export const setAdminByQual = (admins, changedType) => ({
  type: type.SET_ADMINS_BY_QUAL,
  admins,
  changedType,
});

export const setActiveStatus = (status) => ({
  type: type.SET_ACTIVE_STATUS,
  status,
});

export const setSelectedAdmin = (admin) => ({
  type: type.SET_SELECTED_ADMIN,
  admin,
});

export const setSelectedRate = (rate) => ({
  type: type.SET_SELECTED_RATE,
  rate,
});

export const setSelectedAdminAndRate = (admin, rate) => ({
  type: type.SET_SELECTED_ADMIN_RATE,
  admin,
  rate,
});

export const updateAssignmentDueDate = (assignment) => ({
  type: type.UPDATE_ASSIGNMENT_DUE_DATE,
  assignment,
});

export const updateAssignmentStatus = (assignment) => ({
  type: type.UPDATE_ASSIGNMENT_STATUS,
  assignment,
});

export const setAssignments = (assignments) => ({
  type: type.SET_ASSIGNMENTS,
  assignments,
});

export const setIsViewRates = (isViewRates) => ({
  type: type.SET_IS_VIEW_RATES,
  isViewRates,
});

export const addAssignment = (assignment) => ({
  type: type.ADD_ASSIGNMENT,
  assignment,
});

export const onShowQuestionDetails = (assignment) => ({
  type: type.SHOW_QUESTION_DETAILS,
  assignment,
});

export const clearSelectedAssignment = () => ({
  type: type.CLEAR_SELECTED_ASSIGNMENT,
});

export const onShowQuestionOverview = (assignment, show = true) => ({
  type: type.SHOW_QUESTION_OVERVIEW,
  assignment,
  show,
});

export const setAssignmentUpdate = (assignment) => ({
  type: type.ASSIGNMENT_UPDATE,
  assignment,
});

export const updateAssignmentsAfterDelete = (assignment) => ({
  type: type.ASSIGNMENT_UPDATE_DELETE,
  assignment,
});

export const updateAssignmentsAfterRejection = (assignment) => ({
  type: type.ASSIGNMENT_UPDATE_REJECTION,
  assignment,
});

export const updateAssignmentsAfterComplete = (assignment) => ({
  type: type.ASSIGNMENT_UPDATE_COMPLETE,
  assignment,
});

export const setReloadAdminAssignments = (isReloadAdmin) => ({
  type: type.IS_RELOAD_ADMIN_ASSIGNMENT,
  isReloadAdmin,
});

export const setIsChangingType = (value) => ({
  type: type.SET_CHANGE_TYPE,
  value,
});

export const setLoading = (loading) => ({
  type: type.SET_LOADING,
  loading,
});

export const setAssignmentsCount = (count) => ({
  type: type.SET_ASSIGNMENTS_COUNT,
  count,
});

export const setAwaitingForApproval = (value) => ({
  type: type.SET_AWAITING_APPROVAL,
  value,
});

export const toggleQuestionOverview = (value) => ({
  type: type.TOGGLE_QUESTION_OVERVIEW,
  value,
});

export const updateStudyProgramAdmins = (admins) => ({
  type: type.UPDATE_SP_ADMINS,
  admins,
});

export const noGroups = (value) => ({
  type: type.NO_GROUPS,
  value,
});

export const setAdminScroll = (scroll) => ({
  type: type.SET_ADMIN_SCROLL,
  scroll,
});

export const setIsBackFromQuestion = (isBackFromQuestion) => ({
  type: type.SET_IS_BACK_FROM_QUESTION,
  isBackFromQuestion,
});

export const setMyStudyPrograms = (sp) => ({
  type: type.SET_MY_SP,
  sp,
});

export const setLoadingMySP = (isLoading) => ({
  type: type.SET_LOADING_MY_SP,
  isLoading,
});

export const setWriters = (writers) => ({
  type: type.SET_WRITERS,
  writers,
});

export const setSelectedWriter = (writer) => ({
  type: type.SET_SELECTED_WRITER,
  writer,
});

export const setAssignModalWaiting = (value) => ({
  type: type.SET_ASSIGN_MODAL_WAITING,
  value,
});

export const setAssignModalSuccess = (value) => ({
  type: type.SET_ASSIGN_MODAL_SUCCESS,
  value,
});

export const setAssignModalError = (value) => ({
  type: type.SET_ASSIGN_MODAL_ERROR,
  value,
});

export const setAssignModalReferences = (references) => ({
  type: type.SET_ASSIGN_MODAL_REFERENCES,
  references,
});

export const setAssignModalRates = (rates) => ({
  type: type.SET_ASSIGN_MODAL_RATES,
  rates,
});

export const setAssignModalSelectedRate = (selectedRate) => ({
  type: type.SET_ASSIGN_MODAL_SELECTED_RATE,
  selectedRate,
});

export const setAssignModalLoadingReferences = (value) => ({
  type: type.SET_ASSIGN_MODAL_LOADING_REFERENCES,
  value,
});

export const setLoadingCreateGPTquestion = (value) => ({
  type: type.LOADING_CREATE_GPT_QUESTIONS,
  value,
});

export const setLoadingSummaries = (value) => ({
  type: type.LOADING_GET_SUMMARIES,
  value,
});

export const setSummariesActions = (summaries) => ({
  type: type.SET_SUMMARIES_ACTION,
  summaries,
});

export const setLoadingSummary = (value) => ({
  type: type.SET_LOADING_SUMMARY,
  value,
});

export const setSummary = (summary) => ({
  type: type.SET_SUMMARY,
  summary,
});
