import * as t from './actionTypes';
import { Map, List } from 'immutable';

const initialState = Map({
  roles: List([]),
  pages: List([]),
  workflowActions: List([]),
  waiting: false,
  creating: false,
  editing: false,
  updating: false,
  selectedRole: {},
  checks: [],
  roleName: '',
  newRoleMode: false,
  pagePagination: { total: 1, page: 1 },
  isLoading: false,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case t.TOGGLE_NEW_ROLE:
      return state.set('newRoleMode', action.payload);

    case t.FETCH_ALL_ROLES:
      return state.set('isLoading', true);
    case t.UPDATE_CHECKS:
      return state.set('checks', action.payload);

    case t.UPDATE_ROLE_NAME:
      return state.set('roleName', action.payload);

    case t.SET_SELECTED_ROLE:
      return state.set('selectedRole', action.payload);

    case t.ALLROLESRECEIVED:
      return state
        .set('roles', List(action.payload.data))
        .set('isLoading', false);

    case t.PAGEROLESRECEIVED:
      return state
        .set('roles', List(action.payload.data))
        .set('pagePagination', {
          total: action.payload.pagination.total,
          page: action.page,
        })
        .set('isLoading', false);

    case t.PAGESRECEIVED:
      return state.set('pages', List(action.payload));

    case t.ACTIONSRECEIVED:
      return state.set('workflowActions', List(action.payload));

    case t.CREATEROLE:
      let roles = state.get('roles').push(action.payload);
      return state.set('roles', roles);

    case t.EDITROLE:
      let rolesUpdated = state
        .get('roles')
        .map(role => (role.id === action.payload.id ? action.payload : role));
      return state.set('roles', rolesUpdated);

    case t.DELETEROLE:
      let rolesUpdatedFromDeletion = state
        .get('roles')
        .filter(role => role.id !== action.payload.id);

      return state.set('roles', rolesUpdatedFromDeletion);

    case t.UPDATE_WAITING:
      return state.set('waiting', action.waiting);

    case t.UPDATE_ACTION_INDICATOR:
      return state.set(action.indicator, action.status);

    case t.SET_SELECTED_PAGE:
      return state.set('selectedRole', action.payload);

    default:
      return state;
  }
}
