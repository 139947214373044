import * as type from './actionTypes';

export const setSelectedCoupon = (coupon) => ({
  type: type.SET_COUPON,
  coupon,
});

export const setCoupons = (coupons) => ({
  type: type.SET_COUPONS,
  coupons,
});

export const setCodeGroups = (codeGroups) => ({
  type: type.SET_CODE_GROUPS,
  codeGroups,
});

export const setNewCoupons = (newCoupons) => ({
  type: type.SET_NEW_COUPONS,
  newCoupons,
});

export const setLoadCoupons = (value) => ({
  type: type.SET_LOAD_COUPONS,
  value,
});

export const setCouponDetail = (nameCoupon) => ({
  type: type.SET_COUPON_DETAIL,
  nameCoupon,
});

export const setDeleteCoupon = (value) => ({
  type: type.SET_DELETE_COUPON,
  value,
});

export const setCodeGroup = (codeGroup) => ({
  type: type.SET_CODE_GROUP,
  codeGroup,
});

export const setLoadingCodeGroups = (isLoading) => ({
  type: type.SET_IS_LOADING_CODE_GROUPS,
  isLoading,
});

export const setGettingCodeGroup = (isLoading) => ({
  type: type.SET_IS_GETTING_CODE_GROUP,
  isLoading,
});

export const addCodeGroup = (codeGroup) => ({
  type: type.ADD_CODE_GROUPS,
  codeGroup,
});

export const cancelAllCodes = (codeGroup) => ({
  type: type.CANCELL_ALL_CODES,
  codeGroup,
});

export const updateCodeGroup = (codeGroup) => ({
  type: type.UPDATE_CODE_GROUP,
  codeGroup,
});

export const addCoupon = (coupon) => ({
  type: type.ADD_COUPON,
  coupon,
});

export const updateNewCoupons = (coupon) => ({
  type: type.UPDATE_NEW_COUPONS,
  coupon,
});

export const updateSubcodes = (couponName, subcodes) => ({
  type: type.UPDATE_SUBCODES,
  couponName,
  subcodes,
});

export const updateCoupon = (coupon) => ({
  type: type.UPDATE_COUPON,
  coupon,
});

export const cleanCodeGroup = {
  type: type.CLEAN_CODE_GROUP,
};

export const setSelectedCodeGroup = (selectedCodeGroup) => ({
  type: type.SET_SELECETED_CODE_GROUP,
  selectedCodeGroup,
});

export const cancelCode = (code) => ({
  type: type.CANCEL_CODE,
  code,
});

export const setRedeemedCodes = (redeemedCodes) => ({
  type: type.SET_REDEEMED_CODES,
  redeemedCodes,
});

export const setUnredeemedCodes = (codes) => ({
  type: type.SET_UNREDEEMED_CODES,
  codes,
});

export const setPlansSubscriptions = (plans) => ({
  type: type.SET_PLAN_SUBSCRIPTIONS,
  plans,
});

export const setCouponsReferral = (couponsReferral) => ({
  type: type.SET_COUPONS_REFERRAL,
  couponsReferral,
});

export const setSelectedCouponReferral = (couponReferral) => ({
  type: type.SET_SELECTED_COUPON_REFERRAL,
  couponReferral,
});

export const setLoadingCoupons = (value) => ({
  type: type.SET_LOADING_COUPONS,
  value,
});

export const setWaitingResponse = (value) => ({
  type: type.SET_WAITING_RESPONSE,
  value,
});

export const setBadRequest = (value) => ({
  type: type.SET_BAD_REQUEST,
  value,
});
