import React from 'react';
import SettingRow from './SettingRow';
import './Settings.css';
import Spinner from '../../../commonComponents/Spinner/Spinner';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.onLoad();
  }

  render() {
    let { user = {}, generalPermissions = [] } = this.props;
    let canEdit = user.isSuperAdmin
      ? true
      : generalPermissions.find((r) => r === 'Settings.EditKeys');
    return (
      <div className="settingsPageContainer">
        <div className="title">Settings</div>
        {this.props.loading ? (
          <div className="settings-spinner">
            <Spinner text="Loading" />
          </div>
        ) : (
          <div className="keysContainer">
            {this.props.settings.map((setting) => (
              <SettingRow
                key={'setting-key' + setting.id}
                setting={setting}
                handleSubmit={this.props.handleSubmit}
                updateKey={this.props.updateKey}
                disabled={!canEdit}
                showWarning={this.props.showWarning}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default Settings;
