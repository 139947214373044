import * as type from './actionTypes';

export const setQuals = (quals, page) => ({
  type: type.SET_QUALS,
  quals,
  page,
});

export const addQual = qual => ({
  type: type.ADD_QUAL,
  qual,
});

export const setSelectedQual = qual => ({
  type: type.SET_SELECTED_QUAL,
  qual,
});

export const setAllStudyPrograms = studyPrograms => ({
  type: type.SET_ALL_SP,
  studyPrograms,
});
