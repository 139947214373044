import React from 'react';
import { Col } from 'react-bootstrap';
import TextWithTooltip from '../TextWithTooltip/TextWithTooltip';
import NumberUtils from '../../lib/NumberUtils';
import './SubHeader.css';

export default class SubHeader extends React.Component {
  getStatusFilters = () => {
    const {
      options,
      onOptionChange,
      activeOption,
      disabledOptions,
    } = this.props;
    return options.map((option, index) => {
      let isDisabled =
        disabledOptions && disabledOptions.length > 0
          ? disabledOptions.includes(index)
          : false;
      if (isDisabled) {
        return (
          <div className="statusName" key={NumberUtils.obtainUniqueKey()}>
            <TextWithTooltip
              text={option}
              tooltip={'You do not have permission to view this page.'}
            />
          </div>
        );
      }
      return (
        <div
          onClick={() => onOptionChange(option)}
          className={
            activeOption === option ? 'activeStatus statusName' : 'statusName'
          }
          key={NumberUtils.obtainUniqueKey()}
        >
          {option}
        </div>
      );
    });
  };

  handleOnBuildAll = () => {
    this.props.buildAllExams();
  };

  render() {
    const { calledFromTenWeek } = this.props;
    if (calledFromTenWeek === true) {
    }
    return (
      <Col md={12} className="statusHeaderContainer">
        <div className="assignmentsStatusContainer">
          {this.getStatusFilters()}
          {this.props.onSearch && (
            <div className="searchInputText">
              <input
                id="input"
                className="form-control"
                value={this.props.searchValue}
                onChange={this.props.onSearch}
              />
              {this.props.searchValue ? (
                <i
                  onClick={() => this.props.clearSearchInput()}
                  className={
                    'fa fa-close ' +
                    (calledFromTenWeek ? 'tenWeekCloseIcon fa-lg' : 'fa-lg')
                  }
                />
              ) : (
                <i
                  className={
                    'fa fa-search ' +
                    (calledFromTenWeek ? 'tenWeeksearchIcon fa-lg' : 'fa-lg')
                  }
                />
              )}
              {this.props.onPreviewClick && (
                <i
                  style={{ margin: '11px 10px', cursor: 'pointer' }}
                  className="fa fa-newspaper-o fa-lg"
                  title="Study Plans Preview"
                  onClick={this.props.onPreviewClick}
                />
              )}
            </div>
          )}
          {this.props.onPlusClick && (
            <i
              style={{ margin: '0px 10px', cursor: 'pointer' }}
              className={
                'fa fa-plus ' +
                (calledFromTenWeek ? 'tenWeekPlus fa-lg' : 'fa-lg')
              }
              onClick={this.props.onPlusClick}
            />
          )}
          {this.props.buildAllExams && (
            <button
              disabled={
                this.props.buildAllExamsWaiting &&
                this.props.buildAllExamsWaiting === true
              }
              className="build-all-exams-button btn btn-primary"
              onClick={this.handleOnBuildAll}
            >
              BUILD ALL
            </button>
          )}
        </div>
      </Col>
    );
  }
}
