import * as type from './actionTypesSagas';

export const loadSubtopicsByRate = rate => ({
  type: type.LOAD_SUBTOPICS_BY_RATE,
  rate,
});

export const updateChapterSubtopics = (
  reference,
  chapter,
  checkedSubtopics
) => ({
  type: type.UPDATE_CHAPTER_SUBTOPICS,
  reference,
  chapter,
  checkedSubtopics,
});

export const loadRate = rateId => ({
  type: type.LOAD_RATE,
  rateId,
});

export const loadChaptersByRate = rate => ({
  type: type.LOAD_CHAPTERS_BY_RATE,
  rate,
});

export const updateSubtopicChapters = (subtopic, chapters) => ({
  type: type.UPDATE_SUBTOPIC_CHAPTERS,
  subtopic,
  chapters,
});
