// Insights/actions.js
import * as t from './actionTypes';

export const setSubtopics = subtopics => ({
  type: t.SET_SUBTOPICS,
  subtopics,
});

export const setRate = rate => ({
  type: t.SET_RATE,
  rate,
});

export const setChapters = chapters => ({
  type: t.SET_CHAPTERS,
  chapters,
});
