import './Books.css';

import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import { CSVLink } from 'react-csv';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'underscore';

import AddFilter from '../../../commonComponents/Modals/AddFilter/AddFilter';
import CreateRate from '../../../commonComponents/Modals/CreateRate/CreateRate';
import CreateReference from '../../../commonComponents/Modals/CreateReference/CreateReference';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import TeamsRatesList from '../../../commonComponents/TeamsRatesList/TeamsRatesList';
import TextLink from '../../../commonComponents/TextLink/TextLink';
import Utils from '../../../lib/NumberUtils';
import {
  hasPagePermission,
  hasPermissions,
} from '../../../utilities/FilterLinksForTabs';
import BooksList from './BooksList';
import FilterItem from './FilterItem';
import SearchBar from './SearchBar';
import SearchBarFilter from './SearchBarFilter';

class Books extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rate: this.props.selectedRate,
      activeFilter: null,
      originalReferences: this.props.references,
      references: this.props.references,
      historyObject: {},
      filename: 'chapter-questions.csv',
      called: false,
      fromSearch: false,
      expand: false,
      sidebarScrolled: false,
      mainScrolled: false,
      barFilter: '',
      barFilterActive: false,
    };
  }

  setFromSearch(value) {
    this.setState({
      fromSearch: value,
      itemId: this.props.location.query.referenceid,
      actasparent: this.props.location.query.actasparent,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.searchText !== this.props.searchText) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    // This is a quick fix, because we don't want to clear if we are navigating to question page
    const history = sessionStorage.getItem('books_page_history');
    if (!history) {
      this.props.onClearReferences();
    }
    this.props.setIsViewDeletedBooks(false);
  }

  componentWillMount() {
    if (this.props.location.query.referenceid) {
      this.props.getReferenceById(this.props.location.query.referenceid);
      this.setFromSearch(true);
    } else {
      if (this.state.rate && !this.state.called) {
        //   this.selectRate(this.state.rate, null, true);
        this.setState({ called: true });
      }
    }
    this.setState({ sidebarScrolled: false, mainScrolled: false });
  }

  componentDidUpdate(prevProps) {
    const { references, loading } = this.props;
    if (references.length > 0 && this.scrollToReference && !loading) {
      const scrollTo = document.getElementById(
        `reference-${this.scrollToReference}`,
      );
      if (scrollTo) {
        window.jQuery('.infinite-scroll').scrollTop(scrollTo.offsetTop - 150);
      }
      this.scrollToReference = undefined;
    }

    if (
      this.props.finishProcessing &&
      Array.isArray(this.props.chapterQuestionsCSV) &&
      this.props.chapterQuestionsCSV.length > 0
    ) {
      document.getElementById('chapter-questions-download').click();
      this.props.onClearChapterQuestions();
      this.setState({ filename: 'chapter-questions.csv' });

      this.props.setFinishProcessing(false);
    }

    if (this.props.references && this.state.fromSearch) {
      const element = document.querySelector(
        `#itemName-${Number(this.state.itemId)}`,
      );
      if (element && !this.state.expand && this.state.actasparent === 'true') {
        const arrow =
          element.parentElement.parentElement.parentElement.childNodes[0];
        if (arrow.classList.contains('tree-view_arrow-collapsed')) {
          element.click();
          this.setState({ expand: true });
        }
      }
    }

    if (
      !this.state.sidebarScrolled &&
      this.props.rates &&
      this.props.rates.length > 0
    ) {
      const element = document.querySelector('.rolesTable');
      const tab = document.querySelector('.roleRow.active');
      if (element && tab) {
        window.jQuery('.rolesTable').scrollTop(Number(tab.offsetTop) - 200);
        this.setState({ sidebarScrolled: true });
      }
    }

    if (!this.state.mainScrolled && !loading && references.length > 0) {
      const element = document.querySelector('.infinite-scroll');

      const tab = document.querySelector(`#${this.props.lastSelectedPanel}`);

      if (element && tab) {
        const firstPosition = String(this.props.lastSelectedPanel).includes(
          'reference',
        )
          ? 0
          : tab.parentElement.parentElement.parentElement.offsetTop;
        if (Number(tab.offsetTop + firstPosition - 400) > 0) {
          window
            .jQuery('.infinite-scroll')
            .scrollTop(Number(tab.offsetTop + firstPosition - 400));
        }
        this.setState({ mainScrolled: true });
      }
    }
    if (prevProps.urlPdfPrivate !== this.props.urlPdfPrivate) {
      window.open(this.props.urlPdfPrivate, '_blank');
    }
  }

  componentWillReceiveProps({
    rates,
    referencesImmutable,
    references,
    filters,
    user,
    generalRolesPermissions,
    loading,
  }) {
    if (
      this.state.rate === null &&
      rates.length &&
      !this.props.location.query.referenceid
    ) {
      let index = 0;
      if (
        !generalRolesPermissions.find(
          (permission) => permission === 'RatingExams',
        ) &&
        !user.isSuperAdmin
      )
        index = 1;

      if (!loading) {
        this.selectRate(rates[index]);
      }
    }

    if (this.props.referencesImmutable !== referencesImmutable && references) {
      const referencesData =
        this.state.rate && this.state.rate.id === 0
          ? references.filter((reference) => !reference.archived)
          : references;

      this.setState({
        references: referencesData,
        originalReferences: referencesData,
      });
    }

    if (
      (!this.state.activeFilter || !_.isEqual(filters, this.props.filters)) &&
      filters
    ) {
      this.setState({
        activeFilter: filters.filter((filter) => filter.isActive),
      });

      // New filter was created, run it now
      if (filters.length === this.props.filters.length + 1) {
        const id = _.difference(
          _.pluck(filters, 'id'),
          _.pluck(this.props.filters, 'id'),
        );
        const filterToRun = _.findWhere(filters, { id: id[0] });
        this.runFilter(filterToRun, '');
      } else if (_.difference(filters, this.props.filters).length === 1) {
        // Filter was edited, if it's active, run it now
        const filterToRun = _.difference(filters, this.props.filters)[0];
        if (filterToRun.isActive) {
          this.runFilter(filterToRun, '');
        }
      }
    }
    if (this.props.loading && !loading) {
      this.setState({ mainScrolled: false });
    }
  }

  componentDidMount() {
    sessionStorage.setItem('searchRate', '');
    if (
      this.props.rates &&
      this.props.rates.length > 0 &&
      this.props.location.state &&
      this.props.location.state.historyObject &&
      !this.props.location.query.referenceid
    ) {
      const historyObject = this.props.location.state.historyObject;
      window
        .jQuery('.roleRow')
        .parent()
        .parent()
        .scrollTop(historyObject.sideScroll);

      if (!this.props.loading) {
        this.selectRate(
          this.props.location.state.selectedRate,
          this.props.location.state.historyObject,
        );
      }

      return;
    }
    this.props.loadRates();
  }

  selectRate = (rate, paramHistoryObject = null, clearMainScroll = false) => {
    const { references, loading } = this.props;
    const sideScroll = window.jQuery('.roleRow').parent().parent().scrollTop();

    const historyObject = paramHistoryObject || this.state.historyObject;
    historyObject.sideScroll = sideScroll;
    if (clearMainScroll) {
      historyObject.mainScroll = 0;
    }
    this.props.setSelectedRate(rate);
    this.props.setSearchText('');
    this.setState({ historyObject, rate }, () => {
      let history = sessionStorage.getItem('books_page_history');
      if (history) {
        history = JSON.parse(history);
        this.scrollToReference = history.referenceId;
        if (references.length > 0 && this.scrollToReference && !loading) {
          const scrollTo = document.getElementById(
            `reference-${this.scrollToReference}`,
          );
          if (scrollTo) {
            window
              .jQuery('.infinite-scroll')
              .scrollTop(this.getPosition(scrollTo).y - 1300);
            sessionStorage.removeItem('books_page_history');
          }
          this.scrollToReference = undefined;
        }
        return;
      }
      if (rate === undefined) {
        rate = {
          id: 0,
        };
      }
      if (rate && rate.id === 0) {
        this.props.loadReferences(
          null,
          null,
          '',
          0,
          false,
          0,
          historyObject.isBackFromQuestion
            ? historyObject.amountOfReferences
            : null,
        );
        if (historyObject.isBackFromQuestion) {
          historyObject.isBackFromQuestion = false;
        }
      } else {
        const activeFilterInRate = rate
          ? _.where(this.state.activeFilter, {
              rate: rate.id,
            })[0]
          : null;
        if (activeFilterInRate) this.runFilter(activeFilterInRate);
        else
          this.props.obtainAllForRate(rate.id, this.props.isViewDeletedBooks);
      }

      this.props.onClearReferences();
    });
  };

  getPosition = (el) => {
    let x = 0;
    let y = 0;

    while (el !== null && (el.tagName || '').toLowerCase() !== 'html') {
      x += el.offsetLeft || 0;
      y += el.offsetTop || 0;
      el = el.parentElement;
    }

    return { x: parseInt(x, 10), y: parseInt(y, 10) };
  };

  showCreateRateModal = () => {
    this.props.openModal({
      modalTitle: 'Create a rate',
      modalContent: (
        <CreateRate
          onSubmit={this.props.createRate}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  showFilterModal = (filter = null) => {
    this.props.openModal({
      modalTitle: filter ? 'Edit filter' : 'Add filter',
      modalContent: (
        <AddFilter
          onCancel={this.props.closeModal}
          onSubmit={filter ? this.props.editFilter : this.props.createFilter}
          filter={filter || null}
          user={this.props.user}
          rate={this.state.rate}
        />
      ),
      cross: true,
      modalClassName: 'AddFilterModal',
      titleClassName: 'addFilterTitle',
    });
  };

  runFilter = (filter, search) => {
    // if rate id does not exist search without rate
    const rate = this.state.rate.id ? this.state.rate.id : '';

    switch (filter.book_status) {
      case 'Assigned to bibs':
        this.props.obtainAssignedToBib(
          rate,
          filter.rank,
          filter.dutystate,
          search,
        );
        return;

      case 'Not assigned to bibs':
        this.props.obtainNotAssignedToBib(rate, search);
        return;

      default:
        this.props.obtainArchived(rate, search);
    }
  };

  changeFilter = (filter) => {
    let activeFilters = this.state.activeFilter;
    const activeFilterIds = _.pluck(activeFilters, 'id');

    // this.setState({ searchText: '' });
    this.props.setSearchText('');

    if (
      activeFilters.length === 0 ||
      (activeFilters.length > 0 && activeFilterIds.indexOf(filter.id) < 0)
    ) {
      filter.isActive = true;

      // Remove active filter for this rate
      activeFilters = activeFilters.filter((af) => af.rate !== filter.rate);

      // Add this filter to active filters (instant display of active)
      activeFilters.push(filter);

      this.props.setActiveFilter(filter);
      this.runFilter(filter);
    } else if (
      activeFilters.length > 0 &&
      activeFilterIds.indexOf(filter.id) >= 0
    ) {
      filter.isActive = false;

      // Remove active filter for this rate
      activeFilters = activeFilters.filter((af) => af.id !== filter.id);

      this.props.editFilter(filter);
      this.props.obtainAllAssigned(this.state.rate.id);
    } else {
      activeFilters.push(filter);
    }

    // Set the active filters for immediate ui feedback
    this.setState({
      activeFilter: activeFilters,
    });
  };

  renderFilters = (filters) => {
    const activeFilterIds = _.pluck(this.state.activeFilter, 'id');

    if (!filters || filters.length === 0) return;
    return filters.map((filter) =>
      this.state.rate.id === filter.rate ? (
        <div key={Utils.obtainUniqueKey()} className="filterListContainer">
          <FilterItem
            item={filter}
            openEditModal={() => this.showFilterModal(filter)}
            onDelete={() => this.props.deleteFilter(filter.id)}
            onClick={(filter) => this.changeFilter(filter)}
            active={
              this.state.activeFilter
                ? activeFilterIds.indexOf(filter.id) >= 0
                : false
            }
          />
        </div>
      ) : (
        ''
      ),
    );
  };

  changePage = () => {
    const activeFilters = this.state.activeFilter
      ? this.state.activeFilter
      : [];
    const currentFilter = this.state.rate
      ? _.where(activeFilters, { rate: this.state.rate.id })[0]
      : null;
    if (
      !this.state.rate ||
      (this.state.rate.id !== 0 && this.props.searchText === '')
    )
      return;
    this.debounce(
      () => {
        if (
          activeFilters.length > 0 &&
          currentFilter &&
          this.state.rate.id !== 0
        ) {
          this.runFilter(currentFilter, this.props.searchText);
        } else {
          if (this.state.barFilterActive) {
            this.props.loadReferences(
              null,
              null,
              this.props.searchText || '',
              this.props.page + 1,
              true,
              this.state.rate.id,
              undefined,
              this.state.barFilter,
            );
          } else {
            this.props.loadReferences(
              null,
              null,
              this.props.searchText || '',
              this.props.page + 1,
              true,
              this.state.rate.id,
            );
          }
        }
      },
      500,
      true,
    );
  };

  debounce = (func, wait = 500, immediate = false, ...theArgs) => {
    const context = this;
    const args = theArgs;
    const later = () => {
      this.timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !this.timeout;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };

  openCreateReferenceModal = () => {
    if (this.hasEditPermission) {
      const { rate } = this.state;
      this.setFromSearch(false);
      this.props.openModal({
        modalContent: (
          <CreateReference
            onSubmit={(fields) => {
              if (rate && rate.id !== 0) {
                fields.rate = rate.id;
              }
              this.props.createReference(fields);
            }}
            onCancel={this.props.closeModal}
          />
        ),
        modalClassName: 'modal-create-reference',
        className: '',
      });
    }
  };

  hasEditPermission = () => {
    const { generalRolesPermissions, user, rolesPermissions } = this.props;
    const isSuperAdmin = user ? user.isSuperAdmin : false;
    if (
      (hasPagePermission('Books.View', generalRolesPermissions) &&
        hasPagePermission('Books.Edit', generalRolesPermissions)) ||
      isSuperAdmin
    ) {
      return true;
    }
    this.props.showNotification('Not enough permissions');
    return false;
  };

  getAddBookButton = () => {
    const { generalRolesPermissions, user, rolesPermissions } = this.props;
    const isSuperAdmin = user ? user.isSuperAdmin : false;
    const rate = this.state.rate || { id: 0 };
    if (
      (hasPagePermission('Books.View', generalRolesPermissions) &&
        hasPagePermission('Books.Edit', generalRolesPermissions)) ||
      hasPermissions('References', rolesPermissions, rate) ||
      isSuperAdmin
    ) {
      return (
        <Button
          onClick={this.openCreateReferenceModal}
          className="btn-sm floatRight addBookButton"
          variant="success"
          data-test="create-book-button"
        >
          <i className="fa fa-plus icon" />
          Create Book
        </Button>
      );
    }
    return '';
  };

  toggleExpandAll = (value = null) => {
    this.props.onExpandAll(!this.props.expandAll);
  };

  getSpinner = (isBigSpinner) => {
    if (isBigSpinner) {
      return (
        <div className="centerSpinner">
          <Spinner text="Loading books" />
        </div>
      );
    }
    return <Spinner text="Loading books" />;
  };

  toggleViewDeleted = () => {
    this.props.setIsViewDeletedBooks(!this.props.isViewDeletedBooks);
    this.props.onClearReferences();
    this.props.obtainAllForRate(
      this.state.rate.id,
      !this.props.isViewDeletedBooks,
    );
  };

  setParentStates = (letra) => {
    this.setState({
      barFilter: letra,
      barFilterActive: true,
    });
  };

  // handleDownloadRateQuestions = () => {
  //   const { references } = this.props;
  //   const { rate } = this.state;
  //   const chapterIds = [];

  //   for (const reference of references) {
  //     for (const chapter of reference.chapters) {
  //       chapterIds.push(chapter.id);
  //     }
  //   }
  //   this.setState({ filename: `${rate.name}-questions.csv` });
  //   this.props.loadRateQuestionsCSV(chapterIds);
  // };

  infiniteScrollRender() {
    const { loading, loadingInfiniteScroll } = this.props;

    if (loading) {
      return null;
    }

    // Calculate the height for the books list container based on filters listed or not
    //  then set the style on the infinite-scroll class element
    const filterCount = 0;
    let filterHeight = filterCount * 55.5 + 155 || 0;
    filterHeight += 90; // header row
    const isSuperAdmin = this.props.user ? this.props.user.isSuperAdmin : false;

    return (
      <div
        className="infinite-scroll"
        style={{
          height:
            this.state.rate && !this.state.rate.id
              ? this.props.namesToSecondBar &&
                this.props.namesToSecondBar.length > 0
                ? '86%'
                : '91%'
              : '100%',
          overflow: 'auto',
        }}
      >
        <InfiniteScroll
          pageStart={this.props.page}
          loadMore={this.changePage}
          hasMore={!this.state.fromSearch}
          loader={loadingInfiniteScroll}
          initialLoad={false}
          useWindow={false}
        >
          <BooksList
            hasEditPermission={this.hasEditPermission}
            getUrlPdf={this.props.getUrlPdf}
            urlPdfPrivate={this.props.urlPdfPrivate}
            lastSelectedBookByRate={this.props.lastSelectedBookByRate}
            setLastBookOpenPerRate={this.props.setLastBookOpenPerRate}
            referencesInmutable={this.props.referencesImmutable}
            references={this.state.references}
            waiting={this.props.waiting}
            createReference={this.props.createReference}
            createChapter={this.props.createChapter}
            updateReference={this.props.updateReference}
            deleteReference={this.props.deleteReference}
            updateChapter={this.props.updateChapter}
            deleteChapter={this.props.deleteChapter}
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
            associateRates={this.props.associateRates}
            selectedRate={this.state.rate}
            loading={loadingInfiniteScroll}
            rolesPermissions={this.props.rolesPermissions}
            isSuperAdmin={isSuperAdmin}
            generalRolesPermissions={this.props.generalRolesPermissions}
            onMoveChapters={this.props.onMoveChapters}
            expandAll={this.props.expandAll}
            onExpandAll={this.props.onExpandAll}
            filterHeight={filterHeight}
            setMainScroll={this.props.setMainScroll}
            setSidebarScroll={this.props.setSidebarScroll}
            setLastSelectedPanel={this.props.setLastSelectedPanel}
            setLastSelectedTreeView={this.props.setLastSelectedTreeView}
            lastSelectedTreeView={this.props.lastSelectedTreeView}
            lastSelectedPanel={this.props.lastSelectedPanel}
            activeFilter={
              this.state.activeFilter && this.state.rate
                ? this.state.activeFilter.filter(
                    (f) => f.isActive && f.rate === this.state.rate.id,
                  )[0]
                : {}
            }
            historyObject={this.state.historyObject}
            questions={this.props.questions}
            loadQuestionsOfChapter={this.props.loadQuestionsOfChapter}
            loadChapterQuestionsCSV={(chapter) => {
              this.setState({
                filename: `${chapter.name}-questions-${moment().format(
                  'MMM',
                )}${moment().format('DD')}-${moment().format('YYYY')}.csv`,
              });
              this.props.loadChapterQuestionsCSV(chapter.id, false, false);
            }}
            summaries={this.props.summaries}
            user={this.props.user}
            loadAddChapter={this.props.loadAddChapter}
            rateList={this.props.rates}
          />
        </InfiniteScroll>
      </div>
    );
  }

  render() {
    const { filters, loading, chapterQuestionsCSV = '' } = this.props;

    return (
      <Row className="BooksContainer">
        <Col
          xs={6}
          md={3}
          // log={3}
          style={{
            paddingRight: '0px',
            paddingLeft: '0px',
            maxWidth: '480px',
            minWidth: '320px',
            overflow: 'visible',
          }}
          className="rates-list-container"
        >
          <div className="ratesListContainer">
            <TeamsRatesList
              rates={this.props.rates}
              selectedRate={this.state.rate}
              onRateClick={(rate) => {
                this.selectRate(rate, null, true);
                this.setFromSearch(false);
              }}
              booksPage
              user={this.props.user}
              generalRolesPermissions={this.props.generalRolesPermissions}
              rolesPermissions={this.props.rolesPermissions}
              sidebarScroll={this.props.sidebarScroll}
              loadingBooks={loading}
            />
          </div>
        </Col>
        <div
          style={{
            paddingLeft: '0px',
            paddingRight: '0px',
            height: '86vh',
            flex: 1,
            minWidth: '150px',
          }}
          className="assignmentsTables"
        >
          {this.state.rate && this.state.rate.id !== 0 && (
            <div className="filterListWrapper">
              {this.renderFilters(filters)}
            </div>
          )}
          {loading ? (
            this.getSpinner(loading)
          ) : (
            <Row style={{ margin: 0 }}>
              <div className="booksPageTop">
                {this.state.rate && this.state.rate.id !== 0 && (
                  <div
                    onClick={() => this.showFilterModal()}
                    className={
                      this.state.rate && this.state.rate.id === 0
                        ? 'addFilterContainer fix'
                        : 'addFilterContainer'
                    }
                  >
                    <i className="fa fa-filter fa-lg" aria-hidden="true" />
                    <i className="fa fa-plus" aria-hidden="true" />
                    <span className="filterLabel">Filter</span>
                  </div>
                )}
                <SearchBar
                  originalReferences={this.state.originalReferences}
                  activeFilter={this.state.activeFilter}
                  rate={this.state.rate}
                  setHasMoreData={this.props.setHasMoreData}
                  onClearReferences={this.props.onClearReferences}
                  loadReferences={this.props.loadReferences}
                  setParentState={() => this.setState.bind(this)}
                  runFilter={this.runFilter}
                  obtainAllForRate={this.props.obtainAllForRate}
                  setSearchText={this.props.setSearchText}
                  setFromSearch={(value) => this.setFromSearch(value)}
                />
                {this.getAddBookButton()}
              </div>
              <div className="clearfix" />
              <div className="expandAllDiv">
                {this.props.references.length > 0 && (
                  <TextLink
                    text={this.props.expandAll ? 'Collapse all' : 'Expand all'}
                    onAction={this.toggleExpandAll}
                  />
                )}
                {false && (
                  <TextLink
                    text={
                      this.props.isViewDeletedBooks
                        ? 'View published books'
                        : 'View deleted books'
                    }
                    onAction={this.toggleViewDeleted}
                    style={{ marginRight: '10px' }}
                  />
                )}
              </div>
            </Row>
          )}
          {this.state.rate && !this.state.rate.id && (
            <SearchBarFilter
              loading={loading}
              namesToSecondBar={this.props.namesToSecondBar}
              getNamesToSecondBar={this.props.getNamesToSecondBar}
              loadReferences={this.props.loadReferences}
              setParentStates={this.setParentStates}
              setParentState={this.setState}
              references={this.state.references}
              rate={this.state.rate}
              onClearReferences={this.props.onClearReferences}
            />
          )}

          {this.infiniteScrollRender()}

          <div style={{ display: 'none' }}>
            <CSVLink
              id="chapter-questions-download"
              filename={this.state.filename}
              data={chapterQuestionsCSV || ''}
              headers={[
                { label: 'questionID', key: 'questionID' },
                { label: 'AI_question', key: 'AI_question' },
                { label: 'AI_question_change', key: 'AI_question_change' },
                { label: 'AI_answer', key: 'AI_answer' },
                { label: 'AI_answer_change', key: 'AI_answer_change' },
                { label: 'question', key: 'question' },
                { label: 'correctanswer', key: 'correctanswer' },
                { label: 'incorrectanswer_0', key: 'incorrectanswer_0' },
                { label: 'incorrectanswer_1', key: 'incorrectanswer_1' },
                { label: 'incorrectanswer_2', key: 'incorrectanswer_2' },
                { label: 'notes', key: 'notes' },
                { label: 'softwarepage', key: 'softwarepage' },
                { label: 'flashmode', key: 'flashmode' },
                { label: 'flashmodeonly', key: 'flashmodeonly' },
                { label: 'documentpage', key: 'documentpage' },
                { label: 'deleted', key: 'deleted' },
                { label: 'link', key: 'link' },
                { label: 'AI_prompt', key: 'AI_prompt' },
              ]}
            />
          </div>
        </div>
      </Row>
    );
  }
}

export default Books;
