export const GET_ALL_PROMPTS = `prompts/GET_ALL_PROMPTS `;
export const SET_ALL_PROMPTS = `prompts/SET_ALL_PROMPTS `;
export const SET_PROMPT_DETAILS = `prompts/SET_PROMPT_DETAILS `;
export const PROMPT_DETAILS = `prompts/PROMPT_DETAILS `;
export const LOAD_PROMPT_DETAILS = `prompts/LOAD_PROMPT_DETAILS `;
export const CREATE_PROMPT_VIEW = `prompts/CREATE_PROMPT_VIEW `;
export const CREATE_PROMPT = `prompts/CREATE_PROMPT`;
export const EDIT_PROMPT = `prompts/EDIT_PROMPT`;
export const SET_LOADING_INFO_PROMPT = `prompts/SET_LOADING_INFO_PROMPT`;
export const DELETE_PROMPT = `prompts/DELETE_PROMPT `;
export const TOGGLE_ACTIVE_PROMPT = `prompts/TOGGLE_ACTIVE_PROMPT `;
export const CREATE_MODE = `prompts/CREATE_MODE `;
