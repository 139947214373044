import * as React from 'react';

function obtainClassName(pmk, selected) {
  let className;
  if (pmk) {
    className = 'rate-line-pmk';
  } else {
    className = 'rate-line';
  }
  if (selected) {
    className += ' active';
  }
  return className;
}

export default function({ onClick, name, selected, pmk, id }) {
  return (
    <div className={obtainClassName(pmk, selected)} onClick={onClick}>
      {name}
    </div>
  );
}
