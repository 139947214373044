import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';

import addAbilityToOpenModal from '../../../utilities/modalHOC';
import { loadRates } from '../../ChangeExam/actionsSagas';
import { getRates } from '../../ChangeExam/selectors';
import { setLoading } from '../../Login/actions';
import {
  getGeneralRolesPermissions,
  getRolesPermissions,
  getUser,
} from '../../Login/selectors';
import { loadAllQuals } from '../../Quals/actionsSagas';
import { getAllStudyPrograms as getQuals } from '../../Quals/selectors';
import { getAllRolesActionSagas } from '../../Roles/actionsSagas';
import { getAllRoles } from '../../Roles/selectors';
import {
  searchAdminsReceivedAction,
  setSelectedAdmin,
  updateProfileAction,
} from '../actions';
import {
  createAdminUserActionSagas,
  deleteAdminActionSagas,
  forcePasswordChange,
  getActivity,
  getAdminHistorySaga,
  getAdminRolesSaga,
  getAdminsActionSagas,
  getRolesActionSagas,
  loginAs,
  resetPasswordUserActionSagas,
  toggleActiveActionSagas,
  toggleAdminRoleSagas,
  updateAdminFileSaga,
  updateAdminGroupSaga,
  updateAdminSaga,
  updateSuperAdmin,
} from '../actionsSagas';
import {
  getActivitySelector,
  getAdminHistory,
  getAdminRoles,
  getAdmins,
  getAdminsPagination,
  getAdminsProfile,
  getHistoryPagination,
  getLoadingInfo,
  getRoles,
} from '../selectors';
import Admins from './Admins';

const mapStateToProps = (state) => ({
  admins: getAdmins(state),
  pagination: getAdminsPagination(state),
  profile: getAdminsProfile(state),
  roles: getRoles(state),
  adminHistory: getAdminHistory(state),
  historyPagination: getHistoryPagination(state),
  adminRoles: getAdminRoles(state),
  user: getUser(state),
  generalPermissions: getGeneralRolesPermissions(state),
  rolesPermissions: getRolesPermissions(state),
  allRoles: getAllRoles(state),
  rates: getRates(state),
  quals: getQuals(state),
  loadingInfo: getLoadingInfo(state),
  activity: getActivitySelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAdminsFunction: (search, page, userId) => {
    dispatch(getAdminsActionSagas(search, page, userId));
  },
  getRolesFunction: () => {
    dispatch(getRolesActionSagas());
  },
  changeMainRole: (admin, role) => {
    dispatch(toggleAdminRoleSagas(admin, role));
  },
  updateProfile: (user) => {
    dispatch(updateProfileAction(user));
  },
  resetPassword: (id, passwordFields) => {
    dispatch(resetPasswordUserActionSagas(id, passwordFields));
  },
  deleteUser: (id) => {
    dispatch(deleteAdminActionSagas(id));
  },
  onCreateAdmin: (fields) => {
    dispatch(createAdminUserActionSagas(fields));
  },
  toggleActive: (id, currentActive) => {
    dispatch(toggleActiveActionSagas(id, currentActive));
  },
  setSelectedAdmin: (admin) => {
    dispatch(setSelectedAdmin(admin));
  },
  updateAdmin: (admin) => {
    dispatch(updateAdminSaga(admin));
  },
  updateAdminFile: (admin) => {
    dispatch(updateAdminFileSaga(admin));
  },
  getAdminHistory: (admin, page, filter) => {
    dispatch(getAdminHistorySaga(admin, page, filter));
  },
  getAdminRoles: (admin) => {
    dispatch(getAdminRolesSaga(admin));
  },
  resetForm: () => {
    dispatch(reset('NewAdmin'));
  },
  loginAs: (admin) => {
    dispatch(loginAs(admin));
  },
  forcePasswordChange: (id) => {
    dispatch(forcePasswordChange(id));
  },
  updateSuperAdmin: (admin, value) => {
    dispatch(updateSuperAdmin(admin, value));
  },
  setLoading: (value) => {
    dispatch(setLoading(value));
  },
  getAllRoles: () => {
    dispatch(getAllRolesActionSagas());
  },
  loadRates: () => {
    dispatch(loadRates(true));
  },
  loadQuals: () => {
    dispatch(loadAllQuals());
  },
  onUpdateAdminGroups: (admin) => {
    dispatch(updateAdminGroupSaga(admin));
  },
  cleanAdmins: () => {
    dispatch(
      searchAdminsReceivedAction({ pagination: {}, data: [], userId: 0 }),
    );
  },
  getActivity: (id) => {
    dispatch(getActivity(id));
  },
});

const connected = connect(mapStateToProps, mapDispatchToProps)(Admins);

const AdminsContainer = reduxForm({
  form: 'NewAdmin',
})(connected);

export default addAbilityToOpenModal(AdminsContainer);
