import React from 'react';
import EmailItem from './EmailItem';
import './StudyPlanEmails.css';

class StudyPlanEmails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { studyPlan, updateEmail, introEmail, canEdit } = this.props;
    let emails = studyPlan.emails.filter((email) =>
      !introEmail ? email.week !== 0 : email.week === 0,
    );
    emails = emails.sort((a, b) => {
      return a.week - b.week;
    });
    return (
      <div>
        <div>
          <h3>
            {introEmail
              ? 'Introduction Email content for ' + this.props.studyPlan.title
              : 'Email content for ' + this.props.studyPlan.title}
          </h3>
          {!introEmail ? (
            <span className="rememberMessage">
              Do not forget to add the variable{' '}
              <strong>{'{CHAPTER_LINKS}'}</strong> or the email sent to the
              subscribers will not contain the list of study links.
            </span>
          ) : null}
          {emails.map((email) => (
            <EmailItem
              key={'k-' + email.week}
              subject={email.subject}
              body={email.body}
              studyPlan={this.props.studyPlan.id}
              week={email.week}
              email={email.id}
              updateEmail={updateEmail}
              introEmail={this.props.introEmail}
              disabled={!canEdit}
              showWarning={this.props.showWarning}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default StudyPlanEmails;
