import './UserSubmittedQuestionsReport.css';

import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';

import Spinner from '../../../../commonComponents/Spinner/Spinner';
import RateItem from './components/RateItem';
import { useDebounce } from './hooks/useDebounce';

function UserSubmittedQuestionsReport({
  rates,
  isLoadingRates,
  loadRates,
  getUserSubmittedQuestionsReportForGivenRates,
  userSubmittedQuestionsReport,
  isUserSubmittedQuestionsReportLoading,
  setUserSubmittedQuestionsReport,
}) {
  const [selectedRates, setSelectedRates] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const debouncedValue = useDebounce(searchValue, 300);

  useEffect(() => {
    loadRates();
  }, []);

  useEffect(() => {
    if (Object.keys(userSubmittedQuestionsReport).length > 0) {
      setTimeout(() => {
        document.getElementById('report-download').click();
        setUserSubmittedQuestionsReport({});
      }, 100);
    }
  }, [userSubmittedQuestionsReport]);

  const handleToggleSelectedRate = (rateId) => {
    const index = selectedRates.indexOf(rateId);
    if (index === -1) {
      setSelectedRates((sr) => [...sr, rateId]);
    } else {
      setSelectedRates(selectedRates.filter((r) => r !== rateId));
    }

    if (selectedRates.length === rates.length && rateId !== 0) {
      setSelectedRates((sr) => sr.filter((r) => r !== 0));
    } else if (
      selectedRates.length === rates.length - 2 &&
      rateId !== 0 &&
      selectedRates.indexOf(0) === -1
    ) {
      setSelectedRates((sr) => [...sr, 0]);
    }
  };

  const handleSelectAllRates = () => {
    if (selectedRates.length === rates.length) {
      setSelectedRates([]);
    } else {
      setSelectedRates(rates.map((rate) => rate.id));
    }
  };

  const isRateSelected = (rateId) => {
    if (rateId === 0) {
      return (
        selectedRates.length === rates.length ||
        (selectedRates.length === rates.length - 1 &&
          selectedRates.indexOf(0) === -1)
      );
    }
    return !!(selectedRates.indexOf(rateId) !== -1);
  };

  const handleDownloadCSV = () => {
    if (selectedRates.length === rates.length) {
      getUserSubmittedQuestionsReportForGivenRates(0);
    } else {
      getUserSubmittedQuestionsReportForGivenRates(selectedRates);
    }
  };

  const filteredRates =
    rates.length > 0
      ? rates.filter(
          (rate) =>
            rate.shortname
              ?.toLowerCase()
              .includes(debouncedValue.toLowerCase()) ||
            rate.name?.toLowerCase().includes(debouncedValue.toLowerCase()),
        )
      : [];

  return (
    <div className="submitted__questions__container">
      <div className="submitted__questions__left__container">
        <div className="d-none">
          <CSVLink
            id="report-download"
            className="report-download"
            filename={`User-Submitted-Questions-Report-${Date.now()}.csv`}
            data={userSubmittedQuestionsReport?.submitQuestionsReport ?? []}
            headers={userSubmittedQuestionsReport?.reportHeaders ?? []}
          />
        </div>
        {isUserSubmittedQuestionsReportLoading ? (
          <Spinner text="Downloading report..." />
        ) : (
          <div
            className={`whiteButtons ${
              selectedRates.length === 0 ||
              isUserSubmittedQuestionsReportLoading
                ? 'disabled'
                : ''
            }`}
            disabled={
              selectedRates.length === 0 ||
              isUserSubmittedQuestionsReportLoading
            }
            onClick={handleDownloadCSV}
          >
            Generate Report
          </div>
        )}

        {isLoadingRates ? (
          <Spinner />
        ) : (
          <>
            <h4 className="submitted__questions__title">
              Select the rates you want included in the User Submitted Questions
              Report
            </h4>

            <div className="submitted__questions__search__container">
              <span>Search rate</span>
              <input
                type="text"
                className="submitted__questions__search__input"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>

            <div className="submitted__questions__rate__container">
              {rates
                ? filteredRates.map((rate) => (
                    <RateItem
                      key={rate.id}
                      rate={rate}
                      onChange={
                        rate.id === 0
                          ? handleSelectAllRates
                          : () => handleToggleSelectedRate(rate.id)
                      }
                      checked={isRateSelected(rate.id)}
                    />
                  ))
                : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default UserSubmittedQuestionsReport;
