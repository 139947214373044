import React from 'react';
import './TextLink.css';
import PropTypes from 'prop-types';

export default class TextLink extends React.Component {
  render() {
    return (
      <div
        className="TextLink"
        style={this.props.style}
        onClick={this.props.onAction}
      >
        <div className="Text">{this.props.text}</div>
      </div>
    );
  }
}

TextLink.propTypes = {
  text: PropTypes.string,
  onAction: PropTypes.func,
};

TextLink.defaultProps = {
  style: { textAlign: 'center' },
};
