import React from 'react';
import './bannerListStyle.css';
import moment from 'moment';
import BannerCreateView from './bannerCreateView';
import DropDown from '../../../commonComponents/DropDown/DropDown';
import BannerView from './bannerView';
import addAbilityToOpenModal from '../../../utilities/modalHOC';
import ConfirmDeletion from '../../../commonComponents/Modals/ConfirmDeletion/ConfirmDeletion';

class bannerDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadBanner: false,
      viewBanner: false,
    };
  }
  componentWillMount() {}

  componentWillReceiveProps = nextProps => {};

  openEdit = bannerDetails => {
    this.props.createBannerView(true, bannerDetails);
    this.props.loadBannerDetails(false);
  };

  renderBannerView(backgroundColor, fontSize, description) {
    return (
      <div
        className="bannerDetail"
        style={{ backgroundColor: backgroundColor }}
      >
        <p className="bannerText" style={{ fontSize: fontSize }}>
          {description}
        </p>
      </div>
    );
  }

  bannerDelete = banner => {
    this.props.openModal({
      modalTitle: `Deleting Banner: ${banner.name}`,
      modalContent: (
        <ConfirmDeletion
          text={<span>Enter the word DELETE to confirm deletion.</span>}
          onSubmit={() => {
            this.props.closeModal();
            this.props.deleteBanner(banner.id);
          }}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  render() {
    const {
      bannerDetails,
      loadBanner,
      viewBannerCreate,
      activeBanner,
    } = this.props;
    return (
      <div className="contectCenter">
        {loadBanner && (
          <div className="cardBanner">
            <div className="bannerInformation">
              <div className="headerBanner">
                <div>
                  <div
                    className={
                      bannerDetails.active
                        ? 'containerActive'
                        : 'containerActive inactive'
                    }
                  >
                    <b>{bannerDetails.active ? 'Active' : 'Inactive'}</b>
                  </div>
                  <div>
                    <b> Name: </b>
                    <span>{bannerDetails.name}</span>
                  </div>
                  <div>
                    <b> Expiry at:</b>{' '}
                    <span>
                      {' '}
                      {moment(Number(bannerDetails.expiryAt)).format('L')}
                    </span>
                  </div>
                  <div>
                    <div
                      className="buttonViewBanner"
                      onClick={() => {
                        this.setState({ viewBanner: !this.state.viewBanner });
                      }}
                    >
                      View Banner
                    </div>
                  </div>
                </div>
                <div>
                  <DropDown
                    options={[
                      {
                        name: 'Edit',
                        event: () => this.openEdit(bannerDetails),
                      },
                      {
                        name: !bannerDetails.active ? 'Active' : 'Inactive',
                        event: () =>
                          activeBanner(bannerDetails.id, !bannerDetails.active),
                      },
                      {
                        name: 'Delete',
                        event: () => this.bannerDelete(bannerDetails),
                      },
                    ]}
                    title="Banner"
                    style={{
                      position: 'relative',
                    }}
                  />
                </div>
              </div>
            </div>

            {this.state.viewBanner && (
              <BannerView
                backgroundColor={
                  bannerDetails.color ? bannerDetails.color : '#fa7900'
                }
                fontSize={bannerDetails.fontSize}
                description={bannerDetails.description}
              />
            )}
          </div>
        )}
        {viewBannerCreate && (
          <BannerCreateView
            createBanner={this.props.createBanner}
            editBannerView={this.props.editBannerView}
            loadBannerDetails={this.props.loadBannerDetails}
            createBannerView={this.props.createBannerView}
            editBanner={this.props.editBanner}
          />
        )}
      </div>
    );
  }
}

export default addAbilityToOpenModal(bannerDetails);
