import './SetNewPassword.css';

import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap-button-loader';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { clearErrorFromService as clearErrorFromServiceAdmin } from '../../../components/Admins/actions';
import {
  getFormErrors as getFormErrorsAdmin,
  getIsWaiting as getIsWaitingAdmin,
} from '../../../components/Admins/selectors';
import { updateFormErrors as clearErrorFromServiceUser } from '../../../components/Users/actions';
import {
  getFormErrors as getFormErrorsUser,
  getIsWaiting as getIsWaitingUser,
} from '../../../components/Users/selectors';
import Input from '../../Input/Input';

class SetNewPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
    };
  }

  componentWillUnmount() {
    this.props.clearErrorMessageAdmin();
    this.props.clearErrorMessageUser();
  }

  validate({ currentPassword, password, confirmPassword }, callback) {
    const errors = {};

    if (!currentPassword) {
      errors.currentPassword = 'Current Password field is mandatory';
    }

    if (!password) {
      errors.password = 'Password field is mandatory';
    }

    if (!confirmPassword) {
      errors.confirmPassword = 'Confirm Password field is mandatory';
    }

    if (currentPassword === password) {
      errors.password = 'New password must be different from the current one';
    }

    if (password !== confirmPassword) {
      errors.general = 'Both fields must match';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: null, waiting: true });
    callback({ currentPassword, password, confirmPassword });
  }

  render() {
    const { handleSubmit, onSubmit, onCancel } = this.props;

    return (
      <div className="set-new-password-component">
        <div className="card-block">
          <form
            className="widget-form add-member-in-group-form"
            onSubmit={handleSubmit((fields) => this.validate(fields, onSubmit))}
          >
            <div>
              <span className="label">Current Password:</span>
              <Field
                name="currentPassword"
                type="password"
                component={Input}
                icon="lock"
                placeholder="Current password"
              />
              {this.state.errors && this.state.errors.currentPassword && (
                <span className="errors">
                  {this.state.errors.currentPassword}
                </span>
              )}
            </div>

            <div>
              <span className="label">New Password:</span>
              <Field
                name="password"
                type="password"
                component={Input}
                icon="lock"
                placeholder="New password"
              />
              {this.state.errors && this.state.errors.password && (
                <span className="errors">{this.state.errors.password}</span>
              )}
            </div>

            <div>
              <span className="label">Confirm New Password:</span>
              <Field
                name="confirmPassword"
                type="password"
                component={Input}
                icon="lock"
                placeholder="Confirm New Password"
              />
              {this.state.errors && this.state.errors.confirmPassword && (
                <span className="errors">
                  {this.state.errors.confirmPassword}
                </span>
              )}
              {this.state.errors &&
                !this.state.errors.confirmPassword &&
                this.state.errors.general && (
                  <span className="errors">{this.state.errors.general}</span>
                )}
            </div>

            <div className="buttons">
              <Button className="btn btn-secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button className="btn btn-success" type="submit">
                Reset password
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

SetNewPassword.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  requesting: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  formErrorsAdmin: getFormErrorsAdmin(state),
  isWaitingAdmin: getIsWaitingAdmin(state),
  formErrorsUser: getFormErrorsUser(state),
  isWaitingUser: getIsWaitingUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearErrorMessageAdmin: () => {
    dispatch(clearErrorFromServiceAdmin());
  },
  clearErrorMessageUser: () => {
    dispatch(clearErrorFromServiceUser());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'ResetPassword' })(SetNewPassword));
