import './bannerListStyle.css';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import DatePicker from 'react-datepicker';

import { SimpleDatePicker } from '../../../commonComponents/Datepicker/Datepicker';
import {
  SimpleInput,
  SimpleTextarea,
} from '../../../commonComponents/Input/Input';
import BannerView from './bannerView';

class bannerCreateView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expiryAt: null,
      color: this.props.editBannerView ? this.props.editBannerView.color : '',
      fontSize: this.props.editBannerView
        ? this.props.editBannerView.fontSize
        : 0,
      viewBanner: false,
      description: this.props.editBannerView
        ? this.props.editBannerView.description
        : '',
      name: this.props.editBannerView ? this.props.editBannerView.name : '',
      active: this.props.editBannerView
        ? this.props.editBannerView.active
        : false,
      title: this.props.editBannerView.id ? 'Edit Banner' : 'Create Banner',
      dateEdit: this.props.editBannerView
        ? this.props.editBannerView.expiryAt
        : null,
      required: false,
    };
  }

  componentWillMount() {}

  componentWillReceiveProps(nextProps) {}

  saveBanner() {
    const banner = {};
    if (!this.state.expiryAt) {
      this.setState({ required: true });
    } else {
      banner.expiryAt = this.state.expiryAt
        ? moment(this.state.expiryAt).valueOf()
        : parseInt(this.state.dateEdit);
    }
    banner.color = this.state.color ? this.state.color : '#fa7900';
    banner.fontSize = parseFloat(this.state.fontSize);
    banner.description = this.state.description;
    banner.name = this.state.name;
    banner.active = this.state.active ? this.state.active : false;
    if (this.props.editBannerView.id) {
      banner.id = this.props.editBannerView.id;
      this.props.editBanner(banner);
    } else {
      if (banner.expiryAt) {
        this.props.createBanner(banner);
      }
    }
  }

  cancelButton() {
    this.props.createBannerView(false, {});
    this.props.loadBannerDetails(false);
  }

  renderFormBanner() {
    return (
      <Row>
        <Col md={12}>
          <Row>
            <Col md={6}>
              <span>Name</span>
              <SimpleInput
                value={this.state.name}
                onChange={(e) => {
                  this.setState({ name: e.target.value });
                }}
              />
            </Col>
            <Col md={6}>
              <span>Expiry At</span>
              <DatePicker
                selected={this.state.expiryAt ? this.state.expiryAt : null}
                minDate={new Date()}
                selectsEnd
                onChange={(expiryAt) => {
                  this.setState({ expiryAt });
                }}
                className={
                  this.state.required && !this.state.expiryAt
                    ? 'required'
                    : null
                }
                placeholderText={
                  this.state.dateEdit
                    ? new Date(Number(this.state.dateEdit)).toLocaleDateString(
                        'en-US',
                      )
                    : 'MM/DD/YYYY'
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <span>Background color</span>
              <input
                className="colorContainer"
                type="color"
                value={this.state.color ? this.state.color : '#fa7900'}
                onChange={(e) => {
                  this.setState({ color: e.target.value });
                }}
              />
            </Col>
            <Col md={6}>
              <span>Font size</span>
              <SimpleInput
                type="number"
                min={0}
                placeholder={this.state.fontSize}
                onChange={(e) => {
                  this.setState({ fontSize: e.target.value });
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col md={12}>
          <span>Description</span>
          <SimpleTextarea
            value={this.state.description}
            onChange={(e) => {
              this.setState({ description: e.target.value });
            }}
          />
          {this.props.editBannerView.id ? null : (
            <div>
              <div>
                <input
                  className="inputCheckbox"
                  type="checkbox"
                  id="active"
                  checked={this.state.active}
                  onChange={() => {
                    this.setState({ active: !this.state.active });
                  }}
                />
                <label htmlFor="active">Active</label>
              </div>
            </div>
          )}
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Row className="cardCreateBanner">
        <Col md={12}>
          <div>
            <h5>
              <b>{this.state.title}</b>
            </h5>
          </div>
          <div>{this.renderFormBanner()}</div>
        </Col>
        <Col md={12}>
          <div
            className="buttonViewBanner"
            onClick={() => {
              this.setState({ viewBanner: !this.state.viewBanner });
            }}
          >
            View Banner
          </div>
          <div>
            {this.state.viewBanner && (
              <BannerView
                backgroundColor={
                  this.state.color ? this.state.color : '#fa7900'
                }
                fontSize={this.state.fontSize}
                description={this.state.description}
              />
            )}
          </div>
        </Col>
        <Col md={12}>
          <div className="buttonContainer">
            <Button
              type="submit"
              className="btn-success btn-success-save"
              onClick={() => {
                this.saveBanner();
              }}
            >
              {this.props.editBannerView.id ? 'Edit' : 'Save'}
            </Button>

            <Button
              type="submit"
              className="btn-success btn-success-cancel"
              onClick={() => {
                this.cancelButton();
              }}
            >
              Cancel
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}

export default bannerCreateView;
