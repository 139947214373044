export const SET_PRACTICE_EXAM_INFORMATION_WAITING =
  'practice_exams/SET_PRACTICE_EXAM_INFORMATION_WAITING';
export const SET_PRACTICE_EXAM_INFORMATION =
  'practice_exams/SET_PRACTICE_EXAM_INFORMATION';
export const SET_RATE = 'practice_exams/SET_RATE';
export const SET_DUTY_STATE = 'practice_Exams/SET_DUTY_STATE';
export const SET_RANK = 'practice_exams/SET_RANK';
export const SET_EXAM = 'practice_exams/SET_EXAM';
export const SET_CYCLE = 'practice_exams/SET_CYCLE';
export const SET_BUILD_PRACTICE_EXAM_WAITING =
  'practice_exams/SET_BUILD_PRACTICE_EXAM_WAITING';
export const SET_EXAM_AFTER_BUILD = 'practice_exams/SET_EXAM_AFTER_BUILD';
export const SET_REMOVE_QUESTION_WAITING =
  'practice_exams/SET_REMOVE_QUESTION_WAITING';
export const SET_REMOVE_QUESTION_SUCCESS =
  'practice_exams/SET_REMOVE_QUESTION_SUCCESS';
export const SET_ACTIVATE_EXAM_WAITING =
  'practice_exams/SET_ACTIVATE_EXAM_WAITING';
export const SET_ACTIVATE_EXAM_SUCCESS =
  'practice_exams/SET_ACTIVATE_EXAM_SUCCESS';
export const SET_BUILD_ALL_EXAMS_WAITING =
  'practice_exams/SET_BUILD_ALL_EXAMS_WAITING';
export const SET_BUILD_ALL_EXAMS_SUCCESS =
  'practice_exams/SET_BUILD_ALL_EXAMS_SUCCESS';
export const SET_BUILD_ALL_EXAMS_PROGRESS =
  'practice_exams/SET_BUILD_ALL_EXAMS_PROGRESS';
