import { connect } from 'react-redux';
import { getUrlPdfPrivate, getWaiting } from '../selectors';
import CreateAIquestionForChapterModal from './CreateAIquestionForChapterModal';
import { getUrlPdf } from '../../Bibliographies/actionsSagas';
import { submitQuestionsGPT } from '../../Assignments/actionsSagas';
import {
  deletePdfChapterActionSagas,
  updateChapterActionSagas,
} from '../../References/actionsSagas';
import { updateQuestionPdfProcessing } from '../../Questions/actions';
import { getEditPermission } from '../../Questions/selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    closeModal: ownProps.closeModal,
    chapter: ownProps.chapter,
    referenceId: ownProps.referenceId,
    pdf: ownProps.pdf,
    totalPages: ownProps.totalPages,
    rateId: ownProps.rateId,
    isWaiting: getWaiting(state),
    editPermission: getEditPermission(state),
    urlPdfPrivate: getUrlPdfPrivate(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUrlPdf: (url) => {
      dispatch(getUrlPdf(url));
    },
    submitQuestionsGPT: (fields) => {
      dispatch(submitQuestionsGPT(fields));
    },
    deletePdfChapter: (chapter, isCreateAiQuestion) => {
      dispatch(deletePdfChapterActionSagas(chapter, false, isCreateAiQuestion));
    },
    updateQuestionPdfProcessing: (question) =>
      dispatch(updateQuestionPdfProcessing(question)),
    updateChapter: (fields, chapterId, isCreateAiQuestion) => {
      dispatch(
        updateChapterActionSagas(
          fields,
          chapterId,
          false,
          false,
          isCreateAiQuestion,
        ),
      );
    },
  };
};

const CreateAIquestionForChapterContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAIquestionForChapterModal);

export default CreateAIquestionForChapterContainer;
