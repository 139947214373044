import * as t from './actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  chapters: [],
  subtopics: [],
  rateForSubtopics: null,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case t.SET_SUBTOPICS:
      return state.set('subtopics', action.subtopics);
    case t.SET_CHAPTERS:
      return state.set('chapters', action.chapters);
    case t.SET_RATE:
      return state.set('rateForSubtopics', action.rate);
    default:
      return state;
  }
}
