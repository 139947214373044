/*
if (process.env.REACT_APP_KEYS_ENV === 'code3prep') {
  module.exports = require('./links.code3prep');
} else if (process.env.REACT_APP_KEYS_ENV === 'production') {
  module.exports = require('./links.prod');
} else if (process.env.REACT_APP_KEYS_ENV === 'stage') {
  module.exports = require('./links.stage');
} else if (process.env.REACT_APP_KEYS_ENV === 'local') {
  module.exports = require('./links.local');
} else {
  module.exports = require('./links.dev');
}
*/
export const BLUEJACKETEER_LINK = process.env.REACT_APP_BLUEJACKETEER_LINK;
export const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
