import './WYSIWYG.css';
import 'react-quill/dist/quill.snow.css';

import PropTypes from 'prop-types';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import ImageResize from 'quill-image-resize-module--fix-imports-error';
import React from 'react';
import ReactQuill, { Quill } from 'react-quill';

import * as api from '../../utilities/ServiceManager';

const icons = Quill.import('ui/icons');
icons.undo = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
</svg>`;
icons.redo = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
</svg>`;

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
Quill.register('modules/imageResize', ImageResize);
// eslint-disable-next-line
Quill.register('modules/counter', function (quill, options) {
  const container = document.querySelector(options.container);
  // eslint-disable-next-line
  quill.on('text-change', function () {
    const text = quill.getText().trim();
    if (container?.innerText != null) {
      container.innerText = `Characters: ${text.length}`;
    }
  });
});
// eslint-disable-next-line
Quill.register('modules/disableTab', function (quill, options) {
  // Disable /t char insert in tab press
  delete quill.getModule('keyboard').bindings['9'];

  // Disable tab navigation for toolbar icons
  // The query below matches all toolbar classes like ql-icon-picker, ql-bold, ql-italic, etc.
  const toolbarButtons = document.querySelectorAll(
    '[class^="ql-"]:not(.ql-editor)',
  );
  toolbarButtons.forEach((item) => {
    item.setAttribute('tabindex', -1);
  });
});

export default class WYSIWYG extends React.Component {
  constructor() {
    super();

    this.handleModelChange = this.handleModelChange.bind(this);
    this.state = {
      model: '<p></p>',
    };

    this.reactQuillRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    if (this.props.value) {
      this.setState({
        model: this.props.value,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.value !== nextProps.value &&
      nextProps.value &&
      nextProps.updateEditorView !== false
    ) {
      this.setState({
        model: nextProps.value,
      });
    }
    if (!nextProps.value && nextProps.updateEditorView) {
      this.setState({
        model: '<p></p>',
      });
    }
  }

  handleModelChange = (model) => {
    this.setState({
      model,
    });
    if (this.props.redBorder && model.length === 0) {
      this.setState({
        model: '<p></p>',
      });
    }

    // Empty model is <p><br></p> or <p></p> by default
    // So we need to set it to empty string
    // To make validation work
    if (model === '<p><br></p>' || model === '<p></p>') {
      model = '';
    }

    if (this.props.onChange) {
      this.props.onChange(model);
    }
  };

  uploadImageCallBack = (file) => {
    const data = new FormData();
    data.append('file', file);
    return new Promise((resolve, reject) => {
      api
        .uploadFile(data)
        .then((d) => {
          resolve({
            data: {
              link: d.path,
            },
          });
        })
        .catch((err) => {
          if (this.props.showWarning) {
            this.props.showWarning(
              'Input error!',
              'Error uploading image. Please try again later.',
            );
          }
          reject(err);
        });
    });
  };

  uploadImage(file) {
    this.uploadImageCallBack(file)
      .then((res) => {
        if (res.data.link) {
          const editor = this.reactQuillRef?.current?.getEditor();
          const range = editor.getSelection();
          editor.insertEmbed(
            range.index,
            'image',
            res.data.link,
            Quill.sources.USER,
          );
        } else {
          // eslint-disable-next-line
          console.error(res.data);
        }
      })
      .catch((e) => {
        // eslint-disable-next-line
        console.error(e);
      });
  }

  // when user clicks in upload image
  imageHandlerButton = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = () => {
      this.uploadImage(input.files[0]);
    };
  };

  // when user drags and drops image
  imageHandlerDrop = (imageDataUrl, type, imageData) => {
    const file = imageData.toFile();
    this.uploadImage(file);
  };

  myUndo = () => this?.reactQuillRef?.current?.getEditor()?.history?.undo();

  myRedo = () => this?.reactQuillRef?.current?.getEditor()?.history?.redo();

  render() {
    const { submit, modalSize, nonModifiedQuestionOrAnswer } = this.props;

    const handleKeyDownSimple = (e) => {
      if (e.ctrlKey && e.keyCode === 13) {
        if (submit) submit();
      }
    };

    const handleKeyUpSimple = () => {};

    const handleKeyDownFull = (e) => {
      if (e.ctrlKey && !e.shiftKey && e.keyCode === 13) {
        const btnSaveCreate =
          document.getElementsByClassName('btn-save-create')[0];
        if (btnSaveCreate) {
          btnSaveCreate.click();
          btnSaveCreate.disabled = true;

          const questionInput = document.getElementsByClassName(
            'TextArea BorderTextArea form-control',
          );
          if (questionInput && questionInput.length > 0) {
            questionInput[0].focus();
          }
        }
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 13) {
        const btnSaveDefault =
          document.getElementsByClassName('btn-save-default')[0];
        if (btnSaveDefault) {
          btnSaveDefault.click();
          btnSaveDefault.disabled = true;
          const questionInput = document.getElementsByClassName(
            'TextArea BorderTextArea form-control',
          );
          if (questionInput && questionInput.length > 0) {
            questionInput[0].focus();
          }
        }
      }
    };

    const handleKeyUpFull = () => {
      const btnSaveCreate =
        document.getElementsByClassName('btn-save-create')[0];
      if (btnSaveCreate) {
        btnSaveCreate.disabled = false;
      }
      const btnSaveDefault =
        document.getElementsByClassName('btn-save-default')[0];
      if (btnSaveDefault) {
        btnSaveDefault.disabled = false;
      }
    };

    const quillFormats = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video',
      'width',
      'image',
      'script',
      'align',
      'color',
      'background',
      'direction',
    ];

    const modulesSimple = {
      toolbar: [[{ script: 'sub' }, { script: 'super' }]],
      disableTab: true,
    };

    const modulesFull = {
      toolbar: {
        container: [
          [{ font: [] }],
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ size: [] }],
          // [{ size: fontSizeArr }],
          [{ align: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          [{ script: 'sub' }, { script: 'super' }],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image', 'video'],
          ['clean'],
          ['undo', 'redo'],
        ],
        handlers: {
          image: this.imageHandlerButton,
          undo: this.myUndo,
          redo: this.myRedo,
        },
      },
      history: {
        delay: 100,
        maxStack: 100,
        userOnly: false,
      },
      imageDropAndPaste: {
        handler: this.imageHandlerDrop,
      },
      imageResize: {
        // modules: ['Resize', 'DisplaySize', 'Toolbar'],
        modules: ['Resize', 'DisplaySize'],
      },
      counter: {
        container: '#counter',
        unit: 'character',
      },
      clipboard: {
        matchVisual: false,
      },
      disableTab: true,
    };
    return (
      <>
        {this.props.isInput ? (
          <div
            id={this.props.id ? this.props.id : 'editor'}
            className={this.props.hide ? 'editor-hide' : 'editor'}
            style={
              nonModifiedQuestionOrAnswer
                ? { borderTop: 'solid 6px #9f8fef', borderRadius: '1rem' }
                : null
            }
          >
            {/* Simple editor */}
            <ReactQuill
              theme="snow"
              value={this.state.model}
              onChange={this.handleModelChange}
              modules={modulesSimple}
              ref={this.reactQuillRef}
              onKeyDown={handleKeyDownSimple}
              formats={quillFormats}
              onKeyUp={handleKeyUpSimple}
            />
          </div>
        ) : (
          <>
            {/* Full featured editor */}
            <div id="editor">
              <ReactQuill
                theme="snow"
                value={this.state.model}
                onChange={this.handleModelChange}
                modules={modulesFull}
                formats={quillFormats}
                ref={this.reactQuillRef}
                className={
                  this.props.modalSize ? 'full-editor-125' : 'full-editor-350'
                }
                onKeyDown={handleKeyDownFull}
                onKeyUp={handleKeyUpFull}
              />
            </div>
            <div id="counter" className="counter">
              Characters: 0
            </div>
          </>
        )}
      </>
    );
  }
}

WYSIWYG.propTypes = {
  onChange: PropTypes.func.isRequired,
  // If true, means no toolbar by default, but activate x_2 x^2 and omega when clicked in 'more text'
  // button. Uses config=input, else config=config
  isInput: PropTypes.bool,
  value: PropTypes.string.isRequired,
  updateEditorView: PropTypes.bool,
  submit: PropTypes.func,
  modalSize: PropTypes.bool,
  id: PropTypes.string,
  hide: PropTypes.bool,
};
