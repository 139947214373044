import { connect } from 'react-redux';
import ChangePrimaryWriter from '../../../commonComponents/Modals/ChangePrimaryWriter/ChangePrimaryWriter';
import { loadWriters } from '../../Writers/actionsSagas';
import { updateWaiting } from '../../Writers/actions';
import { getWriters, getWaiting } from '../../Writers/selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    writers: getWriters(state),
    writer: ownProps.writer,
    rate: ownProps.rate,
    qual: ownProps.qual,
    onCancel: ownProps.onCancel,
    isWaiting: getWaiting(state),
    onSubmit: ownProps.onSubmit,
    group: ownProps.group,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoad: (type, typeId) => {
      dispatch(updateWaiting(true));
      dispatch(loadWriters(type, typeId));
    },
  };
};

const ChangePrimaryWriterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePrimaryWriter);

export default ChangePrimaryWriterContainer;
