const questionsSagaNamespace = 'questions-saga';
export const LOAD_REFERENCE = `${questionsSagaNamespace}/LOAD_REFERENCE`;
export const LOAD_QUESTIONS = `${questionsSagaNamespace}/LOAD_QUESTIONS`;
export const EDIT_QUESTION = `${questionsSagaNamespace}/EDIT_QUESTION`;
export const CREATE_QUESTION = `${questionsSagaNamespace}/CREATE_QUESTION`;
export const BATCH_CREATE_QUESTIONS = `${questionsSagaNamespace}/BATCH_CREATE_QUESTIONS`;
export const CHANGE_STATUS = `${questionsSagaNamespace}/CHANGE_STATUS`;
export const LOAD_ALL_QUESTIONS = `${questionsSagaNamespace}/LOAD_ALL_QUESTIONS`;
export const EDIT_QUESTION_SAGAS = `${questionsSagaNamespace}/EDIT_QUESTION_SAGAS`;
export const DELETE_QUESTION_SAGAS = `${questionsSagaNamespace}/DELETE_QUESTION_SAGAS`;
export const DELETE_PDF_SAGAS = `${questionsSagaNamespace}/DELETE_PDF_SAGAS`;
export const LOAD_CHAPTER_SAGAS = `${questionsSagaNamespace}/LOAD_CHAPTER_SAGAS`;
export const LOAD_PUBLISHED_QUESTION = `${questionsSagaNamespace}/LOAD_PUBLISHED_QUESTION`;
export const RESTORE_QUESTION_SAGAS = `${questionsSagaNamespace}/RESTORE_QUESTION_SAGAS`;
export const DELETE_ALL_CHAPTER_QUESTIONS_SAGAS = `${questionsSagaNamespace}/DELETE_ALL_CHAPTER_QUESTIONS_SAGAS`;
export const SEARCH_QUESTION_IN_CHAPTER_BY_ID = `${questionsSagaNamespace}/SEARCH_QUESTION_IN_CHAPTER_BY_ID`;
export const CHECK_QUESTION_SPELLING = `${questionsSagaNamespace}/CHECK_QUESTION_SPELLING`;
export const AMOUNT_OF_QUESTIONS_IN_CHAPTER = `${questionsSagaNamespace}/AMOUNT_OF_QUESTIONS_IN_CHAPTER`;
export const GET_QUESTIONS_FOR_DOWNLOAD = `${questionsSagaNamespace}/GET_QUESTIONS_FOR_DOWNLOAD`;
export const CREATE_USER_CUSTOM_PROMPT = `${questionsSagaNamespace}/CREATE_USER_CUSTOM_PROMPT`;
export const GET_USER_CUSTOM_PROMPT = `${questionsSagaNamespace}/GET_USER_CUSTOM_PROMPT`;
export const GET_QUESTION_HISTORY = `${questionsSagaNamespace}/GET_QUESTION_HISTORY`;
export const IMPROVE_QUESTION_WITH_AI = `${questionsSagaNamespace}/IMPROVE_QUESTION_WITH_AI`;
export const UPDATE_INCLUDE_NOTES_IN_SUGGESTION = `${questionsSagaNamespace}/UPDATE_INCLUDE_NOTES_IN_SUGGESTION`;
