import * as type from './actionTypesSagas';

export const loadRates = (page, isBibRates = false) => ({
  type: type.LOAD_RATES,
  page,
  isBibRates,
});

export const loadAllRates = () => ({
  type: type.LOAD_ALL_RATES,
});

export const loadQuals = page => ({
  type: type.LOAD_QUALS,
  page,
});

export const loadTopics = (
  id,
  page = null,
  isByReference = false,
  isBySP = false
) => ({
  type: type.LOAD_TOPICS,
  id,
  page,
  isByReference,
  isBySP,
});

export const updateTopic = (id, name) => ({
  type: type.UPDATE_TOPIC,
  id,
  name,
});

export const updateSubtopic = (id, name) => ({
  type: type.UPDATE_SUBTOPIC,
  id,
  name,
});

export const deleteTopic = id => ({
  type: type.DELETE_TOPIC,
  id,
});

export const deleteSubtopic = id => ({
  type: type.DELETE_SUBTOPIC,
  id,
});

export const createTopic = (rateId, name) => ({
  type: type.CREATE_TOPIC,
  rateId,
  name,
});

export const createSubtopic = (topicId, name) => ({
  type: type.CREATE_SUBTOPIC,
  topicId,
  name,
});

export const createRate = fields => ({
  type: type.CREATE_RATE,
  fields,
});

export const updateSP = fields => ({
  type: type.UPDATE_SP,
  fields,
});

export const deleteSP = sp => ({
  type: type.DELETE_SP,
  sp,
});
