function QuestionItemInput({
  isEditable = false,
  isTextArea = false,
  hasAddButton = false,
  handleAddInput,
  ...props
}) {
  return (
    <div>
      {isEditable ? (
        isTextArea ? (
          <div className="question__item__input__container">
            <textarea className="question__item__input" {...props} />
            {hasAddButton ? (
              <button
                className="btn btn-primary question__item__input__button"
                onClick={handleAddInput}
              >
                +
              </button>
            ) : null}
          </div>
        ) : (
          <div className="question__item__input__container">
            <input type="text" className="question__item__input" {...props} />
            {hasAddButton ? (
              <button
                className="btn btn-primary question__item__input__button"
                onClick={handleAddInput}
              >
                +
              </button>
            ) : null}
          </div>
        )
      ) : (
        <span className="question__item__span">{props.value}</span>
      )}
    </div>
  );
}

export default QuestionItemInput;
