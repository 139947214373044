import React from 'react';
import { Col } from 'react-bootstrap';
import RatesList from '../../../../commonComponents/RatesList/RatesList';
import CreateRate from '../../../../commonComponents/Modals/CreateRate/CreateRate';
import './QualsList.css';

export default class QualsPage extends React.Component {
  componentWillMount = () => {
    this.props.onLoadQuals(this.props.pagination.page, this.props.selectedQual);
  };

  showCreateRateModal = () => {
    this.props.openModal({
      modalTitle: 'Create a Qual',
      modalContent: (
        <CreateRate
          onSubmit={this.props.onCreateQual}
          onCancel={this.props.closeModal}
          isQual={true}
        />
      ),
    });
  };

  render() {
    let { quals } = this.props;

    // if (!quals.find(qual => qual.id === 0))
    //   quals.unshift({ name: 'All', id: 0 });

    return (
      <Col xs={6} md={3} className="quals-list-container">
        <RatesList
          title="Quals"
          rates={quals}
          selected={this.props.selectedQual}
          onRateClick={qual => {
            this.props.onSelectQual(qual);
          }}
          pagination={this.props.pagination}
          onPageChange={page => {
            this.props.onLoadQuals(page);
          }}
          forQuals={true}
        />
      </Col>
    );
  }
}
