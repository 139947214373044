import * as t from './actionTypes';

export const updateCheckedSubtopics = (subtopics) => ({
  type: t.UPDATECHECKEDSUBTOPICS,
  payload: subtopics,
});

export const updateCheckedReferences = (references) => ({
  type: t.UPDATECHECKEDREFERENCES,
  payload: references,
});

export const updateDutyState = (dutyState) => ({
  type: t.UPDATEDUTYSTATE,
  payload: dutyState,
});

export const hasMoreData = (value) => ({
  type: t.SET_HAS_MORE_DATA,
  payload: value,
});

export const setIsUndoChecked = (value) => ({
  type: t.SET_IS_UNDO_CHECKED,
  payload: value,
});

export const setIsRevertChecked = (value) => ({
  type: t.SET_IS_REVERT_CHECKED,
  payload: value,
});

export const setUndoState = (value) => ({
  type: t.SET_UNDO_STATE,
  payload: value,
});

export const setActualState = (value) => ({
  type: t.SET_ACTUAL_STATE,
  payload: value,
});

export const setReferences = (references) => ({
  type: t.SET_REFERENCES,
  references,
});

export const setReferenceById = (reference) => ({
  type: t.SET_REFERENCE_BY_ID,
  reference,
});

export const updateRank = (rank) => ({
  type: t.UPDATERANK,
  payload: rank,
});

export const updateDutyStates = (dutyStates) => ({
  type: t.UPDATEDUTYSTATES,
  payload: dutyStates,
});

export const updateRanks = (ranks) => ({
  type: t.UPDATERANKS,
  payload: ranks,
});

export const updateCycleAction = (rank) => ({
  type: t.UPDATECYCLE,
  payload: rank,
});

export const referencesReceivedAction = (response) => ({
  type: t.REFERENCESRECEIVED,
  payload: response,
});

export const searchReferenceReceived = (response, isInfiniteList, page) => ({
  type: t.SEARCH_REFS_RECEIVED,
  payload: response,
  isInfiniteList,
  page,
});

export const searchReferenceDuplicateChapter = (refs) => ({
  type: t.SEARCH_DUPLICATE_CHAPTER,
  refs,
});

export const bookSelectAction = (referenceId) => ({
  type: t.REFERENCE_SELECT_DUPLICATE_CHAPTER,
  referenceId,
});

export const rateSelectAction = (rateId) => ({
  type: t.RATE_SELECT_DUPLICATE_CHAPTER,
  rateId,
});

export const referencesReceived = (response) => ({
  type: t.REFS_RECEIVED,
  payload: response,
});

export const createReferenceReceivedAction = (response) => ({
  type: t.REFERENCECREATION,
  payload: response,
});

export const createChapterReceivedAction = (response) => ({
  type: t.CHAPTERCREATION,
  payload: response,
});

export const loadAddChapter = (value) => {
  {
    return {
      type: t.LOAD_ADD_CHAPTER,
      value,
    };
  }
};
export const submitBibReceivedAction = (response) => ({
  type: t.SUBMITBIB,
  payload: response,
});

export const examReceivedAction = (response) => ({
  type: t.EXAMRECEIVED,
  payload: response,
});

export const updateLoadingExam = (status) => ({
  type: t.UPDATELOADINGEXAM,
  payload: status,
});

export const updateLoadingGrid = (status) => ({
  type: t.UPDATELOADINGGRID,
  status,
});

export const loadReferencesDuplicateChapter = (status) => ({
  type: t.LOADREFERENCESDUPLICATECHAPTER,
  status,
});

export const updateLoadingInfiniteScroll = (status) => ({
  type: t.UPDATE_LOADING_INFINITE_SCROLL,
  status,
});

export const updateReference = (reference) => ({
  type: t.UPDATE_REFERENCE,
  payload: reference,
});

export const deleteReference = (referenceId) => ({
  type: t.DELETE_REFERENCE,
  payload: referenceId,
});

export const deleteChapterAction = (
  chapterId,
  referenceId,
  presentationIndex,
) => ({
  type: t.DELETE_CHAPTER_ACTION,
  payload: { chapterId, referenceId, presentationIndex },
});

export const updateChapterAction = (response) => ({
  type: t.UPDATE_CHAPTER_ACTION,
  payload: response,
});

export const updateChapterAfterUpdate = (response) => ({
  type: t.UPDATE_CHAPTER,
  payload: response,
});

export const updateChapterAfterUpdateInfo = (response) => ({
  type: t.UPDATE_CHAPTER_INFO,
  payload: response,
});

export const setArchived = (response, filter) => ({
  type: t.ARCHIVED,
  payload: response,
  filter,
});

export const updateReferenceAfterDeletePdf = (response) => ({
  type: t.UPDATE_REFERENCE_DELETE_PDF,
  payload: response,
});

export const updateChapterAfterDeletePdf = (response) => ({
  type: t.UPDATE_CHAPTER_DELETE_PDF,
  payload: response,
});

export const relocateChapters = (chapter1, chapter2) => ({
  type: t.RELOCATE_CHAPTERS,
  chapter1,
  chapter2,
});

export const assignWritersChapter = (response) => ({
  type: t.ASSIGN_WRITERS_CHAPTER_ACTION,
  payload: response,
});

export const setChapterBooksPage = (chapter) => ({
  type: t.SET_CHAPTER_BOOKS_PAGE,
  chapter,
});

export const deleteChapterForBooksPage = (chapter) => ({
  type: t.DELETE_CHAPTER_BOOKS_PAGE,
  chapter,
});

export const updateSelectedTab = (selectedTab) => ({
  type: t.UPDATE_SELECTED_TAB,
  selectedTab,
});

export const updateSelectedListTab = (selectedListTab) => ({
  type: t.UPDATE_SELECTED_LIST_TAB,
  selectedListTab,
});

export const updateBibLogs = (bibliographyLogs) => ({
  type: t.UPDATE_BIB_LOGS,
  bibliographyLogs,
});

export const setRatingExamQuestions = (
  ratingExamQuestions,
  isAdd,
  isReplace,
) => ({
  type: t.SET_RATING_EXAM_QUESTIONS,
  ratingExamQuestions,
  isAdd,
  isReplace,
});

export const setRateBibliographyJson = (json) => ({
  type: t.SET_RATE_BIBLIOPGRAPHY_JSON,
  json,
});

export const setRateChapterQuestionsReport = (json) => ({
  type: t.SET_RATE_CHAPTER_QUESTION_REPORT,
  json,
});

export const setPaginationPage = (page) => ({
  type: t.SET_PAGINATION_PAGE,
  page,
});

export const addAssignmentToBook = (assignment) => ({
  type: t.ADD_ASSIGNMENT_TO_BOOK,
  assignment,
});

export const updateChapterQuestionsQuantity = (
  referenceId,
  chapterId,
  questionsQty,
) => ({
  type: t.UPDATE_CHAPTER_QUESTIONS_QTY,
  questionsQty,
  referenceId,
  chapterId,
});
