export default function compare(obj1, obj2) {
  let result = {};
  for (let key in obj1) {
    if (obj2[key] !== obj1[key]) result[key] = obj2[key];
    if (obj2[key] instanceof Array && typeof obj1[key] instanceof Array)
      result[key] = compare(obj1[key], obj2[key]);
    if (obj2[key] instanceof Array && obj1[key] instanceof Array)
      result[key] = compare(obj1[key], obj2[key]);
  }
  return result;
}
