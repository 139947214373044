export const GET_ALL_RATES = 'GET_ALL_RATES';
export const SET_ALL_RATES = 'SET_ALL_RATES';
export const SET_RATE = 'SET_RATE';
export const GET_USER_SUBMITTED_QUESTIONS = 'GET_USER_SUBMITTED_QUESTIONS';
export const GET_USER_DELETED_SUBMITTED_QUESTIONS =
  'GET_USER_DELETED_SUBMITTED_QUESTIONS';
export const SET_USER_SUBMITTED_QUESTIONS = 'SET_USER_SUBMITTED_QUESTIONS';
export const GET_FAVORITE_QUESTIONS = 'GET_FAVORITE_QUESTIONS';
export const SET_USER_FAVORITE_QUESTIONS = 'SET_USER_FAVORITE_QUESTIONS';
export const DELETE_SUBMITTED_QUESTION = 'DELETE_SUBMITTED_QUESTION';
export const UNDELETE_SUBMITTED_QUESTION = 'UNDELETE_SUBMITTED_QUESTION';
export const APPROVE_SUBMITTED_QUESTION = 'APPROVE_SUBMITTED_QUESTION';
export const REJECT_SUBMITTED_QUESTION = 'REJECT_SUBMITTED_QUESTION';
export const EDIT_SUBMITTED_QUESTION = 'EDIT_SUBMITTED_QUESTION';
export const REMOVE_FAVORITE_QUESTION = 'REMOVE_FAVORITE_QUESTION';
export const SET_IS_LOADING_FAVORITE_QUESTIONS =
  'SET_IS_LOADING_FAVORITE_QUESTIONS';
export const SET_IS_LOADING_SUBMITTED_QUESTIONS =
  'SET_IS_LOADING_SUBMITTED_QUESTIONS';
