import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'login');
}

export const getGeneralRolesPermissions = state =>
  getState(state).get('generalRolesPermissions');

export const getRolesPermissions = state =>
  getState(state).get('rolesPermissions');

export const getMessages = state => getState(state).get('messages');

export const getMessage = state => getState(state).get('message');

export const getErrors = state => getState(state).get('errors');

export const getLoading = state => getState(state).get('loading');

export const getSuccessful = state => getState(state).get('successful');

export const getRequesting = state => getState(state).get('requesting');

export const getUser = state => getState(state).get('user');

export const getLogin = state => getState(state).get('login');

export const getAuthyRequesting = state =>
  getState(state).get('authy_requesting');

export const getAuthyRetry = state => getState(state).get('authy_retry');

export const getEmail = state => getState(state).get('email');

export const getAuthyWaiting = state => getState(state).get('authy_waiting');

export const getCurrentUserPhone = state => getState(state).get('user_phone');

export const getCurrentUserCc = state => getState(state).get('user_cc');
