import * as t from './actionTypes';

export const updateWaiting = (value) => ({
  type: t.UPDATE_WAITING,
  value,
});

export const setFinishProcessing = (value) => ({
  type: t.FINISH_PROCESSING,
  value,
});

export const addFilter = (filter) => ({
  type: t.ADD_FILTER,
  filter,
});

export const removeFilter = (filter) => ({
  type: t.REMOVE_FILTER,
  filter,
});

export const editFilter = (filter) => ({
  type: t.EDIT_FILTER,
  filter,
});

export const setFilters = (filters) => ({
  type: t.SET_FILTERS,
  filters,
});

export const onExpandAll = (expandAll) => ({
  type: t.UPDATE_EXPAND_ALL,
  expandAll,
});

export const setSearchText = (text) => ({
  type: t.UPDATE_SEARCH_TEXT,
  text,
});

export const setLastBookOpenPerRateActions = (rate, book) => ({
  type: t.SET_LAST_BOOK_OPEN_PER_RATE,
  rate,
  book,
});

export const setSelectedRate = (rate) => ({
  type: t.SET_SELECTED_RATE,
  rate,
});

export const setIsViewDeletedBooks = (isViewDeletedBooks) => ({
  type: t.SET_IS_VIEW_DELETED_BOOKS,
  isViewDeletedBooks,
});

export const setQuestionsCSV = (questionsCSV) => ({
  type: t.SET_CHAPTER_QUESTIONS_CSV,
  questionsCSV,
});

export const setQuestionCsvByDetail = (questionsCSV) => ({
  type: t.SET_QUESTIONS_CSV_BY_DETAIL,
  questionsCSV,
});

export const setLogs = (logs) => ({
  type: t.SET_LOGS,
  logs,
});

export const setLastSelectedId = (value) => ({
  type: t.SET_LAST_SELECTED_ID,
  value,
});

export const setLastSelectedPanel = (value) => ({
  type: t.SET_LAST_SELECTED_PANEL,
  value,
});

export const setLastSelectedTreeView = (value) => ({
  type: t.SET_LAST_SELECTED_TREE_VIEW,
  value,
});

export const setSidebarScroll = (value) => ({
  type: t.SET_SIDEBAR_SCROLL,
  value,
});

export const setMainScroll = (value) => ({
  type: t.SET_MAIN_SCROLL,
  value,
});

export const setNamesToBuildSecondBar = (references) => ({
  type: t.SET_NAMES_TO_BUILD_SECOND_BAR,
  references,
});

export const urlPdfPrivate = (url) => ({
  type: t.SET_URL_PDF_PRIVATE,
  url,
});
