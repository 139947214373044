import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import QuestionHistory from './QuestionHistory';
import QuestionHistoryDetails from './QuestionHistoryDetails';

function QuestionHistoryModal({
  questionHistory,
  showModal,
  setShowModalQuestionHistory,
}) {
  const [showDetail, setShowDetail] = useState(false);
  const [historySelectedForDetails, setHistorySelectedForDetails] =
    useState(null);

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModalQuestionHistory(false);
      }}
      className="modal-question-history"
    >
      <Modal.Header closeButton>
        <Modal.Title>Question History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showDetail && historySelectedForDetails ? (
          <QuestionHistoryDetails
            history={questionHistory.find(
              (h) => h.id === historySelectedForDetails,
            )}
          />
        ) : (
          <QuestionHistory
            questionHistory={questionHistory}
            setShowDetail={setShowDetail}
            setHistorySelectedForDetails={setHistorySelectedForDetails}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {showDetail && historySelectedForDetails && (
          <Button
            variant="secondary"
            onClick={() => {
              setHistorySelectedForDetails(null);
            }}
          >
            Back
          </Button>
        )}

        <Button
          variant="secondary"
          onClick={() => {
            setShowModalQuestionHistory(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default QuestionHistoryModal;
