import { List, Map } from 'immutable';
import _ from 'underscore';

import * as type from './actionTypes';

const initialState = Map({
  reference: {},
  chapter: {},
  waiting: false,
  questions: List(),
  allQuestions: List(),
  pagination: { total: 1, page: 1, rows: 10 },
  isLoadingQuestions: false,
  questionsAssigment: [],
  assigment: {},
  publishedQuestion: {},
  waitingPdf: false,
  questionPdfProcessing: null,
  selectedView: 'listView',
  selectedQuestion: null,
  isCreatingQuestion: false,
  isSaveQuestion: false,
  isShowDeletedQuestions: false,
  urlQuestion: null,
  currentPage: 0,
  createAnother: false,
  updating: false,
  editPermission: null,
  amountOfQuestions: 0,
  questionsToDownload: [],
  isCreatingAiQuestion: false,
  userCustomPrompts: [],
  questionsForDeleted: [],
  showQuestionHistoryModal: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case type.UPDATE_QUESTION_PDF:
      return state.set(`questionPdfProcessing`, action.question);

    case type.UPDATE_SELECTED_VIEW:
      return state.set(`selectedView`, action.view);

    case type.UPDATE_WAITING_PDF:
      return state.set(`waitingPdf`, action.value);

    case type.SET_CURRENT_PAGE:
      return state.set(`currentPage`, action.currentPage);

    case type.SET_IS_SHOW_DELETED:
      return state.set(`isShowDeletedQuestions`, action.isShowDeleted);

    case type.UPDATE_WAITING:
      return state.set(`waiting`, action.waiting);

    case type.UPDATE_UPDATING:
      return state.set(`updating`, action.updating);

    case type.SET_CREATE_ANOTHER:
      return state.set('createAnother', action.value);

    case type.SET_IS_LOADING_QUESTIONS:
      return state.set(`isLoadingQuestions`, action.isLoadingQuestions);

    case type.SET_REFERENCE:
      let updatedState = state.set('reference', { ...action.payload });
      if (action.payload.actAsParent === false) {
        const updatedChapter = updatedState.get('chapter');
        updatedChapter.pdfUrl = action.payload.chapters[0].pdfUrl;
        updatedChapter.pdfPages = action.payload.chapters[0].pdfPages;
        updatedState = updatedState.set('chapter', updatedChapter);
      }
      return updatedState;

    case type.SET_CHAPTER:
      let updateQuestionsFile = state.get('questions');
      if (action.deleting)
        updateQuestionsFile = updateQuestionsFile.map((question) => {
          if (question.chapter.id) question.chapter.pdfUrl = null;
          else if (question.chapter === action.chapter.id)
            question.chapter = action.chapter;
          return question;
        });
      if (action.adding)
        updateQuestionsFile = updateQuestionsFile.map((question) => {
          if (question.chapter.id)
            question.chapter.pdfUrl = action.chapter.pdfUrl;
          else if (question.chapter === action.chapter.id)
            question.chapter = action.chapter;
          return question;
        });
      return state
        .set('chapter', action.chapter)
        .set('questions', updateQuestionsFile);

    case type.SET_CHAPTER_AFTER_DELETE_PDF:
      const updatedChapter = state.get('chapter');
      updatedChapter.pdfUrl = '';
      updatedChapter.pdfPages = null;
      return state.set('chapter', updatedChapter);

    case type.SET_PUBLISHED_QUESTION:
      return state.set('publishedQuestion', action.question);

    case type.SET_QUESTIONS:
      if (action.isInfiniteScroll) {
        const orderedQuestions = state
          .get('questions')
          .concat(List(action.payload.data))
          .sortBy((question) => question.presentationIndex);

        const distinct = (array, indice) => {
          const uniques = [];
          return array.filter((item) =>
            uniques.indexOf(item[indice]) < 0
              ? uniques.push(item[indice])
              : false,
          );
        };
        // get questions distinct by id
        const distinctOrderedQuestions = distinct(orderedQuestions, 'id');

        return state
          .set('questions', distinctOrderedQuestions)
          .set('pagination', {
            total: action.payload.pagination.total,
            page: action.page,
            rows: action.rows ? action.rows : 10,
          });
      }
      const orderedQuestions = _.sortBy(
        action.payload.data,
        (question) => question.presentationIndex,
      );
      return state.set('questions', List(orderedQuestions)).set('pagination', {
        total: action.payload.pagination.total,
        page: action.page,
        rows: action.rows ? action.rows : 10,
      });

    case type.SET_QUESTION_CHANGE_STATE:
      return state.set(
        'questions',
        state.get('questions').map((question) => {
          if (question.id === action.payload.id) {
            question.enabled = action.payload.enabled;
          }
          return question;
        }),
      );

    case type.SET_CHAPTER_EDIT_SUBTOPICS:
      const chapterEditedSubtopics = state.get('chapter');
      let uptatedSubtopics = [];
      if (action.payload.subtopics && action.payload.subtopics.length) {
        uptatedSubtopics = action.payload.subtopics.map((subtopic) => ({
          ...subtopic,
          topic: subtopic.topic.id,
        }));
      }
      chapterEditedSubtopics.subtopics = uptatedSubtopics;
      return state.set('chapter', chapterEditedSubtopics);

    case type.ADD_QUESTION: {
      const updatedPagination = state.get('pagination');
      updatedPagination.total++;
      return state
        .set('questions', state.get('questions').push(action.question))
        .set('pagination', updatedPagination);
    }
    case type.BATCH_ADD_QUESTION:
      const batchUpdatedPagination = state.get('pagination');
      batchUpdatedPagination.total += action.questions.length;
      return state
        .set('questions', state.get('questions').concat(List(action.questions)))
        .set('pagination', batchUpdatedPagination);

    case type.UPDATE_QUESTION:
      const questionListUpdated = updatePresentationIndex(
        state.get('questions'),
        action.question,
      );
      const stateQuestionUpdated = questionListUpdated.map((question) => {
        if (!question.sourceQuestion) {
          if (action.question.sourceQuestion) {
            if (question.id === action.question.sourceQuestion) {
              return action.question;
            }
          } else {
            if (question.id === action.question.id) {
              return question;
            }
          }
        } else {
          if (question.id === action.question.id) {
            return action.question;
          }
        }
        if (question.id === action.question.id) {
          question.enabled = action.question.enabled;
        }
        return question;
      });
      return state.set('questions', List(stateQuestionUpdated));

    case type.DELETE_QUESTION: {
      const updatedPagination = state.get('pagination');
      updatedPagination.total--;

      const questionsUpdated = state
        .get('questions')
        .filter((question) => question.id !== action.payload);
      // reorder presentation index after delete
      // .map((question) => {
      //   question.presentationIndex = presentationIndex++;
      //   return question;
      // });

      return state
        .set('questions', questionsUpdated)
        .set('pagination', updatedPagination);
    }
    case type.SET_SELECTED_QUESTION:
      return state.set('selectedQuestion', action.question);

    case type.ADD_ALL_QUESTIONS:
      return state.set('allQuestions', action.questions.questions);

    case type.SET_ON_SAVE_QUESTION:
      return state.set('isSaveQuestion', action.isSaveQuestion);

    case type.SET_URL_QUESTION:
      return state.set(`urlQuestion`, action.question);

    case type.IS_REFRESH_FORM:
      return state.set(`isRefreshForm`, action.isRefreshForm);

    case type.RESTORE_QUESTION:
      return state.set(
        'questions',
        state
          .get('questions')
          .map((question) =>
            question.sourceQuestion === action.question.id
              ? action.question
              : question,
          ),
      );

    case type.SET_EDIT_PERMISSION:
      return state.set('editPermission', action.value);
    case type.SET_AMOUNT_OF_QUESTIONS_IN_CHAPTER:
      return state.set('amountOfQuestions', action.value);
    case type.SET_QUESTIONS_TO_DOWNLOAD:
      return state.set('questionsToDownload', action.questions);
    case type.CLEAR_QUESTIONS_TO_DOWNLOAD:
      return state.set('questionsToDownload', []);
    case type.SET_IS_CREATING_AI_QUESTION:
      return state.set('isCreatingAiQuestion', action.value);
    case type.SET_USER_CUSTOM_PROMPTS:
      return state.set('userCustomPrompts', action.userCustomPrompts);
    case type.SET_SELECTED_QUESTIONS_FOR_DELETED:
      let array;
      array = state.get('questionsForDeleted');
      if (!action.value) {
        if (action.question) {
          array.push(action.question);
        } else {
          array = [];
        }
      } else {
        array = array.filter((item) => item !== action.question);
      }
      return state.set('questionsForDeleted', array);

    case type.SET_QUESTION_HISTORY:
      return state.set('questionHistory', action.questionHistory);

    case type.SET_SHOW_MODAL_QUESTION_HISTORY:
      return state.set('showQuestionHistoryModal', action.value);

    case type.SET_IMPROVE_AI_IN_QUESTION: {
      const questionsUpdated = state.get('questions').map((q) =>
        q.id === action.questionId
          ? {
              ...q,
              aiSuggestion: action.aiSuggestion,
              aiSuggestionStatus: action.status,
            }
          : q,
      );

      const questionUpdated = questionsUpdated.find(
        (q) => q.id === action.questionId,
      );

      // if receive a background message to aiSuggestion for the question selected, update both, otherwise only the array.
      if (
        state.get('selectedQuestion') &&
        state.get('selectedQuestion').id === questionUpdated.id
      ) {
        return state
          .set('questions', questionsUpdated)
          .set('selectedQuestion', questionUpdated);
      }
      return state.set('questions', questionsUpdated);
    }
    case type.SET_INCLUDE_NOTES_IN_SUGGESTION: {
      let updatedQuestion = null;

      const questionsUpdated = state.get('questions').map((q) => {
        if (q.id === action.questionId) {
          const updatedQ = { ...q, includeNotesInSuggestion: action.value };
          updatedQuestion = updatedQ;
          return updatedQ;
        }
        return q;
      });

      return state
        .set('questions', questionsUpdated)
        .set('selectedQuestion', updatedQuestion);
    }

    default:
      return state;
  }
}

function updatePresentationIndex(questions, questionUpdated) {
  let incrementPositionIndex = false;
  let decrementPositionIndex = false;
  let actualPosition;
  const questionsListUpdated = questions.map((question) => {
    if (question.id === questionUpdated.id) {
      actualPosition = question.presentationIndex;
      if (question.presentationIndex !== questionUpdated.presentationIndex) {
        if (questionUpdated.presentationIndex < question.presentationIndex) {
          incrementPositionIndex = true;
        } else {
          decrementPositionIndex = true;
        }
      }
      const returnedValue = questionUpdated;
      return returnedValue;
    }
    return question;
  });
  const stateQuestionsUpdatedInfo = questionsListUpdated.map((question) => {
    if (
      incrementPositionIndex &&
      question.presentationIndex >= questionUpdated.presentationIndex &&
      question.presentationIndex < actualPosition &&
      question.id !== questionUpdated.id &&
      question.chapter.id === questionUpdated.chapter.id
    ) {
      const auxQuestionUpdatePosition = question;
      auxQuestionUpdatePosition.presentationIndex += 1;
      return auxQuestionUpdatePosition;
    }
    if (
      decrementPositionIndex &&
      question.presentationIndex > actualPosition &&
      question.presentationIndex <= questionUpdated.presentationIndex &&
      question.id !== questionUpdated.id &&
      question.chapter.id === questionUpdated.chapter.id
    ) {
      const auxQuestionUpdatePositionDec = question;
      auxQuestionUpdatePositionDec.presentationIndex -= 1;
      return auxQuestionUpdatePositionDec;
    }
    return question;
  });

  return stateQuestionsUpdatedInfo;
}
