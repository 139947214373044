import { connect } from 'react-redux';

import {
  clearAutomaticActiveSubscriptions,
  clearPropsSubscriptions,
  downloadActiveSubscriptions,
  getActiveSubscriptions,
  latestCsvCreationDateSubscriptions,
} from '../../actions';
import {
  getActiveSubscriptionsError,
  getaskingFor,
  getAutomaticSubscriptionsReport,
  getCSVData,
  getCsvDataSubsActive,
  getLatestCsvCreationDateSubscriptions,
  hasMore,
  lastCustomerSubscription,
} from '../../selectors';
import ActiveSubscriptions from './getActiveSubscriptions';

const mapStateToProps = (state) => ({
  CSVData: getCSVData(state),
  lastCustomer: lastCustomerSubscription(state),
  hasMore: hasMore(state),
  askingFor: getaskingFor(state),
  activeSubscriptionsError: getActiveSubscriptionsError(state),
  automaticSubscriptionsReport: getAutomaticSubscriptionsReport(state),
  latestCsvDate: getLatestCsvCreationDateSubscriptions(state),
  csvDataSubsActive: getCsvDataSubsActive(state),
});

const mapDispatchToProps = (dispatch) => ({
  getActiveSubscriptions: (lastCustomerSubscription, askingFor) => {
    dispatch(getActiveSubscriptions(lastCustomerSubscription, askingFor));
  },
  clearPropsSubscriptions: () => {
    dispatch(clearPropsSubscriptions());
  },
  downloadActiveSubscriptions: () => {
    dispatch(downloadActiveSubscriptions());
  },
  latestCsvCreationDateSubsActive: () => {
    dispatch(latestCsvCreationDateSubscriptions());
  },
  clearCsv: () => {
    dispatch(clearAutomaticActiveSubscriptions());
  },
});

const ActiveSubscriptionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActiveSubscriptions);

export default ActiveSubscriptionsContainer;
