import React from 'react';
import './CycleForm.css';
import { Field, reduxForm } from 'redux-form';
import Button from 'react-bootstrap-button-loader';
import {
  InputWithoutIcon,
  RadioButton,
  SimpleInput,
} from '../../../commonComponents/Input/Input';
import { Datepicker } from '../../../commonComponents/Datepicker/Datepicker';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import CheckBoxList from '../../../commonComponents/CheckBoxList/CheckBoxList';
import MoveRatesModal from './MoveRatesModal';

class CycleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialSetup: false,
      errors: null,
      waiting: false,
      waitingSetActive: false,
      waitingArchive: false,
      waitingUpdate: false,
      waitingDelete: false,
      action: '',
      checkedOptionA: false,
      checkedOptionB: false,
      checkedOptionC: false,
      checkedOptionD: false,
      dateE4: null,
      dateE5: null,
      dateE6: null,
      dateE7: null,
      focusedE4: false,
      focusedE5: false,
      focusedE6: false,
      focusedE7: false,
      title: '',
      description: '',
      rateSearch: '',
      selectAllRates: false,
      ratesChecked: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submitSucceeded && this.state.waiting) {
      this.setState({ waiting: false });
    }

    if (prevProps.submitSucceeded && this.state.waitingSetActive) {
      this.setState({ waitingSetActive: false });
    }
    if (prevProps.submitSucceeded && this.state.waitingArchive) {
      this.setState({ waitingArchive: false });
    }
    if (prevProps.submitSucceeded && this.state.waitingUpdate) {
      this.setState({ waitingUpdate: false });
    }
    if (prevProps.submitSucceeded && this.state.waitingDelete) {
      this.setState({ waitingDelete: false });
    }
    let { cycle } = this.props;
    if (
      cycle.id !== prevProps.cycle.id ||
      !this.state.initialSetup ||
      this.props.updatedCycle
    ) {
      let optionA = cycle && cycle.type === 'A' ? true : false;
      let optionB = cycle && cycle.type === 'B' ? true : false;
      let optionC = cycle && cycle.type === 'C' ? true : false;
      let optionD = cycle && cycle.type === 'D' ? true : false;
      let dateE4 = cycle && cycle.dateE4 ? moment.utc(cycle.dateE4) : null;
      let dateE5 = cycle && cycle.dateE5 ? moment.utc(cycle.dateE5) : null;
      let dateE6 = cycle && cycle.dateE6 ? moment.utc(cycle.dateE6) : null;
      let dateE7 = cycle && cycle.dateE7 ? moment.utc(cycle.dateE7) : null;
      this.setState({
        checkedOptionA: optionA,
        checkedOptionB: optionB,
        checkedOptionC: optionC,
        checkedOptionD: optionD,
        errors: null,
        dateE4: dateE4,
        dateE5: dateE5,
        dateE6: dateE6,
        dateE7: dateE7,
        focusedE4: false,
        focusedE5: false,
        focusedE6: false,
        focusedE7: false,
        initialSetup: true,
        rateSearch: '',
        selectAllRates: false,
        ratesChecked: {},
      });
      this.props.setUpdatedCycle(false);
    }
  }

  handleSubmit = (fields) => {};

  validate = (fields, callback) => {
    let errors = {};

    if (this.props.cycle) {
      fields.id = this.props.cycle.id;
      fields.type = this.props.cycle.type;
    } else {
      let auxFields = {
        description: fields.description,
        title: fields.title,
        type: fields.type,
      };
      fields = auxFields;
    }

    if (!fields.title) {
      errors.title = 'Title field is required';
    }

    if (!fields.description) {
      errors.description = 'Description field is required';
    }

    if (
      this.props.cycle &&
      (this.state.checkedOptionA ||
        this.state.checkedOptionB ||
        this.state.checkedOptionC ||
        this.state.checkedOptionD)
    ) {
      fields.type = this.state.checkedOptionA
        ? 'A'
        : this.state.checkedOptionB
        ? 'B'
        : this.state.checkedOptionC
        ? 'C'
        : 'D';
    } else {
      errors.type = 'You must select an option';
    }

    if (
      !this.state.dateE4 &&
      (this.state.checkedOptionA || this.state.checkedOptionC)
    ) {
      errors.dateE4 = 'You must select an exam date';
    } else {
      if (this.state.checkedOptionA || this.state.checkedOptionC) {
        fields.dateE4 = this.state.dateE4.toJSON();
      }
    }

    if (
      !this.state.dateE5 &&
      (this.state.checkedOptionA || this.state.checkedOptionC)
    ) {
      errors.dateE5 = 'You must select an exam date';
    } else {
      if (this.state.checkedOptionA || this.state.checkedOptionC) {
        fields.dateE5 = this.state.dateE5.toJSON();
      }
    }
    if (
      !this.state.dateE6 &&
      (this.state.checkedOptionA || this.state.checkedOptionC)
    ) {
      errors.dateE6 = 'You must select an exam date';
    } else {
      if (this.state.checkedOptionA || this.state.checkedOptionC) {
        fields.dateE6 = this.state.dateE6.toJSON();
      }
    }
    if (
      !this.state.dateE7 &&
      (this.state.checkedOptionB || this.state.checkedOptionD)
    ) {
      errors.dateE7 = 'You must select an exam date';
    } else {
      if (this.state.checkedOptionB || this.state.checkedOptionD) {
        fields.dateE7 = this.state.dateE7.toJSON();
      }
    }

    if (
      this.state.action === 'Update' &&
      this.props.cycle.isActive &&
      this.props.cycle.type !== fields.type
    ) {
      this.props.showNotificationError(
        'Please, activate another cycle with the same type, before updating the current one.',
      );
      return;
    }

    if (this.state.action === 'Archive' && this.props.cycle.isActive) {
      this.props.showNotificationError(
        'Please, activate another cycle with the same type, before archiving the current one.',
      );
      return;
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
    if (this.state.action === 'SetActive') {
      this.setState({ waitingSetActive: true });
    }
    if (this.state.action === 'Archive') {
      this.setState({ waitingArchive: true });
    }
    if (this.state.action === 'Update') {
      this.setState({ waitingUpdate: true });
    }
    if (this.state.action === 'MoveSubstitutes') {
      this.setState({ waitingUpdate: true });
    }
    if (this.state.action === 'Delete') {
      this.setState({ waitingDelete: true });
    }
    this.setState({ errors: null, waiting: true });
    callback(fields);
  };

  renderInput = (
    name,
    placeholder,
    type = 'text',
    icon,
    checked = false,
    onCheck,
    validation = true,
    defaultValue,
    radioValue = '',
  ) => {
    let disabled = this.props.cycle ? this.props.cycle.isArchived : false;
    return (
      <div className="form-group">
        {type !== 'radio' && <span className="label">{placeholder}:</span>}
        {type !== 'radio' && (
          <Field
            name={name}
            type={type}
            component={InputWithoutIcon}
            icon={icon}
            placeholder={placeholder}
            defaultValue={defaultValue}
            disabled={disabled}
          />
        )}
        {type === 'radio' && (
          <Field
            name={name}
            type={type}
            component={RadioButton}
            label={placeholder}
            checked={checked}
            onCheck={onCheck}
            radioValue={radioValue}
            disabled={disabled}
          />
        )}
        {validation && this.state.errors && this.state.errors[name] && (
          <span className="errors">{this.state.errors[name]}</span>
        )}
      </div>
    );
  };

  renderRadioButton = (name, options) => {
    return (
      <div className="form-group">
        <Field name={name} component={RadioButton} options={options} />
        {this.state.errors && this.state.errors[name] && (
          <span className="errors">{this.state.errors[name]}</span>
        )}
      </div>
    );
  };

  onCheckOptionA = () => {
    let dateE4 =
      this.props.cycle && this.props.cycle.dateE4
        ? moment.utc(this.props.cycle.dateE4)
        : null;
    let dateE5 =
      this.props.cycle && this.props.cycle.dateE5
        ? moment.utc(this.props.cycle.dateE5)
        : null;
    let dateE6 =
      this.props.cycle && this.props.cycle.dateE6
        ? moment.utc(this.props.cycle.dateE6)
        : null;
    let dateE7 =
      this.props.cycle && this.props.cycle.dateE7
        ? moment.utc(this.props.cycle.dateE7)
        : null;
    this.setState({
      checkedOptionA: !this.state.checkedOptionA,
      checkedOptionB: false,
      checkedOptionC: false,
      checkedOptionD: false,
      dateE4: dateE4,
      dateE5: dateE5,
      dateE6: dateE6,
      dateE7: dateE7,
      focusedE4: false,
      focusedE5: false,
      focusedE6: false,
      focusedE7: false,
    });
  };

  onCheckOptionB = () => {
    let dateE4 =
      this.props.cycle && this.props.cycle.dateE4
        ? moment.utc(this.props.cycle.dateE4)
        : null;
    let dateE5 =
      this.props.cycle && this.props.cycle.dateE5
        ? moment.utc(this.props.cycle.dateE5)
        : null;
    let dateE6 =
      this.props.cycle && this.props.cycle.dateE6
        ? moment.utc(this.props.cycle.dateE6)
        : null;
    let dateE7 =
      this.props.cycle && this.props.cycle.dateE7
        ? moment.utc(this.props.cycle.dateE7)
        : null;
    this.setState({
      checkedOptionA: false,
      checkedOptionB: !this.state.checkedOptionB,
      checkedOptionC: false,
      checkedOptionD: false,
      dateE4: dateE4,
      dateE5: dateE5,
      dateE6: dateE6,
      dateE7: dateE7,
      focusedE4: false,
      focusedE5: false,
      focusedE6: false,
      focusedE7: false,
    });
  };

  onCheckOptionC = () => {
    let dateE4 =
      this.props.cycle && this.props.cycle.dateE4
        ? moment.utc(this.props.cycle.dateE4)
        : null;
    let dateE5 =
      this.props.cycle && this.props.cycle.dateE5
        ? moment.utc(this.props.cycle.dateE5)
        : null;
    let dateE6 =
      this.props.cycle && this.props.cycle.dateE6
        ? moment.utc(this.props.cycle.dateE6)
        : null;
    let dateE7 = null;
    this.setState({
      checkedOptionA: false,
      checkedOptionB: false,
      checkedOptionC: !this.state.checkedOptionC,
      checkedOptionD: false,
      dateE4: dateE4,
      dateE5: dateE5,
      dateE6: dateE6,
      dateE7: dateE7,
      focusedE4: false,
      focusedE5: false,
      focusedE6: false,
      focusedE7: false,
    });
  };

  onCheckOptionD = () => {
    let dateE4 = null;
    let dateE5 = null;
    let dateE6 = null;
    let dateE7 =
      this.props.cycle && this.props.cycle.dateE7
        ? moment.utc(this.props.cycle.dateE7)
        : null;
    this.setState({
      checkedOptionA: false,
      checkedOptionB: false,
      checkedOptionC: false,
      checkedOptionD: !this.state.checkedOptionD,
      dateE4: dateE4,
      dateE5: dateE5,
      dateE6: dateE6,
      dateE7: dateE7,
      focusedE4: false,
      focusedE5: false,
      focusedE6: false,
      focusedE7: false,
    });
  };

  renderDatepickerE4 = (cycle) => {
    return (
      <div className="form-group m-2">
        <Field
          name="dateE4"
          component={InputWithoutIcon}
          type="text"
          placeholder="Date"
          hidden
        />
        <Datepicker
          date={this.state.dateE4}
          onDateChange={(dateE4) => this.setState({ dateE4 })}
          focused={this.state.focusedE4}
          onFocusChange={(focusedE4) => this.setState({ focusedE4 })}
          label="E4:"
          disabled={cycle ? cycle.isArchived : false}
        />
        {this.state.errors && this.state.errors['dateE4'] && (
          <span className="errors">{this.state.errors['dateE4']}</span>
        )}
      </div>
    );
  };

  renderDatepickerE5 = (cycle) => {
    return (
      <div className="form-group m-2">
        <Field
          name="dateE5"
          component={InputWithoutIcon}
          type="text"
          placeholder="Date"
          hidden
        />
        <Datepicker
          date={this.state.dateE5}
          onDateChange={(dateE5) => this.setState({ dateE5 })}
          focused={this.state.focusedE5}
          onFocusChange={(focusedE5) => this.setState({ focusedE5 })}
          label="E5:"
          disabled={cycle ? cycle.isArchived : false}
        />
        {this.state.errors && this.state.errors['dateE5'] && (
          <span className="errors">{this.state.errors['dateE5']}</span>
        )}
      </div>
    );
  };

  renderDatepickerE6 = (cycle) => {
    return (
      <div className="form-group m-2">
        <Field
          name="dateE6"
          component={InputWithoutIcon}
          type="text"
          placeholder="Date"
          hidden
        />
        <Datepicker
          date={this.state.dateE6}
          onDateChange={(dateE6) => this.setState({ dateE6 })}
          focused={this.state.focusedE6}
          onFocusChange={(focusedE6) => this.setState({ focusedE6 })}
          label="E6:"
          disabled={cycle ? cycle.isArchived : false}
        />
        {this.state.errors && this.state.errors['dateE6'] && (
          <span className="errors">{this.state.errors['dateE6']}</span>
        )}
      </div>
    );
  };

  renderDatepickerE7 = (cycle) => {
    return (
      <div className="form-group m-2">
        <Field
          name="dateE7"
          component={InputWithoutIcon}
          type="text"
          placeholder="Date"
          hidden
        />
        <Datepicker
          date={this.state.dateE7}
          onDateChange={(dateE7) => this.setState({ dateE7 })}
          focused={this.state.focusedE7}
          onFocusChange={(focusedE7) => this.setState({ focusedE7 })}
          label="E7:"
          disabled={cycle ? cycle.isArchived : false}
        />
        {this.state.errors && this.state.errors['dateE7'] && (
          <span className="errors">{this.state.errors['dateE7']}</span>
        )}
      </div>
    );
  };

  datepickersOptionA = (cycle) => {
    return (
      <Row className="button-row">
        {this.renderDatepickerE4(cycle)}
        {this.renderDatepickerE5(cycle)}
        {this.renderDatepickerE6(cycle)}
      </Row>
    );
  };

  datepickersOptionB = (cycle) => {
    return <Row>{this.renderDatepickerE7(cycle)}</Row>;
  };

  renderDatepickers = (cycle) => {
    return (
      <div>
        {this.state.checkedOptionA && this.datepickersOptionA(cycle)}
        {(this.state.checkedOptionB || this.state.checkedOptionD) &&
          this.datepickersOptionB(cycle)}
        {this.state.checkedOptionC && this.datepickersOptionA(cycle)}
      </div>
    );
  };

  handleMoveRates = (ratesChecked) => {
    const selectedRates = Object.keys(ratesChecked).filter(
      (key) => ratesChecked[key],
    );

    this.props.onMoveRates(this.props.cycle.id, selectedRates);
    this.props.closeModal();
  };

  openRatesModal = () => {
    this.props.openModal({
      modalTitle: `Move rates to: ${this.props.cycle.title}`,
      modalContent: (
        <MoveRatesModal
          notShow={this.props.cycle.activeForRates || []}
          isWaiting={this.props.isWaiting}
          handleMoveRates={(rates) => this.handleMoveRates(rates)}
          closeModal={this.props.closeModal}
          rates={this.props.rates}
        />
      ),
    });
  };

  currentRates = () => {
    if (
      !this.props.cycle ||
      !this.props.cycle.activeForRates ||
      !this.props.cycle.activeForRates.length
    )
      return;
    const rates = this.props.cycle.activeForRates
      .map((rateId) => {
        const r = this.props.rates.find((rate) => rate.id == rateId);
        return r ? r.shortname : '';
      })
      .sort();
    return (
      <div>
        <strong>Current rates on this cycle ({rates.length}):</strong>
        {rates.map((rate) => (
          <span>{` ${rate}`}</span>
        ))}
      </div>
    );
  };

  render() {
    const {
      handleSubmit,
      onSubmit,
      onSetActive,
      onUpdate,
      onArchive,
      onMoveSubstitutes,
      rates,
    } = this.props;
    let disableButtons = this.props.cycle ? this.props.cycle.isArchived : false;
    let moveRatesDisabled = disableButtons;
    if (
      rates &&
      rates.length &&
      this.props.cycle &&
      this.props.cycle.activeForRates &&
      this.props.cycle.activeForRates.length
    ) {
      moveRatesDisabled =
        rates.length === this.props.cycle.activeForRates.length;
    }

    let archiveButtonText = disableButtons ? 'Unarchive' : 'Archive';

    return (
      <div className="cycle-form-component">
        <div className="card-block">
          <form
            className="widget-form new-cycle-form"
            onSubmit={handleSubmit((fields) => {
              if (this.state.action === 'SetActive') {
                if (this.props.cycle && !this.props.cycle.plusAction) {
                  onSetActive(this.props.cycle.id);
                } else {
                  this.validate(fields, onSubmit);
                }
              }
              if (this.state.action === 'MoveSubstitutes') {
                onMoveSubstitutes(this.props.cycle.id);
              }
              if (this.state.action === 'Update') {
                this.validate(fields, onUpdate);
              }
              if (
                this.state.action === 'Archive' ||
                this.state.action === 'Unarchive'
              ) {
                this.validate(fields, onArchive);
              }
            })}
          >
            <Row>
              <Col xs={12} md={6}>
                {this.renderInput(
                  'type',
                  'E4-E6 Regular & Substitute (option A)',
                  'radio',
                  '',
                  this.state.checkedOptionA,
                  this.onCheckOptionA,
                  false,
                  '',
                  'A',
                )}
                {this.renderInput(
                  'type',
                  'E7 Regular & Substitute (option B)',
                  'radio',
                  '',
                  this.state.checkedOptionB,
                  this.onCheckOptionB,
                  false,
                  '',
                  'B',
                )}
                {this.renderInput(
                  'type',
                  'E-4 through E-6 Reserve (option C)',
                  'radio',
                  '',
                  this.state.checkedOptionC,
                  this.onCheckOptionC,
                  true,
                  '',
                  'C',
                )}
                {this.renderInput(
                  'type',
                  'E-7 Reserve (option D)',
                  'radio',
                  '',
                  this.state.checkedOptionD,
                  this.onCheckOptionD,
                  true,
                  '',
                  'D',
                )}
              </Col>
              <Col xs={12} md={6}>
                {this.currentRates()}
              </Col>
            </Row>
            {this.renderDatepickers(this.props.cycle)}

            {this.renderInput(
              'title',
              'Title',
              'text',
              'reload',
              false,
              function () {},
              true,
              this.props.cycle ? this.props.cycle.title : '',
            )}
            {this.renderInput(
              'description',
              'Description',
              'text',
              'reload',
              false,
              function () {},
              true,
              this.props.cycle ? this.props.cycle.description : '',
            )}

            {this.state.errors && this.state.errors.general && (
              <div className="errors">{this.state.errors.general}</div>
            )}
            <Row className="button-row">
              <Button
                className="btn btn-success success-cycle-form m-2"
                type="submit"
                loading={this.state.waitingSetActive}
                onClick={() => this.setState({ action: 'SetActive' })}
                disabled={disableButtons || this.props.cycle.isActive}
              >
                {this.props.cycle && !this.props.cycle.plusAction
                  ? 'Set as the active cycle'
                  : 'Create'}
              </Button>
              {this.props.cycle && !this.props.cycle.plusAction && (
                <Button
                  className="btn btn-success success-cycle-form m-2"
                  type="submit"
                  loading={this.state.waitingUpdate}
                  onClick={() => this.setState({ action: 'MoveSubstitutes' })}
                  disabled={disableButtons}
                >
                  Move substitutes here
                </Button>
              )}
              {this.props.cycle && !this.props.cycle.plusAction && (
                <Button
                  className="btn btn-success success-cycle-form m-2"
                  loading={this.state.waitingUpdate}
                  onClick={() => this.openRatesModal()}
                  disabled={moveRatesDisabled}
                >
                  Move rates here
                </Button>
              )}
              {this.props.cycle && !this.props.cycle.plusAction && (
                <Button
                  className="btn btn-primary success-cycle-form m-2"
                  type="submit"
                  loading={this.state.waitingArchive}
                  onClick={() => this.setState({ action: archiveButtonText })}
                >
                  {archiveButtonText}
                </Button>
              )}
              {this.props.cycle && !this.props.cycle.plusAction && (
                <Button
                  className="btn btn-primary success-cycle-form m-2"
                  type="submit"
                  loading={this.state.waitingUpdate}
                  onClick={() => this.setState({ action: 'Update' })}
                  disabled={disableButtons}
                >
                  Update
                </Button>
              )}
              {this.props.cycle && !this.props.cycle.plusAction && (
                <Button
                  className="btn btn-danger success-cycle-form m-2"
                  loading={this.state.waitingDelete}
                  onClick={() => {
                    this.setState({ action: 'Delete' });
                    this.props.onDelete(this.props.cycle.id);
                  }}
                  disabled={disableButtons}
                >
                  Delete
                </Button>
              )}
            </Row>
          </form>
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: 'CycleForm' })(CycleForm);
