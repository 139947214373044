import axios from 'axios';
import Cookies from 'universal-cookie';

import * as userToken from './userToken';

export default function redirectToMaintenancePage(callback) {
  const errorStatus = 503;
  const errorMessages = ['Maintenance'];

  const cookies = new Cookies();

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { response } = error;
        if (
          (response &&
            response.status === errorStatus &&
            'data' in response &&
            typeof response.data === 'object' &&
            'err' in response.data &&
            errorMessages.indexOf(response.data.err) !== -1) ||
          (response &&
            response.status === errorStatus &&
            'data' in response &&
            typeof response.data === 'string')
          // this string is a maintenance sails HTML but is not used.
        ) {
          // cookies.remove('token');
          userToken.removeUserToken();
          if (!window.location.href.includes('/maintenance')) {
            window.location.href = '/maintenance';
          }
        }
      }
      return Promise.reject(error);
    },
  );
}
