import * as t from './actionTypes';
import { Map } from 'immutable';
import _ from 'underscore';

const initialState = Map({
  selectedStudyPlan: null,
  studyPlans: [],
  studyPlansUsers: [],
  selectedStudyPlanUser: null,
  selectedTenWeekListTab: 'Study Plans',
  previewPlans: false,
  studyPlansUsersPage: 1,
  isLoadingUsers: false,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case t.SET_SELECTED_STUDY_PLAN:
      return state.set('selectedStudyPlan', action.studyPlan);
    case t.SET_STUDY_PLANS:
      return state.set('studyPlans', action.studyPlans);
    case t.ADD_STUDY_PLAN:
      let studyPlans = state.get('studyPlans');
      studyPlans.data = studyPlans.data.concat([action.studyPlan]);
      return state.set('studyPlans', studyPlans);
    case t.UPDATE_STUDY_PLAN:
      return state.set(
        'studyPlans',
        state.get('studyPlans').map(sc => {
          if (sc.id === action.studyPlan.id) {
            sc.title = action.studyPlan.title;
            sc.isActive = action.studyPlan.isActive;
          }
          return sc;
        })
      );
    case t.SET_SELECTED_STUDY_PLAN_USER:
      return state.set('selectedStudyPlanUser', action.studyPlanUser);
    case t.SET_LOADING_USERS:
      return state.set('isLoadingUsers', action.isLoading);
    case t.SET_STUDY_PLANS_USERS:
      let { studyPlansUsers, page } = action,
        users = _.union(state.get('studyPlansUsers'), studyPlansUsers);
      users = page === 0 ? studyPlansUsers : users;
      return state
        .set('studyPlansUsers', users)
        .set('studyPlansUsersPage', page + 1);
    case t.UPDATE_SELECTED_TEN_WEEK_LIST_TAB:
      return state.set('selectedTenWeekListTab', action.selectedTenWeekListTab);
    case t.SET_PREVIEW_PLANS:
      return state.set('previewPlans', action.previewPlans);
    default:
      return state;
  }
}
