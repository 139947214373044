import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './CodeGroupList.css';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import CodeGroup from './CodeGroup';
import { SearchWithIcon } from '../CouponList/CouponList';
import CreateCodeGroup from '../../../../commonComponents/Modals/CreateCodeGroup/CreateCodeGroup';
import CreateCoupon from '../../../../commonComponents/Modals/CreateCoupon/CreateCoupon';
import DropDown from '../../../../commonComponents/DropDown/DropDown';
import { pageSize } from '../../../../constants/pagination';

export default class CodeGroupList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      showExpanded: false,
      page: 1,
      analyticsCodeGroup: null,
    };
  }

  componentDidMount() {
    if (!this.props.rates || this.props.rates.length === 0) {
      this.props.loadRates();
    }
    this.props.setCodeGroupList(this.props.codeGroups);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedCoupon &&
      (!prevProps.selectedCoupon ||
        this.props.selectedCoupon.id !== prevProps.selectedCoupon.id)
    ) {
      this.props.loadCodeGroups(this.props.selectedCoupon.id);
    }
    if (prevState.search !== this.state.search) {
      let { codeGroups } = this.props;
      let codeGroupsFilter =
        this.state.search !== ''
          ? codeGroups.filter(c =>
              c.description
                .toLowerCase()
                .includes(this.state.search.toLowerCase()),
            )
          : codeGroups;
      this.props.setCodeGroupList(codeGroupsFilter);
    }
    if (
      prevProps.selectedCodeGroup !== null &&
      this.props.selectedCodeGroup === null
    ) {
      this.setState({ showExpanded: false });
      this.setState({ analyticsCodeGroup: false });
      this.setState({ page: 1 });
    }
  }

  onSearch = e => {
    this.setState({ search: e.target.value });
  };

  openCreateCodeGroup = () => {
    this.props.openModal({
      modalContent: (
        <CreateCodeGroup
          onSubmit={this.props.onCreateCodeGroup}
          onCancel={this.props.closeModal}
          coupon={this.props.selectedCoupon}
          title="Create coupon codes"
          rates={this.props.rates}
        />
      ),
    });
  };

  openEditModal = codeGroup => {
    this.props.openModal({
      modalContent: (
        <CreateCodeGroup
          onSubmit={this.props.onEditGroupCode}
          onCancel={this.props.closeModal}
          coupon={this.props.selectedCoupon}
          title="Edit coupon codes"
          rates={this.props.rates}
          codeGroup={codeGroup}
        />
      ),
    });
  };

  openEditCouponModal = coupon => {
    this.props.openModal({
      modalContent: (
        <CreateCoupon
          onSubmit={this.props.onEditCoupon}
          onCancel={this.props.closeModal}
          coupon={coupon}
          title="Create Coupon"
        />
      ),
    });
  };

  onChangePage = page => {
    let { onSelectCodeGroup, selectedCodeGroup } = this.props;
    onSelectCodeGroup(
      selectedCodeGroup.id,
      (page - 1) * pageSize,
      page * pageSize,
    );
    this.setState({ page });
  };

  getPreviousCodeGroup = () => {
    let {
      codeGroups,
      selectedCodeGroup,
      onSelectCodeGroup,
      onSelectSelectedCodeGroup,
    } = this.props;
    let codeGroupsFilter =
      this.state.search !== ''
        ? codeGroups.filter(c =>
            c.description
              .toLowerCase()
              .includes(this.state.search.toLowerCase()),
          )
        : codeGroups;
    const codeGroupIndex = codeGroupsFilter.findIndex(
      cg => cg.id === selectedCodeGroup.id,
    );
    const previousCodeGroup = codeGroupsFilter[codeGroupIndex - 1];
    onSelectSelectedCodeGroup(previousCodeGroup);
    onSelectCodeGroup(previousCodeGroup.id, 0, 20);
    this.setState({ page: 1 });
  };

  getNextCodeGroup = () => {
    let {
      codeGroups,
      selectedCodeGroup,
      onSelectCodeGroup,
      onSelectSelectedCodeGroup,
    } = this.props;
    let codeGroupsFilter =
      this.state.search !== ''
        ? codeGroups.filter(c =>
            c.description
              .toLowerCase()
              .includes(this.state.search.toLowerCase()),
          )
        : codeGroups;
    const codeGroupIndex = codeGroupsFilter.findIndex(
      cg => cg.id === selectedCodeGroup.id,
    );
    const nextCodeGroup = codeGroupsFilter[codeGroupIndex + 1];
    onSelectSelectedCodeGroup(nextCodeGroup);
    onSelectCodeGroup(nextCodeGroup.id, 0, 20);
    this.setState({ page: 1 });
  };

  clickAnalyticsBack = () => {
    this.props.cleanCodeGroup();
    this.setState({ analyticsCodeGroup: null });
    this.props.onSelectSelectedCodeGroup(null);
    this.setState({
      showExpanded: false,
      page: 1,
    });
  };

  getPreviousAnalytics = () => {
    let {
      selectedCodeGroup,
      codeGroups,
      onSelectSelectedCodeGroup,
    } = this.props;
    let codeGroupsFilter =
      this.state.search !== ''
        ? codeGroups.filter(c =>
            c.description
              .toLowerCase()
              .includes(this.state.search.toLowerCase()),
          )
        : codeGroups;
    const codeGroupIndex = codeGroupsFilter.findIndex(
      cg => cg.id === selectedCodeGroup.id,
    );
    const previousCodeGroup = codeGroupsFilter[codeGroupIndex - 1];
    onSelectSelectedCodeGroup(previousCodeGroup);
    this.setState({ analyticsCodeGroup: previousCodeGroup });
  };

  getNextAnalytics = () => {
    let {
      selectedCodeGroup,
      codeGroups,
      onSelectSelectedCodeGroup,
    } = this.props;
    let codeGroupsFilter =
      this.state.search !== ''
        ? codeGroups.filter(c =>
            c.description
              .toLowerCase()
              .includes(this.state.search.toLowerCase()),
          )
        : codeGroups;
    const codeGroupIndex = codeGroupsFilter.findIndex(
      cg => cg.id === selectedCodeGroup.id,
    );
    const nextCodeGroup = codeGroupsFilter[codeGroupIndex + 1];
    onSelectSelectedCodeGroup(nextCodeGroup);
    this.setState({ analyticsCodeGroup: nextCodeGroup });
  };

  render() {
    let {
      codeGroups,
      isLoadingCodeGroups,
      selectedCoupon,
      selectedCodeGroup,
      onSelectSelectedCodeGroup,
      canEdit,
    } = this.props;
    let codeGroupsFilter =
      this.state.search !== ''
        ? codeGroups.filter(c =>
            c.description
              .toLowerCase()
              .includes(this.state.search.toLowerCase()),
          )
        : codeGroups;
    return (
      <Col xs={6} md={9} className="CodeGroupList">
        <div style={{ height: '100%' }}>
          <Row className="CodeGroupListHeader">
            <Col md={3} style={{ display: 'flex', alignItems: 'center' }}>
              {this.state.analyticsCodeGroup ? (
                <div>
                  <a className="linkButton" onClick={this.clickAnalyticsBack}>
                    <i className="fa fa-arrow-left fa-lg" />
                    <span className="spanButton">Codes</span>
                  </a>
                  {codeGroupsFilter.findIndex(
                    cg => cg.id === this.state.analyticsCodeGroup.id,
                  ) !== 0 ? (
                    <div style={{ display: 'inline-block' }}>
                      <span className="spanButtonSeparator">|</span>
                      <a
                        className="linkButton"
                        onClick={this.getPreviousAnalytics}
                      >
                        <i className="fa fa-angle-left fa-lg" />
                        <span className="spanButton">Previous</span>
                      </a>
                    </div>
                  ) : null}
                  {codeGroupsFilter.findIndex(
                    cg => cg.id === this.state.analyticsCodeGroup.id,
                  ) !==
                  codeGroupsFilter.length - 1 ? (
                    <div style={{ display: 'inline-block' }}>
                      <span className="spanButtonSeparator">|</span>
                      <a className="linkButton" onClick={this.getNextAnalytics}>
                        <span className="spanButton">Next</span>
                        <i className="fa fa-angle-right fa-lg" />
                      </a>
                    </div>
                  ) : null}
                </div>
              ) : selectedCodeGroup ? (
                <div>
                  <a
                    className="linkButton"
                    onClick={() => {
                      onSelectSelectedCodeGroup(null);
                      this.setState({
                        showExpanded: false,
                        page: 1,
                      });
                    }}
                  >
                    <i className="fa fa-arrow-left fa-lg" />
                    <span className="spanButton">Codes</span>
                  </a>
                  {codeGroupsFilter.findIndex(
                    cg => cg.id === selectedCodeGroup.id,
                  ) !== 0 ? (
                    <div style={{ display: 'inline-block' }}>
                      <span className="spanButtonSeparator">|</span>
                      <a
                        className="linkButton"
                        onClick={this.getPreviousCodeGroup}
                      >
                        <i className="fa fa-angle-left fa-lg" />
                        <span className="spanButton">Previous</span>
                      </a>
                    </div>
                  ) : null}
                  {codeGroupsFilter.findIndex(
                    cg => cg.id === selectedCodeGroup.id,
                  ) !==
                  codeGroupsFilter.length - 1 ? (
                    <div style={{ display: 'inline-block' }}>
                      <span className="spanButtonSeparator">|</span>
                      <a className="linkButton" onClick={this.getNextCodeGroup}>
                        <span className="spanButton">Next</span>
                        <i className="fa fa-angle-right fa-lg" />
                      </a>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </Col>
            <Col md={4}>
              <SearchWithIcon
                search={this.state.search}
                onSearch={this.onSearch}
                onClearSearch={() => this.setState({ search: '' })}
              />
            </Col>
            <Col md={5}>
              {canEdit && (
                <DropDown
                  options={[
                    {
                      name: 'Edit',
                      event: () => this.openEditCouponModal(selectedCoupon),
                    },
                  ]}
                  title="Coupon"
                  style={{
                    position: 'absolute',
                    right: 0,
                  }}
                />
              )}
              {canEdit && (
                <div className="whiteButton" onClick={this.openCreateCodeGroup}>
                  <i className="fa fa-plus fa-lg mt-1" />
                  Create Coupon Codes
                </div>
              )}
            </Col>
          </Row>
          {isLoadingCodeGroups ? (
            <Spinner text="Loading code groups" />
          ) : selectedCodeGroup ? (
            <div className="scrollable">
              <CodeGroup
                item={selectedCodeGroup}
                codeGroups={codeGroups}
                showExpanded={this.state.showExpanded}
                onCancelAll={this.props.onCancelAll}
                onEdit={this.openEditModal}
                onSelectAnalytics={this.props.onSelectAnalytics}
                onSelectCodeGroup={selectedCodeGroup => {
                  onSelectSelectedCodeGroup(selectedCodeGroup);
                  this.setState({ showExpanded: true });
                }}
                getCodeGroup={this.props.onSelectCodeGroup}
                currentCodeGroup={this.props.codeGroup}
                isGettingCodeGroup={this.props.isGettingCodeGroup}
                page={this.state.page}
                onChangePage={this.onChangePage}
                onCancelCode={this.props.onCancelCode}
                loadRedeems={this.props.loadRedeems}
                setAnalyticsCodeGroup={analyticsCodeGroup =>
                  this.setState({ analyticsCodeGroup })
                }
                analyticsCodeGroup={this.state.analyticsCodeGroup}
                onClearUnredeemed={this.props.onClearUnredeemed}
                unredeemedCodes={this.props.unredeemedCodes}
                downloadUnredeemed={this.props.downloadUnredeemed}
                canEdit={canEdit}
              />
            </div>
          ) : codeGroupsFilter.length ? (
            <div className="scrollable">
              {codeGroupsFilter.map(cg => (
                <CodeGroup
                  key={cg.id}
                  item={cg}
                  codeGroups={codeGroups}
                  showExpanded={this.state.showExpanded}
                  onCancelAll={this.props.onCancelAll}
                  onEdit={this.openEditModal}
                  onSelectAnalytics={this.props.onSelectAnalytics}
                  onSelectCodeGroup={selectedCodeGroup => {
                    onSelectSelectedCodeGroup(selectedCodeGroup);
                    this.setState({ showExpanded: true });
                  }}
                  getCodeGroup={this.props.onSelectCodeGroup}
                  currentCodeGroup={this.props.codeGroup}
                  isGettingCodeGroup={this.props.isGettingCodeGroup}
                  page={this.state.page}
                  onChangePage={this.onChangePage}
                  onCancelCode={this.props.onCancelCode}
                  loadRedeems={this.props.loadRedeems}
                  setAnalyticsCodeGroup={analyticsCodeGroup =>
                    this.setState({ analyticsCodeGroup })
                  }
                  analyticsCodeGroup={this.state.analyticsCodeGroup}
                  onClearUnredeemed={this.props.onClearUnredeemed}
                  unredeemedCodes={this.props.unredeemedCodes}
                  downloadUnredeemed={this.props.downloadUnredeemed}
                  canEdit={canEdit}
                />
              ))}
            </div>
          ) : (
            <div className="centered">This coupon has no code groups</div>
          )}
        </div>
      </Col>
    );
  }
}
