import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'bibliographies');
}

export const getCheckedSubtopics = (state) =>
  getState(state).get('checkedSubtopics');

export const getCheckedReferences = (state) =>
  getState(state).get('checkedReferences');

export const getDutyState = (state) => getState(state).get('dutyState');

export const getBibliographyLogs = (state) =>
  getState(state).get('bibliographyLogs');

export const getRank = (state) => getState(state).get('rank');

export const getDutyStates = (state) => getState(state).get('dutyStates');

export const getRanks = (state) => getState(state).get('ranks');

export const getCycle = (state) => getState(state).get('cycle');

export const getIsUndoChecked = (state) => getState(state).get('isUndoChecked');

export const getIsRevertChecked = (state) =>
  getState(state).get('isRevertChecked');

export const getUndoState = (state) => getState(state).get('undoState');

export const getActualState = (state) => getState(state).get('actualState');

export const getReferences = (state) =>
  getState(state).get('references').toArray();

export const getReferencesImmutable = (state) =>
  getState(state).get('references');

export const getChapters = (state) => getState(state).get('chapters').toArray();

export const getChaptersImmutable = (state) => getState(state).get('chapters');

export const loadingExam = (state) => getState(state).get('loadingExam');

export const loadingGrid = (state) => getState(state).get('loadingGrid');

export const loadingReferences = (state) =>
  getState(state).get('loadingReferences');

export const getLoadingInfiniteScroll = (state) =>
  getState(state).get('loadingInfiniteScroll');

export const getActiveTab = (state) => getState(state).get('selectedTab');
export const getActiveListTab = (state) =>
  getState(state).get('selectedListTab');

export const getOriginalCheckedSubtopics = (state) =>
  getState(state).get('originalCheckedSubtopics');
export const getOriginalCheckedReferences = (state) =>
  getState(state).get('originalCheckedReferences');

export function getPage(state) {
  return getState(state).get('page');
}
export function getHasMoreData(state) {
  return getState(state).get('hasMoreData');
}

export const getRatingExamQuestions = (state) =>
  getState(state).get('ratingExamQuestions');

export const getBibliographyJson = (state) =>
  getState(state).get('bibliographyJson');

export const getRateChapterQuestionsReport = (state) =>
  getState(state).get('reportJson');

export const getRerferenceDuplicateChapter = (state) =>
  getState(state).get('referencesDuplicateChapter');

export const getReferenceIdSelect = (state) =>
  getState(state).get('referenceIdSelect');

export const getLoadAddChapter = (state) =>
  getState(state).get('loadAddChapter');

export const getRateIdSelect = (state) => getState(state).get('rateIdSelect');
