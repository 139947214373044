import { connect } from 'react-redux';
import AssociateRatesToReference from '../../../commonComponents/Modals/AssociateRatesToReference/AssociateRatesToReference';
import { loadAllStudyPrograms } from '../actionsSagas';
import { getStudyPrograms, getWaiting } from '../selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    onCancel: ownProps.onCancel,
    onSubmit: ownProps.onSubmit,
    rates: getStudyPrograms(state),
    reference: ownProps.reference,
    waiting: getWaiting(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoad: () => {
      dispatch(loadAllStudyPrograms());
    },
  };
};

const AssociateRatesToReferenceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociateRatesToReference);

export default AssociateRatesToReferenceContainer;
