import React from 'react';
import './QuestionGptModal.css';
import { Row, Col } from 'react-bootstrap';
import DropFileInput from '../../../../commonComponents/DropFileInput/DropFileInput';
import {
  SimpleInput,
  SimpleTextarea,
} from '../../../../commonComponents/Input/Input';
import Button from 'react-bootstrap-button-loader';
const MAX_SUMMARY_WORDS = 10000;
export default class QuestionGptModal extends React.Component {
  constructor(props) {
    super(props);

    let originalPdfUrl = '';
    if (this.props?.assignment?.chapter?.pdfUrl) {
      originalPdfUrl = this.props.assignment.chapter.pdfUrl;
    }
    this.state = {
      useOriginalPdf: !!originalPdfUrl,
      originalPdfUrl: originalPdfUrl,
      newPdfFile: null, //file object only
      newPdfUrl: '',
      errors: null,
      questionPerPage: 'default',
      typeQuestions: 'multi',
      questionPerPageOptions: ['Default', 'Low', 'High'],
      typeQuestionsOptions: ['Multiple-choice', 'Flashcards'],
      customPrompt: '',
      wating: false,
      length: null,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.urlPdfPrivate !== this.props.urlPdfPrivate) {
      window.open(this.props.urlPdfPrivate, '_blank');
    }
  };

  handleKeyPress = (event) => {
    if (event.ctrlKey && event.key === 'Enter') {
      document.getElementById('btn-success').click();
    }
  };

  validate = () => {
    let errors = {};
    let fields = Object.assign({}, this.state);

    delete fields.errors;
    delete fields.questionPerPageOptions;
    delete fields.typeQuestionsOptions;
    delete fields.wating;
    if (this.props.createQuestion) {
      delete fields.length;
    } else {
      delete fields.questionPerPage;
      delete fields.typeQuestions;
      delete fields.questionPerPageOptions;
    }

    if (
      !fields.useOriginalPdf &&
      !fields.newPdfFile &&
      fields.newPdfUrl === ''
    ) {
      errors.name = 'Pdf or url field is required';
    }

    if (fields.newPdfFile && fields.newPdfFile.length > 0) {
      fields.newPdfFile = fields.newPdfFile[0];
    }
    if (
      fields.newPdfFile &&
      fields.newPdfFile.name &&
      fields.newPdfFile.name.toLowerCase().substr(-3) !== 'pdf'
    ) {
      errors.pdf = 'Please select a pdf file';
    }

    if (!this.props.createQuestion) {
      if (!fields.length || fields.length === 0) {
        errors.length = 'Length field is required';
      }
      if (fields.length > MAX_SUMMARY_WORDS || fields.length < 20) {
        errors.length = `The length field must be between 20 and ${MAX_SUMMARY_WORDS}`;
      }
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    fields.url = fields.useOriginalPdf
      ? fields.originalPdfUrl
      : fields.newPdfUrl;
    fields.newPdfFile = fields.useOriginalPdf ? null : fields.newPdfFile;

    this.setState({ errors: null, wating: true });
    if (this.props.createQuestion) {
      this.props.onSubmitQuestions(fields, this.props.assignment);
    } else {
      this.props.onSubmitSummary(
        fields,
        this.props.assignment,
        this.props.option,
      );
    }
  };

  renderCheckbox(option, questionsType) {
    let typeQuestion =
      option === 'Multiple-choice'
        ? 'multi'
        : option === 'Flashcards'
        ? 'flash'
        : null;
    const title =
      option === 'Default'
        ? '5 questions page per page'
        : option === 'Low'
        ? '3 questions page per page'
        : option === 'High'
        ? '10 questions page per page'
        : '';
    return (
      <div className="checkBoxContainer">
        <input
          type="checkbox"
          onChange={(e) => {
            if (!questionsType) {
              option === 'Default'
                ? this.setState({ questionPerPage: 'default' })
                : option === 'Low'
                ? this.setState({ questionPerPage: 'low' })
                : this.setState({ questionPerPage: 'high' });
            } else {
              option === 'Multiple-choice'
                ? this.setState({ typeQuestions: 'multi' })
                : this.setState({ typeQuestions: 'flash' });
            }
          }}
          id={option}
          checked={
            questionsType
              ? typeQuestion === this.state.typeQuestions
              : option.toLowerCase() === this.state.questionPerPage
          }
          title={title}
        />
        <label htmlFor={option} className="lebelCheck" title={title}>
          {option}
        </label>
      </div>
    );
  }

  render() {
    return (
      <div className="modal-question-container">
        <div className="card-block">
          <div className="title-container">
            {this.props.title}
            <i
              className="fa fa-times fa-xs"
              onClick={() => this.props.onCancel()}
            />
            <hr className="hrModal" />
          </div>
          <div className="widget-form new-admin-form">
            <Row>
              {!this.state.newPdfUrl && (
                <>
                  <Col md={4}>
                    <span className="labelForm">Upload a Pdf</span>
                  </Col>
                  <Col md={8}>
                    <DropFileInput
                      input={{
                        onChange: (e) => {
                          // e contains the File object
                          if (e !== this.state.originalPdfUrl) {
                            this.setState({
                              useOriginalPdf: false,
                              newPdfFile: e,
                              newPdfUrl: '',
                            });
                          }
                        },
                      }}
                      value={this.state.originalPdfUrl}
                      defaultValue={this.state.originalPdfUrl}
                      multiple={false}
                      accept={{ 'application/pdf': ['.pdf'] }}
                      getUrlPdf={this.props.getUrlPdf}
                    />
                  </Col>
                </>
              )}
            </Row>

            {!this.state.useOriginalPdf && !this.state.newPdfFile && (
              <Row>
                <Col md={4}>
                  <span className="labelForm">Or insert the URL</span>
                </Col>
                <Col md={8}>
                  <SimpleInput
                    type="text"
                    value={this.state.newPdfUrl}
                    onChange={(e) => {
                      this.setState({
                        newPdfUrl: e.target.value,
                        newPdfFile: null,
                      });
                    }}
                  />
                </Col>
              </Row>
            )}
            <hr className="hrModal" />
            {this.props.createQuestion ? (
              <>
                <Row>
                  <Col md={4}>
                    <span className="labelForm">
                      Choose the type of questions
                    </span>
                  </Col>
                  <Col md={8}>
                    <div className="checkBoxContainer mt-2">
                      {this.state.typeQuestionsOptions.map((option, index) => {
                        return (
                          <div key={index}>
                            {this.renderCheckbox(option, true)}
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <span className="labelForm">Questions per page?</span>
                  </Col>
                  <Col md={8}>
                    <div className="checkBoxContainer mt-2">
                      {this.state.questionPerPageOptions.map(
                        (option, index) => {
                          return (
                            <div key={index}>
                              {this.renderCheckbox(option, false)}
                            </div>
                          );
                        },
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col md={4}>
                    <span className="labelForm">Length in words numbers</span>
                  </Col>
                  <Col md={8}>
                    <SimpleInput
                      numberType="number"
                      value={this.state.length}
                      onChange={(e) => {
                        this.setState({ length: e.target.value });
                      }}
                      errorValue={this.state.errors && this.state.errors.length}
                      placeholder="Add the summary length"
                      min={20}
                      max={MAX_SUMMARY_WORDS}
                    />
                  </Col>
                </Row>
              </>
            )}

            <Row>
              <Col md={4} className="alignItems">
                <span className="labelForm mb-0">Custom Prompt</span>
              </Col>
              <Col md={8} className="mt-2">
                <div>
                  <SimpleTextarea
                    type="text"
                    value={this.state.customPrompt}
                    onChange={(e) => {
                      this.setState({ customPrompt: e.target.value });
                    }}
                    style={{ height: '6rem' }}
                    error={
                      this.state.errors && this.state.errors.customPrompt
                        ? this.state.errors.customPrompt
                        : null
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
          <hr />
          {this.state.errors && this.state.errors.name && (
            <span style={{ color: 'red' }}>*{this.state.errors.name}</span>
          )}
          {this.state.errors && this.state.errors.pdf && (
            <span style={{ color: 'red' }}>*{this.state.errors.pdf}</span>
          )}
          {!this.props.createQuestion && (
            <>
              {this.state.errors && this.state.errors.length && (
                <span style={{ color: 'red' }}>
                  *{this.state.errors.length}
                </span>
              )}
            </>
          )}
          <div className="bottom-buttons-new">
            <Button
              id="btn-success"
              className="btn btn-success"
              onClick={() => this.validate()}
              loading={this.state.wating}
            >
              Create
            </Button>
            <Button
              className="btn btn-secondary"
              onClick={() => this.props.onCancel()}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
