import React from 'react';
import PropTypes from 'prop-types';
import NumberUtils from '../../../../lib/NumberUtils';

export default class RateLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: this.props.expanded,
    };
  }

  componentWillReceiveProps({ expanded }) {
    if (this.props.expanded !== expanded) {
      this.setState({ expanded });
    }
  }

  getRowName(adminsFilter, admin) {
    let fullName = admin.name + ' ' + admin.lastName;
    switch (adminsFilter) {
      case 'All':
        if (
          admin.counts.inProgress !== 0 ||
          admin.counts.submitted !== 0 ||
          admin.counts.notStarted !== 0
        ) {
          return (
            <div className="width100">
              {fullName}
              <span className="adminCountNumber">
                {Number(
                  admin.counts.inProgress +
                    admin.counts.submitted +
                    admin.counts.notStarted,
                )}
              </span>
            </div>
          );
        }
        break;
      default:
        if (admin.counts.submitted !== 0) {
          return (
            <div className="width100">
              {fullName}
              <span className="adminCountNumber">{admin.counts.submitted}</span>
            </div>
          );
        }
    }

    return fullName;
  }

  getClassName(selectedAdminId, admin, index) {
    return index === 0
      ? selectedAdminId === admin.id
        ? 'SelectableName isSameAdmin firstRate'
        : 'SelectableName firstRate'
      : selectedAdminId === admin.id
      ? 'SelectableName isSameAdmin'
      : 'SelectableName';
  }

  renderExpandIcon() {
    return (
      <div className="expandIconContainer">
        <i
          onClick={() => this.setState({ expanded: !this.state.expanded })}
          className={
            this.state.expanded
              ? 'fa fa-caret-down marginRight14'
              : 'fa fa-caret-right marginRight18'
          }
          aria-hidden="true"
        />
      </div>
    );
  }

  render() {
    const { adminsFilter } = this.props;
    const { expanded } = this.state;

    let rate = this.props.rate;
    let selectedAdminId = this.props.selectedAdmin
      ? this.props.selectedAdmin.id
      : null;
    let admins = rate.adminUsers;
    if (adminsFilter === 'Submitted')
      admins = admins.filter(admin => admin.counts.submitted > 0);
    return (
      <div className="Group">
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => this.setState({ expanded: !this.state.expanded })}
        >
          {this.renderExpandIcon()}
          <div className="RateName">
            {rate.shortname
              ? '(' + rate.shortname + ') ' + rate.name
              : rate.name}
          </div>
        </div>
        {expanded &&
          admins.map((admin, index) => (
            <div
              id={`${rate.id}-${admin.id}`}
              className={
                this.props.isSelectedRate && selectedAdminId === admin.id
                  ? index === 0
                    ? 'SelectableName active firstRate'
                    : 'SelectableName active'
                  : this.getClassName(selectedAdminId, admin, index)
              }
              onClick={() => {
                if (
                  selectedAdminId !== admin.id ||
                  this.props.selectedRate.id !== rate.id
                ) {
                  this.props.setAdminScroll(
                    window
                      .jQuery('.SelectableName')
                      .parent()
                      .parent()
                      .scrollTop(),
                  );
                  this.props.updateLoading(true);
                  this.props.onAdminClick(admin, rate);
                  if (this.props.activeStatus === 'awaitingApproval') {
                    this.props.toggleActive(true);
                  }
                }
              }}
              key={NumberUtils.obtainUniqueKey()}
            >
              {this.getRowName(adminsFilter, admin)}
            </div>
          ))}
      </div>
    );
  }
}

RateLine.propTypes = {
  rate: PropTypes.object,
  onAdminClick: PropTypes.func,
};
