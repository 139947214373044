import { Map } from 'immutable';

const initialState = Map({
  generalRolesPermissions: [],
  rolesPermissions: [],
});

const reducer = function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
