import React from 'react';
import WheelhouseIcon from '../../../commonComponents/Images/dashboard-nav-icon.png';
import RatesIcon from '../../../commonComponents/Images/rates-icon.png';
import QualsIcon from '../../../commonComponents/Images/quals.png';
import PeopleIcon from '../../../commonComponents/Images/people.png';
import ConnectIcon from '../../../commonComponents/Images/connect.png';
import ConnectIconWhite from '../../../commonComponents/Images/connect-white.png';
import PeopleIconWhite from '../../../commonComponents/Images/people-white.png';
import QualsIconWhite from '../../../commonComponents/Images/quals-white.png';
import RatesIconWhite from '../../../commonComponents/Images/rates-white.png';
import WheelhouseIconWhite from '../../../commonComponents/Images/dashboard-white.png';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import './Sidebar.css';
import SidebarLink from './SidebarLink';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hideWheelhouse: false,
      hideRates: false,
      hideQuals: false,
      hidePeople: false,
      hideConnect: false,
      hideSettings: true,
    };
  }

  componentWillMount() {
    this.updateSidebar(
      this.props.rolesPermissions,
      this.props.generalRolesPermissions
    );
  }

  componentWillReceiveProps({ rolesPermissions, generalRolesPermissions }) {
    if (
      this.props.rolesPermission !== rolesPermissions ||
      this.props.generalRolesPermissions !== generalRolesPermissions
    ) {
      this.updateSidebar(rolesPermissions, generalRolesPermissions);
    }
  }

  updateSidebar(rolesPermissions, generalRolesPermissions) {
    if (cookies.get('isSuperAdmin') !== 'true') {
      let wheelhouseActions = rolesPermissions.filter(rolePermission =>
        rolePermission.actions.find(action => action.startsWith('Assignment'))
      );
      wheelhouseActions = wheelhouseActions && wheelhouseActions.length > 0;
      let wheelhouse = generalRolesPermissions.find(permission =>
        permission.startsWith('Wheelhouse')
      );
      if (!wheelhouse && !wheelhouseActions) {
        this.setState({ hideWheelhouse: true });
      } else {
        if (this.state.hideWheelhouse) {
          this.setState({ hideWheelhouse: false });
        }
      }
      let people = generalRolesPermissions.find(permission =>
        permission.startsWith('People')
      );
      if (!people) {
        this.setState({ hidePeople: true });
      } else {
        if (this.state.hidePeople) {
          this.setState({ hidePeople: false });
        }
      }
      let connect = generalRolesPermissions.find(
        permission => permission === 'Connect'
      );
      if (!connect) {
        this.setState({ hideConnect: true });
      } else {
        if (this.state.hideConnect) {
          this.setState({ hideConnect: false });
        }
      }

      let qualsActions =
        rolesPermissions.filter(qual => qual.type === 'qual').length > 0;
      let quals = generalRolesPermissions.find(
        permission => permission === 'Quals'
      );
      if (!quals && !qualsActions) {
        this.setState({ hideQuals: true });
      } else {
        this.setState({ hideQuals: false });
      }

      let ratesActions =
        rolesPermissions.filter(
          role =>
            role.type === 'rate' &&
            !role.actions.find(action => action === 'Assignment.Write')
        ).length > 0;
      let rates = generalRolesPermissions.find(permission =>
        permission.startsWith('Rates')
      );
      if (!rates && !ratesActions) {
        this.setState({ hideRates: true });
      } else {
        this.setState({ hideRates: false });
      }
    }

    if (this.props.user.id && this.props.user.isSuperAdmin) {
      this.setState({ hideSettings: false });
    }
  }

  isPageActive(page) {
    return this.props.location.pathname.indexOf(`/home/${page}`) > -1;
  }

  render() {
    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <ul className="nav">
            <SidebarLink
              to="/home/wheelhouse"
              name="Wheelhouse"
              activeIcon={WheelhouseIconWhite}
              inactiveIcon={WheelhouseIcon}
              isActive={this.isPageActive('wheelhouse')}
              hidden={this.state.hideWheelhouse}
            />
            <SidebarLink
              to="/home/rates/topics"
              name="Topics"
              activeIcon={RatesIconWhite}
              inactiveIcon={RatesIcon}
              isActive={this.isPageActive('rates')}
              hidden={this.state.hideRates}
            />
            <SidebarLink
              to="/home/quals"
              name="Quals"
              activeIcon={QualsIconWhite}
              inactiveIcon={QualsIcon}
              isActive={this.isPageActive('quals')}
              hidden={this.state.hideQuals}
            />
            <SidebarLink
              to="/home/people"
              name="People"
              activeIcon={PeopleIconWhite}
              inactiveIcon={PeopleIcon}
              isActive={this.isPageActive('people')}
              hidden={this.state.hidePeople}
            />
            <SidebarLink
              to="/home/connect"
              name="Connect"
              activeIcon={ConnectIconWhite}
              inactiveIcon={ConnectIcon}
              isActive={this.isPageActive('connect')}
              hidden={this.state.hideConnect}
            />
            <SidebarLink
              to="/home/settings"
              name="Settings"
              noImg
              isActive={this.isPageActive('settings')}
              hidden={this.state.hideSettings}
            />
          </ul>
        </nav>
      </div>
    );
  }
}

export default Sidebar;
