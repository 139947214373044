import './UpdateQuestionIndexModal.styles.css';

import { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

function UpdateQuestionIndexModal({
  question,
  handleChangeQuestionIndex,
  isQuestionIndexModalOpen,
  handleToggleQuestionIndexModal,
  amountOfQuestions,
}) {
  const [inputErrorMessage, setInputErrorMessage] = useState('');
  const indexInputRef = useRef();

  const validateIndex = (newIndex) => {
    const newIndexNumber = Number(newIndex);

    if (newIndexNumber === question.presentationIndex) {
      return 'The new index is the same as the current index.';
    }
    if (newIndexNumber > amountOfQuestions) {
      return `The new index is higher than the amount of questions (${amountOfQuestions}).`;
    }
    if (newIndexNumber < 1) {
      return 'The index cannot be lower than 1.';
    }
    return null;
  };

  const handleSubmit = () => {
    const newIndex = indexInputRef.current.value;
    const inputError = validateIndex(newIndex);
    if (inputError) {
      setInputErrorMessage(inputError);
    } else {
      setInputErrorMessage('');
      handleChangeQuestionIndex(question, newIndex);
      handleToggleQuestionIndexModal();
    }
  };

  const questionTextWithoutHTMLTags = question.text.replace(/<[^>]*>?/gm, '');

  return (
    <Modal
      show={isQuestionIndexModalOpen}
      onHide={handleToggleQuestionIndexModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Change question index</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="d-flex flex-column">
          <p>
            Insert the new index for the question &quot;
            {questionTextWithoutHTMLTags}
            &quot;. Its current index is {question.presentationIndex}:
          </p>
          <input
            className={inputErrorMessage ? 'input is-invalid' : 'input'}
            ref={indexInputRef}
            type="number"
            min={1}
            max={amountOfQuestions}
          />
          {inputErrorMessage ? (
            <span className="invalid-feedback">{inputErrorMessage}</span>
          ) : null}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          Update index
        </Button>{' '}
        <Button variant="secondary" onClick={handleToggleQuestionIndexModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateQuestionIndexModal;
