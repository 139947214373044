import React from 'react';
import Button from 'react-bootstrap-button-loader';

class TopicItemEditMode extends React.Component {
  componentDidMount() {
    this.textInput && this.textInput.focus();
  }

  render() {
    const { item, onToggleEditMode, onUpdate, onUpdateEditedName } = this.props;

    return (
      <span style={{ display: 'flex' }}>
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            onUpdate(item.editedName);
          }}
          style={{
            flex: 1,
            display: 'flex',
            height: '20px',
            alignItems: 'center',
          }}
        >
          <input
            type="text"
            ref={(input) => item.focus && (this.textInput = input)}
            value={item.editedName}
            style={{ flex: 1 }}
            onChange={(ev) => onUpdateEditedName(ev.target.value)}
            onKeyDown={(ev) => ev.keyCode === 27 && onToggleEditMode()}
          />
          <input type="submit" style={{ display: 'none' }} />
          <button
            className="btn btn-sm btn-secondary"
            style={{ margin: '0 10px' }}
            onClick={(ev) => {
              ev.stopPropagation();
              onToggleEditMode();
            }}
          >
            Cancel
          </button>
          <Button
            type="submit"
            className="btn-sm"
            variant="success"
            loading={item.loading || false}
            onClick={(ev) => {
              ev.stopPropagation();
              onUpdate(item.editedName);
            }}
          >
            Save
          </Button>
        </form>
      </span>
    );
  }
}

function TopicItemAddMode({ item, onAdd, onUpdate, waiting, level, id }) {
  return (
    <span style={{ display: 'flex' }}>
      <form
        onSubmit={(ev) => {
          ev.preventDefault();
          onAdd(item.name);
        }}
        style={{ flex: 1, display: 'flex' }}
      >
        <input
          type="text"
          id={id}
          style={{ flex: 1 }}
          placeholder={level === 2 ? 'Enter new subtopic' : 'Enter new topic'}
          value={item.name}
          onChange={(ev) => onUpdate(ev.target.value)}
        />
        <input type="submit" style={{ display: 'none' }} />
        <Button
          type="submit"
          className="btn-sm addBtn"
          variant="success"
          loading={item.loading && waiting}
        >
          {level === 2 ? 'Add subtopic' : 'Add topic'}
        </Button>
      </form>
    </span>
  );
}

function TopicItemNormalMode({
  item,
  onToggleEditMode,
  onDelete,
  waiting,
  chaptersUpdatedThisTopic,
  redoChaptersUpdatedThisTopic,
  updateSubtopicChapters,
  onUndoChaptersUpdatedThisTopic,
  onRedoChaptersUpdatedThisTopic,
}) {
  return (
    <span className="sub-tree-item">
      <span className="itemName">{item.name}</span>
      <span className="tree-options">
        {chaptersUpdatedThisTopic && chaptersUpdatedThisTopic.length > 0 && (
          <span className="studyProgramsContainer">
            <span className="undo-sp">
              {chaptersUpdatedThisTopic.length.toString()}
            </span>
            <i
              className="fa fa-backward icon-button"
              title={`Undo to ${
                chaptersUpdatedThisTopic[chaptersUpdatedThisTopic.length - 1]
                  .subtopic.chapters.length
              } chapters`}
              onClick={(ev) => {
                ev.stopPropagation();
                updateSubtopicChapters(
                  chaptersUpdatedThisTopic[chaptersUpdatedThisTopic.length - 1]
                    .subtopic,
                  chaptersUpdatedThisTopic[
                    chaptersUpdatedThisTopic.length - 1
                  ].subtopic.chapters.map((c) => c.id),
                );
                onUndoChaptersUpdatedThisTopic();
              }}
            />
          </span>
        )}
        {redoChaptersUpdatedThisTopic &&
          redoChaptersUpdatedThisTopic.length > 0 && (
            <span className="studyProgramsContainer">
              <span className="redo-sp">
                {redoChaptersUpdatedThisTopic.length.toString()}
              </span>
              <i
                className="fa fa-forward icon-button"
                title={`Redo to ${
                  redoChaptersUpdatedThisTopic[
                    redoChaptersUpdatedThisTopic.length - 1
                  ].chapters.length
                } chapters`}
                onClick={(ev) => {
                  ev.stopPropagation();
                  updateSubtopicChapters(
                    redoChaptersUpdatedThisTopic[
                      redoChaptersUpdatedThisTopic.length - 1
                    ].subtopic,
                    redoChaptersUpdatedThisTopic[
                      redoChaptersUpdatedThisTopic.length - 1
                    ].chapters.map((c) => c.id),
                  );
                  onRedoChaptersUpdatedThisTopic();
                }}
              />
            </span>
          )}
        {item.subtopics && (
          <span className="subtopics-qty">
            {`${item.subtopics.length} `}Subtopics
          </span>
        )}
        {item.chapters && (
          <span className="subtopics-qty">
            {`${item.chapters.length} `}Chapters
          </span>
        )}
        <i
          className="fa fa-pencil icon-button"
          onClick={(ev) => {
            ev.stopPropagation();
            onToggleEditMode();
          }}
        />

        {item.loading && waiting ? (
          <Button
            variant="link"
            loading
            spinColor="#2983d7"
            style={{ padding: '0 5px' }}
          />
        ) : (
          <i
            className="fa fa-trash icon-button"
            onClick={(ev) => {
              ev.stopPropagation();
              onDelete();
            }}
          />
        )}
      </span>
    </span>
  );
}

class TopicItem extends React.Component {
  render() {
    const { item, level, onClick } = this.props;
    let className =
      level === 0
        ? `list-group-item tree-level-${level}`
        : item.mode === 'add'
        ? `list-group-item tree-level-${level} addSubtopicBox`
        : `list-group-item tree-level-${level}`;
    className += onClick ? ` pointer ` : '';
    const mode = item.mode || 'normal';
    return (
      <span
        className={className}
        onClick={item.mode !== 'edit' ? onClick : () => {}}
      >
        {mode === 'normal' && <TopicItemNormalMode {...this.props} />}
        {mode === 'edit' && <TopicItemEditMode {...this.props} />}
        {mode === 'add' && <TopicItemAddMode {...this.props} />}
      </span>
    );
  }
}

export default TopicItem;
