import * as t from './actionTypesSagas';

export const loadSettings = () => ({
  type: t.LOAD_SETTINGS,
});

export const updateKey = key => ({
  type: t.UPDATE_KEY,
  key,
});

export const updateReferralDates = dates => ({
  type: t.UPDATE_REFERRAL_DATES,
  dates,
});
