export const LOGIN_REQUESTING = 'LOGIN_REQUESTING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_EXISTING = 'LOGIN_EXISTING';
export const AUTHY_REQUESTING = 'AUTHY_REQUESTING';
export const LOGOUT = 'LOGOUT';
export const NAME = 'login';
export const LEVEL_ONE = 'LoginComponentReducer';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOGIN_AS = 'SET_LOGIN_AS';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const LOGOUT_USER_SAGAS = 'LOGOUT_USER_SAGAS';
export const AUTHY_RETRY = 'AUTHY_RETRY';
export const AUTHY_VERIFICATION_REQUEST = 'AUTHY_VERIFICATION_REQUEST';
export const AUTHY_WAITING = 'AUTHY_WAITING';
export const AUTHY_ERROR = 'AUTHY_ERROR';
export const RESEND_CODE_REQUEST = 'RESEND_CODE_REQUEST';
export const RESEND_CODE_DONE = 'RESEND_CODE_DONE';
export const RESET_STATUS = 'RESET_STATUS';
export const LOGIN_DEFAULT = 'LOGIN_DEFAULT';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_VALID_PASSWORD = 'UPDATE_USER_VALID_PASSWORD';
export const UPDATE_USER_EXPIRED_PASSWORD = 'UPDATE_USER_EXPIRED_PASSWORD';
