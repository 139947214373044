import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from 'react-bootstrap-button-loader';
import { connect } from 'react-redux';
import {
  getFormErrors,
  getIsWaiting,
} from '../../../components/Admins/selectors';
import { clearErrorFromService } from '../../../components/Admins/actions';
import './NewAdmin.css';
import Input from '../../Input/Input';

class NewAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
    };
  }

  componentWillUnmount() {
    this.props.clearErrorMessage();
  }

  validate(fields, callback) {
    let errors = {};

    // fields.name
    // fields.lastName
    // fields.email
    // fields.password
    // fields.passwordConfirmation

    if (!fields.name) {
      errors.name = 'Name field is required';
    }

    if (!fields.lastName) {
      errors.lastName = 'Last name field is required';
    }

    if (!fields.email) {
      errors.email = 'Email field is required';
    }

    if (!fields.password) {
      errors.password = 'Password field is required';
    }

    if (!fields.confirmPassword) {
      errors.confirmPassword = 'Password field is required';
    } else if (fields.password !== fields.confirmPassword) {
      errors.confirmPassword = 'Both passwords must match';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: null });
    callback(fields);
  }

  renderInput(name, placeholder, type = 'text', icon) {
    return (
      <div className="form-group">
        <span className="label">{placeholder}:</span>
        <Field
          name={name}
          type={type}
          component={Input}
          icon={icon}
          placeholder={placeholder}
        />
        {this.state.errors &&
          this.state.errors[name] &&
          <span className="errors">{this.state.errors[name]}</span>}
      </div>
    );
  }

  render() {
    const { handleSubmit, onSubmit, onCancel, formErrors } = this.props;
    let showError =
      (this.state.errors && this.state.errors.general) || formErrors;
    let errorMessage = this.state.errors && this.state.errors.general
      ? this.state.errors.general
      : formErrors;

    return (
      <div className="new-admin-component">
        <div className="card-block">
          <form
            className="widget-form new-admin-form"
            onSubmit={handleSubmit(fields => this.validate(fields, onSubmit))}
          >
            {this.renderInput('name', 'Name', 'text', 'user')}
            {this.renderInput('lastName', 'Last Name', 'text', 'user')}
            {this.renderInput('email', 'Email', 'email', 'at')}
            {this.renderInput('password', 'Password', 'password', 'lock')}
            {this.renderInput(
              'confirmPassword',
              'Password Confirmation',
              'password',
              'lock'
            )}

            {showError && <div className="errors">{errorMessage}</div>}

            <div className="bottom-buttons">
              <Button className="btn btn-secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                className="btn btn-success"
                type="submit"
                loading={this.props.isWaiting}
              >
                Create
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    formErrors: getFormErrors(state),
    isWaiting: getIsWaiting(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearErrorMessage: () => {
      dispatch(clearErrorFromService());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({ form: 'NewAdmin' })(NewAdmin)
);
