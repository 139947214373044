import * as t from './actionsTypes';

export const getAllRates = () => ({
  type: t.GET_ALL_RATES,
});

export const setAllRates = rates => ({
  type: t.SET_ALL_RATES,
  rates,
});

export const setRate = rate => ({
  type: t.SET_RATE,
  rate,
});

export const createNotification = notification => ({
  type: t.CREATE_NOTIFICATION,
  notification,
});

export const loadNotifications = value => ({
  type: t.LOAD_NOTIFICATIONS,
  value,
});

export const getNotificationByRate = rateId => ({
  type: t.GET_NOTIFICATIONS_BY_RATE,
  rateId,
});

export const setNotificationByRate = notifications => ({
  type: t.SET_NOTIFICATIONS_BY_RATE,
  notifications,
});

export const deleteNotification = notificationId => ({
  type: t.DELETE_NOTIFICATION,
  notificationId,
});

export const editNotification = (notificationId, notificationValue) => ({
  type: t.EDIT_NOTIFICATION,
  notificationId,
  notificationValue,
});
