import { connect } from 'react-redux';

import {
  getRateChapterQuestionsReport,
  loadingGrid,
} from '../../../Bibliographies/selectors';
import { loadRates } from '../../../Rates/actionsSagas';
import { getRates, getWaiting } from '../../../Rates/selectors';
import {
  getTopicsReportForGivenRates,
  setCompletionStatus,
} from '../../actions';
import { getCompletionStatusSagas } from '../../actionsSagas';
import {
  getCompletionStatus,
  getTopicsCSVJsonReport,
  getTopicsReportError,
  getTopicsReportIsLoading,
} from '../../selectors';
import Topics from './TopicsReport';

const mapStateToProps = (state) => ({
  report: getRateChapterQuestionsReport(state),
  isLoading: loadingGrid(state),
  ratesIsLoading: getWaiting(state),
  rates: getRates(state),
  topicsReport: getTopicsCSVJsonReport(state),
  topicsReportIsLoading: getTopicsReportIsLoading(state),
  topicsReportError: getTopicsReportError(state),
  completionStatus: getCompletionStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadRates: () => {
    dispatch(loadRates());
  },
  generateReportForSelectedRates: (rates, ratingExams, multiple) => {
    dispatch(getTopicsReportForGivenRates(rates, ratingExams, multiple));
  },
  setCompletionStatus: (data) => {
    dispatch(setCompletionStatus(data));
  },
  getCompletionStatusSagas: () => {
    dispatch(getCompletionStatusSagas());
  },
});

const TopicsReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Topics);

export default TopicsReportContainer;
