import React from 'react';
import { Col, Row } from 'react-bootstrap';

class rateList extends React.Component {
  state = {
    search: '',
    idRate: -1,
  };

  componentDidMount() {
    if (this.props.rates && this.props.setRate) {
      this.props.setRate({});
    }
  }

  componentWillReceiveProps = nextProps => {};

  onSearch = e => {
    this.setState({ search: e.target.value });
  };

  getSearchRates = () => {
    let { rates } = this.props;

    if (this.state.search !== '') {
      let search = this.state.search.toLowerCase();
      rates = rates.filter(r => {
        if (r.id !== 0) {
          return (
            r.name.toLowerCase().includes(search) ||
            r.shortname.toLowerCase().includes(search)
          );
        } else {
          return null;
        }
      });
    }
    rates = rates.sort(function(a, b) {
      return a.shortname - b.shortname;
    });
    return rates;
  };

  handleClickRate(rate) {
    this.setState({ idRate: rate.id });
    this.props.setRate(rate);
    this.props.getNotifications(rate.id);
  }

  renderSearch(search) {
    return (
      <Col>
        <div className="searchInput">
          <input
            id="input"
            className="form-control"
            value={search}
            onChange={this.onSearch}
          />
          {search !== '' ? (
            <i
              onClick={() => this.setState({ search: '' })}
              className="fa fa-close fa-lg fa-close"
            />
          ) : (
            <i className="fa fa-search fa-lg fa-in-search" />
          )}
        </div>
      </Col>
    );
  }

  render() {
    let filteredRate = this.getSearchRates();

    return (
      <div>
        <div className="searchContainer">
          <div>{this.renderSearch(this.state.search)}</div>
        </div>
        <div className="rate-name-list">
          {filteredRate.length > 0 ? (
            filteredRate.map(rate => {
              return (
                <div key={rate.id}>
                  <div
                    onClick={() => this.handleClickRate(rate)}
                    className={
                      rate.id === this.state.idRate
                        ? 'rate-title active'
                        : 'rate-title'
                    }
                  >
                    <div>
                      {`${rate.name} ${
                        rate.shortname ? '(' + rate.shortname + ')' : ''
                      }`}
                    </div>
                    {rate.notifications !== null &&
                      rate.notifications !== undefined && (
                        <div>
                          <i className="fa fa-bell fa-lg" />
                        </div>
                      )}
                  </div>
                </div>
              );
            })
          ) : (
            <p>No Rates</p>
          )}
        </div>
      </div>
    );
  }
}

export default rateList;
