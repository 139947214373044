import './Roles.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import Select from 'react-select';
import _ from 'underscore';

import Card from '../../../../commonComponents/Card/Card';
import CheckBoxList from '../../../../commonComponents/CheckBoxList/CheckBoxList';
import NumberUtils from '../../../../lib/NumberUtils';

const ALL_RATES = 'All Rates';

class Activity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRoles: [],
      editGeneralRoles: false,
      roleToEdit: null,
      isQual: false,
      rolesBeforeEdit: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.user &&
      this.props.user &&
      nextProps.user.id !== this.props.user.id
    ) {
      this.setState({ editGeneralRoles: false });
    }
    if (nextProps.user) {
      this.setUserRoles(nextProps.user);
    }
  }

  setUserRoles = (user) => {
    this.setState({ selectedRoles: _.pluck(user.roles, 'id') });
  };

  editRole = (role, isQual) => {
    if (isQual && this.props.quals.length === 0) {
      this.props.loadQuals();
    }
    if (!isQual && this.props.rates.length === 0) {
      this.props.loadRates();
    }
    this.setState({ roleToEdit: role.id, isQual });
  };

  onAccept = (sp) => {
    let oldSps = this.state.rolesBeforeEdit;
    oldSps = oldSps.map((s) => {
      const obj = { role: this.state.roleToEdit };
      obj[this.state.isQual ? 'qual' : 'rate'] = s.studyProgram.id;
      return obj;
    });

    if (sp.length > 0) {
      const sps = sp.map((s) => {
        const obj = { role: this.state.roleToEdit };
        obj[this.state.isQual ? 'qual' : 'rate'] = s;
        return obj;
      });
      this.props.onUpdateAdminGroups({
        id: this.props.user.id,
        adminGroups: sps,
        oldAdminGroups: oldSps,
      });
    } else {
      const sps = [{ role: this.state.roleToEdit }];
      sps[0][this.state.isQual ? 'qual' : 'rate'] = 0;

      this.props.onUpdateAdminGroups({
        id: this.props.user.id,
        adminGroups: sps,
        oldAdminGroups: oldSps,
      });
    }
    this.setState({ roleToEdit: null, rolesBeforeEdit: null });
  };

  getStudyProgramRoles = (isQual) => {
    const { allRoles, user, roles } = this.props;
    const allSpRoles = allRoles.filter((r) => r.isStudyProgramRole);
    if (!allSpRoles || allSpRoles.length === 0) {
      return <p className="pClass">No permissions</p>;
    }
    const filteredRoles = roles.filter((r) =>
      isQual ? !r.studyProgram.shortname : r.studyProgram.shortname,
    );
    return (
      <div className="centered">
        {allSpRoles.map((role) => {
          if (
            this.state.roleToEdit &&
            this.state.roleToEdit === role.id &&
            isQual === this.state.isQual
          ) {
            const optionsRates = this.props.rates;
            if (
              Array.isArray(optionsRates) &&
              !optionsRates.find((item) => item.value === ALL_RATES)
            ) {
              optionsRates?.unshift({ id: ALL_RATES, shortname: ALL_RATES });
            }

            return (
              <RoleEdit
                role={role}
                adminRoles={roles}
                admin={user}
                type={isQual ? 'qual' : 'rate'}
                options={isQual ? this.props.quals : optionsRates}
                onCancel={() => this.setState({ roleToEdit: false })}
                placeholder={isQual ? 'Select qual' : 'Select Rate'}
                onAccept={this.onAccept}
              />
            );
          }
          return (
            <Role
              key={NumberUtils.obtainUniqueKey()}
              role={role}
              admin={user}
              adminRoles={filteredRoles}
              onEdit={(r) => {
                const rolesBeforeEdit = filteredRoles.filter(
                  (fr) => fr.adminUser === user.id && fr.role === r.id,
                );
                this.setState({ rolesBeforeEdit });
                this.editRole(role, isQual);
              }}
            />
          );
        })}
      </div>
    );
  };

  getGeneralPermissions = (user) => {
    const generalRolesPermissions = user && user.roles ? user.roles : [];
    return (
      <Row style={{ width: '100%' }}>
        <Col
          xs={4}
          md={4}
          className={this.props.myAccount ? 'responsive-col-4' : ''}
        >
          <div>General roles</div>
        </Col>
        <Col
          xs={6}
          md={6}
          className={this.props.myAccount ? 'responsive-col-6' : ''}
        >
          {generalRolesPermissions.length === 0 ? (
            <p className="pClass"> No permissions</p>
          ) : (
            <div className="centered">
              {generalRolesPermissions.map((p) => (
                <p key={NumberUtils.obtainUniqueKey()} className="pClass">
                  {p.name}
                </p>
              ))}
            </div>
          )}
        </Col>
        {!this.props.myAccount && (
          <Col xs={1} md={1}>
            <i
              onClick={() => {
                this.setUserRoles(user);
                this.setState({ editGeneralRoles: true });
              }}
              className="fa fa-pencil fa-lg valueName"
            />
          </Col>
        )}
      </Row>
    );
  };

  itemsForCheckboxList = () =>
    this.props.allRoles
      .filter((r) => !r.isStudyProgramRole)
      .map((r) => ({
        id: r.id,
        name: r.name,
        checked: this.state.selectedRoles.includes(r.id),
      }));

  CheckBoxListChange = (id) => {
    const role = parseInt(id, 10);
    let selectedRoles = this.state.selectedRoles;
    if (selectedRoles.includes(role)) {
      selectedRoles = selectedRoles.filter((r) => r !== role);
    } else {
      selectedRoles.push(role);
    }
    this.setState({ selectedRoles });
  };

  onGeneralRoleChange = () => {
    this.props.updateAdmin({
      id: this.props.user.id,
      roles: this.state.selectedRoles,
    });
    this.setState({ editGeneralRoles: false });
  };

  getGeneralPermissionsEdit = (user) => (
    <div>
      <Row style={{ width: '100%', zIndex: '5' }}>
        <Col xs={4} md={4} lg={4}>
          <div className="valueName">General roles</div>
        </Col>
        <Col xs={8} md={8} lg={8}>
          <CheckBoxList
            items={this.itemsForCheckboxList()}
            onChange={this.CheckBoxListChange}
          />
        </Col>
        <div className="buttonsContainer editGeneralRoles">
          <Button
            className="btn btn-success"
            onClick={() => this.onGeneralRoleChange()}
          >
            Accept
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              this.setState({ editGeneralRoles: false });
            }}
          >
            Cancel
          </Button>
        </div>
      </Row>
    </div>
  );

  renderContent = (roles, user) => (
    <Row className="cardContentWrapper">
      {this.state.editGeneralRoles
        ? this.getGeneralPermissionsEdit(user)
        : this.getGeneralPermissions(user)}
      <div className="separatorLine" />
      <Row style={{ width: '100%' }}>
        <Col
          xs={4}
          md={4}
          className={this.props.myAccount ? 'responsive-col-4' : ''}
        >
          <div>Rates roles</div>
        </Col>
        <Col
          xs={8}
          md={8}
          className={this.props.myAccount ? 'responsive-col-8' : ''}
        >
          {this.getStudyProgramRoles(false)}
        </Col>
      </Row>
      <div className="separatorLine" />
      <Row style={{ width: '100%' }}>
        <Col
          xs={4}
          md={4}
          className={this.props.myAccount ? 'responsive-col-4' : ''}
        >
          <div>Quals roles</div>
        </Col>
        <Col
          xs={8}
          md={8}
          className={this.props.myAccount ? 'responsive-col-8' : ''}
        >
          {this.getStudyProgramRoles(true)}
        </Col>
      </Row>
    </Row>
  );

  render() {
    return (
      <div className="ActivityContainer">
        <Card
          key={NumberUtils.obtainUniqueKey()}
          title="Permissions & Roles"
          content={this.renderContent(this.props.roles, this.props.user)}
        />
      </div>
    );
  }
}

export default Activity;

export class Role extends React.Component {
  getRole = () => {
    const { role, adminRoles } = this.props;
    if (!role) {
      return '';
    }
    let permissionRates = adminRoles.filter((r) => r.role === role.id);
    if (permissionRates.length > 0) {
      permissionRates = permissionRates.map((p, index) => {
        if (p.studyProgram.shortname) {
          return `${p.studyProgram.shortname}`;
        }
        return `${p.studyProgram.description}`;
      });
      permissionRates = permissionRates.sort().toString();
    } else {
      permissionRates = 'none';
    }
    return (
      <Row style={{ width: '100%' }}>
        <Col xs={10} md={10} className="mr-auto">
          <b>{role.name}</b> <br /> {permissionRates}
        </Col>
        <Col xs={1} md={1} className="pl-3 pr-0">
          <i
            onClick={() => this.props.onEdit(role)}
            className="fa fa-pencil fa-lg valueName"
          />
        </Col>
      </Row>
    );
  };

  render() {
    return <div style={{ marginBottom: '10px' }}>{this.getRole()}</div>;
  }
}

export class RoleEdit extends React.Component {
  constructor() {
    super();

    this.state = {
      value: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.updateSelectedValues(this.props);
  }

  componentDidMount() {
    this.openSelect();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.options.length !== nextProps.options.length) {
      this.updateSelectedValues(nextProps);
    }
  }

  onCancel = () => {
    this.updateSelectedValues(this.props);
    this.props.onCancel();
  };

  openSelect = () => {
    if (this.refs && this.refs.tags) {
      this.refs.tags.focus();
    }
  };

  updateSelectedValues = (props) => {
    const { role, adminRoles, options, type } = props;
    if (role && adminRoles && adminRoles.length > 0) {
      const filteredRoles = adminRoles.filter((r) => {
        if (type === 'qual') {
          return !r.studyProgram.hasOwnProperty('isPmkOnly');
        }
        return r.studyProgram.hasOwnProperty('isPmkOnly');
      });
      const optionsIds = options.map((option) => option.id);
      const adminR = filteredRoles.filter(
        (group) =>
          group.role === role.id && optionsIds.includes(group.studyProgram.id),
      );
      this.setState({ value: adminR.map((r) => r.studyProgram) });
    } else {
      this.setState({ CSSMathValue: [] });
    }
  };

  /*
  onSelect(event) {
    let idToAdd = parseInt(event.params.data.id, 10);
    let value = this.state.value;
    value.push(idToAdd);
    this.setState({ value });
  }
  onUnselect(event) {
    let idToAdd = parseInt(event.params.data.id, 10);
    let value = this.state.value;
    value = value.filter(v => v !== idToAdd);
    this.setState({ value });
  }
*/
  handleChange = (selectedOption) => {
    const isAllSelected = selectedOption.find(
      (item) => item.value === ALL_RATES || item.id === ALL_RATES,
    );

    if (isAllSelected) {
      const allRates = this.props.options
        .filter((rate) => rate?.id !== ALL_RATES && rate?.label !== ALL_RATES)
        .map((rate) => ({
          'label': rate.label,
          'value': rate.id,
        }))
        .sort((a, b) => a.label?.localeCompare(b.label));

      this.setState({
        value: allRates,
      });
    } else {
      const values = selectedOption
        .map((v) => ({
          value: v?.id || v?.value,
          label: v?.shortname || v?.label || v?.description,
        }))
        .sort((a, b) => a.label?.localeCompare(b.label));

      this.setState({ value: values });
    }
  };

  valuesToSelect = () =>
    this.state.value
      .map((v) => ({
        value: v?.id || v?.value,
        label: v?.shortname || v?.label || v?.description,
      }))
      .sort((a, b) => a.label?.localeCompare(b.label));

  valuesToAccept = () => this.state.value.map((v) => v?.id || v?.value);

  render() {
    const { role, options } = this.props;
    const values = this.valuesToSelect();

    return (
      <div className="RolesContainer">
        <div style={{ marginBottom: '10px' }}>
          <b>{role.name}</b> <br />
          <div className="selectContainer">
            {options.length > 0 && Array.isArray(values) && (
              <Select
                value={values}
                className="writersSelector"
                placeholder="Select Rate"
                options={options.map((option) => {
                  option.value = option.id;
                  option.label = option.shortname
                    ? option.shortname
                    : option.name;
                  return option;
                })}
                isMulti
                onChange={this.handleChange}
                ref="tags"
                closeMenuOnSelect
                openMenuOnFocus
              />
            )}
            <div className="buttonsEditContainer">
              <div className="check">
                <i
                  onClick={() => {
                    this.props.onAccept(this.valuesToAccept());
                  }}
                  className="fa fa-check fa-lg"
                />
              </div>
              <div className="cross">
                <i onClick={this.onCancel} className="fa fa-close fa-lg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
