import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'practiceExams');
}

export const getPracticeExamInformation = state =>
  getState(state).get('practiceExamInformation');

export const getPracticeExamInformationWaiting = state =>
  getState(state).get('practiceExamInformationWaiting');

export const getRate = state => getState(state).get('rate');
export const getRank = state => getState(state).get('rank');
export const getDutyState = state => getState(state).get('dutyState');
export const getExam = state => getState(state).get('exam');
export const getCycle = state => getState(state).get('selectedCycle');
export const getBuildPracticeExamWaiting = state =>
  getState(state).get('buildPracticeExamWaiting');
export const getRemoveQuestionSuccess = state =>
  getState(state).get('removeQuestionSuccess');
export const getRemoveQuestionWaiting = state =>
  getState(state).get('removeQuestionWaiting');
export const getActivateExamWaiting = state =>
  getState(state).get('activateExamWaiting');
export const getActivateExamSuccess = state =>
  getState(state).get('activateExamSuccess');
export const getBuildAllExamsWaiting = state =>
  getState(state).get('buildAllExamsWaiting');
export const getBuildAllExamsSuccess = state =>
  getState(state).get('buildAllExamsSuccess');
export const getBuildAllExamsProgress = state =>
  getState(state).get('buildAllExamsProgress');
