import React from 'react';
import PropTypes from 'prop-types';
import DefinitionsListRow from './DefinitionsListRow';
import DefinitionsListEmptyRow from './DefinitionsListEmptyRow';
import { Row, Col } from 'react-bootstrap';
import './DefinitionsList.css';
import Button from 'react-bootstrap-button-loader';

export default class DefinitionsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
    };
  }

  componentWillMount() {
    if (this.props.onLoad && !this.props.isQuestion) {
      this.props.onLoad(this.props.reference);
    }
    if (this.props.isQuestion && this.props.reference.defaultQuestion) {
      if (this.props.updatedDefinitions.length > 0) {
        this.setState({ list: this.props.updatedDefinitions });
      }
    } else {
      if (
        this.props.reference.definitions &&
        this.props.reference.definitions.length > 0
      ) {
        this.setState({
          list: this.props.isQuestion
            ? JSON.parse(this.props.reference.definitions)
            : this.props.reference.definitions,
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.props.clearDefinitions) {
      this.props.clearDefinitions();
    }
  }

  isEven = n => {
    return n % 2 === 0;
  };

  shouldIncludeDefinition = definition => {
    return (
      !this.props.questionText ||
      this.props.questionText.search(definition.term) >= 0
    );
  };

  getListItemsCreateQuestion = () => {
    let items = [];
    let index = 0;
    if (this.state.list.length > 0) {
      for (let item of this.state.list) {
        items.push(
          <DefinitionsListRow
            item={item}
            onEdit={this.onEditQuestionDef}
            onDelete={this.onDeleteQuestionDef}
            style={!this.isEven(index) ? '' : 'rowStriped'}
            readOnly={this.props.readOnly}
            assignment={this.props.assignment}
            isQuestion={this.props.isQuestion}
          />,
        );
        index++;
      }
      if (items.length > 0) {
        return items;
      }
    }
  };

  getListItems = () => {
    let items = [];
    let index = 0;
    let list = this.props.isQuestion ? this.state.list : this.props.list;
    if (list && list.length > 0) {
      for (let item of list) {
        if (this.shouldIncludeDefinition(item)) {
          items.push(
            <DefinitionsListRow
              item={item}
              onEdit={
                this.props.isQuestion
                  ? this.onEditQuestionDef
                  : this.props.onEdit
              }
              onDelete={
                this.props.isQuestion
                  ? this.onDeleteQuestionDef
                  : this.props.onDelete
              }
              style={!this.isEven(index) ? '' : 'rowStriped'}
              readOnly={this.props.readOnly}
              assignment={this.props.assignment}
              isQuestion={this.props.isQuestion}
              editPermission={this.props.editPermission}
              showNotification={this.props.showNotification}
            />,
          );
          index++;
        }
      }
      if (items.length > 0) {
        return items;
      }
    }
    return <div>{this.props.noRecordsText}</div>;
  };

  onEditQuestionDef = (item, assignment) => {
    if (this.props.editPermission) {
      this.setState({
        list: this.state.list.map(definition =>
          definition.id === item.id ? item : definition,
        ),
      });
    } else {
      this.props.showNotification('Denied', 'Not enough permissions', 'error');
    }
  };

  onDeleteQuestionDef = (item, assignment) => {
    if (this.props.editPermission) {
      this.setState({
        list: this.state.list.filter(definition => definition.id !== item.id),
      });
    } else {
      this.props.showNotification('Denied', 'Not enough permissions', 'error');
    }
  };

  onAdd = item => {
    if (this.props.editPermission) {
      if (this.props.isQuestion) {
        if (this.state.list.length > 0)
          item.id = this.state.list[this.state.list.length - 1].id + 1;
        if (this.state.list.length === 0) item.id = 1;
        this.setState({ list: this.state.list.concat([item]) });
      } else {
        item.reference = this.props.reference.id;
        this.props.onAdd(
          item,
          this.props.assignment ? this.props.assignment.id : null,
        );
      }
    } else {
      this.props.showNotification('Denied', 'Not enough permissions', 'error');
    }
  };

  termAlreadyExists = term => {
    let list = this.props.isQuestion ? this.state.list : this.props.list;
    return list.find(definition => {
      return definition.term === term;
    });
  };

  getEmptyItem = () => {
    let className = !this.isEven(this.props.list.length) ? '' : 'rowStriped';
    return (
      <DefinitionsListEmptyRow
        onAdd={this.onAdd}
        style={className}
        termAlreadyExists={this.termAlreadyExists}
      />
    );
  };

  onClickSaveChanges = () => {
    if (this.props.editPermission) {
      if (this.props.reference.defaultQuestion) {
        this.props.setQuestionDefinitions(this.state.list);
      } else {
        let updatedQuestion = this.props.reference;
        updatedQuestion.definitions = JSON.stringify(this.state.list);
        this.props.updateQuestion(
          updatedQuestion,
          this.props.assignment ? this.props.assignment.id : null,
        );
      }
      this.props.closeModal();
    } else {
      this.props.showNotification('Denied', 'Not enough permissions', 'error');
    }
  };

  render() {
    return (
      <div
        className={
          !this.props.isQuestion
            ? 'definitions-list-component'
            : 'definition-list-component-question'
        }
      >
        <Row className="headerRow">
          <Col sm={5}>Word or phrase</Col>
          <Col sm={5}>Meaning</Col>
          <Col sm={2} className="textRight">
            {this.props.closeModal && (
              <span className="closeButton" onClick={this.props.closeModal}>
                &times;
              </span>
            )}
          </Col>
        </Row>
        <div className={this.props.isQuestion ? 'definitions-container' : ''}>
          {!this.props.reference.defaultQuestion
            ? this.getListItems()
            : this.getListItemsCreateQuestion()}
          {!this.props.readOnly && this.getEmptyItem()}
        </div>
        {this.props.isQuestion && (
          <Button className="btn btn-success" onClick={this.onClickSaveChanges}>
            Save changes
          </Button>
        )}
      </div>
    );
  }
}

DefinitionsList.propTypes = {
  list: PropTypes.array,
  questionText: PropTypes.string,
  onLoad: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  readOnly: PropTypes.bool,
};

DefinitionsList.defaultProps = {
  list: [],
  reference: 0,
};
