import Notifications from 'react-notification-system-redux';
import { connect } from 'react-redux';
import { reduxForm, reset } from 'redux-form';

import { loadRates } from '../../ChangeExam/actionsSagas';
import { getRates } from '../../ChangeExam/selectors';
import {
  getGeneralRolesPermissions,
  getRolesPermissions,
  getUser,
} from '../../Login/selectors';
import {
  getBetaUser,
  getCancellationNoteAction,
  getCancellationReason,
  getCouponUserAction,
  getUserEducationalInstitutionData,
  getUserNote,
  loadApplicableCoupons,
  searchUsersReceivedAction,
  setCheckBetaUser,
  setDisableCyleAction,
  setDoNotContact,
  setIntercomCancellation,
  setIsNewStudent,
  setMessage,
  setNewStudentError,
  setResponse,
  setRestartPracticeExamSuccess,
  setSelectedUser,
  setShowChangeExamTypeModal,
  setSubscriptionType,
  setUserFavoriteBlockStatus,
  setUserSubmissionBlockStatus,
  upadteCancellationReasonNote,
  updateEducationalInstitutionData,
} from '../actions';
import {
  blockUserActionSagas,
  createStudent,
  deleteUserActionSagas,
  getCylesInformation,
  getUsersActionSagas,
  loadPracticeExams,
  loadSelectedUser,
  loadUserStatistics,
  loadUserSubscriptionType,
  loginAs,
  resetPasswordUserActionSagas,
  resetUserQuestion,
  restartPracticeExam,
  setUserVerificationAction,
  toggleActiveActionSagas,
  unlockUserActionSagas,
  updateUser,
  updateUserSubscription,
  userVerificationAction,
} from '../actionsSagas';
import {
  getActivityPagination,
  getApplicableCoupons,
  getCouponUserSelector,
  getCreationResponse,
  getDisableCycle,
  geteducationalInstitutionData,
  getIntercomCancellationReason,
  getIsBetaUserSelector,
  getIsLoadingBlockStatusSelector,
  getIsLoadingFavoriteBlockStatus,
  getIsNewStudent,
  getListNewCycles,
  getLoading,
  getLoadingInfo,
  getMessage,
  getNewStudentError,
  getNoteDoNotContact,
  getPracticeExams,
  getPracticeExamsWaiting,
  getReasonCancellation,
  getRestartPracticeExamSuccess,
  getRestartPracticeExamWaiting,
  getSelectedUser,
  getShowChangeExamTypeModal,
  getStatistics,
  getUserPagination,
  getUsers,
  getUserSubscription,
  getUserVerificationSelector,
} from '../selectors';
import Users from './Users';

const mapStateToProps = (state) => ({
  users: getUsers(state),
  pagination: getUserPagination(state),
  rates: getRates(state),
  loading: getLoading(state),
  selectedUser: getSelectedUser(state),
  statistics: getStatistics(state),
  creationResponse: getCreationResponse(state),
  activityPagination: getActivityPagination(state),
  user: getUser(state),
  generalPermissions: getGeneralRolesPermissions(state),
  rolesPermissions: getRolesPermissions(state),
  message: getMessage(state),
  userSubscription: getUserSubscription(state),
  isNewStudent: getIsNewStudent(state),
  loadingInfo: getLoadingInfo(state),
  practiceExams: getPracticeExams(state),
  practiceExamsLoading: getPracticeExamsWaiting(state),
  restartPracticeExamWaiting: getRestartPracticeExamWaiting(state),
  restartPracticeExamSuccess: getRestartPracticeExamSuccess(state),
  newStudentError: getNewStudentError(state),
  noteDoNotContact: getNoteDoNotContact(state),
  listNewCycles: getListNewCycles(state),
  disableCycle: getDisableCycle(state),
  reasonCancellation: getReasonCancellation(state),
  intercomCancellationReason: getIntercomCancellationReason(state),
  applicableCoupons: getApplicableCoupons(state),
  userVerification: getUserVerificationSelector(state),
  educationalInstitutionData: geteducationalInstitutionData(state),
  couponUser: getCouponUserSelector(state),
  isLoadingBlockStatus: getIsLoadingBlockStatusSelector(state),
  isLoadingFavoriteBlockStatus: getIsLoadingFavoriteBlockStatus(state),
  isBetaUser: getIsBetaUserSelector(state),
  showChangeExamTypeModal: getShowChangeExamTypeModal(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersFunction: (search, page, filterBy) => {
    dispatch(getUsersActionSagas(search, page, filterBy));
  },
  deleteUser: (id) => {
    dispatch(deleteUserActionSagas(id));
  },
  resetPassword: (id, passwordFields) => {
    dispatch(resetPasswordUserActionSagas(id, passwordFields));
  },
  toggleActive: (id, currentActive) => {
    dispatch(toggleActiveActionSagas(id, currentActive));
  },
  unlockUser: (id) => {
    dispatch(unlockUserActionSagas(id));
  },
  blockUser: (id, state) => {
    dispatch(blockUserActionSagas(id, state));
  },
  updateUser: (user) => {
    dispatch(updateUser(user));
  },
  loadRates: () => {
    dispatch(loadRates());
  },
  createStudent: (student) => {
    dispatch(createStudent(student));
  },
  setSelectedUser: (user) => {
    dispatch(setSelectedUser(user));
  },
  loadUserStatistics: (user, page, filter) => {
    dispatch(loadUserStatistics(user, page, filter));
  },
  loadUserSubscription: (user) => {
    dispatch(loadUserSubscriptionType(user));
    dispatch(setSubscriptionType({}));
  },
  loadSelectedUser: (id) => {
    dispatch(loadSelectedUser(id));
  },
  resetForm: () => {
    dispatch(setResponse(null));
    dispatch(reset('NewStudent'));
  },
  loginAs: (student, platformType, adminUserId) => {
    dispatch(loginAs(student, platformType, adminUserId));
  },
  setMessage: (message) => {
    dispatch(setMessage(message));
  },
  updateUserSubscription: (sub) => {
    dispatch(updateUserSubscription(sub));
  },
  cleanUsers: () => {
    dispatch(searchUsersReceivedAction({ pagination: {}, data: [] }));
  },
  onShowNotification: (message) => {
    dispatch(Notifications.show({ title: 'Notice', message }, 'error'));
  },
  onResetUserQuestions: (user) => {
    dispatch(resetUserQuestion(user));
  },
  setIsNewStudent: (isNewStudent) => dispatch(setIsNewStudent(isNewStudent)),
  forcePasswordUpdate: (user) => {
    dispatch(
      updateUser({
        id: user.id,
        isForceUpdatePassword: true,
        email: user.email,
      }),
    );
  },
  loadPracticeExams: (userId) => dispatch(loadPracticeExams(userId)),
  restartPracticeExam: (practiceExamId, userId) =>
    dispatch(restartPracticeExam(practiceExamId, userId)),
  setRestartPracticeExamSuccess: (value) =>
    dispatch(setRestartPracticeExamSuccess(value)),
  setNewStudentError: (value) => dispatch(setNewStudentError(value)),
  setDoNotContact: (payload) => dispatch(setDoNotContact(payload)),
  getUserNote: (userId) => dispatch(getUserNote(userId)),
  getCylesInformation: (userId) => dispatch(getCylesInformation(userId)),
  setDisableCyleAction: (cycleId, value) =>
    dispatch(setDisableCyleAction(cycleId, value)),
  setIntercomCancellation: (payload) =>
    dispatch(setIntercomCancellation(payload)),
  getCancellationReason: (userId) => dispatch(getCancellationReason(userId)),
  getCancellationNoteAction: (userId) =>
    dispatch(getCancellationNoteAction(userId)),
  loadApplicableCoupons: (userId) => dispatch(loadApplicableCoupons(userId)),
  upadteCancellationReason: (userId, cancelledReason) =>
    dispatch(upadteCancellationReasonNote(userId, cancelledReason)),
  getUserVerification: (userId) => dispatch(userVerificationAction(userId)),
  setUserVerification: (userId, customer) =>
    dispatch(setUserVerificationAction(userId, customer)),
  updateEducationalInstitutionData: (userId, educationalInstitution) =>
    dispatch(updateEducationalInstitutionData(userId, educationalInstitution)),
  getUserEducationalInstitutionData: (userId) =>
    dispatch(getUserEducationalInstitutionData(userId)),
  getCouponUserAction: (userId) => dispatch(getCouponUserAction(userId)),
  setUserSubmissionBlockStatus: (userId, status) =>
    dispatch(setUserSubmissionBlockStatus(userId, status)),
  setUserFavoriteBlockStatus: (userId, status) =>
    dispatch(setUserFavoriteBlockStatus(userId, status)),
  setCheckBetaUser: (userId, status) =>
    dispatch(setCheckBetaUser(userId, status)),
  getBetaUser: (userId) => dispatch(getBetaUser(userId)),
  setShowChangeExamTypeModal: (value) =>
    dispatch(setShowChangeExamTypeModal(value)),
});

const connected = connect(mapStateToProps, mapDispatchToProps)(Users);

const UsersContainer = reduxForm({
  form: 'NewStudent',
})(connected);

export default UsersContainer;
