import React from 'react';
import Button from 'react-bootstrap-button-loader';
import { Row, Col } from 'react-bootstrap';
import { SimpleInput, SimpleTextarea } from '../../Input/Input';
import './CreateStudyPlan.css';

export default class CreateStudyPlan extends React.Component {
  state = {
    title: this.props.studyPlan ? this.props.studyPlan.title : '',
    isActive: this.props.studyPlan ? this.props.studyPlan.isActive : false,
  };

  validate = () => {
    let errors = {};
    let fields = this.state;

    if (!fields.title || fields.title === '') {
      errors.title = 'This field is required';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: null, waiting: true });
    if (this.props.studyPlan) {
      fields.id = this.props.studyPlan.id;
    }
    this.props.onSubmit(fields);
  };

  render() {
    const { onCancel, title: modalTitle } = this.props;
    const { title, isActive, errors, waiting } = this.state;
    return (
      <div className="create-study-cycle-component">
        <div className="card-block">
          <div className="createModalTitle">
            {modalTitle}
            <i
              className="fa fa-times fa-xs"
              onClick={() => this.props.onCancel()}
            />
            <hr style={{ width: '600px', marginLeft: '-15px' }} />
          </div>
          <Row>
            <Col md={4}>
              <span className="InputLebel">Title</span>
            </Col>
            <Col md={8}>
              <SimpleTextarea
                type="text"
                value={title}
                onChange={e => {
                  this.setState({ title: e.target.value });
                }}
                error={errors && errors.title ? errors.title : null}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="InputLebel">IsActive</span>
            </Col>
            <Col md={8}>
              <SimpleInput
                type="checkbox"
                value={isActive}
                onChange={e => {
                  this.setState({ isActive: !isActive });
                }}
                error={errors && errors.isActive ? errors.isActive : null}
              />
            </Col>
          </Row>
          <hr style={{ width: '600px', marginLeft: '-15px' }} />
          <div className="bottom-buttons-new">
            <Button
              id="btn-success"
              className="btn btn-success"
              onClick={this.validate}
              loading={waiting}
            >
              Submit
            </Button>
            <Button className="btn btn-secondary" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
