import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getStateCustomerSubmission(state) {
  return getStateWithOnlyOneLevel(state, 'customerSubmission');
}

export const getAllRatesSelectors = (state) =>
  getStateCustomerSubmission(state).get('rates');

export const getRateSelector = (state) =>
  getStateCustomerSubmission(state).get('rate');

export const getUserSubmittedQuestionsSelector = (state) =>
  getStateCustomerSubmission(state).get('submittedQuestions');

export const getIsLoadingSubmittedQuestionsSelector = (state) =>
  getStateCustomerSubmission(state).get('isLoadingSubmittedQuestions');

export const getFavoriteQuestionsSelector = (state) =>
  getStateCustomerSubmission(state).get('favoriteQuestions');

export const getIsMoreFavoriteQuestionsSelector = (state) =>
  getStateCustomerSubmission(state).get('isMoreFavoriteQuestions');

export const getIsLoadingFavoriteQuestionsSelector = (state) =>
  getStateCustomerSubmission(state).get('isLoadingFavoriteQuestions');
