import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import Spinner from '../Spinner/Spinner';

export default class InfiniteList extends React.Component {
  render() {
    const { isLoading } = this.props;
    return (
      <InfiniteScroll pageStart={0} useWindow={false}>
        {isLoading && <Spinner />}
        {this.props.children}
        {isLoading && <Spinner />}
      </InfiniteScroll>
    );
  }
}
