import { connect } from 'react-redux';

import Maintenance from '../components/Maintenance/Maintenance';
import { verifyIsMaintenance } from '../components/User/actions';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  verifyIsMaintenance: () => dispatch(verifyIsMaintenance()),
});

const MaintenanceContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Maintenance);

export default MaintenanceContainer;
