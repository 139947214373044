import * as t from './actionTypes';
import { Map, List } from 'immutable';

const initialState = Map({
  watiting: false,
  changeHistory: List([]),
});

export default function(state = initialState, action) {
  switch (action.type) {
    case t.SET_CHANGE_HISTORY:
      return state.set('changeHistory', List(action.payload));

    case t.SET_WAITING:
      return state.set('waiting', action.payload);

    default:
      return state;
  }
}
