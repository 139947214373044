import Notifications from 'react-notification-system-redux';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import _ from 'underscore';

import { closeModal } from '../../containers/Full/actions';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import * as api from '../../utilities/ServiceManager';
import {
  onShowQuestionDetails,
  onShowQuestionOverview,
  setActiveStatus,
  setAdminByQual,
  setAdminByRate,
  setAssignments,
  setAssignmentsCount,
  setAssignModalLoadingReferences,
  setAssignModalRates,
  setAssignModalReferences,
  setAssignModalWaiting,
  setIsChangingType,
  setLoading,
  setLoadingCreateGPTquestion,
  setLoadingMySP,
  setLoadingSummaries,
  setLoadingSummary,
  setMyStudyPrograms,
  setSelectedAdminAndRate,
  setSelectedRate,
  setSelectedWriter,
  setSummariesActions,
  setSummary,
  setWriters,
  updateAssignmentDueDate,
  updateAssignmentsAfterComplete,
  updateAssignmentsAfterDelete,
  updateAssignmentsAfterRejection,
  updateAssignmentStatus,
} from './actions';
import {
  ASSIGNMENTS_COUNT_SAGAS,
  DELETE_ASSIGNMENT,
  GET_ALL_QUALS_ADMINS,
  GET_ALL_RATES_ADMINS,
  GET_AWAITING_APPROVAL,
  LOAD_ASSIGNMENTS,
  LOAD_MY_ASSIGNMENTS,
  LOAD_RATES_FOR_ASSIGN_MODAL,
  LOAD_REFERENCES_FOR_ASSIGN_MODAL,
  LOAD_WRITERS,
  ON_EDIT_ASSIGNMENT,
  SUBMIT_QUESTIONS_GPT,
  SUBMIT_SUMMARY,
} from './actionTypesSagas';

/**
 * LOAD ALL RATES ADMINS
 */
function* loadAllRatesAdminsSaga() {
  yield takeEvery(GET_ALL_RATES_ADMINS, loadAllRatesAdmins);
}

function* loadAllRatesAdmins(action) {
  try {
    const admins = yield call(
      api.getAllAdminsByRate,
      action.showInactive,
      true,
    );
    yield put(setAdminByRate(admins));
    if (!action.removingApprovalFilter) {
      for (const admin of admins) {
        if (admin.adminUsers && admin.adminUsers.length > 0) {
          if (!action.isNotSetAdmin) {
            yield put(setSelectedAdminAndRate(admin.adminUsers[0], admin));
          }
          yield put(setIsChangingType(false));
          break;
        }
      }
    }
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load the rates admins. Please try again later.',
      ),
    );
  }
}

/**
 * LOAD ALL QUALS ADMINS
 */
function* loadAllQualsAdminsSaga() {
  yield takeEvery(GET_ALL_QUALS_ADMINS, loadAllQualsAdmins);
}

function* loadAllQualsAdmins(action) {
  try {
    const admins = yield call(
      api.getAllAdminsByRate,
      action.showInactive,
      false,
    );
    yield put(setAdminByQual(admins));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load the quals admins. Please try again later.',
      ),
    );
  }
}

/**
 * LOAD ASSIGNMENTS
 */
function* loadAssignmentsSaga() {
  yield takeLatest(LOAD_ASSIGNMENTS, loadAssignments);
}

function* loadAssignments(action) {
  try {
    yield put(setLoading(true));
    const admins = yield call(
      api.getAssigments,
      action.admin,
      action.status ? action.status : 'Not_Started',
      action.studyProgram,
    );
    yield put(setAssignments(admins));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load the assignment. Please try again later.',
      ),
    );
  } finally {
    yield put(setLoading(false));
  }
}

/**
 * EDIT ASSIGNMENT
 */
function* onEditAssignmentSaga() {
  yield takeLatest(ON_EDIT_ASSIGNMENT, onEditAssignment);
}

function* onEditAssignment(action) {
  try {
    const assignment = yield call(api.onEditAssignment, action.assignment);

    if (action.assignment.writerEmail) {
      const writers = yield call(api.getAllWriters);
      yield put(setWriters(writers));
      let writerForSelect;
      _.each(writers, (value) => {
        if (value[0].email === action.assignment.writerEmail) {
          writerForSelect = value[0];
        }
      });
      yield put(setSelectedWriter(writerForSelect));
    }

    if (action.isDisableQuestion) {
      yield put(onShowQuestionDetails(assignment));
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message: 'Question edited successfully',
            autoDismiss: 1,
          },
          'success',
        ),
      );
      return;
    }
    if (action.rejection) {
      yield put(updateAssignmentsAfterRejection(assignment));
      yield put(setActiveStatus('In progress'));
      yield put(onShowQuestionOverview(null, false));
    } else {
      if (action.isUpdateStatus) {
        yield put(updateAssignmentStatus(assignment));
      } else {
        yield put(updateAssignmentDueDate(assignment));
      }
    }
    if (assignment.status === 'Published') {
      yield put(updateAssignmentsAfterComplete(assignment));
      yield put(onShowQuestionOverview(null, false));
    }

    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Assignment edited successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not edit the assignment. Please try again later.',
      ),
    );
  }
}
/**
 * LOAD RATES FOR ASSIGN MODAL
 */

function* loadRatesForAssignModalSaga() {
  yield takeLatest(LOAD_RATES_FOR_ASSIGN_MODAL, loadRatesForAssignModal);
}

function* loadRatesForAssignModal(action) {
  try {
    yield put(setAssignModalWaiting(true));
    const rates = yield call(api.getRates);
    yield put(setAssignModalRates(rates.data));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load rates. Please try again later.',
      ),
    );
  } finally {
    yield put(setAssignModalWaiting(false));
  }
}

/**
 * LOAD REFERENCES FOR ASSIGN MODAL
 */

function* loadReferencesForAssignModalSaga() {
  yield takeLatest(
    LOAD_REFERENCES_FOR_ASSIGN_MODAL,
    loadReferencesForAssignModal,
  );
}

function* loadReferencesForAssignModal(action) {
  try {
    yield put(setAssignModalLoadingReferences(true));
    const references = yield call(
      api.getReferencesForStudyProgram,
      action.typeId,
      action.studyProgramType,
    );
    yield put(setAssignModalReferences(references));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load references. Please try again later.',
      ),
    );
  } finally {
    yield put(setAssignModalLoadingReferences(false));
  }
}

/**
 * DELETE ASSIGNMENT
 */
function* onDeleteAssignmentSaga() {
  yield takeLatest(DELETE_ASSIGNMENT, onDeleteAssignment);
}

function* onDeleteAssignment(action) {
  try {
    yield call(api.deleteAssignment, action.assignment.id);
    if (action.writerEmail) {
      const writers = yield call(api.getAllWriters);
      yield put(setWriters(writers));
      let writerForSelect;
      _.each(writers, (value) => {
        if (value[0].email === action.writerEmail) {
          writerForSelect = value[0];
        }
      });
      yield put(setSelectedWriter(writerForSelect));
    }
    if (!action.writerEmail) {
      yield put(updateAssignmentsAfterDelete(action.assignment));
    }
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Assignment deleted successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not delete the assignment. Please try again later.',
      ),
    );
  }
}

/**
 * LOAD ASSIGNMENTS COUNTS
 */
function* loadAssignmentsCountsSaga() {
  yield takeLatest(ASSIGNMENTS_COUNT_SAGAS, loadAssignmentsCounts);
}

function* loadAssignmentsCounts(action) {
  try {
    const count = yield call(
      api.getAssigmentsCounts,
      action.admin,
      action.rate ? action.rate.id : null,
      action.StudyProgramType,
    );
    yield put(setAssignmentsCount(count));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load the assignments. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 * LOAD AWAITING APPROVAL
 */
function* loadAwaitingApprovalSaga() {
  yield takeLatest(GET_AWAITING_APPROVAL, loadAwaitingApproval);
}

function* loadAwaitingApproval(action) {
  try {
    yield put(setLoading(true));
    const admins = yield call(api.getAwaitingApproval);
    yield put(setAssignments(admins));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load the assignments. Please try again later.',
      ),
    );
  } finally {
    yield put(setLoading(false));
  }
}

/**
 * LOAD AWAITING APPROVAL
 */
function* loadMyAssignmentsSaga() {
  yield takeLatest(LOAD_MY_ASSIGNMENTS, loadMyAssignments);
}

function* loadMyAssignments(action) {
  try {
    yield put(setLoadingMySP(true));
    const sps = yield call(api.getMyAssignments);
    yield put(setMyStudyPrograms(sps));
    if (sps.length > 0 && !action.selectedSP) {
      yield put(setSelectedRate(sps[0]));
    }
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load the assignments. Please try again later.',
      ),
    );
  } finally {
    yield put(setLoadingMySP(false));
  }
}

/**
 * LOAD ALL Writers
 */
function* loadAllWritersSaga() {
  yield takeLatest(LOAD_WRITERS, loadAllWriters);
}

function* loadAllWriters(action) {
  try {
    const writers = yield call(api.getAllWriters);
    yield put(setWriters(writers));

    let WritersObj = [];
    _.each(writers, (value) => {
      WritersObj.push(value[0]);
    });
    WritersObj = _.sortBy(WritersObj, 'name');
    const first = _.first(_.toArray(WritersObj));

    yield put(setSelectedWriter(first));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load the writers. Please try again later.',
      ),
    );
  }
}

/**
 * CREATE GPT QUESTIONS
 */
function* submitGptQuestionsSaga() {
  yield takeEvery(SUBMIT_QUESTIONS_GPT, submitGptQuestions);
}

function* submitGptQuestions(action) {
  try {
    if (action.assignment)
      yield call(
        api.createGPTquestionsService,
        action.fields,
        action.assignment.chapter.reference.id,
        action.assignment.chapter.id,
        action.assignment.id,
      );
    else yield call(api.createGPTquestionsService, action.fields, null);
    yield put(setLoadingCreateGPTquestion(false));
    yield put(closeModal());
    if (action.assignment) {
      yield put(onShowQuestionOverview(action.assignment));
    } else {
      const path = `/home/questions/${action.fields.referenceId}/${action.fields.chapterId}/${action.fields.rateId}/false`;
      setTimeout(() => {
        window.location.href = path;
      }, 500);
    }
  } catch (exception) {
    yield put(setLoadingCreateGPTquestion(false));
    yield put(closeModal());
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not create GPT questions. Please try again later.',
      ),
    );
  }
}

function* submitSummarySagas() {
  yield takeEvery(SUBMIT_SUMMARY, submitSummary);
}

function* submitSummary(action) {
  try {
    yield put(setLoadingSummary(true));
    const response = yield call(
      api.submitSummaryService,
      action.fields,
      action.assignment.chapter.reference.id,
      action.assignment.chapter.id,
      action.assignment.id,
    );
    yield put(setSummary(response));
    yield put(setLoadingSummary(false));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: `Summary ${action.option} successfully `,
          autoDismiss: 3,
        },
        'success',
      ),
    );
    yield put(closeModal());
  } catch (exception) {
    yield put(setLoadingSummary(false));
    yield put(closeModal());
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not create summary. Please try again later.',
      ),
    );
  }
}

/**
 * DEFAUL EXPORT
 */
export default function* sagas() {
  yield all([
    loadAllRatesAdminsSaga(),
    onEditAssignmentSaga(),
    loadAssignmentsSaga(),
    onDeleteAssignmentSaga(),
    loadAssignmentsCountsSaga(),
    loadAwaitingApprovalSaga(),
    loadAllQualsAdminsSaga(),
    loadMyAssignmentsSaga(),
    loadAllWritersSaga(),
    loadRatesForAssignModalSaga(),
    loadReferencesForAssignModalSaga(),
    submitGptQuestionsSaga(),
    submitSummarySagas(),
  ]);
}

function showNotificationErrorFromException(exception) {
  const message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
