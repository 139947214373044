import './ConfirmDeletion.css';

import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap-button-loader';
import { reduxForm } from 'redux-form';

class ConfirmDeletion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      waiting: false,
      questionsSelectedDeleted: [],
    };
    this.inputRef = React.createRef(null);
  }

  componentDidMount() {
    if (this.props.questionsSelected) {
      this.setState({
        questionsSelectedDeleted: this.props.questionsSelected,
      });
    }
  }

  componentWillReceiveProps({ submitSucceeded }) {
    if (submitSucceeded && this.state.waiting) {
      this.setState({ waiting: false });
    }
  }

  validate = ({ confirm }, callback) => {
    if (
      !this.props.isShowDeletedQuestions &&
      this.inputRef &&
      this.inputRef.current &&
      this.inputRef.current.value !== 'DELETE'
    ) {
      this.setState({ error: 'Invalid text' });
      return;
    }

    this.setState({ error: null, waiting: true });
    callback();
  };

  removeList(question) {
    let listQuestions = [...this.state.questionsSelectedDeleted];

    listQuestions = listQuestions.filter((item) => item.id !== question.id);
    this.props.setSeletedQuestionsForDeleted(question, true);
    this.setState({ questionsSelectedDeleted: listQuestions });
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      onCancel,
      itemName = 'an item',
      text,
      message,
      isDelete,
    } = this.props;
    const { questionsSelectedDeleted } = this.state;
    return (
      <div className="confirm-deletion-component">
        <div className="card-block">
          {questionsSelectedDeleted && questionsSelectedDeleted.length ? (
            <div>
              <div className="containerQuestions">
                {questionsSelectedDeleted.map((question, index) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    key={index}
                  >
                    <div
                      style={{ marginTop: '0.5rem' }}
                      dangerouslySetInnerHTML={{
                        __html: question.text,
                      }}
                    />
                    <i
                      style={{ cursor: 'pointer' }}
                      className="fa fa-times fa-md"
                      onClick={() => this.removeList(question)}
                    />
                  </div>
                ))}
              </div>
              <hr />
            </div>
          ) : null}
          {text ? (
            <div className="text">{text}</div>
          ) : (
            !message && (
              <div className="text">
                {`You are about to delete ${itemName}.`}
                <br />
                If you are sure, please type DELETE to confirm.
              </div>
            )
          )}
          {message && <div className="text">{message}</div>}
          <form
            className="widget-form deleteConfirmationForm"
            onSubmit={handleSubmit((fields) => this.validate(fields, onSubmit))}
          >
            {!this.props.noTypeDelete && !this.props.isShowDeletedQuestions && (
              <div className="input-group mb-3">
                <input
                  className="form-control"
                  ref={this.inputRef}
                  name="confirm"
                  // component={Input}
                  icon="trash"
                  placeholder="Type DELETE to confirm"
                  data-test="delete-book-input"
                />
              </div>
            )}
            {this.state.error ? (
              <div className="errors">{this.state.error}</div>
            ) : null}
            <div className="bottom-buttons">
              <Button className="btn btn-secondary" onClick={onCancel}>
                No, go back
              </Button>
              <Button
                className="btn btn-danger"
                type="submit"
                loading={this.state.waiting}
                data-test="delete-book-confirmation-button"
                disabled={
                  this.props.isDelete
                    ? this.state.questionsSelectedDeleted &&
                      this.state.questionsSelectedDeleted.length < 1
                    : false
                }
              >
                {this.props.isShowDeletedQuestions
                  ? 'Yes, undelete it'
                  : 'Yes, delete it'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

ConfirmDeletion.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  requesting: PropTypes.bool,
};

export default reduxForm({ form: 'ConfirmDeletion' })(ConfirmDeletion);
