import { connect } from 'react-redux';
import { getLoadingSummary } from '../../selectors';
import QuestionGptModalView from './QuestionGptModal';
import { submitQuestionsGPT, submitSummary } from '../../actionsSagas';
import { getUrlPdf } from '../../../Bibliographies/actionsSagas';
import { getUrlPdfPrivate } from '../../../Books/selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    urlPdfPrivate: getUrlPdfPrivate(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitQuestions: (fields, assignment) => {
      dispatch(submitQuestionsGPT(fields, assignment));
    },
    getUrlPdf: (url) => {
      dispatch(getUrlPdf(url));
    },
    onSubmitSummary: (fields, assignment, option) => {
      dispatch(submitSummary(fields, assignment, option));
    },
  };
};

const QuestionGptModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionGptModalView);

export default QuestionGptModal;
