import React from 'react';

import Spinner from '../../../../commonComponents/Spinner/Spinner';
import ImageSizeNotificationModal from './components/ImageSizeNotificationModal';

export default class UploadInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waitingPrivateUrl: false,
      isNotificationModalOpen: false,
    };
  }

  componentDidMount() {
    this.props.updateQuestionPdfProcessing(false);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isPrivatePDF &&
      this.state.waitingPrivateUrl &&
      prevProps.urlPdfPrivate !== this.props.urlPdfPrivate
    ) {
      window.open(this.props.urlPdfPrivate, '_blank');
      this.setState({ waitingPrivateUrl: false });
    }
  }

  onClickDeletePDF = (chapter, flag, reference) => {
    const { isWaiting, deletePdfChapter } = this.props;

    if (!isWaiting) {
      const newRef = {
        ...reference,
        pdfUrl:
          reference && reference.actAsParent
            ? reference.imageUrl
            : chapter.imageUrl,
      };
      deletePdfChapter(chapter, flag, newRef);
    }
  };

  getPrivateUrl = () => {
    this.setState({ waitingPrivateUrl: true });
    if (this.props.imageUrl)
      this.props.getPrivateUrl({ url: this.props.imageUrl });
    else this.props.getPrivateUrl({ url: this.props.chapter?.pdfUrl });
  };

  renderPdf(
    chapter,
    deletePdfChapter,
    updateQuestionPdfProcessing,
    questionPdfProcessing,
    updateChapter,
    accept = 'application/pdf',
    className = '',
    reference,
  ) {
    if (questionPdfProcessing) {
      return (
        <div className="pdf-upload">
          <Spinner />
        </div>
      );
    }

    let imageUrl = chapter?.pdfUrl;
    // reference && !reference.actAsParent ? reference.pdfUrl : chapter.pdfUrl;
    if (this.props.imageUrl) {
      imageUrl = this.props.imageUrl;
    }

    if (imageUrl === chapter.pdfUrl && reference) {
      reference.pdfUrl = null;
    }

    if (imageUrl) {
      const imageLink = imageUrl.includes('http') ? imageUrl : null;
      const fileName = imageUrl.toLowerCase().split('/');
      return (
        <div className="SubtopicBox Pointer">
          {this.props.isPrivatePDF ? (
            <a
              onClick={this.getPrivateUrl}
              className={className}
              target="_blank"
            >
              {fileName[fileName.length - 1]}
            </a>
          ) : (
            <a
              href={imageLink}
              className={className}
              target="_blank"
              rel="noreferrer"
            >
              {fileName[fileName.length - 1]}
            </a>
          )}
          {this.props.editPermission && (
            <i
              className="fa fa-times fa-lg mt-1"
              data-test="delete-question-image-button"
              onClick={() => this.onClickDeletePDF(chapter, true, reference)}
            />
          )}
        </div>
      );
    }

    return (
      <div className="pdf-upload cellContainer furtherReadingCell">
        {this.props.editPermission && (
          <label htmlFor={`file-input-${chapter.id}`}>
            <i
              className="fa fa-cloud-upload icons font-2xl d-block mt-2 pointer "
              data-test="upload-question-image-button"
            />
          </label>
        )}
        <input
          id={`file-input-${chapter.id}`}
          data-test="question-file-upload-button"
          type="file"
          accept={accept}
          disabled={this.props.isWaiting}
          onChange={(e) => {
            const fileType = e.target.files[0].type;
            const fileSize = e.target.files[0].size;
            if (fileType.includes('image') && fileSize < 75000) {
              this.setState({
                isNotificationModalOpen: true,
              });
              e.target.value = '';
            } else if (!this.props.isWaiting) {
              updateQuestionPdfProcessing(chapter.id);
              const reference =
                chapter.reference && chapter.reference.id
                  ? chapter.reference.id
                  : chapter.reference;
              const pdfFile = e.target.files[0];
              const myNewFile = new File(
                [pdfFile],
                pdfFile.name.replace('.PDF', '.pdf'),
                { type: pdfFile.type },
              );
              const updatedChapter = {
                pdf: myNewFile,
                reference,
              };

              try {
                updateChapter(updatedChapter, chapter.id, true, reference);
              } catch (err) {}
            }
          }}
        />
        {this.state.isNotificationModalOpen ? (
          <ImageSizeNotificationModal
            isOpen={this.state.isNotificationModalOpen}
            handleToggleModal={() =>
              this.setState({
                isNotificationModalOpen: false,
              })
            }
          />
        ) : null}
      </div>
    );
  }

  render() {
    const {
      chapter,
      deletePdfChapter,
      updateQuestionPdfProcessing,
      questionPdfProcessing,
      updateChapter,
      accept,
      className,
      reference,
    } = this.props;
    return this.renderPdf(
      chapter,
      deletePdfChapter,
      updateQuestionPdfProcessing,
      questionPdfProcessing,
      updateChapter,
      accept,
      className,
      reference,
    );
  }
}
