import './AdminProfile.css';

import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import _ from 'underscore';

import { SimpleDatePicker } from '../Datepicker/Datepicker';
import { InlineInput } from '../Input/Input';
import Select from '../Select/Select';

export default class Card extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      value: '',
      passwordError: '',
    };
  }

  componentWillReceiveProps({ value }) {
    if (this.props.value === value) return;
    this.setState({ value });
  }

  componentWillUnmount() {
    this.setState({ value: '' });
  }

  onSubmit = () => {
    if (!this.state.value && !this.props.select) {
      this.setState({ error: true });
      return;
    }
    const updatedUser = { ...this.props.profile };
    updatedUser[this.props.nameForValue] = this.props.select
      ? this.state.value.id
      : this.state.value;
    if (this.props.profile.rate && !this.props.isRate)
      updatedUser.rate = this.props.profile.rate.id;
    this.props.updateUser(_.pick(updatedUser, 'id', this.props.nameForValue));
    this.setState({ error: false, editMode: false });
  };

  onSubmitDate = (time) => {
    if (!time && !this.props.profile) {
      this.setState({ error: true });
      return;
    }
    const user = { id: this.props.profile.id };
    user[this.props.nameForValue] = time;
    this.props.updateUser(user);
    this.setState({ error: false, editMode: false });
  };

  validatePassword = () => {
    if (
      String(this.state.value).length < 12 ||
      String(this.state.value).length > 30
    ) {
      this.setState({
        passwordError: 'password must be between 12 and 30 chars',
      });
      return false;
    }
    const re =
      /^(?=(.*[^A-Za-z0-9]){2,})(?=(.*[A-Z]){2,})(?=(.*\d){2,})(?=(.*[a-z]){2,}).+/;
    const result = re.test(String(this.state.value));
    if (!result) {
      this.setState({
        passwordError:
          'password must contain 2 special characters, 2 numbers, 2 upper case, and 2 lower case',
      });
    } else {
      this.setState({ passwordError: '' });
    }
    return result;
  };

  render() {
    return (
      <Row className="cardRowContainer" style={{ width: '100%' }}>
        <Col xs={4} md={4} lg={4} className="pr-0">
          <div className="valueName">{this.props.valueName}</div>
        </Col>
        <Col
          md={this.state.editMode ? 8 : 6}
          xs={this.state.editMode ? 8 : 6}
          lg={this.state.editMode ? 8 : 6}
          className="middleColumn pr-0"
        >
          {this.state.editMode ? (
            this.props.select ? (
              <div>
                <Select
                  onOptionClick={(value) => {
                    this.setState({ value });
                  }}
                  onSubmit={(value) => this.onSubmit()}
                  options={this.props.items}
                  onClose={() =>
                    this.setState({ editMode: false, error: false })
                  }
                  value={this.state.value}
                  isRate={this.props.isRate}
                />
              </div>
            ) : (
              <InlineInput
                defaultValue={this.state.value}
                onChange={(value) => this.setState({ value })}
                close={() =>
                  this.setState({
                    editMode: false,
                    error: false,
                    passwordError: '',
                    value: '',
                  })
                }
                onSubmit={() =>
                  this.props.isPassword
                    ? this.validatePassword()
                      ? this.onSubmit()
                      : ''
                    : this.onSubmit()
                }
                type={this.props.isPassword ? 'password' : 'text'}
                error={this.state.error}
                canShowPassword
              />
            )
          ) : (
            <div className="value" title={this.props.value}>
              {this.props.select &&
              this.props.value.name &&
              !this.props.isPassword
                ? this.props.value.name
                : !this.props.isDate && this.props.value}
              {this.props.isPassword && '************'}
              {this.props.isDate && (
                <SimpleDatePicker
                  date={
                    this.props.value
                      ? moment(parseInt(this.props.value, 10))
                      : null
                  }
                  onChange={(date) => {
                    if (!this.props.editable) return;
                    this.onSubmitDate(date.valueOf());
                  }}
                  placeholderText="MM/DD/YYYY"
                  className="datePickerClass"
                />
              )}
            </div>
          )}
        </Col>
        {!this.state.editMode &&
          !this.props.noEditable &&
          !this.props.isDate &&
          !this.props.myAccount && (
            <Col
              style={{ paddingLeft: '41px', paddingRight: '0px' }}
              xs={1}
              md={1}
              lg={1}
            >
              {this.props.editable && (
                <i
                  onClick={() => {
                    if (this.props.load) this.props.load();
                    this.setState({
                      editMode: true,
                      value: this.props.value,
                    });
                  }}
                  className="fa fa-pencil fa-lg valueName"
                />
              )}
            </Col>
          )}
        {this.state.passwordError ? (
          <div className="warning-tooltip-password warning-tooltip-change-password">
            {this.state.passwordError}
          </div>
        ) : (
          ''
        )}
      </Row>
    );
  }
}
