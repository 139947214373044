import * as type from './actionTypesSagas';

export const loadPracticeExamInformation = (rate, rank, dutyState) => ({
  type: type.LOAD_PRACTICE_EXAM_INFORMATION,
  rate,
  rank,
  dutyState,
});

export const buildPracticeExam = practiceExamId => ({
  type: type.BUILD_PRACTICE_EXAM,
  practiceExamId,
});

export const checkBuildPEProgress = () => ({
  type: type.CHECK_BUILD_PE_PROGRESS,
});

export const removeQuestion = (practiceExamId, questionId) => ({
  type: type.REMOVE_QUESTION,
  practiceExamId,
  questionId,
});

export const activateExam = practiceExamId => ({
  type: type.ACTIVATE_EXAM,
  practiceExamId,
});

export const buildAllExams = ratingExamsList => ({
  type: type.BUILD_ALL_EXAMS,
  ratingExamsList,
});
