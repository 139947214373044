// Insights/actions.js
import * as t from './actionTypes';

export const allRolesReceivedAction = response => ({
  type: t.ALLROLESRECEIVED,
  payload: response,
});

export const pagesReceivedAction = response => ({
  type: t.PAGESRECEIVED,
  payload: response,
});

export const pagesRolesReceivedAction = (response, page) => ({
  type: t.PAGEROLESRECEIVED,
  payload: response,
  page,
});

export const actionsReceivedAction = response => ({
  type: t.ACTIONSRECEIVED,
  payload: response,
});

export const createRoleReceivedAction = response => ({
  type: t.CREATEROLE,
  payload: response,
});

export const editRoleReceivedAction = response => ({
  type: t.EDITROLE,
  payload: response,
});

export const deleteRoleReceivedAction = response => ({
  type: t.DELETEROLE,
  payload: response,
});

export const waiting = waiting => ({
  type: t.UPDATE_WAITING,
  waiting,
});

export const actionIndicator = (indicator, status) => ({
  type: t.UPDATE_ACTION_INDICATOR,
  indicator,
  status,
});

export const errorFetchingFromService = error => ({
  type: t.ERROR_FETCHING_FROM_SERVICE,
  payload: error,
});

export const setSelectedRole = role => ({
  type: t.SET_SELECTED_ROLE,
  payload: role,
});

export const updateChecks = checks => ({
  type: t.UPDATE_CHECKS,
  payload: checks,
});

export const updateRoleName = name => ({
  type: t.UPDATE_ROLE_NAME,
  payload: name,
});

export const toggleNewRole = value => ({
  type: t.TOGGLE_NEW_ROLE,
  payload: value,
});

export const fetchAllRoles = () => ({
  type: t.FETCH_ALL_ROLES,
});
