import React from 'react';
import { Col, Row } from 'react-bootstrap';
import SubHeader from '../../../../commonComponents/SubHeader/SubHeader';
import CyclesList from '../../../../commonComponents/CyclesList/CyclesList';
import { hasPagePermissionRates } from '../../../../utilities/FilterLinksForTabs';
import TextLink from '../../../../commonComponents/TextLink/TextLink';
import _ from 'underscore';

const dutyState = ['Regular', 'Substitute', 'Reserve'];
const ranks = ['E4', 'E5', 'E6', 'E7'];
const listOptions = ['Rating Exam', 'Cycles'];

export default class BibliographyList extends React.Component {
  state = {
    search: '',
    viewArchivedCycles: false,
  };

  getRatesAdmins = () => {
    let {
      rates,
      rank,
      dutyState,
      rate,
      onListUpdate,
      ratingExamQuestions,
      getRateRatingExamQuestions,
    } = this.props;
    if (this.state.search !== '') {
      let search = this.state.search.toLowerCase();
      rates = rates.filter(r => {
        if (r.id !== 0) {
          return (
            r.name.toLowerCase().includes(search) ||
            r.shortname.toLowerCase().includes(search)
          );
        } else {
          return null;
        }
      });
    }
    if (rates.length > 0) {
      if (rates[0].id === 0) {
        rates.shift();
      }
      return rates.map((r, i) => (
        <RateLine
          setIsUndoChecked={this.props.setIsUndoChecked}
          setIsRevertChecked={this.props.setIsRevertChecked}
          setUndoState={this.props.setUndoState}
          setActualState={this.props.setActualState}
          cycle={this.props.cycle}
          key={`${r}_${i}`}
          rate={r}
          rank={rank}
          selectedRate={rate}
          dutyState={dutyState}
          onUpdate={onListUpdate}
          ratingExamQuestions={ratingExamQuestions}
          onRateUpdate={getRateRatingExamQuestions}
        />
      ));
    }
    return <p>No rates</p>;
  };

  selectCycle(cycle) {
    this.props.updateSelectedCycle(cycle);
  }

  sortCycles = cycles => {
    let activeCycles = _.filter(cycles, { isActive: true });
    let nonActiveCycles = _.filter(cycles, { isActive: false });

    return activeCycles.concat(nonActiveCycles);
  };

  toggleCycles = () => {
    if (this.state.viewArchivedCycles) {
      this.props.loadCycles();
    } else {
      this.props.loadArchivedCycles();
    }
    this.setState({ viewArchivedCycles: !this.state.viewArchivedCycles });
  };

  getCycles = () => {
    let viewCyclesLinkText = this.state.viewArchivedCycles
      ? 'View Active'
      : 'View Achived';
    return (
      <div>
        <CyclesList
          cycles={this.sortCycles(this.props.cycles)}
          selected={this.props.selectedCycle}
          onCycleClick={cycle => this.selectCycle(cycle)}
          showNewCycle={!this.state.viewArchivedCycles}
        />
        <div className="TextLinkAtBottom">
          <hr />
          <TextLink onAction={this.toggleCycles} text={viewCyclesLinkText} />
        </div>
      </div>
    );
  };

  onSearch = event => {
    this.setState({ search: event.target.value });
  };

  clearSearchInput = () => {
    this.setState({ search: '' });
  };

  getDisabeldOptions = () => {
    if (
      !this.props.user.isSuperAdmin &&
      !hasPagePermissionRates('Cycles', this.props.generalRolesPermissions)
    ) {
      return [1];
    }
    return [];
  };

  render() {
    let searchAction;
    let plusAction;
    if (this.props.activeListTab === listOptions[0]) {
      searchAction = this.onSearch;
    } else {
      plusAction = () => {
        this.props.updateSelectedCycle({
          plusAction: true,
          title: '',
          description: '',
        });
      };
    }
    return (
      <Col
        md={3}
        className="GroupList"
        style={{
          paddingLeft: '0px',
          paddingRight: '0px',
          minWidth: '400px',
        }}
      >
        <SubHeader
          options={listOptions}
          onOptionChange={this.props.onUpdateSelectedListTab}
          activeOption={this.props.activeListTab}
          onSearch={searchAction}
          onPlusClick={plusAction}
          disabledOptions={this.getDisabeldOptions()}
          searchValue={this.state.search}
          clearSearchInput={this.clearSearchInput}
        />
        <div className="ratesContainer">
          {this.props.activeListTab === listOptions[0]
            ? this.getRatesAdmins()
            : this.getCycles()}
        </div>
      </Col>
    );
  }
}

export class RateLine extends React.Component {
  state = {
    expanded:
      this.props.selectedRate &&
      this.props.rate &&
      this.props.selectedRate.id === this.props.rate.id,
  };
  getRatingExamNumber(rank) {
    switch (rank) {
      case 'E4':
        return '3';
      case 'E5':
        return '2';
      case 'E6':
        return '1';
      default:
        return 'C';
    }
  }

  filterClick = (rank, dutyState) => {
    this.props.onUpdate(this.props.rate, rank, dutyState);
    this.props.setIsUndoChecked(true);
    this.props.setIsRevertChecked(true);
    this.props.setUndoState(null);
    this.props.setActualState(null);
  };

  getRowClass = (currentRank, currentDutyState) => {
    const {
      rate,
      rank,
      dutyState,
      selectedRate,
      ratingExamQuestions = [],
    } = this.props;
    if (
      rate.id === selectedRate.id &&
      rank === currentRank &&
      currentDutyState === dutyState
    ) {
      return 'activeRow';
    }
    let questions = ratingExamQuestions.find(
      a =>
        a.rank === currentRank &&
        a.dutyState === currentDutyState &&
        a.rate === rate.id,
    );
    if (questions && questions.linkedRatingExam) {
      let res =
        questions.dutyState === 'Substitute' ||
        questions.dutyState === 'Reserve'
          ? 'linked'
          : '';
      return res;
    }
    return '';
  };

  getQuestions = (rank, dutyState) => {
    const { ratingExamQuestions = [], rate } = this.props;
    let questions = ratingExamQuestions.find(
      a => a.rank === rank && a.dutyState === dutyState && a.rate === rate.id,
    );
    if (questions) {
      return (
        <span style={{ float: 'right' }}>
          {questions.dutyState === 'Substitute' &&
          questions.linkedRatingExam ? (
            <i className="fa fa-link bibliography-linked" />
          ) : questions.dutyState === 'Reserve' &&
            questions.linkedRatingExam ? (
            <i className="fa fa-link bibliography-linked" />
          ) : (
            ''
          )}
          <b>{questions.percentage}</b> {questions.questions}
        </span>
      );
    }
    return (
      <span style={{ float: 'right' }}>
        <b>0%</b> 0 of 0
      </span>
    );
  };

  shouldGetRatingExamQuestions = expanded => {
    const { ratingExamQuestions = [], rate, onRateUpdate } = this.props;
    let questions = ratingExamQuestions.find(a => a.rate === rate.id);
    if (!questions && expanded) {
      onRateUpdate(rate, this.props.rank, this.props.dutyState);
    }
  };

  getDisableButton = (rank, dutyState) => {
    const { ratingExamQuestions = [], rate } = this.props;
    let questions = ratingExamQuestions.find(
      a => a.rank === rank && a.dutyState === dutyState && a.rate === rate.id,
    );
    let notClick = false;
    if (questions) {
      if (
        (questions.dutyState === 'Substitute' ||
          questions.dutyState === 'Reserve') &&
        questions.linkedRatingExam
      ) {
        notClick = true;
        return notClick;
      }
    }
    return notClick;
  };

  getRow = currentRank => {
    const { rate } = this.props;
    return (
      <div>
        <Row className={this.getRowClass(currentRank, dutyState[0])}>
          <Col md={4} className="centerText">
            {rate.shortname + this.getRatingExamNumber(currentRank)}
          </Col>
          <Col
            md={8}
            className="pointer"
            onClick={() => {
              this.filterClick(currentRank, dutyState[0]);
            }}
          >
            {dutyState[0]}
            {this.getQuestions(currentRank, dutyState[0])}
          </Col>
        </Row>
        <Row className={this.getRowClass(currentRank, dutyState[1])}>
          <Col md={4} />
          <Col
            md={8}
            className={
              !this.getDisableButton(currentRank, dutyState[1]) ? 'pointer' : ''
            }
            onClick={() => {
              if (this.getDisableButton(currentRank, dutyState[1]) === false) {
                this.filterClick(currentRank, dutyState[1]);
              }
            }}
          >
            {dutyState[1]}
            {this.getQuestions(currentRank, dutyState[1])}
          </Col>
        </Row>
        <Row className={this.getRowClass(currentRank, dutyState[2])}>
          <Col md={4} />
          <Col
            md={8}
            className="pointer"
            onClick={() => {
              if (this.getDisableButton(currentRank, dutyState[2]) === false) {
                this.filterClick(currentRank, dutyState[2]);
              }
            }}
          >
            {dutyState[2]}
            {this.getQuestions(currentRank, dutyState[2])}
          </Col>
        </Row>
      </div>
    );
  };

  getExpandedContent = () => {
    return (
      <div className="expandedContentContainer">
        {this.getRow(ranks[0])}
        {this.getRow(ranks[1])}
        {this.getRow(ranks[2])}
        {this.getRow(ranks[3])}
      </div>
    );
  };

  getExpandedContentOld() {
    return (
      <div style={{ width: '50%', margin: '0 auto', paddingTop: '10px' }}>
        {ranks.map(r => (
          <Row>
            <Col md={6}>{r}</Col>
            <Col md={6}>
              {dutyState.map(d => (
                <p>{d}</p>
              ))}
            </Col>
          </Row>
        ))}
      </div>
    );
  }

  renderExpandIcon() {
    return (
      <div className="expandIconContainer">
        <i
          className={
            this.state.expanded
              ? 'fa fa-caret-down marginRight14'
              : 'fa fa-caret-right marginRight18'
          }
          aria-hidden="true"
        />
      </div>
    );
  }
  render() {
    const { rate } = this.props;
    const { expanded } = this.state;
    return (
      <div className="Group">
        <div
          className="GroupRowHover"
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => {
            this.shouldGetRatingExamQuestions(!expanded);
            this.setState({ expanded: !expanded });
          }}
        >
          {this.renderExpandIcon()}
          <div className="RateName">
            <span className="rateNameLabel">
              {rate.shortname
                ? '(' + rate.shortname + ') ' + rate.name
                : rate.name}
            </span>
            <div className="percentage">
              <span>
                <b>{rate.percentage}</b> {rate.questions}
              </span>
            </div>
          </div>
        </div>
        {expanded && this.getExpandedContent()}
      </div>
    );
  }
}
