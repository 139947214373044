import { NAME } from './constants';

export const ADMINS_GET_SAGAS = `${NAME}-sagas/ADMINSGETSAGAS`;
export const ROLES_GET_SAGAS = `${NAME}-sagas/ROLESGETSAGAS`;
export const CHANGE_MAIN_ROLE_SAGAS = `${NAME}-sagas/CHANGEMAINROLESAGAS`;
export const DELETE_ADMIN_SAGAS = `${NAME}-sagas/DELETE_ADMIN_SAGAS`;
export const TOGGLE_ACTIVE_SAGAS = `${NAME}-sagas/TOGGLE_ACTIVE_SAGAS`;
export const RESET_PASSWORD_ADMIN_SAGAS = `${NAME}-sagas/RESET_PASSWORD_ADMIN_SAGAS`;
export const CREATE_ADMIN_USER_SAGAS = `${NAME}-sagas/CREATE_ADMIN_USER_SAGAS`;
export const TOGGLE_ADMIN_ROLE_SAGAS = `${NAME}-sagas/TOGGLE_ADMIN_ROLE_SAGAS`;
export const UPDATE_ADMIN_SAGAS = `${NAME}-sagas/UPDATE_ADMIN_SAGAS`;
export const UPDATE_ADMIN_FILES_SAGAS = `${NAME}-sagas/UPDATE_ADMIN_FILES_SAGAS`;
export const GET_ADMIN_HISTORY_SAGAS = `${NAME}-sagas/GET_ADMIN_HISTORY_SAGAS`;
export const GET_ADMIN_ROLES_SAGAS = `${NAME}-sagas/GET_ADMIN_ROLES_SAGAS`;
export const LOGIN_AS_ADMIN = `${NAME}-sagas/LOGIN_AS_ADMIN`;
export const UPDATE_ADMIN_GROUP_SAGA = `${NAME}-sagas/UPDATE_ADMIN_GROUP_SAGA`;
export const FETCH_ADMIN_LOGS_SAGA = `${NAME}-sagas/FETCH_ADMIN_LOGS_SAGA`;
export const RESET_PASSWORD = `${NAME}-sagas/RESET_PASSWORD`;
export const UPDATE_PHONE_NUMBER_SAGAS = `${NAME}-sagas/UPDATE_PHONE_NUMBER_SAGAS`;
export const GET_ACTIVITY = `${NAME}-sagas/GET_ACTIVITY`;
export const UPDATE_SUPER_ADMIN = `${NAME}-sagas/UPDATE_SUPER_ADMIN`;
export const FORCE_PASSWORD_CHANGE = `${NAME}-sagas/FORCE_PASSWORD_CHANGE`;
