import { connect } from 'react-redux';
import Permissions from './Permissions';
import {
  getAllRoles,
  getPages,
  getSelectedRole,
  getRolePagination,
  isCreating,
  isUpdating,
  isDeleting,
  getRoleName,
  getChecks,
  getNewRoleMode,
} from '../../Roles/selectors';
import { getUser, getGeneralRolesPermissions } from '../../Login/selectors';
import {
  getPagesActionSagas,
  createRoleActionSagas,
  getPageRolesActionSagas,
  editRoleActionSagas,
  deleteRoleActionSagas,
} from '../../Roles/actionsSagas';
import addAbilityToOpenModal from '../../../utilities/modalHOC';
import {
  setSelectedRole,
  updateChecks,
  updateRoleName,
  toggleNewRole,
} from '../../Roles/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    roles: getAllRoles(state),
    pages: getPages(state),
    selectedRole: getSelectedRole(state),
    pagination: getRolePagination(state),
    creating: isCreating(state),
    updating: isUpdating(state),
    deleting: isDeleting(state),
    roleName: getRoleName(state),
    checks: getChecks(state),
    newRoleMode: getNewRoleMode(state),
    user: getUser(state),
    generalPermissions: getGeneralRolesPermissions(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleNewRole: role => dispatch(toggleNewRole(role)),
    onLoad: page => {
      dispatch(getPagesActionSagas());
      dispatch(getPageRolesActionSagas(page));
    },
    onSelect: role => {
      dispatch(setSelectedRole(role));
    },
    createRole: role => dispatch(createRoleActionSagas(role)),
    updateRole: role => dispatch(editRoleActionSagas(role)),
    deleteRole: role => dispatch(deleteRoleActionSagas(role)),
    setSelectedRole: role => dispatch(setSelectedRole(role)),
    updateRoleName: name => dispatch(updateRoleName(name)),
    updateChecks: checks => dispatch(updateChecks(checks)),
  };
};

const PermissionsContainer = connect(mapStateToProps, mapDispatchToProps)(
  Permissions
);

export default addAbilityToOpenModal(PermissionsContainer);
