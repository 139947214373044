import './EditQuestionModal.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';

import { AI_TASK_STATUS } from '../../../components/Questions/commonComponents/Header/constants/constants';
import UploadInput from '../../../components/Questions/commonComponents/UploadInput/UploadInput';
import Spinner from '../../Spinner/Spinner';
import WYSIWYG from '../../WYSIWYG/WYSIWYG';

class EditQuestionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textareaValue: '',
      order: '',
      documentPage: '',
      softwarePage: '',
      presentationIndex: 0,
      substantialChangesQuestion: false,
      substantialChangesAnswer: false,
      isCreatingAiQuestion: this.props.isCreatingAiQuestion,
      customPrompt: '',
      addCustom: false,
      customText: '',
      userCustomPrompts: this.props.userCustomPrompts,
      value: false,
      errorCustom: false,
      customPromptSelect: '',
      createCustom: true,
      question: this.props.question,
      textInitial: this.props.question.text,
      loadSpinner: false,
    };
  }

  componentWillMount() {
    const { item, isAnswer, isQuestion, multipleOption, question } = this.props;
    let initialState;
    if (isAnswer) {
      initialState = {
        textareaValue: item.text,
        order: item.order,
      };

      if (item.correct) {
        this.setState({ substantialChangesAnswer: item.substantialChanges });
      }
    }
    if (isQuestion) {
      initialState = {
        textareaValue: multipleOption ? item.text : item.flashQuestionText,
      };

      this.setState({
        substantialChangesQuestion: question.substantialChanges,
      });
    }
    if (!isQuestion && !isAnswer) {
      initialState = {
        textareaValue: question.notes,
        documentPage: question.documentPage,
        softwarePage: question.softwarePage,
      };
    }
    initialState.presentationIndex = question.presentationIndex;
    this.setState(initialState);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isQuestion) {
      const modal = document.querySelector('.modal-content');
      if (this.state.isCreatingAiQuestion) {
        modal.classList.add('change-background');
      } else {
        modal.classList.remove('change-background');
      }

      if (
        (this.props.userCustomPrompts && !this.state.value) ||
        prevProps.question !== this.props.question
      ) {
        // Check for userCustomPrompts. If create, keep last prompt; if editquestion, keep the created custom.
        let customSelect = this.state.userCustomPrompts[0];
        let textCustom = this.state.customText;
        let addCustom = this.state.addCustom;
        let create = this.state.createCustom;
        if (this.props.question) {
          if (
            this.props.question &&
            this.props.question.originalResponse &&
            !this.props.question.originalResponse.adminUser
          ) {
            this.setState({
              isCreatingAiQuestion: false,
            });
            this.props.setIsCreatingAiQuestion(false);
          }
          if (
            this.props.userCustomPrompts &&
            this.props.userCustomPrompts.length
          ) {
            this.props.userCustomPrompts.forEach((custom) => {
              if (this.props.question.originalResponse) {
                if (
                  custom.id === this.props.question.originalResponse.ai_prompt
                ) {
                  customSelect = custom;
                  textCustom = custom.prompt.replace('USER_PROMPT ', '');
                  addCustom = true;
                  create = false;
                }
              }
            });
          }
        }

        this.setState({
          value: true,
          customText: textCustom,
          customPromptSelect: customSelect,
          addCustom,
          createCustom: create,
        });
      }
    }
  }

  _handleKeyDown(event) {
    if (event.ctrlKey && event.key === 'Enter') {
      this.submitAndClose();
    }
  }

  componentDidMount() {
    if (this.nameInput) this.nameInput.focus();

    if (this.props.question && this.props.question.wasAIGenerated) {
      this.setState({ isCreatingAiQuestion: false });
      this.props.setIsCreatingAiQuestion(false);
    }
    // this.addEventListener();
  }

  optionIsSelected = (option) =>
    !!this.props.question.answers.find((answer) => answer.order === option);

  renderAnswerOptions = (answer) => {
    const { order } = this.state;
    return (
      <div>
        <select
          className="orderSelect BorderTextArea form-control"
          value={order}
          onChange={(event) => {
            const order = Number(event.target.value);
            this.setState({ order });
          }}
        >
          <option value={0}>Random</option>
          <option value={1} disabled={this.optionIsSelected(1)}>
            1
          </option>
          <option value={2} disabled={this.optionIsSelected(2)}>
            2
          </option>
          <option value={3} disabled={this.optionIsSelected(3)}>
            3
          </option>
          <option value={4} disabled={this.optionIsSelected(4)}>
            4
          </option>
        </select>
      </div>
    );
  };

  renderTextArea = (item) => {
    const { textareaValue } = this.state;
    return (
      <div
        className={
          this.props.isQuestion
            ? 'textAreaContainer pTop20'
            : 'textAreaContainer'
        }
      >
        <textarea
          ref={(input) => {
            this.nameInput = input;
          }}
          onChange={(event) =>
            this.setState({ textareaValue: event.target.value })
          }
          onKeyDown={(event) => {
            if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
              this.submit();
              event.preventDefault();
            }
          }}
          name="text"
          type="text"
          value={textareaValue}
          className={
            this.state.redBorder
              ? 'QuestionTextArea BorderTextArea textAreaContent form-control redBorder'
              : 'QuestionTextArea BorderTextArea textAreaContent form-control'
          }
        />
      </div>
    );
  };

  onChangeEditorAnswer = (value) => {
    this.setState({ textareaValue: value });
  };

  renderAnswerArea = (item) => (
    <div className="editorAnswerWrapper">
      <WYSIWYG
        isInput
        onChange={this.onChangeEditorAnswer}
        redBorder={this.state.redBorder}
        height={400}
        value={this.state.textareaValue}
        updateEditorView={false}
        focus
        submit={this.submit}
        modalSize
        showWarning={this.props.showWarning}
      />
    </div>
  );

  renderInput = (item, title) => {
    const { presentationIndex } = this.state;
    const valueInput = this.props.isPresentationIndex
      ? presentationIndex
      : title === 'Document page'
        ? this.state.documentPage
        : this.state.softwarePage;
    return (
      <div className="textAreaContainer">
        <textarea
          ref={(input) => {
            this.nameInput = input;
          }}
          onChange={(event) =>
            this.changePresentationIndexOrDocumentPage(
              event.target.value,
              title,
            )
          }
          name="text"
          type="text"
          value={valueInput}
          className={
            this.state.redBorder
              ? 'QuestionTextArea BorderTextArea form-control redBorder'
              : 'QuestionTextArea BorderTextArea form-control'
          }
        />
      </div>
    );
  };

  changePresentationIndexOrDocumentPage = (value, title) => {
    if (this.props.isPresentationIndex) {
      this.setState({ presentationIndex: value });
    } else if (title === 'Document page') {
      this.setState({ documentPage: value });
    } else if (title === 'Software page') {
      this.setState({ softwarePage: value });
    }
  };

  onChangeEditor = (value) => {
    this.setState({ textareaValue: value });
  };

  renderEditor = (item) => (
    <div className="editorWrapper">
      <WYSIWYG
        onChange={this.onChangeEditor}
        height={400}
        redBorder={this.state.redBorder}
        value={this.state.textareaValue}
        disabled={false}
        updateEditorView={false}
        focus
        submit={this.submit}
        modalSize
        showWarning={this.props.showWarning}
      />
      {this.state.redBorder && this.state.textareaValue.length === 0 && (
        <span className="redTextQuestion">You must fill in the question.</span>
      )}
      {this.state.redBorder && this.state.textareaValue.length > 1000 && (
        <span className="redTextQuestion">
          Question should not exceed 1000 characters.
        </span>
      )}
    </div>
  );

  validate = () => {
    const stateToSet = { redBorder: false };
    if (
      !this.props.isAnswer &&
      !this.state.textareaValue &&
      !this.props.isPresentationIndex &&
      !this.props.isFurtherReading &&
      this.props.title !== 'Document page' &&
      this.props.title !== 'Software page'
    ) {
      stateToSet.redBorder = true;
      this.setState(stateToSet);
      return false;
    }
    if (!this.state.presentationIndex && this.props.isPresentationIndex) {
      stateToSet.redBorder = true;
      this.setState(stateToSet);
      return false;
    }
    if (this.state.textareaValue.length > 1000) {
      stateToSet.redBorder = true;
      this.setState(stateToSet);
      return false;
    }

    if (
      this.state.isCreatingAiQuestion &&
      !this.state.customText &&
      !this.state.customPromptSelect
    ) {
      this.setState({ errorCustom: true });
      return false;
    }
    this.setState(stateToSet);
    this.setState({ errorCustom: false });
    return true;
  };

  getQuestionUpdated = () => {
    const questionUpdated = this.props.question;
    if (this.props.multipleOption) {
      questionUpdated.text = this.state.textareaValue;
    } else {
      questionUpdated.flashQuestionText = this.state.textareaValue;
    }
    if (this.state.question && this.state.question.fileImageUrl) {
      questionUpdated.fileImageUrl = this.state.question.fileImageUrl;
      questionUpdated.imageUrl = this.state.question.imageUrl;
    }
    return questionUpdated;
  };

  getPresentationIndexUpdated = () => ({
    chapter: this.props.question.chapter,
    id: this.props.question.id,
    presentationIndex: this.state.presentationIndex,
  });

  getNotesUpdated = () => {
    const questionUpdated = this.props.question;
    questionUpdated.documentPage = this.state.documentPage;
    questionUpdated.softwarePage = this.state.softwarePage;
    questionUpdated.notes = this.state.textareaValue;
    questionUpdated.flashQuestionNotes = this.state.textareaValue;
    return questionUpdated;
  };

  countNotEmpty = (answers) => {
    let count = 0;
    answers.forEach((a) => {
      if (a.text !== '') count++;
    });
    return count;
  };

  submit = () => {
    if (!this.validate() && this.state.textareaValue.length > 1000) {
      <span className="redTextQuestion">
        Question should not exceed 1000 characters.
      </span>;
      return;
    }
    let canSend = true;
    if (this.props.item.correct && this.props.item.correct === true) {
      canSend = this.state.textareaValue !== '';
    } else {
      const count = this.countNotEmpty(this.props.question.answers);
      if (this.props.question.answers.length >= 4 && count >= 2) {
        canSend = true;
      } else {
        canSend =
          this.state.textareaValue !== '' ||
          this.state.documentPage !== '' ||
          this.state.softwarePage !== '';
      }
    }
    let presentIndex = false;
    const pi = this.props.isPresentationIndex
      ? this.getPresentationIndexUpdated()
      : null;
    presentIndex = !!pi;

    if (canSend || presentIndex || this.props.isFurtherReading) {
      const questionUpdated = this.props.isAnswer
        ? this.prepareAnswerForSubmit()
        : this.props.isQuestion
          ? this.getQuestionUpdated()
          : this.getNotesUpdated();
      this.setState({ hasError: false });
      questionUpdated.presentationIndex = pi
        ? pi.presentationIndex
        : questionUpdated.presentationIndex;
      if (questionUpdated.wasAIGenerated && !this.props.isAnswer)
        questionUpdated.substantialChanges =
          this.state.substantialChangesQuestion;
      if (questionUpdated.answers) {
        questionUpdated.answers.map((a) => {
          if (a && a.correct)
            if (this.props.item && this.props.item.wasAIGenerated) {
              a.substantialChanges = this.state.substantialChangesAnswer;
            }
        });
      }

      if (questionUpdated.aiquestion) {
        questionUpdated.aiquestion = JSON.stringify(questionUpdated.aiquestion);
      }

      if (this.state.customPromptSelect && this.state.customPromptSelect.id) {
        questionUpdated.customPromptId = this.state.customPromptSelect.id;
      } else {
        questionUpdated.customPromptId = this.state.customPromptSelect;
      }

      questionUpdated.isCreatingAiQuestion = this.state.isCreatingAiQuestion;
      this.props.onSubmit(
        questionUpdated,
        this.props.assignment,
        this.props.isPresentationIndex,
      );
      this.props.closeModal();
    } else {
      this.setState({ hasError: true });
    }
  };

  isValidString = (text) => text && text !== '' && text.trim() !== '';

  validateCorrect = (question) => {
    let canSend = true;
    const correctAnswer = question.answers.filter((a) => a.correct)[0];
    const incorrectAnswers = question.answers.filter(
      (a) => !a.correct && a.text !== '',
    );
    incorrectAnswers.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
    if (question.flashMode) {
      canSend = !!(correctAnswer && this.isValidString(correctAnswer.text));
      return canSend;
    }
    canSend = !!(
      correctAnswer &&
      this.isValidString(correctAnswer.text) &&
      incorrectAnswers.length >= 1
    );
    return canSend;
  };

  prepareAnswerForSubmit = () => {
    const beforeUpdate = this.props.question.answers;
    const newAnswer = {
      id: this.props.item.id,
      text: this.state.textareaValue,
      order: this.state.order,
      correct: this.props.item.correct,
    };
    const oldAnswer = this.props.question.answers.find(
      (answer) => answer.id === newAnswer.id,
    );
    let questionUpdated = this.props.question;
    if (oldAnswer && oldAnswer.order !== newAnswer.order) {
      questionUpdated = this.onChangeOrder(
        newAnswer.order,
        questionUpdated,
        oldAnswer.order,
        newAnswer.id,
      );
    }
    questionUpdated.answers = questionUpdated.answers.map((answer) => {
      if (answer.id === newAnswer.id) return newAnswer;
      return answer;
    });
    const editingCorrect = !!newAnswer.correct;
    if (this.validateCorrect(questionUpdated)) {
      questionUpdated.hasError = false;
    } else if (editingCorrect) {
      questionUpdated.hasError = false;
    } else {
      questionUpdated.answers = beforeUpdate;
      questionUpdated.hasError = true;
    }
    if (!questionUpdated.id) questionUpdated.id = this.props.question.id;
    return questionUpdated;
  };

  renderButtons = () => (
    <div className="buttonsContainer">
      <Button
        className="btn btn-success"
        onClick={() => this.submit()}
        loading={this.props.loading}
      >
        Save
      </Button>
      <Button
        className="btn btn-secondary"
        onClick={() => this.props.closeModal()}
      >
        Cancel
      </Button>
      <span className="helper-text">Ctrl or cmd + enter to save</span>
    </div>
  );

  onChangeOrder = (newOrder, question, oldOrder, answerId) => {
    newOrder = Number(newOrder);
    if (newOrder !== 0 && oldOrder !== 0) {
      let increment = false;
      if (newOrder > oldOrder) {
        increment = true;
      }
      const answers = question.answers.map((answer) => {
        if (answer.id === answerId) answer.order = newOrder;
        return answer;
      });
      question.answers = answers.map((answer) => {
        if (
          increment &&
          answer.order <= newOrder &&
          answer.order > oldOrder &&
          answerId !== answer.id &&
          answer.order !== 0
        ) {
          answer.order -= 1;
        }
        if (
          !increment &&
          answer.order >= newOrder &&
          answer.order < oldOrder &&
          answer.id !== answerId &&
          answer.order !== 0
        ) {
          answer.order += 1;
        }
        return answer;
      });
    } else {
      question.answers = question.answers.map((answer) => {
        if (answer.id === answerId) {
          answer.order = newOrder;
        }
        return answer;
      });
    }
    return question;
  };

  renderFurtherReadingOptions = (item) => (
    <div className="pagesContainer">
      <label className="pageLabel">Display page:</label>
      <input
        min="0"
        type="text"
        className="pages pagesOne BorderTextArea"
        onChange={(e) => this.setState({ documentPage: e.target.value })}
        value={this.state.documentPage}
      />
      <label className="pageLabel">Software page:</label>
      <input
        min="0"
        type="number"
        className="pages BorderTextArea"
        onChange={(e) => this.setState({ softwarePage: e.target.value })}
        value={this.state.softwarePage}
      />
    </div>
  );

  onSelect = (event) => {
    let selectPrompt;
    const { userCustomPrompts } = this.state;
    const selectedIndex = userCustomPrompts.findIndex(
      (item) => item.prompt.replace('USER_PROMPT ', '') === event,
    );

    userCustomPrompts.forEach((item) => {
      if (item.prompt.replace('USER_PROMPT ', '') === event) {
        selectPrompt = item;
      }
    });

    // Here the selected item is moved to the first position
    if (selectedIndex > 0) {
      const selectedPrompt = userCustomPrompts.splice(selectedIndex, 1);
      userCustomPrompts.unshift(selectedPrompt[0]);
    }
    this.setState({
      customText: event,
      addCustom: !this.state.addCustom,
      customPromptSelect: selectPrompt,
      errorCustom: false,
      userCustomPrompts,
      createCustom: false,
    });
  };

  handleCreateUserCustomPrompt() {
    if (!this.state.customText) {
      this.setState({
        errorCustom: true,
      });
    } else {
      const { userCustomPrompts } = this.state;
      const newArray = [
        { prompt: this.state.customText },
        ...userCustomPrompts,
      ];
      this.setState({
        userCustomPrompts: newArray,
        customPromptSelect: this.state.customText,
        createCustom: false,
        addCustom: false,
      });
    }

    this.props.createUserCustomPrompt(
      this.state.customText,
      this.props.chapterId,
    );
  }

  handleChangeCustom() {
    if (!this.state.addCustom) {
      this.setState({ customText: '' });
    }
    this.setState({ addCustom: !this.state.addCustom });
  }

  handlePrompt(event) {
    let existPrompt = true;

    this.state.userCustomPrompts.forEach((item) => {
      if (item.prompt === event) {
        existPrompt = false;
      }
    });

    this.setState({ customText: event, createCustom: existPrompt });
  }

  showSuggestionValidation() {
    const question = this.props.question;
    if (!question?.text || !question?.aiSuggestion) {
      return false;
    }
    return true;
  }

  removeCharacters(text) {
    return text.replace(/<\/?p>/g, '');
  }

  render() {
    const {
      title,
      isAnswer,
      textarea,
      item,
      isQuestion,
      isPresentationIndex,
      isEditingPage,
    } = this.props;

    const suggestionInProgress =
      this.props.question.aiSuggestionStatus === AI_TASK_STATUS.IN_PROGRESS;

    const isButtonDisabled =
      !this.props.question.id ||
      this.removeCharacters(this.state.textInitial).trim() !==
        this.removeCharacters(this.state.textareaValue).trim() ||
      suggestionInProgress;
    return (
      <div className="edit-question-modal">
        <div>
          {isQuestion && (
            <div>
              {item &&
                (!item.text ||
                  (item.originalResponse &&
                    item.originalResponse.adminUser)) && (
                  <div className="mt-3">
                    <span>
                      {item && item.text
                        ? 'Edit AI question'
                        : 'Create AI question'}
                    </span>
                    <label
                      className="switch switch-3d switch-primary ml-2 mt-1"
                      style={{ width: 25, height: 15 }}
                    >
                      <input
                        type="checkbox"
                        className="switch-input"
                        checked={this.state.isCreatingAiQuestion}
                        onChange={() => {
                          this.setState({
                            isCreatingAiQuestion:
                              !this.state.isCreatingAiQuestion,
                          });
                          this.props.setIsCreatingAiQuestion(
                            !this.state.isCreatingAiQuestion,
                          );
                        }}
                      />
                      <span className="switch-label" />
                      <span
                        className="switch-handle"
                        style={{ height: 15, width: 15 }}
                      />
                    </label>
                  </div>
                )}
              <Row>
                <Col xs={3} md={5}>
                  <div className="editionTitle">{title}</div>
                </Col>
                <Col
                  xs={5}
                  md={7}
                  className="pb-1 pt-1"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                  }}
                >
                  {
                    /* In the grid view, only include the "improve question with AI" and 
                      "include further reading in ai suggestion" options when the question has already been created */
                    this.props.question.id && (
                      <>
                        <div
                          className={`more-options-btn  ${isButtonDisabled ? 'disabledButton' : 'suggestion-btn'}`}
                          onClick={() => {
                            if (
                              !isButtonDisabled &&
                              this.props.question.text !== ''
                            ) {
                              this.setState({ loadSpinner: true });
                              this.props.improveQuestionWithAi(
                                this.props.question.id,
                                this.props.question.chapter.id,
                              );
                              this.spinnerTimeout = setTimeout(() => {
                                this.setState({ loadSpinner: false });
                              }, 300);
                            }
                          }}
                          title={
                            !isButtonDisabled
                              ? 'Improve question with AI'
                              : suggestionInProgress
                                ? 'Ai suggestion in progress'
                                : 'Unsaved changes'
                          }
                        >
                          <i className="fa fa-lightbulb-o" />
                        </div>
                        <div
                          title="Select to include further reading in the context of suggestion"
                          className="include-further-reading-checkbox"
                        >
                          <label style={{ fontSize: '0.8rem' }}>
                            <input
                              onClick={() => {
                                this.props.updateIncludeNotesInSuggestion(
                                  this.props.question.id,
                                  !this.props.question.includeNotesInSuggestion,
                                );
                              }}
                              type="checkbox"
                              checked={
                                this.props.question.includeNotesInSuggestion
                              }
                            />
                            Include further reading in ai suggestion
                          </label>
                        </div>
                      </>
                    )
                  }
                </Col>
              </Row>
            </div>
          )}
          {!isQuestion && <div className="editionTitle">{title}</div>}
          {isAnswer && this.renderAnswerOptions(item)}
          {!isAnswer &&
            !isQuestion &&
            !isPresentationIndex &&
            !isEditingPage &&
            this.renderFurtherReadingOptions(item)}
        </div>
        {textarea &&
          !isPresentationIndex &&
          !isEditingPage &&
          this.renderAnswerArea(item)}
        {!textarea &&
          !isPresentationIndex &&
          !isEditingPage &&
          this.renderEditor(item)}
        {isPresentationIndex && this.renderInput(item)}
        {isEditingPage && this.renderInput(item, title)}
        {this.state.hasError && (
          <p style={{ color: 'red' }}>This answer can't be deleted</p>
        )}
        {(isQuestion || (isAnswer && item.correct)) && item.wasAIGenerated ? (
          <Row className="substantialChangesCheckbox mb-2">
            <input
              type="checkbox"
              htmlFor="substantialChanges"
              value={
                isQuestion
                  ? this.state.substantialChangesQuestion
                  : this.state.substantialChangesAnswer
              }
              onChange={(e) => {
                if (isQuestion)
                  this.setState({
                    substantialChangesQuestion:
                      !this.state.substantialChangesQuestion,
                  });
                if (isAnswer)
                  this.setState({
                    substantialChangesAnswer:
                      !this.state.substantialChangesAnswer,
                  });
              }}
              id={
                isQuestion
                  ? 'substantialCheckboxQuestion'
                  : 'substantialCheckboxAnswer'
              }
              checked={
                isQuestion
                  ? this.state.substantialChangesQuestion
                  : this.state.substantialChangesAnswer
              }
            />
            <label
              className="mb-0"
              htmlFor={
                isQuestion
                  ? 'substantialCheckboxQuestion'
                  : 'substantialCheckboxAnswer'
              }
            >
              {`Substantial Changes Made to AI Generated ${
                isQuestion ? 'Question' : 'Answer'
              }`}
            </label>
          </Row>
        ) : (
          ''
        )}
        {/* Ai suggestion  section */}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {(this.state.loadSpinner ||
            this.props.question?.aiSuggestionStatus ===
              AI_TASK_STATUS.IN_PROGRESS) && (
            <div
              style={{
                marginLeft: '30px',
              }}
            >
              <Spinner
                color="rgba(223,36,218,1)"
                style={{
                  padding: '10px 0 0 0',
                  textAlign: 'unset',
                }}
              />
            </div>
          )}
          {!this.state.loadSpinner &&
            this.showSuggestionValidation() &&
            isQuestion && (
              <Row className="aiSuggestion">
                <i className="fa fa-lightbulb-o" />
                <span>{this.props.question.aiSuggestion}</span>
              </Row>
            )}
        </div>

        {isQuestion ? (
          <div>
            <div className="edit-question-grid-image">
              <UploadInput
                editPermission
                imageUrl={this.props.question.imageUrl}
                accept="image/x-png,image/gif,image/jpeg,image/jpg"
                chapter={{}}
                deletePdfChapter={(chapter) => {
                  const question = Object.assign(this.props.question);
                  question.imageUrl = null;
                  delete question.fileImageUrl;
                  this.setState({ question });
                }}
                updateQuestionPdfProcessing={() => {}}
                questionPdfProcessing={false}
                updateChapter={(chapter) => {
                  const question = Object.assign(this.props.question);
                  question.fileImageUrl = chapter.pdf;
                  question.imageUrl = chapter.pdf.name;
                  this.setState({ question });
                }}
                className="questionImageUrl"
              />
            </div>
          </div>
        ) : (
          ''
        )}
        {this.state.isCreatingAiQuestion && isQuestion && (
          <Col md={12} className="mt-3">
            <span>Custom Prompt</span>
            {this.state.errorCustom && (
              <span style={{ color: 'red' }} className="ml-1">
                required
              </span>
            )}

            <div className="mt-3">
              <div>
                {!this.state.addCustom ? (
                  <select
                    value={this.state.customPrompt}
                    className="form-control mb-2"
                    onChange={(e) => {
                      this.onSelect(e.target.value);
                    }}
                  >
                    {this.state.userCustomPrompts &&
                      this.state.userCustomPrompts.map((p) => (
                        <option value={p.prompt.replace('USER_PROMPT ', '')}>
                          {' '}
                          {p.prompt.replace('USER_PROMPT ', '').length > 70
                            ? `${p.prompt
                                .replace('USER_PROMPT ', '')
                                .substring(0, 70)}...`
                            : p.prompt.replace('USER_PROMPT ', '')}
                        </option>
                      ))}
                  </select>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <textarea
                      type="text"
                      value={this.state.customText}
                      disabled={false}
                      style={{
                        width: '100%',
                        border: this.state.errorCustom ? '1px solid red' : null,
                      }}
                      onChange={(e) => {
                        this.handlePrompt(e.target.value);
                      }}
                    />
                    {this.state.createCustom && (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="check">
                          <i
                            className="fa fa-check fa-lg"
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.handleCreateUserCustomPrompt()}
                          />
                        </div>
                        <div className="cross">
                          <i
                            className="fa fa-close fa-lg"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              this.setState({
                                addCustom: !this.state.addCustom,
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div>
                  <Button
                    className="btn btn-info mt-2 marginLeft"
                    onClick={() => this.handleChangeCustom()}
                  >
                    {' '}
                    {this.state.addCustom ? 'Select Custom' : 'Add Custom'}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        )}
        {this.renderButtons()}
      </div>
    );
  }
}

export default EditQuestionModal;
