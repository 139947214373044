// rootReducer.js
import { reducer as notifications } from 'react-notification-system-redux';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import RootReducer from '../containers/Full/reducer';
import admins from './Admins';
import assignments from './Assignments';
import associateRatesToReference from './AssociateRatesToReference';
import banners from './Banners';
import bibliographies from './Bibliographies';
import books from './Books';
import changeExam from './ChangeExam';
import changeHistory from './ChangeHistoryContainer';
import changePrimaryWriter from './ChangePrimaryWriter';
import coupons from './Coupons';
import CustomerSubmission from './CustomerSubmission';
import cycles from './Cycles';
import dailyQuiz from './DailyQuiz';
import definitions from './Definitions';
import groups from './Groups';
import login from './Login';
import messageCenter from './MessageCenter';
import newAdmin from './NewAdmin';
import practiceExams from './PracticeExams';
import prompts from './Prompts';
import quals from './Quals';
import questions from './Questions';
import rates from './Rates';
import references from './References';
import reports from './Reports/reducer';
import resetPassword from './ResetPassword';
import roles from './Roles';
import search from './Search';
import settings from './Settings';
import sidebar from './Sidebar';
import subtopics from './Subtopics';
import tenWeek from './TenWeek';
import UserReducer from './User/reducer';
import users from './Users';
import writers from './Writers';

const appReducer = combineReducers({
  notifications,
  form: formReducer,
  RootReducer,
  [login.constants.NAME]: login.reducer,
  UserReducer,
  [quals.constants.NAME]: quals.reducer,
  [coupons.constants.NAME]: coupons.reducer,
  [users.constants.NAME]: users.reducer,
  [admins.constants.NAME]: admins.reducer,
  [newAdmin.constants.NAME]: newAdmin.reducer,
  [resetPassword.constants.NAME]: resetPassword.reducer,
  [rates.constants.NAME]: rates.reducer,
  [groups.constants.NAME]: groups.reducer,
  [roles.constants.NAME]: roles.reducer,
  [bibliographies.constants.NAME]: bibliographies.reducer,
  [cycles.constants.NAME]: cycles.reducer,
  [references.constants.NAME]: references.reducer,
  [subtopics.constants.NAME]: subtopics.reducer,
  [search.constants.NAME]: search.reducer,
  [practiceExams.constants.NAME]: practiceExams.reducer,
  [definitions.constants.NAME]: definitions.reducer,
  [writers.constants.NAME]: writers.reducer,
  [sidebar.constants.NAME]: sidebar.reducer,
  [questions.constants.NAME]: questions.reducer,
  [assignments.constants.NAME]: assignments.reducer,
  [changeHistory.constants.NAME]: changeHistory.reducer,
  [changePrimaryWriter.constants.NAME]: changePrimaryWriter.reducer,
  [settings.constants.NAME]: settings.reducer,
  [changeExam.constants.NAME]: changeExam.reducer,
  [associateRatesToReference.constants.NAME]: associateRatesToReference.reducer,
  [books.constants.NAME]: books.reducer,
  [tenWeek.constants.NAME]: tenWeek.reducer,
  [messageCenter.constants.NAME]: messageCenter.reducer,
  [banners.constants.NAME]: banners.reducer,
  [prompts.constants.NAME]: prompts.reducer,
  [dailyQuiz.constants.NAME]: dailyQuiz.reducer,
  reports,
  [CustomerSubmission.constants.NAME]: CustomerSubmission.reducer,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = initialState;
  }

  return appReducer(state, action);
};

export default rootReducer;
