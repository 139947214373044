import Notifications from 'react-notification-system-redux';
import { browserHistory } from 'react-router';
import { all, call, cancelled, put, takeEvery } from 'redux-saga/effects';

import { newAdminUser } from '../../utilities/ServiceManager';
import { NEW_ADMIN_ERROR, NEW_ADMIN_SUCCESS } from './actionTypes';
import { NEW_ADMIN_SAGAS } from './actionTypesSagas';

function validate(payload) {
  if (
    !payload.name ||
    !payload.lastName ||
    !payload.email ||
    !payload.password ||
    !payload.confirmPassword
  ) {
    throw new Error('All fields are required');
  }
}

function* creationFlow(action) {
  let response;
  try {
    validate(action.payload);
    response = yield call(newAdminUser, action.payload);
    if (response) {
      yield put({ type: NEW_ADMIN_SUCCESS });
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message: 'User created successfully',
            autoDismiss: 1,
          },
          'success',
        ),
      );
    }
  } catch (message) {
    yield put({ type: NEW_ADMIN_ERROR, message });
  } finally {
    if (yield cancelled()) {
      browserHistory.replace('/');
    }
  }

  return response;
}

export function* newAdminWatcher() {
  yield takeEvery(NEW_ADMIN_SAGAS, creationFlow);
}

export default function* sagas() {
  yield all([newAdminWatcher()]);
}
