// Insights/selectors.js
import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'roles');
}

export const getAllRoles = state =>
  getState(state)
    .get('roles')
    .toArray();

export const getPages = state =>
  getState(state)
    .get('pages')
    .toArray();
export const getRolePagination = state => getState(state).get('pagePagination');

export const getWorkFLowActions = state =>
  getState(state)
    .get('workflowActions')
    .toArray();

export const getWaiting = state => getState(state).get('waiting');
export const isCreating = state => getState(state).get('creating');
export const isUpdating = state => getState(state).get('updating');
export const isDeleting = state => getState(state).get('deleting');
export const getSelectedRole = state => getState(state).get('selectedRole');
export const getRoleName = state => getState(state).get('roleName');
export const getChecks = state => getState(state).get('checks');
export const getNewRoleMode = state => getState(state).get('newRoleMode');
export const getIsLoading = state => getState(state).get('isLoading');
