import { Button, Modal } from 'react-bootstrap';

export default function DeleteAIQuestionNotificationModal({
  isOpen,
  toggle,
  aiTaskId,
  handleCloseNotification,
  deleteNotification,
}) {
  const handleDeleteNotification = () => {
    deleteNotification(aiTaskId);
    handleCloseNotification();
    toggle();
  };

  return (
    <Modal show={isOpen} onHide={toggle}>
      <Modal.Header>
        <Modal.Title>Delete AI Question notification</Modal.Title>
        <Button onClick={toggle}>
          <i className="fa fa-times" onClick={toggle} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        Are you sure that you want to delete this notification?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleDeleteNotification}>
          Delete
        </Button>
        <Button variant="secondary" onClick={toggle}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
