import React from 'react';
import PropTypes from 'prop-types';
import { SimpleDatePicker } from '../../../../../commonComponents/Datepicker/Datepicker';
import './AssignWriterModal.css';
import Select2 from 'react-select2-wrapper';
import Spinner from '../../../../../commonComponents/Spinner/Spinner';

class AssignWriterModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dueDate: null,
      selectedRateId: 0,
      selectedBookId: 0,
      selectedBookObject: {},
      selectedChapterId: 0,
      selectedChapterObject: {},
      primaryWriter: 0,
      possibleSecondaryWriters: [],
      secondaryWriters: [],
      showChapters: true,
    };
  }

  componentWillMount = () => {
    this.props.loadRatesForAssignModal();
  };

  isDisableBook = (book, assignments) => {
    const chapters = book.chapters;
    return chapters.every(
      elem => this.isAssignedChapter(elem, assignments).assigned,
    );
  };

  isAssignedChapter = (chapter, assignments) => {
    const indexAssigned = assignments.findIndex(assignment => {
      if (assignment.chapter && chapter) {
        return assignment.chapter.id === chapter.id;
      } else {
        return false;
      }
    });
    return indexAssigned !== -1
      ? { assigned: true, assignedTo: assignments[indexAssigned].assignedTo }
      : { assigned: false };
  };

  componentWillReceiveProps = props => {
    props.assignModalReferences.map(ref =>
      console.log('chapter !asp', ref.actAsParent ? 'ASP' : ref.chapters),
    );
  };

  getNameAssignedTo = assignedTo => {
    let res = ' (';
    assignedTo.forEach(writer => {
      res += writer.name + ' ' + writer.lastName + ' - ';
    });
    return res.slice(0, res.length - 3) + ')';
  };

  getRatesOptions = () => {
    const ratesPermission = this.props.rolesPermissions.map(permission => {
      if (permission.actions.find(action => action === 'Assignment.Assign')) {
        return Number(permission.typeId);
      }
      return -1;
    });

    const filteredRates = this.props.user.isSuperAdmin
      ? this.props.assignModalRates
      : this.props.assignModalRates.filter(
          rate => ratesPermission.indexOf(Number(rate.id)) !== -1,
        );
    const options = filteredRates.map(rate => (
      <option key={rate.id} id={rate.id} value={rate.id}>
        {rate.name
          ? '(' + rate.shortname + ') ' + rate.name
          : '(' + rate.shortname + ') ' + rate.description}
      </option>
    ));
    return options;
  };

  getBooksOptions = () => {
    const styles = {
      color: 'red',
    };
    const options = this.props.assignModalReferences.map(reference => {
      const assignmentsOfRate = this.props.assignments.filter(
        assignment =>
          assignment.studyProgram.typeId.id ===
          Number(this.state.selectedRateId),
      );
      const disabled = this.isDisableBook(reference, assignmentsOfRate);
      if (!reference.actAsParent) {
        if (disabled) {
          const assigned = this.isAssignedChapter(
            reference.chapters[0],
            assignmentsOfRate,
          );
          const name = assigned.assignedTo
            ? this.getNameAssignedTo(assigned.assignedTo)
            : '';
          const assignedName = disabled && !reference.actAsParent ? name : '';
          return (
            <option
              style={styles}
              key={reference.id}
              value={reference.id}
              disabled
            >
              {`* ${reference.name} +  ${assignedName}`}
            </option>
          );
        } else {
          return (
            <option
              key={reference.id}
              value={reference.id}
              id={reference.id}
              style={styles}
            >
              {`* ${reference.name} `}
            </option>
          );
        }
      } else {
        return (
          <option key={reference.id} id={reference.id} value={reference.id}>
            {reference.name}
          </option>
        );
      }
    });
    return options;
  };

  getChaptersOptions = () => {
    const styles = {
      color: 'red',
    };
    const assignmentsOfRate = this.props.assignments.filter(
      assignment =>
        assignment.studyProgram.typeId.id === Number(this.state.selectedRateId),
    );

    if (this.state.selectedBookObject.chapters) {
      const options = this.state.selectedBookObject.chapters.map(chapter => {
        const disabled = this.isAssignedChapter(chapter, assignmentsOfRate);

        if (disabled.assigned) {
          const nameAssigned = this.getNameAssignedTo(disabled.assignedTo);

          return (
            <option
              style={styles}
              key={chapter.id}
              value={chapter.id}
              id={chapter.id}
              disabled={true}
            >
              {`* ${chapter.name} ${nameAssigned}`}
            </option>
          );
        } else if (
          chapter.subtopicActiveCycleCount === 0 ||
          Number(chapter.subtopicCount) === 0 ||
          Number(chapter.questionCount) === 0
        ) {
          return (
            <option
              style={styles}
              key={chapter.id}
              value={chapter.id}
              id={chapter.id}
            >
              {` ${chapter.name} (${chapter.questionCount} questions, ${
                chapter.subtopicCount
              } subtopics) `}
            </option>
          );
        } else {
          return (
            <option
              key={chapter.id}
              value={chapter.id}
              id={chapter.id}
              disabled={false}
            >
              {` ${chapter.name} (${chapter.questionCount} questions, ${
                chapter.subtopicCount
              } subtopics) `}
            </option>
          );
        }
      });
      return options;
    }
  };

  handleRateChange = e => {
    this.setState({
      selectedRateId: e.target.value,
      selectedBookId: 0,
      selectedBookObject: {},
      selectedChapterId: 0,
      selectedChapterObject: {},
      possibleSecondaryWriters: this.props.writers,
    });

    let selectedRate = this.props.assignModalRates.find(
      rate => rate.id === Number(e.target.value),
    );
    if (selectedRate === undefined) {
      selectedRate = {};
    }
    this.props.setAssignModalSelectedRate(selectedRate);
    let type = null;
    if (selectedRate.id) {
      type = selectedRate.type;
    }
    if (type) {
      this.props.loadReferencesForAssignModal(type, selectedRate.id);
      this.props.getWriters(type, selectedRate.id);
    }
    if (e.target.value === -1) {
      this.setState({
        selectedBookId: 0,
        selectedBookObject: {},
        selectedChapterId: 0,
        selectedChapterObject: {},
      });
    }
  };

  handleBookChange = e => {
    this.setState({ selectedBookId: e.target.value });
    let selectedBook = this.props.assignModalReferences.find(
      reference => reference.id === Number(e.target.value),
    );
    if (selectedBook === undefined) {
      selectedBook = {};
    }
    this.setState({
      selectedBookObject: selectedBook,
      selectedChapterId: 0,
      selectedChapterObject: {},
    });
    if (selectedBook !== {} && !selectedBook.actAsParent) {
      this.setState({
        showChapters: false,
        selectedChapterId: selectedBook.chapters[0].id,
        selectedChapterObject: selectedBook.chapters[0],
      });
    } else {
      this.setState({
        showChapters: true,
        selectedChapterId: 0,
        selectedChapterObject: {},
      });
    }
  };

  handleChapterChange = e => {
    this.setState({ selectedChapterId: e.target.value });
    let selectedChapter = this.state.selectedBookObject.chapters.find(
      chapter => Number(chapter.id) === Number(e.target.value),
    );
    if (selectedChapter === undefined) {
      selectedChapter = {};
    }
    this.setState({ selectedChapterObject: selectedChapter });
  };

  handlePrimaryWriterChange = e => {
    const possibleSecondaryWriters = this.props.writers.filter(
      writer => Number(writer.id) !== Number(e.target.value),
    );
    this.setState({
      primaryWriter: e.target.value,
      possibleSecondaryWriters: possibleSecondaryWriters,
    });
  };

  selectSecondaryWriters = e => {
    const idToAdd = parseInt(e.params.data.id, 10);
    if (this.state.secondaryWriters.indexOf(idToAdd) < 0) {
      const newSecondaryWriters = this.state.secondaryWriters;
      newSecondaryWriters.push(idToAdd);
      this.setState({ secondaryWriters: newSecondaryWriters });
    }
  };

  unselectSecondaryWriters = e => {
    const idToRemove = parseInt(e.params.data.id, 10);
    const newSecondaryWriters = this.state.secondaryWriters.filter(
      writer => writer !== idToRemove,
    );
    this.setState({ secondaryWriters: newSecondaryWriters });
  };

  assignWriters = () => {
    const sp = this.props.assignModalRates.find(
      rate => Number(rate.id) === Number(this.state.selectedRateId),
    );

    this.props.createAssignment(
      Number(this.state.selectedChapterId),
      this.state.primaryWriter,
      this.state.secondaryWriters,
      this.state.dueDate ? this.state.dueDate.toJSON() : null,
      null,
      this.props.user.id,
      sp,
    );
  };

  render() {
    const disabledFields =
      Number(this.state.selectedRateId) <= 0 ||
      this.props.assignModalLoadingReferences;
    return (
      <div className="AssignWriterModal">
        <div className="AssignWriterModalTitle">
          <span className="title">{this.props.title}</span>
          <i
            className="fa fa-times fa-xs closeIcon"
            onClick={this.props.closeModal}
          />
        </div>
        <hr />
        <div className="AssignWriterModalBody">
          {/* FORM */}
          {this.props.assignModalWaiting && (
            <div className="assignWriterSpinnerContainer">
              <Spinner />
            </div>
          )}
          {!this.props.assignModalWaiting && (
            <div className="AssignWriterForm form-control">
              {/* RATE SELECTOR */}
              <div className="form-group">
                <label htmlFor="rate">Rate</label>
                <select
                  className="form-control"
                  id="rate"
                  onChange={this.handleRateChange}
                >
                  <option value={0}>Select a Rate</option>
                  {this.getRatesOptions()}
                </select>
              </div>
              {/* BOOK SELECTOR */}
              <div className="form-group">
                <label htmlFor="book">Book</label>
                <select
                  className="form-control"
                  id="book"
                  disabled={disabledFields}
                  value={Number(this.state.selectedBookId)}
                  onChange={this.handleBookChange}
                >
                  <option value={0}>Select a Book</option>
                  {this.getBooksOptions()}
                </select>
              </div>
              {/* CHAPTER SELECTOR */}
              {this.state.showChapters && (
                <div className="form-group">
                  <label htmlFor="chapter">Chapter</label>
                  <select
                    className="form-control"
                    id="chapter"
                    onChange={this.handleChapterChange}
                    value={Number(this.state.selectedChapterId)}
                    disabled={disabledFields}
                  >
                    <option value={0}>Select a Chapter</option>
                    {this.getChaptersOptions()}
                  </select>
                </div>
              )}
              {/* PR SELECTOR */}
              <div className="form-group">
                <label htmlFor="primaryWriter">Primary Writer</label>
                <select
                  className="form-control"
                  id="primaryWriter"
                  disabled={disabledFields}
                  value={this.state.primaryWriter}
                  onChange={this.handlePrimaryWriterChange}
                >
                  <option value={0} key={`writersForAssign_0`}>
                    Select a Writer
                  </option>
                  {this.state.selectedRateId !== 0 &&
                    this.props.writers.map((writer, index) => (
                      <option
                        key={`writersForAssign_${writer.id}_${index}`}
                        value={writer.id}
                      >
                        {`${writer.name} ${writer.lastName} (${
                          writer.assignments
                        }) `}
                      </option>
                    ))}
                </select>
              </div>
              {/* SR SELECTOR */}
              <div className="form-group">
                <span className="label" htmlFor="secondaryWriters">
                  Secondary writers:
                </span>
                <Select2
                  id="secondaryWriters"
                  value={this.state.secondaryWriters}
                  className="secondaryWritersSelector form-control"
                  multiple
                  data={this.state.possibleSecondaryWriters.map(writer => {
                    writer.text = writer.name + ' ' + writer.lastName;
                    return writer;
                  })}
                  options={{
                    placeholder: 'Search by name',
                  }}
                  onSelect={this.selectSecondaryWriters}
                  onUnselect={this.unselectSecondaryWriters}
                  disabled={disabledFields}
                />
              </div>
              {/* DUE DATE SELECTOR */}
              <div className="form-group">
                <label htmlFor="dueDate">Due date</label>
                <SimpleDatePicker
                  disabled={disabledFields}
                  date={this.state.dueDate}
                  onChange={dueDate => this.setState({ dueDate })}
                  placeholderText="MM/DD/YYYY"
                  className="form-control due-date-picker"
                />
              </div>
            </div>
          )}

          {/* BOTTOM BUTTONS */}
          <div className="AssignWriterButtons">
            <button className="btn btn-danger" onClick={this.props.closeModal}>
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={this.assignWriters}
              disabled={
                Number(this.state.primaryWriter) === 0 ||
                Number(this.state.selectedChapterId) === 0 ||
                Number(this.state.selectedRateId) === 0
              }
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

AssignWriterModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  assignModalRates: PropTypes.array.isRequired,
  assignModalReferences: PropTypes.array.isRequired,
};

export default AssignWriterModal;
