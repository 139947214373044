import React from 'react';
import Button from 'react-bootstrap-button-loader';
import { Row, Col } from 'react-bootstrap';
import { SimpleInput, SimpleTextarea } from '../../Input/Input';
import './CreateCoupon.css';
import { SimpleDatePicker } from '../../Datepicker/Datepicker';
import moment from 'moment';

export default class CreateCoupon extends React.Component {
  state = {
    description: this.props.coupon ? this.props.coupon.description : '',
    stripeCode: this.props.coupon ? this.props.coupon.stripeCode : '',
  };

  validate = () => {
    let errors = {};
    let fields = this.state;

    if (!fields.description || fields.description === '') {
      errors.description = 'This field is required';
    }

    if (!fields.stripeCode || fields.stripeCode === '') {
      errors.stripeCode = 'This field is required';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: null, waiting: true });
    if (this.props.coupon) {
      fields.id = this.props.coupon.id;
      delete fields.stripeCode;
    }
    this.props.onSubmit(fields);
  };

  getCuponInputs = coupon => {
    return (
      <div>
        <Row>
          <Col md={4}>
            <span className="InputLebel">Duration in months</span>
          </Col>
          <Col md={8}>
            <SimpleInput type="text" value={coupon.duration} disabled={true} />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <span className="InputLebel">Max redemptions</span>
          </Col>
          <Col md={8}>
            <SimpleInput
              type="text"
              value={coupon.maxRedeptions}
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <span className="InputLebel">
              {coupon.percentageOff ? 'Percent off' : 'Amount off'}
            </span>
          </Col>
          <Col md={8}>
            <SimpleInput
              type="text"
              value={
                coupon.percentageOff ? (
                  coupon.percentageOff + '%'
                ) : (
                  coupon.amountOff
                )
              }
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <span className="InputLebel">Redeem by</span>
          </Col>
          <Col md={8}>
            <SimpleDatePicker
              date={coupon.expiryAt ? moment(Number(coupon.expiryAt)) : null}
              disabled={true}
              placeholderText="MM/DD/YYYY"
            />
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const { onCancel, title, coupon } = this.props;
    return (
      <div className="create-code-group-component">
        <div className="card-block">
          <div className="createChapterTitle">
            {title}
            <i
              className="fa fa-times fa-xs"
              onClick={() => this.props.onCancel()}
            />
            <hr style={{ width: '600px', marginLeft: '-15px' }} />
          </div>
          <Row>
            <Col md={4}>
              <span className="InputLebel">Description</span>
            </Col>
            <Col md={8}>
              <SimpleTextarea
                type="text"
                value={this.state.description}
                onChange={e => {
                  this.setState({ description: e.target.value });
                }}
                error={
                  this.state.errors && this.state.errors.description ? (
                    this.state.errors.description
                  ) : null
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <span className="InputLebel">Stripe Code</span>
            </Col>
            <Col md={8}>
              <SimpleInput
                type="text"
                value={this.state.stripeCode}
                onChange={e => {
                  this.setState({ stripeCode: e.target.value });
                }}
                error={
                  this.state.errors && this.state.errors.stripeCode ? (
                    this.state.errors.stripeCode
                  ) : null
                }
                disabled={coupon ? true : false}
              />
            </Col>
          </Row>
          {coupon && this.getCuponInputs(coupon)}
          <hr style={{ width: '600px', marginLeft: '-15px' }} />
          <div className="bottom-buttons-new">
            <Button
              id="btn-success"
              className="btn btn-success"
              onClick={this.validate}
              loading={this.state.waiting}
            >
              Submit
            </Button>
            <Button className="btn btn-secondary" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
