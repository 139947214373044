import './AdminProfile.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import _ from 'underscore';

import ChangeExamContainer from '../../components/ChangeExam/components/ChangeExamContainer';
import addAbilityToOpenModal from '../../utilities/modalHOC';
import Card from '../Card/Card';
import CheckBoxList from '../CheckBoxList/CheckBoxList';
import AdminUserLogs from '../Modals/AdminUserLogs/AdminUserLogs';
import ChangeStripeId from '../Modals/ChangeStripeId/ChangeStripeId';
import ConfirmDeletion from '../Modals/ConfirmDeletion/ConfirmDeletion';
import ResetPassword from '../Modals/ResetPassword/ResetPassword';
import SetNewPassword from '../Modals/SetNewPassword/SetNewPassword';
import SimpleConfirmation from '../Modals/SimpleConfirmation/SimpleConfirmation';
import UpdatePhoneNumber from '../Modals/UpdatePhoneNumber/UpdatePhoneNumber';
import CardRow from './CardRow';
import PhotoRow from './PhotoRow';

class AdminProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myAccount: null,
    };
  }

  componentDidMount() {
    if (this.props.myAccount) {
      this.setState({ myAccount: true });
    } else {
      this.setState({ myAccount: false });
    }
  }

  openToggleActiveModal = () => {
    const { profile } = this.props;

    this.props.openModal({
      modalTitle: `${
        profile && profile.active ? 'Disabling' : 'Enabling'
      } a user`,
      modalContent: (
        <SimpleConfirmation
          onConfirm={() =>
            this.props.onToggleActive(profile.id, profile.active)
          }
          onCancel={this.props.closeModal}
          text={
            <div>
              {`Are you sure you want to ${
                profile && profile.active ? 'disable' : 'enable'
              } `}
              <strong>
                {profile.name} {profile.lastName}
              </strong>
              ?
            </div>
          }
        />
      ),
    });
  };

  openDeleteUserModal = () => {
    const { profile } = this.props;

    this.props.openModal({
      modalTitle: 'Deleting a user',
      modalContent: (
        <ConfirmDeletion
          onSubmit={() => this.props.onDelete(profile.id)}
          message={`You are about to delete the user ${profile.name} ${profile.lastName}.`}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  resetPasswordModal = () => {
    this.props.openModal({
      modalTitle: 'Reseting a user password',
      modalContent: (
        <ResetPassword
          onSubmit={(fields) =>
            this.props.onResetPassword(this.props.profile.id, fields)
          }
          onCancel={this.props.closeModal}
          user={this.props.profile}
        />
      ),
    });
  };

  openChangeStripeIdModal = () => {
    this.props.openModal({
      modalTitle: `Change stripe id for ${this.props.profile.email}`,
      modalContent: (
        <ChangeStripeId
          onSubmit={(fields) => this.props.updateUser(fields)}
          onCancel={this.props.closeModal}
          user={this.props.profile}
        />
      ),
    });
  };

  openChangeExamModal = () => {
    this.props.openModal({
      modalTitle: `Change exam for ${this.props.profile.email}`,
      modalContent: (
        <ChangeExamContainer
          onSubmit={(fields) => this.props.updateUser(fields)}
          onCancel={this.props.closeModal}
          user={this.props.profile}
        />
      ),
    });
  };

  openLogsModal = () => {
    this.props.openModal({
      modalContent: (
        <AdminUserLogs
          profile={this.props.profile}
          onClose={this.props.closeModal}
        />
      ),
      modalClassName: 'modal-create-chapter log-modal',
    });
  };

  openModal = (content, title, className, user, action) => {
    this.props.openModal({
      modalContent: content,
      modalTitle: title,
      modalClassName: className,
      user,
      action,
    });
  };

  CheckBoxListChange = (id) => {
    this.props.changeRole(this.props.profile, id);
  };

  itemsForCheckboxList = (roles, profile) => {
    const items = [{ name: 'Super admin', checked: profile.isSuperAdmin }];
    const itemsAux = roles.map((role) => {
      role.checked = !!(
        _.find(profile.roles, { id: role.id }) && !items[0].checked
      );
      role.disabled = !!items[0].checked;
      return role;
    });
    return items.concat(itemsAux);
  };

  renderThirdInput = (forAdmin, roles, profile) => {
    if (forAdmin) {
      return (
        <div className="subtopics-list">
          <div className="subtopics-list-title">Roles</div>
          <CheckBoxList
            items={this.itemsForCheckboxList(roles, profile)}
            onChange={this.CheckBoxListChange}
          />
        </div>
      );
    }
    return (
      <button
        className="btn btn-sm btn-primary changeExam"
        onClick={() => this.openChangeExamModal()}
      >
        Change exam
      </button>
    );
  };

  buttons = (forAdmin, profile, roles) => (
    <div className="buttonsContainer">
      <Row>
        <Col xs={4} md={4}>
          <button
            className="btn btn-sm btn-primary resetPwd"
            onClick={this.resetPasswordModal}
          >
            Reset password
          </button>
        </Col>
        <Col xs={8} md={8}>
          <button
            className="btn btn-sm btn-primary disableUser"
            onClick={this.openToggleActiveModal}
          >
            {profile && profile.active ? 'Disable user' : 'Enable user'}
          </button>
        </Col>
      </Row>
      <Row>
        <Col xs={4} md={4}>
          {this.renderThirdInput(forAdmin, roles, profile)}
        </Col>
        <Col xs={8} md={8}>
          <button
            className="btn btn-sm btn-primary deleteUser"
            onClick={this.openDeleteUserModal}
          >
            Delete User
          </button>
        </Col>
      </Row>
      {!forAdmin && (
        <Row>
          <Col xs={4} md={4}>
            <button
              className="btn btn-sm btn-primary changeStripeIdBtn"
              onClick={() => this.openChangeStripeIdModal(profile)}
            >
              Change stripe id
            </button>
          </Col>
        </Row>
      )}
    </div>
  );

  hasPermission = (name, generalPermissions) => {
    if (!generalPermissions) return false;
    return !!generalPermissions.find((permission) => permission === name);
  };

  evaluatePermission = (name, generalPermissions) => {
    switch (name) {
      case 'name':
        return this.hasPermission('Admins.ProfileDetails', generalPermissions);

      case 'lastName':
        return this.hasPermission('Admins.ProfileDetails', generalPermissions);

      case 'userName':
        return this.hasPermission('Admins.ProfileDetails', generalPermissions);

      case 'email':
        return this.hasPermission('Admins.ProfileDetails', generalPermissions);

      case 'phone':
        return this.hasPermission(
          'Admins.EditEmployeeDetails',
          generalPermissions,
        );

      case 'photo':
        return this.hasPermission('Admins.ProfileDetails', generalPermissions);

      case 'position':
        return this.hasPermission(
          'Admins.EditEmployeeDetails',
          generalPermissions,
        );

      case 'dateHired':
        return this.hasPermission(
          'Admins.EditEmployeeDetails',
          generalPermissions,
        );

      case 'nextRaiseDue':
        return this.hasPermission(
          'Admins.EditEmployeeDetails',
          generalPermissions,
        );

      case 'files':
        return this.hasPermission(
          'Admins.EditEmployeeDetails',
          generalPermissions,
        );

      case 'password':
        return this.hasPermission('Admins.ChangePassword', generalPermissions);

      default:
        return false;
    }
  };

  cardRow = (
    user = this.props.user,
    generalPermissions = this.props.generalPermissions,
    nameForValue = '',
    name,
    value,
    noEditable = false,
    isPassword = false,
    items = [],
    load = () => {},
    select = false,
    isRate = false,
    isDate = false,
  ) => (
    <CardRow
      valueName={name}
      value={value}
      updateUser={this.props.updateUser}
      noEditable={noEditable}
      profile={this.props.profile}
      isPassword={isPassword}
      items={items}
      load={load}
      select={select}
      isRate={isRate}
      nameForValue={nameForValue}
      isDate={isDate}
      myAccount={this.state.myAccount}
      editable={
        user && user.isSuperAdmin
          ? true
          : this.evaluatePermission(nameForValue, generalPermissions)
      }
    />
  );

  renderRow(valueName, value) {
    return (
      <Row style={{ width: '100%' }}>
        <Col xs={12} md={12} lg={4} style={{ paddingRight: '0px' }}>
          <div className="valueName">{valueName}</div>
        </Col>
        <Col
          xs={12}
          md={12}
          lg={8}
          className="middleColumn"
          style={{ paddingRight: '0px', paddingLeft: '0px' }}
        >
          <div className="userON value">Just Now - {value}</div>
        </Col>
      </Row>
    );
  }

  cardContent = (user, generalPermissions) => (
    <Row className="cardContentWrapper">
      {this.props.isNowActive
        ? this.renderRow('Last Seen:', this.props.path)
        : null}
      <PhotoRow
        valueName="Photo"
        value={this.props.profile ? this.props.profile.photo : null}
        updateUser={this.props.updateAdminFile}
        updatePhoto={this.props.updateUser}
        profile={this.props.profile}
        items={[]}
        load={() => {}}
        nameForValue="photo"
        multiple={false}
        accept="image/*"
        editable={
          user && user.isSuperAdmin
            ? true
            : this.evaluatePermission('photo', generalPermissions)
        }
      />
      {this.cardRow(
        user,
        generalPermissions,
        'isActive',
        'Status',
        this.props.profile
          ? this.props.profile.active
            ? 'Enabled'
            : 'Disabled'
          : '',
        true,
      )}
      {this.cardRow(
        user,
        generalPermissions,
        'name',
        'First name',
        this.props.profile ? this.props.profile.name : '',
      )}
      {this.cardRow(
        user,
        generalPermissions,
        'lastName',
        'Last name',
        this.props.profile ? this.props.profile.lastName : '',
      )}
      {this.cardRow(
        user,
        generalPermissions,
        'userName',
        'User name',
        this.props.profile ? this.props.profile.userName : '',
      )}
      {this.cardRow(
        user,
        generalPermissions,
        'phoneNumber',
        'Phone Number',
        this.props.profile ? this.props.profile.phone : '',
      )}
      {this.cardRow(
        user,
        generalPermissions,
        'email',
        'Email',
        this.props.profile ? this.props.profile.email : '',
      )}
      {this.cardRow(
        user,
        generalPermissions,
        'password',
        'Password',
        '',
        false,
        true,
      )}
    </Row>
  );

  loginAs = () => {
    this.props.loginAs(this.props.profile.id);
  };

  forcePasswordChange = () => {
    this.props.forcePasswordChange(this.props.profile.id);
  };

  updateSuperAdmin = () => {
    this.props.updateSuperAdmin(this.props.profile);
  };

  getOptions = (user, generalPermissions) => {
    let options = [
      {
        name: 'Login as admin',
        event: this.loginAs,
      },
      {
        name: this.props.profile.active ? 'Disable admin' : 'Enable admin',
        event: this.openToggleActiveModal,
      },
      {
        name: 'View IPs',
        event: this.openLogsModal,
      },
    ];

    if (this.props.loggedUser && this.props.loggedUser.isSuperAdmin) {
      options.push(
        {
          name: this.props?.profile?.isSuperAdmin
            ? 'Unset SuperAdmin'
            : 'Set SuperAdmin',
          event: this.updateSuperAdmin,
        },
        {
          name: 'Force password update',
          event: this.forcePasswordChange,
        },
      );
    }

    if (user && user.isSuperAdmin && !this.props.myAccount) return options;
    if (
      !generalPermissions.find((permission) => permission === 'Admins.LoginAs')
    )
      options = options.filter((option) => option.name !== 'Login as admin');
    if (
      !generalPermissions.find((permission) => permission === 'Admins.Disable')
    )
      options = options.filter(
        (option) =>
          option.name !== 'Disable admin' && option.name !== 'Enable admin',
      );
    if (
      !generalPermissions.find((permission) => permission === 'Admins.Delete')
    )
      options = options.filter((option) => option.name !== 'Delete admin');

    if (this.props.myAccount && this.props.profile.use2FA) {
      options = [
        {
          name: 'Reset Password',
          event: this.openResetPasswordModal,
        },
        {
          name: 'Disable 2FA',
          event: this.openChange2FAModal,
        },
        {
          name: 'Change Phone Number',
          event: this.openUpdatePhoneNumberModal,
        },
      ];
    }

    if (this.props.myAccount && this.props.profile.use2FA === false) {
      options = [
        {
          name: 'Reset Password',
          event: this.openResetPasswordModal,
        },
        {
          name: 'Enable 2FA',
          event: this.openChange2FAModal,
        },
        {
          name: 'Change Phone Number',
          event: this.openUpdatePhoneNumberModal,
        },
      ];
    }

    return options;
  };

  openResetPasswordModal = () => {
    this.props.openModal({
      modalTitle: 'Reseting a user password',
      modalContent: (
        <SetNewPassword
          onSubmit={(fields) =>
            this.props.onResetPassword(this.props.profile.id, fields)
          }
          onCancel={this.props.closeModal}
          user={this.props.profile}
        />
      ),
    });
  };

  openChange2FAModal = () => {
    this.props.openModal({
      modalTitle: 'Updating your 2FA configuration',
      modalContent: (
        <UpdatePhoneNumber
          user={this.props.profile}
          updateUser={() => this.props.updateUser}
          onSubmit={(fields) => this.props.onUpdatePhoneNumber(fields)}
          onCancel={this.props.closeModal}
          updatePhoneNumber={false}
        />
      ),
    });
  };

  openUpdatePhoneNumberModal = () => {
    this.props.openModal({
      modalTitle: 'Updating your Phone Number',
      modalContent: (
        <UpdatePhoneNumber
          user={this.props.profile}
          updateUser={() => this.props.updateUser}
          onSubmit={(fields) => this.props.onUpdatePhoneNumber(fields)}
          onCancel={this.props.closeModal}
          updatePhoneNumber
        />
      ),
    });
  };

  render() {
    const { loggedUser, generalPermissions } = this.props;
    return (
      <div className="userCardsContainer">
        <Card
          title={
            this.props.profile ? `Admin ID ${this.props.profile.id}` : 'Admin'
          }
          content={this.cardContent(loggedUser, generalPermissions)}
          options={this.getOptions(loggedUser, generalPermissions)}
          myAccount={this.state.myAccount}
        />
      </div>
    );
  }
}

export default addAbilityToOpenModal(AdminProfile);
