import { connect } from 'react-redux';
import { getSubtopics } from '../selectors';
import { loadSubtopicsByRate, updateChapterSubtopics } from '../actionsSagas';
import UpdateChapterSubtopics from '../../../commonComponents/Modals/UpdateChapterSubtopics/UpdateChapterSubtopics';

const mapStateToProps = (state, ownProps) => ({
  subtopics: getSubtopics(state),
  reference: ownProps.reference,
  rate: ownProps.rate,
  chapter: ownProps.chapter,
  onCancel: ownProps.onCancel,
});

const mapDispatchToProps = dispatch => ({
  loadSubtopicsByRate: rate => dispatch(loadSubtopicsByRate(rate)),
  updateChapterSubtopics: (reference, chapter, checkedSubtopics) =>
    dispatch(updateChapterSubtopics(reference, chapter, checkedSubtopics)),
});

const SubtopicsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateChapterSubtopics);

export default SubtopicsContainer;
