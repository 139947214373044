import React from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import Notifications from 'react-notification-system-redux';
import { PersistGate } from 'redux-persist/integration/react';
import routes from '../../routes';
import HeaderIntercom from '../../components/Header/HeaderIntercom';
import * as Sentry from '@sentry/browser';

class Root extends React.Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtra(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    const { store, persistor } = this.props;
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div>
            <Router history={browserHistory} routes={routes(store)} />
            <Notifications notifications={this.props.notifications} />
            <HeaderIntercom />
          </div>
        </PersistGate>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default connect((state) => ({ notifications: state.notifications }))(
  Root,
);
