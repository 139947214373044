import React from 'react';
import { Col, Row } from 'react-bootstrap';
import RateLine from './RateLine';
import './GroupsList.css';
import { hasPagePermission } from '../../../../utilities/FilterLinksForTabs';
import DropDown from '../../../../commonComponents/DropDown/DropDown';
import NumberUtils from '../../../../lib/NumberUtils';

export default class GroupsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: 'active',
      showAll: false,
      adminsFilter: 'All',
      expanded: false,
    };
  }

  componentWillMount() {
    if (
      this.props.adminsByRate.length === 0 &&
      this.props.adminsByQual.length === 0
    ) {
      this.props.onLoadAdmins(
        false,
        false,
        this.props.selectedAdmin ? this.props.isBackFromQuestion : false
      );
      this.props.onLoadAdminsQuals(false);
    }
  }

  componentDidUpdate(prevProps) {
    let {
      isBackFromQuestion,
      selectedAdmin,
      selectedRate,
      adminsByRate,
      studyProgramAdmins,
    } = this.props;
    if (
      isBackFromQuestion &&
      adminsByRate.length > 0 &&
      selectedRate &&
      selectedAdmin
    ) {
      let scrollTo = document.getElementById(
        `${selectedRate.id}-${selectedAdmin.id}`
      );
      if (scrollTo && scrollTo.parentNode && scrollTo.parentNode.parentNode) {
        scrollTo.parentNode.parentNode.scrollTop = scrollTo.offsetTop - 80;
        this.props.setIsBackFromQuestion(false);
      }
    }
    let admins = this.getRatesAdmins(studyProgramAdmins);
    this.props.noGroups(!Array.isArray(admins));
  }

  componentWillUnmount() {
    this.props.onClearAdmins();
  }

  state = {
    filter: 'active',
    showAll: false,
  };

  componentWillReceiveProps({
    selectedAdmin,
    selectedRate,
    adminsByRate,
    adminsByQual,
    adminsByRateImmutable,
    adminsByQualImmutable,
    activeStatus,
    isBackFromQuestion,
  }) {
    if (
      this.props.adminsByRateImmutable !== adminsByRateImmutable ||
      this.props.adminsByQualImmutable !== adminsByQualImmutable
    ) {
      this.props.updateStudyProgramAdmins(
        adminsByRate.concat(
          adminsByQual
            ? adminsByQual.map(qual => {
                let qualParam = Object.assign({}, qual);
                qualParam.isQual = true;
                return qualParam;
              })
            : []
        )
      );
    }
    if (
      selectedAdmin === null ||
      selectedRate === null ||
      (this.props.selectedAdmin === selectedAdmin &&
        this.props.selectedRate === selectedRate &&
        this.props.activeStatus === activeStatus)
    ) {
      return;
    } else {
      this.props.setAssignmentsCount(
        this.props.selectedAdmin !== selectedAdmin
          ? selectedAdmin
          : this.props.selectedAdmin,
        this.props.selectedRate !== selectedRate
          ? selectedRate
          : this.props.selectedRate,
        selectedRate.isQual ? 'qual' : 'rate'
      );

      // if (
      //   activeStatus !== this.props.activeStatus &&
      //   activeStatus !== "Completed"
      // ) {
      //   this.setState({ adminsFilter: activeStatus });
      // }
    }
  }

  appearRate = rate => {
    if (this.state.adminsFilter !== 'All') {
      return (
        rate &&
        rate.adminUsers &&
        rate.adminUsers.length > 0 &&
        rate.adminUsers.filter(user => user.counts.submitted > 0).length > 0
      );
    } else {
      return rate && rate.adminUsers && rate.adminUsers.length > 0;
    }
  };

  getRatesAdmins = adminsList => {
    if (adminsList && adminsList.length > 0) {
      let admins = [];
      let isSelectedRate = false;
      for (let rate of adminsList) {
        if (this.appearRate(rate)) {
          isSelectedRate = this.props.selectedRate
            ? this.props.selectedRate.id === rate.id
            : false;
          admins.push(
            <RateLine
              selectedAdmin={this.props.selectedAdmin}
              isSelectedRate={isSelectedRate}
              onAdminClick={this.props.onSelectAdmin}
              rate={rate}
              key={NumberUtils.obtainUniqueKey()}
              updateLoading={this.props.updateLoading}
              selectedRate={this.props.selectedRate}
              activeStatus={this.props.activeStatus}
              setAwaitingForApproval={this.props.setAwaitingForApproval}
              toggleActive={this.toggleActive}
              adminsFilter={this.state.adminsFilter}
              expanded={this.state.expanded || isSelectedRate}
              setAdminScroll={this.props.setAdminScroll}
              setIsBackFromQuestion={this.props.setIsBackFromQuestion}
            />
          );
        }
      }
      if (admins.length > 0) {
        return admins;
      }
    }
    return <p>No groups</p>;
  };

  getClassName = (filterName, selectedFilter) => {
    let name = 'SelectableName';
    name += filterName === selectedFilter ? ' active' : '';
    return name;
  };

  toggleShowAll = () => {
    if (this.props.activeStatus === 'awaitingApproval')
      this.props.setActiveStatus('Submitted');
    this.props.onLoadAdmins(!this.state.showAll, this.props.isViewRates);
    this.setState({ filter: 'showAll' });
  };

  toggleActive = (removingApprovalFilter = false) => {
    if (this.props.activeStatus === 'awaitingApproval')
      this.props.setActiveStatus('Submitted');
    this.props.onLoadAdmins(
      this.state.filter === 'active' ? true : false,
      removingApprovalFilter ? removingApprovalFilter : false
    );
    this.props.onLoadAdminsQuals(
      this.state.filter === 'active' ? true : false,
      removingApprovalFilter ? removingApprovalFilter : false
    );
    this.setState({
      filter: this.state.filter === 'active' ? 'inactive' : 'active',
    });
  };

  toggleAwaitingApproval = () => {
    this.props.getAwaitingApproval(this.props.user.id);
    this.setState({ filter: 'awaitingApproval' });
  };

  toggleShowType = () => {
    this.props.updateIsChangingType(true);
    this.props.updateLoading(true);
    this.props.onLoadAdmins(this.state.showAll, !this.props.isViewRates);
    this.props.onChangeView(!this.props.isViewRates);
  };

  evaluateApprovePermission = () => {
    let hasRolePermission = false;
    if (this.props.rolesPermissions) {
      this.props.rolesPermissions.map(role => {
        if (role.actions.find(action => action === 'Assignment.Approve')) {
          hasRolePermission = true;
        }
        return role;
      });
    }
    if (hasRolePermission) return true;
    return (
      (this.props.user && this.props.user.isSuperAdmin) ||
      (this.props.pagePermissions &&
        hasPagePermission('Wheelhouse.Assignments', this.props.pagePermissions))
    );
  };

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  getOptions = () => {
    let options = [
      {
        name: this.state.filter === 'active' ? 'Inactive' : 'Active',
        event: this.toggleActive,
      },
      {
        name: this.state.expanded ? 'Collapse All' : 'Expand All',
        event: this.toggleExpanded,
      },
    ];
    return options;
  };

  getGlobalCount = filter => {
    if (this.props.studyProgramAdmins.length === 0) return +' ' + 0;
    let filterToApply =
      filter === 'Not Started'
        ? 'notStarted'
        : filter === 'In progress' ? 'inProgress' : 'submitted';
    let total = 0;
    this.props.studyProgramAdmins.map(rate => {
      if (rate && rate.adminUsers) {
        rate.adminUsers.map(admin => {
          if (filter === 'All') {
            total += admin.counts['notStarted'];
            total += admin.counts['inProgress'];
            total += admin.counts['submitted'];
          } else total += admin.counts[filterToApply];
          return admin;
        });
      }

      return rate;
    });
    return +' ' + total;
  };

  renderGlobalCounts = () => {
    return (
      <div className="globalCountsContainer">
        <div className="countsContainer">
          <div
            onClick={() =>
              this.setState(
                { adminsFilter: 'All' },
                this.props.setActiveStatus('All')
              )}
            className={
              this.state.adminsFilter === 'All' ? 'count activeTab' : 'count'
            }
          >
            All: {this.getGlobalCount('All')}
          </div>
          <div
            onClick={() =>
              this.setState(
                { adminsFilter: 'Submitted' },
                this.props.setActiveStatus('Submitted')
              )}
            className={
              this.state.adminsFilter === 'Submitted' ? (
                'count activeTab'
              ) : (
                'count'
              )
            }
          >
            Awaiting approval: {this.getGlobalCount('Submitted')}
          </div>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <DropDown
            title={'Assignments'}
            options={this.getOptions()}
            style={{
              position: 'absolute',
              right: '5px',
              zIndex: '5',
            }}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <Col
        xs={6}
        md={3}
        className="GroupList"
        style={{ paddingLeft: '0px', paddingRight: '0px', height: '100%' }}
      >
        <Row className="GroupRow">{this.renderGlobalCounts()}</Row>
        <div className="ratesContainer">
          {this.getRatesAdmins(this.props.studyProgramAdmins)}
        </div>
      </Col>
    );
  }
}
