import { connect } from 'react-redux';
import couponDetail from './couponDetail';
import addAbilityToOpenModal from '../../../utilities/modalHOC';
import { setCouponDetail, setDeleteCoupon } from '../../Coupons/actions';
import { getCouponDetail, getDeleteCoupon } from '../../Coupons/selectors';
import { deleteDisableCoupon } from '../../Coupons/actionsSagas';
import {
  loadNewCouponsAction,
  listPlansSubscriptions,
  editNewCoupon,
  createSubcodes,
  loadSubcodes,
} from '../../Coupons/actionsSagas';
import { setPlansSubscriptions } from '../../Coupons/selectors';
import { getNewCoupons, getLoadCoupons } from '../../Coupons/selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    newCoupons: getNewCoupons(state),
    couponDetail: getCouponDetail(state),
    plansSubscriptions: setPlansSubscriptions(state),
    loadCoupons: getLoadCoupons(state),
    deleteCoupon: getDeleteCoupon(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCouponDetail: (value) => dispatch(setCouponDetail(value)),
    deleteDisableCoupon: (coupon, value) =>
      dispatch(deleteDisableCoupon(coupon, value)),
    editCoupon: (updatedCoupon, couponName) =>
      dispatch(editNewCoupon(updatedCoupon, couponName)),
    loadNewCoupons: () => dispatch(loadNewCouponsAction()),
    listPlansSubscriptions: () => dispatch(listPlansSubscriptions()),
    setDeleteCoupon: (value) => dispatch(setDeleteCoupon(value)),
    createSubcodes: (couponName) => dispatch(createSubcodes(couponName)),
    loadSubcodes: (couponName) => dispatch(loadSubcodes(couponName)),
  };
};

const couponDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(couponDetail);

export default addAbilityToOpenModal(couponDetailContainer);
