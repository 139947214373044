import { all, put, call, takeEvery } from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import { setStudyPrograms, setWaiting } from './actions';
import { GET_ALL_SP } from './actionTypesSagas';
import * as api from '../../utilities/ServiceManager';

/**
 * LOAD ALL STUDY PROGRAMS
 */
function* loadAllStudyProgramsSagas() {
  yield takeEvery(GET_ALL_SP, loadAllStudyPrograms);
}

function* loadAllStudyPrograms(action) {
  try {
    yield put(setWaiting(true));
    const studyPrograms = yield call(api.getAllStudyPrograms);
    yield put(setStudyPrograms(studyPrograms));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load study programs. Please try again later.',
      ),
    );
  } finally {
    yield put(setWaiting(false));
  }
}

/**
 * DEFAUL EXPORT
 */
export default function* sagas() {
  yield all([loadAllStudyProgramsSagas()]);
}

function showNotificationErrorFromException(exception) {
  let message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
