import React from 'react';
import './Activity.css';
import { Row, Col } from 'react-bootstrap';
import ActivityModal from './ActivityModal';

class CardRow extends React.Component {
  state = {};

  openActivityModal = () => {
    if (
      this.props.actions === 'subscriptionSetToCancel' ||
      this.props.actions === 'subscriptionCancelFailed' ||
      this.props.actions === 'subscriptionCanceled'
    ) {
      this.props.openModal({
        modalContent: (
          <ActivityModal
            actions={this.props.actions}
            closeModal={this.props.closeModal}
            userEmail={this.props.userEmail}
            messageError={this.props.messageError}
          />
        ),
        modalTitle: 'User activity',
        modalClassName: 'modal-create-reference',
        className: '',
      });
    }
  };

  render() {
    return (
      <Row
        style={
          this.props.margin
            ? { width: '100%', marginTop: '9px' }
            : { width: '100%' }
        }
      >
        <Col
          xs={this.props.link || this.props.status ? 9 : 4}
          md={this.props.link || this.props.status ? 9 : 4}
          lg={this.props.link || this.props.status ? 9 : 4}
          style={{ paddingRight: '0px' }}
        >
          <div>{this.props.title}</div>
        </Col>
        <Col
          xs={this.props.link || this.props.status ? 3 : 6}
          md={this.props.link || this.props.status ? 3 : 6}
          lg={this.props.link || this.props.status ? 3 : 6}
          style={{ paddingLeft: '0px' }}
        >
          {this.props.link && (
            <div className="link" onClick={() => this.openActivityModal()}>
              {this.props.link}
            </div>
          )}
        </Col>
        <Col style={{ marginTop: '13px' }} xs={12} md={12} lg={12}>
          {this.props.content}
        </Col>

        <div className="separatorLine" />
      </Row>
    );
  }
}

export default CardRow;
