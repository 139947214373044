import React from 'react';
import { SimpleInput } from '../../../../commonComponents/Input/Input';
import './StudyPlanUser.css';

class StudyPlanUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rate: this.props.selectedStudyPlanUser.rate.id,
      dutyState: this.props.selectedStudyPlanUser.dutyState,
      rank: this.props.selectedStudyPlanUser.rank,
      isSubscribed: this.props.selectedStudyPlanUser.isSubscribed,
    };
  }

  componentDidUpdate(prevProps) {
    let { selectedStudyPlanUser } = this.props;
    if (
      selectedStudyPlanUser &&
      (!prevProps.selectedStudyPlanUser ||
        selectedStudyPlanUser.id !== prevProps.selectedStudyPlanUser.id)
    ) {
      this.setState({
        rate: selectedStudyPlanUser.rate.id,
        dutyState: selectedStudyPlanUser.dutyState,
        rank: selectedStudyPlanUser.rank,
        isSubscribed: selectedStudyPlanUser.isSubscribed,
      });
    }
  }

  onRateChange = event => {
    this.setState({ rate: event.target.value });
  };

  onRankChange = event => {
    this.setState({ rank: event.target.value });
  };

  onDutyStateChange = event => {
    this.setState({ dutyState: event.target.value });
  };

  handleDeleteUser = () => {
    return;
  };

  onUpdateUser = () => {
    let { selectedStudyPlanUser, editStudyPlanUser } = this.props;
    editStudyPlanUser(
      this.state.rate,
      this.state.dutyState,
      this.state.rank,
      this.state.isSubscribed,
      selectedStudyPlanUser.id
    );
  };

  onDeleteUser = () => {
    let { selectedStudyPlanUser, deleteStudyPlanUser } = this.props;
    deleteStudyPlanUser(selectedStudyPlanUser.id);
  };

  render() {
    let { selectedStudyPlanUser, canEdit } = this.props;
    return selectedStudyPlanUser ? (
      <div className="studyPlan">
        <div>
          <div className="header">
            <h1>User Card</h1>
          </div>
          <div className="dataContainer">
            <div className="personalData">
              <div>
                <span>
                  <strong>First Name:</strong> {selectedStudyPlanUser.name}
                </span>
              </div>
              <div>
                <span>
                  <strong>Last Name:</strong> {selectedStudyPlanUser.lastName}
                </span>
              </div>
              <div>
                <span>
                  <strong>Email:</strong> {selectedStudyPlanUser.email}
                </span>
              </div>
              <div>
                <span>
                  <strong>Study Plan:</strong>{' '}
                  {selectedStudyPlanUser.studyPlan ? (
                    selectedStudyPlanUser.studyPlan.title
                  ) : (
                    ''
                  )}
                </span>
              </div>
            </div>
            <div>
              <span>
                <strong>Is Subscribed:</strong>
              </span>
              <SimpleInput
                type="checkbox"
                value={this.state.isSubscribed}
                onChange={e => {
                  this.setState({ isSubscribed: !this.state.isSubscribed });
                }}
                error={
                  this.state.errors && this.state.errors.isSubscribed ? (
                    this.state.errors.isSubscribed
                  ) : null
                }
                disabled={!canEdit}
              />
            </div>
            <div>
              <select
                className="form-control"
                id="rateSelect"
                name="rate_id"
                required=""
                value={this.state.rate}
                onChange={this.onRateChange}
              >
                <option value="">Select your rate</option>
                {this.props.rates.map(rate => {
                  return (
                    <option key={'rate-' + rate.id} value={rate.id}>
                      {rate.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <select
                className="form-control"
                id="examRankSelect"
                name="exam_rank_id"
                required=""
                value={this.state.rank}
                onChange={this.onRankChange}
              >
                <option value="">Exam Rank</option>
                <option value="E4">E-4</option>
                <option value="E5">E-5</option>
                <option value="E6">E-6</option>
                <option value="E7">E-7</option>
              </select>
            </div>
            <div>
              <select
                className="form-control"
                id="examTypeSelect"
                name="exam_type_id"
                required=""
                value={this.state.dutyState}
                onChange={this.onDutyStateChange}
              >
                <option value="">Exam Type</option>
                <option value="Regular">Regular</option>
                <option value="Substitute">Substitute</option>
                <option value="Reserve">Reserve</option>
              </select>
            </div>
          </div>
          <div className="buttonsContainer">
            <button
              className={
                this.state.rate !== '' &&
                this.state.rank !== '' &&
                this.state.dutyState !== '' ? (
                  'navyButton'
                ) : (
                  'navyButton disabled'
                )
              }
              disabled={!canEdit}
              onClick={this.onUpdateUser}
            >
              Update User
            </button>
            <button
              className="navyButton"
              onClick={this.onDeleteUser}
              disabled={!canEdit}
            >
              Delete User
            </button>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default StudyPlanUser;
