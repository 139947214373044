import * as t from './actionTypes';

export const setSettings = settings => ({
  type: t.SET_SETTINGS,
  settings,
});

export const setLoading = value => ({
  type: t.SET_LOADING,
  value,
});
