import React from 'react';
import Button from 'react-bootstrap-button-loader';
import Spinner from '../../../commonComponents/Spinner/Spinner';

class ViewActivityModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      waiting: false,
    };
  }

  componentWillMount() {
    this.props.onLoad(this.props.id, this.props.isChapter);
  }

  render() {
    const { closeModal, waiting, isChapter } = this.props;

    return (
      <div className="simple-confirmation-component">
        <div className="card-block">
          <div className="createChapterTitle">
            View {isChapter ? 'chapter ' : 'book '} activity log
            <i className="fa fa-times fa-xs" onClick={closeModal} />
            <hr />
          </div>
          {waiting && <Spinner text="Loading activity logs" />}
          {!waiting && (
            <div className="text">
              {this.props.logs &&
                this.props.logs.length !== 0 &&
                this.props.logs.map(log => (
                  <div className="log-item">
                    <span>
                      {new Date(log.createdAt).toLocaleString() + '  '}
                    </span>
                    <span>
                      {log.user.name +
                        ' ' +
                        log.user.lastName +
                        ' (id:' +
                        log.user.id +
                        ')  '}{' '}
                    </span>
                    <span>{' (ip:' + log.ip + ')  '} </span>
                    <span>{' ' + log.fileAction + '  '} </span>
                    <span>{' ' + log.fileName + '  '} </span>
                  </div>
                ))}
              {this.props.logs && this.props.logs.length === 0 && (
                <div className="text-center">
                  There are no Activity logs registered for this{' '}
                  {isChapter ? 'chapter' : 'book'}.
                </div>
              )}
            </div>
          )}
          <br />
          {this.state.error ? (
            <div className="errors">{this.state.error}</div>
          ) : null}
          <div className="bottom-buttons">
            <Button className="btn btn-danger closeBtn" onClick={closeModal}>
              {' '}
              close
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewActivityModal;
