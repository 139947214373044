import { connect } from 'react-redux';
import Banners from './Banners';
import addAbilityToOpenModal from '../../utilities/modalHOC';
import {
  getAllBannersAction,
  setBannerDetailsAction,
  loadBannerDetails,
  createBannerView,
  createBannerAction,
  editBannerAction,
  deleteBannerAction,
  activeBannerAction,
} from './actionSagas';
import {
  getBannersSelectors,
  getBannerDetailsSelectors,
  loadBannerDetailsSelectors,
  createBannerViewSelector,
  editBannerViewSelector,
  setLoadingInfoSelector,
} from './selectors';

const mapStateToProps = state => ({
  banners: getBannersSelectors(state),
  bannerDetails: getBannerDetailsSelectors(state),
  loadBanner: loadBannerDetailsSelectors(state),
  viewBannerCreate: createBannerViewSelector(state),
  editBannerView: editBannerViewSelector(state),
  loadingInfo: setLoadingInfoSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getAllBanners: () => dispatch(getAllBannersAction()),
  setBannerDetail: bannerId => dispatch(setBannerDetailsAction(bannerId)),
  loadBannerDetails: value => dispatch(loadBannerDetails(value)),
  createBannerView: (value, editBanner) =>
    dispatch(createBannerView(value, editBanner)),
  createBanner: banner => dispatch(createBannerAction(banner)),
  editBanner: banner => dispatch(editBannerAction(banner)),
  deleteBanner: banner => dispatch(deleteBannerAction(banner)),
  activeBanner: (bannerId, value) =>
    dispatch(activeBannerAction(bannerId, value)),
});

const BannersContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Banners);

export default addAbilityToOpenModal(BannersContainer);
