import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './AssignmentComments.css';
import AssignmentCommentsRow from './AssignmentCommentsRow';

export default class AssignmentComments extends React.Component {
  render() {
    return (
      <div className="assignemnt-comments-component">
        {this.props.onCancel && (
          <span className="closeButton" onClick={this.props.onCancel}>
            &times;
          </span>
        )}
        <Row className="headerRow">
          <Col sm={4}>User</Col>
          <Col sm={4}>Date</Col>
          <Col sm={4}>Comment</Col>
        </Row>
        {this.props.comments
          .filter(comment => comment.comment !== '')
          .map(comment => (
            <AssignmentCommentsRow item={comment} />
          ))}
      </div>
    );
  }
}
