import * as t from './actionTypes';

export const setChangeHistory = changeHistory => ({
  type: t.SET_CHANGE_HISTORY,
  payload: changeHistory,
});

export const updateWaiting = waiting => ({
  type: t.SET_WAITING,
  payload: waiting,
});
