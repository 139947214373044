import { connect } from 'react-redux';
import Search from './Search';
import addAbilityToOpenModal from '../../../utilities/modalHOC';
import {
  getBooksResponse,
  getChaptersResponse,
  getQuestionsResponse,
  getSearchSuccess,
  getSearchError,
  getErrors,
  getQuery,
  getSearchFromHeader,
  getOptions,
  getMoreBooks,
  getMoreChapters,
  getMoreQuestions,
  getIsSearchingQuestions,
  getIsSearchingBooks,
  getIsSearchingChapters,
} from '../selectors';
import {
  setIsSearchingBooks,
  setIsSearchingChapters,
  setIsSearchingQuestions,
  setSearchSuccess,
  setSearchError,
  setErrors,
  setQuery,
  setSearchFromHeader,
  setOptions,
  setMoreBooks,
  setBooksResponse,
  setChaptersResponse,
  setQuestionsResponse,
} from '../actions';
import {
  submitBooksSearch,
  submitChaptersSearch,
  submitQuestionsSearch,
} from '../actionSagas';

const mapStateToProps = (state, ownProps) => ({
  booksResponse: getBooksResponse(state),
  chaptersResponse: getChaptersResponse(state),
  questionsResponse: getQuestionsResponse(state),
  isSearchingBooks: getIsSearchingBooks(state),
  isSearchingChapters: getIsSearchingChapters(state),
  isSearchingQuestions: getIsSearchingQuestions(state),
  searchSuccess: getSearchSuccess(state),
  searchError: getSearchError(state),
  errors: getErrors(state),
  query: getQuery(state),
  searchFromHeader: getSearchFromHeader(state),
  options: getOptions(state),
  moreBooks: getMoreBooks(state),
  moreChapters: getMoreChapters(state),
  moreQuestions: getMoreQuestions(state),
});

const mapDispatchToProps = dispatch => ({
  setBooksResponse: response => dispatch(setBooksResponse(response)),
  setChaptersResponse: response => dispatch(setChaptersResponse(response)),
  setQuestionsResponse: response => dispatch(setQuestionsResponse(response)),
  setIsSearchingBooks: value => dispatch(setIsSearchingBooks(value)),
  setIsSearchingChapters: value => dispatch(setIsSearchingChapters(value)),
  setIsSearchingQuestions: value => dispatch(setIsSearchingQuestions(value)),
  setSearchSuccess: value => dispatch(setSearchSuccess(value)),
  setSearchError: value => dispatch(setSearchError(value)),
  setErrors: errors => dispatch(setErrors(errors)),
  setQuery: query => dispatch(setQuery(query)),
  setSearchFromHeader: value => dispatch(setSearchFromHeader(value)),
  setOptions: options => dispatch(setOptions(options)),
  setMoreBooks: value => dispatch(setMoreBooks(value)),
  submitBooksSearch: (query, separatedTags, count, more) =>
    dispatch(submitBooksSearch(query, separatedTags, count, more)),
  submitChaptersSearch: (query, separatedTags, count, more) =>
    dispatch(submitChaptersSearch(query, separatedTags, count, more)),
  submitQuestionsSearch: (query, separatedTags, count, more) =>
    dispatch(submitQuestionsSearch(query, separatedTags, count, more)),
});

const SearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Search);

export default addAbilityToOpenModal(SearchContainer);
