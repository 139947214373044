import React from 'react';
import RoleRow from './RoleRow';
import NumberUtils from '../../lib/NumberUtils';
import { Row, Col } from 'react-bootstrap';
import Spinner from '../Spinner/Spinner';
import './RolesList.css';

class RolesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    const { selectedRole, updateChecks } = this.props;

    if (selectedRole !== nextProps.selectedRole) {
      if (selectedRole && selectedRole[this.props.permissionsAttribute]) {
        updateChecks(
          selectedRole[this.props.permissionsAttribute].map(
            permission => permission.id
          )
        );
      }
    }
  }

  reloadUsers = (search = this.state.search, page = this.state.page) => {
    this.setState({ search: search || '', page: page || 1 });
    this.props.reloadUsers(search, page);
  };

  changePage = page => {
    this.setState({ page });
    this.reloadUsers(null, page);
  };

  renderRole = role => {
    return (
      <RoleRow
        key={NumberUtils.obtainUniqueKey()}
        role={role}
        selected={
          this.props.selectedRole && this.props.selectedRole.id ? (
            this.props.selectedRole.id === role.id
          ) : (
            false
          )
        }
        setSelectedRole={this.props.setSelectedRole}
        updateRoleName={this.props.updateRoleName}
        toggleNewRole={this.props.toggleNewRole}
      />
    );
  };

  renderRoles = roles => {
    if (roles) {
      return roles.map(option => this.renderRole(option));
    }
  };

  renderLoading = () => {
    return <Spinner />;
  };

  renderTable = (loading, roles) => {
    if (loading) {
      return this.renderLoading();
    }
    return <div>{this.renderRoles(roles)}</div>;
  };

  render() {
    const {
      loading,
      roles,
      updateRoleName,
      updateChecks,
      toggleNewRole,
      setSelectedRole,
      loggedUser,
      generalPermissions,
    } = this.props;
    return (
      <div>
        <div className="rolesListItems">
          <Row style={{ width: '100%' }}>
            <Col className="title" xs={9} md={9}>
              Roles
            </Col>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                textAlign: 'center',
              }}
              xs={2}
              md={2}
            >
              {((loggedUser && loggedUser.isSuperAdmin) ||
                (generalPermissions &&
                  generalPermissions.find(
                    permission =>
                      permission === 'Roles.Create' ||
                      permission === 'Teams.Add'
                  ))) && (
                <div className="plusContainer">
                  <i
                    onClick={() => {
                      updateRoleName('');
                      updateChecks([]);
                      toggleNewRole(true);
                      setSelectedRole({});
                    }}
                    className="fa fa-plus fa-lg"
                  />
                </div>
              )}
            </Col>
            <div className="line" />
          </Row>
        </div>
        <div className="animated fadeIn rolesTable">
          {this.renderTable(loading, roles)}
        </div>
      </div>
    );
  }
}

export default RolesList;
