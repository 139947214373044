import React from 'react';
import './ViewSummaryModal.sass';

export default class ViewSummaryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="modal-summary-container">
        <div className="card-block">
          <div className="title-container mt-1">
            <h4 className="title mb-0">SUMMARY: {this.props.chapter.name}</h4>
            <i
              className="fa fa-times fa-xs mt-0"
              onClick={() => {
                this.props.onCancel();
              }}
            />
          </div>
          <hr className="hrModal" />
          <div>
            <div>
              <div className="customPromptContainer mt-1">
                <div className="p-2">
                  <h6 className="substitle">Summary Text</h6>
                  <div className="summaryText">
                    {this.props.summary.summaryText}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
