import './TextWithTooltip.css';

import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

function TextWithTooltip({ id, text, tooltip, place = 'top', type = 'info' }) {
  return (
    <span>
      <span
        data-tooltip-content={tooltip}
        data-tooltip-id={`react-tooltip-key-${id}`}
      >
        {text}
      </span>
      <ReactTooltip
        id={`react-tooltip-key-${id}`}
        place={place}
        variant={type}
        multiline
        className="react-tooltip"
      />
    </span>
  );
}

export default TextWithTooltip;
