import moment from 'moment';
import React from 'react';
import Button from 'react-bootstrap-button-loader';
import ReactDOM from 'react-dom';
import { Link } from 'react-router';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import DropDown from '../../../commonComponents/DropDown/DropDown';
import AddPdf from '../../../commonComponents/Images/addPdf.png';
import ViewPdf from '../../../commonComponents/Images/viewPdf.png';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import NumberUtils from '../../../lib/NumberUtils';
import {
  hasPagePermission,
  hasPermissions,
} from '../../../utilities/FilterLinksForTabs';
import {
  allowRolesPermissions,
  hasDownloadCSV,
} from '../../../utilities/functionalitiesForCSV';
import { isGptQuestionGenerator } from '../../../utilities/isGptQuestionGenerator';

const isTextValid = (text) => (text ? text.trim().length > 0 : false);

class ReferenceItemEditMode extends React.Component {
  componentDidMount() {
    this.textInput && this.textInput.focus();
  }

  render() {
    const { item, onToggleEditMode, onUpdate, onUpdateEditedName } = this.props;

    return (
      <span style={{ display: 'flex' }}>
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            onUpdate(item.editedName);
          }}
          style={{ flex: 1, display: 'flex' }}
        >
          <input
            type="text"
            ref={(input) => item.focus && (this.textInput = input)}
            value={item.editedName}
            style={{ flex: 1 }}
            onChange={(ev) => onUpdateEditedName(ev.target.value)}
            onKeyDown={(ev) => ev.keyCode === 27 && onToggleEditMode()}
          />
          <input type="submit" style={{ display: 'none' }} />
          <button
            className="btn btn-sm btn-secondary"
            style={{ margin: '0 10px' }}
            onClick={(ev) => {
              ev.stopPropagation();
              onToggleEditMode();
            }}
          >
            Cancel
          </button>
          <Button
            type="submit"
            className="btn-sm"
            variant="success"
            loading={item.loading || false}
            onClick={(ev) => {
              ev.stopPropagation();
              onUpdate(item.editedName);
            }}
          >
            Save
          </Button>
        </form>
      </span>
    );
  }
}

class ReferenceItemAddMode extends React.Component {
  state = {
    name: this.props.item && this.props.item.name ? this.props.item.name : '',
    description:
      this.props.item && this.props.item.description
        ? this.props.item.description
        : '',
    selectedChapter: 0,
    isDisabled: this.props.loadAddChapter,
  };

  valiateFields = () => {
    const { name, description, selectedChapter } = this.state;
    if (!isTextValid(name) || !isTextValid(description)) {
      this.setState({
        nameError: !isTextValid(name),
        descriptionError: !isTextValid(description),
      });
    } else {
      let presentationIndex = 0;
      if (selectedChapter) {
        const chapter = this.props.allChapters.find(
          (c) => Number(c.id) === Number(selectedChapter),
        );
        presentationIndex = chapter ? chapter.presentationIndex + 1 : 0;
      }
      this.props.onAdd(name, description, presentationIndex);
    }
  };

  render() {
    const { item, waiting, level, isReference, allChapters = [] } = this.props;
    return (
      <span style={{ display: 'flex' }}>
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
            this.valiateFields();
          }}
          style={{
            flex: 1,
            display: 'flex',
            height: '40px',
            alignSelf: 'center',
          }}
        >
          <input
            id="title"
            type="title"
            data-test="create-chapter-title-input"
            style={{ marginRight: '7px' }}
            placeholder={isReference ? 'BOOK TITLE' : 'CHAPTER TITLE'}
            onChange={(event) => this.setState({ name: event.target.value })}
            value={this.state.name}
            className={this.state.nameError ? 'inputErrorChapter' : ''}
            disabled={this.state.isDisabled}
          />
          <input
            id="description"
            type="description"
            data-test="create-chapter-description-input"
            style={{ flex: 1, marginRight: '7px' }}
            placeholder={
              isReference ? 'BOOK DESCRIPTION' : 'CHAPTER DESCRIPTION'
            }
            onChange={(event) =>
              this.setState({ description: event.target.value })
            }
            value={this.state.description}
            className={this.state.descriptionError ? 'inputErrorChapter' : ''}
            disabled={this.state.isDisabled}
          />
          {allChapters ? (
            <select
              value={this.state.selectedChapter}
              style={{ width: '200px', marginRight: '7px', height: 'auto' }}
              className="form-control"
              onChange={(e) =>
                this.setState({ selectedChapter: e.target.value })
              }
            >
              <option key={0} value={0}>
                Insert after
              </option>
              {allChapters.map((c) => (
                <option key={`chapter-id-${c.id}`} value={c.id}>
                  {c.name}
                </option>
              ))}
            </select>
          ) : null}
          <input type="submit" style={{ display: 'none' }} />
          <Button
            type="submit"
            className="btn-sm addBtn"
            variant="success"
            loading={item.loading && waiting}
            data-test="add-chapter-button"
          >
            {level === 2 ? 'Add chapter' : 'Add book'}
          </Button>
        </form>
      </span>
    );
  }
}

const renderStudyPrograms = (reference) =>
  reference.studyPrograms.map((studyProgram, index) => {
    if (index < 10 && studyProgram && studyProgram.typeInstance) {
      return (
        <span ref={`sp-${reference.id}`} key={`studyProgram-${index}`}>
          <span className="studyProgramWithSpace">
            {studyProgram.typeInstance.shortname
              ? studyProgram.typeInstance.shortname
              : studyProgram.typeInstance.description
                ? studyProgram.typeInstance.description
                : studyProgram.typeInstance.name}
          </span>
        </span>
      );
    }
    if (index === 10) {
      return (
        <span ref={`sp-${reference.id}`} key={`studyProgram-${index}`}>
          ...
        </span>
      );
    }
    return false;
  });

const getItemName = (item) => {
  const enabled = !item.enabled ? ' (Disabled) ' : '';
  const className = item.deletedAt ? 'linethrough' : '';
  if (item.description) {
    return (
      <span className={className} title={`${item.name} - ${item.description}`}>
        {`${item.name} - ${item.description}${enabled}`}
      </span>
    );
  }
  return (
    <span className={className} title={item.name}>
      {item.name}
      {` ${enabled}`}
    </span>
  );
};
/*
const goToLink = (pathname, state, referenceId, references) => {
  let hystory = {
    references,
    referenceId,
  };
  sessionStorage.setItem('books_page_history', JSON.stringify(hystory));
  browserHistory.push({ pathname, state });
  return;
};


const getNameWithArrows = (
  chapter,
  level,
  index,
  allChapters,
  onMoveChapters,
) => {
  if (level === 2) {
    return (
      <div className="NameWithArrows">
        <MoveRowUpDown
          current={chapter}
          up={allChapters[index - 1]}
          down={allChapters[index + 1]}
          onMoveRows={onMoveChapters}
        />
        {getItemName(chapter)}
      </div>
    );
  }
  return getItemName(chapter);
};
*/
class ReferenceItemNormalMode extends React.Component {
  componentDidMount() {
    // Adjust the width of the itemName dynamically based on the number of SPs attached
    const studyProgramElement = ReactDOM.findDOMNode(
      this.refs[`sp-${this.props.item.id}`],
    );
    const itemNameElement = ReactDOM.findDOMNode(
      this.refs[`itemName-${this.props.item.id}`],
    );
    if (studyProgramElement && studyProgramElement.parentNode) {
      const itemNameWidth =
        studyProgramElement.parentNode.parentNode.getBoundingClientRect()
          .width -
        studyProgramElement.getBoundingClientRect().width +
        175;
      window.jQuery(itemNameElement).width(itemNameWidth);
    }
  }

  redFilter2 = (item, activeFilter, chapter) => {
    let items = item;
    if (!chapter) {
      items = item.chapters[0];
    }
    const allRatingExam = [];
    if (items && items.subtopics) {
      if (items.subtopics.length > 0) {
        if (items.subtopicBibs) {
          items.subtopicBibs.map((sb) => {
            if (!sb.ignoreChapter) {
              let red = true;
              items.subtopics.map((s) => {
                if (sb.subtopic.includes(s)) {
                  red = false;
                }
              });
              if (red) {
                if (
                  (!activeFilter || activeFilter.rank === '') &&
                  sb.linkedTo !== 'Ignore'
                ) {
                  if (sb.linkedTo === 'Substitute') {
                    allRatingExam.push(` ${sb.dutyState} ${sb.ratingExam}`);
                    allRatingExam.push(` ${sb.linkedTo} ${sb.ratingExam}`);
                  } else {
                    allRatingExam.push(` ${sb.dutyState} ${sb.ratingExam}`);
                  }
                } else if (
                  activeFilter &&
                  activeFilter.rank ===
                    this.replaceRatingExamForRank(sb.ratingExam) &&
                  activeFilter.dutystate === sb.dutyState
                ) {
                  allRatingExam.push(' ');
                }
              }
            }
          });
        }
      }
    }
    return allRatingExam;
  };

  replaceRatingExamForRank = (ratingExam) => {
    const rating = ratingExam[ratingExam.length - 1];
    let result;
    switch (rating) {
      case '3':
        result = 'E4';
        break;
      case '2':
        result = 'E5';
        break;
      case '1':
        result = 'E6';
        break;
      case 'C':
        result = 'E7';
        break;
      default:
        result = ratingExam;
        break;
    }
    return result;
  };

  hoverRating = (ratings) => {
    let element = '';
    if (ratings !== undefined) {
      ratings.map((re) => {
        if (element.indexOf(re.ratingExam) === -1) {
          element = `${element} ${re.ratingExam}`;
        }
      });
    }
    if (element === '') return 'No rating exam';
    return element;
  };

  getPdfImage = (item, onUpload, setLoadingPdf) => {
    const pdfUrl = item.pdfUrl
      ? item.pdfUrl
      : !item.actAsParent &&
          item.chapters &&
          item.chapters[0] &&
          item.chapters[0].pdfPages &&
          item.chapters[0].pdfUrl
        ? item.chapters[0].pdfUrl
        : null;

    if (item.isLoadingPdf && !pdfUrl) {
      return (
        <span className="flexAlignCenter">
          <Spinner />
        </span>
      );
    }
    if (pdfUrl) {
      return (
        <a
          onClick={(ev) => {
            this.props.getUrlPdf({ url: pdfUrl });
            ev.stopPropagation();
            ev.preventDefault();
          }}
        >
          <img
            className="pdfImage"
            title="View PDF"
            alt="View PDF"
            src={ViewPdf}
            loading="lazy"
          />
        </a>
      );
    }
    if (item.deletedAt) {
      return '';
    }
    return (
      <a
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          if (this.props.hasEditPermission()) {
            this.upload.click();
          }
        }}
      >
        <img
          className="pdfImage"
          title="Add PDF"
          alt="Add PDF"
          src={AddPdf}
          loading="lazy"
        />
        <input
          type="file"
          id={`file-input-${NumberUtils.obtainUniqueKey()}`}
          ref={(ref) => (this.upload = ref)}
          style={{ display: 'none' }}
          accept="application/pdf"
          onChange={(e) => {
            setLoadingPdf();
            onUpload(e.target.files);
          }}
          onClick={(ev) => ev.stopPropagation()}
        />
      </a>
    );
  };

  renderQuestionCount = (item, selectedRate) => {
    if (!item || item.actAsParent) {
      return '';
    }
    const ratingExams = this.props.isQual
      ? []
      : this.redFilter2(
          this.props.item,
          this.props.activeFilter,
          this.props.isChapter,
        );
    const count = item.questionCount
      ? item.questionCount
      : item.chapters && item.chapters[0]
        ? item.chapters[0].questionCount
        : 0;
    const subtopicCount = item.subtopicCount
      ? item.subtopicCount
      : item.chapters && item.chapters[0]
        ? item.chapters[0].subtopicCount
        : 0;
    const subtopicActiveCycleCount = item.subtopicActiveCycleCount
      ? item.subtopicActiveCycleCount
      : item.chapters && item.chapters[0]
        ? item.chapters[0].subtopicActiveCycleCount
        : 0;

    let pdfCount = item.pdfPages
      ? item.pdfPages
      : !item.actAsParent &&
          item.chapters &&
          item.chapters[0] &&
          item.chapters[0].pdfPages
        ? item.chapters[0].pdfPages
        : null;

    if (item && item.pdfPage) {
      pdfCount = item.pdfPage;
    }

    const isReference = item.hasOwnProperty('actAsParent');
    return (
      <span
        className={
          !isReference ? 'chapters-qty in-chapters-qty' : 'chapters-qty'
        }
      >
        <span className={Number(count) === 0 ? 'redText' : ''}>
          {pdfCount && (
            <span style={{ color: '#999' }}>{`${pdfCount} Pages, `}</span>
          )}
          {`${count} Questions`}
          {!this.props.isQual && selectedRate && selectedRate.id !== 0 && (
            <span className="blackText">{', '}</span>
          )}
          {!this.props.isQual && selectedRate && selectedRate.id !== 0 && (
            <span
              className={
                Number(subtopicCount) === 0 ||
                Number(subtopicActiveCycleCount) === 0 || // flag
                ratingExams.length > 0
                  ? 'redText'
                  : 'blackText'
              }
              data-tooltip-content="dsa"
              data-tootltip-id={`${item.id}-tooltip-ratingExam`}
            >
              {`${subtopicCount} Subtopics`}
              {ratingExams.length !== 0 && ratingExams[0] !== ' ' ? (
                <ReactTooltip
                  id={`${item.id}-tooltip-ratingExam`}
                  place="bottom"
                  variant="info"
                  className="react-tooltip"
                >
                  <span>{ratingExams}</span>
                </ReactTooltip>
              ) : null}
            </span>
          )}
        </span>
      </span>
    );
  };

  renderAssignedIcon = (originItem, showUserIcon, onAssign) => {
    let item = originItem;

    if (originItem && !originItem.actAsParent && !originItem.reference) {
      // Check if passed reference as a item of BookItem
      if (originItem.chapters.length > 0) {
        item = originItem.chapters[0];
      }
    }

    if (!item || !item.admins || !item.admins.length) {
      if (!showUserIcon || item.deletedAt) {
        return '';
      }

      return (
        <a
          className="tooltip-icon-add"
          onClick={(ev) => {
            onAssign();
            ev.stopPropagation();
            ev.preventDefault();
          }}
        >
          <i className="fa fa-user-plus" />
        </a>
      );
    }

    const createdBy = item.admins.find(
      (a) => Number(a.id) === Number(item.createdBy),
    );
    const primaryWriter = item.admins.find(
      (a) => Number(a.id) === Number(item.assignmentPrimaryWriter),
    );
    let secondaryWriter = item.admins.filter(
      (a) =>
        Number(a.id) !== Number(item.assignmentPrimaryWriter) &&
        Number(a.id) !== Number(item.createdBy),
    );
    if (secondaryWriter.length) {
      secondaryWriter = secondaryWriter.map((a) => `${a.name} ${a.lastName}`);
    }

    return (
      <a
        className="tooltip-icon"
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
        }}
        data-tooltip-content=""
        data-tooltip-id={`${item.id}-assigned`}
      >
        <i className="fa fa-user" />
        <ReactTooltip
          id={`${item.id}-assigned`}
          place="bottom"
          variant="light"
          className="react-tooltip"
        >
          {createdBy && (
            <span>
              <div className="tooltip-title">Assigned by</div>
              <div className="tooltip-text">
                {createdBy.name} {createdBy.lastName} -{' '}
                {moment(Number(item.assignmentCreatedAt)).format('L')}
              </div>
            </span>
          )}
          {primaryWriter && (
            <span>
              <div className="tooltip-title">Primary writer</div>
              <div className="tooltip-text">
                {primaryWriter.name} {primaryWriter.lastName}
              </div>
            </span>
          )}
          {secondaryWriter.length > 0 && (
            <span>
              <div className="tooltip-title">Secondary writer(s)</div>
              <div className="tooltip-text">{secondaryWriter.join()}</div>
            </span>
          )}
        </ReactTooltip>
      </a>
    );
  };

  render() {
    const {
      item,
      onPlusButton,
      openEditModal,
      rolesPermissions,
      isSuperAdmin,
      selectedRate,
      level,
      generalRolesPermissions,
      onUpdatePdf,
      setLoadingPdf,
      onAssign,
      references = [],
      onDownloadCSV,
      onDownloadXLSX,
      onDownloadXLS,
      openViewReferenceActivityModal,
      openViewChapterActivityModal,
      openDuplicateChapterModal,
      isChapter,
      openCreateAIquestionForChapterModal,
      openSummaryViewModal,
      user,
    } = this.props;
    let canCreateGPTQuestions = false;
    if (user) {
      canCreateGPTQuestions = isGptQuestionGenerator(
        user.roles,
        user.isSuperAdmin,
      );
    }
    let options = [
      {
        name: 'Edit',
        event: openEditModal,
      },
    ];

    if (!isChapter) {
      options.push({
        name: 'View Reference Activity',
        event: openViewReferenceActivityModal,
      });
    } else {
      options.push({
        name: 'Duplicate',
        event: openDuplicateChapterModal,
      });
      options.push({
        name: 'View Chapter Activity',
        event: openViewChapterActivityModal,
      });

      if (canCreateGPTQuestions) {
        options.push({
          name: 'Create GPT/AI question',
          event: openCreateAIquestionForChapterModal,
        });
      }
    }
    if (selectedRate && selectedRate.id !== 0) {
      options.push({
        name: 'Assign Writer',
        event: onAssign,
      });
    }
    if (generalRolesPermissions || isSuperAdmin) {
      const permission = hasDownloadCSV(generalRolesPermissions);
      const rolesPerm = allowRolesPermissions(rolesPermissions);

      if (permission || rolesPerm || isSuperAdmin) {
        options.push(
          {
            name: 'Download CSV',
            event: onDownloadCSV,
          },
          {
            name: 'Download XLSX',
            event: onDownloadXLSX,
          },
          {
            name: 'Download XLS',
            event: onDownloadXLS,
          },
        );
      }
    }

    if (item.deletedAt) {
      options = [
        {
          name: 'Restore',
          event: onDownloadCSV,
        },
      ];
    }

    let canAssign =
      isSuperAdmin ||
      hasPagePermission(
        'Wheelhouse.Assignments',
        generalRolesPermissions || [],
      );
    if (!canAssign) {
      const reference =
        level === 1 ? item : references.find((r) => r.id === item.reference);
      for (const sp of reference.studyPrograms) {
        canAssign =
          canAssign ||
          hasPermissions('Assign', rolesPermissions || [], sp.typeInstance);
      }
    }
    let showUserIcon = level === 1 ? !item.actAsParent : true;
    showUserIcon = showUserIcon && canAssign;

    let spList = [];

    if (
      item.studyPrograms &&
      item.studyPrograms &&
      item.studyPrograms.length > 10
    ) {
      spList = item.studyPrograms.sort((a, b) => {
        let short1 = 1;
        if (a.typeInstance) {
          short1 = a.typeInstance.shortname
            ? a.typeInstance.shortname
            : a.typeInstance.description;
        }
        let short2 = 1;
        if (b.typeInstance) {
          short2 = b.typeInstance.shortname
            ? b.typeInstance.shortname
            : b.typeInstance.description;
        }

        return short1.localeCompare(short2);
      });
    }

    return (
      <span>
        <span
          ref={`itemName-${item.id}`}
          id={`itemName-${item.id}`}
          className="itemName"
        >
          {getItemName(item)}
        </span>
        <span className="tree-options">
          {item.studyPrograms && item.studyPrograms.length > 0 ? (
            <span
              data-tooltip-content=""
              data-tooltip-id={`${item.id}-tooltip`}
              ref={`sp-${item.id}`}
              className={
                item.studyPrograms && item.studyPrograms.length > 0
                  ? item.studyPrograms.length > 10
                    ? 'studyProgramsContainer centerSPs'
                    : 'studyProgramsContainer centerSPs withReactTooltip'
                  : ''
              }
              onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                onPlusButton();
                ev.preventDefault();
              }}
            >
              {item.studyPrograms &&
                item.studyPrograms.length > 0 &&
                renderStudyPrograms(item)}
            </span>
          ) : (
            level !== 2 && (
              <span
                className="associateRatePlus"
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.preventDefault();
                  onPlusButton();
                  ev.preventDefault();
                }}
              >
                {onPlusButton && <i className="fa fa-plus icon-button" />}
              </span>
            )
          )}
          {item.chapters && item.actAsParent && (
            <span className="chapters-qty">
              {`${item.chapters.length} Chapters`}
            </span>
          )}
          {this.renderQuestionCount(item, selectedRate)}
          {this.renderAssignedIcon(item, showUserIcon, onAssign)}
          {this.getPdfImage(item, onUpdatePdf, setLoadingPdf)}
          <DropDown
            data-test="reference-option-dropdown"
            options={options}
            shouldCloseAfter
            containerStyle={{
              right: '35px',
              zIndex: '5',
              marginTop: '-5px',
            }}
          />
        </span>
        {item.studyPrograms && item.studyPrograms.length > 10 && (
          <ReactTooltip
            id={`${item.id}-tooltip`}
            place="bottom"
            variant="light"
            className="react-tooltip"
            style={{ zIndex: 9999 }}
          >
            <span>
              <div className="tooltip-title">Associated Rates</div>
              <div className="tooltip-text rates-tooltip">
                {spList.map((sp, index) => (
                  <span className="tooltip-span" key={index}>
                    {`${
                      sp.typeInstance
                        ? sp.typeInstance.shortname
                          ? sp.typeInstance.shortname
                          : sp.typeInstance.description
                        : ''
                    }   `}
                  </span>
                ))}
              </div>
            </span>
          </ReactTooltip>
        )}
      </span>
    );
  }
}

const getActions = (props) => {
  let rate = { id: 0 };
  const {
    item,
    rolesPermissions,
    isSuperAdmin,

    generalRolesPermissions,
    historyObject,
    isQual,
    references,
    selectedRate,
  } = props;
  let pathname = '';
  let action = props.onClick;
  if (props.level === 2 || !item.actAsParent) {
    if (rate.id === 0 && selectedRate && selectedRate.id) {
      rate = selectedRate;
    }
    const chapter = item;
    let canSeeLink = isSuperAdmin;
    if (!canSeeLink && generalRolesPermissions) {
      canSeeLink = isQual
        ? hasPagePermission('Quals', generalRolesPermissions)
        : hasPagePermission('Books.View', generalRolesPermissions);
    }

    if (!canSeeLink && rolesPermissions) {
      const type = isQual ? 'qual' : 'rate';
      canSeeLink = rolesPermissions.find(
        (role) =>
          role.type === type &&
          role.typeId === rate.id.toString() &&
          role.actions.find((act) => act === 'Books.View'),
      );
    }

    if (canSeeLink) {
      let referenceId = chapter.reference;
      let chapterId = chapter.id;

      if (!chapter.reference) {
        referenceId = chapter.id;
        chapterId =
          chapter.chapters && chapter.chapters.length > 0
            ? chapter.chapters[0].id
            : 0;
      }

      const qual = isQual ? '/true' : '/false';
      const path = `/home/questions/${referenceId}/${chapterId}/${rate.id}${qual}`;
      const history = {
        referenceId,
      };
      pathname = {
        pathname: path,
        state: { selectedRate: rate, historyObject, isQual },
      };

      action = () => {
        sessionStorage.setItem('books_page_history', JSON.stringify(history));
      };
      return { pathname, action };
    }
  }
  return { pathname, action };
};

class ReferenceItem extends React.Component {
  state = {
    dragged_over: false,
    being_dragged: false,
  };

  dragStart = (e) => {
    this.dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData(
      'text',
      `${this.dragged.dataset.index}b${this.dragged.dataset.bookindex}`,
    );
  };

  dragEnd = (e) => {
    this.setState({ being_dragged: false });
    this.dragged = null;
  };

  drop = (e) => {
    e.preventDefault();
    this.over = e.currentTarget;
    this.over.style.border = '';
    const data_string = e.dataTransfer.getData('text');
    const b = data_string.indexOf('b');
    const data = parseInt(data_string.substr(0, b), 10);
    const book_data = parseInt(data_string.substr(b + 1), 10);
    this.setState({ dragged_over: false });
    if (book_data === this.props.book_index) {
      this.props.onUpdateIndex(
        this.props.allChapters[data - 1],
        data,
        (this.props.index + 1 > data
          ? this.props.index + 1
          : this.props.index + 2) || 1,
      );
    }
  };

  dragOver = (e) => {
    e.preventDefault();
    if (this.dragged) {
      this.setState({ being_dragged: true });
    } else {
      this.setState({ dragged_over: true });
    }
  };

  dragLeave = (e) => {
    e.preventDefault();
    this.setState({ dragged_over: false });
  };

  saveScrollPosition = (action, isChapter, id) => {
    if (!this.props.isQual) {
      const sidebarScroll = document.querySelector('.rolesTable').scrollTop;
      const mainScroll = document.querySelector('.infinite-scroll').scrollTop;
      this.props.setMainScroll(mainScroll || 0);
      this.props.setSidebarScroll(sidebarScroll || 0);
      if (!isChapter) {
        this.props.setLastBookOpenPerRate(
          this.props.rateId,
          this.props.item.id,
        );
        this.props.setLastSelectedTreeView(null);
      } else {
        this.props.setLastSelectedTreeView(this.props.book_index);
      }
      this.props.setLastSelectedPanel(id);
    }
    if (action) {
      action();
    }
  };

  render() {
    const goTo = getActions(this.props);

    const mode = this.props.item.mode || 'normal';
    return (
      <div className="mt-1" style={{ width: '100%' }}>
        {this.props.item.mode === 'add' && (
          <ReferenceItemAddMode {...this.props} />
        )}
        <Link
          to={goTo.pathname}
          id={this.props.id}
          className={
            this.props.item.id
              ? (this.props.isReference
                  ? `list-group-item tree-level-${this.props.level}`
                  : this.props.item.mode === 'add'
                    ? `list-group-item tree-level-${this.props.level} addChapterBox`
                    : `list-group-item tree-level-${this.props.level}`) +
                (this.state.dragged_over ? ' dragged-over' : '') +
                (this.state.being_dragged ? ' being-dragged' : '')
              : 'chapterDisable'
          }
          data-index={`${this.props.index + 1}`}
          data-bookindex={this.props.book_index}
          onClick={() =>
            this.saveScrollPosition(
              goTo.action,
              this.props.isChapter,
              this.props.id,
            )
          }
          draggable={this.props.level === 2 ? 'true' : ''}
          onDragEnd={this.props.level === 2 ? this.dragEnd : () => {}}
          onDrop={this.props.level === 2 ? this.drop : () => {}}
          onDragOver={this.props.level === 2 ? this.dragOver : () => {}}
          onDragLeave={this.props.level === 2 ? this.dragLeave : () => {}}
          onDragStart={this.props.level === 2 ? this.dragStart : () => {}}
        >
          {mode === 'normal' && <ReferenceItemNormalMode {...this.props} />}
          {mode === 'edit' && <ReferenceItemEditMode {...this.props} />}
        </Link>
      </div>
    );
  }
}

export default ReferenceItem;
