import React from 'react';
import './Card.css';
import { Row } from 'react-bootstrap';
import DropDown from '../DropDown/DropDown';

export default class Card extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="cardContainer">
        <Row
          style={{
            width: '100%',
            marginRight: '0px',
            marginLeft: '0px',
          }}
        >
          <div className="header">
            {this.props.title}
            {this.props.date && (
              <DropDown
                title="Calendar"
                options={this.props.datesArray}
                isDate={true}
              />
            )}
            <DropDown title={this.props.title} options={this.props.options} />
            {this.props.activityText && (
              <div className="activityHeader">{this.props.activityText}</div>
            )}
          </div>
        </Row>
        {this.props.content}
        {this.props.footer}
      </div>
    );
  }
}
