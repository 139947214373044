import { connect } from 'react-redux';

import {
  clearIntercomActiveUsersAPIError,
  clearIntercomActiveUsersReportProps,
  getIntercomActiveUsersReport,
} from '../../actions';
import {
  getAPIErrorIntercomActiveUsersReport,
  getIntercomActiveUsersReportCSVData,
  getIntercomActiveUsersReportHasMore,
  getlastCustomerSubscriptionIntercomActiveUsersReport,
} from '../../selectors';
import intercomActiveUsersReport from './IntercomActiveUsersReport';

const mapStateToProps = (state) => ({
  lastCustomerSubscription:
    getlastCustomerSubscriptionIntercomActiveUsersReport(state),
  hasMore: getIntercomActiveUsersReportHasMore(state),
  CSVData: getIntercomActiveUsersReportCSVData(state),
  APIError: getAPIErrorIntercomActiveUsersReport(state),
});

const mapDispatchToProps = (dispatch) => ({
  getIntercomActiveUsersReport: (lastCustomerSubscription) => {
    dispatch(getIntercomActiveUsersReport(lastCustomerSubscription));
  },
  clearProps: () => {
    dispatch(clearIntercomActiveUsersReportProps());
  },
  clearAPIError: () => {
    dispatch(clearIntercomActiveUsersAPIError());
  },
});

const intercomActiveUsers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(intercomActiveUsersReport);

export default intercomActiveUsers;
