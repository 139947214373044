import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap-button-loader';

import './SimpleConfirmation.css';

class SimpleConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      waiting: false,
    };
  }

  render() {
    const {
      onConfirm,
      onCancel,
      text = 'Are you sure?',
      yesButtonText = 'Yes, do it',
      noButtonText = 'No, go back',
      greenButton,
      showYesButton = true,
    } = this.props;

    return (
      <div className="simple-confirmation-component">
        <div className="card-block">
          <div className="text">
            {text}
          </div>
          <form
            className="widget-form simple-confirmation-form"
            onSubmit={ev => {
              ev.preventDefault();
              this.setState({ waiting: true });
              onConfirm();
            }}
          >
            {this.state.error
              ? <div className="errors">{this.state.error}</div>
              : null}
            <div className="bottom-buttons">
              <Button className="btn btn-secondary" onClick={onCancel}>
                {noButtonText}
              </Button>
              {showYesButton
                ? <Button
                    className={
                      greenButton ? 'btn btn-success' : 'btn btn-danger'
                    }
                    type="submit"
                    loading={this.state.waiting}
                  >
                    {yesButtonText}
                  </Button>
                : ''}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

SimpleConfirmation.propTypes = {
  handleSubmit: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SimpleConfirmation;
