import React, { Component } from 'react';
import { Link } from 'react-router';

class HeaderOption extends Component {
  render() {
    if (!this.props.option.name) return null;

    return (
      <li className="nav-item px-3">
        {this.props.option.route ? (
          <Link
            to={this.props.option.route}
            className={
              this.props.secondHeader
                ? this.props.isActive
                  ? 'nav-link activeSecondHeader'
                  : this.props.secondHeader && 'nav-link'
                : this.props.isActive && !this.props.secondHeader
                ? 'nav-link active'
                : !this.props.secondHeader && 'nav-link'
            }
          >
            {this.props.isActive ? (
              <b>{this.props.option.name}</b>
            ) : (
              this.props.option.name
            )}
          </Link>
        ) : this.props.option.isExternal ? (
          <Link
            to={{ pathname: this.props.option.url }}
            className="nav-link"
            target={this.props.option.target}
          >
            {this.props.option.name}
          </Link>
        ) : (
          this.props.option.name
        )}
      </li>
    );
  }
}

export default HeaderOption;
