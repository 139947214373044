import React from 'react';
import StudyProgramsList from './StudyProgramsList';
import StudyProgramsDetails from './StudyProgramDetails';

export default class StudyPrograms extends React.Component {
  componentWillMount() {
    this.props.loadRates();
    this.props.loadQuals();
  }

  render() {
    return (
      <div className="bibliography-container">
        <StudyProgramsList {...this.props} />
        <StudyProgramsDetails loggedUser={this.props.user} {...this.props} />
      </div>
    );
  }
}
