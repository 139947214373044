import 'react-select2-wrapper/css/select2.css';
import './UpdateSubtopics.css';

import React from 'react';
import Button from 'react-bootstrap-button-loader';
import Select2 from 'react-select2-wrapper';
import { reduxForm } from 'redux-form';
import _ from 'underscore';

import Spinner from '../../Spinner/Spinner';

class UpdateSubtopics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subtopics: this.getSubtopics(),
    };
  }

  componentWillMount() {
    if (
      this.props.chapter &&
      Array.isArray(this.props.chapter?.subtopics) &&
      this.state.subtopics.length > 0
    ) {
      const allSubtopicsOfChapter = this.state.subtopics.filter((subtopic) =>
        this.props.chapter.subtopics.find(
          (subtopicAux) => subtopicAux.id === subtopic.id,
        ),
      );
      this.setState({
        allSubtopicsOfChapter: allSubtopicsOfChapter.map(
          (subtopic) => subtopic.id,
        ),
        subtopicsOfTopic: _.pluck(
          allSubtopicsOfChapter.filter((subtopic) =>
            this.props.chapter.subtopics.find(
              (subtopicAux) => subtopicAux.id === subtopic.id,
            ),
          ),
          'id',
        ),
        selectedTopic: this.props.topics[0].id,
      });
    }
  }

  componentWillUnmount() {}

  componentWillReceiveProps() {}

  componentDidMount() {
    const aux = this.props.selectedRateObject
      ? this.props.selectedRateObject
      : this.props.selectedAssignment
        ? this.props.selectedAssignment.studyProgram.typeId
        : null;

    if (this.props.topics[0]) {
      this.props.loadRate(this.props.topics[0].rate);
    } else {
      if (this.props.topics[0]) {
        this.props.loadRate(aux);
      } else {
        if (this.props.rateId !== undefined) {
          this.props.loadRate(this.props.rateId);
        } else {
          this.props.loadRate(this.props.selectedAssignment.studyProgram);
        }
      }
    }
  }

  getSubtopics = () => {
    let subtopics = [];
    if (this.props.topics.length > 0) {
      this.props.topics.map((topic) => {
        subtopics = subtopics.concat(topic.subtopics);
        return topic;
      });
    }
    return subtopics;
  };

  submitSubtopics = () => {
    const stateSubsIds = this.state.subtopics.map((s) => s.id);
    const checkedAndNotInList = this.props.chapter.subtopics
      .map((s) => s.id)
      .filter((s) => !stateSubsIds.includes(s));
    this.props.updateChapterSubtopics(
      this.props.reference,
      this.props.chapter.id,
      this.state.allSubtopicsOfChapter.concat(checkedAndNotInList),
    );
    this.props.closeModal();
  };

  selectTopic = (id) => {
    this.setState({
      subtopicsOfTopic: _.pluck(
        this.state.subtopics.filter(
          (subtopic) =>
            subtopic.topic === parseInt(id, 10) &&
            this.props.chapter.subtopics.find(
              (subtopicAux) => subtopicAux.id === subtopic.id,
            ),
        ),
        'id',
      ),
      selectedTopic: parseInt(id, 10),
    });
  };

  selectSubtopicsOfTopic = (subtopicId) => {
    const idToAdd = parseInt(subtopicId, 10);
    const allSubtopicsOfChapter = this.state.allSubtopicsOfChapter;
    if (allSubtopicsOfChapter.indexOf(idToAdd) === -1)
      allSubtopicsOfChapter.push(idToAdd);
    if (this.state.subtopicsOfTopic.indexOf(idToAdd) < 0) {
      const subtopicsOfTopic = this.state.subtopicsOfTopic;
      subtopicsOfTopic.push(idToAdd);
      this.setState({
        subtopicsOfTopic,
        allSubtopicsOfChapter,
      });
    }
  };

  unselectSubtopicsOfTopic = (subtopicId) => {
    const idToRemove = parseInt(subtopicId, 10);
    const subtopicsOfTopic = this.state.subtopicsOfTopic.filter(
      (subtopic) => subtopic !== idToRemove,
    );
    this.setState({
      subtopicsOfTopic,
      allSubtopicsOfChapter: this.state.allSubtopicsOfChapter.filter(
        (id) => id !== idToRemove,
      ),
    });
  };

  renderSubtopicLine = (subtopic, subtopicsOfTopic) => {
    const { topics } = this.props;
    const currentTopic = topics.find((t) => t.id === subtopic.topic);
    return (
      <label className="subtopicsContainer">
        <input
          type="checkbox"
          className="subtopicCheckbox"
          onChange={(e) => {
            if (subtopicsOfTopic.find((stopic) => stopic === subtopic.id))
              this.unselectSubtopicsOfTopic(subtopic.id);
            else this.selectSubtopicsOfTopic(subtopic.id);
          }}
          checked={!!subtopicsOfTopic.find((stopic) => stopic === subtopic.id)}
        />
        <span>
          {currentTopic ? `${currentTopic.name} ` : ''}
          <i className="fa fa-long-arrow-right" />
          {` ${subtopic.name}`}
        </span>
      </label>
    );
  };

  render() {
    const { closeModal, rateForSubtopics, selectedRate } = this.props;
    const possibleSubtopics = this.state.subtopics;
    const noSubtopicsText = 'There are no subtopics';
    let subtopicsActiveCycle = [];
    let subtopicsInactiveCycle = [];

    if (possibleSubtopics && possibleSubtopics.length > 0) {
      subtopicsActiveCycle = possibleSubtopics.filter(
        (s) => s.isActiveCycleSubtopic === true,
      );
      subtopicsInactiveCycle = possibleSubtopics.filter(
        (s) => s.isActiveCycleSubtopic === false,
      );
    }

    return (
      <div className="update-subtopics-component">
        <div className="card-block">
          {this.props.isWaiting ? (
            <Spinner text="Loading subtopics" />
          ) : this.state.subtopics.length > 0 ? (
            <form className="widget-form new-admin-form">
              <div className="form-group">
                <span className="label labelPosition">Rate:</span>
                <br />
                <span>
                  {rateForSubtopics
                    ? `(${rateForSubtopics.shortname}) ${rateForSubtopics.name}`
                    : ''}
                </span>
              </div>
              <div className="form-group scrollableList">
                {subtopicsActiveCycle.length > 0 && (
                  <div className="subtopics-title-container">
                    <span className="label labelPosition">
                      Active Subtopics:
                    </span>
                    <i
                      className="fa fa-question-circle fa-lg"
                      data-toggle="tooltip"
                      title={`Subtopics enabled for any current ${
                        rateForSubtopics ? rateForSubtopics.shortname : ''
                      } rating exam`}
                    />
                  </div>
                )}
                {subtopicsActiveCycle.length > 0 &&
                  subtopicsActiveCycle.map((subtopic) =>
                    this.renderSubtopicLine(
                      subtopic,
                      this.state.subtopicsOfTopic,
                    ),
                  )}
                {subtopicsInactiveCycle.length > 0 && (
                  <div className="subtopics-title-container">
                    <span className="label labelPosition">
                      Inactive Subtopics:
                    </span>
                    <i
                      className="fa fa-question-circle fa-lg"
                      data-toggle="tooltip"
                      title={`Subtopics not enabled for any current ${
                        rateForSubtopics ? rateForSubtopics.shortname : ''
                      } rating exam`}
                    />
                  </div>
                )}
                {subtopicsInactiveCycle.length > 0 &&
                  subtopicsInactiveCycle.map((subtopic) =>
                    this.renderSubtopicLine(
                      subtopic,
                      this.state.subtopicsOfTopic,
                    ),
                  )}
                {subtopicsInactiveCycle.length === 0 &&
                  subtopicsActiveCycle.length === 0 && (
                    <span className="label labelPosition">
                      Inactive Subtopics:
                    </span>
                  )}
                {subtopicsInactiveCycle.length === 0 &&
                  subtopicsActiveCycle.length === 0 &&
                  possibleSubtopics.length > 0 &&
                  possibleSubtopics.map((subtopic) =>
                    this.renderSubtopicLine(
                      subtopic,
                      this.state.subtopicsOfTopic,
                    ),
                  )}
              </div>
              {this.state.errors && this.state.errors.general && (
                <div className="errors">{this.state.errors.general}</div>
              )}
              <div className="separatorLine" />
              <div className="bottom-buttons">
                <Button
                  className="btn btn-success"
                  onClick={this.submitSubtopics}
                  loading={this.state.isWaiting}
                  disabled={this.state.selectedTopic === 0}
                >
                  Add
                </Button>
                <Button className="btn btn-secondary" onClick={closeModal}>
                  Cancel
                </Button>
              </div>
            </form>
          ) : (
            <div className="center-text">{noSubtopicsText}</div>
          )}
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: 'UpdateSubtopics' })(UpdateSubtopics);
