import { connect } from 'react-redux';

import {
  setIsLoadingSubmittedQuestions,
  setRate,
  setUserSubmittedQuestions,
} from './actions';
import {
  approveSubmittedQuestion,
  deleteSubmittedQuestion,
  editSubmittedQuestion,
  getAllRates,
  getFavoriteQuestions,
  getUserDeletedSubmittedQuestions,
  getUserSubmittedQuestions,
  rejectSubmittedQuestion,
  removeFavoriteQuestion,
  undeleteSubmittedQuestion,
} from './actionsSagas';
import CustomerSubmissionView from './CustomerSubmissionView';
import {
  getAllRatesSelectors,
  getFavoriteQuestionsSelector,
  getIsLoadingFavoriteQuestionsSelector,
  getIsLoadingSubmittedQuestionsSelector,
  getIsMoreFavoriteQuestionsSelector,
  getRateSelector,
  getUserSubmittedQuestionsSelector,
} from './selectors';

const mapStateToProps = (state) => ({
  rates: getAllRatesSelectors(state),
  selectedRate: getRateSelector(state),
  userSubmittedQuestions: getUserSubmittedQuestionsSelector(state),
  isLoadingSubmittedQuestions: getIsLoadingSubmittedQuestionsSelector(state),
  favoriteQuestions: getFavoriteQuestionsSelector(state),
  isMoreFavoriteQuestions: getIsMoreFavoriteQuestionsSelector(state),
  isLoadingFavoriteQuestions: getIsLoadingFavoriteQuestionsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAllRates: () => dispatch(getAllRates()),
  setRate: (rate) => dispatch(setRate(rate)),
  getUserSubmittedQuestions: (rateId) =>
    dispatch(getUserSubmittedQuestions(rateId)),
  resetUserSubmittedQuestions: () => dispatch(setUserSubmittedQuestions([])),
  setIsLoadingSubmittedQuestions: (status) =>
    dispatch(setIsLoadingSubmittedQuestions(status)),
  deleteSubmittedQuestion: (questionId, questionRate, timestamp) =>
    dispatch(deleteSubmittedQuestion(questionId, questionRate, timestamp)),
  approveSubmittedQuestion: (questionId, rateId, timestamp) =>
    dispatch(approveSubmittedQuestion(questionId, rateId, timestamp)),
  rejectSubmittedQuestion: (questionId, rateId, timestamp) =>
    dispatch(rejectSubmittedQuestion(questionId, rateId, timestamp)),
  editSubmittedQuestion: (questionId, question, rateId, timestamp) =>
    dispatch(editSubmittedQuestion(questionId, question, rateId, timestamp)),
  getFavoriteQuestions: (rateId, limit, skip) =>
    dispatch(getFavoriteQuestions(rateId, limit, skip)),
  removeFavoriteQuestion: (rateId, questions) =>
    dispatch(removeFavoriteQuestion(rateId, questions)),
  getUserDeletedSubmittedQuestions: (rateId) =>
    dispatch(getUserDeletedSubmittedQuestions(rateId)),
  undeleteSubmittedQuestion: (questionId, userId, updatedAt, rateId) =>
    dispatch(undeleteSubmittedQuestion(questionId, userId, updatedAt, rateId)),
});

const CustomerSubmissionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CustomerSubmissionView);

export default CustomerSubmissionContainer;
