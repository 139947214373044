import { connect } from 'react-redux';
import {
  getWriters,
  getChapterWriters,
  getWaiting,
  getReferences,
  getAssignment,
} from '../selectors';
import AssignWriters from '../../../commonComponents/Modals/AssignWriters/AssignWriters';
import {
  loadWriters,
  assignWriters,
  getChapterWritersSaga,
  createAssignment,
  loadReferencesOfStudyProgram,
  loadAssignment,
  updateAssignment,
} from '../actionsSagas';
import { updateWaiting, clearWriters } from '../actions';
import { getUser } from '../../../components/Login/selectors';
import {
  getSelectedRate,
  getAssignments,
} from '../../../components/Assignments/selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    writers: getWriters(state),
    assignments: getAssignments(state),
    isWaiting: getWaiting(state),
    chapterWriters: getChapterWriters(state),
    references: getReferences(state),
    chapter: ownProps.chapter,
    rate: ownProps.rate,
    reference: ownProps.reference,
    closeModal: ownProps.closeModal,
    isQual: ownProps.isQual,
    assign: ownProps.assign,
    forAssign: ownProps.forAssign,
    assignmentId: ownProps.assignment,
    loggedUser: getUser(state),
    assignment: getAssignment(state),
    selectedUser: ownProps.selectedUser,
    selectedStudyProgram: getSelectedRate(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoad: (type, typeId, chapter) => {
      dispatch(updateWaiting(true));
      dispatch(loadWriters(type, typeId));
      dispatch(getChapterWritersSaga(chapter));
      dispatch(loadReferencesOfStudyProgram(type, typeId));
    },
    onLoadAssignment: (type, typeId) => {
      dispatch(updateWaiting(true));
      dispatch(loadWriters(type, typeId));
      dispatch(loadReferencesOfStudyProgram(type, typeId));
    },
    onLoadReassign: assigment => {
      dispatch(updateWaiting(true));
      dispatch(loadAssignment(assigment));
    },
    loadWritersOfChapter: chapter => {
      dispatch(getChapterWritersSaga(chapter));
    },
    onAssignWriters: (chapter, primaryWriter, secondaryWriters, reference) => {
      dispatch(
        assignWriters(chapter, primaryWriter, secondaryWriters, reference)
      );
    },
    clearWriters: () => {
      dispatch(clearWriters());
    },
    createAssignment: (
      chapter,
      primaryWriter,
      secondaryWriters,
      dueDate,
      status,
      createdBy,
      studyProgram
    ) => {
      dispatch(
        createAssignment(
          chapter,
          primaryWriter,
          secondaryWriters,
          dueDate,
          status,
          createdBy,
          studyProgram
        )
      );
    },
    updateAssignment: (assigment, primaryWriter, secondaryWriters, selectedWriterEmail) => {
      if(selectedWriterEmail) {
        dispatch(updateAssignment(assigment, primaryWriter, secondaryWriters, selectedWriterEmail));
      } else {
        dispatch(updateAssignment(assigment, primaryWriter, secondaryWriters));
      }
    },
  };
};

const WritersContainer = connect(mapStateToProps, mapDispatchToProps)(
  AssignWriters
);

export default WritersContainer;
