import { connect } from 'react-redux';
import addAbilityToOpenModal from '../../../../utilities/modalHOC';
import CodeGroupList from './CodeGroupList';
import {
  getIsLoadingCodeGroups,
  getCodeGroups,
  getSelectedCoupon,
  getCodeGroup,
  getIsGettingCodeGroup,
  getSelectedCodeGroup,
  getRedeemedCodes,
  getUnredeemedCodes,
} from '../../selectors';
import { setUnredeemedCodes } from '../../actions';
import {
  loadCodeGroups,
  createCodeGroup,
  cancelAllCodes,
  editCodeGroup,
  editCoupon,
  loadCodeGroup,
  cancelCode,
  loadRedeems,
  downloadUnredeemedCodes,
} from '../../actionsSagas';
import { setSelectedCodeGroup, cleanCodeGroup } from '../../actions';
import { loadRates } from '../../../ChangeExam/actionsSagas';
import { getRates } from '../../../ChangeExam/selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    codeGroups: getCodeGroups(state),
    codeGroup: getCodeGroup(state),
    loading: getIsLoadingCodeGroups(state),
    selectedCoupon: getSelectedCoupon(state),
    isLoadingCodeGroups: getIsLoadingCodeGroups(state),
    rates: getRates(state),
    isGettingCodeGroup: getIsGettingCodeGroup(state),
    selectedCodeGroup: getSelectedCodeGroup(state),
    redeemedCodes: getRedeemedCodes(state),
    unredeemedCodes: getUnredeemedCodes(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCodeGroups: coupon => dispatch(loadCodeGroups(coupon)),
    onCreateCodeGroup: codeGroup => dispatch(createCodeGroup(codeGroup)),
    loadRates: () => dispatch(loadRates()),
    onCancelAll: codeGroup => dispatch(cancelAllCodes(codeGroup)),
    onEditGroupCode: codeGroup => dispatch(editCodeGroup(codeGroup)),
    onEditCoupon: coupon => dispatch(editCoupon(coupon)),
    onSelectCodeGroup: (codeGroup, fromValue, toValue) =>
      dispatch(loadCodeGroup(codeGroup, fromValue, toValue)),
    onSelectSelectedCodeGroup: selectedCodeGroup =>
      dispatch(setSelectedCodeGroup(selectedCodeGroup)),
    onCancelCode: (code, codeGroup, fromValue, toValue) =>
      dispatch(cancelCode(code, codeGroup, fromValue, toValue)),
    loadRedeems: codeGroup => dispatch(loadRedeems(codeGroup)),
    cleanCodeGroup: () => dispatch(cleanCodeGroup),
    downloadUnredeemed: codeGroup =>
      dispatch(downloadUnredeemedCodes(codeGroup)),
    onClearUnredeemed: () => dispatch(setUnredeemedCodes()),
  };
};

const CodeGroupListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CodeGroupList);

export default addAbilityToOpenModal(CodeGroupListContainer);
