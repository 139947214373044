import * as Sentry from '@sentry/browser';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { SENTRY_URL } from './constants/links';
import Root from './containers/Root/Root';
import configureStore from './store/configureStore';

const config = configureStore();
export const store = config.store;

Sentry.init({
  dsn: SENTRY_URL,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
});

const persistOnLocal = localStorage.getItem('persist:root');

if (persistOnLocal) {
  localStorage.removeItem('persist:root');
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Root store={config.store} persistor={config.persistor} />);
