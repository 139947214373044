import React from 'react';
import { connect } from 'react-redux';
import GroupsListContainer from './GroupsList/GroupsListContainer';
import StatusFiltersContainer from './StatusFilters/StatusFiltersContainer';
import AssignemntsContainer from './Assignemnts/AssignemntsContainer';
import { Row, Col } from 'react-bootstrap';
import {
  getSelectedAssignment,
  getIsQuestionOverview,
  getSelectedRate,
  getIsViewRates,
} from '../selectors';
import { clearSelectedAssignment } from '../actions';
import QuestionsContainer from '../../Questions/components/QuestionsContainer';
import ReviewHistoryContainer from '../../ReviewHistory/components/ReviewHistoryContainer';
import { withRouter } from 'react-router';

export class AssignmentsPage extends React.Component {
  componentWillUnmount() {
    this.props.clearSelectedAssignment();
  }

  getQuestionComponent = () => {
    if (
      this.props.isQuestionOverview &&
      this.props.selectedAssignment.status === 'Submitted'
    ) {
      return (
        <ReviewHistoryContainer
          assignment={this.props.selectedAssignment}
          isQual={!this.props.isViewRates}
        />
      );
    }
    let reference = {};
    if (
      this.props.selectedAssignment &&
      this.props.selectedAssignment.chapter &&
      this.props.selectedAssignment.chapter.reference
    ) {
      reference = this.props.selectedAssignment.chapter.reference;
    }
    return (
      <QuestionsContainer
        assignment={this.props.selectedAssignment}
        selectedRate={this.props.selectedRate.id}
        isQual={!this.props.isViewRates}
        reference={reference}
      />
    );
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        {this.props.selectedAssignment ? (
          this.getQuestionComponent()
        ) : (
          <Row style={{ height: '100%' }}>
            <GroupsListContainer />
            <Col
              md={9}
              style={{
                flex: 1,
                paddingRight: 0,
                paddingLeft: 0,
                height: '90%',
                overflow: 'auto',
              }}
            >
              <StatusFiltersContainer />
              <AssignemntsContainer />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedAssignment: getSelectedAssignment(state),
    isQuestionOverview: getIsQuestionOverview(state),
    selectedRate: getSelectedRate(state),
    isViewRates: getIsViewRates(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearSelectedAssignment: () => {
      dispatch(clearSelectedAssignment());
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AssignmentsPage)
);
