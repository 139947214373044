import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import Spinner from '../../../commonComponents/Spinner/Spinner';
import SubmittedQuestionItem from './SubmittedQuestionItem';

function SubmittedQuestionsList({
  selectedRateId,
  getUserSubmittedQuestions,
  userSubmittedQuestions,
  isLoadingSubmittedQuestions,
  deleteSubmittedQuestion,
  approveSubmittedQuestion,
  rejectSubmittedQuestion,
  editSubmittedQuestion,
  resetUserSubmittedQuestions,
  setIsLoadingSubmittedQuestions,
  getUserDeletedSubmittedQuestions,
  undeleteSubmittedQuestion,
}) {
  const [isDeletedOnly, setIsDeletedOnly] = useState(false);

  useEffect(() => {
    if (selectedRateId !== undefined) {
      if (isDeletedOnly) {
        getUserDeletedSubmittedQuestions(selectedRateId);
      } else {
        getUserSubmittedQuestions(selectedRateId);
      }
    }
  }, [selectedRateId, isDeletedOnly]);

  const sortedSubmittedQuestions =
    Array.isArray(userSubmittedQuestions) &&
    userSubmittedQuestions.sort(
      (a, b) => Number(b.createdAt) - Number(a.createdAt),
    );

  const questionsWithAnswers = sortedSubmittedQuestions.filter(
    (question) => question.answer.length > 0,
  );

  const unrevisedQuestions = userSubmittedQuestions.filter(
    (question) => question.status === 'Submitted',
  ).length;

  const questionsWithSubmittedStatus = questionsWithAnswers.filter(
    (question) => question.status === 'Submitted',
  );

  const questionsWithoutSubmittedStatus = questionsWithAnswers
    .filter((question) => question.status !== 'Submitted')
    .sort((a, b) => b.status.localeCompare(a.status));

  return (
    <div className="submitted__questions__grids__container">
      {isDeletedOnly ? (
        <div className="questions__submitted__status__container--full">
          <div className="top__container__submitted">
            {userSubmittedQuestions &&
            Array.isArray(userSubmittedQuestions) &&
            userSubmittedQuestions.length > 0 &&
            !isLoadingSubmittedQuestions ? (
              <span className="submitted__questions__header__title">
                Deleted Questions
              </span>
            ) : null}
            <button
              className="btn btn-primary refresh__button"
              disabled={isLoadingSubmittedQuestions}
              onClick={() => {
                setIsLoadingSubmittedQuestions(true);
                resetUserSubmittedQuestions();
                if (isDeletedOnly) {
                  getUserDeletedSubmittedQuestions(selectedRateId);
                } else {
                  getUserSubmittedQuestions(selectedRateId);
                }
              }}
            >
              <i className="fas fa-sync-alt" />
              Refresh
            </button>
            <label className="d-flex flex-row align-items-center btn btn-danger my-auto mr-2">
              <span className="mr-2">View deleted only</span>
              <input
                id="deleted-toggle"
                type="checkbox"
                checked={isDeletedOnly}
                disabled={
                  isLoadingSubmittedQuestions &&
                  userSubmittedQuestions &&
                  Array.isArray(userSubmittedQuestions) &&
                  userSubmittedQuestions.length > 0
                }
                onChange={() => setIsDeletedOnly((prev) => !prev)}
              />
            </label>
            <span className="unrevised__questions">
              Unrevised questions: {unrevisedQuestions}
            </span>
          </div>

          {userSubmittedQuestions &&
          Array.isArray(userSubmittedQuestions) &&
          userSubmittedQuestions.length > 0 &&
          !isLoadingSubmittedQuestions ? (
            <>
              <div className="question__header question__item">
                <Row>
                  <Col md={3}>Question</Col>
                  <Col md={3}>Correct Answer</Col>
                  <Col md={2}>Notes</Col>
                  <Col md={1}>Type</Col>
                  <Col md={1} className="question__item__rate">
                    Rate
                  </Col>
                  <Col md={1}>Status</Col>
                  <Col md={1}>Options</Col>
                </Row>
              </div>
              <div className="question__list__grid__container--full">
                {userSubmittedQuestions &&
                Array.isArray(userSubmittedQuestions) &&
                userSubmittedQuestions.length > 0 ? (
                  userSubmittedQuestions.map((question) => (
                    <SubmittedQuestionItem
                      key={question.id}
                      question={question}
                      selectedRateId={selectedRateId}
                      deleteSubmittedQuestion={deleteSubmittedQuestion}
                      approveSubmittedQuestion={approveSubmittedQuestion}
                      rejectSubmittedQuestion={rejectSubmittedQuestion}
                      editSubmittedQuestion={editSubmittedQuestion}
                      isDeletedOnly={isDeletedOnly}
                      undeleteSubmittedQuestion={undeleteSubmittedQuestion}
                    />
                  ))
                ) : (
                  <span className="ml-3">
                    No submitted questions available.
                  </span>
                )}
              </div>
            </>
          ) : null}
          {isLoadingSubmittedQuestions ? (
            <Spinner text="Loading submitted questions" />
          ) : null}
          {!isLoadingSubmittedQuestions &&
          userSubmittedQuestions.length === 0 ? (
            <span>No user submitted questions available.</span>
          ) : null}
        </div>
      ) : (
        <>
          <div className="questions__submitted__status__container">
            <div className="top__container__submitted">
              {userSubmittedQuestions &&
              Array.isArray(userSubmittedQuestions) &&
              userSubmittedQuestions.length > 0 &&
              !isLoadingSubmittedQuestions ? (
                <span className="submitted__questions__header__title">
                  Submitted Questions
                </span>
              ) : null}
              <button
                className="btn btn-primary refresh__button"
                disabled={isLoadingSubmittedQuestions}
                onClick={() => {
                  setIsLoadingSubmittedQuestions(true);
                  resetUserSubmittedQuestions();
                  if (isDeletedOnly) {
                    getUserDeletedSubmittedQuestions(selectedRateId);
                  } else {
                    getUserSubmittedQuestions(selectedRateId);
                  }
                }}
              >
                <i className="fas fa-sync-alt" />
                Refresh
              </button>
              <label className="d-flex flex-row align-items-center btn btn-danger my-auto mr-2">
                <span className="mr-2">View deleted only</span>
                <input
                  id="deleted-toggle"
                  type="checkbox"
                  checked={isDeletedOnly}
                  disabled={
                    isLoadingSubmittedQuestions &&
                    userSubmittedQuestions &&
                    Array.isArray(userSubmittedQuestions) &&
                    userSubmittedQuestions.length > 0
                  }
                  onChange={() => setIsDeletedOnly((prev) => !prev)}
                />
              </label>
              <span className="unrevised__questions">
                Unrevised questions: {unrevisedQuestions}
              </span>
            </div>

            {userSubmittedQuestions &&
            Array.isArray(userSubmittedQuestions) &&
            userSubmittedQuestions.length > 0 &&
            !isLoadingSubmittedQuestions ? (
              <>
                <div className="question__header question__item">
                  <Row>
                    <Col md={3}>Question</Col>
                    <Col md={3}>Correct Answer</Col>
                    <Col md={2}>Notes</Col>
                    <Col md={1}>Type</Col>
                    <Col md={1} className="question__item__rate">
                      Rate
                    </Col>
                    <Col md={1}>Status</Col>
                    <Col md={1}>Options</Col>
                  </Row>
                </div>
                <div className="question__list__grid__container">
                  {questionsWithSubmittedStatus &&
                  Array.isArray(questionsWithSubmittedStatus) &&
                  questionsWithSubmittedStatus.length > 0 ? (
                    questionsWithSubmittedStatus.map((question) => (
                      <SubmittedQuestionItem
                        key={question.id}
                        question={question}
                        selectedRateId={selectedRateId}
                        deleteSubmittedQuestion={deleteSubmittedQuestion}
                        approveSubmittedQuestion={approveSubmittedQuestion}
                        rejectSubmittedQuestion={rejectSubmittedQuestion}
                        editSubmittedQuestion={editSubmittedQuestion}
                        isDeletedOnly={isDeletedOnly}
                        undeleteSubmittedQuestion={undeleteSubmittedQuestion}
                      />
                    ))
                  ) : (
                    <span className="ml-3">
                      No submitted questions available.
                    </span>
                  )}
                </div>
              </>
            ) : null}
            {isLoadingSubmittedQuestions ? (
              <Spinner text="Loading submitted questions" />
            ) : null}
            {!isLoadingSubmittedQuestions &&
            userSubmittedQuestions.length === 0 ? (
              <span>No user submitted questions available.</span>
            ) : null}
          </div>

          <div className="questions__submitted__status__container">
            <div className="top__container__submitted">
              {userSubmittedQuestions &&
              Array.isArray(userSubmittedQuestions) &&
              userSubmittedQuestions.length > 0 &&
              !isLoadingSubmittedQuestions ? (
                <span className="submitted__questions__header__title">
                  Published/Edited Questions
                </span>
              ) : null}
            </div>
            {userSubmittedQuestions &&
            Array.isArray(userSubmittedQuestions) &&
            userSubmittedQuestions.length > 0 &&
            !isLoadingSubmittedQuestions ? (
              <>
                <div className="question__header question__item">
                  <Row>
                    <Col md={3}>Question</Col>
                    <Col md={3}>Correct Answer</Col>
                    <Col md={2}>Notes</Col>
                    <Col md={1}>Type</Col>
                    <Col md={1} className="question__item__rate">
                      Rate
                    </Col>
                    <Col md={1}>Status</Col>
                    <Col md={1}>Options</Col>
                  </Row>
                </div>
                <div className="question__list__grid__container">
                  {questionsWithoutSubmittedStatus &&
                  Array.isArray(questionsWithoutSubmittedStatus) &&
                  questionsWithoutSubmittedStatus.length > 0 ? (
                    questionsWithoutSubmittedStatus.map((question) => (
                      <SubmittedQuestionItem
                        key={question.id}
                        question={question}
                        selectedRateId={selectedRateId}
                        deleteSubmittedQuestion={deleteSubmittedQuestion}
                        approveSubmittedQuestion={approveSubmittedQuestion}
                        rejectSubmittedQuestion={rejectSubmittedQuestion}
                        editSubmittedQuestion={editSubmittedQuestion}
                        isDeletedOnly={isDeletedOnly}
                        undeleteSubmittedQuestion={undeleteSubmittedQuestion}
                      />
                    ))
                  ) : (
                    <span className="ml-3">
                      No published/edited questions available.
                    </span>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}

export default SubmittedQuestionsList;
