import { connect } from 'react-redux';
import { getChapters } from '../selectors';
import { loadChaptersByRate, updateSubtopicChapters } from '../actionsSagas';
import UpdateSubtopicChapters from '../../../commonComponents/Modals/UpdateChapterSubtopics/UpdateSubtopicChapters';

const mapStateToProps = (state, ownProps) => ({
  subtopic: ownProps.subtopic,
  rate: ownProps.rate,
  onCancel: ownProps.onCancel,
  chapters: getChapters(state),
});

const mapDispatchToProps = dispatch => ({
  loadChaptersByRate: rate => dispatch(loadChaptersByRate(rate)),
  updateSubtopicChapters: (subtopic, checkedChapters) =>
    dispatch(updateSubtopicChapters(subtopic.id, checkedChapters)),
});

const SubtopicChaptersContainer = connect(mapStateToProps, mapDispatchToProps)(
  UpdateSubtopicChapters
);

export default SubtopicChaptersContainer;
