import * as t from './actionTypes';

export const setBooksResponse = (response) => ({
  type: t.SET_BOOKS_RESPONSE,
  response,
});

export const setChaptersResponse = (response) => ({
  type: t.SET_CHAPTERS_RESPONSE,
  response,
});

export const setQuestionsResponse = (response) => ({
  type: t.SET_QUESTIONS_RESPONSE,
  response,
});

export const attachBooksResponse = (response) => ({
  type: t.ATTACH_BOOKS_RESPONSE,
  response,
});

export const attachChaptersResponse = (response) => ({
  type: t.ATTACH_CHAPTERS_RESPONSE,
  response,
});

export const attachQuestionsResponse = (response) => ({
  type: t.ATTACH_QUESTIONS_RESPONSE,
  response,
});

export const setIsSearchingBooks = (value) => ({
  type: t.SET_IS_SEARCHING_BOOKS,
  value,
});

export const setIsSearchingChapters = (value) => ({
  type: t.SET_IS_SEARCHING_CHAPTERS,
  value,
});

export const setIsSearchingQuestions = (value) => ({
  type: t.SET_IS_SEARCHING_QUESTIONS,
  value,
});

export const setSearchSuccess = (value) => ({
  type: t.SET_SEARCH_SUCCES,
  value,
});

export const setSearchError = (value) => ({
  type: t.SET_SEARCH_ERROR,
  value,
});

export const setErrors = (errors) => ({
  type: t.SET_ERRORS,
  errors,
});

export const setQuery = (query) => ({
  type: t.SET_QUERY,
  query,
});

export const setSearchFromHeader = (value) => ({
  type: t.SET_SEARCH_FROM_HEADER,
  value,
});

export const setOptions = (options) => ({
  type: t.SET_OPTIONS,
  options,
});

export const setMoreBooks = (value) => ({
  type: t.SET_MORE_BOOKS,
  value,
});

export const setMoreChapters = (value) => ({
  type: t.SET_MORE_CHAPTERS,
  value,
});

export const setMoreQuestions= (value) => ({
  type: t.SET_MORE_QUESTIONS,
  value,
});