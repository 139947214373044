export const blacklistedReducers = [
  'reports',
  'users',
  'assignments',
  'groups',
  'questions',
  'roles',
  'writers',
  'practiceExams',
];
