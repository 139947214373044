import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import Spinner from '../../../../commonComponents/Spinner/Spinner';

export default class IntercomActiveUsersReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      csvCompleted: false,
      retryAvailable: false,
      step: 0,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.lastCustomerSubscription !==
        prevProps.lastCustomerSubscription &&
      this.props.hasMore &&
      this.state.csvCompleted === false
    ) {
      this.setState({ step: this.state.step + 1 });
      this.props.getIntercomActiveUsersReport(
        this.props.lastCustomerSubscription,
      );
    }

    if (!this.props.hasMore && prevProps.hasMore !== this.props.hasMore) {
      document.getElementById('generate-report-download').click();
      this.state.csvCompleted = true;
      this.state.isLoading = false;
      this.props.clearProps();
    }

    if (
      prevProps.APIError !== this.props.APIError &&
      this.props.APIError === true
    ) {
      this.setState({ retryAvailable: true });
    }
  }

  componentWillUnmount() {
    this.props.clearProps();
  }

  render() {
    return (
      <div>
        {this.state.isLoading && this.state.retryAvailable === false ? (
          <Spinner text={`Working on step ${this.state.step}`} />
        ) : (
          <Row>
            <Col className="d-block col-12">
              <div>
                {this.state.retryAvailable ? (
                  <div
                    className="whiteButtons"
                    onClick={() => {
                      this.props.getIntercomActiveUsersReport(
                        this.props.lastCustomerSubscription,
                      ),
                        this.setState({
                          isLoading: true,
                          retryAvailable: false,
                        }),
                        this.props.clearAPIError();
                    }}
                  >
                    Retry
                  </div>
                ) : (
                  <div
                    className="whiteButtons manuallyReportButtonWidth"
                    onClick={() => {
                      this.props.getIntercomActiveUsersReport(
                        this.props.lastCustomerSubscription,
                        0,
                      ),
                        this.setState({
                          isLoading: true,
                        });
                    }}
                  >
                    Generate Report
                  </div>
                )}
              </div>
            </Col>
          </Row>
        )}
        <div style={{ display: 'none' }}>
          <CSVLink
            id="generate-report-download"
            filename="getIntercomActiveUsersData.csv"
            data={this.props.CSVData}
          >
            Generate Report
          </CSVLink>
        </div>
      </div>
    );
  }
}
