import moment from 'moment';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

function CardRow({
  history,
  setShowDetail,
  setHistorySelectedForDetails,
  lastRow,
}) {
  const renderDate = (date) => {
    const dateString = moment(Number(date));
    return `${dateString.format('MMMM')} ${dateString.format(
      'DD',
    )}, ${dateString.format('YYYY')} ${dateString.format(
      'HH',
    )}:${dateString.format('mm')}`;
  };

  return (
    <Row style={{ width: '100%', padding: '10px' }}>
      <Col xs={9} md={9} lg={9}>
        <div className="">{renderDate(history.updatedAt)}</div>
      </Col>
      <Col xs={3} md={3} lg={3}>
        <Button
          variant="secondary"
          onClick={() => {
            setHistorySelectedForDetails(history.id);
            setShowDetail(true);
          }}
        >
          Detail
        </Button>
      </Col>
      <Col xs={12} md={12} lg={12} style={{ marginBottom: '1rem' }}>
        Question <b>{history.action}</b> by{' '}
        {history.action === 'create' ? (
          <b>{history.createdBy}</b>
        ) : (
          <b>{history.updatedBy}</b>
        )}
      </Col>

      {!lastRow && <div className="separatorLine" />}
    </Row>
  );
}

export default CardRow;
