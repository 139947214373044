import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Button from 'react-bootstrap-button-loader';
import { connect } from 'react-redux';
import {
  getFormErrors as getFormErrorsAdmin,
  getIsWaiting as getIsWaitingAdmin,
} from '../../../components/Admins/selectors';

import {
  getCurrentUserPhone,
  getCurrentUserCc,
} from '../../../components/Login/selectors';
import {
  getFormErrors as getFormErrorsUser,
  getIsWaiting as getIsWaitingUser,
} from '../../../components/Users/selectors';
import { clearErrorFromService as clearErrorFromServiceAdmin } from '../../../components/Admins/actions';
import { updateFormErrors as clearErrorFromServiceUser } from '../../../components/Users/actions';
import './UpdatePhoneNumber.css';
import Input from '../../Input/Input';

class UpdatePhoneNumber extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      cc: '',
      phone: ''
    };
  }

  componentWillUnmount() {
    this.props.clearErrorMessageAdmin();
    this.props.clearErrorMessageUser();
  }

  validate({ phoneNumber, cc, password }, callback, updateUser) {
    let errors = {};

    if (this.props.updatePhoneNumber || !this.props.user.use2FA) {
      if (!phoneNumber) {
        errors.phoneNumber = 'Mandatory field';
      }

      if (phoneNumber && (phoneNumber.length > 12 || phoneNumber.length < 9)) {
        errors.phoneNumberLength =
          'Phone must be between 9 and 12 characters long';
      }

      if (!cc) {
        errors.cc = 'Mandatory field';
      }

      if (cc && cc.length > 3) {
        errors.ccLength = 'At most 3 characters';
      }
    }

    if (!password) {
      errors.password = 'Mandatory field';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: null, waiting: true });

    let fieldsToSend = {};

    if (this.props.updatePhoneNumber) {
      fieldsToSend = {
        phoneNumber: phoneNumber,
        countryCode: cc,
        userId: this.props.user.id,
        password: password,
        action: 'phone',
      };
    } else {
      if (!this.props.user.use2FA) {
        fieldsToSend = {
          phoneNumber: phoneNumber,
          countryCode: cc,
          userId: this.props.user.id,
          password: password,
          action: 'enable',
        };
      } else {
        fieldsToSend = {
          userId: this.props.user.id,
          password: password,
          action: 'disable',
        };
      }
    }

    callback(fieldsToSend);
    setTimeout(function() {
      updateUser();
    }, 3000);
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      onCancel,
      updateUser,
      initialValues,
    } = this.props;
    return (
      <div className="update-phone-number-component">
        <div className="card-block">
          <form
            className="widget-form add-member-in-group-form"
            onSubmit={handleSubmit(fields =>
              this.validate(fields, onSubmit, updateUser)
            )}
          >
            {(!this.props.user.use2FA || this.props.updatePhoneNumber) && (
              <div>
                <div className="row">
                  <div className="col-3">
                    <small> Country code</small>
                    <Field
                      id="cc"
                      name="cc"
                      type="number"
                      component={Input}
                      placeholder={
                        (typeof Number(initialValues.cc) === 'number' && initialValues.cc != null) ? (
                          initialValues.cc
                        ) : (
                          '1'
                        )
                      }
                      defaultValue={
                        (typeof Number(initialValues.cc) === 'number' && initialValues.cc != null) ? (
                          initialValues.cc
                        ) : (
                          1
                        )
                      }
                    />
                    {this.state.errors &&
                    this.state.errors.cc && (
                      <small className="errors">{this.state.errors.cc}</small>
                    )}
                    {this.state.errors &&
                    this.state.errors.ccLength && (
                      <small className="errors">
                        {this.state.errors.ccLength}
                      </small>
                    )}
                  </div>
                  <div className="col-9">
                    <small> Phone number</small>
                    <Field
                      id="phoneNumber"
                      name="phoneNumber"
                      type="phoneNumber"
                      component={Input}
                      icon="phone"
                      placeholder={
                        (typeof Number(initialValues.phoneNumber) === 'number' && initialValues.phoneNumber!= null) ? (
                          initialValues.phoneNumber
                        ) : (
                          '5417543010'
                        )
                      }
                      defaultValue={
                        (typeof Number(initialValues.phoneNumber) === 'number' && initialValues.phoneNumber!= null)  ? (
                          initialValues.phoneNumber
                        ) : (
                          ''
                        )
                      }
                    />
                    {this.state.errors &&
                    this.state.errors.phoneNumber && (
                      <small className="errors">
                        {this.state.errors.phoneNumber}
                      </small>
                    )}
                    {this.state.errors &&
                    this.state.errors.phoneNumberLength && (
                      <small className="errors">
                        {this.state.errors.phoneNumberLength}
                      </small>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className="text">
                Enter your current password for complete this action
              </div>
              <div>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  component={Input}
                  placeholder="**********"
                  defaultValue=""
                />
                {this.state.errors &&
                this.state.errors.password && (
                  <small className="errors">{this.state.errors.password}</small>
                )}
              </div>
            </div>

            <div className="buttons">
              <Button className="btn btn-secondary ml-0" onClick={onCancel}>
                Cancel
              </Button>
              <Button className="btn btn-success" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

UpdatePhoneNumber.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  requesting: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    formErrorsAdmin: getFormErrorsAdmin(state),
    isWaitingAdmin: getIsWaitingAdmin(state),
    formErrorsUser: getFormErrorsUser(state),
    isWaitingUser: getIsWaitingUser(state),
    initialValues: {
      phoneNumber: getCurrentUserPhone(state),
      cc: getCurrentUserCc(state),
    },
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearErrorMessageAdmin: () => {
      dispatch(clearErrorFromServiceAdmin());
    },
    clearErrorMessageUser: () => {
      dispatch(clearErrorFromServiceUser());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({ form: 'UpdatePhoneNumber', enableReinitialize: true })(
    UpdatePhoneNumber
  )
);
