import { Map, List } from 'immutable';

import * as type from './actionTypes';

const initialState = Map({
  groups: List(),
  roles: List(),
  admins: List(),
  waiting: false,
  deleteResponse: null,
  groupToDelete: null,
  selectedRate: null,
  selectedQual: null,
  viewRates: true,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case type.SET_SELECTED_RATE:
      return state.set('selectedRate', action.rate);

    case type.SET_SELECTED_QUAL:
      return state.set('selectedQual', action.qual);

    case type.SET_VIEW_RATES:
      return state.set('viewRates', action.viewRates);

    case type.UPDATE_GROUP_TO_DELETE:
      return state.set('groupToDelete', action.payload);

    case type.DELETE_RESPONSE:
      return state.set('deleteResponse', action.payload);

    case type.UPDATE_WAITING:
      return state.set(`waiting${action.what}`, action.waiting);

    case type.SET_ADMINS:
      return state.set('admins', List(action.payload.data));

    case type.SET_ROLES_FOR_RATES:
      return state.set('roles', List(action.payload.data));

    case type.SET_GROUPS_FOR_STUDY_PROGRAM:
      return state.set('groups', List(action.payload));

    case type.ADD_GROUP:
      return state.set('groups', state.get('groups').concat([action.payload]));

    case type.REMOVE_GROUP:
      let groups = state.get('groups');

      groups = groups.delete(
        groups.findIndex(item => {
          return item.id === action.groupId;
        })
      );

      return state.set('groups', groups);

    default:
      return state;
  }
}
