import './Questions.css';

import React from 'react';
import { browserHistory } from 'react-router';
import Cookies from 'universal-cookie';

import ChapterDefinitions from '../../../commonComponents/Modals/ChapterDefinitions/ChapterDefinitions';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import { infinitePageSize } from '../../../constants/pagination';
import {
  hasPagePermission,
  hasPermissions,
} from '../../../utilities/FilterLinksForTabs';
import ChangeHistoryContainer from '../../ChangeHistoryContainer/components/ChangeHistoryContainer';
import SubtopicsContainer from '../../SubtopicsQuestionView/components/SubtopicsQuestionViewContainer';
import Footer from '../commonComponents/Footer/Footer';
import Header from '../commonComponents/Header/HeaderContainer';
import QuestionGrid from './QuestionGrid/QuestionGrid';
import QuestionListView from './QuestionListView/QuestionListView';
import QuestionPDFView from './QuestionPDFView/QuestionPDFView';

export default class Questions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      question: null,
      selectedRate: { id: 0 },
      historyObject: {},
      hasTosetQuestion: false,
      scrolled: false,
      questionToSet: null,
    };

    this.backListener = browserHistory.listen((location, event) => {
      if (location.action === 'POP') {
        this.onBrowserBack();
      }
    });
  }

  componentWillMount() {
    if (
      !this.props.assignment &&
      this.props.params &&
      this.props.params.reference &&
      this.props.params.chapter
    ) {
      this.props.loadChapter(this.props.params.chapter);
      this.props.loadReference(this.props.params.reference);

      if (this.props.location.query.questionid) {
        this.setState({
          hasTosetQuestion: true,
          questionToSet: this.props.location.query.questionid,
        });
      }

      if (this.props.params && this.props.params.isQual !== 'true') {
        if (this.props.params.rate && this.props.params.rate !== '0') {
          this.props.loadTopics(null, this.props.params.rate);
        } else {
          this.props.loadTopics(this.props.params.reference);
        }
      }
    }

    if (this.props.assignment && this.props.assignment.chapter) {
      this.props.loadChapter(this.props.assignment.chapter.id);
      if (!this.props.isQual) {
        const studyProgram =
          typeof this.props.assignment.studyProgram === 'object'
            ? this.props.assignment.studyProgram.id
            : this.props.assignment.studyProgram;
        this.props.loadTopics(null, null, studyProgram);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.chapter &&
      !this.props.chapter.reference &&
      !this.props.reference.id &&
      nextProps.chapter.reference
    ) {
      this.props.loadReference(nextProps.chapter.reference);
    }
    if (
      nextProps.chapter &&
      nextProps.chapter.reference &&
      nextProps.editPermission === null &&
      nextProps.generalRolesPermissions &&
      nextProps.loggedUser &&
      nextProps.loggedUser.isSuperAdmin !== undefined
    ) {
      this.hasEditQuestionPermission(nextProps);
    }
  }

  componentDidUpdate() {
    if (
      this.state.hasTosetQuestion &&
      !this.state.scrolled &&
      !this.props.isLoadingQuestions
    ) {
      const questionListContainer = document.querySelector(
        '.questionListContainer',
      );
      let scrollView = null;
      if (questionListContainer) {
        scrollView = questionListContainer.childNodes[0];
      }
      const questionElement = window.jQuery(
        `#question-list-id-${this.state.questionToSet}`,
      );
      if (questionListContainer && scrollView && questionElement) {
        const position = window
          .jQuery(`#question-list-id-${this.state.questionToSet}`)
          .position();
        if (position) {
          scrollView.scrollTop = position.top - scrollView.clientHeight * 0.5;
          questionElement.click();
          this.setState({ scrolled: true });
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.setEditPermission(null);
    this.props.onViewChange('listView');
    this.props.onClearQuestions();
    this.props.clearTopics();
    this.props.clearReference();
    this.props.clearChapter();
    this.props.setIsViewDeletedQuestions(false);
    this.backListener();
  }

  componentDidMount() {
    if (this.props.location && this.props.location.state) {
      const historyObject = this.props.location.state.historyObject;
      const rate =
        this.props.location.state.selectedRate || this.props.selectedRate;
      this.setState({ selectedRate: rate, historyObject, rate });
    } else {
      this.setState({
        selectedRate: this.props.selectedRate,
        rate: this.props.selectedRate,
      });
    }
    if (
      this.props.location &&
      this.props.location.query &&
      this.props.location.query.question
    ) {
      this.props.setUrlQuestion(this.props.location.query.question);
    }
  }

  editSubtopics = () => {
    const { selectedRate, selectedRateObject } = this.props;
    const rate = this.props.params ? this.props.params.rate : selectedRate;
    this.props.openModal({
      modalTitle: 'Add Subtopics',
      modalContent: (
        <SubtopicsContainer
          onCancel={this.props.closeModal}
          reference={this.props.chapter.reference}
          chapter={this.props.chapter}
          topics={this.props.topics}
          rateId={rate}
          selectedRateObject={selectedRateObject}
        />
      ),
      modalClassName: 'modal-add-subtopic',
      className: '',
    });
  };

  onDeleteSubtopic = (subptopicToDelete) => {
    this.props.onDeleteSubtopic(
      this.props.reference,
      this.props.chapter,
      this.props.chapter.subtopics,
      subptopicToDelete,
    );
  };

  renderContent = (view, isWaiting, isUpdating) => {
    if ((isWaiting && !isUpdating) || !this.props?.chapter?.id) {
      return (
        <div className="centerSpinner">
          <Spinner text="Loading questions" />
        </div>
      );
    }
    switch (view) {
      case 'pdfView':
        return <QuestionPDFView {...this.props} />;
      case 'gridView':
        return (
          <QuestionGrid
            {...this.props}
            openHistoryView={this.openHistoryView}
          />
        );
      default:
        return <QuestionListView {...this.props} />;
    }
  };

  onBrowserBack = () => {
    this.props.onBack(this.props.assignment, this.state);
  };

  openQuestionsModal = () => {
    this.props.openModal({
      modalClassName: 'modal-question-definitions',
      modalContent: (
        <ChapterDefinitions
          reference={
            this.props.assignment
              ? this.props.assignment.chapter.reference
              : this.props.reference
          }
          question={this.props.selectedQuestion}
          closeModal={this.props.closeModal}
          assignment={this.props.assignment}
        />
      ),
    });
  };

  openHistoryView = (question) => {
    this.setState({ question, isInViewHistory: true });
  };

  onHistoryBack = () => {
    this.setState({ isInViewHistory: false });
  };

  toggleDeletedQuestions = () => {
    const {
      isShowDeletedQuestions,
      setIsViewDeletedQuestions,
      onClearQuestions,
      loadQuestionsOfChapter,
      params,
      assignment,
    } = this.props;
    setIsViewDeletedQuestions(!isShowDeletedQuestions);
    onClearQuestions();
    loadQuestionsOfChapter(
      assignment && assignment.id ? assignment.chapter.id : params.chapter,
      1,
      infinitePageSize,
      assignment ? assignment.id : null,
      null,
      undefined,
      !isShowDeletedQuestions,
    );
  };

  hasEditQuestionPermission = (props) => {
    const cookies = new Cookies();
    const { location, params, generalRolesPermissions, loggedUser } = props;
    let hasPermission = loggedUser.isSuperAdmin;
    const role = cookies.get('userRoles');
    if (!hasPermission) {
      if (location && location.pathname.includes('questions')) {
        if (params.isQual === 'true') {
          hasPermission = hasPagePermission('Quals', generalRolesPermissions);
        } else {
          if (generalRolesPermissions.length < 0) {
            hasPermission =
              hasPagePermission('Books.View', generalRolesPermissions) &&
              hasPagePermission('Books.Edit', generalRolesPermissions);
          } else {
            if (role.includes('Books.View') && role.includes('Books.Edit')) {
              hasPermission = true;
            }
          }
        }
      } else {
        hasPermission = true;
      }
    }
    this.props.setEditPermission(hasPermission);
  };

  render() {
    return this.state.isInViewHistory ? (
      <ChangeHistoryContainer
        question={this.state.question}
        assignment={this.props.assignment.id}
        onBack={this.onHistoryBack}
      />
    ) : (
      <div style={{ height: '100%' }}>
        <Header
          {...this.props}
          subtopics={this.props.chapter ? this.props.chapter.subtopics : []}
          topics={this.props.topics}
          onAddSubtopics={this.editSubtopics}
          onShowDefinitions={this.openQuestionsModal}
          onHeaderBack={(state) =>
            this.props.onBack(
              this.props.assignment,
              state,
              this.props.isMyAssignments,
              this.props.writers,
              this.props.location,
            )
          }
          onDeleteSubtopic={this.onDeleteSubtopic}
          selectedRate={this.state.selectedRate}
          rate={this.state.rate}
          historyObject={this.state.historyObject}
          isQual={this.props.params && this.props.params.isQual === 'true'}
          toggleDeletedQuestionsView={this.toggleDeletedQuestions}
          isWaiting={this.props.isWaiting}
          getQuestionsForDownload={this.props.getQuestionsForDownload}
          questionsForDeleted={this.props.questionsForDeleted}
          deleteQuestion={this.props.deleteQuestion}
          setSeletedQuestionsForDeleted={
            this.props.setSeletedQuestionsForDeleted
          }
          restoreQuestion={this.props.restoreQuestion}
        />
        {this.renderContent(
          this.props.selectedView,
          this.props.isWaiting,
          this.props.isUpdating,
        )}
        <Footer {...this.props} />
      </div>
    );
  }
}
