import Notifications from 'react-notification-system-redux';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import _ from 'underscore';

import { closeModal } from '../../containers/Full/actions';
import * as api from '../../utilities/ServiceManager';
import { getSocket } from '../../utilities/socketService';
import { updateChapterQuestionsQuantity } from '../Bibliographies/actions';
import { setUpdatedDefinitions } from '../Definitions/actions';
import {
  addQuestion,
  batchAddQuestions,
  deleteQuestionAction,
  editQuestion,
  setAllQuestions,
  setAmountOfQuestionsInChapter,
  setChapter,
  setCreateAnother,
  setCurrentPage,
  setImproveAiInQuestion,
  setIncludeNotesInSuggestion,
  setIsLoadingQuestions,
  setIsRefreshForm,
  setPublishedQuestion,
  setQuestionAfterChangeState,
  setQuestionHistory,
  setQuestions,
  setQuestionsToDownload,
  setReference,
  setSelectedQuestion,
  setShowModalQuestionHistory,
  setUserCustomPrompts,
  updateQuestionPdfProcessing,
  updateWaitingPdf,
  updating,
  waiting,
} from './actions';
import { loadQuestionsOfChapter } from './actionsSagas';
import {
  AMOUNT_OF_QUESTIONS_IN_CHAPTER,
  BATCH_CREATE_QUESTIONS,
  CHANGE_STATUS,
  CHECK_QUESTION_SPELLING,
  CREATE_QUESTION,
  CREATE_USER_CUSTOM_PROMPT,
  DELETE_ALL_CHAPTER_QUESTIONS_SAGAS,
  DELETE_PDF_SAGAS,
  DELETE_QUESTION_SAGAS,
  EDIT_QUESTION,
  EDIT_QUESTION_SAGAS,
  GET_QUESTION_HISTORY,
  GET_QUESTIONS_FOR_DOWNLOAD,
  GET_USER_CUSTOM_PROMPT,
  IMPROVE_QUESTION_WITH_AI,
  LOAD_ALL_QUESTIONS,
  LOAD_CHAPTER_SAGAS,
  LOAD_PUBLISHED_QUESTION,
  LOAD_QUESTIONS,
  LOAD_REFERENCE,
  RESTORE_QUESTION_SAGAS,
  SEARCH_QUESTION_IN_CHAPTER_BY_ID,
  UPDATE_INCLUDE_NOTES_IN_SUGGESTION,
} from './actionTypesSagas';
import { AI_TASK_STATUS } from './commonComponents/Header/constants/constants';
import { getCreateAnother } from './selectors';

/** **************************************************************************
 * LOAD REFERENCE
 */
function* loadReferenceSaga() {
  yield takeLatest(LOAD_REFERENCE, loadReference);
}

function* loadReference({ id }) {
  try {
    yield put(waiting(true));
    const reference = yield call(api.getReference, id);
    yield put(setReference(reference));
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not load questions. Please try again later.',
    );
  } finally {
    yield put(waiting(false));
  }
}

/** **************************************************************************
 * LOAD CHAPTER
 */
function* loadChapterSaga() {
  yield takeLatest(LOAD_CHAPTER_SAGAS, loadChapter);
}

function* loadChapter({ chapter }) {
  try {
    const gotChapter = yield call(api.getChapter, chapter);
    yield put(setChapter(gotChapter));
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not load chapter. Please try again later.',
    );
  }
}

// GET THE QUESTIONS TO DOWNLOAD

function* getQuestionsToDownloadSagas() {
  yield takeLatest(GET_QUESTIONS_FOR_DOWNLOAD, getQuestionsToDownload);
}

function* getQuestionsToDownload(chapter) {
  try {
    const response = yield call(api.getQuestionsToDownload, chapter.chapter);
    yield put(setQuestionsToDownload(response.data));
  } catch (err) {
    showNotificationErrorFromException(
      false,
      'Could not load questions. Please try again later.',
    );
  }
}

/** **************************************************************************
 * LOAD AMOUNT OF QUESTIONS TO BUILD PAGINATION
 */

function* loadAmountOfQuestionsInChapter() {
  yield takeLatest(AMOUNT_OF_QUESTIONS_IN_CHAPTER, amountOfQuestionsInChapter);
}

function* amountOfQuestionsInChapter(chapter) {
  try {
    const amountOfQuestions = yield call(
      api.amountOfQuestionsInChapter,
      chapter.chapter,
    );
    yield put(setAmountOfQuestionsInChapter(amountOfQuestions));
  } catch (error) {
    showNotificationErrorFromException(
      false,
      'Could not load amount of questions in chapter. Please try again later.',
    );
  }
}

/** **************************************************************************
 * LOAD QUESTIONS
 */
function* loadQuestionsSaga() {
  yield takeLatest(LOAD_QUESTIONS, loadQuestions);
}

function* loadQuestions({
  id,
  page,
  rows,
  assignment,
  isInfiniteScroll,
  skip,
  isViewDeletedQuestions,
  loadAll,
  downloadCsv,
  deletedByAdmin,
}) {
  try {
    yield put(setIsLoadingQuestions(true));

    const questions = yield call(
      api.getQuestionsOfChapter,
      id,
      page,
      rows,
      assignment,
      skip,
      isViewDeletedQuestions,
      loadAll,
      deletedByAdmin,
    );
    yield put(setQuestions(questions, page, rows, isInfiniteScroll));
    if (questions.data && questions.data.length > 0) {
      if (loadAll) {
        const selectedQuestion = questions.data.filter(
          (q) => q.id === Number(loadAll),
        );
        if (selectedQuestion[0]) {
          yield put(setSelectedQuestion(selectedQuestion[0]));
        } else {
          yield put(setSelectedQuestion(questions.data[0]));
        }
      } else {
        yield put(setSelectedQuestion(questions.data[0]));
      }
    }
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load questions. Please try again later.',
      ),
    );
  } finally {
    yield put(setIsLoadingQuestions(false));
  }
}

/** **************************************************************************
 * LOAD QUESTIONS
 */
function* searchQuestionInChapterByIdSaga() {
  yield takeLatest(
    SEARCH_QUESTION_IN_CHAPTER_BY_ID,
    searchQuestionInChapterById,
  );
}

function* searchQuestionInChapterById({ id, chapter }) {
  try {
    yield put(setIsLoadingQuestions(true));
    const question = yield call(api.getQuestion, id);
    const isQuestionInChapter = question
      ? question.chapter.id === chapter
      : false;
    const payload = {
      data: isQuestionInChapter ? [question] : [],
      pagination: { total: isQuestionInChapter ? 1 : 0 },
    };
    yield put(setQuestions(payload, 1));
  } catch (exception) {
    const payload = {
      data: [],
      pagination: { total: 0 },
    };
    yield put(setQuestions(payload, 1));
  } finally {
    yield put(setIsLoadingQuestions(false));
  }
}

/** **************************************************************************
 * LOAD ALL QUESTIONS
 */
function* loadAllQuestionsSaga() {
  yield takeLatest(LOAD_ALL_QUESTIONS, loadAllQuestions);
}

function* loadAllQuestions({ id }) {
  try {
    const questions = yield call(api.getAllQuestionsOfChapter, id);
    yield put(setAllQuestions(questions));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load questions. Please try again later.',
      ),
    );
  }
}
/** **************************************************************************
 * CHANGE STATUS
 */
function* changeStatusSaga() {
  yield takeLatest(CHANGE_STATUS, changeStatus);
}

function* changeStatus(action) {
  try {
    const questionUpdated = { ...action.question };
    questionUpdated.enabled = !action.question.enabled;
    questionUpdated.pageNumber = 0;
    delete questionUpdated.answers;
    Object.keys(questionUpdated).forEach(
      (key) => questionUpdated[key] == null && delete questionUpdated[key],
    );
    if (questionUpdated.chapter.id)
      questionUpdated.chapter = questionUpdated.chapter.id;
    const response = yield call(
      api.updateQuestion,
      questionUpdated,
      action.assignment,
    );
    yield put(setQuestionAfterChangeState(response));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Question updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    yield put(closeModal());
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not change state. Please try again later.',
    );
  }
}

/** **************************************************************************
 * EDIT QUESTIONS
 */
function* editQuestionsSaga() {
  yield takeLatest(EDIT_QUESTION, editQuestions);
}

function* editQuestions(action) {
  try {
    yield put(waiting(true));
    const editedQuestions = yield call(
      api.updateQuestion,
      action.question,
      action.assignment,
    );
    yield put(
      editQuestion(
        editedQuestions,
        action.assignment ? action.assignment : null,
      ),
    );
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Question edited successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    if (
      exception &&
      exception.response &&
      exception.response.data &&
      exception.response.data ===
        `Question ${action.question.id} is already under edition. Please edit the question in progress.`
    ) {
      yield put(
        Notifications.show(
          { title: 'Notice', message: exception.response.data },
          'warning',
        ),
      );
    } else {
      yield put(
        showNotificationErrorFromException(
          exception,
          'Could not edit question. Please try again later.',
        ),
      );
    }
  } finally {
    if (action.showWorkInProgressAlert) {
      yield put(
        Notifications.show(
          {
            title: 'The filter has been changed!',
            message: 'This is a work in progress question',
          },
          'info',
        ),
      );
    }
    yield put(waiting(false));
  }
}

/** **************************************************************************
 * CREATE QUESTIONS
 */
function* createQuestionsSaga() {
  yield takeLatest(CREATE_QUESTION, createQuestions);
}

function* createQuestions(action) {
  const createAnotherState = yield select(getCreateAnother);

  try {
    yield put(waiting(true));
    yield put(updating(true));
    const question = action.question;
    question.answers = JSON.stringify(action.question.answers);
    const newQuestions = yield call(
      api.createQuestion,
      action.question,
      action.assignment,
    );
    yield put(setIsRefreshForm(true));
    yield put(addQuestion(newQuestions));
    yield put(setUpdatedDefinitions([]));
    if (createAnotherState) {
      yield put(setSelectedQuestion(null));
      yield put(setCurrentPage(0));
      yield put(setCreateAnother(false));
    } else {
      yield put(setSelectedQuestion(newQuestions));
      if (newQuestions.softwarePage) {
        yield put(setCurrentPage(newQuestions.softwarePage));
      } else {
        yield put(setCurrentPage(0));
      }
    }
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Question created successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    yield call(updateChaptersQuestionQuantityOnBibliographyReducer);
  } catch (exception) {
    yield put(setCurrentPage(0));
    yield put(setCreateAnother(false));
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not create question. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
    yield put(updating(false));
  }
}

function* batchCreateQuestionsSaga() {
  yield takeLatest(BATCH_CREATE_QUESTIONS, batchCreateQuestions);
}

function* batchCreateQuestions(action) {
  try {
    yield put(waiting(true));
    const questions = JSON.stringify(action.questions);
    const newQuestions = yield call(
      api.batchCreateQuestions,
      questions,
      action.assignment,
    );

    yield put(setIsRefreshForm(true));
    yield put(batchAddQuestions(newQuestions));
    yield put(setUpdatedDefinitions([]));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Questions imported and saved successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    yield call(updateChaptersQuestionQuantityOnBibliographyReducer);
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Ops! something went wrong while importing questions. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
  }
}

/** **************************************************************************
 * EDIT QUESTION INFO
 */
function* editQuestionInfoSaga() {
  yield takeLatest(EDIT_QUESTION_SAGAS, editQuestionInfo);
}

function* editQuestionInfo(action) {
  try {
    yield put(waiting(true));
    yield put(updating(true));
    if (action.question.pdf) yield put(updateWaitingPdf(true));
    const questionUpdated = {
      ..._.omit(action.question, [
        'rates',
        'assignments',
        'redBorderA',
        'redBorderB',
        'redBorderC',
        'redBorderD',
        'redBorderText',
      ]),
    };
    if (typeof questionUpdated.definitions === 'object')
      delete questionUpdated.definitions;
    questionUpdated.pageNumber = 0;
    Object.keys(questionUpdated).forEach(
      (key) => questionUpdated[key] == null && delete questionUpdated[key],
    );
    if (action.question.answers) {
      const answersWithoutUpdatedAtProperty = action.question.answers.map(
        (answer) => {
          delete answer.updatedAt;
          return answer;
        },
      );
      questionUpdated.answers = JSON.stringify(answersWithoutUpdatedAtProperty);
    }
    if (questionUpdated.chapter && questionUpdated.chapter.id)
      questionUpdated.chapter = questionUpdated.chapter.id;
    const editedQuestions = yield call(
      api.updateQuestion,
      questionUpdated,
      action.assignment,
    );
    yield put(
      editQuestion(
        editedQuestions,
        action.assignment ? action.assignment : null,
        !!action.question.pdf,
      ),
    );
    if (action.isEditPresentationIndex) {
      yield put(
        loadQuestionsOfChapter(
          action.question.chapter.id,
          1,
          1000,
          action.assignment,
          0,
        ),
      );
    } else {
      yield put(setSelectedQuestion(editedQuestions));
    }

    if (action.question.softwarePage) {
      yield put(setCurrentPage(action.question.softwarePage));
    } else {
      yield put(setCurrentPage(0));
    }
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Question edited successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(setCurrentPage(0));

    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update question. Please try again later.',
      ),
    );
  } finally {
    yield put(updateQuestionPdfProcessing(null));
    yield put(updateWaitingPdf(false));
    yield put(waiting(false));
    yield put(updating(false));
  }
}

/** **************************************************************************
 * EDIT QUESTION INFO
 */
function* checkQuestionSpellingSaga() {
  yield takeLatest(CHECK_QUESTION_SPELLING, checkQuestionSpelling);
}

function* checkQuestionSpelling(action) {
  try {
    const question = yield call(api.checkQuestionSpelling, action.id);
    yield put(setSelectedQuestion(question));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not check for spelling. Please try again later.',
      ),
    );
  }
}

/** **************************************************************************
 * QUESTION HISTORY
 */
function* geyQuestionHistorySaga() {
  yield takeLatest(GET_QUESTION_HISTORY, getQuestionHistory);
}

function* getQuestionHistory(action) {
  try {
    const questionHistory = yield call(
      api.getQuestionHistory,
      action.questionId,
    );
    yield put(setQuestionHistory(questionHistory));
    yield put(setShowModalQuestionHistory(true));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not obtain question history. Please try again later.',
      ),
    );
    yield put(setShowModalQuestionHistory(false));
  }
}

/** **************************************************************************
 * DELETE QUESTION
 */
function* deleteQuestionSaga() {
  yield takeLatest(DELETE_QUESTION_SAGAS, deleteQuestion);
}

function* deleteQuestion(action) {
  try {
    const questions = [];
    let chapterId;
    if (!Array.isArray(action.question)) {
      questions.push(question.id);
      chapterId = question.chapter.id;
    } else {
      action.question.map((q) => {
        questions.push(q.id);
        chapterId = q.chapter.id;
      });
    }

    yield call(api.deleteQuestion, questions, action.assignment);
    if (!action.assignment) {
      yield put(deleteQuestionAction(action.question.id));
      yield put(loadQuestionsOfChapter(chapterId, 1, 1000, false, 0));
    } else {
      yield put(
        loadQuestionsOfChapter(chapterId, 1, 1000, action.assignment, 0),
      );
      // updateChapterAfterUpdateSubtopics
      /*
      yield put(
        editQuestion(
          response,
          action.assignment,
          action.question.pdf ? true : false
        )
      ); */
    }
    yield put(closeModal());
    yield put(setIsRefreshForm(true));
    yield put(setSelectedQuestion(null));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Question deleted successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    yield call(updateChaptersQuestionQuantityOnBibliographyReducer);
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not delete question. Please try again later.',
    );
  }
}

function* updateChaptersQuestionQuantityOnBibliographyReducer() {
  const questionsState = yield select((state) => state.questions.toJSON());
  const questionsQuantity = questionsState.pagination
    ? questionsState.pagination.total
    : 0;
  const referenceId = questionsState.reference.id;
  const chapterId = questionsState.chapter.id;

  yield put(
    updateChapterQuestionsQuantity(referenceId, chapterId, questionsQuantity),
  );
}

/** **************************************************************************
 * DELETE ALL CHAPTERS QUESTIONS
 */
function* deleteAllChapterQuestionsSaga() {
  yield takeLatest(
    DELETE_ALL_CHAPTER_QUESTIONS_SAGAS,
    deleteAllChapterQuestions,
  );
}

function* deleteAllChapterQuestions({ chapter, assignment }) {
  try {
    yield call(api.deleteAllChapterQuestions, chapter, assignment);
    yield put(loadQuestionsOfChapter(chapter.id, 1, 1000, assignment, 0));
    yield put(closeModal());
    yield put(setSelectedQuestion(null));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'All question deleted successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    yield call(updateChaptersQuestionQuantityOnBibliographyReducer);
  } catch (exception) {
    yield put(closeModal());
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not delete all questions. Please try again later.',
      ),
    );
  }
}

/** **************************************************************************
 * DELETE PDF QUESTION
 */
function* deletePdfSaga() {
  yield takeLatest(DELETE_PDF_SAGAS, deletePdf);
}

function* deletePdf(action) {
  try {
    const response = yield call(api.deletePdfQuestion, action.question.id);
    yield put(editQuestion(response));
    yield put(closeModal());
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Question updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not delete pdf. Please try again later.',
    );
  }
}

/** **************************************************************************
 * LOAD PUBLISHED QUESTION
 */
function* loadPublishedQuestionSaga() {
  yield takeLatest(LOAD_PUBLISHED_QUESTION, loadPublishedQuestion);
}

function* loadPublishedQuestion({ question }) {
  try {
    const publishedQuestion = yield call(api.getQuestion, question);
    yield put(setPublishedQuestion(publishedQuestion));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load published question. Please try again later.',
      ),
    );
  } finally {
  }
}

/** **************************************************************************
 * RESTORE QUESTION
 */
function* restoreQuestionSaga() {
  yield takeLatest(RESTORE_QUESTION_SAGAS, restoreQuestion);
}

function* restoreQuestion(action) {
  try {
    const questionsIds = [];
    let chapterId;
    action.question.map((q) => {
      questionsIds.push(q.id);
      chapterId = q.chapter.id;
    });

    yield call(api.restoreQuestion, questionsIds, action.assignment);
    yield put(loadQuestionsOfChapter(chapterId, 1, 1000, action.assignment, 0));

    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Question restored successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    yield put(closeModal());
  } catch (exception) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message: 'Could not restore question. Please try again later.',
          autoDismiss: 5,
        },
        'error',
      ),
    );
    yield put(closeModal());
  }
}

/** **************************************************************************
 * CREATE USER CUSTOM PROMPT
 */
function* createUserCustomPromptSaga() {
  yield takeLatest(CREATE_USER_CUSTOM_PROMPT, createUserCustomPrompt);
}

function* createUserCustomPrompt(action) {
  try {
    const customPrompts = yield call(
      api.createCustomPrompt,
      action.customPrompt,
      action.chapterId,
    );

    yield put(setUserCustomPrompts(customPrompts));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Custom prompt created successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not custom prompt. Please try again later.',
    );
  }
}

/** **************************************************************************
 * GET USER CUSTOM PROMPT
 */
function* getUserCustomPromptSaga() {
  yield takeLatest(GET_USER_CUSTOM_PROMPT, getUserCustomPrompt);
}

function* getUserCustomPrompt(action) {
  try {
    const customPrompts = yield call(api.getUserCustomPrompt, action.chapterId);
    yield put(setUserCustomPrompts(customPrompts));
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not get user custom prompt. Please try again later.',
    );
  }
}

/** **************************************************************************
 * IMPROVE QUESTION WITH Ai
 */
function* improveQuestionWithAiSaga() {
  yield takeEvery(IMPROVE_QUESTION_WITH_AI, improveQuestionWithAi);
}

function* improveQuestionWithAi(action) {
  try {
    yield put(
      setImproveAiInQuestion(
        null,
        action.questionId,
        AI_TASK_STATUS.IN_PROGRESS,
      ),
    );

    /* SOCKET */
    // get channel to take events from sockets
    const socket = getSocket();
    const socketChannel = yield call(
      createSocketChannelForAiSuggestion,
      socket,
      action.questionId,
    );
    // function to listen to the response in the background of a aiSuggestion
    yield fork(
      handleSocketChannelForAiSuggestion,
      action.questionId,
      socketChannel,
    );
    /** ******** */

    const response = yield call(
      api.improveQuestionWithAi,
      action.questionId,
      action.chapterId,
    );

    if (response.status === AI_TASK_STATUS.FAILED) {
      // AI_TASK_STATUS.FAILED
      yield put(
        showNotificationErrorFromException(false, 'Failed ai suggestion'),
      );
    }
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'We could not get ai suggestion.',
      ),
    );
  }
}

function* handleSocketChannelForAiSuggestion(questionId, socketChannel) {
  try {
    const responseSocket = yield take(socketChannel);
    if (responseSocket.status === AI_TASK_STATUS.FAILED) {
      yield put(
        showNotificationErrorFromException(
          false,
          `Failed ai suggestion in background for question ${questionId}`,
        ),
      );
    }
    yield put(
      setImproveAiInQuestion(
        responseSocket.aiSuggestion,
        responseSocket.questionId,
        responseSocket.status,
      ),
    );
  } catch (error) {
    yield put(
      showNotificationErrorFromException(
        error,
        `There's been a problem in socket channel for aiSuggestion.`,
      ),
    );
  } finally {
    if (socketChannel) {
      // if it take a message or throw error, close the channel and de listener.
      socketChannel.close();
    }
  }
}

function createSocketChannelForAiSuggestion(socket, questionId) {
  return eventChannel((emit) => {
    // listen to the response for the identifier
    const identifier = `aiSuggestion-${questionId}`;
    socket.on(identifier, (responseSocketOn) => {
      // response background from server
      emit(responseSocketOn);
    });

    return () => {
      // stop listening
      socket.off(`aiSuggestion-${questionId}`, (responseSocketOff) => {
        emit(responseSocketOff);
      });
    };
  });
}

function* updateIncludeNotesInSuggestionSaga() {
  yield takeLatest(
    UPDATE_INCLUDE_NOTES_IN_SUGGESTION,
    updateIncludeNotesInSuggestion,
  );
}

function* updateIncludeNotesInSuggestion(action) {
  try {
    yield call(
      api.updateIncludeNotesInSuggestion,
      action.questionId,
      action.value,
    );
    // update question locally
    yield put(setIncludeNotesInSuggestion(action.questionId, action.value));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Update successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(exception, 'We could update question'),
    );
  }
}

/** **************************************************************************
 * DEFAULT EXPORT
 *************************************************************************** */
export default function* sagas() {
  yield all([
    loadReferenceSaga(),
    loadChapterSaga(),
    loadQuestionsSaga(),
    loadAllQuestionsSaga(),
    editQuestionsSaga(),
    createQuestionsSaga(),
    batchCreateQuestionsSaga(),
    changeStatusSaga(),
    editQuestionInfoSaga(),
    deleteQuestionSaga(),
    deletePdfSaga(),
    loadPublishedQuestionSaga(),
    restoreQuestionSaga(),
    searchQuestionInChapterByIdSaga(),
    deleteAllChapterQuestionsSaga(),
    checkQuestionSpellingSaga(),
    loadAmountOfQuestionsInChapter(),
    getQuestionsToDownloadSagas(),
    createUserCustomPromptSaga(),
    getUserCustomPromptSaga(),
    geyQuestionHistorySaga(),
    improveQuestionWithAiSaga(),
    updateIncludeNotesInSuggestionSaga(),
  ]);
}

/** **************************************************************************
 * PRIVATE FUNCTIONS
 */
function showNotificationErrorFromException(
  exception,
  defaultMessage = 'Something went wrong, please try again.',
) {
  let message = defaultMessage;

  if (
    exception &&
    exception.response &&
    exception.response.data &&
    exception.response.data.error &&
    exception.response.data.error.code &&
    exception.response.data.error.code !== 'UsageError' &&
    exception.response.data.error.message
  ) {
    message = exception.response.data.error.message;
  }
  return Notifications.show({ title: 'Ops!', message }, 'error');
}
