export const GET_ALL_RATES = 'messageCenter/GET_ALL_RATES';
export const SET_ALL_RATES = 'messageCenter/SET_ALL_RATES';
export const SET_RATE = 'messageCenter/SET_RATE';
export const CREATE_NOTIFICATION = 'messageCenter/CREATE_NOTIFICATION';
export const LOAD_NOTIFICATIONS = 'messageCenter/LOAD_NOTIFICATIONS';
export const GET_NOTIFICATIONS_BY_RATE =
  'messageCenter/GET_NOTIFICATIONS_BY_RATE';
export const SET_NOTIFICATIONS_BY_RATE =
  'messageCenter/SET_NOTIFICATIONS_BY_RATE';
export const DELETE_NOTIFICATION = 'messageCenter/DELETE_NOTIFICATION';
export const EDIT_NOTIFICATION = 'messageCenter/EDIT_NOTIFICATION';
