import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import DropDown from '../../../commonComponents/DropDown/DropDown';
import { MODAL_TYPES, QUESTION_TYPES } from '../helpers/constants';
import { userSubmittedQuestionAdapter } from '../helpers/userSubmittedQuestionAdapter';
import ConfirmationModal from './ConfirmationModal';
import IncorrectQuestionsList from './IncorrectQuestionsList';
import QuestionItemInput from './QuestionItemInput';

function SubmittedQuestionItem({
  question,
  selectedRateId,
  deleteSubmittedQuestion,
  approveSubmittedQuestion,
  rejectSubmittedQuestion,
  editSubmittedQuestion,
  isDeletedOnly,
  undeleteSubmittedQuestion,
}) {
  const adaptedQuestion = question && userSubmittedQuestionAdapter(question);

  const [questionState, setQuestionState] = useState(adaptedQuestion);
  const [correctAnswerError, setCorrectAnswerError] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [modalType, setModalType] = useState('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  useEffect(() => {
    if (questionState.incorrectAnswers.every((ia) => ia === '')) {
      setQuestionState((prev) => ({
        ...prev,
        type: QUESTION_TYPES.FLASHCARD,
      }));
    } else {
      setQuestionState((prev) => ({
        ...prev,
        type: QUESTION_TYPES.MC,
      }));
    }
  }, [questionState.incorrectAnswers]);

  const handleToggleModal = () => {
    setIsConfirmationModalOpen((prev) => !prev);
  };

  const handleToggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.includes('incorrectAnswer')) {
      const index = name.replace('incorrectAnswer', '');
      const incorrectAnswers = [...questionState.incorrectAnswers];
      incorrectAnswers[index - 1] = value;
      setQuestionState((prev) => ({
        ...prev,
        incorrectAnswers,
      }));
    }

    setQuestionState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const questionType =
    questionState.incorrectAnswers.filter((ia) => ia).length > 0
      ? QUESTION_TYPES.MC
      : QUESTION_TYPES.FLASHCARD;

  return (
    <article className="question__item">
      <Row>
        <Col md={3}>
          <QuestionItemInput
            isEditable={isEditable}
            value={questionState.text}
            name="text"
            isTextArea
            disabled={!isEditable}
            onChange={handleInputChange}
          />
        </Col>
        <Col md={3}>
          <QuestionItemInput
            isEditable={isEditable}
            isTextArea
            value={questionState.correctAnswer}
            name="correctAnswer"
            disabled={!isEditable}
            onChange={handleInputChange}
          />
          {correctAnswerError ? (
            <span className="question__item__error">
              Correct answer is required
            </span>
          ) : null}
        </Col>
        <Col md={2}>
          <QuestionItemInput
            isEditable={isEditable}
            value={questionState.notes}
            disabled={!isEditable}
            name="notes"
            onChange={handleInputChange}
          />
        </Col>
        <Col md={1}>
          {isEditable ? (
            <>
              <a
                data-tip={`${
                  questionType === QUESTION_TYPES.MC
                    ? 'To convert to Flashcard, clear all the incorrect answer fields'
                    : 'To convert to MC, complete at least one incorrect answer field'
                }`}
              >
                {questionType}
              </a>
              <ReactTooltip place="top" type="dark" effect="solid" />
            </>
          ) : (
            questionType
          )}
        </Col>
        <Col md={1}>{question.shortname}</Col>
        <Col md={1}>{question.status}</Col>
        <Col md={1} className="question__item__options">
          {!isEditable ? (
            <button
              className="question__item__expand-button"
              onClick={handleToggleExpand}
            >
              <i
                className={`${
                  isExpanded ? 'fas fa-lg fa-sort-up' : 'fas fa-lg fa-sort-down'
                }`}
              />
            </button>
          ) : null}
          <DropDown
            options={
              isDeletedOnly
                ? [
                    {
                      name: 'Recover',
                      event: () => {
                        setModalType(MODAL_TYPES.RECOVER);
                        handleToggleModal();
                      },
                    },
                  ]
                : [
                    ...(question.status !== 'Published'
                      ? [
                          {
                            name: 'Approve',
                            event: () => {
                              setModalType(MODAL_TYPES.APPROVE);
                              handleToggleModal();
                            },
                          },
                        ]
                      : []),
                    ...(question.status === 'Published'
                      ? [
                          {
                            name: 'Reject',
                            event: () => {
                              setModalType(MODAL_TYPES.REJECT);
                              handleToggleModal();
                            },
                          },
                        ]
                      : []),
                    ...(!isEditable
                      ? [
                          {
                            name: 'Edit',
                            event: () => {
                              setCorrectAnswerError(false);
                              setIsEditable((prev) => !prev);
                              setIsExpanded(true);
                            },
                          },
                        ]
                      : []),
                    {
                      name: 'Delete',
                      event: () => {
                        setModalType(MODAL_TYPES.DELETE);
                        handleToggleModal();
                      },
                    },
                  ]
            }
            title="Question Menu"
            style={{
              position: 'absolute',
              right: '10px',
            }}
          />
        </Col>
      </Row>
      {isExpanded ? (
        <>
          {questionState.incorrectAnswers.filter((ia) => ia).length > 0 ||
          isEditable ? (
            <Row>
              <Col
                md={12}
                className="question__item__incorrect-answers__container"
              >
                Incorrect answers:
                <ul className="question__item__incorrect-answers__list">
                  <IncorrectQuestionsList
                    isEditable={isEditable}
                    handleInputChange={handleInputChange}
                    incorrectAnswers={questionState.incorrectAnswers}
                  />
                </ul>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md={5}>
              <span className="font-weight-bold">Question ID:</span>{' '}
              {question.id.toString()}
            </Col>
            <Col md={5}>
              <span className="font-weight-bold">User email:</span>{' '}
              {question.email}
            </Col>
            {isEditable ? (
              <Col md={2} className="question__item__button__container">
                <button
                  className="btn btn-primary question__item__button__accept"
                  onClick={() => {
                    if (!questionState.correctAnswer) {
                      setCorrectAnswerError(true);
                    } else {
                      setCorrectAnswerError(false);
                      setModalType(MODAL_TYPES.EDIT);
                      handleToggleModal();
                    }
                  }}
                >
                  Accept
                </button>
                <button
                  className="btn btn-secondary question__item__button__cancel"
                  onClick={() => {
                    setCorrectAnswerError(false);
                    setIsEditable(false);
                    handleToggleExpand();
                    setQuestionState(adaptedQuestion);
                  }}
                >
                  Cancel
                </button>
              </Col>
            ) : null}
          </Row>
        </>
      ) : null}

      {isConfirmationModalOpen ? (
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          handleToggleModal={handleToggleModal}
          handleCloseExpand={() => setIsExpanded(false)}
          handleEndEdition={() => setIsEditable(false)}
          handleResetQuestion={() => setQuestionState(adaptedQuestion)}
          type={modalType}
          deleteSubmittedQuestion={deleteSubmittedQuestion}
          approveSubmittedQuestion={approveSubmittedQuestion}
          rejectSubmittedQuestion={rejectSubmittedQuestion}
          editSubmittedQuestion={editSubmittedQuestion}
          question={question}
          questionState={questionState}
          selectedRateId={selectedRateId}
          undeleteSubmittedQuestion={undeleteSubmittedQuestion}
        />
      ) : null}
    </article>
  );
}

export default SubmittedQuestionItem;
