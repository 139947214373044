import React from 'react';
import { CSVLink } from 'react-csv';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import { Col, Row } from 'react-bootstrap';

export default class NumberOfQuestionsEnabledPerRatingExamReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      csvCompleted: false,
      retryAvailable: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.lastRate !== prevProps.lastRate &&
      this.props.hasMore &&
      this.state.csvCompleted === false
    ) {
      this.props.getNumberOfQuestionsEnabledPerRatingExamReport(
        this.props.lastRate,
      );
    }
    if (this.props.hasMore === false) {
      document.getElementById('generate-report-download').click();
      this.state.csvCompleted = true;
      this.state.isLoading = false;
      this.props.clearProps();
    }
    if (
      prevProps.APIError !== this.props.APIError &&
      this.props.APIError === true
    ) {
      this.setState({ retryAvailable: true });
    }
  };

  componentWillUnmount = () => {
    this.props.clearProps();
  };

  render() {
    return (
      <div>
        {this.state.isLoading && this.state.retryAvailable === false ? (
          <Spinner text={'Loading... This may take a couple of minutes'} />
        ) : (
          <Row>
            <Col className="d-block col-12">
              <div>
                {this.state.retryAvailable ? (
                  <div
                    className="whiteButtons"
                    onClick={() => {
                      this.props.getNumberOfQuestionsEnabledPerRatingExamReport(
                        this.props.lastRate,
                      ),
                        this.setState({
                          isLoading: true,
                          retryAvailable: false,
                        }),
                        this.props.clearNumberOfQuestionsEnabledPerRatingExamAPIError();
                    }}
                  >
                    Retry
                  </div>
                ) : (
                  <div
                    className="whiteButtons manuallyReportButtonWidth"
                    onClick={() => {
                      this.props.getNumberOfQuestionsEnabledPerRatingExamReport(
                        '',
                      ),
                        this.setState({
                          isLoading: true,
                          csvCompleted: false,
                          step: 0,
                        });
                    }}
                  >
                    Generate Report
                  </div>
                )}
              </div>
            </Col>
          </Row>
        )}
        <div style={{ display: 'none' }}>
          <CSVLink
            id="generate-report-download"
            filename={'Question-Number-Rating-Exam-Report.csv'}
            data={this.props.CSVData}
          >
            Generate Report
          </CSVLink>
        </div>
      </div>
    );
  }
}
