import React from 'react';
import Button from 'react-bootstrap-button-loader';
import './ChangeExam.css';

class ChangeExam extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      rank: 'E4',
      dutyState: 'Regular',
      rate: '',
    };
  }

  componentWillMount() {
    let rate = '';
    if (this.props.user.rate.id) rate = this.props.user.rate.id;
    this.setState({
      rate: rate,
      rank: this.props.user.rank,
      dutyState: this.props.user.dutyState,
    });
  }

  componentDidMount() {
    this.props.onLoad();
  }

  validate(callback) {
    let errors = {};
    if (this.state.rate === '') errors.rate = 'Select a rate';

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
    this.setState({ errors: null });
    let user = {
      id: this.props.user.id,
      rank: this.state.rank,
      dutyState: this.state.dutyState,
      rate: Number(this.state.rate),
    };
    callback(user);
  }

  render() {
    const { onSubmit, onCancel, rates } = this.props;
    return (
      <div className="new-admin-component changeExam">
        <div className="card-block">
          <div className="form-group">
            Rate:
            <select
              value={this.state.rate}
              className="form-control"
              onChange={e => this.setState({ rate: e.target.value })}
            >
              <option value="" />
              {rates.filter(rate => rate.name !== 'PMK').map(rate => (
                <option key={'rate-change-exam' + rate.id} value={rate.id}>
                  {rate.name}
                </option>
              ))}
            </select>
            {this.state.errors &&
            this.state.errors['rate'] && (
              <div className="errorsContainer">
                <span className="errors">{this.state.errors['rate']}</span>
              </div>
            )}
          </div>
          <div className="form-group">
            Rank:
            <select
              value={this.state.rank}
              className="form-control"
              onChange={e => this.setState({ rank: e.target.value })}
            >
              <option value="E4">E-4</option>
              <option value="E5">E-5</option>
              <option value="E6">E-6</option>
              <option value="E7">E-7</option>
            </select>
          </div>
          <div className="form-group">
            Exam type:
            <select
              value={this.state.dutyState}
              className="form-control"
              onChange={e => this.setState({ dutyState: e.target.value })}
            >
              <option value="Regular">Regular</option>
              <option value="Substitute">Substitute</option>
              <option value="Reserve">Reserve</option>
            </select>
          </div>
          <div className="bottom-buttons">
            <Button className="btn btn-secondary" onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button
              className="btn btn-success"
              type="submit"
              loading={this.props.waiting}
              onClick={() => this.validate(onSubmit)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangeExam;
