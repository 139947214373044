import React from 'react';
import './Activity.css';
import Card from '../../../../commonComponents/Card/Card';
import { Row, Col } from 'react-bootstrap';
import CardRow from './CardRowContaier';
import Stats from '../../../../commonComponents/Stats/Stats';
import Pagination from '../../../../commonComponents/Pagination/Pagination';
import moment from 'moment';
import { SimpleDatePicker } from '../../../../commonComponents/Datepicker/Datepicker';
import Spinner from '../../../../commonComponents/Spinner/Spinner';

class Activity extends React.Component {
  constructor(props) {
    super(props);

    this.timeout = null;

    this.state = {
      page: 1,
      optionSelected: this.props.filtered,
      showDate: false,
      dateInit: 0,
      dateEnd: 0,
      totalPages: this.props.pagination,
    };
  }

  renderDate = date => {
    let dateString = moment(Number(date));
    return (
      dateString.format('MMMM') +
      ' ' +
      dateString.format('DD') +
      ', ' +
      dateString.format('YYYY') +
      ' ' +
      dateString.format('HH') +
      ':' +
      dateString.format('mm')
    );
  };

  reloadActivity = (user = this.props.user.id, page = this.state.page) => {
    this.setState({ page: page || 1 });
    let init =
      this.state.dateInit !== 0 ? moment(this.state.dateInit).valueOf() : 0;
    let finish =
      this.state.dateEnd !== 0 ? moment(this.state.dateEnd).valueOf() : 0;
    const filter = {
      filter: this.state.optionSelected,
      dateI: init,
      dateE: finish,
    };
    this.props.loadUserStatistics(user, page, filter);
  };

  changePage = page => {
    this.setState({ page });
    this.reloadActivity(this.props.user.id, page);
  };

  getOptions = () => {
    let options = [
      {
        name: 'All Activity',
        event: this.allActivity,
      },
      {
        name: 'Statistics',
        event: this.statistics,
      },
      {
        name: 'History',
        event: this.history,
      },
    ];
    return options;
  };

  getDates = () => {
    let dates = [
      {
        name: 'Show/Hide',
        event: this.dateStatus,
      },
      {
        name: 'Clear',
        event: this.clearDate,
      },
    ];
    return dates;
  };

  clearDate = () => {
    this.setState(
      {
        dateInit: 0,
        dateEnd: 0,
      },
      this.callApi,
    );
  };

  dateStatus = () => {
    let aux = this.state.showDate;
    this.setState({ showDate: !aux });
  };

  allActivity = () => {
    this.setState({ optionSelected: 'All Activity' }, this.callApi);
  };

  statistics = () => {
    this.setState({ optionSelected: 'Statistics' }, this.callApi);
  };

  history = () => {
    this.setState({ optionSelected: 'History' }, this.callApi);
  };

  callApi = () => {
    let init =
      this.state.dateInit !== 0 ? moment(this.state.dateInit).valueOf() : 0;
    let finish =
      this.state.dateEnd !== 0 ? moment(this.state.dateEnd).valueOf() : 0;
    const filter = {
      filter: this.state.optionSelected,
      dateI: init,
      dateE: finish,
    };
    this.props.loadUserStatistics(this.props.user.id, this.props.page, filter);
  };

  dateFrom = date => {
    this.setState({ dateInit: date }, this.callApi);
  };

  dateTo = date => {
    this.setState({ dateEnd: date }, this.callApi);
  };

  renderDates = () => {
    return (
      <Row className="paddingRow">
        <Col
          xs={12}
          md={12}
          lg={12}
          style={{
            paddingRight: '0px',
          }}
        >
          <SimpleDatePicker
            className="datePickerStyle"
            date={this.state.dateInit ? moment(this.state.dateInit) : null}
            onChange={dateInit => {
              this.dateFrom(dateInit);
            }}
            placeholderText="FROM"
          />
          <SimpleDatePicker
            className="datePickerStyle"
            date={this.state.dateEnd ? moment(this.state.dateEnd) : null}
            onChange={dateEnd => {
              this.dateTo(dateEnd);
            }}
            placeholderText="TO"
          />
        </Col>
        <div className="separatorLine" />
      </Row>
    );
  };

  renderContent = countByDate => {
    const { loading } = this.props;
    return (
      <Row className="cardContentWrapper">
        {this.state.showDate ? this.renderDates() : null}
        {countByDate.length === 0 ? (
          <div className="no-activity"> There is no current activity</div>
        ) : loading ? (
          <Spinner text="Loading activity" />
        ) : (
          this.props.countByDate.map(count =>
            count.action ? (
              <CardRow
                key={'card-row-activity-' + count.createdAt}
                title={this.renderDate(count.createdAt)}
                content={<b>{count.action}</b>}
                link="Detail"
                actions={count.action}
                messageError={count.messageError}
                userEmail={this.props.user.email}
              />
            ) : (
              <CardRow
                key={'card-row-activity-' + count.date}
                title={this.renderDate(count.date)}
                content={
                  <Stats
                    answered={count.answered}
                    correct={count.correct}
                    incorrect={count.incorrect}
                    margin={countByDate[0].date !== count.date}
                  />
                }
                link="Breakdown"
              />
            ),
          )
        )}
        <div className="paginationContainer">
          <Pagination
            currentPage={this.state.page}
            totalRecords={
              this.props.pagination ? this.props.pagination.total : 0
            }
            onChangePage={this.changePage}
          />
        </div>
      </Row>
    );
  };

  render() {
    const { countByDate } = this.props;
    const { filtered } = this.props;
    return (
      <div className="ActivityContainer">
        <Card
          title="Filter By: "
          activityText={filtered}
          options={this.getOptions()}
          content={this.renderContent(countByDate)}
          date={true}
          datesArray={this.getDates()}
        />
      </div>
    );
  }
}

export default Activity;
