import { Map } from 'immutable';

import * as t from './actionTypes';

const initialState = Map({
  waiting: false,
  filters: [],
  expandAll: false,
  searchText: '',
  selectedRate: null,
  isViewDeletedBooks: false,
  chapterQuestionsCSV: '',
  logs: [],
  lastSelectedBookId: '',
  lastSelectedPanel: null,
  lastSelectedTreeView: null,
  sidebarScroll: 0,
  mainScroll: 0,
  lastSelectedBookPerRate: [],
  namesToSecondBar: [],
  urlPdfPrivate: '',
  finishProcessing: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case t.UPDATE_WAITING:
      return state.set('waiting', action.value);

    case t.SET_LOGS:
      return state.set('logs', action.logs);

    case t.UPDATE_SEARCH_TEXT:
      return state.set('searchText', action.text);

    case t.SET_SELECTED_RATE:
      return state.set('selectedRate', action.rate);

    case t.SET_LAST_SELECTED_ID:
      return state.set('lastSelectedId', action.value);

    case t.SET_LAST_SELECTED_PANEL:
      return state.set('lastSelectedPanel', action.value);

    case t.SET_LAST_SELECTED_TREE_VIEW:
      return state.set('lastSelectedTreeView', action.value);

    case t.SET_SIDEBAR_SCROLL:
      return state.set('sidebarScroll', action.value);

    case t.SET_MAIN_SCROLL:
      return state.set('mainScroll', action.value);

    case t.SET_NAMES_TO_BUILD_SECOND_BAR:
      return state.set('namesToSecondBar', action.references);

    case t.SET_CHAPTER_QUESTIONS_CSV:
    case t.SET_QUESTIONS_CSV_BY_DETAIL:
      let filteredQuestionsCSV = '';
      let questionsCsv;
      if (action.type === t.SET_CHAPTER_QUESTIONS_CSV) {
        questionsCsv = 'chapterQuestionsCSV';
      } else if (action.type === t.SET_QUESTIONS_CSV_BY_DETAIL) {
        questionsCsv = 'questionsCSVbyDetail';
      }
      if (Array.isArray(action.questionsCSV)) {
        filteredQuestionsCSV = action.questionsCSV.map((question) => {
          const newQuestion = {};
          Object.keys(question).forEach((key) => {
            if (typeof question[key] === 'string') {
              newQuestion[key] = question[key].replace(/\r?\n|\r/g, ' ');
            } else {
              newQuestion[key] = question[key];
            }
          });
          return newQuestion;
        });
      }

      if (questionsCsv === 'chapterQuestionsCSV') {
        const chapterQuestionsCSVCurrent = state.get('chapterQuestionsCSV');

        if (chapterQuestionsCSVCurrent && filteredQuestionsCSV) {
          const chapterQuestionsCSVConcat =
            chapterQuestionsCSVCurrent.concat(filteredQuestionsCSV);
          return state.set(questionsCsv, chapterQuestionsCSVConcat);
        }

        return state.set(questionsCsv, filteredQuestionsCSV);
      }

      return state.set(questionsCsv, filteredQuestionsCSV);

    case t.SET_IS_VIEW_DELETED_BOOKS:
      return state.set('isViewDeletedBooks', action.isViewDeletedBooks);
    case t.FINISH_PROCESSING:
      return state.set('finishProcessing', action.value);
    case t.ADD_FILTER:
      return state.set(
        'filters',
        state
          .get('filters')
          .map((filter) => {
            if (
              action.filter.isActive &&
              filter.rate === action.filter.rate &&
              filter.isActive
            )
              filter.isActive = false;
            if (filter.id === action.filter.id) return action.filter;
            return filter;
          })
          .concat([action.filter]),
      );

    case t.REMOVE_FILTER:
      return state.set(
        'filters',
        state.get('filters').filter((filter) => filter.id !== action.filter),
      );

    case t.UPDATE_EXPAND_ALL:
      return state.set('expandAll', action.expandAll);

    case t.EDIT_FILTER:
      return state.set(
        'filters',
        state.get('filters').map((filter) => {
          if (
            action.filter.isActive &&
            filter.rate === action.filter.rate &&
            filter.isActive
          )
            filter.isActive = false;
          if (filter.id === action.filter.id) return action.filter;
          return filter;
        }),
      );

    case t.SET_FILTERS:
      return state.set('filters', action.filters);

    case t.SET_LAST_BOOK_OPEN_PER_RATE:
      const par = [];
      let flag = true;
      if (state.get('lastSelectedBookPerRate').length === 0) {
        par.push({ rate: action.rate, book: action.book });
      }
      state.get('lastSelectedBookPerRate').map((rates) => {
        if (rates.rate !== action.rate) {
          par.push(rates);
        } else {
          par.push({ rate: action.rate, book: action.book });
          flag = false;
        }
      });
      if (flag) {
        par.push({ rate: action.rate, book: action.book });
      }
      return state.set('lastSelectedBookPerRate', par);

    case t.SET_URL_PDF_PRIVATE:
      return state.set('urlPdfPrivate', action.url);

    default:
      return state;
  }
}
