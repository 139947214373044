import * as t from './actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  allBanners: [],
  bannerDetail: {},
  loadBannerDetails: false,
  createBannerView: false,
  editBanner: {},
  loadingInfo: false,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case t.SET_ALL_BANNERS:
      return state.set('allBanners', action.banners);
    case t.BANNER_DETAILS:
      return state.set('bannerDetail', action.banner);
    case t.LOAD_BANNER_DETAILS:
      return state.set('loadBannerDetails', action.value);
    case t.CREATE_BANNER_VIEW:
      return state
        .set('createBannerView', action.value)
        .set('editBanner', action.editBanner);
    case t.SET_LOADING_INFO_BANNER:
      return state.set('loadingInfo', action.value);
    default:
      return state;
  }
}
