import React from 'react';
import Red from '../Images/red-circle.png';
import Green from '../Images/green-circle.png';
import DropdownIcon from '../Images/dropdown-icon.png';
import ManualRed from '../Images/manual-red.png';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ChapterGridAccordionLib from './ChapterGridAccordionLib';
import { Link } from 'react-router';

const menuItemStyle = {
  padding: '0px',
  minHeight: '30px',
  fontSize: '14px',
  lineHeight: '30px',
  width: '169px',
};

const menuStyle = { maxWidth: '169px', width: '169px' };

function renderReferenceName(reference, rate, rolesPermissions, superAdmin) {
  if (
    !reference.actAsParent &&
    (superAdmin ||
      rolesPermissions.find(
        (permission) =>
          Number(permission.typeId) === rate &&
          permission.actions.find(
            (action) => action === 'Assignment.Publish',
          ) &&
          permission.actions.find((action) => action === 'Rates.References'),
      ))
  ) {
    return (
      <Link
        to={
          '/home/questions/' +
          reference.id +
          '/' +
          reference.chapters[0].id +
          '/' +
          rate
        }
      >
        <span title={reference.name}>{reference.name}</span>
      </Link>
    );
  }
  return <span title={reference.name}>{reference.name}</span>;
}

export default class ReferencesGridAccordionLib {
  static getReferencesForGrid(
    references,
    chapters,
    functions,
    rate,
    rolesPermissions,
    superAdmin,
  ) {
    let formattedReferences = references.map((reference) => {
      let chaptersAux = [];
      if (reference.actAsParent) {
        chaptersAux = reference.chapters;
      }
      return {
        id: reference.id,
        name: renderReferenceName(
          reference,
          rate,
          rolesPermissions,
          superAdmin,
        ),
        subitems: this.getSubitems(reference, chapters),
        questions: this.getQuestionCount(reference),
        pdf: this.getPdfColumn(reference, functions.onDeletePdf),
        status: this.getStatusColumn(reference, functions.onStateClick),
        writer: this.getWriterColumnReference(reference),
        definitions: this.getDefinitionsColumn(
          reference,
          functions.onDefinitionsClick,
        ),
        settings: this.getSettingsColumn(
          reference,
          functions.onChangeSettingsReference,
          functions.onAddChapter,
        ),
        chapters: chaptersAux,
        subtopicsIndicator: this.getSubtopicsIndicatorRef(reference),
        questionsIndicator: this.getQuestionsIndicatorRef(
          reference,
          functions.onChangeQuestionsIndicator,
        ),
      };
    });
    return formattedReferences;
  }

  static getSubitems(reference, chapters) {
    if (reference.actAsParent) {
      let subitems = chapters.filter(
        (chapter) => chapter.reference === reference.id,
      );
      return subitems.length;
    }
    return 0;
  }

  static getQuestionCount(reference) {
    let questionsCount = 0;
    if (reference.chapters) {
      reference.chapters.map(
        (chapter) => (questionsCount += chapter.questionCount),
      );
    }
    return questionsCount;
  }

  static getPdfColumn(entity, onDeletePdf) {
    if (entity.pdfUrl !== '') {
      return (
        <div>
          <a href={entity.pdfUrl} target="_blank">
            <i className="fa fa-file-pdf-o fa-lg mt-2" />
          </a>
          <i
            onClick={() => {
              onDeletePdf(entity);
            }}
            className="fa fa-trash fa-lg mt-2"
          />
        </div>
      );
    }
  }

  static getStatusColumn(reference, onStateClick) {
    if (reference.enabled) {
      return (
        <div
          className="statusLabel"
          onClick={() => {
            onStateClick(reference);
          }}
        >
          Active
        </div>
      );
    }
    return (
      <div
        className="statusLabel"
        onClick={() => {
          onStateClick(reference);
        }}
      >
        Disabled
      </div>
    );
  }

  static getWriterColumnReference(reference) {
    if (reference.actAsParent) {
      return '-';
    } else {
      return ChapterGridAccordionLib.writerColumnChapter(reference.chapters[0]);
    }
  }

  static getDefinitionsColumn(reference, onDefinitionsClick) {
    return (
      <div
        className="statusLabel"
        onClick={() => {
          onDefinitionsClick(reference);
        }}
      >
        Definitions
      </div>
    );
  }

  static getSettingsColumn(reference, onChangeSettingsReference, addChapter) {
    return (
      <div className="settingsRow">
        <MuiThemeProvider>
          <IconMenu
            iconButtonElement={<i className="fa fa-gear fa-lg mt-2" />}
            menuStyle={menuStyle}
            menuItemStyle={menuItemStyle}
            onItemTouchTap={(event, child) =>
              onChangeSettingsReference(event, child, reference)
            }
          >
            <MenuItem
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              targetOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              Edit
            </MenuItem>
            <MenuItem
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              targetOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              Delete
            </MenuItem>
            <MenuItem
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              targetOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              {reference.archived ? 'Unarchive' : 'Archive'}
            </MenuItem>
            <MenuItem
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              targetOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              Copy
            </MenuItem>
            {!reference.actAsParent && (
              <MenuItem
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                targetOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                Assign writer
              </MenuItem>
            )}
          </IconMenu>
        </MuiThemeProvider>
        {reference.actAsParent && (
          <i
            className="fa fa-plus fa-lg mt-1"
            onClick={() => {
              addChapter(reference);
            }}
          />
        )}
      </div>
    );
  }

  static getSubtopicsIndicatorRef(reference) {
    let redIndicator = reference.chapters.find(
      (chap) =>
        chap.indicators.subtopics === 'red' ||
        chap.indicators.subtopics === 'forced',
    );
    if (
      redIndicator ||
      reference.chapters.length === 0 ||
      !reference.actAsParent
    ) {
      return <img alt="red" className="circleSize" src={Red} loading="lazy" />;
    } else {
      return (
        <img alt="green" className="circleSize" src={Green} loading="lazy" />
      );
    }
  }

  static getQuestionsIndicatorRef(reference, onChangeQuestionsIndicator) {
    let redIndicator = reference.chapters.find(
      (chap) =>
        chap.indicators.questions === 'red' ||
        chap.indicators.questions === 'forced',
    );
    let icon;
    if (redIndicator || reference.chapters.length === 0) {
      icon = <img alt="red" className="circleSize" src={Red} loading="lazy" />;
    } else {
      icon = (
        <img alt="green" className="circleSize" src={Green} loading="lazy" />
      );
    }
    if (!reference.actAsParent) {
      return this.renderQuestionsIndicatorNotActAsParent(
        reference,
        onChangeQuestionsIndicator,
      );
    } else {
      return icon;
    }
  }

  static renderQuestionsIndicatorNotActAsParent(
    reference,
    onChangeQuestionsIndicator,
  ) {
    let chapter = reference.chapters[0];
    let icon = (
      <div>
        <img alt="green" className="circleSize" src={Green} loading="lazy" />
        <img
          alt="dropdown-icon"
          className="circleSizeArrow"
          src={DropdownIcon}
          loading="lazy"
        />
      </div>
    );
    let textMenu = 'Set manual red';
    if (chapter && chapter.indicators.questions === 'red') {
      icon = (
        <div>
          <img alt="red" className="circleSize" src={Red} loading="lazy" />
          <img
            alt="dropdown-icon"
            className="circleSizeArrow"
            src={DropdownIcon}
            loading="lazy"
          />
        </div>
      );
    }
    if (chapter && chapter.indicators.questions === 'forced') {
      icon = (
        <div>
          <img
            className="circleSizeManualRed"
            title={chapter.questionIndicatorReason}
            alt={chapter.questionIndicatorReason}
            src={ManualRed}
            loading="lazy"
          />
          <img
            alt="dropdown-icon"
            className="circleSizeArrow"
            src={DropdownIcon}
            loading="lazy"
          />
        </div>
      );
      textMenu = 'Remove';
    }
    return (
      <MuiThemeProvider>
        <IconMenu
          iconButtonElement={icon}
          menuStyle={menuStyle}
          menuItemStyle={menuItemStyle}
          onItemTouchTap={(event, child) => {
            onChangeQuestionsIndicator(event, child, chapter, textMenu);
          }}
        >
          <MenuItem
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            {textMenu}
          </MenuItem>
        </IconMenu>
      </MuiThemeProvider>
    );
  }
}
