import './Table.css';
import '../../QuestionGptModal/QuestionGptModal.css';

import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { SimpleDatePicker } from '../../../../../commonComponents/Datepicker/Datepicker';
import DropDown from '../../../../../commonComponents/DropDown/DropDown';
import AssignmentComments from '../../../../../commonComponents/Modals/AssignmentComments/AssignmentComments';
import ConfirmDeletion from '../../../../../commonComponents/Modals/ConfirmDeletion/ConfirmDeletion';
import { getStatusByName } from '../../../../../constants/assignments';
import { hasPagePermission } from '../../../../../utilities/FilterLinksForTabs';
import { isGptQuestionGenerator } from '../../../../../utilities/isGptQuestionGenerator';
import {
  AI_TASK_STATUS,
  AI_TASK_TYPE,
} from '../../../../Questions/commonComponents/Header/constants/constants';
import WritersContainer from '../../../../Writers/components/WritersContainer';
import QuestionGptModal from '../../QuestionGptModal/QuestionGptModalContainer';
import ViewSummaryModal from '../../ViewSummaryModal/ViewSummaryModal';

export default class Table extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.loadingCreateGPTquestions) {
      this.props.toggleModalVisibility({
        modalContent: (
          <QuestionGptModal
            onCancel={this.props.closeModal}
            loadingCreateGPTquestions={this.props.loadingCreateGPTquestions}
            assignment={this.props.loadingCreateGPTquestions}
            getUrlPdf={this.props.getUrlPdf}
          />
        ),
        modalClassName: 'modal-question-container',
      });

      this.props.setLoadingCreateGPTquestion(false);
    }
  }

  viewComments = (assignment) => {
    this.props.openModal({
      modalTitle: 'View comments',
      modalContent: (
        <AssignmentComments
          comments={assignment.comments}
          onCancel={this.props.closeModal}
        />
      ),
      modalClassName: 'viewComments',
    });
  };

  renderCommentsNumber = (assignment) => {
    let counter = 0;
    if (assignment && assignment.comments) {
      assignment.comments.forEach((item) => {
        if (item.comment !== '') {
          counter++;
        }
      });
    }

    return counter;
  };

  renderHeader = (canViewLastEdited) => {
    const width = canViewLastEdited ? 1 : 2;
    const { user } = this.props;
    const canCreateGPTQuestions = isGptQuestionGenerator(
      user.roles,
      user.isSuperAdmin,
    );
    const width4 = this.props.writers ? 3 : 4;
    const widthReference = this.props.writers ? 1 : 2;

    const gptWidth = canCreateGPTQuestions ? 1 : 2;
    return (
      <Row className="headerContainer">
        <Col
          md={widthReference}
          className={this.props.writers ? 'tHeader referenceTitle' : 'tHeader'}
        >
          Reference
        </Col>
        <Col md={width} className="tHeader">
          Chapter
        </Col>
        <Col md={1} className="tHeader">
          Due date
        </Col>
        <Col md={gptWidth} className="tHeader">
          Date assigned
        </Col>
        {canViewLastEdited && (
          <Col md={1} className="tHeader">
            Last edited
          </Col>
        )}
        <Col md={gptWidth} className="tHeader">
          Assigned by
        </Col>
        <Col md={1} className="tHeader">
          Comments
        </Col>
        {this.props.writers && (
          <Col md={1} className="tHeader referenceTitle rateColumn">
            Rate
          </Col>
        )}
        <Col md={1} className="tHeader justify-content-center">
          Questions
        </Col>
        {canCreateGPTQuestions && (
          <Col md={2} className="tHeader">
            Create Question
          </Col>
        )}
        <Col md={width4} className="tHeader" />
      </Row>
    );
  };

  onDateChange = (date, assignment) => {
    let editedAssignment = {
      id: assignment.id,
      dueDate: date.toJSON(),
    };
    if (this.props.writers) {
      editedAssignment = {
        id: assignment.id,
        dueDate: date.toJSON(),
        writerEmail: this.props.selectedWriter.email,
      };
      this.props.onEditAssignment(editedAssignment);
    } else {
      editedAssignment = {
        id: assignment.id,
        dueDate: date.toJSON(),
      };
      this.props.onEditAssignment(editedAssignment);
    }
  };

  getActions = (state, assignment, summary) => {
    const user = this.props.user;
    const permissions = this.props.rolesPermissions;
    const pagePermissions = this.props.generalRolesPermissions;
    let isGenerateSummary = true;
    if (
      this.props.summary &&
      this.props.summary.chapter === assignment.chapter.id
    ) {
      isGenerateSummary = false;
    }
    let permission;
    if (this.props.selectedRate) {
      permission = permissions.find((p) => {
        const id = this.props.selectedRate.typeId
          ? this.props.selectedRate.typeId
          : this.props.selectedRate.id;
        return p.typeId === id.toString();
      });
    } else if (assignment.studyProgram.typeId && this.props.writers) {
      permission = permissions.find((p) => {
        const id = assignment.studyProgram.typeId.id;
        return p.typeId === id.toString();
      });
    }

    const actions = { buttonText: null, options: [] };
    const canCreateGPTQuestions = isGptQuestionGenerator(
      user.roles,
      user.isSuperAdmin,
    );
    if (canCreateGPTQuestions && !assignment.summary && isGenerateSummary) {
      actions.options.push('Generate a Summary');
    } else if (
      assignment.summary ||
      (summary && summary.chapter === assignment.chapter.id)
    ) {
      actions.options.push('View a Summary');
      if (canCreateGPTQuestions) {
        actions.options.push('Regenerate a Summary');
      }
    }

    switch (state) {
      case 'Not Started':
        if (
          user.isSuperAdmin ||
          hasPagePermission('Wheelhouse.Assignments', pagePermissions)
        ) {
          actions.buttonText = 'Start';
          actions.options.push('Reassign');
          actions.options.push('Delete');
          return actions;
        }
        if (
          permission &&
          permission.actions.find((action) => action === 'Assignment.Write')
        ) {
          actions.buttonText = 'Start';
        }
        if (
          permission &&
          permission.actions.find((action) => action === 'Assignment.Assign')
        ) {
          actions.options.push('Reassign');
        }
        break;
      case 'In progress':
        if (
          user.isSuperAdmin ||
          (hasPagePermission('Wheelhouse.Assignments', pagePermissions) &&
            ((permission &&
              permission.actions.find(
                (action) => action === 'Assignment.Approve',
              )) ||
              (permission &&
                permission.actions.find(
                  (action) => action === 'Assignment.Publish',
                ))))
        ) {
          actions.options.push('Submit');
          actions.buttonText = 'Open';
          if (assignment.comments && assignment.comments.length > 0) {
            actions.options.push('View comments');
          }
          return actions;
        }
        if (
          assignment.primaryWriter === user.id &&
          permission &&
          permission.actions.find((action) => action === 'Assignment.Write')
        ) {
          actions.buttonText = 'Open';
          actions.options.push('Submit');
        } else {
          if (
            (assignment.assignedTo.find((writer) => writer.id === user.id) &&
              assignment.primaryWriter !== user.id) ||
            (permission &&
              permission.actions.find((action) => action === 'Assignment.Edit'))
          ) {
            if (
              assignment.assignedTo.find((writer) => writer.id === user.id) &&
              assignment.primaryWriter !== user.id
            ) {
              actions.options.push('Submit');
            }
            actions.buttonText = 'Open';
          }
        }
        if (assignment.comments && assignment.comments.length > 0)
          actions.options.push('View comments');

        break;
      case 'Submitted':
        if (
          user.isSuperAdmin ||
          hasPagePermission('Wheelhouse.Assignments', pagePermissions)
        ) {
          actions.buttonText = 'Review';
          actions.options.push('Delete');
          if (assignment.comments && assignment.comments.length > 0)
            actions.options.push('View comments');
          return actions;
        }
        if (
          permission &&
          permission.actions.find((action) => action === 'Assignment.Approve')
        ) {
          actions.buttonText = 'Review';
        }
        if (assignment.comments && assignment.comments.length > 0)
          actions.options.push('View comments');

        break;

      case 'Completed':
        if (
          user.isSuperAdmin ||
          hasPagePermission('Wheelhouse.Assignments', pagePermissions)
        ) {
          actions.options.push('Delete');
          return actions;
        }
        return null;

      default:
        break;
    }
    if (
      permission &&
      permission.actions.find((action) => action === 'Assignment.Delete')
    ) {
      actions.options.push('Delete');
    }
    return actions;
  };

  onStatusChange = (assignment, newStatus) => {
    let editedAssignment = {};

    if (this.props.writers) {
      editedAssignment = {
        id: assignment.id,
        status: newStatus,
        writerEmail: this.props.selectedWriter.email,
      };
      this.props.onEditAssignment(editedAssignment, true);
    } else {
      editedAssignment = {
        id: assignment.id,
        status: newStatus,
      };
      this.props.onEditAssignment(editedAssignment, true);
    }
  };

  showReassignModal = (assignment) => {
    if (this.props.writers) {
      this.props.openModal({
        modalContent: (
          <WritersContainer
            title="Reassign"
            closeModal={this.props.closeModal}
            forAssign
            assignment={assignment.id}
            rate={assignment.studyProgram.typeId.id}
            isQual={assignment.studyProgram.type === 'qual'}
            selectedWriterEmail={this.props.selectedWriter.email}
          />
        ),
        modalClassName: 'modal-create-chapter grey-modal-body',
      });
    } else {
      this.props.openModal({
        modalContent: (
          <WritersContainer
            title="Reassign"
            closeModal={this.props.closeModal}
            forAssign
            assignment={assignment.id}
            rate={this.props.selectedRate.id}
            isQual={!this.props.isViewRates}
          />
        ),
        modalClassName: 'modal-create-chapter grey-modal-body',
      });
    }
  };

  onSettingOptions = (assignment, option) => {
    switch (option) {
      case 'Open':
      case 'Edit':
        this.props.onQuestionDetails(assignment);
        break;
      case 'Submit':
        this.onStatusChange(assignment, getStatusByName('Submitted'));
        break;
      case 'Start':
        this.onStatusChange(assignment, getStatusByName('In progress'));
        this.props.onQuestionDetails(assignment);
        break;
      case 'Review':
        if (this.props.writers) {
          assignment.writerEmail = this.props.selectedWriter.email;
        } else {
          assignment.writerEmail = null;
        }
        this.props.onQuestionOverview(assignment);
        break;
      case 'Reassign':
        this.showReassignModal(assignment);
        break;
      case 'Publish':
        this.onStatusChange(assignment, getStatusByName('Published'));
        break;
      case 'Delete':
        this.props.openModal({
          modalTitle: 'Deleting an assignment',
          modalContent: (
            <ConfirmDeletion
              onSubmit={() => {
                if (this.props.writers) {
                  this.props.onDelete(
                    assignment,
                    this.props.selectedWriter.email,
                  );
                } else {
                  this.props.onDelete(assignment);
                }
              }}
              message="You are about to delete an assignment, all changes applied to the questions under it will be deleted"
              onCancel={this.props.closeModal}
            />
          ),
        });
        break;
      case 'Generate a Summary':
      case 'Regenerate a Summary':
        this.props.openModal({
          modalContent: (
            <QuestionGptModal
              onCancel={this.props.closeModal}
              chapter={assignment.chapter}
              title={option}
              option={
                option === 'Generate a Summary' ? 'generate' : 'regenerate'
              }
              getUrlPdf={this.props.getUrlPdf}
              createQuestion={false}
              assignment={assignment}
            />
          ),
          modalClassName: 'modal-question-container',
        });
        break;
      case 'View a Summary':
        let summary = assignment.summary;
        if (
          this.props.summary &&
          this.props.summary.chapter === assignment.chapter.id
        )
          summary = this.props.summary;

        this.props.openModal({
          modalContent: (
            <ViewSummaryModal
              onCancel={this.props.closeModal}
              chapter={assignment.chapter}
              summary={summary}
            />
          ),
          modalClassName: 'viewComments',
        });

        break;
      case 'View comments':
        this.props.openModal({
          modalTitle: 'View comments',
          modalContent: (
            <AssignmentComments
              comments={assignment.comments}
              onCancel={this.props.closeModal}
            />
          ),
          modalClassName: 'viewComments',
        });
        break;

      default:
        break;
    }
  };

  renderOptions = (options, assignment) => {
    if (options.length === 0) {
      return null;
    }
    return (
      <DropDown
        writers={this.props.writers}
        options={options.map((option) => ({
          name: option,
          event: () => this.onSettingOptions(assignment, option),
        }))}
        title="Assignment"
        style={{
          position: 'absolute',
          right: '0px',
        }}
      />
    );
  };

  hasDueDatePermission = (user, rolesPermissions) => {
    if (
      user.isSuperAdmin ||
      rolesPermissions.filter((role) =>
        role.actions.find((a) => a === 'ChangeDueDate'),
      ).length > 0
    )
      return true;
    return false;
  };

  renderRowsForWritersView = (canViewLastEdited, summary) => {
    const cursor = { cursor: 'pointer' };
    const width = canViewLastEdited ? 1 : 2;
    return this.props.assignments.map((assignment) => {
      const amountOfQuestions = assignment.amountOfQuestions;
      const isCreatingAIQuestions =
        assignment.aiTask?.status === AI_TASK_STATUS.IN_PROGRESS &&
        assignment.aiTask?.type === AI_TASK_TYPE.QUESTION_GENERATION;

      const assignedBy = assignment.createdBy
        ? `${assignment.createdBy.name} ${assignment.createdBy.lastName}`
        : '';
      const chapterName = assignment.chapter ? assignment.chapter.name : 'n/a';
      const referenceName =
        assignment.chapter && assignment.chapter.reference
          ? assignment.chapter.reference.name
          : '';
      const actions = this.getActions(
        this.props.activeStatus,
        assignment,
        summary,
      );
      let rate;

      if (this.props.writers) {
        rate =
          assignment.studyProgram.typeId &&
          assignment.studyProgram.typeId.shortname
            ? assignment.studyProgram.typeId.shortname
            : '';
      }
      const { user } = this.props;
      const canCreateGPTQuestions = isGptQuestionGenerator(
        user.roles,
        user.isSuperAdmin,
      );
      const width2 = canCreateGPTQuestions ? 1 : 2;

      return (
        <Row className="assignmentRow" key={`asr-${assignment.id}`}>
          <Col
            md={1}
            className={
              this.props.writers ? 'tHeader referenceTitle' : 'tHeader'
            }
          >
            <div title={referenceName}>{referenceName}</div>
          </Col>
          <Col md={width} className="tHeader">
            <div title={chapterName}>{chapterName}</div>
          </Col>
          <Col md={1} className="tHeader">
            <div style={{ width: '100px' }}>
              {this.hasDueDatePermission(
                this.props.user,
                this.props.rolesPermissions,
              ) ? (
                <SimpleDatePicker
                  date={
                    assignment.dueDate ? moment(assignment.dueDate).utc() : null
                  }
                  onChange={(date) => {
                    this.onDateChange(date, assignment);
                  }}
                  className={
                    moment().utc() > moment(assignment.dueDate).utc()
                      ? 'red'
                      : ''
                  }
                />
              ) : (
                <div title={moment(assignment.dueDate).utc()}>
                  {assignment.dueDate
                    ? moment(assignment.dueDate).utc().format('MM/DD/YYYY')
                    : 'No date'}
                </div>
              )}
            </div>
          </Col>
          <Col md={width2} className="tHeader">
            {moment(assignment.createdAt).format('MM/DD/YYYY')}
          </Col>
          {canViewLastEdited && (
            <Col md={1} className="tHeader">
              {moment(assignment.updatedAt).format('MM/DD/YYYY')}
            </Col>
          )}
          <Col md={width2} className="tHeader">
            <div title={assignedBy}>{assignedBy}</div>
          </Col>

          <Col md={1} className="tHeader">
            <div
              title={this.renderCommentsNumber(assignment)}
              style={this.renderCommentsNumber(assignment) > 0 ? cursor : null}
              onClick={
                this.renderCommentsNumber(assignment) > 0
                  ? () => this.viewComments(assignment)
                  : null
              }
              className={
                this.renderCommentsNumber(assignment) > 0
                  ? 'comments-display comment-show'
                  : 'comments-display comment-none'
              }
            >
              {this.renderCommentsNumber(assignment)}
            </div>
          </Col>
          <Col md={1} className="tHeader referenceTitle rateColumn">
            {this.props.writers && <div title="RATE">{rate}</div>}
          </Col>
          <Col md={1} className="tHeader justify-content-center">
            {amountOfQuestions}
          </Col>
          {canCreateGPTQuestions &&
            (isCreatingAIQuestions ? (
              <Col md={1} className="tHeader">
                <button disabled className="creatingAIquestions">
                  <i
                    className="fa fa-circle-o-notch fa-spin"
                    style={{ height: '1rem', width: '1rem', color: '#9f8fef' }}
                  />
                  <span>Creating AI Questions...</span>
                </button>
              </Col>
            ) : (
              <Col md={2} className="tHeader justify-content-center">
                <div
                  className="createGPTquestions"
                  onClick={() => this.openCreteQuestionModal(assignment, true)}
                >
                  Create GPT/AI Questions
                </div>
              </Col>
            ))}
          <Col md={1} className="tHeader containerAssignmentsBtn">
            {actions && actions.buttonText && (
              <div
                className="assignmentsBtn mr-3"
                onClick={() =>
                  this.onSettingOptions(assignment, actions.buttonText)
                }
              >
                <div className="assignmentsBtnText">{actions.buttonText}</div>
              </div>
            )}
            {actions &&
              actions.options &&
              this.renderOptions(actions.options, assignment)}
          </Col>
        </Row>
      );
    });
  };

  openCreteQuestionModal(assignment, createQuestion) {
    this.props.toggleModalVisibility({
      modalContent: (
        <QuestionGptModal
          onCancel={this.props.closeModal}
          loadingCreateGPTquestions={this.props.loadingCreateGPTquestions}
          assignment={assignment}
          getUrlPdf={this.props.getUrlPdf}
          title="Create GPT Questions"
          createQuestion={createQuestion}
        />
      ),
      modalClassName: 'modal-question-container',
    });
  }

  renderRows = (canViewLastEdited, summary) => {
    const cursor = { cursor: 'pointer' };
    const width = canViewLastEdited ? 1 : 2;

    const adminuser = this.props.selectedAdmin.id;
    const { user } = this.props;
    const canCreateGPTQuestions = isGptQuestionGenerator(
      user.roles,
      user.isSuperAdmin,
    );
    const width2 = canCreateGPTQuestions ? 2 : 4;
    const width3 = canViewLastEdited && canCreateGPTQuestions ? 1 : 2;
    return this.props.assignments
      .filter((assignment) =>
        assignment.assignedTo.find((admin) => admin.id === Number(adminuser)),
      )
      .map((assignment) => {
        const amountOfQuestions = assignment.amountOfQuestions;
        const isCreatingAIQuestions =
          assignment.aiTask?.status === AI_TASK_STATUS.IN_PROGRESS &&
          assignment.aiTask?.type === AI_TASK_TYPE.QUESTION_GENERATION;

        const assignedBy = assignment.createdBy
          ? `${assignment.createdBy.name} ${assignment.createdBy.lastName}`
          : '';
        const chapterName = assignment.chapter
          ? assignment.chapter.name
          : 'n/a';
        const referenceName =
          assignment.chapter && assignment.chapter.reference
            ? assignment.chapter.reference.name
            : '';
        const actions = this.getActions(
          this.props.activeStatus,
          assignment,
          summary,
        );
        const canCreateGPTQuestions = isGptQuestionGenerator(
          user.roles,
          user.isSuperAdmin,
        );

        const gptWidth = canCreateGPTQuestions ? 1 : 2;

        const width3 = gptWidth === 1 && canViewLastEdited ? 1 : 2;
        return (
          <Row className="assignmentRow" key={assignment.id}>
            <Col md={2} className="tHeader">
              <div title={referenceName}>{referenceName}</div>
            </Col>
            <Col md={width3} className="tHeader">
              <div title={chapterName}>{chapterName}</div>
            </Col>
            <Col md={1} className="tHeader">
              <div style={{ width: '100px' }}>
                {this.hasDueDatePermission(
                  this.props.user,
                  this.props.rolesPermissions,
                ) ? (
                  <SimpleDatePicker
                    date={
                      assignment.dueDate
                        ? moment(assignment.dueDate).utc()
                        : null
                    }
                    onChange={(date) => {
                      this.onDateChange(date, assignment);
                    }}
                    className={
                      moment().utc() > moment(assignment.dueDate).utc()
                        ? 'red'
                        : ''
                    }
                  />
                ) : (
                  <div title={moment(assignment.dueDate).utc()}>
                    {assignment.dueDate
                      ? moment(assignment.dueDate).utc().format('MM/DD/YYYY')
                      : 'No date'}
                  </div>
                )}
              </div>
            </Col>
            <Col md={gptWidth} className="tHeader">
              {moment(assignment.createdAt).format('MM/DD/YYYY')}
            </Col>
            {canViewLastEdited && (
              <Col md={1} className="tHeader">
                {moment(assignment.updatedAt).format('MM/DD/YYYY')}
              </Col>
            )}
            <Col md={gptWidth} className="tHeader">
              <div title={assignedBy}>{assignedBy}</div>
            </Col>
            <Col md={1} className="tHeader">
              <div
                title={this.renderCommentsNumber(assignment)}
                style={
                  this.renderCommentsNumber(assignment) > 0 ? cursor : null
                }
                onClick={
                  this.renderCommentsNumber(assignment) > 0
                    ? () => this.viewComments(assignment)
                    : null
                }
                className={
                  this.renderCommentsNumber(assignment) > 0
                    ? 'comments-display comment-show'
                    : 'comments-display comment-none'
                }
              >
                {this.renderCommentsNumber(assignment)}
              </div>
            </Col>
            <Col md={1} className="tHeader justify-content-center">
              {amountOfQuestions}
            </Col>
            {canCreateGPTQuestions &&
              (isCreatingAIQuestions ? (
                <Col md={2} className="tHeader">
                  <button disabled className="creatingAIquestions">
                    <i
                      className="fa fa-circle-o-notch fa-spin"
                      style={{
                        height: '1rem',
                        width: '1rem',
                        color: '#9f8fef',
                      }}
                    />
                    <span>Creating AI Questions...</span>
                  </button>
                </Col>
              ) : (
                <Col md={2} className="tHeader">
                  <div
                    className="createGPTquestions"
                    onClick={() =>
                      this.openCreteQuestionModal(assignment, true)
                    }
                  >
                    Create GPT/AI Questions
                  </div>
                </Col>
              ))}
            <Col md={1} className="containerAssignmentsBtn">
              <div
                className="textButtonAndDrop"
                style={
                  actions && actions.buttonText
                    ? { justifyContent: 'space-between' }
                    : { justifyContent: 'flex-end' }
                }
              >
                {actions && actions.buttonText && (
                  <div
                    className="assignmentsBtn"
                    onClick={() =>
                      this.onSettingOptions(assignment, actions.buttonText)
                    }
                  >
                    <div className="assignmentsBtnText">
                      {actions.buttonText}
                    </div>
                  </div>
                )}
                {actions &&
                  actions.options &&
                  this.renderOptions(actions.options, assignment)}
              </div>
            </Col>
          </Row>
        );
      });
  };

  render() {
    const canViewLastEdited =
      this.props.user.isSuperAdmin ||
      hasPagePermission(
        'Wheelhouse.AssignmentViewLastEdited',
        this.props.generalRolesPermissions,
      );
    let rows;
    if (this.props.writers) {
      rows = this.renderRowsForWritersView(
        canViewLastEdited,
        this.props.summary,
      );
    } else {
      rows = this.renderRows(canViewLastEdited, this.props.summary);
    }
    return (
      <div className="assignmentsTableContainer">
        {rows.length > 0 && this.renderHeader(canViewLastEdited)}
        {rows.length > 0 ? (
          rows
        ) : (
          <div className="noAssignmentsText">No assignments to show</div>
        )}
      </div>
    );
  }
}
