import * as t from './actionTypes';

export const setPracticeExamInformationWaiting = value => ({
  type: t.SET_PRACTICE_EXAM_INFORMATION_WAITING,
  value,
});

export const setPracticeExamInformation = data => ({
  type: t.SET_PRACTICE_EXAM_INFORMATION,
  data,
});

export const setRate = rate => ({
  type: t.SET_RATE,
  rate,
});

export const setRank = rank => ({
  type: t.SET_RANK,
  rank,
});

export const setDutyState = dutyState => ({
  type: t.SET_DUTY_STATE,
  dutyState,
});

export const setExam = exam => ({
  type: t.SET_EXAM,
  exam,
});

export const setCycle = cycle => ({
  type: t.SET_CYCLE,
  cycle,
});

export const setBuildPracticeExamWaiting = value => ({
  type: t.SET_BUILD_PRACTICE_EXAM_WAITING,
  value,
});

export const setExamAfterBuild = (exam, free, bonus, extra) => ({
  type: t.SET_EXAM_AFTER_BUILD,
  exam,
  free,
  bonus,
  extra,
});

export const setRemoveQuestionWaiting = value => ({
  type: t.SET_REMOVE_QUESTION_WAITING,
  value,
});

export const setRemoveQuestionSuccess = value => ({
  type: t.SET_REMOVE_QUESTION_SUCCESS,
  value,
});

export const setActivateExamWaiting = value => ({
  type: t.SET_ACTIVATE_EXAM_WAITING,
  value,
});

export const setActivateExamSuccess = value => ({
  type: t.SET_ACTIVATE_EXAM_SUCCESS,
  value,
});

export const setBuildAllExamsWaiting = value => ({
  type: t.SET_BUILD_ALL_EXAMS_WAITING,
  value,
});

export const setBuildAllExamsSuccess = value => ({
  type: t.SET_BUILD_ALL_EXAMS_SUCCESS,
  value,
});

export const setBuildAllExamsProgress = value => ({
  type: t.SET_BUILD_ALL_EXAMS_PROGRESS,
  value,
});
