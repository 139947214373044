import ioSails from 'sails.io.js';
import socketIOClient from 'socket.io-client';

let ioSocket = null; // singleton Socket

export function connectSocket() {
  // initialize client Socket.io with Sails
  if (!ioSocket) {
    // Only initialize if socket is not already initialized
    ioSocket = ioSails(socketIOClient); // this connect socket with sails
    ioSocket.sails.url = process.env.REACT_APP_API_URL_FOR_SOCKET;
    ioSocket.sails.environment =
      process.env.REACT_APP_KEYS_ENV === 'local' ? 'development' : 'production';
    ioSocket.sails.useCORSRouteToGetCookie = false;
    ioSocket.sails.initialConnectionHeaders = {
      __sails_io_sdk_version: '1.2.1',
      __sails_io_sdk_platform: 'node',
      __sails_io_sdk_language: 'javascript',
    };
  }
}

// Connect the socket
export function getSocket() {
  if (!ioSocket) {
    connectSocket();
  }
  return ioSocket.socket;
}

// Socket disconnection is done automatically via API when the web is closed.
// export function disconnectSocket() {
//   if (ioSocket && ioSocket.socket && ioSocket.socket.isConnected()) {
//     ioSocket.socket.disconnect();
//   }
// }
