import UniversalCookies from 'universal-cookie';

import { setLoading } from '../components/Login/actions';
import { store } from '../index';
import history from './history';
import { getLoggedAdmin } from './ServiceManager';
import setAuthorizationToken from './setAuthorizationToken';
import * as userToken from './userToken';
import { validatePage } from './validatePage';

export function verifyLoggedAdmin() {
  const cookies = new UniversalCookies();
  const url = window.location.href;
  let token = userToken.getUserToken(); // cookies.get('Token');
  if (url && url.includes('token') && !url.includes('unsubscribe')) {
    history.push('/dashboard');
    token = url.substring(url.indexOf('=') + 1, url.length);

    if (window.location.href) {
      setAuthorizationToken(token);
      if (store && store.dispatch) store.dispatch(setLoading(true));
      getLoggedAdmin();

      if (
        !validatePage() ||
        (!window.location.hash.includes('app') &&
          !window.location.hash.includes('support') &&
          !window.location.hash.includes('contact') &&
          !window.location.hash.includes('faq'))
      ) {
        history.push('/app/dashboard');
      }
    } else {
      if (
        (!window.location.hash.includes('password-reset') && !validatePage()) ||
        window.location.hash.includes('app')
      ) {
        history.push('/login');
      }
    }
  }
}
