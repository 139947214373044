import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { reduxForm } from 'redux-form';

class DefinitionsListEmptyRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      defaultValues: false,
      definition: { term: '', definition: '' },
    };
  }

  validate = (fields, callback) => {
    let errors = {};

    if (!fields.term) {
      errors.term = 'This field cant be empty';
    }

    if (this.props.termAlreadyExists(fields.term)) {
      errors.term = 'Term already exists';
    }

    if (!fields.definition) {
      errors.definition = 'This field cant be empty';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    callback(fields);
    this.setState({
      errors: null,
      waiting: true,
      definition: { term: '', definition: '' },
    });
    document.getElementById('definition-term').focus();
  };

  submitForm = () => {
    this.validate(this.state.definition, this.props.onAdd);
  };

  onKeyDown = ev => {
    ev.keyCode === 13 && this.submitForm();
  };

  onInputChange = event => {
    let definition = this.state.definition;
    definition[event.target.name] = event.target.value;
    this.setState({ definition: definition });
  };

  getEditingItem = () => {
    return (
      <Row className={this.props.style}>
        <Col sm={5}>
          <input
            name="term"
            className="form-control"
            type="text"
            placeholder="Term"
            id="definition-term"
            onKeyDown={this.onKeyDown}
            onChange={this.onInputChange}
            value={this.state.definition.term}
          />
          {this.state.errors &&
          this.state.errors['term'] && (
            <span className="errors">{this.state.errors['term']}</span>
          )}
        </Col>
        <Col sm={5}>
          <input
            name="definition"
            className="form-control"
            type="text"
            placeholder="Definition"
            onKeyDown={this.onKeyDown}
            onChange={this.onInputChange}
            value={this.state.definition.definition}
          />
          {this.state.errors &&
          this.state.errors['definition'] && (
            <span className="errors">{this.state.errors['definition']}</span>
          )}
        </Col>
        <Col sm={2} className="editableButtons">
          <i className="fa fa-plus fa-lg mt-1" onClick={this.submitForm} />
        </Col>
      </Row>
    );
  };

  render() {
    return <div>{this.getEditingItem()}</div>;
  }
}

DefinitionsListEmptyRow.propTypes = {
  onAdd: PropTypes.func,
};

export default reduxForm({ form: 'DefinitionsListEmptyRow' })(
  DefinitionsListEmptyRow
);
