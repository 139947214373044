import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CouponListContainer from './components/newCouponsContainer';
import CouponDetailContainer from './components/couponDetailContainer';

class newCoupons extends React.Component {
  state = {};

  render() {
    return (
      <Row className="height100">
        <Col md={3}>
          <CouponListContainer />
        </Col>
        <Col md={9} className="height100">
          <CouponDetailContainer />
        </Col>
      </Row>
    );
  }
}

export default newCoupons;
