import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap-button-loader';
import './ConfirmationPassword.css';
import { Col, Row } from 'react-bootstrap';

class ConfirmationPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      field: '',
      error: null,
      waiting: false,
    };
  }

  componentDidUpdate() {
    if (this.props.submitSucceeded && this.state.waiting) {
      this.setState({ waiting: false });
    }
  }

  validate = () => {
    if (!this.state.field || this.state.field === '') {
      this.setState({ error: 'Please type password' });
      return;
    }
    this.setState({ error: null, waiting: true });
    this.props.onSubmit(this.state.field);
  };

  render() {
    const {
      title,
      placeholder,
      onCancel,
      bodyText,
      submitBtnText,
    } = this.props;

    return (
      <div className="manual-red-component">
        <div className="card-block">
          <div className="createChapterTitle">
            {title}
            <i
              className="fa fa-times fa-xs"
              onClick={() => this.props.onCancel()}
            />
            <hr style={{ width: '610px', marginLeft: '-15px' }} />
          </div>
          <div className="text">{bodyText ? bodyText : ''}</div>
          <div className="widget-form manualRedConfirmationForm">
            <Row>
              <Col md={12}>
                <input
                  onChange={e => this.setState({ field: e.target.value })}
                  type="text"
                  className="form-control"
                  placeholder={placeholder}
                  value={this.props.value}
                />
                {this.state.error ? (
                  <div className="errors">{this.state.error}</div>
                ) : null}
              </Col>
            </Row>
            <hr style={{ width: '610px', marginLeft: '-15px' }} />
            <div className="bottom-buttons-new">
              <Button
                className="btn btn-success"
                onClick={this.validate}
                loading={this.state.waiting}
              >
                {submitBtnText ? submitBtnText : 'Submit'}
              </Button>
              <Button className="btn btn-secondary" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConfirmationPassword.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  requesting: PropTypes.bool,
};

export default ConfirmationPassword;
