import { Row, Container, Col, Button } from 'react-bootstrap';
import React from 'react';

class ActivityModal extends React.Component {
  state = {};
  render() {
    return (
      <div>
        <Row className="py-3">
          <Col className="col-4 pl-4">User</Col>
          <Col className="col-4 pl-4">Action</Col>
          <Col className="col-4 pl-4">
            {this.props.actions == 'subscriptionCancelFailed'
              ? 'Error message'
              : 'Stripe subscription'}
          </Col>
        </Row>
        <div className="separatorLine" />
        <Row className="py-3">
          <Col className="col-4 pl-4">{this.props.userEmail}</Col>
          <Col className="col-4 pl-4">{this.props.actions}</Col>
          <Col className="col-4 pl-4">{this.props.messageError}</Col>
        </Row>
        <Row className="py-3">
          <Col className="float-right">
            <Button
              className="btn-primary float-right"
              onClick={this.props.closeModal}
            >
              Close
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ActivityModal;
