import './Spinner.css';

import React from 'react';

class Spinner extends React.Component {
  render() {
    return (
      <div className="spinnerPosition" style={this.props.style}>
        <i
          className="fa fa-circle-o-notch fa-spin"
          style={{ color: this.props.color || '' }}
        />
        <br />
        {this.props.text || null}
      </div>
    );
  }
}

export default Spinner;
