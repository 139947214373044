import React from 'react';
import { Row } from 'react-bootstrap';
import Card from '../../../../commonComponents/Card/Card';
import RolesEditor from '../../../../commonComponents/RolesEditor/RolesEditor';
import Button from 'react-bootstrap-button-loader';
import ConfirmDeletion from '../../../../commonComponents/Modals/ConfirmDeletion/ConfirmDeletion';
import addAbilityToOpenModal from '../../../../utilities/modalHOC';
import Spinner from '../../../../commonComponents/Spinner/Spinner';

class RoleCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentWillReceiveProps({ creating, deleting, roles, selectedRole }) {
    const was = this.props;

    if (was.creating && !creating && roles.length) {
      let role = roles[roles.length - 1];
      this.props.setSelectedRole(role);
      this.props.updateRoleName(role.name);
      this.props.toggleNewRole(false);
    }

    if (was.deleting && !deleting) {
      this.props.toggleNewRole(true);
      this.props.setSelectedRole({});
      this.props.updateChecks([]);
      this.props.updateRoleName('');
      this.setState({ selectedRole: null, addMode: false, roleName: '' });
    }
  }

  renderContent = () => {
    return (
      <Row className="cardContentWrapper">
        <RolesEditor {...this.props} />
      </Row>
    );
  };

  deleteRole = () => {
    this.props.openModal({
      modalTitle: `Deleting Role: ${this.props.selectedRole.name}`,
      modalContent: (
        <ConfirmDeletion
          text={
            <span>
              Enter the word DELETE to confirm deletion.
              <br />
              <br />
              Please take in consideration that if you remove this role, it will
              affect any user that is associated to it, causing the users to
              lose permissions.
            </span>
          }
          onSubmit={() => {
            this.props.closeModal();
            this.props.onDelete(this.props.selectedRole);
          }}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  submitRole = () => {
    let role = {
      id: this.props.selectedRole && this.props.selectedRole.id,
      name: this.props.roleName,
      [this.props.permissionsAttribute]: this.props.checks.map(Number),
    };

    this.props.selectedRole.id
      ? this.props.onUpdate(role)
      : this.props.onCreate(role);
  };

  getCardFooter = (user, generalPermissions) => {
    if (!this.props.selectedRole && !this.props.selectedRole.id) return;
    return (
      <Row className="cardFooterWrapper">
        <div className="bottom-buttons">
          <Button
            type="submit"
            className="btn-success btn-success-roles"
            loading={this.props.creating || this.props.updating}
            onClick={() => this.submitRole()}
            disabled={
              (user && user.isSuperAdmin
                ? false
                : generalPermissions &&
                  (generalPermissions.find(
                    permission => permission === 'Roles.Edit',
                  ) ||
                    generalPermissions.find(
                      permission => permission === 'Settings.EditPermissions',
                    ))
                ? false
                : true) || this.props.deleting
            }
          >
            Save
          </Button>
          <Button
            onClick={this.deleteRole}
            loading={this.props.deleting}
            className="btn btn-danger btn-cancel-roles"
            disabled={
              (user && user.isSuperAdmin
                ? false
                : generalPermissions &&
                  generalPermissions.find(
                    permission => permission === 'Roles.Edit',
                  )
                ? false
                : true) ||
              this.props.creating ||
              this.props.updating
            }
          >
            Delete
          </Button>
        </div>
      </Row>
    );
  };

  render() {
    const {
      selectedRole,
      loggedUser,
      generalPermissions,
      isLoading,
    } = this.props;
    return (
      <div className="roleCard">
        {isLoading ? (
          <div className="roles-spinner">
            <Spinner />
          </div>
        ) : (
          <Card
            title={selectedRole.name ? selectedRole.name : 'New role'}
            content={this.renderContent()}
            footer={this.getCardFooter(loggedUser, generalPermissions)}
          />
        )}
      </div>
    );
  }
}

export default addAbilityToOpenModal(RoleCard);
