import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'definitions');
}

export const getDefinitions = state => getState(state).get('definitions');
export const getWaiting = state => getState(state).get('waiting');
export const getQuestionDefinitions = state =>
  getState(state).get('questionDefinitions');
export const getUpdatedDefinitions = state =>
  getState(state).get('updatedDefinitions');
