import React from 'react';
import Spinner from '../../Spinner/Spinner';
import '../CreateChapter/CreateChapter.css';
import { connect } from 'react-redux';
import {
  getAdminUserLogs,
  getAdminUserLogsPagination,
} from '../../../components/Admins/selectors';
import { fetchAdminUserLogs } from '../../../components/Admins/actionsSagas';
import { updateAdminLogs } from '../../../components/Admins/actions';
import Pagination from '../../Pagination/Pagination';
import moment from 'moment';

class BibliographyLogs extends React.Component {
  componentDidMount() {
    this.loadLogs();
  }

  componentWillUnmount() {
    this.props.clearLogs();
  }

  loadLogs = (page = 1) => {
    const { profile } = this.props;
    this.props.getLogs(profile.id, page);
  };

  render() {
    const { logs, onClose, profile, pagination } = this.props;
    return (
      <div className="create-chapter-component">
        <div className="card-block">
          <div className="createChapterTitle">
            Recorded IP for {profile.name} {profile.lastName}
            <i className="fa fa-times fa-xs" onClick={onClose} />
            <hr style={{ width: '600px', marginLeft: '-15px' }} />
          </div>
          {!logs && <Spinner text="Loading Logs" />}
          {logs &&
            logs.length > 0 &&
            logs.map(l => (
              <div className="BibliographyLog">
                IP: {l.ip} <br />
                Date: {moment(l.createdAt).format('L')} <br />
                Time: {moment(l.createdAt).format('LT')} <br />
              </div>
            ))}
          {logs &&
          logs.length === 0 && <div>There are no logs for this user</div>}
          {logs &&
          logs.length > 0 && (
            <div className="paginationContainer">
              <Pagination
                currentPage={pagination.page}
                totalRecords={pagination.total}
                onChangePage={this.loadLogs}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    logs: getAdminUserLogs(state),
    pagination: getAdminUserLogsPagination(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLogs: (adminId, page) => {
      dispatch(fetchAdminUserLogs(adminId, page));
    },
    clearLogs: () => {
      dispatch(updateAdminLogs(null, { total: 0, page: 1 }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BibliographyLogs);
