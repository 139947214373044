import { connect } from 'react-redux';
import { getSettings, getLoading } from '../selectors';
import { loadSettings, updateKey } from '../actionsSagas';
import Settings from './Settings';
import { reduxForm } from 'redux-form';
import { setLoading } from '../actions';
import { getGeneralRolesPermissions, getUser } from '../../Login/selectors';
import Notifications from 'react-notification-system-redux';

const mapStateToProps = (state) => {
  return {
    settings: getSettings(state),
    loading: getLoading(state),
    generalPermissions: getGeneralRolesPermissions(state),
    user: getUser(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: () => {
      dispatch(setLoading(true));
      dispatch(loadSettings());
    },
    updateKey: (key) => {
      dispatch(updateKey(key));
    },
    showWarning: (title, message) =>
      dispatch(Notifications.warning({ title, message }, 'failure')),
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Settings);

const SettingsContainer = reduxForm({
  form: 'settings',
})(connected);

export default SettingsContainer;
