import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './Banner.css';
import BannerList from './components/bannerList';
import BannerDetails from './components/bannerDetails';

class Banners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInput: '',
      loadInfo: false,
    };
  }
  componentWillMount() {
    if (!this.state.loadInfo) {
      this.props.getAllBanners();
      this.setState({
        loadInfo: true,
      });
    }
  }

  componentWillUnmount() {}

  componentWillReceiveProps = nextProps => {
    if (nextProps.loadingInfo) {
      this.props.getAllBanners();
    }
  };

  render() {
    return (
      <Row className="bannerContainer">
        <Col md={3} className="bannerListContainer">
          <div className="bannerList">
            <BannerList
              banners={this.props.banners}
              setBannerDetail={this.props.setBannerDetail}
              loadBannerDetails={this.props.loadBannerDetails}
              createBannerView={this.props.createBannerView}
            />
          </div>
        </Col>
        <Col md={9}>
          <BannerDetails
            bannerDetails={this.props.bannerDetails}
            loadBannerDetails={this.props.loadBannerDetails}
            loadBanner={this.props.loadBanner}
            createBannerView={this.props.createBannerView}
            viewBannerCreate={this.props.viewBannerCreate}
            createBanner={this.props.createBanner}
            editBannerView={this.props.editBannerView}
            editBanner={this.props.editBanner}
            deleteBanner={this.props.deleteBanner}
            activeBanner={this.props.activeBanner}
          />
        </Col>
      </Row>
    );
  }
}

export default Banners;
