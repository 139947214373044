import './QuestionGrid.css';

import React from 'react';
import _ from 'underscore';

import DropDown from '../../../../commonComponents/DropDown/DropDown';
import EditQuestionModal from '../../../../commonComponents/Modals/EditQuestionModal/EditQuestionModal';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import QuestionBirectionalList from '../../commonComponents/QuestionBirectionalList/QuestionBirectionalList';
import Pagination from './Pagination';
import QuestionGridRow from './QuestionGridRow';

export default class QuestionGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      questionsPerPage: 'ALL',
      spellCheckCells: false,
      sort: { by: 'presentationIndex', ascending: true },
    };
  }

  componentDidMount() {
    this.props.setIsCreatingAiQuestion(false);

    this.props.amountOfQuestionsInChapter(this.props.chapter.id);
    this.props.onClearQuestions();
    this.props.loadQuestionsOfChapter(
      this.props.chapter.id,
      0,
      this.props.amountOfQuestions,
      this.props.assignment ? this.props.assignment.id : null,
      false,
      0,
      this.props.isShowDeletedQuestions,
      true, // loadAll
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isCreatingAiQuestion !== this.props.isCreatingAiQuestion) {
      this.props.setIsCreatingAiQuestion(false);
    }
  }

  getOptions() {
    const style = {
      textAlign: 'center',
    };

    return [
      {
        name: 'Sort by Soft Page \nascending',
        event: () => {
          this.setState({ sort: { by: 'softwarePage', ascending: true } });
        },
        style,
      },
      {
        name: 'Sort by Soft Page \ndescending',
        event: () => {
          this.setState({ sort: { by: 'softwarePage', ascending: false } });
        },
        style,
      },
      {
        name: 'Sort by Index \nascending',
        event: () => {
          this.setState({
            sort: { by: 'presentationIndex', ascending: true },
          });
        },
        style,
      },
      {
        name: 'Sort by Index \ndescending',
        event: () => {
          this.setState({
            sort: { by: 'presentationIndex', ascending: false },
          });
        },
        style,
      },
    ];
  }

  getDefaultQuestion() {
    return {
      notes: '',
      softwarePage: null,
      documentPage: null,
      text: '',
      answers: [
        { correct: true, order: 0, text: '', id: 1 },
        { correct: false, order: 0, text: '', id: 2 },
        { correct: false, order: 0, text: '', id: 3 },
        { correct: false, order: 0, text: '', id: 4 },
      ],
      multipleOptionMode: true,
      defaultQuestion: true,
    };
  }

  createQuestion = (newQuestion) => {
    newQuestion.flashQuestionText = newQuestion.text;
    if (typeof newQuestion.customPromptId === 'string') {
      newQuestion.customPromptId = this.props.userCustomPrompts[0].id;
    }

    this.props.onCreateQuestion(
      newQuestion,
      this.props.assignment ? this.props.assignment.id : null,
    );
  };

  createQuestionModal = (newQuestion) => {
    this.props.openModal({
      modalClassName: 'modal-edit-question',
      modalContent: (
        <EditQuestionModal
          question={newQuestion}
          closeModal={this.props.closeModal}
          item={newQuestion}
          title="Question"
          textarea
          onSubmit={this.createQuestion}
          isQuestion
          isPresentationIndex={false}
          assignment={this.props.assignment ? this.props.assignment.id : null}
          multipleOption
          showWarning={this.props.showWarning}
          setIsCreatingAiQuestion={this.props.setIsCreatingAiQuestion}
          isCreatingAiQuestion={this.props.isCreatingAiQuestion}
          userCustomPrompts={this.props.userCustomPrompts}
          createUserCustomPrompt={this.props.createUserCustomPrompt}
          chapterId={this.props.chapter.id}
          improveQuestionWithAi={this.props.improveQuestionWithAi}
          selectedQuestion={this.props.selectedQuestion}
          isSaveQuestion={this.props.isSaveQuestion}
          updateIncludeNotesInSuggestion={
            this.props.updateIncludeNotesInSuggestion
          }
        />
      ),
    });
  };

  renderHeader() {
    const newQuestion = this.getDefaultQuestion();
    newQuestion.chapter = this.props.chapter.id;
    delete newQuestion.softwarePage;
    delete newQuestion.documentPage;
    return (
      <div className="headerContainer">
        <div style={{ width: '67px' }} className="tHeader">
          {this.props.editPermission && (
            <i
              onClick={() => this.createQuestionModal(newQuestion)}
              className="fa fa-plus fa-lg mt-1"
            />
          )}
        </div>
        <div style={{ width: '17%' }} className="tHeader">
          QUESTION
        </div>
        <div style={{ width: '13%' }} className="tHeader">
          OPTION A (CORRECT)
        </div>
        <div style={{ width: '13%' }} className="tHeader">
          OPTION B
        </div>
        <div style={{ width: '13%' }} className="tHeader">
          OPTION C
        </div>
        <div style={{ width: '13%' }} className="tHeader">
          OPTION D
        </div>
        <div
          style={{ width: '60px', textAlign: 'center', paddingLeft: '0px' }}
          className="tHeader"
        >
          Soft Page
        </div>
        <div
          style={{ width: '60px', textAlign: 'center', paddingLeft: '0px' }}
          className="tHeader"
        >
          Doc Page
        </div>
        <div style={{ width: '17%' }} className="tHeader">
          FURTHER READING
        </div>
        <div
          style={{
            width: '36px',
            overflow: 'visible',
          }}
          className="tHeader"
        >
          <DropDown
            style={{
              top: '-20px',
              position: 'absolute',
              left: '-160px',
              zIndex: 10000,
            }}
            title=""
            options={this.getOptions()}
          />
        </div>
      </div>
    );
  }

  renderRows(questions) {
    return _.sortBy(questions, 'presentationIndex').map((question, index) => (
      <QuestionGridRow
        key={index}
        question={question}
        amountOfQuestions={questions.length}
        {...this.props}
      />
    ));
  }

  rederRowsSortBy(questions) {
    let option = 'presentationIndex';
    const direction = this.state?.sort?.ascending ? 'asc' : 'desc';
    const orderBy = this.state?.sort?.by;
    if (orderBy) {
      switch (orderBy) {
        case 'softwarePage':
          option = 'softwarePage';
          break;
        default:
          option = 'presentationIndex';
      }
    }

    const questionSort =
      direction === 'asc'
        ? _.sortBy(questions, option)
        : _.sortBy(questions, option).reverse();

    return questionSort.map((question, index) => (
      <QuestionGridRow
        key={index}
        question={question}
        spellCheckCells={this.state.spellCheckCells}
        amountOfQuestions={questions.length}
        {...this.props}
      />
    ));
  }

  questionsPerPage = (amount) => {
    this.setState({ questionsPerPage: amount });
  };

  spellCheckCellsOnChange = () => {
    this.setState({ spellCheckCells: !this.state.spellCheckCells });
  };

  render() {
    const { questions } = this.props;
    return (
      <div className="questionGridContainer">
        <div className="gridActionsContainer">
          <Pagination
            assignment={
              this.props.assignment && this.props.assignment.id
                ? this.props.assignment.id
                : null
            }
            chapter={
              this.props.params
                ? this.props.params.chapter
                : this.props.chapter
                  ? this.props.chapter.id
                  : undefined
            }
            isShowDeletedQuestions={this.props.isShowDeletedQuestions}
            loadQuestionsOfChapter={this.props.loadQuestionsOfChapter}
            questionsPerPages={this.state.questionsPerPage}
            amountOfQuestions={parseInt(this.props.amountOfQuestions)}
            questionsPerPage={this.questionsPerPage}
            onClearQuestions={this.props.onClearQuestions}
            amountOfQuestionsInChapter={this.props.amountOfQuestionsInChapter}
          />
          <div className="spellCheckSwitch">
            Spell check cells{' '}
            <input
              type="checkbox"
              checked={this.state.spellCheckCells}
              value={this.state.spellCheckCells}
              onChange={this.spellCheckCellsOnChange}
            />
          </div>
        </div>

        {this.state.questionsPerPage === 'ALL' ? (
          <>
            {this.props.isUpdating && <Spinner />}
            <QuestionBirectionalList>
              <div className="questionGridContainerScroll">
                {this.renderHeader()}
                {this.state?.sort.by
                  ? this.rederRowsSortBy(questions)
                  : this.renderRows(questions)}
              </div>
            </QuestionBirectionalList>
          </>
        ) : (
          <>
            {(this.props.isLoadingQuestions || this.props.isUpdating) && (
              <Spinner />
            )}
            <div className="questionGridContainerScroll">
              {this.renderHeader()}
              {this.state?.sort.by
                ? this.rederRowsSortBy(questions)
                : this.renderRows(questions)}
            </div>
          </>
        )}
      </div>
    );
  }
}
