export default function (isQuestion, question, answer) {
  if (question?.wasAIGenerated && question.originalResponse && isQuestion) {
    let questionReplace = question.text.replace(/<\/?[^>]+(>|$)/g, '');
    questionReplace = questionReplace.replace(/&nbsp;/g, '');
    if (questionReplace === question.originalResponse.ai_question) return true;
    else return false;
  }

  if (
    !isQuestion &&
    question &&
    question.originalResponse &&
    answer &&
    answer.text
  ) {
    let answerReplace = answer.text.replace(/<\/?[^>]+(>|$)/g, ''); // remove html tags

    // unescape html entities
    answerReplace = answerReplace
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&amp;/g, '&');

    if (answerReplace === question.originalResponse.ai_answer) return true;
    else return false;
  }
}
