import * as type from './actionTypes';

export const setRolesForRates = response => ({
  type: type.SET_ROLES_FOR_RATES,
  payload: response,
});

export const setGroupsForStudyProgram = response => ({
  type: type.SET_GROUPS_FOR_STUDY_PROGRAM,
  payload: response,
});

export const waiting = (waiting, what) => ({
  type: type.UPDATE_WAITING,
  waiting,
  what,
});

export const removeGroup = groupId => ({
  type: type.REMOVE_GROUP,
  groupId,
});

export const setAdmins = admins => ({
  type: type.SET_ADMINS,
  payload: admins,
});

export const addGroup = group => ({
  type: type.ADD_GROUP,
  payload: group,
});

export const setConsultDeleteResponse = deleteResponse => ({
  type: type.DELETE_RESPONSE,
  payload: deleteResponse,
});

export const updateGroupToDelete = group => ({
  type: type.UPDATE_GROUP_TO_DELETE,
  payload: group,
});

export const setSelectedRate = rate => ({
  type: type.SET_SELECTED_RATE,
  rate,
});

export const setSelectedQual = qual => ({
  type: type.SET_SELECTED_QUAL,
  qual,
});

export const setViewRates = viewRates => ({
  type: type.SET_VIEW_RATES,
  viewRates,
});
