import { all, put, call, takeLatest } from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import { setSettings, setLoading } from './actions';
import {
  LOAD_SETTINGS,
  UPDATE_KEY,
  UPDATE_REFERRAL_DATES,
} from './actionTypesSagas';
import * as api from '../../utilities/ServiceManager';

/**
 *  LOAD SETTINGS
 */
function* loadSettingsSaga() {
  yield takeLatest(LOAD_SETTINGS, loadSettings);
}

function* loadSettings(action) {
  try {
    yield put(setLoading(true));
    let settings = yield call(api.getSettings);
    yield put(setSettings(settings));
  } catch (exception) {
    yield put(setLoading(false));
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load settings. Please try again later.',
      ),
    );
  } finally {
    yield put(setLoading(false));
  }
}

/**
 *  UPDATE KEY
 */
function* updateKeySaga() {
  yield takeLatest(UPDATE_KEY, updateKey);
}

function* updateReferralDatesSaga() {
  yield takeLatest(UPDATE_REFERRAL_DATES, updateReferralDates);
}

function* updateKey(action) {
  try {
    yield call(api.updateKey, action.key);
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Setting updated successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update setting. Please try again later.',
      ),
    );
  } finally {
  }
}

function* updateReferralDates(action) {
  try {
    yield call(api.updateReferralDates, action.dates);
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Referral dates updated successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update referral dates. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 * DEFAULT EXPORT
 */
export default function* sagas() {
  yield all([loadSettingsSaga(), updateKeySaga(), updateReferralDatesSaga()]);
}

function showNotificationErrorFromException(exception) {
  let message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
