import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from 'react-bootstrap-button-loader';
import { connect } from 'react-redux';
import {
  getFormErrors,
  getIsWaiting,
} from '../../../components/Users/selectors';
import { clearErrorFromService } from '../../../components/Users/actions';
import { InputWithoutIcon } from '../../Input/Input';

class ChangeStripeId extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
    };
  }

  componentWillUnmount() {
    this.props.clearErrorMessage();
  }

  validate(fields, callback) {
    let errors = {};
    if (!fields.stripeId) {
      errors.stripeId = 'stripe id is required';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
    fields.id = this.props.user.id;
    this.setState({ errors: null });
    callback(fields);
  }

  renderInput(name, placeholder, type = 'text', icon, defaultValue) {
    return (
      <div className="form-group">
        <span className="label">{placeholder}:</span>
        <Field
          name={name}
          type={type}
          component={InputWithoutIcon}
          icon={icon}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
        {this.state.errors &&
        this.state.errors[name] && (
          <span className="errors">{this.state.errors[name]}</span>
        )}
      </div>
    );
  }

  render() {
    const { handleSubmit, onSubmit, onCancel, formErrors } = this.props;
    let showError =
      (this.state.errors && this.state.errors.general) || formErrors;
    let errorMessage =
      this.state.errors && this.state.errors.general
        ? this.state.errors.general
        : formErrors;

    return (
      <div className="new-admin-component">
        <div className="card-block">
          <form
            className="widget-form change-stripe-id-form"
            onSubmit={handleSubmit(fields => this.validate(fields, onSubmit))}
          >
            {this.renderInput(
              'stripeId',
              'Stripe Id',
              'text',
              null,
              this.props.user.stripeId ? this.props.user.stripeId : ''
            )}
            {showError && <div className="errors">{errorMessage}</div>}
            <div className="bottom-buttons">
              <Button className="btn btn-secondary" onClick={() => onCancel()}>
                Cancel
              </Button>
              <Button
                className="btn btn-success"
                type="submit"
                loading={this.props.isWaiting}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    formErrors: getFormErrors(state),
    isWaiting: getIsWaiting(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearErrorMessage: () => {
      dispatch(clearErrorFromService());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({ form: 'ChangeStripeId' })(ChangeStripeId)
);
