import * as t from './actionsTypes';
import { Map } from 'immutable';

const initialState = Map({
  rates: [],
  rate: {},
  loadNotifications: false,
  allNotifications: false,
  notifications: [],
});

export default function(state = initialState, action) {
  switch (action.type) {
    case t.SET_ALL_RATES:
      return state.set('rates', action.rates);
    case t.SET_RATE:
      return state.set('rate', action.rate);
    case t.LOAD_NOTIFICATIONS:
      return state.set('loadNotifications', action.value);
    case t.SET_NOTIFICATIONS_BY_RATE:
      return state.set('notifications', action.notifications);
    default:
      return state;
  }
}
