import { put, call, takeLatest, all } from 'redux-saga/effects';
import {
  setIsSearchingBooks,
  setIsSearchingChapters,
  setIsSearchingQuestions,
  setSearchSuccess,
  setSearchError,
  setErrors,
  setSearchFromHeader,
  setBooksResponse,
  setChaptersResponse,
  setQuestionsResponse,
  attachBooksResponse,
  attachChaptersResponse,
  attachQuestionsResponse,
} from './actions';
import {
  SUBMIT_BOOKS_SEARCH,
  SUBMIT_CHAPTERS_SEARCH,
  SUBMIT_QUESTIONS_SEARCH,
} from './actionTypesSagas';
import * as api from '../../utilities/ServiceManager';
import Notifications from 'react-notification-system-redux';


export function* watchSubmitBooksSearch() {
  yield takeLatest(SUBMIT_BOOKS_SEARCH, submitBooksSearchSagas);
}

function* submitBooksSearchSagas(action) {
  const count = action.count;
  const query = action.query;
  const separatedTags = action.separatedTags;
  const more = action.more;

  //setting states
  yield put(setSearchFromHeader(false));
  yield put(setSearchSuccess(false));
  yield put(setSearchError(false));
  yield put(setErrors([]));
  yield put(setIsSearchingBooks(true));

  try {

    const booksResponse = yield call(api.makeAdvancedBooksSearch, query, separatedTags, count);

    if(more) {
      yield put(attachBooksResponse(booksResponse));
    } else {
      yield put(setBooksResponse(booksResponse));
    }  
    
    yield put(setIsSearchingBooks(false));


  } catch(error) {


    yield put(setSearchError(true));
    yield put(setSearchSuccess(false));
    yield put(setIsSearchingBooks(false));
    yield put(setBooksResponse({books: [], count: 0}));

    yield put(
      Notifications.show(
        { title: 'Oops!', message: error.response.data },
        'error'
      )
    );
  }
}

export function* watchSubmitChaptersSearch() {
  yield takeLatest(SUBMIT_CHAPTERS_SEARCH, submitChaptersSearchSagas);
}

function* submitChaptersSearchSagas(action) {
  const count = action.count;
  const query = action.query;
  const separatedTags = action.separatedTags;
  const more = action.more;

  //setting states
  yield put(setSearchFromHeader(false));
  yield put(setSearchSuccess(false));
  yield put(setSearchError(false));
  yield put(setErrors([]));
  yield put(setIsSearchingChapters(true));

  try {

    const chaptersResponse = yield call(api.makeAdvancedChaptersSearch, query, separatedTags, count);

    if(more) {
      yield put(attachChaptersResponse(chaptersResponse));
    } else {
      yield put(setChaptersResponse(chaptersResponse));
    }  
    
    yield put(setIsSearchingChapters(false));

  } catch(error) {


    yield put(setSearchError(true));
    yield put(setSearchSuccess(false));
    yield put(setIsSearchingChapters(false));
    yield put(setChaptersResponse({chapters: [], count: 0}));

    yield put(
      Notifications.show(
        { title: 'Oops!', message: error.response.data },
        'error'
      )
    );
  }
}

export function* watchSubmitQuestionsSearch() {
  yield takeLatest(SUBMIT_QUESTIONS_SEARCH, submitQuestionsSearchSagas);
}

function* submitQuestionsSearchSagas(action) {
  const count = action.count;
  const query = action.query;
  const separatedTags = action.separatedTags;
  const more = action.more;

  //setting states
  yield put(setSearchFromHeader(false));
  yield put(setSearchSuccess(false));
  yield put(setSearchError(false));
  yield put(setErrors([]));
  yield put(setIsSearchingQuestions(true));

  try {

    const questionsResponse = yield call(api.makeAdvancedQuestionsSearch, query, separatedTags, count);

    if(more) {
      yield put(attachQuestionsResponse(questionsResponse));
    } else {
      yield put(setQuestionsResponse(questionsResponse));
    }
    
    yield put(setIsSearchingQuestions(false));


  } catch(error) {


    yield put(setSearchError(true));
    yield put(setSearchSuccess(false));
    yield put(setIsSearchingQuestions(false));
    yield put(setQuestionsResponse({questions: [], count: 0}));

    yield put(
      Notifications.show(
        { title: 'Oops!', message: error.response.data },
        'error'
      )
    );
  }
}


/* SAGAS EXPORT */
export default function* sagas() {
  yield all([
    watchSubmitBooksSearch(),
    watchSubmitChaptersSearch(),
    watchSubmitQuestionsSearch(),
  ]);
}
