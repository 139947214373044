import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'cycles');
}

export const getCycles = state =>
  getState(state)
    .get('cycles')
    .toArray();

export const getCyclesImmutable = state => getState(state).get('cycles');

export const getSelectedCycle = state => getState(state).get('selectedCycle');
export const getUpdatedCycle = state => getState(state).get('updatedCycle');
