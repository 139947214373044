import { connect } from 'react-redux';
import {
  getWriters,
  getChapterWriters,
  getWaiting,
} from '../../Writers/selectors';
import AssignWriters from '../../../commonComponents/Modals/AssignWriters/AssignWriters';
import {
  loadWriters,
  getChapterWritersSaga,
  createAssignment,
} from '../../Writers/actionsSagas';
import { updateWaiting, clearWriters } from '../../Writers/actions';
import { getUser } from '../../../components/Login/selectors';
import { getRates } from '../../Rates/selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    preselectedValues: true,
    writers: getWriters(state),
    isWaiting: getWaiting(state),
    chapterWriters: getChapterWriters(state),
    references: ownProps.references,
    chapter: ownProps.chapter,
    rate: ownProps.rate,
    reference: ownProps.reference,
    closeModal: ownProps.closeModal,
    isQual: ownProps.isQual,
    assign: ownProps.assign,
    forAssign: true,
    loggedUser: getUser(state),
    selectedUser: ownProps.selectedUser,
    selectedStudyProgram: ownProps.selectedStudyProgram,
    rates: getRates(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadAssignment: (type, typeId, chapter) => {
      dispatch(updateWaiting(true));
      if (chapter) {
        dispatch(getChapterWritersSaga(chapter));
      }
      dispatch(loadWriters(type, typeId));
    },
    clearWriters: () => {
      dispatch(clearWriters());
    },
    createAssignment: (
      chapter,
      primaryWriter,
      secondaryWriters,
      dueDate,
      status,
      createdBy,
      studyProgram
    ) => {
      dispatch(
        createAssignment(
          chapter,
          primaryWriter,
          secondaryWriters,
          dueDate,
          status,
          createdBy,
          studyProgram,
          true
        )
      );
    },
  };
};

const AssignWritersContainer = connect(mapStateToProps, mapDispatchToProps)(
  AssignWriters
);

export default AssignWritersContainer;
