import { connect } from 'react-redux';
import NumberOfQuestionsEnabledPerRatingExam from './NumberOfQuestionsEnabledPerRatingExam';
import {
  getNumberOfQuestionsEnabledPerRatingExamReport,
  clearNumberOfQuestionsEnabledPerRatingExamAPIError,
  clearNumberOfQuestionsEnabledPerRatingExamReportProps,
} from '../../actions';
import {
  getNumberOfQuestionsEnabledPerRatingExamReportCSVData,
  getLastRateToUseNumberOfQuestionsEnabledPerRatingExamReport,
  getNumberOfQuestionsEnabledPerRatingExamReportHasMore,
  getOverallUniqueNumberOfQuestionsEnabledPerRatingExamReport,
  getAPIErrorNumberOfQuestionsEnabledPerRatingExamReport,
} from '../../selectors';

const mapStateToProps = (state) => {
  return {
    lastRate:
      getLastRateToUseNumberOfQuestionsEnabledPerRatingExamReport(state),
    hasMore: getNumberOfQuestionsEnabledPerRatingExamReportHasMore(state),
    CSVData: getNumberOfQuestionsEnabledPerRatingExamReportCSVData(state),
    APIError: getAPIErrorNumberOfQuestionsEnabledPerRatingExamReport(state),
    overallUnique:
      getOverallUniqueNumberOfQuestionsEnabledPerRatingExamReport(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNumberOfQuestionsEnabledPerRatingExamReport: (lastRate) => {
      dispatch(getNumberOfQuestionsEnabledPerRatingExamReport(lastRate));
    },
    clearProps: () => {
      dispatch(clearNumberOfQuestionsEnabledPerRatingExamReportProps());
    },
    clearNumberOfQuestionsEnabledPerRatingExamAPIError: () => {
      dispatch(clearNumberOfQuestionsEnabledPerRatingExamAPIError());
    },
  };
};

const NumberOfQuestionsPerRatingExam = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NumberOfQuestionsEnabledPerRatingExam);

export default NumberOfQuestionsPerRatingExam;
