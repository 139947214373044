import React from 'react';
import { reduxForm } from 'redux-form';
import Button from 'react-bootstrap-button-loader';
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import './AssignWriters.css';
import _ from 'underscore';
import Spinner from '../../Spinner/Spinner';
import { SimpleDatePicker } from '../../Datepicker/Datepicker';

class AssignWriters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      primaryWriter: this.getDefaultPrimaryWriter(),
      secondaryWriters: this.getDefaultSecondaryWriter(),
      references: [],
      focused: false,
      dueDate: null,
      chapter: this.props.preselectedValues
        ? this.props.references[0].chapters[0].id
        : 0,
      book: this.props.preselectedValues ? this.props.references[0].id : 0,
      showChapters: true,
      rate: 0,
    };
  }

  componentWillMount() {
    let type = this.props.isQual ? 'qual' : 'rate';
    if (this.props.forAssign) {
      if (this.props.rate !== 0) {
        this.props.onLoadAssignment(type, this.props.rate);
      } else {
        this.props.clearWriters();
      }
      if (this.props.assignmentId)
        this.props.onLoadReassign(this.props.assignmentId);
    } else {
      if (this.props.onLoad) {
        this.props.onLoad(type, this.props.rate, this.getChapter().id);
      }
    }
  }

  componentWillUnmount() {
    this.props.clearWriters();
  }

  componentWillReceiveProps({
    writers,
    chapterWriters,
    forAssign,
    assignment,
    selectedUser,
  }) {
    let primaryWriter = this.state.primaryWriter;
    let secondaryWriters = this.state.secondaryWriters;
    if (chapterWriters) {
      let primary =
        chapterWriters.primary && chapterWriters.primary !== 0
          ? chapterWriters.primary
          : this.state.primaryWriter;
      secondaryWriters = _.pluck(chapterWriters.secondary, 'id');
      primaryWriter = primary;
    }
    if (
      forAssign &&
      chapterWriters.primary === 0 &&
      chapterWriters.secondary.length === 0
    ) {
      primaryWriter = 0;
      secondaryWriters = [];
    }

    if (forAssign && assignment && assignment.id) {
      let writers = assignment.assignedTo.map(writer => writer.id);
      primaryWriter = assignment.primaryWriter;
      secondaryWriters = writers.filter(writer => writer !== primaryWriter);
    }

    if ((forAssign && !assignment) || !assignment.id) {
      let notListed = true;
      if (writers) {
        writers.forEach(writter => {
          if (writter.id == selectedUser) {
            notListed = false;
          }
        });
      }
      if (notListed) {
        primaryWriter = 0;
      } else {
        primaryWriter = selectedUser;
      }
    }
    this.setState({
      primaryWriter: primaryWriter,
      secondaryWriters: secondaryWriters,
    });
  }

  getChapter = () => {
    return this.props.chapter
      ? this.props.chapter
      : this.props.reference &&
        this.props.reference.chapters &&
        this.props.reference.chapters.length
      ? this.props.reference.chapters[0]
      : {};
  };

  getDefaultPrimaryWriter = () => {
    let chapter = this.getChapter();
    return chapter.primaryWriter && !this.props.forAssign
      ? chapter.primaryWriter
      : 0;
  };

  getDefaultSecondaryWriter = () => {
    let chapter = this.getChapter();
    return chapter.writers && !this.props.forAssign ? chapter.writers : [];
  };

  assignWriters = () => {
    if (this.props.forAssign) {
      if (this.props.assignmentId) {
        if (this.props.selectedWriterEmail) {
          this.props.updateAssignment(
            this.props.assignmentId,
            this.state.primaryWriter,
            this.state.secondaryWriters,
            this.props.selectedWriterEmail,
          );
        } else {
          this.props.updateAssignment(
            this.props.assignmentId,
            this.state.primaryWriter,
            this.state.secondaryWriters,
          );
        }
      } else {
        let sp;
        if (this.props.rate === 0) {
          sp = this.props.reference.studyPrograms.find(
            sp => sp.typeId === Number(this.state.rate) && sp.type === 'rate',
          );
          sp = sp ? sp.typeInstance : null;
        } else {
          sp = this.props.selectedStudyProgram;
        }
        this.props.createAssignment(
          Number(this.state.chapter),
          this.state.primaryWriter,
          this.state.secondaryWriters,
          this.state.dueDate ? this.state.dueDate.toJSON() : null,
          null,
          this.props.loggedUser.id,
          sp,
        );
      }
    } else {
      this.props.onAssignWriters(
        this.props.chapter,
        this.state.primaryWriter,
        this.state.secondaryWriters,
        this.props.reference,
      );
    }
  };

  selectPrimaryWriter = id => {
    this.setState({
      secondaryWriters: this.state.secondaryWriters.filter(
        writer => writer !== parseInt(id, 10),
      ),
      primaryWriter: parseInt(id, 10),
    });
  };

  selectSecondaryWriters = event => {
    let idToAdd = parseInt(event.params.data.id, 10);
    if (this.state.secondaryWriters.indexOf(idToAdd) < 0) {
      let newSecondaryWriters = this.state.secondaryWriters;
      newSecondaryWriters.push(idToAdd);
      this.setState({ secondaryWriters: newSecondaryWriters });
    }
  };

  unselectSecondaryWriters = event => {
    let idToRemove = parseInt(event.params.data.id, 10);
    let newSecondaryWriters = this.state.secondaryWriters.filter(
      writer => writer !== idToRemove,
    );
    this.setState({ secondaryWriters: newSecondaryWriters });
  };

  selectChapter = id => {
    this.setState({ chapter: id });
    if (id !== '0') {
      this.props.loadWritersOfChapter(Number(id));
    } else {
      this.setState({ primaryWriter: 0, secondaryWriters: [] });
    }
  };

  isAssignedChapter = (chapter, assignments) => {
    var indexAssigned = assignments.findIndex(
      assignment => assignment.chapter.id === chapter.id,
    );
    return indexAssigned !== -1
      ? { assigned: true, assignedTo: assignments[indexAssigned].assignedTo }
      : { assigned: false };
  };

  getNameAssignedTo = assignedTo => {
    var res = ' (';
    assignedTo.forEach(writer => {
      res += writer.name + ' ' + writer.lastName + ' - ';
    });
    return res.slice(0, res.length - 3) + ')';
  };

  renderSelectChapters = () => {
    let { preselectedValues, references } = this.props;
    if (preselectedValues) {
      return (
        <div className="form-group greyText">
          <span className="label labelPosition">Chapter:</span>
          {references[0].chapters[0].name}
        </div>
      );
    }
    let selectedBook = this.props.references.find(
      b => b.id === Number(this.state.book),
    );
    let options = [];
    let styles = {
      color: 'red',
    };
    if (selectedBook) {
      options.push(
        <option key={`writersForAssign1`} value={0} disabled={true}>
          Select a Chapter{' '}
        </option>,
      );
      options = options.concat(
        selectedBook.chapters.map(chapter => {
          let disabled = this.isAssignedChapter(
            chapter,
            this.props.assignments,
          );
          if (disabled.assigned) {
            var nameAssigned = this.getNameAssignedTo(disabled.assignedTo);
            return (
              <option
                style={styles}
                key={`writersForAssign1${chapter.id}`}
                value={chapter.id}
                disabled={true}
              >
                {`* ${chapter.name} ${nameAssigned}`}
              </option>
            );
          } else if (
            chapter.subtopicActiveCycleCount === 0 ||
            Number(chapter.subtopicCount) === 0 ||
            Number(chapter.questionCount) === 0
          ) {
            return (
              <option
                style={styles}
                key={`writersForAssign1${chapter.id}`}
                value={chapter.id}
              >
                {` ${chapter.name} (${chapter.questionCount} questions, ${
                  chapter.subtopicCount
                } subtopics) `}
              </option>
            );
          } else {
            return (
              <option
                key={`writersForAssign1${chapter.id}`}
                value={chapter.id}
                disabled={false}
              >
                {` ${chapter.name} (${chapter.questionCount} questions, ${
                  chapter.subtopicCount
                } subtopics) `}
              </option>
            );
          }
        }),
      );
    } else {
      options.push(
        <option key={`writersForAssign1`} value={0}>
          Select a Book
        </option>,
      );
    }
    return (
      <div
        className={selectedBook && !selectedBook.actAsParent ? 'hidden' : ''}
      >
        <span className="label labelPosition">Chapter</span>
        <select
          id="selectChapter"
          className="form-control marginBottom5"
          value={this.state.chapter}
          onChange={e => this.selectChapter(e.target.value)}
        >
          {options}
        </select>
      </div>
    );
  };

  getIdChaptersFromBook = book => {
    if (book) {
      let array = [];
      book.chapters.forEach(data => {
        array.push(data.id);
      });
      return array;
    } else return;
  };

  unassignedChapterFromBook = (book, assignments) => {
    let res = [];
    if (book) {
      book.chapters.forEach(data => {
        assignments.findIndex(
          assignment => assignment.chapter.id === data.id,
        ) === -1 && res.push(data.id);
      });
    }
    return res;
  };

  setSelectedBook = book => {
    let chapter = 0;
    let showChapters = true;
    let reference = this.props.references.find(r => r.id === Number(book));
    if (reference && !reference.actAsParent) {
      chapter = reference.chapters[0].id;
      showChapters = false;
    }
    this.setState({ book, chapter, showChapters });
  };

  renderSelectBooks = () => {
    let {
      forAssign,
      assignmentId,
      assignment,
      preselectedValues,
      references,
    } = this.props;
    let styles = {
      color: 'red',
    };
    if (preselectedValues) {
      return (
        <div className="form-group greyText">
          <span className="label labelPosition">Book:</span>
          {references[0].name}
        </div>
      );
    }
    if (forAssign && assignmentId) {
      return (
        <div className="form-group greyText">
          <span className="label labelPosition">Book:</span>
          {assignment &&
            assignment.chapter &&
            assignment.chapter.reference &&
            assignment.chapter.reference.name}
        </div>
      );
    }
    return (
      <div>
        <span className="label labelPosition">Book</span>
        {this.props.references.length > 0 ? (
          ''
        ) : (
          <i className="fa fa-circle-o-notch fa-spin" />
        )}
        <select
          className="form-control marginBottom5"
          value={this.state.book}
          onChange={e => this.setSelectedBook(e.target.value)}
        >
          <option key={`writersForAssign12`} value={0}>
            {this.props.references.length > 0 ? 'Select a Book' : ''}
          </option>
          {this.props.references.map(book => {
            let disabled = this.isDisableBook(book, this.props.assignments);
            if (!book.actAsParent) {
              if (disabled) {
                let assigned = this.isAssignedChapter(
                  book.chapters[0],
                  this.props.assignments,
                );
                let name = assigned
                  ? this.getNameAssignedTo(assigned.assignedTo)
                  : '';
                let assignedName = disabled && !book.actAsParent ? name : '';
                return (
                  <option
                    style={styles}
                    key={`writersForAssign1${book.id}`}
                    value={book.id}
                    disabled
                  >
                    {`* ${book.name} +  ${assignedName}`}
                  </option>
                );
              } else {
                return (
                  <option
                    style={styles}
                    key={`writersForAssign1${book.id}`}
                    value={book.id}
                  >
                    {`* ${book.name} `}
                  </option>
                );
              }
            } else {
              return (
                <option key={`writersForAssign1${book.id}`} value={book.id}>
                  {book.name}
                </option>
              );
            }
          })}
        </select>
      </div>
    );
  };

  isDisableBook = (book, assignments) => {
    let chapters = book.chapters;
    return chapters.every(
      elem => this.isAssignedChapter(elem, assignments).assigned,
    );
  };

  allowSelectChapter = options => {
    return !options.every(elem => elem.props.disabledChapter);
  };

  renderSelectDate = () => {
    return (
      <div className="form-group">
        <span className="label labelPosition">Due date:</span>
        <SimpleDatePicker
          date={this.state.dueDate}
          onChange={dueDate => this.setState({ dueDate })}
          placeholderText="MM/DD/YYYY"
          className="datePickerClass"
        />
        {this.state.errors && this.state.errors['dueDate'] && (
          <span className="errors">{this.state.errors['dueDate']}</span>
        )}
      </div>
    );
  };

  renderChapterName = () => {
    let { assignment } = this.props;
    if (!assignment || !assignment.chapter) {
      return '';
    }

    return (
      <div className="form-group greyText">
        <span className="label labelPosition">Chapter:</span>
        {assignment.chapter &&
        assignment.chapter.reference &&
        !assignment.chapter.reference.actAsParent
          ? assignment.chapter.reference.name
          : assignment.chapter && assignment.chapter.name
          ? assignment.chapter.name
          : ''}
      </div>
    );
  };

  setSelectedRate = rate => {
    this.setState({ rate });
    this.props.onLoadAssignment('rate', rate);
  };

  renderSelectRate = () => {
    if (this.props.rate !== 0) {
      return '';
    }
    let bookRates = this.props.reference.studyPrograms
      .filter(s => s.type === 'rate')
      .map(s => s.typeId);
    let filteredRates = this.props.rates.filter(r => bookRates.includes(r.id));
    return (
      <div className="form-group">
        <span className="label labelPosition">Select Rate:</span>
        <select
          className="form-control"
          value={this.state.rate}
          onChange={e => this.setSelectedRate(e.target.value)}
        >
          <option key={`writersForAssign0`} value={0} disabled={true}>
            Select a rate
          </option>
          {filteredRates.map(rate => (
            <option key={`writersForAssign-rate-${rate.id}`} value={rate.id}>
              {`${rate.shortname}`}
            </option>
          ))}
        </select>
      </div>
    );
  };

  render() {
    const { closeModal } = this.props;

    let possibleSecondaryWriters = this.props.writers.filter(
      writer => writer.id !== this.state.primaryWriter,
    );
    let noWritersText = this.props.isQual
      ? 'There are no writers for this qual'
      : 'There are no writers for this rate';
    return (
      <div className="assign-writers-component">
        <div className="card-block">
          <div className="createChapterTitle">
            {this.props.title}
            <i className="fa fa-times fa-xs" onClick={closeModal} />
            <hr style={{ width: '594px', marginLeft: '-15px' }} />
          </div>
          {this.props.isWaiting ? (
            <Spinner text="Loading writers" />
          ) : this.props.writers.length > 0 || this.props.forAssign ? (
            <form className="widget-form new-admin-form">
              {this.renderSelectRate()}
              {this.renderSelectBooks()}
              {this.props.forAssign &&
                !this.props.assignmentId &&
                this.state.showChapters &&
                this.renderSelectChapters()}
              {this.props.forAssign &&
                this.props.assignmentId &&
                this.renderChapterName()}
              <div className="form-group">
                <span className="label labelPosition">Primary writer:</span>
                <select
                  className="form-control"
                  value={this.state.primaryWriter}
                  onChange={e => this.selectPrimaryWriter(e.target.value)}
                >
                  <option key={`writersForAssign_0`} value={0}>
                    Select a writer
                  </option>
                  {this.props.writers.map((writer, index) => (
                    <option
                      key={`writersForAssign_${writer.id}_${index}`}
                      value={writer.id}
                    >
                      {`${writer.name} ${writer.lastName} (${
                        writer.assignments
                      }) `}
                    </option>
                  ))}
                </select>
                {this.props.forAssign &&
                  this.state.errors &&
                  this.state.errors['primaryWriter'] && (
                    <span className="errors">
                      {this.state.errors['primaryWriter']}
                    </span>
                  )}
              </div>
              <div className="form-group">
                <span className="label labelPosition">Secondary writers:</span>
                <div className="selectorContainer">
                  <Select2
                    value={this.state.secondaryWriters}
                    className="writersSelector"
                    multiple
                    data={possibleSecondaryWriters.map(writer => {
                      writer.text = writer.name + ' ' + writer.lastName;
                      return writer;
                    })}
                    options={{
                      placeholder: 'Search by name',
                    }}
                    onSelect={this.selectSecondaryWriters}
                    onUnselect={this.unselectSecondaryWriters}
                  />
                </div>
              </div>
              {this.props.forAssign &&
                !this.props.assignmentId &&
                this.renderSelectDate()}
              {this.state.errors && this.state.errors.general && (
                <div className="errors">{this.state.errors.general}</div>
              )}
              <hr style={{ width: '596px', marginLeft: '-15px' }} />
              <div className="bottom-buttons-new">
                <Button
                  className="btn btn-success"
                  onClick={this.assignWriters}
                  loading={this.state.isWaiting}
                  disabled={
                    this.state.primaryWriter === 0 ||
                    ((!this.state.chapter || this.state.chapter === 0) &&
                      this.props.forAssign &&
                      !this.props.assignmentId)
                  }
                >
                  Submit
                </Button>
                <Button className="btn btn-secondary" onClick={closeModal}>
                  Cancel
                </Button>
              </div>
            </form>
          ) : (
            <div className="center-text">{noWritersText}</div>
          )}
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: 'AssignWriters' })(AssignWriters);
