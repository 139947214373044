import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { logoutUser, logoutUserSagas } from '../Login/actions';
import { unsetUser } from '../User/actions';

const Logout = class extends React.Component {
  componentWillMount() {
    this.props.dispatch(logoutUserSagas());
    this.props.dispatch(logoutUser());
    this.props.dispatch(unsetUser());
    browserHistory.replace('login');
  }

  render() {
    return null;
  }
};

export default connect()(Logout);
