import * as React from 'react';

export default function({ onClick, name, selected, id, active }) {
  return (
    <div className="cycle-row">
      <a
        className={selected ? 'cycles-line active' : 'cycles-line'}
        onClick={onClick}
      >
        {name}
      </a>
      {active && <i className="fa fa-check" />}
    </div>
  );
}
