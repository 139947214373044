import { Map } from 'immutable';

import * as type from './actionTypes';

const initialState = Map({
  filter: 'Assigned to bibs',
  dutyState: '',
  rank: '',
});

export default function(state = initialState, action) {
  switch (action.type) {
    case type.UPDATE_FILTER:
      return state.set('filter', action.filter);

    case type.UPDATE_DUTYSTATE_REFERENCES:
      return state.set('dutyStateReferences', action.dutyState);

    case type.UPDATE_RANK_REFERENCES:
      return state.set('rankReferences', action.rank);

    default:
      return state;
  }
}
