import Notifications from 'react-notification-system-redux';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import * as api from '../../utilities/ServiceManager';
import {
  loadPromptDetails,
  promptDetailsAction,
  setAllPromptsAction,
  setLoadingInfo,
} from './actionSagas';
import {
  CREATE_PROMPT,
  DELETE_PROMPT,
  EDIT_PROMPT,
  GET_ALL_PROMPTS,
  SET_PROMPT_DETAILS,
  TOGGLE_ACTIVE_PROMPT,
} from './actionTypes';
import { getPromptsSelectors } from './selectors';

/* LOAD PROMPTS */
function* loadAllPrompts() {
  yield takeEvery(GET_ALL_PROMPTS, loadAllPromptsSaga);
}

function* loadAllPromptsSaga() {
  try {
    const response = yield call(api.getAllPromptsService);

    yield put(setAllPromptsAction(response));
  } catch (exception) {
    yield put(
      Notifications.show(
        {
          title: 'Ups!',
          message: 'Could not load prompts. Please try again later.',
        },
        'error',
      ),
    );
  }
}

function* setPromptDetails() {
  yield takeEvery(SET_PROMPT_DETAILS, setPromptDetailsSaga);
}

function* setPromptDetailsSaga(action) {
  try {
    const response = yield call(api.getPromptService, action.prompt);
    yield put(promptDetailsAction(response));
  } catch (exception) {
    yield put(
      Notifications.show(
        {
          title: 'Ups!',
          message: 'Could not load prompts. Please try again later.',
        },
        'error',
      ),
    );
  }
}

function* createPrompt() {
  yield takeEvery(CREATE_PROMPT, createPromptSaga);
}

function* createPromptSaga(action) {
  try {
    yield put(setLoadingInfo(true));
    const response = yield call(api.createPromptService, action.prompt);
    yield put(setLoadingInfo(false));
    if (!response) {
      yield put(
        Notifications.show(
          {
            title: 'Ups!',
            message: 'It is not possible to create more than 1 active prompt.',
          },
          'error',
        ),
      );
    } else {
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message: 'Prompt created successfully',
            autoDismiss: 1,
          },
          'success',
        ),
      );
      const allPrompts = yield call(api.getAllPromptsService);
      yield put(setAllPromptsAction(allPrompts));
    }
  } catch (exception) {
    yield put(setLoadingInfo(false));
    yield put(
      Notifications.show(
        {
          title: 'Ups!',
          message: 'Could not create prompts. Please try again later.',
        },
        'error',
      ),
    );
  }
}

function* editPrompt() {
  yield takeEvery(EDIT_PROMPT, editPromptSaga);
}

function* editPromptSaga(action) {
  try {
    yield put(setLoadingInfo(true));
    const response = yield call(api.setPromptService, action.prompt);
    yield put(setLoadingInfo(false));
    if (!response) {
      yield put(
        Notifications.show(
          {
            title: 'Ups!',
            message: 'It is not possible to have more than 1 active prompt.',
          },
          'error',
        ),
      );
    } else {
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message: 'Prompt edit successfully',
            autoDismiss: 1,
          },
          'success',
        ),
      );
      yield put(promptDetailsAction(response));
    }
  } catch (exception) {
    yield put(setLoadingInfo(false));
    yield put(
      Notifications.show(
        {
          title: 'Ups!',
          message: 'Could not edit prompts. Please try again later.',
        },
        'error',
      ),
    );
  }
}

function* deletePrompt() {
  yield takeEvery(DELETE_PROMPT, deletePromptSaga);
}

function* deletePromptSaga(action) {
  try {
    yield put(setLoadingInfo(true));
    yield call(api.deletePromptService, action.prompt);
    yield put(setLoadingInfo(false));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Prompt delete successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
    const allPrompts = yield call(api.getAllPromptsService);
    yield put(setAllPromptsAction(allPrompts));
    yield put(loadPromptDetails(false));
  } catch (exception) {
    yield put(setLoadingInfo(false));
    yield put(
      Notifications.show(
        {
          title: 'Ups!',
          message: 'Could not delete prompts. Please try again later.',
        },
        'error',
      ),
    );
  }
}

function* toggleActivePrompt() {
  yield takeEvery(TOGGLE_ACTIVE_PROMPT, toggleActivePromptSaga);
}

function* toggleActivePromptSaga(action) {
  try {
    yield put(setLoadingInfo(true));
    const response = yield call(
      api.setPromptActiveService,
      action.promptId,
      action.value,
    );
    const allPrompts = yield call(api.getAllPromptsService);
    yield put(setAllPromptsAction(allPrompts));
    yield put(setLoadingInfo(false));
    if (!response) {
      yield put(
        Notifications.show(
          {
            title: 'Ups!',
            message: 'It is not possible to more than 1 active prompt.',
          },
          'error',
        ),
      );
    } else {
      yield put(promptDetailsAction(response));
      const message = response.active
        ? 'Prompt set as active successfully'
        : 'Prompt deactivated successfully';
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message,
            autoDismiss: 1,
          },
          'success',
        ),
      );
    }
  } catch (exception) {
    yield put(setLoadingInfo(false));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message:
            'Could not active or inactive prompts. Please try again later.',
        },
        'error',
      ),
    );
  }
}

export default function* sagas() {
  yield all([
    loadAllPrompts(),
    setPromptDetails(),
    createPrompt(),
    editPrompt(),
    deletePrompt(),
    toggleActivePrompt(),
  ]);
}
