import React from 'react';
import { Col } from 'react-bootstrap';
import { AssignmentsStatusFilters } from '../../../../constants/assignments';
import './StatusFilters.css';
import NumberUtils from '../../../../lib/NumberUtils';

export default class StatusFilters extends React.Component {
  getStatusCount(status) {
    if (status === 'Currently assigned') {
      let total = 0;
      this.props.assignmentsCount.map(count => {
        if (count.name !== 'Published' && count.name !== 'Completed')
          total += count.count;
        return count;
      });
      return total;
    }
    if (this.props.assignmentsCount.length > 0) {
      let value = this.props.assignmentsCount.find(count => {
        if (count.name === status) return count;
        if (status === 'In progress' && count.name === 'In_Progress')
          return count;
        if (status === 'Not Started' && count.name === 'Not_Started')
          return count;
        return null;
      });
      if (value) {
        return value.count;
      }
      return 0;
    }
    return 0;
  }

  getStatusFilters = () => {
    return AssignmentsStatusFilters.map((status, index) => (
      <div
        key={NumberUtils.obtainUniqueKey()}
        onClick={() => this.props.onStatusChange(status)}
        className={
          this.props.activeStatus === status ? (
            'activeStatus statusName'
          ) : (
            'statusName'
          )
        }
      >
        {status +
          ': ' +
          this.getStatusCount(status === 'Completed' ? 'Published' : status)}
      </div>
    ));
  };

  render() {
    return (
      <Col md={12} className="statusHeaderContainer">
        {this.props.activeStatus !== 'awaitingApproval' && (
          <div className="assignmentsStatusContainer">
            {this.getStatusFilters()}
          </div>
        )}
      </Col>
    );
  }
}
