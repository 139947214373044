import { connect } from 'react-redux';

import {
  clearAutomaticMasterReportProps,
  clearMasterReportAPIError,
  clearMasterReportProps,
  downloadMasterUserReport,
  getMasterUserReport,
  latestCsvCreationDate,
} from '../../actions';
import {
  getAPIError,
  getAutomaticMasterReportData,
  getCsvAvailability,
  getLastCustomerMaster,
  getLatestCreationDate,
  getMasterReportCSVData,
  getMasterReportHasMore,
} from '../../selectors';
import MasterUserReport from './MasterUserReport';

const mapStateToProps = (state) => ({
  lastCustomer: getLastCustomerMaster(state),
  hasMore: getMasterReportHasMore(state),
  CSVData: getMasterReportCSVData(state),
  APIError: getAPIError(state),
  isCsvAvailable: getCsvAvailability(state),
  automaticMasterReportData: getAutomaticMasterReportData(state),
  lastCreationDate: getLatestCreationDate(state),
});

const mapDispatchToProps = (dispatch) => ({
  getMasterUserReport: (lastCustomer) => {
    dispatch(getMasterUserReport(lastCustomer));
  },
  clearProps: () => {
    dispatch(clearMasterReportProps());
  },
  clearAutomaticReportProps: () => {
    dispatch(clearAutomaticMasterReportProps());
  },
  clearMasterReportAPIError: () => {
    dispatch(clearMasterReportAPIError());
  },
  downloadMasterUserReport: () => {
    dispatch(downloadMasterUserReport());
  },
  latestCsvCreationDate: () => {
    dispatch(latestCsvCreationDate());
  },
});

const HistoricalUsersReportContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MasterUserReport);

export default HistoricalUsersReportContainer;
