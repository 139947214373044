import * as type from './actionTypesSagas';

export const loadCycles = () => ({
  type: type.LOAD_CYCLES,
});

export const createCycleActionSagas = fields => ({
  type: type.CREATE_CYCLES_SAGAS,
  fields,
});

export const updateCycleActionSagas = fields => ({
  type: type.UPDATE_CYCLE_SAGAS,
  fields,
});

export const deleteCycleActionSagas = id => ({
  type: type.DELETE_CYCLE_SAGAS,
  id,
});

export const activateCycleActionSagas = id => ({
  type: type.ACTIVATE_CYCLE_SAGAS,
  id,
});

export const moveSubstitutesActionSagas = id => ({
  type: type.MOVE_SUBSTITUTES_SAGAS,
  id,
});

export const moveSelectedRatesActionSagas = (id, rates) => ({
  type: type.MOVE_SELECTED_RATES_SAGAS,
  id,
  rates,
});

export const loadArchivedCycles = () => ({
  type: type.LOAD_ARCHIVED_CYCLES,
});

export const toggleArchiveCycle = cycle => ({
  type: type.TOOGLE_ARCHIVE_CYCLE,
  cycle,
});
