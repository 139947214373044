import * as t from './actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  studyPrograms: [],
  waiting: false,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case t.SET_SPS:
      return state.set('studyPrograms', action.studyPrograms);

    case t.SET_WAITING:
      return state.set('waiting', action.value);

    default:
      return state;
  }
}
