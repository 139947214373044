import './createNotificationsStyles.css';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import DatePicker from 'react-datepicker';

import {
  SimpleInput,
  SimpleTextarea,
} from '../../../../commonComponents/Input/Input';

class CreateNotifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectRank: ['E4', 'E5', 'E6', 'E7', 'E4-E6'],
      selectDutystate: ['Regular', 'Reserve', 'Substitute'],
      dutystate: this.props.isEdit ? this.props.notification.dutystate : '',
      expireAt: null,
      expireAtEdit: this.props.isEdit ? this.props.notification.expiryAt : null,
      name: this.props.isEdit ? this.props.notification.name : '',
      description: this.props.isEdit ? this.props.notification.description : '',
      lastDaysNewUser: this.props.isEdit
        ? this.props.notification.lastDaysNewUser
        : 0,
      lastActivity: this.props.isEdit
        ? this.props.notification.lastActivity
        : 0,
      sendNewUsers: this.props.isEdit
        ? this.props.notification.sendNewUsers
        : 'allUser',
      questionsAnswered: this.props.isEdit
        ? this.props.notification.questionsAnswered
        : 0,
      lastDays: this.props.isEdit ? this.props.notification.lastDays : 0,
      rank: this.props.isEdit ? this.props.notification.rank : '',
      moreOrLess: this.props.isEdit ? this.props.notification.moreOrLess : '',
      value: this.props.isEdit ? this.props.notification : {},
      required: false,
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  onSubmit = () => {
    this.state.value.expiryAt = this.state.expireAt
      ? moment(this.state.expireAt).valueOf()
      : parseInt(this.state.expireAtEdit, 10);
    this.state.value.rate = this.props.rate.id ? this.props.rate.id : null;
    this.state.value.sendNewUsers = this.state.sendNewUsers
      ? this.state.sendNewUsers
      : 0;
    this.state.value.rank = this.state.dutystate ? this.state.rank : '';
    this.state.value.dutystate = this.state.rank ? this.state.dutystate : '';
    this.state.value.moreOrLess = this.state.moreOrLess;
    this.state.value.name = this.state.name;
    this.state.value.description = this.state.description;
    this.state.value.lastDaysNewUser =
      this.state.sendNewUsers === 'newUser' ? this.state.lastDaysNewUser : 0;
    this.state.value.lastDays = this.state.moreOrLess ? this.state.lastDays : 0;
    this.state.value.questionsAnswered = this.state.moreOrLess
      ? this.state.questionsAnswered
      : 0;
    this.state.value.lastActivity = this.state.lastActivity
      ? this.state.lastActivity
      : 0;

    if (
      !this.state.value.name ||
      !this.state.value.description ||
      (this.state.value.lastActivity && this.state.value.lastActivity < 0) ||
      (this.state.value.lastDays && this.state.value.lastDays <= 0) ||
      (this.state.value.questionsAnswered &&
        this.state.value.questionsAnswered <= 0) ||
      (this.state.value.lastDaysNewUser &&
        this.state.value.lastDaysNewUser <= 0) ||
      (!this.state.value.lastDaysNewUser &&
        this.state.value.sendNewUsers === 'newUser') ||
      (this.state.rank && !this.state.dutystate) ||
      (this.state.moreOrLess &&
        (!this.state.lastDays || !this.state.questionsAnswered))
    ) {
      this.setState({ required: true });
    } else {
      if (this.props.isEdit) {
        this.props.editNotification(
          this.props.notification.id,
          this.state.value,
        );
        this.props.onCancel();
      } else {
        this.props.createNotification(this.state.value);
        this.props.onCancel();
      }
    }
  };

  render() {
    const { onCancel, isEdit } = this.props;
    const { selectRank, value } = this.state;
    const title = isEdit ? 'Edit Notifications' : 'Add Notifications';

    return (
      <Row className="addContainer">
        <Col md={12}>
          <Row className="headContainer">
            <div className="title">{title}</div>
            <i className="fa fa-times" onClick={() => this.props.onCancel()} />
          </Row>
        </Col>
        <hr className="dividingLine" />
        <br />
        <Col md={12} className="bodyContainer">
          <Row>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <span> Name </span>
                  <SimpleInput
                    onChange={(e) => {
                      if (this.state.description) {
                        this.setState({ required: false });
                      }
                      this.setState({ name: e.target.value });
                    }}
                    value={this.state.name}
                    errorValue={!!(this.state.required && !this.state.name)}
                  />
                </Col>
                <Col md={6}>
                  <span> Expire at: </span>
                  <DatePicker
                    selected={this.state.expireAt ? this.state.expireAt : null}
                    minDate={new Date()}
                    placeholderText={
                      isEdit && this.state.expireAtEdit
                        ? new Date(
                            Number(this.state.expireAtEdit),
                          ).toLocaleDateString('en-US')
                        : 'MM/DD/YYYY'
                    }
                    className="expiryAT"
                    onChange={(expireAt) => {
                      this.setState({ expireAt });
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <span>Notification message</span>
              <SimpleTextarea
                onChange={(e) => {
                  this.setState({ description: e.target.value });
                }}
                errorValue={!!(this.state.required && !this.state.description)}
                value={this.state.description}
              />
            </Col>
            <Col md={12} className="mt-2 mb-2">
              <div className="directionRow">
                <span className="mr-1">Select Users</span>
                <select
                  value={this.state.sendNewUsers}
                  className="selects"
                  onChange={(e) => {
                    this.setState({ sendNewUsers: e.target.value });
                  }}
                >
                  <option value="newUser"> New Users</option>
                  <option value="allUser"> All Users</option>
                </select>
              </div>
            </Col>
            {this.state.sendNewUsers === 'newUser' && (
              <Col md={12} className="mt-2 mb-2">
                <div className="directionRow">
                  <span className="mr-1">Days a user is taken as new </span>
                  <input
                    className={
                      this.state.required &&
                      (!this.state.lastDaysNewUser ||
                        this.state.lastDaysNewUser <= 0) &&
                      this.state.sendNewUsers === 'newUser'
                        ? 'redBorder mr-1'
                        : 'inputDays mr-1'
                    }
                    type="number"
                    value={
                      this.state.lastDaysNewUser
                        ? this.state.lastDaysNewUser
                        : ''
                    }
                    min={0}
                    onChange={(e) =>
                      this.setState({ lastDaysNewUser: e.target.value })
                    }
                  />
                </div>
              </Col>
            )}
            <Col md={12} className="mt-2 mb-2">
              <div className="directionRow">
                <span>Users that haven't logged in the last</span>
                <input
                  className={
                    this.state.required && this.state.lastActivity < 0
                      ? 'redBorder mr-1'
                      : 'inputDays mr-1'
                  }
                  type="number"
                  value={this.state.lastActivity ? this.state.lastActivity : ''}
                  min={0}
                  onChange={(e) =>
                    this.setState({
                      lastActivity: parseInt(e.target.value, 10),
                    })
                  }
                />
                <span>days.</span>
              </div>
            </Col>
            <Col md={12} className="mt-2 mb-2">
              <div className="directionRow">
                <span className="mr-1"> Specific rank groups </span>
                <select
                  value={this.state.rank}
                  onChange={(e) => {
                    this.setState({
                      rank: e.target.value,
                    });
                  }}
                  className="selects mr-2"
                >
                  <option value="">Select..</option>;
                  {selectRank.map((rank) => (
                    <option key={rank}>{rank}</option>
                  ))}
                </select>
                {this.state.rank && (
                  <select
                    value={this.state.dutystate}
                    onChange={(e) => {
                      this.setState({
                        dutystate: e.target.value,
                      });
                    }}
                    className={
                      this.state.required &&
                      !this.state.dutystate &&
                      this.state.rank
                        ? 'selects error'
                        : 'selects'
                    }
                  >
                    <option value="">Select..</option>;
                    {this.state.selectDutystate.map((dutystate) => (
                      <option key={dutystate}>{dutystate}</option>
                    ))}
                  </select>
                )}
              </div>
            </Col>
            <Col md={12} className="mt-2 mb-2">
              <div className="directionRow">
                <select
                  value={this.state.moreOrLess}
                  className="selects mr-2"
                  onChange={(e) => {
                    this.setState({
                      moreOrLess: e.target.value,
                    });
                  }}
                >
                  <option value="">Select..</option>;
                  <option value="more">More than</option>
                  <option value="less">Less than</option>
                </select>
                <input
                  className={
                    this.state.required &&
                    (!this.state.questionsAnswered ||
                      this.state.questionsAnswered <= 0) &&
                    this.state.moreOrLess
                      ? 'redBorder mr-1'
                      : 'inputDays mr-1'
                  }
                  type="number"
                  value={
                    this.state.moreOrLess && this.state.questionsAnswered
                      ? this.state.questionsAnswered
                      : ''
                  }
                  min={0}
                  onChange={(e) =>
                    this.setState({
                      questionsAnswered: parseInt(e.target.value, 10),
                    })
                  }
                  disabled={!this.state.moreOrLess}
                />
                <span> questions in the last </span>
                <input
                  className={
                    this.state.required &&
                    (!this.state.lastDays || this.state.lastDays <= 0) &&
                    this.state.moreOrLess
                      ? 'redBorder mr-1'
                      : 'inputDays mr-1'
                  }
                  type="number"
                  min={0}
                  value={
                    this.state.moreOrLess && this.state.lastDays
                      ? this.state.lastDays
                      : ''
                  }
                  onChange={(e) =>
                    this.setState({ lastDays: parseInt(e.target.value, 10) })
                  }
                  disabled={!this.state.moreOrLess}
                />
                <span> days. </span>
              </div>
            </Col>
          </Row>
        </Col>
        <br />
        <hr className="dividingLine" />
        <Col md={12} className="footerContainer">
          <Button
            id="btn-success"
            className="btn btn-success"
            onClick={() => {
              this.onSubmit();
            }}
          >
            Submit
          </Button>
          <Button className="btn btn-secondary" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    );
  }
}

export default CreateNotifications;
