// Insights/actions.js
import * as t from './actionTypes';

export const usersReceivedAction = (response) => ({
  type: t.USERSRECEIVED,
  payload: response,
});

export const searchUsersReceivedAction = (response) => ({
  type: t.SEARCHUSERSRECEIVED,
  payload: response,
});

export const errorFetchingFromService = (error) => ({
  type: t.ERROR_FETCHING_FROM_SERVICE,
  payload: error,
});

export const removeUser = (userId) => ({
  type: t.REMOVE_USER,
  userId,
});

export const setIsNewStudent = (isNewStudent) => ({
  type: t.SET_IS_NEW_STUDENT,
  isNewStudent,
});

export const updateUser = (user) => ({
  type: t.UPDATE_USER,
  payload: user,
});

export const updateIsWaiting = (isWaiting) => ({
  type: t.UPDATEISWAITING,
  isWaiting,
});

export const updateFormErrors = (errors) => ({
  type: t.UPDATEFORMERRORS,
  payload: errors,
});

export const clearErrorFromService = () => ({
  type: t.CLEAR_ERROR_FROM_SERVICE,
});

export const setLoading = (value) => ({
  type: t.SET_LOADING,
  value,
});

export const setSelectedUser = (user) => ({
  type: t.SET_SELECTED_USER,
  user,
});

export const setStatistics = (statistics) => ({
  type: t.SET_STATISTICS,
  statistics,
});

export const listNewCyclesAction = (listNewCycles) => ({
  type: t.SET_LIST_CYCLES,
  listNewCycles,
});

export const setDisableCycle = (payload) => ({
  type: t.SET_DISABLE_CYCLES,
  payload,
});

export const getUserNote = (userId) => ({
  type: t.GET_NOTE_DO_NOT_CONTACT,
  userId,
});

export const getCouponUserAction = (userId) => ({
  type: t.GET_COUPON_USER,
  userId,
});

export const getCancellationNoteAction = (userId) => ({
  type: t.GET_NOTE_CANCELLATION_REASON,
  userId,
});

export const loadApplicableCoupons = (userId) => ({
  type: t.LOAD_APPLICABLE_COUPONS,
  userId,
});

export const setApplicableCoupons = (value) => ({
  type: t.SET_APPLICABLE_COUPONS,
  value,
});

export const setUserNote = (payload) => ({
  type: t.SET_NOTE_DO_NOT_CONTACT,
  payload,
});

export const setCancellationReasonNote = (reason) => ({
  type: t.SET_CANCELLATION_REASON,
  reason,
});

export const upadteCancellationReasonNote = (userId, cancelledReason) => ({
  type: t.UPDATE_CANCELLATION_REASON,
  userId,
  cancelledReason,
});

export const getCancellationReason = (userId) => ({
  type: t.GET_INTERCOM_CANCELLATION_REASON,
  userId,
});

export const setUserIntercomInformation = (payload) => ({
  type: t.SET_INTERCOM_CANCELLATION,
  payload,
});

export const setUserEducationalInstitutionData = (educationalInstitution) => ({
  type: t.SET_EDUCATIONAL_INSTITUTION_DATA,
  educationalInstitution,
});

export const getUserEducationalInstitutionData = (userId) => ({
  type: t.GET_EDUCATIONAL_INSTITUTION_DATA,
  userId,
});

export const setDoNotContact = (payload) => ({
  type: t.GET_DO_NOT_CONTACT,
  payload,
});

export const setDisableCyleAction = (cycleId, value) => ({
  type: t.DISABLE_CYCLE_INFORMATION,
  cycleId,
  value,
});

export const setIntercomCancellation = (payload) => ({
  type: t.GET_INTERCOM_CANCELLATION,
  payload,
});

export const updateEducationalInstitutionData = (
  userId,
  educationalInstitution,
) => ({
  type: t.UPDATE_EDUCATIONAL_INSTITUTION_DATA,
  userId,
  educationalInstitution,
});

export const setResponse = (response) => ({
  type: t.SET_RESPONSE,
  response,
});

export const setSubscriptionType = (subscriptionType) => ({
  type: t.SET_SUBSCTIPTION_TYPE,
  subscriptionType,
});

export const setUserVerificationAction = (payload) => ({
  type: t.SET_USER_VERIFICATION,
  payload,
});

export const setMessage = (message) => ({
  type: t.SET_MESSAGE,
  message,
});

export const setLoadingInfo = (loadingInfo) => ({
  type: t.SET_LOADING_INFO,
  loadingInfo,
});

export const setPracticeExamsWaiting = (value) => ({
  type: t.SET_PRACTICE_EXAMS_WAITING,
  value,
});

export const setPracticeExams = (practiceExams) => ({
  type: t.SET_PRACTICE_EXAMS,
  practiceExams,
});

export const setRestartPracticeExamWaiting = (value) => ({
  type: t.SET_RESTART_PRACTICE_EXAM_WAITING,
  value,
});

export const setRestartPracticeExamSuccess = (value) => ({
  type: t.SET_RESTART_PRACTICE_EXAM_SUCCESS,
  value,
});

export const setNewStudentError = (value) => ({
  type: t.SET_NEW_STUDENT_ERROR,
  value,
});

export const setCouponUserAction = (coupon) => ({
  type: t.SET_COUPON_USER,
  coupon,
});

export const setUserSubmissionBlockStatus = (userId, status) => ({
  type: t.SET_USER_SUBMISSION_BLOCK_STATUS,
  payload: { userId, status },
});

export const setChangeUserSubmissionBlockStatus = (payload) => ({
  type: t.SET_CHANGE_USER_SUBMISSION_BLOCK_STATUS,
  payload: {
    userId: payload.userId,
    status: payload.status,
  },
});

export const setIsBetaUser = (isBetaUser) => ({
  type: t.SET_IS_BETA_USER,
  isBetaUser,
});

export const setIsLoadingBlockStatus = (status) => ({
  type: t.SET_IS_LOADING_BLOCK_STATUS,
  status,
});

export const setUserFavoriteBlockStatus = (userId, status) => ({
  type: t.SET_USER_FAVORITE_BLOCK_STATUS,
  payload: {
    userId,
    status,
  },
});

export const setChangeUserFavoriteBlockStatus = (payload) => ({
  type: t.SET_CHANGE_USER_FAVORITE_BLOCK_STATUS,
  payload: {
    userId: payload.userId,
    status: payload.status,
  },
});

export const setIsLoadingFavoriteBlockStatus = (status) => ({
  type: t.SET_IS_LOADING_FAVORITE_BLOCK_STATUS,
  status,
});

export const setCheckBetaUser = (userId, status) => ({
  type: t.SET_CHECK_BETA_USER,
  userId,
  status,
});

export const getBetaUser = (userId) => ({
  type: t.GET_BETA_USER,
  userId,
});

export const setShowChangeExamTypeModal = (value) => ({
  type: t.SET_SHOW_CHANGE_EXAM_TYPE_MODAL,
  value,
});
