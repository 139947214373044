import { connect } from 'react-redux';
import pure from 'recompose/pure';

import {
  closeModal,
  openModal,
  toggleModalVisibilityAction,
} from '../containers/Full/actions';
import {
  getAction,
  getCross,
  getModalClassName,
  getModalContent,
  getModalFooter,
  getModalTitle,
  getModalVisibility,
  getTitleClassName,
  getUser,
} from '../containers/Full/selectors';

export default function addAbilityToOpenModal(ComponentToWrap) {
  const mapStateToProps = (state) => ({
    modalVisibility: getModalVisibility(state),
    modalContent: getModalContent(state),
    modalClassName: getModalClassName(state),
    modalTitle: getModalTitle(state),
    user: getUser(state),
    action: getAction(state),
    cross: getCross(state),
    titleClassName: getTitleClassName(state),
    modalFooter: getModalFooter(state),
  });

  const mapDispatchToProps = (dispatch) => ({
    toggleModalVisibility: (modalContent) => {
      dispatch(toggleModalVisibilityAction(modalContent));
    },
    closeModal: () => {
      dispatch(closeModal());
    },
    openModal: (configuration) => {
      dispatch(openModal(configuration));
    },
  });

  const WrappedComponentContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ComponentToWrap);

  return pure(WrappedComponentContainer);
}
