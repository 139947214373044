import '../AdminProfile/AdminProfile.css';

import React from 'react';
import { Col } from 'react-bootstrap';
import Dropzone from 'react-dropzone';

export default class DropFileInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      value: '',
    };
  }

  componentWillMount() {
    this.setState({ value: this.props.defaultValue });
    this.props.input.onChange(this.props.defaultValue);
  }

  componentWillReceiveProps({ value }) {
    if (this.props.value === value) return;
    this.setState({ value });
  }

  onDrop = (files) => {
    const {
      input: { onChange },
    } = this.props;
    const myNewFile = new File(
      [files[0]],
      files[0].name.replace('.PDF', '.pdf'),
      { type: files[0].type },
    );
    onChange(myNewFile);
    this.setState({ value: myNewFile });
  };

  onRemoveFile = () => {
    const {
      input: { onChange },
    } = this.props;
    onChange('');
    this.setState({ value: '' });
  };

  render() {
    let dropzoneRef;
    let image =
      this.state.value && this.state.value !== '' ? this.state.value : null;
    if (this.props.nameForValue === 'files') {
      image = null;
    }
    return (
      <div
        className="cardRowContainer"
        style={{ width: '100%', marginBottom: '20px' }}
      >
        {this.state.value ? (
          <Files
            editable
            files={this.state.value}
            onRemoveFile={this.onRemoveFile}
            getUrlPdf={this.props.getUrlPdf}
            createQuestionModal={this.props.createQuestionModal}
          />
        ) : (
          ''
        )}
        {!image && (
          <div className="DropzoneContainer">
            <Dropzone
              ref={(node) => {
                dropzoneRef = node;
              }}
              onDrop={this.onDrop}
              multiple={this.props.multiple}
              accept={this.props.accept}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} className="Dropzone">
                    <input
                      data-test="add-book-file-input"
                      {...getInputProps()}
                    />
                    <p>Drop files here</p>
                  </div>
                </section>
              )}
            </Dropzone>
            <p
              onClick={() => {
                dropzoneRef.open();
              }}
            >
              Find files
            </p>
          </div>
        )}
      </div>
    );
  }
}

export class Files extends React.Component {
  getShortName(name) {
    if (!name) {
      return '';
    }
    const shortName = name.split('/');
    return shortName[shortName.length - 1];
  }

  getFiles = () => {
    const files = this.props.files;
    if (!files) {
      return '';
    }
    const preview = typeof files === 'string' ? files : files.preview;
    const name =
      typeof files === 'string' ? this.getShortName(files) : files.name;
    return (
      <Col className="fileName" data-test="book-file-name">
        <a
          onClick={() => {
            if (preview) {
              this.props.getUrlPdf({ url: preview });
            } else {
              if (files && files.type === 'application/pdf') {
                const fileUrl = URL.createObjectURL(files);
                window.open(fileUrl, '_blank');
              }
            }
          }}
        >
          {this.getShortName(name)}
        </a>
        {!this.props.createQuestionModal && (
          <i
            onClick={this.props.onRemoveFile}
            className="fa fa-trash fa-lg photoValueName"
          />
        )}
      </Col>
    );
  };

  render() {
    return <div style={{ marginBottom: '10px' }}>{this.getFiles()}</div>;
  }
}
