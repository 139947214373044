import * as t from './actionTypes';
import { Map } from 'immutable';

const initialState = Map({
  writers: [],
  waiting: false,
  chapterWriters: {},
  references: [],
  assignment: {},
});

export default function(state = initialState, action) {
  switch (action.type) {
    case t.SET_WRITERS:
      return state.set('writers', action.writers);

    case t.UPDATE_WAITING:
      return state.set('waiting', action.waiting);

    case t.UPDATE_CHAPTERS_WRITERS:
      let writers = {
        primary: action.writers && action.writers.primaryWriter
          ? action.writers.primaryWriter.id
          : 0,
        secondary: action.writers && action.writers.writers
          ? action.writers.writers
          : [],
      };
      return state.set('chapterWriters', writers);

    case t.CLEAR_CHAPTERS_WRITERS:
      return state.set('writers', []).set('chapterWriters', {});

    case t.SET_REFERENCES:
      return state.set('references', action.references);

    case t.SET_ASSIGNMENT:
      return state.set('assignment', action.assignment);

    default:
      return state;
  }
}
