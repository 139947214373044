import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './bannerListStyle.css';

class bannerList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchInput: '',
      selectedId: 0,
    };
  }
  componentWillMount() {}

  componentWillReceiveProps() {}

  renderSearch(search) {
    return (
      <div>
        <div className="searchInput">
          <input
            id="input"
            className="form-control"
            value={search}
            onChange={this.onSearch}
          />
          {search !== '' ? (
            <i
              onClick={() => this.setState({ searchInput: '' })}
              className="fa fa-close fa-lg fa-close"
            />
          ) : (
            <i className="fa fa-search fa-lg fa-in-search" />
          )}
        </div>
      </div>
    );
  }

  onSearch = e => {
    this.setState({ searchInput: e.target.value });
  };

  handleBannerSelect(bannerId) {
    this.props.setBannerDetail(bannerId);
    this.props.createBannerView(false);
    this.props.loadBannerDetails(true);
    this.setState({
      selectedId: bannerId,
    });
  }

  handleAddBanner() {
    this.props.createBannerView(true, {});
    this.props.loadBannerDetails(false);
  }

  renderListBanner() {
    let { banners } = this.props;
    let bannerFilter =
      this.state.searchInput !== ''
        ? banners.filter(b =>
            b.name.toLowerCase().includes(this.state.searchInput.toLowerCase()),
          )
        : banners;

    return bannerFilter.map(banner => {
      return (
        <div key={banner.id}>
          <div
            className={
              banner.id !== this.state.selectedId
                ? 'bannerName'
                : 'bannerName active'
            }
            onClick={() => this.handleBannerSelect(banner.id)}
          >
            {banner.name}
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="containerList">
        <div>
          <Row>
            <Col md={10}>{this.renderSearch(this.state.searchInput)}</Col>
            <Col md={2} className="plusContainer">
              <i
                className="fa fa-plus fa-lg"
                onClick={() => {
                  this.handleAddBanner();
                }}
              />
            </Col>
          </Row>
        </div>
        {this.props.banners.length > 0 ? (
          <div>{this.renderListBanner()}</div>
        ) : (
          <p className="noBanners">No banner has been created</p>
        )}
      </div>
    );
  }
}

export default bannerList;
