import './notificationsMessageDetailsStyle.css';

import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';

import DropDown from '../../../../commonComponents/DropDown/DropDown';
import ConfirmDeletion from '../../../../commonComponents/Modals/ConfirmDeletion/ConfirmDeletion';
import CreateNotifications from '../createNotifications/createNotifications';

class notificationsMessageDetails extends React.Component {
  state = {
    detailsNotification: false,
    rateId: null,
    load: false,
  };

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.loadNotifications) {
      this.props.getNotifications(this.props.rate.id);
    }
  }

  openCreateNotificationsModal = () => {
    this.props.openModal({
      modalContent: (
        <CreateNotifications
          onCancel={this.props.closeModal}
          rate={this.props.rate}
          createNotification={this.props.createNotification}
          isEdit={false}
        />
      ),
      modalClassName: 'modal-create-reference',
      className: '',
    });
  };

  notificationsDelete = (notification) => {
    this.props.openModal({
      modalTitle: `Deleting notification: ${notification.name}`,
      modalContent: (
        <ConfirmDeletion
          text={<span>Enter the word DELETE to confirm deletion.</span>}
          onSubmit={() => {
            this.props.closeModal();
            this.props.deleteNotification(notification.id);
          }}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  notificationsEdit = (notifications) => {
    this.props.openModal({
      modalContent: (
        <CreateNotifications
          onCancel={this.props.closeModal}
          rate={this.props.rate}
          createNotification={this.props.createNotification}
          isEdit
          notification={notifications}
          editNotification={this.props.editNotification}
        />
      ),
      modalClassName: 'modal-create-reference',
      className: '',
    });
  };

  handleDetailNotification = (rateId) => {
    this.setState({ rateId });
    this.setState({ detailsNotification: true });

    if (this.state.detailsNotification && this.state.rateId === rateId) {
      this.setState({ detailsNotification: !this.state.detailsNotification });
    }
  };

  render() {
    return (
      <div className="notificationsMessageContainer">
        {this.props.rate.id > -1 && (
          <Row>
            <Col md={12}>
              <Button
                className="btn-sm floatRight addButton"
                variant="success"
                onClick={this.openCreateNotificationsModal}
              >
                <i className="fa fa-plus icon" />
                Create Notifications
              </Button>
            </Col>
            <Col md={12}>
              {this.props.notifications &&
                this.props.notifications.map((notifications) => (
                  <div
                    key={notifications.id}
                    className="notifications-container"
                  >
                    <a href="#details" className="link">
                      <div
                        className="notifications-title row"
                        onClick={() => {
                          this.handleDetailNotification(notifications.id);
                        }}
                      >
                        <div className="col-lg-4 col-md-4">
                          {`Notification name: ${notifications.name}`}
                        </div>
                        <div className="col-lg-4 col-md-4">
                          {notifications.expiryAt
                            ? `Expiration date: ${moment(
                                Number(notifications.expiryAt),
                              ).format('L')}`
                            : ''}
                        </div>
                        <DropDown
                          className="col-lg-4 col-md-4"
                          options={[
                            {
                              name: 'Edit',
                              event: () =>
                                this.notificationsEdit(notifications),
                            },
                            {
                              name: 'Delete',
                              event: () =>
                                this.notificationsDelete(notifications),
                            },
                          ]}
                          title="Notifications"
                          style={{
                            position: 'relative',
                          }}
                        />
                      </div>
                    </a>
                    {this.state.detailsNotification &&
                      this.state.rateId === notifications.id && (
                        <div className="notifications-details" id="details">
                          <div className="directionRow background">
                            <div className="directionColumn  width100 margin-top pl-2">
                              <div>
                                <span className="subtitle">Description:</span>
                                <span className="inputAreaContainer inputRow ml-1 mr-1">
                                  {notifications.description}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="directionRow background height-5">
                            <div className="directionColumn pl-2">
                              <div className="margin-top itemCenter">
                                <span className="subtitle">
                                  Selected users:
                                </span>
                                <span className="inputAreaContainer inputRow ml-1 mr-1">
                                  {notifications.sendNewUsers === 'newUser'
                                    ? 'all new users'
                                    : 'all users'}
                                </span>
                              </div>
                              {notifications.sendNewUsers === 'newUser' && (
                                <div className="directionRow background itemCenter margin-bottom">
                                  <span className="subtitle">
                                    Number of days to consider a user as a "new
                                    user":
                                  </span>
                                  <span className="inputAreaContainer inputRow ml-1">
                                    {notifications.lastDaysNewUser}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>

                          {notifications.lastActivity ? (
                            <div className="directionRow background height-5 pl-2">
                              <div className="itemCenter margin-top">
                                <span
                                  className={
                                    notifications.lastActivity
                                      ? 'subtitle'
                                      : 'subtitle disabled'
                                  }
                                >
                                  Users that have not logged in the last
                                </span>
                              </div>
                              <span className="inputAreaContainer inputRow ml-1 mr-1 margin-top">
                                {notifications.lastActivity}
                              </span>
                              <span
                                className={
                                  notifications.lastActivity
                                    ? 'subtitle margin-top'
                                    : 'subtitle disabled margin-top'
                                }
                              >
                                days
                              </span>
                            </div>
                          ) : (
                            <div className="directionRow background height-5 pl-2">
                              <div className="itemCenter margin-top">
                                <span>
                                  No <b>Last activity rule</b> for this
                                  notification.
                                </span>
                              </div>
                            </div>
                          )}

                          {notifications.rank && notifications.dutystate ? (
                            <div className="directionRow background  height-5 pl-2">
                              <div className="directionRow">
                                <div className="itemCenter margin-bottom margin-top">
                                  <span
                                    className={
                                      notifications.rank ||
                                      notifications.dutystate
                                        ? 'subtitle'
                                        : 'subtitle disabled'
                                    }
                                  >
                                    Specific rank groups{' '}
                                  </span>
                                  <div>
                                    <span className="inputAreaContainer inputRow ml-1">
                                      {notifications.rank}
                                    </span>
                                    <span className="inputAreaContainer inputRow mr-2 ml-1">
                                      {notifications.dutystate}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="directionRow background height-5 pl-2">
                              <div className="directionRow">
                                <div className="itemCenter margin-bottom margin-top">
                                  <span>
                                    No <b>Specific rank groups</b> for this
                                    notification.
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}

                          {notifications.moreOrLess &&
                          notifications.questionsAnswered &&
                          notifications.lastDays ? (
                            <div className="directionRow background height-5 pl-2">
                              <div className="moreOrLessText">
                                <div className="directionRow itemCenter margin-bottom margin-top">
                                  <span
                                    className={
                                      notifications.moreOrLess
                                        ? 'subtitle'
                                        : 'subtitle disabled'
                                    }
                                  >
                                    Users who answered
                                  </span>
                                  <span className="inputAreaContainer inputRow mr-1 ml-1">
                                    {notifications.moreOrLess}
                                  </span>
                                </div>
                                <div className="directionRow background margin-top">
                                  <span
                                    className={
                                      notifications.moreOrLess
                                        ? 'subtitleThan'
                                        : 'subtitleThan disabled'
                                    }
                                  >
                                    than
                                  </span>
                                  <span className="inputAreaContainer inputRow ml-1 mr-1">
                                    {notifications.questionsAnswered}
                                  </span>
                                  <span
                                    className={
                                      notifications.moreOrLess
                                        ? 'subtitle'
                                        : 'subtitle disabled'
                                    }
                                  >
                                    questions in the last
                                  </span>
                                  <span className="inputAreaContainer inputRow ml-1 mr-1">
                                    {notifications.lastDays}
                                  </span>
                                  <span
                                    className={
                                      notifications.moreOrLess
                                        ? 'subtitle'
                                        : 'subtitle disabled'
                                    }
                                  >
                                    days.
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="directionRow background height-5 pl-2">
                              <div className="directionRow itemCenter margin-bottom margin-top">
                                <span>
                                  No <b>Specific activity levels rule</b> for
                                  this notification.
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                ))}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default notificationsMessageDetails;
