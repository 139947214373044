import React from 'react';
import Mask from '../Mask/Mask';
import './Input.css';

export class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      redBorder: {},
    };
  }

  componentWillMount() {
    this.setState({ value: this.props.defaultValue });
    this.props.input.onChange(this.props.defaultValue);
  }

  componentWillReceiveProps(next) {
    if (this.props.defaultValue !== next.defaultValue) {
      this.setState({ value: next.defaultValue ? next.defaultValue : '' });
    }
  }

  render() {
    const {
      input: { onChange },
      type,
      placeholder,
      icon = 'user',
      hidden = false,
      accept = '',
      disabled = false,
    } = this.props;
    return (
      <div className="input-group mb-3">
        {!hidden && icon && (
          <span className="input-group-addon">
            <i className={`icon-${icon}`} />
          </span>
        )}
        <input
          ref="inputForm"
          onChange={(e) => {
            onChange(e);
            this.setState({ value: e.target.value });
          }}
          type={type}
          className="form-control"
          placeholder={placeholder}
          hidden={hidden}
          value={this.state.value}
          accept={accept}
          disabled={disabled}
        />
      </div>
    );
  }
}

export class SimpleInput extends React.Component {
  defaultChange() {
    return;
  }
  render() {
    const {
      onChange,
      type = 'text',
      disabled = false,
      placeholder = '',
      accept = '',
      onBlur = () => {},
      errorValue,
      numberType,
      dataTest = null,
      error,
      max,
      min,
      notMb = false,
    } = this.props;
    let minNumber = null;
    let maxNumber = null;
    if (numberType) minNumber = 0;
    if (min) minNumber = min;
    if (max) maxNumber = max;
    return (
      <div className="form-group">
        <div className={`input-group ${notMb ? '' : 'mb-3'}`}>
          <input
            onChange={(e) => {
              onChange(e);
            }}
            type={numberType ? numberType : type}
            className={
              type === 'checkbox'
                ? 'form-checkbox-input'
                : `${errorValue ? 'form-control redBorder' : 'form-control'}`
            }
            placeholder={placeholder}
            value={this.props.value}
            disabled={disabled}
            checked={this.props.value}
            accept={accept}
            onBlur={(e) => onBlur(e)}
            min={minNumber >= 0 ? minNumber : null}
            max={maxNumber >= 0 ? maxNumber : null}
            data-test={dataTest}
          />
          {this.props.description && (
            <span style={{ marginLeft: '10px', lineHeight: '30px' }}>
              {this.props.description}
            </span>
          )}
          {error && <span className="errors">{error}</span>}
        </div>
      </div>
    );
  }
}

export class SimpleTextarea extends React.Component {
  render() {
    const {
      onChange,
      type = 'text',
      disabled = false,
      placeholder = '',
      errorValue,
      dataTest = null,
      style = null,
    } = this.props;
    return (
      <div className="form-group">
        <div className="input-group mb-3">
          <textarea
            onChange={(e) => {
              onChange(e);
            }}
            type={type}
            className={errorValue ? 'form-control redBorder' : 'form-control'}
            placeholder={placeholder}
            value={this.props.value}
            disabled={disabled}
            data-test={dataTest}
            style={style}
          />
          {this.props.error && (
            <span className="errors">{this.props.error}</span>
          )}
        </div>
      </div>
    );
  }
}

export class Textarea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };
  }

  componentWillMount() {
    this.setState({ value: this.props.defaultValue });
    this.props.input.onChange(this.props.defaultValue);
  }

  componentWillReceiveProps(next) {
    if (this.props.defaultValue !== next.defaultValue) {
      this.setState({ value: next.defaultValue });
    }
  }

  render() {
    const {
      input: { onChange },
      placeholder,
      disabled = false,
    } = this.props;
    return (
      <div className="input-group mb-3">
        <textarea
          id="textarea-input"
          onChange={(e) => {
            onChange(e);
            this.setState({ value: e.target.value });
          }}
          className="form-control"
          placeholder={placeholder}
          value={this.state.value}
          disabled={disabled}
        />
      </div>
    );
  }
}

export class RadioButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: false,
    };
  }

  componentWillMount() {
    this.setState({ value: this.props.checked });
  }

  componentWillReceiveProps(next) {
    if (this.props.checked !== next.checked) {
      this.setState({ value: next.checked });
    }
  }

  render() {
    const {
      input: { onChange },
      type,
      label,
      onCheck,
      radioValue,
      disabled = false,
    } = this.props;
    return (
      <div className="input-group mb-3">
        <input
          onChange={function (e) {
            onChange(e);
            onCheck();
          }}
          type={type}
          className="radio-btn"
          checked={this.state.value}
          value={radioValue}
          disabled={disabled}
        />
        <span className="radio-button-label">{label}</span>
      </div>
    );
  }
}

export class InputWithoutIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      showPassword: false,
    };
  }

  componentWillMount() {
    this.setState({ value: this.props.defaultValue });
    this.props.input.onChange(this.props.defaultValue);
  }

  componentWillReceiveProps(next) {
    if (this.props.defaultValue !== next.defaultValue) {
      this.setState({ value: next.defaultValue });
      this.props.input.onChange(next.defaultValue);
    }

    if (next.empty) {
      this.props.resetEmptyInput();
      this.setState({ value: '' });
      this.props.input.onChange('');
    }
  }

  render() {
    const {
      input: { onChange },
      type,
      placeholder,
      hidden = false,
      disabled = false,
      className = null,
      onFocusEvent = null,
      empty = false,
      focusInput,
    } = this.props;
    let eyeClassName = this.state.showPassword
      ? 'fa fa-eye-slash'
      : 'fa fa-eye';
    let inputType =
      type === 'password' && this.state.showPassword ? 'text' : type;
    return (
      <div className="input-group mb-3">
        {type === 'checkbox' && (
          <span className="label-checkbox">{placeholder}</span>
        )}
        <input
          onChange={(e) => {
            onChange(e);
            this.setState({ value: e.target.value });
          }}
          type={inputType}
          className={
            type === 'checkbox'
              ? 'form-checkbox'
              : className
              ? className
              : 'form-control'
          }
          placeholder={placeholder}
          hidden={hidden}
          value={this.state.value}
          disabled={disabled}
          required={this.props.isRequired}
          onFocus={() => {
            if (onFocusEvent && empty) onFocusEvent();
          }}
        />
        {type === 'password' && this.props.canShowPassword && (
          <i
            className={`${eyeClassName} viewPassword`}
            onClick={() =>
              this.setState({ showPassword: !this.state.showPassword })
            }
          />
        )}
      </div>
    );
  }
}

export class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: false,
    };
  }

  componentWillMount() {
    this.setState({ value: this.props.defaultValue });
    this.props.input.onChange(this.props.defaultValue);
  }

  render() {
    const {
      input: { onChange },
      type,
      placeholder,
      hidden = false,
      disabled = false,
      rightLabel = false,
    } = this.props;
    let clasName = 'form-checkbox';
    if (rightLabel) {
      clasName += ' marginTop10';
    }
    return (
      <div className="input-group mb-3">
        {!rightLabel && <span className="label-checkbox">{placeholder}</span>}
        <input
          onChange={(e) => {
            onChange(!this.state.value);
            this.setState({ value: !this.state.value });
          }}
          type={type}
          className={clasName}
          placeholder={placeholder}
          hidden={hidden}
          checked={this.state.value}
          disabled={disabled}
        />
        {rightLabel && (
          <span style={{ marginLeft: '10px', lineHeight: '30px' }}>
            {placeholder}
          </span>
        )}
      </div>
    );
  }
}

export class InlineInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      showPassword: false,
    };
  }

  componentWillMount() {
    this.setState({ value: this.props.defaultValue });
    this.props.onChange(this.props.defaultValue);
  }

  componentWillReceiveProps(next) {
    if (this.props.defaultValue !== next.defaultValue) {
      this.setState({ value: next.defaultValue ? next.defaultValue : '' });
    }
  }

  componentDidMount() {
    this.refs.inputForm.select();
  }

  handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      this.props.onSubmit();
    } else if (event.key === 'Escape') {
      this.props.close();
    }
  };

  render() {
    const {
      onChange,
      type,
      placeholder,
      icon = 'user',
      hidden = false,
      accept = '',
      disabled = false,
      close = () => {},
      onSubmit = () => {},
      error = false,
    } = this.props;
    let inputType =
      type === 'password' && this.state.showPassword ? 'text' : type;
    let eyeClassName = this.state.showPassword
      ? 'fa fa-eye-slash'
      : 'fa fa-eye';
    return (
      <div>
        <div className="input-group mb-3 inlineInput">
          <input
            ref="inputForm"
            onChange={(e) => {
              onChange(e.target.value);
              this.setState({ value: e.target.value });
            }}
            onKeyUp={this.handleKeyUp}
            type={inputType}
            className={error ? 'form-control redBorder' : 'form-control'}
            placeholder={placeholder}
            hidden={hidden}
            value={this.state.value}
            accept={accept}
            disabled={disabled}
            autoComplete="none"
          />
          {!hidden && icon && (
            <div className="check">
              <i onClick={() => onSubmit()} className="fa fa-check fa-lg" />
            </div>
          )}
          {!hidden && icon && (
            <div className="cross">
              <i onClick={() => close()} className="fa fa-close fa-lg" />
            </div>
          )}
          {!hidden &&
            icon &&
            type === 'password' &&
            this.props.canShowPassword && (
              <i
                className={`${eyeClassName} viewPassword`}
                onClick={() =>
                  this.setState({ showPassword: !this.state.showPassword })
                }
              />
            )}
        </div>
        <Mask />
      </div>
    );
  }
}

export class InlineTextArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      showPassword: false,
    };
  }

  componentWillMount() {
    this.setState({ value: this.props.defaultValue });
    this.props.onChange(this.props.defaultValue);
  }

  componentWillReceiveProps(next) {
    if (this.props.defaultValue !== next.defaultValue) {
      this.setState({ value: next.defaultValue ? next.defaultValue : '' });
    }
  }

  componentDidMount() {
    this.refs.inputForm.select();
  }

  handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      this.props.onSubmit();
    } else if (event.key === 'Escape') {
      this.props.close();
    }
  };

  render() {
    const {
      onChange,
      type,
      placeholder,
      icon = 'user',
      hidden = false,
      accept = '',
      disabled = false,
      close = () => {},
      onSubmit = () => {},
      error = false,
    } = this.props;
    return (
      <div>
        <div className="input-group mb-3 inlineInput">
          <textarea
            ref="inputForm"
            onChange={(e) => {
              onChange(e.target.value);
              this.setState({ value: e.target.value });
            }}
            type="text"
            className={error ? 'form-control redBorder' : 'form-control'}
            placeholder={placeholder}
            hidden={hidden}
            value={this.state.value.replace(/;/g, '\n')}
            accept={accept}
            disabled={disabled}
            autoComplete="none"
          />
          {!hidden && icon && (
            <div className="check">
              <i onClick={() => onSubmit()} className="fa fa-check fa-lg" />
            </div>
          )}
          {!hidden && icon && (
            <div className="cross">
              <i onClick={() => close()} className="fa fa-close fa-lg" />
            </div>
          )}
        </div>
        <Mask />
      </div>
    );
  }
}

export default InputWithoutIcon;
