import './Books.css';
import 'react-select2-wrapper/css/select2.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import Select2 from 'react-select2-wrapper';

import Spinner from '../../../commonComponents/Spinner/Spinner';

class DuplicateChapterModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      waiting: false,
      reference: '',
      value: '',
      book: {},
      referenceName: '',
      search: false,
      loading: false,
      rateName: '',
      status: true,
    };
  }

  componentDidMount() {
    if (this.props) {
      this.props.bookSelect(0);
      this.props.rateSelect(0);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.value === '' && prevState !== '' && this.state.status) {
      this.setState({ book: {}, status: false });
    }
  }

  search() {
    if (this.state.value) {
      this.setState({
        search: true,
      });
      this.props.loadReferences(
        null,
        null,
        this.state.value,
        0,
        false,
        0,
        null,
        null,
        true,
      );
    }
  }

  handleValue(value) {
    this.setState({ value });
    if (!value) {
      this.setState({ status: true });
    }
  }

  renderSearch() {
    const value = this.state.value;
    return (
      <span className="modalDuplicate">
        <form
          onSubmit={(ev) => {
            ev.preventDefault();
          }}
          className="formSearch"
          onChange={(e) => this.handleValue(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && value) {
              this.search();
            }
          }}
        >
          <div className="search">
            <input
              type="text"
              className="search-input"
              placeholder="Search books"
              value={value}
              disabled={this.props.isWaiting}
            />
            <i
              className={value === '' ? 'fa fa-search' : 'fa fa-search fix'}
              aria-hidden="true"
              onClick={() => this.search()}
            />
            <i
              className={value === '' ? 'fa fa-remove hidden' : 'fa fa-remove'}
              aria-hidden="true"
              onClick={() => {
                {
                  this.setState({ value: '' });
                }
              }}
            />
          </div>
          <input type="submit" style={{ display: 'none' }} />
        </form>
      </span>
    );
  }

  duplicate = () => {
    this.props.duplicateChapter(
      this.props.referenceIdSelect,
      this.props.id,
      this.props.rateIdSelect,
    );
    this.setState({
      error: null,
      waiting: false,
      reference: '',
      value: '',
      book: {},
      referenceName: '',
      search: false,
      loading: false,
      rateName: '',
    });
  };

  selectBook = (event) => {
    let selectedReference = event.params.data.id;
    if (selectedReference) {
      selectedReference = parseInt(selectedReference, 10);
      this.props.bookSelect(selectedReference);
    }
  };

  selectRate = (event) => {
    let selectedRate = event.params.data.id;
    if (selectedRate) {
      selectedRate = parseInt(selectedRate, 10);
      this.props.rateSelect(selectedRate);
    }
  };

  handleBook = (e) => {
    if (e) {
      this.setState({
        referenceName: e,
      });
    }
  };

  handleRate = (e) => {
    if (e) {
      this.setState({
        rateName: e,
      });
    }
  };

  render() {
    const { loadingReferences, closeModal, referenceIdSelect, rateIdSelect } =
      this.props;
    const { value, search } = this.state;
    const rates = this.props.rateList;
    const disabledButton = !!(
      value &&
      search &&
      referenceIdSelect &&
      rateIdSelect
    );

    return (
      <div className="simple-confirmation-component">
        <div className="duplicate-block">
          <div className="createChapterTitle">
            Duplicate Chapter
            <i className="fa fa-times fa-xs" onClick={closeModal} />
            <hr />
          </div>
          <div>
            <Row>
              <Col md={4} className="InputLebel">
                <span>Search books:</span>
              </Col>
              <Col md={8}>{this.renderSearch()}</Col>
            </Row>
            {(loadingReferences || this.props.books === 0) && (
              <Spinner text="Loading book" />
            )}
            {!loadingReferences &&
              this.state.value &&
              this.state.search &&
              this.props.books &&
              !this.props.isWaiting && (
                <Row>
                  <Col md={4}>
                    <span className="InputLebel">
                      Duplicate chapter on book:
                    </span>
                  </Col>
                  <Col md={8}>
                    <div className="form-group">
                      <div className="input-group selectorContainer">
                        <Select2
                          value={this.state.referenceName}
                          className="form-control"
                          data={this.props.books.map((reference) => ({
                            id: reference.id,
                            text: `${reference.name} - ${reference.description}`,
                          }))}
                          options={{
                            minimumResultsForSearch: Infinity,
                            isMultiple: false,
                          }}
                          onSelect={this.selectBook}
                          onChange={(e) => this.handleBook(e.target.value)}
                          getOptionValue={(option) => option}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            {!loadingReferences &&
              this.state.value &&
              this.state.search &&
              this.props.books &&
              !this.props.isWaiting && (
                <Row className="mt-2">
                  <Col md={4}>
                    <span className="InputLebel">
                      Duplicate chapter for rate:
                    </span>
                  </Col>
                  <Col md={8}>
                    <div className="form-group">
                      <div className="input-group selectorContainer">
                        <Select2
                          value={this.state.rateName}
                          className="form-control"
                          data={rates.map((rate) => ({
                            id: rate.id,
                            text: rate.shortname,
                          }))}
                          options={{
                            minimumResultsForSearch: Infinity,
                            isMultiple: false,
                          }}
                          onSelect={this.selectRate}
                          onChange={(e) => this.handleRate(e.target.value)}
                          getOptionValue={(option) => option}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
          </div>
          <br />
        </div>
        {this.props.isWaiting ? (
          <Spinner text="Loading" />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              flexDirection: 'row',
            }}
          >
            <Button className="btn btn-danger closeBtn" onClick={closeModal}>
              {' '}
              Close
            </Button>
            <Button
              className="btn btn-primary closeBtn"
              onClick={() => this.duplicate()}
              disabled={!disabledButton}
            >
              {' '}
              Duplicate
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default DuplicateChapterModal;
