import React from 'react';
import { Link } from 'react-router';

class ForgotPasswordMessage extends React.Component {
  componentWillUnmount = () => {
    this.props.setMessage('');
  };
  render() {
    return (
      <div className="response-container">
        <div className="circle mb-4">
          <i className="icon-email" />
        </div>
        <div className="confirmation-message">
          <h5 className="m-0">Email Sent </h5>
          <p>
            Please check your email and click the password reset link within 24
            hours.
          </p>
        </div>
        <Link to="/login">
          <button className="w-50 pl-2 btn forgot-password-button btn-danger ">
            {this.props.isLoading ? <span>Sign in</span> : 'BACK TO LOGIN'}
          </button>
        </Link>
      </div>
    );
  }
}
export default ForgotPasswordMessage;
