import { all, fork } from 'redux-saga/effects';

import RootSaga from '../containers/Full/sagas';
import admins from './Admins';
import assignments from './Assignments';
import associateRatesToReference from './AssociateRatesToReference';
import banners from './Banners';
import bibliographies from './Bibliographies';
import books from './Books';
import changeExam from './ChangeExam';
import changeHistory from './ChangeHistoryContainer';
import coupons from './Coupons';
import CustomerSubmission from './CustomerSubmission';
import cycles from './Cycles';
import dailyQuiz from './DailyQuiz';
import definitions from './Definitions';
import groups from './Groups';
import LoginSaga from './Login/sagas';
import messageCenter from './MessageCenter';
import newAdmin from './NewAdmin';
import practiceExams from './PracticeExams';
import prompts from './Prompts';
import quals from './Quals';
import questions from './Questions';
import header from './Questions/commonComponents/Header/components';
import rates from './Rates';
import references from './References';
import reports from './Reports/sagas';
import resetPassword from './ResetPassword';
import roles from './Roles';
import search from './Search';
import settings from './Settings';
import subtopics from './Subtopics';
import tenWeek from './TenWeek/sagas';
import users from './Users';
import writers from './Writers';

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    fork(RootSaga),
    fork(LoginSaga),
    fork(users.sagas),
    fork(admins.sagas),
    fork(newAdmin.sagas),
    fork(resetPassword.sagas),
    fork(rates.sagas),
    fork(groups.sagas),
    fork(roles.sagas),
    fork(bibliographies.sagas),
    fork(cycles.sagas),
    fork(subtopics.sagas),
    fork(definitions.sagas),
    fork(references.sagas),
    fork(writers.sagas),
    fork(quals.sagas),
    fork(coupons.sagas),
    fork(questions.sagas),
    fork(assignments.sagas),
    fork(changeHistory.sagas),
    fork(settings.sagas),
    fork(changeExam.sagas),
    fork(associateRatesToReference.sagas),
    fork(books.sagas),
    fork(reports),
    fork(tenWeek),
    fork(search.sagas),
    fork(practiceExams.sagas),
    fork(messageCenter.sagas),
    fork(banners.sagas),
    fork(prompts.sagas),
    fork(header.sagas),
    fork(CustomerSubmission.sagas),
    fork(dailyQuiz.sagas),
  ]);
}
