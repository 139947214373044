function CategorySelector({ selectedCategory, handleChangeSelectedCategory }) {
  return (
    <div className="category__container">
      <div className="category__button__container">
        <button
          className={`category__button category__button__user-submitted ${
            selectedCategory === 'user-submitted'
              ? 'category__button__active'
              : ''
          }`}
          onClick={() => handleChangeSelectedCategory('user-submitted')}
        >
          User submitted
        </button>
      </div>
      <div className="category__button__container">
        <button
          className={`category__button category__button__favorites ${
            selectedCategory === 'favorites' ? 'category__button__active' : ''
          }`}
          onClick={() => handleChangeSelectedCategory('favorites')}
        >
          Favorites
        </button>
      </div>
    </div>
  );
}

export default CategorySelector;
