import { connect } from 'react-redux';
import {
  getAllRoles,
  getPages,
  getWorkFLowActions,
  isCreating,
  isUpdating,
  isDeleting,
  getSelectedRole,
  getRoleName,
  getChecks,
  getNewRoleMode,
  getIsLoading,
} from '../selectors';
import {
  getAllRolesActionSagas,
  getPagesActionSagas,
  getActionsActionSagas,
  createRoleActionSagas,
  editRoleActionSagas,
  deleteRoleActionSagas,
} from '../actionsSagas';
import {
  setSelectedRole,
  updateChecks,
  updateRoleName,
  toggleNewRole,
} from '../actions';
import { getUser, getGeneralRolesPermissions } from '../../Login/selectors';
import Roles from './Roles';

const mapStateToProps = state => {
  return {
    roles: getAllRoles(state),
    pages: getPages(state),
    actions: getWorkFLowActions(state),
    creating: isCreating(state),
    updating: isUpdating(state),
    deleting: isDeleting(state),
    selectedRole: getSelectedRole(state),
    roleName: getRoleName(state),
    checks: getChecks(state),
    newRoleMode: getNewRoleMode(state),
    loggedUser: getUser(state),
    generalPermissions: getGeneralRolesPermissions(state),
    isLoading: getIsLoading(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadRoles: () => dispatch(getAllRolesActionSagas()),
    loadPages: () => dispatch(getPagesActionSagas()),
    loadActions: () => dispatch(getActionsActionSagas()),
    createRole: role => dispatch(createRoleActionSagas(role)),
    updateRole: role => dispatch(editRoleActionSagas(role)),
    deleteRole: role => dispatch(deleteRoleActionSagas(role)),
    setSelectedRole: role => dispatch(setSelectedRole(role)),
    updateRoleName: name => dispatch(updateRoleName(name)),
    updateChecks: checks => dispatch(updateChecks(checks)),
    toggleNewRole: role => dispatch(toggleNewRole(role)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
