/* eslint-disable */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import ResultsTab from './ResultsTab';
import _ from 'underscore';
import { browserHistory } from 'react-router';
import UniversalCookies from 'universal-cookie';
import './Search.css';
import SearchInstructionsModalContainer from './searchInstructionsModalContainer';
const possibleTags = [
  'questionid',
  'chapterid',
  'referenceid',
  'enabled',
  'softwarepage',
  'status',
  'flashmode',
  'archived',
  'actasparent',
  'isghostchapter',
];
const possibleSpacedTags = ['documentpage'];

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.props.options,
      requestSent: false,
      internalQueryError: false,
    };
  }

  componentWillMount() {
    this.props.setIsSearchingBooks(false);
    this.props.setIsSearchingChapters(false);
    this.props.setIsSearchingQuestions(false);
    this.props.setSearchSuccess(false);
    this.props.setSearchError(false);
    this.props.setErrors([]);
    if (this.props.query !== '' && this.props.searchFromHeader) {
      this.validate();
    }
  }

  /* function for validate fields before dispatch */
  // IS only called from search bar. for individual searches, we must use pagination
  validate = () => {
    //prevent to send an empty query to API
    if (this.props.query === '') {
      this.setState({ internalQueryError: true });
    } else {
      let query = this.props.query.toLowerCase();

      //Spaced tag matching
      const RegularExpressionForSpacedTags = /[a-z]{2,20}:"[a-z0-9\s\/\+\?\[\]\.\;\$\#\¿\(\)\!\¡\@\,\_\-]{1,60}"/g;
      const spacedTagsArray = query.match(RegularExpressionForSpacedTags);
      query = query.replace(RegularExpressionForSpacedTags, '');

      //Simple tag matching
      const regularExpressionForTags = /[a-z]{2,20}:[a-z0-9]{1,60}/g;
      const tagsArray = query.match(regularExpressionForTags);
      query = query.replace(regularExpressionForTags, '');
      query = query.trim();
      const separatedTags = this.getTags(tagsArray, spacedTagsArray);

      this.props.setOptions(this.state.options);

      this.setState({ internalQueryError: false, requestSent: true });
      if (this.state.options === 'All' || this.state.options === 'Books') {
        this.props.setBooksResponse({ books: [], count: 0 });
        this.props.submitBooksSearch(query, separatedTags, 0, false);
      }
      if (this.state.options === 'All' || this.state.options === 'Questions') {
        this.props.setQuestionsResponse({ questions: [], count: 0 });
        this.props.submitQuestionsSearch(query, separatedTags, 0, false);
      }
      if (this.state.options === 'All' || this.state.options === 'Chapters') {
        this.props.setChaptersResponse({ chapters: [], count: 0 });
        this.props.submitChaptersSearch(query, separatedTags, 0, false);
      }
    }
  };

  loadMore = option => {
    let query = this.props.query.toLowerCase();

    //Spaced tag matching
    const RegularExpressionForSpacedTags = /[a-z]{2,20}:"[a-z0-9\s\/\+\?\[\]\.\;\$\#\¿\(\)\!\¡\@\,\_\-]{1,60}"/g;
    const spacedTagsArray = query.match(RegularExpressionForSpacedTags);
    query = query.replace(RegularExpressionForSpacedTags, '');

    //Simple tag matching
    const regularExpressionForTags = /[a-z]{2,20}:[a-z0-9]{1,60}/g;
    const tagsArray = query.match(regularExpressionForTags);
    query = query.replace(regularExpressionForTags, '');
    query = query.trim();
    const separatedTags = this.getTags(tagsArray, spacedTagsArray);

    if (option === 'All' || option === 'Books') {
      this.props.submitBooksSearch(
        query,
        separatedTags,
        this.props.booksResponse.books.length,
        true,
      );
    }
    if (option === 'All' || option === 'Questions') {
      this.props.submitQuestionsSearch(
        query,
        separatedTags,
        this.props.questionsResponse.questions.length,
        true,
      );
    }
    if (option === 'All' || option === 'Chapters') {
      this.props.submitChaptersSearch(
        query,
        separatedTags,
        this.props.chaptersResponse.chapters.length,
        true,
      );
    }
  };

  clearAll = () => {
    this.props.setIsSearchingBooks(false);
    this.props.setIsSearchingChapters(false);
    this.props.setIsSearchingQuestions(false);
    this.props.setSearchSuccess(false);
    this.props.setSearchError(false);
    this.props.setErrors([]);
    this.props.setBooksResponse({ books: [], count: 0 });
    this.props.setQuestionsResponse({ questions: [], count: 0 });
    this.props.setChaptersResponse({ chapters: [], count: 0 });
    this.props.setOptions('All');
    this.props.setQuery('');
    this.setState({ requestSent: false });
  };

  getTags = (tagsArray, spacedTagsArray) => {
    //If the user inserts at least one correct tag, It will be on the response
    //If there isn't any correct tag, the response it will be an empty array
    //All incorrect tags will be eliminated

    let separatedTags = [];

    if (tagsArray && tagsArray.length > 0) {
      tagsArray.forEach(tag => {
        const splitted = tag.split(':');
        separatedTags.push({
          tagId: splitted[0],
          tagContent: splitted[1],
        });
      });

      //Deleting invalid tags from separatedTags array
      let invalidTags = [];
      separatedTags.forEach(tag => {
        if (!possibleTags.includes(tag.tagId)) {
          invalidTags.push(tag);
        }
      });

      separatedTags = _.difference(separatedTags, invalidTags);
      invalidTags = [];

      //Validating data types on each name-valid tag
      separatedTags.forEach(tag => {
        if (
          tag.tagId === 'questionid' ||
          tag.tagId === 'chapterid' ||
          tag.tagId === 'referenceid' ||
          tag.tagId === 'softwarepage'
        ) {
          const value = Number(tag.tagContent);
          if (typeof value !== 'number' || value === NaN) {
            invalidTags.push(tag);
          }
        }

        if (
          tag.tagId === 'archived' ||
          tag.tagId === 'flashmode' ||
          tag.tagId === 'enabled' ||
          tag.tagId === 'isghostchapter' ||
          tag.tagId === 'actasparent'
        ) {
          if (!(tag.tagContent === 'true' || tag.tagContent === 'false')) {
            invalidTags.push(tag);
          }
        }

        if (tag.tagId === 'status') {
          if (
            !(
              tag.tagContent === 'published' ||
              tag.tagContent === 'new' ||
              tag.tagContent === 'edit' ||
              tag.tagContent === 'delete'
            )
          ) {
            invalidTags.push(tag);
          }
        }
      });

      separatedTags = _.difference(separatedTags, invalidTags);
    }

    if (spacedTagsArray && spacedTagsArray.length > 0) {
      spacedTagsArray.forEach(tag => {
        const splitted = tag.split(':');

        if (possibleSpacedTags.includes(splitted[0])) {
          const content = splitted[1].slice(1, splitted[1].length - 1);
          separatedTags.push({
            tagId: splitted[0],
            tagContent: content,
          });
        }
      });
    }

    return separatedTags;
  };

  keyPressed = event => {
    if (event.key === 'Enter') {
      this.validate();
    }
  };

  openInstructionsModal = () => {
    this.props.openModal({
      modalContent: (
        <SearchInstructionsModalContainer onCancel={this.props.closeModal} />
      ),
      modalClassName: 'instructionsModal',
    });
  };

  render() {
    return (
      <div className="search-page">
        <div className="search-page-container">
          <Row className="searchBarRow">
            {/* for searchBar */}
            <Col sm={12} md={12} lg={8} className="searchBarContainer">
              <div className="form-inline">
                <i
                  className="fa fa-question-circle fa-lg"
                  onClick={this.openInstructionsModal}
                />
                <input
                  type="text"
                  value={this.props.query}
                  className={
                    this.state.internalQueryError
                      ? 'form-control searchBar red-border'
                      : 'form-control searchBar'
                  }
                  placeholder="Search"
                  onKeyPress={this.keyPressed}
                  onChange={e => this.props.setQuery(e.target.value)}
                />
                <select
                  className="form-control selector"
                  value={this.state.options}
                  onChange={e => this.setState({ options: e.target.value })}
                >
                  <option value="All">All</option>
                  <option value="Books">Books</option>
                  <option value="Chapters">Chapters</option>
                  <option value="Questions">Questions</option>
                </select>
                <button className="btn btn-primary" onClick={this.validate}>
                  <i className="fa fa-search fa-lg" />
                </button>
              </div>
            </Col>
          </Row>
          {!(
            this.props.booksResponse.books.length > 0 ||
            this.props.chaptersResponse.chapters.length > 0 ||
            this.props.questionsResponse.questions.length > 0
          ) && (
            <Row className="indicatorsRow">
              {/* for search indicators */}
              {!this.props.isSearching &&
                !this.props.searchSuccess &&
                !this.props.searchError &&
                !this.state.requestSent && (
                  <Col className="searchIndicator">
                    <i className="fa fa-search fa-lg" />
                    <span className="searchIndicatorText">
                      Type your search term above
                    </span>
                  </Col>
                )}
              {!this.props.isSearchingBooks &&
                !this.props.isSearchingChapters &&
                !this.props.isSearchingQuestions &&
                this.props.booksResponse.books.length == 0 &&
                this.props.chaptersResponse.chapters.length == 0 &&
                this.props.questionsResponse.questions.length == 0 &&
                this.state.requestSent && (
                  <Col className="notFoundMessage">
                    <i className="fa fa-close fa-lg" />
                    <span className="searchIndicatorText">
                      Oops! No Results Found
                    </span>
                    <span className="searchIndicatorText text-muted">
                      Please check your request
                    </span>
                  </Col>
                )}
            </Row>
          )}
          {(this.props.booksResponse.books.length > 0 ||
            this.props.chaptersResponse.chapters.length > 0 ||
            this.props.questionsResponse.questions.length > 0 ||
            this.props.isSearchingBooks ||
            this.props.isSearchingChapters ||
            this.props.isSearchingQuestions) && (
            <Row className="resultsRow">
              {!this.props.isSearchingBooks &&
                !this.props.isSearchingChapters &&
                !this.props.isSearchingQuestions && (
                  <div className="clear-all-container">
                    <div className="badge badge-pill" onClick={this.clearAll}>
                      CLEAR ALL
                      <span aria-hidden="true" className="closeButton">
                        &times;
                      </span>
                    </div>
                  </div>
                )}
              {(this.props.options === 'All' ||
                this.props.options === 'Books') &&
                this.props.booksResponse.books && (
                  <ResultsTab
                    title="Books"
                    resultSize={
                      this.props.booksResponse.books
                        ? this.props.booksResponse.books.length
                        : 0
                    }
                    items={
                      this.props.booksResponse.books
                        ? this.props.booksResponse.books
                        : []
                    }
                    searching={this.props.isSearchingBooks}
                    loadMore={() => this.loadMore('Books')}
                    count={
                      this.props.booksResponse.count
                        ? Number(this.props.booksResponse.count)
                        : 0
                    }
                    query={this.props.query}
                  />
                )}
              {(this.props.options === 'All' ||
                this.props.options === 'Chapters') &&
                this.props.chaptersResponse.chapters && (
                  <ResultsTab
                    title="Chapters"
                    resultSize={
                      this.props.chaptersResponse.chapters
                        ? this.props.chaptersResponse.chapters.length
                        : 0
                    }
                    items={
                      this.props.chaptersResponse.chapters
                        ? this.props.chaptersResponse.chapters
                        : []
                    }
                    searching={this.props.isSearchingChapters}
                    loadMore={() => this.loadMore('Chapters')}
                    count={
                      this.props.chaptersResponse.count
                        ? Number(this.props.chaptersResponse.count)
                        : 0
                    }
                    query={this.props.query}
                  />
                )}
              {(this.props.options === 'All' ||
                this.props.options === 'Questions') &&
                this.props.questionsResponse.questions && (
                  <ResultsTab
                    title="Questions"
                    resultSize={
                      this.props.questionsResponse.questions
                        ? this.props.questionsResponse.questions.length
                        : 0
                    }
                    items={
                      this.props.questionsResponse.questions
                        ? this.props.questionsResponse.questions
                        : []
                    }
                    searching={this.props.isSearchingQuestions}
                    loadMore={() => this.loadMore('Questions')}
                    count={
                      this.props.questionsResponse.count
                        ? Number(this.props.questionsResponse.count)
                        : 0
                    }
                    query={this.props.query}
                  />
                )}
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default Search;
