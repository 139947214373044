import { all, put, call, takeLatest } from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import { setChangeHistory, updateWaiting } from './actions';
import { LOAD_CHANGE_HISTORY } from './actionTypesSagas';
import * as api from '../../utilities/ServiceManager';

/**
 * LOAD change history
 */
function* loadChangeHistorySagas() {
  yield takeLatest(LOAD_CHANGE_HISTORY, callLoadChangeHistory);
}

function* callLoadChangeHistory(action) {
  try {
    yield put(updateWaiting(true));
    const changeHistory = yield call(
      api.getChangeHistory,
      action.assignment,
      action.question,
    );
    yield put(setChangeHistory(changeHistory));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load change history. Please try again later.',
      ),
    );
  } finally {
    yield put(updateWaiting(false));
  }
}

/**
 * DEFAUL EXPORT
 */
export default function* sagas() {
  yield all([loadChangeHistorySagas()]);
}

function showNotificationErrorFromException(exception) {
  let message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
