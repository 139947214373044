import React, { Component } from 'react';

class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <footer
        className="app-footer"
        style={{
          background: this.props.backgroundColor
            ? this.props.backgroundColor
            : '',
        }}
      >
        © {currentYear} Bluejacketeer. All rights reserved.
      </footer>
    );
  }
}

export default Footer;
