import { connect } from 'react-redux';

import addAbilityToOpenModal from '../../../../utilities/modalHOC';
import { getSelectedAssignment } from '../../../Assignments/selectors';
import {
  setIsRefreshForm,
  setQuestions,
  setSelectedQuestion,
  setSeletedQuestionsForDeleted,
  setUrlQuestion,
} from '../../actions';
import {
  amountOfQuestionsInChapter,
  loadQuestionsOfChapter,
  searchQuestionIdSagas,
} from '../../actionsSagas';
import {
  getChapter,
  getIsLoadingQuestions,
  getIsShowDeletedQuestions,
  getPagination,
  getQuestions,
  getQuestionsForDeleted,
  getSelectedQuestion,
  getUrlQuestion,
  getWaiting,
} from '../../selectors';
import QuestionInfiniteList from './QuestionInfiniteList';

const mapStateToProps = (state, ownProps) => ({
  questions: getQuestions(state),
  selectedQuestion: getSelectedQuestion(state),
  pagination: getPagination(state),
  chapter: getChapter(state),
  isWaiting: getWaiting(state),
  assignment: getSelectedAssignment(state),
  isLoadingQuestions: getIsLoadingQuestions(state),
  urlQuestion: getUrlQuestion(state),
  questionsForDeleted: getQuestionsForDeleted(state),
  isShowDeletedQuestions: getIsShowDeletedQuestions(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedQuestion: (question) => {
    dispatch(setSelectedQuestion(question));
    if (question) {
      dispatch(setIsRefreshForm(false));
    } else {
      dispatch(setIsRefreshForm(true));
    }
  },
  onClearQuestions: () => {
    dispatch(
      setQuestions({ data: [], pagination: { total: 0 } }, 0, 25, false),
    );
  },
  loadQuestionsOfChapter: (
    id,
    page,
    rows,
    assignment,
    isInfiniteScroll,
    skip,
  ) => {
    if (id) {
      dispatch(
        loadQuestionsOfChapter(
          id,
          page,
          rows,
          assignment,
          isInfiniteScroll,
          skip,
        ),
      );
    }
  },
  amountOfQuestionsInChapter: (chapter) =>
    dispatch(amountOfQuestionsInChapter(chapter)),
  searchQuestionId: (id, chapter) =>
    dispatch(searchQuestionIdSagas(id, chapter)),
  setUrlQuestion: (question) => dispatch(setUrlQuestion(question)),
  setSeletedQuestionsForDeleted: (question, value) =>
    dispatch(setSeletedQuestionsForDeleted(question, value)),
});

export default addAbilityToOpenModal(
  connect(mapStateToProps, mapDispatchToProps)(QuestionInfiniteList),
);
