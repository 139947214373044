import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';

import Spinner from '../../../commonComponents/Spinner/Spinner';
import { MODAL_TYPES } from '../helpers/constants';
import ConfirmationModal from './ConfirmationModal';
import FavoriteQuestionItem from './FavoriteQuestionItem';

function FavoriteQuestionsList({
  selectedRateId,
  selectedRateShortName,
  getFavoriteQuestions,
  favoriteQuestions,
  isMoreFavoriteQuestions,
  isLoadingFavoriteQuestions,
  removeFavoriteQuestion,
}) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const LIMIT = 50;

  useEffect(() => {
    getFavoriteQuestions(selectedRateId, LIMIT, 0);
  }, [selectedRateId]);

  const handleToggleModal = () => {
    setIsConfirmationModalOpen((prev) => !prev);
  };

  const handleToggleSelectedQuestion = (questionId) => {
    if (selectedQuestions.includes(questionId)) {
      setSelectedQuestions((prev) => prev.filter((qId) => qId !== questionId));
    } else {
      setSelectedQuestions((prev) => [...prev, questionId]);
    }
  };

  const allQuestionsIds =
    favoriteQuestions && favoriteQuestions.map((question) => question.id);

  const sortedFavoriteQuestions =
    Array.isArray(favoriteQuestions) &&
    favoriteQuestions.sort(
      (a, b) => Number(b.maxCreatedAt) - Number(a.maxCreatedAt),
    );

  const favoriteQuestion = () => {
    getFavoriteQuestions(selectedRateId, LIMIT, favoriteQuestions.length);
  };

  if (
    favoriteQuestions &&
    favoriteQuestions.length > 0 &&
    Array.isArray(favoriteQuestions)
  ) {
    return (
      <>
        <div className="action__buttons__container">
          <button
            className="btn btn-primary delete__selected__button"
            disabled={selectedQuestions.length === 0}
            onClick={() => {
              setModalType(MODAL_TYPES.DELETE_SELECTED_FAVORITE);
              handleToggleModal();
            }}
          >
            Delete selected
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              setModalType(MODAL_TYPES.DELETE_ALL_FAVORITE);
              handleToggleModal();
            }}
          >
            Delete all favorites
          </button>
        </div>
        <div className="question__header question__item">
          <Row>
            <Col md={6}>Question</Col>
            <Col md={1} className="text-center">
              Favorite users
            </Col>
            <Col md={1} className="text-center">
              Removed favorites users
            </Col>
            <Col md={1} className="text-center">
              Last favorite date
            </Col>
            <Col md={2}>Rates</Col>
            <Col md={1} className="text-end">
              Options
            </Col>
          </Row>
        </div>

        {Array.isArray(favoriteQuestions) && favoriteQuestions?.length > 0 && (
          <div style={{ height: '85%', overflow: 'auto' }}>
            <InfiniteScroll
              pageStart={0}
              loadMore={favoriteQuestion}
              hasMore={!isLoadingFavoriteQuestions && isMoreFavoriteQuestions}
              useWindow={false}
            >
              {sortedFavoriteQuestions.map((question) => (
                <FavoriteQuestionItem
                  key={question.id}
                  question={question}
                  selectedRateId={selectedRateId}
                  selectedRateShortName={selectedRateShortName}
                  selectedQuestions={selectedQuestions}
                  handleToggleSelectedQuestion={handleToggleSelectedQuestion}
                  removeFavoriteQuestion={removeFavoriteQuestion}
                  allQuestionsIds={allQuestionsIds}
                />
              ))}
            </InfiniteScroll>
            {isLoadingFavoriteQuestions && <Spinner />}
          </div>
        )}

        {isConfirmationModalOpen ? (
          <ConfirmationModal
            isOpen={isConfirmationModalOpen}
            handleToggleModal={handleToggleModal}
            type={modalType}
            allQuestionsIds={allQuestionsIds}
            selectedQuestions={selectedQuestions}
            selectedRateId={selectedRateId}
            removeFavoriteQuestion={removeFavoriteQuestion}
          />
        ) : null}
      </>
    );
  }
  return isLoadingFavoriteQuestions ? (
    <Spinner text="Loading favorite questions" />
  ) : (
    <span>No favorite questions available.</span>
  );
}

export default FavoriteQuestionsList;
