import React from 'react';
import { Link } from 'react-router';
import NumberUtils from '../../lib/NumberUtils';
import './SubNavBar.css';

const SubNavBar = ({
  name = '',
  links = [],
  className,
  onClickEvent,
  showCount,
}) => (
  <div className={'subnavbar-component ' + className}>
    <ul className="nav">
      <li className="px-3 active">{name}</li>
      {links.map(link => (
        <li
          key={NumberUtils.obtainUniqueKey()}
          className={link.active ? 'px-3 active' : 'px-3'}
        >
          {link.to ? (
            <Link to={link.to}>{link.name}</Link>
          ) : link.notClickable ? (
            <span>{link.name}</span>
          ) : (
            <span
              className="span-clickable"
              onClick={() => onClickEvent(link.name)}
            >
              {showCount ? link.name + ' (' + link.count + ')' : link.name}
            </span>
          )}
        </li>
      ))}
    </ul>
  </div>
);

export default SubNavBar;
