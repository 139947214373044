import * as htmlparser from 'htmlparser2';
import Typo from 'typo-js';

const dictionary = new Typo('en_US', false, false, {
  dictionaryPath: '../../dictionaries',
});

const scTag = 'span';
const scClass = 'misspelling';

const markSpellCheckedWords = (text) =>
  // we look for whitespace and non-whitespace chunks of text
  text.replace(/(\s+|[^\s]+)/g, (chunk) => {
    // if white space, keep it as-is
    if (/\s/.test(chunk)) {
      return chunk;
    }

    // wrap misspelled words in desired html marking tags
    const word = chunk.replaceAll(/\W/g, '');
    if (word && !dictionary.check(word)) {
      return `<${scTag} class="${scClass}">${chunk}</${scTag}>`;
    }

    return chunk;
  });
const processHtmlContent = (html) => {
  let resultHtml = '';
  let currentText = '';
  const tagStack = [];

  const parser = new htmlparser.Parser({
    onopentag(name, attrs) {
      if (currentText) {
        // we spell check the accumulated text
        resultHtml += markSpellCheckedWords(currentText);
        currentText = '';
      }

      // we keep the opening tag and it's attributes if any
      resultHtml += `<${name}${Object.keys(attrs)
        .map((key) => ` ${key}="${attrs[key]}"`)
        .join('')}>`;

      // we keep track of nested tags
      tagStack.push(name);
    },
    ontext(text) {
      // we add it to the text to be spell checked
      currentText += text;
    },
    onclosetag(name) {
      if (currentText) {
        // we spell check the accumulated text
        resultHtml += markSpellCheckedWords(currentText);
        currentText = '';
      }

      // if it is the closing tag, we remove the tag from the stack
      if (tagStack.length > 0 && tagStack[tagStack.length - 1] === name) {
        tagStack.pop();
        resultHtml += `</${name}>`;
      }
    },
    onend() {
      if (currentText) {
        // we add any remaining text
        resultHtml += markSpellCheckedWords(currentText);
      }
    },
  });

  parser.write(html);
  parser.end();

  return resultHtml;
};

export const spellCheckContent = (html) => processHtmlContent(html);
