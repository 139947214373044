import * as t from './actionTypes';
import { Map } from 'immutable';

const initialState = Map.of('headerText', Map(), 'modalVisibility', false);

export default function (state = initialState, action) {
  switch (action.type) {
    case t.TOGGLEMODALVISIBILITY:
      if (action.payload === undefined) {
        return state.set('modalVisibility', !state.get('modalVisibility'));
      }

      return state
        .set('modalVisibility', !state.get('modalVisibility'))
        .set('modalContent', action.payload.modalContent)
        .set('modalTitle', action.payload.modalTitle)
        .set('modalClassName', action.payload.modalClassName)
        .set('user', action.payload.user)
        .set('action', action.payload.action);

    case t.OPEN_MODAL:
      return state
        .set('modalContent', action.configuration.modalContent)
        .set('modalTitle', action.configuration.modalTitle)
        .set('modalClassName', action.configuration.modalClassName)
        .set('user', action.configuration.user)
        .set('action', action.configuration.action)
        .set('modalVisibility', true)
        .set('cross', action.configuration.cross)
        .set('titleClassName', action.configuration.titleClassName)
        .set('modalFooter', action.configuration.modalFooter);

    case t.CLOSE_MODAL:
      return state.set('modalVisibility', false);

    default:
      return state;
  }
}
