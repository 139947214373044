import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'changeHistory');
}

export const getChangeHistory = state =>
  getState(state).get('changeHistory').toJS();

export const getWaiting = state => getState(state).get('waiting');
