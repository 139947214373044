import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'prompts');
}

export const getPromptsSelectors = (state) => getState(state).get('allPrompts');

export const getPromptDetailsSelectors = (state) =>
  getState(state).get('promptDetail');

export const loadPromptDetailsSelectors = (state) =>
  getState(state).get('loadPromptDetails');

export const setLoadingInfoSelector = (state) =>
  getState(state).get('loadingInfo');

export const getCreateModeSelector = (state) =>
  getState(state).get('createMode');
