import PropTypes from 'prop-types';
import React from 'react';
import { browserHistory } from 'react-router';
import pure from 'recompose/pure';
import { Field } from 'redux-form';

import Input from '../../../commonComponents/Input/Input';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import Errors from '../../Notifications/Errors';
import Messages from '../../Notifications/Messages';

function ResetPasswordStateless({
  handleSubmit,
  submit,
  requesting,
  errors,
  messages,
  successful,
  successMessageClassName,
  hideSuccessMessage,
  errorMessage,
}) {
  return (
    <div className="container reset-password">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card-group mb-0">
            <div className="iheader">
              <center>
                <img
                  className="logo-bj"
                  src="/img/header.png"
                  alt="bluejacketeer"
                  align="center"
                  loading="lazy"
                />
              </center>
            </div>
            <div className="card p-4">
              <div className="card-block">
                <h1 className="h1-margin-bot">Reset Password</h1>
                <form className="widget-form" onSubmit={handleSubmit(submit)}>
                  <Field
                    name="password"
                    type="password"
                    component={Input}
                    placeholder="Password"
                  />
                  <Field
                    name="confirmPassword"
                    type="password"
                    component={Input}
                    placeholder="Confirm Password"
                  />
                  {errorMessage ? (
                    <p className="error-tooltip-red">{errorMessage}</p>
                  ) : (
                    ''
                  )}
                  <div className="row">
                    <div>
                      <button className="btn btn-login px-4" action="submit">
                        Reset
                      </button>
                    </div>
                    <div>
                      <button
                        className="btn btn-back-home px-4"
                        onClick={() => browserHistory.push('/login')}
                      >
                        Back to Login
                      </button>
                    </div>
                  </div>
                </form>
                <div className="auth-messages">
                  {!requesting && errors && !!errors.length && (
                    <Errors
                      message="Failure to login due to:"
                      errors={errors}
                    />
                  )}
                  {!requesting && !!messages.length && (
                    <Messages messages={messages} />
                  )}
                  {requesting && <Spinner />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ResetPasswordStateless.propTypes = {
  handleSubmit: PropTypes.func,
  creationRequest: PropTypes.func,
  requesting: PropTypes.bool,
  successful: PropTypes.bool,
  messages: PropTypes.array,
  errors: PropTypes.array,
};

export default pure(ResetPasswordStateless);
