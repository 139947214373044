import './Statistics.css';

import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import { submit } from 'redux-form';

import Card from '../../../../commonComponents/Card/Card';
import {
  SimpleInput,
  SimpleTextarea,
} from '../../../../commonComponents/Input/Input';
import Stats from '../../../../commonComponents/Stats/Stats';
import UserFavoriteQuestionsBlock from './components/UserFavoriteQuestionsBlock';
import UserQuestionSubmission from './components/UserQuestionSubmission';

class Statistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      noteNotContact: this.props.noteDoNotContact,
      checkNotContact: this.props.user.doNotContact,
      checkIntercomUnsubscribe: this.props.user.intercomUnsubscribe,
      checkCancelledIntercom: this.props.user.intercomCancellation,
      cancellationReason: this.props.intercomCancellationReason,
      reasonCancellation: this.props.reasonCancellation,
      isEditingEducationalInstitution: false,
      nameCurrentEducationalInstitution: '',
      educationalInstitutionData: this.props.educationalInstitutionData,
      checkUserBeta: false,
    };
  }

  componentWillReceiveProps({
    intercomCancellationReason,
    noteDoNotContact,
    user,
    reasonCancellation,
    educationalInstitutionData,
    isBetaUser,
  }) {
    this.setState({
      checkUserBeta: isBetaUser,
    });
    if (user.id === this.props.user.id) {
      this.setState({
        checkNotContact: user.doNotContact,
        checkIntercomUnsubscribe: user.intercomUnsubscribe,
        checkCancelledIntercom: user.intercomCancellation,
      });
      if (noteDoNotContact) {
        this.setState({
          noteNotContact: noteDoNotContact,
        });
      } else {
        this.setState({
          noteNotContact: '',
        });
      }
      if (intercomCancellationReason) {
        this.setState({
          cancellationReason: intercomCancellationReason,
        });
      } else {
        this.setState({
          cancellationReason: '',
        });
      }
      if (reasonCancellation) {
        this.setState({
          reasonCancellation,
        });
      } else {
        this.setState({
          reasonCancellation: '',
        });
      }
      if (educationalInstitutionData) {
        this.setState({
          nameCurrentEducationalInstitution: educationalInstitutionData,
        });
      } else {
        this.setState({
          nameCurrentEducationalInstitution: '',
        });
      }
    }
  }

  handleDoNotContact = () => {
    const payload = {
      userId: this.props.user.id,
      doNotContact: this.state.checkNotContact,
      note: this.state.noteNotContact,
    };
    this.props.setDoNotContact(payload);
  };

  handleIntercomCancellation = () => {
    const payload = {
      userId: this.props.user.id,
      intercomCancellation: this.state.checkCancelledIntercom,
      intercomCancellationReason: this.state.cancellationReason,
    };
    this.props.setIntercomCancellation(payload);
  };

  handleReasonCancellation = () => {
    const userId = this.props.user.id;
    const reason = this.state.reasonCancellation;
    this.props.upadteCancellationReason(userId, this.state.reasonCancellation);
  };

  renderRow(valueName, value, hasActivity = false) {
    return (
      <Row style={{ width: '100%' }}>
        <Col xs={12} md={12} lg={4} style={{ paddingRight: '0px' }}>
          <div className="valueName">{valueName}</div>
        </Col>
        <Col
          xs={12}
          md={12}
          lg={8}
          className="middleColumn"
          style={{ paddingRight: '0px', paddingLeft: '0px' }}
        >
          <div
            className={
              this.props.isNowActive && hasActivity
                ? 'userON value'
                : valueName === 'Forum posts'
                ? 'posts'
                : 'value'
            }
          >
            {value}
          </div>
        </Col>
      </Row>
    );
  }

  renderStatus = (mobile) =>
    this.props.isNowActive
      ? mobile
        ? `Just Now - Mobile App - ${this.props.path}`
        : `Just Now - Website - ${this.props.path}`
      : null;

  renderDate(date) {
    const dateString = moment(Number(date));
    return `${dateString.format('MMMM')} ${dateString.format(
      'DD',
    )}, ${dateString.format('YYYY')}`;
  }

  handleEditEducationalInstitution = () => {
    this.setState({
      isEditingEducationalInstitution:
        !this.state.isEditingEducationalInstitution,
    });
    if (this.state.isEditingEducationalInstitution) {
      this.setState({
        nameCurrentEducationalInstitution:
          this.props.educationalInstitutionData,
      });
    }

    document.getElementById('input-educational-institution').disabled = false;
    document.getElementById('input-educational-institution').focus();
  };

  submitEducationalInstitutionData = () => {
    const educationalInstitution = this.state.nameCurrentEducationalInstitution;
    this.props.updateEducationalInstitutionData(
      this.props.user.id,
      educationalInstitution,
    );
    this.setState({ isEditingEducationalInstitution: false });
  };

  handleCheckBetaUser = (userId) => {
    this.setState({
      checkUserBeta: !this.state.checkUserBeta,
    });

    this.props.setCheckBetaUser(userId, !this.state.checkUserBeta);
  };

  renderContent() {
    const { statistics } = this.props;
    const title = this.props.isNowActive ? 'Last Seen' : 'Last Login';
    const content = this.props.isNowActive
      ? this.renderStatus(this.props.mobile)
      : this.renderDate(this.props.user.lastLogin);
    return (
      <Row className="cardContentWrapper">
        {this.renderRow(title, content, true)}
        {this.renderRow('Forum posts', 0)}
        <div className="separatorLine" />
        <div className="separatorLineMargin">
          <Row style={{ width: '100%' }}>
            <Col xs={12} md={12} lg={12}>
              <div className="allTime">All time stats</div>
            </Col>
          </Row>
          <div className="statsTitleMargin">
            <Stats
              answered={
                statistics.globalCount ? statistics.globalCount.answered : 0
              }
              correct={
                statistics.globalCount ? statistics.globalCount.correct : 0
              }
              incorrect={
                statistics.globalCount ? statistics.globalCount.incorrect : 0
              }
            />
          </div>
        </div>
        <div className="separatorLine" />

        {this.renderEducationQuestionsContent()}
        <div className="separatorLine" />
        {this.renderCheckBetaUser(this.props.user.id)}
        <div className="separatorLine" />
        <UserQuestionSubmission
          setUserSubmissionBlockStatus={this.props.setUserSubmissionBlockStatus}
          userId={this.props.user.id}
          isUserBlocked={this.props.user.blockSubmitQuestion}
          isLoadingBlockStatus={this.props.isLoadingBlockStatus}
        />
        <UserFavoriteQuestionsBlock
          userId={this.props.user.id}
          isUserBlocked={this.props.user.blockFavoriteQuestion}
          setUserFavoriteBlockStatus={this.props.setUserFavoriteBlockStatus}
          isLoadingFavoriteBlockStatus={this.props.isLoadingFavoriteBlockStatus}
        />
        {this.renderNotContact()}
        {this.renderIntercomCancellation()}
        {this.renderCancellationReason()}
      </Row>
    );
  }

  renderEducationQuestionsContent() {
    const highestEducationLevel = this.props.user.highestEducationLevel
      ? this.props.user.highestEducationLevel
      : '';
    const currentEnrollmentStatus = this.props.user.currentEnrollmentStatus
      ? this.props.user.currentEnrollmentStatus
      : '';
    const estimatedDegreeCompletionDate = this.props.user
      .estimatedDegreeCompletionDate
      ? this.props.user.estimatedDegreeCompletionDate
      : '';
    if (
      this.props.user.highestEducationLevel &&
      this.props.user.currentEnrollmentStatus
    ) {
      if (
        this.props.user.nameCurrentEducationalInstitution &&
        this.props.user.estimatedDegreeCompletionDate
      ) {
        return (
          <div className="educationQuestionsContainer">
            <span className="educationLabelInformation">
              Highest Education Level
            </span>
            <div className="educationQuestions">{highestEducationLevel}</div>
            <span className="educationLabelInformation">
              Current Enrollment Status
            </span>
            <div className="educationQuestions">{currentEnrollmentStatus}</div>
            <span className="educationLabelInformation">
              Current Educational Institution
            </span>

            <div className="educationQuestions row educationQuestions-div">
              <div className="input-width col-lg-9 col-md-9 col-xs-6">
                <input
                  id="input-educational-institution"
                  type="text"
                  value={this.state.nameCurrentEducationalInstitution}
                  disabled={!this.state.isEditingEducationalInstitution}
                  onChange={(e) => {
                    this.setState({
                      nameCurrentEducationalInstitution: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                className="buttons-width col-lg-3 col-md-3 col-xs-6"
                style={
                  !this.state.isEditingEducationalInstitution
                    ? { display: 'block' }
                    : { display: 'none' }
                }
              >
                <i
                  onClick={this.handleEditEducationalInstitution}
                  className="fa fa-pencil fa-lg"
                />
              </div>
              <div
                className="buttons-width col-lg-3 col-md-3 col-xs-6"
                style={
                  this.state.isEditingEducationalInstitution
                    ? { display: 'block' }
                    : { display: 'none' }
                }
              >
                <i
                  onClick={this.submitEducationalInstitutionData}
                  className="fa fa-check fa-lg"
                  style={{ marginRight: '10px' }}
                />
                <i
                  onClick={this.handleEditEducationalInstitution}
                  className="fa fa-close fa-lg"
                />
              </div>
            </div>

            <span className="educationLabelInformation">
              Estimated Degree Completion Date
            </span>
            <div className="educationQuestions">
              {this.renderDate(estimatedDegreeCompletionDate)}
            </div>
          </div>
        );
      }
      return (
        <div className="educationQuestionsContainer">
          <span className="educationLabelInformation">
            Highest Education Level
          </span>
          <div className="educationQuestions">{highestEducationLevel}</div>
          <span className="educationLabelInformation">
            Current Enrollment Status
          </span>
          <div className="educationQuestions">{currentEnrollmentStatus}</div>
        </div>
      );
    }
    return (
      <div className="educationQuestionsContainer">
        <span className="educationLabelInformation">
          The educational information of this user is not available
        </span>
      </div>
    );
  }

  renderNotContact() {
    return (
      <Col md={12}>
        <div className="notContact">
          <SimpleInput
            description="Intercom Unsubscribe"
            type="checkbox"
            value={this.state.checkIntercomUnsubscribe}
            onChange={() => false}
          />

          <SimpleInput
            description="Not contact this user?"
            type="checkbox"
            value={this.state.checkNotContact}
            onChange={(e) => {
              this.setState({ checkNotContact: !this.state.checkNotContact });
            }}
          />

          <SimpleTextarea
            type="text"
            value={this.state.noteNotContact}
            onChange={(e) => {
              this.setState({ noteNotContact: e.target.value });
            }}
          />
          <div className="buttonSave">
            <Button
              className="btn btn-primary"
              onClick={this.handleDoNotContact}
            >
              Save
            </Button>
          </div>
        </div>
      </Col>
    );
  }

  renderCheckBetaUser(userId) {
    return (
      <Col md={12}>
        <div className="notContact">
          <SimpleInput
            description="Is beta user?"
            type="checkbox"
            value={this.state.checkUserBeta}
            onChange={() => this.handleCheckBetaUser(userId)}
          />
        </div>
      </Col>
    );
  }

  renderIntercomCancellation() {
    return (
      <Col md={12}>
        <div className="notContact">
          <SimpleInput
            description="User canceled from Intercom?"
            type="checkbox"
            value={this.state.checkCancelledIntercom}
            onChange={() => {
              this.setState({
                checkCancelledIntercom: !this.state.checkCancelledIntercom,
              });
            }}
          />

          <SimpleTextarea
            type="text"
            value={this.state.cancellationReason}
            placeholder="I was promoted!"
            onChange={(e) => {
              this.setState({ cancellationReason: e.target.value });
            }}
          />
          <div className="buttonSave">
            <Button
              className="btn btn-primary"
              onClick={this.handleIntercomCancellation}
            >
              Save
            </Button>
          </div>
        </div>
      </Col>
    );
  }

  renderCancellationReason() {
    return (
      <Col md={12}>
        <div className="notContact">
          <span>User cancellation reason (Editable)</span>
          <div className="textAreaCancellation">
            <SimpleTextarea
              type="text"
              value={this.state.reasonCancellation}
              onChange={(e) => {
                this.setState({ reasonCancellation: e.target.value });
              }}
            />
            <div className="buttonSave">
              <Button
                className="btn btn-primary"
                onClick={this.handleReasonCancellation}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Col>
    );
  }

  render() {
    return (
      <div className="statisticsContainer">
        <Card title="Statistics" content={this.renderContent()} />
      </div>
    );
  }
}

export default Statistics;
