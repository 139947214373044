import React from 'react';
import Spinner from '../../Spinner/Spinner';
import '../CreateChapter/CreateChapter.css';
import { connect } from 'react-redux';
import { getBibliographyLogs } from '../../../components/Bibliographies/selectors';
import { loadLogs } from '../../../components/Bibliographies/actionsSagas';
import { updateBibLogs } from '../../../components/Bibliographies/actions';
import moment from 'moment';

class BibliographyLogs extends React.Component {
  componentWillMount() {
    const { ratingExam, dutyState } = this.props;
    this.props.getLogs(ratingExam, dutyState);
  }

  componentWillUnmount() {
    this.props.clearLogs();
  }

  getLogs = (text, items) => {
    if (items.length > 0) {
      return (
        <div style={{ display: 'flex' }}>
          {' '}
          {text}{' '}
          <ul>
            {items.map(l => (
              <li>{l}</li>
            ))}
          </ul>
        </div>
      );
    }
  };

  render() {
    const { logs, onClose, ratingExam, dutyState } = this.props;
    return (
      <div className="create-chapter-component">
        <div className="card-block">
          <div className="createChapterTitle">
            Bibliography Logs for {ratingExam} {dutyState}
            <i className="fa fa-times fa-xs" onClick={onClose} />
            <hr style={{ width: '600px', marginLeft: '-15px' }} />
          </div>
          {!logs && <Spinner text="Loading Logs" />}
          {logs &&
            logs.length > 0 &&
            logs.map(l => (
              <div className="BibliographyLog">
                Action: {l.action} <br />
                Admin:{' '}
                {l.createdBy && l.createdBy.email
                  ? l.createdBy.email
                  : 'Information not available'}{' '}
                <br />
                IP: {l.adminIP} <br />
                Date: {moment(l.createdAt).format('L')} <br />
                Time: {moment(l.createdAt).format('LT')} <br />
                Cycle: {l.cycle} <br />
                {this.getLogs('Checked chapters: ', l.checkedChapters)}
                {this.getLogs('Unchecked chapters: ', l.uncheckedChapters)}
                {this.getLogs('Checked subtopics: ', l.checkedSubtopics)}
                {this.getLogs('Unchecked subtopics: ', l.uncheckedSubtopics)}
              </div>
            ))}
          {logs && logs.length === 0 && (
            <div>There are no logs for this rating exam & paygrade</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    logs: getBibliographyLogs(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLogs: (ratingExam, dutyState) => {
      dispatch(loadLogs(ratingExam, dutyState));
    },
    clearLogs: () => {
      dispatch(updateBibLogs(null));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BibliographyLogs);
