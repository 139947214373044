import { Row } from 'react-bootstrap';

import CardRow from './CardRow';

function QuestionHistory({
  questionHistory,
  setShowDetail,
  setHistorySelectedForDetails,
}) {
  return (
    <Row className="cardContentWrapper">
      {!Array.isArray(questionHistory) || questionHistory.length === 0 ? (
        <div> There is no current activity</div>
      ) : (
        questionHistory.map((history, index) => (
          <CardRow
            history={history}
            key={history.id}
            setShowDetail={setShowDetail}
            setHistorySelectedForDetails={setHistorySelectedForDetails}
            lastRow={index === questionHistory.length - 1}
          />
        ))
      )}
      {/*
   <div className="paginationContainer">
     <Pagination
       currentPage=
       totalRecords={}
       onChangePage=
     />
   </div>
   */}
    </Row>
  );
}

export default QuestionHistory;
