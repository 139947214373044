import React from 'react';
import './ProfileSheet.css';
import Card from '../../../../commonComponents/Card/Card';
import Stats from '../../../../commonComponents/Stats/Stats';
import {
  SimpleInput,
  SimpleTextarea,
} from '../../../../commonComponents/Input/Input';
import { Row, Col, Table } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import moment from 'moment';
import { isArray } from 'underscore';
// import  from 'reactstrap';

class ProfileSheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkDisableData: false,
      checkDisableExam: false,
      getData: true,
    };
  }

  // componentWillMount() {

  //   if(this.props.selectedUser.id){
  //     this.props.getCylesInformation(this.props.selectedUser.id)
  //   }
  // }

  componentWillReceiveProps = nextProps => {
    if (this.state.getData && nextProps.user.id) {
      this.props.getCylesInformation(nextProps.user.id);
      this.setState({ getData: false });
    }
  };

  disabledCycle(value, id) {
    this.props.setDisableCyleAction(id, value);
    this.setState({ getData: true });
  }

  renderTable() {
    return (
      <Table>
        <thead>
          <tr>
            <th>
              <div className="checkStyle">#</div>
            </th>
            <th>
              <div className="checkStyle">DISABLE DATA</div>
            </th>
            <th>
              <div className="checkStyle">DISABLE EXAM</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {this.props.listNewCycles &&
            this.props.listNewCycles.length > 0 &&
            this.props.listNewCycles.map((cycle, index) => {
              return (
                <tr key={cycle.id} className="listCycle">
                  <td>
                    <div className="cyclesDescription">
                      <span
                        className={
                          cycle.deletedByUser
                            ? 'fontCycleDeleteByUser'
                            : 'fontCycle'
                        }
                      >
                        {cycle.description}
                      </span>
                      <span className="fontTitle">{cycle.title}</span>
                    </div>
                  </td>
                  <td>
                    <div className="checkStyle">
                      <SimpleInput
                        type="checkbox"
                        value={cycle.disableData}
                        onChange={() => {
                          this.disabledCycle(
                            { value: !cycle.disableData, data: 'disableData' },
                            cycle.id,
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="checkStyle">
                      <SimpleInput
                        type="checkbox"
                        value={cycle.disableExam}
                        onChange={e => {
                          this.disabledCycle(
                            { value: !cycle.disableExam, data: 'disableExam' },
                            cycle.id,
                          );
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  }

  renderContent() {
    return (
      <Row className="cardContentWrapper">
        <div>{this.renderTable()}</div>
      </Row>
    );
  }

  render() {
    return (
      <div className="profileSheetContainer">
        <Card title="Profile Sheet" content={this.renderContent()} />
      </div>
    );
  }
}

export default ProfileSheet;
