import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'groups');
}

export const getRoles = state =>
  getState(state)
    .get('roles')
    .toArray();
export const getRolesInmutable = state => getState(state).get('roles');

export const getGroupsForRole = state =>
  getState(state)
    .get('groups')
    .toArray();
export const getGroupsForRoleInmutable = state => getState(state).get('groups');

export const getAdmins = state =>
  getState(state)
    .get('admins')
    .toArray();
export const getAdminsInmutable = state => getState(state).get('admins');

export const getWaiting = (state, what) =>
  getState(state).get(`waiting${what}`);

export const getDeleteResponse = state => getState(state).get('deleteResponse');

export const getGroupToDelete = state => getState(state).get('groupToDelete');
export const getSelectedRate = state => getState(state).get('selectedRate');
export const getSelectedQual = state => getState(state).get('selectedQual');
export const getViewRates = state => getState(state).get('viewRates');
