import { Map } from 'immutable';

import * as t from './actionTypes';

const initialState = Map({
  allPrompts: [],
  promptDetail: {},
  loadPromptDetails: false,

  editPrompt: {},
  loadingInfo: false,
  createMode: false,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case t.SET_ALL_PROMPTS:
      return state.set('allPrompts', action.prompts);
    case t.PROMPT_DETAILS:
      return state.set('promptDetail', action.prompt).set(
        'allPrompts',
        state.get('allPrompts', action.prompt).map((p) => {
          if (action.prompt.id === p.id) {
            return action.prompt;
          }
          return p;
        }),
      );
    case t.LOAD_PROMPT_DETAILS:
      return state.set('loadPromptDetails', action.value);
    case t.CREATE_PROMPT_VIEW:
      return state.set('editPrompt', action.editPrompt);
    case t.SET_LOADING_INFO_PROMPT:
      return state.set('loadingInfo', action.value);
    case t.CREATE_MODE:
      return state.set('createMode', action.value);
    default:
      return state;
  }
}
