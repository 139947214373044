import React from 'react';
import './MoveRowUpDown.css';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
//import TextWithTooltip from '../../commonComponents/TextWithTooltip/TextWithTooltip';

export default class MoveRowUpDown extends React.Component {
  onMove(isUp) {
    let toMove = isUp ? this.props.up : this.props.down;
    this.props.onMoveRows(this.props.current, toMove);
  }

  render() {
    return (
      <div className="MoveRowUpDown">
        <Row>
          {this.props.up && (
            <i
              className="Arrow fa fa-arrow-up"
              onClick={ev => {
                ev.stopPropagation();
                this.onMove(true);
              }}
            />
          )}
        </Row>
        <Row>
          {this.props.down && (
            <i
              className="Arrow fa fa-arrow-down"
              onClick={ev => {
                ev.stopPropagation();
                this.onMove();
              }}
            />
          )}
        </Row>
      </div>
    );
  }
}

MoveRowUpDown.propTypes = {
  onMoveRows: PropTypes.func,
  current: PropTypes.object,
  up: PropTypes.object,
  down: PropTypes.object,
};
