import { connect } from 'react-redux';
import NewAdminStateful from './NewAdminStateful';
import { newAdminActionSagas } from '../actionSagas';
import { reduxForm } from 'redux-form';

const mapStateToProps = state => {
  return {
    creationRequest: state.newAdmin,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    newAdminRequest: payload => {
      dispatch(newAdminActionSagas(payload));
    },
  };
};

const connected = connect(mapStateToProps, mapDispatchToProps)(
  NewAdminStateful
);

// in our Redux's state, this form will be available in 'form.login'
const NewAdminContainer = reduxForm({
  form: 'NewAdmin',
})(connected);

export default NewAdminContainer;
