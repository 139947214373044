import React from 'react';
import Button from 'react-bootstrap-button-loader';
import { CSVLink } from 'react-csv';

export default class SubcodeModal extends React.Component {
  downloadCodes = () => {
    document.getElementById('subcodes-download').click();
  };

  renderTextUsed(existing) {
    return (
      <div className={existing ? 'used-code' : 'used-code-nc'}>
        <b>{existing ? 'Existing customer used' : 'New customer used'}</b>
      </div>
    );
  }

  render() {
    const { coupon, isNCCouponsAvailable, isECCouponsAvailable } = this.props;
    const totalCodes = coupon.subcodes ? coupon.subcodes.length : 0;
    let usedCodes = 0;
    let usedCodesNC = 0;
    if (totalCodes)
      coupon.subcodes.forEach((c) => {
        if (
          (c.redeemed && !c.redeemedNC) ||
          (!c.redeemed && c.redeemedNC) ||
          (c.redeemedNC && c.redeemed)
        )
          usedCodes++;
      });
    let headers = [{ label: 'Code', key: 'code' }];
    if (isECCouponsAvailable) {
      headers.push({ label: 'Used Exc', key: 'redeemed' });
    }

    if (isNCCouponsAvailable) {
      headers.push({ label: 'Used New', key: 'redeemedNC' });
    }

    return (
      <div className="create-coupon-component">
        <div className="card-block">
          <div className="edit-dialog-header">
            <div className="createCouponTitle">Subcode List</div>
            <i
              style={{ cursor: 'pointer', padding: '0.275rem' }}
              className="fa fa-times fa-xs"
              onClick={() => this.props.onCancel()}
            />
          </div>
          {totalCodes ? (
            <div className="code-uses">
              <div>
                <b>Total codes:</b> {totalCodes}
              </div>
              <div>
                <b>Used codes:</b> {usedCodes}
              </div>
              <div>
                <b>Remaining codes:</b> {totalCodes - usedCodes - usedCodesNC}
              </div>
              <div style={{ display: 'none' }}>
                <CSVLink
                  id="subcodes-download"
                  filename={coupon.name + ' subcodes.csv'}
                  data={
                    coupon.subcodes.map((sc) => {
                      let object = { code: sc.code };
                      if (isNCCouponsAvailable) {
                        object.redeemedNC = sc.redeemedNC ? 'yes' : 'no';

                        if (isECCouponsAvailable) {
                          object.redeemed = sc.redeemed ? 'yes' : 'no';
                        }
                      } else if (isECCouponsAvailable) {
                        object.redeemed = sc.redeemed ? 'yes' : 'no';
                      }

                      return object;
                    }) || []
                  }
                  headers={headers}
                />
              </div>
            </div>
          ) : (
            <div>This coupon doesn't have a subcode list created yet</div>
          )}
          <div className="codes-list">
            {coupon && coupon.subcodes && coupon.subcodes.length
              ? coupon.subcodes.map((subcode) => {
                  return (
                    <div key={subcode.code} className="code-item">
                      <b style={{ width: '5rem' }}>{subcode.code}</b>
                      {subcode.redeemed &&
                        !subcode.redeemedNC &&
                        this.renderTextUsed(true)}
                      {((subcode.redeemedNC && !subcode.redeemed) ||
                        (subcode.redeemedNC && subcode.redeemed)) &&
                        this.renderTextUsed(false)}
                    </div>
                  );
                })
              : null}
          </div>
          <div className="componentFooter">
            <div className="bottom-buttons-new">
              <Button
                disabled={totalCodes}
                className="btn btn-secondary"
                onClick={() => this.props.onSubmit()}
              >
                Generate Codes
              </Button>
              <Button
                className="btn btn-secondary"
                onClick={() => {
                  this.downloadCodes();
                }}
              >
                Download CSV
              </Button>
              <Button
                className="btn btn-secondary"
                onClick={this.props.onCancel}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
