import React from 'react';
import { Col } from 'react-bootstrap';
import NumberUtils from '../../../../lib/NumberUtils';
import './CouponList.css';
import CreateCoupon from '../../../../commonComponents/Modals/CreateCoupon/CreateCoupon';

export default class CouponList extends React.Component {
  state = {
    search: '',
  };

  componentDidMount = () => {
    this.props.loadCoupons();
  };

  onSearch = e => {
    this.setState({ search: e.target.value });
  };

  onOpenCreateModal = () => {
    this.props.openModal({
      modalContent: (
        <CreateCoupon
          onSubmit={this.props.onCreateCoupon}
          onCancel={this.props.closeModal}
          title="Create Coupon"
        />
      ),
    });
  };

  render() {
    let {
      coupons,
      selectedCoupon,
      setSelectedCoupon,
      onCleanCodeGroup,
      onSelectSelectedCodeGroup,
      canEdit,
    } = this.props;
    let isSearch = this.state.search !== '';
    let filteredCoupons = isSearch
      ? coupons.filter(c =>
          c.description.toLowerCase().includes(this.state.search.toLowerCase()),
        )
      : coupons;
    return (
      <Col xs={6} md={3} className="quals-list-container coupons-list">
        <div className="rates-list-component">
          <div className="rates-title">
            <div className="flexItemsTitlePlus">
              Coupons
              <div className="searchInputContainer">
                <SearchWithIcon
                  search={this.state.search}
                  onSearch={this.onSearch}
                  onClearSearch={() => this.setState({ search: '' })}
                />
                {canEdit && (
                  <i
                    className="fa fa-plus fa-lg mt-1"
                    onClick={this.onOpenCreateModal}
                  />
                )}
              </div>
            </div>
            <div className="line-quals-page" />
          </div>
          <div className="rates-list">
            {filteredCoupons.length ? (
              filteredCoupons.map(coupon => (
                <p className="row" key={NumberUtils.obtainUniqueKey()}>
                  <div
                    className={
                      selectedCoupon && selectedCoupon.id === coupon.id
                        ? 'rate-line active'
                        : 'rate-line'
                    }
                    onClick={() => {
                      onCleanCodeGroup();
                      onSelectSelectedCodeGroup(null);
                      setSelectedCoupon(coupon);
                    }}
                  >
                    <div className="description">{coupon.description}</div>
                    <div className="codes">
                      {coupon.groups +
                        ' groups - ' +
                        coupon.codes +
                        ' codes (' +
                        coupon.redeemedCodes +
                        ' redeemed) ' +
                        (Number(coupon.codes) > 0
                          ? (
                              (Number(coupon.redeemedCodes) * 100) /
                              Number(coupon.codes)
                            ).toFixed(2)
                          : 0) +
                        ' percent'}
                    </div>
                  </div>
                </p>
              ))
            ) : (
              <p>No Coupons</p>
            )}
          </div>
        </div>
      </Col>
    );
  }
}

export class SearchWithIcon extends React.Component {
  render() {
    return (
      <div className="searchInput">
        <input
          className="form-control"
          value={this.props.search}
          onChange={this.props.onSearch}
        />
        {this.props.search !== '' ? (
          <i
            onClick={this.props.onClearSearch}
            className="fa fa-close fa-lg fa-in-search"
          />
        ) : (
          <i className="fa fa-search fa-lg fa-in-search" />
        )}
      </div>
    );
  }
}
