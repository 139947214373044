import { Map } from 'immutable';

import * as t from './actionTypes';

const initialState = Map({
  allDailyQuiz: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case t.SET_ALL_DALY_QUIZ_FOR_TODAY:
      return state.set('allDailyQuiz', action.allDailyQuiz);
    default:
      return state;
  }
};
