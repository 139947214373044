import { Col, Row } from 'react-bootstrap';

function QuestionHistoryDetails({ history }) {
  return (
    <>
      <Row>
        <p style={{ marginBottom: '1rem', marginLeft: '1rem' }}>
          <b>{history.action.toUpperCase()}</b> question{' '}
          <b>{history.newQuestion.id}</b> by{' '}
          {history.action === 'create' ? (
            <b>{history.createdBy}</b>
          ) : (
            <b>{history.updatedBy}</b>
          )}
        </p>
      </Row>

      <Row className="headerRow">
        <Col xs={2} className="detailactivity-row">
          <b>Attribute</b>
        </Col>
        <Col xs={5} className="detailactivity-row">
          <b>Old</b>
        </Col>
        <Col xs={5} className="detailactivity-row">
          <b>New</b>
        </Col>
      </Row>
      <Row className="headerRow">
        <Col xs={2} className="detailactivity-row">
          WasAIGenerated
        </Col>
        <Col xs={5} className="detailactivity-row">
          {history.oldQuestion.wasAIGenerated ? 'True' : 'False'}
        </Col>
        <Col xs={5} className="detailactivity-row">
          {history.newQuestion.wasAIGenerated ? 'True' : 'False'}
        </Col>
      </Row>
      <Row className="headerRow">
        <Col xs={2} className="detailactivity-row">
          Flash card
        </Col>
        <Col xs={5} className="detailactivity-row">
          {history.oldQuestion.flashMode ? 'True' : 'False'}
        </Col>
        <Col xs={5} className="detailactivity-row">
          {history.newQuestion.flashMode ? 'True' : 'False'}
        </Col>
      </Row>
      <Row className="headerRow">
        <Col xs={2} className="detailactivity-row">
          Question
        </Col>
        <Col xs={5} className="detailactivity-row">
          <div
            dangerouslySetInnerHTML={{
              __html:
                history.oldQuestion.flashMode === true
                  ? history.oldQuestion.flashQuestionText || '-'
                  : history.oldQuestion.text || '-',
            }}
          />
        </Col>
        <Col xs={5} className="detailactivity-row">
          <div
            dangerouslySetInnerHTML={{
              __html:
                history.newQuestion.flashMode === true
                  ? history.newQuestion.flashQuestionText || '-'
                  : history.newQuestion.text || '-',
            }}
          />
        </Col>
      </Row>
      <Row className="headerRow">
        <Col xs={2} className="detailactivity-row">
          Correct answer
        </Col>
        <Col xs={5} className="detailactivity-row">
          <div
            dangerouslySetInnerHTML={{
              __html: history.oldQuestion.answers?.find(
                (a) => a.correct === true,
              ).text,
            }}
          />
          {}
        </Col>
        <Col xs={5} className="detailactivity-row">
          <div
            dangerouslySetInnerHTML={{
              __html: history.newQuestion.answers?.find(
                (a) => a.correct === true,
              ).text,
            }}
          />
        </Col>
      </Row>

      <Row className="headerRow">
        <Col xs={2} className="detailactivity-row">
          Incorrect answers
        </Col>
        <Col xs={5} className="detailactivity-row">
          {history.oldQuestion.flashMode === true ? (
            '-'
          ) : (
            <ul style={{ paddingLeft: '1rem' }}>
              {history.oldQuestion.answers?.map((a) => {
                if (
                  (a.correct === false || a.correct === 'false') &&
                  // replace any HTML tag for empty string
                  a.text.replace(/<[^>]+>/g, '').trim().length > 0
                ) {
                  return <li dangerouslySetInnerHTML={{ __html: a.text }} />;
                }
                return null;
              })}
            </ul>
          )}
        </Col>
        <Col xs={5} className="detailactivity-row">
          {history.newQuestion.flashMode === true ? (
            '-'
          ) : (
            <ul style={{ paddingLeft: '1rem' }}>
              {history.newQuestion.answers?.map((a) => {
                if (
                  (a.correct === false || a.correct === 'false') &&
                  // replace any HTML tag for empty string
                  a.text.replace(/<[^>]+>/g, '').trim().length > 0
                ) {
                  return <li dangerouslySetInnerHTML={{ __html: a.text }} />;
                }
                return null;
              })}
            </ul>
          )}
        </Col>
      </Row>
    </>
  );
}

export default QuestionHistoryDetails;
