import { Button, Modal } from 'react-bootstrap';

import { MODAL_TYPES } from '../helpers/constants';

function ConfirmationModal({ isOpen, handleToggleModal, type, ...props }) {
  const buttonText = type ? type.split('_')[0] : 'Confirm';
  let titleText = '';
  let bodyText = '';

  const handleSubmit = () => {
    switch (type) {
      case MODAL_TYPES.DELETE:
        props.deleteSubmittedQuestion(
          props.question.id,
          props.selectedRateId,
          props.question.updatedAt,
        );
        handleToggleModal();
        break;

      case MODAL_TYPES.RECOVER:
        props.undeleteSubmittedQuestion(
          props.question.id,
          props.question.user,
          props.question.updatedAt,
          props.selectedRateId,
        );
        handleToggleModal();
        break;

      case MODAL_TYPES.APPROVE:
        props.approveSubmittedQuestion(
          props.question.id,
          props.selectedRateId,
          props.question.updatedAt,
        );
        handleToggleModal();
        break;

      case MODAL_TYPES.REJECT:
        props.rejectSubmittedQuestion(
          props.question.id,
          props.selectedRateId,
          props.question.updatedAt,
        );
        handleToggleModal();
        break;

      case MODAL_TYPES.EDIT: {
        // filter: remove falsy values
        const incorrectAnswersState =
          props.questionState.incorrectAnswers.filter((ia) => ia);
        const incorrectAnswersOriginalQuestion = props.question.answer.filter(
          (answer) => !answer.correct,
        );

        const questionForEndpoint = {
          text: props.questionState.text,
          notes: props.questionState.notes,
          correctAnswer: {
            id: props.question.answer.filter((answer) => answer.correct)[0].id,
            text: props.questionState.correctAnswer,
          },
          incorrectAnswers: incorrectAnswersState.map((ia, index) => {
            if (incorrectAnswersOriginalQuestion[index]) {
              return {
                id: incorrectAnswersOriginalQuestion[index].id,
                text: ia,
              };
            }
            return {
              text: ia,
            };
          }),
        };

        props.editSubmittedQuestion(
          props.question.id,
          questionForEndpoint,
          props.selectedRateId,
          props.question.updatedAt,
        );
        props.handleCloseExpand();
        props.handleEndEdition();
        handleToggleModal();
        break;
      }
      case MODAL_TYPES.DELETE_FAVORITE:
        props.removeFavoriteQuestion(props.selectedRateId, {
          questionsId: [props.question.id],
        });
        handleToggleModal();
        break;

      case MODAL_TYPES.DELETE_ALL_FAVORITE:
        props.removeFavoriteQuestion(props.selectedRateId, {
          questionsId: props.allQuestionsIds,
        });
        handleToggleModal();
        break;

      case MODAL_TYPES.DELETE_SELECTED_FAVORITE:
        props.removeFavoriteQuestion(props.selectedRateId, {
          questionsId: props.selectedQuestions,
        });
        handleToggleModal();
        break;

      default:
        handleToggleModal();
        break;
    }
  };

  switch (type) {
    case MODAL_TYPES.DELETE:
      titleText = 'Delete Question';
      bodyText = 'Are you sure you want to delete this question?';
      break;

    case MODAL_TYPES.RECOVER:
      titleText = 'Recover Question';
      bodyText = 'Are you sure you want to recover this question?';
      break;

    case MODAL_TYPES.REJECT:
      titleText = 'Reject Question';
      bodyText = 'Are you sure you want to reject this question?';
      break;

    case MODAL_TYPES.APPROVE:
      titleText = 'Approve Question';
      bodyText = 'Are you sure you want to approve this question?';
      break;

    case MODAL_TYPES.EDIT:
      titleText = 'Edit Question';
      bodyText = 'Are you sure you want to edit this question?';
      break;

    case MODAL_TYPES.DELETE_FAVORITE:
      titleText = 'Delete Favorite Question';
      bodyText = 'Are you sure you want to delete this favorite question?';
      break;

    case MODAL_TYPES.DELETE_ALL_FAVORITE:
      titleText = 'Delete All Favorite Questions';
      bodyText = 'Are you sure you want to delete all favorite questions?';
      break;

    case MODAL_TYPES.DELETE_SELECTED_FAVORITE:
      titleText = 'Delete Selected Favorite Questions';
      bodyText =
        'Are you sure you want to delete the selected favorite questions?';
      break;

    default:
      titleText = 'Confirmation';
      bodyText = 'Are you sure?';
      break;
  }

  return (
    <Modal show={isOpen} onHide={handleToggleModal}>
      <Modal.Header>
        <Modal.Title>{titleText}</Modal.Title>
        <Button variant="modal__close-button" onClick={handleToggleModal}>
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body>{bodyText}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit}>
          {buttonText}
        </Button>{' '}
        <Button
          variant="secondary"
          onClick={() => {
            if (type === MODAL_TYPES.EDIT) {
              props.handleEndEdition();
              props.handleCloseExpand();
              props.handleResetQuestion();
            }
            handleToggleModal();
          }}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
