import { browserHistory } from 'react-router';
import Cookies from 'universal-cookie';

import { store } from '../index';
import {
  hasPagePermission,
  hasSomePermissionForPage,
} from '../utilities/FilterLinksForTabs';
import * as userToken from '../utilities/userToken';

export function redirect(pagePermissions, rolesPermissions) {
  /* REDIRECT WHEELHOUSE */
  const cookies = new Cookies();
  if (
    hasPagePermission('Wheelhouse', pagePermissions) ||
    hasSomePermissionForPage('Wheelhouse', pagePermissions) ||
    cookies.get('isSuperAdmin') === 'true' ||
    rolesPermissions.filter((permission) =>
      permission.actions.find((action) => action.startsWith('Assignment')),
    ).length > 0
  ) {
    if (cookies.get('isSuperAdmin') === 'true') {
      browserHistory.replace('/home/wheelhouse/assignments');
      return;
    }
    let page = rolesPermissions.find((permission) =>
      permission.actions.find((action) => action.endsWith('ViewAll')),
    );

    page = page ? 'assignments' : 'my-assignments';
    browserHistory.replace(`/home/wheelhouse/${page}`);
    return;
  }
  /* REDIRECT BOOKS */
  if (
    hasPagePermission('Books', pagePermissions) ||
    hasSomePermissionForPage('Books', pagePermissions) ||
    rolesPermissions.filter((role) =>
      role.actions.find((action) => action.startsWith('Books')),
    ).length > 0
  ) {
    browserHistory.replace('/home/books');
    return;
  }
  /* REDIRECT SUBTOPICS */
  if (
    hasPagePermission('Subtopics', pagePermissions) ||
    hasSomePermissionForPage('Subtopics', pagePermissions) ||
    rolesPermissions.filter((role) =>
      role.actions.find((action) => action.EndsWith('Subtopics')),
    ).length > 0
  ) {
    browserHistory.replace('/home/subtopics');
    return;
  }
  /* REDIRECT RATING EXAMS */
  if (
    rolesPermissions.filter(
      (rate) =>
        rate.type === 'rate' &&
        rate.actions.filter((action) => action.startsWith('Books.').length > 0),
    ).length > 0 ||
    hasPagePermission('RatingExams', pagePermissions) ||
    hasPagePermission('Books', pagePermissions) ||
    hasSomePermissionForPage('Books', pagePermissions) ||
    hasPagePermission('Wheelhouse.Cycles', pagePermissions)
  ) {
    browserHistory.replace('/home/rating-exam');
    return;
  }
  /* REDIRECT QUALS */
  if (
    rolesPermissions.filter((rate) => rate.type === 'qual').length > 0 ||
    hasPagePermission('Quals', pagePermissions)
  ) {
    browserHistory.replace('/home/quals');
    return;
  }
  /* REDIRECT ACCOUNTS */
  if (
    hasPagePermission('Accounts', pagePermissions) ||
    hasSomePermissionForPage('Accounts', pagePermissions) ||
    hasSomePermissionForPage('Students', pagePermissions) ||
    hasSomePermissionForPage('Admins', pagePermissions)
  ) {
    if (hasSomePermissionForPage('Students', pagePermissions)) {
      browserHistory.replace('/home/accounts/students');
    } else {
      browserHistory.replace('/home/accounts/admins');
    }
    return;
  }
  // NO PAGE TO REDIRECT
  // TODO: CREATE NOT ACCESS PAGE TO ALLOW USER TO LOGOUT AT LEAST
  browserHistory.replace('/401');
}

export function checkAuthorizationAndRedirectToSomeAllowedPage({ dispatch }) {
  return (nextState, replace, next) => {
    const cookies = new Cookies();
    // const storedToken = cookies.get('token');
    const storedToken = userToken.getUserToken();
    if (storedToken) {
      const rolesPermissions = store.getState().login.get('rolesPermissions');
      const pagePermissions = store
        .getState()
        .login.get('generalRolesPermissions');
      redirect(pagePermissions, rolesPermissions);
      return next();
    }
    sessionStorage.setItem(
      'url_before_redirected_to_login',
      window.location.href,
    );
    browserHistory.replace('/login');
    return next();
  };
}

export function checkLoginAuthorization({ dispatch }) {
  return (nextState, replace, next) => {
    const cookies = new Cookies();
    // const storedToken = cookies.get('token');
    const storedToken = userToken.getUserToken();
    if (!storedToken) {
      return next();
    }
    const rolesPermissions = store.getState().login.get('rolesPermissions');
    const pagePermissions = store
      .getState()
      .login.get('generalRolesPermissions');
    redirect(pagePermissions, rolesPermissions);
    return next();
  };
}

export function checkAuthorization({ dispatch, getState }, name) {
  return (nextState, replace, next) => {
    const cookies = new Cookies();
    const rolesPermissions = store.getState().login.get('rolesPermissions');
    const pagePermissions = store
      .getState()
      .login.get('generalRolesPermissions');
    const loading = store.getState().login.get('loading');
    const storedToken = userToken.getUserToken();
    if (!storedToken) {
      browserHistory.replace('/login');
      return next();
    }
    if (cookies.get('isSuperAdmin') === 'true') {
      return next();
    }
    if (name === 'Search') {
      return next();
    }
    if (
      hasPagePermission(name, pagePermissions) ||
      hasSomePermissionForPage(name, pagePermissions)
    ) {
      return next();
    }
    if (name.startsWith('Books.')) {
      if (
        rolesPermissions.filter((rate) =>
          rate.actions.find((action) => action.startsWith('Books')),
        ).length > 0 ||
        pagePermissions.find((page) => page.startsWith('Books.'))
      ) {
        return next();
      }
    } else {
      if (rolesPermissions.filter((rate) => rate.type === 'qual').length > 0) {
        return next();
      }
      if (name.startsWith('Wheelhouse')) {
        if (
          rolesPermissions.filter((permission) =>
            permission.actions.find((action) =>
              action.startsWith('Assignment'),
            ),
          ).length > 0 ||
          pagePermissions.find((page) => page === 'Wheelhouse.Assignments')
        ) {
          return next();
        }
        if (loading) {
          browserHistory.replace('/loading');
          return next();
        }
        redirect(pagePermissions, rolesPermissions);
        return;
      }
    }
    if (cookies.get('loading') === 'true' || loading) {
      browserHistory.replace('/loading');
      return next();
    }
    browserHistory.replace('/401');
    return next();
  };
}
