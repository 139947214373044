import './Header.css';

import React, { Component } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import Cookies from 'universal-cookie';

import Logo from '../../commonComponents/Images/logo.png';
import NumberUtils from '../../lib/NumberUtils';
import { getUser } from '../Login/selectors';
import HeaderOption from './HeaderOption/headerOption';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      searchInput: '',
    };
  }

  toggle = (e) => {
    e.preventDefault();
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  static sidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  };

  static mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  };

  renderHeader = (header, seconHeader) =>
    header
      ? header.map((option) => this.renderHeaderOption(option, seconHeader))
      : [{ name: '' }].map((option) =>
          this.renderHeaderOption(option, seconHeader),
        );

  clearInput = () => {
    this.setState({ searchInput: '' });
  };

  keyPressed = (event) => {
    if (event.key === 'Enter' && this.state.searchInput !== '') {
      this.props.setQuery(this.state.searchInput);
      this.props.setSearchFromHeader(true);
      browserHistory.push({
        pathname: '/home/search',
      });
    }
  };

  renderHeaderOption = (option, secondHeader) => {
    const optionName = option.name
      ? option.name.trim().split(/\s+/).join('-').toLowerCase()
      : '';
    let isActive =
      this.props.location && this.props.location.pathname.includes(optionName);
    if (
      option.name === 'Bibliographies' &&
      this.props.location.pathname.includes('rating-exam')
    )
      isActive = true;
    if (
      option.name === 'Rates & Quals' &&
      this.props.location.pathname.includes('rates')
    )
      isActive = true;
    if (
      option.name === 'My Assignments' &&
      this.props.location.pathname.includes('my-assignments')
    )
      isActive = true;
    if (
      option.name === 'Assignments' &&
      this.props.location.pathname.includes('my-assignments')
    )
      isActive = false;
    if (
      option.name === '10 Week' &&
      this.props.location.pathname.includes('10week')
    )
      isActive = true;
    if (
      option.name === 'New Coupons' &&
      this.props.location.pathname.includes('newCoupons')
    )
      isActive = true;
    if (
      option.name === 'Banners' &&
      this.props.location.pathname.includes('Banners')
    )
      isActive = true;
    if (
      option.name === 'Message Center' &&
      this.props.location.pathname.includes('message-center')
    )
      isActive = true;
    if (
      option.name === 'Customer Submission' &&
      this.props.location.pathname.includes('customer-submission')
    )
      isActive = true;
    if (
      option.name === 'Daily Quiz' &&
      this.props.location.pathname.includes('dailyQuiz')
    )
      isActive = true;

    return (
      <HeaderOption
        option={option}
        isActive={isActive}
        key={NumberUtils.obtainUniqueKey()}
        secondHeader={secondHeader}
      />
    );
  };

  clearCookies = () => {
    const cookies = new Cookies();
    const availableCookies = cookies.getAll();

    Object.keys(availableCookies).forEach((cookie) => {
      cookies.remove(cookie);
    });
  };

  componentDidMount() {}

  render() {
    return (
      <>
        <header
          className={
            this.props.secondHeader
              ? 'app-header navbar secondHeader'
              : 'app-header navbar firstHeader'
          }
        >
          {!this.props.secondHeader && (
            <button
              className="navbar-toggler mobile-sidebar-toggler d-lg-none"
              onClick={Header.mobileSidebarToggle}
              type="button"
            >
              ☰
            </button>
          )}
          {!this.props.secondHeader && (
            <a className="navbar-brand" href="#">
              <img
                alt="Bluejacketeer"
                src={Logo}
                className="logo"
                loading="lazy"
              />
            </a>
          )}
          <ul className="nav navbar-nav d-md-down-none">
            {false && (
              <li className="nav-item">
                <a
                  className="nav-link navbar-toggler sidebar-toggler"
                  onClick={Header.sidebarToggle}
                  href="#"
                >
                  ☰
                </a>
              </li>
            )}
            {this.renderHeader(
              this.props.headerOptions,
              this.props.secondHeader,
            )}
          </ul>
          {!this.props.secondHeader && (
            <div className="navbar-right-side">
              {this.props.location.pathname !== '/home/search' && (
                <div className="search-on-header">
                  <i className="fa fa-search" />
                  <input
                    type="text"
                    placeholder="Search"
                    onKeyPress={this.keyPressed}
                    value={this.state.searchInput}
                    onChange={(e) =>
                      this.setState({ searchInput: e.target.value })
                    }
                  />
                  <i className="fa fa-close" onClick={this.clearInput} />
                </div>
              )}
              <ul className="nav navbar-nav ml-auto profile">
                <li className="nav-item">
                  {this.props.user.photo ? (
                    <img
                      alt="profile"
                      className="profile-photo"
                      src={this.props.user.photo}
                      loading="lazy"
                    />
                  ) : (
                    <div className="profile-photo" />
                  )}
                </li>
                <li className="nav-item mb-09">
                  <div className="dropdownContainer">
                    <Dropdown
                      isOpen={this.state.dropdownOpen}
                      toggle={(e) => this.toggle(e)}
                    >
                      <DropdownToggle className="nav-link dropdown-toggle nav-link">
                        {this.props.user.name ? this.props.user.name : 'Admin'}
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-rights dropDownPosition">
                        <DropdownItem
                          onClick={() => {
                            browserHistory.replace('/home/my-account');
                            this.setState({ dropdownOpen: false });
                          }}
                        >
                          <i className="fa fa-user" />
                          <Link to="/home/my-account">My Account</Link>
                        </DropdownItem>
                        <DropdownItem>
                          <i className="fa fa-lock" />
                          <Link to="/logout" onClick={this.clearCookies}>
                            Logout
                          </Link>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </header>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(Header);
