import * as type from './actionTypes';

export const getAllPromptsAction = () => ({
  type: type.GET_ALL_PROMPTS,
});

export const setAllPromptsAction = (prompts) => ({
  type: type.SET_ALL_PROMPTS,
  prompts,
});

export const setPromptDetailsAction = (prompt) => ({
  type: type.SET_PROMPT_DETAILS,
  prompt,
});

export const loadPromptDetails = (value) => ({
  type: type.LOAD_PROMPT_DETAILS,
  value,
});

export const promptDetailsAction = (prompt) => ({
  type: type.PROMPT_DETAILS,
  prompt,
});

export const createPromptAction = (prompt) => {
  return {
    type: type.CREATE_PROMPT,
    prompt,
  };
};

export const editPromptAction = (prompt) => ({
  type: type.EDIT_PROMPT,
  prompt,
});

export const deletePromptAction = (prompt) => ({
  type: type.DELETE_PROMPT,
  prompt,
});

export const toggleActivePromptAction = (promptId, value) => ({
  type: type.TOGGLE_ACTIVE_PROMPT,
  promptId,
  value,
});

export const setLoadingInfo = (value) => ({
  type: type.SET_LOADING_INFO_PROMPT,
  value,
});

export const setCreateMode = (value) => ({
  type: type.CREATE_MODE,
  value,
});
