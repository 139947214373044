import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'tenWeek');
}

export const getStudyPlans = state => getState(state).get('studyPlans');
export const getSelectedStudyPlan = state =>
  getState(state).get('selectedStudyPlan');
export const getStudyPlansUsers = state =>
  getState(state).get('studyPlansUsers');
export const getSelectedStudyPlanUser = state =>
  getState(state).get('selectedStudyPlanUser');
export const getActiveListTab = state =>
  getState(state).get('selectedTenWeekListTab');
export const getPreviewPlans = state => getState(state).get('previewPlans');
export const getStudyPlansUsersPage = state =>
  getState(state).get('studyPlansUsersPage');
export const getIsLoadingUserse = state =>
  getState(state).get('isLoadingUsers');
