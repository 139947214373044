const ratesSagaNamespace = 'rates-saga';
export const LOAD_RATES = `${ratesSagaNamespace}/LOAD_RATES`;
export const LOAD_TOPICS = `${ratesSagaNamespace}/LOAD_TOPICS`;
export const CREATE_TOPIC = `${ratesSagaNamespace}/CREATE_TOPIC`;
export const CREATE_SUBTOPIC = `${ratesSagaNamespace}/CREATE_SUBTOPIC`;
export const UPDATE_TOPIC = `${ratesSagaNamespace}/UPDATE_TOPIC`;
export const UPDATE_SUBTOPIC = `${ratesSagaNamespace}/UPDATE_SUBTOPIC`;
export const DELETE_TOPIC = `${ratesSagaNamespace}/DELETE_TOPIC`;
export const DELETE_SUBTOPIC = `${ratesSagaNamespace}/DELETE_SUBTOPIC`;
export const CREATE_RATE = `${ratesSagaNamespace}/CREATE_RATE`;
export const LOAD_QUALS = `${ratesSagaNamespace}/LOAD_QUALS`;
export const LOAD_ALL_RATES = `${ratesSagaNamespace}/LOAD_ALL_RATES`;
export const UPDATE_SP = `${ratesSagaNamespace}/UPDATE_SP`;
export const DELETE_SP = `${ratesSagaNamespace}/DELETE_SP`;
