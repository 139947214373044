import { connect } from 'react-redux';
import { updateChapterSubtopics, loadRate } from '../../Subtopics/actionsSagas';
import { getRate } from '../../Subtopics/selectors';
import { getSelectedAssignment } from '../../Assignments/selectors';
import UpdateSubtopics from '../../../commonComponents/Modals/UpdateSubtopics/UpdateSubtopics';
import { getSelectedRate } from '../../Assignments/selectors';

const mapStateToProps = (state, ownProps) => ({
  topics: ownProps.topics,
  chapter: ownProps.chapter,
  reference: ownProps.chapter.reference,
  closeModal: ownProps.onCancel,
  rateForSubtopics: getRate(state),
  selectedAssignment: getSelectedAssignment(state),
  selectedRate: getSelectedRate(state),
  rateId: ownProps.rateId,
});

const mapDispatchToProps = dispatch => ({
  updateChapterSubtopics: (reference, chapter, checkedSubtopics) =>
    dispatch(updateChapterSubtopics(reference, chapter, checkedSubtopics)),
  loadRate: rate => dispatch(loadRate(rate)),
});

const SubtopicsQuestionViewContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateSubtopics);

export default SubtopicsQuestionViewContainer;
