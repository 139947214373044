import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './ModalWindow.css';

class ModalWindow extends React.Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    modalContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    modalClassName: PropTypes.string,
    modalTitle: PropTypes.string,
  };

  render() {
    return (
      this.props.showModal && (
        <div className="modal">
          <Modal
            backdrop="static"
            keyboard={false}
            show={this.props.showModal}
            onHide={this.props.toggleModalVisibility}
            dialogClassName="modalWindow"
            className={`${this.props.modalClassName} ${this.props.className}`}
          >
            {this.props.modalTitle && (
              <Modal.Header>
                {!this.props.titleClassName && (
                  <strong>{this.props.modalTitle}</strong>
                )}
                {this.props.titleClassName && (
                  <div className={this.props.titleClassName}>
                    {this.props.modalTitle}
                  </div>
                )}
                {this.props.cross && (
                  <i
                    onClick={() => this.props.closeModal()}
                    className="fa fa-times"
                    aria-hidden="true"
                  />
                )}
              </Modal.Header>
            )}
            <Modal.Body>{this.props.modalContent}</Modal.Body>
            {this.props.modalFooter && (
              <Modal.Footer>{this.props.modalFooter}</Modal.Footer>
            )}
          </Modal>
        </div>
      )
    );
  }
}

export default ModalWindow;
