import { put, call, takeLatest, takeEvery, all } from 'redux-saga/effects';
import * as api from '../../utilities/ServiceManager';
import {
  GET_ALL_RATES,
  CREATE_NOTIFICATION,
  GET_NOTIFICATIONS_BY_RATE,
  DELETE_NOTIFICATION,
  EDIT_NOTIFICATION,
} from './actionsTypes';
import Notifications from 'react-notification-system-redux';
import {
  setAllRates,
  loadNotifications,
  setNotificationByRate,
} from './actionsSagas';

function* getAllrates() {
  yield takeEvery(GET_ALL_RATES, getAllRatesSaga);
}

function* getAllRatesSaga() {
  try {
    const response = yield call(api.getAllRatesNotifications);
    yield put(setAllRates(response));
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Done!', message: error.message }, 'error'),
    );
  }
}

function* createNotification() {
  yield takeEvery(CREATE_NOTIFICATION, createNotificationSaga);
}

function* createNotificationSaga(action) {
  try {
    yield put(loadNotifications(true));
    yield call(api.createNotificationService, action.notification);
    yield put(loadNotifications(false));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Notification successfully created',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Done!', message: error.message }, 'error'),
    );
  }
}

function* getNotificacionsByRate() {
  yield takeEvery(GET_NOTIFICATIONS_BY_RATE, getNotificacionsByRatesSaga);
}

function* getNotificacionsByRatesSaga(action) {
  try {
    const response = yield call(api.getNotificationByRate, action.rateId);
    yield put(setNotificationByRate(response));
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Done!', message: error.message }, 'error'),
    );
  }
}

function* deleteNotification() {
  yield takeEvery(DELETE_NOTIFICATION, deleteNotificationSaga);
}

function* deleteNotificationSaga(action) {
  try {
    yield put(loadNotifications(true));
    yield call(api.deleteNotification, action.notificationId);
    yield put(loadNotifications(false));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Notification successfully deleted',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Done!', message: error.message }, 'error'),
    );
  }
}

function* editNotification() {
  yield takeEvery(EDIT_NOTIFICATION, editNotificationSaga);
}

function* editNotificationSaga(action) {
  try {
    yield call(
      api.editNotification,
      action.notificationId,
      action.notificationValue,
    );
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Notification successfully edited',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Done!', message: error.message }, 'error'),
    );
  }
}

export default function* sagas() {
  yield all([
    getAllrates(),
    createNotification(),
    getNotificacionsByRate(),
    deleteNotification(),
    editNotification(),
  ]);
}
