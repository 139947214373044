import { all, call, put, takeEvery } from 'redux-saga/effects';
import { DELETE_TASK_NOTIFICATION } from './actionTypesSagas';
import { deleteAiTaskService } from '../../../../../utilities/ServiceManager';
import Notifications from 'react-notification-system-redux';

function* deleteTaskNotificationWatcher() {
  yield takeEvery(DELETE_TASK_NOTIFICATION, deleteTaskNotification);
}

function* deleteTaskNotification(action) {
  try {
    yield call(deleteAiTaskService, action.taskId);
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Notification deleted successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show(
        { title: 'Error', message: 'Error deleting notification' },
        'error',
      ),
    );
  }
}

export default function* sagas() {
  yield all([deleteTaskNotificationWatcher()]);
}
