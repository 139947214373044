import { all, put, call, takeLatest } from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import { closeModal } from '../../containers/Full/actions';
import {
  setSelectedStudyPlan,
  setStudyPlans,
  addStudyPlan,
  setStudyPlansUsers,
  setSelectedStudyPlanUser,
  setLoadiingUser,
} from './actions';
import {
  loadStudyPlans as getStudyPlans,
  loadStudyPlansUsers as getStudyPlansUsers,
} from './actionsSagas';
import {
  LOAD_STUDY_PLANS,
  CREATE_STUDY_PLAN,
  EDIT_STUDY_PLAN,
  UPDATE_START_DATE,
  UPDATE_EMAIL,
  LOAD_STUDY_PLANS_USERS,
  EDIT_STUDY_PLAN_USER,
  DELETE_STUDY_PLAN_USER,
  SEND_STUDY_PLAN_PREVIEW,
} from './actionTypesSagas';
import * as api from '../../utilities/ServiceManager';

/**
 * LOAD Study Plans
 */
function* loadStudyPlansSaga() {
  yield takeLatest(LOAD_STUDY_PLANS, loadStudyPlans);
}

function* loadStudyPlans(action) {
  try {
    const studyPlans = yield call(api.loadStudyPlans);
    yield put(setStudyPlans(studyPlans));
    if (studyPlans && studyPlans.data && studyPlans.data.length > 0) {
      if (action.selectedStudyPlan) {
        const selectedPlan = studyPlans.data.find(
          (sp) => sp.id === action.selectedStudyPlan,
        );
        yield put(setSelectedStudyPlan(selectedPlan));
      } else yield put(setSelectedStudyPlan(studyPlans.data[0]));
    }
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load study plans. Please try again later.',
      ),
    );
  }
}

/**
 * CREATE Study Plan
 */
function* createStudyPlanSaga() {
  yield takeLatest(CREATE_STUDY_PLAN, createStudyPlan);
}

function* createStudyPlan(action) {
  try {
    const studyPlan = yield call(api.createStudyPlan, action.studyPlan);
    yield put(addStudyPlan(studyPlan));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Study Plan created successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  } finally {
    yield put(closeModal());
  }
}

/**
 * EDIT Study Plan
 */
function* editStudyPlanSaga() {
  yield takeLatest(EDIT_STUDY_PLAN, editStudyPlan);
}

function* editStudyPlan(action) {
  try {
    yield call(
      api.editStudyPlan,
      action.studyPlan,
      action.title,
      action.isActive,
    );
    yield put(getStudyPlans(action.studyPlan));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Study Plan edited successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  } finally {
    yield put(closeModal());
  }
}

/**
 * UPDATE Study Plan Start Date
 */
function* updateStartDateSaga() {
  yield takeLatest(UPDATE_START_DATE, updateStartDate);
}

function* updateStartDate(action) {
  try {
    yield call(
      api.updateStartDate,
      action.studyPlan,
      action.rank,
      action.dutyState,
      action.date,
      action.isApplicable,
    );
    yield put(getStudyPlans(action.studyPlan));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Start Date edited successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  }
}

/**
 * UPDATE Study Plan Email
 */
function* updateStudyPlanEmailSaga() {
  yield takeLatest(UPDATE_EMAIL, updateStudyPlanEmail);
}

function* updateStudyPlanEmail(action) {
  try {
    yield call(
      api.updateStudyPlanEmail,
      action.subject,
      action.content,
      action.emailId,
    );
    yield put(getStudyPlans(action.studyPlan));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Email edited successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  }
}

/**
 * LOAD Study Plans Users
 */
function* loadStudyPlansUsersSaga() {
  yield takeLatest(LOAD_STUDY_PLANS_USERS, loadStudyPlansUsers);
}

function* loadStudyPlansUsers(action) {
  try {
    yield put(setLoadiingUser(true));
    const studyPlansUsers = yield call(
      api.loadStudyPlansUsers,
      action.search,
      action.page,
    );
    yield put(setStudyPlansUsers(studyPlansUsers.data, action.page));
    if (
      studyPlansUsers &&
      studyPlansUsers.data &&
      studyPlansUsers.data.length > 0
    ) {
      if (action.selectedStudyPlanUser) {
        const selectedPlanUser = studyPlansUsers.data.find(
          (sp) => sp.id === action.selectedStudyPlanUser,
        );
        yield put(setSelectedStudyPlanUser(selectedPlanUser));
      } else yield put(setSelectedStudyPlanUser(studyPlansUsers.data[0]));
    }
    yield put(setLoadiingUser(false));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load study plans. Please try again later.',
      ),
    );
    yield put(setLoadiingUser(false));
  }
}

/**
 * UPDATE Study Plan User
 */
function* updateStudyPlanUserSaga() {
  yield takeLatest(EDIT_STUDY_PLAN_USER, updateStudyPlanUser);
}

function* updateStudyPlanUser(action) {
  try {
    yield call(
      api.updateStudyPlanUser,
      action.rate,
      action.dutyState,
      action.rank,
      action.isSubscribed,
      action.id,
    );
    yield put(getStudyPlansUsers(action.id));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User edited successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  }
}

/**
 * DELETE Study Plan User
 */
function* deleteStudyPlanUserSaga() {
  yield takeLatest(DELETE_STUDY_PLAN_USER, deleteStudyPlanUser);
}

function* deleteStudyPlanUser(action) {
  try {
    yield call(api.deleteStudyPlanUser, action.id);
    yield put(getStudyPlansUsers(null));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'User deleted successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  }
}

/**
 * SEND Study Plan Preview
 */
function* sendStudyPlanPreviewSaga() {
  yield takeLatest(SEND_STUDY_PLAN_PREVIEW, sendStudyPlanPreview);
}

function* sendStudyPlanPreview(action) {
  try {
    yield call(
      api.sendPlanPreview,
      Number(action.studyPlan),
      Number(action.rate),
      action.rank,
      action.dutyState,
      Number(action.week),
      action.emails,
    );
    yield put(
      Notifications.show(
        { title: 'Done!', message: 'Plan sent successfully!', autoDismiss: 1 },
        'success',
      ),
    );
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  }
}

/**
 * DEFAUL EXPORT
 */
export default function* sagas() {
  yield all([
    loadStudyPlansSaga(),
    createStudyPlanSaga(),
    editStudyPlanSaga(),
    updateStartDateSaga(),
    updateStudyPlanEmailSaga(),
    loadStudyPlansUsersSaga(),
    updateStudyPlanUserSaga(),
    deleteStudyPlanUserSaga(),
    sendStudyPlanPreviewSaga(),
  ]);
}

function showNotificationErrorFromException(exception) {
  const message = getRequestErrorMessage(exception);
  return Notifications.show({ title: 'Ops!', message }, 'error');
}
