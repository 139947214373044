import { connect } from 'react-redux';
import { getRates } from '../selectors';
import { getIsWaiting } from '../../Users/selectors';
import ChangeExam from '../../../commonComponents/Modals/ChangeExam/ChangeExam';
import { loadRates } from '../actionsSagas';

const mapStateToProps = (state, ownProps) => {
  return {
    rates: getRates(state),
    user: ownProps.user,
    onSubmit: ownProps.onSubmit,
    waiting: getIsWaiting(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoad: () => {
      dispatch(loadRates());
    },
  };
};

const ChangeExamContainer = connect(mapStateToProps, mapDispatchToProps)(
  ChangeExam
);

export default ChangeExamContainer;
