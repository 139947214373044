import Notifications from 'react-notification-system-redux';
import { connect } from 'react-redux';

import addAbilityToOpenModal from '../../../../utilities/modalHOC';
import { onEditAssignment } from '../../../Assignments/actionsSagas';
import { getSelectedAssignment } from '../../../Assignments/selectors';
import { getUser } from '../../../Login/selectors';
import {
  setCreateAnother,
  setIsCreatingAiQuestion,
  setIsRefreshForm,
  setIsSaveQuestion,
  setPublishedQuestion,
  setShowModalQuestionHistory,
} from '../../actions';
import {
  checkQuestionSpelling,
  createQuestion,
  createUserCustomPrompt,
  deleteQuestionSagas,
  editQuestionSagas,
  getQuestionHistory,
  getUserCustomPrompt,
  ImproveQuestionWithAi,
  loadPublishedQuestion,
  restoreQuestionSagas,
  updateIncludeNotesInSuggestion,
} from '../../actionsSagas';
import {
  getChapter,
  getCreateAnother,
  getCreatingAiQuestion,
  getEditPermission,
  getIsRefreshForm,
  getIsSaveQuestion,
  getPublishedQuestion,
  getSelectedQuestion,
  getSelectedQuestionHistory,
  getShowQuestionHistoryModal,
  getUserCustomPromptSelector,
} from '../../selectors';
import QuestionDetails from './QuestionDetails';

const mapStateToProps = (state, ownProps) => ({
  chapter: getChapter(state),
  question: getSelectedQuestion(state),
  questionHistory: getSelectedQuestionHistory(state),
  showModalHistoryQuestion: getShowQuestionHistoryModal(state),
  isSaveQuestion: getIsSaveQuestion(state),
  createAnother: getCreateAnother(state),
  isRefreshForm: getIsRefreshForm(state),
  assignment: getSelectedAssignment(state),
  publishedQuestion: getPublishedQuestion(state),
  editPermission: getEditPermission(state),
  isCreatingAiQuestion: getCreatingAiQuestion(state),
  userCustomPrompts: getUserCustomPromptSelector(state),
  user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  onEditQuestion: (question, assignment, showWorkInProgressAlert) =>
    dispatch(editQuestionSagas(question, assignment, showWorkInProgressAlert)),
  onCreateQuestion: (question, assignment) => {
    dispatch(createQuestion(question, assignment));
  },
  setIsSaveQuestion: () => {
    dispatch(setCreateAnother(false));
    dispatch(setIsSaveQuestion(false));
  },
  setIsRefreshForm: (isRefreshForm) =>
    dispatch(setIsRefreshForm(isRefreshForm)),
  checkQuestionSpelling: (question) =>
    dispatch(checkQuestionSpelling(question)),
  getQuestionHistory: (questionId) => dispatch(getQuestionHistory(questionId)),
  setShowModalQuestionHistory: (value) => {
    dispatch(setShowModalQuestionHistory(value));
  },
  deleteQuestion: (question, assignment) =>
    dispatch(deleteQuestionSagas(question, assignment)),
  loadPublishedQuestion: (question) => {
    if (question) {
      dispatch(loadPublishedQuestion(question));
    } else {
      dispatch(setPublishedQuestion({}));
    }
  },
  onEditAssignment: (assignment) =>
    dispatch(onEditAssignment(assignment, false, false, true)),
  restoreQuestion: (question, assignment) =>
    dispatch(restoreQuestionSagas(question, assignment)),
  showWarning: (title, message) =>
    dispatch(Notifications.warning({ title, message }, 'failure')),
  setIsCreatingAiQuestion: (value) => dispatch(setIsCreatingAiQuestion(value)),
  createUserCustomPrompt: (customPrompt, chapterId) =>
    dispatch(createUserCustomPrompt(customPrompt, chapterId)),
  getUserCustomPrompt: (chapterId) => dispatch(getUserCustomPrompt(chapterId)),
  improveQuestionWithAi: (questionId, chapterId) =>
    dispatch(ImproveQuestionWithAi(questionId, chapterId)),
  updateIncludeNotesInSuggestion: (questionId, value) =>
    dispatch(updateIncludeNotesInSuggestion(questionId, value)),
});

export default addAbilityToOpenModal(
  connect(mapStateToProps, mapDispatchToProps)(QuestionDetails),
);
