import { connect } from 'react-redux';
import CreateCouponReferral from './CreateCouponReferral';
import {
  setLoadingCoupons,
  setBadRequest,
} from '../../../components/Coupons/actions';
import {
  getLoadingCoupons,
  getBadRequest,
  getWaitingResponse,
} from '../../../components/Coupons/selectors';

const mapStateToProps = state => ({
  loadingCoupons: getLoadingCoupons(state),
  badRequest: getBadRequest(state),
  waitingResponse: getWaitingResponse(state),
});

const mapDispatchToProps = dispatch => ({
  setLoadingCoupons: value => dispatch(setLoadingCoupons(value)),
  setBadRequest: value => dispatch(setBadRequest(value)),
});

const CreateCouponReferralContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateCouponReferral);

export default CreateCouponReferralContainer;
