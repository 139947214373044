export const UPDATECHECKEDSUBTOPICS = 'bibliographies/UPDATECHECKEDSUBTOPICS';
export const UPDATECHECKEDREFERENCES = 'bibliographies/UPDATECHECKEDREFERENCES';
export const REFERENCESRECEIVED = 'bibliographies/REFERENCESRECEIVED';
export const REFERENCECREATION = 'bibliographies/REFERENCECREATION';
export const CHAPTERCREATION = 'bibliographies/CHAPTERCREATION';
export const UPDATEDUTYSTATE = 'bibliographies/UPDATEDUTYSTATE';
export const UPDATERANK = 'bibliographies/UPDATERANK';
export const SUBMITBIB = 'bibliographies/SUBMITBIB';
export const EXAMRECEIVED = 'bibliographies/EXAMRECEIVED';
export const UPDATELOADINGEXAM = 'bibliographies/UPDATELOADINGEXAM';
export const UPDATECYCLE = 'bibliographies/UPDATECYCLE';
export const UPDATE_REFERENCE = 'bibliographies/UPDATE_REFERENCE';
export const DELETE_REFERENCE = 'bibliographies/DELETE_REFERENCE';
export const DELETE_CHAPTER_ACTION = `bibliographies/DELETE_CHAPTER_ACTION`;
export const UPDATE_CHAPTER_ACTION = `bibliographies/UPDATE_CHAPTER_ACTION`;
export const UPDATE_CHAPTER = `bibliographies/UPDATE_CHAPTER`;
export const UPDATELOADINGGRID = `bibliographies/UPDATELOADINGGRID`;
export const LOADREFERENCESDUPLICATECHAPTER = `bibliographies/LOADREFERENCESDUPLICATECHAPTER`;
export const ARCHIVED = `bibliographies/ARCHIVED`;
export const UPDATE_CHAPTER_DELETE_PDF = `bibliographies/UPDATE_CHAPTER_DELETE_PDF`;
export const UPDATE_REFERENCE_DELETE_PDF = `bibliographies/UPDATE_REFERENCE_DELETE_PDF`;
export const RELOCATE_CHAPTERS = `bibliographies/RELOCATE_CHAPTERS`;
export const UPDATEDUTYSTATES = `bibliographies/UPDATEDUTYSTATES`;
export const UPDATERANKS = `bibliographies/UPDATERANKS`;
export const ASSIGN_WRITERS_CHAPTER_ACTION = `bibliographies/ASSIGN_WRITERS_CHAPTER_ACTION`;
export const UPDATE_CHAPTER_INFO = `bibliographies/UPDATE_CHAPTER_INFO`;
export const SET_CHAPTER_BOOKS_PAGE = `bibliographies/SET_CHAPTER_BOOKS_PAGE`;
export const DELETE_CHAPTER_BOOKS_PAGE = `bibliographies/DELETE_CHAPTER_BOOKS_PAGE`;
export const UPDATE_SELECTED_TAB = `bibliographies/UPDATE_SELECTED_TAB`;
export const UPDATE_SELECTED_LIST_TAB = `bibliographies/UPDATE_SELECTED_LIST_TAB`;
export const SEARCH_REFS_RECEIVED = `bibliographies/SEARCH_REFS_RECEIVED`;
export const SEARCH_DUPLICATE_CHAPTER = `bibliographies/SEARCH_DUPLICATE_CHAPTER`;
export const REFERENCE_SELECT_DUPLICATE_CHAPTER = `bibliographies/REFERENCE_SELECT_DUPLICATE_CHAPTER`;
export const REFS_RECEIVED = `bibliographies/REFS_RECEIVED`;
export const SET_HAS_MORE_DATA = `bibliographies/SET_HAS_MORE_DATA`;
export const SET_REFERENCES = `bibliographies/SET_REFERENCES`;
export const UPDATE_LOADING_INFINITE_SCROLL = `bibliographies/UPDATE_LOADING_INFINITE_SCROLL`;
export const UPDATE_BIB_LOGS = `bibliographies/UPDATE_BIB_LOGS`;
export const SET_RATING_EXAM_QUESTIONS = `bibliographies/SET_RATING_EXAM_QUESTIONS`;
export const SET_RATE_BIBLIOPGRAPHY_JSON = `bibliographies/SET_RATE_BIBLIOPGRAPHY_JSON`;
export const SET_RATE_CHAPTER_QUESTION_REPORT = `bibliographies/SET_RATE_CHAPTER_QUESTION_REPORT`;
export const SET_PAGINATION_PAGE = `bibliographies/SET_PAGINATION_PAGE`;
export const ADD_ASSIGNMENT_TO_BOOK = 'books/ADD_ASSIGNMENT_TO_BOOK';
export const UPDATE_CHAPTER_QUESTIONS_QTY =
  'bibliographies/UPDATE_CHAPTER_QUESTIONS_QTY';
export const SET_REFERENCE_BY_ID = 'bibliographies/SET_REFERENCE_BY_ID';
export const SET_IS_UNDO_CHECKED = 'bibliographies/SET_IS_UNDO_CHECKED';
export const SET_IS_REVERT_CHECKED = 'bibliographies/SET_IS_REVERT_CHECKED';
export const SET_UNDO_STATE = 'bibliographies/SET_UNDO_STATE';
export const SET_ACTUAL_STATE = 'bibliographies/SET_ACTUAL_STATE';
export const LOAD_ADD_CHAPTER = 'bibliographies/LOAD_ADD_CHAPTER';
export const RATE_SELECT_DUPLICATE_CHAPTER = `bibliographies/RATE_SELECT_DUPLICATE_CHAPTER`;
