import React from 'react';
import Button from 'react-bootstrap-button-loader';

import './pdfRequired.css';

class PdfRequired extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      waiting: false,
    };
  }

  render() {
    const { closeModal, editChapter } = this.props;
    return (
      <div className="modal-create-AI-question-container">
        <div className="card-block mb-0">
          <div className="title-container">
            Currently, questions cannot be created by GPT/AI
            <i
              className="fa fa-times fa-xs"
              onClick={() => {
                if (!this.state.wating) closeModal();
              }}
            />
            <hr className="hrModal" />
          </div>
          <div>
            To create GPT/AI questions, the chapter requires a PDF. Please
            upload a PDF to the chapter and try again.
          </div>
          <div className="bottom-buttons-new">
            <Button
              id="btn-success"
              className="btn btn-success"
              onClick={() => editChapter()}
            >
              Edit chapter
            </Button>
            <Button
              className="btn btn-secondary"
              onClick={() => closeModal()}
              disabled={this.state.wating}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default PdfRequired;
