import * as type from './actionTypes';

export const setStudyPrograms = studyPrograms => ({
  type: type.SET_SPS,
  studyPrograms,
});

export const setWaiting = value => ({
  type: type.SET_WAITING,
  value,
});
