import { connect } from 'react-redux';
import { getDefinitions } from '../selectors';
import DefinitionsList from '../../../commonComponents/DefinitionsList/DefinitionsList';
import Notifications from 'react-notification-system-redux';
import {
  loadDefinitionsByReference,
  removeDefinitions,
  editDefinition,
  createDefinition,
} from '../actionsSagas';
import { clearDefinitions } from '../actions';
import { getEditPermission } from '../../Questions/selectors';

const mapStateToProps = (state, ownProps) => {
  return {
    list: getDefinitions(state),
    reference: ownProps.reference,
    closeModal: ownProps.closeModal,
    questionText: ownProps.questionText,
    readOnly: ownProps.readOnly,
    noRecordsText: ownProps.noRecordsText,
    editPermission: getEditPermission(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoad: reference => {
      dispatch(loadDefinitionsByReference(reference));
    },
    onDelete: id => {
      dispatch(removeDefinitions(id));
    },
    onEdit: item => {
      dispatch(editDefinition(item));
    },
    onAdd: item => {
      dispatch(createDefinition(item));
    },
    clearDefinitions: () => {
      dispatch(clearDefinitions());
    },
    showNotification: (title, message, type) => {
      dispatch(
        Notifications.show(
          {
            title: title,
            message,
          },
          type,
        ),
      );
    },
  };
};

const DefinitionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefinitionsList);

export default DefinitionsContainer;
