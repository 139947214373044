import * as t from './actionsTypes';

export const setRate = (rate) => ({
  type: t.SET_RATE,
  rate,
});

export const setAllRates = (rates) => ({
  type: t.SET_ALL_RATES,
  rates,
});

export const setUserSubmittedQuestions = (questions) => ({
  type: t.SET_USER_SUBMITTED_QUESTIONS,
  questions,
});

export const setUserFavoriteQuestions = (questions, isFirst) => ({
  type: t.SET_USER_FAVORITE_QUESTIONS,
  questions,
  isFirst,
});

export const setIsLoadingFavoriteQuestions = (status) => ({
  type: t.SET_IS_LOADING_FAVORITE_QUESTIONS,
  status,
});

export const setIsLoadingSubmittedQuestions = (status) => ({
  type: t.SET_IS_LOADING_SUBMITTED_QUESTIONS,
  status,
});
