import Notifications from 'react-notification-system-redux';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import * as api from '../../utilities/ServiceManager';
import {
  setAllRates,
  setIsLoadingFavoriteQuestions,
  setIsLoadingSubmittedQuestions,
  setUserFavoriteQuestions,
  setUserSubmittedQuestions,
} from './actions';
import {
  APPROVE_SUBMITTED_QUESTION,
  DELETE_SUBMITTED_QUESTION,
  EDIT_SUBMITTED_QUESTION,
  GET_ALL_RATES,
  GET_FAVORITE_QUESTIONS,
  GET_USER_DELETED_SUBMITTED_QUESTIONS,
  GET_USER_SUBMITTED_QUESTIONS,
  REJECT_SUBMITTED_QUESTION,
  REMOVE_FAVORITE_QUESTION,
  UNDELETE_SUBMITTED_QUESTION,
} from './actionsTypes';

function* getAllRates() {
  yield takeEvery(GET_ALL_RATES, getAllRatesSaga);
}

function* getAllRatesSaga() {
  try {
    const response = yield call(api.getAllRatesNotifications);
    yield put(setAllRates(response));
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Done!', message: error.message }, 'error'),
    );
  }
}

function* getUserSubmittedQuestions() {
  yield takeLatest(GET_USER_SUBMITTED_QUESTIONS, getUserSubmittedQuestionsSaga);
}

function* getUserSubmittedQuestionsSaga(action) {
  try {
    yield put(setIsLoadingSubmittedQuestions(true));
    const resp = yield call(api.getUserSubmittedQuestions, action.rateId);
    yield put(setUserSubmittedQuestions(resp));
  } catch (error) {
    yield put(
      Notifications.show(
        { title: 'Error', message: error.response.data },
        'error',
      ),
    );
  } finally {
    yield put(setIsLoadingSubmittedQuestions(false));
  }
}

function* getUserDeletedSubmittedQuestions() {
  yield takeLatest(
    GET_USER_DELETED_SUBMITTED_QUESTIONS,
    getUserDeletedSubmittedQuestionsSaga,
  );
}

function* getUserDeletedSubmittedQuestionsSaga(action) {
  try {
    yield put(setIsLoadingSubmittedQuestions(true));
    const resp = yield call(
      api.getUserDeletedSubmittedQuestions,
      action.rateId,
    );
    yield put(setUserSubmittedQuestions(resp));
  } catch (error) {
    yield put(
      Notifications.show(
        { title: 'Error', message: error.response.data },
        'error',
      ),
    );
  } finally {
    yield put(setIsLoadingSubmittedQuestions(false));
  }
}

function* getFavoriteQuestions() {
  yield takeLatest(GET_FAVORITE_QUESTIONS, getFavoriteQuestionsSaga);
}

function* getFavoriteQuestionsSaga(action) {
  try {
    yield put(setIsLoadingFavoriteQuestions(true));
    const response = yield call(
      api.getFavoriteQuestions,
      action.rateId,
      action.limit,
      action.skip,
    );
    const isFirst = action.skip === 0;
    yield put(setUserFavoriteQuestions(response, isFirst));

    if (response.length === 0 && !isFirst) {
      yield put(
        Notifications.show(
          { title: 'Done!', message: 'Loaded all questions' },
          'success',
        ),
      );
    }
  } catch (error) {
    yield put(
      Notifications.show(
        { title: 'Error', message: error?.response?.data },
        'error',
      ),
    );
  } finally {
    yield put(setIsLoadingFavoriteQuestions(false));
  }
}

function* approveSubmittedQuestion() {
  yield takeLatest(APPROVE_SUBMITTED_QUESTION, approveSubmittedQuestionSaga);
}

function* approveSubmittedQuestionSaga(action) {
  try {
    yield put(setIsLoadingSubmittedQuestions(true));
    const response = yield call(
      api.approveSubmittedQuestion,
      action.questionId,
      action.rateId,
      action.timestamp,
    );
    yield put(setUserSubmittedQuestions(response));
    yield put(
      Notifications.show(
        { title: 'Done!', message: 'Question approved successfully.' },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show(
        { title: 'Error', message: error.response.data },
        'error',
      ),
    );
  } finally {
    yield put(setIsLoadingSubmittedQuestions(false));
  }
}

function* deleteSubmittedQuestion() {
  yield takeLatest(DELETE_SUBMITTED_QUESTION, deleteSubmittedQuestionSaga);
}

function* deleteSubmittedQuestionSaga(action) {
  try {
    yield put(setIsLoadingSubmittedQuestions(true));
    const response = yield call(
      api.deleteSubmittedQuestion,
      action.questionId,
      action.questionRate,
      action.timestamp,
    );
    yield put(setUserSubmittedQuestions(response));
    yield put(
      Notifications.show(
        { title: 'Done!', message: 'Question deleted successfully.' },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show(
        { title: 'Error', message: error.response.data },
        'error',
      ),
    );
  } finally {
    yield put(setIsLoadingSubmittedQuestions(false));
  }
}

function* undeleteSubmittedQuestion() {
  yield takeLatest(UNDELETE_SUBMITTED_QUESTION, undeleteSubmittedQuestionSaga);
}

function* undeleteSubmittedQuestionSaga(action) {
  try {
    yield put(setIsLoadingSubmittedQuestions(true));
    const resp = yield call(
      api.undeleteSubmittedQuestion,
      action.questionId,
      action.userId,
      action.updatedAt,
      action.rateId,
    );
    yield put(setUserSubmittedQuestions(resp));
    yield put(
      Notifications.show(
        { title: 'Done!', message: 'Question recovered successfully.' },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show(
        { title: 'Error', message: error.response.data },
        'error',
      ),
    );
  } finally {
    yield put(setIsLoadingSubmittedQuestions(false));
  }
}

function* rejectSubmittedQuestion() {
  yield takeLatest(REJECT_SUBMITTED_QUESTION, rejectSubmittedQuestionSaga);
}

function* rejectSubmittedQuestionSaga(action) {
  try {
    yield put(setIsLoadingSubmittedQuestions(true));
    const response = yield call(
      api.rejectSubmittedQuestion,
      action.questionId,
      action.rateId,
      action.timestamp,
    );
    yield put(setUserSubmittedQuestions(response));
    yield put(
      Notifications.show(
        { title: 'Done!', message: 'Question rejected successfully.' },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show(
        { title: 'Error', message: error.response.data },
        'error',
      ),
    );
  } finally {
    yield put(setIsLoadingSubmittedQuestions(false));
  }
}

function* editSubmittedQuestion() {
  yield takeLatest(EDIT_SUBMITTED_QUESTION, editSubmittedQuestionSaga);
}

function* editSubmittedQuestionSaga(action) {
  try {
    yield put(setIsLoadingSubmittedQuestions(true));
    const response = yield call(
      api.editSubmittedQuestion,
      action.questionId,
      action.question,
      action.rateId,
      action.timestamp,
    );
    yield put(setUserSubmittedQuestions(response));
    yield put(
      Notifications.show(
        { title: 'Done!', message: 'Question edited successfully.' },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show(
        { title: 'Error', message: error.response.data },
        'error',
      ),
    );
  } finally {
    yield put(setIsLoadingSubmittedQuestions(false));
  }
}

function* removeFavoriteQuestion() {
  yield takeLatest(REMOVE_FAVORITE_QUESTION, removeFavoriteQuestionSaga);
}

function* removeFavoriteQuestionSaga(action) {
  try {
    yield put(setIsLoadingFavoriteQuestions(true));
    const response = yield call(
      api.removeFavoriteQuestion,
      action.rateId,
      action.questions,
    );
    yield put(setUserFavoriteQuestions(response));
    yield put(
      Notifications.show(
        { title: 'Done!', message: 'Favorite question removed successfully.' },
        'success',
      ),
    );
  } catch (error) {
    yield put(
      Notifications.show({ title: 'Error', message: error.message }, 'error'),
    );
  } finally {
    yield put(setIsLoadingFavoriteQuestions(false));
  }
}

export default function* sagas() {
  yield all([
    getAllRates(),
    getUserSubmittedQuestions(),
    getUserDeletedSubmittedQuestions(),
    getFavoriteQuestions(),
    approveSubmittedQuestion(),
    deleteSubmittedQuestion(),
    rejectSubmittedQuestion(),
    editSubmittedQuestion(),
    removeFavoriteQuestion(),
    undeleteSubmittedQuestion(),
  ]);
}
