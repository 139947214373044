import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import Spinner from '../../../../commonComponents/Spinner/Spinner';

export default class ActiveSubscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      csvCompleted: false,
      isLoading: false,
      step: 0,
      asking: 'active',
      clearReport: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.csvDataSubsActive) {
      window.open(this.props.automaticSubscriptionsReport);
      this.props.clearCsv();
    }
    if (
      this.props.activeSubscriptionsError !==
        prevProps.activeSubscriptionsError &&
      this.props.activeSubscriptionsError
    ) {
      this.setState({ isLoading: false });
    }
    if (!this.props.hasMore && prevProps.hasMore !== this.props.hasMore) {
      this.setState({ csvCompleted: true, isLoading: false });
      document.getElementById('generate-report-download').click();
      this.props.clearPropsSubscriptions();
    } else if (
      this.props.hasMore &&
      this.props.lastCustomer !== prevProps.lastCustomer
    ) {
      this.setState({ step: this.state.step + 1 });
      this.props.getActiveSubscriptions(
        this.props.lastCustomer,
        this.props.askingFor,
      );
      if (
        this.props.askingFor === 'trialing' &&
        prevProps.askingFor === 'active'
      ) {
        this.setState({ step: 0, asking: 'trialing' });
      }
    }
  }

  componentDidMount() {
    this.props.latestCsvCreationDateSubsActive();
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <Spinner
            text={`Step ${this.state.step}: searching ${this.state.asking}`}
          />
        ) : (
          <Row>
            <Col className="d-block col-6">
              <div
                className="whiteButtons manuallyReportButtonWidth"
                onClick={() => {
                  this.props.downloadActiveSubscriptions();
                }}
              >
                Download Latest Report ({this.props.latestCsvDate})
              </div>
            </Col>
            <Col className="d-block col-6">
              <div
                className="whiteButtons"
                onClick={() => {
                  this.props.getActiveSubscriptions(
                    this.props.lastCustomer,
                    'active',
                  ),
                    this.setState({ isLoading: true });
                }}
              >
                Generate Report
              </div>
            </Col>
          </Row>
        )}
        <div style={{ display: 'none' }}>
          <CSVLink
            id="generate-report-download"
            filename={`getActiveUsers${Date.now()}.csv`}
            data={this.props.CSVData}
          >
            Generate Report
          </CSVLink>
        </div>
      </div>
    );
  }
}
