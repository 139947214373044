import { List, Map } from 'immutable';

import ArrayUtils from '../../lib/ArrayUtils';
import * as tsReferences from '../References/actionTypesSagas';
import * as t from './actionTypes';
import * as ts from './actionTypesSagas';

const initialState = Map({
  checkedSubtopics: [],
  checkedReferences: [],
  references: List([]),
  chapters: List([]),
  dutyState: 'Regular',
  rank: 'E4',
  loadingExam: false,
  loadingGrid: false,
  loadingReferences: null,
  cycle: {},
  ranks: [{ name: 'E4' }, { name: 'E5' }, { name: 'E6' }, { name: 'E7' }],
  dutyStates: [
    { name: 'Regular' },
    { name: 'Substitute' },
    { name: 'Reserve' },
  ],
  selectedTab: 'Subtopics',
  selectedListTab: 'Rating Exam',
  originalCheckedSubtopics: [],
  originalCheckedReferences: [],
  hasMoreData: true,
  page: 0,
  loadingInfiniteScroll: false,
  bibliographyLogs: null,
  ratingExamQuestions: undefined,
  bibliographyJson: undefined,
  reportJson: undefined,
  isUndoChecked: true,
  isRevertChecked: true,
  undoState: null,
  actualState: null,
  referencesDuplicateChapter: [],
  referenceIdSelect: null,
  loadAddChapter: false,
  rateIdSelect: null,
});

const updateChapter = (chapterList, updatedChapter) => {
  let incrementPositionIndex = false;
  let decrementPositionIndex = false;
  let actualPosition;
  const chaptersListUpdated = chapterList.map((chapter) => {
    if (chapter.id === updatedChapter.id) {
      actualPosition = chapter.presentationIndex;
      if (chapter.presentationIndex !== updatedChapter.presentationIndex) {
        if (updatedChapter.presentationIndex < chapter.presentationIndex) {
          incrementPositionIndex = true;
        } else {
          decrementPositionIndex = true;
        }
      }
      const returnedValue = updatedChapter;
      returnedValue.questionCount = updatedChapter.questionCount
        ? updatedChapter.questionCount
        : updatedChapter.questions
        ? updatedChapter.questions.length
        : 0;
      returnedValue.primaryWriter = chapter.primaryWriter;
      returnedValue.writers = chapter.writers;
      returnedValue.subtopicCount =
        updatedChapter.subtopicCount !== undefined
          ? updatedChapter.subtopicCount
          : chapter.subtopicCount;
      return returnedValue;
    }
    return chapter;
  });
  return chaptersListUpdated.map((chapter) => {
    if (
      incrementPositionIndex &&
      chapter.presentationIndex >= updatedChapter.presentationIndex &&
      chapter.presentationIndex < actualPosition &&
      chapter.id !== updatedChapter.id &&
      chapter.reference === updatedChapter.reference
    ) {
      const auxChapterUpdatePosition = chapter;
      auxChapterUpdatePosition.presentationIndex += 1;
      return auxChapterUpdatePosition;
    }
    if (
      decrementPositionIndex &&
      chapter.presentationIndex > actualPosition &&
      chapter.presentationIndex <= updatedChapter.presentationIndex &&
      chapter.id !== updatedChapter.id &&
      chapter.reference === updatedChapter.reference
    ) {
      const auxChapterUpdatePositionDec = chapter;
      auxChapterUpdatePositionDec.presentationIndex -= 1;
      return auxChapterUpdatePositionDec;
    }
    return chapter;
  });
};

export default function (state = initialState, action) {
  switch (action.type) {
    case t.UPDATELOADINGEXAM:
      return state.set('loadingExam', action.status);

    case t.SET_RATING_EXAM_QUESTIONS:
      if (action.isReplace) {
        let questions = state.get('ratingExamQuestions') || [];
        const rate = action.ratingExamQuestions[0].rate;
        questions = questions.filter((q) => q.rate !== rate).slice();
        return state.set(
          'ratingExamQuestions',
          questions.concat(action.ratingExamQuestions),
        );
      }
      if (action.isAdd) {
        const questions = state.get('ratingExamQuestions') || [];
        return state.set(
          'ratingExamQuestions',
          questions.concat(action.ratingExamQuestions),
        );
      }
      return state.set('ratingExamQuestions', action.ratingExamQuestions);

    case t.UPDATE_BIB_LOGS:
      return state.set('bibliographyLogs', action.bibliographyLogs);

    case t.UPDATERANKS:
      return state.set('ranks', action.payload);

    case t.UPDATEDUTYSTATES:
      return state.set('dutyStates', action.payload);

    case t.UPDATE_SELECTED_TAB:
      return state.set('selectedTab', action.selectedTab);

    case t.UPDATE_SELECTED_LIST_TAB:
      return state.set('selectedListTab', action.selectedListTab);

    case t.UPDATELOADINGGRID:
      return state.set('loadingGrid', action.status);

    case t.LOADREFERENCESDUPLICATECHAPTER:
      return state.set('loadingReferences', action.status);

    case t.UPDATE_LOADING_INFINITE_SCROLL:
      return state.set('loadingInfiniteScroll', action.status);

    case t.SET_REFERENCES:
      return state.set('references', action.references);

    case t.SET_IS_UNDO_CHECKED:
      return state.set('isUndoChecked', action.payload);

    case t.SET_IS_REVERT_CHECKED:
      return state.set('isRevertChecked', action.payload);

    case t.SET_UNDO_STATE:
      return state.set('undoState', action.payload);

    case t.SET_ACTUAL_STATE:
      return state.set('actualState', action.payload);

    case t.SET_REFERENCE_BY_ID:
      return state
        .set('references', List([action.reference]))
        .set('page', 0)
        .set('hasMoreData', false);

    case t.SEARCH_REFS_RECEIVED: {
      const data = action.payload;
      let refsReceived = state.get('references').concat(data);
      let page;
      page = refsReceived.size
        ? parseInt(refsReceived.size / data.length, 10)
        : 1;

      refsReceived = page === 1 ? data : refsReceived;
      if (action.isInfiniteList) {
        page = action.page;
      }
      return state.set('references', List(refsReceived)).set('page', page);
    }

    case t.SEARCH_DUPLICATE_CHAPTER: {
      return state.set('referencesDuplicateChapter', action.refs);
    }

    case t.REFERENCE_SELECT_DUPLICATE_CHAPTER: {
      return state.set('referenceIdSelect', action.referenceId);
    }

    case t.RATE_SELECT_DUPLICATE_CHAPTER: {
      return state.set('rateIdSelect', action.rateId);
    }

    case t.REFS_RECEIVED: {
      const data = action.payload;
      let refsReceived = state.get('references').concat(data);
      const page = refsReceived.size
        ? parseInt(refsReceived.size / data.length, 10) - 1
        : 0;
      refsReceived = page === 0 ? data : refsReceived;
      return state.set('references', List(refsReceived)).set('page', page);
    }

    case t.SET_HAS_MORE_DATA:
      return state.set('hasMoreData', action.payload);

    case t.REFERENCESRECEIVED:
      const stateChapters = [];
      action.payload.map((reference) => {
        reference.chapters.map((chapter) => {
          if (chapter.id) {
            stateChapters.push(chapter);
          }
          return chapter;
        });
        return reference;
      });
      return state
        .set('references', List(action.payload))
        .set('chapters', List(stateChapters))
        .set('loadingGrid', false);

    case t.UPDATECHECKEDSUBTOPICS:
      return state.set('checkedSubtopics', action.payload);

    case t.UPDATECHECKEDREFERENCES:
      return state.set('checkedReferences', action.payload);

    case t.UPDATEDUTYSTATE:
      return state.set('dutyState', action.payload);

    case t.UPDATERANK:
      return state.set('rank', action.payload);

    case t.UPDATECYCLE:
      return state.set('cycle', action.payload);

    case t.REFERENCECREATION:
      const newRef = action.payload;

      // Add new property to identify new item on the list for scrolling
      newRef.isNew = true;

      const refs = state.get('references').push(newRef);
      const compareNames = ArrayUtils.propComparator('name');
      const newRefs = refs.slice().sort(compareNames);
      let chaptersState = state.get('chapters');

      if (!action.payload.actAsParent) {
        chaptersState = chaptersState.push(action.payload.chapters[0]);
      }
      return state.set('references', newRefs).set('chapters', chaptersState);

    case t.SUBMITBIB:
      if (action.payload.chapters) {
        return state.set('originalCheckedReferences', action.payload.chapters);
      }
      if (action.payload.subtopics) {
        return state.set('originalCheckedSubtopics', action.payload.subtopics);
      }
      return state;

    case t.EXAMRECEIVED:
      return state
        .set('loadingExam', false)
        .set(
          'checkedSubtopics',
          action.payload.subtopics.map((subtopic) => subtopic.id),
        )
        .set(
          'checkedReferences',
          action.payload.chapters.map((chapter) => chapter.id),
        )
        .set(
          'originalCheckedSubtopics',
          action.payload.subtopics.map((subtopic) => subtopic.id),
        )
        .set(
          'originalCheckedReferences',
          action.payload.chapters.map((chapter) => chapter.id),
        );

    case t.CHAPTERCREATION:
      let refUpdated = state
        .get('references')
        .find((ref) => ref.id === action.payload.reference);

      // This line below works for the case where a chapter will be duplicated in a different rate reference.
      if (refUpdated === undefined) refUpdated = action.payload.refToUpdate[0];

      refUpdated.chapters = refUpdated.chapters.map((chapter) => {
        if (chapter.presentationIndex >= action.payload.presentationIndex) {
          chapter.presentationIndex++;
        }
        return chapter;
      });
      refUpdated.chapters.splice(
        Number(action.payload.presentationIndex) - 1,
        0,
        action.payload,
      );
      refUpdated.chapters = refUpdated.chapters.slice(0);
      const refsUpdated = state.get('references').map((ref) => {
        if (ref.id === action.payload.reference) {
          return refUpdated;
        }
        return ref;
      });
      const chaptersAux = state.get('chapters');
      let chaptersUpdated = chaptersAux.push(action.payload);
      chaptersUpdated = orderChapters(chaptersUpdated);
      return state
        .set('references', refsUpdated)
        .set('chapters', chaptersUpdated);

    case t.ADD_ASSIGNMENT_TO_BOOK:
      return state.set(
        'references',
        state.get('references').map((ref) => {
          if (ref.id === action.assignment.chapter.reference.id) {
            ref.chapters = ref.chapters.map((chapter) => {
              if (chapter.id === action.assignment.chapter.id) {
                chapter.assignmentCreatedAt = action.assignment.createdAt;
                chapter.admins = action.assignment.assignedTo;
                chapter.admins.push(action.assignment.createdBy);
                chapter.assignmentPrimaryWriter =
                  action.assignment.primaryWriter.id;
                chapter.createdBy = action.assignment.createdBy.id;
              }
              return chapter;
            });
          }
          return ref;
        }),
      );

    case ts.LOAD_EXAM:
      return state
        .set('loadingExam', true)
        .set('checkedSubtopics', [])
        .set('checkedReferences', [])
        .set('originalCheckedSubtopics', [])
        .set('originalCheckedReferences', []);

    case t.SET_PAGINATION_PAGE:
      return state.set('page', action.page);

    case tsReferences.ALL_FOR_RATE_SAGAS:
      return state.set('loadingGrid', true);

    case tsReferences.ALL_ASSIGNED_SAGAS:
      return state.set('loadingGrid', true);

    case tsReferences.ASSIGNED_TO_BIB_SAGAS:
      return state.set('loadingGrid', true);

    case tsReferences.NOT_ASSIGNED_TO_BIB_SAGAS:
      return state.set('loadingGrid', true);

    case tsReferences.ARCHIVED_REFERENCES_SAGAS:
      return state.set('loadingGrid', true);

    case t.UPDATE_REFERENCE:
      const stateUpdated = state.get('references').map((reference) => {
        if (reference.id === action.payload.id) {
          return action.payload;
        }
        return reference;
      });
      return state.set('references', stateUpdated);

    case t.UPDATE_REFERENCE_DELETE_PDF:
      const stateUpdatedDeletePdf = state.get('references').map((reference) => {
        if (reference.id === action.payload.id) {
          const refAuxDeletePdf = reference;
          refAuxDeletePdf.pdfUrl = '';
          if (!refAuxDeletePdf.actAsParent) {
            refAuxDeletePdf.chapters[0].pdfUrl = '';
            refAuxDeletePdf.chapters[0].pdfPages = null;
          }
          return refAuxDeletePdf;
        }
        return reference;
      });

      let updatedChapters = state.get('chapters');
      if (!action.payload.actAsParent) {
        updatedChapters = state.get('chapters').map((chapter) => {
          const updatedChapter = chapter;
          if (chapter.id === action.payload.chapters[0].id) {
            updatedChapter.pdfUrl = '';
            updatedChapter.pdfPages = null;
          }

          return updatedChapter;
        });
      }

      const combinedState = state.set('references', stateUpdatedDeletePdf);
      return combinedState.set('chapters', updatedChapters);

    case t.UPDATE_CHAPTER_DELETE_PDF:
      return state.set(
        'chapters',
        state.get('chapters').map((chapter) => {
          if (chapter.id === action.payload.id) {
            const auxChapDeletePdf = chapter;
            auxChapDeletePdf.pdfUrl = '';
            auxChapDeletePdf.pdfPages = null;

            return auxChapDeletePdf;
          }
          return chapter;
        }),
      );

    case t.ARCHIVED:
      let stateUpdatedArchived = state.get('references');
      if (action.filter !== 'All') {
        stateUpdatedArchived = stateUpdatedArchived.filter(
          (reference) => reference.id !== action.payload.id,
        );
      } else {
        stateUpdatedArchived = stateUpdatedArchived.map((ref) => {
          if (ref.id === action.payload.id) {
            const auxRefArchived = ref;
            auxRefArchived.archived = !ref.archived;
            return auxRefArchived;
          }
          return ref;
        });
      }
      return state.set('references', stateUpdatedArchived);

    case t.DELETE_REFERENCE:
      return state
        .set(
          'references',
          state
            .get('references')
            .filter((reference) => reference.id !== action.payload),
        )
        .set(
          'chapters',
          state
            .get('chapters')
            .filter((chapter) => chapter.reference !== action.payload),
        );

    case t.DELETE_CHAPTER_ACTION:
      const stateChaptersAfterDelete = state.get('chapters').map((chapter) => {
        if (
          action.payload.referenceId === chapter.reference &&
          chapter.presentationIndex > action.payload.presentationIndex
        ) {
          const updatedPresentation = chapter;
          updatedPresentation.presentationIndex -= 1;
          return updatedPresentation;
        }
        return chapter;
      });
      return state.set(
        'chapters',
        stateChaptersAfterDelete.filter(
          (chapter) => chapter.id !== action.payload.chapterId,
        ),
      );

    case t.UPDATE_CHAPTER_ACTION:
      return state.set(
        'chapters',
        state.get('chapters').map((chapter) => {
          if (chapter.id === action.payload) {
            const aux = chapter;
            aux.questionCount = 0;
            aux.indicators.questions = 'red';
            return aux;
          }
          return chapter;
        }),
      );

    case t.RELOCATE_CHAPTERS:
      const presentationIndex1 = action.chapter1.presentationIndex;
      const presentationIndex2 = action.chapter2.presentationIndex;
      return state.set(
        'references',
        state.get('references').map((reference) => {
          if (reference.id === action.chapter1.reference) {
            reference.chapters = reference.chapters
              .map((chapter) => {
                if (chapter.id === action.chapter1.id) {
                  chapter.presentationIndex = presentationIndex2;
                }
                if (chapter.id === action.chapter2.id) {
                  chapter.presentationIndex = presentationIndex1;
                }
                return chapter;
              })
              .sort((a, b) => a.presentationIndex - b.presentationIndex);
          }
          return reference;
        }),
      );

    case t.UPDATE_CHAPTER:
      const referencesStateUpdated = state.get('references').map((ref) => {
        if (ref.id === action.payload.reference) {
          const newChapterList = updateChapter(ref.chapters, action.payload);
          ref.chapters = newChapterList;
        }
        return ref;
      });
      const stateChaptersUpdated = state.get('chapters').map((chapter) => {
        if (chapter.id === action.payload.id) {
          return action.payload;
        }
        return chapter;
      });
      return state
        .set('chapters', stateChaptersUpdated)
        .set('references', referencesStateUpdated);

    case t.SET_CHAPTER_BOOKS_PAGE:
      return state.set(
        'references',
        List(
          state.get('references').map((reference) => {
            if (reference.id === action.chapter.reference) {
              const newChapterList = updateChapter(
                reference.chapters,
                action.chapter,
              );
              reference.chapters = newChapterList.sort(
                (a, b) => a.presentationIndex - b.presentationIndex,
              );
            }
            return reference;
          }),
        ),
      );

    case t.DELETE_CHAPTER_BOOKS_PAGE:
      return state.set(
        'references',
        List(
          state.get('references').map((reference) => {
            reference.chapters = reference.chapters.filter(
              (chapter) => chapter.id !== action.chapter,
            );
            return reference;
          }),
        ),
      );

    case t.UPDATE_CHAPTER_INFO:
      const newChapterList = updateChapter(
        state.get('chapters'),
        action.payload,
      );
      return state.set('chapters', newChapterList);

    case t.ASSIGN_WRITERS_CHAPTER_ACTION:
      return state.set(
        'chapters',
        state.get('chapters').map((chapter) => {
          if (chapter.id === action.payload.id) {
            const auxChapter = chapter;
            auxChapter.primaryWriter = action.payload.primaryWriter;
            auxChapter.writers = action.payload.writers;
            return auxChapter;
          }
          return chapter;
        }),
      );
    case t.SET_RATE_BIBLIOPGRAPHY_JSON:
      return state.set('bibliographyJson', action.json);
    case t.SET_RATE_CHAPTER_QUESTION_REPORT:
      return state.set('reportJson', action.json);

    case t.UPDATE_CHAPTER_QUESTIONS_QTY: {
      const { questionsQty, referenceId, chapterId } = action;
      const referencesStateToModify = state
        .get('references')
        .map((reference) => {
          if (reference.id === referenceId) {
            reference.chapters = reference.chapters.map((chapter) => {
              if (chapter.id === chapterId) {
                return {
                  ...chapter,
                  questionCount: questionsQty,
                };
              }
              return chapter;
            });
          }
          return reference;
        });
      return state.set('references', referencesStateToModify);
    }
    case t.LOAD_ADD_CHAPTER:
      return state.set('loadAddChapter', action.value);
    default:
      return state;
  }
}

function orderChapters(chapters) {
  chapters.sort((a, b) => {
    if (a.presentationIndex === b.presentationIndex) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }
    return a.presentationIndex - b.presentationIndex;
  });
  return chapters;
}
