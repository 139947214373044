export const AssignmentsStatusFilters = ['Currently assigned', 'Completed'];
export const TablesStatusFilters = [
  'Not Started',
  'In progress',
  'Awaiting approval',
];
export const mapStatusByName = {
  'Not Started': 'Not_Started',
  'In progress': 'In_Progress',
  Submitted: 'Submitted',
  Completed: 'Completed',
  Published: 'Published',
};
export const getStatusByName = status => {
  return mapStatusByName[status];
};
