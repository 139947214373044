import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';

import { SimpleDatePicker } from '../../../commonComponents/Datepicker/Datepicker';
import {
  SimpleInput,
  SimpleTextarea,
} from '../../../commonComponents/Input/Input';

export default class CreateNewCoupon extends React.Component {
  state = {
    description: '',
    name: '',
    stripeCoupon: '',
    expiryAt: null,
    durationInMonths: 0,
    maxRedeptions: 0,
    percentageOff: 0,
    duration: 'once',
    selectedPlan: '',
    amountOff: 0,
    enabled: false,
    isError: false,
    required: false,
    isAmount: false,
    ispercentage: false,
    allPlans: false,
    anualPlan: false,
    semiPlan: false,
    mensualPlan: false,
    amountOrPercent: '',
    getCoupon: true,
    planRequired: false,
    existingStripeCode: 'create',
    couponSame: false,
    plansLoaded: false,
    newCustomers: false,
    existingCustomers: false,
    cuponsCustomers: { newCustomers: [], existingCustomers: [] },
  };

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (
      nextProps.plansSubscriptions &&
      nextProps.plansSubscriptions.length > 0 &&
      !this.state.plansLoaded
    ) {
      const plansInfo = [];
      const coupons = { ...this.state.cuponsCustomers };
      nextProps.plansSubscriptions.map((plan) => {
        plansInfo.push({
          name: plan.product.name,
          product: {
            name: plan.product.name,
          },
          id: plan.id,
          applyCoupon: false,
          amountOrPercent: 'amount',
          value: 0,
          duration: 'once',
          durationInMonths: 0,
          interval: plan.interval,
          intervalCount: plan.interval_count,
        });
      });
      coupons.newCustomers = [...plansInfo];
      coupons.existingCustomers = [...plansInfo];

      this.setState({
        cuponsCustomers: coupons,
        plansLoaded: true,
      });
    }
  }

  validate = () => {
    const plansCustomers = this.state.cuponsCustomers;

    const couponCreate = {
      description: this.state.description,
      name: this.state.name,
      expiryAt: this.state.expiryAt,
      maxRedeptions: this.state.maxRedeptions,
      enabled: this.state.enabled,
      plansSelect: plansCustomers,
      selectedPlan: this.state.selectedPlan,
      stripeCoupon: this.state.stripeCoupon,
      newCustomers: this.state.newCustomers,
      existingCustomers: this.state.existingCustomers,
    };
    const dateExpity = (couponCreate.expiryAt = moment(
      couponCreate.expiryAt,
    ).valueOf());
    const date = new Date();
    let planValidNew = true;

    let planValidExisiting = true;

    let validatePlanNewCusotmers = true;
    let validatePlanExisitingCusotmers = true;

    if (this.state.existingStripeCode === 'create') {
      if (this.state.existingCustomers && plansCustomers.existingCustomers) {
        planValidExisiting = this.validateAmountOrPercent(
          plansCustomers.existingCustomers,
        );
      }
      if (this.state.newCustomers && plansCustomers.newCustomers) {
        planValidNew = this.validateAmountOrPercent(
          plansCustomers.newCustomers,
        );
      }

      if (
        !this.state.name ||
        !this.state.expiryAt ||
        !this.state.maxRedeptions ||
        this.state.maxRedeptions < 0 ||
        dateExpity < date.getTime() ||
        this.state.planRequired ||
        !planValidNew ||
        !planValidExisiting ||
        (!this.state.newCustomers && !this.state.existingCustomers)
      ) {
        this.setState({ required: true });
      } else {
        couponCreate.expiryAt = dateExpity;
        this.props.createNewCoupon(couponCreate);
      }
    } else if (this.state.existingStripeCode === 'existingCoupon') {
      if (this.state.newCustomers && plansCustomers.existingCustomers) {
        validatePlanNewCusotmers = this.validatePlan(
          plansCustomers.existingCustomers,
        );
      }
      if (this.state.existingCustomers && plansCustomers.newCustomers) {
        validatePlanExisitingCusotmers = this.validatePlan(
          plansCustomers.newCustomers,
        );
      }
      if (
        !this.state.name ||
        !this.state.stripeCoupon ||
        (this.state.newCustomers && plansCustomers.newCustomers.length === 0) ||
        (this.state.existingCustomers &&
          plansCustomers.existingCustomers.length === 0) ||
        (!this.state.newCustomers && !this.state.existingCustomers)
      ) {
        this.setState({ required: true });
      } else {
        this.props.createNewCoupon(couponCreate);
      }
    }
  };

  validatePlan(planSelect) {
    const plans = [];
    for (let i = 0; i < planSelect.length; i++) {
      if (planSelect[i].applyCoupon) {
        this.setState({ planRequired: false });
        plans.push(planSelect[i]);
      }
    }
    if (plans.length === 0) {
      this.setState({ planRequired: true });
    }
    return plans;
  }

  validateAmountOrPercent(planSelect) {
    const planValid = this.validatePlan(planSelect);

    if (planValid.length !== 0) {
      for (let i = 0; i < planValid.length; i++) {
        if (planValid[i].amountOrPercent === 'percent') {
          if (
            !planValid[i].value ||
            planValid[i].value < 1 ||
            planValid[i].value > 100
          ) {
            return false;
          }
        }
        if (planValid[i].amountOrPercent === 'amount') {
          if (planValid[i].value < 1 || !planValid[i].value) {
            return false;
          }
        }

        if (
          planValid[i].durationInMonths <= 0 &&
          planValid[i].duration === 'repeating'
        ) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  createTypeValue(value) {
    this.setState({
      existingStripeCode: value,
    });
  }

  onChangePlans(plan, value, inputValue, appliedCouponTo) {
    const selectPlan =
      appliedCouponTo === 'new'
        ? this.state.cuponsCustomers.newCustomers
        : this.state.cuponsCustomers.existingCustomers;
    const coupons = this.state.cuponsCustomers;
    const planValue = selectPlan.map((planSelect) => {
      if (plan === planSelect.name) {
        if (inputValue === 'check') {
          return {
            ...planSelect,
            applyCoupon: value,
          };
        }
        if (inputValue === 'amountOrPercent') {
          return {
            ...planSelect,
            amountOrPercent: value,
          };
        }
        if (inputValue === 'valueAmountOrPercent') {
          return {
            ...planSelect,
            value: parseInt(value),
          };
        }
        if (inputValue === 'duration') {
          return {
            ...planSelect,
            duration: value,
          };
        }
        if (inputValue === 'durationInMonths') {
          return {
            ...planSelect,
            durationInMonths: parseInt(value),
          };
        }
      }
      return planSelect;
    });

    if (appliedCouponTo === 'new') coupons.newCustomers = [...planValue];
    if (appliedCouponTo === 'existing')
      coupons.existingCustomers = [...planValue];

    this.setState({
      cuponsCustomers: coupons,
    });
  }

  placeholderInput(value) {
    if (value === 'amount') {
      return 'Enter a number greater than 0';
    }
    return 'Enter a number from 1 to 100';
  }

  inputValid(applyCoupon, amountOrPercent, value, durationInMonths, duration) {
    if (
      applyCoupon &&
      amountOrPercent === 'percent' &&
      this.state.required &&
      (!value || value < 1 || value > 100)
    ) {
      return true;
    }
    if (
      applyCoupon &&
      amountOrPercent === 'amount' &&
      this.state.required &&
      (!value || value < 1)
    ) {
      return true;
    }
    if (
      applyCoupon &&
      durationInMonths <= 0 &&
      duration === 'repeating' &&
      this.state.required
    ) {
      return true;
    }
    return false;
  }

  inputRequired = () => <span className="requiredInput"> Required </span>;

  newCustomersOrExistingCustomer() {
    if (!this.state.newCustomers && !this.state.existingCustomers) return true;
    return false;
  }

  getCuponInputs = () => {
    const { coupon } = this.props;
    const date = new Date().getTime();
    const { expiryAt } = this.state;
    expiryAt ? moment(expiryAt).valueOf() : null;

    return (
      <div>
        <Row className="mt-2 centerItems">
          <Col md={4}>
            <span className="InputLebel">Name</span>
            <br />
            {this.state.required && !this.state.name && this.inputRequired()}
          </Col>
          <Col md={8} className="mt-2">
            <SimpleInput
              type="text"
              value={this.state.name}
              onChange={(e) => {
                this.setState({ name: e.target.value });
              }}
              error={
                this.state.errors && this.state.errors.name
                  ? this.state.errors.name
                  : null
              }
              disabled={!!coupon}
              errorValue={!!(this.state.required && this.state.name < 1)}
            />
          </Col>
        </Row>
        <Row className="centerItems">
          <Col md={4}>
            <span className="InputLebel">Description</span>
          </Col>
          <Col md={8}>
            <SimpleTextarea
              type="text"
              value={this.state.description}
              onChange={(e) => {
                this.setState({ description: e.target.value });
              }}
              error={
                this.state.errors && this.state.errors.description
                  ? this.state.errors.description
                  : null
              }
            />
          </Col>
        </Row>
        {this.state.existingStripeCode === 'create' && (
          <div>
            <Row className="centerItems">
              <Col md={4}>
                <span className="InputLebel">Max redemptions</span>
                <br />
                {this.state.required &&
                  this.state.maxRedeptions < 1 &&
                  this.inputRequired()}
              </Col>
              <Col md={8} className="mt-2">
                <SimpleInput
                  type="number"
                  numberType="number"
                  placeholder={
                    this.state.maxRedeptions
                      ? this.state.maxRedeptions
                      : 'Enter a number greater than 0'
                  }
                  onChange={(e) => {
                    this.setState({ maxRedeptions: parseInt(e.target.value) });
                  }}
                  errorValue={
                    !!(this.state.required && this.state.maxRedeptions < 1)
                  }
                />
              </Col>
            </Row>
            <Row className="centerItems">
              <Col md={4}>
                <span className="InputLebel">Expiry at</span>
                <br />
                {((this.state.required && expiryAt && expiryAt < date) ||
                  (!expiryAt && this.state.required)) && (
                  <span className="requiredInput"> Enter a valid date </span>
                )}
              </Col>
              <Col md={8}>
                <SimpleDatePicker
                  date={
                    this.state.expiryAt ? moment(this.state.expiryAt) : null
                  }
                  onChange={(expiryAt) => {
                    this.setState({ expiryAt });
                  }}
                  placeholderText="MM/DD/YYYY"
                />
              </Col>
            </Row>
          </div>
        )}
        <Row className="mt-2 mb-2">
          <Col md={4} className="centerItems ">
            <label htmlFor="EnabledCheck" className="InputLebel">
              Enabled
            </label>
          </Col>
          <Col md={8}>
            <input
              type="checkbox"
              value={this.state.enabled}
              onChange={(e) => {
                this.setState({ enabled: !this.state.enabled });
              }}
              id="EnabledCheck"
            />
          </Col>
        </Row>
        {this.state.required &&
          !this.state.newCustomers &&
          !this.state.existingCustomers && (
            <Row className="mt-2 mb-2">
              <Col md={12} className="centerItems ">
                <span className="messageRequired">
                  Choose to whom the coupon applies: new customers, existing
                  customers or both.
                </span>
              </Col>
            </Row>
          )}
      </div>
    );
  };

  renderSelectPlan(appliedCouponTo, plans) {
    return (
      <div>
        <Row>
          <Col md={9} className="ml-5">
            {Array.isArray(plans) &&
              plans.map((plan) => (
                <Col key={plan.id}>
                  <div className="inputCheckbox">
                    <input
                      type="checkbox"
                      value={plan.applyCoupon}
                      onChange={() => {
                        if (!plan.applyCoupon) {
                          this.setState({ planRequired: false });
                        }
                        this.onChangePlans(
                          plan.product.name,
                          !plan.applyCoupon,
                          'check',
                          appliedCouponTo,
                        );
                      }}
                      checked={plan.applyCoupon}
                      id={plan.id + appliedCouponTo}
                    />
                    <label
                      className="labelCheck"
                      htmlFor={plan.id + appliedCouponTo}
                    >
                      {plan.product.name}
                    </label>
                  </div>

                  {plan.applyCoupon &&
                    this.state.existingStripeCode === 'create' && (
                      <div>
                        <div>
                          <select
                            value={plan.amountOrPercent}
                            className="form-control mb-2"
                            onChange={(e) => {
                              this.onChangePlans(
                                plan.product.name,
                                e.target.value,
                                'amountOrPercent',
                                appliedCouponTo,
                              );
                            }}
                          >
                            <option value="amount"> Amount Off</option>
                            <option value="percent">Percent Off</option>
                          </select>
                        </div>
                        <div />
                        <SimpleInput
                          type="number"
                          placeholder={
                            plan.value
                              ? plan.value
                              : this.placeholderInput(plan.amountOrPercent)
                          }
                          onChange={(e) => {
                            this.onChangePlans(
                              plan.product.name,
                              e.target.value,
                              'valueAmountOrPercent',
                              appliedCouponTo,
                            );
                          }}
                          errorValue={
                            !!this.inputValid(
                              plan.applyCoupon,
                              plan.amountOrPercent,
                              plan.value,
                            )
                          }
                        />
                        {plan.interval === 'month' &&
                          plan.intervalCount === 1 && (
                            <div>
                              <Row className="centerItems mt-2">
                                <Col md={4}>
                                  <span className="InputLebel">Duration</span>
                                </Col>
                                <Col md={8} className="mb-1">
                                  <select
                                    value={plan.duration}
                                    className="form-control"
                                    onChange={(e) =>
                                      this.onChangePlans(
                                        plan.product.name,
                                        e.target.value,
                                        'duration',
                                        appliedCouponTo,
                                      )
                                    }
                                  >
                                    <option value="once">once</option>
                                    <option value="repeating">repeating</option>
                                    <option value="forever">forever</option>
                                  </select>
                                </Col>
                              </Row>
                              {plan.duration === 'repeating' && (
                                <Row className="centerItems mt-2">
                                  <Col md={4}>
                                    <span className="InputLebel">
                                      Duration in months
                                    </span>
                                    <br />
                                    {plan.duration &&
                                      plan.duration === 'repeating' &&
                                      !plan.durationInMonths &&
                                      this.state.required &&
                                      this.inputRequired()}
                                  </Col>
                                  <Col md={8} className="mt-2">
                                    <SimpleInput
                                      type="number"
                                      placeholder={
                                        plan.durationInMonths
                                          ? plan.durationInMonths
                                          : 0
                                      }
                                      onChange={(e) =>
                                        this.onChangePlans(
                                          plan.product.name,
                                          e.target.value,
                                          'durationInMonths',
                                          appliedCouponTo,
                                        )
                                      }
                                      errorValue={
                                        !!this.inputValid(
                                          plan.applyCoupon,
                                          plan.amountOrPercent,
                                          plan.value,
                                          plan.durationInMonths,
                                          plan.duration,
                                        )
                                      }
                                    />
                                  </Col>
                                </Row>
                              )}
                            </div>
                          )}
                      </div>
                    )}
                </Col>
              ))}
          </Col>
        </Row>
      </div>
    );
  }

  renderCouponStripe() {
    return (
      <div>
        <Row className="centerItems">
          <Col md={4}>
            <span className="InputLebel">Stripe Coupon</span>
            <br />
            {this.state.required &&
              !this.state.stripeCoupon &&
              this.inputRequired()}
          </Col>
          <Col md={8} className="mt-2">
            <SimpleInput
              type="text"
              value={this.state.stripeCoupon}
              onChange={(e) => {
                this.setState({ stripeCoupon: e.target.value });
              }}
              errorValue={!!(this.state.required && this.state.name.length < 1)}
            />
          </Col>
        </Row>
      </div>
    );
  }

  renderSelectCustomer() {
    return (
      <div>
        <div>
          <Row className="mt-2 mb-2">
            <Col md={4} className="centerItems ">
              <label htmlFor="newCustomersCheck" className="InputLebel">
                New customers
              </label>
            </Col>
            <Col md={8}>
              <input
                type="checkbox"
                value={this.state.newCustomers}
                onChange={(e) => {
                  this.setState({ newCustomers: !this.state.newCustomers });
                }}
                id="newCustomersCheck"
              />
            </Col>
          </Row>
          {this.state.newCustomers &&
            this.renderSelectPlan(
              'new',
              this.state.cuponsCustomers.newCustomers,
            )}
        </div>
        <div>
          <Row className="mt-2 mb-2">
            <Col md={4} className="centerItems ">
              <label htmlFor="existingCustomersCheck" className="InputLebel">
                Existing customers
              </label>
            </Col>
            <Col md={8}>
              <input
                type="checkbox"
                value={this.state.existingCustomers}
                onChange={(e) => {
                  this.setState({
                    existingCustomers: !this.state.existingCustomers,
                  });
                }}
                id="existingCustomersCheck"
              />
            </Col>
          </Row>
          {this.state.existingCustomers &&
            this.renderSelectPlan(
              'existing',
              this.state.cuponsCustomers.existingCustomers,
            )}
        </div>
      </div>
    );
  }

  render() {
    const { onCancel, title, coupon } = this.props;
    return (
      <div className="create-coupon-component">
        <div className="card-block">
          <div className="componentHeader">
            <div className="createCouponTitle">
              <Col>
                <i
                  className="fa fa-times fa-xs"
                  onClick={() => this.props.onCancel()}
                />
                <Row>
                  <div className="containerButton">
                    <button
                      className={
                        this.state.existingStripeCode !== 'create'
                          ? 'buttonCoupon'
                          : 'buttonCoupon active'
                      }
                      onClick={() => this.createTypeValue('create')}
                    >
                      {title}
                    </button>
                    <button
                      className={
                        this.state.existingStripeCode !== 'existingCoupon'
                          ? 'buttonCoupon'
                          : 'buttonCoupon active'
                      }
                      onClick={() => this.createTypeValue('existingCoupon')}
                    >
                      Existing Stripe Code
                    </button>
                  </div>
                </Row>
              </Col>
            </div>
          </div>
          {this.state.existingStripeCode !== '' && (
            <div>
              {this.state.existingStripeCode === 'existingCoupon' && (
                <div>{this.renderCouponStripe()}</div>
              )}
              {this.renderSelectCustomer()}
              {this.getCuponInputs()}
            </div>
          )}

          {this.state.existingStripeCode !== '' && (
            <div className="componentFooter">
              <div className="bottom-buttons-new">
                <Button
                  id="btn-success"
                  className="btn btn-success"
                  onClick={this.validate}
                  loading={this.state.waiting}
                >
                  Submit
                </Button>
                <Button className="btn btn-secondary" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
