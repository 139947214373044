export const SET_IS_SEARCHING_BOOKS = 'search/SET_IS_SEARCHING_BOOKS';
export const SET_IS_SEARCHING_CHAPTERS = 'search/SET_IS_SEARCHING_CHAPTERS';
export const SET_IS_SEARCHING_QUESTIONS = 'search/SET_IS_SEARCHING_QUESTIONS';
export const SET_ERRORS = 'search/SET_ERRORS';
export const SET_BOOKS_RESPONSE = 'search/SET_BOOKS_RESPONSE';
export const SET_CHAPTERS_RESPONSE = 'search/SET_CHAPTERS_RESPONSE';
export const SET_QUESTIONS_RESPONSE = 'search/SET_QUESTIONS_RESPONSE';
export const SET_SEARCH_SUCCES = 'search/SET_SEARCH_SUCCES';
export const SET_SEARCH_ERROR = 'search/SET_SEARCH_ERROR';
export const SET_QUERY = 'search/SET_QUERY';
export const SET_SEARCH_FROM_HEADER = 'search/SET_SEARCH_FROM_HEADER';
export const SET_OPTIONS = 'search/SET_OPTIONS';
export const SET_MORE_BOOKS = 'search/SET_MORE_BOOKS';
export const SET_MORE_CHAPTERS = 'search/SET_MORE_CHAPTERS';
export const SET_MORE_QUESTIONS = 'search/SET_MORE_QUESTIONS';
export const ATTACH_BOOKS_RESPONSE = 'search/ATTACH_BOOKS_RESPONSE';
export const ATTACH_CHAPTERS_RESPONSE = 'search/ATTACH_CHAPTERS_RESPONSE';
export const ATTACH_QUESTIONS_RESPONSE = 'search/ATTACH_QUESTIONS_RESPONSE';
