export const wheelhouse = [
  { name: 'Wheelhouse', route: '/home/wheelhouse' },
  { name: 'Books', route: '/home/books' },
  { name: 'Subtopics', route: '/home/subtopics' },
  { name: 'Bibliographies', route: '/home/rating-exam' },
  { name: 'Quals', route: '/home/quals' },
  { name: 'Accounts', route: '/home/accounts/students' },
  { name: 'Settings', route: '/home/settings/keys' },
  { name: 'Practice Exams', route: '/home/practice-exams' },
  { name: 'Referral Program', route: '/home/referral-program' },
  { name: 'Search', route: '/home/search' },
  {
    name: 'AiChat',
    url: process.env.REACT_APP_AI_CHAT_URL,
    target: '_blank',
    isExternal: true,
    requiredRole: 'AI Chat User',
  },
];

export const accounts = [
  { name: 'Students', route: '/home/accounts/students' },
  { name: 'Admins', route: '/home/accounts/admins' },
  { name: 'Roles', route: '/home/accounts/roles' },
  { name: 'Teams', route: '/home/accounts/teams' },
];

export const people = [
  { name: 'People' },
  { name: 'Users', route: '/home/people' },
  { name: 'Admins', route: '/home/people/admins' },
  { name: 'Roles', route: '/home/people/admins/roles' },
  { name: 'Groups', route: '/home/people/groups' },
];

export const ratingExamsSubHeaderOptions = [
  { name: 'Subtopics', route: '/home/rates/topics' },
  { name: 'References', route: '/home/rates/references' },
  { name: 'Bibliography', route: '/home/rates/bibliography' },
];

export const wheelhouseSubHeaderOptions = [
  { name: 'Assignments', route: '/home/wheelhouse/assignments' },
  { name: 'My Assignments', route: '/home/wheelhouse/my-assignments' },
  { name: 'Writers', route: '/home/wheelhouse/writers' },
  { name: 'Reports', route: '/home/wheelhouse/reports' },
  { name: 'Message Center', route: '/home/wheelhouse/message-center' },
  {
    name: 'Customer Submission',
    route: '/home/wheelhouse/customer-submission',
  },
];

export const ratingExamsHeaderOptions = [{ name: 'RatingExams', route: ' ' }];

export const quals = [{ name: 'Quals' }];

export const questions = [{ name: 'Question Overview' }];

export const settings = [
  { name: 'Keys', route: '/home/settings/keys' },
  { name: 'Permissions', route: '/home/settings/permissions' },
  { name: 'Rates & Quals', route: '/home/settings/rates' },
  { name: '10 Week', route: '/home/settings/10week' },
  { name: 'Banners', route: '/home/settings/banners' },
  { name: 'New Coupons', route: '/home/settings/newCoupons' },
  { name: 'Prompts', route: '/home/settings/prompts' },
  { name: 'Daily Quiz', route: '/home/settings/dailyQuiz' },
];
