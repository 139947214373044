import { all, put, call, takeEvery } from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import {
  GET_ALL_BANNERS,
  SET_BANNER_DETAILS,
  CREATE_BANNER,
  EDIT_BANNER,
  DELETE_BANNER,
  ACTIVE_BANNER,
} from './actionTypes';
import {
  setAllBannersAction,
  bannerDetailsAction,
  setLoadingInfo,
  createBannerView,
  loadBannerDetails,
} from './actionSagas';
import * as api from '../../utilities/ServiceManager';

/*LOAD BANNERS*/
function* loadAllBanners() {
  yield takeEvery(GET_ALL_BANNERS, loadAllBannersSaga);
}

function* loadAllBannersSaga() {
  try {
    const response = yield call(api.getAllBannersService);

    yield put(setAllBannersAction(response));
  } catch (exception) {
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Could not load banners. Please try again later.',
        },
        'error',
      ),
    );
  }
}

function* setBannerDetails() {
  yield takeEvery(SET_BANNER_DETAILS, setBannerDetailsSaga);
}

function* setBannerDetailsSaga(action) {
  try {
    const response = yield call(api.getBannerService, action.banner);
    yield put(bannerDetailsAction(response));
  } catch (exception) {
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Could not load banners. Please try again later.',
        },
        'error',
      ),
    );
  }
}

function* createBanner() {
  yield takeEvery(CREATE_BANNER, createBannerSaga);
}

function* createBannerSaga(action) {
  try {
    const response = yield call(api.createBannerService, action.banner);
    if (!response) {
      yield put(
        Notifications.show(
          {
            title: 'Ups!',
            message: 'It is not possible to create more than 2 active banners.',
          },
          'error',
        ),
      );
    } else {
      yield put(setLoadingInfo(true));
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message: 'Banner created successfully',
            autoDismiss: 1,
          },
          'success',
        ),
      );
      yield put(setLoadingInfo(false));

      yield put(createBannerView(false, {}));
    }
  } catch (exception) {
    yield put(setLoadingInfo(false));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Could not create banners. Please try again later.',
        },
        'error',
      ),
    );
  }
}

function* editBanner() {
  yield takeEvery(EDIT_BANNER, editBannerSaga);
}

function* editBannerSaga(action) {
  try {
    const response = yield call(api.setBannerService, action.banner);
    if (!response) {
      yield put(
        Notifications.show(
          {
            title: 'Ups!',
            message: 'It is not possible to have more than 2 active banners.',
          },
          'error',
        ),
      );
    } else {
      yield put(setLoadingInfo(true));
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message: 'Banner edit successfully',
            autoDismiss: 1,
          },
          'success',
        ),
      );
      yield put(setLoadingInfo(false));
      yield put(createBannerView(false, {}));
    }
  } catch (exception) {
    yield put(setLoadingInfo(false));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Could not edit banners. Please try again later.',
        },
        'error',
      ),
    );
  }
}

function* deleteBanner() {
  yield takeEvery(DELETE_BANNER, deleteBannerSaga);
}

function* deleteBannerSaga(action) {
  try {
    yield put(setLoadingInfo(true));
    yield call(api.deleteBannerService, action.banner);
    yield put(setLoadingInfo(false));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Banner delete successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );

    yield put(createBannerView(false, {}));
    yield put(loadBannerDetails(false));
  } catch (exception) {
    yield put(setLoadingInfo(false));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Could not delete banners. Please try again later.',
        },
        'error',
      ),
    );
  }
}

function* activeBanner() {
  yield takeEvery(ACTIVE_BANNER, activeBannerSaga);
}

function* activeBannerSaga(action) {
  try {
    const response = yield call(
      api.setBannerActiveService,
      action.bannerId,
      action.value,
    );
    if (!response) {
      yield put(
        Notifications.show(
          {
            title: 'Ups!',
            message: 'It is not possible to more than 2 active banners.',
          },
          'error',
        ),
      );
    } else {
      yield put(setLoadingInfo(true));
      yield put(bannerDetailsAction(response));
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message: `Banner ${
              response.active ? 'active' : 'inactive'
            } successfully`,
            autoDismiss: 1,
          },
          'success',
        ),
      );
      yield put(setLoadingInfo(false));
    }
  } catch (exception) {
    yield put(setLoadingInfo(false));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message:
            'Could not active or inactive banners. Please try again later.',
        },
        'error',
      ),
    );
  }
}

export default function* sagas() {
  yield all([
    loadAllBanners(),
    setBannerDetails(),
    createBanner(),
    editBanner(),
    deleteBanner(),
    activeBanner(),
  ]);
}
