import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import './AllCSVReport.css';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import { SimpleInput } from '../../../../commonComponents/Input/Input';
import RatingExamSelector from '../RatingExamSelector/RatingExamSelector';

export default class AllCSVReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRates: [],
      selectedRatingExam: [],
      multiple: false,
    };
  }

  componentDidMount() {
    const { rates } = this.props;
    if (rates && rates.length) {
      this.setState({
        selectedRates: this.props.rates.slice(1),
      });
    } else if (!this.ratesIsLoading) {
      this.props.loadRates();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.rates || !prevProps.rates.length) &&
      this.props.rates &&
      this.props.rates.length
    ) {
      this.setState({
        selectedRates: this.props.rates.slice(1),
      });
    }

    if (
      prevProps.bibliographyReportIsLoading &&
      !this.props.bibliographyReportIsLoading &&
      !this.props.bibliographyReportError &&
      this.props.bibliographyReport.length > 0
    ) {
      if (this.state.multiple) {
        this.downloadXls(this.props.bibliographyReport);
      } else {
        setTimeout(function () {
          document.getElementById('report-download').click();
        }, 1000);
      }
    }
  }

  areAllRatesSelected = () => {
    let areAllRatesSelected = true;
    let areAllRatesDeselected = true;
    this.state.selectedRates.forEach((currentRate) => {
      if (currentRate.isSelected) {
        areAllRatesDeselected = false;
      } else {
        areAllRatesSelected = false;
      }
    });
    return {
      areAllRatesSelected,
      areAllRatesDeselected,
    };
  };

  onChangeRateSelection = (index, newValue) => {
    const { selectedRates } = this.state;
    selectedRates[index].isSelected = newValue;
    if (!selectedRates[index].isSelected) {
      this.deselectRelatedRE(selectedRates[index].shortname);
    }
    this.setState({ selectedRates });
  };

  changeAllValues = (newValue) => {
    const { selectedRates } = this.state;

    if (!newValue) {
      this.setState({ selectedRatingExam: [] });
    }

    this.setState({
      selectedRates: selectedRates.map((rate) => {
        rate.isSelected = newValue;
        return rate;
      }),
    });
  };

  deselectRelatedRE = (ratingExam) => {
    const { selectedRatingExam } = this.state;
    let result = [];
    selectedRatingExam.forEach((SRE, index) => {
      if (SRE.slice(7) != ratingExam) {
        result.push(SRE);
      }
    });

    this.setState({ selectedRatingExam: result });
  };

  renderSelectAllOrNothing = (areAllRatesSelected, areAllRatesDeselected) => (
    <Row>
      <Col md={12} id="all-or-none">
        <SimpleInput
          type="checkbox"
          description={areAllRatesDeselected ? 'Select All' : 'Deselect All'}
          value={!areAllRatesDeselected}
          onChange={(e) => {
            this.changeAllValues(areAllRatesDeselected ? true : false);
          }}
        />
      </Col>
    </Row>
  );

  renderRateAndCheckbox = (rate, index) => {
    if (rate.id) {
      return (
        <Row key={rate.id}>
          <Col md={12}>
            <SimpleInput
              description={`(${rate.shortname}) ${rate.name}`}
              type="checkbox"
              value={rate.isSelected}
              onChange={(e) => {
                this.onChangeRateSelection(index, !rate.isSelected);
              }}
            />
          </Col>
        </Row>
      );
    }
    return null;
  };

  generateReportForSelectedRates = () => {
    const selectedRatesIds = this.state.selectedRates
      .filter((rate) => rate.isSelected)
      .map((rate) => rate.id);
    this.props.generateReportForSelectedRates(
      selectedRatesIds,
      this.state.selectedRatingExam,
      this.state.multiple,
    );
  };

  changeRatingExamSelected = (ratingExamSelected) => {
    const { selectedRatingExam } = this.state;
    const index = selectedRatingExam.indexOf(ratingExamSelected);
    if (index != -1) {
      selectedRatingExam.splice(index, 1);
    } else {
      selectedRatingExam.push(ratingExamSelected);
    }
    this.setState({ selectedRatingExam: selectedRatingExam });
  };

  selectAllRatingExam = (ratingExams) => {
    let { selectedRatingExam } = this.state;

    ratingExams.forEach((RE) => {
      if (!selectedRatingExam.includes(RE)) {
        selectedRatingExam.push(RE);
      }
    });
    this.setState({ selectedRatingExam: selectedRatingExam });
  };

  takeTheRate = (rate) => {
    const separate = rate[0].split(' ');
    const result = separate[0].replace(/([0-9])/g, '');
    return result;
  };

  downloadXls = (books) => {
    let book = window.XLSX.utils.book_new();
    for (let i = 1; i < books.length; i++) {
      let sheet = window.XLSX.utils.json_to_sheet(books[i], { skipHeader: 1 });

      let nameToAppendSS = this.takeTheRate(books[i][2]);
      window.XLSX.utils.book_append_sheet(book, sheet, nameToAppendSS);
    }
    window.XLSX.writeFile(book, 'Bibligraphy-Data-Report.xls');
  };

  render() {
    const { areAllRatesSelected, areAllRatesDeselected } =
      this.areAllRatesSelected();
    return (
      <Row className="all-csv-report">
        {this.props.ratesIsLoading || this.props.bibliographyReportIsLoading ? (
          <Spinner />
        ) : (
          <div className="d-flex w-100">
            <div className="d-none">
              <CSVLink
                id="report-download"
                className="report-download"
                filename="Bibligraphy-Data-Report.csv"
                data={this.props.bibliographyReport}
              />
            </div>
            <Col className="col-6">
              <div
                className={
                  !areAllRatesDeselected
                    ? 'generate-report'
                    : 'generate-report disabled'
                }
                onClick={
                  !areAllRatesDeselected
                    ? this.generateReportForSelectedRates
                    : () => {}
                }
              >
                Generate Report
              </div>
              <Row>
                <Col className="d-contents">
                  <Button
                    className={
                      this.state.multiple
                        ? 'generate-report disabled'
                        : 'generate-report'
                    }
                    onClick={() => this.setState({ multiple: true })}
                  >
                    Multiple tabbed
                  </Button>
                  <Button
                    className={
                      this.state.multiple
                        ? 'generate-report'
                        : 'generate-report disabled'
                    }
                    onClick={() => this.setState({ multiple: false })}
                  >
                    Single tabbed
                  </Button>
                </Col>
              </Row>

              <div className="report-title">
                {' '}
                Select the rates you want included in the report{' '}
              </div>
              {this.renderSelectAllOrNothing(
                areAllRatesSelected,
                areAllRatesDeselected,
              )}
              {this.state.selectedRates.map((rate, index) =>
                this.renderRateAndCheckbox(rate, index),
              )}
            </Col>
            <Col className="d-block col-6">
              {!areAllRatesDeselected ? (
                <RatingExamSelector
                  selectedRatingExam={this.state.selectedRatingExam}
                  selectAllRatingExam={this.selectAllRatingExam}
                  changeRatingExamSelected={this.changeRatingExamSelected}
                />
              ) : (
                <div />
              )}
            </Col>
          </div>
        )}
      </Row>
    );
  }
}

AllCSVReport.propTypes = {
  rates: PropTypes.array.isRequired,
  ratesIsLoading: PropTypes.bool.isRequired,
  loadRates: PropTypes.func.isRequired,

  generateReportForSelectedRates: PropTypes.func,
  bibliographyReport: PropTypes.array,
  bibliographyReportIsLoading: PropTypes.bool,
  bibliographyReportError: PropTypes.string,
};
