import { connect } from 'react-redux';
import {
  getAdminsByRate,
  getSelectedAdmin,
  getSelectedRate,
  getIsViewRates,
  getActiveStatus,
  getAwaitingApproval as getAwaitingApprovalSelector,
  getAdminsByQual,
  getAdminsByRateI,
  getAdminsByQualI,
  getStudyProgramAdmins,
  getAdminScroll,
  getIsBackFromQuestion,
} from '../../selectors';
import {
  getUser,
  getRolesPermissions,
  getGeneralRolesPermissions,
} from '../../../Login/selectors';
import GroupsList from './GroupsList';
import {
  getAllRatesAdmins,
  getAwaitingApproval,
  getAssignmentsCount,
  getAllQualsAdmins,
} from '../../actionsSagas';
import addAbilityToOpenModal from '../../../../utilities/modalHOC';
import {
  setIsViewRates,
  setIsChangingType,
  setLoading,
  setAwaitingForApproval,
  setActiveStatus,
  setSelectedAdminAndRate,
  updateStudyProgramAdmins,
  noGroups,
  setAdminScroll,
  setIsBackFromQuestion,
  setAdminByQual,
  setAdminByRate,
} from '../../actions';

const mapStateToProps = (state, ownProps) => {
  return {
    adminsByRate: getAdminsByRate(state),
    adminsByQual: getAdminsByQual(state),
    selectedAdmin: getSelectedAdmin(state),
    selectedRate: getSelectedRate(state),
    isViewRates: getIsViewRates(state),
    user: getUser(state),
    activeStatus: getActiveStatus(state),
    awaitingApproval: getAwaitingApprovalSelector(state),
    rolesPermissions: getRolesPermissions(state),
    pagePermissions: getGeneralRolesPermissions(state),
    adminsByRateImmutable: getAdminsByRateI(state),
    adminsByQualImmutable: getAdminsByQualI(state),
    studyProgramAdmins: getStudyProgramAdmins(state),
    adminScroll: getAdminScroll(state),
    isBackFromQuestion: getIsBackFromQuestion(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadAdmins: (
      getInactiveAdmins,
      removingApprovalFilter = false,
      isSetAdmin
    ) => {
      dispatch(setAwaitingForApproval(false));
      dispatch(
        getAllRatesAdmins(getInactiveAdmins, removingApprovalFilter, isSetAdmin)
      );
    },
    onLoadAdminsQuals: getInactiveAdmins => {
      dispatch(getAllQualsAdmins(getInactiveAdmins));
    },
    onSelectAdmin: (admin, rate) => {
      dispatch(setSelectedAdminAndRate(admin, rate));
    },
    onChangeView: newView => {
      dispatch(setIsViewRates(newView));
    },
    updateIsChangingType: value => {
      dispatch(setIsChangingType(value));
    },
    updateLoading: loading => {
      dispatch(setLoading(loading));
    },
    getAwaitingApproval: () => {
      dispatch(setActiveStatus('awaitingApproval'));
      dispatch(setAwaitingForApproval(true));
      dispatch(setSelectedAdminAndRate(null, null));
      dispatch(getAwaitingApproval());
    },
    setActiveStatus: status => {
      dispatch(setActiveStatus(status));
    },
    setAwaitingForApproval: value => {
      dispatch(setAwaitingForApproval(value));
    },
    setAssignmentsCount: (admin, rate, type) => {
      dispatch(getAssignmentsCount(admin.id, rate, type));
    },
    updateStudyProgramAdmins: admins => {
      dispatch(updateStudyProgramAdmins(admins));
    },
    noGroups: value => {
      dispatch(noGroups(value));
    },
    setAdminScroll: y => {
      dispatch(setAdminScroll(y));
    },
    setIsBackFromQuestion: isBackFromQuestion => {
      dispatch(setIsBackFromQuestion(isBackFromQuestion));
    },
    onClearAdmins: () => {
      dispatch(setAdminByQual([]));
      dispatch(setAdminByRate([]));
      dispatch(updateStudyProgramAdmins([]));
    },
  };
};

const GroupsListContainer = connect(mapStateToProps, mapDispatchToProps)(
  GroupsList
);

export default addAbilityToOpenModal(GroupsListContainer);
