import React from 'react';
import pure from 'recompose/pure';
import Errors from '../../Notifications/Errors';
import Input from '../../../commonComponents/Input/Input';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Spinner from '../../../commonComponents/Spinner/Spinner';

const AuthyForm = ({
  handleSubmit,
  submit,
  requesting,
  errors,
  resendCode,
  resetStatus
}) => (
  <div className="row justify-content-center">
    <div className="col-md-8">
      <div className="card-group mb-0">
        <div className="card p-4">
          <div className="card-block">
            <form className="widget-form" onSubmit={handleSubmit(submit)}>
              <h1>Two Factor Authentication</h1>
              <p className="text-muted">ENTER SMS CODE</p>
              <Field
                name="code"
                component={Input}
                type="text"
                placeholder="***********"
              />
              <div className="row mb-2">
                <div className="col">
                  <button
                    className="btn btn-primary px-4 ml-0 btn-block"
                    action="submit"
                  >
                    Verify
                  </button>
                </div>
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-link btn-block ml-0 px-4"
                    onClick={resendCode}
                  >
                    Send me the code again
                  </button>
                </div>
              </div>
            </form>
            <div className="row mb-0">
              <div className="col">
                <button className="btn-link pl-0" onClick={resetStatus}>
                  <i className="fa fa-arrow-left fa-lg clickeable" />
                </button>
              </div>
            </div>
            <div className="auth-messages">
              {!requesting && errors && !!errors.length && (
                <Errors message="Failure to login due to:" errors={errors} />
              )}
              {requesting && <Spinner />}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

AuthyForm.propTypes = {
  handleSubmit: PropTypes.func,
  loginRequest: PropTypes.func,
  requesting: PropTypes.bool,
  successful: PropTypes.bool,
  messages: PropTypes.array,
  errors: PropTypes.array
};

export default pure(AuthyForm);
