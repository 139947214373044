import { connect } from 'react-redux';
import ResetPasswordStateful from './ResetPasswordStateful';
import { resetPasswordForgotActionSagas } from '../actionsSagas';
import { resetErrors } from '../actions';
import { reduxForm } from 'redux-form';
import { verifyLoggedAdmin } from '../../../../src/utilities/verifyUserLogged';
import { loginAs } from '../../../../src/components/Admins/actionsSagas';
import {
  authyVerificationRequest,
  resendCodeRequest,
  resetStatus,
} from '../../../../src/components/Login/actions';

const mapStateToProps = state => {
  return {
    resetRequest: state.resetPassword,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPasswordForgotRequest: payload => {
      dispatch(resetPasswordForgotActionSagas(payload));
    },
    verifyLoggedAdmin: () => {
      verifyLoggedAdmin();
    },
    resetErrors: () => {
      dispatch(resetErrors());
    },
    loginAs: admin => {
      dispatch(loginAs(admin));
    },
    authyVerificationRequest: (code, email) => {
      dispatch(authyVerificationRequest(code, email));
    },
    resendCodeRequest: email => {
      dispatch(resendCodeRequest(email));
    },
    resetStatus: () => {
      dispatch(resetStatus());
    },
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordStateful);

// in our Redux's state, this form will be available in 'form.login'
const ResetPasswordContainer = reduxForm({
  form: 'ResetPassword',
})(connected);

export default ResetPasswordContainer;
