import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Button from 'react-bootstrap-button-loader';

import './AddMemberInGroup.css';

class AddMemberInGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      waiting: false,
    };
  }

  componentWillReceiveProps({ submitSucceeded }) {
    if (submitSucceeded && this.state.waiting) {
      this.setState({ waiting: false });
    }
  }

  render() {
    const {
      handleSubmit,
      onSubmit,
      onCancel,
      role,
      admins = [],
      rate,
      studyProgram,
    } = this.props;

    return (
      <div className="add-member-in-group-component">
        <div className="card-block">
          <div className="text">
            <p>
              {rate && <span className="label">Rate:</span>}
              {!rate && <span className="label">Qual:</span>}
              <strong>{studyProgram.name}</strong>
            </p>
            <p>
              <span className="label">Role:</span> <strong>{role.name}</strong>
            </p>
          </div>
          <form
            className="widget-form add-member-in-group-form"
            onSubmit={handleSubmit(fields => {
              this.setState({ waiting: true });
              onSubmit(fields);
            })}
          >
            <span className="label">Admin:</span>
            <Field name="user" component="select" required={true}>
              <option />
              {admins.map(admin => (
                <option
                  key={`AddMemberInGroup-Admin${admin.id}`}
                  value={admin.id}
                >
                  {admin.name + ' ' + admin.lastName}
                </option>
              ))}
            </Field>

            {this.state.error ? (
              <div className="errors">{this.state.error}</div>
            ) : null}
            <div className="buttonsSection">
              <div className="buttonsContainer">
                <Button
                  className="btn btn-success"
                  type="submit"
                  loading={this.state.waiting}
                >
                  Add
                </Button>
                <Button className="btn btn-secondary" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

AddMemberInGroup.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  requesting: PropTypes.bool,
};

export default reduxForm({ form: 'AddMemberInGroup' })(AddMemberInGroup);
