import React from 'react';
import './bannerListStyle.css';

class bannerView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentWillMount() {}

  componentWillReceiveProps = nextProps => {};

  render() {
    const { backgroundColor, fontSize, description } = this.props;
    return (
      <div className="viewContainer">
        <div
          className="bannerDetail"
          style={{ backgroundColor: backgroundColor }}
        >
          <p className="bannerText" style={{ fontSize: fontSize }}>
            {description}
          </p>
        </div>
      </div>
    );
  }
}

export default bannerView;
