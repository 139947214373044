const booksSagaNamespace = 'books';
export const UPDATE_SP_REFERENCE = `${booksSagaNamespace}/UPDATE_SP_REFERENCE`;
export const LOAD_FILTERS = `${booksSagaNamespace}/LOAD_FILTERS`;
export const CREATE_FILTER = `${booksSagaNamespace}/CREATE_FILTER`;
export const EDIT_FILTER = `${booksSagaNamespace}/EDIT_FILTER`;
export const SET_ACTIVE_FILTER = `${booksSagaNamespace}/SET_ACTIVE_FILTER`;
export const DELETE_FILTER = `${booksSagaNamespace}/DELETE_FILTER`;
export const LOAD_CHAPTER_QUESTIONS_CSV = `${booksSagaNamespace}/LOAD_CHAPTER_QUESTIONS_CSV`;
export const GET_LOGS = `${booksSagaNamespace}/GET_LOGS`;
export const GET_NAMES_TO_SECOND_BAR = `${booksSagaNamespace}/GET_NAMES_TO_SECOND_BAR`;
