import { connect } from 'react-redux';
import messageCenter from './messageCenter';
import { getPagination, getRates } from '../Rates/selectors';
import addAbilityToOpenModal from '../../utilities/modalHOC';
import {
  getAllRates,
  setRate,
  createNotification,
  getNotificationByRate,
  deleteNotification,
  editNotification,
} from './actionsSagas';
import {
  getAllRatesSelectors,
  getRateSelector,
  loadNoticationsSelectors,
  getNotificationsSelectors,
} from './selectors';

const mapStateToProps = state => ({
  ratesPagination: getPagination(state),
  rates: getAllRatesSelectors(state),
  rate: getRateSelector(state),
  loadNotifications: loadNoticationsSelectors(state),
  notifications: getNotificationsSelectors(state),
});

const mapDispatchToProps = dispatch => ({
  getAllRates: () => dispatch(getAllRates()),
  setRate: rate => dispatch(setRate(rate)),
  createNotification: notification =>
    dispatch(createNotification(notification)),
  getNotifications: rateId => dispatch(getNotificationByRate(rateId)),
  deleteNotification: notificationId =>
    dispatch(deleteNotification(notificationId)),
  editNotification: (notificationId, notificationValue) =>
    dispatch(editNotification(notificationId, notificationValue)),
});

export default addAbilityToOpenModal(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(messageCenter),
);
