export const GET_ALL_BANNERS = `banners/GET_ALL_BANNERS `;
export const SET_ALL_BANNERS = `banners/SET_ALL_BANNERS `;
export const SET_BANNER_DETAILS = `banners/SET_BANNER_DETAILS `;
export const BANNER_DETAILS = `banners/BANNER_DETAILS `;
export const LOAD_BANNER_DETAILS = `banners/LOAD_BANNER_DETAILS `;
export const CREATE_BANNER_VIEW = `banners/CREATE_BANNER_VIEW `;
export const CREATE_BANNER = `banners/CREATE_BANNER`;
export const EDIT_BANNER = `banners/EDIT_BANNER`;
export const SET_LOADING_INFO_BANNER = `banners/SET_LOADING_INFO_BANNER`;
export const DELETE_BANNER = `banners/DELETE_BANNER `;
export const ACTIVE_BANNER = `banners/ACTIVE_BANNER `;
