export function getRequestErrorMessage(
  exception,
  defaultMessage = 'Something went wrong, please try again.',
) {
  let message = defaultMessage;

  if (exception && exception.response && exception.response.data) {
    const data = exception.response.data;

    if (typeof data === 'string') return data;

    if (
      data.error &&
      data.error.code &&
      data.error.code !== 'UsageError' &&
      data.error.message
    ) {
      message = exception.response.data.error.message;
    }
  }

  return message;
}

export const GENERIC_ERROR_MESSAGE =
  'Oops! Something went wrong!\nHelp us improve your experience by sending an error report';
