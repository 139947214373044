import React from 'react';

export default class TeamRatesRow extends React.Component {
  render() {
    const { rate, selected, onRateClick, viewQuals, loadingBooks } = this.props;
    return (
      <div
        onClick={() => {
          if (!loadingBooks) {
            onRateClick(rate);
          }
        }}
        className={selected ? 'roleRow active' : 'roleRow'}
      >
        <div className="valueName">
          {selected ? (
            viewQuals || !rate.shortname ? (
              <b>{rate.name}</b>
            ) : (
              <b>{`(${rate.shortname}) ${rate.name}`}</b>
            )
          ) : viewQuals || !rate.shortname ? (
            rate.name
          ) : (
            `(${rate.shortname}) ${rate.name}`
          )}
        </div>
      </div>
    );
  }
}
