import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import { setSubtopics, setRate, setChapters } from './actions';
import { updateSubtopic } from '../Rates/actions';
import {
  LOAD_SUBTOPICS_BY_RATE,
  UPDATE_CHAPTER_SUBTOPICS,
  LOAD_RATE,
  LOAD_CHAPTERS_BY_RATE,
  UPDATE_SUBTOPIC_CHAPTERS,
} from './actionTypesSagas';
import * as api from '../../utilities/ServiceManager';
import Notifications from 'react-notification-system-redux';
import { updateChapterAfterUpdate } from '../Bibliographies/actions';
import { updateChapterAfterUpdateSubtopics } from '../Questions/actions';

/**
 * LOAD Subtopics by rate
 */
function* loadSubtopicsByRateSaga() {
  yield takeLatest(LOAD_SUBTOPICS_BY_RATE, callSubtopicsByRate);
}

function* callSubtopicsByRate(action) {
  try {
    let subtopics = yield call(api.getSubtopicsByRate, action.rate);
    yield put(setSubtopics(subtopics));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load subtopics. Please try again later.',
      ),
    );
  } finally {
  }
}

/**
 * update chapter Subtopics
 */
function* updateChapterSubtopicsSaga() {
  yield takeLatest(UPDATE_CHAPTER_SUBTOPICS, callUpdateChapterSubtopics);
}

function* callUpdateChapterSubtopics(action) {
  try {
    let updatedChapter = yield call(
      api.updateChapterSubtopics,
      action.reference,
      action.chapter,
      action.checkedSubtopics,
    );

    let subtopicsForCurrentRateQTY = yield call(
      calculateSubtopicsQTYForCurrentRate,
      updatedChapter,
    );
    yield put(
      updateChapterAfterUpdate({
        ...updatedChapter,
        subtopicCount: subtopicsForCurrentRateQTY,
      }),
    );
    yield put(updateChapterAfterUpdateSubtopics(updatedChapter));

    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Chapter updated successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update subtopics. Please try again later.',
      ),
    );
  } finally {
  }
}

function* calculateSubtopicsQTYForCurrentRate(updatedChapter) {
  let subtopicsForCurrentRateQTY = 0;
  const subtopicState = yield select((state) => state.subtopics.toJSON());
  const topics =
    subtopicState.rateForSubtopics && subtopicState.rateForSubtopics.topics
      ? subtopicState.rateForSubtopics.topics
      : [];
  if (topics.length) {
    updatedChapter.subtopics.forEach((subtopic) => {
      let topic = topics.find(
        (topic) => subtopic.topic && subtopic.topic.id === topic.id,
      );
      if (topic) {
        subtopicsForCurrentRateQTY++;
      }
    });
  }
  return subtopicsForCurrentRateQTY;
}

/****************************************************************************
 * LOAD RATE
 */
function* loadRateSaga() {
  yield takeLatest(LOAD_RATE, loadRate);
}

function* loadRate(action) {
  try {
    const id =
      action.rateId.id !== undefined ? action.rateId.id : action.rateId;
    let rate = yield call(api.getRateById, id);
    yield put(setRate(rate));
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not load rate. Please try again later.',
    );
  }
}

/****************************************************************************
 * LOAD RATE
 */
function* loadRateChaptersSaga() {
  yield takeLatest(LOAD_CHAPTERS_BY_RATE, loadRateChapters);
}

function* loadRateChapters(action) {
  try {
    let chapters = yield call(api.getAllChaptersByRate, action.rate);
    yield put(setChapters(chapters));
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not load rate. Please try again later.',
    );
  }
}

/****************************************************************************
 * LOAD RATE
 */
function* updateSubtopicChaptersSaga() {
  yield takeLatest(UPDATE_SUBTOPIC_CHAPTERS, updateSubtopicChapters);
}

function* updateSubtopicChapters(action) {
  try {
    let subtopic = yield call(
      api.updateSubtopicChapters,
      action.subtopic,
      action.chapters,
    );
    yield put(updateSubtopic(subtopic));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Subtopic updated successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    showNotificationErrorFromException(
      exception,
      'Could not load rate. Please try again later.',
    );
  }
}

export default function* sagas() {
  yield all([
    loadSubtopicsByRateSaga(),
    updateChapterSubtopicsSaga(),
    loadRateSaga(),
    loadRateChaptersSaga(),
    updateSubtopicChaptersSaga(),
  ]);
}

function showNotificationErrorFromException(
  exception,
  defaultMessage = 'Something went wrong, please try again.',
) {
  let message = defaultMessage;

  if (
    exception &&
    exception.response &&
    exception.response.data &&
    exception.response.data.error &&
    exception.response.data.error.code &&
    exception.response.data.error.code !== 'UsageError' &&
    exception.response.data.error.message
  ) {
    message = exception.response.data.error.message;
  }

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
