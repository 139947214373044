import { connect } from 'react-redux';
import Groups from './Groups';
import addAbilityToOpenModal from '../../../utilities/modalHOC';
import {
  getRates,
  getPagination,
  getQuals,
  getPaginationQuals,
} from '../../Rates/selectors';
import { loadRates, loadQuals } from '../../Rates/actionsSagas';
import {
  getRoles,
  getGroupsForRole,
  getAdmins,
  getWaiting,
  getDeleteResponse,
  getGroupToDelete,
  getSelectedRate,
  getSelectedQual,
  getViewRates,
} from '../selectors';
import {
  getUser,
  getGeneralRolesPermissions,
  getRolesPermissions,
} from '../../Login/selectors';
import {
  loadRolesForRates,
  loadGroupsForStudyProgram,
  loadAllAdmins,
  deleteGroup,
  addMemberInGroup,
  consultDelete,
  updatePrimaryWriter,
} from '../actionsSagas';
import {
  setConsultDeleteResponse,
  updateGroupToDelete,
  setSelectedRate,
  setSelectedQual,
  setViewRates,
} from '../actions';

const mapStateToProps = state => ({
  rates: getRates(state),
  roles: getRoles(state),
  groups: getGroupsForRole(state),
  admins: getAdmins(state),
  isWaiting: getWaiting(state),
  isLoadingMembers: getWaiting(state, 'groupsForStudyProgram'),
  ratesPagination: getPagination(state),
  paginationQuals: getPaginationQuals(state),
  quals: getQuals(state),
  deleteResponse: getDeleteResponse(state),
  groupToDelete: getGroupToDelete(state),
  user: getUser(state),
  generalPermissions: getGeneralRolesPermissions(state),
  rolesPermissions: getRolesPermissions(state),
  rate: getSelectedRate(state),
  qual: getSelectedQual(state),
  viewRates: getViewRates(state),
});

const mapDispatchToProps = dispatch => ({
  loadRates: page => dispatch(loadRates(page)),
  loadQuals: page => dispatch(loadQuals(page)),
  loadRoles: () => dispatch(loadRolesForRates()),
  loadAdmins: () => dispatch(loadAllAdmins()),
  loadGroupsForStudyProgram: (type, typeId) =>
    dispatch(loadGroupsForStudyProgram(type, typeId)),
  deleteGroup: groupId => dispatch(deleteGroup(groupId)),
  addMemberInGroup: (fields, rate, role, type) =>
    dispatch(addMemberInGroup(fields, rate, role, type)),
  consultDelete: group => dispatch(consultDelete(group)),
  updateDeleteResponseState: value => dispatch(setConsultDeleteResponse(value)),
  updateGroupToDelete: group => dispatch(updateGroupToDelete(group)),
  updatePrimaryWriter: (writer, writerToDelete) =>
    dispatch(updatePrimaryWriter(writer, writerToDelete)),
  setSelectedRate: rate => dispatch(setSelectedRate(rate)),
  setSelectedQual: qual => dispatch(setSelectedQual(qual)),
  setViewRates: viewRates => dispatch(setViewRates(viewRates)),
});

export default addAbilityToOpenModal(
  connect(mapStateToProps, mapDispatchToProps)(Groups)
);
