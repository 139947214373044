import Notifications from 'react-notification-system-redux';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { closeModal } from '../../containers/Full/actions';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import * as api from '../../utilities/ServiceManager';
import { updateLoadingGrid } from '../Bibliographies/actions';
import { setAllStudyPrograms } from '../Quals/actions';
import {
  addQual,
  addRate,
  addSubtopic,
  addTopic,
  removeSP,
  removeSubtopic,
  removeTopic,
  setQuals,
  setRates,
  setSelectedSP,
  setTopics,
  updateSP as updateSPAction,
  updateSubtopic,
  updateTopic,
  waiting,
} from './actions';
import {
  CREATE_RATE,
  CREATE_SUBTOPIC,
  CREATE_TOPIC,
  DELETE_SP,
  DELETE_SUBTOPIC,
  DELETE_TOPIC,
  LOAD_ALL_RATES,
  LOAD_QUALS,
  LOAD_RATES,
  LOAD_TOPICS,
  UPDATE_SP,
  UPDATE_SUBTOPIC,
  UPDATE_TOPIC,
} from './actionTypesSagas';
/**
 * LOAD RATES
 */
function* loadRatesSaga() {
  yield takeLatest(LOAD_RATES, callLoadRates);
}

function* callLoadRates(action) {
  try {
    const rates = yield call(api.getRates, action.page, action.isBibRates);
    yield put(setRates(rates.data, action.page));
    yield put(setSelectedSP(rates.data.filter((r) => r.id !== 0)[0]));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load rates. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
  }
}

/**
 * LOAD ALL RATES (NO PAGINATION)
 */
function* loadAllRatesSaga() {
  yield takeLatest(LOAD_ALL_RATES, callLoadAllRates);
}

function* callLoadAllRates(action) {
  try {
    yield put(waiting(true));
    const rates = yield call(api.getRates);
    yield put(setAllStudyPrograms(rates.data));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load rates. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
  }
}

/**
 * LOAD QUALS
 */
function* loadQualsSaga() {
  yield takeLatest(LOAD_QUALS, callLoadQuals);
}

function* callLoadQuals(action) {
  try {
    yield put(waiting(true));
    const quals = yield call(api.getQuals, action.page);
    yield put(setQuals(quals, action.page));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load quals. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
  }
}

/**
 * LOAD TOPICS
 */
function* loadTopicsSaga() {
  yield takeLatest(LOAD_TOPICS, callLoadTopics);
}

function* callLoadTopics(action) {
  try {
    yield put(waiting(true));
    yield put(updateLoadingGrid(true));
    let topics = [];
    if (action.isByReference) {
      topics = yield call(api.getTopicsByReference, action.id);
    } else if (action.isBySP) {
      topics = yield call(api.getTopicsBySP, action.id, action.page);
    } else {
      topics = yield call(api.getTopicsForRate, action.id, action.page);
    }

    yield put(setTopics(topics));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load topics. Please try again later.',
      ),
    );
  } finally {
    yield put(updateLoadingGrid(false));
    yield put(waiting(false));
  }
}

/**
 * CREATE TOPIC
 */
function* createTopicSaga() {
  yield takeLatest(CREATE_TOPIC, callCreateTopic);
}

function* callCreateTopic(action) {
  try {
    // yield put(waiting(true));
    const topic = yield call(api.createTopic, action.rateId, action.name);
    topic.collapsed = false;
    yield put(addTopic(topic));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Topic created successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not create the subtopic. Please try again later.',
      ),
    );
  } finally {
    // yield put(waiting(false));
  }
}

/**
 * CREATE SUBTOPIC
 */
function* createSubtopicSaga() {
  yield takeEvery(CREATE_SUBTOPIC, callCreateSubtopic);
}

function* callCreateSubtopic(action) {
  try {
    // yield put(waiting(true));
    const topic = yield call(api.createSubtopic, action.topicId, action.name);

    yield put(addSubtopic(topic));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Subtopic created successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not create the subtopic. Please try again later.',
      ),
    );
  } finally {
    // yield put(waiting(false));
  }
}

/**
 * UPDATE TOPIC
 */
function* updateTopicSaga() {
  yield takeLatest(UPDATE_TOPIC, callUpdateTopic);
}

function* callUpdateTopic(action) {
  try {
    yield put(waiting(true));
    const topic = yield call(api.updateTopic, action.id, action.name);

    yield put(updateTopic(topic));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Topic updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update topic. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
  }
}

/**
 * UPDATE SUBTOPIC
 */
function* updateSubtopicSaga() {
  yield takeLatest(UPDATE_SUBTOPIC, callUpdateSubtopic);
}

function* callUpdateSubtopic(action) {
  try {
    yield put(waiting(true));
    const subtopic = yield call(api.updateSubtopic, action.id, action.name);

    yield put(updateSubtopic(subtopic));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Subtopic updated successfully',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not update the subtopic. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
  }
}

/**
 * DELETE TOPIC
 */
function* deleteTopicSaga() {
  yield takeLatest(DELETE_TOPIC, callDeleteTopic);
}

function* callDeleteTopic(action) {
  try {
    yield put(waiting(true));
    const topic = yield call(api.deleteTopic, action.id);

    yield put(removeTopic(topic));

    yield put(
      Notifications.show(
        { title: 'Deleted!', message: 'Topic deleted', autoDismiss: 1 },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not delete the topic. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
  }
}

/**
 * DELETE SUBTOPIC
 */
function* deleteSubtopicSaga() {
  yield takeLatest(DELETE_SUBTOPIC, callDeleteSubtopic);
}

function* callDeleteSubtopic(action) {
  try {
    yield put(waiting(true));
    const topic = yield call(api.deleteSubtopic, action.id);

    yield put(removeSubtopic(topic));
    yield put(
      Notifications.show(
        { title: 'Deleted!', message: 'Subtopic deleted', autoDismiss: 1 },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not delete the subtopic. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
  }
}

/**
 * CREATE RATE
 */
function* createRateSaga() {
  yield takeLatest(CREATE_RATE, callCreateRate);
}

function* callCreateRate(action) {
  try {
    yield put(waiting(true));
    const sp = yield call(api.createRate, action.fields);

    if (!action.fields.isQual) {
      yield put(addRate(sp));
    } else {
      yield put(addQual(sp));
    }
    yield put(setSelectedSP(sp));
    const type = action.fields.isQual ? 'Qual' : 'Rate';
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: `${type} created successfully`,
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not create the rate. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
    yield put(closeModal());
  }
}

/**
 * UPDATE STUDY PROGRAM
 */
function* updateSPSaga() {
  yield takeLatest(UPDATE_SP, updateSP);
}

function* updateSP(action) {
  try {
    yield put(waiting(true));
    const sp = yield call(api.updateSP, action.fields);
    const type = action.fields.isQual ? 'Qual' : 'Rate';
    sp.isQual = action.fields.isQual;
    sp.type = type.toLowerCase();
    yield put(updateSPAction(sp));
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: `${type} updated successfully`,
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not create the rate. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
  }
}

/**
 * UPDATE STUDY PROGRAM
 */
function* deleteSPSaga() {
  yield takeLatest(DELETE_SP, deleteSP);
}

function* deleteSP(action) {
  try {
    yield put(waiting(true));
    yield call(api.deleteSP, action.sp);
    yield put(removeSP(action.sp));
    yield put(setSelectedSP({ type: action.sp.type }));
    const type = action.sp.type === 'qual' ? 'Qual' : 'Rate';
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: `${type} deleted successfully`,
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not delete the rate. Please try again later.',
      ),
    );
  } finally {
    yield put(waiting(false));
  }
}
/**
 * DEFAUL EXPORT
 */
export default function* sagas() {
  yield all([
    loadRatesSaga(),
    loadTopicsSaga(),
    createTopicSaga(),
    createSubtopicSaga(),
    updateTopicSaga(),
    updateSubtopicSaga(),
    deleteTopicSaga(),
    deleteSubtopicSaga(),
    createRateSaga(),
    loadQualsSaga(),
    loadAllRatesSaga(),
    updateSPSaga(),
    deleteSPSaga(),
  ]);
}

function showNotificationErrorFromException(exception) {
  const message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
