import * as type from './actionTypesSagas';

export const loadRolesForRates = () => ({
  type: type.LOAD_ROLES_FOR_RATES,
});
export const loadGroupsForStudyProgram = (stype, typeId) => ({
  type: type.LOAD_GROUPS_FOR_STUDY_PROGRAM,
  stype,
  typeId,
});

export const deleteGroup = groupId => ({
  type: type.DELETE_GROUP,
  groupId,
});

export const addMemberInGroup = (fields, rate, role, typeSP) => ({
  type: type.ADD_MEMBER_IN_GROUP,
  fields,
  rate,
  role,
  typeSP,
});

export const loadAllAdmins = () => ({
  type: type.LOAD_ALL_ADMINS,
});

export const consultDelete = group => ({
  type: type.CONSULT_DELETE,
  group,
});

export const updatePrimaryWriter = (writer, writerToDelete) => ({
  type: type.UPDATE_PRIMARY_WRITER,
  writer,
  writerToDelete,
});
