import * as t from './actionTypes';
import { Map, List } from 'immutable';

const initialState = Map({
  cycles: List(),
  selectedCycle: {},
  updatedCycle: false,
});

function updateCycles(state, type) {
  let cyclesListUpdated = state.get('cycles').map(cycle => {
    if (cycle.type === type) {
      let aux = cycle;
      aux.isActive = false;
      return aux;
    }
    return cycle;
  });
  return cyclesListUpdated;
}

export default function(state = initialState, action) {
  switch (action.type) {
    case t.CYCLESRECEIVED:
      return state.set('cycles', List(action.payload.data));

    case t.UPDATESELECTEDCYCLE:
      return state.set('selectedCycle', action.cycle);

    case t.CYCLECREATION:
      let cyclesListUpdated = updateCycles(state, action.payload.type);
      let newCycles = cyclesListUpdated.push(action.payload);
      return state
        .set('cycles', newCycles)
        .set('selectedCycle', newCycles.get(newCycles.size - 1));

    case t.CYCLEACTIVATED:
      let cyclesListUpdatedWithActivation = updateCycles(
        state,
        action.payload.type,
      );
      let cycleActivatedFinished = cyclesListUpdatedWithActivation.map(
        cycleIndex => {
          if (cycleIndex.id === action.payload.id) {
            return action.payload;
          }
          return cycleIndex;
        },
      );
      return state
        .set('cycles', cycleActivatedFinished)
        .set('selectedCycle', action.payload);

    case t.SUBSTITUTESUPDATED:
      return state
        .set(
          'cycles',
          state
            .get('cycles')
            .map(cycle =>
              cycle.id === action.payload.id ? action.payload : cycle,
            ),
        )
        .set('selectedCycle', action.payload)
        .set('updatedCycle', true);

    case t.RATES_MOVED:
      return state
        .set(
          'cycles',
          state.get('cycles').map(cycle => {
            if (cycle.id === action.payload.id) return action.payload;
            if (cycle.type === action.payload.type) {
              cycle.activeForRates = cycle.activeForRates.filter(
                r => !action.payload.activeForRates.includes(r),
              );
            }
            return cycle;
          }),
        )
        .set('selectedCycle', action.payload)
        .set('updatedCycle', true);

    case t.CYCLEUPDATE:
      return state
        .set(
          'cycles',
          state
            .get('cycles')
            .map(cycle =>
              cycle.id === action.payload.id ? action.payload : cycle,
            ),
        )
        .set('selectedCycle', action.payload)
        .set('updatedCycle', true);

    case t.SET_UPDATED_CYCLE:
      return state.set('updatedCycle', action.isUpdated);
    case t.CYCLEDELETE:
      let cyclesUpdatedFromDeletion = state
        .get('cycles')
        .filter(cycle => cycle.id !== state.get('selectedCycle').id);
      return state
        .set('cycles', cyclesUpdatedFromDeletion)
        .set('selectedCycle', null);

    default:
      return state;
  }
}
