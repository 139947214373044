import React from 'react';
import ReferenceDefinitions from '../../../components/Definitions/components/DefinitionsContainer';
import QuestionDefinitions from '../../../components/Definitions/components/QuestionDefinitionsContainer';
import { Row, Col } from 'react-bootstrap';

//When this class is used in a modal, the class of the modal must be modal-question-definitions
//And it should not have a header.
class ChapterDefinitions extends React.Component {
  state = {
    showAll: false,
  };

  render() {
    let questionText =
      !this.state.showAll && this.props.question && this.props.question.text;
    return (
      <Row>
        <Col md={6}>
          <Row>
            <Col md={8}>
              <h5>Chapter Definitions</h5>
            </Col>
            <Col md={4}>
              <label style={{ cursor: 'pointer' }}>
                <input
                  name="isGoing"
                  type="checkbox"
                  checked={this.state.showAll}
                  onChange={() => {
                    this.setState({ showAll: !this.state.showAll });
                  }}
                  style={{ marginRight: '10px', cursor: 'pointer' }}
                />
                Show all
              </label>
            </Col>
          </Row>
          <ReferenceDefinitions
            reference={this.props.reference}
            questionText={questionText}
            readOnly={!this.state.showAll}
            noRecordsText="No reference definition applies to the question"
          />
        </Col>
        <Col md={6}>
          <h5>Question Definitions</h5>
          <QuestionDefinitions
            question={this.props.question}
            closeModal={this.props.closeModal}
            assignment={this.props.assignment}
          />
        </Col>
      </Row>
    );
  }
}

export default ChapterDefinitions;
