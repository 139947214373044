import React from 'react';
import './SearchInstructionsModal.css';

class SearchInstructionsModal extends React.Component {
  render() {
    return (
      <div className="si-modal">
        <div className="search-instructions-title">
          <span className="title">Search Instructions</span>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={this.props.closeModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <hr />
        <div className="search-instructions-body">
          {/* GLOBAL TAGS */}
          <div className="subtitle">Global tags</div>
          <div className="item">
            <span className="tag">referenceid: </span>
            <span className="dataType"> (number) </span>
          </div>
          <div className="item">
            <span className="tag">chapterid: </span>
            <span className="dataType"> (number) </span>
          </div>
          <div className="item">
            <span className="tag">questionid: </span>
            <span className="dataType"> (number) </span>
          </div>
          {/* REFERENCES TAGS */}
          <div className="subtitle">References tags</div>
          <div className="item">
            <span className="tag">archived: </span>
            <span className="dataType"> (boolean) </span>
          </div>
          <div className="item">
            <span className="tag">actasparent: </span>
            <span className="dataType"> (boolean) </span>
          </div>
          {/* CHAPTERS TAGS */}
          <div className="subtitle">Chapters tags</div>
          <div className="item">
            <span className="tag">isghostchapter: </span>
            <span className="dataType"> (boolean) </span>
          </div>
          {/* QUESTIONS TAGS */}
          <div className="subtitle">Questions tags</div>
          <div className="item">
            <span className="tag">softwarepage: </span>
            <span className="dataType"> (number) </span>
          </div>
          <div className="item">
            <span className="tag">status: </span>
            <span className="dataType"> [Published, New, Edit, Deleted] </span>
          </div>
          <div className="item">
            <span className="tag">flashmode: </span>
            <span className="dataType"> (boolean) </span>
          </div>
          <div className="item">
            <span className="tag">documentpage: </span>
            <span className="dataType"> (text) </span>
          </div>
          <hr />
          <div className="search-instructions-text-title">
            <span className="title">Instructons for Use</span>
            <div className="instructions-text">
              <p className="instructions">
                Use the tags for search by specific fields in each category.
                Only valid tags will be processed.
              </p>
              <p className="instructions">
                The right way to use a tag is: <strong>tag:value</strong>{' '}
                (whithout spaces, no-case-sensitive). Boolean values:{' '}
                <strong>true, false.</strong>
              </p>
              <p className="instructions">
                The right way to use documentpage tag is:{' '}
                <strong>documentpage:"some text"</strong> (allow spaces and
                special characters).
              </p>
              <p className="instructions">
                Also, it is possible to enter any text to search results by
                notes, title, description, further reading, etc. (depending on
                the category).
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchInstructionsModal;
