import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'writers');
}

export const getWriters = state => getState(state).get('writers');
export const getWaiting = state => getState(state).get('waiting');
export const getChapterWriters = state => getState(state).get('chapterWriters');
export const getReferences = state => getState(state).get('references');
export const getAssignment = state => getState(state).get('assignment');
