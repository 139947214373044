import React from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import CodeList from './CodeList';
import DropDown from '../../../../commonComponents/DropDown/DropDown';
import Analytics from '../Analytics/Analytics';
import { CSVLink } from 'react-csv';

const DEFAULT_FILENAME = 'unredeemed-codes.csv';

class CodeGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: this.props.showExpanded,
      filename: DEFAULT_FILENAME,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showExpanded !== this.props.showExpanded) {
      this.setState({
        expanded: this.props.showExpanded,
      });
    }
    if (
      this.props.unredeemedCodes &&
      this.state.filename !== DEFAULT_FILENAME
    ) {
      document.getElementById('unredeemed-codes-download').click();
      this.props.onClearUnredeemed();
      this.setState({ filename: DEFAULT_FILENAME });
    }
  }

  onDownloadUnredeemedCodes = item => {
    this.props.downloadUnredeemed(item.id);
    this.setState({
      filename: `${item.description}-unredeemed-codes-${moment().format(
        'MMM',
      )}${moment().format('DD')}-${moment().format('YYYY')}.csv`,
    });
  };

  onCodeGroupClick = item => {
    if (!this.state.expanded) {
      this.props.getCodeGroup(item.id, 0, 20);
    }
    this.props.onSelectCodeGroup(item);
    this.props.setAnalyticsCodeGroup(null);
    this.setState({ expanded: true });
  };

  selectAnalytics = item => {
    this.props.loadRedeems(item.id);
    this.props.setAnalyticsCodeGroup(item);
    this.props.onSelectCodeGroup(item);
    this.setState({ expanded: false });
  };

  render() {
    let {
      item,
      page,
      isGettingCodeGroup,
      analyticsCodeGroup,
      setAnalyticsCodeGroup,
      currentCodeGroup,
      getCodeGroup,
      onChangePage,
      onCancelCode,
      codeGroups,
      unredeemedCodes = '',
      canEdit,
    } = this.props;
    return (
      <Row className="CodeGroupRow">
        <Col xs={12} md={12} className="CodeGroup">
          <span
            className="Flex"
            style={{ cursor: 'pointer' }}
            onClick={() => this.onCodeGroupClick(item)}
          >
            <div className="CodeGroupTitle">{item.description}</div>
          </span>
          <div className="Flex">
            <div>
              <div>
                {item.codes
                  ? item.codes + ' codes'
                  : item.couponCodes
                  ? item.couponCodes.length + ' codes'
                  : '0 codes'}
              </div>
              <div>{`${item.redeems || 0} redeems`}</div>
            </div>
            <div className="expireDate">
              {'Expires ' + moment(Number(item.expiryAt)).format('L')}
            </div>
            <div
              style={{
                marginLeft: '40px',
                marginRight: '40px',
                cursor: 'pointer',
              }}
            >
              <i
                className="fa fa-bar-chart fa-lg"
                onClick={() => this.selectAnalytics(item)}
              />
            </div>
            {canEdit && (
              <DropDown
                options={[
                  {
                    name: 'Cancel All',
                    event: () => this.props.onCancelAll(item.id),
                  },
                  {
                    name: 'Edit',
                    event: () => {
                      this.props.onEdit(item);
                    },
                  },
                  {
                    name: 'Download unredeemed codes csv',
                    event: () => this.onDownloadUnredeemedCodes(item),
                  },
                ]}
                title="Codes"
                style={{
                  position: 'absolute',
                  left: '-170px',
                  top: '-20px',
                }}
              />
            )}
          </div>
        </Col>
        {this.state.expanded && currentCodeGroup ? (
          <CodeList
            codes={currentCodeGroup.codes}
            pagination={currentCodeGroup.pagination}
            codeGroupId={item.id}
            getCodeGroup={getCodeGroup}
            isGettingCodeGroup={isGettingCodeGroup}
            page={page}
            onChangePage={onChangePage}
            onCancelCode={onCancelCode}
            redeemedPage={currentCodeGroup.pagination.redeemedPages}
          />
        ) : analyticsCodeGroup ? (
          <Analytics
            codeGroup={analyticsCodeGroup}
            codeGroupList={codeGroups}
            setCurrentCodeGroup={setAnalyticsCodeGroup}
          />
        ) : null}
        <div style={{ display: 'none' }}>
          <CSVLink
            id="unredeemed-codes-download"
            filename={this.state.filename}
            data={unredeemedCodes}
          />
        </div>
      </Row>
    );
  }
}

export default CodeGroup;
