import { connect } from 'react-redux';

import {
  generateDailyQuizForTodayAction,
  getAllDailyQuizForTodayAction,
} from './actionSagas';
import DailyQuiz from './DailyQuiz';
import { getAllDailyQuiz } from './selectors';

const mapStateToProps = (state) => ({
  allDailyQuiz: getAllDailyQuiz(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAllDailyQuizForToday: () => dispatch(getAllDailyQuizForTodayAction()),
  generateDailyQuizForToday: () => dispatch(generateDailyQuizForTodayAction()),
});

const DailyQuizContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DailyQuiz);

export default DailyQuizContainer;
