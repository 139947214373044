/*
if (process.env.REACT_APP_KEYS_ENV === 'code3prep') {
  module.exports = require('./endpoints.code3prep');
} else if (process.env.REACT_APP_KEYS_ENV === 'production') {
  module.exports = require('./endpoints.prod');
} else if (process.env.REACT_APP_KEYS_ENV === 'stage') {
  module.exports = require('./endpoints.staging');
} else if (process.env.REACT_APP_KEYS_ENV === 'local') {
  module.exports = require('./endpoints.local');
} else {
  module.exports = require('./endpoints.dev');
}
*/
export const baseURL = process.env.REACT_APP_API_URL;

export const loginUrl = baseURL + '/adminuser/login';
export const getUsers = baseURL + '/user';
export const admins = baseURL + '/adminuser';
export const getAdmins = baseURL + '/adminuser';
export const roles = baseURL + '/role';
export const getStudyPrograms = baseURL + '/studyProgram';
export const getPages = baseURL + '/pagepermission';
export const getWorkflowActions = baseURL + '/workflowaction';
export const environmentName = process.env.REACT_APP_ENVIRONMENT_NAME;

console.log('env:', process.env.REACT_APP_KEYS_ENV);
