import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import './ChangeHistory.css';
import Spinner from '../../Spinner/Spinner';
import ChangeHistoryRow from './ChangeHistoryRow';
import TextLink from '../../../commonComponents/TextLink/TextLink';

export default class ChangeHistory extends React.Component {
  componentWillMount() {
    if (this.props.onLoad) {
      this.props.onLoad(this.props.assignment, this.props.question.id);
    }
  }

  isEven(n) {
    return n % 2 === 0;
  }

  render() {
    return this.props.waiting
      ? <Spinner text="Loading history" />
      : this.props.changeHistory && this.props.changeHistory.length > 0
        ? <div className="change-history-component">
            <TextLink text="Back to grid" onAction={this.props.onBack} />
            <div className="title">Change history</div>
            <Row className="headerRow">
              <Col sm={2}>User</Col>
              <Col sm={2}>Date</Col>
              <Col sm={3}>Old value</Col>
              <Col sm={3}>New value</Col>
              <Col sm={2}>Changes</Col>
            </Row>
            {this.props.changeHistory.map(change =>
              <ChangeHistoryRow item={change} />
            )}
          </div>
        : <div className="change-history-component">
            <div className="title">Change history</div>
            <TextLink text="Back to grid" onAction={this.props.onBack} />
            <div className="noRecords">No records found</div>
          </div>;
  }
}

ChangeHistory.propTypes = {
  question: PropTypes.object,
  assignment: PropTypes.number,
};

ChangeHistory.defaultProps = {
  question: {},
  assignment: 0,
};
