import Rates from './Rates';
import { connect } from 'react-redux';

import addAbilityToOpenModal from '../../../utilities/modalHOC';
import {
  getRates,
  getTopics,
  getTopicTypeCreated,
  getWaiting,
  getTopicsInmutable,
  getPagination,
  getRate,
  getQuals,
  getLinks,
} from '../selectors';
import {
  loadRates,
  loadTopics,
  createTopic,
  createSubtopic,
  updateTopic,
  updateSubtopic,
  deleteTopic,
  deleteSubtopic,
  createRate,
  loadQuals,
} from '../actionsSagas';
import { updateRate } from '../actions';
import {
  getGeneralRolesPermissions,
  getRolesPermissions,
  getUser,
} from '../../Login/selectors';

const mapStateToProps = (state) => ({
  rates: getRates(state),
  topics: getTopics(state),
  topicTypeCreated: getTopicTypeCreated(state),
  waiting: getWaiting(state),
  topicsInmutable: getTopicsInmutable(state),
  ratesPagination: getPagination(state),
  rate: getRate(state),
  quals: getQuals(state),
  links: getLinks(state),
  generalRolesPermissions: getGeneralRolesPermissions(state),
  rolesPermissions: getRolesPermissions(state),
  user: getUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadRates: (page) => dispatch(loadRates(page)),
  loadQuals: (page) => dispatch(loadQuals(page)),
  loadTopics: (id) => dispatch(loadTopics(id)),
  createTopic: (rateId, name) => dispatch(createTopic(rateId, name)),
  createSubtopic: (id, name) => dispatch(createSubtopic(id, name)),
  updateTopic: (id, name) => dispatch(updateTopic(id, name)),
  updateSubtopic: (id, name) => dispatch(updateSubtopic(id, name)),
  deleteTopic: (id) => dispatch(deleteTopic(id)),
  deleteSubtopic: (id) => dispatch(deleteSubtopic(id)),
  createRate: (fields) => dispatch(createRate(fields)),
  updateRate: (rate) => dispatch(updateRate(rate)),
});

export default addAbilityToOpenModal(
  connect(mapStateToProps, mapDispatchToProps)(Rates),
);
