import { Map, List } from 'immutable';
import * as type from './actionTypes';

const initialState = Map({
  list: List(),
  listQuals: [],
  pagination: { total: 1, page: 1 },
  paginationQuals: { total: 1, page: 1 },
  topics: List(),
  topicTypeCreated: { type: '', topicId: null },
  waiting: false,
  rate: {},
  selectedSP: { type: 'rate' },
  links: [
    { name: 'Subtopics', to: '/home/rates/topics' },
    { name: 'References', to: '/home/rates/references' },
    { name: 'Bibliography', to: '/home/rates/bibliography' },
  ],
});
const sortTopicsAndSubtopics = topics => {
  let sortedTopicsAndSubtopics = topics
    ? topics.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      })
    : [];
  sortedTopicsAndSubtopics.map(topic => {
    topic.subtopics.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });
  });
  return sortedTopicsAndSubtopics;
};

const sortSubtopics = subtopics => {
  let sortedSubtopics = subtopics.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  });
  return sortedSubtopics;
};

export default function(state = initialState, action) {
  let topics = state.get('topics');
  let sortedTopics;

  switch (action.type) {
    case type.UPDATE_WAITING:
      return state.set('waiting', action.waiting);

    case type.SET_SELECTED_SP:
      return state.set('selectedSP', action.sp);

    case type.UPDATE_RATE:
      return state.set('rate', action.rate);

    case type.SET_QUALS:
      let paginationQuals = {
        total: action.payload.pagination.total,
        page: action.page,
      };
      return state
        .set('listQuals', action.payload.data)
        .set('paginationQuals', paginationQuals);

    case type.SET_RATES:
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.unshift({ name: 'All', id: 0 });
      }
      return state.set('list', List(action.payload));

    case type.ADD_RATE:
      return state.set(
        'list',
        state
          .get('list')
          .concat([action.rate])
          .sort((a, b) => a.name.localeCompare(b.name)),
      );

    case type.REMOVE_SP:
      if (action.sp.type === 'qual') {
        return state.set(
          'listQuals',
          state.get('listQuals').filter(q => q.id !== action.sp.id),
        );
      }
      return state.set(
        'list',
        state.get('list').filter(r => r.id !== action.sp.id),
      );

    case type.ADD_QUAL:
      return state.set(
        'listQuals',
        state
          .get('listQuals')
          .concat([action.qual])
          .sort((a, b) => a.name.localeCompare(b.name)),
      );

    case type.UPDATE_SP:
      if (action.sp.isQual) {
        return state.set(
          'listQuals',
          state
            .get('listQuals')
            .map(sp => {
              if (sp.id === action.sp.id) {
                return action.sp;
              }
              return sp;
            })
            .slice(),
        );
      }
      return state.set(
        'list',
        state.get('list').map(sp => {
          if (sp.id === action.sp.id) {
            return action.sp;
          }
          return sp;
        }),
      );

    case type.SET_TOPICS:
      sortedTopics = sortTopicsAndSubtopics(action.payload.data);
      return state.set('topics', List(sortedTopics));

    case type.UPDATE_TOPIC:
      return state.set(
        'topics',
        topics.map(topic => {
          if (topic.id === action.payload.id) {
            topic.name = action.payload.name;
          }
          return topic;
        }),
      );

    case type.UPDATE_SUBTOPIC:
      return state.set(
        'topics',
        topics.map(topic => {
          topic.subtopics.map(subtopic => {
            if (subtopic.id === action.payload.id) {
              subtopic.name = action.payload.name;
              if (action.payload.chapters) {
                subtopic.chapters = action.payload.chapters;
              }
            }
            return subtopic;
          });
          return topic;
        }),
      );

    case type.REMOVE_TOPIC:
      return state.set(
        'topics',
        topics.filter(topic => topic.id !== action.payload.id),
      );

    case type.REMOVE_SUBTOPIC:
      return state.set(
        'topics',
        topics.map(topic => {
          topic.subtopics = topic.subtopics.filter(
            subtopic => subtopic.id !== action.payload.id,
          );
          return topic;
        }),
      );

    case type.ADD_TOPIC:
      sortedTopics = sortTopicsAndSubtopics(topics.concat([action.payload]));
      return state
        .set('topics', sortedTopics)
        .set('topicTypeCreated', { type: 'topic' });

    case type.ADD_SUBTOPIC:
      let topicTypeCreated;
      return state
        .set(
          'topics',
          topics.map(topic => {
            if (topic.id === action.payload.topic) {
              topicTypeCreated = { type: 'subtopic', topicId: topic.id };
              topic.subtopics = sortSubtopics([
                ...topic.subtopics,
                action.payload,
              ]);
            }
            return topic;
          }),
        )
        .set('topicTypeCreated', topicTypeCreated);

    case type.SET_RATING_EXAM_QUESTION_PERCENTAGE:
      let { percentage, questions } = action.percentage;
      return state.set(
        'list',
        state.get('list').map(r => {
          if (r.id === action.rate) {
            r.percentage = percentage;
            r.questions = questions;
          }
          return r;
        }),
      );

    default:
      return state;
  }
}
