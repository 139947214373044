import React from 'react';
import Button from 'react-bootstrap-button-loader';
import { Row, Col } from 'react-bootstrap';
import './CreateChapter.css';
import { SimpleInput, SimpleTextarea } from '../../Input/Input';
import DropFileInput from '../../DropFileInput/DropFileInput';

class CreateChapter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      waiting: false,
      reference: this.props.chapter.reference
        ? this.props.chapter.reference
        : this.props.references && this.props.references[0]
        ? this.props.references[0].id
        : null,
      enabled: this.props.chapter.enabled,
      description: this.props.chapter.description,
      presentationIndex: this.props.chapter.presentationIndex,
      name: this.props.chapter.name,
      noPagesNumbers: this.props.chapter.noPagesNumbers,
      pdf: this.props.chapter.pdfUrl,
      weNeedThisPublication: this.props.chapter.weNeedThisPublication
        ? this.props.chapter.weNeedThisPublication
        : false,
    };
  }

  componentWillReceiveProps({ submitSucceeded }) {
    if (submitSucceeded && this.state.waiting) {
      this.setState({ waiting: false });
    }
  }

  validate(chaptersOfReference = 0, edit = false) {
    let errors = {};
    let fields = Object.assign({}, this.state);

    if (!fields.name || fields.name === '') {
      errors.name = 'Title field is required';
    }

    if (!fields.description) {
      errors.description = 'Description field is required';
    }

    if (fields.pdf && fields.pdf.length > 0) {
      fields.pdf = fields.pdf[0];
    }

    if (
      fields.pdf &&
      fields.pdf.name &&
      fields.pdf.name.toLowerCase().substr(-3) !== 'pdf'
    ) {
      errors.pdf = 'Please select a pdf file';
    }

    if (!fields.noPagesNumbers) {
      fields.noPagesNumbers = false;
    }

    if (!edit && !fields.reference) {
      errors.reference = 'Must select a reference';
    }
    if (edit && !fields.presentationIndex) {
      errors.presentationIndex = 'Presentation index is required';
    }

    if (edit) {
      if (
        fields.presentationIndex < 0 ||
        fields.presentationIndex > chaptersOfReference
      ) {
        errors.presentationIndex =
          'Invalid index: presentation index must be a value within the chapter list.';
      }
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: null, waiting: true });
    this.props.onSubmit(fields);
  }

  handleKeyPress = (event) => {
    if (event.ctrlKey && event.key === 'Enter') {
      document.getElementById('btn-success').click();
    }
  };

  handleKeyPress = (event) => {
    if (event.ctrlKey && event.key === 'Enter') {
      document.getElementById('btn-success').click();
    }
  };

  render() {
    const {
      onSubmit,
      onCancel,
      onDelete,
      references,
      edit = false,
      chapter = {},
      addChapter = false,
      chaptersOfReference = 0,
    } = this.props;

    const reference =
      this.props.references && this.props.references[0]
        ? this.props.references[0]
        : this.props.edit && this.props.reference
        ? this.props.reference
        : null;

    let refOfChapter;
    if (edit && chapter.reference) {
      refOfChapter = reference;
    }

    let title = edit ? 'Edit Chapter' : 'Create Chapter';

    let refName = '';
    if (references && references.length > 0) {
      references.map((reference) => (refName = reference.name));
    }

    return (
      <div className="create-chapter-component">
        <div className="card-block">
          <div className="createChapterTitle">
            {title}
            <i
              className="fa fa-times fa-xs"
              onClick={() => this.props.onCancel()}
            />
            <hr style={{ width: '600px', marginLeft: '-15px' }} />
          </div>
          <form
            onKeyDown={this.handleKeyPress}
            className="widget-form new-admin-form"
            onSubmit={() => this.validate(chaptersOfReference, edit)}
          >
            <Row>
              <Col md={4}>
                {!edit && !addChapter && (
                  <span className="InputLebel">Reference</span>
                )}
              </Col>
              <Col md={8}>
                {!edit && !addChapter && (
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <span className="InputLebel">{refName}</span>
                    </div>
                    {this.state.errors && this.state.errors.reference && (
                      <span className="errors">
                        {this.state.errors.reference}
                      </span>
                    )}
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                {edit && chapter.reference && (
                  <span className="InputLebel">Reference</span>
                )}
              </Col>
              <Col md={8}>
                {edit && chapter.reference && (
                  <div className="InputLebel" disabled={true}>
                    {refOfChapter.name}
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <span className="InputLebel">Title</span>
              </Col>
              <Col md={8}>
                <SimpleInput
                  type="text"
                  value={this.state.name}
                  onChange={(e) => {
                    this.setState({ name: e.target.value });
                  }}
                  error={
                    this.state.errors && this.state.errors.name
                      ? this.state.errors.name
                      : null
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <span className="InputLebel">Description</span>
              </Col>
              <Col md={8}>
                <SimpleTextarea
                  type="text"
                  value={this.state.description}
                  onChange={(e) => {
                    this.setState({ description: e.target.value });
                  }}
                  error={
                    this.state.errors && this.state.errors.description
                      ? this.state.errors.description
                      : null
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <span className="InputLebel">Pages numbers</span>
              </Col>
              <Col md={8}>
                <SimpleInput
                  description="No pages numbers"
                  type="checkbox"
                  value={this.state.noPagesNumbers}
                  onChange={(e) => {
                    this.setState({
                      noPagesNumbers: !this.state.noPagesNumbers,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <span className="InputLebel">Enabled</span>
              </Col>
              <Col md={8}>
                <SimpleInput
                  description="Enabled"
                  type="checkbox"
                  value={this.state.enabled}
                  onChange={(e) => {
                    this.setState({ enabled: !this.state.enabled });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <span className="InputLebel">Pdf</span>
              </Col>
              <Col md={8}>
                <DropFileInput
                  input={{
                    onChange: (e) => {
                      this.setState({ pdf: e });
                    },
                  }}
                  value={this.state.pdf}
                  defaultValue={this.state.pdf}
                  multiple={false}
                  accept="application/pdf"
                  getUrlPdf={this.props.getUrlPdf}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <label className="InputLebel" htmlFor="needPublication">
                  We need this publication!
                </label>
              </Col>
              <Col md={8} className="colNeedPublication">
                <input
                  id="needPublication"
                  type="checkbox"
                  value={this.state.weNeedThisPublication}
                  checked={this.state.weNeedThisPublication}
                  onChange={(e) => {
                    this.setState({
                      weNeedThisPublication: !this.state.weNeedThisPublication,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                {edit && <span className="InputLebel">Presentation index</span>}
              </Col>
              <Col md={8}>
                {edit && (
                  <SimpleInput
                    type="text"
                    value={this.state.presentationIndex}
                    onChange={(e) => {
                      this.setState({ presentationIndex: e.target.value });
                    }}
                    error={
                      this.state.errors && this.state.errors.presentationIndex
                        ? this.state.errors.presentationIndex
                        : null
                    }
                  />
                )}
              </Col>
            </Row>
          </form>
          {addChapter && reference.name && (
            <span className="InputLebel" disabled={true}>
              {reference.name}
            </span>
          )}
          {this.state.errors && this.state.errors.general && (
            <div className="errors">{this.state.errors.general}</div>
          )}
          <hr style={{ width: '600px', marginLeft: '-15px' }} />
          <div className="bottom-buttons-new">
            {edit && (
              <i
                className="fa fa-trash icon-button"
                title="Remove this chapter."
                onClick={(ev) => {
                  ev.stopPropagation();
                  onDelete(reference.id, chapter.id, chapter.presentationIndex);
                }}
              />
            )}
            <Button
              id="btn-success"
              className="btn btn-success"
              onClick={() => this.validate(chaptersOfReference, edit, onSubmit)}
              loading={this.state.waiting}
            >
              Submit
            </Button>
            <Button className="btn btn-secondary" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

CreateChapter.defaultProps = {
  chapter: {
    reference: null,
    description: '',
    presentationIndex: '',
    name: '',
    noPagesNumbers: false,
    pdf: '',
    enabled: true,
  },
};

export default CreateChapter;
