import Sidebar from './Sidebar';
import { connect } from 'react-redux';
import {
  getGeneralRolesPermissions,
  getRolesPermissions,
  getUser,
} from '../../Login/selectors';

const mapStateToProps = state => ({
  generalRolesPermissions: getGeneralRolesPermissions(state),
  rolesPermissions: getRolesPermissions(state),
  user: getUser(state),
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
