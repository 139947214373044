/* eslint-disable */
export function getRanks() {
  return [
    {
      id: 'All',
      name: 'All',
    },
    {
      id: 'E4',
      name: 'E-4',
    },
    {
      id: 'E5',
      name: 'E-5',
    },
    {
      id: 'E6',
      name: 'E-6',
    },
    {
      id: 'E7',
      name: 'E-7',
    },
  ];
}

export function getDutyStates() {
  return [
    {
      id: 'All',
      name: 'All',
    },
    {
      id: 'Regular',
      name: 'Regular',
    },
    {
      id: 'Substitute',
      name: 'Substitute',
    },
    {
      id: 'Reserve',
      name: 'Reserve',
    },
  ];
}

export function getSubcsriptionTypes() {
  return [
    {
      id: 'PMK + Occupational',
      name: 'PMK + Occupational',
    },
    {
      id: 'PMK only',
      name: 'PMK only',
    },
  ];
}

export function getSizeShirtTypes() {
  return [
    {
      id: 'S',
      name: 'S',
    },
    {
      id: 'M',
      name: 'M',
    },
    {
      id: 'L',
      name: 'L',
    },
    {
      id: 'XL',
      name: 'XL',
    },
    {
      id: 'XXL',
      name: 'XXL',
    },
  ];
}

export function getRatingExam(rate, rank) {
  switch (rank) {
    case 'E4':
      return rate.shortname + '3';
    case 'E5':
      return rate.shortname + '2';
    case 'E6':
      return rate.shortname + '1';
    default:
      return rate.shortname + 'C';
  }
}

export function getCycles(cycles) {
  let cyclesResponse = [];

  cycles.forEach(c => {
    cyclesResponse.push(c);
  });
  return cyclesResponse;
}

export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export function validatePassword(password) {
  if (!password || password.length < 12 || password.length > 30) {
    return false;
  }
  var re = /^(?=(.*[^A-Za-z0-9]){2,})(?=(.*[A-Z]){2,})(?=(.*\d){2,})(?=(.*[a-z]){2,}).+/;
  return re.test(String(password));
}

export function validateE(e) {
  const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(e.value)) {
    return true; //'has-success'
  } else {
    return false; //'has-danger'
  }
}
