import Notifications from 'react-notification-system-redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import {
  downloadActiveSubscriptions,
  downloadMasterUserReport,
  generateRateQuestionsReportService,
  getActiveSubscriptionsService,
  getBibliographyReportForGivenRates as getBibliographyReportForGivenRatesServiceCaller,
  getCompletionStatus,
  getFavoriteQuestionsReportForGivenRates as getFavoriteQuestionsReportForGivenRatesServiceCaller,
  getHistoricalUsersReport,
  getIntercomActiveUsersReportService,
  getMasterUserReport,
  getNumberOfQuestionsEnabledPerRatingExamReport,
  getProfileSheetReport,
  getRateQuestionsReportsService,
  getTopicsReportForGivenRates as getTopicsReportForGivenRatesServiceCaller,
  getUsersCount,
  getUserSubmittedQuestionsReportService,
  latestCsvCreationDate,
  latestCsvCreationDateSubscriptions,
} from '../../utilities/ServiceManager';
import {
  callUsersCount,
  countFavoriteReport,
  getBibliographyReportForGivenRatesError,
  getBibliographyReportForGivenRatesSuccess,
  getFavoriteQuestionsReportForGivenRatesError,
  getFavoriteQuestionsReportForGivenRatesSuccess,
  getHistoricalUsersReportError,
  getTopicsReportForGivenRatesError,
  getTopicsReportForGivenRatesSuccess,
  getUsersCountError,
  getUsersCountSuccess,
  loadingCounterReport,
  profileSheetCsvReport,
  setActiveSubscriptions,
  setActiveSubscriptionsError,
  setActiveSubscriptionsErrorFalse,
  setArrayIdsReport,
  setCsvReport,
  setdownloadActiveSubscriptions,
  setdownloadMasterUserReport,
  setdownloadMasterUserReportError,
  setHistoricalUsersReport,
  setIntercomActiveSubscriptionsError,
  setIntercomActiveUsers,
  setIsUserSubmittedQuestionsReportLoading,
  setLastRateQuestionsReport,
  setlatestCsvCreationDate,
  setlatestCsvCreationDateError,
  setlatestCsvCreationDateSubscriptions,
  setMasterUserReport,
  setMasterUserReportError,
  setNumberOfQuestionsEnabledPerRatingExamReport,
  setNumberOfQuestionsEnabledPerRatingExamReportError,
  setUserSubmittedQuestionsReport,
  setUserSubmittedQuestionsReportError,
} from './actions';
import {
  DOWNLOAD_ACTIVE_SUBSCRIPTIONS_REPORT,
  DOWNLOAD_MASTER_USER_REPORT,
  GENERATE_RATE_QUESTIONS_REPORT,
  GET_ACTIVE_SUBSCRIPTIONS,
  GET_BIBLIOGRAPHY_REPORT_FOR_RATES,
  GET_COMPLETION_STATUS,
  GET_FAVORITE_QUESTIONS_REPORT_FOR_RATES,
  GET_HISTORICAL_USERS_REPORT,
  GET_INTERCOM_ACTIVE_USERS_REPORT,
  GET_LAST_RATE_QUESTIONS_REPORTS,
  GET_MASTER_USER_REPORT,
  GET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT,
  GET_PROFILE_SHEET_REPORT,
  GET_TOPICS_REPORT_FOR_RATES,
  GET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES,
  GET_USERS_COUNT,
  LATEST_CSV_CREATION_DATE,
  LATEST_CSV_CREATION_SUBSCRIPTIONS_DATE,
} from './actionTypes';

function* getBibliographyReportForGivenRates(action) {
  try {
    const csvReport = yield call(
      getBibliographyReportForGivenRatesServiceCaller,
      action.rates,
      action.selectedRatingExams,
      action.multiple,
    );
    yield put(getBibliographyReportForGivenRatesSuccess(csvReport));
  } catch (exception) {
    yield put(getBibliographyReportForGivenRatesError(exception.message));
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not generate report. Please try again later.',
      ),
    );
  }
}

function* getTopicsReportForGivenRates(action) {
  try {
    const csvReport = yield call(
      getTopicsReportForGivenRatesServiceCaller,
      action.rates,
      action.selectedRatingExams,
      action.multiple,
    );
    yield put(getTopicsReportForGivenRatesSuccess(csvReport));
  } catch (exception) {
    yield put(getTopicsReportForGivenRatesError(exception.message));
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not generate report. Please try again later.',
      ),
    );
  }
}

function* getFavoriteQuestionsReportForGivenRates(action) {
  try {
    let counter = action.loading;
    let arrayValues = action.csvReport;
    let arrayIds = action.arrayIds;
    do {
      const response = yield call(
        getFavoriteQuestionsReportForGivenRatesServiceCaller,
        action.rates,
        action.selectedRatingExams,
        counter,
        arrayIds,
      );
      yield put(countFavoriteReport(response.count));
      yield put(loadingCounterReport(counter));
      yield put(setCsvReport(response.csvReport));
      yield put(setArrayIdsReport(response.arrayIds));
      if (response.arrayIds && response.arrayIds.length > 0) {
        counter++;
        arrayValues = arrayValues.concat(response.csvReport);
        arrayIds = response.arrayIds;
      } else {
        counter = 0;
        yield put(getFavoriteQuestionsReportForGivenRatesSuccess(arrayValues));
        yield put(loadingCounterReport(1));
        yield put(setCsvReport([]));
        yield put(setArrayIdsReport([]));
      }
    } while (counter > 0);
  } catch (exception) {
    yield put(getFavoriteQuestionsReportForGivenRatesError(exception.message));
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not generate favorite questions report. Please try again later.',
      ),
    );
  }
}

function* getCompletionStatusSagas() {
  try {
    const completionStatusData = yield call(getCompletionStatus);
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not get completion status. Please try again later.',
      ),
    );
  }
}

function* fetchUsersCount(action) {
  try {
    yield put(callUsersCount());
    const usersCountResponse = yield call(getUsersCount, action.query);
    yield put(getUsersCountSuccess(usersCountResponse));
  } catch (exception) {
    yield put(getUsersCountError(exception.message));
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not get users count from server. Please try again later.',
      ),
    );
  }
}

function* getHistoricalUsersReportSagas(action) {
  try {
    const historicalUsersReportResponse = yield call(
      getHistoricalUsersReport,
      action.lastCustomer,
      action.startingDate,
      action.endingDate,
      action.lessCustomers,
    );
    yield put(setHistoricalUsersReport(historicalUsersReportResponse));
  } catch (error) {
    yield put(getHistoricalUsersReportError());
  }
}

function* getProfileSheetSagas(action) {
  try {
    const profileSheetReport = yield call(getProfileSheetReport, action.cycles);
    yield put(profileSheetCsvReport(profileSheetReport));
  } catch (exception) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message:
            'There was an error getting the report data. Please try again or contact the support',
        },
        'error',
      ),
    );
  }
}

function* getNumberOfQuestionsEnabledPerRatingExamReportSagas(action) {
  try {
    const numberOfQuestionsPerRatingExamResponse = yield call(
      getNumberOfQuestionsEnabledPerRatingExamReport,
      action.lastRate,
    );
    yield put(
      setNumberOfQuestionsEnabledPerRatingExamReport(
        numberOfQuestionsPerRatingExamResponse,
      ),
    );
  } catch (error) {
    yield put(setNumberOfQuestionsEnabledPerRatingExamReportError());
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message:
            'There was an error getting the report data. Please try again',
        },
        'error',
      ),
    );
  }
}

function* getMasterUserReportSagas(action) {
  try {
    const masterUserReportResponse = yield call(
      getMasterUserReport,
      action.lastCustomer,
    );
    yield put(setMasterUserReport(masterUserReportResponse));
  } catch (error) {
    yield put(setMasterUserReportError());
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message:
            'There was an error getting the report data. Please try again or contact the support',
        },
        'error',
      ),
    );
  }
}

function* downloadMasterUserReportSagas() {
  try {
    const downloadMasterUserReportResponse = yield call(
      downloadMasterUserReport,
    );
    yield put(setdownloadMasterUserReport(downloadMasterUserReportResponse));
  } catch (error) {
    yield put(setdownloadMasterUserReportError());
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message:
            'There was an error trying download the Master User Report. Please try again or contact the support',
        },
        'error',
      ),
    );
  }
}

function* downloadActiveSubscriptionsSaga() {
  try {
    const downloadResponse = yield call(downloadActiveSubscriptions);
    yield put(setdownloadActiveSubscriptions(downloadResponse));
  } catch (error) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message:
            'There was an error trying download the Master User Report. Please try again or contact the support',
        },
        'error',
      ),
    );
  }
}

function* latestCsvCreationDateSubscriptionsSagas() {
  try {
    const response = yield call(latestCsvCreationDateSubscriptions);
    yield put(setlatestCsvCreationDateSubscriptions(response));
  } catch (error) {
    yield put(
      Notifications.show(
        {
          title: 'Error',
          message:
            'There was an error getting the report data. Please try again or contact the support',
        },
        'error',
      ),
    );
  }
}

function* latestCsvCreationDateSagas() {
  try {
    const latestCsvCreationDateResponse = yield call(latestCsvCreationDate);
    yield put(setlatestCsvCreationDate(latestCsvCreationDateResponse));
  } catch (error) {
    yield put(setlatestCsvCreationDateError());
  }
}

function* getActiveSubscriptionsSagas(action) {
  try {
    const response = yield call(
      getActiveSubscriptionsService,
      action.lastCustomerSubscription,
      action.askingFor,
    );
    yield put(setActiveSubscriptionsErrorFalse());
    yield put(
      setActiveSubscriptions(
        response.csvData,
        response.hasMore,
        response.lastCustomerSubscription,
        response.askingFor,
      ),
    );
  } catch (err) {
    yield put(setActiveSubscriptionsError());
  }
}

function* getUserSubmittedQuestionsReportSagas(action) {
  try {
    yield put(setIsUserSubmittedQuestionsReportLoading(true));
    const response = yield call(
      getUserSubmittedQuestionsReportService,
      action.rates,
    );
    yield put(setUserSubmittedQuestionsReport(response));
  } catch (error) {
    yield put(setUserSubmittedQuestionsReportError(error.message));
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not generate user submitted questions report. Please try again later.',
      ),
    );
  } finally {
    yield put(setIsUserSubmittedQuestionsReportLoading(false));
  }
}

function* getIntercomActiveUsersReportSagas(action) {
  try {
    const response = yield call(
      getIntercomActiveUsersReportService,
      action.lastCustomerSubscription,
    );
    yield put(
      setIntercomActiveUsers(
        response.csvData,
        response.hasMore,
        response.lastCustomerSubscription,
      ),
    );
  } catch (err) {
    yield put(setIntercomActiveSubscriptionsError());
  }
}

function* generateRateQuestionsReport(action) {
  try {
    const response = yield call(
      generateRateQuestionsReportService,
      action.ratesIds,
    );
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: response.message,
          autoDismiss: 2,
        },
        'success',
      ),
    );
  } catch (err) {
    yield put(
      showNotificationErrorFromException(
        err,
        'Error generating rate questoins report',
      ),
    );
  }
}

function* getRateQuestionsReports() {
  try {
    const response = yield call(getRateQuestionsReportsService);
    yield put(setLastRateQuestionsReport(response));
  } catch (err) {
    yield put(showNotificationErrorFromException(err, 'Error'));
  }
}

export default function* sagas() {
  all([
    yield takeLatest(
      GET_BIBLIOGRAPHY_REPORT_FOR_RATES,
      getBibliographyReportForGivenRates,
    ),
    yield takeLatest(
      GET_FAVORITE_QUESTIONS_REPORT_FOR_RATES,
      getFavoriteQuestionsReportForGivenRates,
    ),
    yield takeLatest(GET_USERS_COUNT, fetchUsersCount),
    yield takeLatest(
      GET_HISTORICAL_USERS_REPORT,
      getHistoricalUsersReportSagas,
    ),
    yield takeLatest(GET_PROFILE_SHEET_REPORT, getProfileSheetSagas),
    yield takeLatest(GET_MASTER_USER_REPORT, getMasterUserReportSagas),
    yield takeLatest(
      DOWNLOAD_MASTER_USER_REPORT,
      downloadMasterUserReportSagas,
    ),
    yield takeLatest(
      DOWNLOAD_ACTIVE_SUBSCRIPTIONS_REPORT,
      downloadActiveSubscriptionsSaga,
    ),

    yield takeLatest(LATEST_CSV_CREATION_DATE, latestCsvCreationDateSagas),
    yield takeLatest(
      LATEST_CSV_CREATION_SUBSCRIPTIONS_DATE,
      latestCsvCreationDateSubscriptionsSagas,
    ),
    yield takeLatest(GET_ACTIVE_SUBSCRIPTIONS, getActiveSubscriptionsSagas),
    yield takeLatest(GET_COMPLETION_STATUS, getCompletionStatusSagas),
    yield takeLatest(
      GET_NUMBER_OF_QUESTIONS_ENABLED_PER_RATING_EXAM_REPORT,
      getNumberOfQuestionsEnabledPerRatingExamReportSagas,
    ),
    yield takeLatest(
      GET_USER_SUBMITTED_QUESTIONS_REPORT_FOR_RATES,
      getUserSubmittedQuestionsReportSagas,
    ),
    yield takeLatest(
      GET_INTERCOM_ACTIVE_USERS_REPORT,
      getIntercomActiveUsersReportSagas,
    ),
    yield takeLatest(GET_TOPICS_REPORT_FOR_RATES, getTopicsReportForGivenRates),
    yield takeLatest(
      GENERATE_RATE_QUESTIONS_REPORT,
      generateRateQuestionsReport,
    ),
    yield takeLatest(GET_LAST_RATE_QUESTIONS_REPORTS, getRateQuestionsReports),
  ]);
}

function showNotificationErrorFromException(exception, optionalMessage) {
  const message = optionalMessage || getRequestErrorMessage(exception);
  return Notifications.show({ title: 'Ops!', message }, 'error');
}
