import * as t from './actionTypes';

export const cyclesReceivedAction = response => ({
  type: t.CYCLESRECEIVED,
  payload: response,
});

export const createCycleReceivedAction = response => ({
  type: t.CYCLECREATION,
  payload: response,
});

export const updateCycleReceivedAction = response => ({
  type: t.CYCLEUPDATE,
  payload: response,
});

export const deleteCycleReceivedAction = response => ({
  type: t.CYCLEDELETE,
  payload: response,
});

export const updateSelectedCycleAction = cycle => ({
  type: t.UPDATESELECTEDCYCLE,
  cycle,
});

export const moveSubstitutesReceivedAction = response => ({
  type: t.SUBSTITUTESUPDATED,
  payload: response,
});

export const moveSelectedRatesReceivedAction = response => ({
  type: t.RATES_MOVED,
  payload: response,
});

export const activateCycleReceivedAction = response => ({
  type: t.CYCLEACTIVATED,
  payload: response,
});

export const setUpdatedCycle = isUpdated => ({
  type: t.SET_UPDATED_CYCLE,
  isUpdated,
});
