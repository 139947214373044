function UserFavoriteQuestionsBlock({
  userId,
  isUserBlocked,
  setUserFavoriteBlockStatus,
  isLoadingFavoriteBlockStatus,
}) {
  return (
    <>
      <article className="question__container">
        <span className="question__title">Favorite Questions</span>
        {isUserBlocked ? (
          <p>The user is blocked from saving questions as favorite.</p>
        ) : (
          <p>The user is allowed to save questions as favorite.</p>
        )}
        <div className="question__buttons__container">
          <button
            className="btn question__button--block"
            onClick={() => setUserFavoriteBlockStatus(userId, true)}
            disabled={isUserBlocked || isLoadingFavoriteBlockStatus}
          >
            Block
          </button>
          <button
            className="btn btn-primary"
            onClick={() => setUserFavoriteBlockStatus(userId, false)}
            disabled={!isUserBlocked || isLoadingFavoriteBlockStatus}
          >
            Unblock
          </button>
        </div>
      </article>
      <div className="separatorLine" />
    </>
  );
}

export default UserFavoriteQuestionsBlock;
