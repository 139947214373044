import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'questions');
}

export const getReference = (state) => getState(state).get('reference');
export const getChapter = (state) => getState(state).get('chapter');
export const getWaiting = (state) => getState(state).get(`waiting`);
export const getQuestions = (state) =>
  getState(state).get(`questions`).toArray();
export const getQuestionsImmutable = (state) =>
  getState(state).get(`questions`);
export const getAllQuestions = (state) => getState(state).get(`allQuestions`);
export const getPagination = (state) => getState(state).get(`pagination`);
export const getIsLoadingQuestions = (state) =>
  getState(state).get(`isLoadingQuestions`);
export const getPublishedQuestion = (state) =>
  getState(state).get(`publishedQuestion`);
export const getWaitingPdf = (state) => getState(state).get(`waitingPdf`);
export const getQuestionPdfProcessing = (state) =>
  getState(state).get(`questionPdfProcessing`);
export const getSelectedView = (state) => getState(state).get(`selectedView`);
export const getSelectedQuestion = (state) =>
  getState(state).get(`selectedQuestion`);
export const getIsSaveQuestion = (state) =>
  getState(state).get(`isSaveQuestion`);
export const getIsRefreshForm = (state) => getState(state).get(`isRefreshForm`);
export const getIsShowDeletedQuestions = (state) =>
  getState(state).get(`isShowDeletedQuestions`);
export const getUrlQuestion = (state) => getState(state).get(`urlQuestion`);
export const getCurrentPage = (state) => getState(state).get(`currentPage`);
export const getCreateAnother = (state) => getState(state).get('createAnother');
export const getUpdating = (state) => getState(state).get(`updating`);
export const getEditPermission = (state) =>
  getState(state).get(`editPermission`);
export const getAmountOfQuestions = (state) =>
  getState(state).get(`amountOfQuestions`);
export const getQuestionsToDownload = (state) =>
  getState(state).get(`questionsToDownload`);
export const getCreatingAiQuestion = (state) =>
  getState(state).get(`isCreatingAiQuestion`);
export const getUserCustomPromptSelector = (state) =>
  getState(state).get(`userCustomPrompts`);
export const getQuestionsForDeleted = (state) =>
  getState(state).get(`questionsForDeleted`);
export const getSelectedQuestionHistory = (state) =>
  getState(state).get(`questionHistory`);
export const getShowQuestionHistoryModal = (state) =>
  getState(state).get(`showQuestionHistoryModal`);
