import { getStateWithOnlyOneLevel } from '../../utilities/StateSelectorHelper';

function getState(state) {
  return getStateWithOnlyOneLevel(state, 'references');
}

export const getFilter = state => getState(state).get('filter');

export const getDutyStateReferences = state =>
  getState(state).get('dutyStateReferences');

export const getRankReferences = state => getState(state).get('rankReferences');
