import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from 'react-bootstrap-button-loader';

import './CreateRate.css';
import { InputWithoutIcon, Input, Textarea } from '../../Input/Input';

class CreateRate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      waiting: false,
    };
  }

  componentWillReceiveProps({ submitSucceeded }) {
    if (submitSucceeded && this.state.waiting) {
      this.setState({ waiting: false });
    }
  }

  validate = (fields, callback) => {
    let errors = {};

    if (!fields.name) {
      errors.name = 'Title field is required';
    }

    if (!fields.description) {
      errors.description = 'Description field is required';
    }

    if (!this.props.isQual && !fields.shortname) {
      errors.shortname = 'Shortname field is required';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: null, waiting: true });
    callback(fields);
  };

  renderInput(name, placeholder, type = 'text', icon, acceptFile) {
    return (
      <div className="form-group">
        {type !== 'checkbox' && <span className="label">{placeholder}:</span>}
        {type !== 'checkbox' &&
          type !== 'textarea' &&
          <Field
            name={name}
            type={type}
            component={Input}
            placeholder={placeholder}
            icon={icon}
            accept={acceptFile}
          />}
        {type === 'checkbox' &&
          <Field
            name={name}
            type={type}
            component={InputWithoutIcon}
            placeholder={placeholder}
          />}
        {type === 'textarea' &&
          <Field name={name} component={Textarea} placeholder={placeholder} />}
        {this.state.errors &&
          this.state.errors[name] &&
          <span className="errors">{this.state.errors[name]}</span>}
      </div>
    );
  }

  render() {
    const { handleSubmit, onSubmit, onCancel, isQual } = this.props;
    let descriptionText = isQual ? 'Qual description' : 'Rate description';
    let nameText = isQual ? 'Qual name' : 'Rate name';
    return (
      <div className="create-rate-component">
        <div className="card-block">
          <form
            className="widget-form new-admin-form"
            onSubmit={handleSubmit(fields => this.validate(fields, onSubmit))}
          >
            {this.renderInput('name', nameText, 'text', null, false)}
            {this.renderInput('description', descriptionText, 'textarea', null)}
            {!isQual &&
              this.renderInput('shortname', 'Shortname', 'text', null)}
            {!isQual &&
              this.renderInput(
                'isPmkOnly',
                'Pmk only',
                'checkbox',
                'book-open'
              )}
            {this.state.errors &&
              this.state.errors.general &&
              <div className="errors">{this.state.errors.general}</div>}

            <div className="bottom-buttons">
              <Button className="btn btn-secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                className="btn btn-success"
                type="submit"
                loading={this.state.waiting}
              >
                Create
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: 'CreateRate' })(CreateRate);
