export function exportToCsv(filename, rows) {
  let processRow = function (row) {
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
      let innerValue =
        typeof row[j] === 'undefined' || row[j] === null
          ? ''
          : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/\r?\n|\r/g, ' ');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  let csvFile = '';
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }
  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function downloadXLSX(questions, format, referenceId = '') {
  const res = [];
  const maxColWidth = [0, 0, 0, 0, 0, 0];
  const chapter = questions[0].chapter ? questions[0].chapter : 0;
  let downloadfilename = format === 'xls' ? 'download.xls' : 'download.xlsx';

  if (questions && questions.length > 0) {
    questions.forEach((item, index) => {
      let correct = '';
      const incorrects = [];
      const flashmode = item.flashMode ? 1 : 0;
      const flashModeOnly = item.multipleOptionMode ? 0 : 1;
      const notes = item.notes;
      item.answers.forEach((answer) => {
        answer.correct ? (correct = answer.text) : incorrects.push(answer.text);
      });

      if (flashmode) {
        incorrects[0] = '';
        incorrects[1] = '';
        incorrects[2] = '';
      } else {
        incorrects[0] = incorrects[0] === undefined ? '' : incorrects[0];
        incorrects[1] = incorrects[1] === undefined ? '' : incorrects[1];
        incorrects[2] = incorrects[2] === undefined ? '' : incorrects[2];
      }

      const documentPage = item.documentPage
        ? `'${item.documentPage}`
        : item.documentPage;

      const row = {
        question: item.text,
        correctanswer: correct,
        incorrectanswer_0: incorrects[0],
        incorrectanswer_1: incorrects[1],
        incorrectanswer_2: incorrects[2],
        notes: notes,
        softwarepage: item.softwarePage,
        flashmode: flashmode,
        flashmodeonly: flashModeOnly,
        documentpage: documentPage,
      };

      //for adapting column sizes

      if (item.text.length > maxColWidth[0]) {
        maxColWidth[0] = item.text.length;
      }
      if (correct.length > maxColWidth[1]) {
        maxColWidth[1] = correct.length;
      }
      if (incorrects[0].length > maxColWidth[2]) {
        maxColWidth[2] = incorrects[0].length;
      }
      if (incorrects[1].length > maxColWidth[3]) {
        maxColWidth[3] = incorrects[1].length;
      }
      if (incorrects[2].length > maxColWidth[4]) {
        maxColWidth[4] = incorrects[2].length;
      }
      if (notes.length > maxColWidth[5]) {
        maxColWidth[5] = item.text.length;
      }

      res.push(row);
    });

    if (res.length > 0) {
      downloadfilename =
        format === 'xls'
          ? `questions-from-reference-${
              referenceId
                ? referenceId
                : chapter && chapter.reference
                ? chapter.reference
                : ''
            }-chapter-${chapter.id}.xls`
          : `questions-from-reference-${
              referenceId
                ? referenceId
                : chapter && chapter.reference
                ? chapter.reference
                : ''
            }-chapter-${
              typeof chapter == 'number' ? chapter : chapter.id
            }.xlsx`;
    }

    const ws = window.XLSX.utils.json_to_sheet(res);
    const wb = window.XLSX.utils.book_new();
    const wscols = [];

    maxColWidth.forEach((value, index) => {
      wscols.push({ wch: maxColWidth[index] > 0 ? maxColWidth[index] : 10 });
    });

    for (let i = 0; i < 4; i++) {
      wscols.push({ wch: 13 });
    }

    ws['!cols'] = wscols;

    const nameToAppendSS = format === 'xls' ? 'download.xls' : 'download.xlsx';

    window.XLSX.utils.book_append_sheet(wb, ws, nameToAppendSS);
    window.XLSX.writeFile(wb, downloadfilename);
  }
}

export function downloadCSV(questions) {
  const questionsChapter = questions;
  const res = [];
  let chapter;
  res.push([
    'questionID',
    'AI_question',
    'AI_question_change',
    'AI_answer',
    'AI_answer_change',
    'question',
    'correctanswer',
    'incorrectanswer_0',
    'incorrectanswer_1',
    'incorrectanswer_2',
    'notes',
    'softwarepage',
    'flashmode',
    'flashmodeonly',
    'documentpage',
    'link',
  ]);
  const questionsWithAI = questionsChapter.filter(
    (item) => item.wasAIGenerated === true,
  );
  const questionsWithoutAI = questionsChapter.filter(
    (item) => item.wasAIGenerated === false,
  );
  const sortedQuestions = [...questionsWithAI, ...questionsWithoutAI];
  sortedQuestions.forEach((item) => {
    let correct = '';
    const incorrects = [];
    const flashmode = item.flashMode ? 1 : 0;
    const flashModeOnly = item.multipleOptionMode ? 0 : 1;
    const notes = item.notes;
    item.answers.forEach((answer) => {
      answer.correct ? (correct = answer.text) : incorrects.push(answer.text);
    });
    // we add a ' at the beginning of the document page to avoid excel to use this column as date
    const documentPage = item.documentPage
      ? `'${item.documentPage}`
      : item.documentPage;

    const text = item.flashMode === true ? item.flashQuestionText : item.text;
    let aiQuestionChange = '';
    let aiQuestion = '';
    let aiAnswer = '';
    let aiAnswerChange = '';
    if (item.wasAIGenerated) {
      item.answers.map((answer) => {
        if (answer.wasAIGenerated && answer.correct) {
          aiAnswerChange = answer.substantialChanges
            ? answer.substantialChanges
            : '';
        }
      });
    }
    if (item.originalResponse) {
      aiQuestion = item.originalResponse.ai_question;
      aiAnswer = item.originalResponse.ai_answer;
      aiQuestionChange = item.substantialChanges ? item.substantialChanges : '';
      aiAnswerChange = aiAnswerChange;
    }

    res.push([
      item.id,
      aiQuestion,
      aiQuestionChange,
      aiAnswer,
      aiAnswerChange,
      text,
      correct,
      incorrects[0],
      incorrects[1],
      incorrects[2],
      notes,
      item.softwarePage,
      flashmode,
      flashModeOnly,
      documentPage,
      item.link,
    ]);
    chapter = item.chapter;
  });

  let downloadfilename = 'download.csv';
  if (res.length > 0 && chapter) {
    downloadfilename = `questions-from-reference-${
      chapter && chapter.reference ? chapter.reference : ''
    }-chapter-${chapter.id}.csv`;
  }
  exportToCsv(downloadfilename, res);
}

export function equalArray(a1, a2) {
  return a1.length === a2.length && a1.every((v, i) => v === a2[i]);
}

export function hasDownloadCSV(row) {
  return row.findIndex((data) => data === 'Books.DownloadCSV') !== -1
    ? true
    : false;
}

export function allowRolesPermissions(rolesPermissions) {
  const permission = [];
  rolesPermissions.map((role) => {
    permission.push(hasDownloadCSV(role.actions));
    return role;
  });
  return permission.some((elem) => elem === true);
}
