import * as t from './actionTypes';

const initialState = {
  requesting: false,
  successful: false,
  messages: [],
  errors: [],
  errorMessage: false,
  requestResponse: {},
};

const reducer = function resetPasswordReducer(state = initialState, action) {
  switch (action.type) {
    // Set the requesting flag and append a message to be shown
    case t.RESET_PASSWORD_REQUESTING:
      return {
        requesting: true,
        successful: false,
        messages: [{ body: 'Loading...', time: new Date() }],
        errors: [],
      };
    case t.RESET_ERRORS:
      return {
        errors: [],
        messages: [],
        requesting: false,
        successful: false,
        errorMessage: false,
      };
    // Successful?  Reset the login state.
    case t.RESET_PASSWORD_ENDED:
      return {
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        errorMessage: false,
      };
    case t.REQUEST_RESPONSE:
      return {
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        errorMessage: false,
        requestResponse: action.response.data,
      };
    case t.RESET_PASSWORD_ERROR_USER:
      return {
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        errorMessage: true,
      };
    // Append the error returned from our api
    // set the success and requesting flags to false
    case t.RESET_PASSWORD_ERROR:
      return {
        errors: state.errors.concat([
          {
            body: action.message.message
              ? action.message.message
              : action.message,
            time: new Date(),
          },
        ]),
        messages: [],
        requesting: false,
        successful: false,
      };

    default:
      return state;
  }
};

export default reducer;
