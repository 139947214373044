import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxList from '../../CheckBoxList/CheckBoxList';
import Button from 'react-bootstrap-button-loader';
import './UpdateChapterSubtopics.css';
import Spinner from '../../Spinner/Spinner';

export default class UpdateSubtopicChapters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chapters: this.props.chapters,
      allChecked: false,
      showContent: false,
      cacheUpdatedChapters: [],
    };
  }

  componentWillMount() {
    this.props.loadChaptersByRate(this.props.rate);
  }

  componentWillReceiveProps({ chapters }) {
    if (chapters.length >= 0) {
      const checkableChapters = this.getCheckableChapters(chapters);
      this.setState({
        chapters: checkableChapters,
        showContent: true,
      });
    }
  }

  getCheckableChapters = chapters => {
    const subtopicChapters = this.props.subtopic.chapters.map(c => c.id);
    return chapters.map(chapter => ({
      id: chapter.id,
      name: chapter.referenceName + ' >> ' + chapter.name,
      checked: subtopicChapters.includes(chapter.id),
    }));
  };

  onCheckChange = id => {
    const chapters = this.state.chapters.map(chapter => {
      if (chapter.id === parseInt(id, 10)) {
        chapter.checked = !chapter.checked;
      }
      return chapter;
    });
    this.setState({ chapters: chapters });
  };

  onUpdate = () => {
    const { subtopic } = this.props;
    const checkedChapters = this.state.chapters.filter(c => c.checked);

    // Cache the chapters updated during this Topic, store topic, subtopic, chaptersUpdated
    const chaptersUpdatedThisTopic = [...this.props.chaptersUpdatedThisTopic];
    chaptersUpdatedThisTopic.push({
      subtopic: Object.assign({}, subtopic),
      chapters: [...checkedChapters],
    });
    this.props.onSetUpdatedChaptersCache(chaptersUpdatedThisTopic, subtopic.id);
    this.props.updateSubtopicChapters(subtopic, checkedChapters.map(c => c.id));
    this.props.onCancel();
  };

  render() {
    return (
      <div className="update-subtopics-component">
        <div className="card-block">
          {!this.state.showContent ? (
            <Spinner text="Loading subtopics" />
          ) : this.state.chapters.length ? (
            <div>
              <div className="widget-form new-admin-form form-update-subtopic-chapters">
                <CheckBoxList
                  items={this.state.chapters}
                  onChange={this.onCheckChange}
                />
              </div>
              <div className="separatorLine" />
              <div className="bottom-buttons">
                <Button className="btn btn-success" onClick={this.onUpdate}>
                  Add
                </Button>
                <Button
                  className="btn btn-secondary"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div className="center-text no-chapters">
                There are no chapters in this rate
              </div>
              <div className="separatorLine" />
              <div className="bottom-buttons">
                <Button
                  className="btn btn-secondary"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

UpdateSubtopicChapters.propTypes = {
  chapters: PropTypes.array,
  subtopic: PropTypes.object,
  rate: PropTypes.number,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  loadChaptersByRate: PropTypes.func,
};
