import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './QualInformation.css';
import Spinner from '../../../../commonComponents/Spinner/Spinner';
import ReferencesGridAccordion from '../../../../commonComponents/ReferencesGridAccordion/ReferencesGridAccordion';
import ReferencesGridAccordionLib from '../../../../commonComponents/ReferencesGridAccordion/ReferencesGridAccordionLib';
import ChapterGridAccordionLib from '../../../../commonComponents/ReferencesGridAccordion/ChapterGridAccordionLib';
import CreateReference from '../../../../commonComponents/Modals/CreateReference/CreateReference';
import SimpleConfirmation from '../../../../commonComponents/Modals/SimpleConfirmation/SimpleConfirmation';
import DefinitionsContainer from '../../../Definitions/components/DefinitionsContainer';
import ConfirmDeletion from '../../../../commonComponents/Modals/ConfirmDeletion/ConfirmDeletion';
import CopyReference from '../../../../commonComponents/Modals/CopyReference/CopyReference';
import CreateChapter from '../../../../commonComponents/Modals/CreateChapter/CreateChapter';
import ManualRedModal from '../../../../commonComponents/Modals/ConfirmationReason/ConfirmationReason';
import WritersContainer from '../../../Writers/components/WritersContainer';
import AssignWritersContainer from '../../../Books/components/AssignWritersContainer';

class QualInformation extends React.Component {
  state = {
    header: 'All',
  };

  componentWillMount = () => {
    if (this.props.selectedQual) {
      this.props.loadReferences(this.props.selectedQual.id);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.selectedQual &&
      nextProps.selectedQual !== this.props.selectedQual
    ) {
      this.props.loadReferences(nextProps.selectedQual.id);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.urlPdfPrivate !== this.props.urlPdfPrivate) {
      window.open(this.props.urlPdfPrivate, '_blank');
    }
  };

  getChaptersForGrid = () => {
    let functions = {
      onDeletePdfChapter: (chapter) => {
        this.openSimpleConfirmation(
          'Delete pdf',
          this.getDeleteText(chapter),
          () => {
            this.props.deletePdfChapter(chapter);
          },
        );
      },
      onStateChapterClick: this.props.changeStatusChapter,
      onChangeQuestionsIndicator: this.onChangeQuestionsIndicator,
      onMoveChapters: this.props.onMoveChapters,
      onChangeSettingsChapter: this.onChangeSettingsChapter,
    };

    return ChapterGridAccordionLib.getChaptersForGrid(
      this.props.chapters,
      this.props.references,
      functions,
      true,
      this.props.selectedQual.id,
      this.props.rolesPermissions,
      this.props.user.isSuperAdmin,
    );
  };

  getReferencesForGrid = (references, chapters) => {
    let functions = {
      onDeletePdf: (reference) => {
        this.openSimpleConfirmation(
          'Delete pdf',
          this.getDeleteText(reference),
          () => {
            this.props.deleteReferencePdf(reference);
          },
        );
      },
      onStateClick: (reference) => {
        this.props.changeReferenceStatus(reference);
      },
      onDefinitionsClick: (reference) => {
        this.openModal('Definitions', this.getDefinitionsContent(reference));
      },
      onChangeSettingsReference: this.onChangeSettingsReference,
      onAddChapter: this.showAddChapterModal,
      onChangeQuestionsIndicator: this.onChangeQuestionsIndicator,
    };
    return ReferencesGridAccordionLib.getReferencesForGrid(
      this.props.references,
      this.props.chapters,
      functions,
      this.props.selectedQual.id,
      this.props.rolesPermissions,
      this.props.user.isSuperAdmin,
    );
  };

  getDeleteText = (item) => {
    return (
      <div>
        {`Are you sure you want to delete pdf file`}
        <strong>{item.name}</strong>?
      </div>
    );
  };

  getDefinitionsContent = (reference) => {
    return (
      <DefinitionsContainer
        closeModal={this.props.closeModal}
        reference={reference}
      />
    );
  };

  toggleArchived = (page) => {
    if (page !== this.state.header) {
      this.props.loadReferences(
        this.props.selectedQual.id,
        page === 'Archived',
      );
      this.setState({ header: page });
    }
  };

  openModal = (title, content) => {
    this.props.openModal({
      modalTitle: title,
      modalContent: content,
      modalClassName: 'modal-create-chapter',
      className: '',
    });
  };

  openSimpleConfirmation = (title, text, onConfirm) => {
    let content = [
      <SimpleConfirmation
        onConfirm={onConfirm}
        onCancel={this.props.closeModal}
        text={text}
      />,
    ];
    this.openModal(title, content);
  };

  showCreateReferenceModal = () => {
    let content = [
      <CreateReference
        onSubmit={(fields) => {
          this.props.createReference(fields, this.props.selectedQual);
        }}
        onCancel={this.props.closeModal}
      />,
    ];
    this.openModal('Create a reference', content);
  };

  showEditReferenceModal = (reference) => {
    let content = [
      <CreateReference
        onSubmit={(fields) => this.props.updateReference(fields, reference.id)}
        onCancel={this.props.closeModal}
        edit
        reference={reference}
      />,
    ];
    this.openModal('Edit reference', content);
  };

  showDeleteReferenceModal = (reference) => {
    let content = [
      <ConfirmDeletion
        onSubmit={() => this.props.deleteReference(reference)}
        message={`You are about to delete the reference ${reference.name} .`}
        onCancel={this.props.closeModal}
      />,
    ];
    this.openModal('Delete reference', content);
  };

  showCopyReferenceModal = (reference) => {
    let content = [
      <CopyReference
        onSubmit={(fields) => this.props.copyReference(fields)}
        onCancel={this.props.closeModal}
        reference={reference.id}
        selectedSP={this.props.selectedQual}
        isQual={true}
      />,
    ];
    this.openModal('Copy reference to another qual', content);
  };

  showAddChapterModal = (reference) => {
    let content = [
      <CreateChapter
        onSubmit={(fields) => this.props.createChapter(fields)}
        onCancel={this.props.closeModal}
        addChapter
        references={this.props.references}
        reference={reference}
      />,
    ];
    this.openModal('Create chapter', content);
  };

  showAssignWritterModal = (reference, chapter) => {
    let content = [
      <WritersContainer
        closeModal={this.props.closeModal}
        chapter={chapter}
        reference={reference}
        rate={this.props.selectedQual.id}
        isQual={true}
      />,
    ];
    this.openModal('Assign writers to reference', content);
  };

  onChangeQuestionsIndicator = (event, child, chapter, textMenu) => {
    if (textMenu === 'Set manual red') {
      let content = [
        <ManualRedModal
          onSubmit={(fields) =>
            this.props.setManualRedQuestions(chapter, fields)
          }
          onCancel={this.props.closeModal}
          bodyText={
            "You are about to set manual red for chapter '" +
            chapter.name +
            "' questions"
          }
          reasonField={'questionIndicatorReason'}
        />,
      ];
      this.openModal('Add a reason', content);
    } else {
      this.props.removeManualRedQuestions(chapter);
    }
  };

  onChangeSettingsReference = (event, child, reference) => {
    let action = child.props.children;

    switch (action) {
      case 'Edit':
        this.showEditReferenceModal(reference);
        break;

      case 'Delete':
        this.showDeleteReferenceModal(reference);
        break;

      case 'Archive':
      case 'Unarchive':
        this.props.archiveReference(reference, 'other');
        break;

      case 'Copy':
        this.showCopyReferenceModal(reference);
        break;

      case 'Assign writer':
        this.showAssignWritterModal(reference, null);
        break;

      default:
        return;
    }
  };

  showDeleteChapterModal = (chapter, reference) => {
    let content = [
      <ConfirmDeletion
        onSubmit={() =>
          this.props.deleteChapter(
            reference.id,
            chapter.id,
            chapter.presentationIndex,
          )
        }
        message={`You are about to delete the chapter ${chapter.name} .`}
        onCancel={this.props.closeModal}
      />,
    ];
    this.openModal('Delete chapter', content);
  };

  showEditChapterModal = (chapter, reference) => {
    let content = [
      <CreateChapter
        onSubmit={(fields) => this.props.updateChapter(fields, chapter.id)}
        onCancel={this.props.closeModal}
        edit
        references={this.props.references}
        chapter={chapter}
        chaptersOfReference={reference.chapters.length}
      />,
    ];
    this.openModal('Edit chapter', content);
  };

  showDeleteAllQuestionsModal = (chapter, reference) => {
    let content = [
      <ConfirmDeletion
        onSubmit={() => this.props.deleteAllQuestions(chapter.id)}
        message={`You are about to delete all questions of chapter ${chapter.name} .`}
        onCancel={this.props.closeModal}
      />,
    ];
    this.openModal('Delete all questions of chapter', content);
  };

  onChangeSettingsChapter = (event, child, chapter, reference) => {
    let action = child.props.children;

    switch (action) {
      case 'Edit':
        this.showEditChapterModal(chapter, reference);
        break;

      case 'Delete':
        this.showDeleteChapterModal(chapter, reference);
        break;

      case 'Delete all questions':
        this.showDeleteAllQuestionsModal(chapter, reference);
        break;

      case 'Assign writer':
        this.showAssignWritterModal(reference, chapter);
        break;

      default:
        return;
    }
  };

  onUpdatePdf = (pdf, id, isReference) => {
    if (pdf && pdf.length > 0) {
      pdf = pdf[0];
    }
    if (isReference) {
      this.props.updateReference({ pdf }, id);
    } else {
      this.props.updateChapter({ pdf }, id);
    }
  };

  showAssignModal = (reference, chapter) => {
    let filteredReference = Object.assign({}, reference);
    if (chapter) {
      filteredReference.chapters = reference.chapters.filter(
        (c) => c.id === chapter.id,
      );
    }
    this.props.openModal({
      modalContent: (
        <AssignWritersContainer
          title="Assign"
          closeModal={this.props.closeModal}
          rate={this.props.selectedQual.id}
          forAssign={true}
          isQual={true}
          references={[filteredReference]}
          selectedStudyProgram={this.props.selectedQual}
        />
      ),
      modalClassName: 'modal-create-chapter grey-modal-body',
    });
  };

  render() {
    let { selectedQual, loading } = this.props;
    return (
      <Col xs={6} md={9} className="quals-content-container">
        {selectedQual && (
          <div>
            {loading ? (
              <div className="quals-spinner">
                <Spinner text="Loading quals" />
              </div>
            ) : (
              <Row>
                <Col xs={12} md={12}>
                  <div className="gridContainer">
                    <ReferencesGridAccordion
                      references={this.props.references}
                      getUrlPdf={this.props.getUrlPdf}
                      urlPdfPrivate={this.props.urlPdfPrivate}
                      createReference={this.props.createReference}
                      createChapter={this.props.createChapter}
                      updateReference={this.props.updateReference}
                      deleteReference={this.props.deleteReference}
                      updateChapter={this.props.updateChapter}
                      deleteChapter={this.props.deleteChapter}
                      associateRates={this.props.associateRates}
                      isQual={true}
                      openModal={this.props.openModal}
                      closeModal={this.props.closeModal}
                      selectedQual={this.props.selectedQual}
                      rolesPermissions={this.props.rolesPermissions}
                      user={this.props.user}
                      isSuperAdmin={
                        this.props.user ? this.props.user.isSuperAdmin : false
                      }
                      generalRolesPermissions={
                        this.props.generalRolesPermissions
                      }
                      onUpdatePdf={this.onUpdatePdf}
                      onAssign={this.showAssignModal}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </div>
        )}
      </Col>
    );
  }
}

export default QualInformation;
