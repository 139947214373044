const couponsSagaNamespace = 'studyPlansUsers-saga';
export const LOAD_STUDY_PLANS = `${couponsSagaNamespace}/LOAD_STUDY_PLANS`;
export const CREATE_STUDY_PLAN = `${couponsSagaNamespace}/CREATE_STUDY_PLAN`;
export const EDIT_STUDY_PLAN = `${couponsSagaNamespace}/EDIT_STUDY_PLAN`;
export const UPDATE_START_DATE = `${couponsSagaNamespace}/UPDATE_START_DATE`;
export const UPDATE_EMAIL = `${couponsSagaNamespace}/UPDATE_EMAIL`;
export const LOAD_STUDY_PLANS_USERS = `${couponsSagaNamespace}/LOAD_STUDY_PLANS_USERS`;
export const EDIT_STUDY_PLAN_USER = `${couponsSagaNamespace}/EDIT_STUDY_PLAN_USER`;
export const DELETE_STUDY_PLAN_USER = `${couponsSagaNamespace}/DELETE_STUDY_PLAN_USER`;
export const SEND_STUDY_PLAN_PREVIEW = `${couponsSagaNamespace}/SEND_STUDY_PLAN_PREVIEW`;
