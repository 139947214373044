import { connect } from 'react-redux';
import {
  getActiveStatus,
  getSelectedAdmin,
  getAssignmentsCount,
} from '../../selectors';
import StatusFilters from './StatusFilters';
import { setActiveStatus } from '../../actions';

const mapStateToProps = (state, ownProps) => {
  return {
    activeStatus: getActiveStatus(state),
    selectedAdmin: getSelectedAdmin(state),
    assignmentsCount: getAssignmentsCount(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onStatusChange: status => {
      dispatch(setActiveStatus(status));
    },
  };
};

const StatusFiltersContainer = connect(mapStateToProps, mapDispatchToProps)(
  StatusFilters
);

export default StatusFiltersContainer;
