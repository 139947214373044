import './PromptListStyle.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';

import { SimpleInput } from '../../../commonComponents/Input/Input';
import ConfirmDeletion from '../../../commonComponents/Modals/ConfirmDeletion/ConfirmDeletion';
import addAbilityToOpenModal from '../../../utilities/modalHOC';

class PromptCreateView extends React.Component {
  extractStateFromProps() {
    return {
      viewPrompt: false,
      body: this.props.promptDetails ? this.props.promptDetails.body : '',
      name: this.props.promptDetails ? this.props.promptDetails.name : '',
      active: this.props.promptDetails
        ? this.props.promptDetails?.active
        : false,
      dateEdit: this.props.promptDetails
        ? this.props.promptDetails?.expiryAt
        : null,
      expiryAt: null,
      createMode: false,
      typePrompt: this.props.promptDetails ? this.props.promptDetails.type : '',
    };
  }

  generateDefaultState() {
    return {
      viewPrompt: false,
      body: '',
      name: '',
      active: false,
      dateEdit: null,
      expiryAt: null,
      createMode: true,
      typePrompt: this.props.promptDetails ? this.props.promptDetails.type : '',
    };
  }

  constructor(props) {
    super(props);

    this.state = this.extractStateFromProps();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.promptDetails != this.props.promptDetails) {
      if (this.props.promptDetails && !this.props.createMode) {
        this.setState(this.extractStateFromProps());
      }
    }
    if (
      prevProps.createMode != this.props.createMode &&
      this.props.createMode
    ) {
      this.setState(this.generateDefaultState());
    }
  }

  createPrompt() {
    const prompt = {
      body: this.state.body,
      name: this.state.name,
      type: this.props.activeTab,
      active: false,
    };

    this.props.createPrompt(prompt);
  }

  saveChanges() {
    const prompt = {
      body: this.state.body,
      name: this.state.name,
      active: this.state.active ? this.state.active : false,
      type: this.props.promptDetails?.type,
      id: this.props.promptDetails?.id,
    };
    this.props.editPrompt(prompt);
  }

  cancelButton() {
    this.props.loadPromptDetails(false);
  }

  promptDelete = (prompt) => {
    this.props.openModal({
      modalTitle: `Deleting Prompt: ${prompt.name}`,
      modalContent: (
        <ConfirmDeletion
          text={<span>Enter the word DELETE to confirm deletion.</span>}
          onSubmit={() => {
            this.props.closeModal();
            this.props.deletePrompt(prompt.id);
          }}
          onCancel={this.props.closeModal}
        />
      ),
    });
  };

  render() {
    const unsavedChanges = !(
      this.props.promptDetails.body == this.state.body &&
      this.props.promptDetails.name == this.state.name
    );
    return (
      <Row className="cardCreatePrompt">
        <Col md={12}>
          <div>
            <h5>
              <b>
                {this.props.createMode
                  ? `Create ${this.props.activeTab} New Prompt`
                  : ` Edit ${this.state.typePrompt} Prompt`}
              </b>
            </h5>
            <p className="lastModifiedLabel">
              {!this.props.createMode &&
                !unsavedChanges &&
                `Last modified: ${new Date(
                  this.props.promptDetails.updatedAt,
                ).toLocaleString('en-US')}`}
              {!this.props.createMode && unsavedChanges && `(Unsaved changes)`}
              {this.props.createMode && <br />}
            </p>
          </div>
          <div>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={6}>
                    <span>Name</span>
                    <SimpleInput
                      value={this.state.name}
                      onChange={(e) => {
                        this.setState({
                          name: e.target.value.substring(0, 64),
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={12}>
                <span>Body</span>
                <textarea
                  value={this.state.body}
                  onChange={(e) => {
                    this.setState({ body: e.target.value });
                  }}
                  className="form-control promptBody"
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={12}>
          <div className="buttonContainer">
            {this.props.createMode && (
              <Button
                type="submit"
                className="btn-primary btnSave"
                onClick={() => {
                  this.createPrompt();
                }}
                disabled={!this.state.name || !this.state.body}
              >
                Create
              </Button>
            )}

            {!this.props.createMode && (
              <Button
                type="submit"
                className="btn-primary btnSave"
                onClick={() => {
                  this.saveChanges();
                }}
                disabled={
                  !this.state.name ||
                  !this.state.body || // if there are no changes
                  !unsavedChanges
                }
              >
                Save Changes
              </Button>
            )}

            {!this.props.createMode && !this.props.promptDetails.active && (
              <Button
                className="btn-success btnSetActive"
                onClick={() => {
                  this.props.activePrompt(this.props.promptDetails.id, true);
                }}
                disabled={unsavedChanges}
              >
                Set as Active prompt
              </Button>
            )}
            {!this.props.createMode && this.props.promptDetails.active && (
              <Button
                type="submit"
                className="btn-danger btnUnsetActive"
                onClick={() => {
                  this.props.activePrompt(this.props.promptDetails.id, false);
                }}
                disabled={unsavedChanges}
              >
                Unset Active prompt
              </Button>
            )}
            {!this.props.createMode && (
              <Button
                type="submit"
                className="btn-danger btnUnsetActive"
                onClick={() => {
                  this.promptDelete(this.props.promptDetails);
                }}
              >
                Remove
              </Button>
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

export default addAbilityToOpenModal(PromptCreateView);
