import React from 'react';
import Button from 'react-bootstrap-button-loader';

function FilterItemNormalMode({
  item,
  onDelete,
  waiting,
  openEditModal,
  onClick,
  active,
}) {
  return (
    <span>
      <span
        onClick={() => onClick(item)}
        className={active ? 'eyeContainer active' : 'eyeContainer'}
      >
        <i
          className={active ? 'fa fa-eye active' : 'fa fa-eye'}
          aria-hidden="true"
        />
      </span>
      <span className="infoSection">
        <span className="label">Assignment:</span>
        <span className="value">{item.book_status}</span>
        {item.dutystate && item.book_status === 'Assigned to bibs' && (
          <span className="label">Service:</span>
        )}
        {item.dutystate && item.book_status === 'Assigned to bibs' && (
          <span className="value">{item.dutystate}</span>
        )}
        {item.dutystate && item.book_status === 'Assigned to bibs' && (
          <span className="label">Rank:</span>
        )}
        {item.dutystate && item.book_status === 'Assigned to bibs' && (
          <span className="value">{item.rank}</span>
        )}
      </span>
      <span className="tree-options">
        <i
          className="fa fa-pencil icon-button"
          onClick={(ev) => {
            ev.stopPropagation();
            openEditModal();
          }}
        />
        {item.loading && waiting ? (
          <Button
            variant="link"
            loading
            spinColor="#2983d7"
            style={{ padding: '0 5px' }}
          />
        ) : (
          <i
            className="fa fa-trash icon-button"
            onClick={(ev) => {
              ev.stopPropagation();
              onDelete();
            }}
          />
        )}
      </span>
    </span>
  );
}

function FilterItem(props) {
  return (
    <span className={`list-group-item tree-level-${props.level}`}>
      <FilterItemNormalMode {...props} />
    </span>
  );
}

export default FilterItem;
