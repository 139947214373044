import React from 'react';
import Button from 'react-bootstrap-button-loader';
import { Row, Col } from 'react-bootstrap';
import {
  SimpleInput,
  SimpleTextarea,
} from '../../../commonComponents/Input/Input';

export default class EditCouponModal extends React.Component {
  state = { name: '', description: '' };

  componentDidMount() {
    this.setState({ name: this.props.coupon.name });
    this.setState({ description: this.props.coupon.description });
  }

  validate = () => {
    this.props.onSubmit({
      updatedName: this.state.name,
      updatedDescription: this.state.description,
    });
  };

  render() {
    return (
      <div className="create-coupon-component">
        <div className="card-block">
          <div className="edit-dialog-header">
            <div className="createCouponTitle">Edit coupon</div>
            <i
              style={{ cursor: 'pointer', padding: '0.275rem' }}
              className="fa fa-times fa-xs"
              onClick={() => this.props.onCancel()}
            />
          </div>
          <Row className="mt-2 centerItems">
            <Col md={4}>
              <span className="InputLebel">Name</span>
            </Col>
            <Col md={8} className="mt-2">
              <SimpleInput
                type="text"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Col>
          </Row>
          <Row className="centerItems">
            <Col md={4}>
              <span className="InputLebel">Description</span>
            </Col>
            <Col md={8}>
              <SimpleTextarea
                type="text"
                value={this.state.description}
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </Col>
          </Row>
          <div className="componentFooter">
            <div className="bottom-buttons-new">
              <Button
                id="btn-success"
                className="btn btn-success"
                onClick={() => this.validate()}
              >
                Save
              </Button>
              <Button
                className="btn btn-secondary"
                onClick={this.props.onCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
