import React from 'react';
import { Row, Col } from 'react-bootstrap';
import QualsList from '../../../commonComponents/QualsList/QualsList';
import SimpleConfirmation from '../../../commonComponents/Modals/SimpleConfirmation/SimpleConfirmation';
import Spinner from '../../../commonComponents/Spinner/Spinner';
import './Groups.css';
import AddMemberInGroup from '../../../commonComponents/Modals/AddMemberInGroup/AddMemberInGroup';
import ChangePrimaryWriterContainer from '../../ChangePrimaryWriter/components/ChangePrimaryWriterContainer';
import TeamsRatesList from '../../../commonComponents/TeamsRatesList/TeamsRatesList';

class Groups extends React.Component {
  componentWillMount() {
    this.props.loadRates();
    this.props.loadRoles();
    this.props.loadAdmins();
  }

  componentWillReceiveProps({
    rates,
    isWaiting,
    quals,
    deleteResponse,
    groupToDelete,
  }) {
    if (this.props.rate === null && rates.length) {
      rates = rates.filter(r => r.id && r.id !== 0);
      this.selectRate(rates[0]);
    }

    if (this.props.qual === null && quals.length) {
      this.selectQual(quals[0]);
    }

    if (this.props.isWaiting && !isWaiting) {
      this.props.closeModal();
    }

    if (this.props.deleteResponse !== deleteResponse && groupToDelete) {
      if (deleteResponse) {
        this.openDeletePopup(groupToDelete);
        this.props.updateGroupToDelete(null);
        this.props.updateDeleteResponseState(null);
      } else {
        this.changePrimaryWriterPopup(groupToDelete);
        this.props.updateGroupToDelete(null);
        this.props.updateDeleteResponseState(null);
      }
    }
  }

  selectRate = rate => {
    this.props.setSelectedRate(rate);
    this.props.loadGroupsForStudyProgram('rate', rate.id);
  };

  selectQual = qual => {
    this.props.setSelectedQual(qual);
    this.props.loadGroupsForStudyProgram('qual', qual.id);
  };

  deleteGroup = groupId => {
    this.props.deleteGroup(groupId);
  };

  openAddPopup = (role, groupsForRole) => {
    const adminsAlreadyIn = groupsForRole.map(g => g.adminUser.id);
    let studyProgram = this.props.viewRates && this.props.rate;
    let type = 'rate';
    if (!this.props.viewRates) {
      studyProgram = this.props.qual;
      type = 'qual';
    }
    this.props.openModal({
      modalTitle: 'Add team member',
      modalContent: (
        <AddMemberInGroup
          onSubmit={fields =>
            this.props.addMemberInGroup(fields, studyProgram, role, type)
          }
          requesting={this.props.waiting}
          role={role}
          studyProgram={studyProgram}
          onCancel={this.props.closeModal}
          admins={this.props.admins
            .filter(admin => adminsAlreadyIn.indexOf(admin.id) === -1)
            .filter(admin => !admin.isSuperAdmin)}
          rate={this.props.viewRates}
        />
      ),
    });
  };

  openDeletePopup = group => {
    let studyProgram = this.props.viewRates ? this.props.rate : this.props.qual;
    this.props.openModal({
      modalTitle: 'Removing a group member',
      modalContent: (
        <SimpleConfirmation
          onConfirm={() => this.deleteGroup(group.id)}
          onCancel={this.props.closeModal}
          text={
            <div>
              {'Are you sure you want to remove '}
              <strong>
                {group.adminUser.name} {group.adminUser.lastName}
              </strong>
              {' as '}
              <strong>"{group.role.name}"</strong>
              {' in '}
              <strong>{studyProgram.name}</strong>
              {this.props.viewRates ? ' rate?' : ' qual?'}
            </div>
          }
        />
      ),
    });
  };

  changePrimaryWriterPopup = group => {
    this.props.openModal({
      modalTitle: 'Select new primary writer',
      modalContent: (
        <ChangePrimaryWriterContainer
          onSubmit={this.props.updatePrimaryWriter}
          onCancel={this.props.closeModal}
          rate={this.props.rate}
          qual={this.props.qual}
          writer={group.adminUser.id}
          group={group.id}
        />
      ),
    });
  };

  consultDelete = group => {
    this.props.updateGroupToDelete(group);
    this.props.consultDelete(group);
  };

  toggleList = () => {
    let { viewRates, paginationQuals, ratesPagination } = this.props;
    if (viewRates) {
      this.props.setSelectedRate(null);
      this.props.loadQuals(paginationQuals.page);
    } else {
      this.props.setSelectedQual(null);
      this.props.loadRates(ratesPagination.page);
    }
    this.props.setViewRates(!viewRates);
  };

  setTitle = () => {
    if (this.props.viewRates) {
      return `${this.props.rate ? this.props.rate.name : ''} Members`;
    } else {
      return `${this.props.qual ? this.props.qual.name : ''} Members`;
    }
  };

  render() {
    const { user, generalPermissions, rolesPermissions } = this.props;
    let groupsForRole = this.props.groups.reduce((result, group) => {
      result[group.role.id] = result[group.role.id] || [];
      result[group.role.id].push(group);

      return result;
    }, {});
    let rates = this.props.rates.filter(r => r.id && r.id !== 0);
    let selectedRate = this.props.viewRates ? this.props.rate : this.props.qual;
    return (
      <Row className="groups-component">
        <Col
          xs={6}
          md={3}
          style={{
            paddingRight: '0px',
            paddingLeft: '19px',
            maxWidth: '480px',
            minWidth: '320px',
            overflow: 'visible',
          }}
          className="rates-list-container"
        >
          <div className="ratesListContainer">
            <TeamsRatesList
              rates={this.props.viewRates ? rates : this.props.quals}
              selectedRate={selectedRate}
              onRateClick={this.selectRate}
              pagination={this.props.ratesPagination}
              onPageChange={
                this.props.viewRates
                  ? this.props.loadRates
                  : this.props.loadQuals
              }
              toggleList={this.toggleList}
              onQualsClick={this.selectQual}
              paginationQuals={this.props.paginationQuals}
              viewQuals={!this.props.viewRates}
              generalPermissions={generalPermissions}
              rolesPermissions={rolesPermissions}
              user={user}
            />
          </div>
          {false && (
            <QualsList
              quals={this.props.quals}
              selected={this.props.qual}
              onQualsClick={this.selectQual}
              pagination={this.props.paginationQuals}
              onPageChange={this.props.loadQuals}
            />
          )}
        </Col>

        <Col xs={6} md={6} lg={6}>
          <div className="row groups-content-container">
            {this.props.roles.map(role => (
              <div key={`Role${role.id}`} className="col-sm-12 col-md-12">
                <div className="card">
                  <div className="card-header">
                    {role.name}
                    {(user.isSuperAdmin ||
                      generalPermissions.find(
                        permission => permission === 'Teams.Add',
                      ) ||
                      rolesPermissions.find(
                        role =>
                          Number(role.typeId) === selectedRate.id &&
                          role.actions.find(a => a.includes('Teams.Add')),
                      )) && (
                      <i
                        onClick={() =>
                          this.openAddPopup(role, groupsForRole[role.id] || [])
                        }
                        className="fa fa-plus fa-lg"
                      />
                    )}
                  </div>
                  <div className="card-block">
                    {this.props.isLoadingMembers ? (
                      <Spinner text="loading members" />
                    ) : (
                      <div>
                        {(groupsForRole[role.id] || []).length === 0 ? (
                          <span>No members</span>
                        ) : (
                          (groupsForRole[role.id] || []).map(group => (
                            <div
                              key={`Role${role.id}-User${group.adminUser.id}`}
                              className="member-line"
                            >
                              {`${group.adminUser.name} ${
                                group.adminUser.lastName
                              }`}

                              {(user.isSuperAdmin ||
                                generalPermissions.find(
                                  permission => permission === 'Teams.Remove',
                                ) ||
                                rolesPermissions.find(
                                  role =>
                                    Number(role.typeId) === selectedRate.id &&
                                    role.actions.find(a =>
                                      a.includes('Teams.Remove'),
                                    ),
                                )) && (
                                <i
                                  className="fa fa-trash icon-button"
                                  onClick={() => this.consultDelete(group)}
                                />
                              )}
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    );
  }
}

export default Groups;
