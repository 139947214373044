import './Datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';

export function Datepicker({
  date,
  onDateChange,
  label = '',
  disabled = false,
}) {
  const getJSDate = () => {
    if (date) {
      return moment.isMoment(date) ? date.toDate() : date;
    }
    return null;
  };

  const jsDate = getJSDate();

  return (
    <div className="input-group mb-3">
      <span className="label label-exam-date">{label}</span>
      <div className="date-picker-input">
        <div className="calendar-icon">
          <i className="fa fa-calendar fa-lg" />
        </div>
        <DatePicker
          className="date-blabla"
          selected={jsDate}
          onChange={onDateChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default Datepicker;

export function SimpleDatePicker({
  date,
  onChange,
  placeholderText,
  className,
  disabled = false,
}) {
  const getJSDate = () => {
    if (date) {
      return moment.isMoment(date) ? date.toDate() : date;
    }
    return null;
  };

  const jsDate = getJSDate();

  return (
    <div className="simple-datepicker-container">
      <DatePicker
        selected={jsDate}
        onChange={onChange}
        placeholderText={placeholderText}
        className={className}
        disabled={disabled}
      />
    </div>
  );
}
