import React from 'react';
import { SimpleDatePicker } from '../../../../commonComponents/Datepicker/Datepicker';
import { SimpleInput } from '../../../../commonComponents/Input/Input';
import moment from 'moment';
import './StartDates.css';

class StartDates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: this.props.startDate
        ? moment(Number(this.props.startDate))
        : undefined,
      isApplicable: this.props.isApplicable,
      studyPlan: this.props.studyPlan,
    };
  }

  onDateChange = date => {
    const { studyPlan, isApplicable } = this.state;
    const { rank, dutyState } = this.props;

    this.props.updateStartDate(
      studyPlan,
      rank,
      dutyState,
      date.valueOf(),
      isApplicable
    );
    this.setState({ startDate: date });
  };

  onIsApplicableChange = () => {
    const { studyPlan, startDate, isApplicable } = this.state;
    const { rank, dutyState } = this.props;
    this.props.updateStartDate(
      studyPlan,
      rank,
      dutyState,
      startDate.valueOf(),
      !isApplicable
    );
    this.setState({ isApplicable: !isApplicable });
  };

  render() {
    const { rank, dutyState, disabled } = this.props;
    return (
      <div>
        <span>
          Start Date For <strong>{rank + ' > ' + dutyState}</strong>
        </span>
        <SimpleDatePicker
          date={this.state.startDate}
          placeholderText="YYYY-MM-DD"
          onChange={date => {
            this.onDateChange(date);
          }}
          disabled={disabled}
        />
        <SimpleInput
          type="checkbox"
          value={this.state.isApplicable}
          description="Applicable"
          onChange={() => {
            this.onIsApplicableChange();
          }}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default StartDates;
