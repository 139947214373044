import { connect } from 'react-redux';
import {
  getAssignments,
  getSelectedAdmin,
  getActiveStatus,
  getSelectedRate,
  getIsViewRates,
  isChangingType,
  getLoading,
  getAwaitingApproval,
  getIsReloadAdminAssignments,
  getAssignmentsCount,
  getNoGroups,
  getMyStudyPrograms,
  getIsBackFromQuestion,
  getLoadingCreateQuestions,
  getSummariesSelector,
  getLoadingSummary,
  getSummary,
} from '../../selectors';
import {
  getUser,
  getRolesPermissions,
  getGeneralRolesPermissions,
} from '../../../Login/selectors';
import Assignemnts from './Assignemnts';
import addAbilityToOpenModal from '../../../../utilities/modalHOC';
import {
  onEditAssignment,
  loadAssignments,
  onDeleteAssignment,
  getAllRatesAdmins,
  submitQuestionsGPT,
  submitSummary,
} from '../../actionsSagas';
import {
  onShowQuestionDetails,
  onShowQuestionOverview,
  setLoading,
  setLoadingCreateGPTquestion,
  setReloadAdminAssignments,
  toggleQuestionOverview,
} from '../../actions';
import { getStatusByName } from '../../../../constants/assignments';

const mapStateToProps = (state, ownProps) => {
  return {
    assignments: getAssignments(state),
    selectedAdmin: getSelectedAdmin(state),
    activeStatus: getActiveStatus(state),
    selectedRate: getSelectedRate(state),
    isViewRates: getIsViewRates(state),
    user: getUser(state),
    rolesPermissions: getRolesPermissions(state),
    generalRolesPermissions: getGeneralRolesPermissions(state),
    isChangingType: isChangingType(state),
    loading: getLoading(state),
    awaitingApproval: getAwaitingApproval(state),
    isReloadAdminAssignments: getIsReloadAdminAssignments(state),
    assignmentsCount: getAssignmentsCount(state),
    noGroups: getNoGroups(state),
    myStudyPrograms: getMyStudyPrograms(state),
    isBackFromQuestion: getIsBackFromQuestion(state),
    loadingCreateGPTquestions: getLoadingCreateQuestions(state),
    summaries: getSummariesSelector(state),
    loadingSummary: getLoadingSummary(state),
    summary: getSummary(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onEditAssignment: (assignment, isEditStatus, rejected) => {
      dispatch(onEditAssignment(assignment, isEditStatus, rejected));
    },
    onLoadAssignments: (admin, status, studyProgram) => {
      dispatch(loadAssignments(admin, getStatusByName(status), studyProgram));
    },
    onQuestionDetails: (assignment, rate) => {
      dispatch(onShowQuestionDetails(assignment, rate));
      dispatch(toggleQuestionOverview(true));
    },
    onQuestionOverview: (assignment) => {
      dispatch(onShowQuestionOverview(assignment));
      dispatch(toggleQuestionOverview(true));
    },
    onDelete: (assignment) => {
      dispatch(onDeleteAssignment(assignment));
    },
    updateLoading: (loading) => {
      dispatch(setLoading(loading));
    },
    onLoadAdmins: (
      getInactiveAdmins,
      removingApprovalFilter = false,
      isSetAdmin,
    ) => {
      dispatch(
        getAllRatesAdmins(
          getInactiveAdmins,
          removingApprovalFilter,
          isSetAdmin,
        ),
      );
    },
    setReloadAdminAssignments: (reload) => {
      dispatch(setReloadAdminAssignments(reload));
    },
    toggleQuestionOverview: (value) => {
      dispatch(toggleQuestionOverview(value));
    },
    setLoadingCreateGPTquestion: (value) => {
      dispatch(setLoadingCreateGPTquestion(value));
    },
  };
};

const AssignemntsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Assignemnts);

export default addAbilityToOpenModal(AssignemntsContainer);
