import Notifications from 'react-notification-system-redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import * as api from '../../utilities/ServiceManager';
import { setAllDailyQuizForTodayAction } from './actionSagas';
import {
  GENERATE_DAILY_QUIZ_FOR_TODAY,
  GET_ALL_DALY_QUIZ_FOR_TODAY,
} from './actionTypes';

/* LOAD All daily quiz */
function* getAllDailyQuiz() {
  yield takeLatest(GET_ALL_DALY_QUIZ_FOR_TODAY, getAllDailyQuizForTodaySaga);
}

function* getAllDailyQuizForTodaySaga() {
  try {
    const response = yield call(api.getAllDailyQuizForTodayService);
    yield put(setAllDailyQuizForTodayAction(response));
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  }
}

/* LOAD All daily quiz */
function* generateDailyQuizForToday() {
  yield takeLatest(
    GENERATE_DAILY_QUIZ_FOR_TODAY,
    generateDailyQuizForTodaySaga,
  );
}

function* generateDailyQuizForTodaySaga() {
  try {
    const response = yield call(api.generateDailyQuizForTodayService);
    if (response) {
      yield put(
        Notifications.show(
          {
            title: 'Done!',
            message:
              'Runing daily quiz scheduler in background. The quizzes will be ready in a while.',
            autoDismiss: 5,
          },
          'success',
        ),
      );
    }
  } catch (exception) {
    yield put(showNotificationErrorFromException(exception));
  }
}

function showNotificationErrorFromException(exception) {
  const message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}

export default function* sagas() {
  yield all([getAllDailyQuiz(), generateDailyQuizForToday()]);
}
