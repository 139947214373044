import React from 'react';
import Red from '../Images/red-circle.png';
import Green from '../Images/green-circle.png';
import DropdownIcon from '../Images/dropdown-icon.png';
import ManualRed from '../Images/manual-red.png';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import MoveRowUpDown from '../MoveRowUpDown/MoveRowUpDown';
import { Row, Col } from 'react-bootstrap';
import _ from 'underscore';
import { Link } from 'react-router';

const menuItemStyle = {
  padding: '0px',
  minHeight: '30px',
  fontSize: '14px',
  lineHeight: '30px',
  width: '169px',
};

const menuStyle = { maxWidth: '169px', width: '169px' };

export default class ReferencesGridAccordionLib {
  static getChapterName(chapter, rolesPermissions, superAdmin, rate) {
    let rolePermissionForRate = rolesPermissions.find(
      (permission) => Number(permission.typeId) === rate,
    );
    if (
      superAdmin ||
      (rolePermissionForRate &&
        rolePermissionForRate.actions.find(
          (action) => action === 'Assignment.Publish',
        ) &&
        rolePermissionForRate.actions.find(
          (action) => action === 'Rates.References',
        ))
    ) {
      return (
        <Link
          to={
            '/home/questions/' +
            chapter.reference +
            '/' +
            chapter.id +
            '/' +
            rate
          }
        >
          {chapter.name}
        </Link>
      );
    }
    return <span title={chapter.name}>{chapter.name}</span>;
  }

  static getChaptersForGrid(
    chapters,
    references,
    functions,
    isQual,
    rate,
    rolesPermissions,
    superAdmin,
  ) {
    let orderedChapters = _.sortBy(chapters, 'presentationIndex');
    let formattedChapters = orderedChapters.map((chapter) => {
      let referenceChapters = orderedChapters.filter(
        (chap) => chap.reference === chapter.reference,
      );
      let chapterIndex = referenceChapters.findIndex(
        (i) => i.id === chapter.id,
      );
      return {
        id: chapter.id,
        name: this.getChapterName(chapter, rolesPermissions, superAdmin, rate),
        description: chapter.description,
        questions: chapter.questionCount,
        pdf: this.pdfColumChapter(chapter, functions.onDeletePdfChapter),
        status: this.statusColumnChapter(
          chapter,
          functions.onStateChapterClick,
        ),
        writer: this.writerColumnChapter(chapter),
        settings: this.settingsColumnChapter(
          chapter,
          references,
          {
            chapter,
            previous: referenceChapters[chapterIndex - 1],
            next: referenceChapters[chapterIndex + 1],
          },
          functions.onChangeSettingsChapter,
          functions.onMoveChapters,
          isQual,
        ),
        reference: chapter.reference,
        indicatorIndex: chapter.presentationIndex,
        movePresentationIndex: this.getIndicatorIndex(
          chapter,
          referenceChapters[chapterIndex - 1],
          referenceChapters[chapterIndex + 1],
          functions.onMoveChapters,
        ),
        subtopicsIndicator: this.subtopicsIndicatorChapter(
          chapter,
          functions.onChangeSubtopicsIndicator,
        ),
        questionsIndicator: this.questionsIndicatorChapter(
          chapter,
          functions.onChangeQuestionsIndicator,
        ),
      };
    });

    return formattedChapters;
  }

  static pdfColumChapter(chapter, onDeletePdfChapter) {
    if (chapter.pdfUrl !== '') {
      return (
        <div>
          <a href={chapter.pdfUrl} target="_blank">
            <i className="fa fa-file-pdf-o fa-lg mt-2" />
          </a>
          <i
            onClick={() => {
              onDeletePdfChapter(chapter);
            }}
            className="fa fa-trash fa-lg mt-2"
          />
        </div>
      );
    }
  }

  static statusColumnChapter(chapter, onStateChapterClick) {
    if (chapter.enabled) {
      return (
        <div
          className="statusLabel"
          onClick={() => {
            onStateChapterClick(chapter);
          }}
        >
          Active
        </div>
      );
    }
    return (
      <div
        className="statusLabel"
        onClick={() => {
          onStateChapterClick(chapter);
        }}
      >
        Disabled
      </div>
    );
  }

  static writerColumnChapter(chapter, isFromReference) {
    if (chapter && chapter.primaryWriter) {
      let secondaryChapters = '';
      if (chapter.writers && chapter.writers.length > 0) {
        for (let writer of chapter.writers) {
          secondaryChapters += secondaryChapters !== '' ? '\n' : '';
          secondaryChapters += writer.name + ' ' + writer.lastName;
        }
      }
      return (
        <div title={secondaryChapters}>
          {chapter.primaryWriter.name + ' ' + chapter.primaryWriter.lastName}
        </div>
      );
    }
    return '';
  }

  static settingsColumnChapter(
    chapter,
    references,
    moveChapter,
    onChangeSettingsChapter,
    onMoveChapters,
    isQual,
  ) {
    let colSize = moveChapter ? 6 : 12;
    let ref = references.find(
      (reference) => reference.id === chapter.reference,
    );
    return (
      <Row className="noMargins settingsRow">
        {moveChapter &&
          this.getIndicatorIndex(
            moveChapter.chapter,
            moveChapter.previous,
            moveChapter.next,
            onMoveChapters,
          )}
        <Col xs={colSize} md={colSize}>
          <MuiThemeProvider>
            <IconMenu
              iconButtonElement={<i className="fa fa-gear fa-lg mt-2" />}
              menuStyle={menuStyle}
              menuItemStyle={menuItemStyle}
              onItemTouchTap={(event, child) =>
                onChangeSettingsChapter(event, child, chapter, ref)
              }
            >
              <MenuItem
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                targetOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                Edit
              </MenuItem>
              <MenuItem
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                style={{ padding: '0px' }}
              >
                Delete
              </MenuItem>
              <MenuItem
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                targetOrigin={{ horizontal: 'right', vertical: 'top' }}
              >
                Delete all questions
              </MenuItem>
              {!isQual && (
                <MenuItem
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                  Edit Subtopics
                </MenuItem>
              )}
              {ref && ref.actAsParent && (
                <MenuItem
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                >
                  Assign writer
                </MenuItem>
              )}
            </IconMenu>
          </MuiThemeProvider>
        </Col>
      </Row>
    );
  }

  static getIndicatorIndex(current, previous, next, onMoveChapters) {
    return (
      <Col md={6}>
        <MoveRowUpDown
          current={current}
          up={previous}
          down={next}
          onMoveRows={onMoveChapters}
        />
      </Col>
    );
  }

  static subtopicsIndicatorChapter(chapter, onChangeSubtopicsIndicator) {
    let icon = (
      <div>
        <img alt="green" className="circleSize" src={Green} loading="lazy" />
        <img
          alt="dropdown-icon"
          className="circleSize"
          src={DropdownIcon}
          loading="lazy"
        />
      </div>
    );
    let textMenu = 'Set manual red';
    if (chapter.indicators.subtopics === 'red') {
      icon = (
        <div>
          <img alt="red" className="circleSize" src={Red} loading="lazy" />
          <img
            alt="dropdown-icon"
            className="circleSize"
            src={DropdownIcon}
            loading="lazy"
          />
        </div>
      );
    }
    if (chapter.indicators.subtopics === 'forced') {
      icon = (
        <div>
          <img
            className="circleSizeManualRed"
            title={chapter.subtopicIndicatorReason}
            alt={chapter.subtopicIndicatorReason}
            src={ManualRed}
            loading="lazy"
          />
          <img
            alt="dropdown-icon"
            className="circleSize"
            src={DropdownIcon}
            loading="lazy"
          />
        </div>
      );
      textMenu = 'Remove';
    }
    return (
      <MuiThemeProvider>
        <IconMenu
          iconButtonElement={icon}
          menuStyle={menuStyle}
          menuItemStyle={menuItemStyle}
          onItemTouchTap={(event, child) =>
            onChangeSubtopicsIndicator(event, child, chapter, textMenu)
          }
        >
          <MenuItem
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {textMenu}
          </MenuItem>
        </IconMenu>
      </MuiThemeProvider>
    );
  }

  static questionsIndicatorChapter(chapter, onChangeQuestionsIndicator) {
    let icon = (
      <div>
        <img alt="green" className="circleSize" src={Green} loading="lazy" />
        <img
          alt="dropdown-icon"
          className="circleSize"
          src={DropdownIcon}
          loading="lazy"
        />
      </div>
    );
    let textMenu = 'Set manual red';
    if (chapter.indicators.questions === 'red') {
      icon = (
        <div>
          <img alt="red" className="circleSize" src={Red} loading="lazy" />
          <img
            alt="dropdown-icon"
            className="circleSize"
            src={DropdownIcon}
            loading="lazy"
          />
        </div>
      );
    }
    if (chapter.indicators.questions === 'forced') {
      icon = (
        <div>
          <img
            className="circleSizeManualRed"
            title={chapter.questionIndicatorReason}
            alt={chapter.questionIndicatorReason}
            src={ManualRed}
            loading="lazy"
          />
          <img
            alt="dropdown-icon"
            className="circleSize"
            src={DropdownIcon}
            loading="lazy"
          />
        </div>
      );
      textMenu = 'Remove';
    }
    return (
      <MuiThemeProvider>
        <IconMenu
          iconButtonElement={icon}
          menuStyle={menuStyle}
          menuItemStyle={menuItemStyle}
          onItemTouchTap={(event, child) =>
            onChangeQuestionsIndicator(event, child, chapter, textMenu)
          }
        >
          <MenuItem
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            {textMenu}
          </MenuItem>
        </IconMenu>
      </MuiThemeProvider>
    );
  }
}
