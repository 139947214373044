import * as type from './actionTypesSagas';

export const loadReferences = (
  id,
  sptype,
  search,
  page,
  isLoadingInfiniteList,
  rate,
  amountOfReferences,
  barFilter,
  duplicateChapter,
) => {
  return {
    type: type.LOAD_REFERENCES,
    id,
    sptype,
    search,
    page,
    isLoadingInfiniteList,
    rate,
    amountOfReferences,
    barFilter,
    duplicateChapter,
  };
};

export const loadExam = (dutyState, rank, rate, cycle) => ({
  type: type.LOAD_EXAM,
  dutyState,
  rank,
  rate,
  cycle,
});

export const getReferenceById = (referenceId) => ({
  type: type.GET_REFERENCE_BY_ID,
  referenceId,
});

export const duplicateChapterAction = (referenceId, chapterId, rateId) => ({
  type: type.DUPLICATE_CHAPTER,
  referenceId,
  chapterId,
  rateId,
});

export const createReferenceActionSagas = (fields, rate, stype) => ({
  type: type.CREATE_REFERENCE_SAGAS,
  fields,
  rate,
  stype,
});

export const createChapterActionSagas = (fields) => ({
  type: type.CREATE_CHAPTER_SAGAS,
  fields,
});

export const submitBibliographyActionSagas = (bibliography) => ({
  type: type.SUBMIT_BIB_SAGAS,
  bibliography,
});

export const loadLogs = (ratingExam, dutyState) => ({
  type: type.LOAD_TYPES,
  ratingExam,
  dutyState,
});

export const getUrlPdf = (payload) => ({
  type: type.GET_PDF_BY_REFERENCE,
  payload,
});

export const getRateRatingExamQuestions = (rate, isReplace, cycle) => ({
  type: type.LOAD_RATING_EXAM_QUESTIONS,
  rate,
  cycle,
  isReplace,
});

export const getRateQuestionsPercentage = (rate) => ({
  type: type.LOAD_RATE_QUESTION_PERCENTAGE,
  rate,
});

export const loadRateBibliographyJson = (rate) => ({
  type: type.LOAD_RATE_BIBLIOPGRAPHY_JSON,
  rate,
});

export const loadRateChapterQuestionsReport = (rate) => ({
  type: type.LOAD_RATE_CHAPTER_QUESTION_REPORT,
  rate,
});

export const loadReportSaga = (report) => ({
  type: type.LOAD_REPORT,
  report,
});
