import { USER_SET, USER_UNSET, VERIFY_IS_MAINTENANCE } from './constants';

// there's literally no reason these are in a different
// format from the other component actions other than
// that I just lost track
export function setUser(token) {
  return {
    type: USER_SET,
    token,
  };
}

export function unsetUser() {
  return {
    type: USER_UNSET,
  };
}

export const verifyIsMaintenance = () => ({
  type: VERIFY_IS_MAINTENANCE,
});
