import { all, put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import Notifications from 'react-notification-system-redux';
import { getRequestErrorMessage } from '../../utilities/SagasHelper';
import { reset } from 'redux-form';
import {
  setDefinitions,
  updateWaiting,
  removeDefinition,
  updateDefinition,
  addDefinition,
  setQuestionDefinitions,
  addQuestionDefinition,
  updateQuestionDefinition,
  removeQuestionDefinition,
} from './actions';
import {
  LOAD_DEFINITIONS_BY_REFERENCE,
  REMOVE_DEFINITIONS,
  EDIT_DEFINITION,
  CREATE_DEFINITION,
  LOAD_DEFINITIONS_BY_QUESTION,
} from './actionTypesSagas';
import * as api from '../../utilities/ServiceManager';

/**
 * LOAD Definitions
 */
function* loadDefinitionsSaga() {
  yield takeLatest(LOAD_DEFINITIONS_BY_REFERENCE, callLoadDefinitions);
}

function* callLoadDefinitions(action) {
  try {
    yield put(updateWaiting(true));
    const definitions = yield call(
      api.getDefinitionsByReference,
      action.reference.id,
    );
    yield put(setDefinitions(definitions));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load definitions. Please try again later.',
      ),
    );
  } finally {
    yield put(updateWaiting(false));
  }
}

/**
 * LOAD Definitions by questions
 */
function* loadDefinitionsQuestionsSaga() {
  yield takeLatest(LOAD_DEFINITIONS_BY_QUESTION, callLoadDefinitionsQuestions);
}

function* callLoadDefinitionsQuestions(action) {
  try {
    yield put(updateWaiting(true));
    const definitions = yield call(
      api.getDefinitionsByQuestion,
      action.question.id,
    );
    yield put(setQuestionDefinitions(definitions));
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not load definitions. Please try again later.',
      ),
    );
  } finally {
    yield put(updateWaiting(false));
  }
}

/**
 * Remove Definitions
 */
function* removeDefinitionsSaga() {
  yield takeEvery(REMOVE_DEFINITIONS, callRemoveDefinitions);
}

function* callRemoveDefinitions(action) {
  try {
    yield put(updateWaiting(true));
    yield call(api.removeDefinitions, action.id, action.assignment);
    if (action.isQuestion) {
      yield put(removeQuestionDefinition(action.id));
    } else {
      yield put(removeDefinition(action.id));
    }
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Definition deleted successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not delete definition. Please try again later.',
      ),
    );
  } finally {
    yield put(updateWaiting(false));
  }
}

/**
 * Edit Definitions
 */
function* editDefinitionsSaga() {
  yield takeEvery(EDIT_DEFINITION, callEditDefinition);
}

function* callEditDefinition(action) {
  try {
    yield put(updateWaiting(true));
    const definition = yield call(
      api.editDefinitions,
      action.item,
      action.assignment,
    );
    if (action.isQuestion) {
      yield put(updateQuestionDefinition(definition));
    } else {
      yield put(updateDefinition(definition));
    }
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Definition edited successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not delete definition. Please try again later.',
      ),
    );
  } finally {
    yield put(updateWaiting(false));
  }
}

/**
 * Create Definitions
 */
function* createDefinitionSaga() {
  yield takeEvery(CREATE_DEFINITION, callCreateDefinition);
}

function* callCreateDefinition(action) {
  try {
    yield put(updateWaiting(true));
    const definition = yield call(
      api.createDefinitions,
      action.item,
      action.assignment,
    );
    yield put(reset('DefinitionsListEmptyRow'));
    if (action.item.question) {
      yield put(addQuestionDefinition(definition));
    } else {
      yield put(addDefinition(definition));
    }
    yield put(
      Notifications.show(
        {
          title: 'Done!',
          message: 'Definition created successfully!',
          autoDismiss: 1,
        },
        'success',
      ),
    );
  } catch (exception) {
    yield put(
      showNotificationErrorFromException(
        exception,
        'Could not create definition. Please try again later.',
      ),
    );
  } finally {
    yield put(updateWaiting(false));
  }
}

/**
 * DEFAUL EXPORT
 */
export default function* sagas() {
  yield all([
    loadDefinitionsSaga(),
    removeDefinitionsSaga(),
    editDefinitionsSaga(),
    createDefinitionSaga(),
    loadDefinitionsQuestionsSaga(),
  ]);
}

function showNotificationErrorFromException(exception) {
  const message = getRequestErrorMessage(exception);

  return Notifications.show({ title: 'Ops!', message }, 'error');
}
