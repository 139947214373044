import React from 'react';
import { Col } from 'react-bootstrap';
import SubHeader from '../../commonComponents/SubHeader/SubHeader';
const listOptions = ['Rates', 'Quals'];

export default class StudyProgramslist extends React.Component {
  state = {
    activeTab: 'Rates',
  };

  getActiveContent = () => {
    let { quals = [], rates = [], selectedSP } = this.props;
    if (this.state.activeTab === 'Rates') {
      return rates.map((rate, index) => {
        if (rate.id !== 0) {
          return (
            <SPLine
              sp={rate}
              key={`SPLine_${index}`}
              isSelected={selectedSP && selectedSP.id === rate.id}
              onClick={this.props.onSPClick}
            />
          );
        }
        return '';
      });
    }
    return quals.map(qual => (
      <SPLine
        sp={qual}
        isSelected={selectedSP && selectedSP.id === qual.id}
        onClick={this.props.onSPClick}
      />
    ));
  };

  render() {
    return (
      <Col
        md={3}
        className="GroupList"
        style={{
          paddingLeft: '0px',
          paddingRight: '0px',
          minWidth: '400px',
          background: '#eaecee',
          overflow: 'scroll',
        }}
      >
        <SubHeader
          options={listOptions}
          onOptionChange={activeTab => this.setState({ activeTab })}
          activeOption={this.state.activeTab}
          onPlusClick={() => {
            this.props.onSPClick({
              type: this.state.activeTab === 'Rates' ? 'rate' : 'qual',
            });
          }}
        />
        <div className="rates-list-component">{this.getActiveContent()}</div>
      </Col>
    );
  }
}

class SPLine extends React.Component {
  getName(sp) {
    if (!sp) {
      return '';
    }
    return sp.shortname ? `(${sp.shortname}) ${sp.name}` : sp.name;
  }
  render() {
    return (
      <div
        className={`rate-line ${this.props.isSelected ? 'active' : ''}`}
        onClick={() => this.props.onClick(this.props.sp)}
      >
        {this.getName(this.props.sp)}
      </div>
    );
  }
}
