import React from 'react';
import './Stats.css';
import { Row, Col } from 'react-bootstrap';

export default class Stats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getStat(label, value) {
    return (
      <div className={'statContainer ' + label}>
        <div className="label">{label}</div>
        <div className="statValue">{value}</div>
      </div>
    );
  }

  calculatePercentage(total, correct) {
    if (total === 0) return 0;
    return Math.floor(correct * 100 / total);
  }

  render() {
    const { answered, correct, incorrect } = this.props;
    return (
      <Row className="statsContainer" style={{ width: '100%' }}>
        <Col style={{ paddingRight: '0px' }}>
          {this.getStat('Answered', answered)}
        </Col>
        <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          {this.getStat('Correct', correct)}
        </Col>
        <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          {this.getStat('Incorrect', incorrect)}
        </Col>
        <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          {this.getStat(
            'Percentage',
            this.calculatePercentage(answered, correct) + '%'
          )}
        </Col>
      </Row>
    );
  }
}
